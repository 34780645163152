import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'communicationSvg'

function CommunicationSvg({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4108 2.55758C18.1925 2.60347 17.8931 2.69456 17.4056 2.84457L4.11101 6.93521C3.49623 7.12437 3.10015 7.24721 2.82806 7.35784C2.80623 7.36671 2.78633 7.37509 2.76823 7.38296C2.78475 7.39366 2.80296 7.40518 2.82299 7.41749C3.07319 7.57134 3.44369 7.7576 4.01902 8.04527L9.03326 10.5524L13.4467 6.13896C13.8372 5.74844 14.4704 5.74844 14.8609 6.13896C15.2514 6.52948 15.2514 7.16265 14.8609 7.55317L10.4475 11.9666L12.9546 16.9809C13.2422 17.5561 13.4286 17.9266 13.5825 18.1766C13.5948 18.1967 13.6064 18.215 13.6171 18.2315C13.625 18.2135 13.6333 18.1937 13.6421 18.1719C13.7528 17.8999 13.8756 17.5038 14.0648 16.889L18.1554 3.59446C18.3054 3.1069 18.3966 2.80742 18.4425 2.58906C18.4455 2.57493 18.4481 2.5618 18.4504 2.54965C18.4382 2.55196 18.425 2.55459 18.4108 2.55758ZM17.9993 0.600365C18.3179 0.533381 18.7461 0.475259 19.1779 0.633772C19.7289 0.836034 20.1638 1.27029 20.3664 1.822C20.5249 2.2539 20.4667 2.68223 20.3997 3.00076C20.3326 3.31943 20.2132 3.70744 20.0802 4.13952L15.9626 17.5218C15.7909 18.0799 15.6427 18.5617 15.4949 18.9252C15.3569 19.2644 15.1342 19.7353 14.6842 20.0165C14.1357 20.3592 13.4556 20.4155 12.8582 20.1676C12.3683 19.9643 12.0711 19.5367 11.8791 19.2247C11.6728 18.8894 11.4466 18.437 11.1845 17.9128L8.57484 12.6934C8.52744 12.5986 8.50383 12.5518 8.48551 12.5184C8.48499 12.5174 8.48449 12.5165 8.48402 12.5157C8.48344 12.5154 8.48284 12.515 8.48222 12.5147C8.44853 12.4962 8.40165 12.4727 8.3065 12.4251L3.08241 9.81303C2.56034 9.55203 2.10964 9.3267 1.77541 9.12119C1.46353 8.92942 1.03561 8.63228 0.832244 8.14216C0.584275 7.54455 0.640915 6.86409 0.983501 6.31578C1.26467 5.86578 1.7356 5.64302 2.07477 5.50512C2.43835 5.35729 2.92012 5.20908 3.4782 5.03739C3.49302 5.03283 3.5079 5.02825 3.52284 5.02365L16.8595 0.920052C17.2921 0.786935 17.6804 0.667418 17.9993 0.600365Z"
        fill="white"
      />
    </svg>
  )
}
export default CommunicationSvg
