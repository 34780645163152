import React from 'react'
import { TClassName } from '../types/props'

const HeadHunter: React.FC<TClassName> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
    <rect width="20" height="20" rx="10" fill="#DF0016" />
    <path d="M5.58801 5.28702V8.689C6.10601 8.072 6.72601 7.76102 7.44601 7.76102C7.81701 7.76102 8.149 7.832 8.447 7.972C8.746 8.112 8.96901 8.29101 9.12001 8.50801C9.27201 8.72801 9.374 8.96801 9.429 9.23301C9.484 9.49701 9.51201 9.907 9.51201 10.464V14.101H7.90301V10.825C7.90301 10.175 7.87401 9.76299 7.81301 9.58799C7.75201 9.41199 7.64401 9.273 7.49001 9.172C7.33501 9.067 7.14201 9.016 6.90901 9.016C6.64301 9.016 6.40301 9.083 6.19501 9.215C5.98301 9.348 5.83001 9.548 5.73301 9.814C5.63501 10.081 5.58601 10.474 5.58801 10.996L5.586 14.102H3.979V5.28799H5.58801" fill="white" />
    <path d="M11.9239 5.28702V8.689C12.4419 8.072 13.0619 7.76102 13.7819 7.76102C14.1509 7.76102 14.4859 7.832 14.7839 7.972C15.0809 8.112 15.3049 8.29101 15.4549 8.50801C15.6069 8.72801 15.7099 8.96801 15.7639 9.23301C15.8189 9.49701 15.8469 9.907 15.8469 10.464V14.101H14.2399V10.825C14.2399 10.175 14.2099 9.76299 14.1479 9.58799C14.0869 9.41199 13.9799 9.273 13.8239 9.172C13.6709 9.067 13.4759 9.016 13.2429 9.016C12.9769 9.016 12.7379 9.083 12.5289 9.215C12.3189 9.348 12.1659 9.548 12.0679 9.814C11.9719 10.081 11.9229 10.474 11.9229 10.996V14.102H10.3159V5.28799H11.9229" fill="white" />
  </svg>
)
export default HeadHunter
