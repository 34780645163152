import { put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios/index'
import {
  cancelInterviewModals,
  editInterviewModals, endInterviewModals, getEmptySlots, setEmptySlots, TCancelInterviewModals,
  TEditInterviewModals, TEndInterviewModals

} from './actions'

import { fetchPatchChangeStatusVacancy, fetchPostDuplicateVacancy, TVacancy } from '../../common/api/vacancy'
import { setDuplicateStatusOpen, setOpenModalChangeStatus } from '../../compositions/ManagementVacancies/actions'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { fetchPatchInterview } from '../../common/api/interviews'
import { fetchGetEmptySlots } from '../../common/api/calendar'
import { TGetAllEmptySlots } from '../../compositions/candidate/actions'
import { setLoadingModal, setLoadingProgress } from '../../compositions/InnerRouter/actions'

const moduleName = 'Страница интервью'
function* cancelInterviewSaga({ payload }: PayloadAction<TCancelInterviewModals>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TVacancy = yield fetchPatchInterview(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отменить интервью',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setDuplicateStatusOpen(false))
      showSuccessNotification({
        text: 'Интервью отменено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}
function* editInterviewSaga({ payload: { status, id } }: PayloadAction<TEditInterviewModals>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TVacancy = yield fetchPatchChangeStatusVacancy(id, status)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить интервью',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setOpenModalChangeStatus(false))
      showSuccessNotification({
        text: 'Интервью изменено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}
function* endInterviewSaga({ payload: { status, id } }: PayloadAction<TEndInterviewModals>) {
  try {
    const response: TVacancy = yield fetchPatchChangeStatusVacancy(id, status)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось выполнить действие "завершить интервью"',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setOpenModalChangeStatus(false))
      showSuccessNotification({
        text: 'Действие "завершить интервью" выполнено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingModal(false))
  }
}
function* getEmptySlotsSaga({ payload }: PayloadAction<TGetAllEmptySlots>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetEmptySlots(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setEmptySlots(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

export function* modalsInterviewsSaga() {
  yield takeLatest(getEmptySlots, getEmptySlotsSaga)
  yield takeLatest(cancelInterviewModals, cancelInterviewSaga)
  yield takeLatest(editInterviewModals, editInterviewSaga)
  yield takeLatest(endInterviewModals, endInterviewSaga)
}
