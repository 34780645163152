/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  memo
} from 'react'
import { Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import dayjs from 'dayjs'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import filterNullAndEmptyKeys, {
  getRelocateDetailList,
  getTransferDetailList,
  getWhishesDetailList
} from '../../candidate/utils'

import { TClassName } from '../../../common/types/props'

import { DetailsList } from '../../../common/components/DetailsList'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import './SummaryInfo.scss'
import { selectActiveResume } from '../../../compositions/Resume'

type TProps = {
  className: TClassName
  readOnly: boolean
}
function SummaryInfo({ className, readOnly }: TProps) {
  const dispatch = useDispatch()
  const activeResume = useSelector(selectActiveResume)

  const mainCSSClass = 'summaryInfoInterview'

  return (
    <div className={`${mainCSSClass}`} style={{ height: '100%' }}>
      <NewScrollContainer className={`${mainCSSClass}_scroll`}>
        <div className={`${mainCSSClass}_list`}>
          {activeResume?.specialization && activeResume?.position && activeResume?.wages && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <DetailsList
                  size="md"
                  items={[{
                    label: 'Пожелания кандидата',
                    items: getWhishesDetailList(
                      activeResume.position as string,
                      activeResume.specialization,
                      activeResume.wages,
                      activeResume.labels.currency,
                      activeResume.labels.employment_list.map((el) => el.value).join(', '),
                      activeResume.labels.schedule_list.map((el) => el.value).join(', ')
                    )
                  }]}
                />
              </div>
            </div>
          )}

          {activeResume?.can_relocate && activeResume?.can_business_trip && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <DetailsList
                  size="md"
                  items={[{
                    label: 'Готовность к переездам/командировкам',
                    items: getRelocateDetailList(
                      activeResume.labels.can_relocate.label,
                      activeResume.labels.can_business_trip.label
                    )
                  }]}
                />
              </div>

            </div>
          )}

          {activeResume?.skill_list && activeResume?.skill_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Ключевые навыки </Typography.Title>
                <Typography.Text className={`${mainCSSClass}_list_skills`}>
                  {activeResume.skill_list.map((e) => e.label).join(' ')}
                </Typography.Text>
              </div>

            </div>
          )}

          {activeResume?.certificate_list && activeResume?.certificate_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Cертификаты </Typography.Title>

                {activeResume.certificate_list.map((e) => (
                  <div>
                    <div>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                        {e.number}
                      </Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        ({e.name}){' '}
                      </Typography.Text>
                    </div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.link}</Typography.Text>
                  </div>
                ))}
              </div>

            </div>
          )}

          {activeResume?.recommendation_list && activeResume?.recommendation_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Рекомендации </Typography.Title>

                {activeResume.recommendation_list.map((e) => (
                  <div>
                    <div>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>{e.fio}</Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        {e.position}{' '}
                      </Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        ({e.company})
                      </Typography.Text>
                    </div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.contact}</Typography.Text>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeResume?.info && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Дополнительная информация</Typography.Title>
                <Typography.Paragraph className={`${mainCSSClass}_list_skills`}>
                  {activeResume.info}
                </Typography.Paragraph>
              </div>
            </div>
          )}

          {activeResume?.language && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Языки</Typography.Title>

                <Typography.Paragraph className={`${mainCSSClass}_list_skills`}>
                  {activeResume.labels?.language.map((item) => `${item.label} - ${item.level}`).join(', ')}
                </Typography.Paragraph>
              </div>
            </div>
          )}

          {activeResume?.course_list && activeResume?.course_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Курсы повышения квалификации </Typography.Title>

                {activeResume.course_list.map((e) => (
                  <div>
                    <div>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                        {e.name}
                      </Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        {e.completed_at}{' '}
                      </Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        (Получен диплом)
                      </Typography.Text>
                    </div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.side}</Typography.Text>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeResume?.work_permit && activeResume?.transfer_time && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <DetailsList
                  size="md"
                  items={[{
                    label: 'Гражданство, время пути до работы',
                    items: getTransferDetailList(
                      activeResume.labels.citizenship,
                      activeResume.labels.work_permit,
                      activeResume.labels.transfer_time
                    )
                  }]}
                />
              </div>
            </div>
          )}

          {activeResume?.education_list && activeResume?.education_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Образование </Typography.Title>

                {activeResume.education_list.map((e) => (
                  <div>
                    <div>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                        {e.name}
                      </Typography.Text>
                      {e.period_start && e.period_end && (
                        <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                          ({e.period_start.slice(0, 4)}-{e.period_end.slice(0, 4)})
                        </Typography.Text>
                      )}
                      {e.period_start && !e.period_end && (
                        <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                          ({e.period_start.slice(0, 4)})
                        </Typography.Text>
                      )}
                    </div>

                    <div>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>Высшее</Typography.Text>
                      {e.faculty && (
                        <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                          {e.faculty}
                        </Typography.Text>
                      )}
                    </div>

                    {e.specialization && (
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        {e.specialization}
                      </Typography.Text>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeResume?.experience_list && activeResume?.experience_list.length > 0 && (
            <div className={`${mainCSSClass}_list_items`}>
              <div>
                <Typography.Title level={5}>Опыт работы </Typography.Title>

                {activeResume.experience_list.map((e) => (
                  <div className={`${mainCSSClass}_list_items_experience`}>
                    <div className={`${mainCSSClass}_list_items_experience_years`}>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                        {dayjs(e.period_start).format(DATE_DOTS_FORMAT)} - {e.period_end ? dayjs(e.period_end).format(DATE_DOTS_FORMAT) : 'По настоящее время'}
                      </Typography.Text>
                    </div>
                    <div className={`${mainCSSClass}_list_items_experience_info`}>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                        {e.organization}
                      </Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>Москва</Typography.Text>
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        {e.description}
                      </Typography.Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </NewScrollContainer>
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(SummaryInfo))
