import {
  put, takeLatest, take, race
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  TRequestAnalytics,
  TRequestAnalyticsWithMethods,
  fetchGetSourseOfCandidates,
  fetchGetVertexInfo,
  fetchGetProductivity,
  TRequestAnalyticsEffectivity,
  fetchGetPlanFactInfo, TAnalyticsRejection, fetchGetRejectionInfo
} from '../../common/api/analytics'
import {
  TRequestPlanFact,
  getDictionaries,
  getPlanFact,
  getProductivity,
  getSourseOfCandidates,
  getVertexInfo,
  setDictionaries,
  setLoading,
  setPlanFact,
  setProductivity,
  setSourseOfCandidates,
  setVertexInfo,
  setRejectionInfo, getRejectionInfo
} from './actions'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { setLoadingProgress } from '../InnerRouter/actions'

const moduleName = 'Страница аналитики'

function* getSourseOfCandidatesSaga({ payload }: PayloadAction<TRequestAnalytics>) {
  try {
    if (!payload.download) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const {response} = yield race({
      response: fetchGetSourseOfCandidates(payload),
      cancel: take(getSourseOfCandidates.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (!response.data.ERR) {
      if (payload.download) {

      } else {
        yield put(setSourseOfCandidates(response.data.DATA))

      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getProductivitySaga({ payload }: PayloadAction<TRequestAnalyticsEffectivity>) {
  try {
    if (!payload.download) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const {response} = yield race({response: fetchGetProductivity(payload), cancel: take(getProductivity.type)})
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (!response.data.ERR) {
      if (payload.download) {

      } else {
        yield put(setProductivity(response.data.DATA))

      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getVertexInfoSaga({ payload }: PayloadAction<TRequestAnalyticsWithMethods>) {
  try {
    if (!payload.download) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const {response} = yield race({response: fetchGetVertexInfo(payload), cancel: take(getVertexInfo.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (!response.data.ERR) {
      if (payload.download) {

      } else {
        yield put(setVertexInfo(response.data.DATA))

      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getPlanFactSaga({ payload }: PayloadAction<TRequestPlanFact>) {
  try {
    if (!payload.download) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const {response} = yield race({response: fetchGetPlanFactInfo(payload), cancel: take(getPlanFact.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить отчёт план факт',
          error: response
        })
      )
    } else if (!response.data.ERR) {
      if (payload.download) {

      } else {
        yield put(setPlanFact(response.data.DATA))

      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getRejectionInfoSaga({ payload }: PayloadAction<TAnalyticsRejection>) {
  try {
    if (!payload.download) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const {response} = yield race({response: fetchGetRejectionInfo(payload), cancel: take(getRejectionInfo.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (!response.data.ERR) {
      if (payload.download) {

      } else {
        yield put(setRejectionInfo(response.data.DATA))

      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'Отчет страница')
}

function* analyticsSaga() {
  yield takeLatest(getSourseOfCandidates, getSourseOfCandidatesSaga)
  yield takeLatest(getVertexInfo, getVertexInfoSaga)
  yield takeLatest(getProductivity, getProductivitySaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getPlanFact, getPlanFactSaga)
  yield takeLatest(getRejectionInfo, getRejectionInfoSaga)
}

export default analyticsSaga
