import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ employeesCenter }: TRootState) => employeesCenter

export const selectOpenEmployeesCenter = createSelector(selectState, ({ openEmployeesCenter }) => openEmployeesCenter)

export const selectEmployeesTableData = createSelector(selectState, ({ employeesTableData }) => employeesTableData)
export const selectEmployeesStatusesBars = createSelector(selectState, ({ employeesStatusesBars }) => employeesStatusesBars)
export const selectEmployeesStatusesDiagram = createSelector(selectState, ({ employeesStatusesDiagram }) => employeesStatusesDiagram)
export const selectEmployeesCenterLoading = createSelector(selectState, ({ loadingEmployeesCenter }) => loadingEmployeesCenter)
