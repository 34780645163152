import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { selectNewTrigger, selectTriggers } from '../selectors'
import {
  TTriggers, deleteTrigger, getTriggers, openNewTrigger, reset, setActiveTrigger
} from '..'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'

import NewTrigger from './NewTrigger'
import EditPencil1 from '../../../common/svg/EditPencil1'
import TrashFull from '../../../common/svg/TrashFull'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './Triggers.scss'

const mainCssClass = getCssClassName('triggers')

function Triggers() {
  const dispatch = useDispatch()
  const newTrigger = useSelector(selectNewTrigger)
  const triggers = useSelector(selectTriggers)
  const { rbacControl } = useEntityContext()

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(getTriggers())
  }, [])

  const handleEditTriggers = (item:TTriggers) => {
    dispatch(setActiveTrigger(item))
    dispatch(openNewTrigger(true))
  }

  const handleDeleteTrigger = (id:number) => {
    dispatch(deleteTrigger(id))
  }

  return (
    <div className={mainCssClass}>
      { !newTrigger && (
        <div className={`${mainCssClass}_create`}>
          <Typography.Title level={3}>
            Триггеры
          </Typography.Title>
          { rbacControl?.template.create_access === 1 && (
            <ControlButton typeButton="secondary" size="large" icon="left" className="button" onClick={() => dispatch(openNewTrigger(true))}>
              <AddPlusSvg />
              Создать триггер
            </ControlButton>
          )}

          <div className={`${mainCssClass}_create_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_create_triggers-list`}>

                {
                  triggers?.map((item) => (
                    <div
                      className={`${mainCssClass}_create_triggers-list_item`}
                    >
                      <div className={`${mainCssClass}_create_triggers-list_item_text`}>
                        <Typography.Text className={`${mainCssClass}_create_triggers-list_item_text_title`}>
                          {item.name}
                        </Typography.Text>
                        { item?.status === 1 ? (
                          <Typography.Text className={`${mainCssClass}_create_triggers-list_item_text_quantity`}>
                            Активный
                          </Typography.Text>
                        )
                          : (
                            <Typography.Text className={`${mainCssClass}_create_triggers-list_item_text_quantity`}>
                              Неактивный
                            </Typography.Text>
                          )}
                      </div>
                      <div className={`${mainCssClass}_create_triggers-list_item_action`}>
                        { rbacControl?.template.edit_view_access === 1 && (
                          <ControlButton onClick={() => handleEditTriggers(item)} typeButton="secondary" className="button" size="small">
                            <EditPencil1 />
                          </ControlButton>
                        )}
                        { rbacControl?.template.create_access === 1 && (
                          <ControlButton onClick={() => handleDeleteTrigger(item.id)} typeButton="secondary" className="button" size="small">
                            <TrashFull />
                          </ControlButton>
                        )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </NewScrollContainer>
          </div>
        </div>
      )}

      {newTrigger && rbacControl?.template.create_access === 1 && (
        <NewTrigger />
      )}
    </div>
  )
}
export default memo(withErrorBoundary(Triggers))
