/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Typography } from 'antd'

import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Archive from '../../../common/svg/Archive'
import EditSvg from '../../../common/svg/EditSvg'
import InterviewEvent from '../../../common/svg/InterviewEvent'
import RejectEvent from '../../../common/svg/RejectEvent'
import CandidateAddEvent from '../../../common/svg/CandidateAddEvent'
import RequestAddEvent from '../../../common/svg/RequestAddEvent'
import AddTask from '../../../common/svg/AddTask'
import СommentAddEvent from '../../../common/svg/СommentAddEvent'
import HistoryProcessed from '../../../common/svg/HistoryProcessed'
import HistoryResponded from '../../../common/svg/HistoryResponded'
import Responded from '../../../common/svg/Responded'
import './HistoryActionNameTable.scss'

type TProps = {
    action: string
    after: string
}
const mainCssClass = 'actions-name-table'

const HistoryActionNameTable: FC<TProps> = ({ action, after }) => {
  if (action === 'candidateReject' || (after ? after.includes('Кандидат отклонен') : false)) {
    return (
      <div className={mainCssClass}>
        <RejectEvent />
        <Typography.Text>
          Кандидат отклонен
        </Typography.Text>

      </div>
    )
  }

  if (action === 'candidateArchive') {
    return (
      <div className={mainCssClass}>
        <Archive />
        <Typography.Text>
          Отправлен в архив
        </Typography.Text>

      </div>
    )
  }
  if (action === 'candidateAdd') {
    return (
      <div className={mainCssClass}>
        <CandidateAddEvent />
        <Typography.Text>
          Кандидат добавлен
        </Typography.Text>

      </div>
    )
  }
  if (action === 'edit') {
    return (
      <div className={mainCssClass}>
        <EditSvg />
        <Typography.Text>
          Кандидат Изменён
        </Typography.Text>

      </div>
    )
  }
  if (action === 'commentAdd') {
    return (
      <div className={mainCssClass}>
        <СommentAddEvent />
        <Typography.Text>
          Оставлен комментарий
        </Typography.Text>

      </div>
    )
  }
  if (action === 'add') {
    return (
      <div className={mainCssClass}>
        <RequestAddEvent />
        <Typography.Text>
          Назначен на заявку
        </Typography.Text>

      </div>
    )
  }
  if (action === 'interviewAdd') {
    return (
      <div className={mainCssClass}>
        <InterviewEvent />
        <Typography.Text>
          Назначено интервью
        </Typography.Text>

      </div>
    )
  }

  if (action === 'taskAdd') {
    return (
      <div className={mainCssClass}>
        <AddTask />
        <Typography.Text>
          Назначена задача
        </Typography.Text>

      </div>
    )
  }
  if (action === 'respondAdd') {
    return (
      <div className={mainCssClass}>
        <HistoryResponded />
        <Typography.Text>
          Отклик на вакансию
        </Typography.Text>

      </div>
    )
  }
  if (action === 'respondEdit') {
    return (
      <div className={mainCssClass}>
        <HistoryProcessed />
        <Typography.Text>
          Обработан
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestToCandidateEdit') {
    return (
      <div className={mainCssClass}>
        <RequestAddEvent />
        <Typography.Text>
          Изменена заявка кандидата
        </Typography.Text>

      </div>
    )
  }

  if (action === 'responded') {
    return (
      <div className={mainCssClass}>
        <Responded />
        <Typography.Text>
          Кандидат откликнулся
        </Typography.Text>

      </div>
    )
  }
  return <Typography.Text> Действие не определено</Typography.Text>
}
export default memo<TProps>(withErrorBoundary<TProps>(HistoryActionNameTable))
