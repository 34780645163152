import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName, selectResume } from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { createTransferTime } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: (data: any) => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  citizenship: number
  transfer_time: number
  work_permit: number
}

function ModalTransferTime({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const optionYes = useSelector(selectDictionaryOptionsByName('yes_no'))
  const optionTime = useSelector(selectDictionaryOptionsByName('transferTime'))
  const optionCitizenship = useSelector(selectDictionaryOptionsByName('citizenship'))

  const defaultValues = useMemo(
    () => (editMode && resume?.citizenship && resume?.transfer_time
      ? {
        citizenship: resume?.citizenship,
        transfer_time: resume.transfer_time,
        work_permit: resume.work_permit
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = useCallback(() => {
    dispatch(createTransferTime({ resume_id: Number(resumeId), ...getValues() }))
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Гражданство, время в пути, разрешение на работу"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      maskClosable={false}
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_list`}>
        <SelectFormControl
          name="citizenship"
          label="Гражданство"
          options={optionCitizenship}
          control={control}
          rules={{ required: true }}
        />
        <SelectFormControl
          name="work_permit"
          label="Разрешение на работу"
          options={optionYes}
          rules={{ required: true }}
          control={control}
        />
        <SelectFormControl
          name="transfer_time"
          label="Время в пути до работы"
          options={optionTime}
          rules={{ required: true }}
          control={control}
        />
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalTransferTime))
