import React, { useEffect, useState } from 'react'
import { Input, InputProps, Tooltip } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from 'classnames'
import InputMask from 'react-input-mask' // Импорт из react-input-mask
import './InputFormControl.scss'
import Required from '../../svg/Required'

type TControlInputProps = Omit<
  UseControllerProps &
    InputProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      required?: boolean
  isRequired?: boolean
  mask?: any
  tooltip?: JSX.Element
  candidateId?:string
    },
  'isDisabled'
>

function InputFormControl({
  name,
  control,
  rules,
  className,
  size = 'middle',
  label,
  required,
  defaultValue,
  mask,
  onChange,
  onBlur,
  onFocus,
  tooltip,
  ...rest
}: TControlInputProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event) // Выполняем пользовательскую логику
    }
    field.onChange(event.target.value) // Затем вызываем field.onChange
  }

  const InputComponent = mask ? (
    <div onBlur={onBlur} onFocus={onFocus}>
      <InputMask
        mask={mask}
        value={field.value}
        onChange={handleInputChange}

      >
        {(inputProps: InputProps) => (
          <Input
            size={size}
            className={cn(
              className,
              { error: fieldState.error }
            )}
            {...rest}
            {...inputProps}
          />
        )}
      </InputMask>
    </div>
  ) : (
    <Input
      size={size}
      value={field.value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleInputChange}
      className={cn(
        className,
        { error: fieldState.error }
      )}
      {...rest}
    />
  )

  const handleSpanClick = () => {
    window.open(`/candidate/${tooltip}`, '_blank')
  }

  const currentDomain = window.location.origin
  const tooltipTitle = `${currentDomain}/candidate/${tooltip}`

  return (

    <div
      className={cn(
        className,
        'inputFormControl',
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' },
        { error: fieldState.error }
      )}
    >
      {label && (
        <p className="inputLabel">
          {label}
          {required && <Required className="required" />}
        </p>
      )}
      {InputComponent}
      {tooltip && (
        <p className="errorText">
          Кандидат с такими данными уже существует.
          {' '}
          <a href={`${tooltipTitle}`} target="_blank" rel="noreferrer">Нажмите чтобы посмотреть</a>
        </p>
      )}
    </div>
  )
}

export default InputFormControl
