import { createAction } from '@reduxjs/toolkit'
import {TCommentRequest, TRemoveCandidateOfRequest} from './types'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { THistoryRequestData, TRequest } from '../../common/api/request'

export const getRequest = createAction<string>('request/getRequest')
export const setRequest = createAction<TRequest>('request/setRequest')
export const reset = createAction('request/rest')

export const setLoading = createAction<boolean>('request/setLoading')

export const getDictionaries = createAction<TDictionariesNames>('request/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('request/dictionaries/setDictionaries')

export const openArchiveModal = createAction<boolean>('request/openArchive')
export const openStatusModal = createAction<boolean>('request/openStatusModal')
export const openRejectModal = createAction<boolean>('request/openRejectModal')

export const createCommentRequest = createAction<TCommentRequest>('request/createCommentRequest')

export const openEditRequestModal = createAction<boolean>('request/openEditRequestModal')

export const openEditRequestCustomerModal = createAction<boolean>('request/openEditRequestCustomerModal')

export const setEditCustomerValue = createAction<string>('request/setEditCustomerValue')
export const saveActiveRequestCustomer = createAction<TSaveCustomerRequest>('request/saveActiveRequestCustomer')
export const openCreateRequestModal = createAction<boolean>('request/openCreateRequestModal')
export const openSureRequestModal = createAction<boolean>('request/openSureRequestModal')

export type TSaveCustomerRequest = {
  customer: number[]
  created_at: string
  id: number | string
  customer_list: any[]
}

export type TResposible = {
  name: string
  surname: string
  patronymic: string
  id: number | string
}
export type TUpdateCandidateStatusRequest = {
  status: string | number
  id: string | number
}

export type TRequestHistoryRequestData = {
  id: string
  perPage: number
  page:number
  filter:string
}
export const updateCandidateStatus = createAction<TUpdateCandidateStatusRequest>('request/updateCandidateStatus')

export const getHistoryRequestData = createAction<TRequestHistoryRequestData>('request/getHistoryRequestData')

export const setHistoryRequestData = createAction<THistoryRequestData>('request/setHistoryRequestData')

export const openHistoryRequestData = createAction<boolean>('request/openHistoryRequestData')

export const removeCandidateToRequest = createAction<TRemoveCandidateOfRequest>('request/removeCandidateToRequest')

export const setOpenModalRemove = createAction<boolean>('request/setOpenModalRemove')

export type TRemoveRequestCandidateData = {
  candidateId : number
  requestId:number
}
export const removeRequestCandidateData = createAction<TRemoveRequestCandidateData | null>('request/removeRequestCandidateData')

export type THistoryRequestHeaders = {
  current:number,
  total:number
}

export const setHeaders = createAction<THistoryRequestHeaders>('request/setHeaders')

export const setRequestHistoryLoading = createAction<boolean>('request/setRequestHistoryLoading')

export const setTabRequest = createAction<string>('request/setTabRequest')

export const getRequestHistoryV2 = createAction<TRequestHistoryRequestData>('request/getRequestHistoryV2')
export const setRequestHistoryV2 = createAction<any>('request/setRequestHistoryV2')