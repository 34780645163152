/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef, useLayoutEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Tooltip, Typography, message, Upload } from 'antd'
import cn from 'classnames'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isEqual, isInteger } from 'lodash'
import { showCriticalNotification } from '../../../common/components/notification/utils'
import Search from '../../../common/svg/Search'
import { resetAllRequest } from '../../ManagementRequests/actions'
import { resetAllVacancy } from '../../ManagementVacancies/actions'
import ArchiveCandidateModal from '../../../features/candidateModals/components/ArchiveCandidateModal'
import {
  selectCandidates,
  selectDefDataCandidate,
  selectDictionaryOptionsByName,
  selectHeaders,
  selectIsOpenArchive,
  selectIsOpenChangeStatus,
  selectLoading,
  selectLoadingDashboard,
  selectOpenModalMassStage,
  selectSelectedId,
  selectSelectedStatus
} from '../selectors'
import {
  getCandidates,
  getDictionaries,
  setOpenModalArchive,
  setOpenModalChangeStatus,
  reset,
  exportCSV,
  setDefDataCandidate,
  setShowMassStage
} from '../actions'
import Excel from '../../../common/svg/Excel'
import { resetAllInterview } from '../../ManagementInterviews/actions'
import CandidatesTable from './CandidatesTable'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import ChangeStatusModal from '../../../features/candidateModals/components/ChangeStatusModal'
import { setDefStringFunnel, updateMassStage } from '../../Funnel/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import {
  getFilters,
  openFilters,
  setCountFilters,
  setDefFilters,
  setDeleteFilterByKey,
  setFilters,
  setFiltersLabels
} from '../../../features/filters/actions'
import {
  selectCountFilters,
  selectDefFilters,
  selectDeleteFilterKey,
  selectFilterString,
  selectLabelsFilter
} from '../../../features/filters/selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import FileUpload from '../../../common/svg/FileUpload'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { TStageRequest } from '../../Funnel/types'
import ModalTransferStage from '../../candidate/components/ModalTransferStage'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import { getErrorNotification } from '../../../common/components/ErrorComponentSaga'
import ResponsiveMenuFilters from '../../../common/components/ResponsiveMenu/ResponsiveMenu'
import { formValuesArray } from '../../../features/filters/components/Filters'
import DashboardCandidate from './DashboardCandidate'
import { objectToQueryString } from '../../../features/filters/utils'
import { selectIconNumber } from '../../InnerRouter/selectors'
import { setIconNumber } from '../../InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'
import './CandidateList.scss'

type TFormValues = {
  perPagesCount: number
  name: string
}

const mainCssClass = getCssClassName('candidate-list')

const CandidateList: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const { is_mass_stage, download_access } = useEntityContext()
  const defDataCandidate = useSelector(selectDefDataCandidate)
  const [page, setPage] = useState<number>(defDataCandidate?.page || 1)
  const modalArchiveOpen = useSelector(selectIsOpenArchive)
  const modalChangeStatusOpen = useSelector(selectIsOpenChangeStatus)
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const rejectionReasonOPtions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const filtersCount = useSelector(selectCountFilters)
  const showModal = useSelector(selectOpenModalMassStage)
  const candidatesList = useSelector(selectCandidates)
  const selectedCandidateId = useSelector(selectSelectedId)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const defFilters = useSelector(selectDefFilters)
  const filterKey = useSelector(selectDeleteFilterKey)
  const headers = useSelector(selectHeaders)
  const selectedStatus = useSelector(selectSelectedStatus)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const filterLabels = useSelector(selectLabelsFilter)
  const filteredFilterLabel = useMemo(() => filterLabels.filter((item) => item.value.length > 0), [filterLabels])
  const prevDefDataCandidateRef = useRef(defDataCandidate)
  const memoCandidateStatusOptions = useMemo(() => {
    if (candidateStatusOptions.length > 0) {
      return candidateStatusOptions.filter(
        (el: any) => el.value !== 666 && el.value !== 51 && el.value !== selectedStatus
      )
    }
    return []
  }, [candidateStatusOptions, selectedStatus])

  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataCandidate?.perPage || 10,
      name: defDataCandidate?.search || ''
    }
  })

  const jsonDefData = useMemo(() => (defDataCandidate ? JSON.stringify(defDataCandidate) : {}), [defDataCandidate])

  const props: UploadProps = {
    name: 'excel_file',
    action: `${process.env.DOMAIN}/api/v1/candidate/import-excel`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        showCriticalNotification(
          getErrorNotification({
            moduleName: 'Страница кандидатов',
            text: 'Не удалось произвести ручную вгрузку кандидатов'
          })
        )
      }
    },
    onSuccess() {
      if (defDataCandidate) {
        dispatch(
          getCandidates({
            page: defDataCandidate?.page,
            perPage: defDataCandidate?.perPage,
            filters: defDataCandidate?.filters
          })
        )
      }
    }
  }

  const perPagesCount = watch('perPagesCount')

  const maxPage = useMemo(() => {
    if (headers?.total && headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handleArchiveCancel = () => {
    dispatch(setOpenModalArchive(false))
  }
  const handleChangeModalCancel = () => {
    dispatch(setOpenModalChangeStatus(false))
  }

  const handleExportCSV = () => {
    dispatch(
      exportCSV({
        has_head: true,
        filter: watch('name') ? `${filtersString || ''}&search=${watch('name')}` : filtersString || ''
      })
    )
  }

  const name = watch('name')

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const showButton = useMemo(() => {
    const matches = filtersString.match(/filter\[stage_id\]\[in\]\[\]=(\d+)/g)

    if (!matches) {
      return false
    }

    if (matches.length === 1) {
      const value = matches[0].split('=')[1]
      return value.toString() === '1' || value.toString() === '2' || value.toString() === '101'
    }

    return false
  }, [filtersString])

  const handleClickSearch = () => {
    if (defDataCandidate) {
      dispatch(
        setDefDataCandidate({
          ...defDataCandidate,
          search: name,
          filters: `${filtersString || ''}${name ? `&search=${name}` : ''}`
        })
      )
    }
  }

  const handleBlurSearch = () => {
    if (name !== '' && !name && defDataCandidate) {
      dispatch(
        setDefDataCandidate({
          ...defDataCandidate,
          search: name,
          filters: `${filtersString || ''}${name ? `&search=${name}` : ''}`
        })
      )
    }
  }

  const handleDeleteSearch = () => {
    if (defDataCandidate) {
      dispatch(
        setDefDataCandidate({
          ...defDataCandidate,
          search: '',
          filters: `${filtersString || ''}`
        })
      )
    }
  }

  const handleShowModal = () => {
    dispatch(setShowMassStage(true))
  }
  const closeStageModal = () => {
    dispatch(setShowMassStage(false))
  }
  const handleUpdateStage = (data: TStageRequest) => {
    if (is_mass_stage) {
      if (watch('name')) {
        dispatch(updateMassStage({ filtersString: `${filtersString}&search=${watch('name')}`, stage_id: data.stage }))
      } else {
        dispatch(
          updateMassStage({
            filtersString,
            stage_id: data.stage
          })
        )
      }
      closeStageModal()
    }
  }

  const handleKeyDown = (event: any) => {
    if (defDataCandidate) {
      if (event.key === 'Enter') {
        handleClickSearch()
      }
    }
  }

  const resetValueByKey = (key: string) => {
    const values = JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
    const valuesWithoutDate = { ...values, [key]: Array.isArray(values[key]) ? [] : null }
    const currentFormValuesUpdate = filterLabels.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${pageId || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${pageId || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(pageId || 'candidates', objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: pageId === 'event-funnel' }))
    dispatch(
      setDefFilters({
        type: pageId || 'candidates',
        filter: valuesWithoutDate,
        defFilterString: objectToQueryString(valuesWithoutDate, pageId === 'event-funnel')
      })
    )
  }

  useEffect(() => {
    if (
      (localStorage?.getItem('candidates') || localStorage?.getItem('candidates') === '') &&
      (pageId === 'candidates' || !pageId)
    ) {
      dispatch(setFilters(localStorage.getItem('candidates')))
      dispatch(
        setDefDataCandidate({
          page,
          perPage: perPagesCount,
          filters: defDataCandidate?.search
            ? `${localStorage.getItem('candidates')}&search=${defDataCandidate?.search}`
            : localStorage?.getItem('candidates'),
          search: defDataCandidate?.search || ''
        })
      )

      let defFilter
      try {
        defFilter = localStorage.getItem(`def${pageId || 'candidates'}`)
          ? JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
          : ''
      } catch (parseError) {
        console.error('Error parsing defFilter:', parseError)
        defFilter = ''
      }

      dispatch(
        setDefFilters({
          type: pageId,
          defFilterString: localStorage.getItem(pageId || 'candidates'),
          filter: defFilter
        })
      )
    } else if (!localStorage?.getItem('candidates') && defDataCandidate?.search) {
      dispatch(
        setDefDataCandidate({
          page,
          perPage: perPagesCount,
          filters: `&search=${defDataCandidate?.search}`,
          search: defDataCandidate?.search || ''
        })
      )
    } else {
      dispatch(
        setDefDataCandidate({
          page,
          perPage: perPagesCount,
          filters: '',
          search: defDataCandidate?.search || ''
        })
      )
    }
  }, [page, perPagesCount, filtersString, pageId])

  useEffect(() => {
    if (defDataCandidate && defDataCandidate.filters) {
      dispatch(
        getCandidates({
          page: defDataCandidate?.page,
          perPage: defDataCandidate?.perPage,
          filters: defDataCandidate?.filters
        })
      )
    }
  }, [jsonDefData, pageId])

  useEffect(() => {
    if (localStorage?.getItem(`def${pageId || 'candidates'}`)) {
      try {
        const parsedFilters = JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
        if (!isEqual(parsedFilters, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFilters).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item !== false))
                .length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing filters:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    dispatch(getDictionaries(['archiveReason', 'candidateStatus', 'rejectionReason', 'stageStatus']))
    //_________________________Удалить после НГ
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
    //__________________________
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    if (localStorage.getItem(`${pageId || 'candidates'}-labels`) && (pageId === 'candidates' || !pageId)) {
      try {
        const parsedLabels = JSON.parse(localStorage.getItem(`${pageId || 'candidates'}-labels`))
        dispatch(setFiltersLabels(parsedLabels))
      } catch (parseError) {
        console.error('Error parsing filters labels:', parseError)
        dispatch(setFiltersLabels(formValuesArray))
      }
    } else {
      dispatch(setFiltersLabels(formValuesArray))
    }
  }, [pageId])

  useEffect(() => {
    if (filterKey) {
      resetValueByKey(filterKey)
      dispatch(setDeleteFilterByKey(''))
    }
  }, [filterKey])

  useEffect(() => console.log(iconNumber, 'candidatePage'), [iconNumber])

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_________________________Удалить после НГ*/}
          <ChristmasIcon />
          {/*//___*/}
          <Typography.Title level={2}>Кандидаты</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton
                icon="left"
                size="large"
                typeButton="secondary"
                className="button"
                onClick={handleFiltersOpen}
              >
                <FiltersIcon />
                Фильтры
                {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <InputSearchFormControl
              name="name"
              control={control}
              placeholder="ФИО, телефон"
              onKeyDown={handleKeyDown}
              onBlur={handleBlurSearch}
              handleDelete={handleDeleteSearch}
            />
            <ControlButton
              typeButton="primary"
              className={`${mainCssClass}__search-container_btn button`}
              icon="left"
              onClick={handleClickSearch}
            >
              <Search />
              Поиск
            </ControlButton>
          </div>
          {defDataCandidate?.filters && (
            <div className={`${mainCssClass}__container-info`}>
              <div className={`${mainCssClass}__container-title`}>
                Результат:
                <Typography.Title level={5} className={`${mainCssClass}__title`}>
                  &nbsp;
                  {headers?.totalCount || 'неизестно'}
                </Typography.Title>
                {!!headers?.totalCount &&
                  !!download_access?.candidates &&
                  (!defDataCandidate?.filters ? (
                    <Tooltip title="Выберите фильтр или введите в поиск" placement="rightBottom">
                      <Button
                        type="ghost"
                        icon={<Excel />}
                        className={cn(`${mainCssClass}__excel`, {
                          [`${mainCssClass}__excel-disabled`]: !defDataCandidate?.filters
                        })}
                        onClick={handleExportCSV}
                        disabled={!defDataCandidate?.filters}
                      >
                        {' '}
                        Выгрузить в Excel{' '}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="ghost"
                      icon={<Excel />}
                      className={cn(`${mainCssClass}__excel`)}
                      onClick={handleExportCSV}
                      disabled={!name && !filtersString}
                    >
                      {' '}
                      Выгрузить в Excel{' '}
                    </Button>
                  ))}
                <Upload {...props} showUploadList={false}>
                  <Button
                    className={`${mainCssClass}_upload-button`}
                    icon={<FileUpload color="#2DBE64" />}
                    type="ghost"
                  >
                    Загрузить файл
                  </Button>
                </Upload>
                {showButton && is_mass_stage && (
                  <ControlButton
                    id="ModalTransferStage"
                    onClick={handleShowModal}
                    className={`button ${mainCssClass}__stage`}
                    typeButton="primary"
                  >
                    Перевести на этап
                  </ControlButton>
                )}
              </div>
              {!!headers?.totalCount && (
                <div className={`${mainCssClass}__container-pagination`}>
                  <SelectFormControl
                    className={`${mainCssClass}__pagination-count`}
                    name="perPagesCount"
                    options={optionsPagination}
                    onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                    control={control}
                  />

                  <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                    <ArrowRightSM
                      className="arrowCursor"
                      size="1"
                      onClick={handlePreviousPage}
                      fill={page === 1 ? '#BFC5C0' : '#404542'}
                    />
                    <Typography.Text className={`${mainCssClass}__pagination-font`}>
                      {stringCountsPagination || 'неизвестно'}
                    </Typography.Text>
                    <ArrowLeftSM
                      className="arrowCursor"
                      size="1"
                      onClick={handleNextPage}
                      fill={page === maxPage ? '#BFC5C0' : '#404542'}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div>
            <ResponsiveMenuFilters items={filteredFilterLabel} className={`${mainCssClass}__menu-labels`} />
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              {defDataCandidate?.filters && (
                <CandidatesTable candidates={candidatesList} className={`${mainCssClass}__table`} />
              )}
              {!defDataCandidate?.filters && <DashboardCandidate />}
            </NewScrollContainer>
          </div>
          <ArchiveCandidateModal
            open={modalArchiveOpen}
            candidateId={selectedCandidateId}
            isCandidateTable
            className={`${mainCssClass}__modals`}
            onCancel={handleArchiveCancel}
            options={rejectionReasonOPtions}
          />
          <ChangeStatusModal
            open={modalChangeStatusOpen}
            candidateId={selectedCandidateId}
            isCandidateTable
            className={`${mainCssClass}__modals`}
            onCancel={handleChangeModalCancel}
            options={memoCandidateStatusOptions}
          />
          <ModalTransferStage
            open={showModal}
            wrapClassName="wrapModal"
            stageOptions={stageOptions}
            onCancel={closeStageModal}
            onOk={handleUpdateStage}
            isFunnel
          />
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(CandidateList))
