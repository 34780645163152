import React, { useEffect } from 'react'
import { Input, InputProps } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from 'classnames'
import InputMask from 'react-input-mask' // Импорт из react-input-mask
import './InputFormControl.scss'
import Required from '../../svg/Required'

type TControlInputProps = Omit<
    UseControllerProps &
    InputProps & {
    className?: string;
    size?: 'small' | 'middle' | 'large';
    label?: string;
    index?: number;
    required?: boolean;
    isRequired?: boolean;
    mask?: any;
    tooltip?: JSX.Element;
    candidateId?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Добавляем onKeyDown
},
    'isDisabled'
>;

const InputFormControl = React.forwardRef<HTMLInputElement, TControlInputProps>(
  (
    {
      name,
      control,
      rules,
      className,
      size = 'middle',
      label,
      required,
      defaultValue,
      mask,
      onChange,
      onBlur,
      onFocus,
      tooltip,
      onKeyDown, // Принимаем onKeyDown
      ...rest
    },
    ref // Используем forwardRef для передачи рефа
  ): JSX.Element => {
    const { field, fieldState } = useController({ name, control, rules })

    useEffect(() => {
      if (defaultValue) {
        field.onChange(defaultValue)
      }
    }, [defaultValue, field])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event) // Выполняем пользовательскую логику
      }
      field.onChange(event.target.value) // Затем вызываем field.onChange
    }

    const InputComponent = mask ? (
      <div onBlur={onBlur} onFocus={onFocus}>
        <InputMask mask={mask} value={field.value} onChange={field.onChange}>
          {(inputProps: InputProps) => (
            <Input
              ref={ref} // Передаем реф
              size={size}
              className={cn(className, { error: fieldState.error })}
              onKeyDown={onKeyDown} // Добавляем onKeyDown
              {...rest}
              {...inputProps}
            />
          )}
        </InputMask>
      </div>
    ) : (
      <Input
        ref={ref} // Передаем реф
        size={size}
        value={field.value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleInputChange}
        className={cn(className, { error: fieldState.error })}
        onKeyDown={onKeyDown} // Добавляем onKeyDown
        {...rest}
      />
    )

    const handleSpanClick = () => {
      window.open(`/candidate/${tooltip}`, '_blank')
    }

    const currentDomain = window.location.origin
    const tooltipTitle = `${currentDomain}/candidate/${tooltip}`

    return (
      <div
        className={cn(
          className,
          'inputFormControl',
          { inputLarge: size === 'large' },
          { inputMiddle: size === 'middle' },
          { inputSmall: size === 'small' },
          { error: fieldState.error }
        )}
      >
        {label && (
          <p className="inputLabel">
            {label}
            {required && <Required className="required" />}
          </p>
        )}
        {InputComponent}
        {tooltip && (
          <p className="errorText">
            Кандидат с такими данными уже существует.
            {' '}
            <a href={`${tooltipTitle}`} target="_blank" rel="noreferrer">
              Нажмите чтобы посмотреть
            </a>
          </p>
        )}
      </div>
    )
  }
)

export default InputFormControl
