import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import HistoryActionNameTable from './HistoryActionNameTable'

import { THistoryRequestData } from '../../../common/api/request'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
    historyData: THistoryRequestData[]
} & TClassName
type TRowData = {
    action: JSX.Element
    user: string
    before: string
    after: string
    date: string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    { accessor: 'date', Header: 'Дата' },
    {
      accessor: 'action',
      Header: 'Действие'
    },
    {
      accessor: 'user',
      Header: 'Сотрудник'
    },
    { accessor: 'before', Header: 'Было' },
    { accessor: 'after', Header: 'Стало' }
  ],
  []
)

const useRowsData = (historyData: THistoryRequestData) => {
  const dispatch = useDispatch()
  const rows: TRowData[] = useMemo(
    () => (historyData?.length
      ? historyData.map<TRowData>(
        ({
          action,
          user,
          before,
          after,
          date
        }) => ({
          date: date ? String(date) : '-',
          action: <HistoryActionNameTable action={action || '-'} />,
          user: user?.user_name ? String(user?.user_name) : '-',
          before: before ? <div dangerouslySetInnerHTML={{ __html: before }} /> : '-',
          after: after ? <div dangerouslySetInnerHTML={{ __html: after }} /> : '-',
        })
      )
      : []),
    [historyData]
  )
  const handleRowClick = () => {

  }
  return { rows, handleRowClick }
}
const RequestHistoryTable: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(historyData)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
      handleRowClick={handleRowClick}
    />
  )
}

export default memo(RequestHistoryTable)
