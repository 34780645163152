import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, message, Upload, Typography, Tabs
} from 'antd'
import './CalendarViewMobil.scss'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import dayjs from 'dayjs'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { CalendarOutline } from '../../../common/svg/SvgFromIconWrapper/CalendarOutline'
import {
  getCalendar, getDictionaries, getTimeSlots, setOpenMoreInfoModal, setPeriod
} from '../actions'
import Calendar from './Calendar'
import {
  selectCalendars, selectInterview, selectLoading, selectOpenModalInterview, selectTimeSlots
} from '../selectors'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import { openСalendarSettings } from '../../../features/SettingsCalendar/actions'
import HistoryInterviewModals from '../../../features/interviewModals/components/HistoryInterviewModals'
import MoreInfoInterview from './ModalViewMoreInfo'
import { selectUserInfo } from '../../InnerRouter/selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import TabPane = Tabs.TabPane
import { setVisibleTable } from '../../InnerRouter/actions'
import CalendarMobil from './CalendarMobil'

const { Title } = Typography

const mainCssClass = 'calendar-view-mobil'

type TFormValues = {
  calendarValue: string
}
const CalendarViewMobil: FC = () => {
  const dispatch = useDispatch()
  const {
    control, getValues, reset, handleSubmit, watch
  } = useForm<TFormValues>({
    defaultValues: {
      calendarValue: '1'
    }
  })
  const [page, setPage] = useState<number>(0)
  const calendars = useSelector(selectCalendars)
  const timeSlots = useSelector(selectTimeSlots)
  const loading = useSelector(selectLoading)
  const openInterview = useSelector(selectOpenModalInterview)
  const interview = useSelector(selectInterview)
  const period = watch('calendarValue')
  const stringCountsPagination = useMemo(() => dayjs(new Date()).add(Number(period) * page, 'day').format('DD MMMM'), [page, period])

  const handleNextPage = useCallback(() => {
    setPage((prev) => prev + 1)
  }, [page])

  const handlePreviousPage = useCallback(() => {
    setPage((prev) => prev - 1)
  }, [page])

  const handleOpenCalendarSettings = useCallback(() => {
    dispatch(openСalendarSettings(true))
  }, [])

  const handleCloseModal = useCallback(() => {
    dispatch(setOpenMoreInfoModal(false))
  }, [])

  const handleTabClick = useCallback((e) => {
    dispatch(setVisibleTable(true))
  }, [])

  useEffect(() => {
    dispatch(getCalendar({ user_id: localStorage.getItem('user_id'), period, page }))
  }, [period, page])

  useEffect(() => {
    dispatch(getTimeSlots())
  }, [])

  useEffect(() => {
    dispatch(setPeriod(period))
  }, [period])

  useEffect(() => {
    dispatch(getDictionaries(['candidateStatus', 'interviewRejectionReason', 'interviewNote', 'interviewResult', 'statusResult']))
  }, [])
  return (
    <div className={`${mainCssClass}`}>
      <div className={`${mainCssClass}__header-block`}>
        <div className={`${mainCssClass}__heading-block`}>
          <div className={`${mainCssClass}__title-block`}>
            <Title level={4}>
              Интервью
            </Title>
            {loading && <Preloader className={`${mainCssClass}__loader`} />}
          </div>
          <div className={`${mainCssClass}__tabs`}>
            <Tabs onChange={handleTabClick} defaultActiveKey="1">
              <TabPane key="1" tab="Календарь" />
              <TabPane key="2" tab="Таблица" />
            </Tabs>
          </div>
          <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
            <ArrowRightSM size="1" onClick={handlePreviousPage} fill="#404542" />
            <Typography.Text className={`${mainCssClass}__pagination-font`}>
              {stringCountsPagination}
            </Typography.Text>
            <ArrowLeftSM size="1" onClick={handleNextPage} fill="#404542" />
          </div>
        </div>
      </div>
      {!loading && (
        <div className={`${mainCssClass}__scroll-div`}>
          <ScrollContainer className={`${mainCssClass}__scroll-container`}>
            <CalendarMobil calendars={calendars} className={`${mainCssClass}__table`} />
            <MoreInfoInterview
              open={openInterview}
              interview={interview}
              tags={interview?.vacancy_list || []}
              className={`${mainCssClass}__modals`}
              onCancel={handleCloseModal}
            />
          </ScrollContainer>
        </div>
      )}
    </div>
  )
}

export default memo(withErrorBoundary(CalendarViewMobil))
