import React, {
  Dispatch, SetStateAction, useEffect, useState
} from 'react'
import { isDefined } from './utilsContext'

// type OnChangeCallback<T> = Dispatch<SetStateAction<T>> | ((newValue: T) => void);
type OnChangeCallback<T> = Dispatch<SetStateAction<T>>;

// TODO: add onChange callback
/**
 * Хук используется для реализации controlled компонентов вместо стандартного useState.
 * При отсутствии внешнего state (value) используется внутренний
 */
/**
 * @deprecated
 * @param defaultValue
 * @param value
 */
export function useControlledState<T>(
  defaultValue?: T,
  value?: T
  // onChangeOuter?: OnChangeCallback<T>
): [T, OnChangeCallback<T>] {
  const [innerValue, setInnerValue] = useState<T>(defaultValue as T)

  useEffect(() => {
    if (isDefined(value)) {
      setInnerValue(value)
    }
  }, [JSON.stringify(value)])

  // return [innerValue, onChangeOuter ? onChangeOuter : setInnerValue];
  return [innerValue, setInnerValue]
}
