/* eslint-disable no-console */
import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import {
  DeleteOutlined, PlusOutlined, DownloadOutlined, LinkOutlined
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Preloader } from '../../../../../common/components/Preloader/Preloader'
import SelectFormControl from '../../../../../common/components/formcontrol/SelectFormControl'
import SummaryInfo from './SummaryInfo'
import withErrorBoundary from '../../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate } from '../../../selectors'
import {
  createResume,
  deleteResume,
  getDictionaries,
  selectActiveResume,
  selectCandidateResume,
  setActiveResume,
  setResumeList,
  selectLoading
} from '../../../../Resume'
import { useEntityContext } from '../../../../../common/context/EntityContextProvider'
import './SummaryCandidate.scss'

const MainCSSClass = 'summaryCandidate'

function SummaryCandidate() {
  const dispatch = useDispatch()
  const { control, watch, setValue } = useForm<TFormValues>()
  const activeResume = useSelector(selectActiveResume)
  const candidate = useSelector(selectCandidate)
  const resumeList = useSelector(selectCandidateResume)
  const { rbacControl } = useEntityContext()
  const loading = useSelector(selectLoading)

  type TFormValues = {
    date: string | null
  }

  const date = watch('date')

  // const options = candidate?.resume_list?.map((el) => ({ label: el.labels.name, value: el.labels.name }))

  const options = useMemo(() => {
    if (resumeList && resumeList.length) {
      return resumeList.map((el) => ({ label: el.labels.name, value: el.labels.name }))
    }
    setValue('date', null)
    return []
  }, [resumeList, activeResume])

  const handleCreateResume = useCallback(() => {
    if (candidate) {
      dispatch(createResume({ candidate_id: candidate.id }))
    }
  }, [])

  const handleDeleteResume = () => {
    if (resumeList && activeResume) {
      dispatch(deleteResume({ resume_id: activeResume?.id, candidate_id: candidate?.id }))
      setValue('date', null)
    }
  }

  useEffect(() => {
    if (candidate) {
      dispatch(
        getDictionaries([
          'currency',
          'schedule',
          'schedule',
          'keySkills',
          'language',
          'languageLevel',
          'documentType',
          'yes_no',
          'citizenship',
          'transferTime',
          'educationLevel',
          'region',
          'employment'
        ])
      )
    }
  }, [candidate])

  useEffect(() => {
    if (date && resumeList) {
      const candidateResume = resumeList?.find((obj) => obj.labels.name === date)

      if (candidateResume) {
        if (activeResume && resumeList) {
          // eslint-disable-next-line no-return-assign
          const newResumeList = resumeList.map((item) => (item.id === activeResume.id ? (item = activeResume) : item))
          dispatch(setResumeList(newResumeList))
        }
        dispatch(setActiveResume(candidateResume))
      }
    }
  }, [date])

  return (
    <div className={`${MainCSSClass}`}>
      {loading && <Preloader size="sm" />}

      {!loading

      && (
        <>
          <div className={`${MainCSSClass}_header`}>
            <SelectFormControl name="date" control={control} className={`${MainCSSClass}_select`} options={options} />

            <div className={`${MainCSSClass}_menu`}>
              {rbacControl?.candidate?.edit_view_access === 1 && (
                <Tooltip placement="top" title="Создать резюме">
                  <Button shape="circle" icon={<PlusOutlined />} onClick={handleCreateResume} />
                </Tooltip>
              )}
              <Tooltip placement="top" title="Скопировать ссылку">
                <Button shape="circle" icon={<LinkOutlined />} />
              </Tooltip>
              {rbacControl?.candidate?.edit_view_access === 1 && (
                <Tooltip placement="top" title="Загрузить">
                  <Button shape="circle" icon={<DownloadOutlined />} />
                </Tooltip>
              )}
              {rbacControl?.candidate?.edit_view_access === 1 && (
                <Tooltip placement="top" title="Удалить">
                  <Button shape="circle" icon={<DeleteOutlined />} onClick={handleDeleteResume} />
                </Tooltip>
              )}
            </div>
          </div>
          <div className={`${MainCSSClass}_mainContent`}>
            {activeResume && (
              <SummaryInfo className={`${MainCSSClass}_summaryInfo`} readOnly={rbacControl?.candidate?.edit_view_access !== 1} />)}
          </div>
        </>
      )}

    </div>
  )
}

export default memo(withErrorBoundary(SummaryCandidate))
