import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'empty-asterisk'

function EmptyAsterisk({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="56" height="56" rx="24" fill="#E8ECE9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3947 18C19.796 18 18.5 19.2961 18.5 20.8948C18.5 30.3418 26.1585 38 35.6055 38C37.2043 38 38.5 36.7039 38.5 35.1053L38.4999 32.354C38.4999 31.1272 37.7528 30.0242 36.614 29.5687L33.9785 28.5144C32.9555 28.1052 31.7906 28.2896 30.944 28.9951L30.2629 29.5625C29.8655 29.8936 29.2813 29.8671 28.9156 29.5014L26.9984 27.5844C26.6329 27.2189 26.6064 26.6346 26.9377 26.237L27.505 25.5562C28.2105 24.7095 28.3949 23.5444 27.9857 22.5215L26.9314 19.8858C26.4758 18.7469 25.3727 18 24.146 18H21.3947ZM21.3947 20C20.9006 20 20.5 20.4006 20.5 20.8948C20.5 29.2372 27.263 36 35.6055 36C36.0995 36 36.5 35.5996 36.5 35.1054L36.4999 32.3541C36.4999 31.9453 36.251 31.5775 35.8712 31.4256L33.2357 30.3714C32.8947 30.2349 32.5065 30.2964 32.2244 30.5315L31.5433 31.0989C30.3513 32.0922 28.5986 32.0128 27.5015 30.9157L25.5843 28.9986C24.4869 27.9013 24.4081 26.1485 25.4012 24.9567L25.9686 24.2758C26.2036 23.9938 26.2652 23.6054 26.1287 23.2643L25.0745 20.6286C24.9226 20.249 24.5549 20 24.146 20H21.3947Z" fill="#404542" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.705 18.205C30.9784 17.9317 31.4216 17.9317 31.695 18.205L34 20.5101L36.305 18.205C36.5784 17.9317 37.0216 17.9317 37.295 18.205C37.5683 18.4784 37.5683 18.9216 37.295 19.195L34.9899 21.5L37.2949 23.805C37.5683 24.0784 37.5683 24.5216 37.2949 24.7949C37.0216 25.0683 36.5784 25.0683 36.305 24.7949L34 22.4899L31.695 24.795C31.4216 25.0683 30.9784 25.0683 30.705 24.795C30.4317 24.5216 30.4317 24.0784 30.705 23.805L33.0101 21.5L30.705 19.195C30.4317 18.9216 30.4317 18.4784 30.705 18.205Z" fill="#404542" />
    </svg>

  )
}
export default EmptyAsterisk
