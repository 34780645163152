import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import { Field, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button } from 'antd'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import TrashFull from '../../../common/svg/TrashFull'
import UserAdd from '../../../common/svg/UserAdd'
import { selectActiveQuestionnare } from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import {
  convertToBoolean, convertToBooleanIsShow, processQuestionnaire, transformObject
} from '../utils'
import {
  createQuestionnaire, openQuestionnaireForm, setActiveQuestionnaire, updateQuestionnaire
} from '../actions'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './QuestionnaireForm.scss'

const mainCssClass = getCssClassName('questionnaire-form')

type TFormValues = {
  questionnare_name: string
  question_list: { question: string; is_recruiter:boolean, is_show?:boolean, id?:number }[]
}

function QuestionnaireForm() {
  const dispatch = useDispatch()

  const activeQuestionnaire = useSelector(selectActiveQuestionnare)

  const defaultValues = useMemo(
    () => (activeQuestionnaire ? {
      questionnare_name: activeQuestionnaire.name,
      question_list: activeQuestionnaire?.question_list.map((e) => ({
        question: e.question,
        is_recruiter: convertToBoolean(e.is_recruiter),
        is_show: convertToBooleanIsShow(e.is_show),
        id: e.id
      }))
    }
      : {}),
    [activeQuestionnaire]
  )
  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    append, prepend, remove, fields, update
  } = useFieldArray({
    control,
    name: 'question_list'
  })

  if (fields?.length === 0) {
    prepend({
      question: '',
      is_recruiter: false
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleSave = () => {
    if (!activeQuestionnaire) {
      const updatedValues = processQuestionnaire(getValues())
      dispatch(createQuestionnaire(updatedValues))
    } else {
      const updatedValues = transformObject(getValues())
      dispatch(updateQuestionnaire({ ...updatedValues, questionnare_id: activeQuestionnaire.id }))
    }
  }
  const handleMoveDown = (index) => {
    if (index === fields.length - 1) return
    const fieldBelow = getValues(`question_list[${index + 1}]`)
    const fieldToMove = getValues(`question_list[${index}]`)

    update(index + 1, fieldToMove)
    update(index, fieldBelow)
  }
  const handleMoveUp = (index) => {
    if (index === 0) return
    const fieldAbove = getValues(`question_list[${index - 1}]`)
    const fieldToMove = getValues(`question_list[${index}]`)

    update(index - 1, fieldToMove)
    update(index, fieldAbove)
  }

  const handleCancel = () => {
    dispatch(openQuestionnaireForm(false))
    dispatch(setActiveQuestionnaire(null))
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (

    <div className={mainCssClass}>
      <Typography.Title level={3}>
        {activeQuestionnaire ? 'Редактировать анкету' : 'Новая анкета'}
      </Typography.Title>
      <Typography.Text className={`${mainCssClass}_warning`}>
        Внимание! После сохранения анкеты, вы не сможете редактировать вопросы, но сможете добавлять новые.
      </Typography.Text>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl
              name="questionnare_name"
              control={control}
              label="Название анкеты"
              required
              rules={{ required: true }}
            />

            {fields.map((rec, index) => (
              <div className={`${mainCssClass}_list_item`} key={`${index}-${rec.question}`}>
                {(activeQuestionnaire && rec.question) ? (
                  <InputFormControl
                    label="Вопрос"
                    required
                    name={`question_list[${index}].question`}
                    control={control}
                    disabled
                    rules={{ required: true }}
                  />
                ) : (
                  <InputFormControl
                    label="Вопрос"
                    required
                    name={`question_list[${index}].question`}
                    control={control}
                    rules={{ required: true }}
                  />
                )}
                <div className={`${mainCssClass}_list_item_switch`}>
                  <SwitchFormControl
                    name={`question_list[${index}].is_recruiter`}
                    control={control}
                  />
                  <Typography.Text>Только для рекрутера</Typography.Text>
                </div>
                {activeQuestionnaire && rec.is_show !== undefined && (
                  <div className={`${mainCssClass}_list_item_switch`}>
                    <SwitchFormControl
                      name={`question_list[${index}].is_show`}
                      control={control}
                    />
                    <Typography.Text>Скрыть вопрос</Typography.Text>
                  </div>
                )}

                {!rec.question && (
                  <Button onClick={() => handleRemove(index)}>
                    <TrashFull />
                  </Button>
                )}

                {/* Добавление кнопок для сортировки */}
                {index !== 0 && (
                  <Button onClick={() => handleMoveUp(index)}>Вверх</Button>
                )}
                {index !== fields.length - 1 && (
                  <Button onClick={() => handleMoveDown(index)}>Вниз</Button>
                )}
              </div>
            ))}
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" className="button" onClick={() => append({ question: '', is_recruiter: false })}>
              <UserAdd />
              Добавить вопрос
            </ControlButton>
            <ControlButton typeButton="secondary" className="button" onClick={handleCancel}>Отменить</ControlButton>
            <ControlButton typeButton="primary" className="button" onClick={handleSubmit(handleSave)}>Сохранить</ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(QuestionnaireForm))
