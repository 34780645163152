/* eslint-disable guard-for-in */
import React, {
  memo,
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { Typography, Input, Tooltip } from 'antd'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import CloseMD from '../../../common/svg/CloseMD'
import {
  filterObject,
  objectToQueryString
} from '../utils'
import { getCssClassName } from '../../../common/utils/generateClassName'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import Search from '../../../common/svg/Search'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import {
  getDictionaries,
  openProductivityRecruiterFilter,
  setRecruiterFilterString
} from '../actions'

import Redo from '../../../common/svg/Redo'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { TBaseUrl } from '../../../compositions/InnerRouter/types'
import { selectBaseUrl, selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import './ProductivityFilter.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveTab, selectDictionaryOptionsByName } from '../selectors'
import {
  fetchClientList, fetchRecruiterList, fetchRequestList, fetchResearcherList, fetchVacancyList, filteredOptions
} from '../../../features/filters/utils'

export type TProductivityFilterRecruiter = {
    list_priority: number[]
    list_responsible: number[]
    list_researcher: number[]
    list_vacancy: number[]
    list_request: number[]
    list_group: number[]
    date_registration: string[] | null
  }

const mainCssClass = getCssClassName('productivity-filters')

function ProductivityRecruiterFilter() {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)

  const { pageId } = useParams()

  const defaultValues = useMemo(() => {
    if (pageId && activeTab === 'recruiter') {
      try {
        return JSON.parse(localStorage.getItem('defproductivityrec')) || {};
      } catch (parseError) {
        console.error('Error parsing defproductivityrec:', parseError);
        return {};
      }
    }
    return {};
  }, [pageId, activeTab]);

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TProductivityFilterRecruiter>({ defaultValues })

  const [allModal, setAllModal] = useState<any>(filterObject)
  const [priorityInput, setPriorityInput] = useState('')
  const [groupInput, setGroupInput] = useState('')
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  // const timeOptions = useSelector(selectTimeOptions)

  const handleApply = useCallback(() => {
    localStorage.setItem('defproductivityrec', JSON.stringify(getValues()))
    localStorage.setItem('productivityrec', objectToQueryString(getValues()))
    dispatch(setRecruiterFilterString(objectToQueryString(getValues())))
  }, [])

  const handleAllReset = useCallback(() => {
    const mapValues = getValues()
    if (pageId) {
      localStorage.removeItem('defproductivityrec')
      localStorage.removeItem('productivityrec')
    }
    if (defaultValues) {
      // eslint-disable-next-line prefer-const
      for (let key in mapValues) {
        setValue(key, [])
      }
    } else {
      reset({})
    }
  }, [defaultValues, pageId])

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleCloseFilters = useCallback(() => {
    dispatch(openProductivityRecruiterFilter(false))
  }, [])

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  const handlePriorityInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPriorityInput(e.target.value)
  }

  const resetArrayByKey = (key: string) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: [] }
    localStorage.setItem('defproductivityrec', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('productivityrec', objectToQueryString(valuesWithoutDate))

    dispatch(setRecruiterFilterString(objectToQueryString(valuesWithoutDate)))
    setValue(key, [])
  }

  const resetDateValue = (str: string) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, str: null }
    localStorage.setItem('defproductivityrec', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('productivityrec', objectToQueryString(valuesWithoutDate))

    dispatch(setRecruiterFilterString(objectToQueryString(getValues())))
    setValue(str, null)
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'yes_no',
        'priority',
        'vacancyStatus',
        'statusResult',
        'interviewResult',
        'group',
        'candidateCharacteristic'
      ])
    )
  }, [])

  useEffect(() => {
    reset(defaultValues)
  }, [pageId])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={handleCloseFilters}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Вакансия
                  {watch('list_vacancy') && watch('list_vacancy').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_vacancy').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_vacancy') && watch('list_vacancy').length > 0
                            && <Redo onClick={() => resetArrayByKey('list_vacancy')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('vacancyCandidate')}>
                {allModal.vacancyCandidate ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.vacancyCandidate && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_vacancy"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Вакансия"
                  fetchOptions={fetchVacancyList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Группы
                  {watch('list_group') && watch('list_group').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_group').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_group') && watch('list_group').length > 0
                            && <Redo onClick={() => resetArrayByKey('list_group')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('group')}>
                {allModal.group ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>

            </div>
            {allModal.group && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={groupInput}
                    onChange={handleGroupInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(groupOptions, groupInput)}
                    control={control}
                    name="list_group"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Заявка
                  {watch('list_request') && watch('list_request').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_request').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_request') && watch('list_request').length > 0
                            && <Redo onClick={() => resetArrayByKey('list_request')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('requestContent')}>
                {allModal.requestContent ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.requestContent && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_request"
                  control={control}
                  placeholder="Поиск по наименованию..."
                  mode="multiple"
                  label="Заявка"
                  fetchOptions={fetchRequestList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный рекрутер
                  {watch('list_responsible') && watch('list_responsible').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_responsible').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_responsible') && watch('list_responsible').length > 0
                              && <Redo onClick={() => resetArrayByKey('list_responsible')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('recruiter')}>
                {allModal.recruiter ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.recruiter && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_responsible"
                  control={control}
                  mode="multiple"
                  label="Рекрутер"
                  placeholder="Поиск по имени..."
                  notFoundContent="Начните вводить имя"
                  fetchOptions={fetchRecruiterList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный ресечер
                  {watch('list_researcher') && watch('list_researcher').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_researcher').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_researcher') && watch('list_researcher').length > 0
                              && <Redo onClick={() => resetArrayByKey('list_researcher')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('researcher')}>
                {allModal.researcher ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.researcher && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_researcher"
                  notFoundContent="Начните вводить имя"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по имени..."
                  label="Ресечер"
                  fetchOptions={fetchResearcherList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>По дате оформления</Typography.Title>
                {watch('date_registration') && watch('date_registration')?.length > 0 && (
                  <Redo onClick={() => resetDateValue('date_registration')} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('dateRegistration')}>
                {allModal.dateRegistration ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.dateRegistration && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_registration"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Приоритет
                  {watch('list_priority') && watch('list_priority').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_priority').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_priority') && watch('list_priority')?.length > 0 && <Redo onClick={() => resetArrayByKey('list_priority')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('priority')}>
                {allModal.priority ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.priority && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={priorityInput}
                    onChange={handlePriorityInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(priorityOptions, priorityInput)}
                    control={control}
                    name="list_priority"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>
        </div>
      </NewScrollContainer>
    </div>
  )
}
export default memo(withErrorBoundary(ProductivityRecruiterFilter))
