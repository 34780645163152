import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  color?:string
}

const mainCssClass = 'arrowLeftRight'

function ArrowLeftRight({ className, size = '1', color }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 4.29289C9.09763 4.68342 9.09763 5.31658 8.70711 5.70711L7.41421 7H19C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9H7.41421L8.70711 10.2929C9.09763 10.6834 9.09763 11.3166 8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289L7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289ZM15.2929 12.2929C15.6834 11.9024 16.3166 11.9024 16.7071 12.2929L19.7071 15.2929C20.0976 15.6834 20.0976 16.3166 19.7071 16.7071L16.7071 19.7071C16.3166 20.0976 15.6834 20.0976 15.2929 19.7071C14.9024 19.3166 14.9024 18.6834 15.2929 18.2929L16.5858 17H5C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15H16.5858L15.2929 13.7071C14.9024 13.3166 14.9024 12.6834 15.2929 12.2929Z"
        fill={color || "#404542"}
      />
    </svg>
  )
}
export default ArrowLeftRight
