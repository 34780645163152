/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { TOption } from 'src/common/types/dictionaries'
import { callback } from 'chart.js/helpers'
import $api from '../../common/api/http'
import { generateFilters } from '../../features/filters/utils'
import { TRoleFormFormValues, TRoleSetting } from './types'
import { TFormValuesUser } from './components/UserForm'
import { TStore, TUser } from '../../common/api/systems'
import { TFormValuesStore } from './components/StoreForm'
import analyticsSaga from '../analytics/saga'

export function processQuestionnaire(questionnaire) {
  questionnaire.questionnare_name = questionnaire.questionnare_name || ''

  questionnaire.question_list.forEach((question) => {
    question.is_recruiter = question.is_recruiter ? 1 : 0
    question.is_show = 1
  })

  return questionnaire
}

export function convertToBoolean(value: number) {
  return value === 1
}

export function convertToBooleanIsShow(value: number) {
  return value === 0
}

export function transformObject(obj) {
  for (let i = 0; i < obj.question_list.length; i++) {
    if (obj.question_list[i].is_recruiter === true) {
      obj.question_list[i].is_recruiter = 1
    } else {
      obj.question_list[i].is_recruiter = 0
    }

    if (obj.question_list[i].hasOwnProperty('is_show')) {
      if (obj.question_list[i].is_show === false) {
        obj.question_list[i].is_show = 1
      } else {
        obj.question_list[i].is_show = 0
      }
    } else {
      obj.question_list[i].is_show = 1
    }
  }

  return obj
}

export const storeType = [
  { value: 1, label: 'Вайтстор' },
  { value: 2, label: 'Даркстор' }
]

export async function getModel() {
  const { data } = await $api.get('/api/v1/trigger-action/get-method-list')
  return Object.entries(data.DATA).map(([value, label]) => ({
    label,
    value
  }))
}

export async function getTypes() {
  const { data } = await $api.get('/api/v1/stage/get-types')
  return data.DATA
}

export async function getEvent() {
  const { data } = await $api.get('/api/v1/trigger-action/get-event-list')
  return Object.entries(data.DATA).map(([value, label]) => ({
    label,
    value
  }))
}

export async function getHost() {
  const { data } = await $api.get('/api/v1/trigger-action/get-host-list')
  return Object.entries(data.DATA).map(([value, label]) => ({
    label,
    value
  }))
}

export async function getAction() {
  const { data } = await $api.get('/api/v1/trigger-action/get-action-list')
  return Object.entries(data.DATA).map(([value, label]) => ({
    label,
    value
  }))
}

export async function getAttribute(model: string) {
  if (model) {
    const { data } = await $api.get(`/api/v1/trigger-action/get-method-attribute-list?model=${model}`)
    return Object.entries(data.DATA).map(([value, label]) => ({
      label,
      value
    }))
  }
  return []
}

export async function getTemplate() {
  const { data } = await $api.get('/api/v1/trigger-templates?page=1&per-page=1000&fields=id%2Cname')
  return data.DATA.map((el) => ({
    label: el.name,
    value: el.id
  }))
}

export function defaultCriteria(name: string) {
  return Object.entries(JSON.parse(name))
    .map(([value, label]) => ({
      label,
      value
    }))
    .map((el) => ({ parametr: el.value, value_parametr: el.label }))
}

export async function getCallbackOption() {
  const { data } = await $api.get('/api/v1/callback-setting/get-event-lid-list')
  return data.DATA
}
export function generateRecipients(arr: string | number[], labels: any) {
  const recipients: any[] = []

  arr.forEach((item) => {
    if (typeof item === 'string') {
      recipients.push({ recipient_type: item, recipient: '' })
    } else if (typeof item === 'number') {
      const label = labels[item] || ''
      recipients.push({ recipient_type: 'custom', recipient: { label, value: item } })
    }
  })

  return recipients
}

export async function fetchCustomUserList(name: string): Promise<TOption[]> {
  const filtredName = generateFilters(name.split(' '))

  const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&filter${filtredName}`)

  return data.DATA.map((obj: { id: number; name: string; surname: string; email: string }) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export function transformGetValues(inputObj: any) {
  const transformedObj = {
    ...inputObj,
    holder_list: inputObj.holder_list.map((holder) => (holder.recipient ? holder.recipient : holder.recipient_type)),
    criteria: JSON.stringify(
      inputObj.criteria.reduce((criteriaObj, criterion) => {
        criteriaObj[criterion.parametr] = criterion.value_parametr
        return criteriaObj
      }, {})
    )
  }

  return transformedObj
}

export const stageSort = (array: any) => {
  const noBlock = array.filter((el: any) => !el.is_blocked)
  const begin: any[] = []
  const end: any[] = []

  array.forEach((el: any) =>
    el.is_blocked ? (el.priority < noBlock[0].priority ? begin?.push(el) : end?.push(el)) : null
  )
  return [begin, noBlock, end]
}

export const sortedStage = (stages: any[]) => {
  const separatedArrays = [[], [], [], []]

  stages.forEach((stage) => {
    const vertexId = stage.vertex_id
    separatedArrays[vertexId - 1]?.push(stage)
  })

  const newArr = separatedArrays.map((el, i) => {
    const begin: any[] = []
    const end: any[] = []
    const noBlock = el.filter((elem: any) => !elem.is_blocked)
    el.forEach((elem: any) =>
      elem.is_blocked ? (elem.priority < noBlock[0].priority ? begin?.push(elem) : end?.push(elem)) : null
    )

    return { begin, end, noBlock }
  })

  return newArr
}

export const sortedStageOptions = (stages: any[]) => {
  const separatedArrays = [[], [], [], []]
  stages.forEach((stage) => {
    if (stage.status === 1) {
      const vertexId = stage.vertex_id
      separatedArrays[vertexId - 1]?.push(stage)
    }
  })

  return separatedArrays.map((el) => el.map((elem) => ({ label: elem.name, value: elem.id })))
}

export function prepareForSubmit(values: TRoleFormFormValues, role_id?: string) {
  const objectSubmit = {
    role_name: values.name,
    is_archive: 0,
    main_page: 'candidates',
    pages: {
      candidate: {
        page_access: 1,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      request: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      task: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      interview: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0,
        calendar_access: 0
      },
      vacancy: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      analytics: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      profile: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      company_profile: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      users: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      role: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      questionnare: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      default_stage: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      dictionary: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      site_integration: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      callback: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      template: {
        archive_access: 0,
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      rating_card: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      candidate_list: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      token_generate: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      panels: {
        is_mobile_panel_visible: 0
      },
      leads: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0
      },
      call: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      productivity: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      user_work_plan: {
        page_access: 0,
        create_access: 0,
        edit_view_access: 0,
        archive_access: 0
      },
      common: {
        candidate_only_accepted: 0
      }
    }
  }
  if (role_id) {
    objectSubmit.role_id = role_id
  }
  if (values?.candidate_block) {
    if (values?.candidate_block_archive?.[0] === '1') {
      objectSubmit.pages.candidate.archive_access = 1
    }
    if (values?.candidate_block_is_edit === 'edit') {
      objectSubmit.pages.candidate.edit_view_access = 1
    }
  }
  if (values.is_mobile_panel_visible) {
    objectSubmit.pages.panels.is_mobile_panel_visible = 1
  }
  if (values.candidate_only_accepted) {
    objectSubmit.pages.common.candidate_only_accepted = 1
  }
  if (values?.candidate_create) {
    objectSubmit.pages.candidate.create_access = 1
  }
  if (values?.request_block) {
    objectSubmit.pages.request.page_access = 1
    if (values?.request_block_archive?.[0] === '1') {
      objectSubmit.pages.request.archive_access = 1
    }
    if (values?.request_block_is_edit === 'edit') {
      objectSubmit.pages.request.edit_view_access = 1
    }
  }
  if (values?.request_create) {
    objectSubmit.pages.request.create_access = 1
  }
  if (values?.task_block) {
    objectSubmit.pages.task.page_access = 1
    if (values?.task_block_archive?.[0] === '1') {
      objectSubmit.pages.task.archive_access = 1
    }
    if (values?.task_block_is_edit === 'edit') {
      objectSubmit.pages.task.create_access = 1
      objectSubmit.pages.task.edit_view_access = 1
    }
  }
  if (values?.interview_block) {
    objectSubmit.pages.interview.page_access = 1
    if (values?.interview_block_archive?.[0] === '1') {
      objectSubmit.pages.interview.archive_access = 1
    }
    if (values?.interview_block_is_edit === 'edit') {
      objectSubmit.pages.interview.create_access = 1
      objectSubmit.pages.interview.edit_view_access = 1
    }
    if (values?.interview_is_recruiter === 'is_recruiter') {
      objectSubmit.pages.interview.calendar_access = 1
    }
  }
  if (values?.main_page_block) {
    if (values?.main_page) {
      objectSubmit.main_page = values?.main_page
    }
  }
  if (values?.vacancy_block) {
    objectSubmit.pages.vacancy.page_access = 1
    if (values?.vacancy_block_is_edit === 'edit') {
      objectSubmit.pages.vacancy.edit_view_access = 1
    }
    if (values?.vacancy_block_archive?.[0] === '1') {
      objectSubmit.pages.vacancy.archive_access = 1
    }
  }
  if (values?.vacancy_create) {
    objectSubmit.pages.vacancy.create_access = 1
  }
  if (values?.analytics_block) {
    objectSubmit.pages.analytics.page_access = 1
    if (values?.analytics_block_is_edit === 'edit') {
      objectSubmit.pages.analytics.create_access = 1
      objectSubmit.pages.analytics.edit_view_access = 1
    }
  }
  if (values?.profile_visible) {
    objectSubmit.pages.profile.page_access = 1
    if (values?.profile_is_edit === 'edit') {
      objectSubmit.pages.profile.create_access = 1
      objectSubmit.pages.profile.edit_view_access = 1
    }
  }
  if (values?.callback_visible) {
    objectSubmit.pages.callback.page_access = 1
    if (values?.callback_is_edit === 'edit') {
      objectSubmit.pages.callback.edit_view_access = 1
      objectSubmit.pages.callback.create_access = 1
    }
    if (values?.callback_archive?.[0] === '1') {
      objectSubmit.pages.callback.archive_access = 1
    }
  }

  if (values?.profile_company_visible) {
    objectSubmit.pages.company_profile.page_access = 1
    if (values?.profile_company_is_edit === 'edit') {
      objectSubmit.pages.company_profile.create_access = 1
      objectSubmit.pages.company_profile.edit_view_access = 1
    }
  }
  if (values?.templates_visible) {
    objectSubmit.pages.template.page_access = 1
    if (values?.templates_is_edit === 'edit') {
      objectSubmit.pages.template.create_access = 1
      objectSubmit.pages.template.edit_view_access = 1
      if (values?.template_is_archive?.[0] === '1') {
        objectSubmit.pages.template.archive_access = 1
      }
    }
  }

  if (values?.users_visible) {
    objectSubmit.pages.users.page_access = 1
    if (values?.users_is_edit === 'edit') {
      objectSubmit.pages.users.create_access = 1
      objectSubmit.pages.users.edit_view_access = 1
    }
    if (values?.users_is_archive?.[0] === '1') {
      objectSubmit.pages.users.archive_access = 1
    }
  }

  if (values?.role_visible) {
    objectSubmit.pages.role.page_access = 1
    if (values?.role_is_edit === 'edit') {
      objectSubmit.pages.role.create_access = 1
      objectSubmit.pages.role.edit_view_access = 1
    }
  }
  if (values?.questionnaire_visible) {
    objectSubmit.pages.questionnare.page_access = 1
    if (values?.questionnaire_block_archive === '1') {
      objectSubmit.pages.questionnare.archive_access = 1
    }
    if (values?.questionnaire_is_edit === 'edit') {
      objectSubmit.pages.questionnare.create_access = 1
      objectSubmit.pages.questionnare.edit_view_access = 1
    }
  }
  if (values?.dictionaries_visible) {
    objectSubmit.pages.dictionary.page_access = 1
    if (values?.dictionaries_is_edit === 'edit') {
      objectSubmit.pages.dictionary.create_access = 1
      objectSubmit.pages.dictionary.edit_view_access = 1
    }
  }
  if (values?.stages_visible) {
    objectSubmit.pages.default_stage.page_access = 1
    if (values?.stages_is_edit === 'edit') {
      objectSubmit.pages.default_stage.create_access = 1
      objectSubmit.pages.default_stage.edit_view_access = 1
    }
  }
  if (values?.integrate_career_websites_visible) {
    objectSubmit.pages.site_integration.page_access = 1
    if (values?.integrate_career_websites_is_edit === 'edit') {
      objectSubmit.pages.site_integration.create_access = 1
      objectSubmit.pages.site_integration.edit_view_access = 1
    }
  }
  if (values?.settings_candidates_visible) {
    objectSubmit.pages.candidate_list.page_access = 1
    if (values?.settings_candidates_is_edit === 'edit') {
      objectSubmit.pages.candidate_list.create_access = 1
      objectSubmit.pages.candidate_list.edit_view_access = 1
    }
  }
  if (values?.card_rating_visible) {
    objectSubmit.pages.rating_card.page_access = 1
    if (values?.card_rating_is_edit === 'edit') {
      objectSubmit.pages.rating_card.create_access = 1
      objectSubmit.pages.rating_card.edit_view_access = 1
    }
  }
  if (values?.generate_tokens_visible) {
    objectSubmit.pages.token_generate.page_access = 1
    if (values?.generate_tokens_is_edit === 'edit') {
      objectSubmit.pages.token_generate.create_access = 1
      objectSubmit.pages.token_generate.edit_view_access = 1
    }
  }
  if (values?.leads) {
    objectSubmit.pages.leads.page_access = 1
    objectSubmit.pages.leads.edit_view_access = 1
    objectSubmit.pages.leads.create_access = 1
  }
  if (values?.call) {
    objectSubmit.pages.call.page_access = 1
    if (values?.call_is_edit === 'edit') {
      objectSubmit.pages.call.edit_view_access = 1
      objectSubmit.pages.call.create_access = 1
    }
    if (values?.call_is_archive?.[0] === '1') {
      objectSubmit.pages.call.archive_access = 1
    }
  }
  // if (values?.call) {
  //   objectSubmit.pages.call.page_access = 1
  //   objectSubmit.pages.call.edit_view_access = 1
  //   objectSubmit.pages.call.create_access = 1
  //   objectSubmit.pages.call.archive_access = 1
  // }
  if (values?.user_work_plan) {
    objectSubmit.pages.user_work_plan.page_access = 1
    objectSubmit.pages.user_work_plan.edit_view_access = 1
    objectSubmit.pages.user_work_plan.create_access = 1
    objectSubmit.pages.user_work_plan.archive_access = 1
  }
  if (values?.productivity) {
    objectSubmit.pages.productivity.page_access = 1
    objectSubmit.pages.productivity.edit_view_access = 1
    objectSubmit.pages.productivity.create_access = 1
    objectSubmit.pages.productivity.archive_access = 1
  }
  return objectSubmit
}
export function prepareForDefaultValues(values: TRoleSetting | null) {
  const preparedValues = {
    name: '',
    candidate_block: true,
    candidate_block_is_edit: '',
    candidate_block_archive: '',
    callback_visible: false,
    callback_is_edit: '',
    callback_archive: '',
    request_block: false,
    request_block_is_edit: '',
    request_block_archive: '',
    vacancy_block: false,
    vacancy_block_is_edit: '',
    vacancy_block_archive: '',
    interview_block: false,
    interview_block_is_edit: '',
    interview_block_archive: '',
    interview_is_recruiter: '',
    questionnaire_block_archive: '',
    analytics_block: false,
    analytics_block_is_edit: '',
    candidate_create: false,
    request_create: false,
    vacancy_create: false,
    profile_visible: false,
    profile_is_edit: '',
    profile_company_visible: false,
    profile_company_is_edit: '',
    users_visible: false,
    users_is_edit: '',
    users_is_archive: '',
    role_visible: false,
    role_is_edit: '',
    questionnaire_visible: false,
    questionnaire_is_edit: '',
    stages_visible: false,
    stages_is_edit: '',
    dictionaries_visible: false,
    dictionaries_is_edit: '',
    integrate_career_websites_visible: false,
    integrate_career_websites_is_edit: '',
    templates_visible: false,
    templates_is_edit: '',
    template_is_archive: '',
    card_rating_visible: false,
    card_rating_is_edit: '',
    settings_candidates_visible: false,
    settings_candidates_is_edit: '',
    generate_tokens_visible: false,
    generate_tokens_is_edit: '',
    task_block: false,
    task_block_is_edit: '',
    task_block_archive: '',
    is_mobile_panel_visible: false,
    leads: false,
    call: false,
    user_work_plan: false,
    productivity: false,
    main_page_block: false,
    main_page: '',
    candidate_only_accepted: false,
    call_is_edit: '',
    call_is_archive: ''
  }
  if (values?.role_name) {
    preparedValues.name = values.role_name
  }
  if (values?.pages?.panels?.is_mobile_panel_visible === 1) {
    preparedValues.is_mobile_panel_visible = true
  }
  if (values?.pages.candidate.archive_access === 1) {
    preparedValues.candidate_block_archive = '1'
  }
  if (values?.pages.candidate.edit_view_access === 1) {
    preparedValues.candidate_block_is_edit = 'edit'
  }

  if (values?.pages.candidate.create_access) {
    preparedValues.candidate_create = true
  }
  if (values?.pages.request.page_access) {
    preparedValues.request_block = true
    if (values?.pages.request.archive_access === 1) {
      preparedValues.request_block_archive = '1'
    }
    if (values?.pages.request.edit_view_access === 1) {
      preparedValues.request_block_is_edit = 'edit'
    }
  }
  if (values?.pages.request.create_access === 1) {
    preparedValues.request_create = true
  }
  if (values?.pages.interview.page_access === 1) {
    preparedValues.interview_block = true
    if (values?.pages.interview.archive_access === 1) {
      preparedValues.interview_block_archive = '1'
    }
    if (values?.pages.interview.edit_view_access === 1 && values?.pages.interview.create_access === 1) {
      preparedValues.interview_block_is_edit = 'edit'
    }
    if (values?.pages.interview.calendar_access === 1) {
      preparedValues.interview_is_recruiter = 'is_recruiter'
    } else {
      preparedValues.interview_is_recruiter = 'is_researcher'
    }
  }
  if (values?.main_page) {
    preparedValues.main_page_block = true
    preparedValues.main_page = values?.main_page
  }
  if (values?.pages.vacancy.page_access === 1) {
    preparedValues.vacancy_block = true
    if (values?.pages.vacancy.edit_view_access === 1) {
      preparedValues.vacancy_block_is_edit = 'edit'
    }
    if (values?.pages.vacancy.archive_access === 1) {
      preparedValues.vacancy_block_archive = '1'
    }
  }
  if (values?.pages.callback.page_access === 1) {
    preparedValues.callback_visible = true
    if (values?.pages.callback.edit_view_access === 1) {
      preparedValues.callback_is_edit = 'edit'
    }
    if (values?.pages.callback.archive_access === 1) {
      preparedValues.callback_archive = '1'
    }
  }
  if (values?.pages.vacancy.create_access === 1) {
    preparedValues.vacancy_create = true
  }

  if (values?.pages.analytics.page_access === 1) {
    preparedValues.analytics_block = true
    if (values?.pages.analytics.edit_view_access === 1 && values?.pages.analytics.create_access === 1) {
      preparedValues.analytics_block_is_edit = 'edit'
    }
  }
  if (values?.pages.profile.page_access === 1) {
    preparedValues.profile_visible = true
    if (values?.pages.profile.edit_view_access === 1 && values?.pages.profile.create_access === 1) {
      preparedValues.profile_is_edit = 'edit'
    }
  }
  if (values?.pages.company_profile.page_access === 1) {
    preparedValues.profile_company_visible = true
    if (values?.pages.company_profile.edit_view_access === 1 && values?.pages.company_profile.create_access === 1) {
      preparedValues.profile_company_is_edit = 'edit'
    }
  }
  if (values?.pages.template.page_access === 1) {
    preparedValues.templates_visible = true
    if (values?.pages.template.edit_view_access === 1 && values?.pages.template.create_access === 1) {
      preparedValues.templates_is_edit = 'edit'
    }
    if (values?.pages.template.archive_access === 1) {
      preparedValues.template_is_archive = '1'
    }
  }
  if (values?.pages.users.page_access === 1) {
    preparedValues.users_visible = true
    if (values?.pages.users.edit_view_access === 1 && values?.pages.users.create_access === 1) {
      preparedValues.users_is_edit = 'edit'
    }
    if (values?.pages?.users.archive_access === 1) {
      preparedValues.users_is_archive = '1'
    }
  }
  if (values?.pages.role.page_access === 1) {
    preparedValues.role_visible = true
    if (values?.pages.role.edit_view_access === 1 && values?.pages.role.create_access === 1) {
      preparedValues.role_is_edit = 'edit'
    }
  }
  if (values?.pages.default_stage.page_access === 1) {
    preparedValues.stages_visible = true
    if (values?.pages.default_stage.edit_view_access === 1 && values?.pages.default_stage.create_access === 1) {
      preparedValues.stages_is_edit = 'edit'
    }
  }
  if (values?.pages.dictionary.page_access === 1) {
    preparedValues.dictionaries_visible = true
    if (values?.pages.dictionary.edit_view_access === 1 && values?.pages.dictionary.create_access === 1) {
      preparedValues.dictionaries_is_edit = 'edit'
    }
  }
  if (values?.pages.questionnare.page_access === 1) {
    preparedValues.questionnaire_visible = true
    if (values?.pages.questionnare.edit_view_access === 1 && values?.pages.questionnare.create_access === 1) {
      preparedValues.questionnaire_is_edit = 'edit'
    }
    if (values?.pages.questionnare.archive_access === 1) {
      preparedValues.questionnaire_block_archive = '1'
    }
  }
  if (values?.pages.questionnare.page_access === 1) {
    preparedValues.questionnaire_visible = true
    if (values?.pages.questionnare.edit_view_access === 1 && values?.pages.questionnare.create_access === 1) {
      preparedValues.questionnaire_is_edit = 'edit'
    }
  }
  if (values?.pages.site_integration.page_access === 1) {
    preparedValues.integrate_career_websites_visible = true
    if (values?.pages.site_integration.edit_view_access === 1 && values?.pages.site_integration.create_access === 1) {
      preparedValues.integrate_career_websites_is_edit = 'edit'
    }
  }
  if (values?.pages.candidate_list.page_access === 1) {
    preparedValues.settings_candidates_visible = true
    if (values?.pages.candidate_list.edit_view_access === 1 && values?.pages.candidate_list.create_access === 1) {
      preparedValues.settings_candidates_is_edit = 'edit'
    }
  }
  if (values?.pages.rating_card.page_access === 1) {
    preparedValues.card_rating_visible = true
    if (values?.pages.rating_card.edit_view_access === 1 && values?.pages.rating_card.create_access === 1) {
      preparedValues.card_rating_is_edit = 'edit'
    }
  }
  if (values?.pages.token_generate.page_access === 1) {
    preparedValues.generate_tokens_visible = true
    if (values?.pages.token_generate.edit_view_access === 1 && values?.pages.token_generate.create_access === 1) {
      preparedValues.generate_tokens_is_edit = 'edit'
    }
  }
  if (values?.pages.task.page_access) {
    preparedValues.task_block = true
    if (values?.pages.task.archive_access === 1) {
      preparedValues.task_block_archive = '1'
    }
    if (values?.pages.task.edit_view_access === 1) {
      preparedValues.task_block_is_edit = 'edit'
    }
  }
  if (values?.pages.leads.page_access) {
    preparedValues.leads = true
  }
  if (values?.pages.call.page_access === 1) {
    preparedValues.call = true
    if (values?.pages.callback.edit_view_access === 1) {
      preparedValues.call_is_edit = 'edit'
    }
    if (values?.pages.callback.archive_access === 1) {
      preparedValues.call_is_archive = '1'
    }
  }
  if (values?.pages.user_work_plan.page_access) {
    preparedValues.user_work_plan = true
  }
  if (values?.pages.productivity.page_access) {
    preparedValues.productivity = true
  }
  if (values?.pages.common.candidate_only_accepted === 1) {
    preparedValues.candidate_only_accepted = true
  }

  return preparedValues
}

export const jsonDownloadAccess = (candidates, requests, vacancies, analytics, leads, interviews) => {
  const obj = {
    candidates,
    requests,
    vacancies,
    analytics,
    leads,
    interviews
  }

  return JSON.stringify(obj)
}

export const jsonParseDownloadAccess = (download_access: string | undefined) => {
  if (download_access) {
    try {
      return JSON.parse(download_access)
    } catch (parseError) {
      console.error('Error parsing download_access:', parseError)
      return {}
    }
  }
  return {}
}

export const prepareForSubmitUser = (values: TFormValuesUser, id?: number, codeChalengeToken?: string) => {
  const user: TUser = {
    patronymic: values.patronymic,
    name: values.name,
    department_id: values.department,
    seat: values.seat,
    surname: values.surname,
    status: 10,
    isColdSearch: !!values.isColdSearch,
    role: values.role,
    email: values.email,
    phone: values.phone,
    group_list: values.group_list,
    isCanAppointRecruiter: !!values.isCanAppointRecruiter,
    can_assign_calendar_future_days: !!values.can_assign_calendar_future_days,
    is_can_conduct_interview: !!values.is_can_conduct_interview,
    can_make_report: !!values.can_make_report,
    telegram_chat_id: values?.telegram_chat_id,
    is_tester: !!values.is_tester,
    is_mass_stage: !!values.is_mass_stage,
    sip_login: values.sip_login,
    sip_password: values.sip_password,
    city_id: values.city_id,
    is_can_internship: !!values.is_can_internship,
    is_delete_any_comment: !!values.is_delete_any_comment,
    download_access: jsonDownloadAccess(
      values.candidates,
      values.requests,
      values.vacancies,
      values.analytics,
      values.leads,
      values.interviews
    )
  }
  if (values.password1) {
    user.password = values.password1
  }
  if (codeChalengeToken) {
    user.token_plugin = codeChalengeToken
  }
  if (values.telegram_channel_chat_id) {
    user.telegram_channel_chat_id = values.telegram_channel_chat_id
  }
  if (values.role === 'zakazchik_kur_erov' || values.role === 'courier_curator') {
    user.telegram_tag = values.telegram_tag
  }
  if (id) {
    user.id = id
  }
  return user
}
export const prepareForSubmitStore = (values: TFormValuesStore, finded: any) => {
  const store: TStore = {
    name: values.name,
    address: finded ? finded?.label || '' : values.address,
    city: values?.city?.value ? values?.city?.value : values.city || '',
    metro: values?.metro || '',
    longtitude: finded ? finded.value.split(',')[1] : values.longtitude,
    lattitude: finded ? finded.value.split(',')[0] : values.lattitude,
    type: values.type
  }
  if (values?.id) {
    store.id = values.id
  }
  return store
}

export function generateUserListFilters(words: string) {
  const filters = []

  if (!words || !words.trim()) {
    return ''
  }

  const newWords = words.trim().split(' ')

  for (let i = 0; i < newWords.length; i++) {
    const patronymic = `[patronymic][like]=${newWords[i]}`
    const name_like = `[name][like]=${newWords[i]}`
    const surname_like = `[surname][like]=${newWords[i]}`
    const email = `[email][like]=${newWords[i]}`

    filters?.push(patronymic, name_like, surname_like, email)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return `&filter${query}`
}

export function generateStoreListFilters(words: string) {
  if (!words || !words.trim()) {
    return ''
  }

  return `&filter[name][like]=${words}`
}

export const methodOptions = [
  { label: 'post', value: 'post' },
  { label: 'get', value: 'get' }
]

export const formdOptions = [
  { label: 'Form', value: 1 },
  { label: 'Raw body', value: 2 }
]

export async function getCity() {
  const { data } = await $api.get('/api/v1/city/get-drop-down')
  return data.DATA
}

export const remindToOptions = [
  { label: 'Кандидат', value: 'candidate' },
  { label: 'Заказчик', value: 'customer' },
  { label: 'Рекрутер', value: 'recruiter' },
  { label: 'Ресечер', value: 'researcher' }
]

export const timeOptions = [
  { label: '15 минут', value: 15 },
  { label: '30 минут', value: 30 },
  { label: '1 час', value: 60 },
  { label: '3 часа', value: 180 },
  { label: '6 часов', value: 360 },
  { label: '12 часов', value: 720 },
  { label: '1 день', value: 1440 }
]

export const remindTimeOptions1 = [
  { label: 'До события', value: 'before' },
  { label: 'После события', value: 'after' }
]
export const remindTimeOptions2 = [{ label: 'После события', value: 'after' }]

export async function getLimitationOption() {
  const { data } = await $api.get('/api/v1/candidate/get-attributes-drop-down')
  return data.DATA
}

export function getStageOptions(stages, vertex_id) {
  if (Array.isArray(stages)) {
    const arr = stages.filter((el) => el.vertex_id == vertex_id).map((el) => ({ label: el.name, value: el.id }))
    return [{ label: '', value: null }, ...arr]
  }
  return []
}

export const candidateCustomField = [
  {
    id: 1,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН',
    is_archive: true
  },
  {
    id: 2,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 3,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 4,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 5,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 6,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 7,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 8,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 9,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 10,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 11,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 12,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 13,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 14,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 15,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  },
  {
    id: 16,
    type: 'Свободный ввод',
    priority: 1,
    label: 'ИНН'
  }
]

export const constructorKeyOptions = [
  { key: 1, label: 'Свободный' },
  {
    key: 2,
    label: 'Справочник'
  },
  {
    key: 3,
    label: 'Дата'
  },
  {
    key: 4,
    label: 'Да/нет'
  }
]

export async function getDictionary() {
  const { data } = await $api.get('/api/v1/dictionaries?sort=id&per-page=10000&fields=id,name')
  return data.DATA.map((el) => ({ label: el.name, value: el.id }))
}
