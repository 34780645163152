import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { TDATA } from '../../candidate/types'

import './CostOfHiringTable.scss'
import Duplicate from './Duplicate'
import TableFluidity from '../../../common/components/table/TableFluidity'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TableCostOfHiring from "../../../common/components/table/TableCostOfHiring";
import EditPencil1 from "../../../common/svg/EditPencil1";

const mainCssClass = getCssClassName('cost-of-hiring-table')

type TProps = {
  data: TRowData[]
} & TClassName

type TRowData = {
  article_type: string
  resource: string
  cost_of_everything: string
  dark_store: string
  white_store: string
  buttons: JSX.Element
  id:number
}
const data: TRowData[] = [
  {
    article_type: 'Тип 1',
    resource: 'Ресурс 1',
    cost_of_everything: '1000',
    dark_store: 'Дарк стор 1',
    white_store: 'Вайт стор 1',
    buttons: <ButtonsTable id={1} />,
    id: 1
  },
  {
    article_type: 'Тип 2',
    resource: 'Ресурс 2',
    cost_of_everything: '2000',
    dark_store: 'Дарк стор 2',
    white_store: 'Вайт стор 2',
    buttons: <ButtonsTable id={2} />,
    id: 2
  },
  {
    article_type: 'Тип 3',
    resource: 'Ресурс 3',
    cost_of_everything: '3000',
    dark_store: 'Дарк стор 3',
    white_store: 'Вайт стор 3',
    buttons: <ButtonsTable id={3} />,
    id: 3
  },
  {
    article_type: 'Тип 4',
    resource: 'Ресурс 4',
    cost_of_everything: '4000',
    dark_store: 'Дарк стор 4',
    white_store: 'Вайт стор 4',
    buttons: <ButtonsTable id={4} />,
    id: 4
  },
  {
    article_type: 'Тип 5',
    resource: 'Ресурс 5',
    cost_of_everything: '5000',
    dark_store: 'Дарк стор 5',
    white_store: 'Вайт стор 5',
    buttons: <ButtonsTable id={5} />,
    id: 5
  },
  {
    article_type: 'Тип 6',
    resource: 'Ресурс 6',
    cost_of_everything: '6000',
    dark_store: 'Дарк стор 6',
    white_store: 'Вайт стор 6',
    buttons: <ButtonsTable id={6} />,
    id: 6
  },
  {
    article_type: 'Тип 7',
    resource: 'Ресурс 7',
    cost_of_everything: '7000',
    dark_store: 'Дарк стор 7',
    white_store: 'Вайт стор 7',
    buttons: <ButtonsTable id={7} />,
    id: 7
  },
  {
    article_type: 'Тип 8',
    resource: 'Ресурс 8',
    cost_of_everything: '8000',
    dark_store: 'Дарк стор 8',
    white_store: 'Вайт стор 8',
    buttons: <ButtonsTable id={8} />,
    id: 8
  },
  {
    article_type: 'Тип 9',
    resource: 'Ресурс 9',
    cost_of_everything: '9000',
    dark_store: 'Дарк стор 9',
    white_store: 'Вайт стор 9',
    buttons: <ButtonsTable id={9} />,
    id: 9
  },
  {
    article_type: 'Тип 10',
    resource: 'Ресурс 10',
    cost_of_everything: '10000',
    dark_store: 'Дарк стор 10',
    white_store: 'Вайт стор 10',
    buttons: <ButtonsTable id={10} />,
    id: 10
  },
  {
    article_type: 'Тип 11',
    resource: 'Ресурс 11',
    cost_of_everything: '11000',
    dark_store: 'Дарк стор 11',
    white_store: 'Вайт стор 11',
    buttons: <ButtonsTable id={11} />,
    id: 11
  },
  {
    article_type: 'Тип 12',
    resource: 'Ресурс 12',
    cost_of_everything: '12000',
    dark_store: 'Дарк стор 12',
    white_store: 'Вайт стор 12',
    buttons: <ButtonsTable id={12} />,
    id: 12
  },
  {
    article_type: 'Тип 13',
    resource: 'Ресурс 13',
    cost_of_everything: '13000',
    dark_store: 'Дарк стор 13',
    white_store: 'Вайт стор 13',
    buttons: <ButtonsTable id={13} />,
    id: 13
  },
  {
    article_type: 'Тип 14',
    resource: 'Ресурс 14',
    cost_of_everything: '14000',
    dark_store: 'Дарк стор 14',
    white_store: 'Вайт стор 14',
    buttons: <ButtonsTable id={14} />,
    id: 14
  },
  {
    article_type: 'Тип 15',
    resource: 'Ресурс 15',
    cost_of_everything: '15000',
    dark_store: 'Дарк стор 15',
    white_store: 'Вайт стор 15',
    buttons: <ButtonsTable id={15} />,
    id: 15
  }
]

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'article_type',
      Header: 'Тип статьи',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'resource',
      Header: 'Ресурс',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'cost_of_everything',
      Header: 'Стоимость всего',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'dark_store',
      Header: 'Дарк стор',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'white_store',
      Header: 'Вайт стор',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'buttons', Header: '' }

  ],
  []
)

const useRowsData = (data: TRowData[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const { original: { id } } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (data?.length
      ? data.map<TRowData>(({
        resource,
        cost_of_everything,
        article_type,
        dark_store,
        white_store,
        buttons,
        id
      }) => ({
        article_type: article_type || '-',
        resource: resource || '-',
        cost_of_everything: cost_of_everything || '-',
        dark_store: dark_store || '-',
        white_store: white_store || '-',
        buttons: buttons || <ButtonsTable id={id} />,
        id
      }))
      : []),
    [data]
  )

  return { rows, handleRowClick }
}
const CostOfHiringTable: FC<TProps> = ({ className, data }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(data)

  return (
    <TableCostOfHiring
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CostOfHiringTable))
