import React, {
  useEffect, useLayoutEffect
} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getCssClassName } from '../../../common/utils/generateClassName'
import AsteriskTable from './AsteriskTable'
import { getHistoryAsterisk } from '../actions'
import EmptyList from '../../../features/emptyList/components/EmptyList'
import './HistoryAsterisk.scss'
import { selectCandidate, selectHistoryAsterisk } from '../selectors'
import { selectLoadingModal } from '../../InnerRouter/selectors'

const MainCSSClass = getCssClassName('asterisk-history')

function HistoryAsterisk({ open }) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const historyData = useSelector(selectHistoryAsterisk)
  const loading = useSelector(selectLoadingModal)

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      // Ваш код обработки изменения размеров
    }, 300)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (candidate) {
      dispatch(getHistoryAsterisk(candidate.id))
    }
  }, [candidate, open])

  return (
    <div className={MainCSSClass}>

      {loading && (
        <div>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      <div className={`${MainCSSClass}_scroll-container`}>
        <NewScrollContainer className={`${MainCSSClass}_scroll-container_scroll`}>
          {!loading
          && !!historyData?.length
          && (
            <AsteriskTable
              historyData={historyData}
              className={`${MainCSSClass}_table`}
            />
          )}
          {!loading
          && !historyData?.length
           && <EmptyList type="asterisk" />}

        </NewScrollContainer>
      </div>
    </div>
  )
}

export default HistoryAsterisk
