import React, {
  memo, useEffect, useState
} from 'react'
import {
  ModalProps, Upload, UploadProps
} from 'antd'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { UploadFile } from 'antd/es/upload/interface'
import ControlButton from '../../../common/components/Buttons/Button'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  createNewComment, openCommentModal, setSelectedComment, updateComments
} from '../actions'
import { selectSelectedCommet } from '../selectors'
import FileUpload from '../../../common/svg/FileUpload'
import './SimpleModalCandidate.scss'

const mainCssClass = 'simpleModalCandidate'

export type TProps = {
  candidateId?: number
  user_id?: number
} & ModalProps

type TFormValues = {
  comment: string
}

function ModalNewComment({
  open, className, candidateId, user_id
}: TProps) {
  const dispatch = useDispatch()
  const selectComment = useSelector(selectSelectedCommet)
  const [fileList, setFileList] = useState<UploadFile[]>(selectComment && selectComment.attachments.length
    ? [
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: selectComment.attachments[0].filepath
      }
    ] : [])
  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({
    defaultValues: {
      comment: selectComment ? selectComment.comment : ''
    }
  })

  const comment = watch('comment')
  const [value, setValue] = useState()

  const onSubmit = () => {
    if (candidateId && user_id && !selectComment) {
      const formData = new FormData()
      fileList.forEach((file) => {
        formData.append('file', file as FileType)
      })
      formData.append('comment', comment)
      formData.append('user_id', String(user_id))
      formData.append('candidate_id', String(candidateId))

      dispatch(createNewComment(formData))
    } else if (selectComment) {
      const formData = new FormData()

      if (fileList.length && !fileList[0].url) {
        fileList.forEach((file) => {
          formData.append('file', file as FileType)
        })
      }
      if (!fileList.length) {
        formData.append('file', '')
      }
      formData.append('comment', comment)
      formData.append('id', String(selectComment.id))

      dispatch(updateComments(formData))
    }
  }

  const handleClickCancel = () => {
    dispatch(openCommentModal(false))
    dispatch(setSelectedComment(null))
  }


  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      if (!fileList.length) {
        setFileList([...fileList, file])
      } else {
        setFileList([file])
      }

      return false
    },
    fileList
  }

  useEffect(() => () => {
    reset()
    dispatch(setSelectedComment(null))
  }, [])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleClickCancel}
      title="Комментарий"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <TextAreaFormControl
        label="Текст комментария"
        name="comment"
        control={control}
        placeholder="Введите текст"
        autoSize={{ minRows: 4, maxRows: 5 }}
      />
      <div className={`${mainCssClass}__file-input-wrapper`}>
        <div className={`${mainCssClass}_header`}>
          <Upload
            {...props}
            className="customUpload"
            maxCount={1}
          >
            <div className={`${mainCssClass}__file-input-wrapper`}>
              <ControlButton className="button" typeButton="secondary">
                <FileUpload />
                Загрузить изображение
              </ControlButton>
            </div>
          </Upload>
        </div>
      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalNewComment))
