import { createAction } from '@reduxjs/toolkit'
import { TDATA } from '../candidate/types'
import {
  TCertificateRequest,
  TCoursesRequest,
  TDeleteResumeRequest,
  TEducationRequest,
  TExperienceRequest,
  TLanguageRequest,
  TRecomendationRequest,
  TRelocateRequest,
  TRequestInfoResume,
  TResume,
  TResumeRequest,
  TSkillsRequest,
  TTransferRequest,
  TWishesRequest
} from './types'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'

export const openSkillsModal = createAction<boolean>('resume/openSkillsModal')
export const openEditSkillsModal = createAction<boolean>('resume/openEditSkillsModal')

export const openCertificateModal = createAction<boolean>('resume/openCertificateModal')
export const openEditCertificateModal = createAction<boolean>('resume/openEditCertificateModal')

export const getDictionaries = createAction<TDictionariesNames>('resume/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('resume/dictionaries/setDictionaries')

export const setLoading = createAction<boolean>('resume/setLoading')
export const reset = createAction('resume/rest')

export const getCandidateResume = createAction<number>('resume/getCandidateResume')
export const setCandidateResume = createAction<TResume[]>('resume/setCandidateResume')

export const createSkillsResume = createAction<TSkillsRequest>('resume/createSkillsResume')
export const setResumeList = createAction<TResume[] | null>('resume/setResumeList')

export const setActiveResume = createAction<TResume | null>('resume/setActiveResume')

export const createCetrificateResume = createAction<TCertificateRequest>('resume/createCetrificateResume')
export const createRecomendationResume = createAction<TRecomendationRequest>('resume/createRecomendationResume')

export const openRecomendationModal = createAction<boolean>('resume/openRecomendationModal')
export const openEditRecomendationModal = createAction<boolean>('resume/openEditRecomendationModal')

export const openInfoModal = createAction<boolean>('resume/openInfoModal')
export const openEditInfoModal = createAction<boolean>('resume/openEditInfoModal')
export const createInfoResume = createAction<TRequestInfoResume>('resume/createInfoResume')

export const createLanguageResume = createAction<TLanguageRequest>('resume/createLanguageResume')
export const openLanguageModal = createAction<boolean>('resume/openLanguageModal')
export const openEditLanguageModal = createAction<boolean>('resume/openEditLanguageModal')

export const createCoursesResume = createAction<TCoursesRequest>('resume/createCoursesResume')
export const openCoursesModal = createAction<boolean>('resume/openCoursesModal')
export const openEditCoursesModal = createAction<boolean>('resume/openEditCoursesModal')

export const createRelocateResume = createAction<TRelocateRequest>('resume/createRelocateResume')
export const openRelocateModal = createAction<boolean>('resume/openRelocateModal')
export const openEditRelocateModal = createAction<boolean>('resume/openEditRelocateModal')

export const createTransferTime = createAction<TTransferRequest>('resume/createTransferTime')
export const openTransferModal = createAction<boolean>('resume/openTransferModal')
export const openEditTransferModal = createAction<boolean>('resume/openEditTransferModal')

export const createEducationResume = createAction<TEducationRequest>('resume/createEducationResume')
export const openEducationModal = createAction<boolean>('resume/openEducationModal')
export const openEditEducationModal = createAction<boolean>('resume/openEditEducationModal')

export const createExperienceResume = createAction<TExperienceRequest>('resume/createExperienceResume')
export const openExperienceModal = createAction<boolean>('resume/openExperienceModal')
export const openEditExperienceModal = createAction<boolean>('resume/openEditExperienceModal')

export const createWishesResume = createAction<TWishesRequest>('resume/createWishesResume')
export const openWishesModal = createAction<boolean>('resume/openWishesModal')
export const openEditWishesModal = createAction<boolean>('resume/openEditWishesModal')

export const createResume = createAction<TResumeRequest>('resume/createResume')

export const deleteResume = createAction<TDeleteResumeRequest>('resume/deleteResume')
