import React from 'react'
import { TClassName } from '../types/props'

type TProps = {
  fill?: string
} & TClassName
const Rectangle: React.FC<TProps> = ({ className, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className={className}>
    <rect width="14" height="14" rx="2" fill={fill || '#2DBE64'} />
  </svg>
)
export default Rectangle
