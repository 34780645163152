import React, {
  memo, useEffect, useMemo, useRef, useState
} from 'react'
import {
  Button, message, ModalProps, Typography, Upload, UploadProps
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import Draggable from 'react-draggable'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  openPhoneModal, setCandidateSelected, setDestinationNumber
} from '../actions'
import { getCssClassName } from '../../../common/utils/generateClassName'
import ControlButton from '../../../common/components/Buttons/Button'
import PhoneInputFormControl from '../../../common/components/formcontrol/PhoneInputFormControl'
import './PhoneModal.scss'
import { phoneButton } from '../utils'

const mainCssClass = getCssClassName('phone-modal')

    type TFormValues = {
      phone: string
    }

function PhoneModal({
  open, className
}: ModalProps) {
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(true)
  const [bounds, setBounds] = useState({
    left: 0, top: 0, bottom: 0, right: 0
  })
  const draggleRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)


  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>()

  const handleClickCancel = () => {
    dispatch(openPhoneModal(false))
  }

  const phone = watch('phone')

  const handleClickButton = (el:string) => {
    const phoneInput = document.querySelector<HTMLInputElement>('.vkusvill-app___phone-modal .PhoneInputInput')

    if (phoneInput) {
      const phoneValue = phoneInput.value

      if (!phone) {
        setValue('phone', `${phoneValue}${el}`)
      } else {
        setValue('phone', `${phone}${el}`)
      }
    }
  }

  const handleCall = () => {
    if (phone) {
      let newNumber
      if (phone.startsWith('+7')) {
        newNumber = `8${phone.slice(2)}`
      } else {
        newNumber = phone
      }
      dispatch(setDestinationNumber(newNumber))
      dispatch(openPhoneModal(false))
    }
  }

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    })
  }

  useEffect(() => {
    // Перемещаем курсор в конец input после установки фокуса
    const phoneInput = document.querySelector('.PhoneInputInput');
    if (phoneInput) {
      phoneInput.focus();
    }
  }, []);
  
  useEffect(() => () => {
    reset()
  }, [])

  return (

    <CommonModal
      open={open}
      onCancel={handleClickCancel}
      title={(
        <div
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false)
            }
          }}
          onMouseOut={() => {
            setDisabled(true)
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onFocus={() => {}}
          onBlur={() => {}}
          className="modal-handle"
        >
          Набор номера
        </div>
      )}
      className={cn(className, mainCssClass)}
      width="190px"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      footer={null}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}

    >
      <div
        className={`${mainCssClass}_content`}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false)
          }
        }}
        onMouseOut={() => {
          setDisabled(true)
        }}
        // fix eslintjsx-a11y/mouse-events-have-key-events
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
        onFocus={() => {}}
        onBlur={() => {}}
      >
        <PhoneInputFormControl name="phone" control={control} ref={inputRef} />
        <div className={`${mainCssClass}_content_list`}>
          {phoneButton.map((el, i) => <ControlButton key={i} typeButton="secondary" className="button circle-button" onClick={() => handleClickButton(el)}>{el}</ControlButton>)}
        </div>

        <ControlButton typeButton="primary" className="button" onClick={handleCall}>
          Позвонить
        </ControlButton>
      </div>
    </CommonModal>

  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(PhoneModal))
