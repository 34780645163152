import { useDispatch, useSelector } from 'react-redux'
import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { isArray } from 'lodash'
import {
  Progress, Tooltip, Typography, Button, Dropdown, message
} from 'antd'
import dayjs from 'dayjs'
import type { MenuProps } from 'antd'
import Star from '../../../../common/svg/Star'
import TagInput from './tagInput/TagInput'
import ArchiveCandidateModal from '../../../../features/candidateModals/components/ArchiveCandidateModal'
import {
  selectAssignModal,
  selectCandidate,
  selectCommentModal,
  selectDictionaryOptionsByName,
  selectDuplicateModal,
  selectEditCandidateDrawer,
  selectIsOpenArchive, selectListingStages,
  selectMergeDuplicateModal,
  selectNewInterview,
  selectOpenStatusModal,
  selectOpenSureModal,
  selectOpenUpdateModal, selectStageModal,
  selectStageStatusModal,
  selectUpdateCandidateMode
} from '../../selectors'
import ModalEditCandidate from '../ModalEditCandidate'
import { TDATA, TDelTag, TStageRequest } from '../../types'
import {
  delTagCandidate,
  getCandidateDuplicate,
  getCandidateMergeDuplicate, getStagesListing,
  openArchiveModal, openCheckDocModal, openEditCandidateDrawer, openStageStatusModal, openStatusModal,
  openSureModal,
  openUpdateModal, setAssignModal, setNewInterview, setStageModal,
  setUpdateCandidateMode,
  updateCandidate,
  updateStage
} from '../../actions'
import CloseSM from '../../../../common/svg/CloseSM'
import ModalCloseSure from '../ModalCloseSure'
import UserAvatar from '../../../../common/svg/UserAvatar'
import ControlButton from '../../../../common/components/Buttons/Button'
import { DetailsListItemProps } from '../../../../common/components/DetailsList'
import { Heading } from '../../../../common/components/Text'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import ModalNewComment from '../ModalNewComment'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import DuplicateModal from '../DuplicateModal'
import MergeDuplicateModal from '../MergeDuplicateModal'
import { removePrefixCity, updateCandidatePhone } from '../../utils'
import CheckBig from '../../../../common/svg/CheckBig'
import { WhatsApp } from '../../../../common/svg/SvgFromIconWrapper/WhatsApp'
import Plus from '../../../../common/svg/Plus'
import { selectModalEditOpen } from '../../../../compositions/InterviewCard/selectors'
import ModalTransferStage from '../ModalTransferStage'
import ModalAssiagnApplication from '../ModalAssiagnApplication'
import ArrowLeftSM from '../../../../common/svg/ArrowLeftSM'
import CaretDownMD from '../../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../../common/svg/CaretUpMD'
import HeaderButtonsPanel from './HeaderButtonsPanel'
import CandidateCardRefreshButton from './CandidateCardRefreshButton'
import CandidateCommunication from './CandidateCommunucation'
import ModalStageStatusModal from '../ModalStageStatusModal'
import ModalNewInterview from '../ModalNewInterview'
import { openTaskCreateModal, selectTaskCreateModal } from '../../../Tasks'
import CreateTaskModal from '../../../Tasks/components/CreateTaskModal'
import ChangeStatusModal from '../../../../features/candidateModals/components/ChangeStatusModal'
import EditInterviewModals from '../../../../features/interviewModals/components/EditInterviewModals'
import CandidateProgress from './CandidateProgress'
import AvatarMobVersion from '../../../../common/svg/AvatarMobVersion'
import CandidateEditDrawer from '../CandidateEditDrawer'
import ProgressBar from '../ProgressBar'
import { setHandleModalEditOpen } from '../../../../compositions/InterviewCard/actions'
import './HeaderCandidate.scss'

const MainCSSClass = 'headerCandidate'

type TProps = {
  isMobile: boolean
}

function HeaderCandidate({ isMobile }:TProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const [inputTag, setInputTag] = useState(false)
  const [removeArchive, setRemoveArchive] = useState(false)
  const stageStatusModal = useSelector(selectStageStatusModal)
  const newComment = useSelector(selectCommentModal)
  const duplicateModal = useSelector(selectDuplicateModal)
  const mergeDuplicateModal = useSelector(selectMergeDuplicateModal)
  const stages = useSelector(selectListingStages)
  const editInterview = useSelector(selectModalEditOpen)
  const assignModal = useSelector(selectAssignModal)
  const newInterview = useSelector(selectNewInterview)
  const stageModal = useSelector(selectStageModal)
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const taskModal = useSelector(selectTaskCreateModal)
  const statusModal = useSelector(selectOpenStatusModal)
  const editCandidateDrawer = useSelector(selectEditCandidateDrawer)
  const updateCandidateMode = useSelector(selectUpdateCandidateMode)
  const statusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  const labelOfStage = useMemo(() => {
    if (candidate && stages) {
      const current = stages?.find((stage) => stage.value === candidate.stage_id)
      if (current?.label) {
        return current.label
      }
    }
    return 'неизвестно'
  }, [candidate, stages])

  const colorOfStage = useMemo(() => {
    if (candidate && stages) {
      const current = stages?.find((stage) => stage.value === candidate.stage_id)
      if (current?.color) {
        return current.color
      }
    }
    return '#2DBE64'
  }, [candidate, stages])

  const percent = useMemo(() => {
    if (stages && candidate) {
      const currentIndex = stages.findIndex((stage) => stage.value === candidate.stage_id)
      const percent = ((currentIndex + 1) / stages.length) * 100
      return percent
    }
    return 0
  }, [stages, candidate])

  const currencyOptions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const userId = localStorage.getItem('user_id')

  const modal = useSelector(selectIsOpenArchive)
  const updateModal = useSelector(selectOpenUpdateModal)
  const sureModal = useSelector(selectOpenSureModal)

  const {
    rbacControl, role, department
  } = useEntityContext()
  const items: MenuProps['items'] = useMemo(
    () => candidate?.phone.map((el, i) => ({
      key: i,
      label: (
        <div className={`${MainCSSClass}_dropdown`}>
          <Star color="#D5DBD6" />
          <span>{el}</span>
        </div>)
    })),
    [candidate?.phone]
  )
  const colorArr = useMemo(() => {
    if (stages.length) {
      return stages.map((el) => el.color || '#2DBE64')
    }
    return '#2DBE64'
  }, [stages])

  const handleClickDropdown: MenuProps['onClick'] = ({ key }) => {
    if (Number(key) !== 0 && candidate) {
      dispatch(updateCandidate({ id: candidate?.id, phone: updateCandidatePhone(candidate.phone, Number(key)) }))
    }
  }

  const handleOpenModalEdit = () => {
    dispatch(openUpdateModal(true))
  }
  const handleOpenModalChangeStatus = () => {
    if (role?.role_id !== 'courier_curator') {
      dispatch(openStatusModal(true))
    }
  }
  const handleOpenModalChangeStatusStage = () => {
    if (role?.role_id !== 'courier_curator') {
      dispatch(openStageStatusModal(true))
    }
  }
  const handleOpenModalAssign = () => {
    dispatch(setAssignModal(true))
  }
  const handleOkAssignApplication = () => {
    dispatch(setAssignModal(false))
  }
  const closeAssignModal = () => {
    dispatch(setAssignModal(false))
  }
  const handleClickOpenCheckDoc = () => {
    dispatch(openCheckDocModal(true))
  }
  const candidates:DetailsListItemProps[] = useMemo(
    () => {
      const baseCandidates:DetailsListItemProps[] = [
        { label: 'Возраст', value: (<div onClick={handleOpenModalEdit}>{candidate?.age || 'Не указан'}</div>) },
        {
          label: 'Телефон',
          value: (<div className={`${MainCSSClass}__phone-flex`}>
            {candidate?.phone.length && candidate?.phone.length > 1

              ? (
                <>
                  {candidate?.phone && department !== 175 && (<span><WhatsApp className={`${MainCSSClass}__icon`} onClick={() => window.open(`https://wa.me/${isArray(candidate?.phone) ? candidate?.phone?.[0] : candidate?.phone}`)} /></span>)}

                  <span onClick={handleOpenModalEdit}>{candidate?.phone[0]}</span>
                  <Dropdown
                    menu={{
                      items, defaultSelectedKeys: ['0'], className: MainCSSClass, onClick: handleClickDropdown
                    }}
                    placement="bottomRight"
                    arrow
                  >
                    <span className={`${MainCSSClass}__phone-flex_extra`}>
                      еще
                      {' '}
                      {candidate?.phone.length ? candidate?.phone.length - 1 : '0'}
                      ...
                    </span>

                  </Dropdown>

                </>
              )

              : (
                <>
                  {candidate?.phone && department !== 175 && (<span><WhatsApp className={`${MainCSSClass}__icon`} onClick={() => window.open(`https://wa.me/${isArray(candidate?.phone) ? candidate?.phone?.[0] : candidate?.phone}`)} /></span>)}
                  <span onClick={handleOpenModalEdit}>{candidate?.phone[0]}</span>
                </>
              )}
          </div>)
        },
        { label: 'Email', value: (<div onClick={handleOpenModalEdit}>{ (isArray(candidate?.email) ? candidate?.email?.join(', ') : candidate?.email) || '-' }</div>) },
        { label: 'Адрес', value: (<div onClick={handleOpenModalEdit}>{candidate?.city ? `${candidate.city}${candidate?.address ? `, ${removePrefixCity(candidate.address)}` : ''}` : `${removePrefixCity(candidate?.address) || ''}` }</div>) },
        ...(candidate?.metro ? [{
          label: 'Метро',
          value: (
            <div onClick={handleOpenModalEdit}>
              {` м. ${candidate?.metro}`}
            </div>
          )
        }] : []),
        { label: 'Статус кандидата', value: (<div onClick={handleOpenModalChangeStatus}>{ candidate?.status ? candidate?.labels?.status : 'Не назначен' }</div>) },
        {
          label: 'Статус на этапе',
          value: (
            <>
              <div onClick={handleOpenModalChangeStatusStage}>
                {candidate?.stage_state_id ? candidate?.labels?.stage_state : 'Не выбрано'}
              </div>
              {department === 175 && (
                <div className={`${MainCSSClass}_assign`} onClick={handleOpenModalAssign}>
                  {candidate?.request_id ? 'Переназначить на заявку' : 'Назначить на заявку'}
                  <ArrowLeftSM fill="#2DBE64" className="internship_result" />
                </div>
              )}
            </>
          )
        }

      ]

      if (candidate?.is_show_btn_check_doc) {
        baseCandidates.push({
          label: 'Документы проверены',
          value: (<div className={`${MainCSSClass}__checkdoc`}>
            {candidate?.is_validate_document ? 'Проверены' : 'Не проверены'}
            {!!candidate?.is_show_btn_check_doc && !candidate?.is_validate_document
            && (
              <div onClick={handleClickOpenCheckDoc}>
                <CheckBig color="#2DBE64" />
                Подтвердить
              </div>
            )}
          </div>)
        })
      }
      if (candidate?.reject_id && candidate.status === 51) {
        baseCandidates.push({ value: candidate?.labels?.reject, label: 'Причина отказа', className: 'reject' })
      }

      if (candidate?.reject_comment && candidate.status === 51) {
        baseCandidates.push({
          value:
          candidate.reject_comment.length > 50 ? (
            <Tooltip title={candidate.reject_comment} placement="bottomRight">
              <div>{ `${candidate.reject_comment.slice(0, 50)}...`}</div>
            </Tooltip>
          ) : candidate.reject_comment,

          label: 'Комментарий отказа',
          className: 'reject'
        })
      }

      return baseCandidates
    },
    [candidate, department]
  )

  const statusOptionsWithoutArchive = useMemo(() => {
    if (statusOptions.length > 0) {
      return statusOptions.filter((el: any) => el.value !== 666 && el.value !== 51 && el.value !== candidate?.status)
    }
    return []
  }, [statusOptions, candidate])

  const handleInputTag = () => {
    setInputTag((prev) => !prev)
  }

  const handleModalEditOpen = useCallback(() => {
    dispatch(setHandleModalEditOpen(false))
  }, [])

  const handleModalEditClose = useCallback(() => {
    dispatch(openSureModal(true))
  }, [])

  const handleModalOk = (data: TDATA) => {
    dispatch(updateCandidate({ ...data, id: candidate?.id }))
    dispatch(openUpdateModal(false))
  }

  const handleCloseSureOk = useCallback(() => {
    dispatch(openSureModal(false))
    dispatch(openUpdateModal(false))
    dispatch(openUpdateModal(false))

    dispatch(openEditCandidateDrawer(false))
    dispatch(setUpdateCandidateMode(false))
  }, [])

  const handleCloseSureCancel = () => {
    dispatch(openSureModal(false))
  }

  const handleArchiveOk = () => {
    setRemoveArchive(false)
  }

  const handleDelTag = useCallback(
    (data: TDelTag) => {
      dispatch(delTagCandidate(data))
    },
    [candidate]
  )

  const handleArchiveCancel = useCallback(() => {
    dispatch(openArchiveModal(false))
  }, [])

  const handleOpenRemove = useCallback(() => {
    setRemoveArchive(true)
  }, [])
  const handleOpenStageModal = () => {
    dispatch(setStageModal(true))
  }
  const closeStageModal = () => {
    dispatch(setStageModal(false))
  }

  const closeInterviewModal = () => {
    dispatch(setNewInterview(false))
  }

  const handleUpdateStage = (data: TStageRequest) => {
    dispatch(updateStage({ ...data, candidate_id: candidate?.id }))
    closeStageModal()
  }

  const closeStageStatusModal = () => {
    dispatch(openStageStatusModal(false))
  }

  const handleCloseStatus = () => {
    dispatch(openStatusModal(false))
  }

  const handleOkStatus = () => {}

  const handleCancelCreateTask = () => {
    dispatch(openTaskCreateModal(false))
  }

  // const handleTransferStage = () => {
  //   dispatch(updateStage({ stage: 10, candidate_id: candidate?.id }))
  // }

  const handleClickOpenStatus = () => {
    dispatch(openStatusModal(true))
  }
  const handleMergeDuplicates = () => {
    if (candidate) {
      dispatch(getCandidateDuplicate(candidate.id))
      // dispatch(mergeDuplicates(candidate?.id))
    }
  }

  const handleMergeDuplicatesList = () => {
    if (candidate) {
      dispatch(getCandidateMergeDuplicate(candidate.id))
    }
  }

  useEffect(() => {
    dispatch(getStagesListing())
  }, [])

  return (
    <div className={MainCSSClass}>
      <HeaderButtonsPanel isMobile={isMobile} />
      {
        !!candidate?.is_duplicates
        && (
          <div className={`${MainCSSClass}_duplicates ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <div onClick={handleToggleCollapse} className={`${MainCSSClass}_toggle-section`}>
              {isCollapsed ? <CaretUpMD /> : <CaretDownMD />}
              <Typography.Text>
                {isCollapsed ? 'Дубли' : 'Найден дубль кандидата!'}
              </Typography.Text>
              &nbsp;
              {!isCollapsed && candidate?.merge_status === 1 ? (
                <span className={`${MainCSSClass}_merge-status`}>  (Основной)</span>
              ) : !isCollapsed && candidate?.merge_status === 2 ? (
                <span className={`${MainCSSClass}_merge-status`}>  (Побочный)</span>
              ) : null}
            </div>

            {!isCollapsed && (
              <ControlButton
                typeButton="secondary"
                className="button"
                onClick={handleMergeDuplicates}
              >
                Посмотреть дубли
              </ControlButton>
            )}
          </div>
        )
      }

      {
        !!candidate?.merge_status
        && (
          <div className={`${MainCSSClass}_merge-duplicates ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <div onClick={handleToggleCollapse} className={`${MainCSSClass}_toggle-section`}>
              {isCollapsed ? <CaretUpMD /> : <CaretDownMD />}
              <Typography.Text>
                {isCollapsed ? 'Дубли' : 'Кандидат был ранее слит'}
              </Typography.Text>
              &nbsp;
              {!isCollapsed && candidate?.merge_status === 1 ? (
                <span className={`${MainCSSClass}_merge-status`}>  (Основной)</span>
              ) : !isCollapsed && candidate?.merge_status === 2 ? (
                <span className={`${MainCSSClass}_merge-status`}>  (Побочный)</span>
              ) : null}
            </div>
            {!isCollapsed && (
              <ControlButton
                typeButton="secondary"
                className="button"
                onClick={handleMergeDuplicatesList}
              >
                Посмотреть историю слияния
              </ControlButton>
            )}
          </div>
        )
      }
      <div className={`${MainCSSClass}_container-communication`}>

        {!isMobile && <CandidateCommunication isMobile={isMobile} />}
        <div className={`${MainCSSClass}_header`}>

          {!isMobile

          && (candidate?.photo
            ? (
              <div>
                <img src={candidate.photo} alt="avatar" />
              </div>
            )
            : (
              <div className={`${MainCSSClass}_header_avatar`}>
                <UserAvatar />
              </div>
            ))}

          <div className={`${MainCSSClass}_list`}>
            <Typography.Text className={`${MainCSSClass}_list_created_at`}>
              Откликнулся:
              {' '}
              {candidate?.date_lid_respond ? dayjs(candidate?.date_lid_respond)
                .format('DD.MM.YYYY') : 'дата не указана'}
              {' '}
              •
              {' '}
              Добавлен:
              {' '}
              {candidate?.created_at ? dayjs(candidate?.created_at)
                .format('DD.MM.YYYY') : 'дата не указана'}
              {' '}
              •
              {' '}
              {candidate?.labels?.leadgen_source === '—' ? 'Вручную' : candidate?.labels?.leadgen_source}
            </Typography.Text>
            {isMobile
              ? (
                <div className={`${MainCSSClass}__header_mob_avatar`}>
                  { candidate?.photo
                    ? (
                      <div>
                        <img src={candidate.photo} alt="avatar" />
                      </div>
                    )
                    : (
                      <div>
                        <AvatarMobVersion />
                      </div>
                    )}

                  <Heading.H4 className={`${MainCSSClass}_list_created_at_name`}>
                    {
                      candidate?.last_name
                        ? `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`
                        : `${candidate?.name} ${candidate?.second_name || ''}`
                    }
                  </Heading.H4>
                </div>
              )
              : (
                <Heading.H4 className={`${MainCSSClass}_list_created_at_name`}>
                  {
                    candidate?.last_name
                      ? `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`
                      : `${candidate?.name} ${candidate?.second_name || ''}`
                  }
                </Heading.H4>
              )}

            <Typography.Text className={`${MainCSSClass}_list_created_at`}>
              {candidate?.birthday ? dayjs(candidate?.birthday)
                .format('DD.MM.YYYY') : 'Дата не указана'}
              {' '}
              {candidate?.age !== null ? `(${candidate?.age} лет)` : '(возраст не указан)'}
              {' '}
              •
              {' '}
              {candidate?.address || 'Адрес не указан'}
              {' '}
              •
              {' '}
              {candidate?.city || 'город не указан'}
            </Typography.Text>
            <Typography.Text className={`${MainCSSClass}_list_created_at`}>
              Гражданство:
              {' '}
              {candidate?.citizenship ? candidate.labels.citizenship : 'неизвестно'}
              {' '}
              •
              {' '}
              ИНН:
              {' '}
              {candidate?.inn || 'не указан'}
            </Typography.Text>

            <CandidateCardRefreshButton />

            <div className={`${MainCSSClass}__status`}>
              <Typography.Text className={`${MainCSSClass}__status_label`}>
                Статус кандидата:
                {' '}
                <Typography.Text
                  onClick={handleClickOpenStatus}
                  className={candidate?.status !== 51
                    ? `${MainCSSClass}__status_label_candidate`
                    : `${MainCSSClass}__status_label_reject`}
                  style={{ cursor: 'pointer' }}
                >
                  {candidate?.status ? candidate?.labels?.status : 'Не назначен'}
                </Typography.Text>
              </Typography.Text>
            </div>
            {candidate?.reject_id && candidate.status === 51 && (
              <div className={`${MainCSSClass}__status`}>
                <Typography.Text className={`${MainCSSClass}__status_label_reject`}>
                  Причина отказа:&nbsp;
                  {' '}
                  <Typography.Text className={`${MainCSSClass}__status_label_reject`}>
                    {candidate?.labels?.reject ? candidate?.labels?.reject : 'Отсутствует'}
                  </Typography.Text>
                </Typography.Text>
              </div>
            )}
            {candidate?.reject_comment && candidate?.status === 51 && (
              <div className={`${MainCSSClass}__status`}>
                <Typography.Text className={`${MainCSSClass}__status_label_reject`}>
                  Комментарий отказа:&nbsp;
                  {' '}
                </Typography.Text>
                <Typography.Text className={`${MainCSSClass}__status_label_reject`}>
                  {candidate.reject_comment.length > 50 ? (
                    <Tooltip title={candidate.reject_comment} placement="bottomRight">
                      <div>{ `${candidate.reject_comment.slice(0, 50)}...`}</div>
                    </Tooltip>
                  ) : candidate.reject_comment}
                </Typography.Text>

              </div>
            )}
            {candidate?.is_show_btn_check_doc
             && (
               <div className={`${MainCSSClass}__checkdoc`}>
                 <Typography.Text className={`${MainCSSClass}__status_label`}>
                   Документы:
                 </Typography.Text>
                 <Typography.Text className={`${MainCSSClass}__status_label_candidate`}>
                   {candidate?.is_validate_document ? 'Проверены' : 'Не проверены'}
                 </Typography.Text>
                 {!!candidate?.is_show_btn_check_doc && !candidate?.is_validate_document
              && (
                <div onClick={handleClickOpenCheckDoc}>
                  <CheckBig color="#2DBE64" />
                  Подтвердить
                </div>
              )}
               </div>
             )}
            <div />
            {candidate?.tag_list && (
              <div className={`${MainCSSClass}_list_tag`}>
                {candidate.tag_list.map((el) => (
                  <div key={el.created_at} className={`${MainCSSClass}_list_tag_item`}>
                    <span>{el?.label}</span>
                    {rbacControl?.candidate?.edit_view_access === 1 && (
                      <ControlButton
                        icon="iconOnly"
                        className="button"
                        onClick={() => handleDelTag({
                          label: el?.label,
                          candidate_id: candidate.id,
                          created_at: el.created_at
                        })}
                      >
                        <CloseSM />
                      </ControlButton>
                    )}
                  </div>
                ))}
              </div>
            )}
            {!inputTag && rbacControl?.candidate?.edit_view_access === 1 && (
              <button className={`${MainCSSClass}_addTag`} type="button" onClick={handleInputTag}>
                <Plus color="#d7144b" />
                Настроить теги
              </button>
            )}
            {inputTag && <TagInput onChange={handleInputTag} />}
          </div>
        </div>
      </div>
      <div className={`${MainCSSClass}__progress`}>
        <div className={`${MainCSSClass}__stage`}>
          <Typography.Text className={`${MainCSSClass}__stage_label`}>
            Этап:
          </Typography.Text>
          <ProgressBar stages={stages} currentStage={candidate?.stage_id} isMobile={isMobile}/>
        </div>
        <div className={`${MainCSSClass}__stage`}>
          <Typography.Text className={`${MainCSSClass}__stage_label`} onClick={handleOpenModalChangeStatusStage}>
            Статус на этапе:&nbsp;&nbsp;
          </Typography.Text>
          <Typography.Text
            className={`${MainCSSClass}__stage_text`}
            onClick={handleOpenModalChangeStatusStage}
          >
            {candidate?.stage_state_id ? candidate?.labels?.stage_state : 'Не выбрано'}
          </Typography.Text>
        </div>
      </div>
      <CandidateProgress />
      {/* {updateModal && (
        <>
          <ModalEditCandidate editMode open={updateModal} onCancel={handleModalEditClose} onOk={handleModalOk} />
          <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />
        </>

      )} */}
      {stageStatusModal && candidate && (
        <ModalStageStatusModal open={stageStatusModal} candidate_id={candidate?.id} onCancel={closeStageStatusModal} />
      )}

      <ArchiveCandidateModal
        open={modal}
        isCandidateTable={false}
        options={currencyOptions}
        onOk={handleArchiveOk}
        onCancel={handleArchiveCancel}
        candidateId={candidate?.id ? candidate?.id : 0}
      />
      {assignModal
          && (
            <ModalAssiagnApplication
              candidateId={candidate?.id}
              open={assignModal}
              wrapClassName="wrapModal"
              onCancel={closeAssignModal}
              onOk={handleOkAssignApplication}
            />
          )}
      {newComment && candidate && userId && (
        <ModalNewComment
          open={newComment}
          candidateId={candidate?.id}
          wrapClassName="wrapModal"
          user_id={Number(userId)}
        />
      )}
      {newInterview && (
        <ModalNewInterview
          open={newInterview}
          wrapClassName="wrapModal"
          onCancel={closeInterviewModal}
          onOk={closeInterviewModal}
        />
      )}
      {taskModal && candidate && (
        <CreateTaskModal
          open={taskModal}
          onCancel={handleCancelCreateTask}
          candidate_id={candidate?.id}
        />
      )}
      {statusModal && (
        <ChangeStatusModal
          open={statusModal}
          wrapClassName="wrapModal"
          onCancel={handleCloseStatus}
          onOk={handleOkStatus}
          isCandidateTable={false}
          options={statusOptionsWithoutArchive}
          candidateId={candidate?.id}
        />
      )}
      {
        stageModal
          && (
            <ModalTransferStage
              open={stageModal}
              stageOptions={stageOptions}
              wrapClassName="wrapModal"
              onCancel={closeStageModal}
              onOk={handleUpdateStage}
              isFunnel={false}
            />
          )
      }
      {editInterview && candidate && (
        <EditInterviewModals
          open={editInterview}
          candidate={candidate}
          onCancel={handleModalEditOpen}
          isCandidatePage
        />
      )}
      {duplicateModal && <DuplicateModal open={duplicateModal} candidateId={candidate?.id} />}
      {mergeDuplicateModal && <MergeDuplicateModal open={mergeDuplicateModal} />}
      {isMobile
            && (
              <>
                <CandidateEditDrawer open={editCandidateDrawer} />
                <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />
              </>
            )}

    </div>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(HeaderCandidate))
