import React, { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import { selectActiveQuestionnare, selectActiveToken } from '../selectors'
import { getCallbackOption } from '../utils'
import { createToken, openTokenForm, setActiveToken, updateToken } from '..'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import $api from '../../../common/api/http'
import './NewTokens.scss'

const mainCssClass = getCssClassName('new-tokens')

type TFormValues = {
  token: string
  utm_source: string
  lid_name: string
  is_active: true
} & any

function TemplatesForm() {
  const dispatch = useDispatch()

  const activeQuestionnaire = useSelector(selectActiveQuestionnare)
  const activeToken = useSelector(selectActiveToken)
  const [lidOptions, setLidOptions] = useState([])
  const defaultValues = useMemo(
    () =>
      activeToken
        ? {
            token: activeToken.token,
            utm_source: activeToken.utm_source,
            lid_name: activeToken.lid_name
          }
        : {},
    [activeToken]
  )

  const { control, handleSubmit, reset, getValues, watch, setValue } = useForm<TFormValues>({ defaultValues })

  const handleCancel = () => {
    dispatch(openTokenForm(false))
    dispatch(setActiveToken(null))
  }
  const generateToken = async () => {
    const response = await $api.get('/api/v1/token-integration/token-generate')
    if (!response.data.ERR) {
      setValue('token', response.data.DATA.token)
    }
  }
  const onSubmit = () => {
    const value = getValues()

    if (!activeToken) {
      dispatch(createToken({ ...value }))
    } else {
      dispatch(updateToken({ ...value, id: activeToken.id }))
    }
  }
  useLayoutEffect(() => {
    getCallbackOption()
      .then((responseData) => {
        setLidOptions(responseData.lid_list)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])
  useEffect(
    () => () => {
      reset()
    },
    []
  )
  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>{activeQuestionnaire ? 'Редактировать токен' : 'Новый токен'}</Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl
              name="lid_name"
              control={control}
              label="Название токена"
              required
              rules={{ required: true }}
            />
            <SearchSelectFormControlSource
              name="utm_source"
              control={control}
              label="Источник"
              required
              rules={{ required: true }}
              options={lidOptions}
              showSearch
              optionFilterProp="children"
            />
            <div className={`${mainCssClass}_secondGroup`}>
              <InputFormControl name="token" control={control} label="Токен" required />
              <ControlButton typeButton="secondary" size="large" className="button" onClick={generateToken}>
                Сгенерировать
              </ControlButton>
            </div>
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(TemplatesForm))
