import React, {
  memo,
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { Typography, Input, Tooltip } from 'antd'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import CloseMD from '../../../common/svg/CloseMD'
import {
  fetchCitiesList,
  fetchClientList,
  fetchRecruiterList,
  fetchRequestList,
  fetchResearcherList,
  fetchResponsibleList,
  fetchStoreList,
  fetchTagsList,
  fetchVacancyList,
  filterObject,
  filteredOptions,
  getStageState,
  objectToQueryString
} from '../utils'
import { getCssClassName } from '../../../common/utils/generateClassName'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import Search from '../../../common/svg/Search'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import {
  getDictionaries, getFilters, openFilters, setCountFilters, setDefFilters, setDeleteFilterByKey, setFilters, setFiltersLabels
} from '../actions'
import {
  selectDefFilters, selectDeleteFilterKey, selectDictionaryOptionsByName, selectLabelsFilter, selectTimeOptions
} from '../selectors'
import Redo from '../../../common/svg/Redo'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { selectBlockedFiltersResponsibleOrRecruiterInterview } from '../../../compositions/ManagementInterviews/selectors'
import { selectBlockedFiltersResponsibleOrRecruiter } from '../../../compositions/Funnel/selectors'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { TBaseUrl } from '../../../compositions/InnerRouter/types'
import { selectBaseUrl, selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import './Filters.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'

type TFormValues = {
  citizenship: number[]
  created_at: string[] | null
  stage_id: number[]
  status: number[]
  date_registration: string[] | null
  date_internship: string[] | null
  date_respond: string[] | null
  leadgen_source_id: number[]
  priority_id: number[]
  reject_id: number[]
  researcher: number[]
  responsible: number[]
  research: number[]
  customer: number[]
  vacancy_id: number[]
  city: string[]
  store_id: (number | string)[]
  type_registration: number[]
  recruiter: number[]
  result: number[]
  confirm: boolean[]
  date: string[] | null
  request_id: number[]
  group: number[]
  stage_state: number[]
  tag: any[]
  characteristic: number[]
  stage_state_id: number[]
  office: number[]
  date_interview: string[] | null
  date_teach: string[] | null
  is_duplicates: boolean
  request_city: string[]
  date_interview_offline: string[] | null
  responded: boolean
  candidateReject: boolean
  webmaster_id: string
  updated_at: string[] | null
  date_lid_respond: string[] | null
  respond_groups: number[]
  time: string[] | null
  vacancy_id_search: string
  is_validate_document: boolean
  whitedarkstore: number[]
}

export const formValuesArray = [
  { key: 'citizenship', value: [], label: 'Гражданство' },
  { key: 'created_at', value: [], label: 'По дате добавления' },
  { key: 'stage_id', value: [], label: 'Этап' },
  { key: 'status', value: [], label: 'Статус кандидата' },
  { key: 'date_registration', value: [], label: 'По дате оформления' },
  { key: 'date_internship', value: [], label: 'По дате стажировки' },
  { key: 'date_respond', value: [], label: 'По дате отклика (HH)' },
  { key: 'leadgen_source_id', value: [], label: 'Источник' },
  { key: 'priority_id', value: [], label: 'Приоритет' },
  { key: 'reject_id', value: [], label: 'Причины отказа' },
  { key: 'researcher', value: [], label: 'Ответственный ресечер' },
  { key: 'responsible', value: [], label: 'Ответственный рекрутер' },
  { key: 'research', value: [], label: 'Ресечер' },
  { key: 'customer', value: [], label: 'Заказчик' },
  { key: 'whitedarkstore', value: [], label: 'Дарксторы и Вайтсторы' },
  { key: 'vacancy_id', value: [], label: 'Вакансия' },
  { key: 'city', value: [], label: 'Город' },
  { key: 'store_id', value: [], label: 'Объект' },
  { key: 'type_registration', value: [], label: 'Вид оформления' },
  { key: 'recruiter', value: [], label: 'Рекрутер' },
  { key: 'result', value: [], label: 'Результат' },
  { key: 'confirm', value: [], label: 'Подтверждение' },
  { key: 'date', value: [], label: 'Дата проведения' },
  { key: 'request_id', value: [], label: 'Заявка' },
  { key: 'group', value: [], label: 'Группы' },
  { key: 'stage_state', value: [], label: 'Cтатус на этапе' },
  { key: 'tag', value: [], label: 'Тэги' },
  { key: 'characteristic', value: [], label: 'Характеристика' },
  { key: 'stage_state_id', value: [], label: 'Cтатус на этапе' },
  { key: 'office', value: [], label: 'Офисы' },
  { key: 'date_interview', value: [], label: 'Дата интервью' },
  { key: 'date_teach', value: [], label: 'По дате обучения' },
  { key: 'is_duplicates', value: [], label: 'Дубликат' },
  { key: 'request_city', value: [], label: 'Город заявки' },
  { key: 'date_interview_offline', value: [], label: 'По дате оффлайн собеседования' },
  { key: 'responded', value: [], label: 'Показывать необработанных кандидатов' },
  { key: 'candidateReject', value: [], label: 'Показывать отклоненных кандидатов' },
  { key: 'webmaster_id', value: [], label: 'Webmaster id' },
  { key: 'updated_at', value: [], label: 'По дате обновления' },
  { key: 'date_lid_respond', value: [], label: 'По дате отклика (лидген)' },
  { key: 'respond_groups', value: [], label: 'Группы отклика' },
  { key: 'time', value: [], label: 'Время проведения' },
  { key: 'vacancy_id_search', value: [], label: 'Id вакансии' },
  { key: 'is_validate_document', value: [], label: 'Документы проверены' }
]

type TFiltersProps = {
  candidate?: boolean
  request?: boolean
  vacancy?: boolean
  vacancyPage?: boolean
  interview?: boolean
}

const mainCssClass = getCssClassName('filters')

function Filters({
  candidate, request, vacancy, vacancyPage, interview
}: TFiltersProps) {
  const dispatch = useDispatch()

  const { pageId } = useParams()
  const defFilters = useSelector(selectDefFilters)
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const targetUrl: TBaseUrl = baseUrl || pageId || 'candidates'
  const userInfo = useSelector(selectUserInfo)

  const defaultValues = useMemo(() => (localStorage.getItem(`def${targetUrl || 'candidates'
  }`) ? JSON?.parse(localStorage.getItem(`def${targetUrl || 'candidates'}`)) : {}), [defFilters, targetUrl])
  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TFormValues>({ defaultValues })
  const {
    control: controlRadio, watch: watchRadio, getValues: getValuesRadio, handleSubmit: handleSubmitRadio,
    setValue: setValueRadio, reset: resetRadio, resetField: resetFieldRadio
  } = useForm<TFormValues>()

  const [allModal, setAllModal] = useState<any>(filterObject)

  const [citizenshipInput, setCitizenshipInput] = useState('')
  const [stageInput, setStageInput] = useState('')
  const [statusCandidateInput, setStatusCandidateInput] = useState('')
  const [workSitesInput, setWorkSitesInput] = useState('')
  const [rejReasInput, setRejReasInput] = useState('')
  const [statusStageInput, setStatusStageInput] = useState('')
  const [priorityInput, setPriorityInput] = useState('')
  const [registrTypeInput, setRegistrTypeInput] = useState('')
  const [statusVacancyInput, setStatusVacancyInput] = useState('')
  const [interviewStatusInput, setInterviewStatusInput] = useState('')
  const [interviewResultInput, setInterviewResultInput] = useState('')
  const [characteristicInput, setCharacteristicInput] = useState('')
  const [groupInput, setGroupInput] = useState('')
  const [timeInput, setTimeInput] = useState('')
  const [officeInput, setOfficeInput] = useState('')
  const [respondGroupsInput, setRespondGroupsInput] = useState('')

  const [statusStageOptions, setStatusStageOptions] = useState<any>(undefined)

  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const stageStatusOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const workSitesStatusOptions = useSelector(selectDictionaryOptionsByName('WorkSites'))
  const reasonOptions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const registrOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const vacancyStatusOptions = useSelector(selectDictionaryOptionsByName('vacancyStatus'))
  const interviewStatusOptions = useSelector(selectDictionaryOptionsByName('statusResult'))
  const interviewResultOptions = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const characteristicOptions = useSelector(selectDictionaryOptionsByName('candidateCharacteristic'))
  const officeOptions = useSelector(selectDictionaryOptionsByName('office'))
  const timeOptions = useSelector(selectTimeOptions)
  const filterKey = useSelector(selectDeleteFilterKey)
  const currentFormValues = useSelector(selectLabelsFilter)

  const blockedResponsibleOrRecruiter = useSelector(selectBlockedFiltersResponsibleOrRecruiter)
  const blockedResponsibleOrRecruiterInterview = useSelector(selectBlockedFiltersResponsibleOrRecruiterInterview)

  const confirmOptions = [
    { label: 'Подтверждено', value: 'confirmed' },
    { label: 'Не подтверждено', value: 'unconfirmed' }
  ]

  const handleApply = () => {
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(getValues()))
    localStorage.setItem(targetUrl || 'candidates', objectToQueryString(getValues()))
    const values = getValues()
    const changeValues = formValuesArray.map((item) => {
      if (item.key === 'citizenship') {
        // Если ключ равен citizenship, то фильтруем массив citizenshipOptions
        return {
          ...item,
          value: citizenshipOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'status') {
        return {
          ...item,
          value: candidateStatusOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'reject_id') {
        return {
          ...item,
          value: reasonOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'type_registration') {
        return {
          ...item,
          value: registrOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'priority_id') {
        return {
          ...item,
          value: priorityOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'status' && vacancy) {
        return {
          ...item,
          value: vacancyStatusOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'leadgen_source_id') {
        return {
          ...item,
          value: workSitesStatusOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'status' && interview) {
        return {
          ...item,
          value: interviewStatusOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'result' && interview) {
        return {
          ...item,
          value: interviewResultOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'stage_id') {
        return {
          ...item,
          value: stageStatusOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'group') {
        return {
          ...item,
          value: groupOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'office') {
        return {
          ...item,
          value: officeOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'time') {
        return {
          ...item,
          value: timeOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'characteristic') {
        return {
          ...item,
          value: characteristicOptions.filter((option) => values[item.key]?.includes(option.value))?.map((item) => item.label) || []
        }
      }
      if (item.key === 'date_registration' || item.key === 'date_internship' || item.key === 'date' || item.key === 'created_at' || item.key === 'date_teach'
        || item.key === 'date_interview' || item.key === 'updated_at' || item.key === 'date_respond' || item.key === 'date_lid_respond' || item.key === 'date_interview_offline'
        || item.key === 'date_interview_offline') {
        return {
          ...item,
          value: values[item.key] ? (Array.isArray(values[item.key]) ? [values[item.key].map((item) => dayjs(item).format(DATE_DOTS_FORMAT)).join('-')] : [values[item.key]]) : []
        }
      }
      if (item.key === 'is_duplicates' || item.key === 'responded' || item.key === 'is_validate_document' || item.key === 'candidateReject' || item.key === 'without_store') {
        return {
          ...item,
          value: values[item.key] ? ['Да'] : []
        }
      }
      if (Array.isArray(values[item.key])) {
        return {
          ...item,
          value: values[item.key].map((item) => item.label)// Устанавливаем значение, если оно есть, иначе пустой массив
        }
      }
      // Для всех остальных ключей используем стандартную логику
      return {
        ...item,
        value: values[item.key] ? values[item.key] : [] // Устанавливаем значение, если оно есть, иначе пустой массив
      }
    })
    dispatch(setFiltersLabels(changeValues))
    localStorage.setItem(`${targetUrl || 'candidates'
    }-labels`, JSON.stringify(changeValues))
    dispatch(getFilters({ filter: getValues(), isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({ type: targetUrl || 'candidates', filter: getValues(), defFilterString: objectToQueryString(getValues(), targetUrl === 'event-funnel') }))
  }

  const handleAllReset = useCallback(() => {
    const mapValues = getValues()
    if (targetUrl) {
      localStorage.setItem(targetUrl, '')
      localStorage.setItem(`def${targetUrl}`, '')
      localStorage.setItem(`${targetUrl}-labels`, '')
      dispatch(setFilters(''))
      dispatch(setFiltersLabels(formValuesArray))
    }
    if (defaultValues) {
      // eslint-disable-next-line prefer-const
      for (let key in mapValues) {
        if (key === 'date_registration' || key === 'date_internship' || key === 'date' || key === 'created_at' || key === 'date_teach' || key === 'date_interview') {
          setValueRadio(key, undefined)
          setValue(key, null)
        } else if (key === 'is_duplicates') {
          reset({ key: false })
        } else if (key === 'responded') {
          reset({ key: false })
        } else if (key === 'date_respond') {
          setValue(key, null)
        } else if (key === 'date_lid_respond') {
          setValue(key, null)
        } else if (key === 'date_interview_offline') {
          setValue(key, null)
        } else if (key === 'webmaster_id') {
          setValue(key, '')
        } else if (key === 'candidateReject') {
          reset({ key: false })
        } else if (key === 'without_store') {
          reset({ key: false })
        } else if (key === 'is_validate_document') {
          setValue(key, false)
        } else {
          setValue(key, [])
        }
      }
    } else {
      reset({})
    }
  }, [defaultValues, targetUrl])

  const handleChangeRadio = (e, name) => {
    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      setValue(name, [weekAgo, currentDate])
    } else if (e.target.value === 2) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      setValue(name, [weekAgo, currentDate])
    } else if (e.target.value === 3) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'y').format('YYYY-MM-DD')
      setValue(name, [weekAgo, currentDate])
    } else {
      setValue(name, null)
    }
  }

  // если надо будет прикрутить RadioButton, то возвращаешь скроллы, div убирать -short
  // eslint-disable-next-line no-lone-blocks
  { /* <RadioGroupFormControl
  name="date_interview"
  control={controlRadio}
  options={radioOptions}
  onChange={(e)=> handleChangeRadio(e, 'date_interview')}
  /> */ }

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleCloseFilters = useCallback(() => {
    dispatch(openFilters(false))
  }, [])

  const handleCitizenshipInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCitizenshipInput(e.target.value)
  }

  const handleStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStageInput(e.target.value)
  }

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  const handleRespondGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRespondGroupsInput(e.target.value)
  }

  const handleInterviewResultInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInterviewResultInput(e.target.value)
  }

  const handleCharacteristicInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCharacteristicInput(e.target.value)
  }

  const handleStatusCandidateInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusCandidateInput(e.target.value)
  }
  const handleWorkSitesInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setWorkSitesInput(e.target.value)
  }

  const handleRejReasInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRejReasInput(e.target.value)
  }

  const handleStatusStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusStageInput(e.target.value)
  }
  const handleInterviewStatusInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInterviewStatusInput(e.target.value)
  }

  const handleStatusVacancyInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusVacancyInput(e.target.value)
  }

  const handleRegistrTypeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRegistrTypeInput(e.target.value)
  }

  const handlePriorityInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPriorityInput(e.target.value)
  }

  const handleTimeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTimeInput(e.target.value)
  }

  const handleOfficeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setOfficeInput(e.target.value)
  }

  const resetDateBegin = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, created_at: null }

    localStorage.setItem(`def${targetUrl}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'created_at' ? { ...item, value: [] } : item))

    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl}-labels`, JSON.stringify(currentFormValuesUpdate))
    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('created_at', null)
    setValueRadio('created_at', null)
  }

  const resetDateInternship = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_internship: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_internship' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl || 'candidates'), objectToQueryString(valuesWithoutDate))
    localStorage.setItem(`${targetUrl || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_internship', null)
    setValueRadio('date_internship', null)
  }
  const resetDateTeach = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_teach: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_teach' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl || 'candidates'), objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_teach', null)
    setValueRadio('date_teach', null)
  }
  const resetDateRegistration = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_registration: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_registration' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))

    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl || 'candidates'), objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_registration', null)
    setValueRadio('date_registration', null)
  }
  const resetDateRespond = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_respond: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_respond' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))

    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl|| 'candidates'), objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_respond', null)
  }

  const resetDateLidRespond = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_lid_respond: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_lid_respond' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl|| 'candidates'), objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_lid_respond', null)
  }

  const resetInterviewOffline = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_interview_offline: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_interview_offline' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_interview_offline', null)
  }

  const resetUpdated = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, updated_at: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'updated_at' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('updated_at', null)
  }

  const resetDate = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date', null)
    setValueRadio('date', null)
  }

  const resetDateInterview = () => {
    const values = getValues()
    const valuesWithoutDate = { ...values, date_interview: null }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === 'date_interview' ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue('date_interview', null)
    setValueRadio('date_interview', null)
  }
  const resetArrayByKey = (key: string) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: [] }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem((targetUrl || 'candidates'), objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue(key, [])
  }
  const resetValueByKey = (key: string, value: unknown) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: value }
    const currentFormValuesUpdate = currentFormValues.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${targetUrl || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${targetUrl || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(targetUrl, objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({
      type: targetUrl || 'candidates',
      filter: valuesWithoutDate,
      defFilterString: objectToQueryString(valuesWithoutDate, targetUrl === 'event-funnel')
    }))
    setValue(key, value)
  }

  useEffect(() => {
    getStageState()
      .then((responseData) => {
        setStatusStageOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })

    dispatch(
      getDictionaries([
        'stageStatus',
        'gender',
        'appendType',
        'currency',
        'schedule',
        'WorkSites',
        'rejectionReason',
        'candidateStatus',
        'yes_no',
        'citizenship',
        'priority',
        'transferTime',
        'educationLevel',
        'region',
        'employment',
        'typeRegistrationWork',
        'vacancyStatus',
        'statusResult',
        'interviewResult',
        'group',
        'stageState',
        'candidateCharacteristic',
        'office'
      ])
    )
  }, [])

  useEffect(() => {
    reset(defaultValues)
  }, [targetUrl, targetUrl, defaultValues])

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  useEffect(() => {
    if (localStorage.getItem(`${targetUrl || 'candidates'}-labels`)) {
      dispatch(setFiltersLabels(JSON?.parse(localStorage.getItem(`${targetUrl || 'candidates'}-labels`))))
    }
  }, [targetUrl])

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={handleCloseFilters}>
              <CloseMD />
            </ControlButton>
          </div>

          {(vacancyPage || candidate) && (
            <>
              <div className={`${mainCssClass}_items_content-short`}>
                <SwitchFormControl
                  control={control}
                  name="is_validate_document"
                />
                <p>Документы проверены</p>
              </div>
              <div className={`${mainCssClass}_items_content-short`}>
                <SwitchFormControl
                  control={control}
                  name="responded"
                />
                <p>Показывать необработанных кандидатов</p>
              </div>
              {/*
              {watch('responded')

                  && (
                    <div className={`${mainCssClass}_items`}>
                      <div className={`${mainCssClass}_items_title`}>
                        <div className={`${mainCssClass}_title_redo`}>
                          <Typography.Title level={4}>Группы отклика</Typography.Title>
                          {watch('respond_groups') && watch('respond_groups').length > 0 && <Redo onClick={() => setValue('respond_groups', [])} tooltip />}
                        </div>
                        <ControlButton onClick={() => handldeOpenModal('respondGroups')}>
                          {allModal.respondGroups ? <CaretUpMD />
                            : <CaretDownMD />}
                        </ControlButton>

                      </div>
                      {allModal.respondGroups && (
                        <div className={`${mainCssClass}_items_content`}>
                          <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                            <Input
                              prefix={<Search />}
                              className={`${mainCssClass}_items_content_search`}
                              value={respondGroupsInput}
                              onChange={handleRespondGroupInput}
                            />
                            <CheckBoxFormControl
                              options={filteredOptions(groupOptions, respondGroupsInput)}
                              control={control}
                              name="respond_groups"
                            />
                          </NewScrollContainer>
                        </div>
                      )}
                    </div>
                  )} */}

              {vacancyPage
                    && (
                      <div className={`${mainCssClass}_items_content-short`}>
                        <SwitchFormControl
                          control={control}
                          name="candidateReject"
                        />
                        <p>Показывать отклоненных кандидатов</p>
                      </div>
                    )}

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Вакансия
                      {watch('vacancy_id') && watch('vacancy_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('vacancy_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('vacancy_id') && watch('vacancy_id').length > 0
                          && <Redo onClick={() => resetArrayByKey('vacancy_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('vacancyCandidate')}>
                    {allModal.vacancyCandidate ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.vacancyCandidate && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="vacancy_id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Вакансия"
                      fetchOptions={fetchVacancyList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Вид оформления
                      {watch('type_registration') && watch('type_registration').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('type_registration').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>

                    {watch('type_registration') && watch('type_registration').length > 0 && (
                      <Redo onClick={() => resetArrayByKey('type_registration')} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('registrType')}>
                    {allModal.registrType ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.registrType && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={registrTypeInput}
                        onChange={handleRegistrTypeInput}
                      />

                      <CheckBoxFormControl
                        options={filteredOptions(registrOptions, registrTypeInput)}
                        control={control}
                        name="type_registration"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Город
                      {watch('city') && watch('city').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('city').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>

                    {watch('city') && watch('city').length > 0
                          && <Redo onClick={() => resetArrayByKey('city')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('cities')}>
                    {allModal.cities ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.cities && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="city"
                      control={control}
                      mode="multiple"
                      label="Город"
                      placeholder="Поиск по наименованию..."
                      fetchOptions={fetchCitiesList}
                      notFoundContent="Начните вводить город"
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Гражданство
                      {watch('citizenship') && watch('citizenship').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('citizenship').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('citizenship') && watch('citizenship').length > 0
                          && <Redo onClick={() => resetValueByKey('citizenship', [])} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('citizenship')}>
                    {allModal.citizenship ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.citizenship && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>

                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={citizenshipInput}
                        onChange={handleCitizenshipInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(citizenshipOptions, citizenshipInput)}
                        control={control}
                        name="citizenship"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Группы
                      {watch('group') && watch('group').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('group').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('group') && watch('group').length > 0
                          && <Redo onClick={() => resetArrayByKey('group')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('group')}>
                    {allModal.group ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>

                </div>
                {allModal.group && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={groupInput}
                        onChange={handleGroupInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(groupOptions, groupInput)}
                        control={control}
                        name="group"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Дарксторы и Вайтсторы
                      {watch('whitedarkstore') && watch('whitedarkstore').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('whitedarkstore').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('whitedarkstore') && watch('whitedarkstore')?.length > 0
                          && <Redo onClick={() => resetArrayByKey('whitedarkstore')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('whitedarkstore')}>
                    {allModal.whitedarkstore ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.whitedarkstore && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="whitedarkstore"
                      control={control}
                      mode="multiple"
                      label="Дарксторы и Вайтсторы"
                      placeholder="Поиск по имени..."
                      fetchOptions={fetchClientList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Дубли
                      {watch('is_duplicates') && watch('is_duplicates').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('is_duplicates').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('is_duplicates')
                          && <Redo onClick={() => resetValueByKey('is_duplicates', false)} tooltip />}
                  </div>

                  <ControlButton onClick={() => handldeOpenModal('duplicate')}>
                    {allModal.duplicate ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.duplicate && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <SwitchFormControl
                      control={control}
                      name="is_duplicates"
                    />
                    <p>Показывать только дубли</p>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Заказчик
                      {watch('customer') && watch('customer').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('customer').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('customer') && watch('customer')?.length > 0
                          && <Redo onClick={() => resetArrayByKey('customer')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('client')}>
                    {allModal.client ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.client && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="customer"
                      control={control}
                      mode="multiple"
                      label="Заказчик"
                      placeholder="Поиск по имени..."
                      fetchOptions={fetchClientList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Заявка
                      {watch('request_id') && watch('request_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('request_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('request_id') && watch('request_id').length > 0
                          && <Redo onClick={() => resetArrayByKey('request_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('requestContent')}>
                    {allModal.requestContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.requestContent && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="request_id"
                      control={control}
                      placeholder="Поиск по наименованию..."
                      mode="multiple"
                      label="Заявка"
                      fetchOptions={fetchRequestList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Источник
                      {watch('leadgen_source_id') && watch('leadgen_source_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('leadgen_source_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('leadgen_source_id') && watch('leadgen_source_id').length > 0 && (
                      <Redo onClick={() => resetArrayByKey('leadgen_source_id')} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('workSites')}>
                    {allModal.workSites ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.workSites && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={workSitesInput}
                        onChange={handleWorkSitesInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(workSitesStatusOptions, workSitesInput)}
                        control={control}
                        name="leadgen_source_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              {!blockedResponsibleOrRecruiter && (
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>
                        Ответственный рекрутер
                        {watch('responsible') && watch('responsible').length >= 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('responsible').length}
                            )
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography.Title>
                      {watch('responsible') && watch('responsible').length > 0
                              && <Redo onClick={() => resetArrayByKey('responsible')} tooltip />}
                    </div>
                    <ControlButton onClick={() => handldeOpenModal('recruiter')}>
                      {allModal.recruiter ? <CaretUpMD />
                        : <CaretDownMD />}
                    </ControlButton>
                  </div>

                  {allModal.recruiter && (
                    <div className={`${mainCssClass}_items_content-short`}>
                      <DebounceSelectFormControlMult
                        name="responsible"
                        control={control}
                        mode="multiple"
                        label="Рекрутер"
                        placeholder="Поиск по имени..."
                        notFoundContent="Начните вводить имя"
                        fetchOptions={fetchRecruiterList}
                      />
                    </div>
                  )}
                </div>
              )}

              {!blockedResponsibleOrRecruiter && (
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>
                        Ответственный ресечер
                        {watch('researcher') && watch('researcher').length >= 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('researcher').length}
                            )
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography.Title>
                      {watch('researcher') && watch('researcher').length > 0
                              && <Redo onClick={() => resetArrayByKey('researcher')} tooltip />}
                    </div>
                    <ControlButton onClick={() => handldeOpenModal('researcher')}>
                      {allModal.researcher ? <CaretUpMD />
                        : <CaretDownMD />}
                    </ControlButton>
                  </div>

                  {allModal.researcher && (
                    <div className={`${mainCssClass}_items_content-short`}>
                      <DebounceSelectFormControlMult
                        name="researcher"
                        notFoundContent="Начните вводить имя"
                        control={control}
                        mode="multiple"
                        placeholder="Поиск по имени..."
                        label="Ресечер"
                        fetchOptions={fetchResearcherList}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Офисы
                      {watch('office') && watch('office').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('office').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('office') && watch('office').length > 0
                          && <Redo onClick={() => resetArrayByKey('office')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('officeContent')}>
                    {allModal.officeContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.officeContent && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>

                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={officeInput}
                        onChange={handleOfficeInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(officeOptions, officeInput)}
                        control={control}
                        name="office"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              {userInfo?.department === 176
                    && (
                      <div className={`${mainCssClass}_items`}>
                        <div className={`${mainCssClass}_items_title`}>
                          <div className={`${mainCssClass}_title_redo`}>
                            <Typography.Title level={4}>
                              По дате оффлайн собеседования
                              {watch('date_interview_offline') && watch('date_interview_offline').length >= 1 ? (
                                <span className={`${mainCssClass}_count-in-brackets`}>
                                  (1)
                                </span>
                              ) : (
                                ''
                              )}
                            </Typography.Title>
                            {watch('date_interview_offline') && watch('date_interview_offline')?.length > 0 && (
                              <Redo onClick={resetInterviewOffline} tooltip />
                            )}
                          </div>
                          <ControlButton onClick={() => handldeOpenModal('interviewOffline')}>
                            {allModal.interviewOffline ? <CaretUpMD />
                              : <CaretDownMD />}
                          </ControlButton>
                        </div>
                        {allModal.interviewOffline && (
                          <div className={`${mainCssClass}_items_content-short`}>
                            <RangePickerFormControl
                              control={control}
                              name="date_interview_offline"
                              format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                              label="Выберите даты"
                              className={`${mainCssClass}_items_content_range`}
                            />
                          </div>
                        )}
                      </div>
                    )}

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате добавления</Typography.Title>
                    {watch('created_at') && watch('created_at')?.length > 0 && (
                      <Redo onClick={resetDateBegin} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('begin')}>
                    {allModal.begin ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.begin && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="created_at"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате интервью</Typography.Title>
                    {watch('date_interview') && watch('date_interview')?.length > 0 && (
                      <Redo onClick={resetDateInterview} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('dateInterview')}>
                    {allModal.dateInterview ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.dateInterview && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_interview"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате обновления</Typography.Title>
                    {watch('updated_at') && watch('updated_at')?.length > 0 && (
                      <Redo onClick={resetUpdated} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('updatedContent')}>
                    {allModal.updatedContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.updatedContent && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="updated_at"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате обучения</Typography.Title>
                    {watch('date_teach') && watch('date_teach')?.length > 0 && (
                      <Redo onClick={resetDateTeach} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('teach')}>
                    {allModal.teach ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.teach && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_teach"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате отклика (HH)</Typography.Title>
                    {watch('date_respond') && watch('date_respond')?.length > 0 && (
                      <Redo onClick={resetDateRespond} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('respond')}>
                    {allModal.respond ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.respond && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_respond"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате отклика (лидген)</Typography.Title>
                    {watch('date_lid_respond') && watch('date_lid_respond')?.length > 0 && (
                      <Redo onClick={resetDateLidRespond} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('lidRespondContent')}>
                    {allModal.lidRespondContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.lidRespondContent && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_lid_respond"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате оформления</Typography.Title>
                    {watch('date_registration') && watch('date_registration')?.length > 0 && (
                      <Redo onClick={resetDateRegistration} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('sub')}>
                    {allModal.sub ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.sub && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_registration"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>По дате стажировки</Typography.Title>
                    {watch('date_internship') && watch('date_internship')?.length > 0 && (
                      <Redo onClick={resetDateInternship} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('intership')}>
                    {allModal.intership ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.intership && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date_internship"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Причины отказа
                      {watch('reject_id') && watch('reject_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('reject_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('reject_id') && watch('reject_id').length > 0
                          && <Redo onClick={() => resetArrayByKey('reject_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('rejReas')}>
                    {allModal.rejReas ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.rejReas && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={rejReasInput}
                        onChange={handleRejReasInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(reasonOptions, rejReasInput)}
                        control={control}
                        name="reject_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Статус кандидата
                      {watch('status') && watch('status').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('status').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('status') && watch('status').length > 0
                          && <Redo onClick={() => resetArrayByKey('status')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('statusCandidate')}>
                    {allModal.statusCandidate ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.statusCandidate && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={statusCandidateInput}
                        onChange={handleStatusCandidateInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(candidateStatusOptions, statusCandidateInput)}
                        control={control}
                        name="status"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Cтатус на этапе
                      {watch('stage_state_id') && watch('stage_state_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('stage_state_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('stage_state_id') && watch('stage_state_id').length > 0
                          && <Redo onClick={() => resetArrayByKey('stage_state_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('statusStage')}>
                    {allModal.statusStage ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.statusStage && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={statusStageInput}
                        onChange={handleStatusStageInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(statusStageOptions, statusStageInput)}
                        control={control}
                        name="stage_state_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Тэги
                      {watch('tag') && watch('tag').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('tag').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('tag') && watch('tag').length > 0
                          && <Redo onClick={() => resetArrayByKey('tag')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('tag')}>
                    {allModal.tag ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.tag && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="tag"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Тэг"
                      fetchOptions={fetchTagsList}
                      notFoundContent="Начните вводить тэг"
                    />
                  </div>
                )}
              </div>

              {candidate && (
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>
                        Этап
                        {watch('stage_id') && watch('stage_id').length >= 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('stage_id').length}
                            )
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography.Title>
                      {watch('stage_id') && watch('stage_id').length > 0
                              && <Redo onClick={() => resetArrayByKey('stage_id')} tooltip />}
                    </div>
                    <ControlButton onClick={() => handldeOpenModal('stage')}>
                      {allModal.stage ? <CaretUpMD />
                        : <CaretDownMD />}
                    </ControlButton>
                  </div>

                  {allModal.stage && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={stageInput}
                          onChange={handleStageInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(stageStatusOptions, stageInput)}
                          control={control}
                          name="stage_id"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
              )}

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Webmaster id
                    </Typography.Title>

                    {watch('webmaster_id') && watch('webmaster_id').length > 0
                          && <Redo onClick={() => resetValueByKey('webmaster_id', '')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('webmasterContent')}>
                    {allModal.webmasterContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.webmasterContent && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <InputFormControl
                      name="webmaster_id"
                      control={control}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {request && (
            <>
              <div className={`${mainCssClass}_items_content-short`}>
                <SwitchFormControl
                  control={control}
                  name="without_store"
                />
                <p>Показать заявки под кандидата</p>
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Вакансия
                      {watch('vacancy_id') && watch('vacancy_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('vacancy_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('vacancy_id') && watch('vacancy_id')?.length > 0 && <Redo onClick={() => resetArrayByKey('vacancy_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('vacancyContent')}>
                    {allModal.vacancyContent ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.vacancyContent && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="vacancy_id"
                      control={control}
                      notFoundContent="Начните вводить название вакансии"
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Вакансия"
                      fetchOptions={fetchVacancyList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Город заявки
                      {watch('request_city') && watch('request_city').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('request_city').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>

                    {watch('request_city') && watch('request_city').length > 0 && <Redo onClick={() => resetArrayByKey('request_city')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('requestCity')}>
                    {allModal.requestCity ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.requestCity && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="request_city"
                      control={control}
                      mode="multiple"
                      label="Город"
                      placeholder="Поиск по наименованию..."
                      fetchOptions={fetchCitiesList}
                      notFoundContent="Начните вводить город"
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Группы
                      {watch('group') && watch('group').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('group').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('group') && watch('group').length > 0 && <Redo onClick={() => resetArrayByKey('group')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('group')}>
                    {allModal.group ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.group && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={groupInput}
                        onChange={handleGroupInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(groupOptions, groupInput)}
                        control={control}
                        name="group"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Дарксторы и Вайтсторы
                      {watch('whitedarkstore') && watch('whitedarkstore').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('whitedarkstore').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('whitedarkstore') && watch('whitedarkstore')?.length > 0
                          && <Redo onClick={() => resetArrayByKey('whitedarkstore')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('whitedarkstore')}>
                    {allModal.whitedarkstore ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.whitedarkstore && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="whitedarkstore"
                      control={control}
                      mode="multiple"
                      label="Дарксторы и Вайтсторы"
                      placeholder="Поиск по имени..."
                      fetchOptions={fetchClientList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Заказчик
                      {watch('customer') && watch('customer').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('customer').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('customer') && watch('customer')?.length > 0 && <Redo onClick={() => resetArrayByKey('customer')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('client')}>
                    {allModal.client ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.client && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="customer"
                      control={control}
                      mode="multiple"
                      label="Заказчик"
                      placeholder="Поиск по имени..."
                      fetchOptions={fetchClientList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Объект
                      {watch('store_id') && watch('store_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('store_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('store_id') && watch('store_id')?.length > 0 && <Redo onClick={() => resetArrayByKey('store_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('store')}>
                    {allModal.store ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.store && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="store_id"
                      placeholder="Поиск по наименованию..."
                      control={control}
                      mode="multiple"
                      notFoundContent="Начните вводить название объекта"
                      label="Объект"
                      fetchOptions={fetchStoreList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Ответственный
                      {watch('responsible') && watch('responsible').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('responsible').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('responsible') && watch('responsible')?.length > 0 && (
                      <Redo onClick={() => resetArrayByKey('responsible')} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('responsible')}>
                    {allModal.responsible ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.responsible && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <DebounceSelectFormControlMult
                      name="responsible"
                      control={control}
                      mode="multiple"
                      notFoundContent="Начните вводить имя"
                      label="Ответственный"
                      fetchOptions={fetchResponsibleList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Приоритет
                      {watch('priority_id') && watch('priority_id').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('priority_id').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('priority_id') && watch('priority_id')?.length > 0 && <Redo onClick={() => resetArrayByKey('priority_id')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('priority')}>
                    {allModal.priority ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.priority && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={priorityInput}
                        onChange={handlePriorityInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(priorityOptions, priorityInput)}
                        control={control}
                        name="priority_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

            </>
          )}
          {vacancy && (
            <>
              <div className={`${mainCssClass}_items_content-short`}>
                <InputSearchFormControl name="vacancy_id_search" control={control} placeholder="Введите id вакансии" />
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Группы
                      {watch('group') && watch('group').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('group').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('group') && watch('group').length > 0 && <Redo onClick={() => resetArrayByKey('group')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('group')}>
                    {allModal.group ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>

                {allModal.group && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={groupInput}
                        onChange={handleGroupInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(groupOptions, groupInput)}
                        control={control}
                        name="group"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Статус
                      {watch('status') && watch('status').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('status').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('status') && watch('status').length > 0 && <Redo onClick={() => resetArrayByKey('status')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('statusVacancy')}>
                    {allModal.statusVacancy ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.statusVacancy && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={statusVacancyInput}
                        onChange={handleStatusVacancyInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(vacancyStatusOptions, statusVacancyInput)}
                        control={control}
                        name="status"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

            </>
          )}

          {interview && (
            <>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Время проведения
                      {watch('time') && watch('time').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          1
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('time') && watch('time').length > 0 && <Redo onClick={() => resetArrayByKey('time')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('time')}>
                    {allModal.group ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.time && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={timeInput}
                        onChange={handleTimeInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(timeOptions, timeInput)}
                        control={control}
                        name="time"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Группы
                      {watch('group') && watch('group').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('group').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('group') && watch('group').length > 0 && <Redo onClick={() => resetArrayByKey('group')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('group')}>
                    {allModal.group ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.group && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={groupInput}
                        onChange={handleGroupInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(groupOptions, groupInput)}
                        control={control}
                        name="group"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>Дата проведения</Typography.Title>
                    {watch('date') && watch('date')?.length > 0 && <Redo onClick={resetDate} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('date')}>
                    {allModal.date ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.date && (
                  <div className={`${mainCssClass}_items_content-short`}>
                    <RangePickerFormControl
                      control={control}
                      name="date"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>Подтверждение</Typography.Title>
                    {watch('confirm') && watch('confirm').length > 0 && <Redo onClick={() => resetArrayByKey('confirm')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('confirm')}>
                    {allModal.confirm ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.confirm && (
                  <div className={`${mainCssClass}_items_content`}>
                    <RadioGroupFormControl options={confirmOptions} control={control} name="confirm" />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Результат
                      {watch('result') && watch('result').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('result').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('result') && watch('result').length > 0 && <Redo onClick={() => resetArrayByKey('result')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('interviewResult')}>
                    {allModal.interviewResult ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.interviewResult && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={interviewResultInput}
                        onChange={handleInterviewResultInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(interviewResultOptions, interviewResultInput)}
                        control={control}
                        name="result"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              {!blockedResponsibleOrRecruiterInterview && (
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>
                        Рекрутер
                        {watch('recruiter') && watch('recruiter').length >= 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('recruiter').length}
                            )
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography.Title>
                      {watch('recruiter') && watch('recruiter').length > 0 && <Redo onClick={() => resetArrayByKey('recruiter')} tooltip />}
                    </div>
                    <ControlButton onClick={() => handldeOpenModal('recriuterContent')}>
                      {allModal.recriuterContent ? <CaretUpMD />
                        : <CaretDownMD />}
                    </ControlButton>
                  </div>

                  {allModal.recriuterContent && (
                    <div className={`${mainCssClass}_items_content-short`}>
                      <DebounceSelectFormControlMult
                        name="recruiter"
                        control={control}
                        mode="multiple"
                        placeholder="Поиск по имени..."
                        notFoundContent="Начните вводить имя"
                        label="Рекрутер"
                        fetchOptions={fetchRecruiterList}
                      />
                    </div>
                  )}
                </div>
              )}

              {!blockedResponsibleOrRecruiterInterview && (
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>
                        Ресечер
                        {watch('research') && watch('research').length >= 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('research').length}
                            )
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography.Title>
                      {watch('research') && watch('research').length > 0 && <Redo onClick={() => resetArrayByKey('research')} tooltip />}
                    </div>
                    <ControlButton onClick={() => handldeOpenModal('researchContent')}>
                      {allModal.researchContent ? <CaretUpMD />
                        : <CaretDownMD />}
                    </ControlButton>
                  </div>
                  {allModal.researchContent && (
                    <div className={`${mainCssClass}_items_content-short`}>
                      <DebounceSelectFormControlMult
                        name="research"
                        control={control}
                        mode="multiple"
                        placeholder="Поиск по имени..."
                        notFoundContent="Начните вводить имя"
                        label="Ресечер"
                        fetchOptions={fetchRecruiterList}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Статус
                      {watch('status') && watch('status').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('status').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('status') && watch('status').length > 0 && <Redo onClick={() => resetArrayByKey('status')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('interviewStatus')}>
                    {allModal.interviewStatus ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.interviewStatus && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={interviewStatusInput}
                        onChange={handleInterviewStatusInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(interviewStatusOptions, interviewStatusInput)}
                        control={control}
                        name="status"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Характеристика
                      {watch('characteristic') && watch('characteristic').length >= 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                          (
                          {watch('characteristic').length}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Title>
                    {watch('characteristic') && watch('characteristic').length > 0 && <Redo onClick={() => resetArrayByKey('characteristic')} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('characteristic')}>
                    {allModal.characteristic ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.characteristic && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={characteristicInput}
                        onChange={handleCharacteristicInput}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(characteristicOptions, characteristicInput)}
                        control={control}
                        name="characteristic"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>
            </>
          )}

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>
        </div>
      </NewScrollContainer>
    </div>
  )
}
export default memo<TFiltersProps>(withErrorBoundary<TFiltersProps>(Filters))
