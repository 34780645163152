import React, { memo, useEffect } from 'react'
import { ModalProps, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

import { getEmployeesCenter, setOpenEmployeesCenter } from '../actions'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { selectEmployeesCenterLoading, selectEmployeesTableData } from '../selectors'
import EmployeesTable from './employessTable'
import DoughnutChart from './StatusesEmployeesDiagram'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import './employeesModal.scss'
import HorizontalBarChart from './StatusesEmployeesBars'

const mainCssClass = 'employees-center'
function EmployeesCenter({
  open, className
}:ModalProps) {
  const dispatch = useDispatch()
  const loading = useSelector(selectEmployeesCenterLoading)
  const employeesData = useSelector(selectEmployeesTableData)

  const handleOnCancel = () => {
    dispatch(setOpenEmployeesCenter(false))
  }

  useEffect(() => {
    dispatch(getEmployeesCenter())
  }, [])

  return (
    <CommonModal
      open={open}
      onCancel={handleOnCancel}
      closable
      className={cn(className, mainCssClass)}
      wrapClassName="wrapModal"
      width="1000px"
      footer={null}
      title="Сотрудники"
    >
      {loading && <Preloader size="md" />}
      {!loading && (
        <div className={`${mainCssClass}__content`}>
          <div className={`${mainCssClass}__container-charts`}>
            <div className={`${mainCssClass}__container-diagram`}>
              <div className={`${mainCssClass}__title`}>
                <Typography.Title level={4}>Статусы сотрудников</Typography.Title>
              </div>
              <DoughnutChart />
            </div>
            <div className={`${mainCssClass}__container-bars`}>
              <div className={`${mainCssClass}__title`}>
                <Typography.Title level={4}>Чем заняты сотрудники</Typography.Title>
              </div>
              <HorizontalBarChart />
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div className={`${mainCssClass}__container-table`}>
              <div className={`${mainCssClass}__title`}>
                <Typography.Title level={4}>Контроль сотрудников</Typography.Title>
                {loading && <Preloader size="md" />}
              </div>
              <div className={`${mainCssClass}__table`}>
                <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
                  <EmployeesTable employeesData={employeesData} />
                </NewScrollContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(EmployeesCenter))
