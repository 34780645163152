import { createReducer } from '@reduxjs/toolkit'
import {
  setVacancies,
  setLoading,
  setDictionaries,
  setOpenModalChangeStatus,
  setDuplicateStatusOpen,
  setSelectedVacancyId,
  setSelectedVacancy,
  setOpenModalEditVacancy,
  reset,
  setDefDataVacancy,
  setHeaders,
  resetAllVacancy
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'
import { TResponseVacancyData, TVacancy } from '../../common/api/vacancy'

export type TDefDataVacancy = {
  page:number,
  perPage: number,
  filters: string,
  search: string
  status: string
} | null

type TManagementVacanciesState = {
  vacancies: null | TResponseVacancyData
  dictionaries: TDictionaries | null
  loading: boolean
  changeStatus: boolean
  duplicateStatus: boolean
  selectedVacancyId: number | null
  vacancy: TVacancy | null
  editVacancyModal: boolean
  defString: string
  defDataVacancy: TDefDataVacancy
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
}

const initialState: TManagementVacanciesState = {
  vacancies: null,
  dictionaries: null,
  loading: false,
  changeStatus: false,
  duplicateStatus: false,
  selectedVacancyId: null,
  vacancy: null,
  editVacancyModal: false,
  defString: '',
  defDataVacancy: null,
  headers: null
}

const managementVacanciesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setVacancies, (state, { payload }) => {
      state.vacancies = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setOpenModalChangeStatus, (state, { payload }) => {
      state.changeStatus = payload
    })
    .addCase(setDuplicateStatusOpen, (state, { payload }) => {
      state.duplicateStatus = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setSelectedVacancyId, (state, { payload }) => {
      state.selectedVacancyId = payload
    })
    .addCase(setSelectedVacancy, (state, { payload }) => {
      state.vacancy = payload
    })
    .addCase(setOpenModalEditVacancy, (state, { payload }) => {
      state.editVacancyModal = payload
    })
    .addCase(reset, (state) => ({
      ...initialState,
      defDataVacancy: state.defDataVacancy
    }))
    .addCase(setDefDataVacancy, (state, { payload }) => {
      state.defDataVacancy = payload
    })
    .addCase(resetAllVacancy, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
})

export default managementVacanciesReducer
