import { createAction } from '@reduxjs/toolkit'
import { TRequest, TResponseRequestData } from '../../common/api/request'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TPaginationRequest } from '../../common/types/pagination'

export const getRequests = createAction<TPaginationRequest>('managementRequests/getRequests')
export const setRequests = createAction<TResponseRequestData | null>('managementRequests/setRequests')

export const getDictionaries = createAction<TDictionariesNames>('managementRequests/dictionaries/getDictionaries')
export const getCurators = createAction('managementRequests/getCurators')
export const setCurators = createAction<any>('managementRequests/setCurators')
export const setDictionaries = createAction<TDictionaries>('managementRequests/dictionaries/setDictionaries')

export const setOpenModalEditRequest = createAction<boolean>('managementRequests/setOpenModalEditRequest')
export const setOpenModalArchiveRequest = createAction<boolean>('managementRequests/setOpenModalArchiveRequest')
export const setSelectedRequestId = createAction<number | null>('managementRequests/setSelectedRequestId')

export const setOpenModalSure = createAction<boolean>('managementRequests/setOpenModalSure')

export const getSelectedRequest = createAction<number|string>('managementRequests/getSelectedRequest')
export const setSelectedRequest = createAction<TRequest | null>('managementRequests/setSelectedRequest')
export const setLoading = createAction<boolean>('managementRequests/setLoading')
export const reset = createAction('managementRequests/reset')

export const archiveOpen = createAction<boolean>('managementRequests/archiveOpen')

export type TRequestRequestsCSV = {
    has_head: boolean
    filter:string
  }
export const exportCSV = createAction<TRequestRequestsCSV>('managementRequests/exportCSV')

export type TDefDataRequest = {
  page: number,
  perPage: number,
  filters: string,
  search:string,
  status:string
}

export const setDefDataRequest = createAction<TDefDataRequest>('managementRequests/setDefDataRequest')

export const resetAllRequest = createAction('managementRequests/resetAllRequest')

export type TRequestHeaders = {
current:number,
total:number,
totalCount: number
}

export const setHeaders = createAction<TRequestHeaders>('managementRequests/setHeaders')

