/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ innerRouter }: TRootState) => innerRouter

export const selectBaseUrl = createSelector(selectState, ({ baseUrl }) => baseUrl)

export const selectSubUrl = createSelector(selectState, ({ subUrl }) => subUrl)

export const selectNavigateToUrl = createSelector(selectState, ({ navigateToUrl }) => navigateToUrl)

export const selectUserInfo = createSelector(selectState, ({ userInfo }) => userInfo)

export const selectRbacModel = createSelector(selectState, ({ rbacInfo }) => rbacInfo)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectPreviousPage = createSelector(selectState, ({ previousPage }) => previousPage)
export const selectVisibleTable = createSelector(selectState, ({ visibleTable }) => visibleTable)

export const selectInnerMenuMobil = createSelector(selectState, ({ openMobilMenu }) => openMobilMenu)

export const selectOpenSettingsCalendar = createSelector(selectState, ({ openSettings }) => openSettings)

export const selectDestinationNumber = createSelector(selectState, ({ destinationNumber }) => destinationNumber)
export const selectNeedPauseCall = createSelector(selectState, ({ pauseCall }) => pauseCall)
export const selectNeedResumeCall = createSelector(selectState, ({ resumeCall }) => resumeCall)
export const selectNeedHangUpCall = createSelector(selectState, ({ hangUpCall }) => hangUpCall)
export const selectNeedTransferCall = createSelector(selectState, ({ transferCall }) => transferCall)

export const selectCandidateSelected = createSelector(selectState, ({ candidate }) => candidate)

export const selectSettingWebRTC = createSelector(selectState, ({ settingsWebRTC }) => settingsWebRTC)

export const selectLoadingModal = createSelector(selectState, ({ loadingModal }) => loadingModal)

export const selectSystemColor = createSelector(selectState, ({ userInfo }) => userInfo?.color_system || 'blue')
export const selectOpenRefreshModalByTime = createSelector(selectState, ({ openModalRefreshByTime }) => openModalRefreshByTime)
export const selectOpenRefreshModalByVersion = createSelector(selectState, ({ openModalFrontRefreshByVersion }) => openModalFrontRefreshByVersion)
export const selectVersionFront = createSelector(selectState, ({ versionFront }) => versionFront)
export const selectStatusesMobiles = createSelector(selectState, ({ statusesMobiles }) => statusesMobiles)
export const selectCurrentUserStatusMobile = createSelector(selectState, ({ currentStatusMobile }) => currentStatusMobile)
export const selectFullVersion = createSelector(selectState, ({ fullVersion }) => fullVersion)
export const selectPhoneModal = createSelector(selectState, ({ phoneModal }) => phoneModal)
export const selectQueueModal = createSelector(selectState, ({ queueModal }) => queueModal)
export const selectStatusPhone = createSelector(selectState, ({ statusPhone }) => statusPhone)
export const selectInternalInformationByUserId = createSelector(selectState, ({ internalInformationByUserId }) => internalInformationByUserId)
export const selectModalAutoCallId = createSelector(selectState, ({ modalAutoCallId }) => modalAutoCallId)
export const selectModalAutoCallOpen = createSelector(selectState, ({ modalAutoCallOpen }) => modalAutoCallOpen)
export const selectModalAutoCallOptions = createSelector(selectState, ({ optionsAutoCall }) => optionsAutoCall)
export const selectLoadingAutoCall = createSelector(selectState, ({ loadingAutoCall }) => loadingAutoCall)

export const selectIsFirstLogin = createSelector(selectState, ({ isFirstLogin }) => isFirstLogin)
export const selectLoadingProgress = createSelector(selectState, ({ loadingProgress }) => loadingProgress)
export const selectConnectedAsterisk = createSelector(selectState, ({ connectedAsterisk }) => connectedAsterisk)

export const selectUserActivity = createSelector(selectState, ({ userActivity }) => userActivity)
export const selectMessageWhatsApp = createSelector(selectState, ({ messageWhatsApp }) => messageWhatsApp)
export const selectLeadMessage = createSelector(selectState, ({ leadMessage }) => leadMessage)
export const selectCallMessage = createSelector(selectState, ({ callMessage }) => callMessage)
export const selectCurrentRtt = createSelector(selectState, ({ currentRtt }) => currentRtt)

export const selectEditKeyDetailList = createSelector(selectState, ({ editKeyDetailList }) => editKeyDetailList)

export const selectIconNumber = createSelector(selectState, ({ iconNumber }) => iconNumber)
