/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  setCandidate,
  setLoading,
  deleteCandidate,
  setDictionaries,
  delTagCandidate,
  reset,
  openArchiveModal,
  openCreateModal,
  openSureModal,
  openStatusModal,
  openUpdateModal,
  setEmptySlots,
  openVacancyModal,
  setQuestionnaire,
  setIsEditModeQuestionnaire,
  setNewInterview,
  openRecruiterModal,
  openReseacherModal,
  openStageStatusModal,
  setComments,
  setCommentsPage,
  setOpenModalMap,
  setHistoryCandidateData,
  openHistoryCandidateData,
  setTotalCommentsPage,
  setStageStatus,
  setChatVisible,
  setStageModal,
  setRejectModal,
  setAssignModal,
  setPhoneError,
  setEmailError,
  setInformationModal,
  setLoadingSlots,
  setSelectedComment,
  openDeleteCommentModal,
  openCommentModal,
  setHeaders,
  openDuplicateModal,
  setCandidateDuplicate,
  setRespondsCandidate,
  setIsLoadingRespondHandler,
  setOldHistoryCandidateData,
  uploudCommentFile,
  setCandidateMergeDuplicate,
  openMergeDuplicateModal,
  setHistoryVariable,
  setHistoryAsterisk,
  openTasksModal,
  showLimitationStageCandidateModal,
  setMessageLimitationCandidate,
  setCandidateCoordinates,
  setStageListing,
  setInnError,
  openResultInternshipModal,
  setCandidateDocument,
  setCandidateQuestionnaire,
  openCheckDocModal,
  setHistoryCandidateV2,
  openChatDrawer,
  openAsteriskDrawer,
  openCommunicationDrawer,
  openEditCandidateDrawer,
  setUpdateCandidateMode,
} from './actions'
import { TCandidateState } from './types'
import { openChatMobil } from '../ManagamentCandidates/actions'

const initialState: TCandidateState = {
  candidate: null,
  loading: false,
  dictionaries: null,
  archiveModal: false,
  createModal: false,
  resultInternshipModal: false,
  sureModal: false,
  statusModal: false,
  updateModal: false,
  vacancyModal: false,
  questionnaire: null,
  createQuestionnaire: false,
  isEditQuestionnaire: false,
  emptySlots: [],
  newInterview: false,
  recruiterModal: false,
  reseacherModal: false,
  stageStatusModal: false,
  comments: null,
  commentsPage: 1,
  commentFile: '',
  openModalMap: false,
  historyAction: [],
  oldHistoryAction: [],
  historyModal: false,
  totalCommentPage: null,
  openChat: false,
  stageStatus: null,
  chatVisible: 'message',
  historyVariable: 'oldNewhistory',
  stageModal: false,
  duplicateModal: false,
  assignModal: false,
  rejectModal: false,
  informationModal: false,
  phoneError: [],
  innError: [],
  emailError: [],
  loadingSlots: false,
  selectedComment: null,
  deleteCommentModal: false,
  commentModal: false,
  headers: null,
  duplicateList: [],
  mergeDuplicateList: [],
  responds: [],
  isLoadingRespondHandler: false,
  mergeDuplicateModal: false,
  asteriskHistory: [],
  tasksModal: false,
  openLimitationStageCandidateModal: false,
  messageLimitationCandidate: '',
  candidateCoordinates: null,
  stagesListing: [],
  document_list: [],
  candidateQuestionnaire: null,
  checkDocModal: false,
  candidateHistoryV2: [],
  chatDrawer: false,
  asteriskDrawer: false,
  communicationDrawer: false,
  editCandidateDrawer: false,
  updateCandidateMode: false
}

const candidateReducerV2 = createReducer(initialState, (builder) => {
  builder
    .addCase(setCandidate, (state, { payload }) => {
      state.candidate = payload
    })
    .addCase(setHistoryCandidateData, (state, { payload }) => {
      state.historyAction = payload
    })
    .addCase(setOldHistoryCandidateData, (state, { payload }) => {
      state.oldHistoryAction = payload
    })
    .addCase(openChatMobil, (state, { payload }) => {
      state.openChat = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(deleteCandidate, (state) => {
      state = { ...initialState }
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setIsLoadingRespondHandler, (state, { payload }) => {
      state.isLoadingRespondHandler = payload
    })
    .addCase(delTagCandidate, (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.candidate!.tag_list = state.candidate?.tag_list?.filter((el) => el.created_at !== payload.created_at)
    })
    .addCase(reset, () => initialState)
    .addCase(openArchiveModal, (state, { payload }) => {
      state.archiveModal = payload
    })
    .addCase(openCreateModal, (state, { payload }) => {
      state.createModal = payload
    })
    .addCase(openHistoryCandidateData, (state, { payload }) => {
      state.historyModal = payload
    })
    .addCase(openUpdateModal, (state, { payload }) => {
      state.updateModal = payload
    })
    .addCase(openSureModal, (state, { payload }) => {
      state.sureModal = payload
    })
    .addCase(setEmptySlots, (state, { payload }) => {
      state.emptySlots = payload
    })
    .addCase(setLoadingSlots, (state, { payload }) => {
      state.loadingSlots = payload
    })
    .addCase(setNewInterview, (state, { payload }) => {
      state.newInterview = payload
    })
    .addCase(openVacancyModal, (state, { payload }) => {
      state.vacancyModal = payload
    })
    .addCase(openStatusModal, (state, { payload }) => {
      state.statusModal = payload
    })
    .addCase(setQuestionnaire, (state, { payload }) => {
      state.questionnaire = payload
    })
    .addCase(setIsEditModeQuestionnaire, (state, { payload }) => {
      state.isEditQuestionnaire = payload
    })
    .addCase(openRecruiterModal, (state, { payload }) => {
      state.recruiterModal = payload
    })
    .addCase(openReseacherModal, (state, { payload }) => {
      state.reseacherModal = payload
    })
    .addCase(openDuplicateModal, (state, { payload }) => {
      state.duplicateModal = payload
    })
    .addCase(openStageStatusModal, (state, { payload }) => {
      state.stageStatusModal = payload
    })
    .addCase(setComments, (state, { payload }) => {
      state.comments = payload
    })
    .addCase(setStageStatus, (state, { payload }) => {
      state.stageStatus = payload
    })
    .addCase(setCommentsPage, (state, { payload }) => {
      state.commentsPage = payload
    })
    .addCase(uploudCommentFile, (state, { payload }) => {
      state.commentFile = payload
    })
    .addCase(setOpenModalMap, (state, { payload }) => {
      state.openModalMap = payload
    })
    .addCase(setTotalCommentsPage, (state, { payload }) => {
      state.totalCommentPage = payload
    })
    .addCase(setChatVisible, (state, { payload }) => {
      state.chatVisible = payload
    })
    .addCase(setHistoryVariable, (state, { payload }) => {
      state.historyVariable = payload
    })
    .addCase(setStageModal, (state, { payload }) => {
      state.stageModal = payload
    })
    .addCase(setRejectModal, (state, { payload }) => {
      state.rejectModal = payload
    })
    .addCase(setAssignModal, (state, { payload }) => {
      state.assignModal = payload
    })
    .addCase(setPhoneError, (state, { payload }) => {
      state.phoneError = payload
    })
    .addCase(setInnError, (state, { payload }) => {
      state.innError = payload
    })
    .addCase(setEmailError, (state, { payload }) => {
      state.emailError = payload
    })
    .addCase(setInformationModal, (state, { payload }) => {
      state.informationModal = payload
    })
    .addCase(setSelectedComment, (state, { payload }) => {
      state.selectedComment = payload
    })
    .addCase(openDeleteCommentModal, (state, { payload }) => {
      state.deleteCommentModal = payload
    })
    .addCase(openCommentModal, (state, { payload }) => {
      state.commentModal = payload
    })
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setCandidateDuplicate, (state, { payload }) => {
      state.duplicateList = payload
    })
    .addCase(setRespondsCandidate, (state, { payload }) => {
      state.responds = payload
    })
    .addCase(setCandidateMergeDuplicate, (state, { payload }) => {
      state.mergeDuplicateList = payload
    })
    .addCase(setStageListing, (state, { payload }) => {
      state.stagesListing = payload
    })
    .addCase(openMergeDuplicateModal, (state, { payload }) => {
      state.mergeDuplicateModal = payload
    })
    .addCase(setHistoryAsterisk, (state, { payload }) => {
      state.asteriskHistory = payload
    })
    .addCase(openTasksModal, (state, { payload }) => {
      state.tasksModal = payload
    })
    .addCase(showLimitationStageCandidateModal, (state, { payload }) => {
      state.openLimitationStageCandidateModal = payload
    })
    .addCase(setMessageLimitationCandidate, (state, { payload }) => {
      state.messageLimitationCandidate = payload
    })
    .addCase(setCandidateCoordinates, (state, { payload }) => {
      state.candidateCoordinates = payload
    })
    .addCase(openResultInternshipModal, (state, { payload }) => {
      state.resultInternshipModal = payload
    })
    .addCase(setCandidateDocument, (state, { payload }) => {
      state.document_list = payload
    })
    .addCase(setCandidateQuestionnaire, (state, { payload }) => {
      state.candidateQuestionnaire = payload
    })
    .addCase(openCheckDocModal, (state, { payload }) => {
      state.checkDocModal = payload
    })
    .addCase(setHistoryCandidateV2, (state, { payload }) => {
      state.candidateHistoryV2 = payload
    })
    .addCase(openChatDrawer, (state, { payload }) => {
      state.chatDrawer = payload
    })
    .addCase(openAsteriskDrawer, (state, { payload }) => {
      state.asteriskDrawer = payload
    })
    .addCase(openCommunicationDrawer, (state, { payload }) => {
      state.communicationDrawer = payload
    })
    .addCase(openEditCandidateDrawer, (state, { payload }) => {
      state.editCandidateDrawer = payload
    })
    .addCase(setUpdateCandidateMode, (state, { payload }) => {
      state.updateCandidateMode = payload
    })
})

export default candidateReducerV2
