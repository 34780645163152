import React, { memo, useCallback } from 'react'
import { Modal, ModalProps, Typography } from 'antd'
import cn from 'classnames'
import './SimpleModalCandidate.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateStatusCandidate } from '../../../features/candidateModals/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { removeFromRequest } from '../actions'
import { selectCandidate } from '../selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

type TProps = {
  candidateId?: number | null | string
  request_id?: number | string
  onOk: () => void
  isRequest?: boolean
} & ModalProps

function ModalRemoveFromArchive({
  onOk, open, className, onCancel, candidateId, request_id,isRequest
}: TProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)

  const handleSubmit = useCallback(() => {
    if (candidateId) {
      if (request_id) {
        dispatch(removeFromRequest({ candidate_id: candidateId, request_id, isRequest:!!isRequest }))
      }
      onOk()
    }
  }, [candidateId, isRequest])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      title="Отвязать заявку?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      centered
    >
      <Typography.Text>Вы собираетесь убрать кандидата с заявки. Подтвердить действие?</Typography.Text>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalRemoveFromArchive))
