import React from 'react'
import Icon from '../svgIcons/InfoSolid.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function InfoSolid(props: IconProps) {
  return (
    <IconWrapper {...props} name="InfoSolid">
      <Icon />
    </IconWrapper>
  )
}
