import {
  put, call, takeLatest, spawn, select
} from 'redux-saga/effects'
import { AxiosError } from 'axios'
import {
  getEmployeesCenter,
  setEmployeesStatusesBars,
  setEmployeesStatusesDiagram,
  setEmployeesTableData,
  setLoadingEmployeesCenter
} from './actions'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { fetchGetEmployeesData } from '../../common/api/employees'
import { setLoadingProgress } from '../../compositions/InnerRouter/actions'

const moduleName = 'Сотрудники'

function* getEmployeesCenterSaga() {
  try {
    yield put(setLoadingEmployeesCenter(true))
    yield put(setLoadingProgress(true))
    const response = yield fetchGetEmployeesData()
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить данные о сотрудниках',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setEmployeesTableData(response.data.DATA.users))
      yield put(setEmployeesStatusesBars(response.data.DATA.activities))
      yield put(setEmployeesStatusesDiagram(response.data.DATA.statuses))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingEmployeesCenter(false))
  }
}

function* employeesCenterSaga() {
  yield takeLatest(getEmployeesCenter, getEmployeesCenterSaga)
}

export default employeesCenterSaga
