import { TWorkloadFilter } from './components/WorkloadFilters'

export function removeFilterInterviewFragments(inputString: string) {
  const regex = /&filter\[or\]\[\d+\]\[(recruiter|research)\]=\d+/g

  return inputString.replace(regex, '')
}

export const defObject: any = {
  recruiterModal: false,
  groupModal: false
}

export function objectToQueryString(obj: TWorkloadFilter): string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&${key}=${strArrStr}`
      } else if (typeof obj[key][0] === 'number') {
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&${key}=${strArrStr}`
      }
    } else if (typeof obj[key] === 'string') {
      str += `&${key}=${obj[key]}`
    }
  }

  return str
}

export function splitArray(inputArray: string[], numberOfSubarrays = 3) {
  const result = [];
  const elementsPerSubarray = Math.ceil(inputArray.length / numberOfSubarrays);
  let startOfSubarray = 0;

  for (let i = 0; i < numberOfSubarrays; i++) {
    result.push(inputArray.slice(startOfSubarray, startOfSubarray + elementsPerSubarray));
    startOfSubarray += elementsPerSubarray;
  }
  return result;
}