import { createAction } from '@reduxjs/toolkit'
import { TGetAllEmptySlots } from '../../compositions/candidate/actions'

export type TCancelInterviewModals = {
    status: number
    id: string | number
}

export type TEditInterviewModals = {
    status: number
    id: string | number
}
export type TEndInterviewModals = {
    status: number
    id: string | number
}
export type THistoryInterviewModals = {
    status: number
    id: string | number
}
export const cancelInterviewModals = createAction<TCancelInterviewModals>('interviewModals/cancelInterviewModals')

export const editInterviewModals = createAction<TEditInterviewModals>('interviewModals/editInterviewModals')

export const endInterviewModals = createAction<TEndInterviewModals>('interviewModals/endInterviewModals')

export const historyInterviewModals = createAction<THistoryInterviewModals>('interviewModals/historyInterviewModals')

export const getEmptySlots = createAction<TGetAllEmptySlots>('interviewModals/getEmptySlots')

export const setEmptySlots = createAction<string[]>('interviewModals/setEmptySlots')
export const resetData = createAction('interviewModals/reset')

export const openActiveInterviewModal = createAction<boolean>('interviewModals/hasActiveInterviewModal')