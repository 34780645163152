import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import { Tabs, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import EmptyList from '../../../features/emptyList/components/EmptyList'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectLoadingModal } from '../../../compositions/InnerRouter/selectors'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {objectToQueryString } from '../utils'
import TabPane = Tabs.TabPane
import './CandidateHistory.scss'
import { selectCandidate, selectCandidateHistoryV2, selectHeaders, selectHistoryVariable, selectOpenHistoryCandidateData } from '../../../compositions/candidateV2/selectors'
import { CandidateHistoryV2 } from '../../../compositions/candidateV2/components/candidateHistory/CandidateHistoryV2'
import { getHistoryCandidateV2 } from '../../../compositions/candidateV2/actions'

type TFormValues = {
    actions: string
    perPagesCount: number
  }

export type TFormFilter = {
    user_id: number[]
    date: any
  }

const MainCSSClass = getCssClassName('candidate-history')

function CandidateHistory() {
  const openHistoryAction = useSelector(selectOpenHistoryCandidateData)
  const { control, watch } = useForm<TFormValues>({ defaultValues: { perPagesCount: 100 } })
  const { control: filterControl, watch: filterWatch, getValues } = useForm<TFormFilter>()

  const [page, setPage] = useState<number>(1)
  const variable = useSelector(selectHistoryVariable)
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const historyV2 = useSelector(selectCandidateHistoryV2)
  const headers = useSelector(selectHeaders)
  const loading = useSelector(selectLoadingModal)

  const responsible = filterWatch('user_id')
  const date = filterWatch('date')

  const stringCountsPagination = useMemo(
    () => `${headers?.current || '0'} из ${headers?.total || '0'}`,
    [headers]
  )

  const handleNextPage = useCallback(() => {
    if (page < headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      // Ваш код обработки изменения размеров
    }, 300)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // useEffect(() => {
  //   if (candidate) {
  //     dispatch(getHistoryCandidateV2(candidate.id))
  //   }
  // }, [candidate])

  const perPage = watch('perPagesCount')

  useEffect(() => {
    if (candidate) {
      dispatch(getHistoryCandidateV2({
        candidate_id: candidate.id, page, perPage, filter: objectToQueryString(getValues()) || ''
      }))
    }
  }, [candidate, page, perPage, variable, responsible, date])

  return (
    <div className={MainCSSClass}>
      {/* <div className={`${MainCSSClass}_select_div`}>
        <SelectFormControlMult
          name="actions"
          control={control}
          options={actionOptions}
          className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}
          placeholder="Действие"
          style={{
            maxHeight: '38px',
            overflowY: 'auto'}}
        />
        <DebounceSelectFormControlMult
          name="user_id"
          control={filterControl}
          mode="multiple"
          placeholder="Начните вводить имя"
          notFoundContent="Начните вводить имя"
          fetchOptions={fetchResponsibleList}
          className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}
        />
        <RangePickerFormControl
          name="date"
          control={filterControl}
          className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}

        />
      </div> */}
      { !loading && (
        <div className={`${MainCSSClass}__container-pagination`}>
          <SelectFormControl
            className={`${MainCSSClass}__pagination-count`}
            name="perPagesCount"
            options={optionsPagination}
            // onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
            control={control}
          />

          <div className={`${MainCSSClass}__wrapper-arrow-pagination`}>
            <ArrowRightSM
              className="arrowCursor"
              size="1"
              onClick={handlePreviousPage}
              fill={page === 1 ? '#BFC5C0' : '#404542'}
            />
            <Typography.Text className={`${MainCSSClass}__pagination-font`}>
              {
                stringCountsPagination
          || 'неизвестно'
              }
            </Typography.Text>
            <ArrowLeftSM
              onClick={handleNextPage}
              fill={headers?.total ? page >= headers?.total ? '#BFC5C0' : '#404542' : '#BFC5C0'}
              className="arrowCursor"
              size="1"
            />
          </div>

        </div>
      )}

      {loading && (
        <div>
          <Preloader size="md" />
          {' '}
        </div>
      )} 
      <div className={`${MainCSSClass}_scroll-container`}>
          {!loading && !!historyV2?.length && (
            <CandidateHistoryV2 />
          )}
          {!loading && !historyV2?.length && (
            <EmptyList type="history" />
          )}

      </div>
    </div>
  )
}

export default CandidateHistory
