import cn from 'classnames'

import '../Typography.scss'

import { InternalText, InternalTextType } from './InternalText'
import { TextProps } from './Text'

interface Heading extends InternalTextType {
  L1: typeof HL1;
  L2: typeof HL2;
  H1: typeof H1;
  H2: typeof H2;
  H3: typeof H3;
  H4: typeof H4;
  H5: typeof H5;
  H6: typeof H6;
}

export const Heading: Heading = InternalText as Heading

Heading.L1 = HL1
Heading.L2 = HL2
Heading.H1 = H1
Heading.H2 = H2
Heading.H3 = H3
Heading.H4 = H4
Heading.H5 = H5
Heading.H6 = H6

function HL1({ className, ...props }: TextProps) {
  return <InternalText tag="h1" className={cn('hl1', className)} {...props} />
}

function HL2({ className, ...props }: TextProps) {
  return <InternalText tag="h2" className={cn('hl2', className)} {...props} />
}

function H1({ className, ...props }: TextProps) {
  return <InternalText tag="h1" className={cn(className)} {...props} />
}

function H2({ className, ...props }: TextProps) {
  return <InternalText tag="h2" className={cn(className)} {...props} />
}

function H3({ className, ...props }: TextProps) {
  return <InternalText tag="h3" className={cn(className)} {...props} />
}

function H4({ className, ...props }: TextProps) {
  return <InternalText tag="h4" className={cn(className)} {...props} />
}

function H5({ className, ...props }: TextProps) {
  return <InternalText tag="h5" className={cn(className)} {...props} />
}

function H6({ className, ...props }: TextProps) {
  return <InternalText tag="h6" className={cn(className)} {...props} />
}
