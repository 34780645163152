import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ funnel }: TRootState) => funnel

export const selectCandidatesData = createSelector(
  selectState,
  ({ candidatesData }) => candidatesData || []
)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectLoadingCandidates = createSelector(selectState, ({ loadingCandidates }) => loadingCandidates)

export const selectOpenCreateVacancyModal = createSelector(selectState, ({ createVacancyModal }) => createVacancyModal)

export const selectShowModal = createSelector(selectState, ({ showModal }) => showModal)
export const selectCounters = createSelector(selectState, ({ counters }) => counters)

export const selectShowButton = createSelector(selectState, ({ showButton }) => showButton)

export const selectDefString = createSelector(selectState, ({ defString }) => defString)
export const selectMaxPage = createSelector(selectState, ({ maxPage }) => maxPage)
export const selectBlockedFiltersResponsibleOrRecruiter = createSelector(selectState, ({ blockedFiltersResponsibleOrRecruiter }) => blockedFiltersResponsibleOrRecruiter)

export const selectSelfFunnel = createSelector(selectState, ({ self }) => self)

export const selectOpenLimitationStageFunnel = createSelector(selectState, ({ openLimitationStageFunnelModal }) => openLimitationStageFunnelModal)

export const selectMessageLimitationFunnel = createSelector(selectState, ({ messageLimitationFunnel }) => messageLimitationFunnel)
