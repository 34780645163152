import { spawn } from 'redux-saga/effects'
import authSaga from '../../compositions/auth/saga'
import candidateSaga from '../../compositions/candidate/saga'
import innerRouterSaga from '../../compositions/InnerRouter/saga'
import { managementCandidatesSaga } from '../../compositions/ManagamentCandidates/saga'
import { managementRequestsSaga } from '../../compositions/ManagementRequests/saga'
import { managementVacanciesSaga } from '../../compositions/ManagementVacancies/saga'
import { modalsCandidatesSaga } from '../../features/candidateModals/saga'
import requestSaga from '../../compositions/Request/saga'
import { modalsRequestSaga } from '../../features/requestModals/saga'
import { managementInterviewsSaga } from '../../compositions/ManagementInterviews/saga'
import interviewCardSaga from '../../compositions/InterviewCard/saga'
import { modalsVacanciesSaga } from '../../features/vacancyModals/saga'
import resumeSaga from '../../compositions/Resume/saga'
import filtersSaga from '../../features/filters/saga'
import calendarSaga from '../../compositions/Calendar/saga'
import settingsCalendarSaga from '../../features/SettingsCalendar/saga'
import chatSaga from '../../features/chat/saga'
import { modalsInterviewsSaga } from '../../features/interviewModals/saga'
import systemsSaga from '../../compositions/Systems/saga'
import { mapSaga } from '../../features/map/saga'
import { funnelSaga } from '../../compositions/Funnel/saga'
import notificationsSaga from '../../features/notificaitionCenter/saga'
import reportFilterSaga from '../../features/reportFilter/saga'
import tasksSaga from '../../compositions/Tasks/saga'
import analyticsSaga from '../../compositions/analytics/saga'
import employeesCenterSaga from '../../features/employeesModal/saga'
import internshipSaga from '../../compositions/internship/saga'
import { leadsSaga } from '../../compositions/Leads'
import autocallSaga from '../../compositions/autocalls/saga'
import { chatPanelSaga } from '../../features/chatPanel'
import { userWorkPlanSaga } from '../../compositions/DepartmentWorkPlan'
import { productivitySaga } from '../../compositions/Productivity'
import candidateSagaV2 from '../../compositions/candidateV2/saga'

export default function* saga() {
  // yield spawn(наименование саги из файла и импорт)
  yield spawn(authSaga)
  yield spawn(innerRouterSaga)
  yield spawn(candidateSaga)
  yield spawn(modalsCandidatesSaga)
  yield spawn(managementCandidatesSaga)
  yield spawn(managementRequestsSaga)
  yield spawn(managementVacanciesSaga)
  yield spawn(requestSaga)
  yield spawn(modalsRequestSaga)
  yield spawn(managementInterviewsSaga)
  yield spawn(interviewCardSaga)
  yield spawn(modalsVacanciesSaga)
  yield spawn(resumeSaga)
  yield spawn(filtersSaga)
  yield spawn(chatSaga)
  yield spawn(calendarSaga)
  yield spawn(settingsCalendarSaga)
  yield spawn(modalsInterviewsSaga)
  yield spawn(systemsSaga)
  yield spawn(mapSaga)
  yield spawn(funnelSaga)
  yield spawn(notificationsSaga)
  yield spawn(reportFilterSaga)
  yield spawn(analyticsSaga)
  yield spawn(tasksSaga)
  yield spawn(employeesCenterSaga)
  yield spawn(leadsSaga)
  yield spawn(internshipSaga)
  yield spawn(autocallSaga)
  yield spawn(chatPanelSaga)
  yield spawn(userWorkPlanSaga)
  yield spawn(productivitySaga)
  yield spawn(candidateSagaV2)
}
