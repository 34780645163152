import React, { FC, memo } from 'react'
import { Typography } from 'antd'
import './HeadHunterCostRow.scss'
import HeadHunter from '../../../common/svg/HeadHunter'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TProps = {
  cost: number
}
const mainCssClass = 'headhunter-vacancy-cost-row-table'
const HeadHunterCostRow: FC<TProps> = ({ cost }) => (
  <div className={mainCssClass}>
    <HeadHunter />
    <Typography.Text>{cost}</Typography.Text>

  </div>
)
export default memo<TProps>(withErrorBoundary<TProps>(HeadHunterCostRow))
