import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import './RequestNameTableMobil.scss'
import { useDispatch } from 'react-redux'
import Ellipse from '../../../common/svg/Ellipse'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { navigateTo } from '../../../common/actions/navigate'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'

type TProps = {
    name: string
    priority: number
    id: number
    cost:number
    cost_process:number
}
const mainCssClass = 'request-name-table-mobil'
const RequestNameTable: FC<TProps> = ({
  name, priority, id, cost, cost_process
}) => {
  const dispatch = useDispatch()

  const handleNavigateRequest = useCallback(() => {
    if (id) {
      dispatch(navigateTo(`/application/${id}`))
    }
  }, [id])

  const fill = useMemo(() => {
    if (priority === 4) {
      return '#FFD608'
    }
    if (priority === 5) {
      return '#2DBE64'
    }
    if (priority === 6) {
      return '#404542'
    }
    return '#DF1E12'
  }, [priority])

  return (
    <div className={mainCssClass} onClick={handleNavigateRequest}>
      <div className={`${mainCssClass}__title`}>

        <Typography.Title level={4} className={`${mainCssClass}__title-text`}>
          <Ellipse fill={fill} />
          {' '}
          {name}
        </Typography.Title>
        <Typography.Text className={`${mainCssClass}__coust`}>
          Позиции:
          {' '}
          {cost}
          {' '}
          | Кандидатов:
          {cost_process}
        </Typography.Text>
      </div>
      <div className={`${mainCssClass}__arrow`}>
        <ArrowLeftSM />

      </div>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(RequestNameTable))
