import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Menu, Button, Dropdown, MenuProps, ModalProps
} from 'antd'
import { useNavigate, useParams } from 'react-router'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { openNotifications, selectNotifications, selectOpenNotification } from '../../../features/notificaitionCenter'
import Users from '../../../common/svg/Users'
import UserVoice from '../../../common/svg/UserVoice'
import Star from '../../../common/svg/Star'
import Case from '../../../common/svg/Case'
import Bell from '../../../common/svg/Bell'
import { getUserInfo, OpenInnerMenuMobil, setFullVersion } from '../actions'
import { selectInnerMenuMobil, selectRbacModel, selectUserInfo } from '../selectors'
import { getLogout } from '../../auth/actions'
import ModalEditCandidate from '../../candidate/components/ModalEditCandidate'

import './InnerMenuMobil.scss'
import { TDATA } from '../../candidate/types'
import { selectCandidate, selectOpenCreateModal, selectOpenSureModal } from '../../candidate/selectors'
import { navigateTo } from '../../../common/actions/navigate'
import ModalCloseSure from '../../candidate/components/ModalCloseSure'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ModalEditRequest from '../../../features/requestModals/components/ModalEditRequest'
import {
  selectOpenCreateRequestModal,
  selectSureRequestModal,
  selectLoading
} from '../../Request/selectors'
import { openCreateRequestModal, openSureRequestModal } from '../../Request/actions'
import { openCreateVacancyModal } from '../../Funnel/actions'
import ChatConversation from '../../../common/svg/ChatConversation'
import { selectOpenCreateVacancyModal } from '../../Funnel/selectors'
import PaperPlane from '../../../common/svg/PaperPlane'
import CreateVacancyModal from '../../../features/vacancyModals/components/CreateVacancyModal'
import ControlButton from '../../../common/components/Buttons/Button'
import Funnel from '../../../common/svg/Funnel'
import { selectDictionaryOptionsByName } from '../../ManagementRequests/selectors'
import { Heading, Title } from '../../../common/components/Text'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import BellNotificationMobil from '../../../common/svg/BellNotificationMobil'
import LogoMobil from '../../../common/svg/LogoMobil'
import CloseMD from '../../../common/svg/CloseMD'
import ListChecklist from '../../../common/svg/ListChecklist'
import NotifyDrawer from '../../../features/notificaitionCenter/components/NotifyDrawer'
import CandidateEditDrawer from '../../../compositions/candidateV2/components/CandidateEditDrawer'
import {
  createCandidate, createNewComment, openCreateModal, openEditCandidateDrawer, openSureModal, setUpdateCandidateMode
} from '../../../compositions/candidateV2/actions'
import { selectEditCandidateDrawer } from '../../../compositions/candidateV2/selectors'
import { selectOpenRequestDrawer } from '../../../features/requestModals/selectors'
import EditRequestDrawer from '../../../features/requestModals/components/EditRequestDrawer'
import { openRequestDrawer, setEditRequestMode } from '../../../features/requestModals/actions'

const mainCssClass = 'inner-menu-mobil'

const isWorkChat = process.env.WORK_CHAT
const isFirstLine = process.env.FIRST_LINE

function InnerMenuMobil({
  onCancel, closable
}: ModalProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const [current, setCurrent] = useState<string>('candidates')
  const userInfo = useSelector(selectUserInfo)
  const createModal = useSelector(selectOpenCreateModal)
  const sureModal = useSelector(selectOpenSureModal)
  const sureRequestModal = useSelector(selectSureRequestModal)
  const createRequestModal = useSelector(selectOpenCreateRequestModal)
  const createVacancyModal = useSelector(selectOpenCreateVacancyModal)
  const editDrawer = useSelector(selectOpenRequestDrawer)
  const loading = useSelector(selectLoading)
  const notifyCenter = useSelector(selectOpenNotification)
  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const navigate = useNavigate()
  const notifications = useSelector(selectNotifications)
  const openMobilMenu = useSelector(selectInnerMenuMobil)
  const editCandidateDrawer = useSelector(selectEditCandidateDrawer)

  const { pageId } = useParams()
  const rbacModel = useSelector(selectRbacModel)

  const handleMenuClick = (e) => {
    setCurrent(e.key)
    if (e.key === 'firstLine' && process.env.FIRST_LINE) {
      window.open(isFirstLine, '_blank')
    }
    if (e.key === 'workChat' && process.env.WORK_CHAT) {
      window.open(isWorkChat, '_blank')
    }
    if (e.key !== 'createMode' && e.key !== 'profile' && e.key !== 'firstLine' && e.key !== 'workChat') { dispatch(navigateTo(`/${e.key}`)) }
  }

  useEffect(() => {
    dispatch(getUserInfo(true))
  }, [])

  const handleModalAddCandidate = useCallback(() => {
    dispatch(openCreateModal(true))
    dispatch(openEditCandidateDrawer(true))
  }, [])

  const handleModalOk = (data: TDATA) => {
    dispatch(createCandidate({ ...data, status: 49 }))
    if (data.comment) {
      dispatch(createNewComment({ comment: data.comment, candidate_id: candidate?.id }))
    }

    dispatch(openCreateModal(false))
  }

  const handleCloseSureOk = () => {
    dispatch(openCreateModal(false))
    dispatch(openSureModal(false))
    dispatch(setUpdateCandidateMode(false))
    dispatch(openEditCandidateDrawer(false))
  }

  const handleCloseAddCandidate = () => {
    dispatch(openSureModal(true))
  }

  const handleCloseSureCancel = () => {
    dispatch(openSureModal(false))
  }

  const handleCreateRequest = () => {
    dispatch(openRequestDrawer(true))
  }

  const handleCancelRequestModal = () => {
    dispatch(openSureRequestModal(true))
  }
  const handleIsWorkChat = () => {
    window.open(isWorkChat, '_blank')
  }
  const handleFullVersion = () => {
    localStorage.setItem('fullVersion', 'true')
    window.location.href = window.location.href
  }
  const handleFirstLine = () => {
    window.open(isFirstLine, '_blank')
  }
  const handleNavigate = (path) => {
    dispatch(navigateTo(`/${path}`))
    dispatch(OpenInnerMenuMobil(false))
  }
  const handleOkRequestModal = () => {}

  const handleOkSureRequest = () => {
    dispatch(openSureRequestModal(false))
    dispatch(openRequestDrawer(false))
    dispatch(setEditRequestMode(false))
  }
  const handleModalClose = () => {
    dispatch(OpenInnerMenuMobil(false))
  }

  const handleCancelSureRequest = () => {
    dispatch(openSureRequestModal(false))
  }

  return (
    <CommonModal
      open={openMobilMenu}
      onCancel={handleModalClose}
      closable
      className={mainCssClass}
      width="100%"

    >
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__logo-container`}>
          <Heading.H3 className={`${mainCssClass}__heading`}>
            <LogoMobil />
            <span style={{ marginLeft: '10px' }}>Меню</span>
          </Heading.H3>
        </div>
        <div className={`${mainCssClass}__close`}>
          <CloseMD onClick={handleModalClose} />
        </div>

        <div className={`${mainCssClass}__two-block`}>
          <div className={`${mainCssClass}__block-items-selected`}>
            <div className={`${mainCssClass}__block-items`}>
              { rbacModel?.candidate.page_access === 1 && (
                <ControlButton
                  className="button"
                  typeButton="mobil"
                  icon="none"
                  size="large"
                  data-id="candidates"
                  onClick={() => handleNavigate('candidates')}
                >
                  <div className={`${mainCssClass}__block-items`}>
                    <Users
                      className={cn(`${mainCssClass}__icon`, {
                        [`${mainCssClass}__icon-selected`]: pageId === 'candidates' || pageId === 'candidate' || pageId === undefined
                      })}
                    />
                    <span>Кандидаты</span>
                  </div>
                </ControlButton>
              )}
            </div>
          </div>
          <div className={`${mainCssClass}__block-items-selected`}>
            <div className={`${mainCssClass}__block-items`}>
              { rbacModel?.request.page_access === 1 && (
                <ControlButton
                  className="button"
                  typeButton="mobil"
                  icon="none"
                  size="large"
                  data-id="applications"
                  onClick={() => handleNavigate('applications')}
                >
                  <div className={`${mainCssClass}__block-items`}>
                    <Star
                      className={cn(`${mainCssClass}__icon`, {
                        [`${mainCssClass}__icon-selected`]: pageId === 'applications' || pageId === 'application'
                      })}
                    />
                    <span>Заявки</span>
                  </div>
                </ControlButton>
              )}
            </div>
          </div>

          <div className={`${mainCssClass}__block-items-selected`}>
            <div className={`${mainCssClass}__block-items`}>
              <ControlButton
                className="button"
                typeButton="mobil"
                icon="none"
                size="large"
                data-id="interviews"
                onClick={() => handleNavigate('interviews')}
              >
                <div className={`${mainCssClass}__block-items`}>
                  <UserVoice
                    className={cn(`${mainCssClass}__icon`, {
                      [`${mainCssClass}__icon-selected`]: pageId === 'interviews' || pageId === 'interview'
                    })}
                  />
                  <span>Интервью</span>
                </div>
              </ControlButton>
            </div>
          </div>
          <div className={`${mainCssClass}__block-items-selected`}>
            <div className={`${mainCssClass}__block-items`}>
              { rbacModel?.task.page_access === 1 && (
                <ControlButton
                  className="button"
                  typeButton="mobil"
                  icon="none"
                  size="large"
                  data-id="tasks"
                  onClick={() => handleNavigate('tasks')}
                >
                  <div className={`${mainCssClass}__block-items`}>
                    <ListChecklist
                      className={cn(`${mainCssClass}__icon`, {
                        [`${mainCssClass}__icon-selected`]: pageId === 'tasks'
                      })}
                    />
                    <span>Задачи</span>
                  </div>
                </ControlButton>
              )}
            </div>
          </div>
        </div>
        <div className={`${mainCssClass}__line`} />
        <div className={`${mainCssClass}__one-block`}>
          <div className={`${mainCssClass}__create`}>
            Создать
          </div>
          <ControlButton
            className="button"
            typeButton="mobil"
            icon="none"
            size="large"
            onClick={handleModalAddCandidate}
          >
            <div className={`${mainCssClass}__block-items`}>
              <Users />
              Создать кандидата
              {' '}
            </div>
          </ControlButton>
          <ControlButton
            className="button"
            typeButton="mobil"
            icon="none"
            size="large"
            onClick={handleCreateRequest}
          >
            <div className={`${mainCssClass}__block-items`}>
              <Star />
              Создать заявку
              {' '}
            </div>
          </ControlButton>

        </div>
        <div className={`${mainCssClass}__line`} />
        <div className={`${mainCssClass}__bell-group`} />
        <div
          onClick={() => dispatch(openNotifications(true))}
          className={`${mainCssClass}__bell-container`}
        >
          <ControlButton
            className="button"
            typeButton="mobil"
            icon="none"
            size="large"
          >
            <div className={`${mainCssClass}__block-items`}>
              {notifications.length > 0 ? <BellNotificationMobil /> : <Bell />}
              Уведовления
            </div>
          </ControlButton>
        </div>
        {isFirstLine && (
          <div className={`${mainCssClass}__bell-container`}>
            <ControlButton
              className="button"
              typeButton="mobil"
              icon="none"
              size="large"
              onClick={handleFirstLine}
            >
              <div className={`${mainCssClass}__block-items`}>
                <PaperPlane />
                Первая линия
              </div>
            </ControlButton>

          </div>

        )}
        {isWorkChat && (
          <div className={`${mainCssClass}__bell-container`}>
            <ControlButton
              className="button"
              typeButton="mobil"
              icon="none"
              size="large"
              onClick={handleIsWorkChat}
            >
              <div className={`${mainCssClass}__block-items`}>
                <ChatConversation />
                Рабочий чат
              </div>
            </ControlButton>

          </div>
        )}
        <div className={`${mainCssClass}__bell-container`}>
          <ControlButton
            className="button"
            typeButton="mobil"
            icon="none"
            size="large"
            onClick={handleFullVersion}
          >
            <div className={`${mainCssClass}__block-items`}>
              Перейти на полную версию
            </div>
          </ControlButton>

        </div>

        <div className={`${mainCssClass}__line`} />
        <div className={`${mainCssClass}__three-block`}>
          <ControlButton
            typeButton="mobil"
            icon="none"
            className="button"
            size="large"
            onClick={() => dispatch(getLogout())}
          >
            <div className={`${mainCssClass}__block-items`}>
              <Case />
              Выйти
            </div>
          </ControlButton>
        </div>

        {/* {createModal && (
          <>
            <ModalEditCandidate
              editMode={false}
              onCancel={handleCloseAddCandidate}
              open={createModal}
              onOk={handleModalOk}
            />
            <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />
          </>
        )} */}
        <CandidateEditDrawer open={editCandidateDrawer} />
        {editCandidateDrawer
        && <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />}
        <EditRequestDrawer
          open={editDrawer}
          onCancel={handleCancelRequestModal}
          onOk={handleOkRequestModal}
        />
        {editDrawer
        && <ModalCloseSure open={sureRequestModal} onOk={handleOkSureRequest} onCancel={handleCancelSureRequest} />}
        <NotifyDrawer
          open={notifyCenter}
        />
      </div>
    </CommonModal>
  )
}
export default memo(withErrorBoundary(InnerMenuMobil))
