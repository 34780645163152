import { createReducer } from '@reduxjs/toolkit'
import {
  setRequests,
  setLoading,
  setDictionaries,
  reset,
  setOpenModalEditRequest,
  setOpenModalArchiveRequest,
  setSelectedRequestId,
  setSelectedRequest,
  setOpenModalSure,
  archiveOpen,
  resetAllRequest,
  setHeaders,
  setDefDataRequest, setCurators
} from './actions'
import { TDictionaries, TOption } from '../../common/types/dictionaries'
import { TRequest, TResponseRequestData } from '../../common/api/request'

type TManagementRequestsState = {
  requests: null | TResponseRequestData
  dictionaries: TDictionaries | null
  loading: boolean
  editModalOpen: boolean
  archiveModalOpen: boolean
  requestId: number | null
  request: TRequest | null
  curators: TOption[]
  modalSure: boolean
  archiveOpen: boolean
  defDataRequest: {
    page:number,
    perPage: number,
    filters: string,
    search: string
    status: string
  } | null
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
}

const initialState: TManagementRequestsState = {
  requests: null,
  dictionaries: null,
  loading: false,
  editModalOpen: false,
  archiveModalOpen: false,
  requestId: null,
  request: null,
  modalSure: false,
  archiveOpen: false,
  defDataRequest: null,
  headers: null,
  curators: []
}

const managementRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRequests, (state, { payload }) => {
      state.requests = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setOpenModalEditRequest, (state, { payload }) => {
      state.editModalOpen = payload
    })
    .addCase(setOpenModalArchiveRequest, (state, { payload }) => {
      state.archiveModalOpen = payload
    })
    .addCase(setOpenModalSure, (state, { payload }) => {
      state.modalSure = payload
    })
    .addCase(setSelectedRequestId, (state, { payload }) => {
      state.requestId = payload
    })
    .addCase(setSelectedRequest, (state, { payload }) => {
      state.request = payload
    })
    .addCase(setCurators, (state, { payload }) => {
      state.curators = payload
    })
    .addCase(archiveOpen, (state, { payload }) => {
      state.archiveOpen = payload
    })
    .addCase(reset, (state) => ({
      ...initialState,
      defDataRequest: state.defDataRequest
    }))
    .addCase(setDefDataRequest, (state, { payload }) => {
      state.defDataRequest = payload
    })
    .addCase(resetAllRequest, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
})

export default managementRequestsReducer
