import { TOption } from 'src/common/types/dictionaries'
import $api from '../../common/api/http'

export async function getGroupDictionary(id?: number[]) {
  const { data } = await $api.get('/api/v1/dictionary-options?fields=id,value,params&filter[dictionary_id]=39&filter[is_hidden]=0&page=1&per-page=1000')

  if (id && id.length) {
    return data.DATA.filter((el) => !!id.includes(el.id))
  }

  return data.DATA
}

export type TGroupOption = {
    id: number,
    value: string
}

export function groupByCollectionName(data: any[]) {
  const result = {}

  data.forEach((item) => {
    if (item.params && item.params.collection_name) {
      const collectionName = item.params.collection_name

      // Если такой label еще нет, создаем массив
      if (!result[collectionName]) {
        result[collectionName] = []
      }

      // Добавляем id в соответствующий массив
      result[collectionName].push(item.id)
    }
  })
  // Преобразуем объект в массив нужного формата
  return Object.keys(result).map((label) => ({
    label,
    value: JSON.stringify(result[label])
  }))
}

export const getGroupOptions = (arr: TGroupOption[]) => {
  if (!arr.length) {
    return []
  }
  return arr.map((el) => ({ label: el.value, value: el.id }))
}

export function parseArrayStringToNumbers(array :any[]) {
  return array
    .flatMap((item) => item.slice(1, -1).split(',').map(Number))
}

export function getUniqueValues(array1, array2) {
  return [...new Set([...array1, ...array2])]
}

export function addNewGroupOptions(firstArray, secondArray, thirdArray) {
  // Преобразуем строки в массивы чисел для второго и третьего массивов
  const parseValues = (arr) => arr.flatMap((str) => JSON.parse(str))

  const secondArrayParsed = parseValues(secondArray)
  const thirdArrayParsed = parseValues(thirdArray)

  // Находим элементы, которые есть во втором массиве, но отсутствуют в третьем
  const differenceArray = secondArrayParsed.filter((num) => !thirdArrayParsed.includes(num))

  // Объединяем первый массив с найденной разницей и удаляем дубликаты
  return [...new Set([...firstArray, ...differenceArray])]
}

export function delGroupOptions(array1, array2, array3) {
  // Преобразуем строки в массивы чисел
  const parseNumbers = (arr) => arr.flatMap((item) => item.slice(1, -1).split(',').map(Number))

  // Преобразуем строки в массивы чисел
  const parsedArray1 = parseNumbers(array1)
  const parsedArray2 = parseNumbers(array2)

  // Создаем множества для быстрого поиска
  const setArray2 = new Set(parsedArray2)
  const difference = new Set(parsedArray1.filter((item) => !setArray2.has(item)))

  // Создаем множество для быстрого поиска
  const setDifference = new Set(difference)

  // Исключаем найденные элементы из array3
  const result = array3.filter((item) => !setDifference.has(item))

  return result
}

export function updateLabels(arrayOfObjects, referenceArray, valueArray) {
  // Функция для удаления старых совпадений из label
  const removeOldLabelInfo = (label) => label.replace(/ \d+\/\d+$/, '').trim()

  // Преобразуем строки в массивы чисел
  const parseValue = (value) => JSON.parse(value)

  return arrayOfObjects.map((item) => {
    // Если value не присутствует в valueArray, сбрасываем label
    if (!valueArray.includes(item.value)) {
      return {
        ...item,
        label: removeOldLabelInfo(item.label)
      }
    }

    const values = parseValue(item.value)
    const matches = values.filter((num) => referenceArray.includes(num)).length
    const total = values.length

    // Обновляем label, если есть совпадения
    if (matches > 0) {
      return {
        ...item,
        label: `${removeOldLabelInfo(item.label)} ${matches}/${total}`.trim()
      }
    }

    // Возвращаем label к исходному состоянию, если нет совпадений
    return {
      ...item,
      label: removeOldLabelInfo(item.label)
    }
  })
}

export function delDictionaryOptions(firstArray, secondArray) {
  // Функция для проверки, содержит ли массив хотя бы одно из значений из другого массива
  const containsAny = (values, checkArray) => values.some((value) => checkArray.includes(value))

  // Преобразуем значения в `firstArray` в массивы чисел
  const filteredGroups = firstArray
    .filter((group) => {
      const groupValues = JSON.parse(group.value) // Преобразуем строку в массив чисел
      return containsAny(groupValues, secondArray) // Проверяем наличие хотя бы одного совпадения
    })
    .map((group) => group.value) // Возвращаем строку `value`, если совпадение найдено

  return filteredGroups
}

export function findMatchingValues(objArray, numbersArray) {
  // Преобразуем числа второго массива в Set для быстрого поиска
  const numbersSet = new Set(numbersArray)

  // Функция для проверки, содержит ли строка все числа из numbersSet
  function containsAllNumbers(valueStr) {
    // Преобразуем строку в массив чисел
    const valueArray = JSON.parse(valueStr)
    // Проверяем, что каждое число из numbersSet присутствует в valueArray
    return valueArray.every((num) => [...numbersSet].includes(num))
  }

  // Фильтруем объект по условию, что value содержит все числа из numbersArray
  const result = objArray
    .filter((item) => containsAllNumbers(item.value))
    .map((item) => item.value)


  const parsedArray = result.map((str) => JSON.parse(str))

  // Фильтруем массивы, оставляя только те, которые содержат хотя бы одно значение из второго массива
  const filteredArray = parsedArray.filter((arr) => arr.some((num) => numbersArray.includes(num)))

  // Преобразуем массивы обратно в строки
  return filteredArray.map((arr) => JSON.stringify(arr))
}

export function getFilterString(obj : any) {
  let str = ''
  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length && key !== 'stor') {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }
      if (typeof obj[key][0] === 'number') {
        if (key === 'group') {
          const strArrStr = obj[key].map((el) => el).join(',')
          const newStrArr = strArrStr.replace(/undefined/g, '')
          str += `&groups=${newStrArr}`
        } else {
          const strArrStr = obj[key].reduce<string>((acc, el) => {
            acc += `&filter[${key}][in][]=${el}`
            return acc
          }, '')
          str += strArrStr
        }
      }
      if (key === 'created_at') {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (typeof obj[key][0] === 'string' && key !== 'created_at') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
    } else if (typeof obj[key] === 'string' && !!obj[key].length) {
      if (key === 'is_at_work') {
        str += `&union[${key}]=${obj[key]}`
      } else str += `&filter[${key}]=${obj[key]}`
    }
  }
  return str
}

export const periodArr = [
  {
    id: 1,
    name: 'Сентябрь',
    work_days: 20,
    date: '01.10.24 - 31.10.24',
    status: 'plan'
  },
  {
    id: 2,
    name: 'Октябрь',
    work_days: 20,
    date: '01.10.24 - 31.10.24',
    status: 'actual'
  },
  {
    id: 3,
    name: 'Ноябрь',
    work_days: 20,
    date: '01.10.24 - 31.10.24',
    status: 'last'
  },
  {
    id: 4,
    name: 'Декабрь',
    work_days: 20,
    date: '01.10.24 - 31.10.24',
    status: 'last'
  }
]
