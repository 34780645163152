import { createAction } from '@reduxjs/toolkit'
import { TRequestDialogs } from '../../common/api/chatPanel'
import { TCandidate, TDATA } from '../../compositions/candidateV2/types'

export type TUpdateRequestStatus = {
  status: string | number
  id: string | number
  priority_id?: number
  isRequestTable: boolean
  comment?: string
}
export const getDialogs = createAction<TRequestDialogs>('chatPanel/getDialogs')
export const getCandidate = createAction<string>('chatPanel/getCandidate')
export const setCandidate = createAction<TDATA | null>('chatPanel/setCandidate')
export const setDialogs = createAction<any[]>('chatPanel/setDialogs')
export const reset = createAction('chatPanel/reset')
export const setLoading = createAction<boolean>('chatPanel/setLoading')

export type TChatPanelHeaders = {
  current:number,
  total:number,
  totalCount: number
}

export const setHeaders = createAction<TChatPanelHeaders>('chatPanel/setHeaders')
