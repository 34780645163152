/* eslint-disable react/function-component-definition */
import React, {
  FC, memo
} from 'react'
import { Typography } from 'antd'

import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

import './PhoneRecordLink.scss'
import Download from '../../../common/svg/Download'

type TProps = {
        link: string
    }

const mainCssClass = 'phone-record-link'

const PhoneRecordLink: FC<TProps> = ({ link }) => {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noreferrer" className={mainCssClass}>
        <Typography.Text>
          Запись звонка
        </Typography.Text>

        <Download />
      </a>
    )
  }

  return ''
}
export default memo<TProps>(withErrorBoundary<TProps>(PhoneRecordLink))
