import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  createObject, createUpdateObject, fetchResponsibleList, formatDateTime, generateTimeArray
} from '../utils'

import { createTask, getDictionaries, setSelectTask, updateTask } from '../actions'
import { selectDictionaryOptionsByName, selectSelectTask } from '../selectors'
import { selectUserInfo } from '../../InnerRouter/selectors'
import './CreateTaskModal.scss'

type TFormValues = {
  user_id:any
  time: any
  target_id: number | null
  myTask: boolean
}

export type TProps = {
candidate_id:number|string
editMode?: boolean
} & ModalProps

const mainCSSclass = 'task-create'

function CreateTaskModal({
  open, className, onCancel, candidate_id, editMode
}: TProps) {
  const dispatch = useDispatch()
  const [timeObjects, setTimeObjects] = useState(generateTimeArray())
  const selectTask = useSelector(selectSelectTask)
  const targetOPtions = useSelector(selectDictionaryOptionsByName('targetTask'))
  const user = useSelector(selectUserInfo)

  const defaultValues = useMemo(() => {
    if (selectTask ) {
      const { deadline_at, time } = formatDateTime(selectTask.deadline_at)
      return {
        name: selectTask.name,
        description: selectTask.description,
        user_id: { value: selectTask.user_id, label: selectTask.user_label },
        deadline_at,
        time,
        target_id: selectTask.target_id,
        myTask: selectTask.user_id === user?.id
      }
    }
    return {}
  }, [selectTask, timeObjects])
  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = () => {
    const values = getValues()
    const user_id = watch('user_id')
    if (selectTask) {
      dispatch(updateTask({ ...createUpdateObject(values), task_id: selectTask?.task_id, user_id: typeof user_id === 'object' ? user_id.value : user_id }))
    } else dispatch(createTask({ ...createObject(values), candidate_id, user_id: typeof user_id === 'object' ? user_id.value : user_id }))
  }

  const handleChange = (data) => {
    setValue('time', null)
    const selectedDate = new Date(data)
    const day = new Date()

    selectedDate.setHours(0, 0, 0, 0)
    day.setHours(0, 0, 0, 0)
    if (selectedDate.getTime() === day.getTime()) {
      const today = new Date()
      const todayHours = today.getHours()
      const todayMinutes = today.getMinutes()
      const formattedHours = todayHours < 10 ? `0${todayHours}` : todayHours
      const formattedMinutes = todayMinutes < 10 ? `0${todayMinutes}` : todayMinutes

      setTimeObjects(generateTimeArray(`${formattedHours}:${formattedMinutes}`))
    } else { setTimeObjects(generateTimeArray()) }
  }

  const myTask = watch('myTask')
  const user_id = watch('user_id')

  useEffect(() => {
    if (myTask) {
      setValue('user_id', { label: `${user?.name} ${user?.surname}`, value: user.id })
    } else if (!myTask && (typeof user_id === 'object' ? user_id.value == user?.id : user_id == user?.id)) {
      setValue('user_id', null)
    }
  }, [myTask])

  useEffect(() => {
    dispatch(
      getDictionaries([
        'targetTask'
      ])
    )
  }, [])

  useEffect(()=> () => {
    dispatch(setSelectTask(null))
  }, [])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      centered
      title={selectTask ? 'Редактирование задачи' : 'Новая задача'}
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCSSclass}>
        <InputFormControl control={control} name="name" label="Название задачи" required rules={{ required: true }} />
        <TextAreaFormControl control={control} name="description" label="Описание задачи" />

        <DebounceSelectFormControl
          name="user_id"
          control={control}
          placeholder="Выберите исполнителя"
          fetchOptions={fetchResponsibleList}
          notFoundContent="Начните вводить имя"
          label="Исполнитель"
          rules={{ required: true }}
          key={`${myTask}`}

        />

        <div className={`${mainCSSclass}_switch`}>
          <SwitchFormControl control={control} name="myTask" />
          <p>Назначить себя исполнителем</p>
        </div>
        <SelectFormControl
          name="target_id"
          control={control}
          label="Цель задачи"
          options={targetOPtions}
        />

        <DatePickerFormControl
          name="deadline_at"
          label="Дата исполнения"
          required
          control={control}
          rules={{ required: true, validate: (value :string) => value.replace(/[^0-9]/g, '').length > 7 && !value.includes('_') }}
          isDisableDate
          tasks
          onChange={(data) => handleChange(data)}
        />
        <SelectFormControl
          name="time"
          control={control}
          label="Время исполнения"
          required
          options={timeObjects}
          rules={{ required: true }}
        />

      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CreateTaskModal))
