import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { Select, SelectProps, Typography } from 'antd'
import cn from 'classnames'
import { useController, UseControllerProps } from 'react-hook-form'
import debounce from 'lodash/debounce'
import Required from '../../svg/Required'
import { TOption } from '../../types/dictionaries'
import './DebounceSelectFormControl.scss'

const { Title } = Typography

type CombinedProps = Omit<
  UseControllerProps &
  SelectProps & {
  triggerFetch?: string;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  label?: string;
  index?: number;
  fetchOptions: (search: string) => Promise<TOption[]>;
  debounceTimeout?: number;
  required?: boolean;
  maxSelections?: number; // Добавляем новый пропс для ограничения выбора
},
  'isDisabled'
>;

const mainCssClass = 'debounce-select'

function DebounceSelectFormControlMult({
  triggerFetch,
  name,
  control,
  rules,
  fetchOptions,
  debounceTimeout = 1000,
  label,
  required,
  className,
  maxSelections = 250, // Значение по умолчанию для ограничения выбора
  ...rest
}: CombinedProps) {
  const { field, fieldState } = useController({ name, control, rules })

  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<TOption[]>([])
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const handleSelectChange = (value: any, selectedOptions: TOption[] | TOption) => {
    if (Array.isArray(selectedOptions) && selectedOptions.length <= maxSelections) {
      const selectedValues = selectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
        key: option?.key ? option.key : value?.key,
        group: option?.group
      }))
      field.onChange(selectedValues)
    }
  }

  useEffect(() => {
    fetchOptions('').then((defaultOptions) => {
      setOptions(defaultOptions)
    })
  }, [triggerFetch])

  return (
    <div className={cn(mainCssClass, className)}>
      {label && (
        <Title className="textAreaLabel" level={4}>
          {label}
          {required && <Required className="required" />}
        </Title>
      )}

      <Select
        labelInValue
        showSearch
        className={fieldState.error ? 'error' : ''}
        filterOption={false}
        onSearch={debounceFetcher}
        {...rest}
        value={field.value}
        options={options}
        mode="multiple"
        onChange={handleSelectChange}
      />
    </div>
  )
}

export default DebounceSelectFormControlMult
