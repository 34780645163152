import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'check-all-big'

function CheckAllBig({ className, color, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M23.2639 5.63605C23.6544 6.02658 23.6544 6.65974 23.2639 7.05026L12.6568 17.6569C12.2663 18.0474 11.6332 18.0474 11.2426 17.6569L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.68342 10.9024 7.31658 10.9024 7.70711 11.2929L11.9498 15.5355L21.8497 5.63602C22.2402 5.2455 22.8734 5.24552 23.2639 5.63605ZM18.3131 5.68633C18.7037 6.07685 18.7037 6.71002 18.3131 7.10054L13.0098 12.4038C12.6193 12.7944 11.9862 12.7944 11.5956 12.4038C11.2051 12.0133 11.2051 11.3802 11.5956 10.9896L16.8989 5.68633C17.2895 5.2958 17.9226 5.2958 18.3131 5.68633ZM1.3427 11.3432C1.73322 10.9527 2.36639 10.9527 2.75691 11.3432L7.70666 16.2929C8.09718 16.6835 8.09718 17.3166 7.70666 17.7071C7.31613 18.0977 6.68297 18.0977 6.29245 17.7071L1.3427 12.7574C0.952174 12.3669 0.952174 11.7337 1.3427 11.3432Z" fill={color || 'white'} />
    </svg>

  )
}

export default CheckAllBig
