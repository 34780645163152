import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'request-progress'

function RequestProgress({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.00003" y="1" width="38" height="38" rx="19" fill="#EBD4FE" />
      <rect x="1.00003" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2929 13.2929C20.6834 12.9024 21.3166 12.9024 21.7071 13.2929L27.7071 19.2929C28.0977 19.6834 28.0977 20.3166 27.7071 20.7071L21.7071 26.7071C21.3166 27.0976 20.6834 27.0976 20.2929 26.7071C19.9024 26.3166 19.9024 25.6834 20.2929 25.2929L24.5858 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H24.5858L20.2929 14.7071C19.9024 14.3166 19.9024 13.6834 20.2929 13.2929Z" fill="#5A2882" />
    </svg>

  )
}
export default RequestProgress
