import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Menu, Button, Dropdown, MenuProps } from 'antd'
import { useNavigate, useParams } from 'react-router'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  openNotifications,
  selectOpenNotification,
  selectUnreadNotifications
} from '../../../features/notificaitionCenter'
import Users from '../../../common/svg/Users'
import UserVoice from '../../../common/svg/UserVoice'
import Star from '../../../common/svg/Star'
import Case from '../../../common/svg/Case'
import Chart from '../../../common/svg/Chart'
import Logo from '../../../common/svg/Logo'
import Plus from '../../../common/svg/Plus'
import BellNotification from '../../../common/svg/BellNotification'
import UserCircle from '../../../common/svg/UserCircle'
import Settings from '../../../common/svg/Settings'
import { selectRbacModel, selectUserInfo } from '../selectors'
import { getLogout } from '../../auth/actions'

import { navigateTo } from '../../../common/actions/navigate'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ModalEditRequest from '../../../features/requestModals/components/ModalEditRequest'
import { selectSureRequestModal } from '../../Request/selectors'
import { openSureRequestModal } from '../../Request/actions'
import { openCreateVacancyModal } from '../../Funnel/actions'
import { selectOpenCreateVacancyModal } from '../../Funnel/selectors'
import CreateVacancyModal from '../../../features/vacancyModals/components/CreateVacancyModal'
import ControlButton from '../../../common/components/Buttons/Button'
import { openActiveInterviewModal } from '../../../features/interviewModals/actions'
import Funnel from '../../../common/svg/Funnel'
import { selectActiveInterviewModal } from '../../../features/interviewModals/selectors'
import NotifySVG from '../../../common/svg/NotifySVG'
import HasActiveModals from '../../../features/interviewModals/components/HasActiveModals'
import AvatarNavbar from '../../../common/svg/AvatarNavbar'
import ListChecklist from '../../../common/svg/ListChecklist'
import Information from '../../../common/svg/Information'
import UsersGroup from '../../../common/svg/UsersGroup'
import Suitcase from '../../../common/svg/Suitcase'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import NotifyDrawer from '../../../features/notificaitionCenter/components/NotifyDrawer'
import './InnerMenu.scss'
import MoreGrid from '../../../common/svg/MoreGrid'
import MoreHorisontal from '../../../common/svg/MoreHorisontal'
import UserCardID from '../../../common/svg/UserCardID'
import ArrowReload from '../../../common/svg/ArrowReload'
import UserAdd from '../../../common/svg/UserAdd'
import UserBucks from '../../../common/svg/UserBucks'
import Stroke from '../../../common/svg/Stroke'
import { Download } from '../../../common/svg/SvgFromIconWrapper/Download'
import PhoneSvg from '../../../common/svg/PhoneSvg'
import ListCheck from '../../../common/svg/ListCheck'
import CallAlone from '../../../common/svg/SvgFromIconWrapper/CallAlone'
import CircleCheck from '../../../common/svg/CircleCheck'
import {
  createCandidate,
  createNewComment,
  openCreateModal,
  openEditCandidateDrawer,
  openSureModal,
  openUpdateModal,
  setInformationModal,
  setUpdateCandidateMode
} from '../../../compositions/candidateV2/actions'
import {
  selectCandidate,
  selectEditCandidateDrawer,
  selectInformationModal,
  selectOpenCreateModal,
  selectOpenSureModal,
  selectUpdateCandidateMode
} from '../../../compositions/candidateV2/selectors'
import ModalEditCandidate from '../../../compositions/candidateV2/components/ModalEditCandidate'
import ModalCloseSure from '../../../compositions/candidateV2/components/ModalCloseSure'
import CandidateEditDrawer from '../../../compositions/candidateV2/components/CandidateEditDrawer'
import EditRequestDrawer from '../../../features/requestModals/components/EditRequestDrawer'
import { selectEditMode, selectOpenRequestDrawer } from '../../../features/requestModals/selectors'
import { openRequestDrawer, setEditRequestMode } from '../../../features/requestModals/actions'
import LogoNewYear from '../../../common/svg/LogoNewYear'
import HatNewYear from '../../../common/svg/HatNewYear'
import EllipseNewYaer from '../../../common/svg/EllipseNewYaer'
import UnionNewYear from '../../../common/svg/UnionNewYear'

const mainCssClass = 'inner-menu'

const isWorkChat = process.env.WORK_CHAT
const isFirstLine = process.env.FIRST_LINE

function InnerMenu() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const [current, setCurrent] = useState<string>('candidates')
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const userInfo = useSelector(selectUserInfo)
  const createModal = useSelector(selectOpenCreateModal)
  const sureModal = useSelector(selectOpenSureModal)
  const sureRequestModal = useSelector(selectSureRequestModal)
  const createVacancyModal = useSelector(selectOpenCreateVacancyModal)
  const rbacModel = useSelector(selectRbacModel)
  const notifyCenter = useSelector(selectOpenNotification)
  const notifications = useSelector(selectUnreadNotifications)
  const activeInterviewModal = useSelector(selectActiveInterviewModal)
  const informationModal = useSelector(selectInformationModal)
  const editCandidateDrawer = useSelector(selectEditCandidateDrawer)
  const editDrawer = useSelector(selectOpenRequestDrawer)

  const isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  const activeInterview = useMemo(() => {
    if (userInfo && 'has_active_interview' in userInfo && 'id' in userInfo.has_active_interview) {
      return userInfo.has_active_interview.id
    }
    return null
  }, [userInfo])

  const { pageId } = useParams()

  const handleMenuClick = (e) => {
    setCurrent(e.key)
    if (e.key === 'firstLine') {
      window.open('https://t.me/HrmSupportBot', '_blank')
    }
    if (e.key === 'workChat') {
      window.open('https://vkusvill-chat.fraxis.ru/', '_blank')
    }
    if (
      e.key !== 'createMode' &&
      e.key !== 'profile' &&
      e.key !== 'menuMode' &&
      e.key !== 'firstLine' &&
      e.key !== 'workChat' &&
      !activeInterview
    ) {
      if (e.domEvent.ctrlKey || e.domEvent.metaKey || e.domEvent.which === 2) {
        // Если Ctrl была нажата, открываем ссылку в новой вкладке
        window.open(`/${e.key}`, '_blank')
      } else dispatch(navigateTo(`/${e.key}`))
    } else if (activeInterview) {
      dispatch(openActiveInterviewModal(true))
    }
  }

  const handleModalAddCandidate = useCallback(() => {
    dispatch(openEditCandidateDrawer(true))
  }, [])

  const handleModalOk = (data: TDATA) => {
    if (userInfo) {
      userInfo?.department === 175
        ? dispatch(createCandidate({ ...data, status: 49, responsible: userInfo?.id }))
        : dispatch(createCandidate({ ...data, status: 49 }))
      if (data.comment) {
        dispatch(createNewComment({ comment: data.comment, candidate_id: candidate?.id }))
      }
      dispatch(openCreateModal(false))
    }
  }

  const handleCloseSureOk = () => {
    dispatch(openCreateModal(false))
    dispatch(openSureModal(false))
    dispatch(setUpdateCandidateMode(false))
    dispatch(openEditCandidateDrawer(false))
    dispatch(openUpdateModal(false))
  }

  const handleCloseAddCandidate = () => {
    dispatch(openSureModal(true))
  }

  const handleCloseSureCancel = () => {
    dispatch(openSureModal(false))
  }

  const handleCreateRequest = () => {
    dispatch(openRequestDrawer(true))
  }

  const handleCancelRequestModal = () => {
    dispatch(openSureRequestModal(true))
  }

  const handleOkRequestModal = () => {}

  const handleOkSureRequest = () => {
    dispatch(openSureRequestModal(false))
    dispatch(openRequestDrawer(false))
    dispatch(setEditRequestMode(false))
  }

  const handleCreateVacancy = () => {
    dispatch(openCreateVacancyModal(true))
  }

  const handleCancelSureRequest = () => {
    dispatch(openSureRequestModal(false))
  }

  const handleCancelVacancyModal = () => {
    dispatch(openCreateVacancyModal(false))
  }
  const items: MenuProps['items'] = [
    ...(rbacModel?.candidate?.create_access === 1 && !activeInterview
      ? [
          {
            key: 'create-candidate',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="none"
                size="large"
                onClick={handleModalAddCandidate}
              >
                <Users />
                Создать кандидата{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.request?.create_access === 1 && userInfo?.role?.role_id !== 'recruitment_agency' && !activeInterview
      ? [
          {
            key: 'create-application',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="none"
                size="large"
                onClick={handleCreateRequest}
              >
                <Star />
                Создать заявку{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.vacancy?.create_access === 1 && userInfo?.role?.role_id !== 'recruitment_agency' && !activeInterview
      ? [
          {
            key: 'create-interview',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="none"
                size="large"
                onClick={handleCreateVacancy}
              >
                <Case />
                Создать вакансию{' '}
              </ControlButton>
            )
          }
        ]
      : [])
  ]
  const itemsMenu: MenuProps['items'] = [
    ...((rbacModel?.analytics.page_access === 1 || userInfo?.can_make_report) &&
    userInfo?.role?.role_id !== 'courier_curator'
      ? [
          {
            key: 'analytics',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/analytics', '_blank')
                  } else dispatch(navigateTo('/analytics'))
                }}
              >
                <Chart />
                Аналитика{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(userInfo?.is_can_internship
      ? [
          {
            key: 'internships',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/internships', '_blank')
                  } else dispatch(navigateTo('/internships'))
                }}
              >
                <UserCardID />
                Стажировка{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    // ...[
    //   {
    //     key: 'fluidity',
    //     label: (
    //       <ControlButton
    //         className="button"
    //         typeButton="ghost"
    //         icon="iconOnly"
    //         size="large"
    //         onClick={(e) => {
    //           if (e.ctrlKey || e.metaKey || e.which === 2) {
    //             // Если Ctrl была нажата, открываем ссылку в новой вкладке
    //             window.open('/fluidity', '_blank')
    //           } else dispatch(navigateTo('/fluidity'))
    //         }}
    //       >
    //         <ArrowReload />
    //         Текучесть
    //         {' '}
    //       </ControlButton>
    //     )
    //   }
    // ],
    // ...[
    //   {
    //     key: 'costOfHiring',
    //     label: (
    //       <ControlButton
    //         className="button"
    //         typeButton="ghost"
    //         icon="iconOnly"
    //         size="large"
    //         onClick={(e) => {
    //           if (e.ctrlKey || e.metaKey || e.which === 2) {
    //             // Если Ctrl была нажата, открываем ссылку в новой вкладке
    //             window.open('/costOfHiring', '_blank')
    //           } else dispatch(navigateTo('/costOfHiring'))
    //         }}
    //       >
    //         <UserBucks />
    //         Стоимость найма
    //         {' '}
    //       </ControlButton>
    //     )
    //   }
    // ],
    // ...[
    //   {
    //     key: 'phot',
    //     label: (
    //       <ControlButton
    //         className="button"
    //         typeButton="ghost"
    //         icon="iconOnly"
    //         size="large"
    //         onClick={(e) => {
    //           if (e.ctrlKey || e.metaKey || e.which === 2) {
    //             // Если Ctrl была нажата, открываем ссылку в новой вкладке
    //             window.open('/phot', '_blank')
    //           } else dispatch(navigateTo('/phot'))
    //         }}
    //       >
    //         <Stroke />
    //         ФОТ
    //         {' '}
    //       </ControlButton>
    //     )
    //   }
    // ],
    ...(rbacModel?.user_work_plan?.create_access === 1
      ? [
          {
            key: 'departmentWorkPlan',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/departmentWorkPlan', '_blank')
                  } else dispatch(navigateTo('/departmentWorkPlan'))
                }}
              >
                <ListCheck />
                План работы отдела{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.call?.page_access === 1
      ? [
          {
            key: 'autocalls',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/autocalls', '_blank')
                  } else dispatch(navigateTo('/autocalls'))
                }}
              >
                <CallAlone />
                Обзвоны{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.vacancy.page_access === 1 && userInfo?.role?.role_id !== 'courier_curator'
      ? [
          {
            key: 'vacancies',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/vacancies', '_blank')
                  } else dispatch(navigateTo('/vacancies'))
                }}
              >
                <Case />
                Вакансии{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.productivity.page_access === 1
      ? [
          {
            key: 'productivity',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/productivity', '_blank')
                  } else dispatch(navigateTo('/productivity'))
                }}
              >
                <CircleCheck />
                Продуктивность{' '}
              </ControlButton>
            )
          }
        ]
      : [])
    // {
    //   key: 'parsing',
    //   label: (
    //     <ControlButton
    //       className="button"
    //       typeButton="ghost"
    //       icon="iconOnly"
    //       size="large"
    //       onClick={(e) => {
    //         if (e.ctrlKey || e.metaKey || e.which === 2) {
    //           // Если Ctrl была нажата, открываем ссылку в новой вкладке
    //           window.open('/parsing', '_blank')
    //         } else dispatch(navigateTo('/parsing'))
    //       }}
    //     >
    //       <Download />
    //       Парсинг
    //       {' '}
    //     </ControlButton>
    //   )
    // },
    // {
    //   key: 'parsing',
    //   label: (
    //     <ControlButton
    //       className="button"
    //       typeButton="ghost"
    //       icon="iconOnly"
    //       size="large"
    //       onClick={(e) => {
    //         if (e.ctrlKey || e.metaKey || e.which === 2) {
    //           // Если Ctrl была нажата, открываем ссылку в новой вкладке
    //           window.open('/autocalls', '_blank')
    //         } else dispatch(navigateTo('/autocalls'))
    //       }}
    //     >
    //       <PhoneSvg fill="#404542" />
    //       Обзвоны
    //       {' '}
    //     </ControlButton>
    //   )
    // }
  ]

  const itemsProfile: MenuProps['items'] = [
    ...(rbacModel?.profile?.page_access === 1
      ? [
          {
            key: 'profile',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="iconOnly"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/systems/profile/my', '_blank')
                  } else dispatch(navigateTo('/systems/profile/my'))
                }}
              >
                <UserCircle />
                Мой профиль{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    ...(rbacModel?.users.create_access ||
    rbacModel?.role.create_access ||
    rbacModel?.questionnare.create_access ||
    rbacModel?.dictionary.create_access ||
    rbacModel?.default_stage.create_access ||
    (rbacModel?.template.create_access && !!activeInterview)
      ? [
          {
            key: 'settings',
            label: (
              <ControlButton
                className="button"
                typeButton="ghost"
                icon="none"
                size="large"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.which === 2) {
                    // Если Ctrl была нажата, открываем ссылку в новой вкладке
                    window.open('/systems/profile/my', '_blank')
                  } else dispatch(navigateTo('/systems/settings/questionnaire'))
                }}
              >
                <Settings />
                Системные настройки{' '}
              </ControlButton>
            )
          }
        ]
      : []),
    {
      key: 'logout',
      label: (
        <ControlButton
          typeButton="ghost"
          icon="none"
          className="button"
          size="large"
          onClick={() => dispatch(getLogout())}
        >
          <Case />
          Выйти из системы
        </ControlButton>
      )
    }
  ]
  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__logo-container`}>
        {/* <Logo /> */}
        {/* Вернуть после НГ */}
        <div className={`${mainCssClass}__NewYear`}>
          <HatNewYear />
        </div>
        <LogoNewYear />
      </div>
      <Menu
        onClick={handleMenuClick}
        mode="vertical"
        defaultSelectedKeys={['candidates']}
        style={{ backgroundColor: 'none' }}
      >
        <Menu.Item
          key="candidates"
          className={`${
            pageId === 'candidates' || pageId === 'candidate' || pageId === undefined
              ? `${mainCssClass}__block-items-selected`
              : ''
          }`}
        >
          <NavLink to="/candidates">
            <div className={`${mainCssClass}__block-items`}>
              <Users
                className={cn(`${mainCssClass}__icon`, {
                  [`${mainCssClass}__icon-selected`]:
                    pageId === 'candidates' || pageId === 'candidate' || pageId === undefined
                })}
              />
              <span>Кандидаты</span>
            </div>
          </NavLink>
        </Menu.Item>
        {rbacModel?.request.page_access === 1 && (
          <Menu.Item
            key="applications"
            className={`${
              pageId === 'applications' || pageId === 'application' ? `${mainCssClass}__block-items-selected` : ''
            }`}
          >
            <NavLink to="/applications">
              <div className={`${mainCssClass}__block-items`}>
                <Star
                  className={cn(`${mainCssClass}__icon`, {
                    [`${mainCssClass}__icon-selected`]: pageId === 'applications' || pageId === 'application'
                  })}
                />
                <span>Заявки</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
        {rbacModel?.interview.page_access === 1 && userInfo?.department !== 175 && (
          <Menu.Item
            key="interviews"
            className={`${
              pageId === 'interviews' || pageId === 'interview' ? `${mainCssClass}__block-items-selected` : ''
            }`}
          >
            <NavLink to="/interviews">
              <div className={`${mainCssClass}__block-items`}>
                <UserVoice
                  className={cn(`${mainCssClass}__icon`, {
                    [`${mainCssClass}__icon-selected`]: pageId === 'interviews' || pageId === 'interview'
                  })}
                />
                <span>Интервью</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
        {userInfo?.role?.role_id !== 'courier_curator' && (
          <Menu.Item
            key="event-funnel"
            className={`${pageId === 'event-funnel' ? `${mainCssClass}__block-items-selected` : ''}`}
          >
            <NavLink to="/event-funnel">
              <div className={`${mainCssClass}__block-items`}>
                <Funnel
                  className={cn(`${mainCssClass}__icon`, {
                    [`${mainCssClass}__icon-selected`]: pageId === 'event-funnel'
                  })}
                />
                <span>Воронка</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {/* {userInfo?.is_can_internship */}

        {/* && ( */}
        {/*  <Menu.Item */}
        {/*    key="internships" */}
        {/*    className={`${ */}
        {/*      pageId === 'internships' ? `${mainCssClass}__block-items-selected` : '' */}
        {/*    }`} */}
        {/*  > */}
        {/*    <NavLink to="/internships"> */}
        {/*      <div className={`${mainCssClass}__block-items`}> */}
        {/*        <Suitcase */}
        {/*          className={cn(`${mainCssClass}__icon`, { */}
        {/*            [`${mainCssClass}__icon-selected`]: pageId === 'internships' */}
        {/*          })} */}
        {/*        /> */}
        {/*        <span>Стажировка</span> */}
        {/*      </div> */}
        {/*    </NavLink> */}
        {/*  </Menu.Item> */}
        {/* )} */}
        {/* { rbacModel?.vacancy.page_access === 1 && userInfo?.role?.role_id !== 'courier_curator' && ( */}
        {/*  <Menu.Item */}
        {/*    key="vacancies" */}
        {/*    className={`${ */}
        {/*      pageId === 'vacancies' ? `${mainCssClass}__block-items-selected` : '' */}
        {/*    }`} */}
        {/*  > */}
        {/*    <NavLink to="/vacancies"> */}
        {/*      <div className={`${mainCssClass}__block-items`}> */}
        {/*        <Case */}
        {/*          className={cn(`${mainCssClass}__icon`, { */}
        {/*            [`${mainCssClass}__icon-selected`]: pageId === 'vacancies' */}
        {/*          })} */}
        {/*        /> */}
        {/*        <span>Вакансии</span> */}
        {/*      </div> */}
        {/*    </NavLink> */}
        {/*  </Menu.Item> */}
        {/* )} */}
        {rbacModel?.task.page_access === 1 && userInfo?.role?.role_id !== 'courier_curator' && (
          <Menu.Item key="tasks" className={`${pageId === 'tasks' ? `${mainCssClass}__block-items-selected` : ''}`}>
            <NavLink to="/tasks">
              <div className={`${mainCssClass}__block-items`}>
                <ListChecklist
                  className={cn(`${mainCssClass}__icon`, {
                    [`${mainCssClass}__icon-selected`]: pageId === 'tasks'
                  })}
                />
                <span>Задачи</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
        {/* { (rbacModel?.analytics.page_access === 1 || userInfo?.can_make_report) && userInfo?.role?.role_id !== 'courier_curator' && ( */}
        {/*  <Menu.Item */}
        {/*    key="analytics" */}
        {/*    className={`${ */}
        {/*      pageId === 'analytics' ? `${mainCssClass}__block-items-selected` : '' */}
        {/*    }`} */}
        {/*  > */}
        {/*    <NavLink to="/analytics"> */}
        {/*      <div className={`${mainCssClass}__block-items`}> */}
        {/*        <Chart */}
        {/*          className={cn(`${mainCssClass}__icon`, { */}
        {/*            [`${mainCssClass}__icon-selected`]: pageId === 'analytics' */}
        {/*          })} */}
        {/*        /> */}
        {/*        <span>Аналитика</span> */}
        {/*      </div> */}
        {/*    </NavLink> */}
        {/*  </Menu.Item> */}
        {/* )} */}
        {rbacModel?.leads.page_access === 1 && (
          <Menu.Item key="leads" className={`${pageId === 'leads' ? `${mainCssClass}__block-items-selected` : ''}`}>
            <NavLink to="/leads">
              <div className={`${mainCssClass}__block-items`}>
                <UsersGroup
                  className={cn(`${mainCssClass}__icon`, {
                    [`${mainCssClass}__icon-selected`]: pageId === 'leads'
                  })}
                />
                <span>Лиды</span>
              </div>
            </NavLink>
          </Menu.Item>
        )}
        {userInfo?.role?.role_id !== 'courier_curator' && (
          <Menu.Item key="menuMode" className={`${mainCssClass}__menu-mode-item`}>
            <Dropdown
              menu={{ items: itemsMenu, selectable: true }}
              placement="right"
              arrow={{ pointAtCenter: true }}
              type="ghost"
              overlayClassName={`${mainCssClass}__dropdown-open`}
            >
              <div className={`${mainCssClass}__dropdown-container-menu`}>
                <Button
                  className={`${mainCssClass}__dropdown-btnm`}
                  type="default"
                  shape="circle"
                  icon={<MoreHorisontal className={`${mainCssClass}__icon-menu`} />}
                  size="small"
                />
                <span className={`${mainCssClass}__block-items`}> Ещё</span>
              </div>
            </Dropdown>
          </Menu.Item>
        )}
        <div className={`${mainCssClass}__line`} />
        {(rbacModel?.vacancy?.create_access === 1 ||
          rbacModel?.request?.create_access === 1 ||
          rbacModel?.candidate?.create_access === 1) && (
          <Menu.Item key="createMode" className={`${mainCssClass}__create-mode-item`}>
            <Dropdown
              menu={{ items, selectable: true }}
              placement="right"
              arrow={{ pointAtCenter: true }}
              type="ghost"
              overlayClassName={`${mainCssClass}__dropdown-open`}
            >
              <div className={`${mainCssClass}__dropdown-container`}>
                {/* <Button */}
                {/*  className={`${mainCssClass}__dropdown-btn`} */}
                {/*  type="primary" */}
                {/*  shape="circle" */}
                {/*  icon={<Plus />} */}
                {/*  size="large" */}
                {/* /> */}
                <div className={`${mainCssClass}__dropdown-ellipse`}>
                  <EllipseNewYaer color="#e5e7eb" />
                </div>
                <div className={`${mainCssClass}__dropdown-union`}>
                  <UnionNewYear color={'#FFD608'} />
                </div>
                <Button
                  className={`${mainCssClass}__dropdown-btn`}
                  type="primary"
                  shape="circle"
                  icon={<Plus color="#FFFFFF" />}
                  size="large"
                />
                <span className={`${mainCssClass}__dropdown-caption`}> Создать</span>
              </div>
            </Dropdown>
          </Menu.Item>
        )}

        <div className={`${mainCssClass}__info-container`}>
          <Information onClick={() => dispatch(setInformationModal(!informationModal))} />
        </div>
        <div className={`${mainCssClass}__bell-container`}>
          {notifications ? (
            <BellNotification onClick={() => dispatch(openNotifications(true))} />
          ) : (
            <NotifySVG onClick={() => dispatch(openNotifications(true))} />
          )}
        </div>

        {/* <Menu.Item key="firstLine" className={cn(`${mainCssClass}__first-line-item`)}> */}
        {/*  <div className={`${mainCssClass}__block-items`}> */}
        {/*    <PaperPlane /> */}
        {/*    <span>Первая линия</span> */}
        {/*  </div> */}
        {/* </Menu.Item> */}

        {/* <Menu.Item key="workChat" className={cn(`${mainCssClass}__chat-line-item`)}> */}
        {/*  <div className={`${mainCssClass}__block-items`}> */}
        {/*    <ChatConversation /> */}
        {/*    <span>Рабочий чат</span> */}
        {/*  </div> */}
        {/* </Menu.Item> */}

        <Menu.Item key="profile" className={`${mainCssClass}__profile-mode-item`}>
          <Dropdown
            className={`${mainCssClass}__dropdown`}
            menu={{ items: itemsProfile, selectable: true }}
            placement="right"
            arrow={{ pointAtCenter: true }}
            type="ghost"
            overlayClassName={`${mainCssClass}__dropdown-open`}
          >
            <div className={`${mainCssClass}__dropdown-container`}>
              {userInfo?.avatar ? (
                <img src={userInfo?.avatar} alt="стрелка" className={`${mainCssClass}__avatar`} />
              ) : (
                <AvatarNavbar />
              )}
              <span className={`${mainCssClass}__dropdown-caption`}> {userInfo?.name}</span>
            </div>
          </Dropdown>
          {/* {createModal && !activeInterview && (
            <>
              <ModalEditCandidate
                editMode={false}
                onCancel={handleCloseAddCandidate}
                open={createModal}
                onOk={handleModalOk}
              />
              <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />
            </>
          )} */}

          {createVacancyModal && !activeInterview && (
            <CreateVacancyModal
              open={createVacancyModal}
              onCancel={handleCancelVacancyModal}
              onOk={handleOkRequestModal}
            />
          )}
        </Menu.Item>

        <NotifyDrawer open={notifyCenter} />
        <CandidateEditDrawer open={editCandidateDrawer} />
        <EditRequestDrawer open={editDrawer} onCancel={handleCancelRequestModal} onOk={handleOkRequestModal} />
        {editDrawer && (
          <ModalCloseSure open={sureRequestModal} onOk={handleOkSureRequest} onCancel={handleCancelSureRequest} />
        )}
        {editCandidateDrawer && (
          <ModalCloseSure open={sureModal} onOk={handleCloseSureOk} onCancel={handleCloseSureCancel} />
        )}
        {activeInterviewModal && <HasActiveModals open={activeInterviewModal} />}
      </Menu>
    </div>
  )
}
export default memo(withErrorBoundary(InnerMenu))
