import { createReducer } from '@reduxjs/toolkit'
import {
  setEmployeesStatusesBars, setEmployeesStatusesDiagram, setEmployeesTableData, setOpenEmployeesCenter,
  setLoadingEmployeesCenter
} from './actions'

type TEmployeesCenterState = {
  openEmployeesCenter: boolean,
  employeesTableData: any[]
  employeesStatusesBars: any[]
  employeesStatusesDiagram: any[]
  loadingEmployeesCenter: boolean
}

const initialState: TEmployeesCenterState = {
  openEmployeesCenter: false,
  employeesTableData: [],
  employeesStatusesBars: [],
  employeesStatusesDiagram: [],
  loadingEmployeesCenter: false

}

const employeesCenterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOpenEmployeesCenter, (state, { payload }) => {
      state.openEmployeesCenter = payload
    })
    .addCase(setEmployeesStatusesBars, (state, { payload }) => {
      state.employeesStatusesBars = payload
    })
    .addCase(setEmployeesStatusesDiagram, (state, { payload }) => {
      state.employeesStatusesDiagram = payload
    })
    .addCase(setEmployeesTableData, (state, { payload }) => {
      state.employeesTableData = payload
    })
    .addCase(setLoadingEmployeesCenter, (state, { payload }) => {
      state.loadingEmployeesCenter = payload
    })
})

export default employeesCenterReducer
