import dayjs from 'dayjs'
import cn from 'classnames'
import React from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import ControlButton from '../../../common/components/Buttons/Button'
import CloseSM from '../../../common/svg/CloseSM'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { formatTimestamp } from '../utils'
import './Notify.scss'
import Ellipse from '../../../common/svg/Ellipse'

type TNotify = {
    title:string,
    text:string,
    time: number,
    unread: boolean
    onClick?: () => void
    onClickRead: (guid: string, unread: boolean) => void;
}

const mainCssClass = getCssClassName('one-notify')

function Notify({
  title, text, onClick, time, unread, onClickRead
} : TNotify) {
  return (
    <div className={mainCssClass} onClick={onClickRead}>
      <div className={`${mainCssClass}_header`}>
        <div className={`${mainCssClass}_header_svg`}>
          <Ellipse fill={unread ? '#D7144B' : '#A7ACA8'} />
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
      <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
        <Typography.Text>
          {formatTimestamp(time)}
        </Typography.Text>
      </div>
    </div>

  )
}

export default Notify
