import React, { FC, memo, useMemo } from 'react'
import { Typography } from 'antd'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './StatusInterviewTableRow.scss'
import { useSelector } from 'react-redux'
import { selectDictionaryOptionsByName } from '../selectors'
import { prepareResultInterview, prepareResultInterviewObject } from '../../InterviewCard/utils'
import cn from 'classnames'

type TProps = {
  status: number
}
const mainCssClass = 'request-name-table'
const StatusInterviewTableRow: FC<TProps> = ({ status }) => {
  const interviewResult = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const result = prepareResultInterviewObject(status, interviewResult)
  const statusObj = useMemo(() => {
    if (result?.value === 96) {
      return { label: result?.label, color: '#DF1E12' }
    }
    if (result?.value === 97) {
      return { label: result?.label, color: '#5A615C' }
    }
    if (result?.value === 98) {
      return { label: result?.label, color: '#2DBE64' }
    }
    if (result?.value === 99) {
      return { label: result?.label, color: '#2DBE64' }
    }
    return {
      label: result?.label || '-', color: '#5A615C'
    }
  }, [result])

  return (
    <div className={mainCssClass} color={statusObj.color}>
      <Typography.Text className={`${mainCssClass}__color-${result?.value ? result.value : 'default'}`}>{statusObj.label}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(StatusInterviewTableRow))
