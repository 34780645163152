import { AxiosResponse } from 'axios'
import { TDictionaries, TDictionariesNames } from '../types/dictionaries'
import $api from './http'

export type TResponseDictionaries = {
  ERR: boolean | string
  DATA: TDictionaries
}
export async function fetchGetV1DictionariesList(dictionaryNames: TDictionariesNames, withParams?: string): Promise<TDictionaries> {
  const queryParams = dictionaryNames.join('%26')
  const response: AxiosResponse<TResponseDictionaries> = await $api.get(
    `/api/v1/dictionary/get-drop-down?${queryParams}${withParams ? `&${withParams}` : ''}`
  )
  const availableData = response.data.DATA
  return availableData
}
