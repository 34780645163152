import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  fill?: string
}

const mainCssClass = 'chat-circle'

function ChatCircle({ className, size = '1', fill }: TSvgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={cn(className, mainCssClass)}>
      <circle id="Ellipse 1" cx="10" cy="10" r="10" fill={!fill ? '#404542' : fill} />
    </svg>
  )
}
export default ChatCircle
