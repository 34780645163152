import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
} from 'chart.js'
import { useSelector } from 'react-redux'
import { selectEmployeesStatusesBars, selectEmployeesStatusesDiagram } from '../selectors'
import { getColorForBars, getColorForDiagram } from '../utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function HorizontalBarChart() {
  const statusesBars = useSelector(selectEmployeesStatusesBars)
  const preparedDataValue = useMemo(() => statusesBars?.map((item) => item.count), [statusesBars])
  const preparedItemColors = useMemo(() => statusesBars?.map((item) => getColorForBars(item.id)), [statusesBars])

  const preparedItemLabels = useMemo(() => statusesBars?.map((item) => item.label), [statusesBars])
  const maxValue = useMemo(() => statusesBars?.reduce((prev, current) => prev + current.count, 0), [statusesBars])
  const dataValue = useMemo(() => statusesBars?.map((item) => maxValue - item.count), [statusesBars, maxValue])

  const data = {
    labels: preparedItemLabels,
    datasets: [
      {
        data: preparedDataValue,
        backgroundColor: preparedItemColors,
        borderWidth: 1,
        stack: 'Stack 0' // Добавить стек для наложений
      },
      {
        data: dataValue,
        backgroundColor: preparedItemLabels.map(() => '#E8ECE9'),
        borderWidth: 1,
        stack: 'Stack 0' // Добавить стек для наложений
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: () => '', // Отключить заголовок подсказки
          label: (context) => {
            const { datasetIndex, parsed } = context
            // Отключить подсказки для второго бара
            if (datasetIndex === 1) {
              return `Всего: ${maxValue}`
            }
            return `${preparedItemLabels[context.dataIndex]}: ${parsed.x}`
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
          drawTicks: false
        },
        stacked: true,
        drawTicks: false,
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false
        },
        border: {
          display: false // Убрать линию по оси X
        }
      },
      y: {
        ticks: {
          display: true
        },
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false,
          offset: true
        },
        border: {
          display: false // Убрать линию по оси Y
        }
      }
    }
  }

  return (
    <div style={{ height: '94px' }}>
      <Bar data={data} options={options} />
    </div>
  )
}

export default HorizontalBarChart
