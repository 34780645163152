import React, { useState, useEffect, memo } from 'react'
import './CalendarMobil.scss'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { Typography } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { TCalendar } from '../../../common/api/calendar'
import { getDayOfWeek } from '../utils'
import { selectOpenModalInterview, selectSelectedInterviewId } from '../selectors'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { getInterview, setOpenMoreInfoModal, setSelectedInterviewId } from '../actions'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

dayjs.locale('ru')
dayjs.extend(calendar)

type TProps = {
  calendars: TCalendar[] | null;
} & TClassName;

const mainCssClass = 'calendar'

function CalendarMobil({ calendars, className }: TProps) {
  const interviewId = useSelector(selectSelectedInterviewId)
  const openMoreInfo = useSelector(selectOpenModalInterview)
  const [activeTimeSlot, setActiveTimeSlot] = useState(dayjs().format('HH:mm'))
  const [updateCount, setUpdateCount] = useState(0)

  const dispatch = useDispatch()

  const handleView = (interviewId?: number) => {
    if (interviewId) {
      dispatch(getInterview(interviewId))
      dispatch(setSelectedInterviewId(interviewId))
      dispatch(setOpenMoreInfoModal(true))
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs().add(1, 'minute').format('HH:mm')
      setActiveTimeSlot(currentTime)
      setUpdateCount((prevCount) => prevCount + 1)
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className={cn(mainCssClass, className)}>
      <table className={`${mainCssClass}__table`}>
        <thead>
          <tr>
            {calendars?.map((calendarItem) => (
              <th key={calendarItem.date}>
                <div />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendars?.[0]?.slots
          && Object.keys(calendars[0].slots).map((timeSlot) => (
            <tr key={timeSlot}>
              {calendars?.map((calendarItem) => {
                const slotDetails = calendarItem.slots[timeSlot]
                const [startTime, endTime] = timeSlot.split('-') // Разбиваем диапазон времени
                const isCurrentTimeSlot = timeSlot >= startTime && timeSlot <= endTime
                const isActiveTimeSlot = activeTimeSlot >= startTime && activeTimeSlot <= endTime
                  && calendarItem?.date === dayjs().format(DATE_DOTS_FORMAT)

                return (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {slotDetails && calendarItem?.is_blocked !== 1 ? (
                      <td
                        key={calendarItem.date + timeSlot}
                        className={cn(mainCssClass, {
                          [`${mainCssClass}__centrium`]: !isActiveTimeSlot,
                          [`${mainCssClass}__current-time-slot`]: isActiveTimeSlot
                        })}
                      >
                        <div className={isActiveTimeSlot ? (slotDetails?.interview_id
                          ? `${mainCssClass}__current-time-marker-with-card` : `${mainCssClass}__current-time-marker`) : ''}
                        >
                          {/* Рисуйте красную линию и точку как метку текущего времени */}
                        </div>
                        {slotDetails?.interview_id && (
                          <div
                            onClick={() => handleView(slotDetails?.interview_id)}
                            className={cn(
                              `${mainCssClass}__slot-detail_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__slot-detail_${slotDetails?.confirm}_selected`]:
                                slotDetails?.interview_id === interviewId
                              }
                            )}
                          >
                            <Typography.Paragraph className={cn(
                              `${mainCssClass}__candidate-label_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__candidate-label_${slotDetails?.confirm}_selected`]:
                                slotDetails?.interview_id === interviewId
                              }
                            )}
                            >
                              {slotDetails?.candidate_label || 'Нет информации'}
                            </Typography.Paragraph>
                            <Typography.Paragraph className={cn(
                              `${mainCssClass}__timeslot-label_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__timeslot-label_${slotDetails?.confirm}_selected`]:
                                slotDetails?.interview_id === interviewId
                              }
                            )}
                            >
                              {timeSlot}
                            </Typography.Paragraph>
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className={cn(`${mainCssClass}__empty-slot`, {

                        [`${mainCssClass}__centrium`]: !isActiveTimeSlot,
                        [`${mainCssClass}__current-time-slot`]: isActiveTimeSlot
                      })}
                      />
                    )}
                    <tr>
                      <td />
                      <td className={`${mainCssClass}__centrium`}>
                        {calendars?.map((calendarItem) => {
                          const cancelledSlots = Object.keys(calendarItem?.cancelled || {})

                          return (
                            cancelledSlots.map((cancelledSlot) => {
                              const cancelledSlotDetails = calendarItem?.cancelled[cancelledSlot]

                              return (
                                <div
                                  key={`cancelled-${cancelledSlot}`}
                                  onClick={() => handleView(cancelledSlotDetails.interview_id)}
                                  className={`${mainCssClass}__cancelled-slot`}
                                >
                                  <Typography.Paragraph className={cn(
                                    `${mainCssClass}__candidate-label_${cancelledSlotDetails.confirm}`,
                                    {
                                      [`${mainCssClass}__candidate-label_${cancelledSlotDetails.confirm}_selected`]:
                                            cancelledSlotDetails.interview_id === interviewId
                                    }
                                  )}
                                  >
                                    {cancelledSlotDetails.candidate_label || 'Нет информации'}
                                  </Typography.Paragraph>
                                  <Typography.Paragraph className={cn(
                                    `${mainCssClass}__timeslot-label_${cancelledSlotDetails.confirm}`,
                                    {
                                      [`${mainCssClass}__timeslot-label_${cancelledSlotDetails.confirm}_selected`]:
                                            cancelledSlotDetails.interview_id === interviewId
                                    }
                                  )}
                                  >
                                    {cancelledSlot}
                                  </Typography.Paragraph>
                                </div>
                              )
                            })
                          )
                        })}
                      </td>
                    </tr>
                  </>
                )
              })}
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  )
}
export default memo(withErrorBoundary(CalendarMobil))
