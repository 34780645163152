import {
  put, takeLatest, spawn, race, take
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import {
  createCommentRequest,
  getDictionaries,
  getRequest,
  setDictionaries,
  setLoading,
  setRequest,
  updateCandidateStatus,
  getHistoryRequestData, setHistoryRequestData, TRequestHistoryRequestData, setHeaders, setRequestHistoryLoading,
  getRequestHistoryV2,
  setRequestHistoryV2
} from './actions'
import {
  TCommentRequest, TResponseOneRequest
} from './types'
import {
  fetchGetHistoryRequestData,
  fetchGetRequest,
  fetchGetRequestHistoryV2,
  fetchPostCommentRequest, TResponseHistoryRequestData
} from '../../common/api/request'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'
import {
  fetchPatchUpdateStatusCandidate
} from '../../common/api/Candidate'
import { TArchiveCandidateRequest } from '../../features/candidateModals/actions'
import { TCandidate } from '../candidate/types'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { setLoadingProgress } from '../InnerRouter/actions'

type RequestResponse = AxiosResponse<TResponseOneRequest>

const moduleName = 'Страница заявки'
function* getRequestSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchGetRequest(payload),
      cancel: take(getRequest.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequest(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getHistoryRequestDataSaga({ payload }: PayloadAction<TRequestHistoryRequestData>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setRequestHistoryLoading(true))
    const { response } = yield race({
      response: fetchGetHistoryRequestData(payload),
      cancel: take(getHistoryRequestData.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setHistoryRequestData(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setRequestHistoryLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createCommentRequestSaga({ payload }: PayloadAction<TCommentRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostCommentRequest(payload),
      cancel: take(createCommentRequest.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить комментарий',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequest(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'страница заявки')
}

function* updateCandidateStatusSaga({ payload }: PayloadAction<TArchiveCandidateRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPatchUpdateStatusCandidate(payload),
      cancel: take(updateCandidateStatus.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обнавить статус',
          error: response as AxiosError
        })
      )
    } else if (response.data.DATA.request_id) {
      yield spawn(getRequestSaga, getRequest(String(response.data.DATA.request_id)))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getHistoryRequestV2Saga({ payload }: PayloadAction<TRequestHistoryRequestData>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setRequestHistoryLoading(true))
    const { response } = yield race({
      response: fetchGetRequestHistoryV2(payload),
      cancel: take(getRequestHistoryV2.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequestHistoryV2(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setRequestHistoryLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* requestSaga() {
  yield takeLatest(getRequest, getRequestSaga)
  yield takeLatest(getHistoryRequestData, getHistoryRequestDataSaga)
  yield takeLatest(getRequestHistoryV2, getHistoryRequestV2Saga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(createCommentRequest, createCommentRequestSaga)
  yield takeLatest(updateCandidateStatus, updateCandidateStatusSaga)
}

export default requestSaga
