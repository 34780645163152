import { createReducer } from '@reduxjs/toolkit'
import {
  setBaseUrl,
  setSubUrl,
  setUserInfo,
  setLoading,
  TPreviousPage,
  OpenInnerMenuMobil,
  OpenFilterMobil,
  setPreviousPage,
  setVisibleTable,
  setRbacInfo,
  OpenSettingsCalendarMobil,
  setDestinationNumber,
  setPauseCall,
  setHangUpCall,
  setResumeCall,
  setTransferCall,
  setCandidateSelected,
  setLoadingModal,
  showOpenModalRefreshByTime,
  showOpenModalFrontRefreshByVersion,
  setVersionFront,
  setStatusesMobiles,
  setCurrentStatus,
  setFullVersion,
  openPhoneModal,
  setStatusPhone,
  setInternalInformationByUserId,
  TInternalInformationData,
  setModalAutoCallOpen,
  setModalAutoCallId,
  setOptionsAutoCall,
  setLoadingAutoCall, setLoadingProgress, openQueueModal, setConectedWss, setMessageWhatsApp,
  setUserActivity, setCallMessage, setLeadMessage, setCurrentRtt,
  setEditKeyDetailList, setIconNumber
} from './actions'
import { TBaseUrl } from './types'
import { navigateTo, setIsFirstLogin } from '../../common/actions/navigate'
import { TEntity } from '../../common/types/entity'
import { TRbacControl } from '../Systems'
import { TDATA } from '../candidate/types'
import { TOption } from '../../common/types/dictionaries'

type TState = {
  navigateToUrl: string | null
  baseUrl: TBaseUrl | null
  subUrl: string | null
  userInfo: TEntity | null
  loading: boolean
  previousPage: TPreviousPage | null
  visibleTable: boolean
  rbacInfo: TRbacControl | null
    openMobilMenu:boolean
  filterMobil: boolean
  openSettings:boolean
  destinationNumber: number | string | null
  pauseCall: boolean
  resumeCall: boolean
  hangUpCall: boolean
  transferCall: number | string | null
  candidate: TDATA | null
  loadingModal: boolean
  systemColor: string
  statusesMobiles: TOption[]
  openModalRefreshByTime:boolean
  openModalFrontRefreshByVersion:boolean
  versionFront: string
  currentStatusMobile: TOption | null
  fullVersion: boolean
  phoneModal: boolean
  statusPhone: boolean
  internalInformationByUserId: TInternalInformationData | null
  modalAutoCallOpen: boolean
  modalAutoCallId: number | null
  optionsAutoCall: TOption[]
  loadingAutoCall: boolean
  isFirstLogin:boolean
  loadingProgress: boolean
  queueModal: boolean
  connectedAsterisk: boolean
  userActivity: any
  messageWhatsApp: any
  callMessage: any
  leadMessage: any
  currentRtt: any
  editKeyDetailList: string
  iconNumber: number
}

const initialState: TState = {
  navigateToUrl: null,
  baseUrl: null,
  subUrl: null,
  userInfo: null,
  loading: false,
  previousPage: null,
  visibleTable: true,
  rbacInfo: null,
  openMobilMenu: false,
  openModalRefreshByTime: false,
  filterMobil: false,
  openSettings: false,
  destinationNumber: null,
  pauseCall: false,
  resumeCall: false,
  hangUpCall: false,
  transferCall: null,
  candidate: null,
  loadingModal: false,
  systemColor: 'blue',
  openModalFrontRefreshByVersion: false,
  versionFront: '',
  statusesMobiles: [],
  currentStatusMobile: null,
  fullVersion: false,
  phoneModal: false,
  statusPhone: false,
  internalInformationByUserId: null,
  modalAutoCallOpen: false,
  modalAutoCallId: null,
  optionsAutoCall: [],
  loadingAutoCall: false,
  isFirstLogin: false,
  loadingProgress: false,
  queueModal: false,
  connectedAsterisk: false,
  userActivity: null,
  messageWhatsApp: null,
  callMessage: null,
  leadMessage: null,
  currentRtt: null,
  editKeyDetailList: '',
  iconNumber: 1
}

export const innerRouterReducer = createReducer(initialState, (builder) => builder
  .addCase(setBaseUrl, (state, { payload }) => {
    state.baseUrl = payload
  })
  .addCase(setSubUrl, (state, { payload }) => {
    state.subUrl = payload
  })
  .addCase(navigateTo, (state, { payload }) => {
    state.navigateToUrl = payload
  })
  .addCase(setUserInfo, (state, { payload }) => {
    state.userInfo = payload
  })
  .addCase(setLoading, (state, { payload }) => {
    state.loading = payload
  })
  .addCase(setPreviousPage, (state, { payload }) => {
    state.previousPage = payload
  })
  .addCase(setVisibleTable, (state, { payload }) => {
    state.visibleTable = payload
  })
  .addCase(setRbacInfo, (state, { payload }) => {
    state.rbacInfo = payload
  })
  .addCase(OpenSettingsCalendarMobil, (state, { payload }) => {
    state.openSettings = payload
  })
  .addCase(OpenInnerMenuMobil, (state, { payload }) => {
    state.openMobilMenu = payload
  })
  .addCase(setDestinationNumber, (state, { payload }) => {
    state.destinationNumber = payload
  })
  .addCase(setPauseCall, (state, { payload }) => {
    state.pauseCall = payload
  })
  .addCase(setHangUpCall, (state, { payload }) => {
    state.hangUpCall = payload
  })
  .addCase(setLoadingProgress, (state, { payload }) => {
    state.loadingProgress = payload
  })
  .addCase(setResumeCall, (state, { payload }) => {
    state.resumeCall = payload
  })
  .addCase(setTransferCall, (state, { payload }) => {
    state.transferCall = payload
  })
  .addCase(setLoadingModal, (state, { payload }) => {
    state.loadingModal = payload
  })
  .addCase(setCandidateSelected, (state, { payload }) => {
    state.candidate = payload
  })
  .addCase(showOpenModalRefreshByTime, (state, { payload }) => {
    state.openModalRefreshByTime = payload
  })
  .addCase(setVersionFront, (state, { payload }) => {
    state.versionFront = payload
  })
  .addCase(showOpenModalFrontRefreshByVersion, (state, { payload }) => {
    state.openModalFrontRefreshByVersion = payload
  })
  .addCase(setCurrentStatus, (state, { payload }) => {
    state.currentStatusMobile = payload
  })
  .addCase(setFullVersion, (state, { payload }) => {
    state.fullVersion = payload
  })
  .addCase(setStatusesMobiles, (state, { payload }) => {
    state.statusesMobiles = payload
  })
  .addCase(setStatusPhone, (state, { payload }) => {
    state.statusPhone = payload
  })
  .addCase(setLoadingAutoCall, (state, { payload }) => {
    state.loadingAutoCall = payload
  })
  .addCase(setModalAutoCallOpen, (state, { payload }) => {
    state.modalAutoCallOpen = payload
  })
  .addCase(setModalAutoCallId, (state, { payload }) => {
    state.modalAutoCallId = payload
  })
  .addCase(setOptionsAutoCall, (state, { payload }) => {
    state.optionsAutoCall = payload
  })
  .addCase(setInternalInformationByUserId, (state, { payload }) => {
    state.internalInformationByUserId = payload
  })
  .addCase(setIsFirstLogin, (state, { payload }) => {
    state.isFirstLogin = payload
  })
  .addCase(openPhoneModal, (state, { payload }) => {
    state.phoneModal = payload
  })
  .addCase(openQueueModal, (state, { payload }) => {
    state.queueModal = payload
  })
  .addCase(setConectedWss, (state, { payload }) => {
    state.connectedAsterisk = payload
  })
  .addCase(setUserActivity, (state, { payload }) => {
    state.userActivity = payload
  })
  .addCase(setMessageWhatsApp, (state, { payload }) => {
    state.messageWhatsApp = payload
  })
  .addCase(setCallMessage, (state, { payload }) => {
    state.callMessage = payload
  })
  .addCase(setLeadMessage, (state, { payload }) => {
    state.leadMessage = payload
  })
  .addCase(setCurrentRtt, (state, { payload }) => {
    state.currentRtt = payload
  })
  .addCase(setEditKeyDetailList, (state, { payload }) => {
    state.editKeyDetailList = payload
  })
  .addCase(setIconNumber, (state, { payload }) => {
    state.iconNumber = payload
  }))
