import React, { FC, memo, useMemo } from 'react'
import { Typography } from 'antd'
import './StatusInterviewTableRowMobil.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'

type TProps = {
    status: number
  candidate_name:string
  period:string
  date:string
  confirm:string
}
const mainCssClass = 'interview-name-table'
const StatusInterviewTableRowMobil: FC<TProps> = ({
  status, candidate_name, period, date, confirm
}) => {
  const statusStr = useMemo(() => {
    if (status === 93) {
      return 'Отменено'
    }
    if (status === 94) {
      return 'Назначено'
    }
    if (status === 95) {
      return 'Проведено'
    } return 'Не определен'
  }, [status])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__title`}>
        <Typography.Title level={4} className={`${mainCssClass}__title-text`}>
          {candidate_name}
        </Typography.Title>

        <Typography.Text className={`${mainCssClass}__coust`}>
          {date}
          {' '}
          |
          {' '}
          {period}

        </Typography.Text>
        <Typography.Text className={`${mainCssClass}__coust`}>
          {statusStr}
        </Typography.Text>

      </div>
      <div className={`${mainCssClass}__arrow`}>
        <ArrowLeftSM />

      </div>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(StatusInterviewTableRowMobil))
