import React, {
  memo, useEffect
} from 'react'

import './ModalAutoCall.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getInternalAutoCall, getStartAutoCall, setModalAutoCallOpen } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import { selectLoadingAutoCall, selectModalAutoCallOptions } from '../selectors'
import { Preloader } from '../../../common/components/Preloader/Preloader'

const mainCssClass = 'modal-auto-call'
type TProps ={
  open: boolean
}
function ModalAutoCall({ open }: TProps) {
  const dispatch = useDispatch()
  const { control, watch } = useForm()
  const autoCallId = watch('autoCall')
  const optionsAutoCall = useSelector(selectModalAutoCallOptions)
  const loading = useSelector(selectLoadingAutoCall)
  const handleModalClose = () => {
    dispatch(setModalAutoCallOpen(false))
  }
  const handleOk = () => {
    if (autoCallId) {
      dispatch(getStartAutoCall(autoCallId))
    }
  }
  useEffect(() => {
    dispatch(getInternalAutoCall())
  }, [])

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleModalClose}
      title="Запуск автообзвона"
      className={mainCssClass}
      width="400px"
      typeButtonAccept="primary"
      okText="Запустить"
      cancelText="Выйти"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      {loading && <Preloader />}
      { !loading && (
        <SelectFormControl
          className={`${mainCssClass}__auto-call`}
          name="autoCall"
          options={optionsAutoCall}
          control={control}
        />
      )}

    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalAutoCall))
