import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'
import { TResume } from './types'

const selectState = ({ resume }: TRootState) => resume

export const selectCandidateResume = createSelector(selectState, ({ resume_list }) => resume_list)

export const selectSkillsModal = createSelector(selectState, ({ skillsModal }) => skillsModal)
export const selectEditSkillsModal = createSelector(selectState, ({ editSkillsModal }) => editSkillsModal)

export const selectResume = (resumeId: string | number) =>
  createSelector(
    selectCandidateResume,
    (resumeList: TResume[] | undefined) => resumeList?.find((resume) => resume.id === resumeId) || null
  )

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) =>
  createSelector(
    selectDictionariesMap,
    // @ts-ignore
    (dictionaries) => dictionaries[name] || []
  )

export const selectActiveResume = createSelector(selectState, ({ resume }) => resume)

export const selectCertificateModal = createSelector(selectState, ({ certificateModal }) => certificateModal)

export const selectEditCertificateModal = createSelector(
  selectState,
  ({ editCertificateModal }) => editCertificateModal
)

export const selectRecomendationModal = createSelector(selectState, ({ recomendationModal }) => recomendationModal)

export const selectEditRecomendationModal = createSelector(
  selectState,
  ({ editRecomendationModal }) => editRecomendationModal
)

export const selectInfoModal = createSelector(selectState, ({ infoModal }) => infoModal)
export const selectEditInfoModal = createSelector(selectState, ({ editInfoModal }) => editInfoModal)

export const selectLanguageModal = createSelector(selectState, ({ languageModal }) => languageModal)
export const selectEditLanguageModal = createSelector(selectState, ({ editLanguageModal }) => editLanguageModal)

export const selectCoursesModal = createSelector(selectState, ({ coursesModal }) => coursesModal)
export const selectEditCoursesModal = createSelector(selectState, ({ editCoursesModal }) => editCoursesModal)

export const selectRelocateModal = createSelector(selectState, ({ relocateModal }) => relocateModal)
export const selectEditRelocateModal = createSelector(selectState, ({ editRelocateModal }) => editRelocateModal)

export const selectTransferModal = createSelector(selectState, ({ transferModal }) => transferModal)
export const selectEditTransferModal = createSelector(selectState, ({ editTransferModal }) => editTransferModal)

export const selectEducationModal = createSelector(selectState, ({ educationModal }) => educationModal)
export const selectEditEducationModal = createSelector(selectState, ({ editEducationModal }) => editEducationModal)

export const selectExperienceModal = createSelector(selectState, ({ experienceModal }) => experienceModal)
export const selectEditExperienceModal = createSelector(selectState, ({ editExperienceModal }) => editExperienceModal)

export const selectWishesModal = createSelector(selectState, ({ wishesModal }) => wishesModal)
export const selectEditWishesModal = createSelector(selectState, ({ editWishesModal }) => editWishesModal)

export const selectLoading = createSelector(selectState, ({loading}) => loading)
