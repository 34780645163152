import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'paperPlane'

function PaperPlane({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4108 4.55761C19.1925 4.6035 18.8931 4.6946 18.4056 4.8446L5.11098 8.93524C4.4962 9.1244 4.10012 9.24724 3.82803 9.35787C3.8062 9.36674 3.7863 9.37513 3.7682 9.38299C3.78472 9.39369 3.80293 9.40521 3.82296 9.41752C4.07316 9.57137 4.44366 9.75764 5.01899 10.0453L10.0332 12.5524L14.4467 8.13899C14.8372 7.74847 15.4704 7.74847 15.8609 8.13899C16.2514 8.52952 16.2514 9.16268 15.8609 9.5532L11.4475 13.9666L13.9546 18.9809C14.2422 19.5561 14.4286 19.9266 14.5825 20.1767C14.5948 20.1967 14.6064 20.215 14.6171 20.2316C14.6249 20.2135 14.6333 20.1937 14.6421 20.172C14.7527 19.8999 14.8756 19.5039 15.0647 18.8891L19.1554 5.59449C19.3054 5.10693 19.3965 4.80745 19.4425 4.58909C19.4454 4.57496 19.4481 4.56183 19.4504 4.54968C19.4382 4.55199 19.425 4.55462 19.4108 4.55761ZM18.9993 2.6004C19.3179 2.53341 19.7461 2.47529 20.1779 2.6338C20.7288 2.83606 21.1638 3.27032 21.3663 3.82203C21.5249 4.25393 21.4666 4.68226 21.3996 5.00079C21.3326 5.31946 21.2132 5.70747 21.0802 6.13955L16.9626 19.5219C16.7909 20.08 16.6427 20.5617 16.4948 20.9253C16.3569 21.2645 16.1342 21.7353 15.6842 22.0165C15.1357 22.3592 14.4556 22.4155 13.8582 22.1676C13.3683 21.9644 13.0711 21.5368 12.8791 21.2248C12.6728 20.8894 12.4466 20.437 12.1845 19.9128L9.57481 14.6935C9.52741 14.5987 9.5038 14.5518 9.48548 14.5184C9.48496 14.5175 9.48446 14.5166 9.48399 14.5157C9.4834 14.5154 9.48281 14.5151 9.48219 14.5147C9.4485 14.4963 9.40162 14.4727 9.30647 14.4251L4.08238 11.8131C3.56031 11.5521 3.10961 11.3267 2.77538 11.1212C2.4635 10.9294 2.03558 10.6323 1.83221 10.1422C1.58424 9.54458 1.64088 8.86412 1.98347 8.31581C2.26464 7.86581 2.73557 7.64305 3.07474 7.50515C3.43832 7.35733 3.92009 7.20911 4.47817 7.03742C4.49299 7.03286 4.50787 7.02828 4.52281 7.02368L17.8595 2.92008C18.292 2.78697 18.6804 2.66745 18.9993 2.6004Z" fill="#404542" />
    </svg>

  )
}
export default PaperPlane
