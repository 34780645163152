import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

function CandidateAddEvent({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#E1FAE6" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17 13C15.3431 13 14 14.3431 14 16C14 17.6569 15.3431 19 17 19C18.6569 19 20 17.6569 20 16C20 14.3431 18.6569 13 17 13ZM12 16C12 13.2386 14.2386 11 17 11C19.7614 11 22 13.2386 22 16C22 18.7614 19.7614 21 17 21C14.2386 21 12 18.7614 12 16ZM27 17C27.5523 17 28 17.4477 28 18V20H30C30.5523 20 31 20.4477 31 21C31 21.5523 30.5523 22 30 22H28V24C28 24.5523 27.5523 25 27 25C26.4477 25 26 24.5523 26 24V22H24C23.4477 22 23 21.5523 23 21C23 20.4477 23.4477 20 24 20H26V18C26 17.4477 26.4477 17 27 17ZM12.2027 23.3395C13.4742 22.4918 15.1733 22 17 22C18.8267 22 20.5258 22.4918 21.7973 23.3395C23.0619 24.1826 24 25.457 24 27C24 27.5523 23.5523 28 23 28C22.4477 28 22 27.5523 22 27C22 26.3338 21.5949 25.6083 20.6879 25.0036C19.7879 24.4036 18.487 24 17 24C15.513 24 14.2121 24.4036 13.3121 25.0036C12.4051 25.6083 12 26.3338 12 27C12 27.5523 11.5523 28 11 28C10.4477 28 10 27.5523 10 27C10 25.457 10.9381 24.1826 12.2027 23.3395Z" fill="#2DBE64" />
    </svg>

  )
}
export default CandidateAddEvent
