import axios, { AxiosResponse } from 'axios'
import $api from './http'
import {
  TAddStageRequest,
  TCreateFieldConfig,
  TFieldConfig,
  TFieldConfigRequest,
  TQuestionnare,
  TRequestCallbacks,
  TRequestCreateCallback,
  TRequestCreateDictionaryOptions,
  TRequestCreateQuestionnaire,
  TRequestCreateToken,
  TRequestCreateWhatsappTemplate,
  TRequestPAssword,
  TRequestPatchStageState,
  TRequestPinWhatsappTemplate,
  TRequestTokens,
  TRequestUpdateCallback,
  TRequestUpdateDictionaryOptions,
  TRequestUpdateReminder,
  TRequestUpdateToken,
  TRequestUpdateTrigger,
  TRequestUpdateVertices,
  TRequestUpdateWhatsappTemplate,
  TRequestWhatsappTemplates,
  TRoleSetting,
  TTriggers,
  TUpdateFieldConfig,
  TUpdateStageLimitation,
  TUpdateStageRequest,
  updateStageLimitation
} from '../../compositions/Systems'
import { TPaginationRequest } from '../types/pagination'
import { TResponse } from '../types/response'

export type TQuestionnaireResponse = {
  ERR: boolean
  DATA: TQuestionnare[]
}

export async function fetchGetQuestionnaire(): Promise<AxiosResponse<TQuestionnaireResponse>> {
  const response = await $api.get('/api/v1/questionnares?sort=-id&per-page=10000')
  return response
}

export type TRequestStatusQuestionnaire = {
  id: number
  status: number
}

export type TRequestStatusDictionary = {
  key: string
  is_archive: boolean
}

export async function fetchPatchStatusQuestionnaire(payload: TRequestStatusQuestionnaire): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/questionnares/${payload.id}`, payload)
  return response
}

export type TRequestStatusArchive = {
  id: string
  payloadData: {
    is_archive?: number
    role_id?: string
  }
}
export async function fetchPostCreateQuestionnaire(payload: TRequestCreateQuestionnaire): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/questionnare/set-questions', payload)
  return response
}

export async function fetchPostUpdateQuestionnaire(payload: TRequestCreateQuestionnaire): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/questionnare/update-questions', payload)
  return response
}

export async function fetchPostValidatePassword(payload: TRequestPAssword): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/user/validate-password', payload)
  return response
}

export async function fetchGetTriggers(): Promise<AxiosResponse<TResponseTriggers>> {
  const response = await $api.get('/api/v1/trigger-actions?sort=-id&per-page=10000')
  return response
}

export async function fetchGetVersion(): Promise {
  const response = await $api.get('/api/v1/system/version')
  return response
}

export type TResponseTrigger = {
  err: string
  DATA: TTriggers
}

export type TResponseTriggers = {
  err: string
  DATA: TTriggers[]
}

export type TRequestCreateTrigger = {
  name: string
  table_name: string
  criteria: string
  event: string
  callback: string
  holder_list: string | number[]
  template_subject?: string
  template_id?: number
}

export async function fetchPostCreateTrigger(payload: TRequestCreateTrigger): Promise<AxiosResponse<TResponseTrigger>> {
  const response = await $api.post('/api/v1/trigger-actions', payload)
  return response
}

export async function fetchGetCreateLinkTrigger(payload: TLinkTrigger): Promise<AxiosResponse<any>> {
  const response = await $api.get(
    `api/v1/trigger-template/create-link?trigger_action_id=${payload.trigger_action}&trigger_template_id=${payload.trigger_template}`
  )
  return response
}

type TLinkTrigger = {
  trigger_action: number
  trigger_template?: number
}

export async function fetchDeleteTrigger(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/trigger-actions/${payload}`)
  return response
}

// export async function fetchGetDeleteLinkTrigger(payload: TLinkTrigger): Promise<AxiosResponse<any>> {
//   const data = await $api.delete(`/api/v1/trigger-template/remove-link?trigger_to_template_id=${payload.trigger_template}`)
//   return data
// }

export async function fetchPatchUpdateTrigger(
  payload: TRequestUpdateTrigger
): Promise<AxiosResponse<TResponseTrigger>> {
  const response = await $api.patch(`/api/v1/trigger-actions/${payload.id}`, payload)
  return response
}

export async function fetchGetTemplates(): Promise<AxiosResponse<any>> {
  const response = await $api.get('/api/v1/trigger-templates?sort=-id&per-page=10000')
  return response
}

export type TRequestCreateTemplate = {
  name: string
  text: string
  params: any
}

export async function fetchPostCreateTemplate(payload: TRequestCreateTemplate): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/trigger-templates', payload)
  return response
}

export type TRequestUpdateTemplate = {
  id: number
  name: string
  text: string
  params: any
}

export async function fetchPatchUpdateTemplate(payload: TRequestUpdateTemplate): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/trigger-templates/${payload.id}`, payload)
  return response
}

export async function fetchDeleteTemplate(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/trigger-templates/${payload}`)
  return response
}

export async function fetchGetDictionaries(): Promise<AxiosResponse<any>> {
  const response = await $api.get('/api/v1/dictionaries?sort=id&per-page=10000')
  return response
}

export async function fetchPatchStatusDictionary(payload: TRequestStatusDictionary): Promise<AxiosResponse<any>> {
  const response = await $api.patch('/api/v1/dictionary/update', payload)
  return response
}

export async function fetchGetDictionary(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/dictionary-options?filter[dictionary_id]=${payload}&page=1&per-page=1000`)
  return response
}

export async function fetchPatchDictionaryOptions(
  payload: TRequestUpdateDictionaryOptions
): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/dictionary-options/${payload.id}`, payload)
  return response
}

export async function fetchPostDictionaryOptions(
  payload: TRequestCreateDictionaryOptions
): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/dictionary-options', payload)
  return response
}

export type TVertices = {
  id: number
  key: string
  name: string
  description: null | string
  priority: number
  created_at: string
  updated_at: string
  department: number
}

export type TVerticesResponse = {
  ERR: boolean
  DATA: TVertices[]
}

export async function fetchGetVertices(): Promise<AxiosResponse<TVerticesResponse>> {
  const response = await $api.get('/api/v1/vertices?sort=id')
  return response
}
export type TStore = {
  id?: number
  name: string
  is_archive?: boolean
  address?: string
  dadata_city_key?: string
  city: string
  metro?: string
  longtitude: number
  lattitude: number
  created_at?: string
  updated_at?: string
  type: number | null
}
export type TStoreResponse = {
  DATA: TStore[]
  ERR: string
  Headers: {
    'x-pagination-total-count': number[]
    'x-pagination-page-count': number[]
    'x-pagination-current-page': number[]
  }
}
export async function fetchGetStores(payload: TPaginationRequest): Promise<AxiosResponse<TStoreResponse>> {
  const response = await $api.get(
    `/api/v1/stores?sort=is_archive,-updated_at&per-page=${payload.perPage || 10}&page=${payload.page || 1}${payload.filters ? payload.filters : ''}`
  )
  return response
}

export async function fetchPostStore(requestData: TStore): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/stores', requestData)
  return response
}
export async function fetchPatchStore(requestData: TStore): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/stores/${requestData.id}`, requestData)
  return response
}
export async function fetchDeleteStore(requestData: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/stores/${requestData}`)
  return response
}
export type TUser = {
  id?: number
  email?: string
  password?: string
  phone?: string
  surname?: string
  telegram_channel_chat_id?: string
  telegram_chat_id?: string
  telegram_tag?: string
  name?: string
  seat?: string
  patronymic?: string
  status?: number
  role?:
    | {
        role_id: string
        description?: string
        access_list?: string
      }
    | string
  isColdSearch?: boolean
  isCanAppointRecruiter: boolean
  department?: number
  city_id?: number
  avatar?: string
  created_at?: string
  updated_at?: string
  group_list: number[]
  can_assign_calendar_future_days: boolean
  is_can_conduct_interview: boolean
  can_make_report: boolean
  token_plugin: string
  is_tester: boolean
  is_mass_stage: boolean
  sip_login: string
  sip_password: string
  is_can_internship: boolean
  is_delete_any_comment: boolean
}

export type TStage = {
  id: number
  name: string
  description: null | string
  priority: number
  vertex_id: number
  position: number
  created_at: string
  updated_at: string
  is_finalist: null | number
  is_process: null | number
  is_accepted: null | number
  status: number | null
}

export type TStagesResponse = {
  ERR: boolean
  DATA: TStage[]
}

export async function fetchGetStages(): Promise<AxiosResponse<TStagesResponse>> {
  const response = await $api.get('/api/v1/stages?sort=priority&page=1&per-page=1000')
  return response
}

export async function fetchPostCreateStage(payload: TAddStageRequest): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/stages', payload)
  return response
}

export async function fetchPatchUpdateStage(payload: TUpdateStageRequest): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/stages/${payload.id}`, payload)
  return response
}

export type TRequestSortStage = {
  stage_list: { stage_id: number; priority: number }[]
}

export async function fetchPostSortStage(payload: TRequestSortStage): Promise<AxiosResponse<TStagesResponse>> {
  const response = await $api.post('/api/v1/stage/update-sort', payload)
  return response
}

export type TRoleRbac = {
  role_id: string
  role_name: string
  is_archive: number
}
export type TRbacRoleList = {
  role_list: TRoleRbac[]
}
export type TRbacRoleListResponse = {
  ERR: string
  DATA: TRbacRoleList
}

export async function fetchGetRoleList(): Promise<AxiosResponse<TRbacRoleListResponse>> {
  const response = await $api.get('/api/v1/rbac/get-role-list')
  return response
}
export async function fetchGetUsersList(payload: TPaginationRequest): Promise<AxiosResponse<TUser[]>> {
  const response = await $api.get(
    `/api/v1/users?sort=-updated_at&fields=id,avatar,name,surname,patronymic,status,email,phone,role&per-page=${payload.perPage || 10}&page=${payload.page || 1}${payload.filters ? payload.filters : ''}`
  )
  return response
}
export async function fetchGetSelectUser(payload: number) {
  const response = await $api.get(`/api/v1/users?filter[id][in][]=${payload}`)
  return response
}
export async function fetchPatchUser(body: TUser): Promise<AxiosResponse<TUser>> {
  const response = await $api.patch('/api/v1/user/update', body)
  return response
}
export async function fetchPostUser(body: TUser): Promise<AxiosResponse<TUser>> {
  const response = await $api.post('/api/v1/user/create', body)
  return response
}
export async function fetchDeleteUser(body): Promise<AxiosResponse<any>> {
  const response = await $api.delete('/api/v1/user/delete', body)
  return response
}
export async function fetchPatchUpdateRoleArchive(payload: TRequestStatusArchive): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/rbac/update-role?role_id=${payload.id}`, payload.payloadData)
  return response
}

export async function fetchDeleteRoleArchive(payload: TRequestStatusArchive): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/rbac/delete-role?role_id=${payload.id}`)
  return response
}
export async function fetchPatchUpdateRole(payload: TRoleSetting): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/rbac/update-role?role_id=${payload.role_id}`, payload)
  return response
}
export async function fetchGetRole(payload: string): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/rbac/view?role_id=${payload}`)
  return response
}

export async function fetchCreateRole(payload: TRoleSetting): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/rbac/create-role', payload)
  return response
}

export async function fetchGetStageState(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/stage-states?sort=id&filter[stage_id]=${payload}`)
  return response
}

export async function fetchPatchUpdateStageState(payload: TRequestPatchStageState): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/stage-states/${payload.id}`, payload)
  return response
}

export async function fetchPostCreateStageState(payload: TRequestPatchStageState): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/stage-states', payload)
  return response
}

export async function fetchPatchUpdateVertices(payload: TRequestUpdateVertices): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/vertices/${payload.id}`, payload)
  return response
}

export async function fetchGetWhatsappTemplates(payload: TRequestWhatsappTemplates): Promise<AxiosResponse<any>> {
  const response = await $api.get(
    `/api/v1/whatsapp-templates?page=${payload.page || 1}&per-page=${payload.perPage || 10}`
  )
  return response
}

export async function fetchPostCreateWhatsappTemplate(
  payload: TRequestCreateWhatsappTemplate
): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/whatsapp-templates', payload)
  return response
}

export async function fetchPatchUpdateWhatsappTemplate(
  payload: TRequestUpdateWhatsappTemplate
): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/whatsapp-templates/${payload.id}`, payload)
  return response
}

export async function fetchDeleteWhatsappTemplate(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/whatsapp-templates/${payload}`)
  return response
}
export async function fetchPostPinWhatsappTemplate(payload: TRequestPinWhatsappTemplate): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/whatsapp-template-pins', payload)
  return response
}

export async function fetchDeletePinWhatsappTemplate(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/whatsapp-template-pins/${payload}`)
  return response
}

export type TCallback = {
  id: number
  event_id: number
  lid_utm_id: number
  method: 'get' | 'post'
  url: string
  params: string
  is_archive: boolean
  created_at: string
  event_label: string
  form: boolean
  lid_utm_label: string
  is_send_duplicate: boolean
  is_send_message: boolean
}
export type TResponseCallbacks = {
  ERR: boolean
  DATA: {
    page: string
    per_page: number
    page_total: number
    callback_list: TCallback[] | []
  }
}
export type TToken = {
  id: number | string
  token: string
  utm_source: string
  lid_name: string
  is_active: true
}
export type TResponseTokens = {
  ERR: boolean
  DATA: {
    page: string
    per_page: number
    page_total: number
    callback_list: TToken[] | []
  }
}

export async function fetchGetCallbacks(payload: TRequestCallbacks): Promise<AxiosResponse<TResponseCallbacks>> {
  const response = await $api.get(
    `/api/v1/callback-setting/get-list?page=${payload.page || 1}&per-page=${payload.perPage || 10}`
  )
  return response
}

export async function fetchPostCreateCallbacks(payload: TRequestCreateCallback): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/callback-setting/create', payload)
  return response
}

export async function fetchPatchUpdateCallback(payload: TRequestUpdateCallback): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/callback-setting/update?id=${payload.id}`, payload)
  return response
}

export async function fetchGetUserSynchronization(payload: string): Promise<AxiosResponse<TUser>> {
  const response = await $api.get(`/api/v1/user/sip-synchronization?user_id=${payload}`)
  return response
}

export type TRequestReminders = {
  page: number
  perPage: number
}

export type TReminder = {
  id: number
  name: string
  event_id: number
  remind_to: 'candidate' | 'recruiter' | 'researcher' | 'customer'
  time: number
  remind_time: 'before' | 'after'
  text: string
  is_archive: boolean
  created_at: string
}
export type TResponseReminders = {
  ERR: boolean
  DATA: TReminder[] | []
}

export async function fetchGetReminders(payload: TRequestReminders): Promise<AxiosResponse<TResponseReminders>> {
  const response = await $api.get(`/api/v1/reminders?page=${payload.page || 1}&per-page=${payload.perPage || 10}`)
  return response
}

export type TRequestCreateReminder = {
  name: string
  event_id: number
  remind_to: 'candidate' | 'recruiter' | 'researcher' | 'customer'
  time: number
  remind_time: 'before' | 'after'
  text: string
  is_archive: boolean
}

export async function fetchPostCreateRemind(payload: TRequestCreateReminder): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/reminders', payload)
  return response
}

export async function fetchPatchUpdateReminder(payload: TRequestUpdateReminder): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/reminders/${payload.id}`, payload)
  return response
}
export async function fetchGetTokens(payload: TRequestTokens): Promise<AxiosResponse<TResponseTokens>> {
  const response = await $api.get(
    `/api/v1/token-integrations?page=${payload.page || 1}&per-page=${payload.perPage || 10}`
  )
  return response
}

export async function fetchPostCreateTokens(payload: TRequestCreateToken): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/token-integrations', payload)
  return response
}

export async function fetchPatchUpdateToken(payload: TRequestUpdateToken): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/token-integrations/${payload.id}`, payload)
  return response
}

export async function fetchGetFieldsConfig(
  payload: TFieldConfigRequest
): Promise<AxiosResponse<TResponse<TFieldConfig[]>>> {
  const response = await $api.get(
    `/api/v1/field-visability-configs?sort=priority&per-page=1000&page=1${payload.filter}`
  )
  return response
}

export async function fetchPatchUpdateFieldsConfig(
  payload: TUpdateFieldConfig
): Promise<AxiosResponse<TResponse<TFieldConfig>>> {
  const response = await $api.patch(`/api/v1/field-visability-configs/${payload.id}`, payload)
  return response
}

export async function fetchPostCreateFieldsConfig(
  payload: TCreateFieldConfig
): Promise<AxiosResponse<TResponse<TFieldConfig>>> {
  const response = await $api.post(`/api/v1/field-visability-configs`, payload)
  return response
}

export async function fetchPostPriorityIncrementFieldsConfig(payload: {
  id: number
}): Promise<AxiosResponse<TResponse<TFieldConfig>>> {
  const response = await $api.post(`/api/v1/field-visability-configs/priority-increment/${payload.id}`, payload)
  return response
}

export async function fetchPostPriorityDecrementFieldsConfig(payload: {
  id: number
}): Promise<AxiosResponse<TResponse<TFieldConfig>>> {
  const response = await $api.post(`/api/v1/field-visability-configs/priority-decrement/${payload.id}`, payload)
  return response
}
