import { createReducer } from '@reduxjs/toolkit'
import { TDictionaries, TOption } from '../../common/types/dictionaries'
import { TRequestNotify, openNotifications, reset, setDefDataNotify, setLoading, setNotifications, setNotifyHeaders } from '.'

type TNotificationsState = {
  loading: boolean
  openNotification: boolean,
  notifications: any[],
  defData: TRequestNotify,
  headers: {
    current: number
    total :number
    totalCount: number
    unread: boolean
  } | null
}

const initialState: TNotificationsState = {
  openNotification: false,
  loading: false,
  notifications: [],
  defData: {
    filter: '',
    status: '&unread=1',
    page: 0,
    per_page: 20
  },
  headers: null
}

const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openNotifications, (state, { payload }) => {
      state.openNotification = payload
    })
    .addCase(setNotifications, (state, { payload }) => {
      state.notifications = payload
    })
    .addCase(setDefDataNotify, (state, { payload }) => {
      state.defData = payload
    })
    .addCase(setNotifyHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
})

export default notificationReducer
