/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Radio, Tabs, Typography } from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'

import './AutoCalls.scss'
import TabPane from 'antd/es/tabs/TabPane'
import { useDispatch, useSelector } from 'react-redux'
import { TClassName } from '../../../common/types/props'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import { optionsPagination } from '../../../common/components/options'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import AutoCallsModal from './AutoCallsModal'
import Plus from '../../../common/svg/Plus'
import AutoCallsTable from './AutoCallsTable'
import { getInternalAutoCall } from '../../InnerRouter/actions'
import { getAutoCalls } from '../actions'
import { selectAutoCalls } from '../selectors'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const mainCssClass = getCssClassName('auto_calls__list')

type TFormValues = {
  perPagesCount: number
}

const AutoCalls: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const autoCalls = useSelector(selectAutoCalls)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10
    }
  })
  const loading = false
  const handleCancelModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  //_________________________Удалить после НГ
  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])
  //___________________________
  useEffect(() => {
    dispatch(getAutoCalls())
  }, [])

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_________________________Удалить после НГ*/}
          <ChristmasIcon />
          {/*//________________________*/}
          <Typography.Title level={2}>Обзвоны</Typography.Title>
          {!!loading && <Preloader size="md" />}
        </div>
        <div className={`${mainCssClass}__container-buttons-phone`}>
          <div className={`${mainCssClass}__pause-button`}>
            <ControlButton icon="left" size="large" typeButton="primary" className="button" onClick={handleOpenModal}>
              <Plus color="#FFF" className={`${mainCssClass}__pause-phone`} />
              Создать обзвон
            </ControlButton>
          </div>
        </div>
      </div>
      <div className={`${mainCssClass}__container-info`}>
        <div className={`${mainCssClass}__option_tabs`}>
          <Typography.Text>Результат: {autoCalls.length}</Typography.Text>
        </div>
        {/* <div> */}
        {/*   <div className={`${mainCssClass}__container-pagination`}> */}
        {/*     <SelectFormControl */}
        {/*       className={`${mainCssClass}__pagination-count`} */}
        {/*       name="perPagesCount" */}
        {/*       options={optionsPagination} */}
        {/*       control={control} */}
        {/*     /> */}

        {/*     <div className={`${mainCssClass}__wrapper-arrow-pagination`}> */}
        {/*       <ArrowRightSM */}
        {/*         className="arrowCursor" */}
        {/*         size="1" */}
        {/*       /> */}
        {/*       <Typography.Text className={`${mainCssClass}__pagination-font`}> */}
        {/*         {0 || 'неизвестно'} */}
        {/*       </Typography.Text> */}
        {/*       <ArrowLeftSM */}
        {/*         className="arrowCursor" */}
        {/*         size="1" */}
        {/*       /> */}

        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
      <div className={`${mainCssClass}__scroll-div`}>
        <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
          <AutoCallsTable parsingData={autoCalls} className={mainCssClass} handleOpenModal={handleOpenModal} />
        </NewScrollContainer>
      </div>
      {openModal && <AutoCallsModal open={openModal} onCancel={handleCancelModal} />}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(AutoCalls))
