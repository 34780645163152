import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { loadState } from '../utils/localStorageUtils'
import rootReducer from './rootReducer'

export const sagaMiddleware = createSagaMiddleware()

function createStore() {
  const preloadedState = loadState()
  const reducer = rootReducer()

  return {
    store: configureStore({
      reducer,
      preloadedState,
      devTools: true,
      middleware: [sagaMiddleware]
    })
  }
}

export default createStore
