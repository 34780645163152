/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  openCertificateModal,
  openCoursesModal,
  openEditCertificateModal,
  openEditCoursesModal,
  openEditEducationModal,
  openEditExperienceModal,
  openEditInfoModal,
  openEditLanguageModal,
  openEditRecomendationModal,
  openEditRelocateModal,
  openEditSkillsModal,
  openEditTransferModal,
  openEditWishesModal,
  openEducationModal,
  openExperienceModal,
  openInfoModal,
  openLanguageModal,
  openRecomendationModal,
  openRelocateModal,
  openSkillsModal,
  openTransferModal,
  openWishesModal,
  reset,
  setActiveResume,
  setCandidateResume,
  setDictionaries,
  setLoading
} from './actions'
import { TResume } from './types'
import { TDictionaries } from '../../common/types/dictionaries'

export type TResumeState = {
  skillsModal: boolean
  infoModal: boolean
  certificateModal: boolean
  editCertificateModal: boolean
  loading: boolean
  resume_list: null | TResume[]
  resume: TResume | null
  editInfoModal: boolean
  editSkillsModal: boolean
  recomendationModal: boolean
  editRecomendationModal: boolean
  languageModal: boolean
  editLanguageModal: boolean
  coursesModal: boolean
  editCoursesModal: boolean
  relocateModal: boolean
  editRelocateModal: boolean
  transferModal: boolean
  editTransferModal: boolean
  educationModal: boolean
  editEducationModal: boolean
  experienceModal: boolean
  editExperienceModal: boolean
  wishesModal: boolean
  editWishesModal: boolean
  dictionaries: TDictionaries | null
}

const initialState: TResumeState = {
  infoModal: false,
  skillsModal: false,
  editInfoModal: false,
  editSkillsModal: false,
  loading: false,
  resume_list: null,
  resume: null,
  certificateModal: false,
  editCertificateModal: false,
  recomendationModal: false,
  editRecomendationModal: false,
  languageModal: false,
  editLanguageModal: false,
  coursesModal: false,
  editCoursesModal: false,
  relocateModal: false,
  editRelocateModal: false,
  editTransferModal: false,
  transferModal: false,
  educationModal: false,
  editEducationModal: false,
  experienceModal: false,
  editExperienceModal: false,
  wishesModal: false,
  editWishesModal: false,
  dictionaries: null
}

const resumeReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setCandidateResume, (state, { payload }) => {
      state.resume_list = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(reset, () => initialState)
    .addCase(openInfoModal, (state, { payload }) => {
      state.infoModal = payload
    })
    .addCase(openEditInfoModal, (state, { payload }) => {
      state.editInfoModal = payload
    })
    .addCase(openSkillsModal, (state, { payload }) => {
      state.skillsModal = payload
    })
    .addCase(openEditSkillsModal, (state, { payload }) => {
      state.editSkillsModal = payload
    })
    .addCase(setActiveResume, (state, { payload }) => {
      state.resume = payload
    })
    .addCase(openCertificateModal, (state, { payload }) => {
      state.certificateModal = payload
    })
    .addCase(openEditCertificateModal, (state, { payload }) => {
      state.editCertificateModal = payload
    })
    .addCase(openRecomendationModal, (state, { payload }) => {
      state.recomendationModal = payload
    })
    .addCase(openEditRecomendationModal, (state, { payload }) => {
      state.editRecomendationModal = payload
    })
    .addCase(openLanguageModal, (state, { payload }) => {
      state.languageModal = payload
    })
    .addCase(openEditLanguageModal, (state, { payload }) => {
      state.editLanguageModal = payload
    })
    .addCase(openCoursesModal, (state, { payload }) => {
      state.coursesModal = payload
    })
    .addCase(openEditCoursesModal, (state, { payload }) => {
      state.editCoursesModal = payload
    })
    .addCase(openRelocateModal, (state, { payload }) => {
      state.relocateModal = payload
    })
    .addCase(openEditRelocateModal, (state, { payload }) => {
      state.editRelocateModal = payload
    })
    .addCase(openTransferModal, (state, { payload }) => {
      state.transferModal = payload
    })
    .addCase(openEditTransferModal, (state, { payload }) => {
      state.editTransferModal = payload
    })
    .addCase(openEducationModal, (state, { payload }) => {
      state.educationModal = payload
    })
    .addCase(openEditEducationModal, (state, { payload }) => {
      state.editEducationModal = payload
    })
    .addCase(openExperienceModal, (state, { payload }) => {
      state.experienceModal = payload
    })
    .addCase(openEditExperienceModal, (state, { payload }) => {
      state.editExperienceModal = payload
    })
    .addCase(openWishesModal, (state, { payload }) => {
      state.wishesModal = payload
    })
    .addCase(openEditWishesModal, (state, { payload }) => {
      state.editWishesModal = payload
    })
})

export default resumeReducer
