import React, {
  memo,
  useCallback, useEffect, useMemo
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  Button, Typography
} from 'antd'
import CloseMD from '../../../common/svg/CloseMD'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  selectActiveDateChange,
  selectBlockedSlots,
  selectDictionaryOptionsByName,
  selectEmptySlots,
  selectEmptySlotsTimeStop,
  selectHolidays,
  selectReserveSlots
} from '../selectors'

import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import {
  createReserveSlot, createTimeSlotStop, deleteBlockedSlot, deleteReserveSlot, getBlockedSlots,
  getDictionaries,
  getEmptySlotByUser, getEmptySlotsTimeStop,
  getHolidays,
  getReserveSlotsUser,
  openСalendarSettings,
  updateCalendarSettings,
  updateUserCalendarSettings
} from '../actions'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import {
  DATE_DOTS_FORMAT,
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_SECOND_FORMAT,
  SERVER_TIME_FORMAT
} from '../../../common/utils/dateFormats'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import './SettingsCalendar.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TFormValues = {
  status: number
  date_status: string
  slot: string
  schedule_id_list?: number
  time_day: string[]
  date_vacation: string
  date_sick: string
  schedule_date: string
  time_stop_date: string
  time_stop: string
}

const mainCssClass = getCssClassName('settings-calendar')

function SettingsCalendar() {
  const dispatch = useDispatch()
  const sсheduleOptions = useSelector(selectDictionaryOptionsByName('scheduleRecruiter'))
  const dayStatus = [{ value: 1, label: 'Нерабочий' }, { value: 0, label: 'Рабочий' }]
  const holidays = useSelector(selectHolidays)
  const emptySlots = useSelector(selectEmptySlots)
  const reserveSlots = useSelector(selectReserveSlots)
  const timeSlotsStop = useSelector(selectEmptySlotsTimeStop)
  const blockedSlots = useSelector(selectBlockedSlots)
  const activeDate = useSelector(selectActiveDateChange)
  const { calendar_settings, role } = useEntityContext()
  const defaultValues: TFormValues = {
    schedule_id_list: calendar_settings?.graphic_option_id,
    time_day: [dayjs(new Date(calendar_settings?.begin_time_at)), dayjs(new Date(calendar_settings?.end_time_at))]
  }
  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TFormValues>({ defaultValues })

  const status = watch('status')
  const dateStatus = watch('date_status')
  const slot = watch('slot')
  const scheduleId = watch('schedule_id_list')
  const timeDay = watch('time_day')
  const dateVacation = watch('date_vacation')
  const dateSick = watch('date_sick')
  const scheduleDate = watch('schedule_date')
  const time_stop_date = watch('time_stop_date')
  const time_stop = watch('time_stop')

  const disabledSubmitStatus = useMemo(() => !((dateStatus && status !== undefined)), [status, dateStatus])
  const disabledSubmitSlot = useMemo(() => !slot, [slot])
  const disabledSubmitScheduleList = useMemo(() => !scheduleId, [scheduleId])
  const disabledSubmitTimeDay = useMemo(() => !timeDay, [timeDay])
  const disabledSubmitTimeStop = useMemo(() => !time_stop_date || !time_stop, [time_stop, time_stop_date])
  const disabledSubmitDateVacation = useMemo(() => !dateVacation, [dateVacation])

  const handleApply = useCallback(() => {

  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  const handleCloseFilters = useCallback(() => {
    dispatch(openСalendarSettings(false))
  }, [])
  const handleUpdateCalendarGraphic = useCallback(() => {
    if (calendar_settings && scheduleId && scheduleDate) {
      dispatch(updateUserCalendarSettings(
        {
          user_id: 0,
          graphic_option_id: scheduleId,
          recalculate_at: dayjs(scheduleDate).format(SERVER_DATE_TIME_SECOND_FORMAT)
        }
      ))
    }
  }, [calendar_settings, scheduleId, scheduleDate])

  const handleUpdateCalendarTime = useCallback(() => {
    if (calendar_settings && timeDay) {
      const newDate = dayjs(new Date()).format(SERVER_DATE_FORMAT)
      dispatch(updateUserCalendarSettings({
        user_id: 0,
        begin_time_at: `${newDate} ${dayjs(timeDay[0]).format(SERVER_TIME_FORMAT)}`,
        end_time_at: `${newDate} ${dayjs(timeDay[1]).format(SERVER_TIME_FORMAT)}`
      }))
    }
  }, [calendar_settings, timeDay])

  const handleUpdateCalendarTimeStop = useCallback(() => {
    if (time_stop && time_stop_date) {
      const newDate = dayjs(time_stop_date).format(SERVER_DATE_FORMAT)
      const splitTime = time_stop.split('-')
      dispatch(createTimeSlotStop({
        date: time_stop_date,
        begin_at: `${newDate} ${splitTime[0]}:00`,
        end_at: `${newDate} ${splitTime[1]}:00`
      }))
    }
  }, [time_stop, time_stop_date])

  const handleUpdateVacation = useCallback(() => {
    if (calendar_settings && dateVacation) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(dateVacation[0]).format(SERVER_DATE_FORMAT),
        end_at: dayjs(dateVacation[1]).format(SERVER_DATE_FORMAT),
        user_id: 0,
        is_blocked: 1
      }))
    }
  }, [calendar_settings, dateVacation])

  const handleAddStatusDay = useCallback(() => {
    if (calendar_settings && dayStatus && dateStatus) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(dateStatus).format(SERVER_DATE_FORMAT),
        end_at: dayjs(dateStatus).format(SERVER_DATE_FORMAT),
        user_id: 0,
        is_blocked: status
      }))
    }
  }, [calendar_settings, status, dateStatus, activeDate])

  const handleAddReserveSlot = useCallback(() => {
    if (calendar_settings && slot) {
      dispatch(createReserveSlot({
        time_interval: slot,
        user_id: 0,
        date: dayjs(dayjs(activeDate ? new Date(activeDate) : new Date()))
          .format(SERVER_DATE_FORMAT)
      }))
    }
  }, [calendar_settings, slot, activeDate])

  const handleDeleteDays = useCallback((item) => {
    if (calendar_settings) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(item.begin_at)
          .format(SERVER_DATE_FORMAT),
        end_at: dayjs(item.end_at)
          .format(SERVER_DATE_FORMAT),
        user_id: 0,
        is_blocked: 0,
        calendar_id: item.calendar_id
      }))
    }
  }, [calendar_settings])

  const handleDeleteReserve = useCallback((item) => {
    dispatch(deleteReserveSlot({
      user_id: 0,
      reserve_slot_id: item.id
    }))
  }, [])
  const handleDeleteBlocked = useCallback((item) => {
    dispatch(deleteBlockedSlot({
      block_slot_id: item.id,
      date: time_stop_date
    }))
  }, [time_stop_date])

  useEffect(() => {
    dispatch(getDictionaries(['scheduleRecruiter', 'daystatus']))
  }, [])

  useEffect(() => {
    dispatch(getEmptySlotByUser({
      userId: 0,
      date: dayjs(activeDate ? new Date(activeDate) : new Date())
        .format(SERVER_DATE_FORMAT)
    }))
    dispatch(getReserveSlotsUser({
      userId: 0,
      date: dayjs(activeDate ? new Date(activeDate) : new Date())
        .format(SERVER_DATE_FORMAT)
    }))
    dispatch(getHolidays())
    dispatch(getBlockedSlots({
      date: dayjs(activeDate ? new Date(activeDate) : new Date())
        .format(SERVER_DATE_FORMAT)
    }))
  }, [activeDate])

  useEffect(() => {
    if (time_stop_date) {
      dispatch(getEmptySlotsTimeStop(dayjs(time_stop_date).format(SERVER_DATE_FORMAT)))
      dispatch(getBlockedSlots({
        date: dayjs(time_stop_date)
          .format(SERVER_DATE_FORMAT)
      }))
    }
  }, [time_stop_date])

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
        <div className={`${mainCssClass}__content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Настройки</Typography.Title>
            </div>
            <Button onClick={handleCloseFilters}>
              <CloseMD />
            </Button>
          </div>
          <div className={`${mainCssClass}__block-items`}>
            <Typography.Title level={5}>
              Установить статус на день
            </Typography.Title>
            <DatePickerFormControl control={control} name="date_status" tasks isDisableDate />
            <SelectFormControl
              control={control}
              name="status"
              label="Статус"
              placeholder="Выберите статус"
              options={dayStatus}
            />
            <ControlButton className="button" size="large" typeButton="primary" state="disabled" disabled={disabledSubmitStatus} onClick={handleAddStatusDay}>Применить</ControlButton>
          </div>
          <div className={`${mainCssClass}__block-items`}>
            <Typography.Title level={5}>
              Добавить резерв на сегодня
            </Typography.Title>
            <SelectFormControlMult
              control={control}
              name="slot"
              label="Слот"
              placeholder="Выберите слот времени"
              options={emptySlots}
            />
            <ControlButton className="button" size="large" typeButton="primary" state="disabled" disabled={disabledSubmitStatus} disabled={disabledSubmitSlot} onClick={handleAddReserveSlot}>Применить</ControlButton>
            {reserveSlots && !!reserveSlots?.length && reserveSlots.map((item) => (
              <div className={`${mainCssClass}__content-delete`}>
                <Typography.Paragraph className={`${mainCssClass}__labels-delete`}>
                  {`${item.time_interval}  ( ${item?.cnt || 0} / ${item?.max_count || 0} ) `}
                </Typography.Paragraph>
                <Button icon={<CloseMD />} onClick={() => handleDeleteReserve(item)} className={`${mainCssClass}__delete-button`} />
              </div>
            ))}
          </div>
          {/* <div className={`${mainCssClass}__block-items`}> */}
          {/*   <Typography.Title level={5}> */}
          {/*     Мой график работы */}
          {/*   </Typography.Title> */}
          {/*   <SelectFormControl */}
          {/*     control={control} */}
          {/*     name="schedule_id_list" */}
          {/*     label="График" */}
          {/*     options={sсheduleOptions} */}
          {/*   /> */}
          {/*   <DatePickerFormControl control={control} name="schedule_date" label="Выберите дату" tasks isDisableDate /> */}
          {/*   <ControlButton */}
          {/*     className="button" */}
          {/*     size="large" */}
          {/*     typeButton="secondary" */}
          {/*     state="disabled" */}
          {/*     disabled={disabledSubmitScheduleList} */}
          {/*     onClick={handleUpdateCalendarGraphic} */}
          {/*   > */}
          {/*     Сохранить */}
          {/*   </ControlButton> */}
          {/* </div> */}
          <div className={`${mainCssClass}__block-items`}>
            <Typography.Title level={5}>
              Рабочее время
            </Typography.Title>
            <RangePickerFormControl
              control={control}
              name="time_day"
              label="Выберите время"
              // @ts-ignore
              placeholder={['Начало дня', 'Конец дня']}
              picker="time"
              className={`${mainCssClass}_items_content_range`}
              format="HH:mm"
              isTime
              withoutRange
            />

            <ControlButton
              className="button"
              size="large"
              typeButton="secondary"
              state="disabled"
              disabled={disabledSubmitTimeDay}
              onClick={handleUpdateCalendarTime}
            >
              Добавить
            </ControlButton>
          </div>
          <div className={`${mainCssClass}__block-items`}>
            <Typography.Title level={5}>
              Нерабочие дни
            </Typography.Title>
            <RangePickerFormControl
              control={control}
              name="date_vacation"
              // @ts-ignore
              placeholder={['Начало', 'Конец']}
              format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
              label="Выберите даты"
              className={`${mainCssClass}_items_content_range`}
              withoutRange
            />
            <ControlButton
              className="button"
              size="large"
              typeButton="primary"
              state="disabled"
              disabled={disabledSubmitDateVacation}
              onClick={handleUpdateVacation}
            >
              Добавить
            </ControlButton>
            {holidays && !!holidays?.length && holidays.map((item) => (
              <div className={`${mainCssClass}__content-delete`}>
                <Typography.Paragraph className={`${mainCssClass}__labels-delete`}>
                  {`${dayjs(item.begin_at).format(DATE_DOTS_FORMAT)}-${dayjs(item.end_at).format(DATE_DOTS_FORMAT)}`}
                </Typography.Paragraph>
                <Button icon={<CloseMD />} onClick={() => handleDeleteDays(item)} className={`${mainCssClass}__delete-button`} />
              </div>
            ))}
          </div>
          <div className={`${mainCssClass}__block-items`}>
            <Typography.Title level={5}>
              Перерывы
            </Typography.Title>
            <DatePickerFormControl control={control} name="time_stop_date" label="Выберите дату" tasks isDisableDate />
            <SelectFormControl name="time_stop" label="Выберите слот" control={control} options={timeSlotsStop} disabled={!time_stop_date} />
            <ControlButton
              className="button"
              size="large"
              typeButton="secondary"
              state="disabled"
              disabled={disabledSubmitTimeStop}
              onClick={handleUpdateCalendarTimeStop}
            >
              Добавить
            </ControlButton>
            {blockedSlots && !!blockedSlots?.length && blockedSlots.map((item) => (
              <div className={`${mainCssClass}__content-delete`}>
                <Typography.Paragraph className={`${mainCssClass}__labels-delete`}>
                  {`${dayjs(item.begin_at).format('HH:mm')}-${dayjs(item.end_at).format('HH:mm')}`}
                </Typography.Paragraph>
                <Button icon={<CloseMD />} onClick={() => handleDeleteBlocked(item)} className={`${mainCssClass}__delete-button`} />
              </div>
            ))}
          </div>
        </div>
      </NewScrollContainer>
    </div>
  )
}
export default memo(withErrorBoundary(SettingsCalendar))
