import { PayloadAction } from '@reduxjs/toolkit'
import {
  call,
  put, race, select, take, takeLatest
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import $api from '../../common/api/http'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  TRequestCandidateCSV, exportCSV, getCandidates, getDictionaries, setCandidates, setDictionaries, setHeaders, setLoading, mergeDuplicates,
  setDashboard,
  getDashboard,
  TCandidatesFilterFromDashboardType,
  setCandidatesFilterFromDashboard,
  setLoadingDashboard, TDashboardRequest, setDisabledDashboard
} from './actions'
import {
  fetchGetCandidateDashboard, fetchGetCandidates, fetchPostCreateCSV, fetchPostDuplicatesCandidates
} from '../../common/api/Candidate'
import { TPaginationRequest } from '../../common/types/pagination'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { selectDefDataCandidate } from './selectors'
import { setLoadingProgress } from '../InnerRouter/actions'
import { TDashaboard } from './types'
import { selectUserInfo } from '../InnerRouter/selectors'
import { objectToQueryString } from '../../features/filters/utils'
import {
  getFilters, openFilters, setDefFilters, setFiltersLabels
} from '../../features/filters/actions'
import { managamentCandidatesDefLabel } from './utils'

const moduleName = 'Кандидаты таблица'
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'кандидаты таблица')
}
function* getCandidatesSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))

    const { response } = yield race({
      response: fetchGetCandidates(payload),
      cancel: take(getCandidates.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить кандидатов',
          error: response as AxiosResponse
        })
      )
    } else {
      yield put(setCandidates(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TRequestCandidateCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostCreateCSV(payload),
      cancel: take(exportCSV.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить файл',
          error: response as AxiosError
        })
      )
    } else {

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* mergeDuplicatesSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostDuplicatesCandidates(payload),
      cancel: take(mergeDuplicates.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось соединить дубликаты',
          error: response as AxiosError
        })
      )
    } else {
      const data = yield select(selectDefDataCandidate)
      if (data) {
        yield put(getCandidates({ page: data.page, perPage: data.perPage, filters: data.filters }))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getDashboardSaga({ payload }: PayloadAction<TDashboardRequest>) {
  try {
    if (!payload.isNeedDisable) {
      yield put(setLoadingDashboard(true))
    }
    yield put(setDisabledDashboard(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchGetCandidateDashboard(payload.filter),
      cancel: take(getDashboard.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить дашборд',
          error: response as AxiosResponse
        })
      )
    } else {
      yield put(setDashboard(response.data.DATA as TDashaboard))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingDashboard(false))
    yield put(setLoadingProgress(false))
    yield put(setDisabledDashboard(false))
  }
}

function* setCandidatesFilterFromDashboardSaga({ payload }: PayloadAction<TCandidatesFilterFromDashboardType>) {
  try {
    yield put(setLoadingProgress(true))
    const userInfo = yield select(selectUserInfo)

    if (payload.role === 'admin' || payload.role === 'superadmin' || payload.role === 'hr_department' || payload.role === 'group_leader') {
      const changeValues = yield managamentCandidatesDefLabel('group', userInfo?.group_list, payload.stage_id, payload.stage_label)
      localStorage.setItem(
        `def${payload.pageId}`,
        JSON.stringify({
          group: userInfo?.group_list,
          ...(payload.stage_id && { stage_id: [payload.stage_id] }) // Добавить stage_id только если он есть
        })
      )
      localStorage.setItem(payload.pageId, `&groups=${userInfo?.group_list.join(',')
      }${payload.stage_id ? `&filter[stage_id][in][]=${payload.stage_id}` : ''}`)
      yield put(setFiltersLabels(changeValues))
      localStorage.setItem(`${payload.pageId}-labels`, JSON.stringify(changeValues))
      yield put(getFilters({
        filter:
        {
          group: userInfo?.group_list,
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        },
        isEventFunnel: payload.pageId === 'event-funnel'
      }))
      yield put(setDefFilters({
        type: payload.pageId || 'candidates',
        filter: {
          group: userInfo?.group_list,
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        },
        defFilterString: objectToQueryString({ group: userInfo?.group_list }, payload.pageId === 'event-funnel')
      }))
    } else if (payload.role === 'client' || payload.role === 'customer_in_bulk') {
      const response = yield call($api.get, `api/v1/requests?sort=-updated_at&fields=name,id&per-page=1&page=1000&union[without_store]=false&union[customer]=${userInfo.id}&filter[status][in][]=92`)

      if (!response.data.ERR) {
        const changeValues = yield managamentCandidatesDefLabel('request_id', response.data.DATA.request_list.map((el) => el.name), payload.stage_id, payload.stage_label)
        localStorage.setItem(`def${payload.pageId}`, JSON.stringify({
          request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        }))
        localStorage.setItem(payload.pageId, `&union[request]=${response.data.DATA.request_list.map((el) => el.id).join(',')}
        ${payload.stage_id ? `&filter[stage_id][in][]=${payload.stage_id}` : ''}
        `)
        yield put(setFiltersLabels(changeValues))
        localStorage.setItem(`${payload.pageId}-labels`, JSON.stringify(changeValues))
        yield put(getFilters({
          filter:
          {
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          },
          isEventFunnel: payload.pageId === 'event-funnel'
        }))
        yield put(setDefFilters({
          type: payload.pageId || 'candidates',
          filter: {
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          },
          defFilterString: objectToQueryString({
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          }, payload.pageId === 'event-funnel')
        }))
      }
    } else if (payload.role === 'vaytstory_i_darkstory') {
      const response = yield call($api.get, `api/v1/requests?sort=-updated_at&fields=name,id&per-page=1&page=1000&union[without_store]=false&union[whitedarkstore]=${userInfo.id}&filter[status][in][]=92`)

      if (!response.data.ERR) {
        const changeValues = yield managamentCandidatesDefLabel('request_id', response.data.DATA.request_list.map((el) => el.name), payload.stage_id, payload.stage_label)
        localStorage.setItem(`def${payload.pageId}`, JSON.stringify({
          request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        }))
        localStorage.setItem(payload.pageId, `&union[request]=${response.data.DATA.request_list.map((el) => el.id).join(',')}
        ${payload.stage_id ? `&filter[stage_id][in][]=${payload.stage_id}` : ''}
        `)
        yield put(setFiltersLabels(changeValues))
        localStorage.setItem(`${payload.pageId}-labels`, JSON.stringify(changeValues))
        yield put(getFilters({
          filter:
          {
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          },
          isEventFunnel: payload.pageId === 'event-funnel'
        }))
        yield put(setDefFilters({
          type: payload.pageId || 'candidates',
          filter: {
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          },
          defFilterString: objectToQueryString({
            request_id: response.data.DATA.request_list.map((el) => ({ label: el.name, value: el.id })),
            ...(payload.stage_id && { stage_id: [payload.stage_id] })
          }, payload.pageId === 'event-funnel')
        }))
      }
    } else if (payload.role === 'recruiter') {
      const changeValues = yield managamentCandidatesDefLabel('responsible', [`${userInfo?.name} ${userInfo.second_name || ''}`], payload.stage_id, payload.stage_label)
      localStorage.setItem(`def${payload.pageId}`, JSON.stringify({
        responsible: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
        ...(payload.stage_id && { stage_id: [payload.stage_id] })

      }))
      localStorage.setItem(payload.pageId, `&filter[responsible][in][]=${userInfo.id}${payload.stage_id ? `&filter[stage_id][in][]=${payload.stage_id}` : ''}`)
      yield put(setFiltersLabels(changeValues))
      localStorage.setItem(`${payload.pageId}-labels`, JSON.stringify(changeValues))
      yield put(getFilters({
        filter: {
          responsible: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        },
        isEventFunnel: payload.pageId === 'event-funnel'
      }))
      yield put(setDefFilters({
        type: payload.pageId || 'candidates',
        filter: {
          responsible: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })

        },
        defFilterString: objectToQueryString({
          responsible: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })

        }, payload.pageId === 'event-funnel')
      }))
    } else {
      const changeValues = yield managamentCandidatesDefLabel('researcher', [`${userInfo?.name} ${userInfo.second_name || ''}`], payload.stage_id, payload.stage_label)
      localStorage.setItem(`def${payload.pageId}`, JSON.stringify({
        researcher: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
        ...(payload.stage_id && { stage_id: [payload.stage_id] })

      }))
      localStorage.setItem(payload.pageId, `&filter[researcher][in][]=${userInfo.id}${payload.stage_id ? `&filter[stage_id][in][]=${payload.stage_id}` : ''}`)
      yield put(setFiltersLabels(changeValues))
      localStorage.setItem(`${payload.pageId}-labels`, JSON.stringify(changeValues))
      yield put(getFilters({
        filter: {
          researcher: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        },
        isEventFunnel: payload.pageId === 'event-funnel'
      }))
      yield put(setDefFilters({
        type: payload.pageId || 'candidates',
        filter: {
          researcher: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        },
        defFilterString: objectToQueryString({
          researcher: [{ label: `${userInfo?.name} ${userInfo.second_name || ''}`, value: userInfo.id }],
          ...(payload.stage_id && { stage_id: [payload.stage_id] })
        }, payload.pageId === 'event-funnel')
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

export function* managementCandidatesSaga() {
  yield takeLatest(getCandidates, getCandidatesSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
  yield takeLatest(mergeDuplicates, mergeDuplicatesSaga)
  yield takeLatest(getDashboard, getDashboardSaga)
  yield takeLatest(setCandidatesFilterFromDashboard, setCandidatesFilterFromDashboardSaga)
}
