import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { TOption } from '../../../common/types/dictionaries'
import './ModalRejectRequest.scss'
import { archiveStatusRequest } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'reject-request'

type TFormValues = {
  reason: string
  comment: string
}

export type TProps = {
  options: TOption[]
  requestId?: number | null | string
  isRequestTable: boolean
} & ModalProps
function ModalRejectRequest({
  open, className, onCancel, options, requestId, isRequestTable
}: TProps) {
  const dispatch = useDispatch()

  const {
    control, watch, getValues, handleSubmit
  } = useForm<TFormValues>()

  const onSubmit = useCallback(() => {
    const dataCom = watch('comment')

    if (requestId && dataCom) {
      dispatch(
        archiveStatusRequest({
          data: {
            status: 112,
            id: requestId,
            ...getValues()
          },
          isRequestTable
        })
      )
    }
  }, [requestId])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Отклонить заявку?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Отклонить"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <SelectFormControl
          name="reason"
          control={control}
          placeholder="Выберите заявку"
          options={options}
          required
          label="Причина"
          rules={{ required: true }}
        />
        <TextAreaFormControl name="comment" control={control} label="Комментарий" required rules={{ required: true }} />
      </div>
    </CommonModal>
  )
}

export default ModalRejectRequest
