import React, { FC, useMemo } from 'react'
import Ellipse from '../../../common/svg/Ellipse'
import './AutoCallsStatus.scss'

const mainCssClass = 'autocall-status'

// Define the props type
type ParsingStatusProps = {
  status: number
  text: string
}

const AutoCallsStatus: FC<ParsingStatusProps> = ({ status, text }) => {
  const fill = useMemo(() => {
    if (status === 5) {
      return '#727974'
    }
    return '#2DBE64'
  }, [status])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__icon-container`}>
        <Ellipse fill={fill} />
      </div>
      {text}
    </div>
  )
}

export default AutoCallsStatus
