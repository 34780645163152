import React from 'react'
import { Typography } from 'antd'
import cn from 'classnames'
import { getCssClassName } from '../../../common/utils/generateClassName'
import EmptyHistory from '../../../common/svg/EmptyHistory'
import EmptyAsterisk from '../../../common/svg/EmptyAsterisk'
import './EmptyList.scss'
import EmptyCandidateComment from '../../../common/svg/EmptyCandidateComment'

const { Title } = Typography

const mainCssClass = getCssClassName('empty-list')

export type TEmptyListProps = {
  type: 'history' | 'asterisk' | 'comments'
  className?: string
}

function EmptyList({ type, className }:TEmptyListProps) {
  return (

    <div className={cn(mainCssClass, className)}>
      {type === 'history' && <EmptyHistory />}
      {type === 'asterisk' && <EmptyAsterisk />}
      {type === 'comments' && <EmptyCandidateComment />}

      <Title level={5}>
        {type === 'history' && 'История пуста'}
        {type === 'asterisk' && 'Звонков нет'}
        {type === 'comments' && 'Комментариев нет'}
      </Title>
      <p>
        {type === 'history' && 'Записей в истории пока что нет'}
        {type === 'asterisk' && 'Записей в истории звонков пока что нет'}
      </p>
    </div>

  )
}
export default EmptyList
