import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName, selectResume } from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { createRelocateResume, createSkillsResume } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: (data: any) => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  can_relocate: number
  can_business_trip: number
}

function ModalRelocate({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const option = useSelector(selectDictionaryOptionsByName('yes_no'))

  const defaultValues = useMemo(
    () => (editMode && resume?.can_business_trip && resume?.can_relocate
      ? {
        can_relocate: resume.can_relocate,
        can_business_trip: resume.can_business_trip
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = useCallback(() => {
    dispatch(createRelocateResume({ resume_id: resumeId, ...getValues() }))
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Переезд, коммандировки"
      className={cn(className, mainCssClass)}
      width="400px"
      maskClosable={false}
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_list`}>
        <SelectFormControl
          name="can_relocate"
          label="Переезд"
          options={option}
          control={control}
          rules={{ required: true }}
        />
        <SelectFormControl
          name="can_business_trip"
          label="Коммандировки"
          options={option}
          rules={{ required: true }}
          control={control}
        />
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalRelocate))
