import { AxiosResponse } from 'axios'
import { TCandidate } from '../../compositions/candidate/types'
import $api from './http'
import { TAddInterview, TGetAllEmptySlots, TRequestUpdateInterview } from '../../compositions/candidate/actions'
import { TCalendarSettings, TDeleteReserveSlot } from '../../features/SettingsCalendar/actions'

export type TCalendarResponse = {
  ERR: any
  DATA: TCalendar
}
export type TCalendar = {
  calendar_id: number
  date: string
  day_types_label: string
  description: string
  is_blocked: number
  title: string
  slots:TSlot
}
export type TSlot = {
  [key: string]: {
    state: number
    description: string
    interview_id: number
    request_label: string
    candidate_label: string
    state_interview: string
    status: number
    title: string
    confirm: string
    user_id: number
    calendar_id: number
  }
}
export type TResponseEmptySlotByUser = {
  DATA: string[]
  ERR: boolean
}
export async function fetchGetCalendar(user_id:string, period: string, page?: string): Promise<AxiosResponse<TCalendarResponse>> {
  const response = await $api.get(`/api/v1/calendar/show?period=${period}&user_id=0&page=${page}`)
  return response
}
export async function fetchGetSlots(payload?: string): Promise<AxiosResponse<string[]>> {
  const date = payload ? `?date=${payload}` : ''
  const response = await $api.get(`api/v1/calendar/get-slot-list${date}`)
  return response
}

export async function fetchGetSlotsTimeStop(date: string): Promise<AxiosResponse<string[]>> {
  const response = await $api.get(`api/v1/calendar/get-single-empty-slots?date=${date}&user_id=0`)
  return response
}

export async function fetchGetEmptySlotsByUser(userId: number, date: string): Promise<AxiosResponse<TResponseEmptySlotByUser>> {
  const response = await $api.get(`api/v1/calendar/get-single-reserve-slots?date=${date}&user_id=0`)
  return response
}
export async function fetchGetReserveSlotsByUser(date: string): Promise<AxiosResponse<TResponseEmptySlotByUser>> {
  const response = await $api.get(`api/v1/calendar/get-reserve-slots?date=${date}&user_id=0`)
  return response
}

export type TBlockedSlots ={
  id: number
  begin_at: string
  end_at: string
  user_id: number
}
export type TBlockedSlotsResponse ={
  DATA: TBlockedSlots[]
}
export async function fetchGetBlockedSlotsByUser(date: string): Promise<AxiosResponse<TBlockedSlotsResponse>> {
  const response = await $api.get(`api/v1/calendar/get-blocked-slots?date=${date}&user_id=0`)
  return response
}
export type TResponseEmptySlots = {
  DATA: string[]
  ERR: boolean
}
export async function fetchGetEmptySlots(payload: TGetAllEmptySlots): Promise<AxiosResponse<TResponseEmptySlots>> {
  const response = await $api.get(`api/v1/calendar/get-all-empty-slots?date=${payload.date}&user_id=0&${payload.isRecruiter && payload.user_id ? `&isResercherBeRecruter=${payload.user_id}` : ''}&candidate_id=${payload.candidate_id}`)
  return response
}

export type TRequestBodyUpdateSettings ={
  begin_at: string,
  end_at: string,
  user_id: number,
  is_blocked: number
  calendar_id?: string
}

export type TRequestBodyBlockedSlots={
  begin_at: string,
  end_at: string
  date?: string
}
export async function fetchPostCalendar(body: TRequestBodyUpdateSettings): Promise<AxiosResponse<TRequestBodyUpdateSettings>> {
  const response = await $api.post('api/v1/calendar/change-days', body)
  return response
}

export async function fetchPostCalendarBlockedSlots(body: TRequestBodyBlockedSlots): Promise<AxiosResponse<TRequestBodyBlockedSlots>> {
  const response = await $api.post('api/v1/calendar/create-blocked-slot', body)
  return response
}

export type TBlockedSlotDelete = {
  block_slot_id: string
  date: string
}
export async function fetchDeleteCalendarBlockedSlot(body: TBlockedSlotDelete): Promise<AxiosResponse<TBlockedSlotDelete>> {
  const response = await $api.post('api/v1/calendar/delete-blocked-slot', { block_slot_id: body.block_slot_id })
  return response
}
export async function fetchGetCalendarHolidaysUser(): Promise<AxiosResponse<any>> {
  const response = await $api.get('api/v1/calendar/get-days')
  return response
}
export async function fetchPostCalendarAddInterview(body: TRequestUpdateInterview): Promise<AxiosResponse<any>> {
  const response = await $api.post('api/v1/calendar/assign-user', body)
  return response
}

export async function fetchPostCreateReserveSlot(payload: TCalendarSettings): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/calendar/create-reserve-slot', payload)
  return response
}

export async function fetchPostDeleteReserveSlot(payload: TDeleteReserveSlot): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/calendar/delete-reserve-slot', { reserve_slot_id: payload.reserve_slot_id })
  return response
}
