/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import { TResponseMessages } from 'src/common/api/chat'
import {
  resetChat,
  resetMailMessages,
  setActualEmailMessages,
  setLoading,
  setMailMessages,
  setMessageForChat,
  setMessages,
  setPageMessages,
  setTemplateContent,
  setHeaders
} from './actions'

export type TChatState = {
  loading?: boolean
  messages: TResponseMessages[] | null
  mailMessages: TResponseMessages[]
  pageMessages: number | null
  actualMailPage: null | number
  templateContent: string
  messageForChat: any
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
}

const initialState: TChatState = {
  loading: false,
  messages: [],
  pageMessages: null,
  mailMessages: [],
  actualMailPage: null,
  templateContent: '',
  messageForChat: null,
  headers: null
}

const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(resetChat, () => initialState)
    .addCase(setMessages, (state, { payload }) => {
      state.messages = payload
    })
    .addCase(setPageMessages, (state, { payload }) => {
      state.pageMessages = payload
    })
    .addCase(setMailMessages, (state, { payload }) => {
      state.mailMessages = payload
    })
    .addCase(setActualEmailMessages, (state, { payload }) => {
      state.actualMailPage = payload
    })
    .addCase(resetMailMessages, (state) => {
      state.mailMessages = []
    })
    .addCase(setTemplateContent, (state, { payload }) => {
      state.templateContent = payload
    })
    .addCase(setMessageForChat, (state, { payload }) => {
      state.messageForChat = payload
    })
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
})

export default chatReducer
