import { createReducer } from '@reduxjs/toolkit'
import { setOpenModalHidePost, setOpenModalNewEntry } from './actions'

type TCostOfHiringState = {
    openModalHidePost:boolean
    openModalNewEntry:boolean
}

const initialState: TCostOfHiringState = {
  openModalHidePost: false,
  openModalNewEntry: false
}

const costOfHiringReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setOpenModalHidePost, (state, { payload }) => {
      state.openModalHidePost = payload
    })
    .addCase(setOpenModalNewEntry, (state, { payload }) => {
      state.openModalNewEntry = payload
    })
})

export default costOfHiringReducer
