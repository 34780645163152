import React, {
  memo
} from 'react'
import {
  Typography
} from 'antd'
import './StageLimitationModal.scss'
import { useDispatch } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModalLimitation from '../../../common/components/CommonModalLimitation/CommonModalLimitation'
import { setMessageLimitationCandidate, showLimitationStageCandidateModal } from '../../../compositions/candidate/actions'
import { setMessageLimitationFunnel, showLimitationStageFunnelModal } from '../../../compositions/Funnel/actions'
import { convertHttpToLink } from '../../notificaitionCenter/utils'

const mainCssClass = 'stage-limitation-modal'
function StageLimitationModal({ open, text }) {
  const dispatch = useDispatch()

  const handleModalClose = () => {
    dispatch(showLimitationStageCandidateModal(false))
    dispatch(showLimitationStageFunnelModal(false))
    dispatch(setMessageLimitationFunnel(''))
    dispatch(setMessageLimitationCandidate(''))
  }

  return (
    <CommonModalLimitation
      centered
      open={open}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      title="Невозможно перевести на этап"
      className={mainCssClass}
      width="400px"
      typeButtonAccept="primary"
      okText="Хорошо"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />

    </CommonModalLimitation>
  )
}

export default memo(withErrorBoundary(StageLimitationModal))
