import React from 'react'
import Icon from '../svgIcons/PausePhoneSmall.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function PausePhoneSmall(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid" size={props?.size || 'sm'}>
      <Icon />
    </IconWrapper>
  )
}
