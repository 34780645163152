import React, {
  FC, memo, useMemo
} from 'react'
import { Typography } from 'antd'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Rectangle from '../../../common/svg/Rectangle'
import './EmployeeStatusTableRow.scss'

type TProps = {
  status: string
  status_id: number
}
const mainCssClass = 'employee-status-table'
const EmployeeStatusTableRow: FC<TProps> = ({ status, status_id }) => {
  const fill = useMemo(() => {
    if (status_id === 1) {
      return '#DF1E12'
    }
    if (status_id === 2) {
      return '#2DBE64'
    }
    if (status_id === 3) {
      return '#5A615C'
    }
    if (status_id === 4) {
      return '#FFD608'
    }
    if (status_id === 5) {
      return '#F5695A'
    }
    return '#BFC5C0'
  }, [status_id])

  return (
    <div className={mainCssClass}>
      <Rectangle fill={fill} />
      <Typography.Text>{status === 'n/a' ? 'Неизвестно' : status}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(EmployeeStatusTableRow))
