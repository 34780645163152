import React from 'react'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'santaClausChristmas'

function SantaClausChristmas({ className, size = '1', color }: TSvgProps) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="santa-claus-christmas-svgrepo-com 1" clipPath="url(#clip0_2_5441)">
        <g id="Group">
          <path id="Vector" d="M6.06888 21.5172H26.4826V9.93103H6.06888V21.5172Z" fill="#FDC794" />
          <path
            id="Vector_2"
            d="M28.1378 15.4483C26.5042 15.4483 25.1099 14.4321 24.5461 12.9987C24.7154 14.5496 25.1314 16.0712 25.6981 17.5587C25.8046 17.8389 25.8641 18.1369 25.8686 18.4459C25.8912 20.0447 24.3375 21.4346 22.4335 21.5134C21.1055 21.5686 19.9264 21.0224 19.2462 20.1716C18.5869 19.3468 17.5524 18.7939 16.3689 18.7636C16.247 18.7603 16.124 18.7587 15.9998 18.7587C15.8756 18.7587 15.7526 18.7603 15.6307 18.7636C14.4473 18.794 13.4127 19.3468 12.7534 20.1716C12.0732 21.0224 10.8941 21.5686 9.56612 21.5134C7.66212 21.4346 6.1085 20.0447 6.13106 18.4459C6.1355 18.1369 6.19506 17.839 6.30156 17.5587C6.92062 15.9344 7.37519 14.2709 7.5065 12.5706L4.14262 13.1852C3.53312 15.5813 1.28981 26.1727 8.22994 29.7932C8.22994 29.7932 8.70056 29.1118 8.70056 28.138C8.70056 28.138 9.17119 32.0001 15.9486 32.0001C22.7259 32.0001 23.2584 28.138 23.2584 28.138C23.2584 29.1118 23.7908 29.7932 23.7908 29.7932C29.3649 26.8856 28.9936 19.4831 28.3116 15.4395C28.2537 15.4422 28.1963 15.4483 28.1378 15.4483Z"
            fill="#E6E7E8"
          />
          <path
            id="Vector_3"
            d="M22.2608 23.7242C18.8003 26.6665 13.1998 26.6665 9.73882 23.7242C13.1998 23.7242 15.9998 22.6787 15.9998 22.069C15.9998 22.6787 18.8003 23.7242 22.2608 23.7242Z"
            fill="#FDC794"
          />
          <path
            id="Vector_4"
            d="M15.9999 24.2759C17.2241 24.2759 18.2961 23.9228 18.9158 23.392C17.1646 23.034 15.9999 22.4618 15.9999 22.069C15.9999 22.4618 14.8352 23.0339 13.084 23.392C13.7036 23.9228 14.7756 24.2759 15.9999 24.2759Z"
            fill="#D14A41"
          />
          <path
            id="Vector_5"
            d="M15.9929 29.7931H15.9697C14.8199 29.7859 13.8031 29.2574 13.3153 28.4138C13.1631 28.1501 13.2529 27.813 13.5167 27.6601C13.7804 27.5067 14.1181 27.5978 14.2703 27.8621C14.5633 28.368 15.2165 28.6852 15.9763 28.6896C16.7332 28.6797 17.424 28.3746 17.7324 27.8559C17.8885 27.5939 18.2256 27.5078 18.4888 27.6639C18.7509 27.8195 18.8364 28.1577 18.6808 28.4198C18.1766 29.2684 17.1482 29.7931 15.9929 29.7931Z"
            fill="#BDC2C6"
          />
          <g id="Group_2">
            <path
              id="Vector_6"
              d="M4.05081 13.562C3.66956 13.3645 3.24256 13.2415 2.78238 13.2415C1.24581 13.2415 0 14.4762 0 16.0001C0 17.5234 1.24581 18.7587 2.78238 18.7587C2.97275 18.7587 3.15481 18.7289 3.333 18.6898C3.42625 16.5121 3.80256 14.6108 4.05081 13.562Z"
              fill="#FDC794"
            />
            <path
              id="Vector_7"
              d="M31.1404 14.0129C30.4662 14.8461 29.4526 15.3889 28.31 15.4397C28.4678 16.3765 28.6091 17.4871 28.6636 18.6894C28.8429 18.7286 29.0256 18.7589 29.217 18.7589C30.7541 18.7589 31.9999 17.5236 31.9999 16.0003C31.9999 15.2179 31.6684 14.515 31.1404 14.0129Z"
              fill="#FDC794"
            />
          </g>
          <path
            id="Vector_8"
            d="M24.2758 11.5862C24.2758 9.91284 25.3417 8.49215 26.8303 7.95584L22.9969 7.25515C18.3707 6.4099 13.6291 6.4099 9.00294 7.25515L3.45038 8.27034C2.73151 8.40165 2.20682 9.05709 2.20682 9.82347V11.664C2.20682 12.6428 3.04657 13.3854 3.96463 13.2177L9.45869 12.2135C13.7836 11.4229 18.2162 11.4229 22.5412 12.2135L24.4027 12.554C24.3226 12.2439 24.2758 11.9212 24.2758 11.5862Z"
            fill="#A6352B"
          />
          <path
            id="Vector_9"
            d="M28.1378 7.72412C28.5025 7.72412 28.8545 7.77819 29.1889 7.87256C28.6642 5.60388 24.1858 0 16.5516 0C9.35163 0 5.82501 6.47394 5.10719 7.96744L9.00294 7.25519C13.6291 6.40994 18.3707 6.40994 22.9969 7.25519L26.8303 7.95587C27.2391 7.80856 27.6783 7.72412 28.1378 7.72412Z"
            fill="#BE3A2B"
          />
          <path
            id="Vector_10"
            d="M28.1378 8.27551C27.9916 8.27551 27.8454 8.21757 27.7372 8.10338C27.7074 8.07194 24.7177 4.96519 20.9654 4.96519C20.6608 4.96519 20.4136 4.71857 20.4136 4.41344C20.4136 4.10888 20.6608 3.86169 20.9654 3.86169C25.2004 3.86169 28.4042 7.20294 28.5389 7.34526C28.748 7.56651 28.7381 7.91519 28.5168 8.12482C28.4104 8.22532 28.2741 8.27551 28.1378 8.27551Z"
            fill="#A6352B"
          />
          <g id="Group_3">
            <path
              id="Vector_11"
              d="M17.6551 16.5518C17.6551 17.1614 16.9141 18.7587 15.9999 18.7587C15.0857 18.7587 14.3447 17.1614 14.3447 16.5518C14.3447 15.9427 15.0856 15.4484 15.9999 15.4484C16.9141 15.4483 17.6551 15.9427 17.6551 16.5518Z"
              fill="#F9A671"
            />
            <path
              id="Vector_12"
              d="M12.1378 16C11.8333 16 11.5861 15.7528 11.5861 15.4483C11.5861 15.1542 11.1993 14.8965 10.7585 14.8965C10.3177 14.8965 9.93094 15.1542 9.93094 15.4483C9.93094 15.7528 9.68375 16 9.37919 16C9.07463 16 8.82744 15.7528 8.82744 15.4483C8.82744 14.5357 9.69363 13.7931 10.7585 13.7931C11.8234 13.7931 12.6896 14.5357 12.6896 15.4483C12.6896 15.7528 12.4424 16 12.1378 16Z"
              fill="#F9A671"
            />
            <path
              id="Vector_13"
              d="M22.6206 16C22.316 16 22.0688 15.7528 22.0688 15.4483C22.0688 15.1542 21.6821 14.8965 21.2413 14.8965C20.8004 14.8965 20.4137 15.1542 20.4137 15.4483C20.4137 15.7528 20.1665 16 19.8619 16C19.5574 16 19.3102 15.7528 19.3102 15.4483C19.3102 14.5357 20.1764 13.7931 21.2413 13.7931C22.3061 13.7931 23.1723 14.5357 23.1723 15.4483C23.1723 15.7528 22.9251 16 22.6206 16Z"
              fill="#F9A671"
            />
          </g>
          <path
            id="Vector_14"
            d="M31.9999 11.5862C31.9999 13.7191 30.2708 15.4482 28.1378 15.4482C26.0049 15.4482 24.2758 13.7191 24.2758 11.5862C24.2758 9.45325 26.0049 7.72412 28.1378 7.72412C30.2708 7.72412 31.9999 9.45325 31.9999 11.5862Z"
            fill="#D46F67"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_5441">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SantaClausChristmas
