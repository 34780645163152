import React from 'react'
import Icon from '../svgIcons/ClosePhone.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function ClosePhone(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid" size="lg">
      <Icon />
    </IconWrapper>
  )
}
