/* eslint-disable react/function-component-definition */
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Typography, Radio } from 'antd'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isEqual, isInteger } from 'lodash'
import ModalDuplicateRequest from '../../../features/requestModals/components/ModalDuplicateRequest'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import Excel from '../../../common/svg/Excel'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import RequestsTable from './RequestsTable'
import {
  getRequests,
  reset,
  setOpenModalArchiveRequest,
  setOpenModalEditRequest,
  setOpenModalSure,
  setSelectedRequest,
  setSelectedRequestId,
  getDictionaries,
  archiveOpen,
  exportCSV,
  setDefDataRequest
} from '../actions'
import {
  selectActualRequestCount,
  selectArchiveRequestCount,
  selectConsiderationRequestCount,
  selectDictionaryOptionsByName,
  selectModalArchiveOpen,
  selectModalEditOpen,
  selectOpenSureModal,
  selectRequests,
  selectResultRequestCount,
  selectSelectedRequest,
  selectSelectedRequestId,
  selectLoading,
  selectDefDataRequest,
  selectHeaders
} from '../selectors'
import {
  getFilters,
  openFilters,
  setCountFilters,
  setDefFilters,
  setDeleteFilterByKey,
  setFilters,
  setFiltersLabels
} from '../../../features/filters/actions'
import { removeFilterFragments } from '../utils'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { optionsPagination } from '../../../common/components/options'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ModalEditRequest from '../../../features/requestModals/components/ModalEditRequest'
import ModalArchiveRequest from '../../../features/requestModals/components/ModalArchiveRequest'
import ModalCloseSure from '../../candidate/components/ModalCloseSure'
import ControlButton from '../../../common/components/Buttons/Button'
import {
  selectCountFilters,
  selectDefFilters,
  selectDeleteFilterKey,
  selectFilterString,
  selectLabelsFilter
} from '../../../features/filters/selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ModalMap from '../../../features/map/components/ModalMap'
import { setOpenModalMap } from '../../candidateV2/actions'
import { selectOpenModalMap } from '../../candidateV2/selectors'
import MapRequest from '../../../common/svg/MapRequest'
import Search from '../../../common/svg/Search'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ModalAppointCustomer from '../../Request/components/ModalAppointCustomer'
import { selectOpenEditCustomerModal, selectSaveCustomerModal } from '../../Request/selectors'
import { openEditRequestCustomerModal } from '../../Request/actions'
import ResponsiveMenuFilters from '../../../common/components/ResponsiveMenu/ResponsiveMenu'
import { formValuesArray } from '../../../features/filters/components/Filters'
import { objectToQueryString } from '../../../features/filters/utils'
import { selectOpenDuplicateModal } from '../../../features/requestModals/selectors'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'
import './ManagementRequests.scss'

type TFormValues = {
  perPagesCount: number
  globalSearch: string
}

const mainCssClass = getCssClassName('management-requests')
const ManagementRequest: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const defDataRequest = useSelector(selectDefDataRequest)
  const [page, setPage] = useState<number>(defDataRequest?.page || 1)
  const [requestStatus, setRequestStatus] = useState<string>(defDataRequest?.status || '&filter[status][in][]=92')
  const requests = useSelector(selectRequests)
  const resultCount = useSelector(selectResultRequestCount)
  const considerationCount = useSelector(selectConsiderationRequestCount)
  const filtersCount = useSelector(selectCountFilters)
  const archiveCount = useSelector(selectArchiveRequestCount)
  const actualCount = useSelector(selectActualRequestCount)
  const editModal = useSelector(selectModalEditOpen)
  const archiveModal = useSelector(selectModalArchiveOpen)
  const requestId = useSelector(selectSelectedRequestId)
  const request = useSelector(selectSelectedRequest)
  const sureModal = useSelector(selectOpenSureModal)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const filterKey = useSelector(selectDeleteFilterKey)
  const filterLabels = useSelector(selectLabelsFilter)
  const filteredFilterLabel = useMemo(() => filterLabels.filter((item) => item.value.length > 0), [filterLabels])
  const reasonOptions = useSelector(selectDictionaryOptionsByName('archiveReason'))
  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const educationOptions = useSelector(selectDictionaryOptionsByName('requestCandidateEducation'))
  const characteristicOptions = useSelector(selectDictionaryOptionsByName('requestCandidateCharacteristic'))
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const { rbacControl, download_access } = useEntityContext()
  const defFilters = useSelector(selectDefFilters)
  const selectOpenMap = useSelector(selectOpenModalMap)
  const headers = useSelector(selectHeaders)
  const saveCustomerSelect = useSelector(selectSaveCustomerModal)
  const editCustomerModal = useSelector(selectOpenEditCustomerModal)
  const prevDefDataCandidateRef = useRef(defDataRequest)
  const duplicateModal = useSelector(selectOpenDuplicateModal)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)

  const { page: pageRequest, perPage: perPageRequest, filters: filtersRequest } = defDataRequest ?? {}

  const handleOpenEditCustomer = useCallback(() => dispatch(openEditRequestCustomerModal(false)), [])
  const { control, watch, getValues, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataRequest?.perPage || 10,
      globalSearch: defDataRequest?.search || ''
    }
  })

  const perPagesCount = watch('perPagesCount')
  const maxPage = useMemo(() => {
    if (headers?.totalCount) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
    return 0
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handleCancelEdit = useCallback(() => dispatch(setOpenModalSure(true)), [])

  const handleSureClose = useCallback(() => dispatch(setOpenModalSure(false)), [])
  const handleSureOk = useCallback(() => {
    dispatch(setOpenModalEditRequest(false))
    dispatch(setOpenModalSure(false))
    dispatch(setSelectedRequest(null))
    dispatch(setSelectedRequestId(null))
  }, [])

  const handleCancelArchive = useCallback(() => {
    dispatch(setOpenModalArchiveRequest(false))
    dispatch(setSelectedRequestId(null))
  }, [])

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handleChangeRequestStatus = (e) => {
    setPage(1)
    setRequestStatus(e.target.value)
    if (e.target.value === '&filter[status][in][]=650') {
      dispatch(archiveOpen(true))
    } else {
      dispatch(archiveOpen(false))
    }
  }

  const globalSearch = watch('globalSearch')

  const handleExportCSV = () => {
    dispatch(
      exportCSV({
        has_head: true,
        filter: watch('globalSearch')
          ? `${filtersString || ''}&search=${globalSearch}${memoRequestStatus || ''}`
          : `${filtersString || ''}${memoRequestStatus || ''}`
      })
    )
  }
  const handleModalMapOpen = () => {
    dispatch(setOpenModalMap(true))
  }

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const handleClickSearch = () => {
    if (defDataRequest) {
      dispatch(
        setDefDataRequest({
          ...defDataRequest,
          search: globalSearch,
          filters: `${filtersString || ''}${memoRequestStatus}${globalSearch ? `&search=${globalSearch}` : ''}`
        })
      )
    }
  }

  const handleKeyDown = (event: any) => {
    if (defDataRequest) {
      if (event.key === 'Enter') {
        handleClickSearch()
      }
    }
  }

  const memoRequestStatus = useMemo(
    () => (filtersString !== requestStatus ? requestStatus : ''),
    [requestStatus, filtersString]
  )

  const handleBlurSearch = () => {
    if (!globalSearch && defDataRequest) {
      dispatch(
        setDefDataRequest({
          ...defDataRequest,
          search: globalSearch,
          filters: `${filtersString || ''}${globalSearch ? `&search=${globalSearch}` : ''}`
        })
      )
    }
  }

  const handleDeleteSearch = () => {
    if (defDataRequest) {
      dispatch(
        setDefDataRequest({
          ...defDataRequest,
          search: '',
          filters: `${filtersString || ''}`
        })
      )
    }
  }

  const resetValueByKey = (key: string, value: unknown) => {
    const values = JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
    const valuesWithoutDate = { ...values, [key]: Array.isArray(values[key]) ? [] : null }
    const currentFormValuesUpdate = filterLabels.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${pageId || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${pageId || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(pageId || 'candidates', objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: pageId === 'event-funnel' }))
    dispatch(
      setDefFilters({
        type: pageId || 'candidates',
        filter: valuesWithoutDate,
        defFilterString: objectToQueryString(valuesWithoutDate, pageId === 'event-funnel')
      })
    )
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'rejectionReason',
        'schedule',
        'archiveReason',
        'requestCandidateEducation',
        'requestCandidateCharacteristic',
        'priority',
        'requestRejectionReason'
      ])
    )

    if (pageId === defFilters?.type && defFilters?.defFilterString) {
      localStorage.setItem(defFilters.type, defFilters.defFilterString)
      localStorage.setItem(`def${defFilters.type}`, JSON.stringify(defFilters.filter))
      dispatch(setFilters(defFilters?.defFilterString))
    } else if (
      pageId &&
      (localStorage?.getItem(pageId) || localStorage?.getItem(pageId) === '') &&
      (!defFilters?.type || defFilters?.type !== pageId)
    ) {
      dispatch(setFilters(localStorage.getItem(pageId)))

      let parsedFilter
      try {
        parsedFilter = localStorage.getItem(`def${pageId}`) ? JSON.parse(localStorage.getItem(`def${pageId}`)) : ''
      } catch (parseError) {
        console.error('Error parsing defFilter:', parseError)
        parsedFilter = ''
      }

      dispatch(setDefFilters({ type: pageId, defFilterString: localStorage.getItem(pageId), filter: parsedFilter }))
    } else {
      dispatch(setFilters(''))
      dispatch(setDefFilters(null))
    }
  }, [filtersString, pageId])

  useEffect(() => {
    if (localStorage.getItem(`def${pageId}`)) {
      try {
        const parsedFilters = JSON.parse(localStorage.getItem(`def${pageId}`))
        if (!isEqual(parsedFilters, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFilters).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item !== false))
                .length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing filters:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    if (localStorage?.getItem(pageId) && pageId === 'applications') {
      dispatch(
        setDefDataRequest({
          page,
          perPage: perPagesCount,
          filters: defDataRequest?.search
            ? `${localStorage.getItem(pageId)}&search=${defDataRequest?.search}${memoRequestStatus}`
            : `${localStorage?.getItem(pageId)}${memoRequestStatus}`,
          search: defDataRequest?.search || '',
          status: requestStatus
        })
      )
    } else if (!localStorage?.getItem(pageId) && defDataRequest?.search) {
      dispatch(
        setDefDataRequest({
          page,
          perPage: perPagesCount,
          filters: `&search=${defDataRequest?.search}${memoRequestStatus}`,
          search: defDataRequest?.search || '',
          status: requestStatus
        })
      )
    } else {
      dispatch(
        setDefDataRequest({
          page,
          perPage: perPagesCount,
          filters: memoRequestStatus,
          search: defDataRequest?.search || '',
          status: requestStatus
        })
      )
    }
  }, [page, perPagesCount, filtersString, requestStatus, memoRequestStatus])

  useEffect(() => {
    if (pageRequest && perPageRequest && filtersRequest) {
      dispatch(getRequests({ page: pageRequest, perPage: perPageRequest, filters: filtersRequest }))
    }
  }, [pageRequest, perPageRequest, filtersRequest])

  useEffect(() => {
    if (localStorage.getItem(`${pageId}-labels`) && pageId === 'applications') {
      try {
        const parsedLabels = JSON.parse(localStorage.getItem(`${pageId}-labels`))
        dispatch(setFiltersLabels(parsedLabels))
      } catch (parseError) {
        console.error('Error parsing filters labels:', parseError)
        dispatch(setFiltersLabels(formValuesArray))
      }
    } else {
      dispatch(setFiltersLabels(formValuesArray))
    }
  }, [pageId])

  useEffect(() => {
    if (filterKey) {
      resetValueByKey(filterKey)
      dispatch(setDeleteFilterByKey(''))
    }
  }, [filterKey])
  //_________________________Удалить после НГ
  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_________________________Удалить после НГ*/}
          <ChristmasIcon />
          {/*//___*/}
          <Typography.Title level={2} className={`${mainCssClass}__title_application_typography`}>
            Заявки
          </Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons`}>
            <Button
              className={`${mainCssClass}__map-btn`}
              icon={<MapRequest />}
              type="ghost"
              onClick={handleModalMapOpen}
            >
              Заявки на карте{' '}
            </Button>
            <ControlButton
              icon="none"
              typeButton="secondary"
              size="large"
              className="button"
              onClick={handleFiltersOpen}
            >
              <FiltersIcon />
              <div className={`${mainCssClass}__count_filter`}>Фильтры</div>
              {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
            </ControlButton>
          </div>
        )}
      </div>

      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <Radio.Group value={requestStatus} onChange={handleChangeRequestStatus} className={`${mainCssClass}__tabs`}>
              <Radio.Button value="&filter[status][in][]=92">
                Актуальные&nbsp;
                {/* {actualCount || ''} */}
              </Radio.Button>
              <Radio.Button value="&filter[status][in][]=91">
                На рассмотрении&nbsp;
                {/* {considerationCount || ''} */}
              </Radio.Button>
              <Radio.Button value="&filter[status][in][]=650">
                Архив&nbsp;
                {/* {archiveCount || ''} */}
              </Radio.Button>
            </Radio.Group>
            <div className={`${mainCssClass}__search-container_search`}>
              <InputSearchFormControl
                className={`${mainCssClass}__global-search`}
                name="globalSearch"
                control={control}
                placeholder="Название заявки"
                onKeyDown={handleKeyDown}
                onBlur={handleBlurSearch}
                handleDelete={handleDeleteSearch}
              />
              <ControlButton
                typeButton="primary"
                className={`${mainCssClass}__search-container_btn button`}
                icon="left"
                onClick={handleClickSearch}
              >
                <Search />
                Поиск
              </ControlButton>
            </div>
          </div>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <div className={`${mainCssClass}__container-title_result`}>Результат:</div>
              <Typography.Title level={5} className={`${mainCssClass}__title`}>
                &nbsp;
                {headers?.totalCount || ''}
              </Typography.Title>

              {!!headers && !!download_access?.requests && (
                <Button type="ghost" icon={<Excel />} className={`${mainCssClass}__excel`} onClick={handleExportCSV}>
                  {' '}
                  Выгрузить в Excel{' '}
                </Button>
              )}
            </div>
            {!!headers && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />
                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM
                    className="arrowCursor"
                    size="1"
                    onClick={handlePreviousPage}
                    fill={page === 1 ? '#BFC5C0' : '#404542'}
                  />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM
                    className="arrowCursor"
                    size="1"
                    onClick={handleNextPage}
                    fill={page === maxPage ? '#BFC5C0' : '#404542'}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <ResponsiveMenuFilters items={filteredFilterLabel} className={`${mainCssClass}__menu-labels`} />
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <RequestsTable requests={requests || []} className={`${mainCssClass}__table`} />
            </NewScrollContainer>
          </div>
          {/* {request && (
            <>
              <ModalEditRequest
                editMode
                requestId={requestId}
                open={editModal}
                onCancel={handleCancelEdit}
                isRequestTable
                sheduleOptions={sheduleOptions}
                educationOptions={educationOptions}
                characteristicOptions={characteristicOptions}
                priorityOptions={priorityOptions}
              />
              <ModalCloseSure open={sureModal} onCancel={handleSureClose} onOk={handleSureOk} />
            </>
          )} */}
          {archiveModal && (
            <ModalArchiveRequest
              isRequestTable
              options={reasonOptions}
              open={archiveModal}
              onCancel={handleCancelArchive}
              requestId={requestId}
            />
          )}
          <ModalAppointCustomer
            requestId={saveCustomerSelect?.id}
            created_at={saveCustomerSelect?.created_at}
            customer={saveCustomerSelect?.customer}
            customer_list={saveCustomerSelect?.customer_list || []}
            open={editCustomerModal}
            onCancel={handleOpenEditCustomer}
            isRequestTable
          />
          {duplicateModal && <ModalDuplicateRequest open={duplicateModal} />}
        </>
      )}
      <ModalMap isEdit={false} open={selectOpenMap} />
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ManagementRequest))
