/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ notification }: TRootState) => notification

export const selectOpenNotification = createSelector(selectState, ({ openNotification }) => openNotification)

export const selectNotifications = createSelector(selectState, ({ notifications }) => notifications)

export const selectUnreadNotifications = createSelector(selectState, ({ headers }) => headers?.unread)

export const selectDefDataNotify = createSelector(selectState, ({ defData }) => defData)

export const selectNotifyHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)