import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Typography, Modal, ModalProps, DatePicker
} from 'antd'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import { fetchResponsibleList } from '../../../features/chat/utils'
import RequestHistoryTable from './RequestHistoryTable'
import { getHistoryRequestData, openHistoryRequestData } from '../actions'
import {
  selectRequest, selectHistoryRequestData, selectOpenHistoryRequestData, selectRequestHistoryHeaders, selectRequestHistoryLoading
} from '../selectors'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { objectToQueryString } from '../utils'
import EmptyList from '../../../features/emptyList/components/EmptyList'
import './ModalHistoryRequestAction.scss'

const { Title } = Typography

const MainCSSClass = 'actionsRequest'
type TFormValues = {
    actions: string
} & ModalProps

type TFormRequestHistoryRequest = {
  perPagesCount: number
}

export type TFormFilter = {
  user_id: number[]
  date: any
}

function HistoryActionRequest({ className }: TFormValues) {
  const openHistoryAction = useSelector(selectOpenHistoryRequestData)
  const [page, setPage] = useState<number>(1)
  const loading = useSelector(selectRequestHistoryLoading)
  const { control, watch } = useForm<TFormRequestHistoryRequest>()
  const { control: filterControl, watch: filterWatch, getValues } = useForm<TFormFilter>()

  const dispatch = useDispatch()
  const request = useSelector(selectRequest)
  const historyData = useSelector(selectHistoryRequestData)
  const headers = useSelector(selectRequestHistoryHeaders)

  const stringCountsPagination = useMemo(
    () => `${headers?.current || '0'} из ${headers?.total || '0'}`,
    [headers]
  )

  const handleNextPage = useCallback(() => {
    if (page < headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const perPage = watch('perPagesCount')
  const responsible = filterWatch('user_id')
  const date = filterWatch('date')

  useEffect(() => {
    if (request) {
      const filterString = getValues()
      dispatch(getHistoryRequestData({
        id: request.id, filter: objectToQueryString(filterString) || '', page, perPage
      }))
    }
  }, [request, page, perPage, responsible, date])

  return (
    <div className={`${MainCSSClass}`}>
      <div className={`${MainCSSClass}_header`}>
        <Typography.Title level={3}>История заявки</Typography.Title>

      </div>
      {loading && (
        <div>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      {!!historyData?.length && !loading
      && (
        <>
          {/* <div className={`${MainCSSClass}_select_div`}>
            <SelectFormControlMult
                name="actions"
                control={control}
                options={actionOptions}
                className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}
                placeholder="Действие"
                style={{
                  maxHeight: '38px',
                  overflowY: 'auto'}}
              />
            <DebounceSelectFormControlMult
              name="user_id"
              control={filterControl}
              mode="multiple"
              placeholder="Сотрудник"
              notFoundContent="Начните вводить имя"
              fetchOptions={fetchResponsibleList}
              className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}
              style={{
                maxHeight: '60px',
                overflowY: 'auto'
              }}
            />
            <RangePickerFormControl
              name="date"
              control={filterControl}
              className={cn(`${MainCSSClass}_select`, `${MainCSSClass}_select_div_child`)}
            />
          </div> */}
          <div className={`${MainCSSClass}__container-pagination`}>
            <SelectFormControl
              className={`${MainCSSClass}__pagination-count`}
              name="perPagesCount"
              options={optionsPagination}
              // onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
              control={control}
            />

            <div className={`${MainCSSClass}__wrapper-arrow-pagination`}>
              <ArrowRightSM
                className="arrowCursor"
                size="1"
                onClick={handlePreviousPage}
                fill={page === 1 ? '#BFC5C0' : '#404542'}
              />
              <Typography.Text className={`${MainCSSClass}__pagination-font`}>
                {stringCountsPagination
                      || 'неизвестно'}
              </Typography.Text>
              <ArrowLeftSM
                onClick={handleNextPage}
                fill={page >= headers?.total ? '#BFC5C0' : '#404542'}
                className="arrowCursor"
                size="1"
              />
            </div>

          </div>

        </>
      )}

      {!!historyData?.length && !loading && (
        <div className={`${MainCSSClass}_table`}>
          <NewScrollContainer className={`${MainCSSClass}_scroll`}>
            <RequestHistoryTable
              historyData={historyData}
            />
          </NewScrollContainer>
        </div>
      )}

      {!loading && !historyData?.length && (
        <EmptyList type="history" />
      )}

    </div>

  )
}

export default memo(withErrorBoundary(HistoryActionRequest))
