import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography, Input } from 'antd'
import 'dayjs/locale/ru'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import CloseMD from '../../../common/svg/CloseMD'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import {
  fetchCitiesList,
  fetchClientList, fetchRecruiterList, fetchRequestList, fetchResearcherList,
  fetchStoreList, fetchTagsList, fetchVacancyList, filteredOptions,
  getStageState
} from '../../../features/filters/utils'
import {
  createObjectsArray,
  defObject,
  objectToQueryString,
  registrationOptions
} from '../utils'
import { openInternshipFilter, setInternshipFilter } from '../actions'
import './InternshipFilters.scss'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { selectDictionaryOptionsByName } from '../selectors'
import Search from '../../../common/svg/Search'

const mainCssClass = getCssClassName('internship-filters')

export type TTaskFilter = {
    is_came: number
    registration: number
    list_vacancy: number[]
    list_type_registration: number[]
    list_citizenship: number[]
    list_group: number[]
    list_customer: number[]
    list_request: number[]
    list_leadgen_source: number[]
    list_responsible: number[]
    list_researcher: number[]
    list_office: number[]
    date_interview_offline: string[] | null
    date_lid_respond: string[] | null
    date_created_at: string[] | null
    date_registration: string[] | null
    date_internship: string[] | null
    date_interview: string[] | null
    list_status: number[]
    list_stage: number[]
    list_tags: any[]
    list_stage_state: number[]
    list_city: string[]
    store_id: number[]
    time: string[]
  }

function InternshipFilters() {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TTaskFilter>(
    {
      defaultValues: (() => {
        try {
          const storedValues = localStorage.getItem('definternships')
          return storedValues ? JSON.parse(storedValues) : undefined
        } catch (parseError) {
          console.error('Error parsing default values:', parseError)
          return undefined
        }
      })()
    }
  )

  const [allModal, setAllModal] = useState<any>(defObject)
  const registrOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))
  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const workSitesStatusOptions = useSelector(selectDictionaryOptionsByName('WorkSites'))
  const officeOptions = useSelector(selectDictionaryOptionsByName('office'))
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const stageStatusOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const [stageInput, setStageInput] = useState('')

  const [registrTypeInput, setRegistrTypeInput] = useState('')
  const [citizenshipInput, setCitizenshipInput] = useState('')
  const [groupInput, setGroupInput] = useState('')
  const [statusStageInput, setStatusStageInput] = useState('')
  const [statusCandidateInput, setStatusCandidateInput] = useState('')
  const [workSitesInput, setWorkSitesInput] = useState('')
  const [officeInput, setOfficeInput] = useState('')
  const [timeInput, setTimeInput] = useState('')

  const [statusStageOptions, setStatusStageOptions] = useState<any>(undefined)
  const [timeOptions, setTimeOptions] = useState(createObjectsArray())

  const handleRegistrTypeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRegistrTypeInput(e.target.value)
  }
  const handleStatusStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusStageInput(e.target.value)
  }
  const handleWorkSitesInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setWorkSitesInput(e.target.value)
  }
  const handleCitizenshipInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCitizenshipInput(e.target.value)
  }
  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }
  const handleOfficeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setOfficeInput(e.target.value)
  }
  const handleStatusCandidateInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusCandidateInput(e.target.value)
  }
  const handleStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStageInput(e.target.value)
  }
  const handleTimeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTimeInput(e.target.value)
  }

  const handleAllReset = useCallback(() => {
    localStorage.removeItem('internships')
    localStorage.removeItem('definternships')
    dispatch(setInternshipFilter(''))
    reset({})
    setValue('date_registration', null)
    setValue('date_created_at', null)
    setValue('date_interview', null)
    setValue('date_lid_respond', null)
    setValue('date_registration', null)
    setValue('date_internship', null)
  }, [])

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const resetValueByKey = (key: string, value: unknown) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: value }
    localStorage.setItem('definternships', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('internships', objectToQueryString(valuesWithoutDate))
    dispatch(setInternshipFilter(objectToQueryString(valuesWithoutDate)))
    setValue(key, value)
  }

  const handleApply = () => {
    localStorage.setItem('internships', objectToQueryString(getValues()))
    localStorage.setItem('definternships', JSON.stringify(getValues()))
    dispatch(setInternshipFilter(objectToQueryString(getValues())))
  }

  const dateRegistration = watch('date_registration') as unknown as string[] | null
  const dateInternship = watch('date_internship') as unknown as string[] | null
  const dateCreated = watch('date_created_at') as unknown as string[] | null

  useEffect(() => {
    getStageState()
      .then((responseData) => {
        setStatusStageOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={() => dispatch(openInternshipFilter(false))}>
              <CloseMD />
            </ControlButton>
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Cотрудник пришёл на стажировку
                </Typography.Title>
                {watch('is_came') && watch('is_came')
                    && <Redo onClick={() => resetValueByKey('is_came', null)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('is_came')}>
                {allModal.is_came ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.is_came && (
              <div className={`${mainCssClass}_items_content-short`}>
                <SelectFormControl
                  control={control}
                  name="is_came"
                  label="Cотрудник пришёл на стажировку"
                  options={registrationOptions}
                />
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Оформление
                </Typography.Title>
                {watch('registration') && watch('registration')
                    && <Redo onClick={() => resetValueByKey('registration', null)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('registration')}>
                {allModal.registration ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.registration && (
              <div className={`${mainCssClass}_items_content-short`}>
                <SelectFormControl
                  control={control}
                  name="registration"
                  label="Оформление"
                  options={registrationOptions}
                />
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Вакансия
                  {watch('list_vacancy') && watch('list_vacancy').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_vacancy').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_vacancy') && watch('list_vacancy').length > 0
                    && <Redo onClick={() => resetValueByKey('list_vacancy', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('vacancy_id')}>
                {allModal.vacancy_id ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.vacancy_id && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_vacancy"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Вакансия"
                  fetchOptions={fetchVacancyList}
                />
              </div>
            )}
          </div>
          {' '}
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Вид оформления
                  {watch('list_type_registration') && watch('list_type_registration').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_type_registration').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_type_registration') && watch('list_type_registration').length > 0 && (
                  <Redo onClick={() => resetValueByKey('list_type_registration', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('registrType')}>
                {allModal.registrType ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.registrType && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={registrTypeInput}
                    onChange={handleRegistrTypeInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(registrOptions, registrTypeInput)}
                    control={control}
                    name="list_type_registration"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Время проведения
                  {watch('time') && watch('time').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('time').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('time') && watch('time').length > 0 && <Redo onClick={() => resetValueByKey('time', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('time')}>
                {allModal.time ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.time && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={timeInput}
                    onChange={handleTimeInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(timeOptions, timeInput)}
                    control={control}
                    name="time"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Город
                  {watch('list_city') && watch('list_city').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_city').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>

                {watch('list_city') && watch('list_city').length > 0
                    && <Redo onClick={() => resetValueByKey('list_city', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('cities')}>
                {allModal.cities ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.cities && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_city"
                  control={control}
                  mode="multiple"
                  label="Город"
                  placeholder="Поиск по наименованию..."
                  fetchOptions={fetchCitiesList}
                  notFoundContent="Начните вводить город"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Гражданство
                  {watch('list_citizenship') && watch('list_citizenship').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_citizenship').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_citizenship') && watch('list_citizenship')?.length > 0
                    && <Redo onClick={() => resetValueByKey('list_citizenship', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('citizenship')}>
                {allModal.citizenship ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.citizenship && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>

                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={citizenshipInput}
                    onChange={handleCitizenshipInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(citizenshipOptions, citizenshipInput)}
                    control={control}
                    name="list_citizenship"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Группы
                  {watch('list_group') && watch('list_group').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_group').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_group') && watch('list_group').length > 0
                    && <Redo onClick={() => resetValueByKey('list_group', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('group')}>
                {allModal.group ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>

            </div>
            {allModal.group && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={groupInput}
                    onChange={handleGroupInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(groupOptions, groupInput)}
                    control={control}
                    name="list_group"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Заказчик
                  {watch('list_customer') && watch('list_customer').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_customer').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_customer') && watch('list_customer')?.length > 0
                    && <Redo onClick={() => resetValueByKey('list_customer', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('client')}>
                {allModal.client ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.client && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_customer"
                  control={control}
                  mode="multiple"
                  label="Заказчик"
                  placeholder="Поиск по имени..."
                  fetchOptions={fetchClientList}
                />
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Заявка
                  {watch('list_request') && watch('list_request').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_request').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_request') && watch('list_request').length > 0
                    && <Redo onClick={() => resetValueByKey('list_request', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('requestContent')}>
                {allModal.requestContent ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.requestContent && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_request"
                  control={control}
                  placeholder="Поиск по наименованию..."
                  mode="multiple"
                  label="Заявка"
                  fetchOptions={fetchRequestList}
                />
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Источник
                  {watch('list_leadgen_source') && watch('list_leadgen_source').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_leadgen_source').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_leadgen_source') && watch('list_leadgen_source').length > 0 && (
                  <Redo onClick={() => resetValueByKey('list_leadgen_source', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('workSites')}>
                {allModal.workSites ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.workSites && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={workSitesInput}
                    onChange={handleWorkSitesInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(workSitesStatusOptions, workSitesInput)}
                    control={control}
                    name="list_leadgen_source"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный рекрутер
                  {watch('list_responsible') && watch('list_responsible').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_responsible').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_responsible') && watch('list_responsible').length > 0
                    && <Redo onClick={() => resetValueByKey('list_responsible', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('recruiter')}>
                {allModal.recruiter ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.recruiter && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_responsible"
                  control={control}
                  mode="multiple"
                  label="Рекрутер"
                  placeholder="Поиск по имени..."
                  notFoundContent="Начните вводить имя"
                  fetchOptions={fetchRecruiterList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный ресечер
                  {watch('list_researcher') && watch('list_researcher').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_researcher').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_researcher') && watch('list_researcher').length > 0
                    && <Redo onClick={() => resetValueByKey('list_researcher', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('researcher')}>
                {allModal.researcher ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.researcher && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_researcher"
                  notFoundContent="Начните вводить имя"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по имени..."
                  label="Ресечер"
                  fetchOptions={fetchResearcherList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Офисы
                  {watch('list_office') && watch('list_office').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_office').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_office') && watch('list_office').length > 0
                    && <Redo onClick={() => resetValueByKey('list_office', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('officeContent')}>
                {allModal.officeContent ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.officeContent && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>

                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={officeInput}
                    onChange={handleOfficeInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(officeOptions, officeInput)}
                    control={control}
                    name="list_office"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата оффлайн собеседования</Typography.Title>
                {watch('date_interview_offline') && watch('date_interview_offline')?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_interview_offline', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('interviewOffline')}>
                {allModal.interviewOffline ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.interviewOffline && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_interview_offline"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата добавления</Typography.Title>
                {dateCreated && dateCreated?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_created_at', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_created_at')}>
                {allModal.date_created_at ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_created_at && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_created_at"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата интервью</Typography.Title>
                {watch('date_interview') && watch('date_interview')?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_interview', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_interview')}>
                {allModal.date_interview ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_interview && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_interview"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата отклика</Typography.Title>
                {watch('date_lid_respond') && watch('date_lid_respond')?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_lid_respond', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_lid_respond')}>
                {allModal.date_lid_respond ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_lid_respond && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_lid_respond"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата оформления</Typography.Title>
                {Array.isArray(dateRegistration) && dateRegistration.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_registration', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_registration')}>
                {allModal.date_registration ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_registration && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_registration"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата стажировки</Typography.Title>
                {dateInternship && dateInternship?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('date_internship', null)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_internship')}>
                {allModal.date_internship ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_internship && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_internship"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Объект
                  {watch('store_id') && watch('store_id').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('store_id').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('store_id') && watch('store_id').length > 0
                    && <Redo onClick={() => resetValueByKey('store_id', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('store')}>
                {allModal.store ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.store && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="store_id"
                  placeholder="Поиск по наименованию..."
                  control={control}
                  mode="multiple"
                  notFoundContent="Начните вводить название объекта"
                  label="Объект"
                  fetchOptions={fetchStoreList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Статус кандидата
                  {watch('list_status') && watch('list_status').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_status').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_status') && watch('list_status').length > 0
                    && <Redo onClick={() => resetValueByKey('list_status', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('statusCandidate')}>
                {allModal.statusCandidate ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.statusCandidate && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={statusCandidateInput}
                    onChange={handleStatusCandidateInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(candidateStatusOptions, statusCandidateInput)}
                    control={control}
                    name="list_status"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Cтатус на этапе
                  {watch('list_stage_state') && watch('list_stage_state').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_stage_state').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_stage_state') && watch('list_stage_state').length > 0
                    && <Redo onClick={() => resetValueByKey('list_stage_state', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('stage')}>
                {allModal.stage ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.stage && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={statusStageInput}
                    onChange={handleStatusStageInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(statusStageOptions, statusStageInput)}
                    control={control}
                    name="list_stage_state"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Тэги
                  {watch('list_tags') && watch('list_tags').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_tags').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_tags') && watch('list_tags').length > 0
                    && <Redo onClick={() => resetValueByKey('list_tags', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('tag')}>
                {allModal.tag ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.tag && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_tags"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Тэг"
                  fetchOptions={fetchTagsList}
                  notFoundContent="Начните вводить тэг"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Этап
                  {watch('list_stage') && watch('list_stage').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_stage').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_stage') && watch('list_stage').length > 0
                    && <Redo onClick={() => resetValueByKey('list_stage', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('stageState')}>
                {allModal.stageState ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.stageState && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={stageInput}
                    onChange={handleStageInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(stageStatusOptions, stageInput)}
                    control={control}
                    name="list_stage"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>

        </div>
      </NewScrollContainer>
    </div>
  )
}

export default memo(withErrorBoundary(InternshipFilters))
