import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import axios from 'axios'
import 'antd/dist/antd-with-locales'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import 'moment/locale/ru'
import createStore, { sagaMiddleware } from '../common/store/store'
import saga from '../common/store/rootSaga'
import InnerRouter from '../compositions/InnerRouter/components/InnerRouter'
import 'dotenv/config'
import InnerRouterMobil from '../compositions/InnerRouter/components/InnerRouterMobil'
import './App.scss'

import YandexMetrika from './YandexMetrika'

const { store } = createStore()
sagaMiddleware.run(saga)


axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const isFullVersion = localStorage.getItem('fullVersion')

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const uri = queryParams.get('redirectUrl')
    localStorage.setItem('redirectUrl', uri || '')
  }, [])


  return (
    <>
      <YandexMetrika />
      <ConfigProvider locale={ruRU}>
        <Provider store={store}>
          <Routes>
            {isMobile && isFullVersion !== 'true' ? (
              <>
                <Route path="/" Component={InnerRouterMobil} />
                <Route path="/:pageId/:entityId/:subPageId" Component={InnerRouterMobil} />
                <Route path="/:pageId/:entityId" Component={InnerRouterMobil} />
                <Route path="/:pageId" Component={InnerRouterMobil} />
              </>
            )
              : (
                <>
                  <Route path="/" Component={InnerRouter} />
                  <Route path="/:pageId/:entityId/:subPageId" Component={InnerRouter} />
                  <Route path="/:pageId/:entityId" Component={InnerRouter} />
                  <Route path="/:pageId" Component={InnerRouter} />
                </>
              )}
          </Routes>
        </Provider>
      </ConfigProvider>
    </>
  )
}

export default App
