import React, {
  memo,
  useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import {
  Typography
} from 'antd'
import { useForm } from 'react-hook-form'
import EditPencil1 from '../../../common/svg/EditPencil1'
import Redo from '../../../common/svg/Redo'
import Archive from '../../../common/svg/Archive'
import { getCssClassName } from '../../../common/utils/generateClassName'

import {
  createOrEditUser,
  getDictionariesDropdown,
  getRoleList,
  getSelectedUser,
  getUsers,
  reset, setDefDataUser, setSelectedUser, updateUserModel
} from '../actions'
import {
  selectCreateOrEditUserFormShow,
  selectDefDataUser,
  selectLoading,
  selectUserListActive,
  selectUserListArchive,
  selectUsersHeaders
} from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import { TUser } from '../../../common/api/systems'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import UserForm from './UserForm'
import UserAvatar from '../../../common/svg/UserAvatar'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import InputFormControlGlobalSearch from '../../../common/components/formcontrol/InputFormControlGlobalSearch'
import { generateUserListFilters } from '../utils'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './UserListConstructor.scss'

const mainCssClass = getCssClassName('user-list-constructor')

export type TFormValues = {
  perPagesCount: number
  filter:string
}

function UserListConstructor() {
  const dispatch = useDispatch()
  const userFormShow = useSelector(selectCreateOrEditUserFormShow)
  const userListActive = useSelector(selectUserListActive)
  const userListArchive = useSelector(selectUserListArchive)
  const scrollbarsRef = useRef(null)
  const defDataUser = useSelector(selectDefDataUser)
  const headers = useSelector(selectUsersHeaders)

  const [page, setPage] = useState<number>(defDataUser?.page || 1)

  const loading = useSelector(selectLoading)

  const {
    control, watch, getValues, setValue
  } = useForm<TFormValues>({
    defaultValues: {
      perPagesCount: defDataUser?.perPage || 10
    }
  })

  const perPage = watch('perPagesCount')
  const filter = watch('filter')

  const { rbacControl } = useEntityContext()
  const [userStatus, setUserStatus] = useState<string>('active')
  const activeUserListOnTab = useMemo(() => {
    if (userStatus === 'active') {
      return userListActive
    }
    return userListArchive
  }, [userListArchive, userListActive, userStatus])

  const handleArchive = (item) => {
    dispatch(updateUserModel({ user: { ...item, status: 0 } }))
  }

  const handleReturnArchive = (item) => {
    dispatch(updateUserModel({ user: { ...item, status: 10 } }))
  }
  const handleEdit = (item:TUser) => {
    if (item.id) {
      dispatch(getSelectedUser(item.id))
    }
    if (scrollbarsRef.current) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToTop()
    }
  }

  const handleChangeStatusUser = useCallback((e) => {
    setUserStatus(e)
  }, [userListActive, userListArchive])

  const handleCreateUser = () => {
    dispatch(createOrEditUser(true))
    dispatch(setSelectedUser(null))

    if (scrollbarsRef.current) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToTop()
    }
  }

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleNextPage = useCallback(() => {
    if (page !== headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  useEffect(() => {
    if (defDataUser) {
      dispatch(getUsers(defDataUser))
    }
  }, [defDataUser])

  useEffect(() => {
    dispatch(setDefDataUser({ filters: generateUserListFilters(filter), page, perPage }))
  }, [perPage, page, filter])

  useEffect(() => {
    if (headers && page > headers?.total) { setPage(1) }
  }, [headers])

  useEffect(() => {
    dispatch(getDictionariesDropdown(['group']))
    dispatch(getRoleList())
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>

      <div className={`${mainCssClass}_create`}>
        <Typography.Title level={3}>
          Пользователи
        </Typography.Title>

        {(loading || !headers) && <Preloader size="md" />}

        {!loading && (
          <>

            {headers
          && (
            <div className={`${mainCssClass}_search-container`}>
              <InputFormControlGlobalSearch name="filter" control={control} placeholder="Начните вводить название" className={`${mainCssClass}_global-search`} />

              <SelectFormControl
                className={`${mainCssClass}__pagination-count`}
                name="perPagesCount"
                control={control}
                options={optionsPagination}
                onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
              />

              <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                <Typography.Text className={`${mainCssClass}__pagination-font`}>
                  {stringCountsPagination}
                </Typography.Text>
                <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === headers?.total ? '#BFC5C0' : '#404542'} />
              </div>
            </div>
          )}
            {headers
             && (
               <div className={`${mainCssClass}__btn-header-container`}>
                 <div className={`${mainCssClass}__btn-header-container_tab`}>
                   <div
                     onClick={() => handleChangeStatusUser('active')}
                     className={cn(`${mainCssClass}__btn-header-container_tab_item`, {
                       [`${mainCssClass}__btn-header-container_tab_item_active`]: userStatus === 'active'
                     })}
                   >
                     Активные
                     {' '}
                     {userListActive.length || 0}
                   </div>
                   <div
                     onClick={() => handleChangeStatusUser('archive')}
                     className={cn(`${mainCssClass}__btn-header-container_tab_item`, {
                       [`${mainCssClass}__btn-header-container_tab_item_active`]: userStatus === 'archive'
                     })}
                   >
                     Архив
                     {' '}
                     {userListArchive?.length || 0}
                   </div>
                 </div>
                 {rbacControl?.users?.create_access === 1 && (
                   <ControlButton
                     typeButton="secondary"
                     size="large"
                     icon="left"
                     className="button"
                     onClick={handleCreateUser}
                   >
                     <AddPlusSvg />
                     Создать пользователя
                   </ControlButton>
                 )}
               </div>
             )}

            <div className={`${mainCssClass}_create_scrollContainer`}>
              <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`} scrollbarsRef={scrollbarsRef}>

                {userFormShow && rbacControl?.users.edit_view_access === 1 && (
                  <UserForm />
                )}
                <div className={`${mainCssClass}_create_role-list`}>
                  {activeUserListOnTab?.map((item) => (
                    <div
                      key={item?.role_id}
                      className={`${mainCssClass}_create_role-list_item`}
                    >
                      <div className={`${mainCssClass}__img-container`}>
                        {item.avatar ? (<img src={item?.avatar} alt="avatar" />) : (<UserAvatar size="0.5" />)}
                      </div>
                      <div className={`${mainCssClass}_create_role-list_item_all_text`}>
                        <div className={`${mainCssClass}_create_role-list_item_text`}>
                          <Typography.Text className={`${mainCssClass}_create_role-list_item_text_title`}>
                            {item.status !== 10 ? `${item.name} ${item.surname} (архив)` : `${item.name} ${item.surname}`}
                          </Typography.Text>
                          <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_quantity`}>
                            {item?.role?.description}
                          </Typography.Text>
                        </div>
                        <div className={`${mainCssClass}_create_role-list_item_text2`}>
                          <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_quantity`}>
                            {item.phone}
                          </Typography.Text>
                          <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_quantity`}>
                            {item.email}
                          </Typography.Text>
                        </div>
                      </div>
                      <div className={`${mainCssClass}_create_role-list_item_action`}>
                        {item.status === 10
                          ? (
                            <>
                              {
                                rbacControl?.users.edit_view_access === 1
                               && (
                                 <ControlButton typeButton="secondary" className="button" onClick={() => handleEdit(item)}>
                                   <EditPencil1 />
                                 </ControlButton>
                               )
                              }
                              {
                                rbacControl?.users.archive_access === 1
                               && (
                                 <ControlButton typeButton="secondary" className="button" onClick={() => handleArchive(item)}>
                                   <Archive />
                                 </ControlButton>
                               )
                              }
                            </>
                          ) : (
                            <>
                              {rbacControl?.users.edit_view_access === 1 && (
                                <ControlButton typeButton="secondary" className="button" onClick={() => handleReturnArchive(item)}>
                                  <Redo />
                                </ControlButton>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </NewScrollContainer>
            </div>
          </>
        )}

      </div>

    </div>
  )
}

export default memo(withErrorBoundary(UserListConstructor))
