import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ modalsInterview }: TRootState) => modalsInterview

export const selectEmptySlots = createSelector(selectState, ({ emptySlots }) => emptySlots?.map((item: string) => ({ value: item, label: item })) || [])


export const selectActiveInterviewModal = createSelector(selectState, ({ activeInterviewModal }) => activeInterviewModal)
