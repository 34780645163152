import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ tasks }: TRootState) => tasks

export const selectTaskCreateModal = createSelector(selectState, ({ createModal }) => createModal)

export const selectTasks = createSelector(selectState, ({ tasks }) => tasks)

export const selectTasksFilter = createSelector(selectState, ({ tasksFilter }) => tasksFilter)

export const selectMoreInfoModal = createSelector(selectState, ({ moreInfoModal }) => moreInfoModal)
export const selectSelectTask = createSelector(selectState, ({ selectTask }) => selectTask)

export const selectFilterString = createSelector(selectState, ({ filter }) => filter)

export const selectIsArchive = createSelector(selectState, ({ is_archive }) => is_archive)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)
export const selectNotifyTask = createSelector(selectState, ({ notifyTask }) => notifyTask)

export const selectTasksLength = createSelector(selectState, ({ tasks }) => tasks.reduce((acc, cur) => acc += cur.list.length, 0))
