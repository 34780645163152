import React from 'react'
import { TClassName } from '../types/props'

const ApproveEdit: React.FC<TClassName> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9284 0.861657C11.6386 -0.164281 13.1046 -0.296469 13.9869 0.585865L17.5122 4.11114C18.3946 4.99347 18.2624 6.45947 17.2364 7.16973L13.8878 9.48799C13.6901 9.62486 13.5187 9.79626 13.3819 9.99395L11.0636 13.3425C10.3533 14.3685 8.88735 14.5007 8.00502 13.6183L4.47974 10.0931C3.59741 9.21073 3.7296 7.74473 4.75554 7.03447L8.10413 4.71621C8.30183 4.57935 8.47323 4.40795 8.61009 4.21025L10.9284 0.861657ZM12.5727 2.00008L16.098 5.52535L12.7494 7.84361C12.354 8.11734 12.0112 8.46014 11.7375 8.85553L9.41923 12.2041L5.89396 8.67885L9.24255 6.3606C9.63795 6.08686 9.98074 5.74407 10.2545 5.34867L12.5727 2.00008Z" fill="#BFC5C0" />
    <path d="M7.10657 12.72L5.37799 10.9914L0.160357 17.0118C-0.0651681 17.272 -0.0512477 17.6623 0.192245 17.9058C0.435737 18.1493 0.826005 18.1632 1.08623 17.9376L7.10657 12.72Z" fill="#BFC5C0" />
  </svg>
)
export default ApproveEdit
