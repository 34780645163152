/* eslint-disable react/function-component-definition */
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { Typography, Tabs } from 'antd'
import { useForm } from 'react-hook-form'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useParams } from 'react-router'
import ControlButton from '../../../common/components/Buttons/Button'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import {
  getDictionaries,
  getInternship,
  openInternshipFilter,
  reset,
  setDefDataInternship,
  setInternshipFilter,
  setPage,
  setSearchInternship
} from '../actions'
import {
  selectDefDataInternship,
  selectFilterString,
  selectInternship,
  selectInternshipDateModal,
  selectLoading,
  selectOpenInternshipModal,
  selectPage,
  selectSearchString
} from '../selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import TabPane = Tabs.TabPane
import './InternshipView.scss'
import { SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import Internship from './Internship'
import InternshipModal from './InternshipModal'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import Search from '../../../common/svg/Search'
import { selectCountFilters, selectDefFilters } from '../../../features/filters/selectors'
import { setCountFilters } from '../../../features/filters/actions'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import InternshipEditModal from './InternshipEditModal'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const { Title } = Typography
dayjs.extend(customParseFormat)
const mainCssClass = 'internship-view'

type TFormValues = {
  internshipValue: string
  perPagesCount: number
  search: string
}
const InternshipView: FC = () => {
  const dispatch = useDispatch()
  const { control, getValues, handleSubmit, watch, setValue } = useForm<TFormValues>({
    defaultValues: {
      internshipValue: '1',
      perPagesCount: 10,
      name: ''
    }
  })
  const perPagesCount = watch('perPagesCount')
  const search = watch('search')
  const page = useSelector(selectPage)
  const searchString = useSelector(selectSearchString)
  const internships = useSelector(selectInternship)
  const defDataInternship = useSelector(selectDefDataInternship)
  const filtersString = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)
  const filtersCount = useSelector(selectCountFilters)
  const openInternshipModal = useSelector(selectOpenInternshipModal)
  const filterString = useSelector(selectFilterString)
  const internshipDateModal = useSelector(selectInternshipDateModal)
  const { pageId } = useParams()
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const [pageCount, setPageCount] = useState(0)
  const [internshipValue, setInternshipValue] = useState('1')
  const [stringPagination, setStringPagination] = useState('')
  const [currentDate, setCurrentDate] = useState(dayjs().format(SERVER_DATE_FORMAT))
  const getDateByPeriod = (period: string) => {
    if (period === '1') {
      return dayjs().format(SERVER_DATE_FORMAT)
    }
    return currentDate
  }
  const handleIncrementPage = () => {
    setPageCount((prev) => prev + 1)
  }
  const date = useMemo(() => {
    const currentDate = dayjs()
    let period
    if (internshipValue === '7') {
      if (page === 0) {
        const monday = currentDate.startOf('week').format('DD.MM.YYYY')
        const sunday = currentDate.endOf('week').format('DD.MM.YYYY')
        period = `${monday}-${sunday}`
        setStringPagination(period)
      } else {
        const monday = currentDate
          .startOf('week')
          .add(Number(internshipValue) * page, 'day')
          .format('DD.MM.YYYY')
        const sunday = currentDate
          .endOf('week')
          .add(Number(internshipValue) * page, 'day')
          .format('DD.MM.YYYY')
        period = `${monday}-${sunday}`
        setStringPagination(period)
      }
    } else if (page === 0) {
      period = `${currentDate.format('DD.MM.YYYY')}-${currentDate.format('DD.MM.YYYY')}`
      setStringPagination(currentDate.format('DD.MM.YYYY'))
    } else {
      period = `${currentDate.add(Number(internshipValue) * page, 'day').format('DD.MM.YYYY')}-${currentDate
        .add(Number(internshipValue) * page, 'day')
        .format('DD.MM.YYYY')}`
      setStringPagination(currentDate.add(Number(internshipValue) * page, 'day').format('DD.MM.YYYY'))
    }
    return period
  }, [page, internshipValue])
  const handleInternshipValueChange = (value: string) => {
    setInternshipValue(value)
    setCurrentDate(getDateByPeriod(value))
  }
  const handleResetPage = () => {
    setPageCount(0)
  }
  const handleNextPage = useCallback(() => {
    dispatch(setPage(page + 1))
    setPageCount(0)
  }, [page])

  const handlePreviousPage = useCallback(() => {
    dispatch(setPage(page - 1))
    setPageCount(0)
  }, [page])

  const handleFiltersOpen = () => {
    dispatch(openInternshipFilter(true))
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setValue('search', search.trim())
      dispatch(setSearchInternship(search.trim()))
    }
  }

  const handleBlurSearch = () => {
    if (!search.trim()) {
      dispatch(setSearchInternship(search.trim()))
      setValue('search', '')
    }
  }

  const handleClickSearch = () => {
    dispatch(setSearchInternship(search.trim()))
  }

  const handleDeleteSearch = () => {
    dispatch(setSearchInternship(''))
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'candidateStatus',
        'interviewRejectionReason',
        'interviewNote',
        'interviewResult',
        'statusResult',
        'typeRegistrationWork',
        'stageStatus',
        'citizenship',
        'group',
        'WorkSites',
        'office'
      ])
    )
  }, [])

  useEffect(() => {
    if (localStorage?.getItem('internships') || localStorage?.getItem('internships') === '') {
      dispatch(setInternshipFilter(localStorage.getItem('internships')))
    }
  }, [])

  useEffect(() => {
    try {
      const storedFilters = localStorage.getItem(`def${pageId}`)
      if (storedFilters) {
        let filters
        try {
          filters = JSON.parse(storedFilters)
        } catch (parseError) {
          console.error('Error parsing filters:', parseError)
          dispatch(setCountFilters(0))
          return
        }

        if (!isEqual(filters, {})) {
          const filterCount = Object.values(filters).filter((item) =>
            Array.isArray(item) ? item.length > 0 : !!item !== false
          ).length
          dispatch(setCountFilters(filterCount))
        } else {
          dispatch(setCountFilters(0))
        }
      } else {
        dispatch(setCountFilters(0))
      }
    } catch (error) {
      console.error('Error processing filters:', error)
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    if (date) {
      dispatch(
        getInternship({
          page: pageCount,
          date,
          filter: filterString,
          search: searchString || ''
        })
      )
    }
  }, [date, pageCount, filterString, searchString])
  //_________________________Удалить после НГ
  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={`${mainCssClass}`}>
      <div className={`${mainCssClass}__header-block`}>
        <div className={`${mainCssClass}__heading-block`}>
          <div className={`${mainCssClass}__title-block`}>
            {/*//______________________Удалить после НГ*/}
            <ChristmasIcon />
            {/*//__________________________*/}
            <Title level={2}>Стажировка</Title>
            {loading && <Preloader className={`${mainCssClass}__loader`} />}
          </div>
          <SelectFormControl
            control={control}
            name="internshipValue"
            options={[
              { label: 'Неделя', value: '7' },
              { label: 'День', value: '1' }
            ]}
            className={`${mainCssClass}__select`}
            value={internshipValue}
            onChange={handleInternshipValueChange}
          />
          <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
            <ArrowRightSM
              size="1"
              onClick={handlePreviousPage}
              fill="#404542"
              className={`${mainCssClass}__pagination-cursor`}
            />
            <Typography.Text className={`${mainCssClass}__pagination-font`}>{stringPagination}</Typography.Text>
            <ArrowLeftSM
              size="1"
              onClick={handleNextPage}
              fill="#404542"
              className={`${mainCssClass}__pagination-cursor`}
            />
          </div>
        </div>
        <ControlButton
          icon="left"
          size="large"
          typeButton="secondary"
          className={cn('button', `${mainCssClass}__filter-button`)}
          onClick={handleFiltersOpen}
        >
          <FiltersIcon />
          Фильтры
          {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
        </ControlButton>
      </div>
      <div className={`${mainCssClass}__search-container`}>
        <InputSearchFormControl
          name="search"
          control={control}
          placeholder="ФИО, телефон"
          onKeyDown={handleKeyDown}
          onBlur={handleBlurSearch}
          handleDelete={handleDeleteSearch}
        />
        <ControlButton
          typeButton="primary"
          className={`${mainCssClass}__search-container_btn button`}
          icon="left"
          onClick={handleClickSearch}
        >
          <Search />
          Поиск
        </ControlButton>
      </div>
      {!loading && (
        <div className={`${mainCssClass}__scroll-div`}>
          <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
            <Internship
              handleIncrementPage={handleIncrementPage}
              internships={internships}
              internshipValue={internshipValue}
              currentDate={currentDate}
              className={`${mainCssClass}__table`}
            />
          </NewScrollContainer>
        </div>
      )}
      {internshipDateModal && <InternshipEditModal open={internshipDateModal} date={date} />}
      {openInternshipModal && (
        <InternshipModal
          open={openInternshipModal}
          search={defDataInternship?.search || ''}
          date={date}
          handleReset={handleResetPage}
        />
      )}
    </div>
  )
}

export default memo(withErrorBoundary(InternshipView))
