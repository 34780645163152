import React from 'react'
import { Checkbox, CheckboxProps, CheckboxOptionType } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from 'classnames'
import './CheckBoxFormControl.scss'

type CustomCheckboxOptionType = {
  label: string
  value: string | boolean
  description?: string // Добавляем опциональное свойство description
}

type TControlCheckBoxProps = Omit<
  UseControllerProps &
    CheckboxProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      options?: CustomCheckboxOptionType[]
    },
  'isDisabled'
>

function CheckBoxFormControl({
  name, control, rules, className, size, options, ...rest
}: TControlCheckBoxProps) {
  const { field } = useController({ name, control, rules })

  return (
    <Checkbox.Group
      options={options}
      value={field.value}
      onChange={field.onChange}
      className={cn(
        className,
        'checkBox',
        { checkBoxLarge: size === 'large' },
        { checkBoxMiddle: size === 'middle' },
        { checkBoxSmall: size === 'small' }
      )}
      {...rest}
    />
  )
}

export default CheckBoxFormControl
