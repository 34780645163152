/* eslint-disable react/function-component-definition */
/* eslint-disable no-redeclare */
/* eslint-disable no-console */
import React, {
  FC, memo, useEffect, useMemo
} from 'react'
import {
  DeleteOutlined, PlusOutlined, DownloadOutlined, LinkOutlined
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import SummaryInfo from './SummaryInfo'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate } from '../selectors'
import { getCandidateResume, selectActiveResume, selectCandidateResume, selectLoading, setActiveResume, setResumeList } from '../../../compositions/Resume'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import './SummaryCandidateInterview.scss'

const MainCSSClass = 'summaryCandidateInterview'

const SummaryCandidate: FC = () => {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const activeResume = useSelector(selectActiveResume)
  const resumeList = useSelector(selectCandidateResume)
  const { rbacControl } = useEntityContext()
  const loading = useSelector(selectLoading)

  const { control, watch, setValue } = useForm<TFormValues>()

  type TFormValues = {
    date: string
  }

  const date = watch('date')

  useEffect(() => {
    if (date) {
      const candidateResume = resumeList?.find((obj) => obj.labels.name === date)

      if (candidateResume) {
        if (activeResume && resumeList) {
          // eslint-disable-next-line no-return-assign
          const newResumeList = resumeList.map((item) => (item.id === activeResume.id ? (item = activeResume) : item))
          dispatch(setResumeList(newResumeList))
        }
        dispatch(setActiveResume(candidateResume))
      }
    }
  }, [date])

  // const options = candidate?.resume_list?.map((el) => ({ label: el.labels.name, value: el.labels.name }))

  const options = useMemo(() => {
    if (resumeList && resumeList.length) {
      return resumeList.map((el) => ({ label: el.labels.name, value: el.labels.name }))
    }
    setValue('date', null)
    return []
  }, [resumeList, activeResume])

  useEffect(() => {
    if (candidate) {
      dispatch(getCandidateResume(candidate?.id))
    }
  }, [candidate])

  return (
    <div className={`${MainCSSClass}`}>
      {loading && <Preloader size="sm" />}

      {!loading
            && (
              <>
                <div className={`${MainCSSClass}_header`}>
                  <SelectFormControl name="date" control={control} className={`${MainCSSClass}_select`} options={options} />
                </div>
                <div style={{ height: '90%', display: 'flex', flexDirection: 'column' }}>{activeResume && <SummaryInfo readOnly />}</div>
              </>
            )}

    </div>
  )
}

export default memo(withErrorBoundary(SummaryCandidate))
