import { PayloadAction, current } from '@reduxjs/toolkit'
import {put, race, select, take, takeLatest} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  TRequestLeadsCSV,
 exportCSV,
 exportMovementCSV,
 getDictionaries, getLeads, setDictionaries,
 setHeaders,
 setLeads,
 setLoading,
} from './actions'
import { TRequestLeads, fetchGetLeads, fetchPostCreateCSV, fetchPostCreateMovemetCSV } from '../../common/api/leads'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {changeUserActivity, setLoadingProgress} from '../InnerRouter/actions'
import {fetchPostChangeUserActivity} from "../../common/api/user";


const moduleName = 'Лиды'

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'лиды')
}

export default function* getLeadsSaga({ payload }: PayloadAction<TRequestLeads>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
      let response: AxiosResponse<any>;
      ({response} = yield race({
          response: fetchGetLeads(payload),
          cancel: take(getLeads.type)
      }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить кандидатов',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setLeads(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TRequestLeadsCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
      let response: any;
      ({response} = yield race({
          response: fetchPostCreateCSV(payload),
          cancel: take(exportCSV.type)
      }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить CSV',
          error: response as AxiosError
        })
      )
    } else {

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportMovementCSVSaga({ payload }: PayloadAction<TRequestLeadsCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
      let response: any;
      ({response} = yield race({
          response: fetchPostCreateMovemetCSV(payload),
          cancel: take(exportMovementCSV.type)
      }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить CSV (движение лидов)',
          error: response as AxiosError
        })
      )
    } else {

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}


export function* leadsSaga() {
  yield takeLatest(getLeads, getLeadsSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
  yield takeLatest(exportMovementCSV, exportMovementCSVSaga)
}
