import React, {
  FC, memo, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import './CandidateTableRowMobil.scss'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TClassName } from '../../../common/types/props'
import { Heading } from '../../../common/components/Text'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'

type TProps = {
  name: string
  requestLabel: string
  img?: any
} & TClassName
const mainCssClass = 'candidate-status-table-row-mobil'
const CandidatesTableRowMobil: FC<TProps> = ({
  name, className, requestLabel, img
}) => (
  <div className={cn(className, mainCssClass)}>
    <img src={img} />
    <div className={`${mainCssClass}__title`}>
      <Typography.Title level={4} className={`${mainCssClass}__title-text`}>
        {name}
      </Typography.Title>
      <Typography.Text className={`${mainCssClass}__request`}>
        {requestLabel}
      </Typography.Text>
    </div>
    <div className={`${mainCssClass}__arrow`}>
      <ArrowLeftSM />

    </div>

  </div>
)
export default memo<TProps>(withErrorBoundary<TProps>(CandidatesTableRowMobil))
