import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import IntlCollator from 'intl'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { NavLink } from 'react-router-dom'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { TRequest } from '../../../common/api/request'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import StatusInterviewTableRow from './StatusInterviewTableRow'
import { navigateTo } from '../../../common/actions/navigate'
import { TInterview } from '../../../common/api/interviews'
import ResultInterviewTableRow from './ResultInterviewTableRow'
import ConfirmInterviewTableRow from './ConfirmInterviewTableRow'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './InterviewTable.scss'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('interviews-table')

type TProps= {
  interviews: TInterview[]
} & TClassName
type TRowData = {
  candidate_name: JSX.Element
  date: string
  period: string
  confirm: JSX.Element
  status: JSX.Element
  phone:string
  result: JSX.Element
  characteristic: string
  recruiter_name: string
  research_name: string
}

const useColumns = (): Column<any>[] => (
  useMemo(
    () => ([
      {
        accessor: 'candidate_name',
        Header: 'Кандидат',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'date',
        Header: 'Дата',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = dayjs(rowA.original[columnId], 'DD.MM.YYYY')
          const valueB = dayjs(rowB.original[columnId], 'DD.MM.YYYY')

          return desc ? valueA.isBefore(valueB) : valueA.isAfter(valueB) ? 0 : 1
        }
      },
      {
        accessor: 'period',
        Header: 'Время',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'confirm',
        Header: 'Подтверждено',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'status',
        Header: 'Статус интервью',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'vacancy',
        Header: 'Вакансия',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'city',
        Header: 'Город',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'phone',
        Header: 'Номер телефона',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'result',
        Header: 'Результат интервью',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'characteristic',
        Header: 'Характеристика',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'recruiter_name',
        Header: 'Рекрутер',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'research_name',
        Header: 'Ресечер',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      }
    ]),
    []
  )
)

const useRowsData = (interviews: TInterview[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
    },
    []
  )
  const rows: TRowData[] = useMemo(
    () => (
      interviews?.length ? interviews.map<TRowData>(({
        candidate_name, date, period, confirm, recruiter_name, researcher_name,city, id, status, result, characteristic, vacancy_list,request
      }) => ({
        candidate_name: <NavLink to={`/interview/${id}`} className={`${mainCssClass}__link`}>
          {' '}
          {candidate_name?.label || '-'}
        </NavLink>,
        date: dayjs(date).format(DATE_DOTS_FORMAT),
        period: period || '-',
        confirm: <ConfirmInterviewTableRow confirm={confirm} id={id} />,
        status: <StatusInterviewTableRow status={status} />,
        phone: candidate_name.phone || '',
        result: <ResultInterviewTableRow status={result} />,
        characteristic: `${characteristic.label || 'Отсутствует'}`,
        recruiter_name: `${recruiter_name?.surname} ${recruiter_name?.name}` || '-',
        research_name: `${researcher_name?.surname} ${researcher_name?.name}` || '-',
        vacancy: vacancy_list?.label || '-',
        city: candidate_name?.city || '-',
        id
      })) : []
    ),
    [interviews]
  )
  return { rows, handleRowClick }
}
const InterviewsTable: FC<TProps> = ({  interviews }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(interviews)

  return (
    <Table
      className={mainCssClass}
      columns={columns}
      data={rows}
      sortabled
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(InterviewsTable))
