import React, { forwardRef } from 'react'
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2'

function ReactCustomScrollbarForward(props: ScrollbarProps, ref?: any) {
  return (
    <Scrollbars {...props} ref={ref} />
  )
}

export const ReactCustomScrollbar = forwardRef(ReactCustomScrollbarForward)
