import React, { FC, memo, useState } from 'react'
import { Tag } from 'antd'
import './Vacation.scss'

type TProps = {
  vacancies_list: { label: string; value: string }[] | null
}
const Vacation: FC<TProps> = ({ vacancies_list }) => (
  <div>
    {vacancies_list && vacancies_list?.map((tag) => (
      <Tag key={tag?.value} className="customLabel" color="blue">
        {tag.label}
      </Tag>
    ))}
  </div>
)

export default memo(Vacation)
