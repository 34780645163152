import React from 'react'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'ellipseNewYaer'

function EllipseNewYaer({ className, size = '1', color }: TSvgProps) {
  return (

    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.674 2.59348C15.9123 4.6312 11.8895 5.53733 8.13738 8.80438C4.38525 12.0714 2.11514 15.606 1.08636 14.4245C-0.199625 12.9476 1.0417 8.23145 4.79382 4.96439C8.54594 1.69734 12.4365 -1.08829 14.674 2.59348Z"
        fill={color || 'white'}
        fillOpacity="0.4"
      />
    </svg>

  )
}

export default EllipseNewYaer
