import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'events-List-Icon-OUT'

function EventsListIconOUT({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#BFF1CA" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8947 10C11.296 10 10 11.2961 10 12.8948C10 22.3418 17.6585 30 27.1055 30C28.7043 30 30 28.7039 30 27.1053L29.9999 24.354C29.9999 23.1272 29.2528 22.0242 28.114 21.5687L25.4785 20.5144C24.4555 20.1052 23.2906 20.2896 22.444 20.9951L21.7629 21.5625C21.3655 21.8936 20.7813 21.8671 20.4156 21.5014L18.4984 19.5844C18.1329 19.2189 18.1064 18.6346 18.4377 18.237L19.005 17.5562C19.7105 16.7095 19.8949 15.5444 19.4857 14.5215L18.4314 11.8858C17.9758 10.7469 16.8727 10 15.646 10H12.8947ZM12.8947 12C12.4006 12 12 12.4006 12 12.8948C12 21.2372 18.763 28 27.1055 28C27.5995 28 28 27.5996 28 27.1054L27.9999 24.3541C27.9999 23.9453 27.751 23.5775 27.3712 23.4256L24.7357 22.3714C24.3947 22.2349 24.0065 22.2964 23.7244 22.5315L23.0433 23.0989C21.8513 24.0922 20.0986 24.0128 19.0015 22.9157L17.0843 20.9986C15.9869 19.9013 15.9081 18.1485 16.9012 16.9567L17.4686 16.2758C17.7036 15.9938 17.7652 15.6054 17.6287 15.2643L16.5745 12.6286C16.4226 12.249 16.0549 12 15.646 12H12.8947Z" fill="#16782C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.8047 10.205C26.0651 10.4784 26.0651 10.9216 25.8047 11.195L24.2761 12.8L29.3333 12.8C29.7015 12.8 30 13.1134 30 13.5C30 13.8866 29.7015 14.2 29.3333 14.2L24.2761 14.2L25.8047 15.805C26.0651 16.0784 26.0651 16.5216 25.8047 16.795C25.5444 17.0683 25.1223 17.0683 24.8619 16.795L22.1953 13.995C21.9349 13.7216 21.9349 13.2784 22.1953 13.005L24.8619 10.205C25.1223 9.93166 25.5444 9.93166 25.8047 10.205Z" fill="#16782C" />
    </svg>

  )
}
export default EventsListIconOUT
