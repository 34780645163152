import { createReducer } from '@reduxjs/toolkit'
import { setOpenModalAutoDial, setOpenModalNewAutoDial } from './actions'

type TFluidityState = {
  openModalAutoDial:boolean
  openModalNewAutoDial:boolean
}

const initialState: TFluidityState = {
  openModalAutoDial: false,
  openModalNewAutoDial: false
}

const fluidityReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setOpenModalAutoDial, (state, { payload }) => {
      state.openModalAutoDial = payload
    })
    .addCase(setOpenModalNewAutoDial, (state, { payload }) => {
      state.openModalNewAutoDial = payload
    })
})

export default fluidityReducer
