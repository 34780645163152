/* eslint-disable no-mixed-operators */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Dropdown, MenuProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { getCssClassName } from '../../../common/utils/generateClassName'
import ArrowLeftMD from '../../../common/svg/ArrowLeftMD'
import { InfoOutline } from '../../../common/svg/SvgFromIconWrapper/InfoOutline'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import { selectBaseUrl, selectUserInfo } from '../../InnerRouter/selectors'
import {
  addNewGroupOptions, delDictionaryOptions, delGroupOptions,
  getGroupDictionary, getGroupOptions, getUniqueValues, groupByCollectionName, updateLabels
} from '../../DepartmentWorkPlan/utils'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import $api from '../../../common/api/http'
import { getDashboard, setCandidatesFilterFromDashboard, setDashboardFilter } from '../actions'
import {
  selectDashboard, selectDashboardFilter, selectDisabledDashboard, selectLoadingDashboard
} from '../selectors'
import { filterUserListDashbord, getDashboardFilter } from '../utils'
import { TBaseUrl } from '../../InnerRouter/types'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import './DashboardCandidate.scss'

// Регистрация компонентов Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const mainCssClass = getCssClassName('dashboard-candidate')

// Опции для первого графика
const options1 = {
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false // Скрыть легенду
    },
    title: {
      display: false
    }
  },
  scales: {
    x: {
      ticks: {
        // @ts-ignore
        callback(value, index, values) {
          // @ts-ignore
          return this.getLabelForValue(value)
        },
        maxRotation: 90, // Максимальный угол поворота
        minRotation: 90 // Минимальный угол поворота (чтобы всегда было вертикально)
      },
      grid: {
        display: false // Убираем сетку по оси X
      }
    },
    y: {
      grid: {
        display: false // Убираем сетку по оси X
      }
    }
  }
}

// Опции для второго графика
const options2 = {
  indexAxis: 'y' as const, // Горизонтальный график
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  },
  scales: {
    x: {
      position: 'right',
      beginAtZero: true,
      ticks: {
        display: true,
        align: 'start',
        padding: 10
      },
      grid: {
        display: false // Убираем сетку по оси X
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false // Убираем сетку по оси X
      }
    }
  }
}

type TFormValues = {
  name_group: number[]
  list_user: number[]
  stor: any[]
}

function DashboardCandidate() {
  const dispatch = useDispatch()

  const [groupDictionary, setGroupDictionary] = useState<any>()
  const [groupOptions, setGroupOptions] = useState<any>()
  const [activePeriod, setActivePeriod] = useState('week')
  const [stageType, setStageType] = useState(11)
  const { pageId } = useParams()

  const userInfo = useSelector(selectUserInfo)
  const loadingDashboard = useSelector(selectLoadingDashboard)
  const dashboard = useSelector(selectDashboard)
  const dashboardFilter = useSelector(selectDashboardFilter)
  const disabled = useSelector(selectDisabledDashboard)
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const targetUrl: string = baseUrl || pageId || 'candidates'

  const prevStorValue = useRef<any[]>(null)

  const {
    control, watch, setValue, getValues
  } = useForm<TFormValues>()

  const name_group = watch('name_group')
  const list_user = watch('list_user')

  const handleChangeSelectStore = (e: any[]) => {
    if (e && e.length && !prevStorValue.current) {
      if (name_group) {
        setValue('name_group', getUniqueValues(name_group, JSON.parse(e[0])))
      } else {
        setValue('name_group', JSON.parse(e[0]))
      }
    } else if (e && e.length && prevStorValue.current && prevStorValue.current?.length < e.length) {
      setValue('name_group', addNewGroupOptions(name_group, e, prevStorValue.current))
    } else {
      setValue('name_group', delGroupOptions(prevStorValue.current, e, name_group))
    }
    // @ts-ignore
    prevStorValue.current = e
  }

  const handleClickStage = (stage_id: number, stage_label: string) => {
    if (userInfo) {
      dispatch(setCandidatesFilterFromDashboard({
        pageId: targetUrl, role: userInfo?.role?.role_id as string, stage_id, stage_label
      }))
    }
  }

  const fetchUsersList = useCallback(async (name: string) => {
    if (name_group && name_group.length) {
      const data = await $api.get(`/api/v1/user/user-list?search=${name}${name_group && name_group.length ? `&group_list=${name_group.join(',')}` : ''}`)

      const result = data.data.DATA.map((obj: any) => ({
        label: `${obj.name} ${obj.surname}`,
        value: obj.id,
        group: obj.group_list
      }))
      return result
    }
    return []
  }, [name_group])

  const handleClickPeriod = (key: string) => {
    setActivePeriod(key)
  }

  const chartData1 = useMemo(() => ({

    labels: dashboard?.successCandidate?.items?.map((item) => dayjs(item.date).format(DATE_DOTS_FORMAT)),
    datasets: [
      {
        label: '',
        data: dashboard?.successCandidate?.items?.map((item) => item.value),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]

  }), [dashboard])

  const handleClickMyCandidate = () => {
    if (userInfo) {
      dispatch(setCandidatesFilterFromDashboard({ pageId: targetUrl, role: userInfo?.role?.role_id as string }))
    }
  }

  const chartData2 = useMemo(() => ({

    labels: dashboard?.candidateOnVacancy?.items?.map((item) => item.label),
    datasets: [
      {
        label: '',
        data: dashboard?.candidateOnVacancy?.items?.map((item) => item.value),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]

  }), [dashboard])

  const handleChangeStageType = (key: number) => {
    setStageType(key)
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div onClick={() => handleChangeStageType(11)}>
          Кол-во положительных кандидатов
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div onClick={() => handleChangeStageType(13)}>
          {' '}
          Кол-во принятых кандидатов
        </div>
      )
    }
  ]

  useEffect(() => {
    if (name_group && groupDictionary) {
      setValue('stor', delDictionaryOptions(groupDictionary, name_group))
      // @ts-ignore
      prevStorValue.current = delDictionaryOptions(groupDictionary, name_group)
      setGroupDictionary(updateLabels(groupDictionary, name_group, delDictionaryOptions(groupDictionary, name_group)))
    }
    if ((!name_group || !name_group.length) && list_user && list_user.length) {
      setValue('list_user', [])
    }
    if (name_group && name_group.length && list_user && list_user.length) {
      setValue('list_user', filterUserListDashbord(name_group, list_user))
    }
  }, [name_group])

  useEffect(
    () => {
      if (userInfo) {
        getGroupDictionary(userInfo?.group_list)
          .then((responseData) => {
            setGroupDictionary(groupByCollectionName(responseData))
            setGroupOptions(getGroupOptions(responseData))
          })
          .catch((error) => {
            console.error('Error fetching model data:', error)
          })
      }
    },
    [userInfo?.group_list]
  )

  useEffect(() => {
    dispatch(setDashboardFilter(getDashboardFilter(name_group, activePeriod, list_user, stageType)))
  }, [
    name_group, activePeriod, list_user, stageType
  ])

  useEffect(() => {
    if (dashboardFilter && !dashboard) {
      dispatch(getDashboard({ filter: dashboardFilter, isNeedDisable: false }))
    }
    if (dashboardFilter && dashboard) {
      dispatch(getDashboard({ filter: dashboardFilter, isNeedDisable: true }))
    }
  }, [dashboardFilter])

  return (
    <div className={mainCssClass}>
      {loadingDashboard && <Preloader size="sm" />}
      {!loadingDashboard
            && (
              <>
                <div className={`${mainCssClass}__warning-block`}>
                  <div className={`${mainCssClass}__warning`}>
                    <InfoOutline />
                  </div>
                  <div className={`${mainCssClass}__text`}>
                    Чтобы увидеть список кандидатов, настройте фильтры или введите поисковой запрос
                  </div>
                </div>

                {(userInfo?.role?.role_id === 'admin' || userInfo?.role?.role_id === 'superadmin' || userInfo?.role?.role_id === 'hr_department' || userInfo?.role?.role_id === 'group_leader')
              && (
                <div className={`${mainCssClass}__search-container`}>
                  <div className={`${mainCssClass}__search-container-select`}>
                    <SelectFormControlMult
                      name="stor"
                      labelInValue
                      options={groupDictionary}
                      placeholder="Все мои отделы"
                      control={control}
                      onChange={handleChangeSelectStore}
                      disabled={disabled}
                    />
                  </div>
                  <div className={cn(
                    `${mainCssClass}__search-container-select`,
                    `${mainCssClass}__search-container-select-mult`
                  )}
                  >
                    <SelectFormControlMult
                      name="name_group"
                      control={control}
                      placeholder="Группы"
                      mode="multiple"
                      options={groupOptions}
                      disabled={disabled}
                    />
                  </div>
                  <div className={`${mainCssClass}__search-container-select`}>
                    <DebounceSelectFormControlMult
                      name="list_user"
                      control={control}
                      mode="multiple"
                      triggerFetch={String(name_group)}
                      placeholder="Поиск по наименованию..."
                      fetchOptions={fetchUsersList}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}

                <div className={`${mainCssClass}__table-block`}>
                  <div className={`${mainCssClass}__candidate-block`}>
                    <div className={`${mainCssClass}__my-candidate`}>
                      <div className={`${mainCssClass}__title-candidate`}>
                        Мои кандидаты:
                      </div>
                      {dashboard?.MyCandidateList?.items && dashboard?.MyCandidateList?.items?.length
                            && (
                              <div className={`${mainCssClass}__list-candidate`}>
                                <NewScrollContainer>
                                  {dashboard?.MyCandidateList?.items?.map((item, index) => (
                                    <div
                                      key={item.label}
                                      className={`${mainCssClass}__list-item ${index === 0 ? `${mainCssClass}__list-item--first` : ''}`}
                                    >
                                      <span>
                                        {item.label}
                                        :
                                        {' '}
                                      </span>
                                      <div className={`${mainCssClass}__list-item_div`}>
                                        <span>{item.value}</span>
                                        <ArrowLeftMD fill="#2DBE64" onClick={() => handleClickStage(item.id, item.label)} />
                                      </div>
                                    </div>
                                  ))}
                                </NewScrollContainer>
                              </div>
                            )}

                    </div>
                  </div>

                  <div className={`${mainCssClass}__chart-candidate`}>
                    <div className={`${mainCssClass}__chart-title-tabs`}>
                      <Dropdown menu={{ items }}>
                        <div
                          className={`${mainCssClass}__chart-title`}
                          onClick={(e) => e.preventDefault()}
                        >
                          {stageType === 11 ? 'Количество положительных кандидатов' : 'Количество принятых кандидатов'}
                          <CaretDownMD />
                        </div>
                      </Dropdown>
                      <div className={`${mainCssClass}__tabs`}>
                        <div
                          className={cn(
                            `${mainCssClass}__tabs_item`,
                            { [`${mainCssClass}__tabs_item-isActive`]: activePeriod === 'week' }
                          )}
                          onClick={() => handleClickPeriod('week')}
                        >
                          Неделя
                        </div>
                        <div
                          className={cn(
                            `${mainCssClass}__tabs_item`,
                            { [`${mainCssClass}__tabs_item-isActive`]: activePeriod === 'month' }
                          )}
                          onClick={() => handleClickPeriod('month')}
                        >
                          Месяц
                        </div>
                        <div
                          className={cn(
                            `${mainCssClass}__tabs_item`,
                            { [`${mainCssClass}__tabs_item-isActive`]: activePeriod === 'year' }
                          )}
                          onClick={() => handleClickPeriod('year')}
                        >
                          Год
                        </div>
                      </div>
                    </div>

                    <div className={`${mainCssClass}__chart-wrapper-candidate`}>
                      <Bar data={chartData1} options={options1} width="100%" />
                    </div>

                  </div>
                  <div className={`${mainCssClass}__actions_vacancies`}>
                    <div className={`${mainCssClass}__actions`}>
                      <div className={`${mainCssClass}__actions-title`}>
                        Дополнительные действия:
                      </div>
                      <div className={`${mainCssClass}__actions-list`}>
                        {/* <div className={`${mainCssClass}__actions-item`}>
  Применить последние фильтры
  <ArrowLeftMD fill="#2DBE64" />
</div> */}
                        <div className={`${mainCssClass}__actions-item`} onClick={handleClickMyCandidate}>
                          Показать моих кандидатов
                          <ArrowLeftMD fill="#2DBE64" />
                        </div>
                      </div>
                    </div>
                    <div className={`${mainCssClass}__top-vacancies`}>
                      <div className={`${mainCssClass}__title-vacancy`}>
                        Топ вакансий с моими кандидатами
                      </div>
                      <div className={`${mainCssClass}__vacancy-list`}>
                        {dashboard?.topVacancyList?.items?.map((vacancy, index) => (
                          <Typography.Text
                            key={index}
                            className={`${mainCssClass}__vacancy-item`}
                            ellipsis={{ rows: 2, tooltip: vacancy.label }}
                          >
                            {index + 1}
                            .
                            {' '}
                            {vacancy.label}
                          </Typography.Text>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${mainCssClass}__chart-vacancy`}>
                  <div className={`${mainCssClass}__chart-title`}>
                    Воронка кандидатов на вакансиях
                  </div>
                  <div className={`${mainCssClass}__chart-wrapper-vacancy`}>
                    <Bar data={chartData2} options={options2} width="100%" height={400} />
                  </div>

                </div>
              </>
            )}

    </div>

  )
}

export default DashboardCandidate
