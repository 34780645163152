import { TOption } from '../../common/types/dictionaries'
import $api from '../../common/api/http'
import { TRequestCreateTask } from './actions'
import { TTaskFilter } from './components/TasksFilter'
import Candidate from '../candidate/components/Candidate'

export function generateFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(name_like, surname_like)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return query
}

export async function fetchResponsibleList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/users?page=1&per-page=100')

    return data.DATA.map((obj: { id: number; name: string; surname: string}) => ({
      label: `${obj.name} ${obj.surname}`,
      value: obj.id
    }))
  }
  const filtredName = generateFilters(name.split(' '))

  const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&fields=id,name,surname&filter${filtredName}`)

  return data.DATA.map((obj: { id: number; name: string; surname: string;}) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export function createObject(obj:any):any {
  return {
    name: obj.name,
    description: obj.description,
    status: 0,
    is_archive: false,
    deadline_at: `${obj.deadline_at} ${obj.time}`,
    user_id: obj.user_id,
    target_id: obj.target_id
  }
}

export function createUpdateObject(obj:any):any {
  return {
    name: obj.name,
    description: obj.description,
    is_archive: false,
    deadline_at: `${obj.deadline_at} ${obj.time}`,
    user_id: obj.user_id,
    target_id: obj.target_id
  }
}

export function generateTimeArray(startTimeString = '') {
  const currentTime = startTimeString ? new Date(`2023-10-07T${startTimeString}:00`) : new Date('2023-10-07T08:00:00') // Установите начальное время
  const endTime = new Date('2023-10-07T21:00:00') // Установите конечное время
  const timeArray = []

  // Округляем текущее время до ближайшего интервала времени (20 минут)
  const roundedMinutes = Math.ceil(currentTime.getMinutes() / 20) * 20
  currentTime.setMinutes(roundedMinutes)

  while (currentTime <= endTime) {
    const label = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    const value = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    timeArray.push({ label, value })

    currentTime.setMinutes(currentTime.getMinutes() + 20) // Увеличиваем время на 20 минут
  }

  return timeArray
}

export const defObject: any = {
  authorModal: false,
  addTypeModal: false,
  statusModal: false,
  dateModal: false,
  targetModal: false,
  typeRegistrationModal: false
}

export const typeOptions = [{ value: '0', label: 'Автоматические' }, { value: '1', label: 'Ручные' }]

export const statusOptions = [{ value: '0', label: 'В работе' }, { value: '1', label: 'Выполнены' }]

export function objectToQueryString(obj: TTaskFilter): string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&${key}=${strArrStr}`
      } else if (typeof obj[key][0] === 'number') {
        if (key !== 'type_registration') {
          const strArrStr = obj[key].map((el) => el).join(',')
          str += `&target_id=${strArrStr}`
        } else {
          const strArrStr = obj[key].map((el) => el).join(',')
          str += `&union[type_registration]=${strArrStr}`
        }
      }
    } else if (typeof obj[key] === 'string') {
      str += `&${key}=${obj[key]}`
    }
  }

  return str
}

export function formatDateTime(dateTimeString:string) {
  const dateTime = dateTimeString.split(' ')
  const deadline_at = dateTime[0] // Получаем дату в формате "YYYY-MM-DD"
  const time = dateTime[1].slice(0, 5) // Получаем время в формате "HH:MM"

  return { deadline_at, time }
}

export function filteredOptions(array: TOption[], searchValue: string): TOption[] {
  if (!array) return []
  if (!searchValue) return array

  const lowerCaseSearchValue = searchValue.toLowerCase()

  return array.filter((obj) => obj.label.toLowerCase().includes(lowerCaseSearchValue))
}
