import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'
import { selectRequest } from '../Request/selectors'

const selectState = ({ managementRequests }: TRootState) => managementRequests

export const selectRequests = createSelector(selectState, ({ requests }) => (requests ? requests?.request_list : []))

export const selectResultRequestCount = createSelector(selectState, ({ requests }) => {
  const { count } = requests?.counters?.find((item) => item.id === 'result') || {}
  return count || 0
})

export const selectActualRequestCount = createSelector(selectState, ({ requests }) => {
  const { count } = requests?.counters?.find((item) => item.id === 92) || {}
  return count || 0
})

export const selectConsiderationRequestCount = createSelector(selectState, ({ requests }) => {
  const { count } = requests?.counters?.find((item) => item.id === 91) || {}
  return count || 0
})
export const selectArchiveRequestCount = createSelector(selectState, ({ requests }) => {
  const { count } = requests?.counters?.find((item) => item.id === 650) || {}
  return count || 0
})

export const selectModalEditOpen = createSelector(selectState, ({ editModalOpen }) => editModalOpen)

export const selectModalArchiveOpen = createSelector(selectState, ({ archiveModalOpen }) => archiveModalOpen)

export const selectSelectedRequestId = createSelector(selectState, ({ requestId }) => requestId)

export const selectSelectedRequest = createSelector(selectState, ({ request }) => request)
export const selectCurators = createSelector(selectState, ({ curators }) => curators)
export const selectOpenSureModal = createSelector(selectState, ({ modalSure }) => modalSure)
export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) =>
  createSelector(
    selectDictionariesMap,
    // @ts-ignore
    (dictionaries) => dictionaries[name] || []
  )

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectRequestArchiveOpen = createSelector(selectState, ({ archiveOpen }) => archiveOpen)


export const selectDefDataRequest = createSelector(selectState, ({ defDataRequest }) => defDataRequest)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

