import axios, { AxiosResponse } from 'axios'
import { TRequestNotify } from '../../features/notificaitionCenter'
import $api from './http'

export type TNotify = {
  message_type:string
  message:string
  class:string
  type:string
  guid: string
  user_id:number
  created_at:number
}

export type TNotificationsResponse =
{
  ERR: boolean,
  DATA: {
      page: number,
      per_page: number,
      notify_list: TNotify []
  },
  Headers: {
      vary: string[],
      'content-type': string[
      ]
  }
}

export async function fetchGetNotifications(payload:TRequestNotify): Promise<AxiosResponse<TNotificationsResponse>> {
  const response = await $api.post(`/api/v1/notify/get-list?user_id=0&page=${payload.page || 0}&per_page=${payload.per_page || 20}${payload.filter || ''}${payload.status || ''}`)
  return response
}

export async function fetchDeleteNotifications(): Promise<AxiosResponse<any>> {
  const response = await $api.delete('/api/v1/notify/delete-all-notify?user_id=0')
  return response
}

export async function fetchDeleteNotifiy(payload:string): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/notify/delete-notify?guid=${payload}`)
  return response
}

export async function fetchReadNotifiy(payload:string): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/notify/read-notify?guid=${payload}`)
  return response
}

export async function fetchReadNotifications(): Promise<AxiosResponse<any>> {
  const response = await $api.patch('/api/v1/notify/read-all-notify?user_id=0')
  return response
}
