import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Input, Radio, Tooltip, Typography
} from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import CloseMD from '../../../common/svg/CloseMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import { getDictionaries, openReportFilters, setFilter } from '../actions'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import {
  addTypeOptions, defInputObject, defObject, departmentOptions, objectToQueryString
} from '../utils'
import {
  fetchCitiesList,
  fetchRecruiterList, fetchRequestList, fetchResearcherList, fetchStoreList, fetchVacancyList, filteredOptions
} from '../../filters/utils'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import Search from '../../../common/svg/Search'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { selectDictionaryOptionsByName, selectResetFilter } from '../selectors'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'

import './ReportFilter.scss'

const mainCssClass = getCssClassName('report-filter')

export type TReoirtFilter = {
  vacancy_id: number[]
  request_id: number[]
  source_id: number[]
  researcher_id: number[]
  recruiter_id:number[]
  add_type: number[]
  created_at: string[] | null
  department_id: number[]
  added_at: string[] | null
  group: number[]
  city_vacancy: string[]
  city_candidate: string[]
  citizenship: number[]
  customer: number[]
  schedule: number[]
  id: number[]
  store_id: number[]
  stage_id: number[]
  researcher: number[]
  responsible: number[]
  responsible_id: number[]
  reject_id: number[]
  updated_at: string[] | null
}

function ReportFilter({ type } : {type :string}) {
  const dispatch = useDispatch()

  const workSitesStatusOptions = useSelector(selectDictionaryOptionsByName('WorkSites'))
  const typeOptions = useSelector(selectDictionaryOptionsByName('appendType'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const scheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const reasonOptions = useSelector(selectDictionaryOptionsByName('rejectionReason'))


  const resetFilter = useSelector(selectResetFilter)

  const [allModal, setAllModal] = useState<any>(defObject)
  const [allInput, setAllInput] = useState<any>(defInputObject)

  const [dateBeginValue, setDateBeginValue] = useState<number | undefined>(undefined)

  const handleDateBegin = (e) => {
    setDateBeginValue(e.target.value)

    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      setValue('added_at', [weekAgo, currentDate])
    } else if (e.target.value === 2) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      setValue('added_at', [weekAgo, currentDate])
    } else if (e.target.value === 3) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      setValue('added_at', [weekAgo, currentDate])
    } else {
      setValue('added_at', null)
    }
  }

  const handleAllReset = useCallback(() => {
    reset({})
  }, [])

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TReoirtFilter>()

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleInputChange = (key: string, value: string) => {
    setAllInput((prevAllInput:any) => ({
      ...prevAllInput,
      [key]: value
    }))
  }

  const handleApply = () => {
    dispatch(setFilter(objectToQueryString(getValues())))
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'appendType',
        'currency',
        'schedule',
        'WorkSites',
        'employment',
        'typeRegistrationWork',
        'group',
        'stageState',
        'citizenship',
        'stageStatus',
        'rejectionReason'
      ])
    )
  }, [])

  useEffect(() => {
    if (resetFilter) { handleAllReset() }
  }, [resetFilter])

  const value = getValues()


  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={() => dispatch(openReportFilters(false))}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Вакансия
                  {watch('vacancy_id') && watch('vacancy_id').length > 1 ? (
                      <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                        {watch('vacancy_id').length}
                        )
                          </span>
                  ) : (
                      ''
                  )}
                </Typography.Title>
                {watch('vacancy_id') && watch('vacancy_id').length > 0 && <Redo onClick={() => resetField('vacancy_id', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('vacancyModal')}>
                {allModal.vacancyModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.vacancyModal && (
              <div className={`${mainCssClass}_items_content-without-scroll`}>
                <DebounceSelectFormControlMult
                  name="vacancy_id"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Вакансия"
                  fetchOptions={fetchVacancyList}
                />
              </div>
            )}
          </div>

          {(type === 'closingOfApplications' || type === 'rejection')

            && (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Заявка
                      {watch('id') && watch('id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('id') && watch('id').length > 0 && <Redo onClick={() => resetField('id', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('idModal')}>
                    {allModal.idModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.idModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Заявка"
                      fetchOptions={fetchRequestList}
                    />
                  </div>
                )}
              </div>
            )}

          { type === 'rejection' && (
            <>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Этап
                      {watch('stage_id') && watch('stage_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('stage_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('stage_id') && watch('stage_id').length > 0 && (
                      <Redo onClick={() => resetField('stage_id', undefined)} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('stageModal')}>
                    {allModal.stageModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.stageModal && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={allInput.stageInput}
                        onChange={(e) => handleInputChange('stageInput', e.target.value)}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(stageOptions, allInput.stageInput)}
                        control={control}
                        name="stage_id"
                      />
                    </NewScrollContainer>
                  </div>

                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Причины отказа
                      {watch('reject_id') && watch('reject_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('reject_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('reject_id') && watch('reject_id').length > 0 && <Redo onClick={() => setValue('reject_id', [])} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('rejReas')}>
                    {allModal.rejReas ? <CaretUpMD />
                      : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.rejReas && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={allInput.rejInput}
                        onChange={(e) => handleInputChange('rejInput', e.target.value)}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(reasonOptions, allModal.rejInput)}
                        control={control}
                        name="reject_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Рекрутер
                      {watch('responsible') && watch('responsible').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('responsible').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('responsible') && watch('responsible').length > 0
                && <Redo onClick={() => resetField('responsible', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('recruiterModal')}>
                    {allModal.recruiterModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.recruiterModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="responsible"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Рекрутер"
                      fetchOptions={fetchRecruiterList}
                    />
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Ресечер
                      {watch('researcher') && watch('researcher').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('researcher').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('researcher') && watch('researcher').length > 0 && <Redo onClick={() => resetField('researcher', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('researcherModal')}>
                    {allModal.researcherModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.researcherModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="researcher"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Ресечер"
                      fetchOptions={fetchResearcherList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>Период совершенного действия</Typography.Title>
                    {watch('updated_at') && watch('updated_at')?.length > 0 && (
                      <Redo
                        onClick={() => {
                          resetField('updated_at', undefined)
                          setDateBeginValue(undefined)
                        }}
                        tooltip
                      />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('updatedModal')}>
                    {allModal.updatedModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.updatedModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <RangePickerFormControl
                      control={control}
                      name="updated_at"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          { type === 'sourceOfCandidates' && (
            <>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Этап
                      {watch('stage_id') && watch('stage_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('stage_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('stage_id') && watch('stage_id').length > 0 && (
                      <Redo onClick={() => resetField('stage_id', undefined)} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('stageModal')}>
                    {allModal.stageModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.stageModal && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={allInput.stageInput}
                        onChange={(e) => handleInputChange('stageInput', e.target.value)}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(stageOptions, allInput.stageInput)}
                        control={control}
                        name="stage_id"
                      />
                    </NewScrollContainer>
                  </div>

                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Рекрутер
                      {watch('recruiter_id') && watch('recruiter_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('recruiter_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('recruiter_id') && watch('recruiter_id').length > 0
                      && <Redo onClick={() => resetField('recruiter_id', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('recruiterModal')}>
                    {allModal.recruiterModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.recruiterModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="recruiter_id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Рекрутер"
                      fetchOptions={fetchRecruiterList}
                    />
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Ресечер
                      {watch('responsible_id') && watch('responsible_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('responsible_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('responsible_id') && watch('responsible_id').length > 0 && <Redo onClick={() => resetField('responsible_id', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('researcherModal')}>
                    {allModal.researcherModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.researcherModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="responsible_id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Ресечер"
                      fetchOptions={fetchResearcherList}
                    />
                  </div>
                )}
              </div>

              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>Период совершенного действия</Typography.Title>
                    {watch('created_at') && watch('created_at')?.length > 0 && (
                      <Redo
                        onClick={() => {
                          resetField('created_at', undefined)
                          setDateBeginValue(undefined)
                        }}
                        tooltip
                      />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('updatedModal')}>
                    {allModal.updatedModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.updatedModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <RangePickerFormControl
                      control={control}
                      name="created_at"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {type === 'closingOfApplications'

              && (

                <>
                  <div className={`${mainCssClass}_items`}>
                    <div className={`${mainCssClass}_items_title`}>
                      <div className={`${mainCssClass}_items_title_redo`}>
                        <Typography.Title level={4}>
                          Объект
                          {watch('store_id') && watch('store_id').length > 1 ? (
                              <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                                {watch('store_id').length}
                                )
                          </span>
                          ) : (
                              ''
                          )}
                        </Typography.Title>
                        {watch('store_id') && watch('store_id').length > 0 && <Redo onClick={() => resetField('store_id', undefined)} tooltip />}
                      </div>
                      <ControlButton onClick={() => handldeOpenModal('storeModal')}>
                        {allModal.storeModal ? <CaretUpMD /> : <CaretDownMD />}
                      </ControlButton>
                    </div>
                    {allModal.storeModal && (
                      <div className={`${mainCssClass}_items_content-without-scroll`}>
                        <DebounceSelectFormControlMult
                          name="store_id"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по наименованию..."
                          label="Объект"
                          fetchOptions={fetchStoreList}
                        />
                      </div>
                    )}
                  </div>

                  <div className={`${mainCssClass}_items`}>
                    <div className={`${mainCssClass}_items_title`}>
                      <div className={`${mainCssClass}_title_redo`}>
                        <Typography.Title level={4}>Период создания заявки</Typography.Title>
                        {watch('created_at') && watch('created_at')?.length > 0 && (
                          <Redo
                            onClick={() => {
                              resetField('created_at', undefined)
                              setDateBeginValue(undefined)
                            }}
                            tooltip
                          />
                        )}
                      </div>
                      <ControlButton onClick={() => handldeOpenModal('createdModal')}>
                        {allModal.createdModal ? <CaretUpMD /> : <CaretDownMD />}
                      </ControlButton>
                    </div>
                    {allModal.createdModal && (
                      <div className={`${mainCssClass}_items_content-without-scroll`}>
                        <RangePickerFormControl
                          control={control}
                          name="created_at"
                          format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                          label="Выберите даты"
                          className={`${mainCssClass}_items_content_range`}
                        />
                      </div>
                    )}
                  </div>
                  <div className={`${mainCssClass}_items`}>
                    <div className={`${mainCssClass}_items_title`}>
                      <div className={`${mainCssClass}_items_title_redo`}>
                        <Typography.Title level={4}>
                          Ответственный
                          {watch('responsible') && watch('responsible').length > 1 ? (
                              <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                                {watch('responsible').length}
                                )
                          </span>
                          ) : (
                              ''
                          )}
                        </Typography.Title>
                        {watch('responsible') && watch('responsible').length > 0 && <Redo onClick={() => resetField('responsible', undefined)} tooltip />}
                      </div>
                      <ControlButton onClick={() => handldeOpenModal('responsibleModal')}>
                        {allModal.vacancyModal ? <CaretUpMD /> : <CaretDownMD />}
                      </ControlButton>
                    </div>
                    {allModal.responsibleModal && (
                      <div className={`${mainCssClass}_items_content-without-scroll`}>
                        <DebounceSelectFormControlMult
                          name="responsible"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по имени..."
                          label="Ответственный"
                          fetchOptions={fetchRecruiterList}
                        />
                      </div>
                    )}
                  </div>
                  <div className={`${mainCssClass}_items`}>
                    <div className={`${mainCssClass}_items_title`}>
                      <div className={`${mainCssClass}_items_title_redo`}>
                        <Typography.Title level={4}>
                          Заказчик
                          {watch('customer') && watch('customer').length > 1 ? (
                              <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                                {watch('customer').length}
                                )
                          </span>
                          ) : (
                              ''
                          )}
                        </Typography.Title>
                        {watch('customer') && watch('customer').length > 0 && <Redo onClick={() => resetField('customer', undefined)} tooltip />}
                      </div>
                      <ControlButton onClick={() => handldeOpenModal('customerModal')}>
                        {allModal.customerModal ? <CaretUpMD /> : <CaretDownMD />}
                      </ControlButton>
                    </div>
                    {allModal.customerModal && (
                      <div className={`${mainCssClass}_items_content-without-scroll`}>
                        <DebounceSelectFormControlMult
                          name="customer"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по имени..."
                          label="Заказчик"
                          fetchOptions={fetchRecruiterList}
                        />
                      </div>
                    )}
                  </div>

                  <div className={`${mainCssClass}_items`}>
                    <div className={`${mainCssClass}_items_title`}>
                      <div className={`${mainCssClass}_title_redo`}>
                        <Typography.Title level={4}>
                          График
                          {watch('schedule') && watch('schedule').length > 1 ? (
                              <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                                {watch('schedule').length}
                                )
                          </span>
                          ) : (
                              ''
                          )}
                        </Typography.Title>
                        {watch('schedule') && watch('schedule').length > 0 && (
                          <Redo onClick={() => resetField('schedule', undefined)} tooltip />
                        )}
                      </div>
                      <ControlButton onClick={() => handldeOpenModal('scheduleModal')}>
                        {allModal.scheduleModal ? <CaretUpMD /> : <CaretDownMD />}
                      </ControlButton>
                    </div>
                    {allModal.scheduleModal && (
                      <div className={`${mainCssClass}_items_content`}>
                        <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                          <Input
                            prefix={<Search />}
                            className={`${mainCssClass}_items_content_search`}
                            value={allInput.scheduleInput}
                            onChange={(e) => handleInputChange('scheduleInput', e.target.value)}
                          />
                          <CheckBoxFormControl
                            options={filteredOptions(scheduleOptions, allInput.scheduleInput)}
                            control={control}
                            name="schedule"
                          />
                        </NewScrollContainer>
                      </div>
                    )}
                  </div>

                </>
              )}

          {type !== 'closingOfApplications' && type !== 'rejection' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_items_title_redo`}>
                  <Typography.Title level={4}>
                    Заявка
                    {watch('request_id') && watch('request_id').length > 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                          {watch('request_id').length}
                          )
                          </span>
                    ) : (
                        ''
                    )}
                  </Typography.Title>
                  {watch('request_id') && watch('request_id').length > 0 && <Redo onClick={() => resetField('request_id', undefined)} tooltip />}
                </div>
                <ControlButton onClick={() => handldeOpenModal('requestModal')}>
                  {allModal.requestModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.requestModal && (
                <div className={`${mainCssClass}_items_content-without-scroll`}>
                  <DebounceSelectFormControlMult
                    name="request_id"
                    control={control}
                    mode="multiple"
                    placeholder="Поиск по наименованию..."
                    label="Заявка"
                    fetchOptions={fetchRequestList}
                  />
                </div>
              )}
            </div>
          )}

          {type !== 'rejection' && type !== 'closingOfApplications'

            && (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_title_redo`}>
                    <Typography.Title level={4}>
                      Источник
                      {watch('source_id') && watch('source_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('source_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('source_id') && watch('source_id').length > 0 && (
                      <Redo onClick={() => resetField('source_id', undefined)} tooltip />
                    )}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('leadgen_source_id')}>
                    {allModal.leadgen_source_id ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.leadgen_source_id && (
                  <div className={`${mainCssClass}_items_content`}>
                    <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                      <Input
                        prefix={<Search />}
                        className={`${mainCssClass}_items_content_search`}
                        value={allInput.sourceInput}
                        onChange={(e) => handleInputChange('sourceInput', e.target.value)}
                      />
                      <CheckBoxFormControl
                        options={filteredOptions(workSitesStatusOptions, allInput.sourceInput)}
                        control={control}
                        name="source_id"
                      />
                    </NewScrollContainer>
                  </div>
                )}
              </div>
            ) }

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Группы
                  {watch('group') && watch('group').length > 1 ? (
                      <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                        {watch('group').length}
                        )
                          </span>
                  ) : (
                      ''
                  )}
                </Typography.Title>
                {watch('group') && watch('group').length > 0 && (
                  <Redo onClick={() => resetField('group', undefined)} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('groupModal')}>
                {allModal.groupModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.groupModal && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={allInput.groupInput}
                    onChange={(e) => handleInputChange('groupInput', e.target.value)}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(groupOptions, allInput.groupInput)}
                    control={control}
                    name="group"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          {(type === 'vertex') && (
            <>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Рекрутер
                      {watch('recruiter_id') && watch('recruiter_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('recruiter_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('recruiter_id') && watch('recruiter_id').length > 0
                && <Redo onClick={() => resetField('recruiter_id', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('recruiterModal')}>
                    {allModal.recruiterModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.recruiterModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="recruiter_id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Рекрутер"
                      fetchOptions={fetchRecruiterList}
                    />
                  </div>
                )}
              </div>
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_title`}>
                  <div className={`${mainCssClass}_items_title_redo`}>
                    <Typography.Title level={4}>
                      Ресечер
                      {watch('researcher_id') && watch('researcher_id').length > 1 ? (
                          <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                            {watch('researcher_id').length}
                            )
                          </span>
                      ) : (
                          ''
                      )}
                    </Typography.Title>
                    {watch('researcher_id') && watch('researcher_id').length > 0 && <Redo onClick={() => resetField('researcher_id', undefined)} tooltip />}
                  </div>
                  <ControlButton onClick={() => handldeOpenModal('researcherModal')}>
                    {allModal.researcherModal ? <CaretUpMD /> : <CaretDownMD />}
                  </ControlButton>
                </div>
                {allModal.researcherModal && (
                  <div className={`${mainCssClass}_items_content-without-scroll`}>
                    <DebounceSelectFormControlMult
                      name="researcher_id"
                      control={control}
                      mode="multiple"
                      placeholder="Поиск по наименованию..."
                      label="Ресечер"
                      fetchOptions={fetchResearcherList}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          { type === 'productivity' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_title_redo`}>
                  <Typography.Title level={4}>
                    Гражданство кандидата
                    {watch('citizenship') && watch('citizenship').length > 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                          {watch('citizenship').length}
                          )
                          </span>
                    ) : (
                        ''
                    )}
                  </Typography.Title>
                  {watch('citizenship') && watch('citizenship').length > 0 && (
                    <Redo onClick={() => resetField('citizenship', undefined)} tooltip />
                  )}
                </div>
                <ControlButton onClick={() => handldeOpenModal('citizenshipModal')}>
                  {allModal.citizenshipModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.citizenshipModal && (
                <div className={`${mainCssClass}_items_content`}>
                  <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                    <Input
                      prefix={<Search />}
                      className={`${mainCssClass}_items_content_search`}
                      value={allInput.citizenshipInput}
                      onChange={(e) => handleInputChange('citizenshipInput', e.target.value)}
                    />
                    <CheckBoxFormControl
                      options={filteredOptions(citizenshipOptions, allInput.citizenshipInput)}
                      control={control}
                      name="citizenship"
                    />
                  </NewScrollContainer>
                </div>
              )}
            </div>
          )}

          {type === 'productivity' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_items_title_redo`}>
                  <Typography.Title level={4}>
                    Город вакансии
                    {watch('city_vacancy') && watch('city_vacancy').length > 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                          {watch('city_vacancy').length}
                          )
                          </span>
                    ) : (
                        ''
                    )}
                  </Typography.Title>
                  {watch('city_vacancy') && watch('city_vacancy').length > 0
                && <Redo onClick={() => resetField('city_vacancy', undefined)} tooltip />}
                </div>
                <ControlButton onClick={() => handldeOpenModal('cityVacancyModal')}>
                  {allModal.cityVacancyModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.cityVacancyModal && (
                <div className={`${mainCssClass}_items_content-without-scroll`}>
                  <DebounceSelectFormControlMult
                    name="city_vacancy"
                    control={control}
                    mode="multiple"
                    label="Город"
                    placeholder="Поиск по наименованию..."
                    fetchOptions={fetchCitiesList}
                    notFoundContent="Начните вводить город"
                  />
                </div>
              )}
            </div>
          )}

          {type === 'productivity' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_items_title_redo`}>
                  <Typography.Title level={4}>
                    Город кандидата
                    {watch('city_candidate') && watch('city_candidate').length > 1 ? (
                        <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                          {watch('city_candidate').length}
                          )
                          </span>
                    ) : (
                        ''
                    )}
                  </Typography.Title>
                  {watch('city_candidate') && watch('city_candidate').length > 0
                && <Redo onClick={() => resetField('city_candidate', undefined)} tooltip />}
                </div>
                <ControlButton onClick={() => handldeOpenModal('cityСandidateModal')}>
                  {allModal.cityVacancyModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.cityСandidateModal && (
                <div className={`${mainCssClass}_items_content-without-scroll`}>
                  <DebounceSelectFormControlMult
                    name="city_candidate"
                    control={control}
                    mode="multiple"
                    label="Город"
                    placeholder="Поиск по наименованию..."
                    fetchOptions={fetchCitiesList}
                    notFoundContent="Начните вводить город"
                  />
                </div>
              )}
            </div>
          )}

          {/* {type === 'vertex' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_title_redo`}>
                  <Typography.Title level={4}>Тип добавления</Typography.Title>
                  {watch('add_type') && watch('add_type').length > 0 && <Redo onClick={() => resetField('add_type', undefined)} tooltip />}
                </div>
                <ControlButton onClick={() => handldeOpenModal('addTypeModal')}>
                  {allModal.addTypeModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.addTypeModal && (
                <div className={`${mainCssClass}_items_content`}>
                  <CheckBoxFormControl options={typeOptions} control={control} name="add_type" />
                </div>
              )}
            </div>
          )} */}

          {type !== 'closingOfApplications' && type !== 'rejection' && type !== 'sourceOfCandidates'
       && (
         <div className={`${mainCssClass}_items`}>
           <div className={`${mainCssClass}_items_title`}>
             <div className={`${mainCssClass}_title_redo`}>
               <Typography.Title level={4}>Период совершенного действия</Typography.Title>
               {watch('created_at') && watch('created_at')?.length > 0 && (
                 <Redo
                   onClick={() => {
                     resetField('created_at', undefined)
                     setDateBeginValue(undefined)
                   }}
                   tooltip
                 />
               )}
             </div>
             <ControlButton onClick={() => handldeOpenModal('createdModal')}>
               {allModal.createdModal ? <CaretUpMD /> : <CaretDownMD />}
             </ControlButton>
           </div>
           {allModal.createdModal && (
             <div className={`${mainCssClass}_items_content-without-scroll`}>
               <RangePickerFormControl
                 control={control}
                 name="created_at"
                 format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                 label="Выберите даты"
                 className={`${mainCssClass}_items_content_range`}
               />
             </div>
           )}
         </div>
       )}

          {/* {type === 'vertex' && (
            <div className={`${mainCssClass}_items`}>
              <div className={`${mainCssClass}_items_title`}>
                <div className={`${mainCssClass}_title_redo`}>
                  <Typography.Title level={4}>Период добавления кандидата на вакансию</Typography.Title>
                  {watch('added_at') && watch('added_at')?.length > 0 && (
                    <Redo
                      onClick={() => {
                        resetField('added_at', undefined)
                        setDateBeginValue(undefined)
                      }}
                      tooltip
                    />
                  )}
                </div>
                <ControlButton onClick={() => handldeOpenModal('dateBeginModal')}>
                  {allModal.dateBeginModal ? <CaretUpMD /> : <CaretDownMD />}
                </ControlButton>
              </div>
              {allModal.dateBeginModal && (
                <div className={`${mainCssClass}_items_content`}>
                  <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                    <RangePickerFormControl
                      control={control}
                      name="added_at"
                      format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                      label="Выберите даты"
                      className={`${mainCssClass}_items_content_range`}
                    />
                    <Radio.Group value={dateBeginValue} onChange={handleDateBegin}>
                      <Radio value={1}>За последний день</Radio>
                      <Radio value={2}>За последний неделю</Radio>
                      <Radio value={3}>За последний месяц</Radio>
                    </Radio.Group>
                  </NewScrollContainer>
                </div>
              )}
            </div>
          )} */}

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>

        </div>
      </NewScrollContainer>
    </div>
  )
}

export default ReportFilter
