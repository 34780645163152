/* eslint-disable react/function-component-definition */
import cn from 'classnames'
import React, { FC, memo, useMemo } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  selectBaseUrl, selectPreviousPage, selectSubUrl, selectUserInfo
} from '../selectors'
import { TMainProps } from '../../../common/types/props'
import ArrowRed from '../../../common/svg/ArrowRed'
import { TUrlParams } from './InnerRouter'
import { navigateTo } from '../../../common/actions/navigate'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { TBaseUrl } from '../types'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { setPreviousPage } from '../actions'
import { openActiveInterviewModal } from '../../../features/interviewModals/actions'
import './ReturnButton.scss'

const mainCssClass = getCssClassName('return-button')
const ReturnButton: FC<TMainProps> = ({ className, disabled }) => {
  const dispatch = useDispatch()
  const { pageId, subPageId } = useParams<TUrlParams>()
  const userInfo = useSelector(selectUserInfo)

  const userName = userInfo?.name
    ? ` ${userInfo?.surname} ${userInfo?.name} ${userInfo?.patronymic}`
    : 'Отсутствует информация по имени'
  // !!! Не забывай добавлять эти переменные в зависимости useMemo !!!
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const subUrl: string | null = useSelector(selectSubUrl)
  const targetUrl: TBaseUrl = baseUrl || pageId || 'candidates'
  const targetSubUrl: string | null = subUrl || subPageId || null
  const previousPage = useSelector(selectPreviousPage)
  const activeInterview = useMemo(() => {
    if (userInfo && 'has_active_interview' in userInfo && 'id' in userInfo.has_active_interview && !previousPage) {
      return userInfo.has_active_interview.id
    }
    return null
  }, [userInfo, previousPage])

  const {
    caption,
    handleClick
  }: {
    caption: string
    handleClick: () => void
  } = useMemo(() => {
    const result = {
      caption: 'Вернуться',
      handleClick: () => {}
    }
    if (activeInterview) {
      result.caption = 'Интервью'
      result.handleClick = () => {
        dispatch(openActiveInterviewModal(true))
      }
    } else if (previousPage && !activeInterview) {
      result.caption = previousPage.caption
      result.handleClick = () => {
        dispatch(navigateTo(`${previousPage.navigate}`))
        dispatch(setPreviousPage(null))
      }
    } else if (!previousPage && !activeInterview) {
      if (targetUrl === 'candidate') {
        result.caption = 'Кандидаты'
        result.handleClick = () => {
          dispatch(navigateTo('/candidates'))
        }
      }
      if (targetUrl === 'application') {
        result.caption = 'Заявки'
        result.handleClick = () => {
          dispatch(navigateTo('/applications'))
        }
      }
      if (targetUrl === 'interview') {
        result.caption = 'Интервью'
        result.handleClick = () => {
          dispatch(navigateTo('/interviews'))
        }
      }
      if (targetUrl === 'vacancy') {
        result.caption = 'Вакансии'
        result.handleClick = () => {
          dispatch(navigateTo('/vacancies'))
        }
      }
    }
    return result
  }, [targetUrl, previousPage, userInfo, activeInterview])

  return (
    <Button
      className={cn(className, mainCssClass)}
      icon={<ArrowRed />}
      onClick={handleClick}
      size="middle"
      type="ghost"
      disabled={disabled}
    >
      {caption}
      <span className={`${mainCssClass}__caption-user`}>
        /
        {' '}
        {userName}
      </span>
    </Button>
  )
}
export default memo<TMainProps>(withErrorBoundary<TMainProps>(ReturnButton))
