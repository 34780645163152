import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { changeUserActivity, getUserActivity } from '../actions'
import { selectUserActivity } from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import './TimePanel.scss'
import { TUserActivityStatus } from '../types'
import { formatTime, timeStringToSeconds } from '../utils'

const mainCssClass = getCssClassName('time-panel')

function TimePanel() {
  const dispatch = useDispatch()
  const userActivity = useSelector(selectUserActivity)
  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false) // Отслеживание статуса секундомера

  const handleChangeStatus = (status: TUserActivityStatus) => {
    dispatch(changeUserActivity(status))
  }

  // Обработчик для запуска/остановки секундомера в зависимости от команды
  useEffect(() => {
    if (userActivity?.command === 'start' || userActivity?.command === 'continue') {
      setIsRunning(true)
    } else if (userActivity?.command === 'pause' || userActivity?.command === 'stop') {
      setIsRunning(false)
    }
  }, [userActivity?.command])

  // Устанавливаем начальное значение времени, если уже есть отработанное время
  useEffect(() => {
    if (userActivity?.time_worked) {
      setTime(timeStringToSeconds(userActivity.time_worked))
    }
  }, [userActivity?.time_worked])

  // Запуск/остановка таймера
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1) // Увеличиваем время каждую секунду
      }, 1000)
    } else if (!isRunning && time !== 0) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [isRunning])

  useEffect(() => {
    dispatch(getUserActivity())
  }, [])

  return (
    <div className={mainCssClass}>
      {(userActivity?.command === 'start' || userActivity?.command === 'continue') && (
        <ControlButton
          className="button"
          typeButton="secondary"
          onClick={() => handleChangeStatus('pause')}
          disabled={!!userActivity?.is_disabled}
          size="small"
        >
          Перерыв
        </ControlButton>
      )}
      {userActivity?.command === 'pause' && (
        <ControlButton
          className="button"
          typeButton="secondary"
          onClick={() => handleChangeStatus('continue')}
          disabled={!!userActivity?.is_disabled}
          size="small"
        >
          Продолжить
        </ControlButton>
      )}
      {userActivity?.command === 'stop' && (
        <ControlButton
          className="button"
          typeButton="primary"
          onClick={() => handleChangeStatus('start')}
          disabled={!!userActivity?.is_disabled}
          size="small"
        >
          Начать
        </ControlButton>
      )}
      {userActivity?.command !== 'stop' && (
        <ControlButton
          className="button"
          typeButton="warning"
          onClick={() => handleChangeStatus('stop')}
          disabled={!!userActivity?.is_disabled}
          size="small"
        >
          Завершить
        </ControlButton>
      )}
      <div className={`${mainCssClass}_time`}>
        <span className={`${mainCssClass}_time_text`}>Время работы</span>
        <span className={`${mainCssClass}_time_timer`}>{formatTime(time)}</span>
      </div>
    </div>
  )
}

export default TimePanel
