import React, { FC, useState } from 'react'
import { PlayPhoneRecord } from '../../../common/svg/SvgFromIconWrapper/PlayPhoneRecord'

const PlayRecordAudioAsterisk: FC<{ link: string }> = ({ link }) => {
  const [audio] = useState(new Audio(link))

  const handlePlay = () => {
    audio.play()
  }

  return (
    <button onClick={handlePlay}>
      <PlayPhoneRecord />
    </button>
  )
}

export default PlayRecordAudioAsterisk
