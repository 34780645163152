import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ userWorkPlan }: TRootState) => userWorkPlan

export const selectUserWorkPlan = createSelector(selectState, ({ userWorkPlan }) => userWorkPlan)
export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectActualPage = createSelector(selectState, ({ actualPage }) => actualPage)
export const selectActualPerPage = createSelector(selectState, ({ actualPerPage }) => actualPerPage)

export const selectEditInfoTable = createSelector(selectState, ({ editInfoTable }) => editInfoTable)
export const selectEditInfs = createSelector(selectState, ({ editInfoTable }) => editInfoTable)
export const selectMassSettings = createSelector(selectState, ({ massSettings }) => massSettings)
export const selectPeriodModal = createSelector(selectState, ({ periodModal }) => periodModal)
export const selectEditPeriod = createSelector(selectState, ({ editPeriod }) => editPeriod)
export const selectSelectedPeriod = createSelector(selectState, ({ selectedPeriod }) => selectedPeriod)