/* eslint-disable react/function-component-definition */
import React, { FC, memo, useState } from 'react'
import { Tag } from 'antd'
import './Vacation.scss'

type TProps = {
  vacancy: string
}
const Vacation: FC<TProps> = ({ vacancy }) => (
  <div>
    {vacancy
      && (
        <Tag key={vacancy} className="customLabel" color="blue">
          {vacancy}
        </Tag>
      )}
  </div>
)

export default memo(Vacation)
