import { createAction } from '@reduxjs/toolkit'
import { TDashaboard, TFakeCandidate } from './types'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TCandidatesData } from '../candidate/types'
import { TPaginationRequest } from '../../common/types/pagination'

// Авторизация

export const getCandidates = createAction<TPaginationRequest>('managementCandidates/getCandidates')
export const setCandidates = createAction<TCandidatesData | null>('managementCandidates/setCandidates')
export const setLoading = createAction<boolean>('managementCandidates/setLoading')

export const setOpenModalArchive = createAction<boolean>('managementCandidates/setOpenModalArchive')

export const setOpenModalChangeStatus = createAction<boolean>('managementCandidates/setOpenModalChangeStatus')

export const setOpenModalAssignVacancy = createAction<boolean>('managementCandidates/setOpenModalAssignVacancy')

export const getDictionaries = createAction<TDictionariesNames>('managementCandidates/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('managementCandidates/dictionaries/setDictionaries')
export const setSelectedCandidateId = createAction<number | null>('managementCandidates/setSelectedCandidateId')
export const mergeDuplicates = createAction<string>('managementCandidates/mergeDuplicates')
export const getVacanciesList = createAction('managementCandidates/getVacanciesList')
export const setVacanciesList = createAction('managementCandidates/setVacanciesList')

export type TRequestCandidateCSV = {
    has_head: boolean
    filter: string
  }

export const exportCSV = createAction<TRequestCandidateCSV>('managementCandidates/exportCSV')

export const reset = createAction('managementCandidates/reset')

export type TDefDataCandidate = {
    page: number,
    perPage: number,
    filters: string,
    search:string
}

export const setDefDataCandidate = createAction<TDefDataCandidate>('managementCandidates/setDefDataCandidate')

export const resetAllCandidate = createAction('managementCandidates/resetAllCandidate')

export type TCandidateHeaders = {
  current:number,
  total:number,
  totalCount: number
}

export const setHeaders = createAction<TCandidateHeaders>('managementCandidates/setHeaders')

export const openChatMobil = createAction<boolean>('managementCandidates/openChatMobil')

export const setSelectedStatus = createAction<number>('managementCandidates/setSelectedStatus')
export const setShowMassStage = createAction<boolean>('managementCandidates/setShowMassStage')


export type TDashboardRequest = {
  filter: string
  isNeedDisable: boolean
}
export const getDashboard = createAction<TDashboardRequest>('managementCandidates/getDashboard')
export const setLoadingDashboard = createAction<boolean>('managementCandidates/setLoadingDashboard')
export const setDisabledDashboard = createAction<boolean>('managementCandidates/setDisabledDashboard')

export const setDashboard = createAction<TDashaboard>('managementCandidates/setDashboard')

export const setDashboardFilter = createAction<string>('managementCandidates/setDashboardFilter')

export type TCandidatesFilterFromDashboardType = {
  role: string
  pageId: string
  stage_id?: number
  stage_label?: string
}

export const setCandidatesFilterFromDashboard = createAction<TCandidatesFilterFromDashboardType>('managementCandidates/setCandidatesFilterFromDashboard')

