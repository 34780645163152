/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tag, Typography } from 'antd'
import { DetailsList } from '../../../../common/components/DetailsList'
import {
  selectDictionaryOptionsByName,
  selectRequest
} from '../../selectors'

import NewScrollContainer from '../../../../common/components/NewScrollContainer/ScrollContainer'
import RequestDetailListEdit from '../RequestDetailListEdit/RequestDetailListEdit'
import { selectEditKeyDetailList } from '../../../../compositions/InnerRouter/selectors'
import { openRequestDrawer, setEditRequestMode } from '../../../../features/requestModals/actions'
import './InfoTabRequest.scss'

const mainCssClass = 'infoTabRequest'
const { Title } = Typography

function InfoTabRequest() {
  const request = useSelector(selectRequest)
  const dispatch = useDispatch()
  const editKeyDetailList = useSelector(selectEditKeyDetailList)
  const characteristic_dictionary = useSelector(selectDictionaryOptionsByName('requestCandidateCharacteristic'))
  const characteristic_list = useMemo(
    () => characteristic_dictionary.filter((item) => request?.characteristic_list?.includes(item.value))?.map((item) => item.label) || [],
    [request, characteristic_dictionary]
  )
  const responsible_id_list = useMemo(
    () => request?.responsible_id_list?.map((item) => `${item.surname}  ${item.name} ${item.patronymic}`) || [],
    [request]
  )
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const handleOpenEditModal = () => {
    dispatch(setEditRequestMode(true))
    dispatch(openRequestDrawer(true))
  }
  // const handleOpenEditCustomerModal = (el) => {
  //   dispatch(openEditRequestCustomerModal(true))
  //   dispatch(setEditCustomerValue(el.id))
  // }
  const vacancy = useMemo(
    () => [
      {
        label: 'График',
        value: (<div>{`${request?.schedule_list?.map((e) => e.value).join(', ')}`}</div>),
        editKey: 'schedule_id_list',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="schedule_id_list"
        />
      },
      { label: 'Адрес', value: (<div onClick={handleOpenEditModal}>{ `${request?.store?.address || 'Не указан'}`}</div>) },
      ...request?.customer_list.map((el, i) => (
        {
          label: `Заказчик ${i + 1}`,
          value: (<div
            // onClick={() => handleOpenEditCustomerModal(el)}
            className={`${mainCssClass}__customer`}
          >
            {' '}
            {`${el.surname || ''} ${el.name || ''} ${el.patronymic}. `}
            {el.phone ? <br /> : null}
            {el.phone ? `Тел: ${el.phone}` : ''}
            {/* <div><EditPencil1 /></div> */}
                  </div>),
          editKey: `customer${i}`,
          edit: true,
          editComponent: <RequestDetailListEdit
            request={request}
            editKey={`customer${i}`}
            customer={el}
          />

        }
      )) || '',
      // { label: 'Контакты заказчика', value: (<div onClick={handleOpenEditModal}>{`${request?.user.customer.phone}`}</div>) },
      {
        label: 'Ответственный',
        value: (<div>{`${request?.responsible_list.surname || ''} ${request?.responsible_list.name || ''}`}</div>),
        editKey: 'responsible',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="responsible"
        />
      },
      {
        label: 'Пояснение',
        value: (<div>{`${request?.description}`}</div>),
        editKey: 'description',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="description"
        />
      },
      {
        label: 'Средняя зп в день на точке',
        value: (<div>{`${request?.reward_average_day || '-'}`}</div>),
        editKey: 'reward_average_day',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="reward_average_day"
        />
      },
      {
        label: 'Средняя зп в месяц на точке',
        value: (<div>{`${request?.reward_average_month || '-'}`}</div>),
        editKey: 'reward_average_month',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="reward_average_month"
        />
      },
      {
        label: 'Подходят студенты',
        value: (<div>{`${request?.is_student ? 'да' : 'нет'}`}</div>),
        editKey: 'is_student',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="is_student"
        />
      },
      {
        label: 'Можно с патентом',
        value: (<div>{`${request?.is_patent ? 'да' : 'нет'}`}</div>),
        editKey: 'is_patent',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="is_patent"
        />
      },
      {
        label: 'Нужна ознакомительная стажировка',
        value: (<div style={{ whiteSpace: 'pre-line' }}>{`${request?.is_internship ? `да\n${request.address_internship}` : 'нет'}`}</div>),
        editKey: 'is_internship',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="is_internship"
        />
      },
      {
        label: 'Особенности кандидата',
        value: (<div>{`${characteristic_list && characteristic_list.length ? `${characteristic_list.join(', ')}` : '-'}`}</div>),
        editKey: 'characteristic_list',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="characteristic_list"
        />
      },
      {
        label: 'Вайтсторы и Дарксторы',
        value: (<div>{`${responsible_id_list.length ? `${responsible_id_list.join(',')}` : '-'}`}</div>),
        editKey: 'responsible_id_list',
        edit: true,
        editComponent: <RequestDetailListEdit
          request={request}
          editKey="responsible_id_list"
        />
      }
    ],
    [editKeyDetailList, request]
  )

  const
    isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={mainCssClass}>
      {!isMobile
            && (
              <NewScrollContainer className={`${mainCssClass}_scroll`}>

                <Title level={4}>Вакансия</Title>
                <div>
                  <Tag className={`${mainCssClass}_label`} color="blue">
                    {request?.vacancy_label}
                  </Tag>
                </div>
                <DetailsList
                  size="m"
                  items={[{
                    items: vacancy
                  }]}
                />
              </NewScrollContainer>
            )}

      {isMobile
            && (
              <>
                <Title level={4}>Вакансия</Title>
                <div>
                  <Tag className={`${mainCssClass}_label`} color="blue">
                    {request?.vacancy_label}
                  </Tag>
                </div>
                <DetailsList
                  size="m"
                  items={[{
                    items: vacancy
                  }]}
                />
              </>
            )}

    </div>
  )
}

export default InfoTabRequest
