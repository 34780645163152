import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'requestPageSvg'

function RequestPageSvg({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '4' }
      )}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="24" fill="#E8ECE9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 20C26.3431 20 25 21.3431 25 23C25 24.6569 26.3431 26 28 26C29.6569 26 31 24.6569 31 23C31 21.3431 29.6569 20 28 20ZM23 23C23 20.2386 25.2386 18 28 18C30.7614 18 33 20.2386 33 23C33 25.7614 30.7614 28 28 28C25.2386 28 23 25.7614 23 23ZM20 37C20 32.5817 23.5817 29 28 29C32.4183 29 36 32.5817 36 37C36 37.5523 35.5523 38 35 38C34.4477 38 34 37.5523 34 37C34 33.6863 31.3137 31 28 31C24.6863 31 22 33.6863 22 37C22 37.5523 21.5523 38 21 38C20.4477 38 20 37.5523 20 37Z"
        fill="#404542"
      />
    </svg>
  )
}
export default RequestPageSvg
