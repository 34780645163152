/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import VacancyNameTable from './VacancyNameTable'
import { TVacancy } from '../../../common/api/vacancy'
import VacancyStatusTableRow from './VacancyStatusTableRow'
import HeadHunterCostRow from './HeadHunterCostRow'
import ButtonsTable from './ButtonsTable'
import HeadHunterUrlRow from './HeadHunterUrl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './VacanciesTable.scss'

const mainCssClass = getCssClassName('client-vacancies-table')

type TProps = {
  vacancies: TVacancy[]
} & TClassName
type TRowData = {
  name: JSX.Element
    id:string
  city: string
  department_name: string
  candidate_cost: string
  request_cost: string | JSX.Element
  status: JSX.Element
  headHunterCost: JSX.Element
    url_hh: JSX.Element | string
  buttons: JSX.Element
}

const useColumns = (): Column<any>[] => useMemo(
  () => [

    {
      accessor: 'name',
      Header: 'Вакансия',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.name && valueB?.props?.name) {
          return valueA.props.name.localeCompare(valueB.props.name, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'id', Header: 'id', sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1) },
    {
      accessor: 'city',
      Header: 'Город',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }

    },
    {
      accessor: 'department_name',
      Header: 'Отдел',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'candidate_cost', Header: 'Кандидаты', sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1) },
    {
      accessor: 'request_cost',
      Header: 'Заявки',
      sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1)
    },
    { accessor: 'status', Header: 'Статус', sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1) },
    { accessor: 'headHunterCost', Header: 'Отклики HH', sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1) },
    { accessor: 'url_hh', Header: (<div className={`${mainCssClass}__url-hh`}> Ссылки HH</div>), sortType: (rowA, rowB, columnId, desc) => (rowA.values[columnId] > rowB.values[columnId] ? 1 : -1) },
    { accessor: 'buttons', Header: '' }
  ],
  []
)

const useRowsData = (vacancies: TVacancy[]) => {
  const dispatch = useDispatch()
  const rows: TRowData[] = useMemo(
    () => (vacancies?.length
      ? vacancies.map<TRowData>(
        ({
          label,
          is_favorite,
          city,
          candidate_cost,
          department_name,
          request_cost,
          id,
          headhunter_cost,
          url_hh,
          status,
          vacancy_count
        }) => ({
          name: <VacancyNameTable name={label || ''} favorite={is_favorite} id={id} />,
          id: String(id) || '-',
          city: String(city) || '-',
          department_name: String(department_name) || '-',
          candidate_cost: String(candidate_cost) || '-',
          request_cost: String(request_cost) || '-',
          status: <VacancyStatusTableRow status={status} />,
          headHunterCost: <HeadHunterCostRow cost={headhunter_cost} />,
          url_hh: <HeadHunterUrlRow url_hh={url_hh} vacancy_count={vacancy_count} />,
          buttons: <ButtonsTable id={id} />
        })
      )
      : []),
    [vacancies]
  )
  return { rows }
}
const VacanciesTable: FC<TProps> = ({ className, vacancies }) => {
  const columns = useColumns()
  const { rows } = useRowsData(vacancies)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      sortabled
      withoutLastColumnElement
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(VacanciesTable))
