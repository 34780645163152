/* eslint-disable react/function-component-definition */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Checkbox, Progress, Tag, Typography
} from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './CardRequest.scss'
import { TClassName } from '../../../common/types/props'

import Circle from '../../../common/svg/Circle'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import UserAdd from '../../../common/svg/UserAdd'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { setOpenDetailsModal, setSelectedRequest, setZoomCoordinates } from '../actions'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import candidate from '../../../compositions/candidate/components/Candidate'
import ModalDetails from './ModalDetails'
import { selectOpenDetailsModal, selectSelectedRequest, selectDictionaryOptionsByName } from '../selectors'
import ArrowDownSM from '../../../common/svg/ArrowDownSM'
import ArrowUpSM from '../../../common/svg/ArrowUpSM'
import { DetailsList } from '../../../common/components/DetailsList'
import Flag from '../../../common/svg/Flag'
import { TRequest } from '../../../common/api/request'
import { selectCandidate } from '../../../compositions/candidateV2/selectors'
import { createLinkCandidate } from '../../../compositions/candidateV2/actions'

type TProps = {
  request: TRequest
  isEdit?: boolean
} & TClassName
const mainCssClass = getCssClassName('card-request')
const CandidateCard: FC<TProps> = ({
  request, className,
  isEdit
}) => {
  const candidate = useSelector(selectCandidate)
  const [open, setOpen] = useState<boolean>(false)
  const selectedRequest = useSelector(selectSelectedRequest)
  const graphic = useMemo(() => request?.schedule_list?.map((e) => e.value).join(', '), [request])
  const [color, setColor] = useState<string>('#404542')
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFF')
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))


  const dispatch = useDispatch()
  const handleAssign = useCallback(() => {
    if (candidate) {
      dispatch(
        createLinkCandidate({
          request_id_list: [request.id],
          candidate_id: candidate?.id
        })
      )
    }
  }, [candidate, request])

  const handleDetail = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])



  const arrayDetails = useMemo(() => [{
    label: 'В работе', value: request?.cost_process || '-'
  }, {
    label: 'Вакансия',
    value: (<Tag className="customLabel" color="blue">
      {request?.vacancy_label}
            </Tag>)
  }, { label: 'График', value: graphic || '-' }, { label: 'Адрес', value: request?.name || '-' }, {
    label: 'Заявка',
    value: (
        <a href={`/application/${request.id}`} target="_blank" rel="noreferrer">
          <div className={`${mainCssClass}__request-arrow`}>
            Перейти в заявку
            <div className={`${mainCssClass}__arrow`}>
              <ArrowLeftSM fill="#D7144B" className="internship_result" />
            </div>

          </div>
        </a>
    )
  }], [request])

  const handleZoomCoordinates = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (request.store && request.store.lattitude && request.store.longtitude && !isNaN(+request.store.lattitude) && !isNaN(request.store.lattitude)) {
      if (request.id === selectedRequest?.id) {
          dispatch(setSelectedRequest(null))
        } else {
          dispatch(setSelectedRequest(request))
          dispatch(setZoomCoordinates([request.store.lattitude, request.store.longtitude]))
        }
    }
  }, [request, selectedRequest])

  useEffect(() => {
    let arrayPriorityStr = priorityOptions?.find((item) => item.value === request?.priority_id)?.params

    if (request?.priority_id) {
      arrayPriorityStr = arrayPriorityStr?.replace(/\\/g, '')
      arrayPriorityStr = arrayPriorityStr
        ?.replace(/^"|"$|\\+/g, '') // Убираем внешние кавычки и экранирование
        .replace(/^\[|]$/g, '{') // Заменяем квадратные скобки на фигурные
        .replace(/""/g, '"') // Исправляем лишние двойные кавычки
        .replace(/color":/g, 'color":') // Убираем лишние кавычки у ключей
        .replace(/backgroundColor":/g, 'backgroundColor":') // Убираем лишние кавычки у ключей
        .replace(/(\{\s*\})|\{\s*\}$/, '') // Удаляем пустые фигурные скобки и лишние фигурные скобки в конце
        .replace(/}$/, '}') // Удаляем лишние фигурные скобки в конце, если они есть
        .replace(/([^}])$/, '$1}') // Закрываем фигурную скобку, если она отсутствует

      // Убираем лишние пробелы в начале и в конце
      arrayPriorityStr = arrayPriorityStr?.trim()

      // Удаляем лишнюю фигурную скобку в конце
      arrayPriorityStr = arrayPriorityStr?.replace(/\{\}\s*$/, '')

      // Проверяем и добавляем закрывающую фигурную скобку, если ее нет
      if (!arrayPriorityStr?.endsWith('}')) {
        arrayPriorityStr += '}'
      }

      // Удаляем кавычки, если они окружают строку
      if (arrayPriorityStr?.startsWith('"') && arrayPriorityStr?.endsWith('"')) {
        arrayPriorityStr = arrayPriorityStr.slice(1, -1)
      }

      // Попробуем распарсить строку в объект
      let arrayPriority = null
      try {
        arrayPriority = JSON.parse(arrayPriorityStr)
      } catch (error) {
        console.error('Ошибка при парсинге JSON:', error)
      }

      const backgroundColor = arrayPriority ? arrayPriority.backgroundColor || '#FFF' : '#FFF'
      const color = arrayPriority ? arrayPriority.color || '#404542' : '#404542'
      setBackgroundColor(backgroundColor)
      setColor(color)
    }
  }, [request, priorityOptions])
  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__header`} onClick={handleZoomCoordinates}>
        <Flag fill={color} fillBg={backgroundColor} />
        <Typography.Title level={5}>{request.name}</Typography.Title>
      </div>
      <Progress
        type="line"
        percent={
          // eslint-disable-next-line no-unsafe-optional-chaining
          request?.closed && request?.cost
            ? Number(request?.closed) === 0
              ? 0
              : (Number(request?.closed) / Number(request?.cost)) * 100
            : 0
        }
        format={() => (request?.closed && request?.cost
          ? `Позиции ${request?.closed}/${request?.cost}`
          : `Позиции 0/ ${request?.cost}`)}
      />
      <div className={`${mainCssClass}__container-details`}>
        <DetailsList
          size="xs"
          className={`${mainCssClass}__details-list`}
          items={[{
            items: arrayDetails
          }]}
        />
      </div>
      <div className={`${mainCssClass}__buttons`}>
        {isEdit && (
          <ControlButton typeButton="secondary" className="button" onClick={handleAssign} icon="left">
            <UserAdd />
            {candidate?.id === request.id ? 'Переназначить на заявку' : 'Назначить на заявку'}
          </ControlButton>
        )}
        <ControlButton typeButton="secondary" className={cn('button', `${mainCssClass}__btn-details`)} onClick={handleDetail} icon="right">
          Детали
          {open ? <ArrowUpSM /> : <ArrowDownSM />}
        </ControlButton>
        {/* <ControlButton typeButton="secondary" className={cn('button', `${mainCssClass}__btn-details`)} onClick={handleOpenRequest}> */}
        {/*  Заявка */}
        {/* </ControlButton> */}
      </div>
      {open && (<ModalDetails request={request} className={`${mainCssClass}__details-list`} />)}
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(CandidateCard))
