/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Radio, RadioGroupProps, Typography } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from 'classnames'
import './RadioFormControl.scss'

type TRadioGroupFormControlProps = Omit<
  UseControllerProps &
    RadioGroupProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
    },
  'isDisabled'
>
const { Title } = Typography

const mainCssClass = 'radioFormControl'

function RadioGroupFormControl({
  name,
  control,
  rules,
  className,
  size,
  options,
  label,
  onChange,
  ...rest
}: TRadioGroupFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  const handleChange = (e) => {
    if (onChange) {
      onChange(e)
    }
    field.onChange(e.target.value)
  }

  return (
    <div
      className={cn(
        className,
        mainCssClass,
        { radioLarge: size === 'large' },
        { radioMiddle: size === 'middle' },
        { radioSmall: size === 'small' },
        { error: fieldState.error }
      )}
    >
      {label && <Title level={4}>{label}</Title>}
      <Radio.Group options={options} onChange={handleChange} value={field.value} {...rest} />
    </div>
  )
}

export default RadioGroupFormControl
