import { createReducer } from '@reduxjs/toolkit'
import {
  OpenFilterMobil,
  openFilters,
  reset,
  setCountFilters,
  setDefFilters,
  setDeleteFilterByKey,
  setDictionaries,
  setFilters,
  setFiltersLabels,
  setStagesFunnelFilter,
  TFilterLabel
} from './actions'
import { TDictionaries, TOption } from '../../common/types/dictionaries'
import { createObjectsArray } from './utils'
import { formValuesArray } from './components/Filters'


type TFiltersState = {
  filters: string
  openFilter: boolean
  dictionaries: TDictionaries | null
  recruiter: TOption[] | null
  researcher: TOption[] | null
  stageFunnelFilter: number[]
  filterMobil:boolean
  defFilters: {
    type: string;
    filter: any
    defFilterString: string
  } | null,
  timeOptions: {value: string, label:string}[]
  countFilters: number
  filterLabels: TFilterLabel[]
  filterKey: string
}

const initialState: TFiltersState = {
  filters: '',
  openFilter: false,
  dictionaries: null,
  recruiter: null,
  researcher: null,
  defFilters: null,
  filterMobil: false,
  stageFunnelFilter: [],
  timeOptions: createObjectsArray(),
  countFilters: 0,
  filterLabels: formValuesArray,
  filterKey: ''
}

const filtersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openFilters, (state, { payload }) => {
      state.openFilter = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setFilters, (state, { payload }) => {
      state.filters = payload
    })
    .addCase(setStagesFunnelFilter, (state, { payload }) => {
      state.stageFunnelFilter = payload
    })
    .addCase(OpenFilterMobil, (state, { payload }) => {
      state.filterMobil = payload
    })
    .addCase(setDefFilters, (state, { payload }) => {
      state.defFilters = payload
    })
    .addCase(setFiltersLabels, (state, { payload }) => {
      state.filterLabels = payload
    })
    .addCase(setCountFilters, (state, { payload }) => {
      state.countFilters = payload
    })
    .addCase(setDeleteFilterByKey, (state, { payload }) => {
      state.filterKey = payload
    })
})

export default filtersReducer
