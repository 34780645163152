import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../common/utils/dateFormats'
import $api from '../../common/api/http'
import { TResume } from '../Resume'
import { TDATA } from './types'

function filterNullAndEmptyKeys(obj: TResume | null) {
  if (!obj) return []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const resumeOptions = [
        { label: 'Пожелания кандидата', value: obj.position },
        { label: 'Языки', value: obj.language },
        { label: 'Ключевые навыки', value: obj.skill_list },
        { label: 'Готовность к переезду/командировкам', value: obj.can_relocate },
        { label: 'Курсы повышения квалификации', value: obj.course_list },
        { label: 'Сертификаты', value: obj.certificate_list },
        { label: 'Дополнительная информация', value: obj.info },
        { label: 'Рекомендации', value: obj.recommendation_list },
        { label: 'Опыт работы', value: obj.experience_list },
        { label: 'Гражданство, время пути до работы', value: obj.citizenship },
        { label: 'Образование', value: obj.education_list }
      ]

      const resumeOptionsFiltred = resumeOptions.filter(
        (el) => !el.value || ((Array.isArray(el.value) && el.value.length === 0))
      )
      const resumeOptionsMap = resumeOptionsFiltred.map((el) => ({ label: el.label, value: el.label }))

      return resumeOptionsMap
    }
  }
}

export default filterNullAndEmptyKeys

export function getTransferDetailList(citizenship: string, work_permit: string, transfer_time: string) {
  const detailListProps = [
    { label: 'Гражданство', value: citizenship },
    { label: 'Разрешение на работу', value: work_permit },
    { label: 'Время в пути на работу', value: transfer_time }
  ]

  return detailListProps
}

export function getWhishesDetailList(
  position: string,
  specialization?: string,
  wages?: number,
  currency?:string,
  employment_list?:string,
  schedule_list?:string
) {
  const detailListProps = [
    { label: 'Должность', value: position },
    { label: 'Специализация', value: specialization },
    { label: 'Зарплата', value: `${wages} ${currency}` },
    { label: 'Занятость', value: employment_list },
    { label: 'График работы', value: schedule_list }
  ]

  return detailListProps
}

export function getRelocateDetailList(relocate: string, trip: string) {
  const detailListProps = [
    { label: 'Переезд', value: relocate },
    { label: 'Командировки', value: trip }
  ]

  return detailListProps
}
export function getQuestionnaireForServer(obj: Object) {
  const arr = []
  for (const [key, value = ''] of Object.entries(obj)) {
    if (key === 'questionnaire') {
      continue
    }

    const question_id = parseInt(key)
    const answer = value || ''

    arr.push({ question_id, answer })
  }
  return arr
}
export function getQuestionnaireFromServer(questionnaire: Array, role, id) {
  if (!questionnaire?.answers?.length) {
    if (id) {
      return { questionnaire: id }
    }
    return {}
  }

  const obj = { questionnaire: questionnaire?.questionnare_data.id }

  questionnaire?.answers?.forEach(({
    question_id, answer
  }) => {
    obj[question_id] = answer
  })
  return obj
}

export async function fetchCitiesList(name: string) {
  const data = await $api.get(`/api/v1/dadata/suggest-city?search=${name}`)
  return data.data.DATA.map((obj: { data: { city: string; city_kladr_id: number | string } }) => ({
    label: `${obj.data.city}`,
    value: obj.data.city,
    key: obj.data.city_kladr_id
  }))
}

export function extractValue(field: any): any {
  if (field) {
    return typeof field === 'object' ? field.value : field
  }
  return null
}

export function generateFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(name_like, surname_like)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return query
}

export function formatDateTime(dateTimeString:string | undefined) {
  if (!dateTimeString) {
    return 'Не назначено'
  }

  const dateTime = dayjs(dateTimeString, ['DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'])
  if (!dateTime.isValid()) {
    return 'Неверный формат'
  }

  return dateTime.format(DATE_TIME_FORMAT)
}

export function createArrayOfTimeObjects() {
  const timeArray = []

  // Добавляем начальный объект
  timeArray.push({ label: 'Не выбрано', value: null })

  // Создаем объекты с интервалом в 30 минут до 21:00
  const currentTime = new Date()
  currentTime.setHours(9, 0, 0, 0) // Начнем с 09:00

  const endTime = new Date()
  endTime.setHours(21, 0, 0, 0) // Закончим в 21:00

  while (currentTime <= endTime) {
    const label = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    const value = label
    timeArray.push({ label, value })

    currentTime.setMinutes(currentTime.getMinutes() + 30)
  }

  return timeArray
}

export function objectToQueryString(obj: any): string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object' && key !== 'tag') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }
      if ((typeof obj[key][0] === 'number' || typeof obj[key][0] === 'boolean') && key !== 'schedule_id' && key !== 'characteristic' && key !== 'group') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
      if ((key === 'date_internship' || key === 'created_at' || key === 'date_registration' || key === 'date')) {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (key === 'tag') {
        const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        str += `&union[tag]=${strArrStr}`
      }
      if (key === 'schedule_id') {
        const strArrStr = obj[key].join(',')
        str += `&union[schedule]=${strArrStr}`
      }
      if (key === 'customer') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[customer]=${strArrStr}`
      }
      if (key === 'request_id') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[request]=${strArrStr}`
      }
      if (key === 'characteristic') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&characteristic=${strArrStr}`
      }
      if (key === 'group') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        const newStrArr = strArrStr.replace(/undefined/g, '')
        str += `&groups=${newStrArr}`
      }
    } else if (typeof obj[key] === 'string' && key !== 'schedule_id') {
      str += `&filter[${key}]=${obj[key]}`
    }
  }

  return str
}

export function removePrefixCity(value : any) {
  return value && value[0] === 'г' ? value.replace(/^[^,]*,\s*/, '') : value
}

export const tasksOptions = [{ label: 'Просроченные', value: 'overdue' }, { label: 'На сегодня', value: 'today' }, { label: 'На завтра', value: 'tomorrow' }, { label: 'Послезавтра и далее', value: 'after_tomorrow' }, { label: 'За всё время', value: 'all' }]

export function updateCandidatePhone<T>(array: T[], index: number): T[] {
  const newArray = [...array] // Создаем копию исходного массива
  const element = newArray.splice(index, 1)[0]
  newArray.unshift(element)

  return newArray
}

const defaultCandidatePhoneNumber = (phone) => {
  if (phone) {
    if (Array.isArray(phone)) {
      if (phone.length) {
        return phone?.map((el: string) => ({ telephone: `+${el}` }))
      }
      return [{ telephone: '' }]
    }
    const phones = phone?.split(',')
    if (phones?.length) {
      return phones?.map((el: string) => ({ telephone: `+${el}` }))
    }

    return [{ telephone: `+${phone}` }]
  }
}

export const getDefaultValuesCandidate = (candidate: TDATA) => (candidate

  ? {
    second_name: candidate.second_name,
    last_name: candidate.last_name,
    city: { value: candidate.city, label: candidate.city, key: candidate.city_kladr_id },
    address: { label: candidate.address, value: candidate.address },
    name: candidate.name,
    birthday: candidate.birthday,
    age: candidate.age,
    date_teach: candidate.date_teach,
    date_registration: candidate.date_registration,
    gender: candidate.gender,
    type_registration: candidate.type_registration,
    currency: candidate.currency,
    schedule: candidate.schedule,
    wages: candidate.wages,
    date_begin_at: candidate.date_begin_at,
    recommend_fio: candidate.recommend_fio,
    leadgen_source_id: candidate.leadgen_source_id,
    metro: candidate.metro,
    email: candidate?.email && Array.isArray(candidate?.email) && candidate.email.length ? candidate?.email.map((el) => ({ mail: el }))
      : [{ mail: candidate?.email || '' }],
    phone: defaultCandidatePhoneNumber(candidate.phone),
    links: candidate.links && Array.isArray(candidate?.links)
     && candidate.links.length ? candidate?.links.map((el: string) => ({ link: el }))
      : [{ link: candidate?.links }],
    type: candidate.type,
    date_internship: candidate.date_internship,
    duration_internship: candidate.duration_internship,
    citizenship: candidate.citizenship,
    recommend_contact: candidate.recommend_contact,
    time_internship: candidate.time_internship ? candidate.time_internship?.slice(0, 5) : null,
    office: candidate.office,
    date_interview_offline: candidate.date_interview_offline,
    inn: candidate.inn
  }
  : {})

export const getResetCandidate = () => ({
  second_name: '',
  last_name: '',
  city: null,
  address: null,
  name: '',
  birthday: null,
  age: null,
  date_teach: null,
  date_registration: null,
  gender: null,
  type_registration: null,
  currency: null,
  schedule: null,
  wages: null,
  date_begin_at: null,
  recommend_fio: null,
  leadgen_source_id: null,
  metro: null,
  email: [{ mail: '' }],
  phone: [{ telephone: '' }],
  links: [{ link: '' }],
  type: null,
  date_internship: null,
  duration_internship: null,
  citizenship: null,
  inn: '',
  recommend_contact: null,
  time_internship: null,
  office: null,
  date_interview_offline: null
})

export const mapContacts = (data: any) => ({
  phone: data.phone.map((el: any) => el.telephone),
  email: data.email.map((el: any) => el.mail),
  links: data.links.map((el: any) => el.link)
})

export const mapCommonFields = (data: any) => ({
  ...mapContacts(data),
  age: data.age ? Number(data.age) : null,
  time_internship: data.time_internship ? data.time_internship : null,
  address: data.address?.value ?? (typeof data.address === 'string' ? data.address : '')
})

export type TRrepareCandidateDataProps = {
  data: any,
  cityKey?: string | number
  responsibleId?: number
  id?: number
  researcherId?: number
}

export const prepareCandidateData = ({
  data, cityKey, responsibleId, id,
  researcherId
}: TRrepareCandidateDataProps) => ({
  ...data,
  id,
  ...mapCommonFields(data),
  city_kladr_id: cityKey,
  city: extractValue(data.city),
  status: 49,
  responsible: responsibleId,
  researcher: researcherId
})

export const validateDateOfBirth = (value: string): boolean => (value ? value.replace(/[^0-9]/g, '').length > 7 && !value.includes('_') : true)
