import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react' // Updated import
import { Integrations } from '@sentry/tracing' // Updated import
import App from './app/App'
import withErrorBoundary from './common/hoc/withErrorBoundary/withErrorBoundary'

const AppWithErrorBoundary = withErrorBoundary(App)

const initializeSentry = () => {
  try {
    Sentry.init({
      dsn: 'https://c5ba703eb4c50348841c2eb2bc57089f@sentry.vkusvill-hrm.ru/2',
      integrations: [
        // @ts-ignore
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', 'dev.vkusvill-hrm.ru', 'vkusvill-hrm.ru', /^\//], // Adjust as needed
          // @ts-ignore
          // eslint-disable-next-line no-restricted-globals
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: true,
          dom: true,
          fetch: true,
          history: true,
          sentry: true,
          xhr: true
        })
      ],
      tracesSampleRate: 1.0
    })
  } catch (error) {
    console.error('Sentry initialization failed:', error)
  }
}

initializeSentry()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithErrorBoundary />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
