import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Table from '../../../common/components/table/Table'
import HistoryActionNameTable from './HistoryActionNameTable'
import { THistoryCandidateData } from '../../../common/api/Candidate'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { removeATag } from '../utils'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
    historyData: THistoryCandidateData[]
} & TClassName
type TRowData = {
    action: JSX.Element
    user: string
    before: JSX.Element | string
    after: JSX.Element | string
    date: string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    { accessor: 'date', Header: 'Дата' },
    {
      accessor: 'action',
      Header: 'Действие'
    },
    {
      accessor: 'user',
      Header: 'Сотрудник'
    },
    { accessor: 'before', Header: 'Было' },
    { accessor: 'after', Header: 'Стало' }
  ],
  []
)

const useRowsData = (historyData: THistoryCandidateData[]) => {
  const userId = localStorage.getItem('user_id')

  const dispatch = useDispatch()
  const rows: TRowData[] = useMemo(
    () => (historyData?.length
      ? historyData.map<TRowData>(
        ({
          action,
          user,
          before,
          after,
          date
        }) => ({
          date: date || '-',
          action: <HistoryActionNameTable action={action || '-'} />,
          user: user?.user_name ? String(user?.user_name) : '-',
          // Объединяем before и after в одну строку
          before:

              before ? <div dangerouslySetInnerHTML={{ __html: before }} /> : '',

          // Просто пробел, чтобы разделить содержимое before и after в строке
          after:
            after ? action === 'taskAdd' ? <div dangerouslySetInnerHTML={{ __html: removeATag(after, user?.user_id, userId) }} />
              : <div dangerouslySetInnerHTML={{ __html: after }} />
              : ''
        })
      )
      : []),
    [historyData]
  )
  const handleRowClick = () => {

  }
  return { rows, handleRowClick }
}
const CandidateHistoryTable: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(historyData)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
      handleRowClick={handleRowClick}
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidateHistoryTable))
