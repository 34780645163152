import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectCandidate, selectDictionaryOptionsByName } from '../../candidate/selectors'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectResume } from '../selectors'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import { createInfoResume, createSkillsResume } from '../actions'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: (data: any) => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  info: string
}

function ModalSkillsCandidate({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)

  const defaultValues = useMemo(
    () => (editMode && resume?.info
      ? {
        info: resume?.info
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = useCallback(() => {
    dispatch(createInfoResume({ resume_id: resumeId, ...getValues() }))
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Доп. информация"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      maskClosable={false}
    >
      <TextAreaFormControl
        name="info"
        control={control}
        label="Дополнительная информация"
        autoSize={{ minRows: 4, maxRows: 10 }}
      />
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalSkillsCandidate))
