import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TInterview, TInterviewsResponseData, TRequestWorkload } from '../../common/api/interviews'
import { TPaginationRequest } from '../../common/types/pagination'

export const getInterviews = createAction<TPaginationRequest>('managementInterviews/getInterviews')
export const setInterviews = createAction<TInterviewsResponseData| null>('managementInterviews/setInterviews')

export const getDictionaries = createAction<TDictionariesNames>('managementInterviews/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('managementInterviews/dictionaries/setDictionaries')

export const setLoading = createAction<boolean>('managementInterviews/setLoading')
export const reset = createAction('managementInterviews/reset')


export type TRequestInterviewsCSV = {
    has_head: boolean
    filter:string
    self: string
  }

export const exportCSV = createAction<TRequestInterviewsCSV>('managementInterviews/exportCSV')

export const setDefStringInterviews = createAction<string>('managementInterviews/setDefString')

export type TDefDataInterview = {
  page: number,
  perPage: number,
  filters: string,
  search:string
}

export const setDefDataInterview = createAction<TDefDataInterview>('managementInterviews/setDefDataInterview')

export const resetAllInterview = createAction('managementInterviews/resetAllInterview')

export type TRequestHeaders = {
current:number,
total:number,
totalCount: number
}

export const setHeaders = createAction<TRequestHeaders>('managementInterviews/setHeaders')

export type TRequestUpdateConfirmInterview = {
  id:number
  confirm:string
}

export const updateInterview = createAction<TRequestUpdateConfirmInterview>('managementInterviews/updateInterview')
export const setBlockedFiltersResponsibleOrRecruiter = createAction<boolean>('managementInterviews/setBlockedFiltersResponsibleOrRecruiter')

export const getWorkload = createAction<TRequestWorkload>('managementInterviews/getWorkload')
export const setWorkload = createAction<any>('managementInterviews/setWorkload')

export const openWorkloadFilter = createAction<boolean>('managementInterviews/openWorkloadFilter')
export const setWorkloadPage = createAction<boolean>('managementInterviews/setWorkloadPage')

export const setWorkloadFilters = createAction<string>('managementInterviews/setWorkloadFilters')
export const setDefWorkloadFilters = createAction<any>('managementInterviews/setDefWorkloadFilters')
