import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { TInterview } from '../../../common/api/interviews'
import { updateInterview } from '../../../compositions/InterviewCard/actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { TOption } from '../../../common/types/dictionaries'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import './CancelInterviewModals.scss'

const mainCssClass = 'cancel-interview-modals'

type TProps = {
    options: TOption[]
    interview: TInterview | null
} & ModalProps
function CancelInterviewModals({
  open, className, onCancel, options, interview
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()
  const status = watch('status')

    type TFormValues = {
        status: string
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }

    const handleSubmit = useCallback(() => {
      if (interview) {
        dispatch(updateInterview({
          ...interview,
          status: 93,
          canceling_reason: status
        }))
      }
    }, [interview, status])

    return (
      <CommonModal
        open={open}
        onOk={handleSubmit}
        onCancel={handleModalClose}
        title="Отменить интервью"
        className={cn(className, mainCssClass)}
        width="400px"
        typeButtonAccept="warning"
        okText="Отменить"
        cancelText="Не отменять"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >
        <div>
          <TextAreaFormControl
            name="status"
            control={control}
            label="Причина"
            options={options}
          />
        </div>
      </CommonModal>
    )
}

export default CancelInterviewModals
