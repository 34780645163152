import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import debounce from 'lodash/debounce'
import Required from '../../svg/Required'

import './DebounceSelectFormControl.scss'
import { TOption } from '../../../common/types/dictionaries'

const { Title } = Typography

type CombinedProps = Omit<
  UseControllerProps &
    SelectProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      fetchOptions: (search: string, id?: string | number) => Promise<TOption[]>
      debounceTimeout?: number
      required?: boolean
      onInputValueChange?: (inputValue: string) => void;
    },
  'isDisabled'
>

const mainCssClass = 'debounce-select'

function DebounceSelectFormControl({
  name,
  control,
  rules,
  fetchOptions,
  debounceTimeout = 1000,
  label,
  required,
  onInputValueChange,
  ...rest
}: CombinedProps) {
  const { field, fieldState } = useController({ name, control, rules })

  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<TOption[]>([])
  const [inputValue, setInputValue] = useState<any>()
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        const clientOption = { value, label: value }
        if (clientOption.value && clientOption.value.trim()) {
          const updatedOptions = [clientOption, ...newOptions]
          setOptions(updatedOptions)
        } else {
          setOptions(newOptions)
        }
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const handleSelectChange = (selectedOption: TOption) => {
    if (selectedOption) {
      field.onChange(selectedOption)
    } else {
      field.onChange(null); 
    }
  }

  useEffect(() => {
    if (onInputValueChange) {
      onInputValueChange(inputValue)
    }
  }, [inputValue, onInputValueChange])


  return (
    <div className={mainCssClass}>
      {label && (
        <Title className="textAreaLabel" level={4}>
          {label}
          {required && <Required className="required" />}
        </Title>
      )}

      <Select
        labelInValue
        showSearch
        filterOption={false}
        onSearch={(value) => {
          setInputValue(value)
          debounceFetcher(value)
        }}
        className={fieldState.error ? 'error' : ''}
        {...rest}
        value={field.value}
        options={options}
        onChange={handleSelectChange}
        defaultValue={null}
      />
    </div>
  )
}

export default DebounceSelectFormControl
