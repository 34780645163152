import React from 'react'
import Icon from '../svgIcons/RedoUndo.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function RedoUndo(props: IconProps) {
  return (
    <IconWrapper {...props} name="RedoUndo">
      <Icon />
    </IconWrapper>
  )
}
