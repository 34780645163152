import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getDictionaries, updateStatusCandidate } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './NewAutodialModal.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import AddPlusSvgGreen from '../../../common/svg/AddPlusSvgGreen'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'

const mainCssClass = 'new-auto-dial-modal'

type TProps = {
    candidateId?: number | null | string
    isCandidateTable: boolean
} & ModalProps
function NewAutodialModal({
  onOk, open, className, onCancel, options, candidateId, isCandidateTable
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()

    type TFormValues = {
        status: number
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }

    // const handleSubmit = useCallback(() => {
    //   if (candidateId) {
    //     const data = watch('status')
    //     if (data) {
    //       dispatch(updateStatusCandidate({ id: candidateId, ...getValues(), isCandidateTable }))
    //     }
    //   }
    // }, [candidateId, isCandidateTable])
    //
    // useEffect(() => {
    //   if (isCandidateTable) {
    //     dispatch(getDictionaries(['candidateStatus']))
    //   }
    // }, [])

    useEffect(
      () => () => {
        reset()
      },
      []
    )

    return (
      <CommonModal
        centered
        open={open}
        onOk={onOk}
        onCancel={handleModalClose}
        title="Новый автообзвон"
        className={cn(className, mainCssClass)}
        width="960px"
        typeButtonAccept="primary"
        okText="Сохранить и запустить"
        cancelText="Отменить"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >
        <div>
          <InputFormControl control={control} name="name" label="Название" rules={{ required: true }} />
        </div>
        <div className={`${mainCssClass}_chime`}>
          <InputFormControl control={control} name="busy" label='Перезвон при "Занят"' rules={{ required: true }} />
          <InputFormControl control={control} name="name" label='Перезвон при "Нет ответа"' rules={{ required: true }} />
          <InputFormControl control={control} name="name" label='Перезвон при "Недоступен"' rules={{ required: true }} />

        </div>
        <div className={`${mainCssClass}_time-out`}>
          <InputFormControl control={control} name="name" label='Таймаут при "Занят"' rules={{ required: true }} />
          <InputFormControl control={control} name="name" label='Таймаут при "Нет ответа"' rules={{ required: true }} />
          <InputFormControl control={control} name="name" label='Таймаут при "Недоступен"' rules={{ required: true }} />
        </div>
        <div>
          <InputFormControl control={control} name="busy" label="Ожидание ответа" rules={{ required: true }} />
        </div>
        <div>
          <SelectFormControl
            name="status"
            control={control}
            label="Кто будет звонить"
            rules={{ required: true }}
          />
        </div>
      </CommonModal>
    )
}

export default memo<TProps>(withErrorBoundary<TProps>(NewAutodialModal))
