import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ managementInterviews }: TRootState) => managementInterviews

export const selectInterviews = createSelector(
  selectState,
  ({ interviews }) => (interviews ? interviews?.interview_list : [])
)
export const selectResultInterviewsCount = createSelector(selectState, ({ interviews }) => {
  const { count } = interviews?.counters?.find((item) => item.id === 'result') || {}
  return count || 0
})

export const selectMineInterviewsCount = createSelector(selectState, ({ interviews }) => {
  const { count } = interviews?.counters?.find((item) => item.id === 'my') || {}
  return count || 0
})
export const selectAllInterviewsCount = createSelector(selectState, ({ interviews }) => {
  const { count } = interviews?.counters?.find((item) => item.id === 'all') || {}
  return count || 0
})

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectDefString = createSelector(selectState, ({ defString }) => defString)

export const selectDefDataInterview = createSelector(selectState, ({ defDataInterview }) => defDataInterview)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectBlockedFiltersResponsibleOrRecruiterInterview = createSelector(
  selectState,
  ({ blockedFiltersResponsibleOrRecruiter }) => blockedFiltersResponsibleOrRecruiter
)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectWorkload = createSelector(selectState, ({ workload }) => workload)
export const selectWorkloadPage = createSelector(selectState, ({ workloadPage }) => workloadPage)
export const selectWorkloadFilter = createSelector(selectState, ({ workloadFilter }) => workloadFilter)
export const selectWorkloadFilterString = createSelector(selectState, ({ workloadFilterString }) => workloadFilterString)
export const selectDefWorkloadFilterString = createSelector(selectState, ({ defWorkloadFilter }) => defWorkloadFilter)
