import React, { useCallback } from 'react'
import {
  ModalProps
} from 'antd'
import cn from 'classnames'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'duplicate-vacancy-modal'

type TProps = {
 parsingData: any
} & ModalProps
// @ts-ignore
function ParsingModal({
  open, className, onCancel, parsingData
}: TProps) {
  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }

  const handleSubmit = useCallback(() => {
  }, [])

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit}
      onCancel={handleModalClose}
      title="Обновить парсинг?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Остановить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      Вы действительно хотите обновить парсинг ?
    </CommonModal>
  )
}

export default ParsingModal
