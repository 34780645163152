import React, {
  memo, useEffect
} from 'react'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import './ModalMap.scss'
import { useDispatch } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import MapComponent from './Map'
import {
  reset
} from '../actions'
import { TCandidate } from '../../../compositions/candidateV2/types'
import { setOpenModalMap } from '../../../compositions/candidateV2/actions'
import ContainerCard from './ContainerCard'

const mainCssClass = 'modal-map'

type TProps = {
  isEdit?: boolean
  candidate: TCandidate
} & ModalProps
function ModalMap({
  className,
  open,
  candidate,
  isEdit
}: TProps) {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(reset())
  }, [])

  return (
    <Modal
      open={open}
      maskClosable
      className={cn(className, mainCssClass)}
      onCancel={() => dispatch(setOpenModalMap(false))}
      closable={false}
      wrapClassName="wrapModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={`${mainCssClass}__container-body`}>
        <ContainerCard open={open} isEdit={isEdit} candidate={candidate} />

        <MapComponent className={`${mainCssClass}__container-map`} />
      </div>
    </Modal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalMap))
