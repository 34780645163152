import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getDictionaries, updateStatusCandidate } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './AutodialModal.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import AddPlusSvgGreen from '../../../common/svg/AddPlusSvgGreen'
import NewAutodialModal from './NewAutodialModal'
import { selectIsOpenAutoDial, selectIsOpenNewAutoDial } from '../../../compositions/Fluidity/selectors'
import { setOpenModalAutoDial, setOpenModalNewAutoDial } from '../../../compositions/Fluidity/actions'

const mainCssClass = 'auto-dial-modal'

type TProps = {
  candidateId?: number | null | string
  isCandidateTable: boolean
} & ModalProps
function AutodialModal({
  onOk, open, className, onCancel, options, candidateId, isCandidateTable
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()
  const modalNewAutoDialOpen = useSelector(selectIsOpenNewAutoDial)
  type TFormValues = {
    status: number
  }

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
      reset()
    }
  }
  const handleOpenNewAutoDial = useCallback(() => {
    dispatch(setOpenModalNewAutoDial(true))
  }, [])
  const handleNewAutoDialModalCancel = () => {
    dispatch(setOpenModalNewAutoDial(false))
  }
  // const handleSubmit = useCallback(() => {
  //   if (candidateId) {
  //     const data = watch('status')
  //     if (data) {
  //       dispatch(updateStatusCandidate({ id: candidateId, ...getValues(), isCandidateTable }))
  //     }
  //   }
  // }, [candidateId, isCandidateTable])
  //
  // useEffect(() => {
  //   if (isCandidateTable) {
  //     dispatch(getDictionaries(['candidateStatus']))
  //   }
  // }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      centered
      open={open}
      onOk={onOk}
      onCancel={handleModalClose}
      title="Автообзвон"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Сохранить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        <SelectFormControl
          name="status"
          control={control}
          label="Сохранненые автообзвоны"
          required
          rules={{ required: true }}
        />
      </div>
      <div className={`${mainCssClass}__new-autoDial`}>
        <AddPlusSvgGreen />
        <div className={`${mainCssClass}__text`} onClick={handleOpenNewAutoDial}>
          Создать новый автообзвон
        </div>
      </div>
      <NewAutodialModal
        open={modalNewAutoDialOpen}
        isCandidateTable
        className={`${mainCssClass}__modals`}
        onCancel={handleNewAutoDialModalCancel}
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(AutodialModal))
