import React, {
  FC, useEffect, useMemo, useState, memo, useCallback
} from 'react'
import { useParams } from 'react-router'
import {
  Button, Typography
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import ArchiveCandidateModal from '../../../features/candidateModals/components/ArchiveCandidateModal'
import {
  selectCandidates,
  selectDictionaryOptionsByName,
  selectIsOpenArchive,
  selectIsOpenChangeStatus,
  selectLoading,
  selectResultCandidateCount,
  selectSelectedId
} from '../selectors'
import {
  getCandidates, getDictionaries, setOpenModalArchive, setOpenModalChangeStatus, reset, exportCSV
} from '../actions'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import Excel from '../../../common/svg/Excel'
import { setDefStringInterviews } from '../../ManagementInterviews/actions'
import CandidatesTable from './CandidatesTable'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'

import ChangeStatusModal from '../../../features/candidateModals/components/ChangeStatusModal'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import { setDefStringFunnel } from '../../Funnel/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import './CandidateListMobil.scss'
import InputFormControlGlobalSearch from '../../../common/components/formcontrol/InputFormControlGlobalSearch'
import { openFilters, setFilters } from '../../../features/filters/actions'
import { selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import CandidatesTableMobil from './CandidatesTableMobil'

const mainCssClass = getCssClassName('candidate-list-mobil')
const userId = localStorage.getItem('user_id')

const CandidateListMobil: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const [page, setPage] = useState<number>(1)
  const modalArchiveOpen = useSelector(selectIsOpenArchive)
  const modalChangeStatusOpen = useSelector(selectIsOpenChangeStatus)
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const archiveReasonOptions = useSelector(selectDictionaryOptionsByName('archiveReason'))
  const rejectionReasonOPtions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const candidatesList = useSelector(selectCandidates)
  const selectedCandidateId = useSelector(selectSelectedId)
  const countResult = useSelector(selectResultCandidateCount)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)

  const defFilters = useSelector(selectDefFilters)

  const memoCandidateStatusOptions = useMemo(() => {
    if (candidateStatusOptions.length > 0) {
      return candidateStatusOptions.filter((el: any) => el.value !== 666 && el.value !== 51)
    }
    return []
  }, [candidateStatusOptions])

  const { control, watch } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10,
      name: ''
    }
  })

  type TFormValues = {
    perPagesCount: number
    name: string
  }

  const perPagesCount = watch('perPagesCount')

  const maxPage = useMemo(() => {
    const counted = countResult / perPagesCount
    if (isInteger(counted)) {
      return counted
    }
    return Math.floor(counted) + 1
  }, [countResult, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])
  const stringCountsPagination = useMemo(
    () => `${perPagesCount * page - perPagesCount + 1} - ${
      countResult > perPagesCount ? perPagesCount * page : perPagesCount * page + countResult - perPagesCount
    } из ${countResult}`,
    [countResult, perPagesCount, page]
  )

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handleArchiveCancel = () => {
    dispatch(setOpenModalArchive(false))
  }
  const handleChangeModalCancel = () => {
    dispatch(setOpenModalChangeStatus(false))
  }

  const handleExportCSV = () => {
    dispatch(exportCSV({
      has_head: true,
      filter: watch('name') ? `${filtersString || ''}&search=${watch('name')}` : (filtersString || '')
    }))
  }

  const name = watch('name')


  useEffect(() => {
    dispatch(getDictionaries(['archiveReason', 'candidateStatus', 'rejectionReason']))
    if ((pageId === defFilters?.type) && defFilters?.defFilterString) { dispatch(setFilters(defFilters?.defFilterString)) }
    dispatch(setDefStringInterviews(''))
    dispatch(setDefStringFunnel(''))
  }, [])

  useEffect(() => {
    setPage(1)
  }, [perPagesCount])

  useEffect(() => {
    if (filtersString) {
      dispatch(getCandidates({
        page,
        perPage: perPagesCount,
        filters: watch('name')
          ? `${filtersString}&search=${watch('name')}` : filtersString
      }))
    } else if (!filtersString && watch('name')) {
      dispatch(getCandidates({ page, perPage: perPagesCount, filters: `&search=${watch('name')}` }))
    } else {
      dispatch(getCandidates({ page, perPage: perPagesCount }))
    }
  }, [page, perPagesCount, filtersString, watch('name')])

  useEffect(
    () => () => {
      dispatch(reset())
      dispatch(setFilters(''))
    },
    []
  )

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>Кандидаты</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__count`}>
            <Typography.Title level={5} className={`${mainCssClass}__title`}>
                &nbsp;
              {countResult}
            </Typography.Title>
          </div>

        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <div className={`${mainCssClass}__input-container`}>
            <InputFormControlGlobalSearch name="name" control={control} placeholder="ФИО, телефон" />
            </div>
          </div>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`} />
            {!!countResult && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === maxPage ? '#BFC5C0' : '#404542'} />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <ScrollContainer className={`${mainCssClass}__scroll-container`}>
              <CandidatesTableMobil candidates={candidatesList} className={`${mainCssClass}__table`} />
            </ScrollContainer>
          </div>
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(CandidateListMobil))
