import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getCssClassName } from '../../../common/utils/generateClassName'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { selectEditInfoTable, setEditInfoTable, TEditTable } from '..'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import CheckBig from '../../../common/svg/CheckBig'
import CloseMD from '../../../common/svg/CloseMD'
import './EditCounter.scss'

export type TEditCounterProps = {
    count: number
} & TEditTable

export type TFormValues = {

}

const mainCssClass = getCssClassName('edit-counter')
function EditCounter({ count, type, id } : TEditCounterProps) {
  const dispatch = useDispatch()
  const { control, watch, setValue } = useForm<TFormValues>()
  const editInfo = useSelector(selectEditInfoTable)

  const handleClickEdit = () => {
    dispatch(setEditInfoTable({
      type, id
    }))
  }
  const handleClickClose = () => {
    dispatch(setEditInfoTable(null))
  }

  return (
    <div className={mainCssClass}>
      {type === editInfo?.type && id === editInfo.id
        ? (
          <>
            <InputFormControl control={control} name="" />
            <CheckBig color="#2dbe64" />
            <CloseMD color="#D7144B" onClick={handleClickClose} />
          </>

        )
        : (
          <>
            <EditPencil1 onClick={handleClickEdit} />
            {count || '0'}
          </>
        )}

    </div>
  )
}

export default EditCounter
