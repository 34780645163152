import React, { useEffect } from 'react'
import { Switch, SwitchProps } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'

type TControlSwitchProps = Omit<UseControllerProps & SwitchProps, 'isDisabled'>

function SwitchFormControl({
  name, control, rules, defaultChecked, onChange, ...rest
}: TControlSwitchProps): JSX.Element {
  const { field } = useController({ name, control, rules })

  const handleSwitchChange: SwitchProps['onChange'] = (checked, event) => {
    if (onChange) {
      onChange(checked, event) // Передаем параметры в пользовательскую логику
    }
    field.onChange(checked) // Затем вызываем field.onChange с новым значением
  }

  useEffect(() => {
    if (defaultChecked) { field.onChange(defaultChecked) }
  }, [])

  return <Switch checked={field.value} className="switchFormControl" onChange={handleSwitchChange} {...rest} />
}

export default SwitchFormControl
