/* eslint-disable react/function-component-definition */
import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import MenuDrop from '../../../common/svg/MenuDrop'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  getSelectedRequest,
  setOpenModalArchiveRequest,
  setOpenModalEditRequest,
  setSelectedRequest,
  setSelectedRequestId
} from '../actions'
import { selectRequestArchiveOpen, selectRequests } from '../selectors'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { openDuplicateModal, openRequestDrawer, setEditRequestMode, setIsRequestTable } from '../../../features/requestModals/actions'

type TProps = {
  id: number | null
}
const ButtonsTable: FC<TProps> = ({ id }) => {
  const dispatch = useDispatch()
  const requests = useSelector(selectRequests)
  const archiveOpen = useSelector(selectRequestArchiveOpen)
  const { rbacControl } = useEntityContext()

  const handleOpenEditModal = useCallback(() => {
    if (id) {
      dispatch(getSelectedRequest(id))
      dispatch(setSelectedRequestId(id))
      dispatch(openRequestDrawer(true))
      dispatch(setEditRequestMode(true))
      dispatch(setIsRequestTable(true))
    }
  }, [id, requests])

  const handleOpenArchiveModal = useCallback(() => {
    if (id) {
      dispatch(setSelectedRequestId(id))
      dispatch(setOpenModalArchiveRequest(true))
    }
  }, [id])

  const handleDuplicateRequest = useCallback(() => {
    if (id) {
      dispatch(setSelectedRequestId(id))
      dispatch(openDuplicateModal(true))
    }
  }, [id])

  const items: MenuProps['items'] = [
    ...(rbacControl?.request?.edit_view_access === 1 ? [{
      key: 'info',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenEditModal}>
          Редактировать
        </Button>
      )
    }] : []),

    ...(rbacControl?.request?.archive_access === 1 && !archiveOpen ? [{
      key: 'questionnaire',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenArchiveModal}>
          Архивировать
        </Button>
      )
    }] : []),
    ...(rbacControl?.request?.archive_access === 1 && archiveOpen ? [{
      key: 'questionnaire',
      label: (
        <Button type="ghost" size="large" onClick={handleDuplicateRequest}>
          Дублировать
        </Button>
      )
    }] : [])

  ]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomLeft"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
