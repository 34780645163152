import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './DepartmentWorkPlanTable.scss'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TableCostOfHiring from '../../../common/components/table/TableCostOfHiring'
import { selectUserWorkPlan } from '../selectors'
import EditCounter from './EditCounter'

const mainCssClass = getCssClassName('cost-of-hiring-table')

type TRowData = {
  fio: string
  work_day_per_month: string | number
  counter_plan: JSX.Element
  counter_fact: JSX.Element
  implementation_plan: string | number
  id:number
  user_id: number
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'fio',
      Header: 'ФИО',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'work_day_per_month',
      Header: 'Рабочих д./месяц',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'counter_plan',
      Header: 'План',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'counter_fact',
      Header: 'Факт',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'implementation_plan',
      Header: 'Выполнение плана',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    }
  ],
  []
)

const useRowsData = (data: TRowData[]) => {
  const userArr = useSelector(selectUserWorkPlan)
  const handleRowClick = useCallback((row) => {
    const { original: { id } } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (userArr?.length
      ? data.map(({
        fio,
        work_day_per_month,
        counter_plan,
        counter_fact,
        implementation_plan,
        id,
        user_id
      }) => ({
        fio: fio || '-',
        work_day_per_month: work_day_per_month || '-',
        counter_plan: <EditCounter count={counter_plan} type='plan' id={user_id}/>,
        counter_fact: <EditCounter count={counter_fact} type='fact' id={user_id}/>,
        implementation_plan: implementation_plan || '-',
        id: user_id
      }))
      : []),
    [userArr]
  )

  return { rows, handleRowClick }
}
const DepartmentWorkPlanTable: FC<TClassName> = ({ className }) => {
  const columns = useColumns()
  const userArr = useSelector(selectUserWorkPlan)
  const { rows, handleRowClick } = useRowsData(userArr)


  return (
    <TableCostOfHiring
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(DepartmentWorkPlanTable))
