import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ fluidity }: TRootState) => fluidity

export const selectIsOpenAutoDial = createSelector(
  selectState,
  ({ openModalAutoDial }) => openModalAutoDial
)

export const selectIsOpenNewAutoDial = createSelector(
  selectState,
  ({ openModalNewAutoDial }) => openModalNewAutoDial
)
