import React, { memo, useEffect } from 'react'
import { ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  mergeDuplicates, openDuplicateModal
} from '../actions'
import { selectDuplicateList } from '../selectors'
import User02 from '../../../common/svg/User02'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import './DuplicateModal.scss'
import { selectLoadingModal, selectUserInfo } from '../../InnerRouter/selectors'

const mainCssClass = 'duplicate-modal'

export type TProps = {
  candidateId?: number
} & ModalProps

type TFormValues = {
  comment: string
}

function DuplicateModal({
  open, className, candidateId
}: TProps) {
  const dispatch = useDispatch()
  const duplicateList = useSelector(selectDuplicateList)
  const loadingModal = useSelector(selectLoadingModal)
  const userInfo = useSelector(selectUserInfo)

  const {
    handleSubmit, reset
  } = useForm<TFormValues>()

  const onSubmit = () => {
    if (candidateId) {
      dispatch(mergeDuplicates(String(candidateId)))
    }
  }

  const handleClickCancel = () => {
    dispatch(openDuplicateModal(false))
  }

  useEffect(() => () => {
    reset()
  }, [])

  return (
    <CommonModal
      open={open}
      onOk={userInfo?.role?.role_id === 'recruitment-agency' ? null : handleSubmit(onSubmit)}
      onCancel={handleClickCancel}
      title="Дубли кандидата"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText={userInfo?.role?.role_id === 'recruitment-agency' ? null : 'Объединить'}
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >

      <div className={`${mainCssClass}_list`}>

        {loadingModal && <Preloader size="md" /> }
        {!!duplicateList.length && !loadingModal
        && duplicateList.map((el, i) => (
          <div key={i} className={`${mainCssClass}_list_item`}>

            <User02 />
            <a href={`/candidate/${el.candidate_id}`} target="_blank" rel="noreferrer">
              {el.candidate_label}
            </a>
            {/* <div className={`${mainCssClass}_list_item_main`}>
              <span className={`${mainCssClass}_list_item_main_id`}>
                {el.is_main === 1 ? '(основной)' : '(побочный)'}
              </span>
            </div> */}
          </div>
        ))}
      </div>

    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(DuplicateModal))
