import React, { FC, memo } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { Button } from 'antd'
import withErrorBoundary from '../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TClassName, TMainProps } from '../../common/types/props'
import { getCssClassName } from '../../common/utils/generateClassName'
import ErrorCommonComponent from '../../common/components/error_component/ErrorCommonComponent'
import { navigateTo } from '../../common/actions/navigate'
import './ErrorPage.scss'
import ControlButton from '../../common/components/Buttons/Button'

type TProps = {
  errorCode?: 403 | 404 | 'init-error' | 'role-error' | 'is-candidate'
} & TMainProps

const mainCssClass = getCssClassName('error-page')

// eslint-disable-next-line react/function-component-definition
const ErrorPage: FC<TProps> = ({ className, errorCode = 404 }) => {
  const dispatch = useDispatch()
  const handleReturn = () => {
    if (localStorage.getItem('initial_url')) {
      dispatch(navigateTo(localStorage.getItem('initial_url')))
    } else {
      dispatch(navigateTo('/'))
    }
  }
  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__content`}>
        {errorCode === 404 && <ErrorCommonComponent title="Ошибка 404" subtitle="Обратитесь к администратору" />}
        {errorCode === 403 && (
          <ErrorCommonComponent
            title="Ошибка 403"
            message={(
              <div className={`${mainCssClass}__message`}>
                {`Ваша роль не соответствует перечню допустимых ролей в конвейере.
        Актуальная информация размещена на ресурсе. Обратитесь за подробностями к админу`}
              </div>
            )}
          />
        )}
        {errorCode === 403 && errorCode === 'is-candidate'(
          <ErrorCommonComponent
            title="Ошибка 403"
            message={(
              <div className={`${mainCssClass}__message`}>
                кандидат находится не в вашей группе, у вас нет прав на его просмотр
              </div>
            )}
          />
        )}
        {errorCode === 'init-error' && (
          <ErrorCommonComponent
            title="Ошибка обратитесь в поддержку"
            message={(
              <div className={`${mainCssClass}__message`}>
                <span>Не удалось запустить приложение.</span>
                <span>Обратитесь в поддержку.</span>
              </div>
            )}
            icon={<div className={`${mainCssClass}__icon`} />}
          />
        )}
        {errorCode === 'role-error' && (
          <ErrorCommonComponent
            title="Ошибка роли"
            message={(
              <div className={`${mainCssClass}__message`}>
                <span>Не удалось определить роль сотрудника.</span>
                <span>Обратитесь в поддержку.</span>
              </div>
            )}
            icon={<div className={`${mainCssClass}__icon`} />}
          />
        )}
        <div className={`${mainCssClass}__homepage`}>
          <ControlButton typeButton="primary" size="large" icon="none" className="button" onClick={handleReturn}>
            Вернуться на главную
          </ControlButton>
        </div>
      </div>
    </div>
  )
}
ErrorPage.displayName = 'ErrorPage'

export default memo<TProps>(withErrorBoundary<TProps>(ErrorPage))
