import { ModalProps, Tooltip, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useState } from 'react'
import AddPlusSvg from '../../../../common/svg/AddPlusSvg'
import { getCssClassName } from '../../../../common/utils/generateClassName'

import {
  openVacancyModal, openRecruiterModal, openReseacherModal, setAssignModal,
  updateCandidate,
  removeRequestLink,
  appointRecruiter,
  appointReseacher
} from '../../actions'
import {
  selectOpenVacancyModal, selectAssignModal, selectCandidate, selectOpenRecriuterModal, selectOpenReseacherModal
} from '../../selectors'
import AssignVacancyModal from '../../../../features/candidateModals/components/AssignVacancyModal'
import EditPencil1 from '../../../../common/svg/EditPencil1'
import CloseMD from '../../../../common/svg/CloseMD'
import { setOpenModalEditVacancy } from '../../../ManagementVacancies/actions'
import { setSelectedRequest } from '../../../../features/map/actions'
import { setSelectedCandidateId } from '../../../ManagamentCandidates/actions'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import { assignVacancy } from '../../../../features/candidateModals/actions'
import { navigateTo } from '../../../../common/actions/navigate'
import { setPreviousPage } from '../../../../compositions/InnerRouter/actions'
import './CandidateProgress.scss'
import ModalAssiagnApplication from '../ModalAssiagnApplication'
import ModalAppointRecruiter from '../ModalAppointRecruiter'
import ModalAppointResearcher from '../ModalAppointResearcher'

const MainCSSClass = getCssClassName('candidateProgress')

function CandidateProgress() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const vacancyModal = useSelector(selectOpenVacancyModal)
  const researcherModal = useSelector(selectOpenReseacherModal)
  const recriuterModal = useSelector(selectOpenRecriuterModal)
  const [removeRequest, setRemoveRequest] = useState(false)
  const assignModal = useSelector(selectAssignModal)
  const {
    rbacControl, has_active_interview, isCanAppointRecruiter, department, role
  } = useEntityContext()

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`
    }
    return text
  }
  const handleClickOpenVacancy = () => {
    dispatch(openVacancyModal(true))
  }

  const handleOpenResearcherClick = () => {
    dispatch(openReseacherModal(true))
  }

  const handleOpenRecruiterClick = () => {
    dispatch(openRecruiterModal(true))
  }

  const handleEditVacancyClick = () => {
    dispatch(openVacancyModal(true))
  }

  const handleEditRequestClick = (request) => {
    setSelectedRequest(request)
    dispatch(setAssignModal(true))
  }

  const handleEditResearcherClick = () => {
    dispatch(openReseacherModal(true))
  }

  const closeAppointResearcher = useCallback(() => {
    dispatch(openReseacherModal(false))
  }, [dispatch])

  const handleCloseAssignVacancy = useCallback(() => {
    dispatch(openVacancyModal(false))
  }, [dispatch])

  const handleOkAssignApplication = () => {
    dispatch(setAssignModal(false))
  }

  const closeAppointRecruiter = () => {
    dispatch(openRecruiterModal(false))
  }

  const handlerRemoveRequestClick = () => {
    dispatch(setAssignModal(true))
  }

  const closeAssignModal = () => {
    dispatch(setAssignModal(false))
  }

  const handleOpenRecruiter = () => {
    if (isCanAppointRecruiter) {
      dispatch(openRecruiterModal(true))
    }
  }

  const deleteCandidateKey = (key : string) => {
    if (candidate) {
      switch (key) {
      case 'request':
        dispatch(removeRequestLink({ candidate_id: candidate?.id, request_id: candidate?.request_id }))
        break
      case 'vacancy':
        dispatch(assignVacancy({ id: candidate.id, vacancy_id: null }))
        break
      case 'responsible':
        dispatch(appointRecruiter({ id: candidate.id, responsible: null }))
        break
      case 'researcher':
        dispatch(appointReseacher({ id: candidate.id, researcher: null }))
        break
      default:
        break
      }
    }
  }

  const handleClickNavigateRequest = () => {
    if (candidate?.request_id) {
      dispatch(navigateTo(`/application/${candidate.request_id}`))
      dispatch(setPreviousPage({ navigate: `/candidate/${candidate.id}`, caption: 'Карточка кандидата' }))
    }
  }

  return (
    <div className={MainCSSClass}>
      <div className={`${MainCSSClass}_actions`}>
        <div className={`${MainCSSClass}_actions_panel`}>
          <Typography.Text className={`${MainCSSClass}_actions_panel_group-button`}>
            Вакансия:&nbsp;&nbsp;
          </Typography.Text>
          {candidate?.vacancy_list?.length ? (
            <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`} title={candidate.vacancy_list[0].label}>
              {candidate.vacancy_list[0].label}

              { role?.role_id !== 'courier_curator'
                            && (
                              <>
                                <EditPencil1
                                  className={`${MainCSSClass}_actions_panel_appoint_svg`}
                                  onClick={handleEditVacancyClick}
                                  color="#2864BE"
                                />
                                <CloseMD
                                  className={`${MainCSSClass}_actions_panel_appoint_svg`}
                                  onClick={() => deleteCandidateKey('vacancy')}
                                  color="#2864BE"
                                />
                              </>
                            )}

            </Typography.Text>
          ) : (
            role?.role_id !== 'courier_curator'
            && (
              <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`} onClick={handleClickOpenVacancy}>
                <AddPlusSvg fill="#2864BE" className={`${MainCSSClass}_actions_panel_appoint_svg`} />
                Назначить вакансию
              </Typography.Text>
            )
          )}
        </div>
        <div className={`${MainCSSClass}_actions_panel`}>
          <Typography.Text className={`${MainCSSClass}_actions_panel_group-button`}>
            Заявка:&nbsp;&nbsp;
          </Typography.Text>
          {candidate?.request_list?.label ? (
            <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`}>
              <div onClick={handleClickNavigateRequest}>
                {candidate.request_list.label}
              </div>
              {role?.role_id !== 'courier_curator'

              && (
                <>
                  <EditPencil1
                    onClick={() => handleEditRequestClick(candidate.request_list)}
                    color="#2864BE"
                  />
                  <CloseMD
                    onClick={() => deleteCandidateKey('request')}
                    color="#2864BE"
                  />
                </>
              )}

            </Typography.Text>
          ) : (
            role?.role_id !== 'courier_curator'
            && (
              <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`} onClick={handlerRemoveRequestClick}>
                <AddPlusSvg fill="#2864BE" className={`${MainCSSClass}_actions_panel_appoint_svg`} />
                Назначить заявку
              </Typography.Text>
            )
          )}
        </div>
        { department !== 175
        && (
          <div className={`${MainCSSClass}_actions_panel`}>
            <Typography.Text className={`${MainCSSClass}_actions_panel_group-button`}>
              Ресечер:&nbsp;&nbsp;
            </Typography.Text>
            {candidate?.labels.researcher && candidate?.labels.researcher !== '—' ? (
              <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`}>
                {candidate.labels.researcher}
                <EditPencil1 onClick={handleEditResearcherClick} color="#2864BE" />
                <CloseMD onClick={() => deleteCandidateKey('researcher')} color="#2864BE" />
              </Typography.Text>
            ) : (
              <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`} onClick={handleOpenResearcherClick}>
                <AddPlusSvg fill="#2864BE" className={`${MainCSSClass}_actions_panel_appoint_svg`} />
                Назначить ресечера
              </Typography.Text>
            )}
          </div>
        )}

        <div className={`${MainCSSClass}_actions_panel`}>
          <Typography.Text className={`${MainCSSClass}_actions_panel_group-button`}>
            Рекрутер: &nbsp;&nbsp;
          </Typography.Text>
          {candidate?.labels.responsible && candidate?.labels.responsible !== '—' ? (
            <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`}>
              {candidate.labels.responsible}
              {role?.role_id !== 'courier_curator'
                 && (
                   <>
                     <EditPencil1 color="#2864BE" onClick={handleOpenRecruiter} />
                     <CloseMD onClick={() => deleteCandidateKey('responsible')} color="#2864BE" />
                   </>
                 )}
            </Typography.Text>
          ) : (role?.role_id !== 'courier_curator'
            ? (
              <Typography.Text className={`${MainCSSClass}_actions_panel_appoint`} onClick={handleOpenRecruiterClick}>
                <AddPlusSvg fill="#2864BE" className={`${MainCSSClass}_actions_panel_appoint_svg`} />
                Назначить рекрутера
              </Typography.Text>
            ) : null
          )}
        </div>
        {vacancyModal && candidate && (
          <AssignVacancyModal
            open={vacancyModal}
            candidate_id={candidate?.id}
            vacancyId={candidate.vacancy_id}
            vacancyLabel={candidate.vacancy_list.length ? candidate.vacancy_list[0].label : ''}
            onCancel={handleCloseAssignVacancy}
          />
        )}
        {researcherModal && candidate && (
          <ModalAppointResearcher
            open={researcherModal}
            candidate_id={candidate?.id}
            onCancel={closeAppointResearcher}
            researcher={candidate.researcher}
          />
        )}
        {recriuterModal && candidate && (
          <ModalAppointRecruiter
            open={recriuterModal}
            candidate_id={candidate?.id}
            onCancel={closeAppointRecruiter}
            responsible={candidate.responsible}
          />
        )}
        {assignModal && (
          <ModalAssiagnApplication
            candidateId={candidate?.id}
            open={assignModal}
            wrapClassName="wrapModal"
            onCancel={closeAssignModal}
            onOk={handleOkAssignApplication}
          />
        )}
      </div>
    </div>
  )
}

export default CandidateProgress
