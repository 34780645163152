import React, {
  useState, useEffect, useRef, useCallback, memo
} from 'react'
import cn from 'classnames'
import { Dropdown } from 'antd'
import plural from 'plural-ru'
import { DownOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { useDispatch } from 'react-redux'
import { setDeleteFilterByKey, TFilterLabel } from '../../../features/filters/actions'
import './ResponsiveMenu.scss'
import { SmallExit } from '../../svg/SvgFromIconWrapper/SmallExit'

type TProps = {
  items: TFilterLabel[];
  className?: string;
};

const mainCssClass = 'responsive-menu-filters'

const ResponsiveMenuFilters: React.FC<TProps> = ({ items, className }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]) // Массив для хранения рефов элементов меню
  const [visibleItems, setVisibleItems] = useState<TFilterLabel[]>([])
  const [hiddenItems, setHiddenItems] = useState<TFilterLabel[]>([])
  const [checkHiddenItemsOnZero, setCheckHiddenItemsOnZero] = useState<boolean>(false)
  const dispatch = useDispatch()

  const getValueString = (value: string[]) => {
    if (value.length > 1) {
      return `Знач.  ${value.length}`
    }
    return value[0]
  }

  // Функция для расчета видимых и скрытых элементов меню
  const adjustMenuItems = useCallback((changeChekHiddenItemsOnZero?: Function) => {
    if (changeChekHiddenItemsOnZero) {
      changeChekHiddenItemsOnZero()
    }
    requestAnimationFrame(() => {
      const containerWidth = containerRef.current?.offsetWidth - 60 || 0
      let totalWidth = 0
      const visible: TFilterLabel[] = []
      const hidden: TFilterLabel[] = []

      items.forEach((item, index) => {
        totalWidth += itemRefs.current[index]?.offsetWidth || 0 // Получаем ширину элемента через реф
        if (totalWidth <= containerWidth) {
          visible.push(item)
        } else {
          hidden.push(item)
        }
      })

      setVisibleItems(visible)
      setHiddenItems(hidden)
    })
  }, [items])

  // Устанавливаем рефы и запускаем измерение после полной отрисовки
  useEffect(() => {
    itemRefs.current = items.map((_, i) => itemRefs.current[i] || null)
  }, [items, adjustMenuItems])

  // Подписка на изменения размеров контейнера
  useEffect(() => {
    const resizeObserver = new ResizeObserver(debounce(() => {
      adjustMenuItems()
    }, 100))

    if (containerRef?.current) {
      resizeObserver.observe(containerRef.current)
    }

    // adjustMenuItems() // Выполняем измерение сразу после рендера

    return () => {
      resizeObserver.disconnect()
    }
  }, [items, adjustMenuItems])

  useEffect(() => {
    if (hiddenItems.length === 0 && !checkHiddenItemsOnZero) {
      const changeChekHiddenItemsOnZero = () => {
        setCheckHiddenItemsOnZero(true)
      }
      adjustMenuItems(changeChekHiddenItemsOnZero)
    }
    if (hiddenItems.length > 0) {
      setCheckHiddenItemsOnZero(false)
    }
  }, [hiddenItems, checkHiddenItemsOnZero])

  const handleDeleteFilter = (key: string) => {
    dispatch(setDeleteFilterByKey(key))
  }
  const handleDeleteByKey = (e: any) => {
    dispatch(setDeleteFilterByKey(e.key))
  }

  const menuProps = {
    items: hiddenItems.map((item, index) => ({
      key: item.key,
      label: (
        <div className={`${mainCssClass}__label`}>
          {item.label}
          :
          &nbsp;
          {getValueString(item.value)}
          <SmallExit className={`${mainCssClass}__small-icon`} onClick={() => handleDeleteFilter(item.key)} />
        </div>
      )
    }))
  }

  return (
    <div className={cn(mainCssClass, className)} ref={containerRef}>
      {visibleItems?.map((item, index) => (
        <div
          key={index}
          ref={(el) => { itemRefs.current[index] = el }} // Присваиваем реф элементу
          className={`${mainCssClass}__label`}
        >
          {`${item.label}:  ${getValueString(item.value)}`}
          <SmallExit className={`${mainCssClass}__small-icon`} onClick={() => handleDeleteFilter(item.key)} />
        </div>
      ))}
      {hiddenItems.length > 0 && (
        <Dropdown menu={menuProps}>
          <div className={`${mainCssClass}__label`}>
            <span>
              Еще:&nbsp;
              {`${hiddenItems.length}`}
              &nbsp;
              {plural(hiddenItems.length, 'фильтр', 'фильтра', 'фильтров')}
              &nbsp;&nbsp;
              <DownOutlined />
            </span>
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default memo(ResponsiveMenuFilters)
