import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'

import { getCssClassName } from '../../../../common/utils/generateClassName'
import ChatConversation from '../../../../common/svg/ChatConversation'
import PhoneSvg from '../../../../common/svg/PhoneSvg'
import MailSvg from '../../../../common/svg/MailSvg'
import './CandidateCommunication.scss'
import { openAsteriskDrawer, openChatDrawer } from '../../actions'
import ChatDrawer from '../chat/ChatDrawer'
import { selectAsteriskDrawer, selectChatDrawer } from '../../selectors'
import AsteriskDrawer from '../asteriskDrawer/AsteriskDrawer'

const MainCSSClass = getCssClassName('candidateCommunication')

function CandidateCommunication({ isMobile } : {isMobile : boolean}) {
  const dispatch = useDispatch()
  const chatDrawer = useSelector(selectChatDrawer)
  const asteriskDrawer = useSelector(selectAsteriskDrawer)

  const handleClickOpenChat = () => {
    dispatch(openChatDrawer(true))
  }

  const handleClickOpenAsterisk = () => {
    dispatch(openAsteriskDrawer(true))
  }

  return (
    <div className={MainCSSClass}>
      <Typography.Text className={`${MainCSSClass}__communication-buttons_text`}>
        Коммуникация:
      </Typography.Text>
      <div className={`${MainCSSClass}__communication-buttons_item`} onClick={handleClickOpenChat}>
        <ChatConversation color="#262A28" />
        Чат с кандидатом
      </div>
      {/* <div className={`${MainCSSClass}__communication-buttons_item`}>
          <MailSvg />
          Почта с кандидатом
        </div> */}
      <div className={`${MainCSSClass}__communication-buttons_item`} onClick={handleClickOpenAsterisk}>
        <PhoneSvg fill="#262A28" />
        Звонки
      </div>

      <ChatDrawer open={chatDrawer} isMobile={isMobile} />
      <AsteriskDrawer open={asteriskDrawer} isMobile={isMobile}/>

      {/* <ControlButton typeButton="secondary" className={`button ${MainCSSClass}__communication-buttons_panel`}> */}
      {/*   <ChatCircleCheck color="#2DBE64" /> */}
      {/*   Согласования */}
      {/* </ControlButton> */}
    </div>
  )
}

export default CandidateCommunication
