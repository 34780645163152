import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import cn from 'classnames'
import './ModalDetails.scss'
import { useDispatch, useSelector } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectLabelById,
  selectOpenDetailsModal
} from '../selectors'
import { TClassName } from '../../../common/types/props'
import { TRequest } from '../../../common/api/request'
import { setOpenDetailsModal } from '../actions'
import { DetailsList } from '../../../common/components/DetailsList'
import { selectDictionaryOptionsByName } from '../../../compositions/Request/selectors'

const mainCssClass = 'details'
type TProps = {
  request: TRequest
  isEdit?: boolean
} & TClassName
function ModalDetails({
  className,
  request
}: TProps) {
  const open = useSelector(selectOpenDetailsModal)
  const dispatch = useDispatch()

  const handleClose = useCallback(() => dispatch(setOpenDetailsModal(false)), [])
  const candidate_characteristic_id = useSelector(selectLabelById(request?.candidate_characteristic_id, 'requestCandidateCharacteristic'))
  const characteristic_dictionary = useSelector(selectDictionaryOptionsByName('requestCandidateCharacteristic'))
  const characteristic_list = useMemo(() => characteristic_dictionary.filter((item) => request?.characteristic_list?.includes(item.value))?.map((item) => item.label) || [], [request, characteristic_dictionary])
  const arrayDetails = useMemo(() => [
    { label: 'Заказчик', value: request?.user?.customer?.length ? request?.user?.customer[0]?.label : '' },
    { label: 'Контакт заказчика', value: request?.user?.customer?.length ? request?.user?.customer[0]?.phone : '' },
    { label: 'Комментарий', value: request?.description || '-' },
    { label: 'Средняя зп в день на точке', value: `${request?.reward_average_day || '-'}` },
    { label: 'Средняя зп в месяц на точке', value: `${request?.reward_average_month || '-'}` },
    { label: 'Подходят студенты', value: `${request?.is_student ? 'да' : 'нет'}` },
    { label: 'Можно с патентом', value: `${request?.is_patent ? 'да' : 'нет'}` },
    { label: 'Нужна ознакомительная стажировка', value: `${request?.is_internship ? `да\n${request.address_internship}` : 'нет'}` },
    { label: 'Особенности кандидата', value: `${characteristic_list ? `${characteristic_list.join(',')}` : '-'}` }

  ], [request, candidate_characteristic_id])
  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__body-container`}>
        <div className={`${mainCssClass}__container-details`}>
          <DetailsList
            size="xs"
            className={`${mainCssClass}__details-list`}
            items={[{ items: arrayDetails }]}
          />
        </div>
      </div>
    </div>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalDetails))
