import { PayloadAction } from '@reduxjs/toolkit'
import {
  call, put, race, takeLatest, take,
  select
} from 'redux-saga/effects'
import { AxiosError } from 'axios'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  TRequestRequestsCSV,
  exportCSV,
  getDictionaries,
  getRequests,
  getSelectedRequest,
  setDictionaries,
  setHeaders,
  setLoading,
  setRequests,
  setSelectedRequest,
  setCurators,
  getCurators,
} from './actions'
import {
  fetchGetCurators,
  fetchGetRequest, fetchGetRequests, fetchPostCreateRequestsCSV
} from '../../common/api/request'
import { TPaginationRequest } from '../../common/types/pagination'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { setLoadingProgress } from '../InnerRouter/actions'

const moduleName = 'Заявки таблица'
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'заявки таблица')
}
function* getRequestsSaga({ payload }:PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: call(fetchGetRequests, payload),
      cancel: take(getRequests.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequests(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TRequestRequestsCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))

    const { response } = yield race({
      response: fetchPostCreateRequestsCSV(payload),
      cancel: take(exportCSV.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить CSV',
          error: response as AxiosError
        })
      )
    } else {
      // const blob = new Blob([response.data], { type: 'text/csv' })
      // const url = window.URL.createObjectURL(blob)
      // const link = document.createElement('a')
      // link.href = url
      // link.download = 'filename'
      // link.click()
      // window.URL.revokeObjectURL(url)

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* selectedRequestSaga({ payload } : PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchGetRequest(payload), cancel: take(getSelectedRequest.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setSelectedRequest(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* getCuratorsSaga() {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchGetCurators(), cancel: take(getCurators.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      const curators = response.data.DATA.map((item) => ({ label: item.curator_label, value: item.id }))
      yield put(setCurators(curators))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

export function* managementRequestsSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getRequests, getRequestsSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
  yield takeLatest(getSelectedRequest, selectedRequestSaga)
  yield takeLatest(getCurators, getCuratorsSaga)
}
