import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import ButtonsTable from './ButtonsTable'
import Table from '../../../common/components/table/Table'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ParsingStatus from './ParsingStatus'
import './ParsingTable.scss'

const mainCssClass = 'parsing-table'

type TProps = {
  parsingData: any []
  handleOpenModal: Function
} & TClassName
type TRowData = {
  name: JSX.Element | string
  status: JSX.Element
  active: string
  processed: string
  failed: JSX.Element | string
  successed: string
  buttons: JSX.Element
  id: string | number
  returned:string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'name',
      Header: 'Название'
    },
    { accessor: 'status', Header: 'Статус' },
    { accessor: 'active', Header: 'Активные' },
    { accessor: 'processed', Header: 'Обработанные' },
    { accessor: 'failed', Header: 'Неудачные' },
    { accessor: 'successed', Header: 'Успешные' },
    { accessor: 'returned', Header: 'Повторных попыток' },
    { accessor: 'buttons', Header: '' }

  ],
  []
)

const useRowsData = (parsingData: any[], handleOpenModal: Function) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row: any) => {
    const {
      original: { id }
    } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (parsingData?.length
      ? parsingData.map<TRowData>(({
        name, text, id, status, activeNumber, processedNumber, successedNumber, failedNumber, returnedNumber
      }) => ({
        name,
        status: <ParsingStatus status={status} text={text} />,
        active: activeNumber,
        processed: processedNumber,
        failed: failedNumber,
        successed: successedNumber,
        returned: returnedNumber,
        buttons: <ButtonsTable id={id} handleOpenModal={handleOpenModal} />,
        id
      }))
      : []),
    [parsingData]
  )
  return { rows, handleRowClick }
}
const ParsingTable: FC<TProps> = ({ className, parsingData, handleOpenModal }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(parsingData, handleOpenModal)

  return (
    <Table
      className={cn(className, mainCssClass)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ParsingTable))
