import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useFieldArray, useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CloseMD from '../../../common/svg/CloseMD'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectResume } from '../selectors'
import { createCetrificateResume } from '../actions'
import './ResumeModal.scss'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  certificate_list: { name: string; number: string; link: string }[]
}

function ModalCertificates({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)

  const defaultValues = useMemo(
    () => (editMode && resume?.certificate_list && resume?.certificate_list.length > 0
      ? {
        certificate_list: resume?.certificate_list.map((e) => ({
          name: e.name,
          number: e.number,
          link: e.link
        }))
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray<TFormValues, 'certificate_list'>({
    control,
    name: 'certificate_list'
  })

  const onSubmit = useCallback(() => {
    dispatch(
      createCetrificateResume({
        resume_id: resumeId,
        certificate_list: getValues().certificate_list.map((el) => ({ ...el, resume_id: resumeId }))
      })
    )
  }, [])

  if (fields?.length === 0) {
    prepend({ name: '', link: '', number: '' })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Cертификаты"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Сохранить"
      centered
      maskClosable={false}
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_list`}>
        {fields?.map((certificate, index) => (index === 0 ? (
          <div className={`${mainCssClass}_items`}>
            <InputFormControl
              label="Организация"
              name={`certificate_list[${index}].name`}
              control={control}
              rules={{ required: true }}
            />
            <InputFormControl
              label="Сертификат"
              name={`certificate_list[${index}].number`}
              control={control}
              rules={{ required: true }}
            />
            <InputFormControl
              label="Ссылка"
              name={`certificate_list[${index}].link`}
              control={control}
              rules={{ required: true }}
            />
          </div>
        ) : (
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_dop`}>
              <InputFormControl
                label="Организация"
                name={`certificate_list[${index}].name`}
                control={control}
                rules={{ required: true }}
              />
              <Button className={`${mainCssClass}_items_dop_close`} onClick={() => handleRemove(index)}>
                <CloseMD />
              </Button>
            </div>

            <InputFormControl
              label="Сертификат"
              name={`certificate_list[${index}].number`}
              control={control}
              rules={{ required: true }}
            />
            <InputFormControl
              label="Ссылка"
              name={`certificate_list[${index}].link`}
              control={control}
              rules={{ required: true }}
            />
          </div>
        )))}

        <Button onClick={() => append({ name: '', link: '', number: '' })} className={`${mainCssClass}_items_add`}>
          <AddPlusSvg />
          Добавить сертификат
        </Button>
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalCertificates))
