import cn from 'classnames'

import '../Typography.scss'

import { InternalText, InternalTextType } from './InternalText'
import { TextProps } from './Text'

interface Title extends InternalTextType {
  Lg: typeof Lg;
  Md: typeof Md;
  Sm: typeof Sm;
}

export const Title: Title = InternalText as Title

Title.Lg = Lg
Title.Md = Md
Title.Sm = Sm

function Lg({ className, ...props }: TextProps) {
  return <InternalText tag="span" className={cn('titleLg', className)} {...props} />
}

function Md({ className, ...props }: TextProps) {
  return <InternalText tag="span" className={cn('titleMd', className)} {...props} />
}

function Sm({ className, ...props }: TextProps) {
  return <InternalText tag="span" className={cn('titleSm', className)} {...props} />
}
