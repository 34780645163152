import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

function AvatarSizeSmall({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_157)">
        <rect width="36" height="36" rx="18" fill="#E8ECE9" />
        <path d="M7.86209 26.2117C8.45088 24.0179 10.4393 22.4928 12.7108 22.4928H13.1332C14.0205 22.4928 14.8804 22.8001 15.5666 23.3626C16.9816 24.5224 19.0184 24.5224 20.4334 23.3626C21.1196 22.8001 21.9795 22.4928 22.8668 22.4928H23.2892C25.5607 22.4928 27.5491 24.0179 28.1379 26.2117L31.125 37.3415H4.875L7.86209 26.2117Z" fill="#D5DBD6" />
        <path d="M12.665 13.858C12.4679 10.7776 14.9132 8.17073 17.9999 8.17073C21.0867 8.17073 23.532 10.7776 23.3349 13.858L23.1477 16.7826C22.9739 19.498 20.7209 21.6114 17.9999 21.6114C15.279 21.6114 13.026 19.498 12.8522 16.7826L12.665 13.858Z" fill="#D5DBD6" />
      </g>
      <defs>
        <clipPath id="clip0_8_157">
          <rect width="36" height="36" rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}
export default AvatarSizeSmall
