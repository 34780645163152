import React, { FC, memo, useMemo } from 'react'
import { Typography } from 'antd'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './StatusInterviewTableRow.scss'
import { prepareStatusInterview } from '../../InterviewCard/utils'
import { useSelector } from 'react-redux'
import { selectDictionaryOptionsByName } from '../selectors'

type TProps = {
  status: number
}
const mainCssClass = 'request-name-table'
const StatusInterviewTableRow: FC<TProps> = ({ status }) => {
  const interviewStatus = useSelector(selectDictionaryOptionsByName('statusResult'))
const statusStr = prepareStatusInterview(status,interviewStatus)

  return (
    <div className={mainCssClass}>
      <Typography.Text>{statusStr}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(StatusInterviewTableRow))
