import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName, selectResume } from '../selectors'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import { createSkillsResume } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: (data: any) => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  skill_list: (number | string)[]
}

function ModalSkillsCandidate({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const skillsOption = useSelector(selectDictionaryOptionsByName('keySkills'))

  const defaultValues = useMemo(
    () => (editMode && resume?.skill_list && resume?.skill_list.length > 0
      ? {
        skill_list: resume?.skill_list.map((e) => e.dictionary_option_id)
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = useCallback(() => {
    dispatch(createSkillsResume({ resume_id: resumeId, ...getValues() }))
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      maskClosable={false}
      title="Ключевые навыки"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <SelectFormControlMult name="skill_list" options={skillsOption} control={control} />
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalSkillsCandidate))
