/* eslint-disable guard-for-in */
import React, {
  memo,
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { Typography, Input, Tooltip } from 'antd'
import { useParams } from 'react-router-dom'
import CloseMD from '../../../common/svg/CloseMD'
import {
  filterObject,
  objectToQueryString,
  timeOptions
} from '../utils'
import { getCssClassName } from '../../../common/utils/generateClassName'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import Search from '../../../common/svg/Search'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import {
  getDictionaries,
  openProductivityRecruiterFilter,
  openProductivityResearcherFilter,
  setResearcherFilterString
} from '../actions'

import Redo from '../../../common/svg/Redo'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import './ProductivityFilter.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveTab, selectDictionaryOptionsByName } from '../selectors'
import {
  fetchRecruiterList, fetchRequestList, fetchResearcherList, fetchVacancyList, filteredOptions,
} from '../../../features/filters/utils'

export type TProductivityFilter = {
      list_priority: number[]
      list_responsible: number[]
      list_researcher: number[]
      list_vacancy: number[]
      list_request: number[]
      list_group: number[]
      list_result: number[]
      list_status: number[]
      date_interview: string[] | null
      time_interview: string[] | null
      list_characteristic: number[]
      date_registration: string[] | null
    }

const mainCssClass = getCssClassName('productivity-filters')

function ProductivityResearcherFilter() {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)
  const { pageId } = useParams()

  const defaultValues = useMemo(() => {
    if (pageId && activeTab === 'researcher') {
      try {
        return JSON.parse(localStorage.getItem('defproductivityres')) || {};
      } catch (parseError) {
        console.error('Error parsing defproductivityres:', parseError);
        return {};
      }
    }
    return {};
  }, [pageId, activeTab]);

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TProductivityFilter>({ defaultValues })

  const [allModal, setAllModal] = useState<any>(filterObject)
  const [priorityInput, setPriorityInput] = useState('')
  const [groupInput, setGroupInput] = useState('')
  const [characteristicInput, setCharacteristicInput] = useState('')
  const [interviewResultInput, setInterviewResultInput] = useState('')
  const [timeInput, setTimeInput] = useState('')
  const [statusInterviewInput, setStatusInterviewInput] = useState('')

  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const characteristicOptions = useSelector(selectDictionaryOptionsByName('candidateCharacteristic'))
  const interviewStatusOptions = useSelector(selectDictionaryOptionsByName('statusResult'))
  const interviewResultOptions = useSelector(selectDictionaryOptionsByName('interviewResult'))

  // const timeOptions = useSelector(selectTimeOptions)

  const handleApply = useCallback(() => {
    localStorage.setItem('defproductivityres', JSON.stringify(getValues()))
    localStorage.setItem('productivityres', objectToQueryString(getValues()))
    dispatch(setResearcherFilterString(objectToQueryString(getValues())))
  }, [])

  const handleAllReset = useCallback(() => {
    const mapValues = getValues()
    if (pageId) {
      localStorage.removeItem('defproductivityres')
      localStorage.removeItem('productivityres')
    }
    if (defaultValues) {
      // eslint-disable-next-line prefer-const
      for (let key in mapValues) {
        if (key === 'date_interview') {
          setValue(key, null)
        } else {
          setValue(key, [])
        }
      }
    } else {
      reset({})
    }
  }, [defaultValues, pageId])

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleCloseFilters = useCallback(() => {
    dispatch(openProductivityResearcherFilter(false))
  }, [])

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  const handlePriorityInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPriorityInput(e.target.value)
  }

  const handleInterviewResultInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInterviewResultInput(e.target.value)
  }

  const handleCharacteristicInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCharacteristicInput(e.target.value)
  }

  const handleTimeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTimeInput(e.target.value)
  }

  const handleStatusInterviewInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusInterviewInput(e.target.value)
  }

  const resetArrayByKey = (key: string) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: [] }
    localStorage.setItem('defproductivityres', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('productivityres', objectToQueryString(valuesWithoutDate))

    dispatch(setResearcherFilterString(objectToQueryString(valuesWithoutDate)))
    setValue(key, [])
  }

  const resetDateValue = (str: string) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, str: null }
    localStorage.setItem('defproductivityres', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('productivityres', objectToQueryString(valuesWithoutDate))
    dispatch(setResearcherFilterString(objectToQueryString(getValues())))
    setValue(str, null)
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'yes_no',
        'priority',
        'vacancyStatus',
        'statusResult',
        'interviewResult',
        'group',
        'candidateCharacteristic',
        'candidateStatus'
      ])
    )
  }, [])

  useEffect(() => {
    reset(defaultValues)
  }, [pageId])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={handleCloseFilters}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Время проведения
                  {watch('time_interview') && watch('time_interview').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      1
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('time_interview') && watch('time_interview').length > 0 && <Redo onClick={() => resetArrayByKey('time_interview')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('time')}>
                {allModal.group ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.time && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={timeInput}
                    onChange={handleTimeInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(timeOptions, timeInput)}
                    control={control}
                    name="time_interview"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>По дате интервью</Typography.Title>
                {watch('date_interview') && watch('date_interview')?.length > 0 && (
                  <Redo onClick={() => resetDateValue('date_interview')} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('dateInterview')}>
                {allModal.dateInterview ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.dateInterview && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_interview"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>По дате оформления</Typography.Title>
                {watch('date_registration') && watch('date_registration')?.length > 0 && (
                  <Redo onClick={() => resetDateValue('date_registration')} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('dateRegistration')}>
                {allModal.dateRegistration ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.dateRegistration && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_registration"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Вакансия
                  {watch('list_vacancy') && watch('list_vacancy').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_vacancy').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_vacancy') && watch('list_vacancy').length > 0
                              && <Redo onClick={() => resetArrayByKey('list_vacancy')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('vacancyCandidate')}>
                {allModal.vacancyCandidate ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.vacancyCandidate && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_vacancy"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Вакансия"
                  fetchOptions={fetchVacancyList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Группы
                  {watch('list_group') && watch('list_group').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_group').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_group') && watch('list_group').length > 0
                              && <Redo onClick={() => resetArrayByKey('list_group')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('group')}>
                {allModal.group ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>

            </div>
            {allModal.group && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={groupInput}
                    onChange={handleGroupInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(groupOptions, groupInput)}
                    control={control}
                    name="list_group"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Заявка
                  {watch('list_request') && watch('list_request').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_request').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_request') && watch('list_request').length > 0
                              && <Redo onClick={() => resetArrayByKey('list_request')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('requestContent')}>
                {allModal.requestContent ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.requestContent && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_request"
                  control={control}
                  placeholder="Поиск по наименованию..."
                  mode="multiple"
                  label="Заявка"
                  fetchOptions={fetchRequestList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный рекрутер
                  {watch('list_responsible') && watch('list_responsible').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_responsible').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_responsible') && watch('list_responsible').length > 0
                                && <Redo onClick={() => resetArrayByKey('list_responsible')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('recruiter')}>
                {allModal.recruiter ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.recruiter && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_responsible"
                  control={control}
                  mode="multiple"
                  label="Рекрутер"
                  placeholder="Поиск по имени..."
                  notFoundContent="Начните вводить имя"
                  fetchOptions={fetchRecruiterList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Ответственный ресечер
                  {watch('list_researcher') && watch('list_researcher').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_researcher').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_researcher') && watch('list_researcher').length > 0
                                && <Redo onClick={() => resetArrayByKey('list_researcher')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('researcher')}>
                {allModal.researcher ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.researcher && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="list_researcher"
                  notFoundContent="Начните вводить имя"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по имени..."
                  label="Ресечер"
                  fetchOptions={fetchResearcherList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Приоритет
                  {watch('list_priority') && watch('list_priority').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_priority').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_priority') && watch('list_priority')?.length > 0 && <Redo onClick={() => resetArrayByKey('list_priority')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('priority')}>
                {allModal.priority ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.priority && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={priorityInput}
                    onChange={handlePriorityInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(priorityOptions, priorityInput)}
                    control={control}
                    name="list_priority"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Результат
                  {watch('list_result') && watch('list_result').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_result').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_result') && watch('list_result').length > 0 && <Redo onClick={() => resetArrayByKey('list_result')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('interviewResult')}>
                {allModal.interviewResult ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.interviewResult && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={interviewResultInput}
                    onChange={handleInterviewResultInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(interviewResultOptions, interviewResultInput)}
                    control={control}
                    name="list_result"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Статус
                  {watch('list_status') && watch('list_status').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_status').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_status') && watch('list_status').length > 0
                          && <Redo onClick={() => resetArrayByKey('list_status')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('statusCandidate')}>
                {allModal.statusCandidate ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.statusCandidate && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={statusInterviewInput}
                    onChange={handleStatusInterviewInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(interviewStatusOptions, statusInterviewInput)}
                    control={control}
                    name="list_status"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Характеристика
                  {watch('list_characteristic') && watch('list_characteristic').length >= 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('list_characteristic').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('list_characteristic') && watch('list_characteristic').length > 0 && <Redo onClick={() => resetArrayByKey('list_characteristic')} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('characteristic')}>
                {allModal.characteristic ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.characteristic && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={characteristicInput}
                    onChange={handleCharacteristicInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(characteristicOptions, characteristicInput)}
                    control={control}
                    name="list_characteristic"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>
        </div>
      </NewScrollContainer>
    </div>
  )
}
export default memo(withErrorBoundary(ProductivityResearcherFilter))
