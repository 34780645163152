/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  setCandidate,
  setLoading,
  setDictionaries,
  setInterview,
  setHistoryData,
  setHandleModalEditOpen,
  setSelectedInterviewId,
  setHandleModalCancelOpen,
  setHandleModalEndOpen,
  setHandleModalHistoryOpen,
  setResumeList,
  setActiveResume,
  reset, setQuestionnaire, setIsEditModeQuestionnaire, setHandleModalQuestionnaireHistoryOpen,
  setCandidateQuestionnaire
} from './actions'
import { TDATA } from '../candidate/types'
import { TDictionaries } from '../../common/types/dictionaries'
import { TInterview, TInterviewHistory } from '../../common/api/interviews'
import { TResume } from '../Resume'

export type TInterviewInitialState = {
  candidate: TDATA | null
  loading: boolean
  dictionaries: TDictionaries | null
  interview: TInterview | null
  historyInterviews: TInterviewHistory[] | null
  editInterview: boolean
  cancelInterview: boolean
  endInterview: boolean
  historyInterviewModalOpen: boolean
  historyQuestionnaireInterviewModalOpen: boolean
  resume_list: null | TResume[]
  resume: TResume | null
  selectedInterviewId: number | null
  questionnaire: any
  isEditQuestionnaire: boolean
  candidateQuestionnaire:{ answers: { question_id: number; answer: string; id: number; question: string }[] } | null
}
const initialState: TInterviewInitialState = {
  candidate: null,
  loading: false,
  dictionaries: null,
  interview: null,
  historyInterviews: null,
  editInterview: false,
  cancelInterview: false,
  endInterview: false,
  historyInterviewModalOpen: false,
  historyQuestionnaireInterviewModalOpen: false,
  resume_list: null,
  resume: null,
  selectedInterviewId: null,
  questionnaire: null,
  isEditQuestionnaire: false,
  candidateQuestionnaire: null

}

const interviewReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCandidate, (state, { payload }) => {
      state.candidate = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setInterview, (state, { payload }) => {
      state.interview = payload
    })
    .addCase(setHandleModalEditOpen, (state, { payload }) => {
      state.editInterview = payload
    })
    .addCase(setHandleModalCancelOpen, (state, { payload }) => {
      state.cancelInterview = payload
    })
    .addCase(setSelectedInterviewId, (state, { payload }) => {
      state.selectedInterviewId = payload
    })
    .addCase(setResumeList, (state, { payload }) => {
      state.resume_list = payload
    })
    .addCase(setActiveResume, (state, { payload }) => {
      state.resume = payload
    })
    .addCase(setHandleModalEndOpen, (state, { payload }) => {
      state.endInterview = payload
    })
    .addCase(setHistoryData, (state, { payload }) => {
      state.historyInterviews = payload
    })
    .addCase(setHandleModalHistoryOpen, (state, { payload }) => {
      state.historyInterviewModalOpen = payload
    })
    .addCase(setHandleModalQuestionnaireHistoryOpen, (state, { payload }) => {
      state.historyQuestionnaireInterviewModalOpen = payload
    })
    .addCase(setQuestionnaire, (state, { payload }) => {
      state.questionnaire = payload
    })
    .addCase(setIsEditModeQuestionnaire, (state, { payload }) => {
      state.isEditQuestionnaire = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setCandidateQuestionnaire, (state, { payload }) => {
      state.candidateQuestionnaire = payload
    })
})

export default interviewReducer
