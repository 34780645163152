import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ managementVacancies }: TRootState) => managementVacancies

export const selectVacancies = createSelector(selectState, ({ vacancies }) => (vacancies ? vacancies?.vacancy_list : []))

export const selectResultVacanciesCount = createSelector(selectState, ({ vacancies }) => {
  const { count } = vacancies?.counters?.find((item) => item.id === 'result') || {}
  return count || 0
})

export const selectActualVacanciesCount = createSelector(selectState, ({ vacancies }) => {
  const { count } = vacancies?.counters?.find((item) => item.id === 'actual') || {}
  return count || 0
})

export const selectMineVacanciesCount = createSelector(selectState, ({ vacancies }) => {
  const { count } = vacancies?.counters?.find((item) => item.id === 'archive') || {}
  return count || 0
})

export const selectOpenModelChangeStatus = createSelector(selectState, ({ changeStatus }) => changeStatus)

export const selectDuplicateStatusOpen = createSelector(selectState, ({ duplicateStatus }) => duplicateStatus)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectSelectedId = createSelector(selectState, ({ selectedVacancyId }) => selectedVacancyId)

export const selectSelectedVacancyEdit = createSelector(selectState, ({ vacancy }) => vacancy)

export const selectSelectedVacancy = createSelector(selectSelectedId, selectVacancies, (selectedVacancyId, vacancies) => vacancies?.find((item) => item.id === selectedVacancyId))

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

// export const selectVacancyArchiveOpen = createSelector(selectState, ({ archiveOpen }) => archiveOpen)

export const selectEditVacancyModal = createSelector(selectState, ({ editVacancyModal }) => editVacancyModal)

export const selectDefString = createSelector(selectState, ({ defString }) => defString)

export const selectDefDataVacancy = createSelector(selectState, ({ defDataVacancy }) => defDataVacancy)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)