import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import './NewEntryModal.scss'
import withErrorBoundary from '../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../common/components/CommonModal/CommonModal'
import InputFormControl from "../../common/components/formcontrol/InputFormControl";

const mainCssClass = 'new-entry-modal'

type TProps = {
    candidateId?: number | null | string
} & ModalProps
function NewEntryModal({
  onOk, open, className, onCancel, options, candidateId
}: TProps) {
  const dispatch = useDispatch()

  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()
    type TFormValues = {
        status: number
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }
    // const handleSubmit = useCallback(() => {
    //   if (candidateId) {
    //     const data = watch('status')
    //     if (data) {
    //       dispatch(updateStatusCandidate({ id: candidateId, ...getValues(), isCandidateTable }))
    //     }
    //   }
    // }, [candidateId, isCandidateTable])
    //
    // useEffect(() => {
    //   if (isCandidateTable) {
    //     dispatch(getDictionaries(['candidateStatus']))
    //   }
    // }, [])

    useEffect(
      () => () => {
        reset()
      },
      []
    )

    return (
      <CommonModal
        centered
        open={open}
        onOk={onOk}
        onCancel={handleModalClose}
        title="Добавить запись"
        className={cn(className, mainCssClass)}
        width="400px"
        typeButtonAccept="primary"
        okText="Применить"
        cancelText="Отменить"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >
        <div>
          <InputFormControl control={control} name="article_type" label="Тип статьи" rules={{ required: true }} />
        </div>
        <div>
          <InputFormControl control={control} name="resource" label="Ресурс" rules={{ required: true }} />
        </div>
      </CommonModal>
    )
}

export default memo<TProps>(withErrorBoundary<TProps>(NewEntryModal))
