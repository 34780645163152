import { AxiosResponse } from 'axios'
import { TDictionaries, TDictionariesNames } from '../types/dictionaries'
import $api from './http'

export type TResponseDictionaries = {
  ERR: boolean | string
  DATA: TDictionaries
}
export async function fetchGetEmployeesData(): Promise<any> {
  const response: AxiosResponse<any> = await $api.get(
    '/api/v1/asterisk/get-members-data'
  )
  return response
}
