import React, {
  FC, memo, useMemo
} from 'react'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Table from '../../../common/components/table/Table'
import EmployeeStatusTableRow from './EmployeeStatusTableRow'
import './employeesTable.scss'
import EmpoyessTableType from './EmpoyessTableType'

const mainCssClass = 'employees-table'

type TProps = {
  employeesData: any[]
} & TClassName
type TRowData = {
  employee: string
  status: JSX.Element
  type: JSX.Element | string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    { accessor: 'employee', Header: 'Сотрудник' },
    {
      accessor: 'status',
      Header: 'Статус'
    },
    {
      accessor: 'type',
      Header: 'Чем занят'
    }
  ],
  []
)

const useRowsData = (employeesData: any[]) => {
  const rows: TRowData[] = useMemo(
    () => (employeesData?.length
      ? employeesData?.map<TRowData>(
        ({
          fio,
          status_id,
          status,
          type
        }) => ({
          employee: fio,
          status: <EmployeeStatusTableRow status_id={status_id} status={status} />,
          type: type === 'n/a' ? 'Неизвестно' : <EmpoyessTableType type={type}/>
        })
      )
      : []),
    [employeesData]
  )

  return { rows }
}
const EmployeesTable: FC<TProps> = ({ className, employeesData }) => {
  const columns = useColumns()
  const { rows } = useRowsData(employeesData)

  return (
    <Table
      className={cn(mainCssClass, className)}
      withoutDividers={true}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(EmployeesTable))
