/* eslint-disable max-len */
import { AxiosResponse, CancelToken } from 'axios'
import $api from './http'
import { TCalendarResponse } from './calendar'
import { TInternshipRequest, TUpdateInternship, TUpdateInternshipDate } from '../../compositions/internship/actions'

export type TInternshipResponse = {
    ERR: any
    DATA: TSaveInternship
}
export type TSaveInternship = {
    calendar_list : [{
        date:string
        count:number
        items: [{
            id:number
            name:string
            last_name:string
            second_name:string
            phone: number
            address:string
            date_internship:string
            total_count:number
            vacancy_label:string
            request_name:string

        }]
    }]

}

export type TResultInternship = {
    is_came: number
    mentor:string
    registration:number
    comment:string
}
export async function fetchGetInternship(payload, cancelToken?: CancelToken): Promise<AxiosResponse<TInternshipResponse>> {
  const response = await $api.get(`/api/v1/internship/calendar?period=${payload.date}&page=${payload.page + 1}${payload.filter ? payload.filter : ''}${payload.search ? `&search=${payload.search}` : ''}`, { cancelToken })
  return response
}

export async function fetchPatchInternship(payload:TUpdateInternship): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/candidate-internships/${payload.id}`, payload)
  return response
}

export type TPatchIntenrship = {
    date: string, id: number, time: string
}
export async function fetchPatchDateInternship(payload: TPatchIntenrship): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/candidate-internships/${payload.id}`, payload)
  return response
}
