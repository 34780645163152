import { useForm, useFieldArray } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { message, Switch, Upload, UploadProps, DrawerProps } from 'antd'
import cn from 'classnames'
import { PlusOutlined } from '@ant-design/icons'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneInputFormControl from '../../../common/components/formcontrol/PhoneInputFormControl'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import CloseMD from '../../../common/svg/CloseMD'

import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import {
  selectCandidate,
  selectDictionaryOptionsByName,
  selectEmailError,
  selectInnError,
  selectPhoneError,
  selectUpdateCandidateMode
} from '../selectors'
import 'dayjs/locale/ru'
import {
  createCandidate,
  createNewComment,
  getCandidateUpdate,
  getDictionaries,
  getEmailFind,
  getInnFind,
  getPhoneFind,
  openSureModal,
  removeDocuments,
  setEmailError,
  setInnError,
  setPhoneError,
  updateCandidate
} from '../actions'
import {
  createArrayOfTimeObjects,
  extractValue,
  fetchCitiesList,
  getDefaultValuesCandidate,
  getResetCandidate,
  mapCommonFields,
  prepareCandidateData,
  validateDateOfBirth
} from '../utils'
import DebounceSelectFormControlCity from '../../../common/components/formcontrol/DebounceSelectFormControlCity'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import $api from '../../../common/api/http'
import AvatarCandidateEdit from '../../../common/svg/AvatarCandidateEdit'
import ControlButton from '../../../common/components/Buttons/Button'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { selectLoadingModal, selectUserInfo } from '../../InnerRouter/selectors'
import CommonDrawer from '../../../common/components/CommonDrawer/CommonDrawer'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './CandidateEditDrawer.scss'

const mainCssClass = getCssClassName('candidate-edit-drawer')
type TFormValues = {
  leadgen_source_id: number
  last_name: string
  schedule: number
  type: number
  name: string
  second_name: string
  phone: { telephone: string }[]
  age?: number
  birthday?: string
  address: string
  gender: number
  email: { mail: string }[]
  links: { link: string }[]
  date_begin_at: string
  date_interview: string
  metro: string
  recommend_fio?: string
  recommend_contact?: string
  wages?: string
  currency?: number
  date_internship?: string
  date_registration?: string
  date_teach?: string
  duration_internship?: number
  city: any
  citizenship: number
  time_internship: string
  date_interview_offline: string | null
} & any
type DrawerPropsWithEditMode = DrawerProps & { editMode?: boolean }

function CandidateEditDrawer({ open }: DrawerPropsWithEditMode) {
  const dispatch = useDispatch()
  const loadingModal = useSelector(selectLoadingModal)
  const candidate = useSelector(selectCandidate)
  const userInfo = useSelector(selectUserInfo)
  const updateCandidateMode = useSelector(selectUpdateCandidateMode)
  const phoneError = useSelector(selectPhoneError)
  const innError = useSelector(selectInnError)
  const mailError = useSelector(selectEmailError)
  const refPrevInn = useRef(candidate?.inn)
  const refPrevPhone = useRef(candidate?.phone)
  const [modal, setModal] = useState(false)
  const workSitesOptions = useSelector(selectDictionaryOptionsByName('WorkSites'))
  const genderOptions = useSelector(selectDictionaryOptionsByName('gender'))
  const currencyOptions = useSelector(selectDictionaryOptionsByName('currency'))
  const appendOptions = useSelector(selectDictionaryOptionsByName('appendType'))
  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const registrationOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))
  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const officeOptions = useSelector(selectDictionaryOptionsByName('office'))
  const loadingFoto = ''

  const [isDisabled, setDisabled] = useState(false)
  const [timeOPtions, setTimeOptions] = useState(createArrayOfTimeObjects())

  const { control, getValues, handleSubmit, reset: resetForm, watch, setValue, formState } = useForm<TFormValues>()

  const actualCity = watch('city')

  const fetchMetroList = useCallback(
    async (name: string) => {
      if (watch('city')?.label && watch('city')?.key) {
        const data = await $api.get(`/api/v1/dadata/suggest-metro?search=${name}&city_kladr_id=${watch('city').key}
      `)

        return data.data.DATA.map((obj: { data: { name: string } }) => ({
          label: `${obj.data.name}`,
          value: obj.data.name
        }))
      }
      return []
    },
    [actualCity]
  )

  const fetchAdressList = useCallback(
    async (name: string) => {
      if (watch('city')?.label) {
        const data = await $api.get(`/api/v1/dadata/suggest-all?search=${name}&city=${watch('city').label}`)

        return data.data.DATA.map((obj) => ({
          label: `${obj.value}`,
          value: obj.value
        }))
      }
      return []
    },
    [actualCity]
  )

  const {
    fields: phoneFields,
    append: phoneAppend,
    prepend: phonePrepend,
    remove: removePhone
  } = useFieldArray({
    control,
    name: 'phone'
  })

  const {
    fields: emailFields,
    append: emailAppend,
    prepend: emailPrepend,
    remove: removeEmail
  } = useFieldArray({
    control,
    name: 'email'
  })

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'links'
  })

  const onChange = (checked: boolean) => {
    if (checked) {
      setModal(true)
    } else setModal(false)
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleRemovePhone = (index: number) => {
    const newPhoneArr = phoneError
      .filter((e) => e.input_id !== index)
      .map((el) => (el.input_id > index ? { input_id: el.input_id - 1, candidate: el.candidate } : el))
    dispatch(setPhoneError(newPhoneArr))
    removePhone(index)
  }

  const handleRemoveEmail = (index: number) => {
    const newPhoneArr = mailError
      .filter((e) => e.input_id !== index)
      .map((el) => (el.input_id > index ? { input_id: el.input_id - 1, candidate: el.candidate } : el))
    dispatch(setEmailError(newPhoneArr))
    removeEmail(index)
  }
  const handleUploadImage = (e) => {}

  const onCancel = () => {
    dispatch(openSureModal(true))
  }

  const onSubmit = () => {
    const data = getValues()
    const city = watch('city')

    if (updateCandidateMode) {
      const candidateData = city
        ? prepareCandidateData({
            data,
            cityKey: city?.key,
            responsibleId: candidate?.responsible,
            id: candidate?.id
          })
        : { ...data, ...mapCommonFields(data) }

      dispatch(updateCandidate(candidateData))
    } else if (userInfo) {
      const responsibleId = userInfo.department === 175 ? userInfo?.id : undefined
      const researcherId = userInfo.department === 176 ? userInfo?.id : undefined
      const candidateData = prepareCandidateData({ data, cityKey: city?.key, responsibleId, researcherId })

      dispatch(createCandidate(candidateData))

      if (data.comment) {
        dispatch(createNewComment({ comment: data.comment, candidate_id: candidate?.id }))
      }
    }
  }

  const handlePaste = (e, index) => {
    const pastedValue = e.clipboardData.getData('Text')
    const input = document.querySelector(`input[name="phone[${index}].telephone"]`)
    if (pastedValue) {
      if (pastedValue.startsWith(input?.value || '+7')) {
        const newValue = pastedValue.slice(input?.value?.length || 2)
        const newPhoneValue = `${input?.value || '+7'}${newValue}`
        setValue(`phone[${index}].telephone`, newPhoneValue)
      } else if (pastedValue.length === 11 && pastedValue[0] === '8' && input?.value === '+7') {
        e.preventDefault()
        setValue(`phone[${index}].telephone`, `+7${pastedValue.slice(1)}`)
      } else {
      }
    } else {
    }
  }

  const handleChangeCity = () => {
    setValue('metro', '')
    setValue('address', '')
  }

  const props: UploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.DOMAIN}/api/v1/candidate/upload-document?candidate_id=${candidate?.id || ''}&document_id=100`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          // eslint-disable-next-line no-console
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file успешно загружен`)
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }
      },
      onDownload(file) {},
      onRemove(file) {
        if (candidate && candidate.id) {
          dispatch(
            removeDocuments({
              fileId: file.uid,
              candidateId: candidate.id
            })
          )
        }
      },
      onSuccess() {
        if (candidate?.id) {
          dispatch(getCandidateUpdate(candidate?.id))
        }
      }
    }),
    [candidate]
  )

  const handleOnBlur = (index: number) => {
    const phone = watch(`phone[${index}].telephone`)
    if (phone) {
      const slicedPhone = phone.slice(1)
      if (phone && isPossiblePhoneNumber(phone) && slicedPhone !== refPrevPhone.current) {
        refPrevPhone.current = slicedPhone
        dispatch(
          getPhoneFind({ input_id: index, phone, candidate_id: updateCandidateMode && candidate ? candidate.id : 0 })
        )
      }
    }

    setTimeout(() => {
      setDisabled(false)
    }, 1500)
  }

  const handleOnBlurInn = () => {
    const innNumber = watch('inn')

    if (innNumber && /^\d{12}$/.test(innNumber) && innNumber !== refPrevInn.current) {
      refPrevInn.current = innNumber
      dispatch(getInnFind({ inn: innNumber, candidate_id: updateCandidateMode && candidate ? candidate.id : 0 }))
    } else if (!innNumber) {
      dispatch(setInnError([]))
    }
    setTimeout(() => {
      setDisabled(false)
    }, 1500)
  }

  const handleOnBlurEmail = (index: number) => {
    const email = watch(`email[${index}].mail`)

    dispatch(
      getEmailFind({ input_id: index, email, candidate_id: updateCandidateMode && candidate ? candidate.id : 0 })
    )

    setTimeout(() => {
      setDisabled(false)
    }, 1500)
  }

  const handleFocus = () => {
    setDisabled(true)
  }

  const uploadButton = (
    <div>
      {loadingFoto ? <Preloader /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить фото</div>
    </div>
  )

  useEffect(
    () => () => {
      resetForm()
      dispatch(setPhoneError([]))
    },
    []
  )

  useEffect(() => {
    if (!updateCandidateMode) {
      dispatch(
        getDictionaries([
          'stageStatus',
          'gender',
          'appendType',
          'currency',
          'schedule',
          'WorkSites',
          'internshipDuration',
          'schedule',
          'rejectionReason',
          'candidateStatus',
          'typeRegistrationWork',
          'citizenship',
          'office'
        ])
      )
    }
  }, [])

  useEffect(() => {
    if (updateCandidateMode && candidate) {
      resetForm(getDefaultValuesCandidate(candidate))
      setModal(!!(candidate?.recommend_fio || candidate?.recommend_contact))
    } else {
      resetForm(getResetCandidate())
    }
  }, [updateCandidateMode, open])

  return (
    <CommonDrawer
      open={open}
      width="800px"
      onClose={onCancel}
      className={mainCssClass}
      footer={
        <>
          <ControlButton className="button" typeButton="secondary" onClick={onCancel} disabled={loadingModal}>
            Отменить
          </ControlButton>
          <ControlButton
            className="button"
            typeButton="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={loadingModal || isDisabled}
          >
            Сохранить
          </ControlButton>
        </>
      }
    >
      <NewScrollContainer>
        <div className={mainCssClass}>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_upload`}>
              {updateCandidateMode ? (
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleUploadImage}
                  {...props}
                >
                  {candidate?.photo ? (
                    <img src={candidate?.photo} alt="avatar" style={{ width: '100%' }} />
                  ) : !updateCandidateMode ? (
                    <AvatarCandidateEdit />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              ) : (
                <AvatarCandidateEdit />
              )}
            </div>

            <InputFormControl name="last_name" control={control} label="Фамилия" />
            <InputFormControl name="name" control={control} label="Имя" rules={{ required: true }} />
            <InputFormControl name="second_name" control={control} label="Отчество" />
          </div>
          <div className={`${mainCssClass}_items-phone`}>
            <div className={`${mainCssClass}_items-phone_links`}>
              {phoneFields?.map((phone, index) =>
                index === 0 ? (
                  <div className={`${mainCssClass}_items-phone_links_first`}>
                    <PhoneInputFormControl
                      rules={{
                        required: true,
                        validate: (value) => {
                          const isObjectFound = phoneError.some((item) => item.input_id === index)
                          const isLengthValid = isPossiblePhoneNumber(value)
                          return !isObjectFound && isLengthValid
                        }
                      }}
                      onPaste={(e) => handlePaste(e, index)}
                      name={`phone[${index}].telephone`}
                      control={control}
                      defaultCountry="RU"
                      label="Телефон"
                      onBlur={() => handleOnBlur(index)}
                      onFocus={handleFocus}
                      required
                      error={formState.errors?.phone ? formState.errors?.phone[index]?.telephone : null}
                      className={phoneError.some((item) => item.input_id === index) ? 'error' : ''}
                      tooltip={
                        phoneError.some((item) => item.input_id === index)
                          ? phoneError.find((el) => el.input_id === index).candidate
                          : null
                      }
                    />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      typeButton="secondary"
                      onClick={() => phoneAppend({ telephone: '' })}
                    >
                      <AddPlusSvg />
                    </ControlButton>
                  </div>
                ) : (
                  <div className={`${mainCssClass}_items-phone_links_other`}>
                    <PhoneInputFormControl
                      rules={{
                        required: true,
                        validate: (value) => {
                          const isObjectFound = phoneError.some((item) => item.input_id === index)
                          const isLengthValid = isPossiblePhoneNumber(value)
                          return !isObjectFound && isLengthValid
                        }
                      }}
                      name={`phone[${index}].telephone`}
                      key={phone.id}
                      control={control}
                      label="Телефон"
                      onPaste={(e) => handlePaste(e, index)}
                      defaultCountry="RU"
                      onBlur={() => handleOnBlur(index)}
                      onFocus={handleFocus}
                      error={formState.errors?.phone ? formState.errors?.phone[index]?.telephone : null}
                      className={phoneError.some((item) => item.input_id === index) ? 'error' : ''}
                      tooltip={
                        phoneError.some((item) => item.input_id === index)
                          ? phoneError.find((el) => el.input_id === index).candidate
                          : null
                      }
                    />

                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      typeButton="warning"
                      onClick={() => handleRemovePhone(index)}
                    >
                      <CloseMD />
                    </ControlButton>
                  </div>
                )
              )}
            </div>

            <div className={`${mainCssClass}_items-phone_links`}>
              {emailFields?.map((email, index) =>
                index === 0 ? (
                  <div className={`${mainCssClass}_items-phone_links_first`}>
                    <InputFormControl
                      name={`email[${index}].mail`}
                      control={control}
                      type="email"
                      label="Email"
                      rules={{
                        validate: (value) => (value ? !mailError.some((item) => item.input_id === index) : true)
                      }}
                      onBlur={() => handleOnBlurEmail(index)}
                      className={mailError.some((item) => item.input_id === index) ? 'error' : ''}
                      onFocus={handleFocus}
                      tooltip={
                        mailError.some((item) => item.input_id === index)
                          ? mailError.find((el) => el.input_id === index).candidate
                          : null
                      }
                    />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      typeButton="secondary"
                      onClick={() => emailAppend({ mail: '' })}
                    >
                      <AddPlusSvg />
                    </ControlButton>
                  </div>
                ) : (
                  <div className={`${mainCssClass}_items-phone_links_other`}>
                    <InputFormControl
                      name={`email[${index}].mail`}
                      control={control}
                      type="email"
                      label="Email"
                      rules={{
                        validate: (value) => (value ? !mailError.some((item) => item.input_id === index) : true)
                      }}
                      onBlur={() => handleOnBlurEmail(index)}
                      className={mailError.some((item) => item.input_id === index) ? 'error' : ''}
                      onFocus={handleFocus}
                      tooltip={
                        mailError.some((item) => item.input_id === index)
                          ? mailError.find((el) => el.input_id === index).candidate
                          : null
                      }
                    />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      typeButton="warning"
                      onClick={() => handleRemoveEmail(index)}
                    >
                      <CloseMD />
                    </ControlButton>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={cn(`${mainCssClass}_items`, `${mainCssClass}_gender`)}>
            <RadioGroupFormControl name="gender" control={control} options={genderOptions} label="Пол" />
          </div>
          <div className={`${mainCssClass}_items`}>
            <DatePickerFormControl
              name="birthday"
              rules={{ validate: validateDateOfBirth }}
              control={control}
              label="Дата рождения"
              mask
            />
            <div>
              <InputFormControl
                name="age"
                control={control}
                label="Возраст"
                rules={{
                  pattern: /^[0-9]*$/
                }}
              />
            </div>
          </div>

          <div className={`${mainCssClass}_items`}>
            <DebounceSelectFormControlCity
              name="city"
              control={control}
              fetchOptions={fetchCitiesList}
              label="Город"
              notFoundContent="Начните вводить название города"
              onChange={handleChangeCity}
              labelInValue
            />
            {watch('city') && watch('city')?.value && (
              <DebounceSelectFormControl
                name="address"
                control={control}
                fetchOptions={fetchAdressList}
                label="Адрес"
                notFoundContent="Начните вводить адрес"
                key={`${watch('city')?.key}0`}
              />
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            {watch('city') && watch('city')?.value && (
              <DebounceSelectFormControl
                name="metro"
                control={control}
                fetchOptions={fetchMetroList}
                label="Метро"
                notFoundContent="Начните вводить название метро"
                key={watch('city')?.key}
              />
            )}
            <SelectFormControl name="office" control={control} label="Офис" options={officeOptions} />
          </div>

          <div className={`${mainCssClass}_items-phone_links_first`}>
            <InputFormControl
              name="wages"
              control={control}
              label="Ожидаемая ЗП"
              rules={{
                pattern: /^[0-9]*$/
              }}
            />
            <SelectFormControl
              name="currency"
              control={control}
              className={`${mainCssClass}_items_ru`}
              options={currencyOptions}
            />
          </div>
          <div className={`${mainCssClass}_items_max`}>
            <SearchSelectFormControlSource
              name="leadgen_source_id"
              control={control}
              label="Источник"
              options={workSitesOptions}
              showSearch
              optionFilterProp="children"
            />
            <SelectFormControl name="type" control={control} label="Тип добавления" options={appendOptions} />
            <SelectFormControl name="schedule" control={control} label="График работы" options={sheduleOptions} />
          </div>
          <div className={`${mainCssClass}_items_max`}>
            {/* <DatePickerFormControl name="date_interview" control={control} label="Дата собеседования" /> */}
            <DatePickerFormControl
              name="date_internship"
              control={control}
              label="Дата стажировки"
              disabled={!candidate?.request_id}
            />
            {watch('date_internship') && (
              <SelectFormControl
                name="time_internship"
                control={control}
                label="Время стажировки"
                options={timeOPtions}
                rules={{ required: true }}
                disabled={!candidate?.request_id}
              />
            )}
            <DatePickerFormControl name="date_teach" control={control} label="Дата обучения" />
            <DatePickerFormControl name="date_begin_at" control={control} label="Дата выхода" />
          </div>
          <div className={`${mainCssClass}_items_max`}>
            <DatePickerFormControl name="date_registration" control={control} label="Дата оформления" />
            {userInfo && Number(userInfo?.department) === 176 && (
              <DatePickerFormControl
                name="date_interview_offline"
                control={control}
                label="Дата оффлайн собеседования"
              />
            )}
          </div>
          <div className={cn(`${mainCssClass}_items_max`, `${mainCssClass}_items_max-inn`)}>
            <SelectFormControl
              name="type_registration"
              control={control}
              label="Вид оформления"
              options={registrationOptions}
            />
            <SelectFormControl name="citizenship" control={control} label="Гражданство" options={citizenshipOptions} />
            <InputFormControl
              name="inn"
              control={control}
              label="ИНН"
              mask="999999999999"
              rules={{
                validate: (value) => (value ? /^\d{12}$/.test(value) && !innError.length : true)
              }}
              onBlur={handleOnBlurInn}
              className={innError.length ? 'error' : ''}
              tooltip={innError.length ? innError[0] : null}
              onFocus={handleFocus}
            />
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_links`}>
              {fields.map((link, index) =>
                index === 0 ? (
                  <div className={`${mainCssClass}_items_links_first`}>
                    <InputFormControl key={link.id} name={`links[${index}].link`} control={control} label="Ссылка" />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      onClick={() => append({ link: '' })}
                      typeButton="secondary"
                    >
                      <AddPlusSvg />
                    </ControlButton>
                  </div>
                ) : (
                  <div className={`${mainCssClass}_items_links_other`}>
                    <InputFormControl key={link.id} name={`links[${index}].link`} control={control} label="Ссылка" />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      onClick={() => handleRemove(index)}
                      typeButton="warning"
                    >
                      <CloseMD />
                    </ControlButton>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={`${mainCssClass}_switchContainer`}>
            <Switch
              onChange={onChange}
              className={`${mainCssClass}_switchContainer_switch`}
              defaultChecked={
                updateCandidateMode ? !!(candidate?.recommend_fio || candidate?.recommend_contact) : false
              }
            />
            <p>Кандидат по реферальной программе</p>
          </div>
          {modal && (
            <div className={`${mainCssClass}_items`}>
              <InputFormControl name="recommend_fio" control={control} label="ФИО Рекомендателя" />
              <InputFormControl name="recommend_contact" control={control} label="Телефон рекомендателя" />
            </div>
          )}
        </div>
      </NewScrollContainer>
    </CommonDrawer>
  )
}

export default CandidateEditDrawer
