import React from 'react'
import PhoneSvg from '../../../common/svg/PhoneSvg'

function EmpoyessTableType({ type } : {type:string}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {type === 'На созвоне'
        ? (
          <>
            На созвоне
            <PhoneSvg fill="#404542" />
          </>
        ) : type}
    </div>
  )
}

export default EmpoyessTableType
