/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Typography, Tabs
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { CalendarOutline } from '../../../common/svg/SvgFromIconWrapper/CalendarOutline'
import {
  getCalendar, getDictionaries, getTimeSlots, setOpenMoreInfoModal, setPage, setPeriod
} from '../actions'
import Calendar from './Calendar'
import {
  selectCalendars, selectInterview, selectLoading, selectOpenModalInterview, selectPage, selectTimeSlots
} from '../selectors'
import { openСalendarSettings } from '../../../features/SettingsCalendar/actions'
import MoreInfoInterview from './ModalViewMoreInfo'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import TabPane = Tabs.TabPane
import { setVisibleTable } from '../../InnerRouter/actions'
import './CalendarView.scss'
import { SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'

const { Title } = Typography

const mainCssClass = 'calendar-view'

 type TFormValues = {
  calendarValue: string
}
const CalendarView: FC = () => {
  const dispatch = useDispatch()
  const {
    control, getValues, reset, handleSubmit, watch
  } = useForm<TFormValues>({
    defaultValues: {
      calendarValue: '1'
    }
  })
  const page = useSelector(selectPage)
  const calendars = useSelector(selectCalendars)
  const timeSlots = useSelector(selectTimeSlots)
  const loading = useSelector(selectLoading)
  const openInterview = useSelector(selectOpenModalInterview)
  const interview = useSelector(selectInterview)
  const period = watch('calendarValue')
  const stringCountsPagination = useMemo(() => dayjs(new Date()).add(Number(period) * page, 'day').format('DD MMMM'), [page, period])

  const handleNextPage = useCallback(() => {
    dispatch(setPage(page + 1))
  }, [page])

  const handlePreviousPage = useCallback(() => {
    dispatch(setPage(page - 1))
  }, [page])

  const handleOpenCalendarSettings = useCallback(() => {
    dispatch(openСalendarSettings(true))
  }, [])

  const handleCloseModal = useCallback(() => {
    dispatch(setOpenMoreInfoModal(false))
  }, [])

  const handleTabClick = useCallback((e) => {
    dispatch(setVisibleTable(true))
  }, [])

  useEffect(() => {
    dispatch(getCalendar({ user_id: 0, period, page }))
  }, [period, page])

  useEffect(() => {
    if (period === '1') {
      const date = dayjs(new Date()).add(page, 'day').format(SERVER_DATE_FORMAT)
      dispatch(getTimeSlots(date))
    } else {
      dispatch(getTimeSlots())
    }
  }, [period, page])

  useEffect(() => {
    dispatch(setPeriod(period))
  }, [period])

  useEffect(() => {
    dispatch(getDictionaries(['candidateStatus', 'interviewRejectionReason', 'interviewNote', 'interviewResult', 'statusResult']))
  }, [])
  return (
    <div className={`${mainCssClass}`}>
      <div className={`${mainCssClass}__header-block`}>
        <div className={`${mainCssClass}__heading-block`}>
          <div className={`${mainCssClass}__title-block`}>
            <Title level={2}>
              Интервью
            </Title>
            {loading && <Preloader className={`${mainCssClass}__loader`} />}
          </div>
          <SelectFormControl
            control={control}
            name="calendarValue"
            options={[{ label: 'Неделя', value: '7' }, { label: 'День', value: '1' }, { label: '2 дня', value: '2' }]}
            className={`${mainCssClass}__select`}
          />
          <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
            <ArrowRightSM size="1" onClick={handlePreviousPage} fill="#404542" />
            <Typography.Text className={`${mainCssClass}__pagination-font`}>
              {stringCountsPagination}
            </Typography.Text>
            <ArrowLeftSM size="1" onClick={handleNextPage} fill="#404542" />
          </div>
        </div>
        <ControlButton size="large" typeButton="secondary" icon="none" className="button" onClick={handleOpenCalendarSettings}>
          {' '}
          <CalendarOutline />
          {' '}
          Настройки
          {' '}
        </ControlButton>
      </div>
      <div className={`${mainCssClass}__tabs`}>
        <Tabs onChange={handleTabClick} defaultActiveKey="1">
          <TabPane key="1" tab="Календарь" />
          <TabPane key="2" tab="Таблица" />
        </Tabs>
      </div>
      {!loading && (
        <div className={`${mainCssClass}__scroll-div`}>
          <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
            <Calendar calendars={calendars} timeSlots={timeSlots} className={`${mainCssClass}__table`} />
            <MoreInfoInterview
              open={openInterview}
              interview={interview}
              tags={interview?.vacancy_list || []}
              className={`${mainCssClass}__modals`}
              onCancel={handleCloseModal}
            />
          </NewScrollContainer>
        </div>
      )}
    </div>
  )
}

export default memo(withErrorBoundary(CalendarView))
