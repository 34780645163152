import { AnchorHTMLAttributes, HTMLAttributes, useEffect } from 'react'
import * as React from 'react'

import cn from 'classnames'

import './style.scss'
import { useDataSourceEvents } from '../hook'
import { AnalyticsProps, BasicPropsNarrow, DemoTourProps } from '../../types/basic'
import {
  devDeprecated, devWarning, isDefined, isTruthy
} from '../../utils/utilsContext'
import { useEnterSpacePress } from '../../utils/utilities'

export type LinkColor = 'primary' | 'primaryLight' | 'secondary';

export type LinkSize = 'sm' | 'md';

type IconAlignment = 'left' | 'right';

type NavigateParams = {
  method?: 'push' | 'replace';
  isLightbox?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: Record<string, any> | null | undefined;
};

type LocationDescriptorObject = {
  pathname?: string;
  search?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: Record<string, any> | null | undefined;
  hash?: string;
  key?: string;
};

type To = string | LocationDescriptorObject;

/**
 * @deprecated
 */
export type NavigateFunction = (to: To, params?: NavigateParams) => void;

export type CommonLinkProps = {
  /**
   * @deprecated
   * Текст ссылки
   */
  text?: string;
  /**
   * @deprecated
   * Адрес ссылки
   */
  href?: string;
  /**
   * Дополнительный класс для компонента
   */
  className?: string;
  /**
   * Дополнительный класс для иконок
   */
  linkIconClassName?: string;
  /**
   * Дополнительный класс для текста ссылки
   */
  linkTextClassName?: string;
  /**
   * Вызывается при клике на компонент
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /**
   * @deprecated
   * Вызывается при клике на ссылку и принимающий в качестве аргумента адрес ссылки
   */
  navigate?: NavigateFunction;
  /**
   * Размер
   */
  size?: LinkSize;
  /**
   * Иконка
   */
  Icon?: JSX.Element;
  /**
   * Местоположение иконки
   */
  iconAlignment?: IconAlignment;
  /**
   * Цвет
   */
  color?: LinkColor;
  /**
   * Если true, то элемент находится в состоянии disabled
   */
  isDisabled?: boolean;
  /**
   * Дополнительный класс для текста ссылки
   */
  linkText?: string;
  /**
   * Нативные атрибуты для ссылки
   */
  config?: HTMLAttributes<HTMLDivElement>;
};

export type LinkProps = CommonLinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof CommonLinkProps> &
  AnalyticsProps &
  DemoTourProps &
  BasicPropsNarrow;

/**
 * Переменная для мэппинга размеров иконок на размеры линка
 * В Link size=sm должны использовать xs иконки
 * В Link size=md должны использовать sm иконки
 */
const ICON_SIZES = {
  md: 'sm',
  sm: 'xs'
}

const mainCssClass = 'Link'
export function Link(props: LinkProps) {
  useDataSourceEvents()
  const {
    size = 'md',
    color = 'primary',
    isDisabled,
    className,
    linkIconClassName,
    linkTextClassName,
    linkText,
    text,
    Icon,
    iconAlignment = 'right',
    children,
    href,
    navigate,
    config,
    ...restProps
  } = props

  useEffect(() => {
    devDeprecated({ text }, 'children')
    devWarning(
      !isDefined(navigate),
      '"navigate" prop is @deprecated. Use "Link" from navigation lib instead '
    )
  }, [href, navigate, text])

  return (
    <Comp
      isDisabled={isDisabled}
      className={cn(
        mainCssClass,
        `${mainCssClass}__${size}`,
        `${mainCssClass}__${color}`,
        isDisabled && `${mainCssClass}__disabled`,
        className
      )}
      href={href}
      navigate={navigate}
      config={config}
      {...restProps}
    >
      <span className={cn(linkIconClassName, `${mainCssClass}_icon`)}>
        {iconAlignment === 'left' && Icon && React.cloneElement(Icon, { size: ICON_SIZES[size] })}
      </span>

      <span
        className={cn(
          `${mainCssClass}_text`,
          `${mainCssClass}_text__${size}`,
          linkText,
          isTruthy(Icon) && {
            [`${mainCssClass}_text__left`]: iconAlignment === 'right',
            [`${mainCssClass}_text__right`]: iconAlignment === 'left'
          },
          linkTextClassName
        )}
      >
        {children || text}
      </span>

      <span className={cn(linkIconClassName, `${mainCssClass}_icon`)}>
        {iconAlignment === 'right' && Icon && React.cloneElement(Icon, { size: ICON_SIZES[size] })}
      </span>
    </Comp>
  )
}

const defaultNavigateEmulationFn = (href?: string, target?: string) => function (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault()
  if (href) {
    window.open(href, target)
  }
}

function Comp({
  isDisabled,
  children,
  navigate,
  href,
  onClick,
  target = '_top',
  ...restProps
}: Omit<LinkProps, 'text' | 'defaultValue'>) {
  const handleClick = isDefined(onClick || navigate)
    ? (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault()
      isDefined(onClick) && onClick(event)
      isDefined(navigate) && isDefined(href) && navigate(href)
    }
    : undefined

  // Если клик-хендлер не определен, а есть только href то вешаем дефолтный переход
  const keyPressedFn = handleClick || defaultNavigateEmulationFn(href, target)
  const handleKeyDown = useEnterSpacePress(keyPressedFn)

  return isDisabled ? (
    <span {...restProps}>{children}</span>
  ) : (
    <a
      href={href}
      tabIndex={0}
      target={target}
      role="link"
      {...restProps}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
