import { createReducer } from '@reduxjs/toolkit'
import {
  setCalendar, setLoading, setOpenMoreInfoModal, setPage, setPeriod, setSelectedInterviewId, setTimeSlots
} from './actions'
import { TCalendar } from '../../common/api/calendar'
import { setInterview } from '../InterviewCard/actions'
import { TInterview } from '../../common/api/interviews'
import { setDictionaries } from '../candidate/actions'
import { TDictionaries } from '../../common/types/dictionaries'

export type TInterviewInitialState = {
  calendar: TCalendar[] | null
  loading: boolean
  timeSlots: string[] | null
  selectedInterviewId: number | null
  openModalMoreInfo: boolean
  interview: TInterview | null
  period: string
  dictionaries: TDictionaries | null
  page: number
}
const initialState: TInterviewInitialState = {
  calendar: null,
  loading: false,
  timeSlots: null,
  selectedInterviewId: null,
  openModalMoreInfo: false,
  interview: null,
  period: '1',
  dictionaries: null,
  page: 0
}

const calendarReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCalendar, (state, { payload }) => {
      state.calendar = payload
    })
    .addCase(setPeriod, (state, { payload }) => {
      state.period = payload
    })
    .addCase(setTimeSlots, (state, { payload }) => {
      state.timeSlots = payload
    })
    .addCase(setInterview, (state, { payload }) => {
      state.interview = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setSelectedInterviewId, (state, { payload }) => {
      state.selectedInterviewId = payload
    })
    .addCase(setOpenMoreInfoModal, (state, { payload }) => {
      state.openModalMoreInfo = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setPage, (state, { payload }) => {
      state.page = payload
    })
})

export default calendarReducer
