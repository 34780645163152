import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TCandidatesData, TDATA } from '../candidate/types'
import { TStageMassRequest, TStageRequest } from './types'
import { TVacancy } from '../../common/api/vacancy'
import { TPaginationRequest } from '../../common/types/pagination'
import { TCounters } from '../../common/api/Candidate'

export const getCandidatesData = createAction<TPaginationRequest>('funnelfunnel/getCandidatesData')
export const getCounters = createAction('funnel/getCounters')
export const setCounters = createAction<null | TCounters[]>('funnel/setCounters')

export const uploadNewCandidates =createAction<TPaginationRequest>('funnel/uploadNewCandidates')
export const setCandidatesData = createAction<TDATA[] | []>('funnel/setCandidatesData')
export const resetCandidates = createAction('funnel/resetCandidates')
export const reset = createAction('funnel/reset')
export const setBlockedFiltersResponsibleOrRecruiter = createAction<boolean>('funnel/setBlockedFiltersResponsibleOrRecruiter')

export const updateStage = createAction<TStageRequest>('funnel/updateStage')
export const updateMassStage = createAction<TStageMassRequest>('funnel/updateMassStage')
export const getDictionaries = createAction<TDictionariesNames>('funnel/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('funnel/dictionaries/setDictionaries')
export const setLoading = createAction<boolean>('funnel/setLoading')
export const resetData = createAction('funnel/resetData')
export const setMaxPage = createAction<Map<any, any>>('funnel/setMaxPage')

export const setLoadingCandidates = createAction<boolean>('funnel/setLoadingCandidates')

export const openEditVacancyModal = createAction<boolean>('funnel/openEditVacancyModal')
export const openCreateVacancyModal = createAction<boolean>('funnel/openCreateVacancyModal')

export const setShowButton = createAction<boolean>('funnel/setShowButton')
export const setShowModal = createAction<boolean>('funnel/setShowModal')

export const setDefStringFunnel = createAction<string>('funnel/setDefStringFunnel')
export const setSelfFunnel = createAction<string>('funnel/setSelfFuunel')

export const changeOrder = createAction<TPaginationRequest>('funnel/changeOrder')

export const showLimitationStageFunnelModal = createAction<boolean>('funnel/showLimitationStageModal')

export const setMessageLimitationFunnel = createAction<string>('funnel/setMessageLimitationFunnel')

export const exportCSV = createAction<TPaginationRequest>('funnel/exportCSV')
