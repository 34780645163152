import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement
} from 'chart.js'
import { useSelector } from 'react-redux'
import { Typography } from 'antd'
import { selectEmployeesStatusesDiagram } from '../selectors'
import { getColorForDiagram } from '../utils'
import Rectangle from '../../../common/svg/Rectangle'
import './StatusesEmployeesDiagram.scss'

ChartJS.register(ArcElement, Tooltip, Legend)
const mainCssClass = 'doughut'
function DoughnutChart() {
  const dataDoughnut = useSelector(selectEmployeesStatusesDiagram)
  const preparedDataValue = useMemo(() => dataDoughnut?.map((item) => item.count), [dataDoughnut])
  const preparedItemColors = useMemo(() => dataDoughnut?.map((item) => getColorForDiagram(item.id)), [dataDoughnut])
  const data = {
    datasets: [
      {
        data: preparedDataValue,
        backgroundColor: preparedItemColors,
        borderWidth: 1
      }
    ]
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      }
    }
  }

  return (
    <div className={mainCssClass}>
      <div style={{ height: '180px', width: '180px' }}>
        <Doughnut data={data} options={options} />
      </div>
      <div className={`${mainCssClass}__statuses`}>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill="#2DBE64" />
          <Typography.Text>На линии</Typography.Text>
        </div>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill="#DF1E12" />
          <Typography.Text>Не на линии</Typography.Text>
        </div>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill="#FFD608" />
          <Typography.Text>Обзвон</Typography.Text>
        </div>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill="#F5695A" />
          <Typography.Text>Не беспокоить</Typography.Text>
        </div>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill="#5A615C" />
          <Typography.Text>Перерыв</Typography.Text>
        </div>
        <div className={`${mainCssClass}__status-container`}>
          <Rectangle fill='#BFC5C0' />
          <Typography.Text>Неизвестно</Typography.Text>
        </div>
      </div>
    </div>
  )
}
export default DoughnutChart
