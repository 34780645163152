import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectRequest } from '../../selectors'
import './DateCreateApplication.scss'

const MainCSSClass = getCssClassName('dateCreateApplication')
function DateCreateApplication() {
  const request = useSelector(selectRequest)
  return (
    <div className={`${MainCSSClass}`}>
      <Typography.Text className={`${MainCSSClass}__display-data`}>
        Дата создания заявки:
        {' '}
        {request?.created_at ? dayjs(request?.created_at).format('DD.MM.YYYY') : 'дата не указана'}
        {' '}
      </Typography.Text>
    </div>
  )
}

export default memo(withErrorBoundary(DateCreateApplication))
