import React, {
  FC, memo, useMemo, useState
} from 'react'
import { useSelector } from 'react-redux'
import { Tag, Typography } from 'antd'
import './CandidateStatusTableRow.scss'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectLabelById } from '../selectors'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Ellipse from '../../../common/svg/Ellipse'
import './Duplicate.scss'

  type TProps = {
    is_duplicates: boolean,
    merge_status: number | null
  }
const mainCssClass = getCssClassName('candidate-duplicate')
function Duplicate({ is_duplicates, merge_status }: TProps) {
  const fill = useMemo(() => {
    if (!is_duplicates && !merge_status) {
      return ['#727974', 'Нет дублей']
    }
    if (merge_status === 1) {
      return ['#2DBE64"', 'Основной']
    }
    if (merge_status === 2) {
      return ['#DF1E12', 'Дубль']
    }
    return ['#727974', 'Есть дубли']
  }, [is_duplicates, merge_status])

  return (
    <div className={mainCssClass}>
      <Ellipse fill={fill[0]} />
      <Typography.Text className={`${mainCssClass}__status`}>{fill[1]}</Typography.Text>

    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(Duplicate))
