/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from 'antd'
import HeaderChat from './HeaderChat'
import AddMessage from './AddMessage'
import Messages from './Messages'
import HistoryAsterisk from '../../../compositions/candidate/components/HistoryAsterisk'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate, selectChatVisible, selectHistoryVariable } from '../../../compositions/candidate/selectors'
import MailMessages from './MailMessages'
import CandidateHistory from './CandidateHistory'
import { selectMessages, selectTemplateContent } from '../selectors'
import CandidateOldHistory from './CandidateOldHistory'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { setHistoryVariable } from '../../../compositions/candidate/actions'
import './Chat.scss'
import TabPane = Tabs.TabPane
import CandidateHistoryDelete from './CandidateHistoryDelete'

const MainCSSClass = getCssClassName('chat')
function Chat() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const chatVisible = useSelector(selectChatVisible)
  const messageArr = useSelector(selectMessages)
  const content = useSelector(selectTemplateContent)
  const variable = useSelector(selectHistoryVariable)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleVisibleHistory = (key: string) => {
    dispatch(setHistoryVariable(key))
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = useMemo(() => windowSize.width < 500 && windowSize.height < 900, [windowSize])

  return (
    <>
      <HeaderChat />
      {chatVisible === 'history' && !isMobile && (
        <div className={`${MainCSSClass}__tabs`}>
          <Tabs activeKey={variable} onChange={(key) => handleVisibleHistory(key)}>
            <TabPane key="history" tab="История" />
            <TabPane key="oldNewhistory" tab="Старая история" />
            <TabPane key="oldHistory" tab="История из Potok" />
            <TabPane key="asterisk" tab="История звонков" />

          </Tabs>
        </div>
      )}
      {chatVisible === 'message' && (
        <>
          <Messages />
          {(messageArr?.length || content || isMobile) && (<AddMessage />)}
        </>
      )}
      {chatVisible === 'mail' && (
        <>
          <MailMessages />
          <AddMessage />
        </>
      )}
      {chatVisible === 'history' && !isMobile && (
        <>
          {variable === 'history' && <CandidateHistory /> }
          {variable === 'oldNewhistory' && <CandidateHistoryDelete /> }
          {variable === 'oldHistory' && <CandidateOldHistory />}
          {variable === 'asterisk' && <HistoryAsterisk />}
        </>
      )}
    </>
  )
}

export default memo(withErrorBoundary(Chat))
