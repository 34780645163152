import React from 'react'
import Icon from '../svgIcons/Call.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export default function Call(props: IconProps) {
  return (
    <IconWrapper {...props} name="Call" size='sm'>
      <Icon />
    </IconWrapper>
  )
}
