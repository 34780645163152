import axios, { AxiosResponse } from 'axios'
import 'dotenv/config'

axios.defaults.withCredentials = false

export async function fetchGetFieldCoordinatesYandex(name:string): Promise<AxiosResponse<any>> {
  if (name) {
    const { data } = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.YANDEX_GEOCODER}&geocode=${name}&format=json`)

    return data?.response?.GeoObjectCollection?.featureMember?.map((value) => ({
      label: `${value.GeoObject.name} (${value.GeoObject.description})`,
      value: `${value.GeoObject.Point.pos.split(' ')
        .reverse()
        .join(',')}`,
      city:`${value.GeoObject.description}`
    }))
  }
}
