import { createReducer } from '@reduxjs/toolkit'
import {
  resetData,
  setCandidatesData,
  setLoading,
  setDictionaries,
  setVacancy,
  setLoadingCandidates,
  openEditVacancyModal,
  openCreateVacancyModal, setEmptySlots, TSlots, openActiveInterviewModal
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'
import { TVacancy } from '../../common/api/vacancy'
import { TCandidates, TCandidatesData } from '../candidate/types'
import { TSlot } from '../../common/api/calendar'

type TInterviewModalsState = {
    emptySlots: null | string[];
    activeInterviewModal: boolean
};

const initialState: TInterviewModalsState = {
  emptySlots: null,
  activeInterviewModal: false
}

const interviewModalsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEmptySlots, (state, { payload }) => {
      state.emptySlots = payload
    })
    .addCase(resetData, () => initialState)
    .addCase(openActiveInterviewModal, (state, { payload }) => {
      state.activeInterviewModal = payload
    })
})

export default interviewModalsReducer
