import { TRequestVacancyCSV } from 'src/compositions/ManagementVacancies/actions'
import { AxiosResponse } from 'axios'
import $api from './http'
import { TDATA } from '../../compositions/candidate/types'
import { TPaginationRequest } from '../types/pagination'
import { changeStatusVacancyModal } from '../../features/vacancyModals/actions'

export type TVacancy = {
  id?: number
  status: number
  author: number
  label: string
  info: string
  description: string
  date_wish_close: string
  name: string
  city: string
  city_kladr_id: number
  department_name: string
  candidate_cost: number
  request_cost: number
  headhunter_cost: number
  url_hh:string[] | null
  is_favorite: boolean
  is_private: boolean
  metro: string
  group: number
  vacancy_count: number
  vacancy_list: [
    {
      value: number
      label: string
    }
  ]
  created_at: string
  updated_at: string
  is_interview_block:boolean
}
export type TResponseVacancy = {
  err: string
  DATA: TResponseVacancyData
  Headers: {
    'x-pagination-total-count': number[],
    'x-pagination-page-count': number[],
    'x-pagination-current-page': number[],
  }
}
export type TResponseVacancyData = {
  vacancy_list: TVacancy[]
  counters: {
    count: number
    label: string
    id: string | number
  }[]
}
export type TResponseVacancyById = {
  err: string
  DATA: TVacancy
}

export type TResponseDuplicateVacancy = {
  ERR: string
  DATA: TVacancy
}

export type TRequestCreateVacancy = {
  status: number
  label: string
  group: number
  city: string
  metro?: string
  author: number
  city_kladr_id: number
  is_favorite: boolean
}

export type TVacancyPatchData = {
  id: number
  label: string
  group: number
  city: string
  metro?: string
  city_kladr_id: number
}

export type TVacancyPatchIsFavorite = {
  id: number
  is_favorite:boolean
}

export type TPatchVacancy = {
  data: TVacancyPatchData | TVacancyPatchIsFavorite
  isVacancyTable?: boolean
}

export async function fetchGetVacancies(queryParams: TPaginationRequest): Promise<TResponseVacancy> {
  const { data } = await $api.get<TResponseVacancy>(
    // eslint-disable-next-line max-len
    `/api/v1/vacancies?sort=-is_favorite,updated_at&fields=vacancy_count,label,url_hh,is_interview_block,is_favorite,city,group,metro,city_kladr_id,candidate_cost,department_name,request_cost,id,headhunter_cost,status,author&per-page=${queryParams?.perPage || 10}&page=${queryParams?.page || 1}${queryParams?.filters || ''}`
  )
  return data
}
export async function fetchPostDuplicateVacancy(payload): Promise<AxiosResponse<TResponseDuplicateVacancy>> {
  const response = await $api.post<TResponseDuplicateVacancy>('/api/v1/vacancies', payload)
  return response
}
export async function fetchPatchChangeStatusVacancy(vacancyId, statusId): Promise<AxiosResponse<TResponseDuplicateVacancy>> {
  const response = await $api.patch<TResponseDuplicateVacancy>(`/api/v1/vacancies/${vacancyId}`, { status: statusId })
  return response
}

export async function fetchGetVacancy(id: number): Promise<AxiosResponse<TResponseVacancyById>> {
  const response = await $api.get<TResponseVacancyById>(`/api/v1/vacancies/${id}`)
  return response
}

export async function fetchPostCreateVacancy(payload: TRequestCreateVacancy): Promise<AxiosResponse<TVacancy>> {
  const response = await $api.post('/api/v1/vacancies', payload)
  return response
}

export async function fetchPatchUpdateVacancy(payload: TVacancyPatchData | TVacancyPatchIsFavorite): Promise<AxiosResponse<TVacancy>> {
  const response = await $api.patch(`/api/v1/vacancies/${payload.id}`, payload)
  return response
}

export async function fetchPostCreateVacancyCSV(payload: TRequestVacancyCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/vacancy/export-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}`)
  return response
}
