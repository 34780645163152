import React, { FC } from 'react'
import { ErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import DefaultFallbackComponent, { TProps as TFBProps } from './DefaultFallbackComponent'
import './ErrorBoundary.scss'

export type TProps = {
  errorClassName?: string;
  fallbackComponent?: FC<TFBProps>;
} & Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'>;

export default function withErrorBoundary<T>(Component: FC<any>, errorBoundaryProps: TProps = {}): FC<T & TProps> {
  return function (props) {
    const {
      errorClassName,
      fallbackComponent,
      fallbackRender,
      fallback,
      onError,
      onReset,
      resetKeys,
      ...restProps
    } = props

    const targetFallBack = fallbackComponent || DefaultFallbackComponent

    const handleError = (error: Error, info: { componentStack: string }) => {
      if (Sentry.getCurrentHub().getClient()) {
        Sentry.captureException(error)
      }

      if (onError) {
        onError(error, info)
      } else if (errorBoundaryProps.onError) {
        errorBoundaryProps.onError(error, info)
      }
    }

    return (
      <ErrorBoundary
        FallbackComponent={(props) => (
          targetFallBack({
            ...props,
            className: errorClassName || errorBoundaryProps?.errorClassName,
            displayResetBtn: !!onReset
          })
        )}
        fallbackRender={fallbackRender || errorBoundaryProps?.fallbackRender}
        fallback={fallback || errorBoundaryProps?.fallback}
        onError={handleError}
        onReset={onReset || errorBoundaryProps?.onReset}
        resetKeys={resetKeys || errorBoundaryProps?.resetKeys}
      >
        <Component {...restProps as T} />
      </ErrorBoundary>
    )
  }
}
