import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'hide'

function Hide({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7071 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L5.73315 7.14737C4.59149 7.91679 3.62267 8.77557 2.88342 9.51036L2.82637 9.56698C2.39945 9.99041 2.00743 10.3792 1.78224 11.0694C1.68394 11.3706 1.65293 11.7148 1.65294 11.9999C1.65294 12.285 1.68397 12.6291 1.78221 12.9303C2.00732 13.6204 2.39875 14.0087 2.82509 14.4317L2.88188 14.488C4.68986 16.2855 8.04384 19 12 19C13.6257 19 15.1423 18.541 16.4771 17.8913L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L4.7071 3.29289ZM7.17775 8.59197C6.03347 9.31834 5.04627 10.1805 4.29333 10.9289C3.80091 11.4183 3.74746 11.494 3.68358 11.6898C3.68358 11.6898 3.68083 11.6984 3.67688 11.7173C3.67304 11.7358 3.66889 11.76 3.66513 11.79C3.65751 11.8506 3.65294 11.923 3.65294 11.9998C3.65294 12.0767 3.65751 12.1491 3.66514 12.2098C3.6689 12.2397 3.67305 12.264 3.6769 12.2824C3.68084 12.3014 3.68361 12.3101 3.68361 12.3101C3.74751 12.506 3.8007 12.5813 4.29194 13.0697C6.04283 14.8103 8.89485 17 12 17C13.0183 17 14.0151 16.7648 14.964 16.3782L13.2932 14.7074C12.9014 14.8948 12.4625 14.9999 12 14.9999C10.3431 14.9999 9 13.6568 9 11.9999C9 11.5372 9.10518 11.0984 9.29254 10.7068L7.17775 8.59197Z"
        fill="#404542"
      />
      <path
        d="M12 5C11.6182 5 11.2422 5.02535 10.8731 5.07256C10.3253 5.14264 9.938 5.64355 10.0081 6.19137C10.0782 6.73919 10.5791 7.12648 11.1269 7.0564C11.4156 7.01946 11.7068 7 12 7C12.5581 7 13.108 7.07073 13.6448 7.19687C16.0949 7.77258 18.2722 9.50251 19.7084 10.9303C20.1998 11.4189 20.253 11.4942 20.3169 11.69C20.3169 11.69 20.3198 11.6992 20.3236 11.7174C20.3274 11.7358 20.3315 11.76 20.3353 11.7899C20.3429 11.8505 20.3474 11.9228 20.3474 11.9997C20.3473 12.0766 20.3427 12.1489 20.3351 12.2097C20.3313 12.2396 20.3271 12.2639 20.3233 12.2824C20.3214 12.2915 20.3197 12.2985 20.3184 12.3034L20.3165 12.3102C20.2526 12.5061 20.1991 12.5819 19.7068 13.0712C19.446 13.3304 19.1558 13.6046 18.8396 13.8827C18.4249 14.2475 18.3844 14.8794 18.7491 15.2941C19.1138 15.7088 19.7457 15.7493 20.1604 15.3845C20.5102 15.0769 20.8301 14.7746 21.1167 14.4897L21.1733 14.4336C21.6006 14.0097 21.9926 13.621 22.2179 12.9306C22.3161 12.6297 22.3472 12.2857 22.3474 12.0006C22.3475 11.7156 22.3166 11.3712 22.2183 11.0698C21.9933 10.3798 21.6017 9.99138 21.1758 9.56885L21.1184 9.51193C19.3104 7.7145 15.9561 5 12 5Z"
        fill="#404542"
      />
    </svg>
  )
}
export default Hide
