import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TResponseVacancyData, TVacancy } from '../../common/api/vacancy'
import { TPaginationRequest } from '../../common/types/pagination'

export const getVacancies = createAction<TPaginationRequest>('managementVacancies/getVacancies')
export const setVacancies = createAction<TResponseVacancyData | null>('managementVacancies/setRequests')

export const getDictionaries = createAction<TDictionariesNames>('managementVacancies/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('managementVacancies/dictionaries/setDictionaries')
export const setLoading = createAction<boolean>('managementVacancies/setLoading')

export const setOpenModalChangeStatus = createAction<boolean>('managementVacancies/setOpenModalChangeStatus')
export const setSelectedVacancyId = createAction<number>('managementVacancies/setSelectedVacancyId')

export const setSelectedInterviewId = createAction<number | null>('managementVacancies/setSelectedVacancyId')

export const setDuplicateStatusOpen = createAction<boolean>('managementVacancies/setDuplicateStatusOpen')

export const setSelectedVacancy = createAction<TVacancy | null>('managementVacancies/setSelectedVacancy')
export const setOpenModalEditVacancy = createAction<boolean>('managementVacancies/setOpenModalEditRequest')

export type TRequestVacancyCSV = {
    has_head: boolean
    filter:string
  }
export const exportCSV = createAction<TRequestVacancyCSV>('managementVacancies/exportCSV')

export const reset = createAction('managementVacancies/reset')


export type TDefDataVacancy = {
  page: number,
  perPage: number,
  filters: string,
  search:string,
  status:string
}

export const setDefDataVacancy = createAction<TDefDataVacancy>('managementVacancies/setDefDataVacancy')

export const resetAllVacancy = createAction('managementVacancies/resetAllVacancy')

export type TRequestHeaders = {
current:number,
total:number
totalCount: number
}

export const setHeaders = createAction<TRequestHeaders>('managementVacancies/setHeaders')