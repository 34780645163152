import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './Templates.scss'
import { selectTemplates, selectTemplatesForm } from '../selectors'
import {
  deleteTemplate, getTemplates, openTemplatesForm, reset, setActiveTemplate
} from '../actions'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import ControlButton from '../../../common/components/Buttons/Button'
import TemplatesForm from './TemplatesForm'
import TrashFull from '../../../common/svg/TrashFull'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = getCssClassName('systems-templates')

function Templates() {
  const dispatch = useDispatch()
  const templatesForm = useSelector(selectTemplatesForm)
  const templates = useSelector(selectTemplates)
  const { rbacControl } = useEntityContext()

  const handleEditTemplates = (item:any) => {
    dispatch(setActiveTemplate(item))
    dispatch(openTemplatesForm(true))
  }

  const handleDeleteTemplate = (id:number) => {
    dispatch(deleteTemplate(id))
  }

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(getTemplates())
  }, [])

  return (
    <div className={mainCssClass}>
      { !templatesForm && (
        <div className={`${mainCssClass}_create`}>
          <Typography.Title level={3}>
            Шаблоны
          </Typography.Title>
          {rbacControl?.template.create_access === 1 && (
            <ControlButton typeButton="secondary" size="large" icon="left" className="button" onClick={() => dispatch(openTemplatesForm(true))}>
              <AddPlusSvg />
              Создать шаблон
            </ControlButton>
          )}
          <div className={`${mainCssClass}_create_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_create_templates-list`}>
                {
                  templates?.map((item) => (
                    <div
                      className={`${mainCssClass}_create_templates-list_item`}
                    >
                      <div className={`${mainCssClass}_create_templates-list_item_text`}>
                        <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                          {item.name}
                        </Typography.Text>
                      </div>
                      <div className={`${mainCssClass}_create_templates-list_item_action`}>
                        {rbacControl?.template.edit_view_access === 1 && (
                          <ControlButton typeButton="secondary" className="button" onClick={() => handleEditTemplates(item)}>
                            <EditPencil1 />
                          </ControlButton>
                        )}
                        {rbacControl?.template.edit_view_access === 1 && (
                          <ControlButton typeButton="secondary" className="button" onClick={() => handleDeleteTemplate(item.id)}>
                            <TrashFull />
                          </ControlButton>
                        )}
                      </div>
                    </div>
                  ))
                }

              </div>
            </NewScrollContainer>
          </div>
        </div>
      )}

      {templatesForm && (rbacControl?.template.create_access === 1 || rbacControl?.template.edit_view_access === 1) && (
        <TemplatesForm />
      )}
    </div>
  )
}
export default memo(withErrorBoundary(Templates))
