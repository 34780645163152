import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import $api from '../../../common/api/http'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import './ModalAppointCustomer.scss'
import { appointRecruiter, setEditCustomerValue } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { selectUserInfo } from '../../InnerRouter/selectors'
import { TOption } from '../../../common/types/dictionaries'
import { TRequest } from '../../../common/api/request'
import { selectSelectedRequest } from '../../ManagementRequests/selectors'
import { extractValue, fetchRequestList } from '../../../features/requestModals/utils'
import { createRequest, updateRequest } from '../../../features/requestModals/actions'

const mainCssClass = 'editModalCustomer'

type TFormValues = {
  customer: number[]
}

export type TProps = {
  requestId?: number | null | string
  created_at?: string
  customer?:number[]
  isRequestTable?: boolean
  customer_list:any[]
} & ModalProps

function ModalAppointCustomer({
  onOk, open, className, onCancel, customer_list, requestId, created_at, customer, isRequestTable
}: TProps) {
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const requestFromTable = useSelector(selectSelectedRequest)
  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues: { customer } })
  const customersWithoutCustomerValue = useMemo(() => {
    if (customer && customer_list) {
      const valueWithoutCustomerValue = customer_list.filter((el) => el.id !== customer[0])
      return valueWithoutCustomerValue.length > 0 ? valueWithoutCustomerValue.map((el) => el.id) : []
    }
    return customer_list?.length > 0 ? customer_list.map((el) => el.id) : []
  }, [customer, customer_list])
  const onSubmit = useCallback(() => {
    const values = getValues()
    const customerForm = values.customer ? [values.customer, ...customersWithoutCustomerValue] : customersWithoutCustomerValue
    if (requestId) {
      dispatch(setEditCustomerValue(''))
      dispatch(
        updateRequest({
          data: {
            id: requestId,
            customer: customerForm,

            created_at
          },
          isRequestTable,
          isCurator: userInfo?.department === 175
        })
      )
    }
    onCancel()
  }, [requestId, getValues, created_at, isRequestTable, userInfo?.department, dispatch, customersWithoutCustomerValue])
  useEffect(() => {
    reset({ customer })
  }, [customer])
  return (
    <CommonModal
      open={open}
      onOk={onSubmit}
      centered
      onCancel={onCancel}
      title="Изменить заказчика"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        Выберите нового заказчика
      </div>
      <DebounceSelectFormControl
        name="customer"
        control={control}
        placeholder="Выберите заказчика"
        fetchOptions={fetchRequestList}
        notFoundContent="Начните вводить имя"
        label="Заказчик"
        rules={{ required: true }}
        required
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalAppointCustomer))
