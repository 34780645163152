import { PayloadAction } from '@reduxjs/toolkit'
import {put, race, select, take, takeLatest} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios/index'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  TRequestInterviewsCSV,
  TRequestUpdateConfirmInterview,
  exportCSV,
  getDictionaries, getInterviews, getWorkload, setDictionaries, setHeaders, setInterviews, setLoading, setWorkload, updateInterview
} from './actions'
import {
  fetchGetInterviews, fetchGetWorkload, fetchPatchInterview, fetchPostCreateInterviewCSV, TInterviewsResponse, TRequestWorkload
} from '../../common/api/interviews'
import { TPaginationRequest } from '../../common/types/pagination'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { selectDefDataInterview } from './selectors'
import { setLoadingProgress } from '../InnerRouter/actions'
import {fetchPostDuplicatesCandidates} from "../../common/api/Candidate";
import {mergeDuplicates} from "../ManagamentCandidates/actions";

const moduleName = 'Интервью таблица'
function* getDictionariesSaga(payload : PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'интервью таблица')
}
function* getInterviewsSaga({ payload }:PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TInterviewsResponse>;
    ({response} = yield race({
      response: fetchGetInterviews(payload),
      cancel: take(getInterviews.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить интервью',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setInterviews(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TRequestInterviewsCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: fetchPostCreateInterviewCSV(payload),
      cancel: take(exportCSV.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить файл',
          error: response as AxiosError
        })
      )
    } else {
      // const blob = new Blob([response.data], { type: 'text/csv' })
      // const url = window.URL.createObjectURL(blob)
      // const link = document.createElement('a')
      // link.href = url
      // link.download = 'filename'
      // link.click()
      // window.URL.revokeObjectURL(url)

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateInterviewSaga({ payload }: PayloadAction<TRequestUpdateConfirmInterview>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any;
    ({response} = yield race({
      response: fetchPatchInterview(payload),
      cancel: take(updateInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось подтвердить интервью',
          error: response as AxiosError
        })
      )
    } else {
      const data = yield select(selectDefDataInterview)
      if (data) {
        yield put(getInterviews({
          page: data.page, perPage: data.perPage, filters: data.filters, self: data.self
        }))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getWorkloadSaga({ payload }: PayloadAction<TRequestWorkload>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any;
    ({response} = yield race({
      response: fetchGetWorkload(payload),
      cancel: take(getWorkload.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить раздел загруженность',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setWorkload(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

export function* managementInterviewsSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getInterviews, getInterviewsSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
  yield takeLatest(updateInterview, updateInterviewSaga)
  yield takeLatest(getWorkload, getWorkloadSaga)

}
