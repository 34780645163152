import { getLogout } from '../compositions/auth/actions'

export function randomNumbersString(length: number) {
  let numbers = 'user'
  for (let i = 0; i < length; i++) {
    numbers += Math.floor(Math.random() * 10)
  }
  return numbers
}

function logout() {
  getLogout()
}

function getTimeUntilNextUpdate() {
  const now = new Date()
  const nextUpdate = new Date()

  nextUpdate.setHours(23 - now.getTimezoneOffset() / 60, 0, 0, 0)

  if (now.getHours() >= 23) {
    nextUpdate.setDate(nextUpdate.getDate() + 1)
  }

  // @ts-ignore
  return nextUpdate.getTime() - now.getTime()
}

// Установить таймер для принудительного обновления страницы
export function setUpdateTimerToLogout() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const timeUntilNextUpdate = getTimeUntilNextUpdate()

  setTimeout(() => {
    logout()
    localStorage.removeItem('token')
    localStorage.removeItem('token_refresh')
    localStorage.removeItem('user_id')
  }, timeUntilNextUpdate)
}

// Запуск таймера при загрузке страницы
