import { createReducer } from '@reduxjs/toolkit'
import {
  TTask, TTasks, openMoreInfoModal, openTaskCreateModal, openTasksFilter,
   reset, setDictionaries, setFilter, setIsArchiveTask, setNotifyTask, setSelectTask, setTasks
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'

type TTasksState = {
  createModal: boolean
  tasks:TTasks[]
  tasksFilter:boolean
  moreInfoModal:boolean
  selectTask: TTask | null
  filter: string
  is_archive: boolean
  dictionaries: TDictionaries | null
  notifyTask: TTask | null
}

const initialState: TTasksState = {
  createModal: false,
  moreInfoModal: false,
  tasks: [],
  tasksFilter: false,
  selectTask: null,
  filter: '',
  is_archive: false,
  dictionaries: null,
  notifyTask: null
}

const tasksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openTaskCreateModal, (state, { payload }) => {
      state.createModal = payload
    })
    .addCase(setSelectTask, (state, { payload }) => {
      state.selectTask = payload
    })
    .addCase(openMoreInfoModal, (state, { payload }) => {
      state.moreInfoModal = payload
    })
    .addCase(setTasks, (state, { payload }) => {
      state.tasks = payload
    })
    .addCase(openTasksFilter, (state, { payload }) => {
      state.tasksFilter = payload
    })
    .addCase(setFilter, (state, { payload }) => {
      state.filter = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setIsArchiveTask, (state, { payload }) => {
      state.is_archive = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setNotifyTask, (state, { payload }) => {
      state.notifyTask = payload
    })
})

export default tasksReducer
