import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { Button, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import CommunicationSvg from '../../../common/svg/CommunicationSvg'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { sendMailMessage, sendMessage, setTemplateContent } from '../actions'
import { selectCandidate, selectChatVisible } from '../../../compositions/candidate/selectors'
import './AddMessage.scss'
import { selectMessages, selectTemplateContent } from '../selectors'
import SearchSelectFormControlSourceMobil
  from '../../../common/components/formcontrol/SearchSelectFormControlSourceMobil'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import EditRows from '../../../common/svg/EditRows'
import ControlButton from '../../../common/components/Buttons/Button'
import { getWhatsappTemplates } from '../utils'

interface TFormValues {
  message: string
}

const MainCSSclass = 'addMessage'

function AddMessage() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const chatVisible = useSelector(selectChatVisible)
  const content = useSelector(selectTemplateContent)
  const messageArr = useSelector(selectMessages)
  const [encodeUri, setEncodeUri] = useState<string>('')
  const {
    control, handleSubmit, getValues, reset, watch, setValue
  } = useForm<TFormValues>()
  const message = watch('message')
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [selectedOption, setSelectedOption] = useState(null)
  const [isSelectorVisible, setIsSelectorVisible] = useState(false)
  const [whatsappOptions, setWhatsappOptions] = useState([])
  const handleSendMessage = useCallback(() => {
    if (candidate?.phone) {
      dispatch(sendMessage({ phone: Number(candidate?.phone[0]), message }))
    }
    reset()
  }, [candidate, chatVisible, message])
  const
    isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])
  const handleSendEmailMessage = useCallback(() => {
    if (candidate?.email) {
      dispatch(sendMailMessage({ email: candidate?.email[0], ...getValues(), candidate_id: candidate.id }))
    }
    reset()
  }, [
    candidate, chatVisible, content
  ])
  const handleTemplateContent = (content) => {
    dispatch(setTemplateContent(content))
  }
  const handleSelectChange = (value) => {
    setSelectedOption(value)
    setValue('message', value)
    setIsSelectorVisible(false)
    // Дополнительная логика при изменении выбранной опции
  }

  const toggleSelector = () => {
    setIsSelectorVisible(!isSelectorVisible)
  }

  const setSelectorPositionRelativeToButton = (buttonRect) => {
    const { bottom, left } = buttonRect
    setSelectorPosition({ top: bottom, left })
  }

  const onButtonClick = (e) => {
    toggleSelector()
    const buttonRect = e.target.getBoundingClientRect()
    setSelectorPositionRelativeToButton(buttonRect)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey)) {
      // Если нажата клавиша Enter с Shift или Ctrl, выполнить перенос строки
    } else if (event.key === 'Enter') {
      event.preventDefault() // Предотвратить отправку формы по умолчанию
      if (chatVisible === 'message') {
        handleSendMessage()
      } else {
        handleSendEmailMessage()
      }
    }
  }
  useEffect(() => {
    getWhatsappTemplates().then((data) => setWhatsappOptions(data))
  }, [])

  useEffect(() => {
    if (content) {
      setEncodeUri(encodeURIComponent(content))
      setValue('message', content)
    }
  }, [content])

  return (
    <div className={`${MainCSSclass}`}>
      {isMobile && (
        <div className={`${MainCSSclass}_button-select`}>
          <ControlButton
            className="button"
            size="small"
            typeButton="secondary"
            onClick={toggleSelector}
          >
            <EditRows />
          </ControlButton>
          <Select
            showSearch
            defaultValue={whatsappOptions.length > 0 ? whatsappOptions[0].value : null}
            onChange={handleSelectChange}
            onSelect={handleSelectChange}
            optionFilterProp="children"
            style={{
              display: isSelectorVisible ? 'block' : 'none',
              position: 'absolute',
              bottom: '100%',
              left: '0',
              width: '200px',
              zIndex: '999'
            }}
          >
            {whatsappOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
      <TextAreaFormControl
        placeholder="Текст сообщения"
        name="message"
        control={control}
        className={`${MainCSSclass}_input`}
        autoSize={{ minRows: 1, maxRows: 4 }}
        onKeyDown={handleKeyDown}
        disabled={!messageArr?.length}

      />
      <Button onClick={chatVisible ? handleSendMessage : handleSendEmailMessage}>
        <CommunicationSvg />
        Отправить
      </Button>
    </div>
  )
}

export default memo(withErrorBoundary(AddMessage))
