import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import TextAreaFormControl from '../../../../common/components/formcontrol/TextAreaFormControl'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import CloseMD from '../../../../common/svg/CloseMD'
import CheckBig from '../../../../common/svg/CheckBig'
import { TRequest } from '../../../../common/api/request'
import { setEditKeyDetailList } from '../../../../compositions/InnerRouter/actions'
import { selectDictionaryOptionsByName } from '../../selectors'
import { getResponsibleList, updateRequest } from '../../../../features/requestModals/actions'
import { selectUserInfo } from '../../../../compositions/InnerRouter/selectors'
import SelectFormControlMult from '../../../../common/components/formcontrol/SelectFormControlMult'
import { fetchRequestList } from '../../../../features/requestModals/utils'
import DebounceSelectFormControl from '../../../../common/components/formcontrol/DebounceSelectFormControl'
import InputFormControl from '../../../../common/components/formcontrol/InputFormControl'
import SwitchFormControl from '../../../../common/components/formcontrol/SwitchFormControl'
import SearchSelectFormControlSource from '../../../../common/components/formcontrol/SearchSelectFormControlSource'
import { checkObj, copyObj } from '../../utils'

import './RequestDetailListEdit.scss'
import { selectResponsibleList } from '../../../../features/requestModals/selectors'

const mainCssClass = getCssClassName('request-detailList-edit')

function RequestDetailListEdit({ request, editKey, customer } : { request: TRequest | null, editKey: string, customer?: any }) {
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)

  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const characteristicOptions = useSelector(selectDictionaryOptionsByName('requestCandidateCharacteristic'))
  const responsibleOptions = useSelector(selectResponsibleList)

  const {
    control, watch, handleSubmit, formState: { errors }
  } = useForm()

  const handleCloseEditMode = () => {
    dispatch(setEditKeyDetailList(''))
  }

  const onSubmit = () => {
    if (request) {
      dispatch(updateRequest({
        data: {
          id: request.id,
          created_at: request?.created_at,
          [editKey]: checkObj(watch(editKey))
        },
        isRequestTable: false,
        isCurator: userInfo?.department === 175
      }))
    }
  }

  const onSubmitSwitch = () => {
    if (request) {
      dispatch(updateRequest({
        data: {
          id: request.id,
          created_at: request?.created_at,
          [editKey]: !!watch(editKey)
        },
        isRequestTable: false,
        isCurator: userInfo?.department === 175
      }))
    }
  }

  const onSubmitCustomer = () => {
    const actualCustomer = watch('customer')
    if (request) {
      dispatch(updateRequest({
        data: {
          id: request.id,
          created_at: request?.created_at,
          customer: request.customer_list.map((el) => (el.id === customer.id ? (actualCustomer.value || actualCustomer) : el.id))
        },
        isRequestTable: false,
        isCurator: userInfo?.department === 175
      }))
    }
  }

  const renderSchedule = () => (
    <div className={mainCssClass}>
      <SelectFormControlMult
        rules={{ required: true }}
        control={control}
        name="schedule_id_list"
        defaultValue={request?.schedule_list?.map((el) => el.id)}
        options={sheduleOptions}
      />
      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderResponsible = () => (
    <div className={mainCssClass}>
      <DebounceSelectFormControl
        placeholder="Поиск по имени..."
        fetchOptions={fetchRequestList}
        style={{ width: '100%' }}
        rules={{ required: true }}
        name="responsible"
        control={control}
        defaultValue={
          {
            value: request?.responsible,
            label: `${request?.responsible_list?.name} ${request?.responsible_list.surname} ${request?.responsible_list.email}`
          }
        }
      />
      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderRewardAverageDay = () => (
    <div className={mainCssClass}>
      {errors.reward_average_day ? (
        <Tooltip placement="bottom" title="Введите не менее 4 цифр для заполнения">
          <InputFormControl
            name="reward_average_day"
            control={control}
            rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
            defaultValue={request?.reward_average_day}
          />
        </Tooltip>
      ) : (
        <InputFormControl
          name="reward_average_day"
          control={control}
          defaultValue={request?.reward_average_day}
          rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
        />
      )}
      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderRewardAverageMonth = () => (
    <div className={mainCssClass}>
      <InputFormControl
        name="reward_average_month"
        control={control}
        rules={{
          pattern: /^[0-9\s,-]*$/
        }}
        defaultValue={request?.reward_average_month}
      />
      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderDescription = () => (
    <div className={mainCssClass}>
      <TextAreaFormControl
        name="description"
        control={control}
        rules={{ required: true }}
        defaultValue={request?.description}
      />
      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderSwitch = () => (
    <div className={mainCssClass}>
      <SwitchFormControl control={control} name={editKey} defaultChecked={request[editKey]} />
      <CheckBig onClick={handleSubmit(onSubmitSwitch)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderCharacteristicList = () => (
    <div className={mainCssClass}>
      <SelectFormControlMult
        mode="multiple"
        control={control}
        defaultValue={request?.characteristic_list}
        name="characteristic_list"
        options={characteristicOptions}
      />

      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderResponsibleIdList = () => (
    <div className={mainCssClass}>
      <SearchSelectFormControlSource
        control={control}
        options={responsibleOptions}
        mode="multiple"
        showSearch
        name="responsible_id_list"
        defaultValue={request?.responsible_id_list.map((el) => el.id)}
      />

      <CheckBig onClick={handleSubmit(onSubmit)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderCustomer = () => (
    <div className={mainCssClass}>
      <DebounceSelectFormControl
        placeholder="Поиск по имени..."
        fetchOptions={fetchRequestList}
        style={{ width: '100%' }}
        rules={{ required: true }}
        name="customer"
        control={control}
        defaultValue={
          { value: customer.id, label: `${customer?.name || ''} ${customer?.surname || ''} ${customer?.email}` }
        }
      />
      <CheckBig onClick={handleSubmit(onSubmitCustomer)} />
      <CloseMD onClick={handleCloseEditMode} />
    </div>
  )

  const renderContent = () => {
    switch (editKey) {
    case 'schedule_id_list':
      return renderSchedule()
    case 'responsible':
      return renderResponsible()
    case 'reward_average_day':
      return renderRewardAverageDay()
    case 'description':
      return renderDescription()
    case 'reward_average_month':
      return renderRewardAverageMonth()
    case 'characteristic_list':
      return renderCharacteristicList()
    case 'is_student':
    case 'is_patent':
    case 'is_internship':
      return renderSwitch()
    case 'responsible_id_list':
      return renderResponsibleIdList()
    case 'customer0':
    case 'customer1':
    case 'customer2':

      return renderCustomer()
    default:
      return null
    }
  }

  useEffect(() => {
    dispatch(getResponsibleList())
  }, [])

  return (
    <>
      {renderContent()}
    </>
  )
}

export default RequestDetailListEdit
