import {
  put, select, spawn, takeLatest
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse, AxiosError } from 'axios'
import {
  changeStatusVacancyModal,
  createVacancy,
  duplicateVacancyName,
  TChangeStatusVacancyModal,
  TDuplicateVacancyName,
  updateVacancy
} from './actions'

import {
  fetchPatchChangeStatusVacancy,
  fetchPatchUpdateVacancy,
  fetchPostCreateVacancy,
  fetchPostDuplicateVacancy,
  TPatchVacancy,
  TRequestCreateVacancy,
  TResponseDuplicateVacancy,
  TVacancy
} from '../../common/api/vacancy'
import {
  getVacancies,
  setDuplicateStatusOpen,
  setOpenModalChangeStatus,
  setOpenModalEditVacancy
} from '../../compositions/ManagementVacancies/actions'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { openCreateVacancyModal } from '../../compositions/Funnel/actions'
import { selectDefDataVacancy } from '../../compositions/ManagementVacancies/selectors'
import { setLoadingModal, setLoadingProgress } from '../../compositions/InnerRouter/actions'
import { TResponse } from '../../common/types/response'
import { TDefDataVacancy } from '../../compositions/ManagementVacancies/reducer'

type TVacancyResponseDuplicate = AxiosResponse<TResponseDuplicateVacancy>
type TResponseVacancy = AxiosResponse<TResponse<TVacancy>>

const moduleName = 'Страница вакансии'
function* duplicateVacancySaga({ payload }: PayloadAction<TDuplicateVacancyName>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TVacancyResponseDuplicate = yield fetchPostDuplicateVacancy(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось дублировать вакансию',
          error: response
        })
      )
    } else {
      const data = yield select(selectDefDataVacancy)
      if (data) {
        yield put(getVacancies({ page: data.page, perPage: data.perPage, filters: data.filters }))
      }
      yield put(setDuplicateStatusOpen(false))
      showSuccessNotification({
        text: 'Вакансия дублирована'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}
function* changeStatusVacancySaga({ payload: { status, id } }: PayloadAction<TChangeStatusVacancyModal>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TVacancyResponseDuplicate = yield fetchPatchChangeStatusVacancy(id, status)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статус вакансии',
          error: response
        })
      )
    } else {
      const data: TDefDataVacancy = yield select(selectDefDataVacancy)
      if (data) {
        yield put(getVacancies({ page: data.page, perPage: data.perPage, filters: data.filters }))
      }
      yield put(setOpenModalChangeStatus(false))
      showSuccessNotification({
        text: 'Статус вакансии изменён'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* createVacancySaga({ payload }: PayloadAction<TRequestCreateVacancy>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TResponseVacancy = yield fetchPostCreateVacancy(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать вакансию',
          error: response
        })
      )
    } else {
      yield put(openCreateVacancyModal(false))

      showSuccessNotification({
        text: 'Вакансия создана'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* updateVacancySaga({ payload: { data, isVacancyTable } }: PayloadAction<TPatchVacancy>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TResponseVacancy = yield fetchPatchUpdateVacancy(data)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить вакансию',
          error: response
        })
      )
    } else {
      if (isVacancyTable) {
        const data = yield select(selectDefDataVacancy)
        if (data) {
          yield put(getVacancies({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
        yield put(setOpenModalEditVacancy(false))
      }
      showSuccessNotification({
        text: 'Вакансия обновлена'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

export function* modalsVacanciesSaga() {
  yield takeLatest(duplicateVacancyName, duplicateVacancySaga)
  yield takeLatest(changeStatusVacancyModal, changeStatusVacancySaga)
  yield takeLatest(createVacancy, createVacancySaga)
  yield takeLatest(updateVacancy, updateVacancySaga)
}
