import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { duplicateVacancyName } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './DuplicateVacancyModal.scss'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import { TVacancy } from '../../../common/api/vacancy'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'duplicate-vacancy-modal'

type TProps = {
    vacancy?: TVacancy | null
} & ModalProps
// @ts-ignore
function DuplicateVacancyModal({
  open, className, onCancel, vacancy
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()

    type TFormValues = {
      name: string
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }

    const handleSubmit = useCallback(() => {
      if (vacancy) {
        const name = watch('name')
        if (name) {
          // @ts-ignore

          dispatch(duplicateVacancyName({
            ...vacancy,
            label: name
          }))
        }
      }
    }, [vacancy])

    return (
      <CommonModal
        centered
        open={open}
        onOk={handleSubmit}
        onCancel={handleModalClose}
        title="Дублировать вакансию"
        className={cn(className, mainCssClass)}
        width="400px"
        typeButtonAccept="primary"
        okText="Применить"
        cancelText="Отменить"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >
        <InputFormControl
          name="name"
          control={control}
          label="Название новой вакансии"
          required
          rules={{ required: true }}
        />
      </CommonModal>
    )
}

export default DuplicateVacancyModal
