import {
  call, delay, put, race, select, take, takeLatest
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { log } from '@rspack/core/compiled/neo-async'
import {
  getStatusesMobiles,
  getUserInfo,
  setLoading,
  setRbacInfo,
  setStatusesMobiles,
  setUserInfo,
  TUpdateStatusesMobiles,
  updateStatusesMobiles,
  setVersionFront,
  showOpenModalFrontRefreshByVersion,
  getVersionFront,
  setCurrentStatus,
  getCurrentStatus,
  getInternalAutoCall,
  getInternalInformationByUserId,
  TInternalInformation,
  setInternalInformationByUserId,
  setModalAutoCallOpen,
  setModalAutoCallId, getStartAutoCall, getStopAutoCall, setOptionsAutoCall, setLoadingAutoCall, getTransferNumbers, setLoadingProgress,
  setUserActivity,
  getUserActivity,
  changeUserActivity, setCurrentRtt, getCurrentRtt
} from './actions'
import {
  fetchGetAutoCall, fetchGetAutoCallStart, fetchGetAutoCallStop,
  fetchGetCurrentStatusUserMobile, fetchGetInternalInformationByUserId, fetchGetPhoneBookList, fetchGetRtt,
  fetchGetStatusesMobile,
  fetchGetUserActivity,
  fetchGetUserInfo,
  fetchPostChangeUserActivity,
  fetchPostStatusMobile
} from '../../common/api/user'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { fetchGetVersion } from '../../common/api/systems'
import { selectVersionFront } from './selectors'
import { navigateTo } from '../../common/actions/navigate'
import { TUserActivityStatus } from './types'
import { fetchGetCandidateHistoryV2 } from '../../common/api/Candidate'
import { getHistoryCandidateV2 } from '../candidateV2/actions'

const moduleName = 'сотрудник'
function* getUserInfoSaga({ payload }: PayloadAction<boolean>) {
  try {
    yield put(setLoading(true))
    const { response } = yield race({
      response: fetchGetUserInfo(),
      cancel: take(getUserInfo.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      if (localStorage.getItem('redirectUrl')) {
        yield delay(2000)
      }
      yield put(setUserInfo(response.data.DATA))

      const jsonString = response.data.Headers?.rbac[0].replace(/\\/g, '')
      let rbacModelObject
      try {
        rbacModelObject = JSON.parse(jsonString)
      } catch (parseError) {
        console.error('Error parsing RBAC model:', parseError)
      }

      if (payload && window.location.pathname === '/candidates') {
        yield put(navigateTo(`/${rbacModelObject.main_page}`))
      }
      yield put(setRbacInfo(rbacModelObject))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
  }
}

function* getVersionFrontSaga() {
  try {
    yield put(setLoading(true))
    const { response } = yield race({
      response: fetchGetVersion(),
      cancel: take(getVersionFront.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить версию фронта',
          error: response
        })
      )
    } else {
      const version = yield select(selectVersionFront)
      if (version && response.data.DATA && version !== response.data.DATA) {
        yield put(showOpenModalFrontRefreshByVersion(true))
      }
      yield put(setVersionFront(response.data.DATA))
    }
  } catch (error) {
    throw Error(error)
  }
}
function* getUserStatusesMobileSaga() {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchGetStatusesMobile(),
      cancel: take(getStatusesMobiles.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить статусы',
          error: response
        })
      )
    } else {
      console.log(response.data.DATA)
      yield put(setStatusesMobiles(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateUserStatusMobileSaga({ payload }: PayloadAction<TUpdateStatusesMobiles>) {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostStatusMobile(payload),
      cancel: take(updateStatusesMobiles.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить статус',
          error: response
        })
      )
    } else {
      yield put(setCurrentStatus({ id: response.data.DATA.status_id }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCurrentStatusUserSaga() {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetCurrentStatusUserMobile(),
      cancel: take(getCurrentStatus.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить статус',
          error: response
        })
      )
    } else {
      console.log(response.data.DATA)
      yield put(setCurrentStatus(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* getInternalInformationByUserIdSaga() {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchGetInternalInformationByUserId(),
      cancel: take(getInternalInformationByUserId.type)
    })
    if (response?.data?.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить статистику звонков',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setInternalInformationByUserId(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getInternalAutoCallSaga() {
  try {
    yield put(setLoadingAutoCall(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetAutoCall(),
      cancel: take(getInternalAutoCall.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить списоков автообзвонов',
          error: response
        })
      )
    } else {
      yield put(setOptionsAutoCall(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingAutoCall(false))
    yield put(setLoadingProgress(false))
  }
}

function* getInternalAutoCallStartSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetAutoCallStart(payload),
      cancel: take(getStartAutoCall.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось запустить автообзвон',
          error: response
        })
      )
    } else {
      yield put(setModalAutoCallOpen(false))
      yield put(setModalAutoCallId(payload))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* getInternalAutoCallStopSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetAutoCallStop(payload),
      cancel: take(getStopAutoCall.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось остановить автообзвон',
          error: response
        })
      )
    } else {
      yield put(setModalAutoCallId(null))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getTransferNumbersSaga() {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetPhoneBookList(),
      cancel: take(getTransferNumbers.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось остановить автообзвон',
          error: response
        })
      )
    } else {
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getUserActivitySaga() {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchGetUserActivity(),
      cancel: take(getUserActivity.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить статус работы',
          error: response
        })
      )
    } else {
      yield put(setUserActivity(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* changeUserActivitySaga({ payload }: PayloadAction<TUserActivityStatus>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({ response } = yield race({
      response: fetchPostChangeUserActivity(payload),
      cancel: take(changeUserActivity.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статус работы',
          error: response
        })
      )
    } else {
      yield put(setUserActivity(response.data.DATA))
    }
  } catch (error: unknown) {
    console.log(error)
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCurrentRttSaga() {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchGetRtt(),
      cancel: take(getCurrentRtt.type)
    })
    if (response?.data?.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить качество связи',
          error: response
        })
      )
    } else {
      console.log(response, 'resp!')
      yield put(setCurrentRtt(response?.data))
    }
  } catch (error: unknown) {
    console.log(error)
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* innerRouterSaga() {
  yield takeLatest(getUserInfo, getUserInfoSaga)
  yield takeLatest(getStatusesMobiles, getUserStatusesMobileSaga)
  yield takeLatest(updateStatusesMobiles, updateUserStatusMobileSaga)
  yield takeLatest(getVersionFront, getVersionFrontSaga)
  yield takeLatest(getCurrentStatus, getCurrentStatusUserSaga)
  yield takeLatest(getInternalAutoCall, getInternalAutoCallSaga)
  yield takeLatest(getInternalInformationByUserId, getInternalInformationByUserIdSaga)
  yield takeLatest(getStartAutoCall, getInternalAutoCallStartSaga)
  yield takeLatest(getStopAutoCall, getInternalAutoCallStopSaga)
  yield takeLatest(getTransferNumbers, getTransferNumbersSaga)
  yield takeLatest(getUserActivity, getUserActivitySaga)
  yield takeLatest(changeUserActivity, changeUserActivitySaga)
  yield takeLatest(getCurrentRtt, getCurrentRttSaga)
}

export default innerRouterSaga
