/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { endInterviewModals } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import { updateCandidate, updateInterview } from '../../../compositions/InterviewCard/actions'
import { TInterview } from '../../../common/api/interviews'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { updateStage } from '../../../compositions/candidate/actions'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import { SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import { createArrayOfTimeObjects } from '../../../compositions/candidate/utils'
import { selectCandidate } from '../../../compositions/InterviewCard/selectors'
import './EndInterviewModals.scss'

const mainCssClass = 'end-interview-modals'

type TFormValues = {
  result: number
  note: number
  candidateCharacteristic:number
  stage: number
  date: string
  rejection_reason: number
  time: string
}

type TProps = {
  optionsResult: TOption[]
  optionsNote:TOption[]
  interview?: TInterview | null
  optionsReason: TOption[]
  optionsCharacteristic: TOption[]
  stageOptions: TOption[]
  stage: any
} & ModalProps
function EndInterviewModals({
  open, className, onCancel, optionsResult, optionsNote, interview, optionsReason, optionsCharacteristic, stageOptions, stage
}: TProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const [timeOPtions, setTimeOptions] = useState(createArrayOfTimeObjects())

  const defaultValues = useMemo(
    () => (
      {
        result: interview?.result,
        note: interview?.note,
        candidateCharacteristic: interview?.characteristic.id,
        date:
        interview?.result === 98 ? (interview.date_registration || null) : interview?.result === 99 ? (candidate?.date_internship || null) : interview?.date ? interview.date : null,
        stage,
        time: interview?.result === 99 ? candidate?.time_internship : ''
      }),
    [interview, candidate]
  )
  const {
    control, watch, getValues, reset, handleSubmit
  } = useForm<TFormValues>({ defaultValues })

  const result = watch('result')
  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
      reset()
    }
  }

  const note = watch('note')
  const date = watch('date')
  const time = watch('time')
  const characteristic = watch('candidateCharacteristic')
  const rejection_reason = watch('rejection_reason')

  const onSubmit = useCallback(() => {
    if (interview) {
      dispatch(updateInterview({
        ...interview,
        status: 95,
        result,
        rejection_reason,
        note,
        characteristic
      }))
    }
    if (interview && note && result === 98) {
      dispatch(updateCandidate({ id: interview.candidate_id, characteristic, date_registration: date ? dayjs(date).format(SERVER_DATE_FORMAT) : '' }))
    } else if (interview && note && result === 99) {
      dispatch(updateCandidate({
        id: interview.candidate_id, characteristic, date_internship: date ? dayjs(date).format(SERVER_DATE_FORMAT) : '', time_internship: time || ''
      }))
    } else if (interview && note && result === 256) {
      dispatch(updateCandidate({ id: interview.candidate_id, characteristic }))
    } else if (interview && note) {
      dispatch(updateCandidate({ id: interview.candidate_id, characteristic }))
    }
    // if (interview && watch('stage')) {
    //   dispatch(updateStage({
    //     stage: watch('stage'),
    //     candidate_id: interview.candidate_id
    //   }))
    // }
  }, [interview, result, note, date, characteristic, rejection_reason, time])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleModalClose}
      title={interview?.result ? 'Редактировать результат интервью' : 'Завершить интервью'}
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Сохранить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >

      <SelectFormControl
        name="result"
        control={control}
        label="Результат"
        placeholder="Выберите результат"
        options={optionsResult}
        required
        rules={{ required: true }}
      />

      {result === 256 && (

        <SearchSelectFormControlSource
          name="rejection_reason"
          control={control}
          label="Причины отказа"
          placeholder="Выберите причину отказа"
          options={optionsReason}
          required
          rules={{ required: true }}
          showSearch
          optionFilterProp="children"
        />
      )}
      <InputFormControl
        name="note"
        control={control}
        label="Примечание"
        rules={{ required: true }}
        placeholder="Введите примечание"
        required
      />

      {(result === 98 || result === 99) && (
        <DatePickerFormControl
          name="date"
          label={result === 99 ? 'Выберите дату стажировки' : 'Выберите дату оформления'}
          placeholder="Дата"
          control={control}
          tasks
          isDisableDate
          rules={{ required: true }}
        />
      )}
      {result === 99 && (
        <SelectFormControl
          name="time"
          label="Время стажировки"
          control={control}
          options={timeOPtions}
        />
      )}
      <SelectFormControl
        name="candidateCharacteristic"
        control={control}
        label="Характеристика"
        options={optionsCharacteristic}
        required
        rules={{ required: true }}
      />
    </CommonModal>
  )
}

export default EndInterviewModals
