import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import './VacancyNameTable.scss'
import { useDispatch } from 'react-redux'
import ApproveEdit from '../../../common/svg/ApproveEdit'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { navigateTo } from '../../../common/actions/navigate'

type TProps = {
  name: string
  favorite: boolean
  id: string | number | null
}
const mainCssClass = 'vacancy-name-table'
const VacancyNameTable: FC<TProps> = ({ name, favorite, id }) => {
  const dispatch = useDispatch()
  const handleNavigate = useCallback(() => {
    if (id) {
      // dispatch(navigateTo(`/vacancy/${id}`))
    }
  }, [id])
  return (
    <div className={mainCssClass} onClick={handleNavigate}>
      {favorite && <ApproveEdit />}
      <Typography.Text>{name}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(VacancyNameTable))
