import { createReducer } from '@reduxjs/toolkit'
import { request } from 'axios'
import {
  setRequests,
  setLoading,
  setFilter,
  reset,
  setFilteredRequests,
  setZoomCoordinates,
  setOpenDetailsModal,
  setDictionaries,
  setSelectedRequest,
  setFiltersOptions,
  TFiltersOptions, setPreviousFilter, setFilteredPreviousRequests, setSlicedRequests, setMapBounds, setTotalCountsOfSliced, setPage,
  setStoreStatus
} from './actions'
import { TRequest } from '../../common/api/request'
import { TDictionaries } from '../../common/types/dictionaries'

type TMapState = {
  requests: TRequest[]
  loading: boolean
  filter: string
  dictionaries: TDictionaries | null
  filteredRequests: TRequest[]
  zoomCoordinates: number[]
  openDetailsModal: boolean
  selectedRequest: TRequest | null
  filterOptions: TFiltersOptions | null
  previousFilter: string
  filteredRequestsPrevious: TRequest[] | null
  slicedRequests: TRequest[] | null
  mapBounds: any
  totalCountsOfSliced: number
  page: number
  store: boolean
  storeStatus: string
}

const initialState: TMapState = {
  requests: [],
  loading: false,
  filter: '',
  filteredRequests: [],
  zoomCoordinates: [],
  openDetailsModal: false,
  dictionaries: null,
  selectedRequest: null,
  filterOptions: null,
  previousFilter: '',
  filteredRequestsPrevious: null,
  slicedRequests: [],
  mapBounds: null,
  totalCountsOfSliced: 0,
  page: 1,
  store: false,
  storeStatus: ''
}

const mapReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRequests, (state, { payload }) => {
      state.requests = payload
    })
    .addCase(setFilteredRequests, (state, { payload }) => {
      state.filteredRequests = payload
    })
    .addCase(setSlicedRequests, (state, { payload }) => {
      state.slicedRequests = payload
    })
    .addCase(setFilteredPreviousRequests, (state, { payload }) => {
      state.filteredRequestsPrevious = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setFilter, (state, { payload }) => {
      state.filter = payload
    })
    .addCase(setPage, (state, { payload }) => {
      state.page = payload
    })
    .addCase(setMapBounds, (state, { payload }) => {
      state.mapBounds = payload
    })
    .addCase(setTotalCountsOfSliced, (state, { payload }) => {
      state.totalCountsOfSliced = payload
    })
    .addCase(setPreviousFilter, (state, { payload }) => {
      state.previousFilter = payload
    })
    .addCase(setZoomCoordinates, (state, { payload }) => {
      state.zoomCoordinates = payload
    })
    .addCase(setSelectedRequest, (state, { payload }) => {
      state.selectedRequest = payload
    })
    .addCase(setOpenDetailsModal, (state, { payload }) => {
      state.openDetailsModal = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setFiltersOptions, (state, { payload }) => {
      state.filterOptions = payload
    })
    .addCase(setStoreStatus, (state, { payload }) => {
      state.storeStatus = payload
    })
    .addCase(reset, (state) => ({
      ...initialState, requests: state.requests, filteredRequests: state.filteredRequests, filterOptions: state.filterOptions
    }))
})

export default mapReducer
