import cn from 'classnames'

import React from 'react'
import { ErrorCommonComponentProps } from './types'

import './styles.scss'

const mainCssClass = 'errorComponent'
export default function ErrorCommonComponent(props: ErrorCommonComponentProps) {
  const { icon, subtitle, message, actionButton, size = 'lg', title } = props
  return (
    <div className={mainCssClass}>
      {icon && <div className={`${mainCssClass}_icon`}>{icon}</div>}
      {title && (
        <div>
          <h3 className={`${mainCssClass}_title`}>{title}</h3>
        </div>
      )}

      <div className={`${mainCssClass}_message`}>
        {size === 'lg' ? (
          <h4 className={`${mainCssClass}_subtitle`}>{subtitle}</h4>
        ) : (
          <h6 className={`${mainCssClass}_subtitle`}>{subtitle}</h6>
        )}

        {message && <div className={cn(`${mainCssClass}_info`, [`${mainCssClass}_info__${size}`])}>{message}</div>}

        {actionButton}
      </div>
    </div>
  )
}
