import React, {
  memo
} from 'react'
import {
  ModalProps, Drawer, DrawerProps
} from 'antd'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { openAsteriskDrawer, openChatDrawer, openCommunicationDrawer } from '../actions'
import ChevronLeft from '../../../common/svg/ChevronLeft'
import './CommunicationDrawer.scss'
import PhoneSvg from '../../../common/svg/PhoneSvg'
import ChatConversation from '../../../common/svg/ChatConversation'

const mainCssClass = getCssClassName('communication-drawer')

function CommunicationDrawer({
  open, className
}:DrawerProps) {
  const dispatch = useDispatch()

  const handleOnClose = () => {
    dispatch(openCommunicationDrawer(false))
  }

  const handleClickOpenChat = () => {
    dispatch(openChatDrawer(true))
  }

  const handleClickOpenAsterisk = () => {
    dispatch(openAsteriskDrawer(true))
  }

  return (
    <Drawer
      title="Коммуникация"
      onClose={handleOnClose}
      open={open}
      className={cn(className, mainCssClass)}
      contentWrapperStyle={{ width: '100%' }}
      closeIcon={<ChevronLeft />}
    >
      <div className={`${mainCssClass}__list`}>
        <div className={`${mainCssClass}__list_item`} onClick={handleClickOpenChat}>
          <ChatConversation color="#2DBE64" />
          Чат с кандидатом
        </div>
        {/* <div className={`${MainCSSClass}__communication-buttons_item`}>
          <MailSvg />
          Почта с кандидатом
        </div> */}
        <div className={`${mainCssClass}__list_item`} onClick={handleClickOpenAsterisk}>
          <PhoneSvg />
          Звонки
        </div>

      </div>
    </Drawer>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(CommunicationDrawer))
