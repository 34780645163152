import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch } from 'react-redux'
// import {
//   mergeDuplicates,
//   setOpenModalArchive,
//   setOpenModalAssignVacancy,
//   setOpenModalChangeStatus,
//   setSelectedCandidateId,
//   setSelectedStatus
// } from '../actions'
import { useNavigate } from 'react-router'
import MenuDrop from '../../../common/svg/MenuDrop'
import { TMainProps } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import {setOpenModalChangeStatus, setSelectedCandidateId, setSelectedStatus} from "../../ManagamentCandidates/actions";
import {setOpenModalAutoDial} from "../actions";

type TProps = {
  id: string | number | null
  status: string | number | null
  is_duplicates: boolean
}
const ButtonsTable: FC<TProps> = ({ id, status, is_duplicates }) => {
  const dispatch = useDispatch()
  const { rbacControl } = useEntityContext()
  const navigate = useNavigate()
  const handleOpenCandidate = () => {
    navigate(`/candidate/${id}`)
  }
  const handleOpenAutoDial = useCallback(() => {
    dispatch(setOpenModalAutoDial(true))
  }, [])
  const items: MenuProps['items'] = [
    {
      key: 'change-status',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={handleOpenCandidate}
        >
          Страница кандидата
        </Button>
      )
    },
    {
      key: 'auto-dial',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={handleOpenAutoDial}
        >
          Автообзвон
        </Button>
      )
    }
  ]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
