/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef, useLayoutEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Tooltip, Typography, message, Upload, Radio } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import { data } from 'autoprefixer'
import { name } from 'jssip'
import { reset, getCandidates, getDictionaries, setDefDataCandidate } from '../../ManagamentCandidates/actions'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import DepartmentWorkPlanTable from './DepartmentWorkPlanTable'
import { getUserWorkPlan, openMassSettingsModal, openPeriodModal } from '../actions'
import { selectHeaders, selectLoading, selectMassSettings, selectPeriodModal } from '../selectors'
import {
  addNewGroupOptions,
  delDictionaryOptions,
  delGroupOptions,
  findMatchingValues,
  getFilterString,
  getGroupDictionary,
  getGroupOptions,
  getUniqueValues,
  groupByCollectionName,
  parseArrayStringToNumbers,
  updateLabels
} from '../utils'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import ControlButton from '../../../common/components/Buttons/Button'
import EditPencil1 from '../../../common/svg/EditPencil1'
import EditSvg from '../../../common/svg/EditSvg'
import MassSettings from './MassSettings'
import './DepartmentWorkPlan.scss'
import PeriodModal from './PeriodModal'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const mainCssClass = getCssClassName('department-work-plan')

type TFormValues = {
  perPagesCount: number
  name_group: number[]
}

const DepartmentWorkPlan: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const [page, setPage] = useState<number>(1)
  const [groupDictionary, setGroupDictionary] = useState<any>()
  const [groupOptions, setGroupOptions] = useState<any>()
  const massSettingsModal = useSelector(selectMassSettings)
  const periodModal = useSelector(selectPeriodModal)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const loading = useSelector(selectLoading)
  const headers = useSelector(selectHeaders)
  const { control, watch, setValue, getValues } = useForm<TFormValues>()
  const prevStorValue = useRef(null)

  const perPagesCount = watch('perPagesCount')

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const handlePreviousPage = () => {
    if (headers && headers.current > 1) {
      dispatch(
        getUserWorkPlan({
          page: headers.current - 1,
          perPage: perPagesCount,
          filters: ''
        })
      )
    }
  }
  const handleNextPage = () => {
    if (headers && headers.current < headers.total) {
      dispatch(
        getUserWorkPlan({
          page: headers.current + 1,
          perPage: perPagesCount,
          filters: ''
        })
      )
    }
  }
  const handleClickMassSettings = () => {
    dispatch(openMassSettingsModal(true))
  }

  const name_group = watch('name_group')
  const stor = watch('stor')
  const handleChangeSelectStore = (e) => {
    if (e && e.length && !prevStorValue.current) {
      if (name_group) {
        setValue('name_group', getUniqueValues(name_group, JSON.parse(e[0])))
      } else {
        setValue('name_group', JSON.parse(e[0]))
      }
    } else if (e && e.length && prevStorValue.current && prevStorValue.current?.length < e.length) {
      setValue('name_group', addNewGroupOptions(name_group, e, prevStorValue.current))
    } else {
      setValue('name_group', delGroupOptions(prevStorValue.current, e, name_group))
    }
    prevStorValue.current = e
  }

  const handleClickPeriodModal = () => {
    dispatch(openPeriodModal(true))
  }

  useEffect(() => {
    const value = getValues()
    getFilterString(value)
  }, [name_group])

  useEffect(() => {
    if (name_group && groupDictionary) {
      setValue('stor', delDictionaryOptions(groupDictionary, name_group))
      prevStorValue.current = delDictionaryOptions(groupDictionary, name_group)
      setGroupDictionary(updateLabels(groupDictionary, name_group, delDictionaryOptions(groupDictionary, name_group)))
    }
  }, [name_group])

  useEffect(() => {
    getGroupDictionary()
      .then((responseData) => {
        setGroupDictionary(groupByCollectionName(responseData))
        setGroupOptions(getGroupOptions(responseData))
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    //_________________________Удалить после НГ
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
    //__________________________
  }, [])

  useEffect(() => {
    dispatch(
      getUserWorkPlan({
        page: 1,
        perPage: perPagesCount,
        filters: ''
      })
    )
  }, [perPagesCount])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  // @ts-ignore
  return (
    <div className={cn(className, mainCssClass)}>
      <NewScrollContainer>
        <div className={`${mainCssClass}__heading`}>
          <div className={`${mainCssClass}__title`}>
            {/*//________________________Удалить после НГ*/}
            <ChristmasIcon />
            {/*//_________________*/}
            <Typography.Title level={2}>План работы отдела</Typography.Title>
            {loading && <Preloader size="md" />}
          </div>

          <div className={`${mainCssClass}__period`}>
            <div className={`${mainCssClass}__period_text`}>
              Отчетный период: <span className={`${mainCssClass}__period_name`}>Сентябрь</span>
            </div>
            <ControlButton className="button" typeButton="secondary" onClick={handleClickPeriodModal}>
              <EditPencil1 />
              Изменить
            </ControlButton>
          </div>
        </div>
        {!loading && (
          <>
            <div className={`${mainCssClass}__container-info`}>
              <div className={`${mainCssClass}__container-title`}>
                <div className={`${mainCssClass}__search-container`}>
                  <div className={`${mainCssClass}__search-container-select`}>
                    <SelectFormControlMult
                      name="stor"
                      labelInValue
                      options={groupDictionary}
                      control={control}
                      onChange={handleChangeSelectStore}
                    />
                  </div>
                  <div className={`${mainCssClass}__search-container-select`}>
                    <SelectFormControlMult
                      name="responsible"
                      options={[
                        { value: 'recruter', label: 'Рекрутер' },
                        { value: 'researcher', label: 'Ресечер' }
                      ]}
                      control={control}
                    />
                  </div>
                  <div
                    className={cn(
                      `${mainCssClass}__search-container-select`,
                      `${mainCssClass}__search-container-select-mult`
                    )}
                  >
                    <SelectFormControlMult name="name_group" control={control} mode="multiple" options={groupOptions} />
                  </div>

                  <span className={`${mainCssClass}__mass-settings`} onClick={handleClickMassSettings}>
                    Массовая настройка
                  </span>
                </div>
              </div>

              {!!headers?.totalCount && (
                <div className={`${mainCssClass}__container-pagination`}>
                  <SelectFormControl
                    className={`${mainCssClass}__pagination-count`}
                    name="perPagesCount"
                    options={optionsPagination}
                    onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                    control={control}
                  />

                  <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                    <ArrowRightSM
                      className="arrowCursor"
                      size="1"
                      onClick={handlePreviousPage}
                      fill={headers.current === 1 ? '#BFC5C0' : '#404542'}
                    />
                    <Typography.Text className={`${mainCssClass}__pagination-font`}>
                      {stringCountsPagination || 'неизвестно'}
                    </Typography.Text>
                    <ArrowLeftSM
                      className="arrowCursor"
                      size="1"
                      onClick={handleNextPage}
                      fill={headers.current === headers.total ? '#BFC5C0' : '#404542'}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={`${mainCssClass}__scroll-div`}>
              <DepartmentWorkPlanTable className={`${mainCssClass}__table`} />
            </div>
            {massSettingsModal && <MassSettings open={massSettingsModal} />}
            {periodModal && <PeriodModal open={periodModal} />}
          </>
        )}
      </NewScrollContainer>
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(DepartmentWorkPlan))
