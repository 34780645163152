import React, { memo } from 'react'
import { ModalProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import './ModalCloseSure.scss'
import { deleteComment, openDeleteCommentModal, setSelectedComment } from '../actions'
import { selectSelectedCommet } from '../selectors'

const mainCssClass = 'modalCloseSure'

function ModalDeleteComment({
  onOk, open, className
}: ModalProps) {
  const modalStyle = {
    zIndex: 19999
  }
  const dispatch = useDispatch()
  const comment = useSelector(selectSelectedCommet)

  const handleCancel = () => {
    dispatch(openDeleteCommentModal(false))
    dispatch(setSelectedComment(null))
  }
  const onSubmit = () => {
    if (comment) {
      dispatch(deleteComment(comment))
    }
  }

  return (
    <CommonModal
      open={open}
      onOk={onSubmit}
      onCancel={handleCancel}
      title="Удалить комментарий?"
      className={cn(className, mainCssClass)}
      width="400px"
      centered
      typeButtonAccept="warning"
      okText="Удалить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      style={modalStyle}
    >
      <p>Данное действие нельзя будет отменить. Удалить?</p>
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(ModalDeleteComment))
