import React, { FC, useState } from 'react'
import cn from 'classnames'
import { Button, Typography } from 'antd'
import { FallbackProps } from 'react-error-boundary'
import { getCssClassName } from '../../utils/generateClassName'
import './ErrorBoundary.scss'
import ControlButton from '../../components/Buttons/Button'

export type TProps = {
  className?: string
  displayResetBtn?: boolean
} & FallbackProps

const mainCssClass = getCssClassName('default-fallback-component')

const DefaultFallbackComponent: FC<TProps> = ({
  className,
  error,
  resetErrorBoundary,
  displayResetBtn
}) => {
  const [showError, setShowError] = useState<boolean>(false)

  return (
    <div className={cn(className, mainCssClass)}>
      <Typography.Title className={`${mainCssClass}__item-title`} level={1}>Упс! Что-то пошло не так!</Typography.Title>
      <ControlButton className="button" typeButton="primary" onClick={() => setShowError(!showError)}>{`${showError ? 'Скрыть' : 'Показать'} ошибку`}</ControlButton>
      {showError && <Typography.Text className={`${mainCssClass}__item-text`}>{error.message}</Typography.Text>}
      {displayResetBtn && (
        <ControlButton
          className="button"
          typeButton="ghost"
          title="Попробовать снова"
          onClick={resetErrorBoundary}
        />
      )}
    </div>
  )
}

export default DefaultFallbackComponent
