import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { selectSystemColor } from '../../../compositions/InnerRouter/selectors'
import ChatCircleDots from '../../../common/svg/ChatCircleDots'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectChatVisible } from '../../../compositions/candidate/selectors'
import './OneMessage.scss'
import CheckAllBig from '../../../common/svg/CheckAllBig'
import CheckBig from '../../../common/svg/CheckBig'

type TOneMessageProps = {
  status?: string
  name?: string
  date?: string
  text?: string
  messageId?: null | string
  statusRead: string
}

const MainCSSClass = 'oneMessage'

function stripHTMLTags(html: string) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  return tempDiv.textContent || ''
}

function OneMessage({ props }: { props: TOneMessageProps }) {
  const strippedText = stripHTMLTags(props.text || '') // Удаляем HTML-теги
  const systemColor = useSelector(selectSystemColor)
  const chatVisible = useSelector(selectChatVisible)

  const oneMessageCSS = useMemo(() => (chatVisible
    ? cn(MainCSSClass, {
      oneMessage_candidate: props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-tomato': systemColor === 'tomato' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-green': systemColor === 'green' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-blue': systemColor === 'blue' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-carrot': systemColor === 'carrot' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-plum': systemColor === 'plum' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-bread': systemColor === 'bread' && props?.status?.toLowerCase() === 'out',
      'oneMessage_candidate-beet': systemColor === 'beet' && props?.status?.toLowerCase() === 'out'
    })
    : cn(MainCSSClass, { oneMessage_candidate: props?.status?.toLowerCase() === 'out' }, { oneMessage_candidate_mail: props?.status?.toLowerCase() === 'out' })), [chatVisible, systemColor])

  const messageStatus = useMemo(() => {
    switch (systemColor) {
    case 'tomato': return '#F98983'
    case 'green': return '#8AD8C3'
    case 'blue': return '#89B7FC'
    case 'carrot': return '#FF9E69'
    case 'plum': return '#C192E8'
    case 'bread': return '#D89888'
    case 'beet': return '#DC75AB'
    default:
      return 'grey'
    }
  }, [chatVisible, systemColor])

  return (
    <div className={oneMessageCSS} key={props?.requestId}>
      <div className={`${MainCSSClass}_title`}>
        <p className={`${MainCSSClass}_name`}>{props.name}</p>
        <div className={`${MainCSSClass}_date`}>
          <p className={`${MainCSSClass}_date_date`}>{props.date}</p>
          {props?.statusRead === 'READ' ? <CheckAllBig color={props?.status?.toLowerCase() === 'out' ? 'white' : 'black'} />
            : props?.statusRead === 'DELIVERED' ? <CheckAllBig color={props?.status?.toLowerCase() === 'out' ? messageStatus : 'grey'} />
              : <CheckBig color={props?.status?.toLowerCase() === 'out' ? 'white' : 'black'} />}
        </div>
      </div>
      <div className={`${MainCSSClass}_text`}>
        <p>
          {strippedText}
          { props.messageId
        && (
          ' (Сообщение отправляется)'
        )}
        </p>
      </div>
    </div>
  )
}

export default memo<TOneMessageProps>(withErrorBoundary<TOneMessageProps>(OneMessage))
