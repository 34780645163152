import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ chatPanel }: TRootState) => chatPanel

export const selectDialogs = createSelector(selectState, ({ dialogs }) => dialogs)

export const selectChatPanelHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectCandidate = createSelector(selectState, ({ candidate }) => candidate)
