import React, {
  memo,
  useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import withErrorBoundary from '../common/hoc/withErrorBoundary/withErrorBoundary'

type TFormValues = {
  reason: string
  comment: string
}

export type TProps = {
} & ModalProps
function Asterisk({
  open, className
}: TProps) {
  const dispatch = useDispatch()
  const iframeRef = useRef(null)
  const navigate = useNavigate()

  const goBack = () => {
    // if (iframeRef.current) {
    //   iframeRef.current.window.history.go(-1);
    // }
    navigate(-1)
  }

  const goForward = () => {
    // if (iframeRef.current) {
    //   iframeRef.current.window.history.go(1);
    // }
    navigate(1)
  }


  return (
    <div style={{ height: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <button type="button" onClick={goBack}>Назад</button>
        <button type="button" onClick={goForward}>Вперед</button>
      </div>

      <iframe
        src={process.env.DOMAIN_ASTERISK}
        width="100%"
        height="100%"
        title="Ваш iframe"
        ref={iframeRef}
      />
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(Asterisk))
