import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TSaveInternship } from '../../common/api/internship'
import internship from './components/Internship'

export const getTimeSlots = createAction<string | undefined>('internship/getTimeSlots')
export const setTimeSlots = createAction<string[]>('internship/setTimeSlots')
export const reset = createAction('internship/reset')
export const setLoading = createAction<boolean>('internship/setLoading')
export const setPage = createAction<number>('internship/setPage')

export const getDictionaries = createAction<TDictionariesNames>('internship/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('internship/dictionaries/setDictionaries')

export type TInternshipRequest = {
  page:number,
  perPage?: number,
  date: string,
  filter: string,
  search:string
}
export const getInternship = createAction<TInternshipRequest>('internship/getInternship')
export const setInternship = createAction<null | TSaveInternship[]>('internship/setInternship')

export const setInternshipFilter = createAction<string>('internship/setInternshipFilter')

export type TInternship = {
  calendar_list : [{
    date:string
    count:number
    items: [{
      id:number
      name:string
      last_name:string
      second_name:string
      phone: number
        address:string
      date_internship:string
        vacancy_label:string
        request_name:string
      total_count:number
        internship:any

    }]
  }]

}
export type TDefDataInternShip = {
    page: number,
    perPage: number,
    filters: string,
    search:string
}

export const setDefDataInternship = createAction<TDefDataInternShip>('internship/setDefDataInternship')

export const openInternshipFilter = createAction<boolean>('internship/openInternshipsFilters')

export const getInternshipModal = createAction('internship/getInternshipModal')

export const setInternshipModal = createAction<boolean>('internship/setInternshipModal')

export const updateInternships = createAction<TUpdateInternship>('internship/updateInternships')

export const setSelectedInternship = createAction<any>('internship/setSelectedInternship')

export type TUpdateInternship = {
  data: {
    id: number,
    is_came: number,
    mentor: string,
    registration: number,
    comment: string
},
    search:string,
    date:string
}

export type TUpdateInternshipDate = {
  data:{
    id: number,
    date: string
    time: string
},
  date:string
}

export const setSearchInternship = createAction<string>('internship/setSearchInternship')

export const openInternshipDateModal = createAction<boolean>('internship/openInternshipDateModal')

export const updateInternshipDate = createAction<TUpdateInternshipDate>('internship/updateInternshipDate')
