import axios, { AxiosResponse } from 'axios'
import { method } from 'lodash'
import $api, { API_URL } from './http'

export type TResponseDialogs = {
    DATA: any[]
    ERR: boolean
}

export type TRequestDialogs = {
  page: number
}

export async function fetchGetDialogs(payload : TRequestDialogs): Promise<AxiosResponse<TResponseDialogs>> {
  const response = await $api.get(`/api/v1/messenger/get-dialogs?page=${payload.page}&per-page=20`)
  return response
}
