import { createAction } from '@reduxjs/toolkit'

export const openNotifications = createAction<boolean>('notificationCenter/openNotifications')

export type TRequestNotify = {
    page: number;
    per_page: number;
    filter: string
    status: string
}

export type TNotifyHeaders = {
    current: number
    total :number
    totalCount: number
    unread: boolean
} | null

export const getNotifications = createAction<TRequestNotify>('notificationCenter/getNotifications')
export const setDefDataNotify = createAction<TRequestNotify>('notificationCenter/setDefDataNotify')
export const setNotifyHeaders = createAction<TNotifyHeaders>('notificationCenter/setNotifyHeaders')
export const reset = createAction('notificationCenter/reset')


export const setLoading = createAction<boolean>('notificationCenter/setLoading')

export const setNotifications = createAction<any[]>('notificationCenter/setNotifications')

export const deleteNotify = createAction<string>('notificationCenter/deleteNotify')
export const deleteNotifications = createAction('notificationCenter/deleteNotifications')

export const readNotify = createAction<string>('notificationCenter/readNotify')
export const readNotifications = createAction('notificationCenter/readNotifications')
