import React, {
  memo, SyntheticEvent, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import {
  Progress, Dropdown, Menu, Tabs
} from 'antd'
import { useForm } from 'react-hook-form'
import ArrowRight from '../../../common/svg/svgIcons/ArrowRight.svg'
import {
  convertSecondsToTime, getCurrentColor, getCurrentHoursAndMinutes, getCurrentTextRtt
} from '../utils'
import {
  selectConnectedAsterisk, selectCurrentRtt,
  selectCurrentUserStatusMobile,
  selectInternalInformationByUserId,
  selectModalAutoCallId,
  selectStatusesMobiles,
  selectStatusPhone,
  selectUserInfo
} from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import Ellipse from '../../../common/svg/Ellipse'
import {
  getCurrentRtt,
  getCurrentStatus,
  getInternalAutoCall,
  getInternalInformationByUserId, getStopAutoCall,
  openPhoneModal, openQueueModal,
  setModalAutoCallOpen,
  setStatusPhone,
  updateStatusesMobiles
} from '../actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Users from '../../../common/svg/Users'
import PhoneSvg from '../../../common/svg/PhoneSvg'
import { setOpenEmployeesCenter } from '../../../features/employeesModal/actions'
import TimePanel from './TimePanel'
import Circle from '../../../common/svg/Circle'
import './ControlPanelPhone.scss'
import UsersGroup from '../../../common/svg/UsersGroup'
import ChartBarVertical from '../../../common/svg/ChartBarVertical'
import InfoTabRequest from '../../Request/components/InfoRequestPage.tsx/InfoTabRequest'
import CandidateTabRequest from '../../Request/components/CandidateTabRequest'
import ReserveTabRequest from '../../Request/components/InfoRequestPage.tsx/ReserveTabRequest'
import TabPane = Tabs.TabPane
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = 'control-panel-phone'
type TFormValues = {
  status_id: number
}
type TProps ={
  targetUrl: string
}

function ControlPanelPhone({ targetUrl }: TProps) {
  const [time, setTime] = useState(getCurrentHoursAndMinutes())
  const [activeKey, setActiveKey] = useState<string>('')
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const dispatch = useDispatch()
  const statusesMobiles = useSelector(selectStatusesMobiles)
  const userInfo = useSelector(selectUserInfo)
  const currentStatus = useSelector(selectCurrentUserStatusMobile)
  const internalInformationByUserId = useSelector(selectInternalInformationByUserId)
  const autoCallId = useSelector(selectModalAutoCallId)
  const visibleStatus = useSelector(selectStatusPhone)
  const connectedWss = useSelector(selectConnectedAsterisk)
  const currentRtt = useSelector(selectCurrentRtt)
  const displayedName = useMemo(() => (userInfo?.sipSettings?.display_name ? userInfo.sipSettings.display_name : 'Не подключен'), [userInfo])
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const {
    control, watch, reset
  } = useForm<TFormValues>()
  const defaultValues = useMemo(() => ({ status_id: currentStatus?.id }), [currentStatus])
  const percentWork = useMemo(() => {
    let percent = 0
    if (internalInformationByUserId?.total?.count === 0 && internalInformationByUserId?.miss?.count === 0) {
      return 100
    }
    if (internalInformationByUserId) {
      percent = (((
        internalInformationByUserId.total.count + internalInformationByUserId.done.count + internalInformationByUserId.take.count)
        / (internalInformationByUserId.total.count
          + internalInformationByUserId.done.count
          + internalInformationByUserId.take.count + internalInformationByUserId.miss.count)) * 100)
      percent = Math.round(percent)
    }
    return percent
  }, [internalInformationByUserId])
  const timeWork = '-'
  const statusPhoneType = watch('status_id')
  const planeOfWork = '-'
  const status = useMemo(() => {
    if (statusPhoneType === 1 || statusPhoneType === 5 || statusPhoneType === 3) {
      dispatch(setStatusPhone(true))
      return 'red'
    }
    dispatch(setStatusPhone(false))
    return 'green'
  }, [statusPhoneType])
  const fill = useMemo(() => {
    if (statusPhoneType === 1) {
      return '#DF1E12'
    }
    if (statusPhoneType === 2) {
      return '#2DBE64'
    }
    if (statusPhoneType === 3) {
      return '#5A615C'
    }
    if (statusPhoneType === 4) {
      return '#FFD608'
    }
    if (statusPhoneType === 5) {
      return '#F5695A'
    }
    return '#BFC5C0'
  }, [statusPhoneType])

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(getCurrentHoursAndMinutes())
    }, 60000)

    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    dispatch(getCurrentStatus())
    dispatch(getCurrentRtt())
  }, [])

  useEffect(() => {
    if (currentStatus && defaultValues) {
      reset(defaultValues)
    }
  }, [currentStatus])

  const onChange = (id:any) => {
    dispatch(updateStatusesMobiles({ user_id: userInfo.id, status_id: id }))
  }
  const handleOpenEmployeesCenter = () => {
    dispatch(setOpenEmployeesCenter(true))
  }
  const handleOpenPhoneModal = () => {
    dispatch(openPhoneModal(true))
  }
  const handleOpenInfo = () => {
    setOpenInfo((prev) => !prev)
  }
  const handleOpenAutoCallModal = () => {
    dispatch(setModalAutoCallOpen(true))
  }
  const handleStopAutoCall = () => {
    if (autoCallId) {
      dispatch(getStopAutoCall(autoCallId))
    }
  }
  const handleOpenQueueModal = () => {
    dispatch(openQueueModal(true))
  }

  useEffect(() => {
    dispatch(getInternalInformationByUserId())
  }, [userInfo])



  const handleChangeMenuItem = (e: SyntheticEvent) => {
    if (e?.key === activeKey) {
      setDropdownVisible((prev) => !prev)
    } else {
      setActiveKey(e.key)
      setDropdownVisible(true)
    }
  }

  const dropdownItems = (
    <div className={cn(`${mainCssClass}__block-container`, `${mainCssClass}__statistic-container-2`)}>
      <Tabs className={mainCssClass} defaultValue="successed">
        <TabPane tab="Успешные" key="successed">
          <div className={`${mainCssClass}__content-tab`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <div className={`${mainCssClass}__phones-wrapper`}>
                { // @ts-ignore
                  internalInformationByUserId?.[activeKey]?.calls?.success?.map((item) => (
                    <div className={`${mainCssClass}__phones-container`}>
                      <div>
                        {item.callee}
                      </div>
                      <div>
                        {convertSecondsToTime(item.duration)}
                      </div>
                    </div>
                  ))
                }
              </div>
            </NewScrollContainer>
          </div>
        </TabPane>
        <TabPane tab="Недозвон" key="failed">
          <div className={`${mainCssClass}__content-tab`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <div className={`${mainCssClass}__phones-wrapper`}>
                { // @ts-ignore
                  internalInformationByUserId?.[activeKey]?.calls?.failed?.map((item) => (
                    <div className={`${mainCssClass}__phones-container`}>
                      <div>
                        {item.callee}
                      </div>
                      <div>
                        {convertSecondsToTime(item.duration)}
                      </div>
                    </div>
                  ))
                }
              </div>
            </NewScrollContainer>
          </div>
        </TabPane>
      </Tabs>
    </div>
  )

  return (
    <div className={`${mainCssClass}`}>
      <div className={cn(`${mainCssClass}__block-container`, `${mainCssClass}__block-container-status`)}>
        <div className={`${mainCssClass}__text-label`}>
          Статус телефонии
        </div>
        <div className={`${mainCssClass}__text-main`}>
          <div className={`${mainCssClass}__icon-container`}>
            <Ellipse fill={fill} />
          </div>
          <SelectFormControl
            onChange={onChange}
            name="status_id"
            options={statusesMobiles}
            control={control}
            className="custom-select"
            placeholder="-"
            dropdownMatchSelectWidth={140}
          />
        </div>
      </div>
      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__container-buttons`} onClick={handleOpenEmployeesCenter}>
          <Users
            className={`${mainCssClass}__icon`}
          />
          Сотрудники
        </div>
      </div>
      {(!visibleStatus && connectedWss) && (
        <div className={`${mainCssClass}__block-container`}>
          <div className={`${mainCssClass}__container-buttons`} onClick={handleOpenPhoneModal}>
            <PhoneSvg />
            Телефон
          </div>
        </div>
      )}
      {!visibleStatus && (
        <div className={`${mainCssClass}__block-container`}>
          <div className={`${mainCssClass}__container-buttons`} onClick={handleOpenQueueModal}>
            <UsersGroup className={`${mainCssClass}__icon`} />
            Очереди
          </div>
        </div>
      )}
      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__container-buttons`} onClick={handleOpenInfo}>
          <ChartBarVertical className={`${mainCssClass}__icon`} />
          Статистика
        </div>
        {openInfo && (
          <>
            {' '}
            <div className={`${mainCssClass}__statistic-container`}>
              <div className={`${mainCssClass}__statistic-container-wrap`}>
                <div className={`${mainCssClass}__text-statistic`}>
                  Статистика
                </div>
                <Dropdown
                  overlay={dropdownItems}
                  trigger={['click']}
                  visible={dropdownVisible}
                  placement="right"
                >
                  <div>
                    <Menu onClick={handleChangeMenuItem}>
                      <Menu.Item className={`${mainCssClass}__menu-item`} key="total">
                        <div className={`${mainCssClass}__container-label`}>
                          <div className={`${mainCssClass}__container-text`}>Совершено звонков </div>
                          <div className={`${mainCssClass}__container-value`}>
                            {internalInformationByUserId?.total?.count || 0}
                          </div>
                          <ArrowRight />
                        </div>
                      </Menu.Item>
                      <Menu.Item key="take">
                        <div className={`${mainCssClass}__container-label`}>
                          <div className={`${mainCssClass}__container-text`}>Принято звонков</div>
                          <div className={`${mainCssClass}__container-value`}>
                            {internalInformationByUserId?.take?.count || 0}
                          </div>
                          <ArrowRight />
                        </div>
                      </Menu.Item>
                      <Menu.Item key="done">
                        <div className={`${mainCssClass}__container-label`}>
                          <div className={`${mainCssClass}__container-text`}>Обработано звонков</div>
                          <div className={`${mainCssClass}__container-value`}>
                            {internalInformationByUserId?.done?.count || 0}
                          </div>
                          <ArrowRight />
                        </div>
                      </Menu.Item>
                      <Menu.Item key="miss">
                        <div className={`${mainCssClass}__container-label`}>
                          <div className={`${mainCssClass}__container-text`}>Пропущено звонков</div>
                          <div className={`${mainCssClass}__container-value-red`}>
                            {internalInformationByUserId?.miss?.count || 0}
                          </div>
                          <ArrowRight />
                        </div>
                      </Menu.Item>
                    </Menu>
                  </div>
                </Dropdown>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={`${mainCssClass}__line`} />

      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__text-label`}>
          Время
        </div>
        <div className={cn(`${mainCssClass}__text-main`, `${mainCssClass}__with-margin`)}>
          {time}
        </div>
      </div>
      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__text-label`}>
          Качество связи
        </div>
        <div className={cn(`${mainCssClass}__with-margin`, `${mainCssClass}__circles`)}>
          <Circle fill={getCurrentColor(currentRtt?.rtt)} />
          <Circle fill={currentRtt?.rtt > 4000 ? getCurrentColor(currentRtt?.rtt) : '#BFC5C0'} />
          <Circle fill={currentRtt?.rtt > 6000 ? getCurrentColor(currentRtt?.rtt) : '#BFC5C0'} />
          <Circle fill={currentRtt?.rtt > 8000 ? getCurrentColor(currentRtt?.rtt) : '#BFC5C0'} />
          <div className={`${mainCssClass}__text-main_rtt`}>
            {getCurrentTextRtt(currentRtt?.rtt || 0)}
          </div>
        </div>
      </div>
      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__text-label`}>
          Выполнение плана
        </div>
        <div className={cn(`${mainCssClass}__text-main`, `${mainCssClass}__with-margin`)}>
          <div className={`${mainCssClass}__container-progress`}>
            <div className={`${mainCssClass}__buttons-text`}>
              <div className={`${mainCssClass}__buttons-text_first`}>
                {`${percentWork || 100}%`}
              </div>
              <Progress
                type="line"
                percent={percentWork}
                strokeColor="#2DBE64"
                showInfo={false}
                size="small"
              />
            </div>
          </div>
        </div>

      </div>

      <div className={`${mainCssClass}__block-container`}>
        <div className={`${mainCssClass}__text-main`}>
          {`${displayedName}` || ''}
        </div>
        <div className={cn(`${mainCssClass}__text-status-phone-${status}`, `${mainCssClass}__with-margin`)}>
          {status === 'red' ? 'Не подключен' : 'Подключен'}
        </div>
      </div>
      <TimePanel />
      {/* {!visibleStatus && !autoCallId && ( */}
      {/*   <div className={`${mainCssClass}__block-container`}> */}
      {/*     <div className={`${mainCssClass}__button-auto-container`} onClick={handleOpenAutoCallModal}> */}
      {/*       <div className={`${mainCssClass}__button-auto`}> */}
      {/*         <UserVoice /> */}
      {/*         Автообзвон */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
      {/* {autoCallId && !visibleStatus && ( */}
      {/*   <div className={`${mainCssClass}__block-container`} onClick={handleStopAutoCall}> */}
      {/*     <div className={`${mainCssClass}__button-auto-container`}> */}
      {/*       <div className={`${mainCssClass}__button-auto-close`}> */}
      {/*         <UserClose /> */}
      {/*         Завершить */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
    </div>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ControlPanelPhone))
