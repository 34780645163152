import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { useLocation } from 'react-router'
import TabPane = Tabs.TabPane
import InfoRequest from './InfoRequestPage.tsx/InfoRequest'
import HeaderRequestPage from './HeaderRequestPage/HeaderRequestPage'
import ReturnButton from '../../InnerRouter/components/ReturnButton'
import {
  getDictionaries, getRequest, reset, setOpenModalRemove, setTabRequest
} from '../actions'
import {
  selectCandidateOfRequest, selectLoading, selectOpenRemoveCandidateofRequest, selectRequest, selectTabRequest
} from '../selectors'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ModalHistoryRequestAction from './ModalHistoryRequestAction'

import ModalRemoveRequest from '../../candidate/components/ModalRemoveRequest'
import ModalHistoryRequestActionV2 from './ModalHistoryRequestActionV2'
import CandidateTabRequest from './CandidateTabRequest'
import DateCreateApplication from './HeaderRequestPage/DateCreateApplication'
import './RequestPage.scss'

const mainCSSClass = 'requestPage'

function RequestPage() {
  const location = useLocation()
  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const tabRequest = useSelector(selectTabRequest)
  const request = useSelector(selectRequest)
  const removeRequest = useSelector(selectCandidateOfRequest)
  const openRequestOfCandidate = useSelector(selectOpenRemoveCandidateofRequest)

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const params = useMemo(() => {
    const parts = location.pathname.split('/')
    return `${parts.pop()}`
  }, [location.pathname])
  const handleOkRemoveRequest = useCallback(() => {
    dispatch(setOpenModalRemove(false))
  }, [])

  const handleChangeTabRequest = (key:string) => {
    dispatch(setTabRequest(key))
  }

  const isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (params) {
      dispatch(getRequest(params))
    }
  }, [])

  useEffect(() => {
    dispatch(
      getDictionaries([
        'requestStatus',
        'currency',
        'schedule',
        'WorkSites',
        'schedule',
        'rejectionReason',
        'priority',
        'archiveReason',
        'requestRejectionReason',
        'requestCandidateCharacteristic',
        'requestCandidateEducation',
        'priority',
        'stageStatus'
      ])
    )
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCSSClass}>
      {loading && (
        <div className={`${mainCSSClass}__preloader`}>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      {!loading && (
        <div className={`${mainCSSClass}_wrapper`}>
          <div className={`${mainCSSClass}__mobileButton`}>
            <ReturnButton />
            <DateCreateApplication />
          </div>
          <div className={`${mainCSSClass}_wrapper_main`}>
            <div className={`${mainCSSClass}_wrapper_main_content`}>
              <HeaderRequestPage isMobile={isMobile}/>
              <InfoRequest />
            </div>
            <div className={`${mainCSSClass}_wrapper_main_history`}>
              <Tabs defaultActiveKey={tabRequest || 'candidate'} onChange={(key) => handleChangeTabRequest(key)}>
                <TabPane key="candidate" tab="Кандидаты" />
                <TabPane key="historyV2" tab="История заявки" />
                <TabPane key="history" tab="Старая история заявки" />
              </Tabs>
              {tabRequest === 'history'
               && <ModalHistoryRequestAction />}
              {tabRequest === 'historyV2'
               && <ModalHistoryRequestActionV2 />}

              {tabRequest === 'candidate'
              && <CandidateTabRequest />}

            </div>
            <ModalRemoveRequest
              open={openRequestOfCandidate}
              candidateId={removeRequest?.candidateId}
              request_id={removeRequest?.requestId}
              onOk={handleOkRemoveRequest}
              onCancel={handleOkRemoveRequest}
              isRequest
            />
          </div>
        </div>
      )}

    </div>
  )
}

export default memo(withErrorBoundary(RequestPage))
