import React from 'react'
import { TClassName } from '../types/props'

const LogoMobil: React.FC<TClassName> = ({ className }) => (
  <svg width="40" height="40" viewBox="0 0 48 48" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_52_1549)">
      <path d="M43.8573 0H5.14272C2.57861 0 0.5 2.07861 0.5 4.64272V43.3573C0.5 45.9214 2.57861 48 5.14272 48H43.8573C46.4214 48 48.5 45.9214 48.5 43.3573V4.64272C48.5 2.07861 46.4214 0 43.8573 0Z" fill="#2DBE64" />
      <path d="M36.1718 32.2352H24.5066V15.764H35.1297C38.5585 15.764 40.4199 17.9009 40.4199 20.4292C40.4199 21.5641 39.8482 22.7239 38.9709 23.6012C40.2637 24.2835 41.3723 25.8224 41.3723 27.4261C41.3723 30.2484 39.1697 32.2344 36.1718 32.2344V32.2352ZM28.5776 28.9216H35.6171C36.6035 28.9216 37.2526 28.1921 37.2526 27.2954V27.2691C37.2526 26.3724 36.6035 25.6429 35.6171 25.6429H28.5776V28.9216ZM28.5776 22.3293H34.5997C35.6658 22.3293 36.3002 21.5997 36.3002 20.7031C36.3002 19.8064 35.7053 19.0768 34.5997 19.0768H28.5776V22.3293Z" fill="white" />
      <path d="M17.3479 32.2352H8.72862V15.764H16.3066C19.7354 15.764 21.5969 17.9009 21.5969 20.4292C21.5969 21.5641 21.0251 22.7239 20.1478 23.6012C21.4406 24.2835 22.5492 25.8224 22.5492 27.4261C22.5492 30.2484 20.3466 32.2344 17.3487 32.2344L17.3479 32.2352ZM12.7996 28.9216H16.794C17.7804 28.9216 18.4295 28.1921 18.4295 27.2954V27.2691C18.4295 26.3724 17.7804 25.6429 16.794 25.6429H12.7996V28.9216ZM12.7996 22.3293H15.7766C16.8427 22.3293 17.4771 21.5997 17.4771 20.7031C17.4771 19.8064 16.8822 19.0768 15.7766 19.0768H12.7996V22.3293Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_52_1549">
        <rect width="48" height="48" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>

)

export default LogoMobil
