import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useFieldArray, useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CloseMD from '../../../common/svg/CloseMD'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume } from '../selectors'
import { createCetrificateResume, createRecomendationResume } from '../actions'
import './ResumeModal.scss'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  recommendation_list: { fio: string; company: string; position: string; contact: string }[]
}

function ModalRecomendation({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)

  const defaultValues = useMemo(
    () => (editMode && resume?.recommendation_list && resume?.recommendation_list.length > 0
      ? {
        recommendation_list: resume?.recommendation_list.map((e) => ({
          fio: e.fio,
          position: e.position,
          company: e.company,
          contact: e.contact
        }))
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray<TFormValues, 'recommendation_list'>({
    control,
    name: 'recommendation_list'
  })

  const onSubmit = useCallback(() => {
    dispatch(
      createRecomendationResume({
        resume_id: resumeId,
        recommendation_list: getValues().recommendation_list.map((el) => ({ ...el, resume_id: resumeId }))
      })
    )
  }, [])

  if (fields?.length === 0) {
    prepend({
      fio: '',
      company: '',
      contact: '',
      position: ''
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Рекомендации"
      className={cn(className, mainCssClass)}
      typeButtonAccept="primary"
      okText="Сохранить"
      centered
      width="500px"
      cancelText="Отменить"
      typeButtonReject="secondary"
      maskClosable={false}
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_coursesContainer`}>
        <NewScrollContainer className={`${mainCssClass}_scroll`}>
          <div className={`${mainCssClass}_list`}>
            {fields?.map((rec, index) => (index === 0 ? (
              <div className={`${mainCssClass}_items`}>
                <InputFormControl
                  label="ФИО"
                  name={`recommendation_list[${index}].fio`}
                  control={control}
                  rules={{ required: true }}
                />
                <InputFormControl
                  label="Должность"
                  name={`recommendation_list[${index}].position`}
                  control={control}
                  rules={{ required: true }}
                />
                <InputFormControl
                  label="Компания"
                  name={`recommendation_list[${index}].company`}
                  control={control}
                  rules={{ required: true }}
                />
                <InputFormControl
                  label="Контакты"
                  name={`recommendation_list[${index}].contact`}
                  control={control}
                  rules={{ required: true }}
                />
              </div>
            ) : (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_dop`}>
                  <InputFormControl
                    label="ФИО"
                    name={`recommendation_list[${index}].fio`}
                    control={control}
                    rules={{ required: true }}
                  />
                  <Button className={`${mainCssClass}_items_dop_close`} onClick={() => handleRemove(index)}>
                    <CloseMD />
                  </Button>
                </div>

                <InputFormControl
                  label="Должность"
                  name={`recommendation_list[${index}].position`}
                  control={control}
                  rules={{ required: true }}
                />
                <InputFormControl
                  label="Компания"
                  name={`recommendation_list[${index}].company`}
                  control={control}
                  rules={{ required: true }}
                />
                <InputFormControl
                  label="Контакты"
                  name={`recommendation_list[${index}].contact`}
                  control={control}
                  rules={{ required: true }}
                />
              </div>
            )))}
            <Button
              onClick={() => append({
                fio: '', company: '', contact: '', position: ''
              })}
              className={`${mainCssClass}_items_add`}
            >
              <AddPlusSvg />
              Добавить Рекомендацию
            </Button>
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalRecomendation))
