import React, { useEffect } from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps, useForm } from 'react-hook-form'
import cn from 'classnames'
import './SelectFormControl.scss'
import Required from '../../svg/Required'
import { TOption } from '../../types/dictionaries'
import { DefaultOptionType } from 'antd/es/select'

type TControlSelectProps = Omit<
  UseControllerProps &
    SelectProps & { className?: string; size?: 'small' | 'middle' | 'large'; label?: string; required?: boolean },
  'isDisabled'
>

interface SelectFormControlProps extends TControlSelectProps {
  rules?: {
    required: boolean
  }
}

const { Title } = Typography

const { Option } = Select

function SearchSelectFormControlSource({
  name,
  control,
  rules,
  label,
  className,
  size,
  required,
  options,
  defaultValue,
  onChange,
  ...rest
}: SelectFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })
  const handleSelectChange = (value: any, option: DefaultOptionType | DefaultOptionType[]) => {
    field.onChange(value)
    if (onChange) {
      onChange(value, option)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])
  return (
    <div
      className={cn(
        className,
        'customSelect',
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' }
      )}
    >
      {label && (
        <div>
          <Title className="customSelect_label" level={4}>
            {label}
            {required && <Required className="required" />}
          </Title>
        </div>
      )}
      <Select
        showSearch
        placeholder="Поиск по наименованию..."
        optionFilterProp="children"
        filterOption={(input, option) => {
          const label = typeof option?.label === 'string' ? option?.label : option?.label?.props?.pinLabel
          return label.toLowerCase().includes(input.toLowerCase())
        }}
        // filterSort={(optionA, optionB) => {
        //   const labelA = typeof optionA?.label === 'string' ? optionA?.label : optionA?.label?.props?.pinLabel
        //   const labelB = typeof optionB?.label === 'string' ? optionB?.label : optionB?.label?.props?.pinLabel
        //   return labelA.toLowerCase().localeCompare(labelB.toLowerCase())
        // }}
        onChange={handleSelectChange}
        value={field.value}
        options={options}
        className={fieldState.error ? 'error' : ''}
        {...rest}
      />
    </div>
  )
}

export default SearchSelectFormControlSource
