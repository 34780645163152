import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Switch
} from 'antd'
import { useFieldArray, useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CloseMD from '../../../common/svg/CloseMD'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName } from '../selectors'
import { createCetrificateResume, createEducationResume, createRecomendationResume } from '../actions'
import './ResumeModal.scss'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  education_list: {
    level: string | number
    name: string
    faculty: string
    specialization: string
    period_start: string
    period_end: string
    switch: boolean
  }[]
}

function ModalEducation({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const educLevel = useSelector(selectDictionaryOptionsByName('educationLevel')) || []

  const defaultValues = useMemo(
    () => (editMode && resume?.education_list && resume?.education_list.length > 0
      ? {
        education_list: resume?.education_list.map((e) => ({
          level: e.level,
          name: e.name,
          faculty: e.faculty,
          period_end: e.period_end,
          period_start: e.period_start,
          specialization: e.specialization
        }))
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray<TFormValues, 'education_list'>({
    control,
    name: 'education_list'
  })

  const onSubmit = useCallback(() => {
    dispatch(
      createEducationResume({
        resume_id: resumeId,
        education_list: getValues().education_list.map((el) => {
          if (el.switch) {
            return { ...el, period_end: '', resume_id: resumeId }
          }
          return { ...el, resume_id: resumeId }
        })
      })
    )
  }, [resumeId])

  if (fields?.length === 0) {
    prepend({
      level: '',
      name: '',
      faculty: '',
      specialization: '',
      period_start: '',
      period_end: '',
      switch: false
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Образование"
      className={cn(className, mainCssClass)}
      typeButtonAccept="primary"
      okText="Сохранить"
      maskClosable={false}
      centered
      width="920px"
      bodyStyle={{ padding: '0', margin: 0 }}
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_coursesContainer`}>
        <NewScrollContainer className={`${mainCssClass}_scroll`}>
          <div className={`${mainCssClass}_list`}>
            {fields?.map((rec, index) => (index === 0 ? (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_courses`}>
                  <SelectFormControl
                    label="Уровень образования"
                    name={`education_list[${index}].level`}
                    control={control}
                    options={educLevel}
                    rules={{ required: true }}
                  />
                  <InputFormControl
                    label="Учебное заведение"
                    name={`education_list[${index}].name`}
                    control={control}
                    rules={{ required: true }}
                  />
                </div>
                <div className={`${mainCssClass}_items_courses`}>
                  <InputFormControl
                    label="Факультет"
                    name={`education_list[${index}].faculty`}
                    control={control}
                    rules={{ required: true }}
                  />
                  <InputFormControl
                    label="Специализация"
                    name={`education_list[${index}].specialization`}
                    control={control}
                    rules={{ required: true }}
                  />
                </div>

                <div className={`${mainCssClass}_items_courses`}>
                  <DatePickerFormControl
                    label="Начало обучения"
                    name={`education_list[${index}].period_start`}
                    control={control}
                  />

                  {!watch(`education_list[${index}].switch`) && (
                    <DatePickerFormControl
                      label="Окончание обучения"
                      name={`education_list[${index}].period_end`}
                      control={control}
                    />
                  )}
                  <div className={`${mainCssClass}_items_courses_switch`}>
                    <span>По настоящее время</span>
                    <SwitchFormControl name={`education_list[${index}].switch`} control={control} />
                  </div>
                </div>
              </div>
            ) : (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_dop`}>
                  <SelectFormControl
                    label="Уровень образования"
                    name={`education_list[${index}].level`}
                    control={control}
                    rules={{ required: true }}
                    options={educLevel}
                  />
                  <InputFormControl
                    label="Учебное заведение"
                    name={`education_list[${index}].name`}
                    control={control}
                    rules={{ required: true }}
                  />
                  <Button className={`${mainCssClass}_items_dop_close`} onClick={() => handleRemove(index)}>
                    <CloseMD />
                  </Button>
                </div>

                <div className={`${mainCssClass}_items_courses`}>
                  <InputFormControl label="Факультет" name={`education_list[${index}].faculty`} control={control} />
                  <InputFormControl
                    label="Специализация"
                    name={`education_list[${index}].specialization`}
                    control={control}
                  />
                </div>
                <div className={`${mainCssClass}_items_courses`}>
                  <DatePickerFormControl
                    label="Начало обучения"
                    name={`education_list[${index}].period_start`}
                    control={control}
                  />
                  {!watch(`education_list[${index}].switch`) && (
                    <DatePickerFormControl
                      label="Окончание обучения"
                      name={`education_list[${index}].period_end`}
                      control={control}
                    />
                  )}
                  <div className={`${mainCssClass}_items_courses_switch`}>
                    <span>По настоящее время</span>
                    <SwitchFormControl name={`education_list[${index}].switch`} control={control} />
                  </div>
                </div>
              </div>
            )))}
            <Button
              onClick={() => {
                append({
                  level: '',
                  name: '',
                  faculty: '',
                  specialization: '',
                  period_start: '',
                  period_end: '',
                  switch: false
                })
              }}
              className={`${mainCssClass}_items_add`}
            >
              <AddPlusSvg />
              Добавить место учёбы
            </Button>
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalEducation))
