import React, {
  useEffect,
  useMemo,
  useState
} from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { ModalProps } from 'antd'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { openInternshipDateModal, updateInternshipDate } from '../actions'
import { selectSelectedInternship } from '../selectors'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import { createArrayOfTimeObjects } from '../../../compositions/candidateV2/utils'
import './InternshipEditModal.scss'

const mainCSSclass = 'internship-edit-modal'

type TFormValues = {
      time: string,
      date: string
}

type Props = ModalProps & {
  date: string
}

function InternshipEditModal({
  open, className, date
}: Props) {
  const selectedInternship = useSelector(selectSelectedInternship)
  const [timeOPtions, setTimeOptions] = useState(createArrayOfTimeObjects())

  const defaultValues = useMemo(
    () => (selectedInternship
      ? {
        date: selectedInternship.date,
        time: selectedInternship.time
      }
      : {}),
    [selectedInternship]
  )
  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>({ defaultValues })
  const dispatch = useDispatch()

  const onSubmit = () => {
    dispatch(updateInternshipDate({
      data: { id: selectedInternship.candidate_internship_id, ...getValues() },
      date
    }))
  }

  const handleOnCancel = () => {
    dispatch(openInternshipDateModal(false))
  }

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleOnCancel}
      centered
      title="Редактировать стажировку"
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCSSclass}>
        <DatePickerFormControl
          name="date"
          label="Выберите дату стажировки"
          placeholder="Дата"
          control={control}
          rules={{ required: true }}
        />
        <SelectFormControl
          name="time"
          label="Время стажировки"
          control={control}
          options={timeOPtions}
        />
      </div>
    </CommonModal>
  )
}

export default InternshipEditModal
