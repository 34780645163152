/* eslint-disable indent */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Button, Typography } from 'antd'

import { useForm } from 'react-hook-form'
import SelectFormControl from '../../../../common/components/formcontrol/SelectFormControl'
import { selectFilterString } from '../../../../features/reportFilter'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { Heading } from '../../../../common/components/Text'
import RejectedCandidatesTable from './RejectedCandidatesTable'
import AddedCandidatesTable from './AddedCandidatesTable'
import { reset } from '../../../Systems'
import {
  selectLoading, selectMethod1, selectMethod2, selectVertex, selectVertexMethod
} from '../../selectors'
import { getVertexInfo, setVertexMethod } from '../../actions'
import './VertexInfo.scss'
import { methodOptions } from '../../utils'
import MonitorPlay from '../../../../common/svg/MonitorPlay'
import { Preloader } from '../../../../common/components/Preloader/Preloader'

const mainCssClass = getCssClassName('vertex')

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'none' as const
    },
    title: {
      display: false
    }
  }
}

type TFormValues = {
  method:string
}

export function VertexInfo() {
  const dispatch = useDispatch()
  const sourseOfCandidates = useSelector(selectVertex)
  const filter = useSelector(selectFilterString)
  const vertexMethod = useSelector(selectVertexMethod)
  const filtersString = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)
  const {
    control, watch, getValues, setValue
  } = useForm<TFormValues>({ defaultValues: { method: vertexMethod } })
  const filterTotal = (items) => items.filter((item) => item.label !== 'Всего')

  const analyticRow1 = sourseOfCandidates?.[0]?.analyticRow || []
  const labels = filterTotal(analyticRow1).map((item) => item.label)
  const dataValues = filterTotal(analyticRow1).map((item) => item.count)

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  const analyticRow2 = sourseOfCandidates?.[1]?.analyticRow || []
  const secondLabels = filterTotal(analyticRow2).map((item) => item.label) || []
  const secondDataValues = filterTotal(analyticRow2).map((item) => item.count) || []

  const secondChartData = {
    labels: secondLabels,
    datasets: [
      {
        label: '',
        data: secondDataValues,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  const method = watch('method')

  const handleChangeVertexMethod = (value :string) => {
    dispatch(setVertexMethod(value))
  }

  useEffect(() => {
    if (filtersString) {
      dispatch(getVertexInfo({ filter, method1: method, method2: method }))
    }
  }, [filter, method])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>
      {/* <div className={`${mainCssClass}__select-block`}> */}
      {/*  <SelectFormControl name="method1" control={control} options={methodOptions} /> */}
      {/* </div> */}
      <div className={`${mainCssClass}__title-block`}>
        <div>
          <Heading.H3>
            Воронка подбора
          </Heading.H3>
        </div>
      </div>
      {loading && <Preloader size="md" />}
      {filtersString
            && !loading && (
            <>
              <div className={`${mainCssClass}__title-block`}>
                <div>
                  <Heading.H4>
                    Откликнувшиеся
                  </Heading.H4>
                  <Typography.Paragraph>
                    {sourseOfCandidates?.[0].total}
                  </Typography.Paragraph>
                  <div className={`${mainCssClass}__select-block`}>
                    <SelectFormControl name="method" control={control} options={methodOptions} onChange={(value) => handleChangeVertexMethod(value)}/>
                  </div>
                </div>
              </div>
              <div className={`${mainCssClass}__table`}>
                <Bar options={options} data={data} />
              </div>
              <div>
                <RejectedCandidatesTable sourse={sourseOfCandidates} />
              </div>
              <div className={`${mainCssClass}__title-block`}>
                <div>
                  <Heading.H4>
                    Добавленные
                  </Heading.H4>
                  <Typography.Paragraph>
                    {sourseOfCandidates?.[1].total}
                  </Typography.Paragraph>
                </div>
                {/* <SelectFormControl name="method2" control={control} options={methodOptions} /> */}
              </div>
              <div className={`${mainCssClass}__table`}>
                <Bar options={options} data={secondChartData} />
              </div>
              <div>
                <AddedCandidatesTable sourse={sourseOfCandidates} />
              </div>
            </>
      )}
      {!filtersString && !loading
          && (
            <div className={`${mainCssClass}_filter`}>
              <span>
                Отчет появится после настройки фильтров.
                <br />
                Настройте фильтры и нажмите кнопку (Применить)
              </span>
            </div>
          )}

    </div>

  )
}
