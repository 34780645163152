import React from 'react'
import Icon from '../svgIcons/CallBack.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export default function CallBack(props: IconProps) {
  return (
    <IconWrapper {...props} name="CallBack" size="sm">
      <Icon />
    </IconWrapper>
  )
}
