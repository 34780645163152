import React from 'react'
import { DatePicker, DatePickerProps, Typography } from 'antd'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { useController, UseControllerProps } from 'react-hook-form'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import cn from 'classnames'
import './DatePickerFormControl.scss'

const { Title } = Typography

type TDatePickerFormControlProps = Omit<
  UseControllerProps &
  DatePickerProps & {
  className?: string;
  size?: 'small' | 'middle' | 'large';
  label?: string;
},
  'isDisabled'
>;

interface IDatePickerFormControlProps extends TDatePickerFormControlProps {
  isTime?: boolean;
  withoutRange?: boolean
  ranges?: any
  rules?: {
    required: boolean;
  };
}

const mainCssClass = 'datePickerFormControl'

const { RangePicker } = DatePicker

function RangePickerFormControl({
  name,
  control,
  rules,
  className,
  size = 'large',
  label,
  picker,
  format,
  placeholder,
  isTime,
  withoutRange = false,
  ...rest
}: IDatePickerFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  const handleDatePickerChange = (dates: RangePickerValue) => {
    if (Array.isArray(dates) && !isTime) {
      const [startDate, endDate] = dates
      const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null
      const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null
      field.onChange([formattedStartDate, formattedEndDate])
    } else if (Array.isArray(dates) && isTime) {
      const [startDate, endDate] = dates
      let adjustedStartDate = startDate
      let adjustedEndDate = endDate
      if (startDate.hour() < 9) {
        adjustedStartDate = startDate.clone().hour(9).minute(0)
      }
      if (endDate.hour() > 21) {
        adjustedEndDate = endDate.clone().hour(21).minute(0)
      }
      if (adjustedStartDate.isAfter(adjustedEndDate) || adjustedStartDate.isSame(adjustedEndDate)) {
        adjustedEndDate = adjustedStartDate.clone().hour(21).minute(0)
      }

      const formattedStartDate = adjustedStartDate.minute(0);
      const formattedEndDate = adjustedEndDate.minute(0);
      field.onChange([formattedStartDate, formattedEndDate]); 
    } else field.onChange(null)
  }

  const renderDateCell = (current: dayjs.Dayjs) => {
    const isSelectedDate = field.value && current.isSame(dayjs(field?.value?.[1]), 'day')

    const isSelectedDateTwo = field.value && current.isSame(dayjs(field?.value?.[0]), 'day')
    const isToday = current.isSame(dayjs(), 'day') && !field.value && !field?.value?.[1]
    return (
      <div className={cn({ 'today': isToday, 'selected': isSelectedDate || isSelectedDateTwo })}>
        {current.date()}
      </div>
    )
  }
  const disabledHours = () => {
    const currentHour = dayjs().hour()
    if (currentHour >= 9 && currentHour <= 21) {
      return []
    }
    return [...Array(24).keys()].filter((hour) => hour < 9 || hour > 21)
  }
  const customRanges = {
    'За последние 7 дней': [dayjs().subtract(6, 'days'), dayjs()],
    'За последний месяц': [dayjs().subtract(1, 'month'), dayjs()],
    'За последний год': [dayjs().subtract(1, 'year'), dayjs()]
  }
  return (
    <div className={className}>
      {label && (
        <Title className="datePickerFormControl_label" level={4}>
          {label}
        </Title>
      )}

      <RangePicker
        onChange={handleDatePickerChange}
        placeholder={placeholder || ['Начало', 'Конец']}
        className={cn(
          mainCssClass,
          { datePickerLarge: size === 'large' },
          { datePickerMiddle: size === 'middle' },
          { datePickerSmall: size === 'small' },
          { error: fieldState.error }
        )}
        value={field.value ? [dayjs(field.value[0]), dayjs(field.value[1])] : null}
        size={size}
        showTime={
          isTime
            ? {
              hideDisabledOptions: true,
              format: 'HH:00',
              defaultValue: [dayjs().hour(9).minute(0), dayjs().hour(21).minute(0)],
              disabledHours: () => {
                const currentHour = dayjs().hour()
                if (currentHour >= 9 && currentHour <= 21) {
                  return []
                }
                return [...Array(24).keys()].filter((hour) => hour < 9 || hour > 21)
              }
            }
            : undefined
        }
        disabledHours={disabledHours}
        picker={picker}
        format={format}
        dateRender={renderDateCell} // Передаем функцию dateRender
        ranges={withoutRange ? null : customRanges} // Передаем пользовательские диапазоны
      />
    </div>
  )
}

export default RangePickerFormControl
