import cn from 'classnames'
import React from 'react'
import { Button, Typography } from 'antd'
import {
  BasicPropsNarrow, NotificationBodyProps, NotificationLinkProps, NotificationStatus
} from '../types'

import './styles.scss'
import { isDefined, isTruthy } from '../../../../utils/utilsContext'
import { Link } from '../../../Link'
import { ErrorTriangleSolid } from '../../../../svg/SvgFromIconWrapper/ErrorTriangleSolid'
import { OkSolid } from '../../../../svg/SvgFromIconWrapper/OkSolid'
import { InfoSolid } from '../../../../svg/SvgFromIconWrapper/InfoSolid'
import { AttentionSolid } from '../../../../svg/SvgFromIconWrapper/AttentionSolid'

const ICON_SIZE = 'sm'

const mainCssClass = 'NotificationBody'
export function NotificationBody({
  linkColor = 'primaryLight',
  'data-testid': testId = 'notification',
  children,
  className,
  href,
  linkText,
  navigate,
  onLinkClick,
  status,
  theme,
  title,
  type,
  buttonConfig,
  style
}: NotificationBodyProps & BasicPropsNarrow) {
  const hasTitle = isTruthy(title)
  const hasLink = isTruthy(linkText) && isTruthy(href)

  return (
    <div
      className={cn(
        className,
        mainCssClass,
        `${mainCssClass}__${theme}`,
        `${mainCssClass}__${status}`,
        `${mainCssClass}____${type}`
      )}
      data-testid={`${testId}-body`}
      style={style}
    >
      <div
        className={`${mainCssClass}_contentWrapper`}
        data-testid={`${testId}-contentWrapper`}
      >
        <div className={`${mainCssClass}_iconContainer`}>{renderLeftIcon(status, true)}</div>
        <div className={`${mainCssClass}_inner`}>
          <div className={`${mainCssClass}_innerTextBlock`}>
            {hasTitle && <Typography.Title className={`${mainCssClass}_title`} level={5}>{title}</Typography.Title>}
            <span
              className={`${mainCssClass}_content`}
              data-testid={`${testId}-body-content`}
            >
              <span className={`${mainCssClass}_text`}>
                {children}
                {' '}
              </span>
              {type === 'line' && hasLink && (
                <NotificationLink
                  data-testid={`${testId}-link`}
                  href={href}
                  navigate={navigate}
                  onLinkClick={onLinkClick}
                  linkText={linkText}
                  color={linkColor}
                />
              )}
            </span>
            {type === 'toast' && hasLink && (
              <NotificationLink
                data-testid={`${testId}-link`}
                href={href}
                navigate={navigate}
                onLinkClick={onLinkClick}
                linkText={linkText}
                color={linkColor}
                className={`${mainCssClass}_link`}
              />
            )}
          </div>
          {isDefined(buttonConfig) ? (
            <Button
              type="ghost"
              {...buttonConfig}
              className={cn(`${mainCssClass}_action`, buttonConfig.className)}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

const mainCssClassIcon = 'InfoIcon'
function renderLeftIcon(status: NotificationStatus, alignIconTop: boolean) {
  const className = cn(mainCssClassIcon, { [`${mainCssClassIcon}__alignTop`]: alignIconTop })

  switch (status) {
  case 'success': {
    return (
      <OkSolid size={ICON_SIZE} className={className} data-testid={`notification-${status}`} />
    )
  }
  case 'warning': {
    return (
      <AttentionSolid
        size={ICON_SIZE}
        className={className}
        data-testid={`notification-${status}`}
      />
    )
  }
  case 'danger': {
    return (
      <ErrorTriangleSolid
        size={ICON_SIZE}
        className={className}
        data-testid={`notification-${status}`}
      />
    )
  }
  default: {
    return (
      <InfoSolid size={ICON_SIZE} className={className} data-testid={`notification-${status}`} />
    )
  }
  }
}

function NotificationLink({
  color = 'primary',
  href,
  linkText,
  navigate,
  onLinkClick,
  className
}: NotificationLinkProps) {
  return (
    <Link
      href={href}
      navigate={navigate}
      className={cn(`${mainCssClass}_linkText`, className)}
      size="sm"
      onClick={onLinkClick}
      color={color}
    >
      {linkText}
    </Link>
  )
}
