/* eslint-disable no-console */
import React, { memo, useState } from 'react'
import { Button } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import DebounceSelectFormControlTags from '../../../../../common/components/formcontrol/DebounceSelectFormControlTags'
import { selectCandidate } from '../../../selectors'
import withErrorBoundary from '../../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { fetchTagsList } from '../../../../../features/filters/utils'
import { createTagCandidate } from '../../../actions'
import './TagInput.scss'

interface TFormValues {
  label: string
}

interface TTagInputProps {
  onChange: (value: boolean) => void
}

function TagInput({ onChange }: TTagInputProps) {
  const [inputValue, setInputValue] = useState<string>('')

  const { control, getValues, watch } = useForm<TFormValues>()
  const candidate = useSelector(selectCandidate)
  const dispatch = useDispatch()

  const handleClickAddTag = () => {
    const data = getValues()
    const selectedValue = data.label ? data.label.value : undefined;

    if (data.label && data.label.value ) {
      dispatch(createTagCandidate({ label: selectedValue, candidate_id: candidate?.id }))
      onChange(false)
    }
    else if (inputValue) {
      dispatch(createTagCandidate({ label: inputValue, candidate_id: candidate?.id }))
      onChange(false)
    }
  }

  const handleClickClose = () => {
    onChange(false)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClickAddTag()
    }
  }

  const handleInputValueChange = (value: string) => {
    setInputValue(value)
  }

  return (
    <div className="tagInput">
      <DebounceSelectFormControlTags
        name="label"
        control={control}
        type="text"
        onKeyDown={handleKeyPress}
        placeholder="Новый тэг"
        fetchOptions={fetchTagsList}
        notFoundContent="Начните вводить тэг"
        onInputValueChange={handleInputValueChange}
      />
      <Button className="custom-button check" shape="circle" icon={<CheckOutlined />} onClick={handleClickAddTag} />
      <Button shape="circle" icon={<CloseOutlined />} className="custom-button" onClick={handleClickClose} />
    </div>
  )
}

export default memo<TTagInputProps>(withErrorBoundary<TTagInputProps>(TagInput))
