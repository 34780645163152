import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import excel from '../../common/svg/Excel'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ internship }: TRootState) => internship

export const selectTimeSlots = createSelector(selectState, ({ timeSlots }) => timeSlots)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectPage = createSelector(selectState, ({ page }) => page)

export const selectInternship = createSelector(selectState, ({ internship }) => internship)

export const selectOpenInternshipModal = createSelector(selectState, ({ getInternshipModal }) => getInternshipModal)

export const selectUpdateInternship = createSelector(selectState, ({ updateInternship }) => updateInternship)

export const selectSelectedInternship = createSelector(selectState, ({ selectedInternship }) => selectedInternship)

export const selectDefDataInternship = createSelector(selectState, ({ defDataInternship }) => defDataInternship)

export const selectInternshipFilter = createSelector(selectState, ({ internshipFilter }) => internshipFilter)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectFilterString = createSelector(selectState, ({ filter }) => filter)

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectSearchString = createSelector(selectState, ({ searchInternship }) => searchInternship)

export const selectInternshipDateModal = createSelector(selectState, ({ internshipDateModal }) => internshipDateModal)
