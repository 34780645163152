import { createAction } from '@reduxjs/toolkit'
import { TDictionariesNames, TDictionaries } from '../../common/types/dictionaries'
import { TPatchVacancy, TRequestCreateVacancy, TVacancy } from '../../common/api/vacancy'

export type TDuplicateVacancyName = TVacancy
export type TChangeStatusVacancyModal = {
  status: number
  id: string | number
}
// Авторизация
export const getDictionaries = createAction<TDictionariesNames>('vacancyModals/dictionaries/getData')

export const setDictionariesData = createAction<TDictionaries>('vacancyModals/dictionaries/setData')

export const duplicateVacancyName = createAction<TDuplicateVacancyName>('vacancyModals/duplicateVacancyName')

export const changeStatusVacancyModal = createAction<TChangeStatusVacancyModal>(
  'vacancyModals/changeStatusVacancyModal'
)

export const createVacancy = createAction<TRequestCreateVacancy>('vacancyModals/createVacancy')

export const updateVacancy = createAction<TPatchVacancy>('vacancyModals/updateVacancy')
