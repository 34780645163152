import React from 'react'
import { TClassName } from '../types/props'

const Excel: React.FC<TClassName> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
    <path d="M14.6336 3.87697H17.7214V5.45037H14.6336V3.87697Z" fill="#2DBE64" />
    <path d="M14.6336 6.38086H17.7214V7.95426H14.6336V6.38086Z" fill="#2DBE64" />
    <path d="M17.7214 8.88469H14.6336V10.4581H17.7214V8.88469Z" fill="#2DBE64" />
    <path d="M14.6336 11.3885H17.7214V12.9619H14.6336V11.3885Z" fill="#2DBE64" />
    <path d="M17.7214 13.8924H14.6336V15.4658H17.7214V13.8924Z" fill="#2DBE64" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1293 1.70713H11.8936V0L0 1.83801V17.9489L11.8936 20V17.4697H19.1293C19.3484 17.4808 19.563 17.4046 19.7262 17.2577C19.8894 17.1108 19.9878 16.9052 20 16.6859V2.49026C19.9876 2.27103 19.8891 2.06561 19.726 1.91887C19.5628 1.77214 19.3483 1.69601 19.1293 1.70713ZM19.2436 16.816H11.8693L11.8571 15.465H13.6336V13.8916H11.8436L11.835 12.9619H13.6336V11.3885H11.8214L11.8129 10.4588H13.6336V8.88539H11.8071V7.95566H13.6336V6.38226H11.8071V5.45253H13.6336V3.87913H11.8071V2.44878H19.2436V16.816ZM3.105 6.1899L4.63786 6.10193L5.60143 8.75453L6.74 5.99251L8.27286 5.90454L6.41143 9.67068L8.27286 13.4461L6.65215 13.3367L5.55786 10.4588L4.46286 13.2273L2.97357 13.0957L4.70357 9.76079L3.105 6.1899Z" fill="#2DBE64" />
  </svg>
)
export default Excel
