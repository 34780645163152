import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getDictionaries, updateStatusCandidate } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './HidePostModal.scss'

import { selectIsOpenAutoDial, selectIsOpenNewAutoDial } from '../../../compositions/Fluidity/selectors'
import { setOpenModalAutoDial, setOpenModalNewAutoDial } from '../../../compositions/Fluidity/actions'
import withErrorBoundary from '../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../common/components/CommonModal/CommonModal'

const mainCssClass = 'hide-post-modal'

type TProps = {
  candidateId?: number | null | string
} & ModalProps
function HidePostModal({
  onOk, open, className, onCancel, options, candidateId
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()
  type TFormValues = {
    status: number
  }

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
      reset()
    }
  }
  // const handleSubmit = useCallback(() => {
  //   if (candidateId) {
  //     const data = watch('status')
  //     if (data) {
  //       dispatch(updateStatusCandidate({ id: candidateId, ...getValues(), isCandidateTable }))
  //     }
  //   }
  // }, [candidateId, isCandidateTable])
  //
  // useEffect(() => {
  //   if (isCandidateTable) {
  //     dispatch(getDictionaries(['candidateStatus']))
  //   }
  // }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      centered
      open={open}
      onOk={onOk}
      onCancel={handleModalClose}
      title="Скрыть запись"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Скрыть"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        Вы хотите скрыть запись из таблицы. Ее можно будет верну ть из раздела “Скрытые записи”
      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(HidePostModal))
