import { TFakeCandidate } from './types'
import { TDATA } from '../candidate/types'
import { formValuesArray } from '../../features/filters/components/Filters'

export function generateCandidateFilters(words: string) {
  const filters = []

  if (words) {
    const newWords = words.split(' ')

    for (let i = 0; i < newWords.length; i++) {
      const name_like = `[name][like]=${newWords[i]}`
      const surname_like = `[last_name][like]=${newWords[i]}`
      const second_name_like = `[second_name][like]=${newWords[i]}`

      filters.push(name_like, surname_like, second_name_like)
    }

    const query = filters.join('&filter')
    return `${query}&unshift=OR`
  }
}

export function getDashboardFilter(name_group: number[], period: string, list_user: any[], stage_type: number) {
  return `filter[period]=${period}${name_group && name_group.length ? `&filter[list_group]=${name_group.join(',')}` : ''}&filter[stage_type]=${stage_type}${list_user && list_user.length ? `&filter[list_user]=${list_user.map((el) => el.value).join(',')}` : ''}`
}

export function managamentCandidatesDefLabel(key: string, keyValue: any[], stage_id?: number, stage_label?: string) {
  return formValuesArray.map((item) => (item.key === key ? { ...item, value: keyValue }

    : stage_id && item.key === 'stage_id' ? { ...item, value: [stage_label] }
      : item))
}

export function filterUserListDashbord(primaryArray: number[], secondaryArray: any[]) {
  console.log(secondaryArray,'secondary')
  return secondaryArray.filter((item) => item.group.some((keyElement) => primaryArray.includes(keyElement)))
}
