import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useParams } from 'react-router'
import {
  Button, Tabs, Typography, Radio
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import { loopHooks } from 'react-table'
import dayjs from 'dayjs'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'

import './ManagementInterviewsMobil.scss'
import {
  exportCSV, getInterviews, getWorkload, reset, setDefStringInterviews, setWorkloadPage
} from '../actions'
import {
  selectAllInterviewsCount,
  selectInterviews,
  selectMineInterviewsCount,
  selectResultInterviewsCount,
  selectLoading,
  selectDefString, selectWorkload, selectWorkloadPage, selectWorkloadFilterString
} from '../selectors'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { openFilters, setFilters } from '../../../features/filters/actions'

import { selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import { removeFilterInterviewFragments } from '../utils'
import InputFormControlGlobalSearch from '../../../common/components/formcontrol/InputFormControlGlobalSearch'
import TabPane = Tabs.TabPane
import { selectUserInfo } from '../../InnerRouter/selectors'
import { setVisibleTable } from '../../InnerRouter/actions'
import { setDefStringFunnel } from '../../Funnel/actions'
import InterviewsTableMobil from './InterviewsTableMobil'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import InterviewsTable from './InterviewsTable'
import Workload from './Workload'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import { SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'

const mainCssClass = getCssClassName('management-interviews-mobil')
const ManagementInterviews: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState<number>(1)
  const [requestStatus, setRequestStatus] = useState<string>('all')
  const workload = useSelector(selectWorkload)
  const workloadPage = useSelector(selectWorkloadPage)
  const interviews = useSelector(selectInterviews)
  const userInfo = useSelector(selectUserInfo)
  const allCount = useSelector(selectAllInterviewsCount)
  const resultCount = useSelector(selectResultInterviewsCount)
  const mineCount = useSelector(selectMineInterviewsCount)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const userId = localStorage.getItem('user_id')
  const nameString = useSelector(selectDefString)
  const workloadFilter = useSelector(selectWorkloadFilterString)
  const defFilters = useSelector(selectDefFilters)
  const { pageId } = useParams()

  const { control, watch, getValues } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10,
      globalSearch: nameString
    }
  })

    type TFormValues = {
        perPagesCount: number
        globalSearch:string
        date: string
    }

    const perPagesCount = watch('perPagesCount')
    const maxPage = useMemo(() => {
      const counted = resultCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }, [resultCount, perPagesCount])

    const memoRequestStatus = useMemo(() => (filtersString !== requestStatus && requestStatus !== 'all' ? requestStatus : ''), [requestStatus, filtersString])

    const handleTabClick = useCallback((e) => {
      if (e === '1') {
        dispatch(setVisibleTable(false))
        setRequestStatus('all')
        dispatch(setWorkloadPage(false))
      } else if (e === '3') {
        setRequestStatus('workload')
        dispatch(setWorkloadPage(true))
      } else {
        dispatch(setVisibleTable(true))
        dispatch(setWorkloadPage(false))
        setRequestStatus('all')
      }
    }, [])

    const date = watch('date')

    useEffect(() => {
      setPage(1)
    }, [perPagesCount])

    const globalSearch = watch('globalSearch')

    useEffect(
      () => {
        dispatch(setDefStringInterviews(globalSearch))
      },
      [globalSearch]
    )

    useEffect(() => {
      if ((pageId === defFilters?.type) && defFilters?.defFilterString) { dispatch(setFilters(defFilters?.defFilterString)) }
      dispatch(setDefStringFunnel(''))
    }, [])

    useEffect(() => {
      if (filtersString) {
        dispatch(getInterviews({
          page,
          perPage: perPagesCount,
          filters: globalSearch
            ? `${filtersString}&union[fio]=${globalSearch}${memoRequestStatus}` : `${filtersString}${memoRequestStatus}`
        }))
      } else if (!filtersString && globalSearch) {
        dispatch(getInterviews({ page, perPage: perPagesCount, filters: `&union[fio]=${globalSearch}${memoRequestStatus}` }))
      } else {
        dispatch(getInterviews({ page, perPage: perPagesCount, filters: memoRequestStatus }))
      }
    }, [page, perPagesCount, filtersString, globalSearch, memoRequestStatus])

    useEffect(
      () => () => {
        dispatch(reset())
        dispatch(setFilters(''))
      },
      []
    )

    useEffect(() => {
      if (requestStatus === 'workload' && date) {
        dispatch(getWorkload({
          date: dayjs(date).format(SERVER_DATE_FORMAT),
          filter: workloadFilter || ''
        }))
      }
    }, [date, requestStatus, workloadFilter])

    return (
      <div className={cn(className, mainCssClass)}>
        <div className={`${mainCssClass}__heading`}>
          <div className={`${mainCssClass}__title`}>
            <Typography.Title level={4}>Интервью</Typography.Title>
            {loading && <Preloader size="md" />}
          </div>
          <div className={`${mainCssClass}__count`}>
            <Typography.Title level={5} className={`${mainCssClass}__title`}>
              &nbsp;
              {resultCount}
            </Typography.Title>
          </div>
        </div>
        <div className={`${mainCssClass}__container-info`}>
          <div className={`${mainCssClass}__container-pagination`}>
            {(userInfo?.role?.role_id === 'recruiter' || userInfo?.role?.role_id === 'superadmin' || userInfo?.role?.role_id === 'admin') && (
              <div className={`${mainCssClass}__tabs`}>
                <Tabs onChange={handleTabClick} defaultActiveKey="2">
                  <TabPane key="1" tab="Календарь" />
                  <TabPane key="2" tab="Таблица" />
                  {(userInfo?.role?.role_id === 'superadmin' || userInfo?.role?.role_id === 'admin') && (<TabPane key="3" tab="Загруженность" />)}
                </Tabs>
              </div>
            )}
          </div>
        </div>
        {!loading && (
          <>
            <div className={`${mainCssClass}__search-container`}>
              <div className={`${mainCssClass}__input-container`}>
                {!workloadPage && (<InputFormControlGlobalSearch name="globalSearch" control={control} placeholder="ФИО кандидата" />)}
                <div>
                  {(userInfo?.role?.role_id === 'superadmin' || userInfo?.role?.role_id === 'admin') && workloadPage && (
                    <DatePickerFormControl
                      control={control}
                      name="date"
                      isDisableDate
                      tasks
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${mainCssClass}__scroll-div`}>
              <ScrollContainer className={`${mainCssClass}__scroll-container`}>
                {requestStatus !== 'workload' && <InterviewsTableMobil interviews={interviews || []} className={`${mainCssClass}__table`} />}
                {requestStatus === 'workload' && workload && <Workload />}
              </ScrollContainer>
            </div>
          </>
        )}
      </div>
    )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ManagementInterviews))
