import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch } from 'react-redux'
import {
  mergeDuplicates,
  setOpenModalArchive,
  setOpenModalAssignVacancy,
  setOpenModalChangeStatus,
  setSelectedCandidateId,
  setSelectedStatus
} from '../actions'
import MenuDrop from '../../../common/svg/MenuDrop'
import { TMainProps } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { useEntityContext } from '../../../common/context/EntityContextProvider'

type TProps = {
  id: string | number | null
  status: string | number | null
  is_duplicates: boolean
}
const ButtonsTable: FC<TProps> = ({ id, status, is_duplicates }) => {
  const dispatch = useDispatch()
  const { rbacControl } = useEntityContext()

  const handleOpenChangeStatus = useCallback(() => {
    dispatch(setOpenModalChangeStatus(true))
    dispatch(setSelectedCandidateId(id))
    dispatch(setSelectedStatus(status))
  }, [])

  const handleOpenArchive = useCallback(() => {
    dispatch(setOpenModalArchive(true))
    dispatch(setSelectedCandidateId(id))
  }, [])
  const handleMergeDuplicates = useCallback(() => {
    dispatch(mergeDuplicates(id))
  }, [])

  const handleOpenApproveVacancy = useCallback(() => {
    dispatch(setOpenModalAssignVacancy(true))
    dispatch(setSelectedCandidateId(id))
  }, [])

  const items: MenuProps['items'] = [
    ...(rbacControl?.candidate?.edit_view_access === 1 ? [{
      key: 'change-status',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={handleOpenChangeStatus}
        >
          Изменить статус
        </Button>
      )
    }] : []),
    ...(rbacControl?.candidate?.archive_access === 1 && status !== 51 ? [{
      key: 'archive',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenArchive}>
          Отклонить
        </Button>
      )
    }
    ] : []), ...(rbacControl?.candidate?.archive_access === 1 && is_duplicates ? [{
      key: 'archive',
      label: (
        <Button type="ghost" size="large" onClick={handleMergeDuplicates}>
          Соединить дубликаты
        </Button>
      )
    }
    ] : [])]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
