/* eslint-disable no-multi-str */
/* eslint-disable no-useless-escape */
import React, {
  memo,
  useCallback,
  useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import ControlButton from '../../../common/components/Buttons/Button'
import CloseMD from '../../../common/svg/CloseMD'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import { selectActiveQuestionnare, selectActiveTemplate } from '../selectors'
import { getAttribute, getModel } from '../utils'
import {
  createTemplate, openTemplatesForm, setActiveTemplate, updateTemplate
} from '..'

import TemplateSelect from '../../../common/components/formcontrol/TemplateSelect'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './TemplatesForm.scss'

const mainCssClass = getCssClassName('templates-form')

const tinymceConfig = {
  height: 200,
  selector: 'textarea#file-picker',
  menubar: false,
  plugins: [
    'lists',
    'link'
  ],
  toolbar: 'numlist | formatselect | bold italic underline | \
      alignleft aligncenter alignright | \ image'
}

type TFormValues = {
  name: string
  params: { parametr: number | null; value_parametr:null | number}[]
  text: string
} & any

function TemplatesForm() {
  const dispatch = useDispatch()

  const activeQuestionnaire = useSelector(selectActiveQuestionnare)
  const activeTemplate = useSelector(selectActiveTemplate)
  // const [editorContent, setEditorContent] = useState<string>(
  //   activeTemplate ? activeTemplate.text : ''
  // )

  const [model, setModel] = useState<any>(undefined)
  const [candOptions, setCandOptions] = useState<any>(undefined)
  const [reqOptions, setReqOptions] = useState<any>(undefined)
  const [intOptions, setIntOptions] = useState<any>(undefined)
  const [vacOptions, setVacOptions] = useState<any>(undefined)

  const defaultValues = useMemo(() => (
    activeTemplate ? {
      name: activeTemplate.name,
      params: activeTemplate.params.map((el) => el.split('.')).map((el) => ({ parametr: el[0], value_parametr: el[1] })),
      text: activeTemplate.text
    } : {}
  ), [activeTemplate])

  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray({
    control,
    name: 'params'
  })

  if (fields?.length === 0) {
    prepend({
      parametr: null,
      value_parametr: null
    })
  }

  const handleParametrChange = (index: number, selectedParametr: number | null) => {
    setValue(`params[${index}].parametr`, selectedParametr)
    setValue(`params[${index}].value_parametr`, null)
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  const getOptions = useCallback((arg) => {
    // eslint-disable-next-line default-case
    switch (arg) {
    case 'candidate':
      return candOptions
    case 'request':
      return reqOptions
    case 'interview':
      return intOptions
    case 'vacancy':
      return vacOptions
    default: return []
    }
  }, [candOptions, reqOptions, intOptions, vacOptions])

  const handleCancel = () => {
    dispatch(openTemplatesForm(false))
    dispatch(setActiveTemplate(null))
  }

  const onSubmit = () => {
    if (!activeTemplate) {
      const params = watch('params')
      dispatch(createTemplate({
        ...getValues(),
        params: params[0].parametr ? params.map((el) => `${el.parametr}.${el.value_parametr}`) : [],
      }))
    } else if (activeTemplate) {
      const params = watch('params')
      dispatch(updateTemplate({
        ...getValues(),
        params: params[0].parametr ? params.map((el) => `${el.parametr}.${el.value_parametr}`) : [],
        id: activeTemplate.id
      }))
    }
  }

  useLayoutEffect(() => {
    getModel()
      .then((responseData) => {
        setModel(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    getAttribute('candidate')
      .then((responseData) => {
        setCandOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    getAttribute('request')
      .then((responseData) => {
        setReqOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    getAttribute('interview')
      .then((responseData) => {
        setIntOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    getAttribute('vacancy')
      .then((responseData) => {
        setVacOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  // const handleEditorChange = (content: string, editor: any) => {
  //   setEditorContent(content)
  // }

  return (

    <div className={mainCssClass}>
      <Typography.Title level={3}>
        {activeQuestionnaire ? 'Редактировать шаблон' : 'Новый шаблон'}
      </Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl
              name="name"
              control={control}
              label="Название шаблона"
              required
              rules={{ required: true }}
            />

            {/* <Editor
              apiKey="8x5c0qsr1y61k41aahbqheo3lfgc7qitppq2poxor6cmvtfn"
              value={editorContent}
              init={tinymceConfig}
              onEditorChange={handleEditorChange}
            /> */}
            <TextAreaFormControl
              name="text"
              control={control}
              autoSize={{ minRows: 4, maxRows: 5 }}
              rules={{ required: true }}
              label="Текст шаблона"
              required
            />

            {fields?.map((email, index) => (index === 0 ? (
              <div className={`${mainCssClass}_list_item-first`} key={index}>
                <TemplateSelect
                  name={`params[${index}].parametr`}
                  control={control}
                  label="Сущность"
                  options={model}
                  required
                  onChange={(selectedParametr: number | null) => handleParametrChange(index, selectedParametr)}
                />

                {watch(`params[${index}].parametr`) && (
                  <SelectFormControl
                    name={`params[${index}].value_parametr`}
                    control={control}
                    label="Значение"
                    required
                    options={getOptions(watch(`params[${index}].parametr`))}
                    rules={{ required: true }}
                  />
                )}

                <ControlButton onClick={() => append({ parametr: null, value_parametr: null })} typeButton="secondary" className="button">
                  <AddPlusSvg />
                </ControlButton>
              </div>
            ) : (
              <div className={`${mainCssClass}_list_item-other`} key={index}>
                <TemplateSelect
                  name={`params[${index}].parametr`}
                  control={control}
                  label="Сущность"
                  options={model}
                  required
                  onChange={(selectedParametr: number | null) => handleParametrChange(index, selectedParametr)}

                />

                {watch(`params[${index}].parametr`) && (
                  <SelectFormControl
                    name={`params[${index}].value_parametr`}
                    control={control}
                    label="Значение"
                    options={getOptions(watch(`params[${index}].parametr`))}
                    required
                    rules={{ required: true }}
                  />
                )}
                <ControlButton typeButton="warning" className="button" onClick={() => handleRemove(index)}>
                  <CloseMD />
                </ControlButton>
              </div>
            )))}
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(TemplatesForm))
