import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { createFieldConfig, setConstructorModal } from '../actions'
import { constructorKeyOptions, getDictionary } from '../utils'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import './ConstructorModal.scss'

const mainCssClass = getCssClassName('constructor-modal')

type TFormValues = {
  label: string
  dictionary_id: number
}

type TConstructorModal = ModalProps & {
  department_id: number
  model: string
  filter: string
}

function ConstructorModal({ open, className, department_id, model, filter }: TConstructorModal) {
  const dispatch = useDispatch()
  const [keyOption, setKeyOption] = useState(1)
  const [dateKey, setDateKey] = useState(3)

  const [dictionaryOptions, setDictionaryOptions] = useState<{ label: string; value: number }[]>([])

  const { control, handleSubmit, reset, getValues, watch } = useForm<TFormValues>()

  const onCancel = () => {
    dispatch(setConstructorModal(false))
  }

  const label = watch('label')
  const dictionary_id = watch('dictionary_id')

  const onOk = () => {
    dispatch(
      createFieldConfig({
        data: {
          label,
          type: keyOption === 3 && dateKey === 5 ? dateKey : keyOption,
          ...(keyOption === 2 && { dictionary_id }),
          model,
          department_id
        },
        filter
      })
    )
  }

  const handleClickKey = (key: number) => {
    setKeyOption(key)
  }

  const handleClickDate = (key: number) => {
    setDateKey(key)
  }

  useEffect(() => {
    getDictionary().then((data) => setDictionaryOptions(data))
  }, [])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onOk)}
      onCancel={onCancel}
      title="Новое поле"
      className={cn(className, mainCssClass)}
      width="420px"
      centered
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}__list`}>
        <div className={`${mainCssClass}__list_action`}>
          {constructorKeyOptions.map((el) => (
            <div
              onClick={() => handleClickKey(el.key)}
              className={cn(`${mainCssClass}__list_action_item`, {
                [`${mainCssClass}__list_action_item-active`]: keyOption === el.key
              })}
            >
              {el.label}
            </div>
          ))}
        </div>
        <InputFormControl control={control} name="label" required rules={{ required: true }} label="Название поля" />
        {keyOption === 2 && (
          <SearchSelectFormControlSource
            name="dictionary_id"
            control={control}
            label="Справочник"
            required
            rules={{ required: keyOption === 2 }}
            options={dictionaryOptions}
            showSearch
            optionFilterProp="children"
          />
        )}

        {keyOption === 3 && (
          <div className={cn(`${mainCssClass}__list_action`, `${mainCssClass}__list_action-date`)}>
            <div
              className={cn(`${mainCssClass}__list_action-date_item`, {
                [`${mainCssClass}__list_action_item-active`]: dateKey === 3
              })}
              onClick={() => handleClickDate(3)}
            >
              Одна дата
            </div>
            <div
              className={cn(`${mainCssClass}__list_action-date_item`, {
                [`${mainCssClass}__list_action_item-active`]: dateKey === 5
              })}
              onClick={() => handleClickDate(5)}
            >
              Диапазон
            </div>
          </div>
        )}
      </div>
    </CommonModal>
  )
}

export default memo<TConstructorModal>(withErrorBoundary<TConstructorModal>(ConstructorModal))
