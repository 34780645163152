import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import IntlCollator from 'intl'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { TClassName } from '../../../../common/types/props'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import Table from '../../../../common/components/table/Table'
import { TInterview } from '../../../../common/api/interviews'
import { Heading } from '../../../../common/components/Text'
import './RejectedCandidatesTable.scss'
import { selectDictionaryOptionsByName, selectVertexMethod } from '../../selectors'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('rejected-vertex')

type TRowData = {
    source: string
    quantity: string
    interview_manager: string
    to_agreed: string
    interview_customer:string
    reserve:string
    offer:string
    accepted:string

}
const mockInterviewsData = [
  {
    source: 'Интернет-портал',
    quantity: '10',
    interview_manager: 'Петр Петров',
    to_agreed: '5',
    interview_customer: 'Да',
    reserve: 'Нет',
    offer: 'Да',
    accepted: 'Да',
    id: 1
  },
  {
    source: 'Рекомендации',
    quantity: '5',
    interview_manager: 'Мария Иванова',
    to_agreed: '3',
    interview_customer: 'Нет',
    reserve: 'Да',
    offer: 'Нет',
    accepted: 'Нет',
    id: 2
  },
  {
    source: 'Локальные объявления',
    quantity: '15',
    interview_manager: 'Иван Петров',
    to_agreed: '7',
    interview_customer: 'Да',
    reserve: 'Нет',
    offer: 'Да',
    accepted: 'Нет',
    id: 3
  }
]

const useColumns = (): Column<any>[] => {
  const arr = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const conversion = useSelector(selectVertexMethod)

  return useMemo(() => {
    let columns: Column<any>[] = []

    if (arr.length > 0) {
      // Сначала объявляем массив columns
      columns = arr.filter((elem) => elem.label !== 'Не выбрано' && elem.label !== 'Добавлен').map((el) => ({
        accessor: el.label,
        Header: el.label,
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      }))
    }

    return columns
  }, [arr, conversion])
}

const useRowsData = (sourseOfCandidates) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
    },
    []
  )
  // const transformedData = sourseOfCandidates?.[0]?.analyticRow.map((item) => ({
  //   [item.label]: item.cost
  // }))

  // const transformedData2 = sourseOfCandidates?.[0]?.analyticRow.map((item) => ({
  //   [item.label]: item.percent
  // }))

  // const combinedObject = sourseOfCandidates?.[0]?.analyticRow.reduce((result, item) => {
  //   result[item.label] = item.cost
  //   return result
  // }, {})
  // const combinedObject2 = sourseOfCandidates?.[0]?.analyticRow.reduce((result, item) => {
  //   result[item.label] = item.percent
  //   return result
  // }, {})
  // const all = [combinedObject, combinedObject2]
  const rows: any[] = useMemo(
    () => {
      if (sourseOfCandidates?.[0]?.analyticRow.length) {
        const arr = sourseOfCandidates?.[0]?.analyticRow.reduce((acc, el) => {
          acc[el.label] = el.count
          return acc
        }, {})
        const arr1 = sourseOfCandidates?.[0]?.analyticRow.reduce((acc, el) => {
          acc[el.label] = `${el.percent}%`
          return acc
        }, {})

        return [arr, arr1]
      }

      return []
    },
    [sourseOfCandidates]
  )
  return { rows, handleRowClick }
}
const RejectedCandidatesTable: FC = ({ sourse }) => {
  const columns = useColumns()

  const { rows, handleRowClick } = useRowsData(sourse)
  return (
    <div>
      {/* <div className={`${mainCssClass}__table`} /> */}
      <Table
        className={cn(mainCssClass)}
        columns={columns}
        data={rows}
        sortabled
        withoutLastColumnElement
        handleRowClick={handleRowClick}
        withoutHeaderDividers
      />
    </div>
  )
}

export default memo(RejectedCandidatesTable)
