import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { log10 } from 'chart.js/helpers'
import { TClassName } from '../../../../common/types/props'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import Table from '../../../../common/components/table/Table'
import { TInterview } from '../../../../common/api/interviews'
import { Heading } from '../../../../common/components/Text'
import { TRejectionInfo, TSourseOfCandidates } from '../../types'
import { selectRejectionInfo } from '../../selectors'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('added-table')
type TProps= {
    sourse: TRejectionInfo[]
} & TClassName
type TRowData = {
    label: string
    count: number

}
const mockInterviewsData = [
  {
    rejection_reason: 'Не отвечает на звонки',
    quantity: '118',
    id: 1
  },
  {
    rejection_reason: 'Несоответствие зарплатных ожиданий',
    quantity: '458',
    id: 2
  },
  {
    rejection_reason: 'Неинтересные задачи / обязанности',
    quantity: '370',
    id: 3
  },
  {
    rejection_reason: 'Неинтересна компания / сфера',
    quantity: '2223',
    id: 4
  },
  {
    rejection_reason: 'Контроффер',
    quantity: '51',
    id: 5
  },
  {
    rejection_reason: 'Семейные обстоятельства',
    quantity: '1033',
    id: 6
  },
  {
    rejection_reason: 'График',
    quantity: '519',
    id: 7
  },
  {
    rejection_reason: 'Условия труда',
    quantity: '246',
    id: 8
  },
  {
    rejection_reason: 'Должностные обязанности',
    quantity: '10%',
    id: 9
  },
  {
    rejection_reason: 'Не актуально',
    quantity: '118',
    id: 10
  },
  {
    rejection_reason: 'Расстояние до работы',
    quantity: '15167',
    id: 11
  },
  {
    rejection_reason: 'Здоровье',
    quantity: '515',
    id: 12
  },
  {
    rejection_reason: 'Трудоустройство по ТК РФ',
    quantity: '4092',
    id: 13
  }
]

const useColumns = (): Column<any>[] => (
  useMemo(
    () => ([
      {
        accessor: 'label',
        Header: 'Причина отказа',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'count',
        Header: 'Количество',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      }

    ]),
    []
  )
)

const useRowsData = (sourse: TProps[]) => {
  const dispatch = useDispatch()

  const rows: TRowData[] = useMemo(
    () => (
      sourse?.length ? sourse.map<TRowData>((el) => ({
        label: el.label,
        count: el.count,

      })) : []
    ),
    [sourse]
  )

  const handleRowClick = useCallback(
    (row) => {
      const { id } = row
      // Добавьте свой код обработки клика по строке, если необходимо
    },
    []
  )

  return { rows, handleRowClick }
}
const RejectionCandidateTable: FC = ({ sourse }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(sourse)

  return (
    <div>
      {/* <div> */}
      {/*  <Heading.H3> */}
      {/*    Добавленные */}
      {/*  </Heading.H3> */}
      {/* </div> */}
      {/* <div className={`${mainCssClass}__table`} /> */}
      <Table
        className={cn(mainCssClass)}
        columns={columns}
        data={rows}
        sortabled
        withoutLastColumnElement
        handleRowClick={handleRowClick}
        withoutHeaderDividers
      />
    </div>

  )
}
export default memo<TProps>(withErrorBoundary<TProps>(RejectionCandidateTable))
