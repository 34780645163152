import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'history-processed'

function HistoryProcessed({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#DEF9FE" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.4318 15.5324C24.8241 15.9211 24.827 16.5543 24.4382 16.9466L21.2103 20.204C20.8216 20.5963 20.1884 20.5991 19.7961 20.2104C19.4038 19.8217 19.4009 19.1885 19.7897 18.7962L23.0176 15.5388C23.4064 15.1465 24.0395 15.1436 24.4318 15.5324ZM29.4346 15.5355C29.8251 15.9261 29.8251 16.5592 29.4346 16.9498L20.9498 25.4351C20.7622 25.6226 20.5079 25.728 20.2427 25.728C19.9774 25.728 19.7231 25.6226 19.5355 25.4351L15.2929 21.1925C14.9024 20.8019 14.9024 20.1688 15.2929 19.7782C15.6834 19.3877 16.3166 19.3877 16.7071 19.7782L20.2426 23.3138L28.0203 15.5356C28.4109 15.1451 29.044 15.145 29.4346 15.5355ZM10.2929 19.7782C10.6834 19.3877 11.3166 19.3877 11.7071 19.7782L15.9497 24.0209C16.3403 24.4114 16.3403 25.0446 15.9497 25.4351C15.5592 25.8256 14.9261 25.8256 14.5355 25.4351L10.2929 21.1925C9.90237 20.8019 9.90237 20.1688 10.2929 19.7782Z" fill="#2EC2DE" />
    </svg>

  )
}
export default HistoryProcessed
