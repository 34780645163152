/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, useParams } from 'react-router'
import React, {
  FC, useCallback, useEffect, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './InnerRouterMobil.scss'


import cn from 'classnames'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { getNotifications, selectDefDataNotify } from '../../../features/notificaitionCenter'
import MyAccount from '../../Systems/components/MyAccount'
import SystemsNav from '../../Systems/components/SystemsNav'
import {
  selectBaseUrl,
  selectNavigateToUrl,
  selectSubUrl,
  selectUserInfo,
  selectVisibleTable,
  selectInnerMenuMobil,
  selectRbacModel, selectOpenSettingsCalendar
} from '../selectors'
import InnerMenu from './InnerMenu'
import ErrorPage from '../../../features/errorPage/ErrorPage'
import { navigateTo } from '../../../common/actions/navigate'
import { TBaseUrl } from '../types'
import { TRootState } from '../../../common/store/rootReducer'
import {
  getUserInfo, OpenInnerMenuMobil, OpenSettingsCalendarMobil, setBaseUrl, setSubUrl
} from '../actions'
import CandidateList from '../../ManagamentCandidates/components/CandidateList'
import FormAuth from '../../auth/components/FormAuth'
import RequestPage from '../../Request/components/RequestPage'
import ManagementInterviews from '../../ManagementInterviews/components/ManagementInterviews'
import Interview from '../../InterviewCard/components/Interview'
import { EntityContextProvider } from '../../../common/context/EntityContextProvider'

import { selectFilters } from '../../../features/filters/selectors'

import CalendarViewMobil from '../../Calendar/components/CalendarViewMobil'

import InnerMenuMobil from './InnerMenuMobil'
import Hamburger_MD from '../../../common/svg/Hamburger_MD'
import Logo from '../../../common/svg/Logo'
import LogoMobil from '../../../common/svg/LogoMobil'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import CandidateListMobil from '../../ManagamentCandidates/components/CandidatesListMobil'
import ManagementRequestsMobil from '../../ManagementRequests/components/ManagementRequestsMobil'
import ManagementInterviewsMobil from '../../ManagementInterviews/components/ManagementInterviewsMobil'
import { OpenFilterMobil } from '../../../features/filters/actions'
import FiltersMobil from '../../../features/filters/components/FiltersMobil'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import Clock from '../../../common/svg/SvgFromIconWrapper/Clock'
import { openHistoryCandidateData } from '../../candidate/actions'
import SettingsCalendarMobil from '../../../features/SettingsCalendar/components/SettingsCalendarMobil'
import CalendarSettings from '../../../common/svg/CalendarSettings'
import { setHandleModalHistoryOpen } from '../../InterviewCard/actions'
import TripleDot from '../../../common/svg/TripleDot'
import CalendarView from '../../Calendar/components/CalendarView'
import WorkloadFilters from '../../ManagementInterviews/components/WorkloadFilters'
import { selectWorkloadFilter, selectWorkloadPage } from '../../ManagementInterviews/selectors'
import TasksPage from '../../Tasks/components/TasksPage'
import TasksPageMobil from '../../Tasks/components/TasksPageMobil'
import Candidate from '../../../compositions/candidateV2/components/Candidate'
import UserVoice from '../../../common/svg/UserVoice'
import CandidateMobil from '../../../compositions/candidateV2/components/CandidateMobil'
import { openCommunicationDrawer } from '../../../compositions/candidateV2/actions'

export type TUrlParams = {
    pageId?: 'vacancies' | 'candidates' | 'interviews' | 'needs' | 'analytics' | 'candidate' | 'interview' | 'vacancy'|'systems'
    entityId?: string
    subPageId?: string
}

const userId = localStorage.getItem('user_id')

const mainCssClass = 'inner-router-mobil'
// eslint-disable-next-line react/function-component-definition
const InnerRouterMobil: FC = () => {
  const userInfo = useSelector(selectUserInfo)

  const dispatch = useDispatch()
  const { pageId, subPageId, entityId } = useParams<TUrlParams>()
  const navigate = useNavigate()
  const navigateToUrl: string | null = useSelector(selectNavigateToUrl)
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const subUrl: string | null = useSelector(selectSubUrl)
  const visibleTable: boolean = useSelector(selectVisibleTable)
  const workloadPage = useSelector(selectWorkloadPage)
  const workloadFilter = useSelector(selectWorkloadFilter)
  const targetUrl: TBaseUrl = baseUrl || pageId || 'candidates'
  const targetSubUrl: string | null = subUrl || subPageId || null
  const filters = useSelector(selectFilters)
  const openSettings = useSelector(selectOpenSettingsCalendar)
  const token = localStorage.getItem('token')

  const openMobilMenu = useSelector(selectInnerMenuMobil)
  const rbacModel = useSelector(selectRbacModel)
  const defDataNotify = useSelector(selectDefDataNotify)


  const handleOpenInnerMenu = () => {
    dispatch(OpenInnerMenuMobil(true))
  }
  const handleOpenFilterMobil = () => {
    dispatch(OpenFilterMobil(true))
  }
  const handleOpenSettingCalendar = () => {
    dispatch(OpenSettingsCalendarMobil(true))
  }
  const handleOpenHistoryInterview = useCallback(() => {
    dispatch(setHandleModalHistoryOpen(true))
  }, [])

  const handleOpenCommunication = () => {
    dispatch(openCommunicationDrawer(true))
  }
  useEffect(() => {
    if (userId) {
      const timerId = setTimeout(() => {
        dispatch(getNotifications(defDataNotify))
      }, 1500)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [userId, defDataNotify])

  const isNotCorrectTargetUrl = useMemo(() => {
    if (!entityId) {
      return (
        targetUrl === 'candidates'
                || targetUrl === 'vacancies'
                || targetUrl === 'interviews'
                || 'analytics'
        || targetUrl === 'tasks'
                || 'application'
                || 'interview'
      )
    }
    return false
  }, [targetUrl])

  useEffect(() => {
    if (subPageId) {
      dispatch(setSubUrl(subPageId))
    } else {
      dispatch(setSubUrl(null))
    }
  }, [subPageId])

  useEffect(() => {
    if (navigateToUrl) {
      navigate(navigateToUrl)
      dispatch(setBaseUrl(null))
      dispatch(navigateTo(null))
    }
  }, [navigateToUrl])
  useEffect(() => {
    if (token) {
      dispatch(getUserInfo(true))
    }
  }, [token])

  if (!localStorage.getItem('token')) {
    return <FormAuth />
  }
  if (!rbacModel) {
    return <Preloader />
  }

  if (targetUrl === 'candidate' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className={cn('mainScroll', 'mainScrollMobile')}>
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
            <div className={`${mainCssClass}__filter-navbar`} onClick={handleOpenCommunication}>
              <UserVoice color="#2DBE64" />
              Коммуникация
            </div>
          </div>
          <EntityContextProvider
            email={userInfo?.email}
            phone={userInfo?.phone}
            surname={userInfo?.surname}
            name={userInfo?.name}
            patronymic={userInfo?.patronymic}
            avatar={userInfo?.avatar}
            role={userInfo?.role}
            rbacControl={rbacModel}
          >
            <CandidateMobil />
            <InnerMenuMobil />
          </EntityContextProvider>
        </div>
      </NewScrollContainer>
    )
  }

  if (targetUrl === 'candidates' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
            <div className={`${mainCssClass}__filter-navbar`}>
              <FiltersIcon onClick={handleOpenFilterMobil} />
            </div>
          </div>
          <div className={`${mainCssClass}__candidates-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              <CandidateListMobil />
            </EntityContextProvider>
          </div>
          <InnerMenuMobil />
          <FiltersMobil candidate />
        </div>
      </NewScrollContainer>
    )
  }
  if (targetUrl === 'applications' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
            <div className={`${mainCssClass}__filter-navbar`}>
              <FiltersIcon onClick={handleOpenFilterMobil} />
            </div>
          </div>
          <div className={`${mainCssClass}__candidates-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              <ManagementRequestsMobil />
            </EntityContextProvider>
          </div>
          <InnerMenuMobil />
          <FiltersMobil request />
        </div>
      </NewScrollContainer>

    )
  }
  if (targetUrl === 'application' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
          </div>

          <InnerMenuMobil />
          <div className={`${mainCssClass}__candidates-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              <RequestPage />
            </EntityContextProvider>
          </div>
        </div>
      </NewScrollContainer>
    )
  }
  if (targetUrl === 'tasks' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
          </div>

          <InnerMenuMobil />
          <div className={`${mainCssClass}__candidates-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              <TasksPageMobil />
            </EntityContextProvider>
          </div>
        </div>
      </NewScrollContainer>
    )
  }
  if (targetUrl === 'interview' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
            {/* <div className={`${mainCssClass}__filter-navbar`}> */}
            {/*   <TripleDot onClick={handleOpenHistoryInterview} fill="#2dbe64" /> */}
            {/* </div> */}
          </div>
          <InnerMenuMobil />
          <div className={`${mainCssClass}__candidates-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              <Interview />
            </EntityContextProvider>
          </div>
        </div>
      </NewScrollContainer>
    )
  }
  if (targetUrl === 'interviews' && localStorage.getItem('token')) {
    return (
      <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
        <div className={`${mainCssClass}__candidate-with-navbar`}>
          <div className={`${mainCssClass}__navbar`}>
            <div className={`${mainCssClass}__logo-navbar`}>
              <Hamburger_MD onClick={handleOpenInnerMenu} className="hamburger-icon" />
              <LogoMobil />
            </div>
            <div className={`${mainCssClass}__filter-navbar`}>
              <CalendarSettings onClick={handleOpenSettingCalendar} />
            </div>
          </div>
          <div className={`${mainCssClass}__calendar-table`}>
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              rbacControl={rbacModel}
            >
              {targetUrl === 'interviews' && userInfo?.department !== 175 && rbacModel?.interview.page_access === 1 ? ((userInfo?.role?.role_id === 'recruiter' || userInfo?.isColdSearch || userInfo?.is_can_conduct_interview) && !visibleTable && rbacModel.interview.calendar_access === 1) ? <CalendarViewMobil /> : <ManagementInterviewsMobil /> : null}
            </EntityContextProvider>
          </div>
          <InnerMenuMobil />
          {openSettings && userInfo?.role?.role_id === 'recruiter' && !visibleTable && (
            <EntityContextProvider
              email={userInfo?.email}
              phone={userInfo?.phone}
              surname={userInfo?.surname}
              name={userInfo?.name}
              patronymic={userInfo?.patronymic}
              avatar={userInfo?.avatar}
              role={userInfo?.role}
              calendar_settings={userInfo?.calendar_settings}
              rbacControl={rbacModel}

            >
              <SettingsCalendarMobil />
            </EntityContextProvider>
          )}
          <InnerMenuMobil />
          {targetUrl === 'interviews' && visibleTable && workloadPage && workloadFilter && <WorkloadFilters />}
          <FiltersMobil interview />
          {filters && (userInfo?.role?.role_id !== 'recruiter' || visibleTable) && <FiltersMobil interview />}

        </div>
      </NewScrollContainer>
    )
  }

  if (targetUrl === 'init-error' && localStorage.getItem('token')) {
    return <ErrorPage errorCode="init-error" />
  }
  if (targetUrl === 'role-error' && localStorage.getItem('token')) {
    return <ErrorPage errorCode="role-error" />
  }
  if (isNotCorrectTargetUrl && localStorage.getItem('token')) {
    return <ErrorPage errorCode={404} />
  }
  return <ErrorPage errorCode={404} />
}

export default InnerRouterMobil
