import { SketchPicker } from 'react-color'
import React, { useState } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import debounce from 'lodash/debounce'

type PickerFormControlProps = UseControllerProps & {
    onChange:() => void
  delay?: number
}
function PickerFormControl({
  name,
  control,
  onChange,
  defaultValue,
  delay = 3000,
  rules, ...rest
}: PickerFormControlProps) {
  const { field, fieldState } = useController({ name, control, rules })
  const [debouncedOnChange] = useState(() => debounce(onChange, delay))
  const handleChange = (colorEvent) => {
    if (colorEvent) {
      const color = colorEvent.hex
      field.onChange(color)
      debouncedOnChange(color)
    }
  }

  return (
    <SketchPicker
      color={field.value ? field.value : defaultValue}
      value={field.value}
      onChange={handleChange}
      presetColors={['#f00', '#0f0', '#00f', '#ff0', '#0ff', '#ffa'
      ]}
      width={230}
      height={80}
      defaultValue={defaultValue}
      {...rest}
    />
  )
}

export default PickerFormControl
