import React, {
  memo, useEffect, useMemo
} from 'react'
import {
  Button, ModalProps, Drawer, DrawerProps,
  Tabs,
  Typography
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import Show from '../../../common/svg/Show'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Notify from './Notify'
import {
  selectDefDataNotify, selectLoading, selectNotifications, selectNotifyHeaders, selectUnreadNotifications
} from '../selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import {
  deleteNotify, openNotifications, readNotifications, readNotify,
  setDefDataNotify,
  setLoading
} from '../actions'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { optionsPagination } from '../../../common/components/options'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'

import TabPane = Tabs.TabPane
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getDate } from '../utils'
import './NotifyDrawer.scss'

const mainCssClass = getCssClassName('notification-center-drawer')

type TFormValues = {
  perPagesCount: number
  date: string | null
}

function NotifyDrawer({
  open, className
}:DrawerProps) {
  const dispatch = useDispatch()
  const notifyArr = useSelector(selectNotifications)
  const defDataNotify = useSelector(selectDefDataNotify)
  const headers = useSelector(selectNotifyHeaders)
  const loading = useSelector(selectLoading)
  // const [notifyStatus, setNotifyStatus] = useState<string>('&unread=1')
  // const [page, setPage] = useState<number>(defDataNotify?.page || 0)

  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataNotify?.per_page || 10
    }
  })

  const date = watch('date')

  // const handleResetAll = () => {
  //   dispatch(deleteNotifications())
  // }

  const handleChangeStatusUser = (e) => {
    dispatch(setLoading(true))
    dispatch(setDefDataNotify({ ...defDataNotify, page: 0, status: e }))
  }
  const handleOnClose = () => {
    dispatch(openNotifications(false))
  }

  const handleDeleteNotify = (guid:string) => {
    dispatch(deleteNotify(guid))
  }

  const handleReadNotify = (guid:string, unread: boolean) => {
    if (unread) {
      dispatch(readNotify(guid))
    }
  }

  const handleReadAll = () => {
    dispatch(readNotifications())
  }

  const handlePerPage = (selectedParametr:number) => {
    dispatch(setDefDataNotify({ ...defDataNotify, per_page: selectedParametr, page: 0 }))
  }

  const handleNextPage = () => {
    if (headers && !loading) {
      if (headers.total - defDataNotify.page > 1) {
        dispatch(setDefDataNotify({ ...defDataNotify, page: defDataNotify.page + 1 }))
      }
    }
  }

  const handlePreviousPage = () => {
    if (headers && !loading) {
      if (defDataNotify.page > 0) {
        dispatch(setDefDataNotify({ ...defDataNotify, page: defDataNotify.page - 1 }))
      }
    }
  }

  const stringCountsPagination = useMemo(
    () => {
      if (typeof (Number(headers?.current)) === 'number' && typeof (Number(headers?.total)) === 'number'
    && headers?.current && headers.total
      ) {
        return `${headers?.current - 1} из ${headers?.total - 1}`
      }
    },
    [headers]
  )

  useEffect(
    () => {
      dispatch(setDefDataNotify({
        ...defDataNotify,
        filter: getDate(date)
      }))
    },

    [date]
  )

  return (
    <Drawer
      title="Уведомления"
      onClose={handleOnClose}
      open={open}
      className={cn(className, mainCssClass)}
      contentWrapperStyle={{ width: '400px' }}
    >
      <div className={`${mainCssClass}_content`}>
        <div className={`${mainCssClass}_content_action`}>

          <Tabs onChange={handleChangeStatusUser} defaultActiveKey={defDataNotify.status} activeKey={defDataNotify.status}>
            <TabPane
              key="&unread=1"
              tab="Новые"
            />
            <TabPane
              key="&unread=0"
              tab="Прочитанные"
            />
          </Tabs>

          {defDataNotify.status === '&unread=1'
             && (
               <Button
                 className={`${mainCssClass}_content_action_read`}
                 onClick={handleReadAll}
               >
                 <Show color="#2DBE64" />
                 Прочитать все
               </Button>
             )}

        </div>

        {headers && (
          <div className={`${mainCssClass}__container-pagination`}>
            <SelectFormControl
              className={`${mainCssClass}__pagination-count`}
              name="perPagesCount"
              options={optionsPagination}
              onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
              control={control}
            />

            <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
              <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={headers?.current === 1 ? '#BFC5C0' : '#404542'} />
              <Typography.Text className={`${mainCssClass}__pagination-font`}>
                {stringCountsPagination || 'неизвестно'}
              </Typography.Text>
              <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={headers?.current === headers?.total ? '#BFC5C0' : '#404542'} />
            </div>
          </div>
        )}
        {defDataNotify.status === '&unread=0'
        && (
          <div className={`${mainCssClass}_content_date`}>
            <RangePickerFormControl control={control} name="date" />
          </div>
        )}
        {loading && <Preloader size="md" />}
        {!loading
        && (
          <div className={`${mainCssClass}_content_notify-list`}>
            <NewScrollContainer style={{ height: '100%' }}>

              {notifyArr?.length > 0 ? notifyArr?.map((el) => (
                <Notify
                  text={el?.message || ''}
                  title={
                    // el?.message_type ||
                    ''
                  }
                  unread={el?.unread}
                  key={el?.guid || ''}
                  time={el?.created_at}
                  onClick={() => handleDeleteNotify?.(el?.guid)}
                  onClickRead={() => handleReadNotify(el?.guid, el.unread)}
                />
              )) : null}
            </NewScrollContainer>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(NotifyDrawer))
