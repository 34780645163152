/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Typography } from 'antd'

import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Archive from '../../../common/svg/Archive'
import RequestDone from '../../../common/svg/RequestDone'
import RequestProgress from '../../../common/svg/RequestProgress'
import EditSvg from '../../../common/svg/EditSvg'
import СommentAddEvent from '../../../common/svg/СommentAddEvent'
import RequestAddEvent from '../../../common/svg/RequestAddEvent'
import RequestAdd from '../../../common/svg/RequestAdd'
import RequestReject from '../../../common/svg/RequestReject'
import './HistoryActionNameTable.scss'

type TProps = {
    action: string
}
const mainCssClass = 'actions-name-table'
const HistoryActionNameTable: FC<TProps> = ({ action }) => {
  if (action === 'requestArchive') {
    return (
      <div className={mainCssClass}>
        <Archive />
        <Typography.Text>
          Заявка архивирована
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestEdit') {
    return (
      <div className={mainCssClass}>
        <EditSvg />
        <Typography.Text>
          Заявка изменена
        </Typography.Text>

      </div>
    )
  }
  if (action === 'commentAdd') {
    return (
      <div className={mainCssClass}>
        <СommentAddEvent />
        <Typography.Text>
          Оставлен комментарий
        </Typography.Text>

      </div>
    )
  }
  if (action === 'add') {
    return (
      <div className={mainCssClass}>
        <RequestAddEvent />
        <Typography.Text>
          Назначение на заявку
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestAdd') {
    return (
      <div className={mainCssClass}>
        <RequestAdd />
        <Typography.Text>
          Заявка создана
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestReject') {
    return (
      <div className={mainCssClass}>
        <RequestReject />
        <Typography.Text>
          Заявка отклонена
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestInProgress') {
    return (
      <div className={mainCssClass}>
        <RequestProgress />
        <Typography.Text>
          Заявка взята в работу
        </Typography.Text>

      </div>
    )
  }
  if (action === 'requestDone') {
    return (
      <div className={mainCssClass}>
        <RequestDone />
        <Typography.Text>
          Заявка успешно закрыта
        </Typography.Text>

      </div>
    )
  }
  return <Typography.Text> Действие не определено</Typography.Text>
}
export default memo<TProps>(withErrorBoundary<TProps>(HistoryActionNameTable))
