export const getColorForDiagram = (status_id: number) => {
  if (status_id === 1) {
    return '#DF1E12'
  }
  if (status_id === 2) {
    return '#2DBE64'
  }
  if (status_id === 3) {
    return '#5A615C'
  }
  if (status_id === 4) {
    return '#FFD608'
  }
  if (status_id === 5) {
    return '#F5695A'
  }
  return '#BFC5C0'
}

export const getColorForBars = (status_id: number) => {
  if (status_id === -1) {
    return '#DF1E12'
  }
  if (status_id === 1) {
    return '#2DBE64'
  }
  if (status_id === 0) {
    return '#FFD608'
  }
  return '#BFC5C0'
}
