import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Typography } from 'antd'
import EmptyList from '../../../../features/emptyList/components/EmptyList'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import { selectLoadingModal, selectUserInfo } from '../../../InnerRouter/selectors'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import ControlButton from '../../../../common/components/Buttons/Button'
import {
  getComments, openCommentModal, openDeleteCommentModal, setCommentsPage, setSelectedComment, updateComments
} from '../../actions'
import {
  selectCandidate, selectCandidateComments, selectCommentModal, selectCommentsPage,
  selectDeleteCommentModal, selectInformationModal, selectTotalCommentPage
} from '../../selectors'
import Pin from '../../../../common/svg/Pin'
import EditPencil1 from '../../../../common/svg/EditPencil1'
import CloseMD from '../../../../common/svg/CloseMD'
import './CandidateComment.scss'
import Download from '../../../../common/svg/Download'
import ModalDeleteComment from '../ModalDeleteComment'
import ModalNewComment from '../ModalNewComment'

const MainCSSClass = getCssClassName('candidate-comment')

function CandidateComment() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const comments = useSelector(selectCandidateComments)
  const { entityId } = useParams()
  const user_id = localStorage.getItem('user_id')
  const page = useSelector(selectCommentsPage)
  const totalPage = useSelector(selectTotalCommentPage)
  const commentModal = useSelector(selectCommentModal)
  const deleteCommentModal = useSelector(selectDeleteCommentModal)
  const loadingModal = useSelector(selectLoadingModal)
  const userInfo = useSelector(selectUserInfo)
  const {
    rbacControl, has_active_interview, isCanAppointRecruiter, department, role
  } = useEntityContext()

  const handleClick = (id:number, pin:boolean) => {
    dispatch(updateComments({ id, is_pinned: !pin }))
  }

  const handleClickDeleteComment = (el: any) => {
    dispatch(setSelectedComment(el))
    dispatch(openDeleteCommentModal(true))
  }

  const handleClickUpdateComment = (el:any) => {
    dispatch(setSelectedComment(el))
    dispatch(openCommentModal(true))
  }

  const handleDownload = (src: string) => {
    window.open(src, '_blank')
  }

  const handleDeleteImg = (el) => {
    if (el.comment) {
      const formData = new FormData()
      formData.append('id', String(el.id))
      formData.append('file', '')
      dispatch(updateComments(formData))
    } else {
      dispatch(setSelectedComment(el))
      dispatch(openDeleteCommentModal(true))
    }
  }

  const handleUploadComments = () => {
    dispatch(setCommentsPage(page + 1))
  }

  const handleClickCreate = () => {
    dispatch(openCommentModal(true))
  }

  useEffect(() => {
    dispatch(getComments({ id: entityId, page }))
  }, [page])

  return (
    <div className={MainCSSClass}>
      <div className={`${MainCSSClass}_title`}>
        <Typography.Title level={3}>
          Комментарии
        </Typography.Title>

        <div className={`${MainCSSClass}_title_create`} onClick={handleClickCreate}>
          <EditPencil1 color="#D7144B" />
          <Typography.Text>Написать</Typography.Text>
        </div>
      </div>
      {!!comments && comments?.length > 0
          && (
            <div className={`${MainCSSClass}_comment-list`}>
              {comments?.map((el) => (
                <div
                  className={classNames(`${MainCSSClass}_comment-list_item`, {
                    'is_pinned': el.is_pinned === true
                  })}
                  key={el.id}
                >
                  <div className={`${MainCSSClass}_comment-list_item_header`}>
                    <Typography.Text className={`${MainCSSClass}_comment-list_item_header_user`}>{el.labels.user}</Typography.Text>
                    <div className={`${MainCSSClass}_comment-list_item_header_pin`}>
                      <Typography.Text>{el.created_at}</Typography.Text>
                      <div className={`${MainCSSClass}_comment-list_item_header_pin_action`}>
                        {rbacControl?.candidate?.edit_view_access === 1 && (role?.role_id === 'superadmin' || Number(el.user_id) === Number(user_id)) && (<EditPencil1 onClick={() => handleClickUpdateComment(el)} />)}
                        {rbacControl?.candidate?.edit_view_access === 1 && (<Pin onClick={() => handleClick(el.id, el.is_pinned)} />)}
                        {rbacControl?.candidate?.edit_view_access === 1 && (role?.role_id === 'superadmin' || role?.role_id === 'admin' || Number(el.user_id) === Number(user_id) || userInfo?.is_delete_any_comment) && (<CloseMD onClick={() => handleClickDeleteComment(el)} />)}
                      </div>
                    </div>

                  </div>
                  <div>
                    <Typography.Text className={`${MainCSSClass}_comment-list_item_comment`}>{el.comment}</Typography.Text>
                  </div>
                  {!!el?.attachments?.length && (
                    <div className={`${MainCSSClass}_comment-list_item_photo`}>
                      <div className={`${MainCSSClass}_comment-list_item_photo_action`}>
                        <Download onCLick={() => handleDownload(el?.attachments[0].filepath)} />
                        {rbacControl?.candidate?.edit_view_access === 1 && (role?.role_id === 'superadmin'
                          || role?.role_id === 'admin' || Number(el.user_id) === Number(user_id)
                          || userInfo?.is_delete_any_comment) && (<CloseMD onClick={() => handleDeleteImg(el)} />)}
                      </div>

                      <img alt="photo" src={el.attachments[0].filepath} style={{ height: 'auto', width: '100%' }} />
                    </div>
                  )}
                </div>
              ))}
              {totalPage && (page < totalPage) && (
                <div className={`${MainCSSClass}_comment-list_button`}>
                  <ControlButton className="button" typeButton="tertiary" onClick={handleUploadComments}>Загрузить ещё 10</ControlButton>
                </div>
              ) }
            </div>
          )}

      {!comments?.length
       && <EmptyList type="comments" />}
      {deleteCommentModal && <ModalDeleteComment open={deleteCommentModal} />}
      {commentModal && <ModalNewComment open={commentModal} candidateId={candidate?.id} user_id={Number(user_id)} />}
    </div>
  )
}

export default CandidateComment
