import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'
import { TResume } from '../Resume'

const selectState = ({ interviewCard }: TRootState) => interviewCard

export const selectCandidate = createSelector(selectState, ({ candidate }) => candidate)

export const selectInterview = createSelector(selectState, ({ interview }) => interview)

export const selectInterviewHistory = createSelector(selectState, ({ historyInterviews }) => historyInterviews[0])
export const selectVacanciesTags = createSelector(selectCandidate, (candidate) => (candidate?.vacancy_list ? candidate.vacancy_list : []))

export const selectHistoryData = createSelector(selectState, ({ historyInterviews }) => historyInterviews || [])

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

// eslint-disable-next-line max-len
export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})

export const selectModalCancelOpen = createSelector(selectState, ({ cancelInterview }) => cancelInterview)

export const selectModalEditOpen = createSelector(selectState, ({ editInterview }) => editInterview)

export const selectInterviewId = createSelector(selectState, ({ selectedInterviewId }) => selectedInterviewId)

export const selectModalEndInterviewOpen = createSelector(selectState, ({ endInterview }) => endInterview)

export const selectModalHistoryInterviewOpen = createSelector(
  selectState,
  ({ historyInterviewModalOpen }) => historyInterviewModalOpen
)
export const selectModalHistoryQuestionnaireInterviewOpen = createSelector(
  selectState,
  ({ historyQuestionnaireInterviewModalOpen }) => historyQuestionnaireInterviewModalOpen
)

export const selectResumeList = createSelector(selectState, ({ candidate }) => candidate?.resume_list)

export const selectResume = (resumeId: string | number) => createSelector(
  selectResumeList,
  (resumeList: TResume[] | undefined) => resumeList?.find((resume) => resume.id === resumeId) || null
)

export const selectActiveResume = createSelector(selectState, ({ resume }) => resume)

export const selectQuestionnaire = createSelector(selectState, ({ questionnaire }) => questionnaire)
export const selectIsEditQuestionnaire = createSelector(selectState, ({ isEditQuestionnaire }) => isEditQuestionnaire)

export const selectTypeById = (
  stageId: number | undefined,
  dictionaryName: TDictionariesName
) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.type : null
})


export const selectCandidateQuestionnaire = createSelector(selectState, ({ candidateQuestionnaire }) => candidateQuestionnaire)