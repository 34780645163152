import { CSSProperties, ReactElement, ReactNode } from 'react'

import { Informer } from '../../Informer'
import { InformerProps } from '../../Informer/types'

import './styles.scss'
import { isDefined } from '../../../utils/utilsContext'
import { BasicProps } from '../../../types/basic'

export type InternalTextProps = {
  /**
   * Контент
   */
  children: ReactNode;
  /**
   * html тег
   * @ignore
   */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  /**
   * Текст информера
   * @type ReactElement | string
   */
  info?: ReactElement | string;
  /**
   * Расположение информера
   */
  infoPlacement?: InformerProps['placement'];
  /**
   * Размер информера
   */
  infoSize?: InformerProps['size'];
  /**
   * Дополнительный класс для компонента
   */
  className?: string;
  /**
   *
   */
  style?: CSSProperties;
} & BasicProps;

export type InternalTextType = typeof InternalText;

export function InternalText({
  infoSize = 'sm',
  infoPlacement = 'topLeft',
  className,
  children,
  info,
  tag,
  'data-testid': testId = 'internalText',
  style
}: InternalTextProps) {
  const TextTag = `${tag}` as keyof JSX.IntrinsicElements
  return (
    <TextTag className={className} style={style} data-testid={testId}>
      {children}
      {isDefined(info) && (
        <Informer
          info={info}
          size={infoSize}
          data-testid={`${testId}-informer`}
          placement={infoPlacement}
          wrapClassName="Informer"
          iconClassName="Informer__icon"
        />
      )}
    </TextTag>
  )
}
