/* eslint-disable max-len */
import React, {
  memo,
  useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectActiveReminder, selectDictionaryOptionsByName } from '../selectors'
import {
  createReminder, openCallbackSettingForm, openReminderForm, setActiveCallback, setActiveReminder, updateReminder
} from '../actions'
import {
  remindTimeOptions1,
  remindTimeOptions2,
  remindToOptions,
  timeOptions
} from '../utils'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import './ReminderForm.scss'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'

const mainCssClass = getCssClassName('reminder-form')

    type TFormValues = {
      name: string,
      event_id: number
    } & any

function ReminderForm() {
  const dispatch = useDispatch()
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))

  const eventOptions = useMemo(
    () => [...stageOptions, { label: 'Отклоненный статус', value: 666 }].map((el) => ({ ...el, key: el.type || el.key })),
    [stageOptions]
  )

  const [pocket, setPocket] = useState(undefined)

  const activeReminder = useSelector(selectActiveReminder)

  const defaultValues = useMemo(
    () => (activeReminder ? {
      event_id: activeReminder.event_id,
      remind_to: activeReminder.remind_to,
      text: activeReminder.text,
      name: activeReminder.name,
      time: activeReminder.time,
      remind_time: activeReminder.remind_time
    }
      : {}),
    [activeReminder]
  )

  const {
    control, handleSubmit, reset, getValues, watch, resetField, setValue
  } = useForm<TFormValues>({ defaultValues })

  const handleCancel = () => {
    dispatch(setActiveReminder(null))
    dispatch(openReminderForm(false))
  }

  const remind_time = watch('remind_time')

  const handleChange = (value, option) => {
    setPocket(option)
    if (option !== 7 && remind_time === 'before') {
      setValue('remind_time', null)
    }
  }

  const onSubmit = () => {
    const value = getValues()

    if (!activeReminder) {
      dispatch(createReminder({ ...value, is_archive: 0 }))
    } else {
      dispatch(updateReminder(({
        ...value, id: activeReminder.id, is_archive: 0
      })))
    }
  }

  useEffect(()=> {
    if (activeReminder && eventOptions?.length) {
      const initialValue = eventOptions.find((option) => option.value === activeReminder.event_id)
      if (initialValue) {
        setPocket(initialValue.key)
      }
    }
  }, [eventOptions])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (

    <div className={mainCssClass}>
      <Typography.Title level={3}>
        {activeReminder ? 'Редактировать напоминание' : 'Новое напоминание'}
      </Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>

          <div className={`${mainCssClass}_list`}>

            <InputFormControl
              name="name"
              control={control}
              label="Название"
              required
              rules={{ required: true }}
            />

            <div className={`${mainCssClass}_list_item`}>
              <SelectFormControl
                name="event_id"
                control={control}
                label="Событие"
                rules={{ required: true }}
                required
                options={eventOptions}
                onChange={(value, option) => {
                  const selectedOption = eventOptions.find((opt) => opt.value === value)
                  if (selectedOption) {
                    handleChange(value, selectedOption.key)
                  }
                }}
              />
              <SelectFormControlMult
                name="remind_to"
                control={control}
                mode="multiple"
                label="Кому напоминать"
                options={remindToOptions}
                rules={{ required: true }}
                required
              />
            </div>
            <div className={`${mainCssClass}_list_item`}>
              <SelectFormControl
                rules={{ required: true }}
                required
                name="time"
                control={control}
                label="Время"
                options={timeOptions}
              />
              <SelectFormControl
                rules={{ required: true }}
                required
                name="remind_time"
                control={control}
                label="Когда напоминать"
                options={pocket === 7 ? remindTimeOptions1 : remindTimeOptions2}
              />

            </div>
            <TextAreaFormControl
              name="text"
              rules={{ required: true }}
              required
              control={control}
              label="Текст напоминания"
              autoSize={{ minRows: 4, maxRows: 5 }}
            />

          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}
export default memo(withErrorBoundary(ReminderForm))
