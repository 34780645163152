import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  onClick?: ()=>void
  color?: string
}

const mainCssClass = 'editPencil1'

function EditPencil1({ className, size = '1', onClick, color }: TSvgProps) {
  return (
    <svg
      onClick={onClick}
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{cursor: 'pointer'}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.382 3.51207C15.7837 3.38156 16.2164 3.38156 16.6181 3.51207C16.9079 3.60622 17.1298 3.76583 17.3046 3.91427C17.4636 4.0493 17.6372 4.22295 17.8155 4.40125L19.5983 6.18403C19.7767 6.36248 19.9506 6.53627 20.0858 6.6955C20.2342 6.87034 20.3938 7.09225 20.4879 7.38198C20.6184 7.78367 20.6184 8.21628 20.4879 8.61797C20.3938 8.90769 20.2342 9.12959 20.0858 9.30444C19.9506 9.46368 19.7767 9.63749 19.5982 9.81596L16.7112 12.703C16.7098 12.7044 16.7085 12.7057 16.7071 12.7071C16.7058 12.7085 16.7044 12.7098 16.703 12.7112L8.70711 20.7071C8.51957 20.8946 8.26522 21 8.00001 21L4.00001 21C3.73479 21 3.48043 20.8946 3.2929 20.7071C3.10536 20.5196 3 20.2652 3 20V16C3 15.7348 3.10536 15.4804 3.29289 15.2929L14.1615 4.42423L14.184 4.40178C14.3625 4.22325 14.5363 4.04939 14.6955 3.91419C14.8704 3.76576 15.0923 3.6062 15.382 3.51207ZM12 9.4142L5 16.4142V19L7.58578 19L14.5858 12L12 9.4142ZM16 10.5858L13.4142 7.99998L15.5757 5.83845C15.7854 5.62878 15.9007 5.5146 15.9899 5.4389C15.9934 5.43588 15.9968 5.43304 16 5.43037C16.0032 5.43302 16.0066 5.43584 16.0101 5.43883C16.0993 5.51456 16.2145 5.62873 16.4242 5.83845L18.1615 7.57571C18.3712 7.78538 18.4854 7.90068 18.5611 7.98986C18.5641 7.99341 18.5669 7.99678 18.5696 7.99998C18.5669 8.00318 18.5641 8.00655 18.5611 8.01009C18.4854 8.09927 18.3712 8.21456 18.1615 8.42423L16 10.5858Z"
        fill={color || '#404542'}
      />
    </svg>
  )
}
export default EditPencil1
