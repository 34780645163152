import { createAction } from '@reduxjs/toolkit'
import { TPaginationRequest } from '../../common/types/pagination'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'

export type TProductivityHeaders = {
    current:number,
    total:number,
    totalCount: number
  }

export const getProductivityRecruiter = createAction<TPaginationRequest>('productivity/getProductivityRecruiter')
export const setProductivityRecruiter = createAction<any | null>('productivity/setProductivityRecruiter')
export const setActiveTab = createAction<'recruiter' | 'researcher'>('productivity/setActiveTab')
export const getProductivityResearcher = createAction<TPaginationRequest>('productivity/getProductivityResearcher')
export const setProductivityResearcher = createAction<any | null>('productivity/setProductivityResearcher')
export const setLoading = createAction<boolean>('productivity/setLoading')
export const setHeaders = createAction<TProductivityHeaders>('productivity/setHeaders')
export const reset = createAction('productivity/reset')
export const openProductivityRecruiterFilter = createAction<boolean>('productivity/openProductivityRecruiterFilter')
export const openProductivityResearcherFilter = createAction<boolean>('productivity/openProductivityResearcherFilter')
export const getDictionaries = createAction<TDictionariesNames>('productivity/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('productivity/dictionaries/setDictionaries')

export const setRecruiterFilterString = createAction<string>('productivity/setRecruiterFilterString')
export const setResearcherFilterString = createAction<string>('productivity/setResearcherFilterString')
export const setSearchText = createAction<string>('productivity/setSearchText')