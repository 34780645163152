import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import Star from '../../../common/svg/Star'

function WhatsAppTemplatesLabel({ pinLabel }: {pinLabel:string}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div style={{ width: '20px', gap: '4px', display: 'flex', alignItems: 'center' }}>
            <Star color="#FFD608" />
        </div>

      {pinLabel}

    </div>
  )
}

export default WhatsAppTemplatesLabel
