import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { getRespondsCandidate, respondHandler } from '../../../actions'
import ControlButton from '../../../../../common/components/Buttons/Button'
import {
  selectCandidate,
  selectIsLoadingRespondHandler,
  selectResponds
} from '../../../selectors'
import { Preloader } from '../../../../../common/components/Preloader/Preloader'
import './RespondsCandidate.scss'

const mainCssClass = 'responds-candidate'

const arr = [
  {
    type: 'string',
    label: 'string',
    isShowProcessButton: true,
    eventlead_list: [
      {
        vacancy_name: 'string',
        aggregator: 'string',
        self: true
      }
    ]
  },
  {
    type: 'string1',
    label: 'string2',
    isShowProcessButton: false,
    eventlead_list: [
      {
        vacancy_name: 'string',
        aggregator: 'string',
        self: true
      }
    ]
  }
]

function RespondsCandidate() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const responds = useSelector(selectResponds)
  const loading = useSelector(selectIsLoadingRespondHandler)

  const handleClickRespond = (type) => {
    if (candidate) {
      dispatch(respondHandler({ candidate_id: candidate.id, type }))
    }
  }

  useEffect(() => {
    if (candidate) {
      dispatch(getRespondsCandidate(candidate?.id))
    }
  }, [])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_list`}>
        {loading && <Preloader size="md" />}
        {!loading && !responds.length && <Typography.Text className={`${mainCssClass}_list_empty`}>Откликов нет</Typography.Text>}
        {!loading && !!responds.length && responds.map((el, i) => (
          <div className={`${mainCssClass}_list_item`}>
            <div className={`${mainCssClass}_list_item_head`}>
              <Typography.Text className={`${mainCssClass}_list_item_head_name`}>
                {el.label}
              </Typography.Text>
              {el.isShowProcessButton && (
                <ControlButton typeButton="primary" className="button" onClick={() => handleClickRespond(el.type)} size='small'>
                  Обработать
                </ControlButton>
              )}
            </div>

            {el.eventlead_list && !!el.eventlead_list.length
                && (
                  <div className={`${mainCssClass}_list_item_eventLeads`}>
                    { el.eventlead_list.map((elem) => (
                      <div className={`${mainCssClass}_list_item_eventLeads_item`}>
                        <Typography.Text className={`${mainCssClass}_list_item_eventLeads_item_name`}>
                          {elem.vacancy_name}
                        </Typography.Text>
                        {elem.self && (
                          <Typography.Text className={`${mainCssClass}_list_item_eventLeads_item_self`}>
                            Моя группа
                          </Typography.Text>
                        )}
                      </div>
                    ))}
                  </div>
                )}

          </div>
        ))}
      </div>
      <div />
    </div>
  )
}

export default RespondsCandidate
