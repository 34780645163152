import React from 'react'
import { KeyCode } from './utilities'

type handleKeyPressCallback<T> = (e: React.KeyboardEvent<T>) => void;

/**
 * Обработчик для нажатия клавиш ENTER и SPACE, используется для досутпности с клавиатуры
 * @param event - нативное событие
 * @param callback - коллбэк
 */
export function handleEnterSpacePress<T = HTMLElement>(
  event: React.KeyboardEvent<T>,
  callback: handleKeyPressCallback<T>
): void {
  const { keyCode } = event
  switch (keyCode) {
  case KeyCode.SPACE: {
    event.preventDefault()
    callback(event)
    break
  }
  case KeyCode.ENTER: {
    event.preventDefault()
    callback(event)
    break
  }
  }
}
