import { AxiosError } from 'axios'
import { PayloadAction, current } from '@reduxjs/toolkit'
import {
  all, call, put, select, takeLatest
} from 'redux-saga/effects'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  TRequestMailMessages, TRequestSendMailMessage, getMailMessages, getMessages, getReadMessages, sendMailMessage, sendMessage, setActualEmailMessages, setLoading, setMailMessages, setMessages, setHeaders
} from './actions'
import {
  TRequestMessages,
  TRequestReadMessage,
  TRequestSendMessage,
  TResponseMessages,
  fetchGetMailMessages,
  fetchGetMessages,
  fetchPostSendMailMessage,
  fetchPostSendMessage,
  fetchReadMessage
} from '../../common/api/chat'
import { selectMailMessages, selectMessages } from './selectors'
import { setLoadingProgress } from '../../compositions/InnerRouter/actions'

const moduleName = 'Страница чат'

function* getMessagesSaga({ payload }: PayloadAction<TRequestMessages>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response: TResponseMessages[] = yield fetchGetMessages(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить сообщения',
          error: response as AxiosError
        })
      )
    } else {
      const currentMessages: TResponseMessages[] = yield select(selectMessages)
      if (response.data.DATA.message_list) {
        const updatedMessages: TResponseMessages[] = [...currentMessages, ...response.data.DATA.message_list]
        yield put(setMessages(updatedMessages))
        showSuccessNotification({
          text: 'Сообщения получены'
        })
      }
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* sendMessageSaga({ payload }: PayloadAction<TRequestSendMessage>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response: TResponseMessages[] = yield fetchPostSendMessage(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отправить сообщение',
          error: response as AxiosError
        })
      )
    } else {
      const currentMessages: TResponseMessages[] = yield select(selectMessages)
      yield put(setMessages([...response.data.DATA.message_list, ...currentMessages]))

      showSuccessNotification({
        text: 'Сообщение отправлено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* sendMessageMailSaga({ payload }: PayloadAction<TRequestSendMailMessage>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response: TResponseMessages[] = yield fetchPostSendMailMessage(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отправить сообщение',
          error: response as AxiosError
        })
      )
    } else {
      const currentMessages: TResponseMessages[] = yield select(selectMailMessages)

      yield put(setMailMessages([...response.data.DATA.mail_list, ...currentMessages]))

      showSuccessNotification({
        text: 'Сообщение отправлено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getMailMessagesSaga({ payload }: PayloadAction<TRequestMailMessages>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response: TResponseMessages[] = yield fetchGetMailMessages(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить сообщения(почта)',
          error: response as AxiosError
        })
      )
    } else {
      const currentMessages: TResponseMessages[] = yield select(selectMailMessages)

      if (currentMessages.length) {
        yield put(setMailMessages([...currentMessages, ...response.data.DATA.mail_list]))
      } else {
        yield put(setMailMessages(response.data.DATA.mail_list))
      }
      if (payload.page === 1) {
        yield put(setActualEmailMessages(response.data.DATA.page_total))
      }

      showSuccessNotification({
        text: 'Сообщения получены (почта)'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

export function* getReadMessagesSaga({ payload }: PayloadAction<TRequestReadMessage[]>) {
  try {
    const requestsPromises = payload.map((el) => call(getMessageReadSaga, el))
    yield all(requestsPromises)
    const currentMessages = yield select(selectMessages)
    const finalMessages = currentMessages.map((el: TResponseMessages) => ({ ...el, status: 'READ' }))
    yield put(setMessages(finalMessages))

    showSuccessNotification({
      text: 'Сообщения прочитаны (чат)'
    })
  } catch (error) {
  } finally {
  }
}

function* getMessageReadSaga(payload : TRequestReadMessage) {
  try {
    const response: any = yield fetchReadMessage(payload)
    return response
  } catch (error) {
    return { data: { ERR: true } }
  }
}

function* chatSaga() {
  yield takeLatest(getMailMessages, getMailMessagesSaga)
  yield takeLatest(getMessages, getMessagesSaga)
  yield takeLatest(sendMessage, sendMessageSaga)
  yield takeLatest(sendMailMessage, sendMessageMailSaga)
  yield takeLatest(getReadMessages, getReadMessagesSaga)
}

export default chatSaga
