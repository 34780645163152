import React from 'react'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import './CommonModalLimitation.scss'
import { useSelector } from 'react-redux'
import { selectLoadingModal } from '../../../compositions/InnerRouter/selectors'

type TControlModalProps =
    ModalProps & {
    className?: string
    sizeButtonAccept?: 'small' | 'large'
    typeButtonAccept?: 'primary' | 'secondary' | 'tertiary' | 'warning'
    sizeButtonReject?: 'small' | 'large'
    typeButtonReject?: 'primary' | 'secondary' | 'tertiary' | 'warning'
}
const mainCssClass = 'modal-limitation-stage'
function CommonModalLimitation({
  sizeButtonAccept = 'large',
  sizeButtonReject = 'large',
  typeButtonReject,
  typeButtonAccept,
  className,
  ...rest
}: TControlModalProps): JSX.Element {
  const okButtonAcceptClassName = cn(
    className,
    sizeButtonAccept,
    mainCssClass,
    `${mainCssClass}__button-modals`,
    typeButtonAccept
  )
  const okButtonRejectClassName = cn(
    className,
    sizeButtonReject,
    mainCssClass,
    `${mainCssClass}__button-modals`,
    typeButtonReject
  )

  const loadingModal = useSelector(selectLoadingModal)

  return (
    <Modal
      className={cn(mainCssClass, className)}
      {...rest}
      okButtonProps={{ className: okButtonAcceptClassName, disabled: loadingModal, ...rest.okButtonProps }}
      cancelButtonProps={{ className: okButtonRejectClassName, disabled: loadingModal, ...rest.okButtonProps }}
    />
  )
}

export default CommonModalLimitation
