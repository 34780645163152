import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Tabs } from 'antd'
import { useForm } from 'react-hook-form'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { selectActiveDictionary, selectDictionaryName } from '../selectors'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import Check from '../../../common/svg/Check'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import CloseMD from '../../../common/svg/CloseMD'
import ControlButton from '../../../common/components/Buttons/Button'
import {
  createDictionaryOptions, setActiveDictionary, setDictionaryName, updateDictionaryOptions
} from '..'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

import './DictionaryForm.scss'

type TFormValues = {
  dictionary_name: string
  json:string
  value: string
  is_hidden :boolean
} & any

const mainCssClass = getCssClassName('dictionary-form')

function DictionaryForm() {
  const dispatch = useDispatch()
  const activeDictionary = useSelector(selectActiveDictionary)
  const dictionaryName = useSelector(selectDictionaryName)
  const [modal, setModal] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState<string|number>(undefined)

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>()

  const handleOpenModal = () => {
    setModal(true)
  }

  const handleClick = (id:number) => {
    dispatch(updateDictionaryOptions({
      id, value: watch(`value${id}`), params: watch(`json${id}`) ?
      JSON.parse(watch(`json${id}`)) : ''
      , is_hidden: watch(`is_hidden${id}`) ? 0 : 1
    }))
  }

  const handleCreate = () => {
    dispatch(createDictionaryOptions({ value: watch('dictionary_name'), dictionary_id: dictionaryName?.id, priority: 0 }))
    setModal(false)
  }

  useEffect(() => {
    if (activeDictionary.length > 0) {
      setActiveTabKey(activeDictionary[0].id)
    }
  }, [activeDictionary])

  useEffect(
    () => () => {
      reset()
      dispatch(setActiveDictionary([]))
      dispatch(setDictionaryName(null))
    },
    []
  )

  const json = watch(`json${activeTabKey}`)

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>
        {dictionaryName?.name}

      </Typography.Title>
      <div className={`${mainCssClass}_add`}>
        { !modal && (
          <ControlButton className="button" onClick={handleOpenModal} typeButton="secondary">
            Добавить запись
          </ControlButton>
        )}
        {modal && (
          <>
            <InputFormControl name="dictionary_name" control={control} label="Новая запись" required rules={{ required: true }} />
            <ControlButton className="button" typeButton="secondary" onClick={handleCreate}>
              <Check />
            </ControlButton>
            <ControlButton className="button" onClick={() => setModal(false)} typeButton="warning">
              <CloseMD />
            </ControlButton>
          </>
        )}
      </div>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <Tabs
              defaultActiveKey="1"
              tabPosition="left"
              items={activeDictionary.map((el) => ({
                label: el.value,
                key: el.id,
                children:
  <div className={`${mainCssClass}_list_item`}>
    <div className={`${mainCssClass}_list_item_switch`}>
      <SwitchFormControl control={control} name={`is_hidden${el.id}`} defaultChecked={el.is_hidden === 0} />
      <Typography.Text>Активная запись</Typography.Text>
    </div>
    <InputFormControl
      control={control}
      name={`value${el.id}`}
      label="Новая запись"
      required
      rules={{ required: activeTabKey === el.id }}
      defaultValue={el.value}
    />
    <TextAreaFormControl
      control={control}
      name={`json${el.id}`}
      label="JSON код для записи"
      autoSize={{ minRows: 4, maxRows: 5 }}
      defaultValue={el.params ? JSON.stringify(el.params) : ''}
    />
    <div>
      <ControlButton
        typeButton="primary"
        className="button"
        onClick={handleSubmit(() => handleClick(el.id))}
        disabled={
          watch(`value${el.id}`) === el.value
          && (
            (!el.params && (json === undefined || json === ''))
            || (el.params && watch(`json${el.id}`) === el.params)
          )
          && (
            (el.is_hidden === 0 && watch(`is_hidden${el.id}`) === true)
            || (el.is_hidden === 1 && (watch(`is_hidden${el.id}`) === false || watch(`is_hidden${el.id}`) === undefined))
          )

        }
      >
        Cохранить

      </ControlButton>
    </div>
  </div>
              }))}
              onChange={(key) => setActiveTabKey(key)}
            />
          </div>
        </NewScrollContainer>
      </div>
    </div>

  )
}

export default memo(withErrorBoundary(DictionaryForm))
