/* eslint-disable react/jsx-closing-tag-location */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { NavLink } from 'react-router-dom'
import { Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import Info from '../../../common/svg/Info'
import Vacation from './Vacation'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import AvatarCandidateEdit from '../../../common/svg/AvatarCandidateEdit'

import { selectProductivityRecruiter, selectProductivityResearcher } from '../selectors'
import { setPreviousPage } from '../../../compositions/InnerRouter/actions'
import './ProductivityTable.scss'

const mainCssClass = getCssClassName('productivity-table')

type TRowData = {
  name: JSX.Element
  request: JSX.Element
  stage: JSX.Element
  status: JSX.Element
  vacations: JSX.Element
  request_priority: JSX.Element
  customer_list: JSX.Element
  date_interview: JSX.Element
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'name',
      Header: 'Кандидат',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'date_interview',
      Header: 'Дата и время интервью',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'request',
      Header: 'Заявка',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA.props.children === 'string' && typeof valueB.props.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'interview_status',
      Header: 'Статус интервью',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA.props.children === 'string' && typeof valueB.props.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'vacations',
      Header: 'Вакансии',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'priority',
      Header:
  <div className={`${mainCssClass}__priority`}>
    Приоритет заявки
    <Tooltip
      title="На момент завершения интервью"
      placement="top"
    >
      <div>
        <Info />
      </div>
    </Tooltip>
  </div>,
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'phone',
      Header: 'Номер телефона',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'interview_result',
      Header: 'Результат интервью',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'characteristic',
      Header: 'Характеристика',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'recruiter_name',
      Header: 'Рекрутер',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'researcher_name',
      Header: 'Ресечер',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    }
  ],
  []
)

const useRowsData = (candidates: any[]) => {
  const dispatch = useDispatch()
  const handleClickPreviousPage = () => {
    dispatch(setPreviousPage({ navigate: '/productivity', caption: 'Продуктивность' }))
  }
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (candidates?.length
      ? candidates.map<any>(({
        candidate_fio, candidate_avatar, candidate_id, id, status, request, vacancy, priority, phone,
        interview_status, interview_result, characteristic, recruiter, researcher,
        interview_date, interview_time
      }) => ({
        name: (
          <NavLink to={`/candidate/${candidate_id}`} className={`${mainCssClass}__link`} onClick={handleClickPreviousPage}>
            <div className={`${mainCssClass}__name-${status}`}>
              <div className={`${mainCssClass}__name_status-columns`}>
                <div className={`${mainCssClass}__name_status-columns_page`}>
                  {candidate_avatar ? (
                    <img
                      src={candidate_avatar}
                      alt={candidate_avatar}
                      className={`${mainCssClass}__name_status-columns_photo`}
                    />
                  ) : (
                    <AvatarCandidateEdit />
                  )}
                </div>
                {' '}
                {candidate_fio?.length > 60
                  ? candidate_fio.slice(45) : candidate_fio }
              </div>
            </div>
          </NavLink>
        ),
        date_interview:
        <div className={`${mainCssClass}__name_status-columns`}>
          {interview_date ? dayjs(interview_date).format(DATE_DOTS_FORMAT) : '' }
          {' '}
          {interview_time || ''}
        </div>,
        request: <div className={`${mainCssClass}__name_status-columns`}>
          {request || 'Не назначена' }
        </div>,
        interview_status: <div className={`${mainCssClass}__name_status-columns`}>
          {interview_status || '' }
        </div>,
        priority: <div className={`${mainCssClass}__name_status-columns`}>
          {priority || 'Не назначен' }
        </div>,
        vacations: <div className={`${mainCssClass}__name_status-columns`}>
          <Vacation vacancy={vacancy} />
        </div>,
        phone: <div className={`${mainCssClass}__name_status-columns`}>
          {phone && phone.length ? phone.join(', ') : ''}
        </div>,
        interview_result: <div className={`${mainCssClass}__name_status-columns`}>
          {interview_result || '' }
        </div>,
        characteristic: <div className={`${mainCssClass}__name_status-columns`}>
          {characteristic || '' }
        </div>,
        recruiter_name: <div className={`${mainCssClass}__name_status-columns`}>
          {recruiter ? `${recruiter.surname} ${recruiter.name || ''} ${recruiter.patronymic || ''}` : '' }
        </div>,
        researcher_name: <div className={`${mainCssClass}__name_status-columns`}>
          {researcher ? `${researcher.surname || ''} ${researcher.name || ''} ${researcher.patronymic || ''}` : '' }
        </div>,
        id

      }))
      : []),
    [candidates]
  )
  return { rows, handleRowClick }
}
function ProductivityResearcherTable({ className } : TClassName) {
  const productivityResearcher = useSelector(selectProductivityResearcher)
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(productivityResearcher)

  return (
    <Table
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ProductivityResearcherTable))
