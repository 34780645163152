import React, { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectActiveTrigger } from '../selectors'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import { createTrigger, openNewTrigger, setActiveTrigger, updateTrigger } from '../actions'
import CloseMD from '../../../common/svg/CloseMD'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import {
  defaultCriteria,
  fetchCustomUserList,
  generateRecipients,
  getAction,
  getAttribute,
  getEvent,
  getHost,
  getModel,
  getTemplate,
  transformGetValues
} from '../utils'
import ControlButton from '../../../common/components/Buttons/Button'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './NewTrigger.scss'

const mainCssClass = getCssClassName('new-trigger')

type TFormValues = {
  name: string
  event: string
  table_name: string
  callback: string
  criteria: { parametr: number | null; value_parametr: string }[]
  holder_list: { recipient_type: number | null; recipient?: number | undefined }[]
  holder_type: string
  template_id: string
  template_subject: string
  status?: boolean
} & any

function NewTrigger() {
  const dispatch = useDispatch()
  const [model, setModel] = useState<any>(undefined)
  const [event, setEvent] = useState<any>(undefined)
  const [callback, setCallback] = useState<any>(undefined)
  const [host, setHost] = useState<any>(undefined)
  const [criteria, setCriteria] = useState<any>(undefined)
  const [templates, setTemplates] = useState<any>(undefined)

  const activeTrigger = useSelector(selectActiveTrigger)

  const defaultValues = useMemo(
    () =>
      activeTrigger
        ? {
            name: activeTrigger.name,
            table_name: activeTrigger.table_name,
            event: activeTrigger.event,
            criteria: defaultCriteria(activeTrigger.criteria),
            template_id: activeTrigger.template?.id,
            callback: activeTrigger.callback,
            holder_list: generateRecipients(activeTrigger.holder_list, activeTrigger.labels.holder_list),
            status: !!activeTrigger.status,
            template_subject: activeTrigger.template?.mail_subject
          }
        : {},
    [activeTrigger]
  )

  const { control, handleSubmit, reset, getValues, watch, resetField, setValue } = useForm<TFormValues>({
    defaultValues
  })

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'criteria'
  })

  const {
    fields: actionFields,
    append: actionAppend,
    prepend: actionPrepend,
    remove: removeAction
  } = useFieldArray({
    control,
    name: 'holder_list'
  })

  if (fields?.length === 0) {
    prepend({
      parametr: null,
      value_parametr: ''
    })
  }

  if (actionFields?.length === 0) {
    actionPrepend({
      recipient: undefined
    })
  }
  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleRemoveAction = (index: number) => {
    removeAction(index)
  }

  const handleCancel = () => {
    dispatch(openNewTrigger(false))
    dispatch(setActiveTrigger(null))
  }

  const table_name = watch('table_name')

  const onSubmit = () => {
    const newObj = transformGetValues(getValues())

    if (!activeTrigger) {
      dispatch(createTrigger(newObj))
    } else {
      dispatch(updateTrigger({ ...newObj, id: activeTrigger.id, status: watch('status') ? 1 : null }))
    }
  }

  useLayoutEffect(() => {
    getModel()
      .then((responseData) => {
        setModel(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })

    getEvent()
      .then((responseData) => {
        setEvent(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })

    getHost()
      .then((responseData) => {
        setHost(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })

    getAction()
      .then((responseData) => {
        setCallback(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })

    getTemplate()
      .then((responseData) => {
        setTemplates(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  useEffect(() => {
    if (table_name) {
      getAttribute(table_name)
        .then((responseData) => {
          setCriteria(responseData)
        })
        .catch((error) => {
          console.error('Error fetching model data:', error)
        })
    }
  }, [table_name])

  const tableNameDef = defaultValues.table_name

  useEffect(() => {
    if (JSON.stringify(tableNameDef) !== JSON.stringify(watch('table_name'))) {
      setValue('criteria', [{ parametr: null, value_parametr: '' }])
      setValue('criteria[0].value_parametr', '')
      setValue('criteria[0].parametr', null)
    }
  }, [table_name])

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>{activeTrigger ? 'Редактировать триггер' : 'Новый триггер'}</Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            {activeTrigger && (
              <div className={`${mainCssClass}_list_item_switch`}>
                <SwitchFormControl control={control} name="status" />
                <Typography.Text>Включить триггер</Typography.Text>
              </div>
            )}
            <InputFormControl
              name="name"
              control={control}
              label="Название триггера"
              required
              rules={{ required: true }}
            />
            <div className={`${mainCssClass}_list_item`}>
              <SelectFormControl
                name="table_name"
                control={control}
                label="Сущность"
                required
                options={model}
                rules={{ required: true }}
              />
              <SelectFormControl
                name="event"
                control={control}
                label="Триггер"
                required
                options={event}
                rules={{ required: true }}
              />
            </div>
            {watch('table_name') &&
              fields?.map((email, index) =>
                index === 0 ? (
                  <div className={`${mainCssClass}_list_item-first`}>
                    <SelectFormControl
                      name={`criteria[${index}].parametr`}
                      control={control}
                      label="Параметр"
                      options={criteria}
                    />
                    <InputFormControl
                      name={`criteria[${index}].value_parametr`}
                      control={control}
                      label="Значение параметра"
                    />
                    <ControlButton
                      onClick={() => append({ parametr: null, value_parametr: '' })}
                      typeButton="secondary"
                      className="button"
                    >
                      <AddPlusSvg />
                    </ControlButton>
                  </div>
                ) : (
                  <div className={`${mainCssClass}_list_item-other`}>
                    <SelectFormControl
                      name={`criteria[${index}].parametr`}
                      control={control}
                      options={criteria}
                      label="Параметр"
                    />
                    <InputFormControl
                      name={`criteria[${index}].value_parametr`}
                      control={control}
                      label="Значение параметра"
                    />
                    <ControlButton onClick={() => handleRemove(index)} className="button" typeButton="warning">
                      <CloseMD />
                    </ControlButton>
                  </div>
                )
              )}

            <div className={`${mainCssClass}_list_item`}>
              <SelectFormControl
                control={control}
                name="callback"
                label="Действие"
                required
                options={callback}
                rules={{ required: true }}
              />
              <SelectFormControl name="template_id" control={control} label="Шаблон" options={templates} />

              {watch('callback') === 'send_email' && (
                <InputFormControl
                  control={control}
                  name="template_subject"
                  label="Тема письма"
                  required
                  rules={{ required: true }}
                />
              )}
            </div>
            {actionFields?.map((email, index) =>
              index === 0 ? (
                <div className={`${mainCssClass}_list_item-first`}>
                  <SelectFormControl
                    name={`holder_list[${index}].recipient_type`}
                    control={control}
                    label="Тип получателя"
                    rules={{ required: true }}
                    options={host}
                    required
                  />

                  {watch(`holder_list[${index}].recipient_type`) === 'custom' && (
                    <DebounceSelectFormControl
                      name={`holder_list[${index}].recipient`}
                      control={control}
                      label="Получатель"
                      fetchOptions={fetchCustomUserList}
                      required
                      rules={{ required: true }}
                    />
                  )}

                  <ControlButton
                    onClick={() => actionAppend({ recipient_type: null, recipient: undefined })}
                    typeButton="secondary"
                    className="button"
                  >
                    <AddPlusSvg />
                  </ControlButton>
                </div>
              ) : (
                <div className={`${mainCssClass}_list_item-other`}>
                  <SelectFormControl
                    name={`holder_list[${index}].recipient_type`}
                    control={control}
                    label="Тип получателя"
                    rules={{ required: true }}
                    options={host}
                    required
                  />
                  {watch(`holder_list[${index}].recipient_type`) === 'custom' && (
                    <DebounceSelectFormControl
                      name={`holder_list[${index}].recipient`}
                      control={control}
                      label="Получатель"
                      fetchOptions={fetchCustomUserList}
                      required
                      rules={{ required: true }}
                    />
                  )}
                  <ControlButton onClick={() => handleRemoveAction(index)} typeButton="warning" className="button">
                    <CloseMD />
                  </ControlButton>
                </div>
              )
            )}
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}
export default memo(withErrorBoundary(NewTrigger))
