import React, {
  memo,
  useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography, Upload, UploadProps, message, Tooltip
} from 'antd'
import { useForm } from 'react-hook-form'
import { PlusOutlined } from '@ant-design/icons'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getUserInfo } from '../../InnerRouter/actions'
import ControlButton from '../../../common/components/Buttons/Button'
import { selectUserInfo } from '../../InnerRouter/selectors'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import { updateUser } from '../actions'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { getCity } from '../utils'
import './MyAccount.scss'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { fetchRequestList } from '../../../features/requestModals/utils'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import AvatarNavbar from '../../../common/svg/AvatarNavbar'

type TFormValues = {
  name: string
  surname:string
  patronymic:string
  role:string
  phone:string
  seat:string
  email:string
  mail_login:string
  password:string
  telegram_chat_id?: string
  telegram_channel_chat_id?: string
  telegram_tag?: string
  city_id: number
  is_sound_notify: boolean
  customer_responsible:number[]
  accessOnlySound: boolean
  accessOnlySoundOnEvening: boolean
}

const mainCssClass = getCssClassName('my-account')

function MyAccount() {
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()
  const [cityOptions, setCityOptions] = useState([])
  const { rbacControl } = useEntityContext()
  const departmentOptions = [{ label: 'Без отдела', value: 299 }, { label: 'Офисный подбор', value: 174 }, { label: 'Курьеры', value: 175 }, { label: 'Розничный подбор', value: 176 }, { label: 'Курьеры (Розница)', value: 177 }]
  const myDepartment = useMemo(() => departmentOptions.find((item) => item.value === userInfo?.department)?.label || '-', [userInfo])

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>()

  const onSubmit = () => {
    const {
      mail_login, password, customer_responsible, is_sound_notify, accessOnlySound, accessOnlySoundOnEvening, ...rest
    } = getValues()
    const responsible = watch('customer_responsible')
    const newData = {
      ...rest,
      mail_settings: { mail_login },
      is_sound_notify: !!is_sound_notify,
      password,
      customer_responsible: responsible ? [responsible] : null,
      calendar_settings: {
        accessOnlySound,
        accessOnlySoundOnEvening
      }
    }
    dispatch(updateUser(newData))
  }

  useLayoutEffect(
    () => {
      dispatch(getUserInfo(false))
    },
    []
  )

  useEffect(() => {
    getCity()
      .then((responseData) => {
        setCityOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(() => {
    if (userInfo) {
      const defaultValues = {
        name: userInfo.name,
        surname: userInfo.surname,
        seat: userInfo.seat || '',
        patronymic: userInfo.patronymic,
        phone: userInfo.phone,
        email: userInfo.email,
        token_plugin: userInfo.token_plugin,
        telegram_channel_chat_id: userInfo?.telegram_channel_chat_id,
        telegram_tag: userInfo?.telegram_tag,
        telegram_chat_id: userInfo?.telegram_chat_id,
        city_id: userInfo.city_id,
        accessOnlySound: !!userInfo?.calendar_settings?.accessOnlySound,
        accessOnlySoundOnEvening: !!userInfo?.calendar_settings?.accessOnlySoundOnEvening,
        is_sound_notify: !!userInfo?.is_sound_notify,
        customer_responsible: userInfo?.hasOwnProperty('customer_responsible') && userInfo.customer_responsible.length ? { label: userInfo.customer_responsible[0].label, value: userInfo.customer_responsible[0].id } : null
      }
      if (typeof userInfo.mail_settings === 'object') {
        defaultValues.mail_login = userInfo.mail_settings.mail_login
        defaultValues.password = userInfo.mail_settings.password
      }

      reset(defaultValues)
    }
  }, [userInfo])
  const props: UploadProps = useMemo(() => ({
    name: 'file',
    action: `${process.env.DOMAIN}/api/v1/user/upload-avatar`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // eslint-disable-next-line no-console
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file успешно загружен`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onSuccess() {
      dispatch(getUserInfo(false))
    }
  }), [])

  const uploadButton = (
    <Tooltip title="Загрузите фото" mouseEnterDelay={0}>
      <div className={`${mainCssClass}_new-avatar`}>
        <AvatarNavbar />
      </div>
    </Tooltip>
  )

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>
        Мой аккаунт
      </Typography.Title>
      <div className={`${mainCssClass}_form`}>
        <Tooltip title="Загрузите фото" mouseEnterDelay={0}>
          <Upload
            name="avatar"
            className={`${mainCssClass}__avatar`}
            showUploadList={false}
            {...props}
          >
            {userInfo?.avatar ? <img src={userInfo?.avatar} alt="avatar" style={{ width: '100%' }} className={`${mainCssClass}__avatar`} /> : uploadButton}
          </Upload>
        </Tooltip>
        <div className={`${mainCssClass}_form_main`}>
          <div className={`${mainCssClass}_form_main_item`}>
            <InputFormControl
              name="name"
              control={control}
              label="Имя"
              required
              disabled={rbacControl?.profile.edit_view_access !== 1}
              rules={{ required: true }}
            />
            <InputFormControl
              name="surname"
              control={control}
              label="Фамилия"
              required
              disabled={rbacControl?.profile.edit_view_access !== 1}
              rules={{ required: true }}

            />
            <InputFormControl
              name="patronymic"
              control={control}
              label="Отчество"
              disabled={rbacControl?.profile.edit_view_access !== 1}
              required
            />
          </div>
          <div className={`${mainCssClass}_form_main_item`}>
            <SearchSelectFormControlSource
              name="city_id"
              label="Город"
              control={control}
              options={cityOptions}
              showSearch
              optionFilterProp="children"
            />
            {userInfo?.role?.role_id === 'client' && (
              <DebounceSelectFormControl
                placeholder="Поиск по имени..."
                fetchOptions={fetchRequestList}
                label="Ответственный"
                name="customer_responsible"
                control={control}
              />
            )}

          </div>

          <InputFormControl
            name="telegram_chat_id"
            control={control}
            label="Телеграмм id"
          />

          {
            (userInfo?.role?.role_id === 'zakazchik_kur_erov' || userInfo?.role?.role_id === 'courier_curator') && (
              <>
                <InputFormControl
                  name="telegram_channel_chat_id"
                  control={control}
                  label="Телеграмм канал"
                />

                <InputFormControl
                  name="telegram_tag"
                  control={control}
                  label="Телеграмм тэг"
                />
              </>
            )
          }
          <div className={`${mainCssClass}_form_main_item`}>
            <InputFormControl
              name="phone"
              control={control}
              label="Телефон"
              mask="+7 (999) 999-99-99"
              required
              // disabled={rbacControl?.profile.edit_view_access !== 1}
              rules={{ required: true }}
            />
            <InputFormControl
              name="email"
              control={control}
              label="Почта"
              type="email"
              disabled={rbacControl?.profile.edit_view_access !== 1}
              required
            />
            <InputFormControl
              name="token_plugin"
              control={control}
              label="Токен плагин"
              disabled

            />

          </div>
          <div className={`${mainCssClass}_form_main_item`}>

            <SwitchFormControl name="is_sound_notify" control={control} />
            <span>Отключить звук уведомлений</span>
          </div>
          <div className={`${mainCssClass}_form_main_item`}>

            <SwitchFormControl name="accessOnlySound" control={control} />
            <span>Можно назначать интервью без видеосвязи</span>
          </div>
          <div className={`${mainCssClass}_form_main_item`}>

            <SwitchFormControl name="accessOnlySoundOnEvening" control={control} />
            <span>Можно записывать после 18:00</span>
          </div>
          <div className={`${mainCssClass}_form_main_item`}>
            <div className={`${mainCssClass}_info_container`}>
              <Typography.Text className={`${mainCssClass}_info_label`}>
                Мой Id
              </Typography.Text>
              <Typography.Text className={`${mainCssClass}_info_value`}>
                {userInfo?.id}
              </Typography.Text>
            </div>
            <div className={`${mainCssClass}_info_container`}>
              <Typography.Text className={`${mainCssClass}_info_label`}>
                Моя роль
              </Typography.Text>
              <Typography.Text className={`${mainCssClass}_info_value`}>
                {userInfo?.role?.description}
              </Typography.Text>
            </div>
            <div className={`${mainCssClass}_info_container`}>
              <Typography.Text className={`${mainCssClass}_info_label`}>
                Мой департамент
              </Typography.Text>
              <Typography.Text className={`${mainCssClass}_info_value`}>
                {myDepartment}
              </Typography.Text>
            </div>
          </div>
          <hr className={`${mainCssClass}__line`} />
          <div className={`${mainCssClass}_form_main_item`}>
            <Typography.Title level={4}>
              Авторизация почты
            </Typography.Title>
          </div>
          <div className={`${mainCssClass}_form_main_item`}>

            <InputFormControl
              name="mail_login"
              control={control}
              label="Email"
              disabled={rbacControl?.profile.edit_view_access !== 1}
            />
            <InputFormControl
              name="password"
              control={control}
              label="Пароль"
              type="password"
              disabled={rbacControl?.profile.edit_view_access !== 1}
            />
          </div>
          <div>
            {rbacControl?.profile.edit_view_access === 1 && (
              <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
                Сохранить
              </ControlButton>
            )}
          </div>

        </div>

      </div>
    </div>

  )
}

export default memo(withErrorBoundary(MyAccount))
