import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

function RequestAddEvent({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '4' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#FFF9D9" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3631 10.5654C20.8269 9.4029 19.1748 9.40335 18.6388 10.5655L16.2238 15.8012L10.4978 16.4801C9.22673 16.6308 8.71626 18.2019 9.656 19.0709L13.889 22.9856L12.7653 28.6411C12.5156 29.8976 13.8535 30.8669 14.9695 30.2423L20.001 27.426L25.0322 30.2422C26.1493 30.8675 27.4854 29.8963 27.236 28.6411L26.1125 22.9856L30.3452 19.071C31.2849 18.2019 30.7746 16.6308 29.5035 16.4801L26.6961 16.1473L23.7782 15.8013L22.6425 13.3391L21.3631 10.5654ZM17.9227 16.893C17.7039 17.3673 17.2543 17.693 16.7369 17.7544L11.8098 18.3386L15.4526 21.7074C15.8363 22.0622 16.0067 22.5902 15.9052 23.101L14.9381 27.9679L19.2685 25.544C19.7241 25.289 20.2789 25.2896 20.7337 25.5441L25.0631 27.9675L24.0963 23.101C23.9948 22.5902 24.1654 22.0621 24.5489 21.7074L27.9894 18.5255L28.1915 18.3386L26.123 18.0933L23.2641 17.7544C22.8668 17.7073 22.5117 17.5048 22.2692 17.201C22.1945 17.1075 22.1305 17.0043 22.0792 16.8931L20.001 12.3873L18.2138 16.262L17.9227 16.893Z" fill="#FFD608" />
    </svg>

  )
}
export default RequestAddEvent
