import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TFilter } from './types'
import {
  TBlockedSlotDelete,
  TBlockedSlots,
  TRequestBodyBlockedSlots,
  TRequestBodyUpdateSettings
} from '../../common/api/calendar'

export const openСalendarSettings = createAction<boolean>('calendarSettings/openСalendarSettings')
export const reset = createAction('calendarSettings/reset')
export const getDictionaries = createAction<TDictionariesNames>('calendarSettings/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('calendarSettings/dictionaries/setDictionaries')

export type TGetEmptyRequest ={
  userId?: number,
  date: string
}
export const getEmptySlotByUser = createAction<TGetEmptyRequest>('calendarSettings/getEmptySlotByUser')
export const setEmptySlotByUser = createAction<string[]>('calendarSettings/setEmptySlotByUser')
export const getEmptySlotsTimeStop = createAction<string  >('calendarSettings/getEmptySlotsTimeStop')
export const setEmptySlotTimeStop = createAction<string[]>('calendarSettings/setEmptySlotTimeStop')

export type TCalendarSettings ={
  user_id: number
  graphic_option_id?: number,
  slot_interval?: number,
  begin_time_at?: string,
  end_time_at?: string,
  recalculate_at?: string,
  time_interval?: string
  date?: string
}
export type TDeleteReserveSlot = {
  reserve_slot_id: string
  user_id: number
}

export const updateUserCalendarSettings = createAction<TCalendarSettings>('calendarSettings/updateUserCalendarSettings')

export const updateCalendarSettings = createAction<TRequestBodyUpdateSettings>('calendarSettings/updateCalendarSettings')
export const createTimeSlotStop = createAction<TRequestBodyBlockedSlots>('calendarSettings/createTimeSlotStop')
export const deleteReserveSlot = createAction<TDeleteReserveSlot>('calendarSettings/deleteReserveSlot')
export const createReserveSlot = createAction<TCalendarSettings>('calendarSettings/createReserveSlot')
export type TGetReserveSlots = TGetEmptyRequest
export const getReserveSlotsUser = createAction<TGetReserveSlots>('calendarSettings/getReserveSlotsUser')
export const setActiveDateChange = createAction<string>('calendarSettings/activeDateChange')
export const setReserveSlotsUser = createAction('calendarSettings/setReserveSlotsUser')
export const getHolidays = createAction('calendarSettings/getHolidays')
export const getBlockedSlots = createAction<TGetEmptyRequest>('calendarSettings/getBlockedSlots')
export const setBlockedSlots = createAction<TBlockedSlots[]>('calendarSettings/setBlockedSlots')
export const deleteBlockedSlot = createAction<TBlockedSlotDelete>('calendarSettings/deleteBlockedSlot')
export const setHolidays = createAction('calendarSettings/setHolidays')
