import React, { useCallback, useEffect } from 'react'
import {
  ModalProps
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import './AutoCallsModal.scss'
import { getGroupUserWhoCalls, getUsersWhoCalls, saveAutoCalls } from '../actions'
import { selectLoading, selectSelectedAutoCall, selectWhoCallsGroupOptions, selectWhoCallsOptions } from '../selectors'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'

const mainCssClass = 'auto-calls-modal'

type TProps = {
} & ModalProps

export type TFormValues = {
  name: string,
  busy_recall: number,
  no_answer_recall: number,
  not_available_recall: number,
  busy_timeout: number,
  no_answer_timeout: number,
  not_available_timeout: number,
  waiting_answer_time: number,
  listUserId: number[]
  listQueueId: number[]
}
const defaultValues = {
  name: '',
  busy_recall: 0,
  no_answer_recall: 0,
  not_available_recall: 0,
  busy_timeout: 0,
  no_answer_timeout: 0,
  not_available_timeout: 0,
  waiting_answer_time: 0,
  listUserId: [],
  listQueueId: []
}

function AutoCallsModal({
  open, className, onCancel
}: TProps) {
  const dispatch = useDispatch()
  const [filterUser, setFilterUser] = React.useState<string>('')
  const autoCall = useSelector(selectSelectedAutoCall)
  const options = useSelector(selectWhoCallsOptions)
  const optionsGroup = useSelector(selectWhoCallsGroupOptions)
  const loading = useSelector(selectLoading)
  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }


  const {
    control, getValues, reset, watch, setValue
  } = useForm<TFormValues>({
    defaultValues
  })
  const group = watch('listQueueId')
  const listUserId = watch('listUserId')

  const handleSubmit = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // @ts-ignore
    const newValues = getValues()
    if (listUserId.length > 0) {
      delete newValues.listQueueId
    }
    dispatch(saveAutoCalls(newValues))
    handleModalClose(e)
  }, [listUserId])

  useEffect(() => {
    dispatch(getUsersWhoCalls(filterUser))
  }, [filterUser])

  useEffect(() => {
    dispatch(getGroupUserWhoCalls())
  }, [])

  useEffect(() => {
    if (group) {
      const newFilterUser = group.map((item) => `queueIdList[]=${item}`).join('&')
      setFilterUser(newFilterUser)
      setValue('listUserId', [])
    } else {
      setFilterUser('')
    }
  }, [group])

  useEffect(() => {
    if (autoCall) {
      reset(autoCall)
    }
  }, [autoCall])

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit}
      onCancel={handleModalClose}
      title="Новый автообзвон"
      className={cn(className, mainCssClass)}
      width="1200px"
      typeButtonAccept="primary"
      okText="Сохранить и запустить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}__container-form`}>
        <InputFormControl
          name="name"
          control={control}
          label="Название"
        />
        <div className={`${mainCssClass}_items`}>
          <InputFormControl
            name="busy_recall"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Перезвонов при "Занят"'
          />
          <InputFormControl
            name="no_answer_recall"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Перезвонов при "Нет ответа"'
          />
          <InputFormControl
            name="not_available_recall"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Перезвонов при "Недоступен"'
          />
        </div>
        <div className={`${mainCssClass}_items`}>
          <InputFormControl
            name="busy_timeout"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Таймаут при "Занят"'
          />
          <InputFormControl
            name="no_answer_timeout"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Таймаут при "Нет ответа"'
          />
          <InputFormControl
            name="not_available_timeout"
            control={control}
            rules={{
              pattern: /^[0-9]*$/
            }}
            label='Таймаут при "Недоступен"'
          />
        </div>
        <InputFormControl
          name="waiting_answer_time"
          control={control}
          rules={{
            pattern: /^[0-9]*$/
          }}
          label="Ожидание ответа"
        />
        <SelectFormControlMult
          name="listQueueId"
          control={control}
          options={optionsGroup}
          disabled={loading}
          label="Группы"
        />
        <SelectFormControlMult
          name="listUserId"
          control={control}
          options={options}
          label="Кто будет звонить"
        />
      </div>
    </CommonModal>
  )
}

export default AutoCallsModal
