import { createAction } from '@reduxjs/toolkit'
import { TDictionariesNames, TDictionaries } from '../../common/types/dictionaries'

// Авторизация
export const getDictionaries = createAction<TDictionariesNames>('candidateModals/dictionaries/getData')

export const setDictionariesData = createAction<TDictionaries>('candidateModals/dictionaries/setData')

export type TUpdateCandidateStatusRequest = {
  status: string | number
  id: string | number
  isCandidateTable: boolean
}

export type TRequestAssignVacancy = {
  id: string | number
  vacancy_id: number | null
}
export const updateStatusCandidate = createAction<TUpdateCandidateStatusRequest>(
  'candidateModals/updateCandidateStatus'
)

export type TArchiveCandidateRequest = {
  reject_comment: string
  reject_id: string
} & TUpdateCandidateStatusRequest
export const archiveCandidate = createAction<TArchiveCandidateRequest>('candidateModals/archiveCandidateStatus')

export const assignVacancy = createAction<TRequestAssignVacancy>('candidateModals/assignVacancy')
