import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Button, Typography } from 'antd'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { Heading } from '../../../../common/components/Text'
import { reset } from '../../../Systems'
import './RejectionInfo.scss'
import RejectedCandidatesTable from '../vertex/RejectedCandidatesTable'
import AddedCandidatesTable from '../vertex/AddedCandidatesTable'
import RejectionLeaderTable from './RejectionLeaderTable'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectLoading, selectRejectionInfo, selectVertex } from '../../selectors'
import { selectFilterString } from '../../../../features/reportFilter'
import RejectionCandidateTable from './RejectionCandidateTable'
import RejectionRecruterTable from './RejectionRecruterTable'
import RejectionResecherTable from './RejectionResecherTable'
import Excel from '../../../../common/svg/Excel'
import MonitorPlay from '../../../../common/svg/MonitorPlay'
import { getRejectionInfo, getSourseOfCandidates } from '../../actions'
import { Preloader } from '../../../../common/components/Preloader/Preloader'
import RejectionCourierTable from './RejectionCourierTable'
import RejectionBar from './RejectionBar'

const mainCssClass = getCssClassName('rejection')

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'none' as const
    },
    title: {
      display: false
    }
  }
}

export function RejectionInfo() {
  const dispatch = useDispatch()
  const filter = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)
  const rejectionData = useSelector(selectRejectionInfo)

  useEffect(() => {
    if (filter) {
      dispatch(getRejectionInfo({ filter }))
    }
  }, [filter])
  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )
  return (
    <div className={mainCssClass}>
      {/* <div className={`${mainCssClass}__select-block`}> */}
      {/*  <SelectFormControl name="method1" control={control} options={methodOptions} /> */}
      {/* </div> */}
      {loading && <Preloader size="md" />}
      {
        !loading && filter && (
          <div className={`${mainCssClass}__title-block`}>
            <div>
              <Heading.H2>
                Отказы
              </Heading.H2>
            </div>
            {rejectionData && rejectionData?.[0]?.analyticRow.map((el, i) => (el.detail && el.detail.length

              ? (
                <div key={i} className={`${mainCssClass}_elem`}>
                  <Heading.H4>
                    {el.label}
                  </Heading.H4>
                  <RejectionBar
                    className={`${mainCssClass}__table`}
                    labels={el.detail.map((elem) => elem.label)}
                    dataValues={el.detail.map((elem) => elem.count)}
                    height={el.detail.length}
                  />
                  <RejectionCandidateTable sourse={el.detail} />
                </div>
              ) : null))}
          </div>
        )
      }

      {!filter && !loading
          && (
            <div className={`${mainCssClass}_filter`}>
              <span>
                Отчет появится после настройки фильтров.
                <br />
                Настройте фильтры и нажмите кнопку (Применить)
              </span>
            </div>
          )}
    </div>

  )
}
export default memo(withErrorBoundary(RejectionInfo))
