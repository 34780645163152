import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import Table from '../../../../common/components/table/Table'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectDictionaryOptionsByName } from '../../selectors'
import './RejectedCandidatesTable.scss'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('rejected-table')

  type TRowData = {
    source: string
    quantity: string
    interview_manager: string
    to_agreed: string
    interview_customer:string
    reserve:string
    offer:string
    accepted:string

  }

const useColumns = (): Column<any>[] => {
  const arr = useSelector(selectDictionaryOptionsByName('stageStatus'))

  return useMemo(() => {
    let columns: Column<any>[] = []

    if (arr.length > 0) {
      // Сначала объявляем массив columns
      columns = [
        {
          accessor: 'label',
          Header: 'Источник',
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        },
        {
          accessor: 'count', // Исправлено на 'count'
          Header: 'Количество',
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        },
        ...arr.filter((elem) => elem.label !== 'Добавлен' && elem.label !== 'Не выбрано').map((el) => ({
          accessor: el.label,
          Header: el.label,
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        }))
      ]
    }

    return columns
  }, [arr])
}

const useRowsData = (sourseOfCandidates) => {
  const dispatch = useDispatch()
  const employeeNames = useMemo(() => (!!sourseOfCandidates.length
    ? sourseOfCandidates
      .map((item) => item.label)
    : []), [sourseOfCandidates])
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
    },
    []
  )

  const rows: any[] = useMemo(
    () => (
      sourseOfCandidates.length ? sourseOfCandidates.map<any>(({
        label,
        count,
        detail,
        id
      }) => {
        label || '-'
        count || '-'
        const detailObject = detail.reduce((acc, el) => {
          acc[el.label] = el.count
          return acc
        }, {})

        return {
          label,
          count,
          ...detailObject,
          id
        }
      })
        : []),
    [sourseOfCandidates, employeeNames]
  )

  return { rows, handleRowClick }
}
const RejectedCandidatesTable: FC = ({ sourse }) => {
  const columns = useColumns()

  const { rows, handleRowClick } = useRowsData(sourse)
  return (
    <div>
      {/* <div className={`${mainCssClass}__table`} /> */}
      <Table
        className={cn(mainCssClass)}
        columns={columns}
        data={rows}
        sortabled
        withoutLastColumnElement
        handleRowClick={handleRowClick}
        withoutHeaderDividers
      />
    </div>
  )
}

export default memo(withErrorBoundary(RejectedCandidatesTable))
