import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TInterview, TInterviewHistory } from '../../common/api/interviews'
import { TDATA } from '../candidate/types'
import { TResume } from '../Resume'
import { TAddInterview, TCandidateQuestionnaire } from '../candidate/actions'

export const getCandidate = createAction<string>('interview/getCandidate')
export const reset = createAction('interview/reset')
export const setCandidate = createAction<TDATA | null>('interview/setCandidate')
export const setLoading = createAction<boolean>('interview/setLoading')
export const getDictionaries = createAction<TDictionariesNames>('interview/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('interview/dictionaries/setDictionaries')
export const getInterview = createAction<string>('interview/getInterview')
export const setInterview = createAction<TInterview | null>('interview/setInterview')


export const updateInterview = createAction<TInterview >('interview/updateInterview')
export const editInterview = createAction<TAddInterview>('interview/editInterview')
export const getHistoryData = createAction<string>('interview/getHistoryData')
export const setHistoryData = createAction<TInterviewHistory[] | null>('interview/setHistoryData')

export const setHandleModalCancelOpen = createAction<boolean>('interview/setHandleModalCancelOpen')
export const createQuestionnaire = createAction<TCandidateQuestionnaire>('interview/createQuestionnaire')

export const setIsEditModeQuestionnaire = createAction<boolean>('interview/setIsEditModeQuestionnaire')

export const getQuestionnaire = createAction('interview/getQuestionnaire')
export const updateCandidate = createAction<TDATA>('interview/updateCandidate')

export const setQuestionnaire = createAction<any>('interview/setQuestionnaire')
export const setHandleModalEditOpen = createAction<boolean>('interview/setHandleModalEditOpen')

export const setSelectedInterviewId = createAction<number | null>('interview/setSelectedInterviewId')

export const setHandleModalEndOpen = createAction<boolean>('interview/setHandleModalEndOpen')

export const setHandleModalHistoryOpen = createAction<boolean>('interview/setHandleModalHistoryOpen')

export const setResumeList = createAction<TResume[] | null>('interview/setResumeList')

export const setActiveResume = createAction<TResume | null>('interview/setActiveResume')

export const setHandleModalQuestionnaireHistoryOpen = createAction<boolean>('interview/setHandleModalQuestionnaireHistoryOpen')

export const getCandidateQuestionnaire = createAction<number>('interview/getCandidateQuestionnaire')
export const setCandidateQuestionnaire = createAction<any>('interview/setCandidateQuestionnaire')

export const getCandidateUpdate = createAction<string>('interview/getCandidateUpdate')
