import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ chat }: TRootState) => chat

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectPageMessages = createSelector(selectState, ({ pageMessages }) => pageMessages)

export const selectMessages = createSelector(selectState, ({ messages }) => messages)

export const selectMailMessages = createSelector(selectState, ({ mailMessages }) => mailMessages)

export const selectActualEmailMessages = createSelector(selectState, ({ actualMailPage }) => actualMailPage)

export const selectTemplateContent = createSelector(selectState, ({ templateContent }) => templateContent)

export const selectMessageForChat = createSelector(selectState, ({ messageForChat }) => messageForChat)
export const selectHeadersForChat = createSelector(selectState, ({ headers }) => headers)
