import React, { FC, memo, useState } from 'react'
import { Tag } from 'antd'
import './Vacation.scss'

type TProps = {
  vacation: string
}
// eslint-disable-next-line react/function-component-definition
const Vacation: FC<TProps> = ({ vacation }) => (
  <div>
    <Tag className="customLabel" color="blue">
      {vacation}
    </Tag>
  </div>
)

export default memo(Vacation)
