import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  selectDefDataWatsapp,
  selectLoading,
  selectWhatsappForm, selectWhatsappHeaders, selectWhatsappTemplates
} from '../selectors'
import {
  createPinWhatsappTemplate, deletePinWhatsappTemplate,
  deleteWhatsappTemplate,
  getWhatsappTemplates, openTemplatesForm,
  openWhatsappTemplatesForm, reset, setActiveWhatsappTemplate, setDefDataWhatsapp
} from '../actions'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import ControlButton from '../../../common/components/Buttons/Button'
import TrashFull from '../../../common/svg/TrashFull'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import Show from '../../../common/svg/Show'
import WhatsTemplatesForm from './WhatsTemplatesForm'
import Hide from '../../../common/svg/Hide'
import './MyWhatsappTemplates.scss'
import Star from '../../../common/svg/Star'

const mainCssClass = getCssClassName('my-systems-whatsapp-templates')

export type TFormValues = {
    perPagesCount: number
}

function MyTemplates() {
  const dispatch = useDispatch()
  const templatesForm = useSelector(selectWhatsappForm)
  const templates = useSelector(selectWhatsappTemplates)
  const loading = useSelector(selectLoading)
  const defData = useSelector(selectDefDataWatsapp)
  const [openTemplate, setOpenTemplate] = useState<number[]>([])
  const userId = localStorage.getItem('user_id')
  const headers = useSelector(selectWhatsappHeaders)
  const [page, setPage] = useState<number>(1)

  const { rbacControl } = useEntityContext()

  const {
    control, handleSubmit, getValues, watch, setValue
  } = useForm<TFormValues>({
    defaultValues: { perPagesCount: defData?.perPage || 10 }
  })

  const perPage = watch('perPagesCount')

  const handleEditTemplates = (item:any) => {
    dispatch(setActiveWhatsappTemplate(item))
    dispatch(openWhatsappTemplatesForm(true))
  }

  const handleDeleteTemplate = (id:number) => {
    dispatch(deleteWhatsappTemplate(id))
  }

  const handleNextPage = useCallback(() => {
    if (page < headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleOpenTemplate = (id:num) => {
    if (openTemplate.includes(id)) {
      setOpenTemplate([])
    } else setOpenTemplate([id])
  }
  const handleClickPin = (isPin, id) => {
    if (!isPin) {
      dispatch(createPinWhatsappTemplate({ whatsapp_template_id: id }))
    } else {
      dispatch(deletePinWhatsappTemplate(isPin))
    }
  }

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(setDefDataWhatsapp({ page, perPage }))
  }, [perPage, page])

  useEffect(() => {
    if (defData) {
      dispatch(getWhatsappTemplates({ page, perPage }))
    }
  }, [defData])

  return (
    <div className={mainCssClass}>

      { !templatesForm && (
        <div className={`${mainCssClass}_create`}>
          <Typography.Title level={3}>
            Мои шаблоны cообщений
          </Typography.Title>

          {loading && <Preloader size="md" />}
          {!loading && (
            <div className={`${mainCssClass}_create_action`}>
              <div className={`${mainCssClass}__pagination-container`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  control={control}
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={headers && page >= headers?.total ? '#BFC5C0' : '#404542'} />
                </div>
              </div>

            </div>
          )}

          {!loading
                        && (
                          <div className={`${mainCssClass}_create_scrollContainer`}>
                            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
                              <div className={`${mainCssClass}_create_templates-list`}>
                                {
                                  templates?.map((item) => (
                                    <div
                                      className={`${mainCssClass}_create_templates-list_item`}
                                      key={item.id}
                                    >
                                      <div className={`${mainCssClass}_create_templates-list_item_main`}>
                                        <div className={`${mainCssClass}_create_templates-list_item_text`}>
                                          <Star
                                            className={`${mainCssClass}_create_templates-list_item_text_star`}
                                            onClick={() => handleClickPin(item.is_pinned, item.id)}
                                            color={
                                              item.is_pinned ? '#FFD608' : ''
                                            }
                                          />
                                          <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                                            {item.name}
                                          </Typography.Text>
                                        </div>
                                      </div>
                                      {openTemplate.includes(item.id) && (
                                        <div>
                                          <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                                            Текст сообщения
                                          </Typography.Text>
                                          {' '}
                                          <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                                            {item.content}
                                          </Typography.Text>
                                        </div>
                                      )}

                                    </div>
                                  ))
                                }

                              </div>
                            </NewScrollContainer>
                          </div>
                        )}
        </div>
      )}

      {templatesForm
                && <WhatsTemplatesForm />}
    </div>
  )
}
export default memo(withErrorBoundary(MyTemplates))
