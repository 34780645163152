/* eslint-disable react/function-component-definition */
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Typography, Radio } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isEqual, isInteger } from 'lodash'
import { useParams } from 'react-router'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import Excel from '../../../common/svg/Excel'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import VacanciesTable from './VacanciesTable'
import {
  exportCSV,
  getDictionaries,
  getVacancies,
  setDefDataVacancy,
  setDuplicateStatusOpen,
  setOpenModalChangeStatus,
  setOpenModalEditVacancy
} from '../actions'
import {
  selectActualVacanciesCount,
  selectDuplicateStatusOpen,
  selectMineVacanciesCount,
  selectOpenModelChangeStatus,
  selectResultVacanciesCount,
  selectLoading,
  selectVacancies,
  selectSelectedId,
  selectDictionaryOptionsByName,
  selectSelectedVacancy,
  selectSelectedVacancyEdit,
  selectEditVacancyModal,
  selectDefDataVacancy,
  selectHeaders
} from '../selectors'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ChangeStatusVacancyModal from '../../../features/vacancyModals/components/ChangeStatusVacancyModal'
import DuplicateVacancyModal from '../../../features/vacancyModals/components/DuplicateVacancyModal'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { optionsPagination } from '../../../common/components/options'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import {
  getFilters,
  openFilters,
  setCountFilters,
  setDefFilters,
  setDeleteFilterByKey,
  setFilters,
  setFiltersLabels
} from '../../../features/filters/actions'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import {
  selectCountFilters,
  selectDefFilters,
  selectDeleteFilterKey,
  selectFilterString,
  selectLabelsFilter
} from '../../../features/filters/selectors'
import CreateVacancyModal from '../../../features/vacancyModals/components/CreateVacancyModal'
import ControlButton from '../../../common/components/Buttons/Button'
import { resetAllCandidate } from '../../ManagamentCandidates/actions'
import { setDefStringFunnel } from '../../Funnel/actions'
import { resetAllInterview } from '../../ManagementInterviews/actions'
import { resetAllRequest } from '../../ManagementRequests/actions'
import Search from '../../../common/svg/Search'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'

import './ManagementVacancies.scss'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ResponsiveMenuFilters from '../../../common/components/ResponsiveMenu/ResponsiveMenu'
import { formValuesArray } from '../../../features/filters/components/Filters'
import { objectToQueryString } from '../../../features/filters/utils'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const mainCssClass = getCssClassName('management-vacancies')
const ManagementVacancies: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()

  const defDataVacancy = useSelector(selectDefDataVacancy)
  const [requestStatus, setRequestStatus] = useState<string>(
    defDataVacancy?.status || '&filter[status][in][]=137&filter[status][in][]=138'
  )
  const vacancies = useSelector(selectVacancies)
  const [page, setPage] = useState<number>(defDataVacancy?.page || 1)
  const resultCount = useSelector(selectResultVacanciesCount)
  const mineCount = useSelector(selectMineVacanciesCount)
  const actualCount = useSelector(selectActualVacanciesCount)
  const filtersCount = useSelector(selectCountFilters)
  const changeStatus = useSelector(selectOpenModelChangeStatus)
  const duplicateStatus = useSelector(selectDuplicateStatusOpen)
  const selectedVacancyId = useSelector(selectSelectedId)
  const loading = useSelector(selectLoading)
  const selectVacancyById = useSelector(selectSelectedVacancy)
  const filtersString = useSelector(selectFilterString)
  const vacancy = useSelector(selectSelectedVacancyEdit)
  const vacancyId = useSelector(selectSelectedId)
  const modalEdit = useSelector(selectEditVacancyModal)
  const { pageId } = useParams()
  const defFilters = useSelector(selectDefFilters)
  const headers = useSelector(selectHeaders)
  const filterKey = useSelector(selectDeleteFilterKey)
  const filterLabels = useSelector(selectLabelsFilter)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const filteredFilterLabel = useMemo(() => filterLabels.filter((item) => item.value.length > 0), [filterLabels])
  const { rbacControl, download_access } = useEntityContext()
  const vacancyStatusOptions = useSelector(selectDictionaryOptionsByName('vacancyStatus'))
  const { page: pageVacancy, filters: filtersVacancy, perPage: perPageVacancy } = defDataVacancy ?? {}
  const { control, watch, getValues, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataVacancy?.perPage || 10,
      globalSearch: defDataVacancy?.search || ''
    }
  })

  type TFormValues = {
    perPagesCount: number
    globalSearch: string
    status: number
  }

  const perPagesCount = watch('perPagesCount')
  const maxPage = useMemo(() => {
    if (headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])
  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handleOpenChangeStatusCancel = () => {
    dispatch(setOpenModalChangeStatus(false))
  }
  const handleDuplicateStatusCancel = () => {
    dispatch(setDuplicateStatusOpen(false))
  }

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handleChangeStatus = (e) => {
    setPage(1)
    setRequestStatus(e.target.value)
  }

  const handleCancelEdit = () => {
    dispatch(setOpenModalEditVacancy(false))
  }

  const handleExportCSV = () => {
    dispatch(
      exportCSV({
        has_head: true,
        filter: watch('globalSearch')
          ? `${filtersString || ''}&search=${globalSearch}${requestStatus || ''}`
          : `${filtersString || ''}${requestStatus || ''}`
      })
    )
  }

  const globalSearch = watch('globalSearch')

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const handleClickSearch = () => {
    if (defDataVacancy) {
      dispatch(
        setDefDataVacancy({
          ...defDataVacancy,
          search: globalSearch,
          filters: `${filtersString || ''}${globalSearch ? `&search=${globalSearch}` : ''}${
            filtersString.includes('status') ? '' : requestStatus
          }`
        })
      )
    }
  }

  const handleKeyDown = (event: any) => {
    if (defDataVacancy) {
      if (event.key === 'Enter') {
        handleClickSearch()
      }
    }
  }

  const handleBlurSearch = () => {
    if (!globalSearch && defDataVacancy) {
      dispatch(
        setDefDataVacancy({
          ...defDataVacancy,
          search: globalSearch,
          filters: `${filtersString || ''}${globalSearch ? `&search=${globalSearch}` : ''}${
            filtersString.includes('status') ? '' : requestStatus
          }`
        })
      )
    }
  }

  const handleDeleteSearch = () => {
    if (defDataVacancy) {
      dispatch(
        setDefDataVacancy({
          ...defDataVacancy,
          search: '',
          filters: `${filtersString || ''}${filtersString.includes('status') ? '' : requestStatus}`
        })
      )
    }
  }

  const resetValueByKey = (key: string) => {
    const values = JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
    const valuesWithoutDate = { ...values, [key]: Array.isArray(values[key]) ? [] : null }
    const currentFormValuesUpdate = filterLabels.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${pageId || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${pageId || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(pageId || 'candidates', objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: pageId === 'event-funnel' }))
    dispatch(
      setDefFilters({
        type: pageId || 'candidates',
        filter: valuesWithoutDate,
        defFilterString: objectToQueryString(valuesWithoutDate, pageId === 'event-funnel')
      })
    )
  }

  useEffect(() => {
    dispatch(getDictionaries(['vacancyStatus']))

    if (pageId === defFilters?.type && defFilters?.defFilterString) {
      localStorage.setItem(defFilters.type, defFilters.defFilterString)
      localStorage.setItem(`def${defFilters.type}`, JSON.stringify(defFilters.filter))
      dispatch(setFilters(defFilters?.defFilterString))
    } else if (
      pageId &&
      (localStorage?.getItem(pageId) || localStorage?.getItem(pageId) === '') &&
      (!defFilters?.type || defFilters?.type !== pageId)
    ) {
      dispatch(setFilters(localStorage.getItem(pageId)))

      let parsedFilter
      try {
        parsedFilter = localStorage.getItem(`def${pageId}`) ? JSON.parse(localStorage.getItem(`def${pageId}`)) : ''
      } catch (parseError) {
        console.error('Error parsing defFilter:', parseError)
        parsedFilter = ''
      }

      dispatch(setDefFilters({ type: pageId, defFilterString: localStorage.getItem(pageId), filter: parsedFilter }))
    } else {
      dispatch(setFilters(''))
      dispatch(setDefFilters(null))
    }

    dispatch(resetAllInterview())
    dispatch(resetAllRequest())
    dispatch(resetAllCandidate())
    dispatch(setDefStringFunnel(''))
  }, [filtersString, pageId])

  useEffect(() => {
    if (localStorage?.getItem(pageId) && pageId === 'vacancies') {
      dispatch(
        setDefDataVacancy({
          page,
          perPage: perPagesCount,
          filters: defDataVacancy?.search
            ? `${localStorage.getItem(pageId)}&search=${defDataVacancy?.search}${
                filtersString.includes('status') ? '' : requestStatus
              }`
            : `${localStorage.getItem(pageId)}${localStorage.getItem(pageId).includes('status') ? '' : requestStatus}`,
          search: defDataVacancy?.search || '',
          status: requestStatus
        })
      )
      if (filtersString.includes('status')) {
        setRequestStatus('')
      }
    } else if (!localStorage.getItem(pageId) && defDataVacancy?.search) {
      dispatch(
        setDefDataVacancy({
          page,
          perPage: perPagesCount,
          filters: `&search=${defDataVacancy?.search}${requestStatus}`,
          search: defDataVacancy?.search || '',
          status: requestStatus
        })
      )
      if (!requestStatus) {
        setRequestStatus('&filter[status][in][]=137&filter[status][in][]=138')
      }
    } else {
      dispatch(
        setDefDataVacancy({
          page,
          perPage: perPagesCount,
          filters: requestStatus,
          search: defDataVacancy?.search || '',
          status: requestStatus
        })
      )
      if (!requestStatus) {
        setRequestStatus('&filter[status][in][]=137&filter[status][in][]=138')
      }
    }
  }, [page, perPagesCount, filtersString, requestStatus, pageId])

  useEffect(() => {
    if (pageVacancy && perPageVacancy) {
      dispatch(getVacancies({ page: pageVacancy, perPage: perPageVacancy, filters: filtersVacancy || '' }))
    }
  }, [pageVacancy, perPageVacancy, filtersVacancy])

  useEffect(() => {
    if (localStorage.getItem(`def${pageId}`)) {
      try {
        const parsedFilters = JSON.parse(localStorage.getItem(`def${pageId}`))
        if (!isEqual(parsedFilters, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFilters).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item !== false))
                .length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing filters:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(setFilters(''))
    },
    []
  )

  useEffect(() => {
    if (localStorage.getItem(`${pageId}-labels`) && pageId === 'vacancies') {
      try {
        const parsedLabels = JSON.parse(localStorage.getItem(`${pageId}-labels`))
        dispatch(setFiltersLabels(parsedLabels))
      } catch (parseError) {
        console.error('Error parsing filters labels:', parseError)
        dispatch(setFiltersLabels(formValuesArray))
      }
    } else {
      dispatch(setFiltersLabels(formValuesArray))
    }
  }, [pageId])

  useEffect(() => {
    if (filterKey) {
      resetValueByKey(filterKey)
      dispatch(setDeleteFilterByKey(''))
    }
  }, [filterKey])

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//__________________________Удалить после НГ*/}
          <ChristmasIcon />
          {/*//___________________________*/}
          <Typography.Title level={2}>Вакансии</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <ControlButton icon="none" size="large" typeButton="secondary" className="button" onClick={handleFiltersOpen}>
            <FiltersIcon />
            Фильтры
            {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
          </ControlButton>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <Radio.Group value={requestStatus} onChange={handleChangeStatus} className={`${mainCssClass}__tabs`}>
              <Radio.Button value="&filter[status][in][]=137&filter[status][in][]=138">
                Актуальные&nbsp;
                {/* {actualCount || ''} */}
              </Radio.Button>
              <Radio.Button value="&filter[status][in][]=640">
                Архив&nbsp;
                {/* {mineCount || ''} */}
              </Radio.Button>
            </Radio.Group>

            <div className={`${mainCssClass}__search-container_search`}>
              <InputSearchFormControl
                className={`${mainCssClass}__global-search`}
                name="globalSearch"
                control={control}
                placeholder="Название вакансии"
                onKeyDown={handleKeyDown}
                onBlur={handleBlurSearch}
                handleDelete={handleDeleteSearch}
              />
              <ControlButton
                typeButton="primary"
                className={`${mainCssClass}__search-container_btn button`}
                icon="left"
                onClick={handleClickSearch}
              >
                <Search />
                Поиск
              </ControlButton>{' '}
            </div>
          </div>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <Typography.Title level={5} className={`${mainCssClass}__title`}>
                Результат:&nbsp;
                {headers?.totalCount || 'неизвестно'}
              </Typography.Title>
              {!!headers && !!download_access?.vacancies && (
                <Button type="ghost" icon={<Excel />} className={`${mainCssClass}__excel`} onClick={handleExportCSV}>
                  {' '}
                  Выгрузить в Excel{' '}
                </Button>
              )}
            </div>
            {!!headers && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  control={control}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                />
                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM
                    className="arrowCursor"
                    size="1"
                    onClick={handlePreviousPage}
                    fill={page === 1 ? '#BFC5C0' : '#404542'}
                  />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM
                    className="arrowCursor"
                    size="1"
                    onClick={handleNextPage}
                    fill={page === maxPage ? '#BFC5C0' : '#404542'}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <ResponsiveMenuFilters items={filteredFilterLabel} className={`${mainCssClass}__menu-labels`} />
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <VacanciesTable vacancies={vacancies || []} className={`${mainCssClass}__table`} />
            </NewScrollContainer>
          </div>
          <DuplicateVacancyModal
            open={duplicateStatus}
            vacancy={selectVacancyById}
            className={`${mainCssClass}__modals`}
            onCancel={handleDuplicateStatusCancel}
          />
          {changeStatus && (
            <ChangeStatusVacancyModal
              open={changeStatus}
              vacancyId={selectedVacancyId}
              className={`${mainCssClass}__modals`}
              onCancel={handleOpenChangeStatusCancel}
              options={vacancyStatusOptions}
            />
          )}

          {modalEdit && (
            <CreateVacancyModal
              open={modalEdit}
              vacancy={vacancy}
              vacancyId={vacancyId}
              editMode
              isVacancyTable
              onCancel={handleCancelEdit}
            />
          )}
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ManagementVacancies))
