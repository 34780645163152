import React, {
  memo, useEffect, useMemo, useRef, useState
} from 'react'
import {
  YMaps, Map as YandexMap, Placemark, Clusterer
} from 'react-yandex-maps'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import humanIcon from '../../../images/manOnMap.png'
import {
  setFilteredRequests,
  setZoomCoordinates,
  setSelectedRequest,
  setFilteredPreviousRequests,
  getFilteredRequests,
  getSlicedRequests,
  setMapBounds, setPage, setSlicedRequests
} from '../actions'
import {
  selectDictionaryOptionsByName,
  selectFilter,
  selectLoading, selectPage, selectRequests, selectSelectedRequest, selectZoomCoordinates
} from '../selectors'
import FiltersMap from './FiltersMap'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getColorOnRequest } from '../utils'
import { selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import { selectCandidateCoordinates } from '../../../compositions/candidate/selectors'

function MapComponent({ isEdit, className }: any) {
  const [mapCards, setMapCards] = useState([])
  const [needZoom, setNeedZoom] = useState<boolean>(false)
  const [notNeedZoom, setNotNeedZoom] = useState<boolean>(false)
  const filters = useSelector(selectFilter)
  const page = useSelector(selectPage)
  const zoomCoordinates = useSelector(selectZoomCoordinates)
  const loading = useSelector(selectLoading)
  const selectedPoint = useSelector(selectSelectedRequest)
  const requests = useSelector(selectRequests)
  const userInfo = useSelector(selectUserInfo)
  const candidateCoordinates = useSelector(selectCandidateCoordinates)
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const mapRef = useRef(null)
  const dispatch = useDispatch()
  const mapState = useMemo(() => {
    if (candidateCoordinates && (filters === '&union[without_store]=false' || !filters)) {
      return {
        center: candidateCoordinates,
        zoom: 10
      }
    }
    if (userInfo?.city && !filters && userInfo?.city?.latitude && userInfo?.city?.longitude) {
      return {
        center: [userInfo?.city?.latitude, userInfo?.city?.longitude],
        zoom: 10
      }
    }
    return {
      center: [55.751574, 37.573856],
      zoom: 10
    }
  }, [userInfo, filters, candidateCoordinates, selectedPoint])
  const [stateCard, setStateCard] = useState(mapState)

  // Обработчик события изменения границ видимой области карты
  const handleMapBoundsChange = (event) => {
    const newBounds = event.get('newBounds')
    if (!isEqual(newBounds, stateCard.bounds)) {
      if (page !== 1) {
        dispatch(setPage(1))
      }
      dispatch(setMapBounds(newBounds))
      setStateCard((prevState) => ({ ...prevState, bounds: newBounds }))
    }
  }

  const filterAndSetRequests = (bounds) => {
    if (!bounds) return

    const [topLeft, bottomRight] = bounds
    const [topLat, leftLon] = topLeft
    const [bottomLat, rightLon] = bottomRight
    const filtered = requests.filter((point) => {
      const lat = point.store.lattitude
      const lon = point.store.longtitude
      return lat <= bottomLat && lat >= topLat && lon >= leftLon && lon <= rightLon
    })
    // Удаление дубликатов по координатам
    if (filtered.length > 300) {
      const uniquePoints = new Map()
      filtered.forEach((point) => {
        const key = `${point.store.lattitude}-${point.store.longtitude}`
        if (!uniquePoints?.has(key)) {
          uniquePoints.set(key, point)
        }
      })

      // Преобразование обратно в массив и обрезка если нужно
      const finalPoints = Array.from(uniquePoints.values())
        .slice(0, 300)

      setMapCards(finalPoints)
    } else setMapCards(filtered)
  }

  useEffect(() => {
    if (mapRef.current && !loading) {
      mapRef?.current?.setCenter(zoomCoordinates, 18)
    }
  }, [zoomCoordinates, loading])

  useEffect(() => {
    if (!loading && requests) {
      const initialBounds = stateCard.bounds || [[55.55, 37.35], [55.95, 37.85]]
      filterAndSetRequests(initialBounds)
    }
  }, [loading, stateCard, requests])

  useEffect(() => {
    setNotNeedZoom(false)
  }, [filters])

  useEffect(() => {
    if (filters !== '&union[without_store]=false' && mapCards?.length === 0 && requests?.length > 0 && !notNeedZoom) {
      setNotNeedZoom(true)
      if (userInfo?.city?.latitude && userInfo?.city?.longitude && mapRef.current && !zoomCoordinates?.length) {
        mapRef.current.setCenter([userInfo?.city?.latitude, userInfo?.city?.longitude], 18)
      }
    }
  }, [filters, zoomCoordinates, requests, mapCards, notNeedZoom])

  useEffect(() => {
    if (selectedPoint) {
      setMapCards([selectedPoint])
      if (selectedPoint && selectedPoint?.store?.lattitude && selectedPoint?.store?.longtitude && mapRef.current) {
        mapRef.current.setCenter([selectedPoint?.store?.lattitude, selectedPoint?.store?.longtitude], 18)
      }
    }
  }, [selectedPoint])

  return (
    <div
      className={className}
    >
      {loading ? (<Preloader />) : (
        <YMaps query={{  apikey:'467ef68b-bad1-405c-82df-71ed4d4bd27a' }} key="7ce43303-2a84-4008-ac2f-13e8916ed7c6">

          <YandexMap modules={['geoObject.addon.hint']} defaultState={stateCard} width="100%" height="100%" onBoundsChange={handleMapBoundsChange} instanceRef={(ref) => (mapRef.current = ref)}>
            <Clusterer>
              {!!mapCards?.length && mapCards.map((point) => (
                <Placemark
                  key={`${point.id}__${point.name}`}
                  geometry={[point?.store?.lattitude, point?.store?.longtitude]}
                  options={{
                    iconColor: getColorOnRequest(point?.updated_at, point.priority_id, priorityOptions)
                  }}
                  properties={{
                    hintContent: `${point.name}`
                  }}
                  onClick={() => {
                    if (point?.id === selectedPoint?.id) {
                      dispatch(getSlicedRequests({
                        filters, isEdit: !!isEdit, bounds: stateCard.bounds || [[55.55, 37.35], [55.95, 37.85]], page: 1
                      }))
                      dispatch(setSelectedRequest(null))
                    } else {
                      dispatch(setSelectedRequest(point))
                      dispatch(setSlicedRequests([point]))
                    }
                  }}
                />
              ))}
            </Clusterer>
            {candidateCoordinates && !!candidateCoordinates.length
            && (

              <Placemark
                geometry={candidateCoordinates} // Здесь указываем координаты человечка
                options={{
                  iconLayout: 'default#image',
                  // Своё изображение иконки метки.
                  iconImageHref: humanIcon,
                  // Размеры метки.
                  iconImageSize: [30, 30],
                  // Смещение левого верхнего угла иконки относительно
                  // её "ножки" (точки привязки).
                  iconImageOffset: [-5, -38]
                }}
                properties={{
                  hintContent: 'адрес кандидата'
                }}
              />
            )}

          </YandexMap>
        </YMaps>
      )}

      <div style={{
        position: 'absolute',
        top: '10px',
        right: '30px',
        zIndex: 1
      }}
      >
        {' '}
        <FiltersMap />
      </div>
    </div>
  )
}

export default memo(MapComponent)
