import React, {
  FC, memo
} from 'react'
import { Typography } from 'antd'

import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Archive from '../../../../common/svg/Archive'
import EditSvg from '../../../../common/svg/EditSvg'
import InterviewEvent from '../../../../common/svg/InterviewEvent'
import RejectEvent from '../../../../common/svg/RejectEvent'
import CandidateAddEvent from '../../../../common/svg/CandidateAddEvent'
import RequestAddEvent from '../../../../common/svg/RequestAddEvent'
import AddTask from '../../../../common/svg/AddTask'
import СommentAddEvent from '../../../../common/svg/СommentAddEvent'
import HistoryProcessed from '../../../../common/svg/HistoryProcessed'

import Responded from '../../../../common/svg/Responded'
import './IconActionHistory.scss'
import HistoryResponded from '../../../../common/svg/HistoryResponded'
import AvatarSizeSmall from '../../../../common/svg/AvatarSizeSmall'

type TProps = {
  action: string
  avatar: string
}
const mainCssClass = 'history-icon-action'

// eslint-disable-next-line react/function-component-definition
const IconActionHistory: FC<TProps> = ({ action, avatar }) => {
  if (action === 'candidateReject') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RejectEvent />
          </div>
        </div>
      </div>
    )
  }

  if (action === 'candidateArchive') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <Archive />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'candidateAdd' || action === 'add' ) {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <CandidateAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'edit' || action === 'candidateRemoveFromRequest' || action === 'interviewEdit' || action === 'interviewConfirmed' ||
    action === 'tagDelete' || action === 'tagAdd' || action === 'taskEdit' || action === 'internshipAdd' || action === 'internshipEdit'
  ) {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <EditSvg />
          </div>
        </div>

      </div>
    )
  }
  if (action === 'commentAdd' || action === 'commentEdit') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <СommentAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'candidateAddToRequest') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <RequestAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'interviewAdd') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <InterviewEvent />
          </div>
        </div>
      </div>
    )
  }

  if (action === 'taskAdd') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <AddTask />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'respondAdd') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <HistoryResponded />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'respondEdit') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <HistoryProcessed />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestToCandidateEdit') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <RequestAddEvent />
          </div>
        </div>
      </div>
    )
  }

  if (action === 'responded') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <Responded />
          </div>
        </div>
      </div>
    )
  }
  return <Typography.Text> Действие не определено</Typography.Text>
}
export default memo<TProps>(withErrorBoundary<TProps>(IconActionHistory))
