import {
  put, takeLatest, take, select, race
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  TTasksResponse, fetchGetTask, fetchGetTasks, fetchPatchUpdateTask, fetchPostCreateTasks
} from '../../common/api/tasks'
import {
  TRequestCreateTask, TRequestTasks, TRequestUpdateTask,
  createTask, getDictionaries, getNotifyTaskId, getTasks, openMoreInfoModal, openTaskCreateModal, setDictionaries, setNotifyTask, setSelectTask, setTasks, updateTask
} from './actions'
import { selectFilterString, selectIsArchive } from './selectors'
import { setLoadingModal, setLoadingProgress } from '../InnerRouter/actions'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { selectUserInfo } from '../InnerRouter/selectors'


const moduleName = 'Страница задач'

  type TasksResponse = AxiosResponse<TTasksResponse>

function* getTasksSaga({ payload } :PayloadAction<TRequestTasks>) {
  try {
    yield put(setLoadingProgress(true))
    let response: TasksResponse;
    ({response} = yield race({
      response: fetchGetTasks(payload),
      cancel: take(getTasks.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить задачи',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setTasks(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* getTaskSaga({ payload } :PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    let response: any;
    ({response} = yield race({
      response: fetchGetTask(payload),
      cancel: take(getNotifyTaskId.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить задачу',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setNotifyTask(response.data.DATA))
      yield put(openMoreInfoModal(true))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* createTaskSaga({ payload } :PayloadAction<TRequestCreateTask>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    // const response = yield fetchPostCreateTasks(payload)
    const {response} = yield race({response: fetchPostCreateTasks(payload), cancel: take(createTask.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать задачу',
          error: response as AxiosError
        })
      )
    } else {
      const user = yield select(selectUserInfo)
      yield put(openTaskCreateModal(false))

      if (user.id == response.data.DATA.author_id) {
        const filter = yield select(selectFilterString)
        const is_archive = yield select(selectIsArchive)
        yield put(getTasks({ filter, is_archive }))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* updateTaskSaga({ payload } :PayloadAction<TRequestUpdateTask>) {
  try {
    yield put(setLoadingProgress(true))
    const {response} = yield race({response: fetchPatchUpdateTask(payload), cancel: take(updateTask.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить задачу',
          error: response as AxiosError
        })
      )
    } else {
      const filter = yield select(selectFilterString)
      const is_archive = yield select(selectIsArchive)
      yield put(getTasks({ filter, is_archive }))
      yield put(openTaskCreateModal(false))
      yield put(setSelectTask(null))


    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'кандидаты таблица')
}

function* tasksSaga() {
  yield takeLatest(getTasks, getTasksSaga)
  yield takeLatest(createTask, createTaskSaga)
  yield takeLatest(updateTask, updateTaskSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getNotifyTaskId, getTaskSaga)
}

export default tasksSaga
