import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate, selectChatVisible } from '../../../compositions/candidate/selectors'
import { selectConnectedAsterisk, selectStatusPhone, selectSystemColor } from '../../../compositions/InnerRouter/selectors'

import TabPane = Tabs.TabPane
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { ColorPickerOptions } from '../../../common/components/ColorPicker/ColorPicker'
import { updateUser } from '../../../compositions/Systems'
import { getWhatsappTemplates } from '../utils'
import { setTemplateContent } from '../actions'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import WhatsAppTemplatesLabel from './WhatsAppTemplatesLabel'
import './HeaderChat.scss'

type TFormValues = {
  color: string
}

function HeaderChat() {
  const dispatch = useDispatch()
  const chatVisible = useSelector(selectChatVisible)
  const candidate = useSelector(selectCandidate)
  const systemColor = useSelector(selectSystemColor)
  const statusPhone = useSelector(selectStatusPhone)
  const connectedWss = useSelector(selectConnectedAsterisk)
  const [whatsappOptions, setWhatsappOptions] = useState<any>([])
  const {
    rbacControl, department
  } = useEntityContext()
  const {
    control
  } = useForm<TFormValues>({ defaultValues: { color: systemColor } })

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = useMemo(() => windowSize.width < 500 && windowSize.height < 900, [windowSize])

  const MainCSSClass = 'headerChat'

  // const handleVisibleChat = (key: string) => {
  //   dispatch(setChatVisible(key))
  // }
  // const handlePhone = () => {
  //   let newNumber = candidate?.phone?.[0]?.toString()
  //   if (newNumber.startsWith('7')) {
  //     newNumber = `8${newNumber.slice(1)}`
  //   } else {
  //     newNumber = candidate?.phone
  //   }
  //   dispatch(setDestinationNumber(newNumber))
  //   dispatch(setCandidateSelected(candidate))
  // }

  const handleSystemColor = (color : string) => {
    dispatch(updateUser({ color_system: color }))
  }

  const handleTemplateContent = (content) => {
    dispatch(setTemplateContent(content))
  }
  const whatsAppTempletesOptions = useMemo(() => {
    if (whatsappOptions.length) {
      return whatsappOptions.map((el) => ({
        label: el.is_pinned ? <WhatsAppTemplatesLabel pinLabel={el.label} />
          : el.label,
        value: el.value
      }))
    }
    return []
  }, [whatsappOptions])

  useEffect(() => {
    getWhatsappTemplates().then((data) => setWhatsappOptions(data))
  }, [])
  return (
    <div className={`${MainCSSClass}`}>

      <div className={`${MainCSSClass}_title`}>
        {/* {!isMobile
          && (
            <>
              <h4>Взаимодействие с кандидатом</h4>
              <div className={`${MainCSSClass}__tabs`}>
                <Tabs defaultActiveKey={chatVisible} onChange={(key) => handleVisibleChat(key)}>
                  <TabPane key="history" tab="История" />
                  <TabPane key="message" tab="Чат" />
                  {department !== 175 && department !== 176 && department !== 177 && (
                    <TabPane key="mail" tab="Почта" />
                  )}
                </Tabs>
              </div>
            </>
          )} */}

        {// ВОТ ЭТОТ КУСОК КОДА НЕ УДАЛЯТЬ
        }
        {/* <div className={`${MainCSSClass}_action`}>
          {(!statusPhone && connectedWss) && (
            <div className={`${MainCSSClass}_action_interaction`}>
              <h4>Звонок:</h4>
              <Button type="link" ghost icon={<PhoneSvg />} disabled={statusPhone || !connectedWss} onClick={handlePhone} />
            </div>
          )}
        </div> */}
      </div>
      {(chatVisible === 'message' || chatVisible === 'mail')
      && (
        <div className={`${MainCSSClass}_second-action`}>
          <SelectFormControl name="color" control={control} options={ColorPickerOptions} className={`${MainCSSClass}_second-action_select`} dropdownStyle={{ width: 'auto' }} onChange={(color) => handleSystemColor(color)} />
          <SearchSelectFormControlSource
            name="templates"
            control={control}
            options={whatsAppTempletesOptions}
            showSearch
            // optionFilterProp="children"
            className={`${MainCSSClass}_second-action_select-templates`}
            placeholder="Шаблон сообщений"
            onChange={(content) => handleTemplateContent(content)}
          />
        </div>
      )}
    </div>
  )
}

export default memo(withErrorBoundary(HeaderChat))
