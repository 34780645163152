import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import debounce from 'lodash/debounce'
import Required from '../../svg/Required'

import './DebounceSelectFormControl.scss'
import { TOption } from '../../../common/types/dictionaries'

const { Title } = Typography

type CombinedProps = Omit<
  UseControllerProps &
    SelectProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      fetchOptions: (search: string, id?: number) => Promise<TOption[]>
      debounceTimeout?: number
      required?: boolean
      onChange?: () => void
    },
  'isDisabled'
>

const mainCssClass = 'debounce-select'

function DebounceSelectFormControlCity({
  name,
  control,
  rules,
  fetchOptions,
  debounceTimeout = 1000,
  label,
  onChange,
  required,
  labelInValue,
  ...rest
}: CombinedProps) {
  const { field, fieldState } = useController({ name, control, rules })

  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<TOption[]>([])
  const fetchRef = useRef(0)
  const [searchValue, setSearchValue] = useState<string>('')

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const handleSelectChange = (selectedOption: TOption) => {
    if (onChange) {
      onChange()
    }
    field.onChange(selectedOption)
    setSearchValue(selectedOption.label)
  }
  const handleInputChange = (value: string) => {
    if (value) {
      setSearchValue(value)
      if (value.length > 1 && field.label !== searchValue) {
        debounceFetcher(value)
      }
    } if (searchValue.length === 1 && !value) {
      setSearchValue(value)
    }
  }

  useEffect(() => {
    fetchOptions('').then((defaultOptions) => {
      setOptions(defaultOptions)
    })
  }, [])

  return (
    <div className={mainCssClass}>
      {label && (
        <Title className="textAreaLabel" level={4}>
          {label}
          {required && <Required className="required" />}
        </Title>
      )}

      <Select
        labelInValue={labelInValue}
        showSearch
        filterOption={false}
        onSearch={handleInputChange}
        searchValue={searchValue}
        className={fieldState.error ? 'error' : ''}
        {...rest}
        defaultValue={field.value ? field.value : ''}
        value={field.value}
        options={options}
        onChange={handleSelectChange}
      />
    </div>
  )
}

export default DebounceSelectFormControlCity
