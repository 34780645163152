/* eslint-disable react/function-component-definition */
import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import CheckBig from '../../../common/svg/CheckBig'
import { updateInterview } from '../actions'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './ConfirmInterviewTableRow.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TProps = {
  id: number | string
  confirm:string
}

const mainCssClass = getCssClassName('confirm-interview-table')

function ConfirmInterviewTableRow({ id, confirm }: TProps) {
  const dispatch = useDispatch()

  const handleConfirm = () => {
    dispatch(updateInterview({
      id,
      confirm: 'confirmed'
    }))
  }

  return (
    <div className={(confirm === 'confirmed' || confirm === 'done') ? cn(mainCssClass, `${mainCssClass}__confirm`) : cn(mainCssClass, `${mainCssClass}__not-confirm`)}>
      {(confirm === 'confirmed' || confirm === 'done') ? 'Да' : 'Нет'}
      {confirm !== 'confirmed' && <CheckBig onClick={handleConfirm} />}
    </div>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ConfirmInterviewTableRow))
