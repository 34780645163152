import cn from 'classnames'

import './styles.scss'

/**
 * Интерфейс скелетона.
 */
export type SkeletonProps = {
  /**
   * Класс для компонента
   */
  className?: string;
  /**
   * Вариант отображения
   */
  variant?: 'text' | 'rect';
  /**
   * Количество элементов, по умолчанию 1.
   */
  rows?: number;
  /**
   * Ширина скелетона,
   * когда width является массивом, свойство может установить ширину каждой строки. В противном случае  ширину для всех элементов
   */
  width?: string | number | Array<string | number>;
  /**
   * Высота скелетона,
   * когда height является массивом, свойство может установить высоту каждой строки. В противном высоту для всех элементов
   */
  height?: string | number | Array<string | number>;
  /**
   * Задает низший порог случайной ширины, в процентах
   */
  randomWidthFrom?: number;
  /**
   * Задает высший порог случайной ширины, в процентах
   */
  randomWidthTo?: number;
};

const DEFAULT_SKELETON_VARIANT = 'text'

export function Skeleton({
  rows = 1,
  variant = DEFAULT_SKELETON_VARIANT,
  width,
  height,
  className,
  randomWidthFrom,
  randomWidthTo = 100
}: SkeletonProps) {
  /**
   * Определяет ширину скелетона
   */
  function getWidth(idx: number) {
    let itemWidth: string | number | undefined = '0'

    if (!randomWidthFrom) {
      itemWidth = Array.isArray(width) ? width[idx] : width
    } else if (randomWidthFrom) {
      // Задает случайную ширину между randomWidthFrom и randomWidthTo
      itemWidth = `calc(${Math.floor(
        Math.random() * (randomWidthTo - randomWidthFrom) + randomWidthFrom
      )}%)`
    }

    return itemWidth
  }

  return (
    <>
      {Array(rows)
        .fill(null)
        .map((_, idx) => {
          const itemHeight = Array.isArray(height) ? height[idx] : height
          return (
            <span
              key={idx}
              style={{
                width: getWidth(idx),
                height: itemHeight
              }}
              className={cn([['Skeleton__root'], [`Skeleton--${variant}`]], className)}
            />
          )
        })}
    </>
  )
}
