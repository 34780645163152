import { createAction } from '@reduxjs/toolkit'
import {TProductivity, TRejectionInfo, TSourseOfCandidates} from './types'
import {
    TAnalyticsRejection,
    TRequestAnalytics,
    TRequestAnalyticsEffectivity,
    TRequestAnalyticsWithMethods
} from '../../common/api/analytics'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'

export const getSourseOfCandidates = createAction<TRequestAnalytics>('analytics/getSourseOfCandidates')
export const setSourseOfCandidates = createAction<TSourseOfCandidates[]>('systems/setSourseOfCandidates')

export const getProductivity = createAction<TRequestAnalyticsEffectivity>('analytics/getProductivity')
export const setProductivity = createAction<TProductivity[]>('systems/setProductivity')
export const setLoading = createAction<boolean>('analytics/setLoading')

export const getVertexInfo = createAction<TRequestAnalyticsWithMethods>('analytics/getVertexInfo')
export const setVertexInfo = createAction<any[]>('systems/setVertexInfo')

export const setScenario = createAction<string>('analytics/setScenario')

export const reset = createAction('analytics/reset')

export const getDictionaries = createAction<TDictionariesNames>('analytics/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('analytics/dictionaries/setDictionaries')

export type TRequestPlanFact = {
    by_vacancy: string
    filter: string
    download?: boolean
}

export type TPlanFact = {
    title: string,
    graphics: {
    label: string,
    count: number
    } [],
    labels: any
    analyticRow: any[]
}

export const getPlanFact = createAction<TRequestPlanFact>('analytics/getPlanFact')
export const setPlanFact = createAction<TPlanFact>('systems/setPlanFact')

export const setPlanFactVacancy = createAction<string>('analytics/setPlanFactVacancy')

export const getRejectionInfo = createAction<TAnalyticsRejection>('analytics/getRejectionInfo')

export const setRejectionInfo = createAction<TRejectionInfo[]>('systems/setRejectionInfo')

export type TRejectionAny = {
    labels: any
    analyticRow: any[]
}

export const setVertexMethod = createAction<string>('analytics/setVertexMethod')
