import React, {
  memo
} from 'react'
import {
  Drawer, DrawerProps
} from 'antd'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { openAsteriskDrawer } from '../../actions'
import HistoryAsterisk from '../HistoryAsterisk'
import './AsteriskDrawer.scss'
import CommonDrawer from '../../../../common/components/CommonDrawer/CommonDrawer'

const mainCssClass = getCssClassName('asterisk-drawer')

type TAsteriskDrawer = DrawerProps & {
  isMobile?: boolean
}
function AsteriskDrawer({
  open, className, isMobile
}:TAsteriskDrawer) {
  const dispatch = useDispatch()

  const handleOnClose = () => {
    dispatch(openAsteriskDrawer(false))
  }

  return (
    <CommonDrawer
      title="История звонков"
      onClose={handleOnClose}
      open={open}
      className={cn(className, mainCssClass)}
      contentWrapperStyle={isMobile ? { width: '700px' } : { width: '100%' }}
    >
      <div className={`${mainCssClass}_candidateChat`}>
        <HistoryAsterisk open={open} />
      </div>
    </CommonDrawer>
  )
}

export default memo<TAsteriskDrawer>(withErrorBoundary<TAsteriskDrawer>(AsteriskDrawer))
