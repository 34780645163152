import { createAction } from '@reduxjs/toolkit'
import { TVacancy } from 'src/common/api/vacancy'
import {
  TAddTag,
  TCandidateComments,
  TCandidateResponseUpdate,
  TComment,
  TCreateLink,
  TDATA,
  TDelTag,
  TRemoveFromRequest,
  TRequestNote,
  TStageRequest
} from './types'
import { TDictionaries, TDictionariesNames, TOption } from '../../common/types/dictionaries'
import { THistoryCandidateData, TOldHistoryCandidateData, TRequestCandidateComments } from '../../common/api/Candidate'

export const getCandidate = createAction<string>('candidateV2/getCandidate')
export const getCandidateUpdate = createAction<string>('candidateV2/getCandidateUpdate')
export const reset = createAction('candidateV2/rest')
export const setCandidate = createAction<TDATA>('candidateV2/setCandidate')
export const setLoading = createAction<boolean>('candidateV2/setLoading')
export const openCreateModal = createAction<boolean>('candidateV2/openCreateCandidate')
export const openUpdateModal = createAction<boolean>('candidateV2/openUpdateModal')

export const openSureModal = createAction<boolean>('candidateV2/openSureModal')
export const createCandidate = createAction<TDATA>('candidateV2/createCandidate')
export const deleteCandidate = createAction<string>('candidateV2/deleteCandidate')

export const updateCandidate = createAction<TCandidateResponseUpdate>('candidateV2/updateCandidate')
export const getDictionaries = createAction<TDictionariesNames>('candidateV2/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('candidateV2/dictionaries/setDictionaries')
export const createTagCandidate = createAction<TAddTag>('candidateV2/createTag')
export const delTagCandidate = createAction<TDelTag>('candidateV2/delTag')
export const createNewComment = createAction<TComment>('candidateV2/createComment')
export const updateStage = createAction<TStageRequest>('candidateV2/updateStage')
export const openArchiveModal = createAction<boolean>('candidateV2/openArchive')
export const openStatusModal = createAction<boolean>('candidateV2/openStatusModal')
export const openVacancyModal = createAction<boolean>('candidateV2/openVacancyModal')
export const openDuplicateModal = createAction<boolean>('candidateV2/openDuplicateModal')

export const createLinkCandidate = createAction<TCreateLink>('candidateV2/createLinkCandidate')
export const removeFromRequest = createAction<TRemoveFromRequest>('candidateV2/removeFromRequest')
export const createNote = createAction<TRequestNote>('candidateV2/createNote')

export const getQuestionnaire = createAction('candidateV2/getQuestionnaire')
export const setOpenModalMap = createAction<boolean>('candidateV2/setOpenModalMap')

export const setQuestionnaire = createAction<any>('candidateV2/setQuestionnaire')

export type TCandidateQuestionnaire = {
  candidate_id: number
  answers: TAnswer[]
}
export type TAnswer = {
  answer: string
  question_id: number
}
export const createQuestionnaire = createAction<TCandidateQuestionnaire>('candidateV2/createQuestionnaire')

export const setIsEditModeQuestionnaire = createAction<boolean>('candidateV2/setIsEditModeQuestionnaire')

export type TGetAllEmptySlots = {
  date: string
  user_id?: number
  isRecruiter?: boolean
  candidate_id: number
}
export const getEmptySlots = createAction<TGetAllEmptySlots>('candidateV2/getEmptySlots')

export const setLoadingSlots = createAction<boolean>('candidateV2/setLoadingSlots')
export const setEmptySlots = createAction<string[]>('candidateV2/setEmptySlots')


export type TAddInterview = {
  data : TRequestUpdateInterview
  isCandidatePage: boolean
}

export type TRequestUpdateInterview = {
    date: string
    slot: string
    questionnaire_id: number
    user_id: number
    candidate_id: number
    vacancy_id: number
    researcher: number
    interview_id?: number
    recruiter: number | null
}
export const addInterview = createAction<TAddInterview>('candidateV2/addInterview')

export const setNewInterview = createAction<boolean>('candidateV2/setNewInterview')

export type TRequestAppoint = {
  id: number
  responsible?: number | string| null
  researcher?: number | null | string
  stage_id?: string | number
}

export type TRequestStageStatusCandidate = {
  id: number
  stage_state?: number
}

export const openRecruiterModal = createAction<boolean>('candidateV2/openRecruiterModal')
export const openReseacherModal = createAction<boolean>('candidateV2/openReseacherModal')

export const appointRecruiter = createAction<TRequestAppoint>('candidateV2/appointRecruiter')
export const appointReseacher = createAction<TRequestAppoint>('candidateV2/appointReseacher')
export const appointAll = createAction<TRequestAppoint>('candidateV2/appointAll')

export const openStageStatusModal = createAction<boolean>('candidateV2/openStageStatusModal')
export const stageStatusCandidate = createAction<TRequestStageStatusCandidate>('candidateV2/stageStatusCandidate')
export const getStageStatusByStage = createAction<number>('candidateV2/getStageStatusByStage')
export const setStageStatus = createAction<any>('candidateV2/setStageStatus')

export type TDocument = {
  fileId: string
  candidateId: number
}
export const removeDocuments = createAction<TDocument>('candidateV2/removeDocuments')

export const getComments = createAction<TRequestCandidateComments>('candidateV2/getComments')
export const setComments = createAction<TCandidateComments[]>('candidateV2/setComments')

export type TRequestPatchCandidateComments = {
  id:number
  is_pinned?: boolean
  comment?: string;
}

export const updateComments = createAction<TRequestPatchCandidateComments>('candidateV2/updateComments')

export const uploudCommentFile = createAction<string>('candidateV2/uploudCommentFile')
export const setCommentsPage = createAction<number>('candidateV2/setCommentsPage')

export type TRequestCandidateHistory = {
  page: number
  perPage: number
  filter: string
  candidate_id: number
}

export const getHistoryCandidateData = createAction<TRequestCandidateHistory>('candidateV2/getHistoryCandidateData')

export const getOldHistoryCandidateData = createAction<TRequestCandidateHistory>('candidateV2/getOldHistoryCandidateData')
export const setHistoryCandidateData = createAction<THistoryCandidateData>('candidateV2/setHistoryCandidateData')

export const setOldHistoryCandidateData = createAction<TOldHistoryCandidateData>('candidateV2/setOldHistoryCandidateData')
export const openHistoryCandidateData = createAction<boolean>('candidateV2/openHistoryCandidateData')

export const openOldHistoryCandidateData = createAction<boolean>('candidateV2/openOldHistoryCandidateData')
export const setTotalCommentsPage = createAction<number>('candidateV2/setTotalCommentsPage')

export const setChatVisible = createAction<'message' | 'history' | 'mail'>('candidateV2/setChatVisible')
export const setHistoryVariable = createAction<'history' | 'oldHistory'| 'asterisk'>('candidateV2/setHistoryVisible')

export const setStageModal = createAction<boolean>('candidateV2/setStageModal')
export const setAssignModal = createAction<boolean>('candidateV2/setAssignModal')
export const setRejectModal = createAction<boolean>('candidateV2/setRejectModal')

export type TRequestPhoneFind = {
  phone?: string
  email?:string
  input_id: number
  candidate_id: number
}
export type TRequestInnFind = {
  inn?: string
  candidate_id: number
}

export const getPhoneFind = createAction<TRequestPhoneFind>('candidateV2/getPhoneFind')
export const setPhoneError = createAction<any>('candidateV2/setPhoneError')

export const getInnFind = createAction<TRequestInnFind>('candidateV2/getInnFind')
export const setInnError = createAction<any>('candidateV2/setInnError')
export const getEmailFind = createAction<TRequestPhoneFind>('candidateV2/getEmailFind')
export const setEmailError = createAction<any>('candidateV2/setEmailError')

export const setInformationModal = createAction<boolean>('candidateV2/setInformationModal')
export const setSelectedComment = createAction<TCandidateComments | null>('candidateV2/setSelectedComments')

export const openDeleteCommentModal = createAction<boolean>('candidateV2/openDeleteCommentModal')

export const deleteComment = createAction<TCandidateComments>('candidateV2/deleteComment')

export const openCommentModal = createAction<boolean>('candidateV2/openCommentModal')

export type TRequestRemoveRequestLink = {
  candidate_id: number
  request_id: number
}

export const removeRequestLink = createAction<TRequestRemoveRequestLink>('candidateV2/removeRequestLink')

export const mergeDuplicates = createAction<string>('candidateV2/mergeDuplicates')

export type TCandidateHeaders = {
  current:number,
  total:number
}

export const setHeaders = createAction<TCandidateHeaders>('candidateV2/setHeaders')
export const getCandidateDuplicate = createAction<number>('candidateV2/getCandidateDuplicate')
export const setCandidateDuplicate = createAction<any[]>('candidateV2/setCandidateDuplicate')

export const getRespondsCandidate = createAction<number>('candidateV2/getRespondsCandidate')
export const setRespondsCandidate = createAction<any[]>('candidateV2/setRespondsCandidate')

export type TRequestRespondHandler = {
  candidate_id: number,
  type: string
}

export const respondHandler = createAction<TRequestRespondHandler>('candidateV2/respondHandler')
export const setIsLoadingRespondHandler = createAction<boolean>('candidateV2/setIsLoadingRespondHandler')

export const getCandidateMergeDuplicate = createAction<number>('candidateV2/getCandidateMergeDuplicate')
export const setCandidateMergeDuplicate = createAction<any[]>('candidateV2/setCandidateMergeDuplicate')

export const openMergeDuplicateModal = createAction<boolean>('candidateV2/openMergeDuplicateModal')

export const getHistoryAsterisk = createAction<number>('candidateV2/getHistoryAsterisk')
export const setHistoryAsterisk = createAction<any[]>('candidateV2/setHistoryAsterisk')

export const openTasksModal = createAction<boolean>('candidateV2/openTasksModal')

export const showLimitationStageCandidateModal = createAction<boolean>('candidateV2/showLimitationStageModal')

export const setMessageLimitationCandidate = createAction<string>('candidateV2/setMessageLimitationCandidate')
export const setCandidateCoordinates = createAction<any>('candidateV2/setCandidateCoordinates')
export const getStagesListing = createAction('candidateV2/getStagesListing')
export const setStageListing = createAction<TOption[]>('candidateV2/setStageListing')

export const openResultInternshipModal = createAction<boolean>('candidateV2/openResultInternshipModal')

export const getCandidateDocument = createAction<number>('candidateV2/getCandidateDocument')
export const setCandidateDocument = createAction<any[]>('candidateV2/setCandidateDocument')

export const getCandidateQuestionnaire = createAction<number>('candidateV2/getCandidateQuestionnaire')
export const setCandidateQuestionnaire = createAction<any>('candidateV2/setCandidateQuestionnaire')


export const getHistoryCandidateV2 = createAction<TRequestCandidateHistory>('candidateV2/getHistoryCandidateV2')
export const setHistoryCandidateV2 = createAction<any>('candidateV2/setHistoryCandidateV2')
// export const setHeadersCandidateV2

export const openCheckDocModal = createAction<any>('candidateV2/openCheckDocModal')

export const openChatDrawer = createAction<boolean>('candidateV2/openChatDrawer')

export const openAsteriskDrawer = createAction<boolean>('candidateV2/openAsteriskDrawer')

export const openCommunicationDrawer = createAction<boolean>('candidateV2/openCommunicationDrawer')

export const openEditCandidateDrawer = createAction<boolean>('candidateV2/openEditCandidateDrawer')

export const setUpdateCandidateMode = createAction<boolean>('candidateV2/setUpdateCandidateMode')

