import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getDictionaries, updateStatusCandidate } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './ChangeStatusModal.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'change-status-modal'

type TProps = {
  options: TOption[]
  candidateId?: number | null | string
  isCandidateTable: boolean
} & ModalProps
function ChangeStatusModal({
  onOk, open, className, onCancel, options, candidateId, isCandidateTable
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()

  type TFormValues = {
    status: number
  }

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
      reset()
    }
  }

  const handleSubmit = useCallback(() => {
    if (candidateId) {
      const data = watch('status')
      if (data) {
        dispatch(updateStatusCandidate({ id: candidateId, ...getValues(), isCandidateTable }))
      }
    }
  }, [candidateId, isCandidateTable])

  useEffect(() => {
    if (isCandidateTable) {
      dispatch(getDictionaries(['candidateStatus']))
    }
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit}
      onCancel={handleModalClose}
      title="Изменить статус"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Сохранить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        <SelectFormControl
          name="status"
          control={control}
          label="Выберите статус"
          options={options}
          required
          rules={{ required: true }}
        />
      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ChangeStatusModal))
