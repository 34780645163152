import { PayloadAction } from '@reduxjs/toolkit'
import {
  put, select, spawn, takeLatest, cancelled, race, take, call
} from 'redux-saga/effects'
import axios, { AxiosError } from 'axios'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  setLoading,
  getDictionaries,
  setInternship,
  getInternship,
  TUpdateInternship,
  updateInternships,
  setInternshipModal,
  setDictionaries,
  TInternshipRequest,
  TUpdateInternshipDate,
  updateInternshipDate,
  openInternshipDateModal
} from './actions'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { selectInternship, selectSearchString } from './selectors'
import {
  fetchGetInternship,
  fetchPatchDateInternship,
  fetchPatchInternship,
} from '../../common/api/internship'

import { setLoadingModal, setLoadingProgress } from '../InnerRouter/actions'

const moduleName = 'Стажировка'

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'Компонент стажировки')
}

function* getInternshipSaga({ payload }: PayloadAction<TInternshipRequest>) {
  const cancelTokenSource = axios.CancelToken.source()
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))

    const { response, cancel } = yield race({
      response: call(fetchGetInternship, payload, cancelTokenSource.token),
      cancel: take(getInternship.type)
    })

    if (response) {
      if (response.data.ERR) {
        showCriticalNotification(
          getErrorNotification({
            moduleName,
            text: 'Не удалось загрузить стажировки',
            error: response as AxiosError
          })
        )
      } else if (Number(payload.page) > 0) {
        const internships = yield select(selectInternship)
        const changedInternships = internships.calendar_list?.map((item) => {
          response.data.DATA.calendar_list.forEach((el) => {
            if (item.date === el.date) {
              const newItems = [...item.items, ...el.items]
              item = {
                ...item, items: newItems
              }
            }
          })
          return item
        })
        yield put(setInternship({ calendar_list: changedInternships }))
      } else {
        yield put(setInternship(response.data.DATA))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      cancelTokenSource.cancel('Запрос был отменен')
      // Запрос был отменен
      yield put(setLoading(false))
      yield put(setLoadingProgress(false))
    } else {
      yield put(setLoading(false))
      yield put(setLoadingProgress(false))
    }
  }
}
function* updateInternshipSaga({ payload } :PayloadAction<TUpdateInternship>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPatchInternship(payload.data), cancel: take(updateInternships.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить стажировку',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setInternshipModal(false))
      yield spawn(getInternshipSaga, getInternship({ page: 0, date: payload.date, search: payload.search }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateInternshipDateSaga({ payload } :PayloadAction<TUpdateInternshipDate>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPatchDateInternship(payload.data), cancel: take(updateInternships.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить стажировку',
          error: response as AxiosError
        })
      )
    } else {
      const search = yield select(selectSearchString)
      yield put(openInternshipDateModal(false))
      yield spawn(getInternshipSaga, getInternship({ page: 0, date: payload.date, search }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* internshipSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(updateInternships, updateInternshipSaga)
  yield takeLatest(getInternship, getInternshipSaga)
  yield takeLatest(updateInternshipDate, updateInternshipDateSaga)
}

export default internshipSaga
