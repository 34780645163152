import React from 'react'
import cn from 'classnames'
import { Typography, Progress, Button, Tooltip } from 'antd'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  tooltip?: boolean
  onClick?: () => void
  isDisabled: boolean
}

const mainCssClass = 'rejectSvg'

function Redo({ className, size = '1', onClick, tooltip, isDisabled }: TSvgProps) {
  return (
    <>
      {tooltip ? (
        <Tooltip placement="bottomRight" title="Очистить данное поле">
          <Button>
            <svg
              className={cn(
                className,
                mainCssClass,
                { s_svg: size === '0.5' },
                { m_svg: size === '1' },
                { l_svg: size === '2' },
                { xl_svg: size === '4' }
              )}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              {...(!isDisabled && { onClick })}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 2C19.5523 2 20 2.44772 20 3V8C20 8.55228 19.5523 9 19 9H14C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7H16.8991C16.1357 6.25195 15.2069 5.68467 14.1808 5.34829C12.7582 4.88195 11.2233 4.88397 9.80198 5.35403C8.38066 5.82409 7.14753 6.7374 6.28351 7.95996C5.41955 9.18244 4.97038 10.6498 5.00174 12.1466C5.03309 13.6434 5.54336 15.0906 6.45777 16.2759C7.37225 17.4612 8.64234 18.3221 10.0821 18.7322C11.5219 19.1423 13.0553 19.0801 14.4571 18.5546C15.8589 18.0291 17.0553 17.068 17.8706 15.8125C18.1714 15.3493 18.7907 15.2177 19.2539 15.5184C19.7171 15.8192 19.8487 16.4386 19.5479 16.9018C18.4996 18.516 16.9614 19.7517 15.1591 20.4273C13.3568 21.1029 11.3853 21.183 9.53415 20.6556C7.68296 20.1283 6.04995 19.0215 4.87426 17.4976C3.69851 15.9736 3.04248 14.1128 3.00217 12.1885C2.96187 10.2642 3.53931 8.37758 4.65023 6.80567C5.7611 5.23382 7.34656 4.05954 9.174 3.45518C11.0014 2.85081 12.9748 2.84822 14.8038 3.4478C15.992 3.83732 17.0787 4.46765 18 5.29166V3C18 2.44772 18.4477 2 19 2Z"
                fill="#404542"
              />
            </svg>
          </Button>
        </Tooltip>
      ) : (
        <svg
          className={cn(
            className,
            mainCssClass,
            { s_svg: size === '0.5' },
            { m_svg: size === '1' },
            { l_svg: size === '2' },
            { xl_svg: size === '4' }
          )}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClick}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 2C19.5523 2 20 2.44772 20 3V8C20 8.55228 19.5523 9 19 9H14C13.4477 9 13 8.55228 13 8C13 7.44772 13.4477 7 14 7H16.8991C16.1357 6.25195 15.2069 5.68467 14.1808 5.34829C12.7582 4.88195 11.2233 4.88397 9.80198 5.35403C8.38066 5.82409 7.14753 6.7374 6.28351 7.95996C5.41955 9.18244 4.97038 10.6498 5.00174 12.1466C5.03309 13.6434 5.54336 15.0906 6.45777 16.2759C7.37225 17.4612 8.64234 18.3221 10.0821 18.7322C11.5219 19.1423 13.0553 19.0801 14.4571 18.5546C15.8589 18.0291 17.0553 17.068 17.8706 15.8125C18.1714 15.3493 18.7907 15.2177 19.2539 15.5184C19.7171 15.8192 19.8487 16.4386 19.5479 16.9018C18.4996 18.516 16.9614 19.7517 15.1591 20.4273C13.3568 21.1029 11.3853 21.183 9.53415 20.6556C7.68296 20.1283 6.04995 19.0215 4.87426 17.4976C3.69851 15.9736 3.04248 14.1128 3.00217 12.1885C2.96187 10.2642 3.53931 8.37758 4.65023 6.80567C5.7611 5.23382 7.34656 4.05954 9.174 3.45518C11.0014 2.85081 12.9748 2.84822 14.8038 3.4478C15.992 3.83732 17.0787 4.46765 18 5.29166V3C18 2.44772 18.4477 2 19 2Z"
            fill="#404542"
          />
        </svg>
      )}
    </>
  )
}

export default Redo
