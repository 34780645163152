/* eslint-disable react/jsx-closing-tag-location */
import React, {
  memo, useMemo
} from 'react'
import { Tag, Typography, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  openEditCandidateDrawer,
  openResultInternshipModal,
  openTasksModal,
  setInformationModal,
  setUpdateCandidateMode
} from '../../../actions'

import { navigateTo } from '../../../../../common/actions/navigate'
import {
  selectCandidate,
  selectCommentModal,
  selectDeleteCommentModal,
  selectInformationModal,
  selectLabelById,
  selectResultInternshipModal,
  selectTasksModal
} from '../../../selectors'
import withErrorBoundary from '../../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { DetailsList } from '../../../../../common/components/DetailsList'
import { useEntityContext } from '../../../../../common/context/EntityContextProvider'
import { formatDateTime } from '../../../utils'
import { setPreviousPage } from '../../../../InnerRouter/actions'
import { DATE_DOTS_FORMAT } from '../../../../../common/utils/dateFormats'
import ModalDeleteComment from '../../ModalDeleteComment'
import ModalNewComment from '../../ModalNewComment'
import { selectEditKeyDetailList, selectLoadingModal, selectUserInfo } from '../../../../InnerRouter/selectors'
import { getTasks, selectTasksLength } from '../../../../Tasks'
import InformationModal from '../../InformationModal'
import ChevronRightMD from '../../../../../common/svg/ChevronRightMD'
import TasksModal from '../../TasksModal'
import ArrowLeftSM from '../../../../../common/svg/ArrowLeftSM'
import ModalResultInternship from '../../ModalResultInternship'
import CandidateDetailListEdit from '../../candidateDetailListEdit/CandidateDetailListEdit'
import './Information.scss'

function Information() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const tasksModal = useSelector(selectTasksModal)
  const resultInternshipModal = useSelector(selectResultInternshipModal)
  const informationModal = useSelector(selectInformationModal)
  const { entityId } = useParams()
  const user_id = localStorage.getItem('user_id')
  const commentModal = useSelector(selectCommentModal)
  const deleteCommentModal = useSelector(selectDeleteCommentModal)
  const loadingModal = useSelector(selectLoadingModal)
  const tasksLength = useSelector(selectTasksLength)
  const userInfo = useSelector(selectUserInfo)
  const editKeyDetailList = useSelector(selectEditKeyDetailList)
  const {
    department
  } = useEntityContext()

  const stage = useSelector(selectLabelById(candidate?.stage_id, 'stageStatus'))

  const type_registration = useSelector(selectLabelById(candidate?.type_registration, 'typeRegistrationWork'))
  const interview_result = useSelector(selectLabelById(candidate?.result_interview, 'interviewResult'))

  const handleOpenModalEdit = () => {
    dispatch(setUpdateCandidateMode(true))
    dispatch(openEditCandidateDrawer(true))
  }

  const handleResultButtonClick = () => {
    dispatch(openResultInternshipModal(true))
  }
  const handleOpenNavigateInterview = () => {
    if (candidate?.id_interview) {
      dispatch(navigateTo(`/interview/${candidate?.id_interview}`))
      dispatch(setPreviousPage({ navigate: `/candidate/${candidate.id}`, caption: 'Карточка кандидата' }))
    }
  }

  const handleOpenTask = () => {
    dispatch(getTasks({ filter: `&candidate_id=${entityId}`, is_archive: false }))
    dispatch(openTasksModal(true))
  }

  const props = useMemo(
    () => [
      ...((tasksLength || candidate?.task_count) ? [{
        label: (<div>
          Задачи
        </div>),
        value: (
          <div className="taskValue_label">
            {tasksLength || candidate?.task_count}
            <span onClick={handleOpenTask}>
              Подробнее
              <ChevronRightMD size="1" />
            </span>
          </div>)
      }] : []),
      {
        label: 'Вид оформления',
        value: (<div>{type_registration || 'Не назначен'}</div>),
        edit: true,
        editKey: 'type_registration',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="type_registration"
        />
      },
      {
        label: 'Гражданство',
        value: (<div>{candidate?.citizenship ? candidate.labels.citizenship : 'Не назначено'}</div>),
        edit: true,
        editKey: 'citizenship',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="citizenship"
        />

      },
      ...(userInfo?.role?.role_id !== 'courier_curator' ? [{
        label: 'Интервью',
        value: (<div onClick={handleOpenNavigateInterview}>
          {formatDateTime(candidate?.date_interview)}
        </div>)
      }] : []),

      ...(department === 176 ? [{
        label: 'Дата оффлайн собеседования',
        value: (<div>
          {candidate?.date_interview_offline ? dayjs(candidate?.date_interview_offline).format(DATE_DOTS_FORMAT) : 'Не назначена'}
        </div>),
        edit: true,
        editKey: 'date_interview_offline',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="date_interview_offline"
        />
      }] : []),
      ...(userInfo?.role?.role_id !== 'courier_curator' ? [{
        label: 'Результат Интервью',
        value: (<div>
          {interview_result || '-'}
        </div>)
      }] : []),
      ...(userInfo?.role?.role_id !== 'courier_curator' ? [{
        label: 'Стажировка',
        value: (
          <div className="internship" onClick={candidate?.internship ? undefined : handleOpenModalEdit}>
            {candidate?.date_internship ? `${dayjs(candidate?.date_internship).format(DATE_DOTS_FORMAT)}${candidate.time_internship ? ` ${candidate.time_internship.slice(0, 5)}` : ''}` : 'Не назначена'}
            {candidate?.internship && candidate?.internship?.is_came && (
              <div
                className="internship_result"
                onClick={(e) => {
                  e.stopPropagation()
                  handleResultButtonClick()
                }}
              >
                Результат
                <ArrowLeftSM fill="#2DBE64" className="internship_result" />
              </div>
            )}
          </div>
        )
      }] : []),
      {
        label: 'Оформление',
        value: (<div>
          {candidate?.date_registration ? dayjs(candidate?.date_registration).format(DATE_DOTS_FORMAT) : 'Не назначено'}
        </div>),
        edit: true,
        editKey: 'date_registration',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="date_registration"
        />
      },
      {
        label: 'Дата выхода',
        value: (<div>
          {candidate?.date_begin_at ? dayjs(candidate?.date_begin_at).format(DATE_DOTS_FORMAT) : 'Не назначена'}
          {' '}
        </div>),
        edit: true,
        editKey: 'date_begin_at',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="date_begin_at"
        />
      },
      {
        label: 'Характеристика',
        value: (<div>
          {candidate?.labels.characteristic}
        </div>)
      },
      {
        label: 'ИНН',
        value: (<div>
          {candidate?.inn || 'Отсутствует'}
        </div>),
        edit: true,
        editKey: 'inn',
        editComponent: <CandidateDetailListEdit
          candidate={candidate}
          editKey="inn"
        />
      }
    ],
    [candidate, stage, loadingModal, tasksLength, editKeyDetailList]
  )

  const MainCSSClass = 'informationCandidate'

  const handleClickInfo = () => {
    dispatch(setInformationModal(!informationModal))
  }

  return (
    <div className={MainCSSClass}>
      <div className={`${MainCSSClass}_container`}>
        <div className={`${MainCSSClass}_vacancy`}>
          <div className={`${MainCSSClass}_vacancy_header`}>
            <Button className={`${MainCSSClass}_vacancy_header_button`} onClick={handleClickInfo}>Информация</Button>
          </div>
        </div>
        <DetailsList
          size="xs"
          items={[{
            items: props
          }]}
        />
      </div>
      {deleteCommentModal && <ModalDeleteComment open={deleteCommentModal} />}
      {commentModal && <ModalNewComment open={commentModal} candidateId={candidate?.id} user_id={Number(user_id)} />}
      {informationModal && <InformationModal open={informationModal} />}
      {tasksModal && <TasksModal open={tasksModal} />}
      {resultInternshipModal && <ModalResultInternship open={resultInternshipModal} /> }
    </div>
  )
}

export default memo(withErrorBoundary(Information))
