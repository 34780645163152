import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ filters }: TRootState) => filters

export const selectFilters = createSelector(selectState, ({ openFilter }) => openFilter)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectFilterString = createSelector(selectState, ({ filters }) => filters)

export const selectDefFilters = createSelector(selectState, ({ defFilters }) => defFilters)

export const selectFilterMobil = createSelector(selectState, ({ filterMobil }) => filterMobil)
export const selectStageFunnelFilter = createSelector(selectState, ({ stageFunnelFilter }) => stageFunnelFilter)
export const selectTimeOptions = createSelector(selectState, ({ timeOptions }) => timeOptions)

export const selectCountFilters = createSelector(selectState, ({ countFilters }) => countFilters)
export const selectLabelsFilter = createSelector(selectState, ({ filterLabels }) => filterLabels)
export const selectDeleteFilterKey = createSelector(selectState, ({ filterKey }) => filterKey)
