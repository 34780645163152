import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ productivity }: TRootState) => productivity

export const selectProductivityRecruiter = createSelector(selectState, ({ productivityRecruiter }) => productivityRecruiter)
export const selectProductivityResearcher = createSelector(selectState, ({ productivityResearcher }) => productivityResearcher)
export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectHeaders = createSelector(selectState, ({ headers }) => headers)
export const selectActiveTab = createSelector(selectState, ({ activeTab }) => activeTab)
export const selectProductivityRecruiterFilter = createSelector(selectState, ({ recruiterFilter }) => recruiterFilter)
export const selectProductivityResearcherFilter = createSelector(selectState, ({ researcherFilter }) => researcherFilter)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectResearcherFilterString = createSelector(selectState, ({ researcherFilterString }) => researcherFilterString)
export const selectRecruiterFilterString = createSelector(selectState, ({ recruiterFilterString }) => recruiterFilterString)
export const selectSearchText = createSelector(selectState, ({ searchText }) => searchText)
