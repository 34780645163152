import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { getHistoryCandidateV2 } from '../../actions'
import { selectCandidate, selectCandidateHistoryV2, selectRequestHistoryV2 } from '../../selectors'
import IconActionHistory from './IconActionHistory'
import { ArrowLeft } from '../../../../common/svg/SvgFromIconWrapper/ArrowLeft'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import './HistoryRequest.scss'

const mainCssClass = getCssClassName('history-request')

export function HistoryRequest() {
  const dispatch = useDispatch()
  const history = useSelector(selectRequestHistoryV2)

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__items`}>
        {history.map((item) => (
          <div className={`${mainCssClass}__action`}>
            <IconActionHistory action={item.action} avatar={item.user_avatar} />
            <div className={`${mainCssClass}__main-info`}>
              <div className={`${mainCssClass}__action-type_container`}>
                <div className={`${mainCssClass}__action-name_container`}>
                  <Typography.Text className={`${mainCssClass}__name-user`}>{item.user_label}</Typography.Text>
                  <Typography.Text className={`${mainCssClass}__action-label`}>{item.changes?.[0]?.action_label || ''}</Typography.Text>
                </div>
                <Typography.Text className={`${mainCssClass}__date`}>{dayjs(item.created_at).format('DD.MM.YY HH:mm')}</Typography.Text>
              </div>
              <div className={`${mainCssClass}__actions`}>
                {item.changes && item.changes.length > 1 && item.changes.slice(1).map((change, index) => (
                  <div key={index} className={`${mainCssClass}_action-container`}>
                    <div className={`${mainCssClass}__action-change-container`}>
                      <Typography.Text className={`${mainCssClass}__action-label`}>
                        {change.label}
                        {' '}
                        :
                      </Typography.Text>
                    </div>
                    {change.before === '' || change.before === '-' ? (
                      <div><Typography.Text className={`${mainCssClass}__action-change`}>{change.after || '-'}</Typography.Text></div>
                    ) : (
                      <div className={`${mainCssClass}__change-type-container`}>
                        <div className={`${mainCssClass}__action-change-before-container`}>
                          <Typography.Text className={`${mainCssClass}__action-change-before-text`}>
                            {change.before || '-'}
                          </Typography.Text>
                        </div>
                        <div>
                          <ArrowLeft />
                        </div>
                        <div className={`${mainCssClass}__action-change-after-container`}>
                          <Typography.Text className={`${mainCssClass}__action-change-after-text`}>
                            {change.after || '-'}
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
