import React, {
  memo, useEffect, useState
} from 'react'
import { Tabs, Tooltip } from 'antd'
import cn from 'classnames'
import './FiltersMap.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectDictionaryOptionsByName, selectFilter, selectFilterOptions, selectLoading,
  selectStoreStatus
} from '../selectors'
import { TClassName } from '../../../common/types/props'
import { setFilter, setPreviousFilter } from '../actions'
import ControlButton from '../../../common/components/Buttons/Button'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import {
  fetchActualVacancyList,
  fetchClientList, fetchResponsibleList, fetchStoreList, fetchVacancyList, objectToQueryString
} from '../../filters/utils'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import TabPane = Tabs.TabPane;
import { RedoUndo } from '../../../common/svg/SvgFromIconWrapper/RedoUndo'
import { selectCandidate } from '../../../compositions/candidate/selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import CheckBig from '../../../common/svg/CheckBig'

const mainCssClass = 'filters-map'
type TFormValues = {
    stage_id: number[]
    priority_id: number[]
    schedule_id: number[]
    reject_id: number[]
    researcher: number[]
    responsible: number[]
    customer: number[]
    vacancy_id: number[]
    store_id: (number | string)[]
    recruiter: number[]
    group: number[]

}
function FiltersMap({
  className
}: TClassName) {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(null)
  const candidate = useSelector(selectCandidate)
  const filterOptions = useSelector(selectFilterOptions)
  const filters = useSelector(selectFilter)
  const loading = useSelector(selectLoading)
  const storeStatus = useSelector(selectStoreStatus)
  const handleTabChange = (key) => {
    const newActiveTab = activeTab === key ? null : key
    setActiveTab(newActiveTab)
  }
  const handleTabClick = (key) => {
    if (activeTab === key) {
      setActiveTab(null)
    } else {
      setActiveTab(key)
    }
  }

  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const scheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const groupsOptions = useSelector(selectDictionaryOptionsByName('group'))

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TFormValues>()
  const priority_id = watch('priority_id')
  const vacancy_id = watch('vacancy_id')
  const customer = watch('customer')
  const responsible = watch('responsible')
  const store_id = watch('store_id')
  const schedule_id = watch('schedule_id')
  const group_id = watch('group')

  // useEffect(() => {
  //   const filtersString = objectToQueryString(getValues())
  //   dispatch(setFilter(filtersString))
  //   if (filtersString !== filters) {
  //     dispatch(setPreviousFilter(filters))
  //   }
  // }, [priority_id, vacancy_id, customer, responsible, store_id, schedule_id, group_id])

  useEffect(() => {
    const defaultValues = {
      vacancy_id: candidate?.vacancy_id ? [candidate?.vacancy_id] : undefined
    }
    if (candidate) reset(defaultValues)
    if (candidate?.vacancy_id) {
      handleSubmitFilters()
    }
  }, [candidate])

  const handleSubmitFilters = () => {

    //добавил еще сюда небольшой фикс, раньше статус не учитывался, когда пользователь выбирал другие фильтры
    const filtersString = objectToQueryString(getValues())
    if (filtersString !== filters) {
      dispatch(setPreviousFilter(filters))
    }
    if (filtersString) {
      dispatch(setFilter(`${filtersString}${storeStatus}`))
    } else {
      dispatch(setFilter(storeStatus))
    }
  }

  return (
    <div className={cn(className, mainCssClass)}>
      <Tabs activeKey={activeTab} onChange={handleTabChange} onTabClick={handleTabClick}>
        <TabPane tab="Приоритет" key="1">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                <CheckBoxFormControl
                  options={priorityOptions}
                  control={control}
                  name="priority_id"
                  disabled={loading}
                />
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Вакансия" key="2">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                {filterOptions?.vacancies ? (
                  <SelectFormControl
                    name="vacancy_id"
                    control={control}
                    options={filterOptions.vacancies}
                    mode="multiple"
                    label="Вакансия"
                  />
                ) : (
                  <DebounceSelectFormControlMult
                    name="vacancy_id"
                    control={control}
                    notFoundContent="Начните вводить название вакансии"
                    mode="multiple"
                    label="Вакансия"
                    fetchOptions={fetchActualVacancyList}
                    disabled={loading}
                  />
                )}
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Ответственный" key="3">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                {filterOptions?.responsible ? (
                  <SelectFormControl
                    name="responsible"
                    control={control}
                    options={filterOptions.responsible}
                    mode="multiple"
                    label="Ответственный"
                  />
                ) : (
                  <DebounceSelectFormControlMult
                    name="responsible"
                    control={control}
                    mode="multiple"
                    notFoundContent="Начните вводить имя"
                    label="Ответственный"
                    fetchOptions={fetchResponsibleList}
                    disabled={loading}
                  />
                )}
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Заказчик" key="4">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                {filterOptions?.customers ? (
                  <SelectFormControl
                    name="customer"
                    control={control}
                    options={filterOptions.customers}
                    mode="multiple"
                    label="Заказчик"
                  />
                ) : (
                  <DebounceSelectFormControlMult
                    name="customer"
                    control={control}
                    mode="multiple"
                    notFoundContent="Начните вводить имя"
                    label="Заказчик"
                    fetchOptions={fetchClientList}
                    disabled={loading}
                  />
                )}
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Объект" key="5">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                {filterOptions?.stores ? (
                  <SelectFormControl
                    name="store_id"
                    control={control}
                    options={filterOptions.stores}
                    mode="multiple"
                    label="Объект"
                  />
                ) : (
                  <DebounceSelectFormControlMult
                    name="store_id"
                    control={control}
                    mode="multiple"
                    notFoundContent="Начните вводить название объекта"
                    label="Объект"
                    fetchOptions={fetchStoreList}
                    disabled={loading}
                  />
                )}
              </NewScrollContainer>
            </div>

          </div>
        </TabPane>
        <TabPane tab="График" key="6">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                <CheckBoxFormControl
                  options={scheduleOptions}
                  control={control}
                  name="schedule_id"
                  disabled={loading}
                />
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Группа" key="7">
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_content`}>
              <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                <CheckBoxFormControl
                  options={groupsOptions}
                  control={control}
                  name="group"
                  disabled={loading}
                />
              </NewScrollContainer>
            </div>
          </div>
        </TabPane>
      </Tabs>
      <div className={`${mainCssClass}__container-btn`}>
        <ControlButton typeButton="ghost" type="ghost" className={`${mainCssClass}__btn}`} onClick={() => reset()}>
          {' '}
          <RedoUndo />
          {' '}
        </ControlButton>
      </div>
      <div className={`${mainCssClass}__container-btn-2`}>
        <Tooltip title="Применить фильтры" placement="bottom">
          <ControlButton
            typeButton="ghost"
            type="ghost"
            className={`${mainCssClass}__btn}`}
            onClick={handleSubmitFilters}
          >
            <CheckBig color="#2dbe64" />
          </ControlButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(FiltersMap))
