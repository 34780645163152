import { createAction } from '@reduxjs/toolkit'
import {TDictionaries, TDictionariesNames, TOption} from "../../common/types/dictionaries";

export type TUpdateRequestStatus = {
  status: string | number
  id: string | number
  priority_id?: number
  isRequestTable: boolean
  comment?: string
}
export const updateStatusRequest = createAction<TUpdateRequestStatus>('requestModals/updateStatusRequest')

export const updateArchiveRequest = createAction<string>('requestModals/updateArchiveRequest')
export type TArchiveRequestStatus = {
  status: string | number
  id: string | number
  comment: string
  reason: string
  isRequestTable: boolean
}

export type TArchiveRequest = {
  data: TArchiveRequestStatus
  isRequestTable: boolean
}
export const archiveStatusRequest = createAction<TArchiveRequest>('requestModals/archiveStatusRequest')

export type TPostRequestData = {
  curator: string[]
  status: number
  responsible: number
  customer: number[]
  description: string
  vacancy_id: number
  store_id: number
  cost: number
  schedule_id_list: string[]
  responsible_id_list: number[]
}

export type TRequestPatchData = {
  responsible: number
  customer: number[]
  description: string
  vacancy_id: number
  store_id: number
  cost: string | number
  schedule_id_list: string | number[]
  id: number | string
  curator: string[]
  created_at?: string
  customer_list:number[]
  responsible_id_list: TOption[]
}
export type TPatchRequest = {
  data: TRequestPatchData
  isRequestTable?: boolean
  isCurator?: boolean
}

export type TPostRequest = {
  data: TPostRequestData
  isCurator?: boolean
}
export const createRequest = createAction<TPostRequest>('request/createRequest')
export const updateRequest = createAction<TPatchRequest>('request/updateRequest')
export const setResponsibleList = createAction<TOption[]>('requestModals/setResponsibleList')
export const getResponsibleList = createAction('requestModals/getResponsibleList')

export interface TCreateLinkShedule {
  request_id: number | string
  schedule_id_list: string | number[]
}

export const getDictionaries = createAction<TDictionariesNames>('requestModals/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('requestModals/dictionaries/setDictionaries')


export const deleteSheduleRequest = createAction<TCreateLinkShedule>('request/deleteSheduleRequest')

export const createLinkSheduleRequest = createAction<TCreateLinkShedule>('request/createLinkSheduleRequest')

export const setEditRequestMode = createAction<boolean>('requestModals/setEditRequestMode')

export const openRequestDrawer = createAction<boolean>('requestModals/openRequestDrawer')

export const reset = createAction('requestModals/reset')

export const openDuplicateModal = createAction<boolean>('requestModals/openRequestModal')

export const getDuplicateRequest = createAction<string>('requestModals/getDuplicateRequest')

export const setIsRequestTable = createAction<boolean>('requestModals/setIsRequestTable')


