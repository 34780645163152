import React, { memo, ReactEventHandler, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Typography } from 'antd'
import cn from 'classnames'
import { update } from 'lodash'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'

import {
  TTask,
  getDictionaries,
  getNotifyTaskId,
  getTasks,
  openMoreInfoModal,
  openTaskCreateModal,
  openTasksFilter,
  reset,
  setIsArchiveTask,
  setNotifyTask,
  setSelectTask,
  updateTask
} from '../actions'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import {
  selectFilterString,
  selectMoreInfoModal,
  selectNotifyTask,
  selectSelectTask,
  selectTaskCreateModal,
  selectTasks
} from '../selectors'
import MoreInfoModal from './MoreInfoModal'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import EditPencil1 from '../../../common/svg/EditPencil1'
import CreateTaskModal from './CreateTaskModal'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { navigateTo } from '../../../common/actions/navigate'
import { setPreviousPage } from '../../InnerRouter/actions'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { selectUserInfo } from '../../InnerRouter/selectors'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'
import './TasksPage.scss'

const mainCssClass = getCssClassName('tasks-page')

type TFormValues = {
  is_archive: boolean
}

function TasksPage() {
  const dispatch = useDispatch()
  const taskId = window.location.search

  const { control, handleSubmit, getValues, watch } = useForm<TFormValues>()

  const tasks = useSelector(selectTasks)
  const moreInfoModal = useSelector(selectMoreInfoModal)
  const filter = useSelector(selectFilterString)
  const createModal = useSelector(selectTaskCreateModal)
  const selectTask = useSelector(selectSelectTask)
  const notifyTask = useSelector(selectNotifyTask)
  const userInfo = useSelector(selectUserInfo)
  const { rbacControl } = useEntityContext()
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const is_archive = watch('is_archive')

  const handleOpenFilter = () => {
    dispatch(openTasksFilter(true))
  }

  const handleClickDone = (id: number) => {
    dispatch(updateTask({ task_id: id, status: 1 }))
  }

  const handleClickArchive = (id: number) => {
    dispatch(updateTask({ task_id: id, is_archive: true }))
  }

  const handleClickOpenMoreInfoModal = (elem: TTask) => {
    dispatch(setSelectTask(elem))
    dispatch(openMoreInfoModal(true))
  }

  const handleCancelInfoModal = () => {
    dispatch(openMoreInfoModal(false))
    dispatch(setSelectTask(null))

    if (notifyTask) {
      dispatch(setNotifyTask(null))
    }
  }

  const handleEditTask = (elem: TTask) => {
    dispatch(setSelectTask(elem))
    dispatch(openTaskCreateModal(true))
  }

  const handleSwitchChange = (checked: boolean) => {
    dispatch(setIsArchiveTask(checked))
  }

  const handleCancelEditModal = () => {
    dispatch(setSelectTask(null))
    dispatch(openTaskCreateModal(false))
  }

  const handleCLickNavigate = (e: any, id: string) => {
    if (e.ctrlKey || e.metaKey || e.which === 2) {
      window.open(`/candidate/${id}`, '_blank')
    } else {
      dispatch(navigateTo(`/candidate/${id}`))
    }
    dispatch(setPreviousPage({ navigate: '/tasks', caption: 'Задачи' }))
  }

  useEffect(() => {
    dispatch(getTasks({ filter, is_archive }))
  }, [filter, is_archive])

  useEffect(() => {
    dispatch(getDictionaries(['targetTask', 'typeRegistrationWork']))

    if (taskId) {
      dispatch(getNotifyTaskId(taskId.slice(1)))
    }
    //_________________________Удалить после НГ
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
    //__________________________
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__header`}>
        {/*//_________________________Удалить после НГ*/}
        <div className={`${mainCssClass}__header_title`}>
          <div className={`${mainCssClass}__header_title_icon`}>
            <ChristmasIcon />
          </div>
          <Typography.Title level={3}>Задачи</Typography.Title>
        </div>
        {/*//___*/}
        {/*//_____________________________Вернуть после НГ*/}
        {/*<Typography.Title level={3}>*/}
        {/*  Задачи*/}
        {/*</Typography.Title>*/}
        <div className={`${mainCssClass}__header_filter`}>
          <div className={`${mainCssClass}__header_filter_switch`}>
            <SwitchFormControl
              name="is_archive"
              control={control}
              onChange={(checked) => handleSwitchChange(checked)}
            />
            <Typography.Text>Показывать архивные</Typography.Text>
          </div>
          <ControlButton icon="left" size="large" typeButton="secondary" className="button" onClick={handleOpenFilter}>
            <FiltersIcon />
            Фильтры
          </ControlButton>
        </div>
      </div>
      <div className={`${mainCssClass}__content`}>
        <NewScrollContainer className={`${mainCssClass}__scroll`}>
          <div className={`${mainCssClass}__container`}>
            {tasks.map((el) => (
              <div key={el.type} className={`${mainCssClass}__container_child`}>
                <div className={`${mainCssClass}__container_child_header`}>
                  <Typography.Text>{el.label}</Typography.Text>
                  <Typography.Text>Задачи: {el.total}</Typography.Text>
                </div>
                <div className={`${mainCssClass}__container_child_list`}>
                  {!!el.list.length &&
                    el.list.map((elem) => (
                      <div
                        className={cn(
                          el.type === 'overdue' || new Date(elem.deadline_at) < new Date()
                            ? `${mainCssClass}__container_child_list_card-overdue`
                            : `${mainCssClass}__container_child_list_card`,
                          {
                            [`${mainCssClass}__container_child_list_card-done`]: elem.status === 1
                          }
                        )}
                        key={elem.task_id}
                      >
                        <div className={`${mainCssClass}__container_child_list_card_main`}>
                          {elem.is_archive && (
                            <Typography.Text
                              className="task-card_name"
                              onClick={() => handleClickOpenMoreInfoModal(elem)}
                            >
                              Архивировано
                            </Typography.Text>
                          )}
                          {!!elem.status && (
                            <Typography.Text
                              className="task-card_name"
                              onClick={() => handleClickOpenMoreInfoModal(elem)}
                            >
                              Выполнена
                            </Typography.Text>
                          )}
                          <div className={`${mainCssClass}__container_child_list_card_main_header`}>
                            <Typography.Text
                              className="task-card_name"
                              onClick={() => handleClickOpenMoreInfoModal(elem)}
                            >
                              {elem.name}
                            </Typography.Text>
                            {!elem.is_archive &&
                              rbacControl?.task.edit_view_access === 1 &&
                              (userInfo?.id === elem.author_id || userInfo?.role?.role_id === 'superadmin') && (
                                <EditPencil1 onClick={() => handleEditTask(elem)} />
                              )}
                          </div>
                          <Typography.Text
                            className="task-card_description"
                            onClick={() => handleClickOpenMoreInfoModal(elem)}
                          >
                            {elem.description?.length > 100
                              ? `${elem.description?.slice(0, 100)}...`
                              : elem.description}
                          </Typography.Text>
                          <Typography.Text
                            className="task-card_deadline_at"
                            onClick={() => handleClickOpenMoreInfoModal(elem)}
                          >
                            Выполнить до {elem.deadline_at.slice(0, -3)}
                          </Typography.Text>
                          <Typography.Text
                            className="task-card_author"
                            onClick={() => handleClickOpenMoreInfoModal(elem)}
                          >
                            Автор: {elem.author_label}
                          </Typography.Text>

                          {elem.target_label && (
                            <Typography.Text
                              className="task-card_author"
                              onClick={() => handleClickOpenMoreInfoModal(elem)}
                            >
                              Цель задачи: {elem.target_label}
                            </Typography.Text>
                          )}

                          {elem.candidate_label && (
                            <Typography.Text
                              className="task-card_author"
                              onClick={(e) => handleCLickNavigate(e, elem.candidate_id)}
                            >
                              Кандидат: {elem.candidate_label} {elem.candidate_phone || ''}
                            </Typography.Text>
                          )}
                        </div>

                        <div className="action">
                          {!elem.status &&
                            !elem.is_archive &&
                            (userInfo?.id === elem.user_id || userInfo?.role?.role_id === 'superadmin') && (
                              <ControlButton
                                className="button"
                                typeButton="secondary"
                                onClick={() => handleClickDone(elem.task_id)}
                              >
                                Выполнена
                              </ControlButton>
                            )}
                          {!elem.is_archive &&
                            rbacControl?.task.archive_access === 1 &&
                            (userInfo?.id === elem.author_id || userInfo?.role?.role_id === 'superadmin') && (
                              <ControlButton
                                typeButton="tertiary"
                                className="button"
                                onClick={() => handleClickArchive(elem.task_id)}
                              >
                                Архивировать
                              </ControlButton>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </NewScrollContainer>
      </div>

      {moreInfoModal && <MoreInfoModal open={moreInfoModal} onCancel={handleCancelInfoModal} />}
      {createModal && (
        <CreateTaskModal
          candidate_id={selectTask?.candidate_id || ''}
          open={createModal}
          editMode
          onCancel={handleCancelEditModal}
        />
      )}
    </div>
  )
}
export default memo(withErrorBoundary(TasksPage))
