import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip, Typography } from 'antd'
import NewScrollContainer from '../../../../common/components/NewScrollContainer/ScrollContainer'
import UserCardID from '../../../../common/svg/UserCardID'
import UserAdd from '../../../../common/svg/UserAdd'
import { navigateTo } from '../../../../common/actions/navigate'
import { selectRequest } from '../../selectors'
import { updateCandidateStatus } from '../../actions'
import RequestPageSvg from '../../../../common/svg/RequestPageSvg'
import { setPreviousPage } from '../../../InnerRouter/actions'
import AvatarSizeSmall from '../../../../common/svg/AvatarSizeSmall'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import './ReserveTabRequest.scss'

const { Title } = Typography
const mainCSSClass = 'reserveTabRequest'

function ReserveTabRequest() {
  const dispatch = useDispatch()
  const request = useSelector(selectRequest)
  const { rbacControl } = useEntityContext()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleNavigate = useCallback((id: number) => {
    dispatch(navigateTo(`/candidate/${id}`))
    dispatch(setPreviousPage({ navigate: `/application/${request?.id}`, caption: 'Карточка заявки' }))
  }, [])

  const handleClickAddCandidate = useCallback((id: number) => {
    dispatch(updateCandidateStatus({ id, status: 50 }))
  }, [])

  const
    isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={mainCSSClass}>

      {!isMobile
       && (
         <NewScrollContainer>
           {!request?.candidate_reserve_list?.length && (
             <div className={`${mainCSSClass}_empty`}>
               <RequestPageSvg />
               <Title level={5}>Кандидатов нет</Title>
               <p>В архиве для данной заявки нет кандидатов</p>
             </div>
           )}

           {request?.candidate_reserve_list?.map((el) => (
             <div key={el.id} className={`${mainCSSClass}_items`}>
               {el.photo ? <img src={el.photo} alt="avatar" className={`${mainCSSClass}_items_photo`} /> : <AvatarSizeSmall /> }
               <div className={`${mainCSSClass}_items_info`}>
                 <span>{`${el.last_name} ${el.name} ${el.second_name || ''}`}</span>
               </div>
               <div className={`${mainCSSClass}_items_actions`}>
                 <Tooltip placement="top" title="Перейти к кандидату">
                   <Button onClick={() => handleNavigate(el.id)}>
                     <UserCardID />
                   </Button>
                 </Tooltip>
                 {rbacControl?.request.edit_view_access === 1 && (
                   <Tooltip placement="top" title="Перевести в кандидаты">
                     <Button onClick={() => handleClickAddCandidate(el.id)}>
                       <UserAdd />
                     </Button>
                   </Tooltip>
                 )}
               </div>
             </div>
           ))}
         </NewScrollContainer>
       )}
      {isMobile
       && (
         <>
           {!request?.candidate_reserve_list?.length && (
             <div className={`${mainCSSClass}_empty`}>
               <RequestPageSvg />
               <Title level={5}>Кандидатов нет</Title>
               <p>В архиве для данной заявки нет кандидатов</p>
             </div>
           )}

           {request?.candidate_reserve_list?.map((el) => (
             <div key={el.id} className={`${mainCSSClass}_items`}>
               {el.photo ? <img src={el.photo} alt="avatar" className={`${mainCSSClass}_items_photo`} /> : <AvatarSizeSmall /> }
               <div className={`${mainCSSClass}_items_info`}>
                 <span>{`${el.last_name} ${el.name} ${el.second_name || ''}`}</span>
               </div>
               <div className={`${mainCSSClass}_items_actions`}>
                 <Tooltip placement="top" title="Перейти к кандидату">
                   <Button onClick={() => handleNavigate(el.id)}>
                     <UserCardID />
                   </Button>
                 </Tooltip>
                 {rbacControl?.request.edit_view_access === 1 && (
                   <Tooltip placement="top" title="Перевести в кандидаты">
                     <Button onClick={() => handleClickAddCandidate(el.id)}>
                       <UserAdd />
                     </Button>
                   </Tooltip>
                 )}
               </div>
             </div>
           ))}
         </>
       )}

    </div>

  )
}

export default ReserveTabRequest
