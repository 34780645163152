import { loopHooks } from 'react-table'
import axios from 'axios'
import $api from '../../common/api/http'
import { TOption } from '../../common/types/dictionaries'
import { TFilter } from './types'

export const filterObject: any = {
  stage: false,
  citizenship: false,
  statusCandidate: false,
  workSites: false,
  rejReas: false,
  cities: false,
  sub: false,
  intership: false,
  begin: false,
  priority: false,
  date: false,
  requestContent: false,
  respond: false,
  vacancyCandidate: false,
  statusStage: false,
  tag: false,
  interviewStatus: false,
  confirm: false,
  interviewResult: false,
  characteristic: false,
  registrType: false,
  recruiter: false,
  researcher: false,
  client: false,
  vacancyContent: false,
  store: false,
  responsible: false,
  statusVacancy: false,
  recriuterContent: false,
  researchContent: false,
  officeContent: false,
  dateInterview: false,
  teach: false,
  duplicate: false,
  requestCity: false,
  interviewOffline: false,
  webmasterContent: false,
  updatedContent: false,
  lidRespondContent: false,
  group: false,
  respondGroups: false,
  time: false,
  whitedarkstore: false
}

export function filteredOptions(array: TOption[], searchValue: string): TOption[] {
  if (!array || !searchValue) return array || []

  const lowerCaseSearchValue = searchValue.toLowerCase()

  return [...array].sort((a, b) => {
    const aLabel = a.label.toLowerCase()
    const bLabel = b.label.toLowerCase()

    const aIndex = aLabel.indexOf(lowerCaseSearchValue)
    const bIndex = bLabel.indexOf(lowerCaseSearchValue)

    // Если совпадений нет, считаем индекс как очень большой (переносим в конец списка)
    return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex)
  })

  // if (!array) return []
  // if (!searchValue) return array

  // const lowerCaseSearchValue = searchValue.toLowerCase()

  // return array.filter((obj) => obj.label.toLowerCase().includes(lowerCaseSearchValue))

}

export function filteredOptionsSource(array: TOption[]): TOption[] {
  if (!array) return []

  // Используем Map для хранения уникальных объектов по ключу value
  const uniqueOptionsMap = new Map<string, TOption>()

  // Фильтруем массив и добавляем уникальные объекты по ключу value в Map
  array.forEach((obj) => {
    if (!uniqueOptionsMap.has(obj.value)) {
      uniqueOptionsMap.set(obj.value, obj)
    }
  })

  // Преобразуем Map обратно в массив объектов
  const uniqueOptions = Array.from(uniqueOptionsMap.values())

  return uniqueOptions
}

export async function fetchStoreList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const data = await $api.get('/api/v1/stores?page=1&per-page=100&fields=id,name')

    return data.data.DATA.map((obj: { id: number; name: string; surname: string}) => ({
      label: `${obj.name}`,
      value: obj.id
    }))
  }

  const data = await $api.get(`/api/v1/stores?page=1&per-page=100&fields=id,name&filter[name][like]=${name}`)

  return data.data.DATA.map((obj: { id: number; name: string; }) => ({
    label: `${obj.name}`,
    value: obj.id
  }))
}

export async function fetchRecruiterList(name: string): Promise<TOption[]> {
  const data = await $api.get(`/api/v1/user/user-list?role_id=recruiter&search=${name}`)

  return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export async function fetchResearcherList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const data = await $api.get('/api/v1/user/user-list?role_id=researcher')

    return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
      label: `${obj.name} ${obj.surname}`,
      value: obj.id
    }))
  }

  const data = await $api.get(`/api/v1/user/user-list?role_id=researcher&search=${name}`)

  return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export async function fetchClientList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/users?page=1&per-page=100&fields=id,name,surname')

    return data.DATA.map((obj: { id: number; name: string; surname: string}) => ({
      label: `${obj.name} ${obj.surname}`,
      value: obj.id
    }))
  }
  const filtredName = generateFilters(name.split(' '))

  const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&fields=id,name,surname&filter${filtredName}`)

  return data.DATA.map((obj: { id: number; name: string; surname: string;}) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export async function fetchVacancyList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/vacancies?sort=-updated_at&page=1&per-page=10')

    return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
      label: obj.label,
      value: obj.id
    }))
  }

  const { data } = await $api.get(`/api/v1/vacancies?page=1&per-page=1000&fields=id,label&filter[label][like]=${name}&unshift=OR`)

  return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
    label: obj.label,
    value: obj.id
  }))
}
export async function fetchActualVacancyList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/vacancies?sort=-updated_at&page=1&per-page=10&filter[status][in][]=137&filter[status][in][]=138')

    return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
      label: obj.label,
      value: obj.id
    }))
  }

  const { data } = await $api.get(`/api/v1/vacancies?page=1&per-page=1000&fields=id,label&filter[label][like]=${name}&filter[status][in][]=137&filter[status][in][]=138`)

  return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
    label: obj.label,
    value: obj.id
  }))
}

export async function fetchResponsibleList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/users?page=1&per-page=100&fields=id,name,surname,email')

    return data.DATA.map((obj: { id: number; name: string; surname: string; email: string }) => ({
      label: `${obj.name} ${obj.surname} ${obj.email}`,
      value: obj.id
    }))
  }
  const filtredName = generateFilters(name.split(' '))

  const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&fields=id,name,surname,email&filter${filtredName}`)

  return data.DATA.map((obj: { id: number; name: string; surname: string; email: string }) => ({
    label: `${obj.name} ${obj.surname} ${obj.email}`,
    value: obj.id
  }))
}

export function generateUsersFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(name_like, surname_like)
  }

  const query = `${filters.join('&orFilter')}`
  return query
}

export function generateFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const email_like = `[email][like]=${words[i]}`
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(email_like, name_like, surname_like)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return query
}

export async function fetchCitiesList(name: string): Promise<TOption[]> {
  const data = await $api.get(`/api/v1/dadata/suggest-city?search=${name}`)

  return data.data.DATA.map((obj: { data: { city: string } }) => ({
    label: `${obj.data.city} `,
    value: obj.data.city
  }))
}

export function objectToQueryString(obj: TFilter, isEventFunnel?: boolean, isWorkload?: boolean): string {
  let str = ''
  if (isEventFunnel) {
  }
  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object' && key !== 'tag' && key !== 'schedule_id' && key !== 'request_id' && key !== 'customer' && key !== 'request_city' && key !== 'whitedarkstore') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }
      if (((typeof obj[key][0] === 'number' || typeof obj[key][0] === 'boolean') && key !== 'schedule_id' && key !== 'characteristic' && key !== 'group' && key !== 'customer' && key !== 'respond_groups') || (key === 'stage_id' && !isEventFunnel)) {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
      if ((key === 'date_internship' || key === 'created_at' || key === 'date_registration' || key === 'date' || key === 'date_teach' || key === 'date_interview_offline' || key === 'updated_at' || key === 'date_lid_respond')) {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (key === 'tag') {
        const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        str += `&union[tag]=${strArrStr}`
      }
      if (key === 'schedule_id') {
        const strArrStr = obj[key].join(',')
        str += `&union[schedule]=${strArrStr}`
      }
      if (key === 'customer' || key === 'whitedarkstore') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[${key}]=${strArrStr}`
      }
      if (key === 'request_id') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[request]=${strArrStr}`
      }
      if ((key === 'date_interview')) {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&${key}=${strArrStr}`
      }
      if ((key === 'date_respond')) {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&${key}=${strArrStr}`
      }
      if (key === 'characteristic') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&characteristic=${strArrStr}`
      }
      if (key === 'group') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        const newStrArr = strArrStr.replace(/undefined/g, '')
        str += `&groups=${newStrArr}`
      }
      if (key === 'respond_groups') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        const newStrArr = strArrStr.replace(/undefined/g, '')
        str += `&union[respond_groups]=${newStrArr}`
      }
      if (key === 'request_city') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[city]=${strArrStr}`
      }
      if (key === 'time') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&time[in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
    } else if (typeof obj[key] === 'string' && key !== 'schedule_id' && !!obj[key].length) {
      if (key === 'webmaster_id') {
        str += `&filter[${key}][in][]=${obj[key]}`
      } else if (key === 'vacancy_id_search') {
        str += `&filter[id]=${obj[key]}`
      } else {
        str += `&filter[${key}]=${obj[key]}`
      }
    } else if (typeof obj[key] === 'boolean' && key !== 'without_store') {
      if (key === 'responded') {
        const strBool = obj[key] ? `&union[${key}]=1` : ''
        str += strBool
      } else if (key === 'is_validate_document'){
        const strBool = obj[key] ? `&filter[${key}]=1` : ''
        str += strBool
      } else if (key === 'candidateReject') {
        const strBool = obj[key] ? '&union[show_reject]=true' : ''
        str += strBool
      } else {
        const strBool = obj[key] ? `&${key}=1` : ''
        str += strBool
      }
    } else if (key === 'without_store') {
      const strBool = obj[key] ? `&union[${key}]=true` : `&union[${key}]=false`
      str += strBool
    }
  }
  return str
}

export async function fetchRequestList(name: string): Promise<TOption[]> {
  if (!name) {
    const { data } = await $api.get('/api/v1/requests?page=1&per-page=10')

    return data.DATA.request_list.map((obj: { id: number; name: string }) => ({
      label: obj.name,
      value: obj.id
    }))
  }

  const { data } = await $api.get(`/api/v1/requests?page=1&per-page=10&fields=id,name&filter[name][like]=${name}`)

  return data.DATA.request_list.map((obj: { id: number; name: string }) => ({
    label: obj.name,
    value: obj.id
  }))
}

export async function fetchTagsList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/candidate-tags?page=1&per-page=10')

    return data.DATA.map((obj: { label: string }) => ({
      label: obj.label,
      value: obj.label
    }))
  }

  const { data } = await $api.get(`/api/v1/candidate-tags?page=1&per-page=10&filter[label][like]=${name}`)

  return data.DATA.map((obj: { label: string }) => ({
    label: obj.label,
    value: obj.label
  }))
}

export async function getStageState() {
  const { data } = await $api.get('/api/v1/stage-state/self')
  return data.DATA
}

export const radioOptions = [{ label: 'За последнюю неделю', value: 1 }, { label: 'За последний месяц', value: 2 },
  { label: 'За последний год', value: 3 }
]

export async function getSlotsList() {
  const { data } = await $api.get('/api/v1/calendar/get-slot-list')
  return data.DATA
}

export function createObjectsArray() {
  const objectsArray = []
  for (let hour = 9; hour < 23; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 20) {
      const label = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}-${(hour + Math.floor((minutes + 20) / 60)).toString().padStart(2, '0')}:${((minutes + 20) % 60).toString().padStart(2, '0')}`
      objectsArray.push({ label, value: label })
    }
  }
  const lastLabel = '23:00-23:20'
  objectsArray.push({ label: lastLabel, value: lastLabel })
  return objectsArray
}
