import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import { Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { selectLoadingModal } from '../../../compositions/InnerRouter/selectors'
import {
  selectCandidate,
  selectHeaders,
  selectOldHistoryCandidateData,
  selectOpenHistoryCandidateData
} from '../../../compositions/candidate/selectors'
import { getOldHistoryCandidateData } from '../../../compositions/candidate/actions'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getCssClassName } from '../../../common/utils/generateClassName'
import EmptyList from '../../../features/emptyList/components/EmptyList'
import CandidateOldHistoryTable from './CandidateOldHistoryTable'
import './CandidateHistory.scss'

type TFormValues = {
    actions: string
    perPagesCount: number
}
type TCandidateHistoryProps = {
  onToggleHistoryType: () => void;
}

const MainCSSClass = getCssClassName('candidate-history')

function CandidateOldHistory() {
  const openHistoryAction = useSelector(selectOpenHistoryCandidateData)
  const { control, watch } = useForm<TFormValues>({ defaultValues: { perPagesCount: 10 } })
  const [page, setPage] = useState<number>(1)

  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const historyData = useSelector(selectOldHistoryCandidateData)
  const loading = useSelector(selectLoadingModal)
  const headers = useSelector(selectHeaders)

  const handleNextPage = useCallback(() => {
    if (page !== headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      // Ваш код обработки изменения размеров
    }, 300)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const perPage = watch('perPagesCount')

  useEffect(() => {
    if (candidate) {
      dispatch(getOldHistoryCandidateData({
        candidate_id: candidate.id, page, perPage, filter: ''
      }))
    }
  }, [candidate, page, perPage])

  return (
    <div className={MainCSSClass}>
      <div className={`${MainCSSClass}__container-pagination`} />

      {loading && (
        <div>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      <div className={`${MainCSSClass}_scroll-container`}>
        <NewScrollContainer className={`${MainCSSClass}_scroll-container_scroll`}>
          {!loading && !!historyData?.length && (
            <CandidateOldHistoryTable
              historyData={historyData}
              className={`${MainCSSClass}_table`}
            />
          )}
          {!loading && !historyData?.length && (
            <EmptyList type="history" />

          )}

        </NewScrollContainer>
      </div>
    </div>
  )
}

export default CandidateOldHistory
