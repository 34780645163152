import axios, { AxiosError } from 'axios'
import * as DOMAIN from 'domain'
import { TAuth } from '../../compositions/auth/types'
import { showCriticalNotification } from '../components/notification/utils'
import { getErrorNotification } from '../components/ErrorComponentSaga'
import 'dotenv/config'
import { navigateTo } from '../actions/navigate'
import * as Sentry from '@sentry/react'

export const API_URL = process.env.DOMAIN

const $api = axios.create({
  baseURL: API_URL
})

$api.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  config.withCredentials = true

  return config
})

$api.interceptors.response.use(
  (response) =>
  // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
  // Здесь можете сделать что-нибудь с ответом
    response,
  async (error) => {
    if (error.response.status !== 401) {
      Sentry.captureException(error)
      return error.response
    }
    // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    // Здесь можете сделать что-то с ошибкой ответа
    if (error.response.status === 401) {
      try {
        const response = await axios.post<TAuth>(`${API_URL}/api/v1/auth/refresh`, {
          jwt_token_refresh: localStorage.getItem('token_refresh')
        })
        if (response?.data?.ERR === true) {
          localStorage.removeItem('token')
          localStorage.removeItem('user_id')
          localStorage.removeItem('token_refresh')
          navigateTo('/')
        }
        localStorage.setItem('token', response.data?.DATA?.token)
        localStorage.removeItem('token_refresh')
        localStorage.setItem('token_refresh', response?.data?.DATA?.token_refresh)
        navigateTo(localStorage.getItem('initial_url'))
      } catch (error) {
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('token_refresh')
        showCriticalNotification(
          getErrorNotification({
            moduleName: 'Ошибка логина',
            text: 'Не удалось получить слоты интервью',
            error: error as AxiosError
          })
        )
        // action всплывашка с текстом ошибки авторизации :(
      }
    }
  }
)

export default $api
