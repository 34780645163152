import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Tabs, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import Draggable from 'react-draggable'
import TabPane from 'antd/es/tabs/TabPane'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { openQueueModal } from '../actions'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './QueueModal.scss'
import Call from '../../../common/svg/SvgFromIconWrapper/Call'
import CallBack from '../../../common/svg/SvgFromIconWrapper/CallBack'
import { formatTimeMinutesSeconds } from '../utils'
import { selectCallMessage, selectLeadMessage } from '../selectors'

const mainCssClass = getCssClassName('queue-modal')

interface CallMessage {
  linkedid: string
  status: string
  type: number
  callee?: number
  phone: string
  caller: number
  secondsElapsed: number
  group: string
}

interface LeadMessage {
  id: string
  status: string
  type: number
  phone: string
  callee: number
  caller: number
  secondsElapsed: number
  linkedid: string
  group: string
}

interface QueueModalProps {
  open: boolean
  className?: string
}

function QueueModal({ open, className }: QueueModalProps) {
  const dispatch = useDispatch()
  const [typeQueue, setTypeQueue] = useState<string>('call')
  const [callQueue, setCallQueue] = useState<CallMessage[]>([])
  const [leadQueue, setLeadQueue] = useState<LeadMessage[]>([])
  const callMessage = useSelector(selectCallMessage) as CallMessage | null
  const leadMessage = useSelector(selectLeadMessage) as LeadMessage | null
  const ivrCallQueue = useMemo(() => callQueue.filter((item) => item.group === 'IVR'), [callQueue])
  const anyCallQueue = useMemo(() => callQueue.filter((item) => item.group !== 'IVR'), [callQueue])
  const ivrLeadQueue = useMemo(() => leadQueue.filter((item) => item.group === 'IVR'), [leadQueue])
  const anyLeadQueue = useMemo(() => leadQueue.filter((item) => item.group !== 'IVR'), [leadQueue])

  const draggleRef = useRef<HTMLDivElement>(null)

  const handleClickCancel = () => {
    dispatch(openQueueModal(false))
  }

  useEffect(() => {
    if (callMessage) {
      // Проверка на статус "ringing"
      if (callMessage.status === 'ringing') {
        setCallQueue((prevQueue) => {
          // Проверка на принадлежность к IVR или Группе Астер
          const isIVR = callMessage.callee === null && String(callMessage.caller).length > 4

          if (!prevQueue.find((item) => item?.linkedid === callMessage.linkedid)) {
            // Добавляем новый звонок в соответствующую группу
            return [
              ...prevQueue,
              {
                ...callMessage,
                secondsElapsed: 0,
                callee: callMessage.callee,
                group: isIVR ? 'IVR' : 'ANY',
                caller: callMessage.caller
              }
            ]
          }

          // Если элемент уже существует, ничего не делаем
          return prevQueue
        })
      } else if (callMessage.status !== 'ringing' && callMessage.status !== 'end') {
        setCallQueue((prevQueue) => {
          // Проверка на принадлежность к IVR или Группе Астер
          const isIVR = callMessage.callee === null && String(callMessage.caller).length > 4

          if (!prevQueue.find((item) => item?.linkedid === callMessage.linkedid)) {
            // Добавляем новый звонок в соответствующую группу
            return [
              ...prevQueue,
              {
                ...callMessage,
                secondsElapsed: 0,
                callee: callMessage.callee,
                group: isIVR ? 'IVR' : 'ANY',
                caller: callMessage.caller
              }
            ]
          }

          // Если элемент уже существует, ничего не делаем
          return prevQueue
        })
      }

      // Обработка статуса "end"
      else if (callMessage.status === 'end') {
        setCallQueue((prevQueue) =>
          prevQueue.map((item) => (item.linkedid === callMessage.linkedid ? { ...item, status: 'end' } : item))
        )

        // Удаляем элемент через 60 секунд
        setTimeout(() => {
          setCallQueue((prevQueue) => prevQueue.filter((item) => item.linkedid !== callMessage.linkedid))
        }, 60000)
      }
    }
  }, [callMessage])

  useEffect(() => {
    if (leadMessage) {
      if (leadMessage.status === 'ringing') {
        setLeadQueue((prevQueue) => {
          const isIVR = leadMessage.callee === null && String(leadMessage.caller).length > 4
          if (!prevQueue.find((item) => item.id === leadMessage.id)) {
            return [
              ...prevQueue,
              {
                ...leadMessage,
                secondsElapsed: 0,
                callee: leadMessage.callee,
                group: isIVR ? 'IVR' : 'ANY',
                caller: leadMessage.caller
              }
            ]
          }
          return prevQueue.map((item) => (item.id === leadMessage.id ? { ...item, callee: leadMessage.callee } : item))
        })
      } else if (leadMessage.status !== 'ringing' && leadMessage.status !== 'end') {
        setLeadQueue((prevQueue) => {
          const isIVR = leadMessage.callee === null && String(leadMessage.caller).length > 4
          if (!prevQueue.find((item) => item?.linkedid === leadMessage.linkedid)) {
            return [
              ...prevQueue,
              { ...leadMessage, secondsElapsed: 0, group: isIVR ? 'IVR' : 'ANY', caller: leadMessage.caller }
            ]
          }
          return prevQueue
        })
      } else if (leadMessage.status === 'end') {
        setLeadQueue((prevQueue) =>
          prevQueue.map((item) => (item.id === leadMessage.id ? { ...item, status: 'end' } : item))
        )

        setTimeout(() => {
          setLeadQueue((prevQueue) => prevQueue.filter((item) => item.id !== leadMessage.id))
        }, 60000)
      }
    }
  }, [leadMessage])

  useEffect(() => {
    const interval = setInterval(() => {
      setLeadQueue((prevQueue) =>
        prevQueue.map((item) => ({
          ...item,
          secondsElapsed: item.status === 'end' ? item.secondsElapsed : item.secondsElapsed + 1
        }))
      )
      setCallQueue((prevQueue) =>
        prevQueue.map((item) => ({
          ...item,
          secondsElapsed: item.status === 'end' ? item.secondsElapsed : item.secondsElapsed + 1
        }))
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const handleChangeTypeQueue = (e: string) => {
    setTypeQueue(e)
  }

  // Если окно не открыто, не рендерим его
  if (!open) return null

  return (
    <Draggable nodeRef={draggleRef} handle={`.${mainCssClass}__header`}>
      <div
        ref={draggleRef}
        className={cn(className, mainCssClass)}
        style={{
          position: 'absolute',
          top: '100px',
          left: '100px',
          width: '380px',
          zIndex: 1000,
          background: '#fff',
          boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
          borderRadius: '8px'
        }}
      >
        <div className={`${mainCssClass}__header`}>
          <Typography.Title level={4} className={`${mainCssClass}__title`}>
            Очереди
          </Typography.Title>
          <button onClick={handleClickCancel} className={`${mainCssClass}__close-button`} style={{ cursor: 'pointer' }}>
            X
          </button>
        </div>
        <div className={`${mainCssClass}__content`}>
          <Tabs className={`${mainCssClass}__tab-container`} defaultValue={typeQueue} onClick={handleChangeTypeQueue}>
            <TabPane tab="Очередь звонков" key="call">
              <div className={`${mainCssClass}__content`}>
                <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
                  {!!ivrCallQueue.length && (
                    <div className={`${mainCssClass}__tag-name-container`}>
                      <Typography.Text className={`${mainCssClass}__tag-name`}>IVR (Входящие вызовы)</Typography.Text>
                    </div>
                  )}
                  <div className={`${mainCssClass}__wrap-container`}>
                    {ivrCallQueue.map((item, index) => (
                      <div
                        key={item.linkedid}
                        className={`${mainCssClass}__container-element ${
                          item.status === 'end'
                            ? `${mainCssClass}__container-element--ended`
                            : index % 2 === 0
                              ? `${mainCssClass}__container-element--green`
                              : ''
                        }`}
                      >
                        <div className={`${mainCssClass}__number-wrapper`}>
                          {String(item?.callee)?.length > 4 ? <Call /> : <CallBack />}
                          <Typography.Text className={`${mainCssClass}__number`}>
                            {item?.callee && String(item?.callee)?.length > 4
                              ? item?.callee
                              : item?.caller && String(item?.caller)?.length > 4
                                ? item?.caller
                                : 'Номер определяется'}
                          </Typography.Text>
                        </div>
                        <Typography.Text
                          className={item.status === 'end' ? `${mainCssClass}__timer-end` : `${mainCssClass}__timer`}
                        >
                          {formatTimeMinutesSeconds(item.secondsElapsed)}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                  {!!anyCallQueue.length && (
                    <div className={`${mainCssClass}__tag-name-container`}>
                      <Typography.Text className={`${mainCssClass}__tag-name`}>Все вызовы</Typography.Text>
                    </div>
                  )}
                  {anyCallQueue.map((item, index) => (
                    <div
                      key={item.linkedid}
                      className={`${mainCssClass}__container-element ${
                        item.status === 'end'
                          ? `${mainCssClass}__container-element--ended`
                          : index % 2 === 0
                            ? `${mainCssClass}__container-element--green`
                            : ''
                      }`}
                    >
                      <div className={`${mainCssClass}__number-wrapper`}>
                        {String(item?.callee)?.length > 4 ? <Call /> : <CallBack />}
                        <Typography.Text className={`${mainCssClass}__number`}>
                          {item?.callee && String(item?.callee)?.length > 4
                            ? item?.callee
                            : item?.caller && String(item?.caller)?.length > 4
                              ? item?.caller
                              : 'Номер определяется'}
                        </Typography.Text>
                      </div>
                      <Typography.Text
                        className={item.status === 'end' ? `${mainCssClass}__timer-end` : `${mainCssClass}__timer`}
                      >
                        {formatTimeMinutesSeconds(item.secondsElapsed)}
                      </Typography.Text>
                    </div>
                  ))}
                </NewScrollContainer>
              </div>
            </TabPane>
            <TabPane tab="Обратные звонки" key="lead">
              <div className={`${mainCssClass}__content`}>
                <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
                  {!!ivrLeadQueue.length && (
                    <div className={`${mainCssClass}__tag-name-container`}>
                      <Typography.Text className={`${mainCssClass}__tag-name`}>IVR (Входящие вызовы)</Typography.Text>
                    </div>
                  )}
                  <div className={`${mainCssClass}__wrap-container`}>
                    {ivrLeadQueue.map((item, index) => (
                      <div
                        key={item.linkedid}
                        className={`${mainCssClass}__container-element ${
                          item.status === 'end'
                            ? `${mainCssClass}__container-element--ended`
                            : index % 2 === 0
                              ? `${mainCssClass}__container-element--green`
                              : ''
                        }`}
                      >
                        <div className={`${mainCssClass}__number-wrapper`}>
                          {String(item?.callee)?.length > 4 ? <Call /> : <CallBack />}
                          <Typography.Text className={`${mainCssClass}__number`}>
                            {item?.callee && String(item?.callee)?.length > 4
                              ? item?.callee
                              : item?.caller && String(item?.caller)?.length > 4
                                ? item?.caller
                                : 'Номер определяется'}
                          </Typography.Text>
                        </div>
                        <Typography.Text
                          className={item.status === 'end' ? `${mainCssClass}__timer-end` : `${mainCssClass}__timer`}
                        >
                          {formatTimeMinutesSeconds(item.secondsElapsed)}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                  {!!anyLeadQueue.length && (
                    <div className={`${mainCssClass}__tag-name-container`}>
                      <Typography.Text className={`${mainCssClass}__tag-name`}>Все вызовы</Typography.Text>
                    </div>
                  )}
                  {anyLeadQueue.map((item, index) => (
                    <div
                      key={item.linkedid}
                      className={`${mainCssClass}__container-element ${
                        item.status === 'end'
                          ? `${mainCssClass}__container-element--ended`
                          : index % 2 === 0
                            ? `${mainCssClass}__container-element--green`
                            : ''
                      }`}
                    >
                      <div className={`${mainCssClass}__number-wrapper`}>
                        {String(item?.callee)?.length > 4 ? <Call /> : <CallBack />}
                        <Typography.Text className={`${mainCssClass}__number`}>
                          {item?.callee && String(item?.callee)?.length > 4
                            ? item?.callee
                            : item?.caller && String(item?.caller)?.length > 4
                              ? item?.caller
                              : 'Номер определяется'}
                        </Typography.Text>
                      </div>
                      <Typography.Text
                        className={item.status === 'end' ? `${mainCssClass}__timer-end` : `${mainCssClass}__timer`}
                      >
                        {formatTimeMinutesSeconds(item.secondsElapsed)}
                      </Typography.Text>
                    </div>
                  ))}
                </NewScrollContainer>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Draggable>
  )
}

export default memo(withErrorBoundary(QueueModal))
