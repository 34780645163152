import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'

import {
  Button, Typography
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectFilter,
  selectFilteredRequests,
  selectLoading,
  selectMapBounds, selectPage,
  selectPreviousFilter,
  selectRequests, selectSlicedRequests,
  selectSlicedRequestsTotalCounts
} from '../selectors'
import CardRequest from './CardRequest'
import {
  getDictionaries,
  getFilteredRequests, getSlicedRequests,
  reset, setFilter, setPage,
  setPreviousFilter,
  setStoreStatus,
  setZoomCoordinates
} from '../actions'

import ControlButton from '../../../common/components/Buttons/Button'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { TRequest } from '../../../common/api/request'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { fetchGetFieldCoordinatesYandex } from '../../../common/api/yandex'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import './ContainerCard.scss'
import { newFilterString } from '../utils'
import { setOpenModalMap } from '../../../compositions/candidateV2/actions'

const mainCssClass = getCssClassName('container-card')

export type TContainerCardProps = {
    open: boolean | undefined
    isEdit : boolean | undefined
  candidate: any
}

function ContainerCard({ open, isEdit, candidate }: TContainerCardProps) {
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const [withoutStore, setWithoutStore] = useState(false)

  const loading = useSelector(selectLoading)
  const requests = useSelector(selectRequests)
  const filters = useSelector(selectFilter)
  const previousFilter = useSelector(selectPreviousFilter)
  const filteredRequests = useSelector(selectFilteredRequests)
  const totalCounts = useSelector(selectSlicedRequestsTotalCounts)
  const mapBounds = useSelector(selectMapBounds)
  const userInfo = useSelector(selectUserInfo)
  const page = useSelector(selectPage)
  const bounds = useSelector(selectMapBounds)
  const [firstRender, setFirstRender] = useState(false)

  const slicedFilteredRequests = useSelector(selectSlicedRequests)
  const { control, watch, getValues } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 25,
      store: false
    }
  })

      type TFormValues = {
        name: string
        store: boolean
      }

      // чтобы previousFilter не появлялся при монтировании, а только при последующих изменениях в компоненте фильтр или свитчере
      useEffect(() => {
        setMounted(true) // Помечаем компонент как смонтированный после первого рендера
      }, [])

      useEffect(() => {
        // добавил обязательно наличие previousFilter
        if (open && (!filteredRequests?.length || filters || previousFilter !== filters || filters)) {
        // временный фикс
        // if (open && filters && filters !== 'filter[status][in][]=92' && filters !== '&union[without_store]=false' && filters !=='&union[without_store]=true') {
          dispatch(getFilteredRequests({
            filters,
            isEdit: !!isEdit
          }))
        }
      }, [filters, open, isEdit, previousFilter, candidate])

      useEffect(() => {
        if (filters.indexOf('union[without_store]=true') === -1) {
          setWithoutStore(false)
        }
        if (filters.indexOf('union[without_store]=true') !== -1) {
          setWithoutStore(true)
        }
        if (open && (filters || filters !== previousFilter || bounds)) {
          if (!withoutStore) {
            dispatch(getSlicedRequests({
              filters,
              isEdit: !!isEdit,
              bounds,
              page
            }))
          }
        }
      }, [filters, open, bounds, previousFilter, withoutStore])

      const name = watch('name')
      const newStore = watch('store')

      const handleClose = useCallback(() => dispatch(setOpenModalMap(false)), [])

      useEffect(() => {
        const oldFilter = newFilterString(filters)
        // добавил новый диспатч на статус, чтобы при обнулении фильтров, отправлялся статус
        dispatch(setStoreStatus(`${newStore ? '&union[without_store]=true' : '&union[without_store]=false'}`))
        dispatch(setFilter(`${oldFilter}${newStore ? '&union[without_store]=true' : '&union[without_store]=false'}`))

        // чтобы previousFilter не появлялся при монтировании, а только при последующих изменениях в компоненте фильтр или свитчере
        if (mounted) {
          dispatch(setPreviousFilter(`${oldFilter}${newStore ? '&union[without_store]=false' : '&union[without_store]=true'}`))
        }
      }, [newStore])

      useEffect(() => {
        if (name) {
          const coordinates = name?.split(',')?.map((el) => Number(el))
          dispatch(setZoomCoordinates(coordinates))
        }
      }, [name])

      useEffect(() => {
        if (name) {
          const coordinates = name?.split(',')?.map((el) => Number(el))
          dispatch(setZoomCoordinates(coordinates))
        }
      }, [name])

      useEffect(() => {
        dispatch(getDictionaries(['priority', 'schedule', 'group']))
      }, [])

      useEffect(() => {
        dispatch(getDictionaries(['priority', 'schedule', 'group', 'requestCandidateCharacteristic']))
      }, [])

      useEffect(() => () => {
        dispatch(reset())
      }, [])
      const handleNewCounts = () => {
        dispatch(setPage(page + 1))
        dispatch(getSlicedRequests({
          filters, bounds, page: page + 1, isEdit: !!isEdit
        }))
      }
      return (
        <div className={`${mainCssClass}`}>
          <div className={`${mainCssClass}__container-header`}>

            <ControlButton className="button" type="ghost" typeButton="ghost" icon="right" onClick={handleClose}>
              <ArrowRightSM />
              Назад
            </ControlButton>
            <Typography.Paragraph className={`${mainCssClass}__label-length`}>
              Количество заявок:&nbsp;
              {slicedFilteredRequests?.length}
      &nbsp;из&nbsp;
              {totalCounts}
            </Typography.Paragraph>
          </div>
          <div className={`${mainCssClass}_switch`}>
            <SwitchFormControl name="store" control={control} />
            <Typography.Text>Показывать потребность под кандидата</Typography.Text>
          </div>
          <DebounceSelectFormControl name="name" control={control} placeholder="Адрес, метро, город" fetchOptions={fetchGetFieldCoordinatesYandex} withDefaultText withoutDefaultOptions />
          <div style={{ height: 'calc(100% - 50px)' }}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              {slicedFilteredRequests?.map((request) => <CardRequest request={request} isEdit={isEdit} />)}
              <div className={`${mainCssClass}__btn-show`}>
                {!!slicedFilteredRequests?.length && slicedFilteredRequests?.length < totalCounts && <Button type="ghost" onClick={handleNewCounts} className={`${mainCssClass}__show`}>Показать еще</Button>}
              </div>
            </NewScrollContainer>
          </div>
        </div>

      )
}

export default memo(ContainerCard)
