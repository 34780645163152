import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import IntlCollator from 'intl'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { TClassName } from '../../../../common/types/props'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import Table from '../../../../common/components/table/Table'
import { TInterview } from '../../../../common/api/interviews'
import { Heading } from '../../../../common/components/Text'
import './ApplicationPriorityTable.scss'
import { TSourseOfCandidates } from '../../types'
import TableAnalytic from '../../../../common/components/table/TableAnalytic'
import { selectPlanFact } from '../../selectors'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('application-priority')
type TProps= {
    sourse: TSourseOfCandidates[]
} & TClassName
type TRowData = {
    priority: string
    business_unit: string
    client: string
    vacancy: string
    chart:number
    quantity_item:number
    quantity_item_close:number
    shortage:number
    responsible_recruter:string
    numbers_works:number
    numbers_perdays_candidate:number
    original_plan: number
    change_plan_title:number
    change_plan:number
    comment:string
    creation_date:number
    date_acceptance_application:number
    people_fired:number
    basic_plan_title:number
    change_plan_title:number

}

const useColumns = (): Column<any>[] => (
  useMemo(
    () => ([
      {
        accessor: 'priority_title',
        Header: 'Приоритет',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'store_title',
        Header: 'Бизнес-юнит',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'customer_title',
        Header: 'Заказчик',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'vacancy_title',
        Header: 'Вакансия',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'schedule_title',
        Header: 'График',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'cost_title',
        Header: 'Кол-во позиций',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'count_accepted_title',
        Header: 'Кол-во закрытых позиций',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'shortage_title',
        Header: 'Недобор',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'responsible_title',
        Header: 'Ответственный рекрутер',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'day_at_work_title',
        Header: 'Кол-во дней в работе',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'average_day',
        Header: 'Среднее кол-во дней на одного кандидата',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'basic_plan_title',
        Header: 'Изначальный план',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'change_plan_title',
        Header: 'Изменение плана',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'comment_title',
        Header: 'Комментарий',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'created_at_title',
        Header: 'Дата создания заявки',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'datetime_starting_title',
        Header: 'Дата взятия в работу заявки',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      },
      {
        accessor: 'fired_count_title',
        Header: 'Количество уволенных',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      }

    ]),
    []
  )
)

const useRowsData = (sourse: TProps[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
    },
    []
  )
  const rows: TRowData[] = useMemo(
    () => (
      sourse?.length ? sourse.map<TRowData>((el) => ({
        priority_title: el.priority_value,
        store_title: el.store_value,
        customer_title: el.customer_value,
        vacancy_title: el.vacancy_value,
        schedule_title: el.schedule_value,
        cost_title: el.cost_value,
        count_accepted_title: el.count_accepted_value,
        shortage_title: el.shortage_value,
        responsible_title: el.responsible_value,
        day_at_work_title: el.day_at_work_value,
        average_day: el.average_days_value,
        basic_plan_title: el.basic_plan_value,
        change_plan_title: el.change_plan_value,
        comment_title: el.comment_value,
        fired_count_title: el.fired_count_value,
        datetime_starting_title: el.datetime_starting_value,
        created_at_title: el.created_at_value
      })) : []
    ),
    [sourse]
  )
  return { rows, handleRowClick }
}
const ApplicationPriorityTable: FC = ({ className }) => {
  const dataPlan = useSelector(selectPlanFact)
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(dataPlan ? dataPlan.analyticRow : [])

  return (
    <div>
      <TableAnalytic
        className={cn(mainCssClass, className)}
        columns={columns}
        data={rows}
        sortabled
        withoutLastColumnElement
        handleRowClick={handleRowClick}
        withoutHeaderDividers
      />
    </div>

  )
}

export default memo(ApplicationPriorityTable)
