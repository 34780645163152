/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, {
  FC, useEffect, useMemo, useState, memo, useCallback, useRef, useLayoutEffect
} from 'react'
import { useParams } from 'react-router'
import {
  Button, Tooltip, Typography,
  message, Upload, Radio
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import { data } from 'autoprefixer'
import {
  reset,
  getCandidates, getDictionaries, setDefDataCandidate
} from '../../ManagamentCandidates/actions'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import { openFilters } from '../../../features/filters/actions'

import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import FiltersIcon from '../../../common/svg/FiltersIcon'

import './Phot.scss'
import {
  selectCandidates,
  selectDefDataCandidate,
  selectHeaders,
  selectLoading

} from '../../ManagamentCandidates/selectors'
import CostOfHiringTable from './PhotTable'
import ButtonsTable from './ButtonsTable'
import FileDownloadGreen from '../../../common/svg/FileDownloadGreen'
import AddPlusSvgGreen from '../../../common/svg/AddPlusSvgGreen'
import ShowGreen from '../../../common/svg/ShowGreen'
import { selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import NewEntryModal from '../../../features/CostOfHiringModals/NewEntryModal'
import { selectIsOpenHidePost, selectIsOpenNewEntry } from '../selectors'
import { setOpenModalHidePost, setOpenModalNewEntry } from '../actions'
import HidePostModal from '../../../features/CostOfHiringModals/HidePostModal'
import PhotTable from './PhotTable'

const mainCssClass = getCssClassName('phot')

const Phot: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const defDataCandidate = useSelector(selectDefDataCandidate)
  const [page, setPage] = useState<number>(defDataCandidate?.page || 1)
  const candidatesList = useSelector(selectCandidates)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const defFilters = useSelector(selectDefFilters)
  const headers = useSelector(selectHeaders)
  const modalNewEntryOpen = useSelector(selectIsOpenNewEntry)
  const modalHidePostOpen = useSelector(selectIsOpenHidePost)
  const prevDefDataCandidateRef = useRef(defDataCandidate)
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataCandidate?.perPage || 10,
      name: defDataCandidate?.search || ''
    }
  })
  const smoke = ''
  const handleNewEntryModalCancel = () => {
    dispatch(setOpenModalNewEntry(false))
  }
  const handleOpenNewEntry = useCallback(() => {
    dispatch(setOpenModalNewEntry(true))
  }, [])
  const handleHidePostModalCancel = () => {
    dispatch(setOpenModalHidePost(false))
  }
  const handleOpenHidePost = useCallback(() => {
    dispatch(setOpenModalHidePost(true))
  }, [])
  type TFormValues = {
    perPagesCount: number
    name: string
  }
  const perPagesCount = watch('perPagesCount')

  const maxPage = useMemo(() => {
    if (headers?.total && headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }
  type TRowData = {
    full_name: string
    plan: number
    implementation_plan: string
    formalized: number
    hours_worked: number
    salary: string
    payment_hour: string
    basic_salary: string
    plan_premium: string
    additional_bonus: string
    additional_bonus_information: string
    total: string
    total_ndfl: string
    up_to_finish_plan: string
    above_plan: string
    fomicheva: string
    x2: string
    interview_positive_character: string
    reutskaya: string
    research_work: string
    buttons: JSX.Element
    id:number
  }
  const data: TRowData[] = [
    {
      full_name: 'Иван Иванов',
      plan: 205,
      implementation_plan: '111,22%',
      formalized: 228,
      hours_worked: 159,
      salary: '70 000,00 ₽',
      payment_hour: '440,25 ₽',
      basic_salary: '70 000,00 ₽',
      plan_premium: '30 000,00 ₽',
      additional_bonus: '20 000,00 ₽',
      additional_bonus_information: 'премия от Туник',
      total: '120 000,00 ₽',
      total_ndfl: '137 931,00 ₽',
      up_to_finish_plan: '137 931,00 ₽',
      above_plan: '137 931,00 ₽',
      fomicheva: '137 931,00 ₽',
      x2: '137 931,00 ₽',
      interview_positive_character: '137 931,00 ₽',
      reutskaya: '137 931,00 ₽',
      research_work: '137 931,00 ₽',
      buttons: <ButtonsTable id={1} />,
      id: 1
    },
    {
      full_name: 'Петр Петров',
      plan: 210,
      implementation_plan: '105,67%',
      formalized: 222,
      hours_worked: 160,
      salary: '75 000,00 ₽',
      payment_hour: '468,75 ₽',
      basic_salary: '75 000,00 ₽',
      plan_premium: '35 000,00 ₽',
      additional_bonus: '25 000,00 ₽',
      additional_bonus_information: 'премия от Иванова',
      total: '135 000,00 ₽',
      total_ndfl: '154 695,00 ₽',
      up_to_finish_plan: '154 695,00 ₽',
      above_plan: '154 695,00 ₽',
      fomicheva: '154 695,00 ₽',
      x2: '154 695,00 ₽',
      interview_positive_character: '154 695,00 ₽',
      reutskaya: '154 695,00 ₽',
      research_work: '154 695,00 ₽',
      buttons: <ButtonsTable id={2} />,
      id: 2
    },
    {
      full_name: 'Александр Александров',
      plan: 215,
      implementation_plan: '115,35%',
      formalized: 248,
      hours_worked: 170,
      salary: '80 000,00 ₽',
      payment_hour: '470,59 ₽',
      basic_salary: '80 000,00 ₽',
      plan_premium: '40 000,00 ₽',
      additional_bonus: '30 000,00 ₽',
      additional_bonus_information: 'премия от Петрова',
      total: '150 000,00 ₽',
      total_ndfl: '171 464,00 ₽',
      up_to_finish_plan: '171 464,00 ₽',
      above_plan: '171 464,00 ₽',
      fomicheva: '171 464,00 ₽',
      x2: '171 464,00 ₽',
      interview_positive_character: '171 464,00 ₽',
      reutskaya: '171 464,00 ₽',
      research_work: '171 464,00 ₽',
      buttons: <ButtonsTable id={3} />,
      id: 3
    },
    {
      full_name: 'Сергей Сергеев',
      plan: 220,
      implementation_plan: '108,18%',
      formalized: 238,
      hours_worked: 180,
      salary: '85 000,00 ₽',
      payment_hour: '472,22 ₽',
      basic_salary: '85 000,00 ₽',
      plan_premium: '45 000,00 ₽',
      additional_bonus: '35 000,00 ₽',
      additional_bonus_information: 'премия от Александрова',
      total: '165 000,00 ₽',
      total_ndfl: '188 990,00 ₽',
      up_to_finish_plan: '188 990,00 ₽',
      above_plan: '188 990,00 ₽',
      fomicheva: '188 990,00 ₽',
      x2: '188 990,00 ₽',
      interview_positive_character: '188 990,00 ₽',
      reutskaya: '188 990,00 ₽',
      research_work: '188 990,00 ₽',
      buttons: <ButtonsTable id={4} />,
      id: 4
    },
    {
      full_name: 'Николай Николаев',
      plan: 225,
      implementation_plan: '112,44%',
      formalized: 253,
      hours_worked: 190,
      salary: '90 000,00 ₽',
      payment_hour: '473,68 ₽',
      basic_salary: '90 000,00 ₽',
      plan_premium: '50 000,00 ₽',
      additional_bonus: '40 000,00 ₽',
      additional_bonus_information: 'премия от Сергеева',
      total: '180 000,00 ₽',
      total_ndfl: '206 518,00 ₽',
      up_to_finish_plan: '206 518,00 ₽',
      above_plan: '206 518,00 ₽',
      fomicheva: '206 518,00 ₽',
      x2: '206 518,00 ₽',
      interview_positive_character: '206 518,00 ₽',
      reutskaya: '206 518,00 ₽',
      research_work: '206 518,00 ₽',
      buttons: <ButtonsTable id={5} />,
      id: 5
    },
    {
      full_name: 'Михаил Михайлов',
      plan: 230,
      implementation_plan: '106,09%',
      formalized: 244,
      hours_worked: 200,
      salary: '95 000,00 ₽',
      payment_hour: '475,00 ₽',
      basic_salary: '95 000,00 ₽',
      plan_premium: '55 000,00 ₽',
      additional_bonus: '45 000,00 ₽',
      additional_bonus_information: 'премия от Николаева',
      total: '195 000,00 ₽',
      total_ndfl: '223 046,00 ₽',
      up_to_finish_plan: '223 046,00 ₽',
      above_plan: '223 046,00 ₽',
      fomicheva: '223 046,00 ₽',
      x2: '223 046,00 ₽',
      interview_positive_character: '223 046,00 ₽',
      reutskaya: '223 046,00 ₽',
      research_work: '223 046,00 ₽',
      buttons: <ButtonsTable id={6} />,
      id: 6
    },
    {
      full_name: 'Владимир Владимиров',
      plan: 235,
      implementation_plan: '110,21%',
      formalized: 259,
      hours_worked: 210,
      salary: '100 000,00 ₽',
      payment_hour: '476,19 ₽',
      basic_salary: '100 000,00 ₽',
      plan_premium: '60 000,00 ₽',
      additional_bonus: '50 000,00 ₽',
      additional_bonus_information: 'премия от Михайлова',
      total: '210 000,00 ₽',
      total_ndfl: '240 584,00 ₽',
      up_to_finish_plan: '240 584,00 ₽',
      above_plan: '240 584,00 ₽',
      fomicheva: '240 584,00 ₽',
      x2: '240 584,00 ₽',
      interview_positive_character: '240 584,00 ₽',
      reutskaya: '240 584,00 ₽',
      research_work: '240 584,00 ₽',
      buttons: <ButtonsTable id={7} />,
      id: 7
    },
    {
      full_name: 'Алексей Алексеев',
      plan: 240,
      implementation_plan: '115,42%',
      formalized: 277,
      hours_worked: 220,
      salary: '105 000,00 ₽',
      payment_hour: '478,64 ₽',
      basic_salary: '105 000,00 ₽',
      plan_premium: '65 000,00 ₽',
      additional_bonus: '55 000,00 ₽',
      additional_bonus_information: 'премия от Владимирова',
      total: '225 000,00 ₽',
      total_ndfl: '259 131,00 ₽',
      up_to_finish_plan: '259 131,00 ₽',
      above_plan: '259 131,00 ₽',
      fomicheva: '259 131,00 ₽',
      x2: '259 131,00 ₽',
      interview_positive_character: '259 131,00 ₽',
      reutskaya: '259 131,00 ₽',
      research_work: '259 131,00 ₽',
      buttons: <ButtonsTable id={8} />,
      id: 8
    },
    {
      full_name: 'Дмитрий Дмитриев',
      plan: 245,
      implementation_plan: '108,57%',
      formalized: 266,
      hours_worked: 230,
      salary: '110 000,00 ₽',
      payment_hour: '479,13 ₽',
      basic_salary: '110 000,00 ₽',
      plan_premium: '70 000,00 ₽',
      additional_bonus: '60 000,00 ₽',
      additional_bonus_information: 'премия от Алексеева',
      total: '240 000,00 ₽',
      total_ndfl: '278 689,00 ₽',
      up_to_finish_plan: '278 689,00 ₽',
      above_plan: '278 689,00 ₽',
      fomicheva: '278 689,00 ₽',
      x2: '278 689,00 ₽',
      interview_positive_character: '278 689,00 ₽',
      reutskaya: '278 689,00 ₽',
      research_work: '278 689,00 ₽',
      buttons: <ButtonsTable id={9} />,
      id: 9
    },
    {
      full_name: 'Евгений Евгеньевич',
      plan: 250,
      implementation_plan: '111,60%',
      formalized: 279,
      hours_worked: 240,
      salary: '115 000,00 ₽',
      payment_hour: '480,42 ₽',
      basic_salary: '115 000,00 ₽',
      plan_premium: '75 000,00 ₽',
      additional_bonus: '65 000,00 ₽',
      additional_bonus_information: 'премия от Дмитриева',
      total: '255 000,00 ₽',
      total_ndfl: '299 258,00 ₽',
      up_to_finish_plan: '299 258,00 ₽',
      above_plan: '299 258,00 ₽',
      fomicheva: '299 258,00 ₽',
      x2: '299 258,00 ₽',
      interview_positive_character: '299 258,00 ₽',
      reutskaya: '299 258,00 ₽',
      research_work: '299 258,00 ₽',
      buttons: <ButtonsTable id={10} />,
      id: 10
    }
  ]

  useEffect(() => {
    if (filtersString && ((!pageId && defFilters?.type === 'candidates') || (pageId === defFilters?.type))) {
      dispatch(setDefDataCandidate({
        page,
        perPage: perPagesCount,
        filters: defDataCandidate?.search
          ? `${filtersString}&search=${defDataCandidate?.search}` : filtersString,
        search: defDataCandidate?.search || ''
      }))
    } else if (!filtersString && defDataCandidate?.search) {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: `&search=${defDataCandidate?.search}`, search: defDataCandidate?.search || ''
      }))
    } else {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: '', search: defDataCandidate?.search || ''
      }))
    }
  }, [page, perPagesCount, filtersString])

  useEffect(() => {
    if (defDataCandidate !== prevDefDataCandidateRef.current && defDataCandidate) {
      prevDefDataCandidateRef.current = defDataCandidate // Обновите предыдущее значение
      dispatch(getCandidates({
        page: defDataCandidate?.page,
        perPage: defDataCandidate?.perPage,
        filters: defDataCandidate?.filters
      }))
    }
  }, [defDataCandidate])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  // @ts-ignore
  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>ФОТ</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton icon="left" size="large" typeButton="secondary" className="button" onClick={handleFiltersOpen}>
                <FiltersIcon />
                Фильтры
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>

          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <div className={`${mainCssClass}__search-container`}>
                <Radio.Group className={`${mainCssClass}__tabs`}>
                  <Radio.Button value="&filter[status][in][]=92">
                    Трудовой договор&nbsp;
                    {/* {actualCount || ''} */}
                  </Radio.Button>
                  <Radio.Button value="&filter[status][in][]=91">
                    ГПХ&nbsp;
                    {/* {considerationCount || ''} */}
                  </Radio.Button>
                </Radio.Group>
              </div>
              <Button type="ghost" icon={<FileDownloadGreen />} className={cn(`${mainCssClass}__excel`)}>
                Выгрузить файл
                {' '}
              </Button>
              <Button type="ghost" icon={<AddPlusSvgGreen />} onClick={handleOpenNewEntry} className={cn(`${mainCssClass}__excel`)}>
                Добавить запись
                {' '}
              </Button>
              <Button type="ghost" icon={<ShowGreen />} className={cn(`${mainCssClass}__excel`)}>
                {' '}
                Скрытые записи
                {' '}
              </Button>
            </div>

            {!!headers?.totalCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === maxPage ? '#BFC5C0' : '#404542'} />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <PhotTable className={`${mainCssClass}__table`} data={data} />
            </NewScrollContainer>
          </div>
          <NewEntryModal
            open={modalNewEntryOpen}
            className={`${mainCssClass}__modals`}
            onCancel={handleNewEntryModalCancel}
          />
          <HidePostModal
            open={modalHidePostOpen}
            className={`${mainCssClass}__modals`}
            onCancel={handleHidePostModalCancel}
          />
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(Phot))
