/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useEffect, useMemo, useRef, useState
} from 'react'

import './FunnelDrop.scss'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Button } from 'antd'
import { loopHooks } from 'react-table'
import { positionValues } from 'react-custom-scrollbars-2'
import {
  selectCandidatesData,
  selectLoading,
  selectDictionaryOptionsByName,
  selectShowModal,
  selectCounters,
  selectMaxPage,
  selectOpenLimitationStageFunnel, selectMessageLimitationFunnel
} from '../selectors'
import {
  changeOrder,
  setShowButton, setShowModal, updateMassStage, updateStage, uploadNewCandidates
} from '../actions'
import { TClassName } from '../../../common/types/props'
import CandidateCard from './CandidateCard'
import Container from './Container'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ModalTransferStage from '../../candidate/components/ModalTransferStage'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { TContainer, TStageRequest } from '../types'
import { selectFilterString } from '../../../features/filters/selectors'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ArrowUpSM from '../../../common/svg/ArrowUpSM'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import StageLimitationModal from '../../../features/stageLimitationModals/components/StageLimitationModal'

const mainCssClass = 'funnel-drop'
export type TProps ={
  requestStatus: string
} & TClassName
const FunnelDrag: FC <TProps> = ({ className, requestStatus, self }) => {
  const dispatch = useDispatch()
  const candidatesData = useSelector(selectCandidatesData)

  // const candidatesData =  [
  //   {
  //       "id": 5102676,
  //       "name": "Иван",
  //       "last_name": "Иванов",
  //       "second_name": "Иванович",
  //       "status": 51,
  //       "stage_id": 1,
  //       "address": null,
  //       "phone": "78641818795",
  //       "city": null,
  //       "updated_at": "2024-04-24 21:21:16",
  //       "stage_total_count": 546755,
  //       "wa_enqueued_count": 0
  //   },
  //   {
  //       "id": 5102671,
  //       "name": "Кандидат",
  //       "last_name": "Кандидатов",
  //       "second_name": "Кандидатович",
  //       "status": 49,
  //       "stage_id": 1,
  //       "address": "",
  //       "phone": "79415774599",
  //       "city": "Москва",
  //       "updated_at": "2024-04-24 21:20:55",
  //       "stage_total_count": 546755,
  //       "wa_enqueued_count": 0
  //   }]
  const loading = useSelector(selectLoading)
  const showModal = useSelector(selectShowModal)
  const openLimitationStageModal = useSelector(selectOpenLimitationStageFunnel)
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const [checkedValues, setCheckedValues] = useState<number[]>([])
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const maxPage = useSelector(selectMaxPage)
  const [checkedDisabled, setCheckedDisabled] = useState<number | null>(null)
  const [candidateArray, setCandidateArray] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const counters = useSelector(selectCounters)
  const filtersString = useSelector(selectFilterString)
  const scrollBarRef = useRef(null)
  const { is_mass_stage } = useEntityContext()
  const selectTextMessage = useSelector(selectMessageLimitationFunnel)
  const closeStageModal = () => {
    dispatch(setShowModal(false))
  }

  const [containers, setContainers] = useState<TContainer[]>([])
  const [isSticky, setIsSticky] = useState(false)

  const handleDragStart = (event, containerId, itemId) => {
    event.dataTransfer.setData('text/plain', JSON.stringify({ containerId, itemId }))
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event, targetContainerId) => {
    event.preventDefault()
    const data = JSON.parse(event.dataTransfer.getData('text/plain'))
    const { containerId, itemId } = data

    if (containerId === targetContainerId) {
      return // Не обновляем состояние, если элемент перетащен в тот же контейнер
    }
    const findedItem = containers?.find((item) => item.id === containerId).items.find((item) => item.id === itemId)
    const updatedContainers = containers.map((container) => {
      if (container.id === containerId) {
        const updatedItems = container.items.filter((item) => item.id !== itemId).sort((a, b) => {
          const aDate = new Date(a.updated_at).getTime()
          const bDate = new Date(b.updated_at).getTime()
          return container.sortOrder === 'asc' ? aDate - bDate : bDate - aDate
        })
        return {
          ...container, items: updatedItems, length: container.length - 1, sortOrder: container.sortOrder ? container.sortOrder : 'asc'
        }
      }
      if (container.id === targetContainerId) {
        // Добавляем элемент в целевой контейнер
        const item = { id: itemId }
        let updatedItems
        if (container?.items) {
          updatedItems = [...container.items, findedItem]
        } else {
          updatedItems = [findedItem]
        }
        if (findedItem) dispatch(updateStage({ stage: targetContainerId, candidate_id: findedItem.id }))
        return { ...container, items: updatedItems, length: container.length + 1 }
      }
      return container
    })

    setContainers(updatedContainers)
    // dispatch(getCounters())
  }
  const handleChangeSortOrder = (containerId, sortOrder) => {
    setCheckedValues([])
    dispatch(changeOrder({
      page, perPage: 10, filters: filtersString, order: sortOrder, value: containerId, self: requestStatus
    }))
  }

  const handleOnChange = (e) => {
    if (e.target.checked) {
      setCheckedValues([...checkedValues, e.target.value])
      dispatch(setShowButton(true))
    }
    if (e.target['data-containerId'] && e.target.checked) {
      setCheckedDisabled(e.target['data-containerId'])
    }
    if (!e.target.checked) {
      const newCheckedValues = checkedValues.filter((item) => item !== e.target.value)
      setCheckedValues(newCheckedValues)
      const newContainers = containers.map((item) => (item.id === Number(e.target['data-containerId']) ? { ...item, length: newCheckedValues.length } : item))
      setContainers(newContainers)
      if (!newCheckedValues?.length) {
        setCheckedDisabled(null)
        dispatch(setShowButton(false))
      }
    }
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target

    if (checked) {
      // Добавить значение флажка в массив
      setCheckedValues((prevValues) => [...prevValues, value])
    } else {
      // Удалить значение флажка из массива
      setCheckedValues((prevValues) => prevValues.filter((val) => val !== value))
    }
  }
  const handleUpdateCheckedValues = (values:number[], containerId) => {
    setCheckedValues(values)
    const newContainers = containers.map((item) => (item.id === Number(containerId) ? { ...item, length: values?.length && !is_mass_stage ? values.length : candidatesData.find((item) => item.id === Number(containerId)).length } : item))
    setContainers(newContainers)
  }
  const handleUpdateCheckedDisabled = (value:number | null) => {
    setCheckedDisabled(value)
  }
  const handleUpdateStage = (data: TStageRequest) => {
    if (is_mass_stage) {
      let newFilterString = filtersString.replace(/filter\[stage_id\]\[in\]\]\=[^&]*&?/, '')
      newFilterString = newFilterString === '' ? `filter[stage_id][in][]=${checkedDisabled}` : `${newFilterString}&filter[stage_id][in][]=${checkedDisabled}`
      newFilterString = newFilterString.replace('&&', '&')
      newFilterString = newFilterString.endsWith('&') ? newFilterString.slice(0, -1) : newFilterString
      dispatch(updateMassStage({ filtersString: newFilterString, stage_id: data.stage, self }))
    } else {
      dispatch(updateStage({
        ...data,
        candidateArray: checkedValues,
        isMass: true
      }))
    }
    closeStageModal()
  }

  const handleNewCandidates = () => {
    dispatch(uploadNewCandidates({
      perPage: 10, page: page + 1, filters: filtersString, self: requestStatus
    }))
    setPage(page + 1)
  }

  const handleScroll = (values: positionValues) => {
    // Вы можете использовать значения прокрутки для определения, нужно ли применять стили
    const { scrollTop } = values
    setIsSticky(scrollTop > 0) // Пример условия, применять стили, если scrollTop больше нуля
  }

  useEffect(() => {
    dispatch(setShowButton(checkedValues.length > 0))
  }, [checkedValues])

  useEffect(() => {
    setContainers(candidatesData)
  }, [candidatesData])

  return (
    <div className={cn(className, mainCssClass)}>
      {loading && (
        <div className={`${mainCssClass}__preloader`}>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      {!loading && (
        <div className={`${mainCssClass}__scroll-div`}>
          <StageLimitationModal open={openLimitationStageModal} text={selectTextMessage} />
          <ModalTransferStage
            open={showModal}
            wrapClassName="wrapModal"
            stageOptions={stageOptions}
            onCancel={closeStageModal}
            onOk={handleUpdateStage}
            isFunnel
          />
          <NewScrollContainer className={`${mainCssClass}__scroll-container`} onScrollFrame={handleScroll}>
            <div className={`${mainCssClass}__drag-menu`}>
              {containers?.map((container, index) => (
                <div className={`${mainCssClass}__container`}>
                  <div className={cn(`${mainCssClass}__header-position`, { [`${mainCssClass}__sticky`]: isSticky })}>
                    <Container
                      key={`${container.id}_${index}`}
                      container={container}
                      className={`${mainCssClass}__header`}
                      setValue={handleUpdateCheckedValues}
                      checkedDisabled={checkedDisabled}
                      setCheckedValue={handleUpdateCheckedDisabled}
                      handleChangeSort={handleChangeSortOrder}
                    />
                  </div>
                  <div
                    key={`${container.id}_${index}`}
                    onDragOver={handleDragOver}
                    className={`${mainCssClass}__container-items`}
                    onDrop={(event) => handleDrop(event, container.id)}
                  >
                    {container?.items?.map((item, index) => (
                      <div
                        key={`${item?.name}_${index}`}
                        draggable
                        onDragStart={(event) => handleDragStart(event, container.id, item.id)}
                        className={`${mainCssClass}__drag-container`}
                      >
                        <CandidateCard
                          key={`${item?.name}_${index}`}
                          candidate={item}
                          containerId={container?.id}
                          handleOnChange={handleOnChange}
                          className={`${mainCssClass}__card`}
                          checkedDisabled={checkedDisabled}
                          checkedValues={checkedValues}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <>
              <div className={`${mainCssClass}__show-div`}>
                <Button type="ghost" onClick={handleNewCandidates} className={`${mainCssClass}__show`}>Показать еще</Button>
              </div>
              <div className={`${mainCssClass}__show-diva`}>
                <Button type="ghost" onClick={handleNewCandidates} className={`${mainCssClass}__show`}>Показать еще</Button>
              </div>
              <div className={`${mainCssClass}__show-divb`}>
                <Button type="ghost" onClick={handleNewCandidates} className={`${mainCssClass}__show`}>Показать еще</Button>
              </div>
            </>

          </NewScrollContainer>
        </div>
      )}
    </div>

  )
}
export default memo<TProps>(withErrorBoundary<TProps>(FunnelDrag))
