import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  onClick?: () => void
}

const mainCssClass = 'pin'

function Pin({ className, size = '1', onClick }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9284 3.86166C14.6386 2.83572 16.1046 2.70353 16.9869 3.58586L20.5122 7.11114C21.3946 7.99347 21.2624 9.45947 20.2364 10.1697L16.8878 12.488C16.6901 12.6249 16.5187 12.7963 16.3819 12.994L14.0636 16.3425C13.3533 17.3685 11.8874 17.5007 11.005 16.6183L7.47974 13.0931C6.59741 12.2107 6.7296 10.7447 7.75554 10.0345L11.1041 7.71621C11.3018 7.57935 11.4732 7.40795 11.6101 7.21025L13.9284 3.86166ZM15.5727 5.00008L19.098 8.52535L15.7494 10.8436C15.354 11.1173 15.0112 11.4601 14.7375 11.8555L12.4192 15.2041L8.89396 11.6789L12.2425 9.3606C12.6379 9.08686 12.9807 8.74407 13.2545 8.34867L15.5727 5.00008Z" fill="#404542" />
      <path d="M10.1066 15.72L8.37799 13.9914L3.16036 20.0118C2.93483 20.272 2.94875 20.6623 3.19224 20.9058C3.43574 21.1493 3.826 21.1632 4.08623 20.9376L10.1066 15.72Z" fill="#404542" />
    </svg>
  )
}
export default Pin
