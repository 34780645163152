import React from 'react'
import Icon from '../svgIcons/PlayPhone.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function PlayPhone(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid" size="lg">
      <Icon />
    </IconWrapper>
  )
}
