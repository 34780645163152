import React from 'react'
import Icon from '../svgIcons/SmallMap.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function SmallMap(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid">
      <Icon />
    </IconWrapper>
  )
}
