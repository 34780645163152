import React, { JSXElementConstructor, useEffect } from 'react'
import cn from 'classnames'

import { Heading } from '../Text'

import { isDefined } from '../../utils/utilsContext'
import { DetailsListItem, TDetailsListProps } from './components/ListItem'
import { DetailsListProps } from './types'
import './styles.scss'

type ModifiedDetailsListProps = Omit<DetailsListProps, 'items'> & {
  items: TDetailsListProps[];
};

export function DetailsList({
  'data-testid': testId = 'detailsList',
  items = [],
  size,
  skeletonOptions = {},
  className,
  isLoading,
  ...props
}: ModifiedDetailsListProps) {
  return (
    <ul className={cn('DetailsList', className)} data-testid={testId} {...props}>
      {items.map((group, idx) => (
        <li key={idx} className="DetailsGroup" data-testid={`${testId}-detailsGroup`}>
          {isDefined(group.label) && (
            <div>
              {' '}
              {group.label}
              {' '}
            </div>
          )}

          {group.items.map((item, index) => (
            <DetailsListItem
              editKey={item.editKey || index}
              item={item}
              editComponent={item.editComponent}
              edit={item.edit}
              size={size}
              data-testid={`${testId}-detailsListItem`}
              isLoading={isLoading}
              className={item.className}
            />
          ))}
        </li>
      ))}
    </ul>
  )
}
