import React, {
  FC, memo, useMemo, useState
} from 'react'
import { useSelector } from 'react-redux'
import { Tag, Typography } from 'antd'
import './CandidateStatusTableRow.scss'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TClassName } from '../../../common/types/props'
import { selectLabelById } from '../selectors'

type TProps = {
  status: number
} & TClassName
const mainCssClass = 'candidate-status-table-row'
const CandidateStatusTableRow: FC<TProps> = ({ status, className }) => {
  const text = useMemo(() => {
    if (status === 155) {
      return 'Зарезервированный'
    }
    if (status === 666) {
      return 'Архивный'
    }
    if (status === 49) {
      return 'Без вакансии'
    }
    if (status === 50) {
      return 'На рассмотрении'
    }
    if (status === 51) {
      return 'Отклоненный'
    }

    return '-'
  }, [status])


  const statusLabel = useSelector(selectLabelById(status, 'candidateStatus'))

  return (
    <div className={cn(className, mainCssClass)}>
      <Typography.Text className={cn(`${mainCssClass}__status`, `${mainCssClass}__status-${status}`)}>{statusLabel}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(CandidateStatusTableRow))
