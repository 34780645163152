import React, { ReactElement } from 'react'

import cn from 'classnames'
import { IconBaseProps } from './IconBaseProps'
import { handleEnterSpacePress } from '../../utils/HandleEnterSpacePress'
import { isDefined } from '../../utils/utilsContext'
import './styles.scss'

export type IconWrapperProps = {
  children: ReactElement;
} & IconBaseProps;

export type IconProps = Omit<IconBaseProps, 'name'>;

const mainCssClass = 'IconWrapper'
export function IconWrapper({
  innerClassName,
  className,
  name,
  children,
  onClick,
  onFocus,
  size,
  tabIndex,
  ...props
}: IconWrapperProps) {
  const svgBaseProps = {
    width: '1.5em',
    height: '1.5em',
    fill: 'currentColor',
    'aria-hidden': 'true',
    'user-select': 'none',
    focusable: 'false'
  }

  const iconClassName = cn({
    [`${mainCssClass}_icon__xxs`]: size === 'xxs',
    [`${mainCssClass}_icon__xs`]: size === 'xs',
    [`${mainCssClass}_icon__sm`]: size === 'sm',
    [`${mainCssClass}_icon__md`]: size === 'md',
    [`${mainCssClass}_icon__lg`]: size === 'lg'
  })

  const isButton = isDefined(onClick)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (isDefined(onClick)) {
      handleEnterSpacePress(event, onClick)
    }
  }

  return (
    <span
      role={isButton ? 'button' : 'img'}
      tabIndex={isButton ? tabIndex || 0 : tabIndex}
      aria-label={name}
      className={cn(mainCssClass, iconClassName, className)}
      onKeyDown={handleKeyPress}
      onClick={onClick}
      onFocus={onFocus}
    >
      {React.cloneElement(children, { ...svgBaseProps, ...props, className: cn(iconClassName, innerClassName) })}
    </span>
  )
}
