/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState, memo, useCallback } from 'react'
import { useParams } from 'react-router'
import { Button, Tooltip, Typography } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isEqual, isInteger } from 'lodash'
import Search from '../../../common/svg/Search'
import { resetAllRequest } from '../../ManagementRequests/actions'
import { resetAllVacancy } from '../../ManagementVacancies/actions'
import {
  selectDefDataLeads,
  selectHeaders,
  selectLeads,
  selectLeadsFilter,
  selectLeadsSearch,
  selectLoading,
  selectOpenModalAutoCall
} from '../selectors'
import {
  getLeads,
  getDictionaries,
  reset,
  exportCSV,
  setSearchLeads,
  openLeadsFilter,
  exportMovementCSV,
  setFilter,
  setOpenModalAutoCall
} from '../actions'
import Excel from '../../../common/svg/Excel'
import { resetAllInterview } from '../../ManagementInterviews/actions'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { setDefStringFunnel } from '../../Funnel/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import { setCountFilters, setDefFilters } from '../../../features/filters/actions'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import { resetAllCandidate } from '../../ManagamentCandidates/actions'
import './Leads.scss'
import LeadsTable from './LeadsTable'
import $api from '../../../common/api/http'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { selectCountFilters } from '../../../features/filters/selectors'
import AutoCallsModal from '../../autocalls/components/AutoCallsModal'
import PhoneSvg from '../../../common/svg/PhoneSvg'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const mainCssClass = getCssClassName('leads')

function Leads({ className }: TClassName) {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const defDataLeads = useSelector(selectDefDataLeads)
  const [page, setPage] = useState<number>(defDataLeads?.page || 1)
  const leads = useSelector(selectLeads)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectLeadsFilter)
  const filtersCount = useSelector(selectCountFilters)
  const headers = useSelector(selectHeaders)
  const search = useSelector(selectLeadsSearch)
  const openModal = useSelector(selectOpenModalAutoCall)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const { rbacControl, download_access } = useEntityContext()
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10,
      name: ''
    }
  })

  type TFormValues = {
    perPagesCount: number
    name: string
  }

  const perPagesCount = watch('perPagesCount')

  const handleCancelModal = () => {
    dispatch(setOpenModalAutoCall(false))
  }

  const maxPage = useMemo(() => {
    if (headers?.total && headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handleFiltersOpen = useCallback(() => {
    dispatch(openLeadsFilter(true))
  }, [])

  const handleExportCSV = () => {
    dispatch(
      exportCSV({
        has_head: true,
        filter: search ? `${filtersString || ''}&search=${search}` : filtersString || ''
      })
    )
  }

  const handleExportMovementCSV = () => {
    dispatch(
      exportMovementCSV({
        has_head: true,
        filter: search ? `${filtersString || ''}&search=${search}` : filtersString || ''
      })
    )
  }
  const handleOpenAutoCall = () => {
    dispatch(setOpenModalAutoCall(true))
  }

  const name = watch('name')
  const perPage = watch('perPagesCount')

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setValue('name', name.trim())
      dispatch(setSearchLeads(name.trim()))
    }
  }

  const handleClickSearch = () => {
    dispatch(setSearchLeads(name.trim()))
  }

  const handleBlurSearch = () => {
    if (!name.trim()) {
      dispatch(setSearchLeads(name.trim()))
      setValue('name', '')
    }
  }

  const handleDeleteSearch = () => {
    dispatch(setSearchLeads(''))
  }

  useEffect(() => {
    dispatch(getDictionaries(['gender', 'candidateStatus', 'WorkSites', 'group']))
    dispatch(setDefFilters(null))
    dispatch(resetAllInterview())
    dispatch(resetAllRequest())
    dispatch(resetAllVacancy())
    dispatch(resetAllCandidate())
    dispatch(setDefStringFunnel(''))
  }, [])

  useEffect(() => {
    console.log(search)
    dispatch(
      getLeads({
        filters: filtersString,
        page,
        perPage,
        search
      })
    )
  }, [search, filtersString, page, perPage])

  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])

  useEffect(() => {
    if (localStorage?.getItem('leads') || localStorage?.getItem('leads') === '') {
      dispatch(setFilter(localStorage.getItem('leads')))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    try {
      const storedFilters = localStorage.getItem(`def${pageId}`)
      if (storedFilters) {
        let filters
        try {
          filters = JSON.parse(storedFilters)
        } catch (parseError) {
          console.error('Error parsing filters:', parseError)
          dispatch(setCountFilters(0))
          return
        }

        if (!isEqual(filters, {})) {
          const filterCount = Object.values(filters).filter((item) =>
            Array.isArray(item) ? item.length > 0 : !!item !== false
          ).length
          dispatch(setCountFilters(filterCount))
        } else {
          dispatch(setCountFilters(0))
        }
      } else {
        dispatch(setCountFilters(0))
      }
    } catch (error) {
      console.error('Error processing filters:', error)
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_______________________Удалить поле НГ*/}
          <ChristmasIcon />
          {/*//___________*/}
          <Typography.Title level={2}>Лиды</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton
                icon="left"
                size="large"
                typeButton="secondary"
                className="button"
                onClick={handleFiltersOpen}
              >
                <FiltersIcon />
                Фильтры
                {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <InputSearchFormControl
              name="name"
              control={control}
              placeholder="Телефон"
              onKeyDown={handleKeyDown}
              onBlur={handleBlurSearch}
              handleDelete={handleDeleteSearch}
            />
            <ControlButton
              typeButton="primary"
              className={`${mainCssClass}__search-container_btn button`}
              icon="left"
              onClick={handleClickSearch}
            >
              <Search />
              Поиск
            </ControlButton>
          </div>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <Typography.Title level={5} className={`${mainCssClass}__title`}>
                Результат:&nbsp;
                {headers?.totalCount || 'неизестно'}
              </Typography.Title>
              {!filtersString ? (
                <>
                  {download_access.leads && (
                    <Tooltip title="Выберите фильтр или введите в поиск" placement="rightBottom">
                      <Button
                        type="ghost"
                        icon={<Excel />}
                        className={cn(`${mainCssClass}__excel`, {
                          [`${mainCssClass}__excel-disabled`]: !filtersString
                        })}
                        disabled={!!filtersString}
                      >
                        {' '}
                        Выгрузить в Excel{' '}
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Выберите фильтр или введите в поиск" placement="rightBottom">
                    <Button
                      type="ghost"
                      icon={<Excel />}
                      className={cn(`${mainCssClass}__excel`, {
                        [`${mainCssClass}__excel-disabled`]: !filtersString
                      })}
                      disabled={!!filtersString}
                    >
                      {' '}
                      Выгрузить движение лидов{' '}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  {download_access.leads && (
                    <Button
                      type="ghost"
                      icon={<Excel />}
                      className={cn(`${mainCssClass}__excel`)}
                      onClick={handleExportCSV}
                      disabled={!filtersString}
                    >
                      {' '}
                      Выгрузить в Excel{' '}
                    </Button>
                  )}
                  <Button
                    type="ghost"
                    icon={<Excel />}
                    className={cn(`${mainCssClass}__excel`)}
                    onClick={handleExportMovementCSV}
                    disabled={!filtersString}
                  >
                    {' '}
                    Выгрузить движение лидов{' '}
                  </Button>
                  <Button
                    type="ghost"
                    icon={<PhoneSvg fill="#2DBE64" />}
                    className={cn(`${mainCssClass}__excel`)}
                    onClick={handleOpenAutoCall}
                    disabled={!filtersString}
                  >
                    Начать автообзвон
                  </Button>
                </>
              )}
            </div>
            {!!headers?.totalCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM
                    className="arrowCursor"
                    size="1"
                    onClick={handlePreviousPage}
                    fill={page === 1 ? '#BFC5C0' : '#404542'}
                  />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM
                    className="arrowCursor"
                    size="1"
                    onClick={handleNextPage}
                    fill={page === maxPage ? '#BFC5C0' : '#404542'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <LeadsTable leads={leads} className={`${mainCssClass}__table`} />
            </NewScrollContainer>
          </div>
          {openModal && <AutoCallsModal open={openModal} onCancel={handleCancelModal} />}
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(Leads))
