import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'

import { Checkbox } from 'antd/lib'
import Vacation from './Vacation'
import { TClassName } from '../../../common/types/props'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { TDATA } from '../../candidate/types'
import { navigateTo } from '../../../common/actions/navigate'
import './CandidateTableMobil.scss'
import CandidatesTableRowMobil from './CandidatesTableRowMobil'
import TableMobil from '../../../common/components/table/TableMobil'
import { OpenInnerMenuMobil } from '../../InnerRouter/actions'
import { openChatMobil } from '../actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
  candidates: TDATA[] | []
} & TClassName
type TRowData = {
  name: JSX.Element
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    // {
    //   accessor: 'checkbox', Header: (<Checkbox />)
    // },
    {
      accessor: 'name',
      Header: 'Кандидат'
    }
  ],
  []
)

const useRowsData = (candidates: TDATA[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    dispatch(navigateTo(`/candidate/${id}`))
    dispatch(openChatMobil(true))
    // modalka chata
  }, [])
  const rows: TRowData[] = useMemo(
    () => (candidates?.length
      ? candidates.map<TRowData>(({
        name, second_name, last_name, id, status, request_list
      }) => ({
        name: (<CandidatesTableRowMobil
          requestLabel={request_list.label || 'Не назначена'}
          name={`${last_name} ${name}
${second_name}`?.length > 60 ? `${`${last_name} ${name} ${second_name || ''}`?.slice(45)}...` : `${last_name} ${name} ${second_name || ''}`}
          className={`${mainCssClass}__name-${status}`}
        />),
        id
      }))
      : []),
    [candidates]
  )
  return { rows, handleRowClick }
}
const CandidatesTableMobil: FC<TProps> = ({ className, candidates }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(candidates)

  return (
    <TableMobil
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidatesTableMobil))
