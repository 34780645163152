import { createAction } from '@reduxjs/toolkit'
import { TVacancy } from 'src/common/api/vacancy'
import {
  TAddTag,
  TCandidateComments,
  TCandidateResponseUpdate,
  TComment,
  TCreateLink,
  TDATA,
  TDelTag,
  TRemoveFromRequest,
  TRequestNote,
  TStageRequest
} from './types'
import { TDictionaries, TDictionariesNames, TOption } from '../../common/types/dictionaries'
import { THistoryCandidateData, TOldHistoryCandidateData, TRequestCandidateComments } from '../../common/api/Candidate'

export const getCandidate = createAction<string>('candidate/getCandidate')
export const getCandidateUpdate = createAction<string>('candidate/getCandidateUpdate')
export const reset = createAction('candidate/rest')
export const setCandidate = createAction<TDATA>('candidate/setCandidate')
export const setLoading = createAction<boolean>('candidate/setLoading')
export const openCreateModal = createAction<boolean>('candidate/openCreateCandidate')
export const openUpdateModal = createAction<boolean>('candidate/openUpdateModal')

export const openSureModal = createAction<boolean>('candidate/openSureModal')
export const createCandidate = createAction<TDATA>('candidate/createCandidate')
export const deleteCandidate = createAction<string>('candidate/deleteCandidate')

export const updateCandidate = createAction<TCandidateResponseUpdate>('candidate/updateCandidate')
export const getDictionaries = createAction<TDictionariesNames>('candidate/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('candidate/dictionaries/setDictionaries')
export const createTagCandidate = createAction<TAddTag>('candidate/createTag')
export const delTagCandidate = createAction<TDelTag>('candidate/delTag')
export const createNewComment = createAction<TComment>('candidate/createComment')
export const updateStage = createAction<TStageRequest>('candidate/updateStage')
export const openArchiveModal = createAction<boolean>('candidate/openArchive')
export const openStatusModal = createAction<boolean>('candidate/openStatusModal')
export const openVacancyModal = createAction<boolean>('candidate/openVacancyModal')
export const openDuplicateModal = createAction<boolean>('candidate/openDuplicateModal')

export const createLinkCandidate = createAction<TCreateLink>('candidate/createLinkCandidate')
export const removeFromRequest = createAction<TRemoveFromRequest>('candidate/removeFromRequest')
export const createNote = createAction<TRequestNote>('candidate/createNote')

export const getQuestionnaire = createAction('candidate/getQuestionnaire')
export const setOpenModalMap = createAction<boolean>('candidate/setOpenModalMap')

export const setQuestionnaire = createAction<any>('candidate/setQuestionnaire')

export type TCandidateQuestionnaire = {
  candidate_id: number
  answers: TAnswer[]
}
export type TAnswer = {
  answer: string
  question_id: number
}
export const createQuestionnaire = createAction<TCandidateQuestionnaire>('candidate/createQuestionnaire')

export const setIsEditModeQuestionnaire = createAction<boolean>('candidate/setIsEditModeQuestionnaire')

export type TGetAllEmptySlots = {
  date: string
  user_id?: number
  isRecruiter?: boolean
  candidate_id: number
}
export const getEmptySlots = createAction<TGetAllEmptySlots>('candidate/getEmptySlots')

export const setLoadingSlots = createAction<boolean>('candidate/setLoadingSlots')
export const setEmptySlots = createAction<string[]>('candidate/setEmptySlots')


export type TAddInterview = {
  data : TRequestUpdateInterview
  isCandidatePage: boolean
}

export type TRequestUpdateInterview = {
    date: string
    slot: string
    questionnaire_id: number
    user_id: number
    candidate_id: number
    vacancy_id: number
    researcher: number
    interview_id?: number
    recruiter: number | null
}
export const addInterview = createAction<TAddInterview>('candidate/addInterview')

export const setNewInterview = createAction<boolean>('candidate/setNewInterview')

export type TRequestAppoint = {
  id: number
  responsible?: number | string| null
  researcher?: number | null | string
  stage_id?: string | number
}

export type TRequestStageStatusCandidate = {
  id: number
  stage_state?: number
}

export const openRecruiterModal = createAction<boolean>('candidate/openRecruiterModal')
export const openReseacherModal = createAction<boolean>('candidate/openReseacherModal')

export const appointRecruiter = createAction<TRequestAppoint>('candidate/appointRecruiter')
export const appointReseacher = createAction<TRequestAppoint>('candidate/appointReseacher')
export const appointAll = createAction<TRequestAppoint>('candidate/appointAll')

export const openStageStatusModal = createAction<boolean>('candidate/openStageStatusModal')
export const stageStatusCandidate = createAction<TRequestStageStatusCandidate>('candidate/stageStatusCandidate')
export const getStageStatusByStage = createAction<number>('candidate/getStageStatusByStage')
export const setStageStatus = createAction<any>('candidate/setStageStatus')

export type TDocument = {
  fileId: string
  candidateId: number
}
export const removeDocuments = createAction<TDocument>('candidate/removeDocuments')

export const getComments = createAction<TRequestCandidateComments>('candidate/getComments')
export const setComments = createAction<TCandidateComments[]>('candidate/setComments')

export type TRequestPatchCandidateComments = {
  id:number
  is_pinned?: boolean
  comment?: string;
}

export const updateComments = createAction<TRequestPatchCandidateComments>('candidate/updateComments')

export const uploudCommentFile = createAction<string>('candidate/uploudCommentFile')
export const setCommentsPage = createAction<number>('candidate/setCommentsPage')

export type TRequestCandidateHistory = {
  page: number
  perPage: number
  filter: string
  candidate_id: number
}

export const getHistoryCandidateData = createAction<TRequestCandidateHistory>('candidate/getHistoryCandidateData')

export const getOldHistoryCandidateData = createAction<TRequestCandidateHistory>('candidate/getOldHistoryCandidateData')
export const setHistoryCandidateData = createAction<THistoryCandidateData>('candidate/setHistoryCandidateData')

export const setOldHistoryCandidateData = createAction<TOldHistoryCandidateData>('candidate/setOldHistoryCandidateData')
export const openHistoryCandidateData = createAction<boolean>('candidate/openHistoryCandidateData')

export const openOldHistoryCandidateData = createAction<boolean>('candidate/openOldHistoryCandidateData')
export const setTotalCommentsPage = createAction<number>('candidate/setTotalCommentsPage')

export const setChatVisible = createAction<'message' | 'history' | 'mail'>('candidate/setChatVisible')
export const setHistoryVariable = createAction<'history' | 'oldHistory'| 'asterisk'>('candidate/setHistoryVisible')

export const setStageModal = createAction<boolean>('candidate/setStageModal')
export const setAssignModal = createAction<boolean>('candidate/setAssignModal')
export const setRejectModal = createAction<boolean>('candidate/setRejectModal')

export type TRequestPhoneFind = {
  phone?: string
  email?:string
  input_id: number
  candidate_id: number
}
export type TRequestInnFind = {
  inn?: string
  candidate_id: number
}

export const getPhoneFind = createAction<TRequestPhoneFind>('candidate/getPhoneFind')
export const setPhoneError = createAction<any>('candidate/setPhoneError')

export const getInnFind = createAction<TRequestInnFind>('candidate/getInnFind')
export const setInnError = createAction<any>('candidate/setInnError')
export const getEmailFind = createAction<TRequestPhoneFind>('candidate/getEmailFind')
export const setEmailError = createAction<any>('candidate/setEmailError')

export const setInformationModal = createAction<boolean>('candidate/setInformationModal')
export const setSelectedComment = createAction<TCandidateComments | null>('candidate/setSelectedComments')

export const openDeleteCommentModal = createAction<boolean>('candidate/openDeleteCommentModal')

export const deleteComment = createAction<TCandidateComments>('candidate/deleteComment')

export const openCommentModal = createAction<boolean>('candidate/openCommentModal')

export type TRequestRemoveRequestLink = {
  candidate_id: number
  request_id: number
}

export const removeRequestLink = createAction<TRequestRemoveRequestLink>('candidate/removeRequestLink')

export const mergeDuplicates = createAction<string>('candidate/mergeDuplicates')

export type TCandidateHeaders = {
  current:number,
  total:number
}

export const setHeaders = createAction<TCandidateHeaders>('candidate/setHeaders')
export const getCandidateDuplicate = createAction<number>('candidate/getCandidateDuplicate')
export const setCandidateDuplicate = createAction<any[]>('candidate/setCandidateDuplicate')

export const getRespondsCandidate = createAction<number>('candidate/getRespondsCandidate')
export const setRespondsCandidate = createAction<any[]>('candidate/setRespondsCandidate')

export type TRequestRespondHandler = {
  candidate_id: number,
  type: string
}

export const respondHandler = createAction<TRequestRespondHandler>('candidate/respondHandler')
export const setIsLoadingRespondHandler = createAction<boolean>('candidate/setIsLoadingRespondHandler')

export const getCandidateMergeDuplicate = createAction<number>('candidate/getCandidateMergeDuplicate')
export const setCandidateMergeDuplicate = createAction<any[]>('candidate/setCandidateMergeDuplicate')

export const openMergeDuplicateModal = createAction<boolean>('candidate/openMergeDuplicateModal')

export const getHistoryAsterisk = createAction<number>('candidate/getHistoryAsterisk')
export const setHistoryAsterisk = createAction<any[]>('candidate/setHistoryAsterisk')

export const openTasksModal = createAction<boolean>('candidate/openTasksModal')

export const showLimitationStageCandidateModal = createAction<boolean>('candidate/showLimitationStageModal')

export const setMessageLimitationCandidate = createAction<string>('candidate/setMessageLimitationCandidate')
export const setCandidateCoordinates = createAction<any>('candidate/setCandidateCoordinates')
export const getStagesListing = createAction('candidate/getStagesListing')
export const setStageListing = createAction<TOption[]>('candidate/setStageListing')

export const openResultInternshipModal = createAction<boolean>('candidate/openResultInternshipModal')

export const getCandidateDocument = createAction<number>('candidate/getCandidateDocument')
export const setCandidateDocument = createAction<any[]>('candidate/setCandidateDocument')

export const getCandidateQuestionnaire = createAction<number>('candidate/getCandidateQuestionnaire')
export const setCandidateQuestionnaire = createAction<any>('candidate/setCandidateQuestionnaire')


export const getHistoryCandidateV2 = createAction<TRequestCandidateHistory>('candidate/getHistoryCandidateV2')
export const setHistoryCandidateV2 = createAction<any>('candidate/setHistoryCandidateV2')
// export const setHeadersCandidateV2

export const openCheckDocModal = createAction<any>('candidate/openCheckDocModal')
