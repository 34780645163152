import cn from 'classnames'
import React from 'react'
import { ScrollContainerProps } from './types'
import { ReactCustomScrollbar } from './ReactCustomScrollBar'

// @ts-ignore
import './styles.scss'

/**
 * ### Когда используется
 *
 * Испольузется когда необоходимо отобразить кастомный скроллбар
 *
 */
const mainCssClass = 'scrollContainer'
export default function ScrollContainer({
  'data-testid': testId = 'scroll',
  children,
  maxHeight = '100%',
  minHeight = '100%',
  translateContentSizesToHolder = false,
  translateContentSizeYToHolder = true,
  translateContentSizeXToHolder = false,
  noIndentY = false,
  scrollbarsRef,
  isCaptureScroll,
  noScrollX,
  isDark,
  contentClassName,
  scrollerClassName,
  trackYStyle,
  thumbYClassName,
  thumbXClassName,
  ...rest
}: ScrollContainerProps) {
  return (
    <ReactCustomScrollbar
      translateContentSizesToHolder={translateContentSizesToHolder}
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      translateContentSizeXToHolder={translateContentSizeXToHolder}
      style={{ maxHeight, minHeight }}
      ref={scrollbarsRef}
      noScrollX={noScrollX}
      wrapperProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return <span {...restProps} ref={elementRef} className={`${mainCssClass}_wrapper`} />
        }
      }}
      contentProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return <span {...restProps} ref={elementRef} className={cn(`${mainCssClass}__content`, contentClassName)} />
        }
      }}
      scrollerProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={cn(scrollerClassName, {
                [`${mainCssClass}_scroller__capture`]: isCaptureScroll
              })}
            />
          )
        }
      }}
      trackYProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={cn(`${mainCssClass}_trackY`, {
                [`${mainCssClass}_trackY__noIndent`]: noIndentY
              })}
              style={trackYStyle}
            />
          )
        }
      }}
      thumbYProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={cn(
                `${mainCssClass}_thumbY`,
                {
                  [`${mainCssClass}_thumbY__dark`]: isDark
                },
                thumbYClassName
              )}
            />
          )
        }
      }}
      trackXProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return <div {...restProps} ref={elementRef} className={`${mainCssClass}_trackX`} />
        }
      }}
      thumbXProps={{
        renderer: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { elementRef, style, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={cn(
                `${mainCssClass}_thumbX`,
                {
                  [`${mainCssClass}_thumbX__dark`]: isDark
                },
                thumbXClassName
              )}
            />
          )
        }
      }}
      data-testid={testId}
      {...rest}
    >
      {children}
    </ReactCustomScrollbar>
  )
}
