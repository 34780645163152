import { createReducer } from '@reduxjs/toolkit'
import { TProductivity, TRejectionInfo, TSourseOfCandidates } from './types'
import {
  TPlanFact,
  reset,
  setDictionaries,
  setLoading,
  setPlanFact,
  setPlanFactVacancy,
  setProductivity,
  setScenario,
  setSourseOfCandidates,
  setVertexInfo,
  setRejectionInfo, TRejectionAny, setVertexMethod
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'

export type TAnalyticsState = {
    sourseOfCandidates : TSourseOfCandidates[] | null
    loading: boolean
    vertexInfo: any
    productivityInfo: TProductivity[] | null
    scenario: string
    dictionaries: TDictionaries | null
    planFactInfo: null | TPlanFact
    planFactVacancy: string
    rejectInfo: TRejectionInfo[] | null
    vertexMethod: string
}

const initialState: TAnalyticsState = {
  sourseOfCandidates: null,
  loading: false,
  vertexInfo: null,
  productivityInfo: null,
  scenario: 'by_user',
  dictionaries: null,
  planFactInfo: null,
  planFactVacancy: 'by_request',
  rejectInfo: null,
  vertexMethod: 'summary'
}

const analyticsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSourseOfCandidates, (state, { payload }) => {
      state.sourseOfCandidates = payload
    })
    .addCase(setVertexInfo, (state, { payload }) => {
      state.vertexInfo = payload
    })
    .addCase(setProductivity, (state, { payload }) => {
      state.productivityInfo = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setScenario, (state, { payload }) => {
      state.scenario = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setPlanFact, (state, { payload }) => {
      state.planFactInfo = payload
    })
    .addCase(setPlanFactVacancy, (state, { payload }) => {
      state.planFactVacancy = payload
    })
    .addCase(setRejectionInfo, (state, { payload }) => {
      state.rejectInfo = payload
    })
    .addCase(setVertexMethod, (state, { payload }) => {
      state.vertexMethod = payload
    })
})

export default analyticsReducer
