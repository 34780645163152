import {
  put, take, takeLatest, spawn, race
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse, AxiosError } from 'axios'
import { TCandidate } from '../candidate/types'
import {
  createCetrificateResume,
  createCoursesResume,
  createEducationResume,
  createExperienceResume,
  createInfoResume,
  createLanguageResume,
  createRecomendationResume,
  createRelocateResume,
  createResume,
  createSkillsResume,
  createTransferTime,
  createWishesResume,
  deleteResume,
  getCandidateResume,
  getDictionaries,
  openCertificateModal,
  openCoursesModal,
  openEditCertificateModal,
  openEditCoursesModal,
  openEditEducationModal,
  openEditInfoModal,
  openEditLanguageModal,
  openEditRecomendationModal,
  openEditRelocateModal,
  openEditSkillsModal,
  openEditTransferModal,
  openEditWishesModal,
  openEducationModal,
  openExperienceModal,
  openInfoModal,
  openLanguageModal,
  openRecomendationModal,
  openRelocateModal,
  openSkillsModal,
  openTransferModal,
  openWishesModal,
  setActiveResume,
  setCandidateResume,
  setDictionaries,
  setLoading
} from './actions'
import {
  TCertificateRequest,
  TCoursesRequest,
  TDeleteResumeRequest,
  TEducationRequest,
  TExperienceRequest,
  TLanguageRequest,
  TRecomendationRequest,
  TRelocateRequest,
  TRequestInfoResume,
  TResume,
  TResumeRequest,
  TSkillsRequest,
  TTransferRequest,
  TWishesRequest
} from './types'
import {
  fetchDeleteCResume,
  fetchGetCandidateResume,
  fetchPatchCreateInfoResume,
  fetchPatchCreateLanguageResume,
  fetchPatchCreateRelocateResume,
  fetchPatchCreateTransferResume,
  fetchPatchCreateWishesResume,
  fetchPostCreateCertificateResume,
  fetchPostCreateCoursesResume,
  fetchPostCreateEducationResume,
  fetchPostCreateExperienceResume,
  fetchPostCreateRecomendationResume,
  fetchPostCreateResume,
  fetchPostCreateSkillsResume
} from '../../common/api/resume'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { setLoadingProgress } from '../InnerRouter/actions'


type CandidateResponse = AxiosResponse<TCandidate>

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'кандидаты резюме')
}

const moduleName = 'Страница резюме'
function* getCandidateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchGetCandidateResume(payload),
      cancel: take(getCandidateResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить резюме',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCandidateResume(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createSkillsResumeSaga({ payload }: PayloadAction<TSkillsRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateSkillsResume(payload),
      cancel: take(createSkillsResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новые ключевые навыки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openEditSkillsModal(false))
      yield put(openSkillsModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createCetrificateResumeSaga({ payload }: PayloadAction<TCertificateRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateCertificateResume(payload),
      cancel: take(createCetrificateResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новый сертификат',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openEditCertificateModal(false))
      yield put(openCertificateModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createRecomendationResumeSaga({ payload }: PayloadAction<TRecomendationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateRecomendationResume(payload),
      cancel: take(createRecomendationResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить рекомендации кандидата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openEditRecomendationModal(false))
      yield put(openRecomendationModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createInfoResumeSaga({ payload }: PayloadAction<TRequestInfoResume>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPatchCreateInfoResume(payload),
      cancel: take(createInfoResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новую дополнительную информацию',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openInfoModal(false))
      yield put(openEditInfoModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createLanguageResumeSaga({ payload }: PayloadAction<TLanguageRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPatchCreateLanguageResume(payload),
      cancel: take(createLanguageResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новый язык',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openLanguageModal(false))
      yield put(openEditLanguageModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createCoursesResumeSaga({ payload }: PayloadAction<TCoursesRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TResume;
    ({response} = yield race({
      response: fetchPostCreateCoursesResume(payload),
      cancel: take(createCoursesResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новые курсы',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openCoursesModal(false))
      yield put(openEditCoursesModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createRelocateResumeSaga({ payload }: PayloadAction<TRelocateRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPatchCreateRelocateResume(payload),
      cancel: take(createRelocateResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось добавить готовность к релакации',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openRelocateModal(false))
      yield put(openEditRelocateModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createTransferTimeSaga({ payload }: PayloadAction<TTransferRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TResume;
    ({response} = yield race({
      response: fetchPatchCreateTransferResume(payload),
      cancel: take(createTransferTime.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось добавить гражданство, разрешение на работу и время в пути',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response))
      yield put(openTransferModal(false))
      yield put(openEditTransferModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createEducationResumeSaga({ payload }: PayloadAction<TEducationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateEducationResume(payload),
      cancel: take(createEducationResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новое образование',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openEducationModal(false))
      yield put(openEditEducationModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createExperienceResumeSaga({ payload }: PayloadAction<TExperienceRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateExperienceResume(payload),
      cancel: take(createExperienceResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новый опыт работы',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openExperienceModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createWishesResumeSaga({ payload }: PayloadAction<TWishesRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPatchCreateWishesResume(payload),
      cancel: take(createWishesResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новые пожелания',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveResume(response.data.DATA))
      yield put(openWishesModal(false))
      yield put(openEditWishesModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createResumeSaga({ payload }: PayloadAction<TResumeRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TResume>;
    ({response} = yield race({
      response: fetchPostCreateResume(payload),
      cancel: take(createResume.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить новое резюме',
          error: response as AxiosError
        })
      )
    } else {
      yield spawn(getCandidateSaga, getCandidateResume(String(payload.candidate_id)))
      yield put(setActiveResume(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* deleteResumeSaga({ payload }: PayloadAction<TDeleteResumeRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const {response} = yield race({response: fetchDeleteCResume(payload), cancel: take(deleteResume.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить резюме',
          error: response as AxiosError
        })
      )
    } else {
      yield fetchDeleteCResume(payload)
      yield spawn(getCandidateSaga, getCandidateResume(String(payload.candidate_id)))
      yield put(setActiveResume(null))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* resumeSaga() {
  yield takeLatest(getCandidateResume, getCandidateSaga)
  yield takeLatest(createSkillsResume, createSkillsResumeSaga)
  yield takeLatest(createCetrificateResume, createCetrificateResumeSaga)
  yield takeLatest(createRecomendationResume, createRecomendationResumeSaga)
  yield takeLatest(createInfoResume, createInfoResumeSaga)
  yield takeLatest(createLanguageResume, createLanguageResumeSaga)
  yield takeLatest(createCoursesResume, createCoursesResumeSaga)
  yield takeLatest(createRelocateResume, createRelocateResumeSaga)
  yield takeLatest(createTransferTime, createTransferTimeSaga)
  yield takeLatest(createEducationResume, createEducationResumeSaga)
  yield takeLatest(createExperienceResume, createExperienceResumeSaga)
  yield takeLatest(createWishesResume, createWishesResumeSaga)
  yield takeLatest(createResume, createResumeSaga)
  yield takeLatest(deleteResume, deleteResumeSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
}

export default resumeSaga
