import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ requestModals }: TRootState) => requestModals

export const selectOpenDuplicateModal = createSelector(selectState, ({ duplicateModal }) => duplicateModal)

export const selectEditMode = createSelector(selectState, ({ editRequestMode }) => editRequestMode)

export const selectOpenRequestDrawer = createSelector(selectState, ({ requestDrawer }) => requestDrawer)

export const selectIsRequestTable = createSelector(selectState, ({ requestTable }) => requestTable)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)
export const selectResponsibleList = createSelector(selectState, ({ responsibleList }) => responsibleList)
