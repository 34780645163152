import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import {
  Button, Tabs, Modal, ModalProps, Select, Switch, Upload, Typography
} from 'antd'
import { useFieldArray, useForm, DefaultValues } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { TVacancy } from '../../../common/api/vacancy'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import { TOption } from '../../../common/types/dictionaries'
import $api from '../../../common/api/http'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { getDictionaries } from '../../../compositions/ManagementVacancies/actions'
import { selectDictionaryOptionsByName } from '../../../compositions/ManagementVacancies/selectors'
import DebounceSelectFormControlCity from '../../../common/components/formcontrol/DebounceSelectFormControlCity'
import {
  extractValue, fetchCitiesList, getGroup, transformArray
} from '../utils'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import UserSquare from '../../../common/svg/UserSquare'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import ControlButton from '../../../common/components/Buttons/Button'
import CloseMD from '../../../common/svg/CloseMD'
import './CreateVacancyModal.scss'
import { createVacancy, updateVacancy } from '../actions'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'

type TFormValues = {
  label: string
  group: number
  city: any
  metro: string
  is_interview_block:boolean
  url_hh: { url: string }[]
} & any

export type TProps = {
  vacancyId?: number | null
  vacancy?: TVacancy
  editMode?: boolean
  isVacancyTable?: boolean
} & ModalProps

const mainCssClass = 'create-vacancy'

function CreateVacancyModal({
  open, className, onCancel, vacancy, vacancyId, isVacancyTable, editMode
}: TProps) {
  const dispatch = useDispatch()
  const userId = localStorage.getItem('user_id')
  const [groupOptions, setGroupOptions] = useState<any>([])

  const defaultValues = useMemo(
    () => (editMode && vacancy
      ? {
        city: { value: vacancy.city, label: vacancy.city, key: vacancy.city_kladr_id },
        metro: { value: vacancy.metro },
        label: vacancy.label,
        group: vacancy.group,
        is_interview_block:vacancy.is_interview_block,
        url_hh: vacancy.url_hh && vacancy.url_hh.length > 0 ? vacancy.url_hh.map((el) => (typeof el === 'object' ? ({ url: el.href }) : { url: el })) : { url: '' }
      }
      : {}),
    [vacancy, editMode]
  )

  const [cityChanged, setCityChanged] = useState(false)

  const {
    control, watch, getValues, reset, handleSubmit, setValue
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields,
    append,
    prepend,
    remove,
    insert
  } = useFieldArray({
    control,
    name: 'url_hh'
  })

  useEffect(() => {
    getGroup()
      .then((responseData) => {
        setGroupOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  const fetchMetroList = useCallback(async (name: string) => {
    if (watch('city').key) {
      const data = await $api.get(`/api/v1/dadata/suggest-metro?search=${name}&city_kladr_id=${watch('city').key}
    `)
      return data.data.DATA.map((obj: { data: { name: string } }) => ({
        label: `${obj.data.name}`,
        value: obj.data.name
      }))
    }
  }, [watch('city')])

  const onSubmit = useCallback(() => {
    const values = getValues()
    const updatedValues = {
      ...values,
      city: extractValue(values.city),
      metro: extractValue(values.metro),
      url_hh: transformArray(values.url_hh)
    }

    if (vacancyId) {
      dispatch(
        updateVacancy({
          data: {
            id: vacancyId,
            ...updatedValues
          },
          isVacancyTable: true
        })
      )
    } else {
      dispatch(
        createVacancy({
          status: 137,
          author: Number(userId) || 0,
          is_favorite: false,
          ...updatedValues
        })
      )
    }
  }, [vacancyId, isVacancyTable])

  const { city } = defaultValues

  if (fields?.length === 0) {
    prepend({ url: '' })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(() => {
    if (JSON.stringify(city) !== JSON.stringify(watch('city'))) {
      setValue('metro', '')
    }
  }, [watch('city')])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={editMode ? 'Редактировать вакансию' : 'Новая вакансия'}
      className={cn(className, mainCssClass)}
      width="960px"
      typeButtonAccept="primary"
      okText="Применить"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div style={{ height: '500px' }}>
        <NewScrollContainer style={{ height: '100%' }}>

          <div className={`${mainCssClass}_content`}>
            <div className={`${mainCssClass}_content_action`}>
              <div className={`${mainCssClass}_content_action_items`}>
                <div className={`${mainCssClass}_content_action_items_title`}>
                  <div>
                    <UserSquare />
                  </div>
                  <div>
                    <Typography.Title level={4}>Описание вакансии</Typography.Title>
                    <Typography.Text>
                      Расскажите кандидатам о будущих обязанностях и преимуществах работы в компании
                    </Typography.Text>
                  </div>
                </div>
                {!editMode && (
                  <div className={`${mainCssClass}_content_action_items_description`}>
                    <Typography.Text>
                      После добавления вакансии, вам станет доступно редактирование информации о ней и привязка вакансий с
                      работных сайтов
                    </Typography.Text>
                  </div>
                )}
              </div>
            </div>
            <div className={`${mainCssClass}_content_list`}>
              <Typography.Title level={3}>Описание вакансии</Typography.Title>
              <InputFormControl
                name="label"
                control={control}
                required
                label="Название вакансии"
                rules={{ required: true }}
              />
              <div className={`${mainCssClass}__switch-container`}>
                <SwitchFormControl name="is_interview_block" control={control} />
                <span>Нельзя назначать интервью</span>
              </div>
              <DebounceSelectFormControlCity
                name="city"
                control={control}
                fetchOptions={fetchCitiesList}
                label="Город"
                notFoundContent="Начните вводить название города"
                required
                className={`${mainCssClass}_content_list_city`}
                labelInValue
              />
              {watch('city') && (
                <DebounceSelectFormControl
                  name="metro"
                  control={control}
                  fetchOptions={fetchMetroList}
                  label="Метро"
                  notFoundContent="Начните вводить название метро"
                  key={watch('city')?.key}
                />
              )}
              <SelectFormControl
                name="group"
                control={control}
                options={groupOptions}
                label="Группа"
                required
                rules={{ required: true }}
              />

              <div className={`${mainCssClass}_content_list_url`}>
                {fields?.map((url_hh, index) => (index === 0 ? (
                  <div className={`${mainCssClass}_content_list_url_first`} key={url_hh.id}>
                    <InputFormControl
                      name={`url_hh[${index}].url`}
                      control={control}
                      label="Ссылка"
                    />
                    <ControlButton
                      icon="iconOnly"
                      className="button"
                      typeButton="secondary"
                      onClick={() => {
                        insert(1, { url: '' }) // Вставляем пустой инпут
                      }}
                    >
                      <AddPlusSvg />
                    </ControlButton>
                  </div>
                ) : (
                  <div className={`${mainCssClass}_content_list_url_other`} key={url_hh.id}>
                    <InputFormControl
                      name={`url_hh[${index}].url`}
                      control={control}
                      label="Ссылка"
                    />
                    <ControlButton icon="iconOnly" className="button" typeButton="warning" onClick={() => handleRemove(index)}>
                      <CloseMD />
                    </ControlButton>
                  </div>
                )))}
              </div>

            </div>
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default CreateVacancyModal
