import { Typography } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import dayjs from 'dayjs'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { getDayOfWeek } from '../../../compositions/Calendar/utils'

import './Workload.scss'
import { selectWorkload } from '../selectors'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import { splitArray } from '../utils'

const mainCssClass = getCssClassName('workload')

function Workload() {
  const objSlot = useSelector(selectWorkload)
  const [stroke, setStroke] = useState<any>([])

  const handleOpen = (time: string) => {
    setStroke([...stroke, time])
  }

  const handleClose = (time :string) => {
    setStroke(stroke.filter((el : string) => el !== time))
  }

  return (
    <div className={mainCssClass}>
      <table className={`${mainCssClass}__table`}>
        <thead>
          <tr>
            <th>
              <Typography.Title
                level={5}
              >
                Время
              </Typography.Title>
            </th>
            <th>
              <div className={`${mainCssClass}__headers`}>
                <Typography.Paragraph className={`${mainCssClass}__headers_day`}>
                  {getDayOfWeek(objSlot.date)}
                  {' '}
                </Typography.Paragraph>
                <Typography.Paragraph className={`${mainCssClass}__headers_date`}>
                  {dayjs(objSlot.date, 'DD.MM.YYYY').format('DD MMMM')}
                </Typography.Paragraph>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {objSlot.slot_list.map((slot) => (
            <tr key={slot.time}>
              <td>
                <Typography.Paragraph className={`${mainCssClass}__headers-font`}>{slot.time}</Typography.Paragraph>
              </td>
              <td>
                <div className={cn(
                  `${mainCssClass}__recruiter-list`,
                  {
                    [`${mainCssClass}__recruiter-list_empty`]: slot.recruiter_list?.count === 0
                  }
                )}
                >
                  {' '}
                  <Typography.Paragraph className={cn(
                    `${mainCssClass}__recruiter-list_count`,
                    {
                      [`${mainCssClass}__recruiter-list_empty_count`]: slot.recruiter_list?.count === 0
                    }
                  )}
                  >
                    Свободных рекрутеров:
                    {' '}
                    {slot.recruiter_list?.count || 0}
                  </Typography.Paragraph>
                  {Number(slot.recruiter_list?.count) <= 10 ? (
                    <Typography.Paragraph className={cn(
                      `${mainCssClass}__recruiter-list_fio`,
                      {
                        [`${mainCssClass}__recruiter-list_empty_fio`]: slot.recruiter_list?.count === 0
                      }
                    )}
                    >
                      {slot.recruiter_list?.count && slot.recruiter_list?.count !== 0

                        ? slot.recruiter_list?.recruiter_label.join(', ') : 'Свободных рекрутеров нет'}
                    </Typography.Paragraph>
                  )
                    : (
                      <Typography.Paragraph className={cn(
                        `${mainCssClass}__recruiter-list_fio`
                      )}
                      >
                        {!stroke.includes(slot.time)
                          ? (
                            <div>
                              <CaretDownMD onClick={() => handleOpen(slot.time)} />
                            </div>
                          )
                          : (
                            <div>
                              <CaretUpMD onClick={() => handleClose(slot.time)} />
                            </div>
                          )}

                        {!stroke.includes(slot.time)
                            && (
                              <div>
                                {`${slot.recruiter_list?.recruiter_label.slice(0, 10).join(', ')}
                                ,...`}
                              </div>
                            )}

                        {stroke.includes(slot.time)
                            && (
                              <div className={`${mainCssClass}__recruiter-list_fio_container`} style={{ display: 'flex', gap: '10px' }}>
                                { splitArray(slot.recruiter_list?.recruiter_label).map((item, index) => (
                                  <div key={index} style={{ flex: '1' }} className={`${mainCssClass}__recruiter-list_fio_container_column`}>
                                    {item.map((fio) => (
                                      <div
                                        className={`${mainCssClass}__recruiter-list_fio_container_column_item`}
                                      >
                                        {fio}
                                      </div>
                                    ))}

                                  </div>
                                ))}
                              </div>
                            )}

                      </Typography.Paragraph>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Workload
