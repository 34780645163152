import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'history-responded'

function HistoryResponded({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#FFD6D1" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.9709 12.5985C25.0318 12.7129 26.1357 12.5016 27.384 11.5255C27.6853 11.2899 28.0945 11.2468 28.4382 11.4144C28.7819 11.5819 29 11.9308 29 12.3132V23.6867C29 23.9943 28.8584 24.2849 28.616 24.4744C26.9552 25.773 25.3318 26.1303 23.7564 25.9604C22.2804 25.8012 20.8849 25.1775 19.6593 24.6297C19.6368 24.6197 19.6144 24.6097 19.5921 24.5997C18.2883 24.0171 17.1624 23.5236 16.0291 23.4014C15.0783 23.2988 14.0929 23.458 13 24.1948V29C13 29.5523 12.5523 30 12 30C11.4477 30 11 29.5523 11 29V12.3133C11 12.0056 11.1416 11.7151 11.384 11.5255C13.0448 10.2269 14.6682 9.86964 16.2436 10.0395C17.7196 10.1987 19.1151 10.8224 20.3407 11.3702C20.3632 11.3802 20.3856 11.3903 20.4079 11.4003C21.7117 11.9828 22.8376 12.4763 23.9709 12.5985ZM13 21.9128C14.1008 21.4231 15.1831 21.2985 16.2436 21.4129C17.7196 21.5721 19.1151 22.1958 20.3407 22.7436C20.3632 22.7536 20.3856 22.7637 20.4079 22.7737C21.7117 23.3562 22.8376 23.8497 23.9709 23.9719C24.9217 24.0745 25.9071 23.9153 27 23.1785V14.0871C25.8992 14.5768 24.8169 14.7014 23.7564 14.587C22.2804 14.4278 20.8849 13.8041 19.6593 13.2563C19.6368 13.2463 19.6144 13.2363 19.5921 13.2263C18.2883 12.6437 17.1624 12.1502 16.0291 12.028C15.0783 11.9255 14.0929 12.0846 13 12.8214V21.9128Z" fill="#F5695A" />
    </svg>

  )
}
export default HistoryResponded
