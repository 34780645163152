import { createReducer } from '@reduxjs/toolkit'
import {
  setCandidates,
  setLoading,
  setOpenModalArchive,
  setOpenModalChangeStatus,
  setOpenModalAssignVacancy,
  setDictionaries,
  setSelectedCandidateId,
  reset,
  setDefDataCandidate,
  resetAllCandidate,
  setHeaders, openChatMobil, setSelectedStatus, setShowMassStage,
  setDashboard,
  setDashboardFilter,
  setLoadingDashboard, setDisabledDashboard
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'
import { TCandidatesData, TDATA } from '../candidate/types'
import { TDashaboard } from './types'

type TManagementCandidateState = {
  candidates: null | TCandidatesData
  openModalArchive: boolean
  dictionaries: TDictionaries | null
  openModalChangeStatus: boolean
  openModalAssignVacancy: boolean
  loading: boolean
  selectedCandidateId: number | null | string
  defDataCandidate: {
    page:number,
    perPage: number,
    filters: string,
    search: string
  } | null
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
  openChat:boolean
  selectedStatus: number | null
  openModalMassStage: boolean
  dashboard: TDashaboard | null
  dashboardFilter: string
  loadingDashboard: boolean
  disabledDashboard: boolean
}

const initialState: TManagementCandidateState = {
  candidates: null,
  openModalArchive: false,
  dictionaries: null,
  openModalChangeStatus: false,
  openModalAssignVacancy: false,
  loading: false,
  selectedCandidateId: null,
  defDataCandidate: null,
  headers: null,
  openChat: false,
  selectedStatus: null,
  openModalMassStage: false,
  dashboard: null,
  dashboardFilter: '',
  loadingDashboard: false,
  disabledDashboard: false
}


const managementCandidateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCandidates, (state, { payload }) => {
      state.candidates = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(openChatMobil, (state, { payload }) => {
      state.openChat = payload
    })
    .addCase(setOpenModalArchive, (state, { payload }) => {
      state.openModalArchive = payload
    })
    .addCase(setOpenModalChangeStatus, (state, { payload }) => {
      state.openModalChangeStatus = payload
    })
    .addCase(setOpenModalAssignVacancy, (state, { payload }) => {
      state.openModalAssignVacancy = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setSelectedCandidateId, (state, { payload }) => {
      state.selectedCandidateId = payload
    })
    .addCase(reset, (state) => ({
      ...initialState,
      defDataCandidate: state.defDataCandidate
    }))
    .addCase(setDefDataCandidate, (state, { payload }) => {
      state.defDataCandidate = payload
    })
    .addCase(resetAllCandidate, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setSelectedStatus, (state, { payload }) => {
      state.selectedStatus = payload
    })
    .addCase(setShowMassStage, (state, { payload }) => {
      state.openModalMassStage = payload
    })
    .addCase(setDashboard, (state, { payload }) => {
      state.dashboard = payload
    })
    .addCase(setDashboardFilter, (state, { payload }) => {
      state.dashboardFilter = payload
    })
    .addCase(setLoadingDashboard, (state, { payload }) => {
      state.loadingDashboard = payload
    })
    .addCase(setDisabledDashboard, (state, { payload }) => {
      state.disabledDashboard = payload
    })

})

export default managementCandidateReducer
