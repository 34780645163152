import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'


export const openReportFilters = createAction<boolean>('reportFilter/openFilters')
export const reset = createAction('reportFilter/reset')

export const getDictionaries = createAction<TDictionariesNames>('reportFilter/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('reportFilter/dictionaries/setDictionaries')

export const setTypeReportFilter = createAction<string>('reportFilter/setTypeReportFilter')

export const setFilter = createAction<string>('reportFilter/setFilter')

export const setResetFilter = createAction<number>('reportFilter/setResetFilter')