/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import Required from '../../svg/Required'

import './DebounceSelectFormControl.scss'
import { TOption } from '../../types/dictionaries'
import { setYandexObjects } from '../../../compositions/Systems'

const { Title } = Typography

type CombinedProps = Omit<
  UseControllerProps &
    SelectProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      fetchOptions: (search: string, id?: string | number) => Promise<TOption[]>
      debounceTimeout?: number
      required?: boolean
  withDefaultText?: boolean
  withoutDefaultOptions?: boolean
  isYandex?: boolean
    },
  'isDisabled'
>

const mainCssClass = 'debounce-select'

function DebounceSelectFormControl({
  name,
  control,
  rules,
  fetchOptions,
  debounceTimeout = 1000,
  label,
  required,
  withDefaultText,
  withoutDefaultOptions = false,
  isYandex,
  defaultValue,
  ...rest
}: CombinedProps) {
  const { field, fieldState } = useController({ name, control, rules })
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<TOption[]>([])
  const fetchRef = useRef(0)
  const [searchValue, setSearchValue] = useState<string>('')

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        if (isYandex) {
          dispatch(setYandexObjects(newOptions))
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const handleSelectChange = (selectedOption: TOption) => {
    const { value, label } = selectedOption
    field.onChange(value)
    setSearchValue(label)
  }
  const handleInputChange = (value: string) => {
    if (value) {
      setSearchValue(value)
      if (value.length > 3 && field.label !== searchValue) {
        debounceFetcher(value)
      }
    } if (searchValue.length === 1 && !value) {
      setSearchValue(value)
    }
  }

  useEffect(() => {
    if (!withoutDefaultOptions) {
      fetchOptions('')
        .then((defaultOptions) => {
          setOptions(defaultOptions)
        })
    }
  }, [withoutDefaultOptions])

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])

  return (
    <div className={mainCssClass}>
      {label && (
        <Title className="textAreaLabel" level={4}>
          {label}
          {required && <Required className="required" />}
        </Title>
      )}

      <Select
        labelInValue
        showSearch
        filterOption={false}
        onSearch={handleInputChange}
        searchValue={searchValue}
        className={fieldState.error ? 'error' : ''}
        {...rest}
        defaultValue={field.value ? field.value : ''}
        value={field.value}
        options={options}
        onChange={handleSelectChange}
      />
    </div>
  )
}

export default DebounceSelectFormControl
