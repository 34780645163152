/* eslint-disable max-len */
import { TOption } from '../../common/types/dictionaries'
import $api from '../../common/api/http'

export function extractValue(field: any): number {
  if (field) {
    return typeof field === 'object' ? field.value : field
  }
  return field
}

export function generateFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const email_like = `[email][like]=${words[i]}`
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(email_like, name_like, surname_like)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return query
}

export async function fetchRequestList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/users?page=1&per-page=100&fields=id,name,surname,email')

    return data.DATA.map((obj: { id: number; name: string; surname: string; email: string }) => ({
      label: `${obj.name} ${obj.surname} ${obj.email}`,
      value: obj.id
    }))
  }

  if (name) {
    const filtredName = generateFilters(name.split(' '))

    const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&fields=id,name,surname,email&filter${filtredName}`)

    return data.DATA.map((obj: { id: number; name: string; surname: string; email: string }) => ({
      label: `${obj.name} ${obj.surname} ${obj.email}`,
      value: obj.id
    }))
  }

  return []
}

export async function fetchStoreList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const data = await $api.get('/api/v1/stores?page=1&per-page=10&fields=id,name,city&filter[is_archive]=0')

    return data.data.DATA.map((obj: { id: number; name: string; city:string}) => ({
      label: obj.name,
      value: obj.id,
      title: obj.city
    }))
  }

  if (name) {
    const data = await $api.get(`/api/v1/stores?page=1&per-page=100&fields=id,name,city&filter[name][like]=${name}&filter[is_archive]=0`)

    return data.data.DATA.map((obj: { id: number; name: string;city:string }) => ({
      label: obj.name,
      value: obj.id,
      title: obj.city
    }))
  }

  return []
}

export async function fetchVacancyList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/vacancies?page=1&per-page=10&fields=id,label&filter[status][in][]=137&filter[status][in][]=138')

    return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
      label: `${obj.label}`,
      value: obj.id
    }))
  }

  if (name) {
    const { data } = await $api.get(`/api/v1/vacancies?page=1&per-page=100&fields=id,label&filter[status][in][]=137&filter[status][in][]=138&filter[label][like]=${name}`)

    return data.DATA.vacancy_list.map((obj: { id: number; label: string }) => ({
      label: `${obj.label}`,
      value: obj.id
    }))
  }
  return []
}

export const resetDefaultValues = () => ({
  curator: null,
  description: '',
  cost: '',
  schedule_id_list: [],
  customer: [],
  priority_id: null,
  store_id: null,
  vacancy_id: null,
  responsible: null,
  address_internship: null,
  is_internship: false,
  is_student: false,
  is_patent: false,
  responsible_internship: '',
  responsible_number_internship: '',
  reward_average_day: '',
  reward_average_month: '',
  candidate_age: '',
  characteristic_list: [],
  education_id: null,
  mileage_average_day: '',
  reward_average_order: '',
  income_average_day: '',
  flag: false,
  nominate_me: false,
  responsible_id_list: []
})
