import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ calendarSettings }: TRootState) => calendarSettings

export const selectOpenSettingsCalendar = createSelector(selectState, ({ openSettings }) => openSettings)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectEmptySlots = createSelector(selectState, ({ emptySlots }) => emptySlots?.map((item: string) => ({ value: item, label: item })) || [])
export const selectReserveSlots = createSelector(selectState, ({ reserveSlots }) => reserveSlots)

export const selectBlockedSlots = createSelector(selectState, ({ blockedSlots }) => blockedSlots)
export const selectHolidays = createSelector(selectState, ({ holidays }) => holidays?.filter((item) => item.is_blocked === 1))

export const selectEmptySlotsTimeStop = createSelector(selectState, ({ emptySlotsTimeStop }) => emptySlotsTimeStop?.filter(el => el !== 'without_video').map((item: string) => ({ value: item, label: item })) || [])

export const selectActiveDateChange = createSelector(selectState, ({ activeDateChange }) => activeDateChange)
