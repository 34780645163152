import React, {
  memo,
  useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { setInformationModal } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

type TFormValues = {
  reason: string
  comment: string
}

export type TProps = {
} & ModalProps
function InformationModal({
  open, className
}: TProps) {
  const dispatch = useDispatch()
  const iframeRef = useRef(null)
  const navigate = useNavigate()

  const goBack = () => {
    // if (iframeRef.current) {
    //   iframeRef.current.window.history.go(-1);
    // }
    navigate(-1)
  }

  const goForward = () => {
    navigate(1)
  }

  const handleCancel = () => {
    dispatch(setInformationModal(false))
  }

  const onSubmit = () => {
    // Ваша логика при отправке формы
  }

  return (
    <CommonModal
      open={open}
      centered
      onCancel={handleCancel}
      title="Информация"
      className={cn(className)}
      width="1600px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      footer={null}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button type="button" onClick={goBack}>Назад</button>
          <button type="button" onClick={goForward}>Вперед</button>
        </div>

        <iframe
          src="https://vkusvill-confluence-prod.fraxis.ru/"
          width="100%"
          height="800px"
          title="Ваш iframe"
          ref={iframeRef}
        />
      </div>
    </CommonModal>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(InformationModal))
