/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import IntlCollator from 'intl'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { TClassName } from '../../../../common/types/props'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import Table from '../../../../common/components/table/Table'
import { TInterview } from '../../../../common/api/interviews'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './ProductivityTable.scss'
import TableAnalytic from '../../../../common/components/table/TableAnalytic'
import { selectDictionaryOptionsByName, selectScenario } from '../../selectors'

dayjs.extend(customParseFormat)

const mainCssClass = getCssClassName('productivity-table')

type TRowData = {
    candidate_name: string
    workload: string
    responded: string
    added: string
    without_bot:string
    telephone_interview:string
    invited:string
    with_bot:string
    failed_bot:string
    reject_bot:string
    interview_manager:string
    positive:string
    education:string
    internship:string
    decor:string
    accepted:string
    fired:string
}
const mockInterviewsData = [
  {
    candidate_name: 'Иван Иванов',
    workload: 'Высокая',
    responded: 'Да',
    added: '2023-09-01',
    without_bot: 'Нет',
    telephone_interview: 'Да',
    invited: 'Да',
    with_bot: 'Да',
    failed_bot: 'Нет',
    reject_bot: 'Нет',
    interview_manager: 'Петр Петров',
    positive: 'Да',
    education: 'Высшее',
    internship: 'Нет',
    decor: 'Да',
    accepted: 'Да',
    fired: 'Нет',
    id: 1
  },
  {
    candidate_name: 'Анна Смирнова',
    workload: 'Средняя',
    responded: 'Да',
    added: '2023-09-02',
    without_bot: 'Да',
    telephone_interview: 'Да',
    invited: 'Да',
    with_bot: 'Нет',
    failed_bot: 'Да',
    reject_bot: 'Нет',
    interview_manager: 'Мария Иванова',
    positive: 'Нет',
    education: 'Среднее',
    internship: 'Да',
    decor: 'Нет',
    accepted: 'Нет',
    fired: 'Да',
    id: 2
  },
  {
    candidate_name: 'Павел Сидоров',
    workload: 'Низкая',
    responded: 'Нет',
    added: '2023-09-03',
    without_bot: 'Нет',
    telephone_interview: 'Нет',
    invited: 'Нет',
    with_bot: 'Нет',
    failed_bot: 'Нет',
    reject_bot: 'Нет',
    interview_manager: 'Иван Петров',
    positive: 'Нет',
    education: 'Высшее',
    internship: 'Нет',
    decor: 'Да',
    accepted: 'Нет',
    fired: 'Нет',
    id: 3
  }]

const useColumns = (): Column<any>[] => {
  const arr = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const scenario = useSelector(selectScenario)

  return useMemo(() => {
    let columns: Column<any>[] = []

    if (arr.length > 0) {
      columns.push({
        accessor: 'by_user',
        Header: scenario === 'by_user' ? 'Сотрудник' : 'Название',
        sortType: (rowA, rowB, columnId, desc) => {
          const valueA = rowA.original[columnId]
          const valueB = rowB.original[columnId]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
          }

          return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
        }
      })

      if (scenario === 'by_user') {
        columns = [
          ...columns,
          {
            accessor: 'role',
            Header: 'Роль',
            sortType: (rowA, rowB, columnId, desc) => {
              const valueA = rowA.original[columnId]
              const valueB = rowB.original[columnId]

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
              }

              return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
            }
          },
          {
            accessor: 'workload',
            Header: 'Загруженность',
            sortType: (rowA, rowB, columnId, desc) => {
              const valueA = rowA.original[columnId]
              const valueB = rowB.original[columnId]

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
              }

              return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
            }
          }
        ]
      }

      columns = [
        ...columns,
        ...arr.filter(el=> el.label !== 'Не выбрано').map((el) => ({
          accessor: el.label,
          Header: el.label,
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        }))
      ]
    }

    return columns
  }, [arr, scenario])
}

const useRowsData = (productivity) => {
  const employeeNames = useMemo(() => (productivity?.[0]?.analyticRow
    ? productivity[0].analyticRow
      .map((item) => item.label)
    : []), [productivity])
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
    },
    []
  )
  const rows: any[] = useMemo(
    () => (productivity?.[0]?.analyticRow.length
      ? productivity?.[0]?.analyticRow.map<any>(({
        label,
        count,
        detail,
        id,
        role_name
      }) => {
        const by_user = employeeNames.includes(label) ? label : '-'
        const workload = detail.find((item) => item.label === 'Загруженность')?.count || '-'
        const role = role_name || '-'

        const detailObject = detail.reduce((acc, el) => {
          acc[el.label] = el.count
          return acc
        }, {})

        return {
          by_user,
          workload,
          role,
          ...detailObject,
          id
        }
      })
      : []),
    [productivity, employeeNames]
  )

  return { rows, handleRowClick }
}

const ProductivityTable: FC = ({ productivity }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(productivity)
  return (
    <div>
      <TableAnalytic
        className={cn(mainCssClass)}
        columns={columns}
        data={rows}
        sortabled
        withoutLastColumnElement
        handleRowClick={handleRowClick}
        withoutHeaderDividers
      />
    </div>

  )
}

export default memo(withErrorBoundary(ProductivityTable))
