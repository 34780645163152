import React, {
  memo, useCallback
} from 'react'
import {
  ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import './ArchiveCandidateModal.scss'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { archiveCandidate } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'archive-candidate'

type TFormValues = {
  reject_id: string
  reject_comment: string
}

export type TProps = {
  options: TOption[]
  candidateId?: number | null | string
  isCandidateTable: boolean
} & ModalProps
function ArchiveCandidateModal({
  open, className, onCancel, options, candidateId, isCandidateTable
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, handleSubmit
  } = useForm<TFormValues>()

  const reject_id = watch('reject_id')
  const reject_comment = watch('reject_comment')

  const onSubmit = () => {
    if (candidateId) {
      dispatch(
        archiveCandidate({
          status: 51,
          id: candidateId,
          reject_id,
          reject_comment: reject_comment || '',
          isCandidateTable
        })
      )
    }
  }

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Отклонить кандидата"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="warning"
      okText="Отклонить"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <Typography.Text>
          Вы собираетесь отклонить кандидата.
        </Typography.Text>
        <SearchSelectFormControlSource
          name="reject_id"
          control={control}
          label="Причина"
          options={options}
          showSearch
          optionFilterProp="children"
          rules={{ required: true }}
        />
        <TextAreaFormControl
          name="reject_comment"
          control={control}
          label="Комментарий"
          maxLength={255}
          showCount
        />
      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ArchiveCandidateModal))
