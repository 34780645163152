import React, {
  useState, useEffect, memo, useMemo
} from 'react'
import './Calendar.scss'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { Typography } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { TCalendar, TSlot } from '../../../common/api/calendar'
import { getDayOfWeek } from '../utils'
import { selectOpenModalInterview, selectSelectedInterviewId } from '../selectors'
import { DATE_DOTS_FORMAT, SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import { getInterview, setOpenMoreInfoModal, setSelectedInterviewId } from '../actions'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { setActiveDateChange } from '../../../features/SettingsCalendar/actions'
import { selectDictionaryOptionsByName } from '../../ManagementInterviews/selectors'
import { TOption } from '../../../common/types/dictionaries'
import { prepareResultInterviewObject } from '../../InterviewCard/utils'

dayjs.locale('ru')
dayjs.extend(calendar)
type TProps = {
  calendars: TCalendar[] | null
  timeSlots: string[] | null
} & TClassName
const mainCssClass = 'calendar'
function Calendar({ calendars, timeSlots, className }: TProps) {
  const interviewId = useSelector(selectSelectedInterviewId)
  const openMoreInfo = useSelector(selectOpenModalInterview)
  const [activeTimeSlot, setActiveTimeSlot] = useState(dayjs().format('HH:mm'))
  const [updateCount, setUpdateCount] = useState(0)
  const dispatch = useDispatch()
  const interviewResult = useSelector(selectDictionaryOptionsByName('interviewResult'))

  const statusObj = (result: TOption | undefined) => {
    if (result?.value === 96) {
      return { label: result?.label, color: '#DF1E12' }
    }
    if (result?.value === 97) {
      return { label: result?.label, color: '#5A615C' }
    }
    if (result?.value === 98) {
      return { label: result?.label, color: '#2DBE64' }
    }
    if (result?.value === 99) {
      return { label: result?.label, color: '#2DBE64' }
    }
    return {
      label: result?.label || '-', color: '#5A615C'
    }
  }

  const sortedTimeSlots = timeSlots ? [...timeSlots].sort((a, b) => {
    const [aStart, aEnd] = a.split('-').map((time) => parseInt(time.replace(':', ''), 10))
    const [bStart, bEnd] = b.split('-').map((time) => parseInt(time.replace(':', ''), 10))

    if (aStart !== bStart) {
      return aStart - bStart
    }
    return aEnd - bEnd
  }) : []

  const handleView = (interviewId?: number) => {
    if (interviewId) {
      dispatch(getInterview(interviewId))
      dispatch(setSelectedInterviewId(interviewId))
      dispatch(setOpenMoreInfoModal(true))
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs().add(1, 'minute').format('HH:mm')
      setActiveTimeSlot(currentTime)
      setUpdateCount((prevCount) => prevCount + 1)
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (calendars?.length === 1) {
      const parts = calendars[0].date.split('.')
      const day = parts[0]
      const month = parts[1]
      const year = parts[2]
      const formattedDate = `${year}-${month}-${day}`
      dispatch(setActiveDateChange(formattedDate))
    }
  }, [calendars])



  return (
    <div className={cn(mainCssClass, className)}>
      <table className={`${mainCssClass}__table`}>
        <thead>
          <tr>
            <th><Typography.Title level={5}>Время</Typography.Title></th>
            {calendars?.map((calendarItem) => (
              <th key={calendarItem.date}>
                <div>
                  <Typography.Paragraph className={cn(
                    `${mainCssClass}__headers-font`,
                    { [`${mainCssClass}__colorized-font`]: calendarItem.date === dayjs(new Date()).format(DATE_DOTS_FORMAT) }
                  )}
                  >
                    {getDayOfWeek(calendarItem.date)}
                    {' '}
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    className={cn(
                      `${mainCssClass}__head-columns`,
                      { [`${mainCssClass}__colorized-font`]: calendarItem.date === dayjs(new Date()).format(DATE_DOTS_FORMAT) }
                    )}
                  >
                    {dayjs(calendarItem.date, 'DD.MM.YYYY').format('DD MMMM')}
                  </Typography.Paragraph>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedTimeSlots?.map((timeSlot) => (
            <tr key={timeSlot}>
              <td><Typography.Paragraph className={`${mainCssClass}__headers-font`}>{timeSlot === 'without_video' ? 'Без видеосвязи' : timeSlot}</Typography.Paragraph></td>
              {calendars?.map((calendarItem) => {
                const slotDetails = calendarItem.slots[timeSlot]
                const [startTime, endTime] = timeSlot.split('-') // Разбиваем диапазон времени
                const isCurrentTimeSlot = timeSlot >= startTime && timeSlot <= endTime
                const isActiveTimeSlot = activeTimeSlot >= startTime && activeTimeSlot <= endTime
                  && calendarItem?.date === dayjs().format(DATE_DOTS_FORMAT)
                if (Array.isArray(slotDetails?.interview_list)) {
                }
                const dateSplit = calendarItem.date.split('.')
                const splitTime = timeSlot.split('-')
                const day = dateSplit[0]
                const month = dateSplit[1]
                const year = dateSplit[2]
                const time = `${splitTime[1]}:00`

                const formattedDate = `${year}-${month}-${day} ${time}`
                const isMoreThenNow = new Date(formattedDate).getTime() <= new Date().getTime()

                return (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Array.isArray(slotDetails?.interview_list) && calendarItem?.is_blocked !== 1 ? (
                      <td
                        key={calendarItem.date + timeSlot}
                        className={cn(mainCssClass, {
                          [`${mainCssClass}__centrium`]: !isActiveTimeSlot,
                          [`${mainCssClass}__current-time-slot`]: isActiveTimeSlot,
                          [`${mainCssClass}__blocked-slot-time`]: (slotDetails.state === 1 && slotDetails?.interview_id === 0)
                        })}
                      >
                        <div className={isActiveTimeSlot ? (slotDetails?.interview_id
                          ? `${mainCssClass}__current-time-marker-with-card` : `${mainCssClass}__current-time-marker`) : ''}
                        />
                        {slotDetails.interview_list?.map((slotDetails) => (
                          <div
                            onClick={() => handleView(slotDetails?.interview_id)}
                            className={cn(
                              `${mainCssClass}__slot-detail_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__slot-detail_${slotDetails?.confirm}_selected`]:
                              slotDetails?.interview_id === interviewId
                              }
                            )}
                          >
                            <Typography.Paragraph className={cn(
                              `${mainCssClass}__candidate-label_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__candidate-label_${slotDetails?.confirm}_selected`]:
                                slotDetails?.interview_id === interviewId
                              }
                            )}
                            >
                              {slotDetails?.candidate_label || 'Нет информации'}
                            </Typography.Paragraph>
                            <Typography.Paragraph className={cn(
                              `${mainCssClass}__timeslot-label_${slotDetails?.confirm}`,
                              {
                                [`${mainCssClass}__timeslot-label_${slotDetails?.confirm}_selected`]:
                                slotDetails?.interview_id === interviewId
                              }
                            )}
                            >
                              {timeSlot}
                            </Typography.Paragraph>
                          </div>
                        ))}
                      </td>
                    ) : (
                      <>
                        {slotDetails && calendarItem?.is_blocked !== 1 ? (
                          <td
                            key={calendarItem.date + timeSlot}
                            className={cn(mainCssClass, {
                              [`${mainCssClass}__centrium`]: !isActiveTimeSlot && isMoreThenNow,
                              [`${mainCssClass}__current-time-slot`]: isActiveTimeSlot && isMoreThenNow,
                              [`${mainCssClass}__blocked-slot-time`]: (slotDetails.state === 1 && slotDetails?.interview_id === 0),
                              [`${mainCssClass}__result-slot`]: isMoreThenNow
                            })}
                          >
                            <div className={isActiveTimeSlot ? (slotDetails?.interview_id
                              ? `${mainCssClass}__current-time-marker-with-card` : `${mainCssClass}__current-time-marker`) : ''}
                            />
                            {Boolean(slotDetails?.interview_id) && !isMoreThenNow && (
                              <div
                                onClick={() => handleView(slotDetails?.interview_id)}
                                className={cn(
                                  `${mainCssClass}__slot-detail_${slotDetails?.confirm}`,
                                  {
                                    [`${mainCssClass}__slot-detail_${slotDetails?.confirm}_selected`]:
                              slotDetails?.interview_id === interviewId
                                  }
                                )}
                              >
                                <Typography.Paragraph className={cn(
                                  `${mainCssClass}__candidate-label_${slotDetails?.confirm}`,
                                  {
                                    [`${mainCssClass}__candidate-label_${slotDetails?.confirm}_selected`]:
                              slotDetails?.interview_id === interviewId
                                  }
                                )}
                                >
                                  {slotDetails?.candidate_label || 'Нет информации'}
                                </Typography.Paragraph>
                                <Typography.Paragraph className={cn(
                                  `${mainCssClass}__timeslot-label_${slotDetails?.confirm}`,
                                  {
                                    [`${mainCssClass}__timeslot-label_${slotDetails?.confirm}_selected`]:
                              slotDetails?.interview_id === interviewId
                                  }
                                )}
                                >
                                  {timeSlot}
                                </Typography.Paragraph>
                              </div>
                            )}
                            {Boolean(slotDetails?.interview_id) && isMoreThenNow && (
                              <div
                                onClick={() => handleView(slotDetails?.interview_id)}
                                className={`${mainCssClass}__slot-isFullFilled`}
                              >

                                <Typography.Paragraph className={`${mainCssClass}__status-color-${slotDetails.interview_result}`}>
                                  {slotDetails?.candidate_label || 'Нет информации'}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                  className={`${mainCssClass}__status-color-${slotDetails.interview_result}`}
                                  color={statusObj(prepareResultInterviewObject(slotDetails.interview_result, interviewResult)).color}
                                >
                                  {statusObj(prepareResultInterviewObject(slotDetails.interview_result, interviewResult))?.label}
                                </Typography.Paragraph>
                              </div>
                            )}

                          </td>
                        ) : (
                          <td className={cn(`${mainCssClass}__empty-slot`, {

                            [`${mainCssClass}__centrium`]: !isActiveTimeSlot,
                            [`${mainCssClass}__current-time-slot`]: isActiveTimeSlot
                          })}
                          >
                            <div className={isActiveTimeSlot ? `${mainCssClass}__current-time-marker` : ''}>
                              {/* Рисуйте красную линию и точку как метку текущего времени */}
                            </div>
                          </td>
                        )}
                      </>
                    )}
                  </>
                )
              })}
            </tr>
          ))}
          <tr>
            <td />
            <td className={`${mainCssClass}__centrium`}>
              {calendars?.map((calendarItem) => {
                const cancelledSlots = Object.keys(calendarItem?.cancelled || {})

                return (
                  cancelledSlots.map((cancelledSlot) => {
                    const cancelledSlotDetails = calendarItem?.cancelled[cancelledSlot]

                    return (
                      <div
                        key={`cancelled-${cancelledSlot}`}
                        onClick={() => handleView(cancelledSlotDetails.interview_id)}
                        className={`${mainCssClass}__cancelled-slot`}
                      >
                        <Typography.Paragraph className={cn(
                          `${mainCssClass}__candidate-label_${cancelledSlotDetails.confirm}`,
                          {
                            [`${mainCssClass}__candidate-label_${cancelledSlotDetails.confirm}_selected`]:
                              cancelledSlotDetails.interview_id === interviewId
                          }
                        )}
                        >
                          {cancelledSlotDetails.candidate_label || 'Нет информации'}
                        </Typography.Paragraph>
                        <Typography.Paragraph className={cn(
                          `${mainCssClass}__timeslot-label_${cancelledSlotDetails.confirm}`,
                          {
                            [`${mainCssClass}__timeslot-label_${cancelledSlotDetails.confirm}_selected`]:
                              cancelledSlotDetails.interview_id === interviewId
                          }
                        )}
                        >
                          {cancelledSlot}
                        </Typography.Paragraph>
                      </div>
                    )
                  })
                )
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default memo(withErrorBoundary(Calendar))
