/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import { setToken, setLoading, getLogout } from './actions'

import { TAuthState } from './types'

const initialState: TAuthState = {
  user_id: null,
  loading: false
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setToken, (state, { payload }) => {
      state.user_id = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(getLogout, (state) => {
      state = { ...initialState }
    })
})

export default authReducer
