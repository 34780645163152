import React, {
  memo, useCallback, useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Select, Switch, Typography, Upload, Tooltip
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { TOption } from '../../../common/types/dictionaries'
import $api from '../../../common/api/http'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import {
  createRequest, getDictionaries, getResponsibleList, updateRequest
} from '../actions'
import { selectRequest } from '../../../compositions/Request/selectors'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import {
  extractValue, fetchRequestList, fetchStoreList, fetchVacancyList,
  resetDefaultValues
} from '../utils'
import { getCurators } from '../../../compositions/ManagementRequests/actions'
import { selectCurators, selectSelectedRequest } from '../../../compositions/ManagementRequests/selectors'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import DebounceSelectFormControlCity from '../../../common/components/formcontrol/DebounceSelectFormControlCity'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectLoadingModal, selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import CommonDrawer from '../../../common/components/CommonDrawer/CommonDrawer'
import ControlButton from '../../../common/components/Buttons/Button'
import { selectDictionaryOptionsByName, selectEditMode, selectIsRequestTable, selectResponsibleList } from '../selectors'
import './EditRequestDrawer.scss'
import { TRequest } from '../../../common/api/request'

type TFormValues = {
    cost: number
    description: string
    schedule_id_list: string | number[]
    vacancy_id: number
    customer: TOption[]
    store_id: {
      value: number
      label: string,
      title: string
    }
    responsible: number | null
    is_student:boolean
    is_patent:boolean
    is_internship: boolean
    address_internship: string
    responsible_internship: string
    responsible_number_internship: string
    curator: string[]
    reward_average_day: number
    reward_average_month: number
    candidate_age: number
    characteristic_list:string[]
    education_id: number
    priority_id: number
    mileage_average_day: string
    reward_average_order: string
    income_average_day: string
    flag: boolean
    nominate_me:boolean
  }

// export type TProps = {
//     request?: TRequest
//   } & ModalProps

const mainCssClass = 'edit-request-drawer'

function EditRequestDrawer({
  open, className, onCancel
}: ModalProps) {
  const dispatch = useDispatch()
  const requestFromRequest = useSelector(selectRequest)
  const loadingModal = useSelector(selectLoadingModal)
  const isRequestTable = useSelector(selectIsRequestTable)
  const userInfo = useSelector(selectUserInfo)
  const editMode = useSelector(selectEditMode)
  const requestFromTable = useSelector(selectSelectedRequest)
  const curators = useSelector(selectCurators)
  const responsibleOptions = useSelector(selectResponsibleList)
  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const educationOptions = useSelector(selectDictionaryOptionsByName('requestCandidateEducation'))
  const characteristicOptions = useSelector(selectDictionaryOptionsByName('requestCandidateCharacteristic'))
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))

  const request = useMemo(() => {
    if (requestFromTable) {
      return requestFromTable
    }
    return requestFromRequest
  }, [requestFromRequest, requestFromTable])

  const defaultValues = useMemo(
    () => (editMode && request
      ? {
        curator: request?.curator_list ? request?.curator_list?.map((el) => el.id) : null,
        description: request.description,
        cost: request.cost,
        schedule_id_list: request.schedule_list?.map((el) => el.id),
        customer: request.customer_list?.map((item) => ({ value: item.id, label: `${item?.name || ''} ${item?.surname || ''} ${item?.email}` })) || [],
        priority_id: request.priority_id,
        store_id: request?.store_id ? {
          value: request?.store_id,
          label: request?.store?.name || 'Фраксис VV',
          title: request?.store.city
        } : null,
        vacancy_id: {
          value: request.vacancy_id,
          label: request.vacancy_label
        },
        responsible: {
          value: request.responsible,
          label: `${request?.responsible_list?.name} ${request.responsible_list.surname} ${request.responsible_list.email}`
        },
        address_internship: request.address_internship,
        is_internship: !!request.is_internship,
        is_student: !!request.is_student,
        is_patent: !!request.is_patent,
        responsible_internship: request.responsible_internship || '',
        responsible_number_internship: request.responsible_number_internship || '',
        reward_average_day: request.reward_average_day || '',
        reward_average_month: request.reward_average_month || '',
        candidate_age: request.candidate_age || '',
        characteristic_list: request.characteristic_list || [],
        education_id: request.education_id,
        mileage_average_day: request.mileage_average_day || '',
        reward_average_order: request.reward_average_order || '',
        income_average_day: request.income_average_day || '',
        flag: !request?.store_id,
        nominate_me: !!request.customer_list.filter((el) => Number(el.id) === Number(userInfo?.id)).length,
        responsible_id_list: request?.responsible_id_list ? request.responsible_id_list.map((el) => el.id) : []
      }
      : {}),
    [request, editMode, open]
  )

  const {
    control, handleSubmit, reset, watch, getValues, setValue, formState: { errors }
  } = useForm<TFormValues>()

  const is_student = watch('is_student')
  const is_internship = watch('is_internship')

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }

  const handleChangeStore = (e) => {
    setValue('address_internship', null)
  }

  const onSubmit = useCallback(() => {
    const values = getValues()
    const updatedValues = {
      ...values,
      vacancy_id: extractValue(values.vacancy_id),
      customer: values?.customer?.map((item) => item.value) || [],
      store_id: extractValue(values.store_id),
      responsible: extractValue(values.responsible),
      cost: Number(values.cost)
    }
    if (editMode && request) {
      dispatch(
        updateRequest({
          data: {
            id: request.id,
            ...updatedValues,
            created_at: request?.created_at
          },
          isRequestTable,
          isCurator: userInfo?.department === 175
        })
      )
    } else {
      dispatch(
        createRequest({
          data: {
            status: 91,
            ...updatedValues
          },
          isCurator: userInfo?.department === 175
        })
      )
    }
  }, [editMode, isRequestTable, request])

  const store = watch('store_id')
  const flag = watch('flag')
  const customer = watch('customer')
  const nominate_me = watch('nominate_me')
  const reward = watch('reward_average_day')

  const fetchAdressList = useCallback(async (name: string) => {
    const data = await $api.get(`/api/v1/dadata/suggest-all?search=${name}&city=${watch('store_id')?.title || userInfo?.city?.name || 'Москва'}`)

    return data.data.DATA.map((obj:any) => ({
      label: `${obj.value}`,
      value: obj.value
    }))
  }, [store])

  const handleChangeFlag = (e) => {
    if (e) { setValue('store_id', null) }
  }
  const handleChangeNominateMe = (e) => {
    const customer = watch('customer')
    if (e) {
      if (userInfo && userInfo?.role?.role_id === 'client' && userInfo?.hasOwnProperty('customer_responsible') && userInfo.customer_responsible.length) {
        setValue('responsible', { label: userInfo?.customer_responsible[0].label, value: userInfo?.customer_responsible[0].id })
      }
      if (customer && customer.length < 3) {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }, ...customer])
      } else if (customer && customer.length === 3) {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }, ...customer.slice(0, 2)])
      } else {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }])
      }
    } if (!e) {
      setValue('customer', customer.filter((el) => Number(el.value) !== Number(userInfo.id)))
      if (userInfo && userInfo?.role?.role_id === 'client' && userInfo?.hasOwnProperty('customer_responsible') && userInfo.customer_responsible.length) {
        setValue('responsible', null)
      }
    }
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'schedule', 'requestCandidateEducation', 'requestCandidateCharacteristic', 'priority'
      ])
    )
  }, [])

  useEffect(() => {
    console.log(123,responsibleOptions)
    dispatch(getResponsibleList())
    dispatch(getCurators())
  }, [])

  useEffect(() => {
    if (nominate_me && customer) {
      const checkCustomer = customer.filter((el) => Number(el.value) === (userInfo?.id))
      if (!checkCustomer.length) {
        setValue('nominate_me', false)
      }
    }
  }, [customer])

  useEffect(() => {
    reset(defaultValues)
  }, [
    open, defaultValues, editMode
  ])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonDrawer
      open={open}
      onClose={handleModalClose}
      width="800px"
      className={mainCssClass}
      footer={(
        <>
          <ControlButton className="button" typeButton="secondary" onClick={handleModalClose} disabled={loadingModal}>
            Отменить
          </ControlButton>
          <ControlButton className="button" typeButton="primary" onClick={handleSubmit(onSubmit)} disabled={loadingModal}>
            Сохранить
          </ControlButton>
        </>
      )}
    >
      <div className={mainCssClass}>
        <NewScrollContainer>
          <div className={`${mainCssClass}__list`}>

            <DebounceSelectFormControl
              rules={{ required: true }}
              name="vacancy_id"
              control={control}
              fetchOptions={fetchVacancyList}
              label="Вакансия"
              required
              placeholder="Поиск по наименованию..."
            />

            <div className={`${mainCssClass}_items-store`}>

              <DebounceSelectFormControlCity
                name="store_id"
                control={control}
                fetchOptions={fetchStoreList}
                rules={{ required: !flag }}
                label="Объект"
                placeholder="Поиск по наименованию..."
                required
                onChange={(e) => handleChangeStore(e)}
                labelInValue
                disabled={flag}
                key={`${flag}1`}

              />
              <div className={`${mainCssClass}_switch`}>
                <SwitchFormControl control={control} name="flag" onChange={handleChangeFlag} />
                <Typography.Text>Потребность под кандидата</Typography.Text>
              </div>
            </div>

            {editMode && (
              <SelectFormControl
                name="priority_id"
                control={control}
                placeholder="Приоритет"
                options={priorityOptions}
                required
                label="Приоритет"
                rules={{ required: true }}
              />
            ) }
            <div className={`${mainCssClass}_switch`}>
              <SwitchFormControl control={control} onChange={handleChangeNominateMe} name="nominate_me" />
              <Typography.Text>Назначить меня заказчиком</Typography.Text>
            </div>
            <div className={`${mainCssClass}_items`}>
              <DebounceSelectFormControlMult
                control={control}
                name="customer"
                placeholder="Поиск по имени..."
                fetchOptions={fetchRequestList}
                label={(userInfo?.department === 175 || userInfo?.department === 177) ? 'Помощник розницы' : 'Заказчик'}
                maxSelections={3}
                required
                rules={{ required: true }}
              />

              <InputFormControl
                name="cost"
                control={control}
                label="Количество позиций"
                required
                rules={{
                  required: true,
                  pattern: /^[0-9]*$/
                }}

              />
              <DebounceSelectFormControl
                placeholder="Поиск по имени..."
                fetchOptions={fetchRequestList}
                style={{ width: '100%' }}
                label={(userInfo?.department === 175 || userInfo?.department === 177) ? 'Заказчик - Ответственный' : 'Ответственный'}
                rules={{ required: true }}
                required
                name="responsible"
                control={control}
              />
            </div>

            <SelectFormControlMult
              mode="multiple"
              control={control}
              name="schedule_id_list"
              rules={{ required: true }}
              label="Графики"
              required
              options={sheduleOptions}
            />
            <SelectFormControlMult
              mode="multiple"
              control={control}
              name="characteristic_list"
              options={characteristicOptions}
              label="Особенности кандидата"
              required={!editMode}
              rules={{ required: !editMode }}
            />
            <div className={`${mainCssClass}_items`}>
              {errors.reward_average_day ? (
                <Tooltip placement="bottom" title="Введите не менее 4 цифр для заполнения">
                  <InputFormControl
                    name="reward_average_day"
                    control={control}
                    label="Средняя зп в день на точке"
                    required
                    rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
                  />
                </Tooltip>
              ) : (
                <InputFormControl
                  name="reward_average_day"
                  control={control}
                  label="Средняя зп в день на точке"
                  required
                  rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
                />
              )}

              <InputFormControl
                name="reward_average_month"
                control={control}
                label="Средняя зп в месяц на точке"
                required={!editMode}
                rules={{
                  pattern: /^[0-9\s,-]*$/, required: !editMode
                }}
              />
              <InputFormControl
                name="candidate_age"
                control={control}
                label="Желаемый возраст кандидата"
                required={!editMode}
                rules={{ validate: (value) => (value ? value.length < 20 : true), required: !editMode }}
              />
            </div>
            {userInfo?.department === 175
              && (
                <div className={`${mainCssClass}_items`}>
                  <InputFormControl
                    name="mileage_average_day"
                    control={control}
                    label="Средний пробег в день"
                    required={!editMode}
                    rules={{
                      pattern: /^[0-9]*$/, required: !editMode && userInfo?.department === 175
                    }}
                  />
                  <InputFormControl
                    name="reward_average_order"
                    control={control}
                    label="Средняя оплата за заказ "
                    required={!editMode}
                    rules={{
                      pattern: /^[0-9]*$/
                    }}
                  />
                  {userInfo?.department !== 175 && userInfo?.department !== 177
                    && (
                      <InputFormControl
                        name="income_average_day"
                        control={control}
                        label="Средний доход в день"
                        required={!editMode}
                        rules={{
                          pattern: /^[0-9]*$/,  required: !editMode && userInfo?.department !== 175 && userInfo?.department !== 177
                        }}
                      />
                    )}
                </div>
              )}

            {userInfo?.department !== 175
               && (
                 <>
                   <div className={`${mainCssClass}_switch`}>
                     <SwitchFormControl control={control} name="is_student" />
                     <Typography.Text>Подходят ли студенты</Typography.Text>
                   </div>
                   <SelectFormControl control={control} name="education_id" label="Форма обучения" options={educationOptions} disabled={!is_student} required={is_student && !editMode} rules={{
                     required: is_student && !editMode
                   }} />
                   <div className={`${mainCssClass}_switch`}>
                     <SwitchFormControl control={control} name="is_patent" />
                     <Typography.Text>Можно с патентом</Typography.Text>
                   </div>
                   <div className={`${mainCssClass}_switch`}>
                     <SwitchFormControl control={control} name="is_internship" />
                     <Typography.Text>Нужна ознакомительная стажировка</Typography.Text>
                   </div>
                   <div className={`${mainCssClass}_items`}>
                     <DebounceSelectFormControl
                       name="address_internship"
                       control={control}
                       fetchOptions={fetchAdressList}
                       label="Адрес"
                       notFoundContent="Начните вводить адрес"
                       disabled={!is_internship}
                       key={`${watch('store_id')?.title}0`}
                       required={!editMode && is_internship}
                       rules={{
                         required: !editMode && is_internship
                       }}

                     />
                     <InputFormControl
                       name="responsible_internship"
                       control={control}
                       label="Ответственный"
                       disabled={!is_internship}
                       required={!editMode && is_internship}
                       rules={{
                         required: !editMode && is_internship
                       }}
                     />
                     <SearchSelectFormControlSource
                       name="responsible_id_list"
                       control={control}
                       options={responsibleOptions}
                       mode="multiple"
                       label="Вайтсторы и Дарксторы"
                       showSearch
                     />

                   </div>

                 </>
               )}

            {userInfo?.department === 175 && (
              <SearchSelectFormControlSource
                mode="multiple"
                control={control}
                showSearch
                name="curator"
                label="Куратор"
                options={curators}
                required={!editMode}
                rules={{
                  required: !editMode
                }}
              />
            )}

            <TextAreaFormControl
              name="description"
              control={control}
              label="Описание"
              required
              rules={{ required: true }}
            />
          </div>
        </NewScrollContainer>
      </div>
    </CommonDrawer>
  )
}

export default EditRequestDrawer
