import { createAction } from '@reduxjs/toolkit'
import {
  TCreateFieldConfig,
  TCreateFieldConfigWithFilter,
  TQuestionnare,
  TRoleSetting,
  TUpdateFieldConfig,
  TUpdatePriorityFieldConfigWithFilter
} from './types'
import {
  TRequestCreateReminder,
  TRequestCreateTemplate,
  TRequestCreateTrigger,
  TRequestReminders,
  TRequestSortStage,
  TRequestStatusArchive,
  TRequestStatusDictionary,
  TRequestStatusQuestionnaire,
  TRequestUpdateTemplate,
  TStage,
  TStore,
  TUser,
  TVertices
} from '../../common/api/systems'
import { TPaginationRequest } from '../../common/types/pagination'
import { TRequestPatchUser } from '../../common/api/user'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TCandidateHeaders } from '../ManagamentCandidates/actions'

export const getDictionariesDropdown = createAction<TDictionariesNames>('systems/dictionaries/getDictionariesDropdown')
export const setDictionariesDropdown = createAction<TDictionaries>('systems/dictionaries/setDictionariesDropdown')

export const getQuestionnaire = createAction('systems/getQuestionnaire')
export const getRoleList = createAction('systems/getRoleList')

export const getStores = createAction<TPaginationRequest>('systems/getStores')
export const setStores = createAction<TStore[]>('systems/setStores')

export const setDefDataStore = createAction<TPaginationRequest>('systems/setDefDataStore')
export const setHeadersStore = createAction<TCandidateHeaders>('systems/setHeadersStore')

export const createStore = createAction<TStore>('systems/createStore')
export const updateStore = createAction<TStore>('systems/updateStore')
export const setPageStore = createAction<number>('systems/setPageStore')
export const setCreateOrEditStore = createAction<boolean>('systems/setCreateOrEditStore')
export const setYandexObjects = createAction<any>('systems/setYandexObjects')

export const setSelectedStore = createAction<TStore | null>('systems/setSelectedStore')

export type TRequestUsers = {
  filter?: string
}

export const getUsers = createAction<TPaginationRequest>('systems/getUsers')
export const setUsers = createAction<TUser[]>('systems/setUsers')

export const setDefDataUser = createAction<TPaginationRequest>('systems/setDefDataUser')
export const setHeadersUser = createAction<TCandidateHeaders>('systems/setHeadersUser')

export const getSelectedUser = createAction<number>('systems/getSelectedUser')
export const setSelectedUser = createAction<TUser | null>('systems/setSelectedUser')
export const setRoleList = createAction<any>('systems/setRoleList')
export const setQuestionnaire = createAction<TQuestionnare[]>('systems/setQuestionnaire')

export const setLoading = createAction<boolean>('systems/setLoading')
export const reset = createAction('systems/reset')

export const updateStatusQuestionnaire = createAction<TRequestStatusQuestionnaire>('systems/updateStatusQuestionnaire')

export const updateStatusRole = createAction<TRequestStatusArchive>('systems/updateStatusRole')
export const archiveStatusRole = createAction<TRequestStatusArchive>('systems/archiveStatusRole')

export const createRole = createAction<TRoleSetting>('systems/createRole')
export const updateRole = createAction<TRoleSetting>('systems/updateRole')

export const setDefDataRole = createAction<TPaginationRequest>('systems/setDefDataRole')
export const setHeadersRole = createAction<TCandidateHeaders>('systems/setHeadersRole')

export type TRequestCreateQuestionnaire = {
  question_list: { question: string; is_recruiter: 0 | 1; is_show: 0 | 1; id?: number }[]
  questionnare_name: string
  questionnare_id?: number
}

export const openQuestionnaireForm = createAction<boolean>('systems/openQuestionnaireForm')

export const createQuestionnaire = createAction<TRequestCreateQuestionnaire>('systems/createQuestionnaire')

export const updateQuestionnaire = createAction<TRequestCreateQuestionnaire>('systems/updateQuestionnaire')

export const setActiveQuestionnaire = createAction<TQuestionnare | null>('systems/activeQuestionnaire')

export const openNewTrigger = createAction<boolean>('systems/openNewTrigger')
export const createOrEditRole = createAction<boolean>('systems/createOrEditRole')
export const createOrEditUser = createAction<boolean>('systems/createOrEditUser')
export const createUser = createAction<TUser>('systems/createUser')

export type TUpdateUserModel = {
  user: TUser
  isCodeChalenge?: boolean
}
export const updateUserModel = createAction<TUpdateUserModel>('systems/updateUserModel')
export const getRoleConstructor = createAction<string>('systems/getRoleConstructor')
export const setRoleConstructor = createAction<TRoleSetting | null>('systems/setRoleConstructor')

export const openDictionaryForm = createAction<boolean>('systems/openDictionaryForm')

export type TRequestPAssword = {
  new_password: string
  new_password_confirm: string
  old_password: string
}
export const changePassword = createAction<any>('systems/changePassword')

export type TTriggers = {
  id: number
  name: string
  criteria: string
  event: string
  created_at: string
  updated_at: string
  table_name: string
  callback: string
  holder_list: string | { label: string; value: number }[]
  status: 1
  type: number
  template?: {
    id: number
    name: string
    mail_subject: null | string
  }
  labels: any
}

export const getTriggers = createAction('systems/getTriggers')
export const setTriggers = createAction<any>('systems/setTriggers')
export const setActiveTrigger = createAction<TTriggers | null>('systems/setActiveTrigger')

export type TRequestUpdateTrigger = {
  name: string
  table_name: string
  criteria: string
  event: string
  callback: string
  holder_list: string | number[]
  template_subject?: string
  template_id?: number
  id: number
}

export const createTrigger = createAction<TRequestCreateTrigger>('systems/createTrigger')
export const updateTrigger = createAction<TRequestUpdateTrigger>('systems/updateTrigger')
export const deleteTrigger = createAction<number>('systems/deleteTrigger')

export const openTemplatesForm = createAction<boolean>('systems/openTemplatesForm')

export const getTemplates = createAction('systems/getTemplates')
export const setTemplates = createAction<any>('systems/setTemplates')
export const setActiveTemplate = createAction<any>('systems/setActiveTemplate')

export const updateUser = createAction<TRequestPatchUser>('systems/updateUser')

export const createTemplate = createAction<TRequestCreateTemplate>('systems/createTemplate')
export const updateTemplate = createAction<TRequestUpdateTemplate>('systems/updateTemplate')

export const deleteTemplate = createAction<number>('systems/deleteTemplate')

export const getDictionaries = createAction('systems/getDictionaries')
export const setDictionaries = createAction<any>('systems/setDictionaries')
export const getActiveDictionary = createAction<number>('systems/getActiveDictionary')
export const setActiveDictionary = createAction<number>('systems/setActiveDictionary')
export const updateStatusDictionary = createAction<TRequestStatusDictionary>('systems/updateStatusDictionary')

export const setDictionaryName = createAction<{ id: number; name: string } | null>('systems/setDictionaryName')

export type TRequestUpdateDictionaryOptions = {
  id: number
  value: string
  dictionary_id: number
  is_hidden: 0 | 1
  params: string
}

export type TRequestCreateDictionaryOptions = {
  value: string
  dictionary_id: number
  priority: number
}

export const createDictionaryOptions = createAction<TRequestCreateDictionaryOptions>('systems/createDictionaryOptions')

export const updateDictionaryOptions = createAction<TRequestUpdateDictionaryOptions>('systems/updateDictionaryOptions')

export const getVertices = createAction('systems/getVerices')
export const setVertices = createAction<TVertices[]>('systems/setVerices')
export type TRequestUpdateVertices = {
  name: string
  id: number
}

export const updateVertices = createAction<TRequestUpdateVertices>('systems/updateVertices')

export const getStages = createAction('systems/getStages')
export const setStages = createAction<TStage[]>('systems/setStages')

export type TAddStageRequest = {
  name: string
  vertex_id: number
  priority: number
  status: number
}

export const createStage = createAction<TAddStageRequest>('systems/createStage')

export type TUpdateStageRequest = {
  id: number
  name?: string
  is_finalist?: null | number
  limit_on_day?: number | string | null
  is_process?: null | number
  is_accepted?: null | number
  is_candidate_show_check_doc?: null | number
  status?: number | null
  type?: number | null
  restriction?: number[] | []
  color?: string
}

export const updateStage = createAction<TUpdateStageRequest>('systems/updateStage')

export const sortStage = createAction<TRequestSortStage>('systems/sortStage')

export const getStageState = createAction<number>('systems/getStageState')

export const setStageState = createAction<any[]>('systems/setStageState')

export type TRequestPatchStageState = {
  id: number
  status?: boolean
  move_to_stage?: number | null
  move_to_stage_state?: number | null
}

export type TRequestCreateStageState = {
  status?: boolean
  name: string
  stage_id: number
}

export const updateStageState = createAction<TRequestPatchStageState>('systems/updateStageState')

export const createStageState = createAction<TRequestCreateStageState>('systems/createStageState')

export type TRequestWhatsappTemplates = {
  page: number
  perPage: number
}

export type TWhatsappTemplatesHeaders = {
  current: number
  total: number
}

export const getWhatsappTemplates = createAction<TRequestWhatsappTemplates>('systems/getWhatsappTemplates')
export const setWhatsappTemplates = createAction<any>('systems/setWhatsappTemplates')
export const setActiveWhatsappTemplate = createAction<any>('systems/setActiveWhatsappTemplate')
export const openWhatsappTemplatesForm = createAction<boolean>('systems/openWhatsappTemplatesForm')

export const setDefDataWhatsapp = createAction<TRequestWhatsappTemplates>('systems/setDefDataWhatsapp')
export const setHeadersWhatsappTemplates = createAction<TWhatsappTemplatesHeaders>(
  'systems/setHeadersWhatsappTemplates'
)

export type TRequestCreateWhatsappTemplate = {
  name: string
  content: string
  user_id: number | string | null
}

export type TRequestUpdateWhatsappTemplate = {
  name: string
  content: string
  user_id: number | string | null
  id: number | string
}
export type TRequestPinWhatsappTemplate = {
  whatsapp_template_id: number | string
}

export const createWhatsappTemplate = createAction<TRequestCreateWhatsappTemplate>('systems/createWhatsappTemplate')
export const updateWhatsappTemplate = createAction<TRequestUpdateWhatsappTemplate>('systems/updateWhatsappTemplate')
export const deleteWhatsappTemplate = createAction<number>('systems/deleteWhatsappTemplate')

export const createPinWhatsappTemplate = createAction<TRequestPinWhatsappTemplate>('systems/createPinWhatsappTemplate')
export const deletePinWhatsappTemplate = createAction<number>('systems/deletePinWhatsappTemplate')
export type TRequestCallbacks = {
  page: number
  perPage: number
}

export const getCallbacks = createAction<TRequestCallbacks>('systems/getCallback')
export const setCallbacks = createAction<any[]>('systems/setCallback')
export const setDefDataCallback = createAction<TRequestWhatsappTemplates>('systems/setDefDataCallback')
export const setHeadersCallback = createAction<TWhatsappTemplatesHeaders>('systems/setHeadersCallback')
export const setActiveCallback = createAction<any>('systems/setActiveCallback')

export type TRequestCreateCallback = {
  event_id: number
  lid_utm_id: number
  method: 'get' | 'post'
  url: string
  params: string
  is_archive: boolean
}

export type TRequestUpdateCallback = {
  id: number | string
  event_id?: number
  lid_utm_id?: number
  method?: 'get' | 'post'
  url?: string
  params?: string
  is_archive?: boolean
}

export const createCallback = createAction<TRequestCreateCallback>('systems/createCallback')
export const updateCallback = createAction<TRequestUpdateCallback>('systems/updateCallback')

export const openCallbackSettingForm = createAction<boolean>('systems/openCallbackSettingForm')

export const getSynchronization = createAction<string>('systems/getSynchronization')

export type TRequestUpdateReminder = {
  id: number
  name?: string
  event_id?: number
  remind_to?: 'candidate' | 'recruiter' | 'researcher' | 'customer'
  time?: number
  remind_time?: 'before' | 'after'
  text?: string
  is_archive?: boolean
}

export const getReminders = createAction<TRequestReminders>('systems/getReminders')
export const setReminders = createAction<any[]>('systems/setReminders')
export const setDefDataReminders = createAction<TRequestWhatsappTemplates>('systems/setDefDataReminders')
export const setHeadersReminders = createAction<TWhatsappTemplatesHeaders>('systems/setHeadersReminders')
export const setActiveReminder = createAction<any>('systems/setActiveReminder')
export const createReminder = createAction<TRequestCreateReminder>('systems/createReminder')
export const updateReminder = createAction<TRequestUpdateReminder>('systems/updateReminder')
export const openReminderForm = createAction<boolean>('systems/openReminderForm')

export type TRequestCreateToken = {
  token: string
  utm_source: string
  lid_name: string
  is_active: true
}

export type TRequestUpdateToken = {
  id: number | string
  token: string
  utm_source: string
  lid_name: string
  is_active: true
}
export const getTokens = createAction<any>('systems/getTokens')
export const setTokens = createAction<any[]>('systems/setTokens')
export const setDefDataTokens = createAction<TRequestWhatsappTemplates>('systems/setDefDataTokens')
export const setHeadersTokens = createAction<TWhatsappTemplatesHeaders>('systems/setHeadersTokens')
export const setActiveToken = createAction<any>('systems/setActiveToken')
export const createToken = createAction<TRequestCreateToken>('systems/createToken')
export const updateToken = createAction<TRequestUpdateToken>('systems/updateToken')
export const openTokenForm = createAction<boolean>('systems/openTokenForm')

export const generateToken = createAction<boolean>('systems/generateToken')

export type TRequestTokens = {
  page: number
  perPage: number
}
export type TDropdownStageSetupKey = 'addStage' | 'candidate' | 'request' | 'funnel' | ''
export const setDropdownStageSetupKey = createAction<TDropdownStageSetupKey>('systems/generateToken')

export const setConstructorModal = createAction<boolean>('systems/setConstructorModal')

export type TFieldConfig = {
  id: number
  attr: string
  model: string
  priority: number
  department_id: number
  status: 0 | 1
  label: string
  type: number
  is_required: boolean
  dictionary_id: number
  dictionary_name: string
}
export type TFieldConfigRequest = {
  filter: string
}

export const getFieldConfig = createAction<TFieldConfigRequest>('systems/getFieldConfigs')
export const setFieldConfig = createAction<TFieldConfig[]>('systems/setFieldConfigs')
export const setFieldConfigFilter = createAction<string>('systems/setFieldConfigFilter')

export const updateFieldConfig = createAction<TUpdateFieldConfig>('systems/updateFieldConfig')
export const createFieldConfig = createAction<TCreateFieldConfigWithFilter>('systems/createFieldConfig')

export const incrementFieldConfig = createAction<TUpdatePriorityFieldConfigWithFilter>('systems/incrementFieldConfig')
export const decrementFieldConfig = createAction<TUpdatePriorityFieldConfigWithFilter>('systems/decrementFieldConfig')
