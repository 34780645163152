/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState, memo, useCallback } from 'react'
import { useParams } from 'react-router'
import { Typography } from 'antd'
import cn from 'classnames'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import {
  selectActiveTab,
  selectHeaders,
  selectLoading,
  selectRecruiterFilterString,
  selectResearcherFilterString,
  selectSearchText
} from '../selectors'
import {
  getProductivityRecruiter,
  getProductivityResearcher,
  openProductivityRecruiterFilter,
  openProductivityResearcherFilter,
  reset,
  setActiveTab,
  setRecruiterFilterString,
  setSearchText
} from '../actions'
import './ProductivityPage.scss'
import ProductivityResearcherTable from './ProductivityResearcherTable'
import ProductivityRecruiterTable from './ProductivityRecruiterTable'
import { setCountFilters } from '../../../features/filters/actions'
import { selectCountFilters } from '../../../features/filters/selectors'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import Search from '../../../common/svg/Search'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

const mainCssClass = getCssClassName('productivity-page')

type TFormValues = {
  perPagesCount: number
  search: string
}

const ProductivityPage: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const [page, setPage] = useState<number>(1)
  const loading = useSelector(selectLoading)
  const headers = useSelector(selectHeaders)
  const activeTab = useSelector(selectActiveTab)
  const researcherFilterString = useSelector(selectResearcherFilterString)
  const recruiterFilterString = useSelector(selectRecruiterFilterString)
  const filtersCount = useSelector(selectCountFilters)
  const searchText = useSelector(selectSearchText)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)

  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10
    }
  })

  const perPage = watch('perPagesCount')
  const search = watch('search')

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  //   const handleNextPage = useCallback(() => {
  //     if (page !== maxPage) {
  //       setPage((prev) => prev + 1)
  //     }
  //   }, [page, maxPage])

  const handleNextPage = useCallback(() => {
    if (page !== headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const handleChangeTab = (status) => {
    dispatch(setActiveTab(status))
    setPage(1)
    setValue('search', '')
    dispatch(setSearchText(''))
  }

  const handleOpenFilter = () => {
    if (activeTab === 'recruiter') {
      dispatch(openProductivityRecruiterFilter(true))
    } else {
      dispatch(openProductivityResearcherFilter(true))
    }
  }
  const handleClickSearch = () => {
    if (search) {
      dispatch(setSearchText(search))
    }
  }

  const handleBlurSearch = () => {
    if (search === '' && !search) {
      dispatch(setSearchText(search))
    }
  }

  const handleDeleteSearch = () => {
    if (search) {
      dispatch(setSearchText(''))
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleClickSearch()
    }
  }

  useEffect(() => {
    if (activeTab === 'recruiter' && localStorage.getItem('productivityrec')) {
      dispatch(setRecruiterFilterString(String(localStorage.getItem('productivityrec'))))
    }
  }, [])

  useEffect(() => {
    if (activeTab === 'recruiter') {
      dispatch(
        getProductivityRecruiter({
          page,
          perPage,
          filters: recruiterFilterString,
          search: searchText
        })
      )
    }
    if (activeTab === 'researcher') {
      dispatch(
        getProductivityResearcher({
          perPage,
          page,
          filters: researcherFilterString,
          search: searchText
        })
      )
    }
  }, [activeTab, page, perPage, recruiterFilterString, researcherFilterString, searchText])

  useEffect(() => {
    if (activeTab === 'recruiter') {
      if (localStorage.getItem('defproductivityrec')) {
        try {
          const parsedFiltersRec = JSON.parse(localStorage.getItem('defproductivityrec'))
          if (!isEqual(parsedFiltersRec, {})) {
            dispatch(
              setCountFilters(
                Object.values(parsedFiltersRec).filter((item) =>
                  Array.isArray(item) ? item.length > 0 : !!item !== false
                ).length
              )
            )
          } else {
            dispatch(setCountFilters(0))
          }
        } catch (parseError) {
          console.error('Error parsing defproductivityrec:', parseError)
          dispatch(setCountFilters(0))
        }
      } else {
        dispatch(setCountFilters(0))
      }
    } else if (localStorage.getItem('defproductivityres')) {
      try {
        const parsedFiltersRes = JSON.parse(localStorage.getItem('defproductivityres'))
        if (!isEqual(parsedFiltersRes, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFiltersRes).filter((item) =>
                Array.isArray(item) ? item.length > 0 : !!item !== false
              ).length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing defproductivityres:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, researcherFilterString, recruiterFilterString, activeTab])
  //_________________________Удалить после НГ
  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])
  //___________________________

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_________________________Удалить поле НГ*/}
          <ChristmasIcon />
          {/*//________________________*/}
          <Typography.Title level={2}>Продуктивность</Typography.Title>
          {loading && <Preloader size="md" />}

          <div className={`${mainCssClass}__btn-header-container_tab`}>
            <div
              onClick={() => handleChangeTab('recruiter')}
              className={cn(`${mainCssClass}__btn-header-container_tab_item`, {
                [`${mainCssClass}__btn-header-container_tab_item_active`]: activeTab === 'recruiter'
              })}
            >
              Для рекрутеров
            </div>
            <div
              onClick={() => handleChangeTab('researcher')}
              className={cn(`${mainCssClass}__btn-header-container_tab_item`, {
                [`${mainCssClass}__btn-header-container_tab_item_active`]: activeTab === 'researcher'
              })}
            >
              Для ресечеров
            </div>
          </div>
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton
                icon="left"
                size="large"
                typeButton="secondary"
                className="button"
                onClick={handleOpenFilter}
              >
                <FiltersIcon />
                Фильтры
                {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <InputSearchFormControl
              name="search"
              control={control}
              placeholder="ФИО, телефон"
              onKeyDown={handleKeyDown}
              onBlur={handleBlurSearch}
              handleDelete={handleDeleteSearch}
            />
            <ControlButton
              typeButton="primary"
              className={`${mainCssClass}__search-container_btn button`}
              icon="left"
              onClick={handleClickSearch}
            >
              <Search />
              Поиск
            </ControlButton>
          </div>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              Результат:
              <Typography.Title level={5} className={`${mainCssClass}__title`}>
                &nbsp;
                {headers?.totalCount || 'неизвестно'}
              </Typography.Title>
            </div>
            {!!headers?.totalCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM
                    className="arrowCursor"
                    size="1"
                    onClick={handlePreviousPage}
                    fill={page === 1 ? '#BFC5C0' : '#404542'}
                  />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM
                    className="arrowCursor"
                    size="1"
                    onClick={handleNextPage}
                    fill={page === headers.total ? '#BFC5C0' : '#404542'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              {activeTab === 'recruiter' ? <ProductivityRecruiterTable /> : <ProductivityResearcherTable />}
            </NewScrollContainer>
          </div>
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ProductivityPage))
