import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import $api, { API_URL } from './http'
import { TRequestLeadsCSV } from '../../compositions/Leads'
import { TPaginationRequest } from '../types/pagination'

export interface TProductivityResponse {
    ERR: string
    DATA: any
    Headers: {
      'x-pagination-total-count': number[],
      'x-pagination-page-count': number[],
      'x-pagination-current-page': number[],
    }
  }

export async function fetchGetProductivityRecruiter(payload: TPaginationRequest): Promise<AxiosResponse<TProductivityResponse>> {
  const response = await $api.get<TProductivityResponse>(`/api/v1/productivity/recruiter?page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filters ? payload.filters : ''}${payload.search ? `&search=${payload.search}` : ''}`)
  return response
}

export async function fetchGetProductivityResearcher(payload: TPaginationRequest): Promise<AxiosResponse<TProductivityResponse>> {
  const response = await $api.get<TProductivityResponse>(`/api/v1/productivity/researcher?page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filters ? payload.filters : ''}${payload.search ? `&search=${payload.search}` : ''}`)
  return response
}
