import { TFormFilter } from './components/ModalHistoryRequestAction'

export function objectToQueryString(obj:TFormFilter):string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }

      if (key === 'date') {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[created_at]=${strArrStr}`
      }
    }
  }

  return str
}

export const checkObj = (data: any) => {
  if (Array.isArray(data)) {
    return data
  }

  return typeof (data) === 'object' ? data.value : data
}

export const copyObj = (data: any) => {
  if (Array.isArray(data)) {
    return data.join(', ')
  }

  return typeof (data) === 'object' ? data.value : data
}
