/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, {
  FC, useEffect, useMemo, useState, memo, useCallback, useRef, useLayoutEffect
} from 'react'
import { useParams } from 'react-router'
import {
  Button, Tooltip, Typography,
  message, Upload, Radio
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import { data } from 'autoprefixer'
import {
  reset,
  getCandidates, getDictionaries, setDefDataCandidate
} from '../../ManagamentCandidates/actions'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import { openFilters } from '../../../features/filters/actions'

import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import FiltersIcon from '../../../common/svg/FiltersIcon'

import './CostOfHiring.scss'
import {
  selectCandidates,
  selectDefDataCandidate,
  selectHeaders,
  selectLoading

} from '../../ManagamentCandidates/selectors'
import CostOfHiringTable from './CostOfHiringTable'
import ButtonsTable from './ButtonsTable'
import FileDownloadGreen from '../../../common/svg/FileDownloadGreen'
import AddPlusSvgGreen from '../../../common/svg/AddPlusSvgGreen'
import ShowGreen from '../../../common/svg/ShowGreen'
import { selectCountFilters, selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import NewEntryModal from '../../../features/CostOfHiringModals/NewEntryModal'
import {selectIsOpenHidePost, selectIsOpenNewEntry} from '../selectors'
import { setOpenModalAutoDial } from '../../Fluidity/actions'
import { setOpenModalHidePost, setOpenModalNewEntry } from '../actions'
import HidePostModal from '../../../features/CostOfHiringModals/HidePostModal'

const mainCssClass = getCssClassName('cost-of-hiring')

const CostOfHiring: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const defDataCandidate = useSelector(selectDefDataCandidate)
  const [page, setPage] = useState<number>(defDataCandidate?.page || 1)
  const filtersCount = useSelector(selectCountFilters)
  const candidatesList = useSelector(selectCandidates)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const defFilters = useSelector(selectDefFilters)
  const headers = useSelector(selectHeaders)
  const modalNewEntryOpen = useSelector(selectIsOpenNewEntry)
  const modalHidePostOpen = useSelector(selectIsOpenHidePost)
  const prevDefDataCandidateRef = useRef(defDataCandidate)
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataCandidate?.perPage || 10,
      name: defDataCandidate?.search || ''
    }
  })
  const smoke = ''
  const handleNewEntryModalCancel = () => {
    dispatch(setOpenModalNewEntry(false))
  }
  const handleOpenNewEntry = useCallback(() => {
    dispatch(setOpenModalNewEntry(true))
  }, [])
  const handleHidePostModalCancel = () => {
    dispatch(setOpenModalHidePost(false))
  }
  const handleOpenHidePost = useCallback(() => {
    dispatch(setOpenModalHidePost(true))
  }, [])
  type TFormValues = {
    perPagesCount: number
    name: string
  }
  const perPagesCount = watch('perPagesCount')

  const maxPage = useMemo(() => {
    if (headers?.total && headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }
  type TRowData = {
    article_type: string
    resource: string
    cost_of_everything: string
    dark_store: string
    white_store: string
    buttons: JSX.Element
    id:number
  }
  const data: TRowData[] = [
    {
      article_type: 'Тип 1',
      resource: 'Ресурс 1',
      cost_of_everything: '1000',
      dark_store: 'Дарк стор 1',
      white_store: 'Вайт стор 1',
      buttons: <ButtonsTable id={1} />,
      id: 1
    },
    {
      article_type: 'Тип 2',
      resource: 'Ресурс 2',
      cost_of_everything: '2000',
      dark_store: 'Дарк стор 2',
      white_store: 'Вайт стор 2',
      buttons: <ButtonsTable id={2} />,
      id: 2
    },
    {
      article_type: 'Тип 3',
      resource: 'Ресурс 3',
      cost_of_everything: '3000',
      dark_store: 'Дарк стор 3',
      white_store: 'Вайт стор 3',
      buttons: <ButtonsTable id={3} />,
      id: 3
    },
    {
      article_type: 'Тип 4',
      resource: 'Ресурс 4',
      cost_of_everything: '4000',
      dark_store: 'Дарк стор 4',
      white_store: 'Вайт стор 4',
      buttons: <ButtonsTable id={4} />,
      id: 4
    },
    {
      article_type: 'Тип 5',
      resource: 'Ресурс 5',
      cost_of_everything: '5000',
      dark_store: 'Дарк стор 5',
      white_store: 'Вайт стор 5',
      buttons: <ButtonsTable id={5} />,
      id: 5
    },
    {
      article_type: 'Тип 6',
      resource: 'Ресурс 6',
      cost_of_everything: '6000',
      dark_store: 'Дарк стор 6',
      white_store: 'Вайт стор 6',
      buttons: <ButtonsTable id={6} />,
      id: 6
    },
    {
      article_type: 'Тип 7',
      resource: 'Ресурс 7',
      cost_of_everything: '7000',
      dark_store: 'Дарк стор 7',
      white_store: 'Вайт стор 7',
      buttons: <ButtonsTable id={7} />,
      id: 7
    },
    {
      article_type: 'Тип 8',
      resource: 'Ресурс 8',
      cost_of_everything: '8000',
      dark_store: 'Дарк стор 8',
      white_store: 'Вайт стор 8',
      buttons: <ButtonsTable id={8} />,
      id: 8
    },
    {
      article_type: 'Тип 9',
      resource: 'Ресурс 9',
      cost_of_everything: '9000',
      dark_store: 'Дарк стор 9',
      white_store: 'Вайт стор 9',
      buttons: <ButtonsTable id={9} />,
      id: 9
    },
    {
      article_type: 'Тип 10',
      resource: 'Ресурс 10',
      cost_of_everything: '10000',
      dark_store: 'Дарк стор 10',
      white_store: 'Вайт стор 10',
      buttons: <ButtonsTable id={10} />,
      id: 10
    },
    {
      article_type: 'Тип 11',
      resource: 'Ресурс 11',
      cost_of_everything: '11000',
      dark_store: 'Дарк стор 11',
      white_store: 'Вайт стор 11',
      buttons: <ButtonsTable id={11} />,
      id: 11
    },
    {
      article_type: 'Тип 12',
      resource: 'Ресурс 12',
      cost_of_everything: '12000',
      dark_store: 'Дарк стор 12',
      white_store: 'Вайт стор 12',
      buttons: <ButtonsTable id={12} />,
      id: 12
    },
    {
      article_type: 'Тип 13',
      resource: 'Ресурс 13',
      cost_of_everything: '13000',
      dark_store: 'Дарк стор 13',
      white_store: 'Вайт стор 13',
      buttons: <ButtonsTable id={13} />,
      id: 13
    },
    {
      article_type: 'Тип 14',
      resource: 'Ресурс 14',
      cost_of_everything: '14000',
      dark_store: 'Дарк стор 14',
      white_store: 'Вайт стор 14',
      buttons: <ButtonsTable id={14} />,
      id: 14
    },
    {
      article_type: 'Тип 15',
      resource: 'Ресурс 15',
      cost_of_everything: '15000',
      dark_store: 'Дарк стор 15',
      white_store: 'Вайт стор 15',
      buttons: <ButtonsTable id={15} />,
      id: 15
    }
  ]

  useEffect(() => {
    if (filtersString && ((!pageId && defFilters?.type === 'candidates') || (pageId === defFilters?.type))) {
      dispatch(setDefDataCandidate({
        page,
        perPage: perPagesCount,
        filters: defDataCandidate?.search
          ? `${filtersString}&search=${defDataCandidate?.search}` : filtersString,
        search: defDataCandidate?.search || ''
      }))
    } else if (!filtersString && defDataCandidate?.search) {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: `&search=${defDataCandidate?.search}`, search: defDataCandidate?.search || ''
      }))
    } else {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: '', search: defDataCandidate?.search || ''
      }))
    }
  }, [page, perPagesCount, filtersString])

  useEffect(() => {
    if (defDataCandidate !== prevDefDataCandidateRef.current && defDataCandidate) {
      prevDefDataCandidateRef.current = defDataCandidate // Обновите предыдущее значение
      dispatch(getCandidates({
        page: defDataCandidate?.page,
        perPage: defDataCandidate?.perPage,
        filters: defDataCandidate?.filters
      }))
    }
  }, [defDataCandidate])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  // @ts-ignore
  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>Стоимость найма</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton icon="left" size="large" typeButton="secondary" className="button" onClick={handleFiltersOpen}>
                <FiltersIcon />
                Фильтры
                {filtersCount >= 1 ? (
                  <span className={`${mainCssClass}_count-in-brackets`}>
                    (
                    {filtersCount}
                    )
                  </span>
                ) : (
                  ''
                )}
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>

          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <div className={`${mainCssClass}__search-container`}>
                <Radio.Group className={`${mainCssClass}__tabs`}>
                  <Radio.Button value="&filter[status][in][]=92">
                    Новичок&nbsp;
                    {/* {actualCount || ''} */}
                  </Radio.Button>
                  <Radio.Button value="&filter[status][in][]=91">
                    Привлеченный&nbsp;
                    {/* {considerationCount || ''} */}
                  </Radio.Button>
                  <Radio.Button value="&filter[status][in][]=650">
                    Отчет&nbsp;
                    {/* {archiveCount || ''} */}
                  </Radio.Button>
                </Radio.Group>
              </div>
              <Button type="ghost" icon={<FileDownloadGreen />} className={cn(`${mainCssClass}__excel`)}>
                Выгрузить файл
                {' '}
              </Button>
              <Button type="ghost" icon={<AddPlusSvgGreen />} onClick={handleOpenNewEntry} className={cn(`${mainCssClass}__excel`)}>
                Добавить запись
                {' '}
              </Button>
              <Button type="ghost" icon={<ShowGreen />} className={cn(`${mainCssClass}__excel`)}>
                {' '}
                Скрытые записи
                {' '}
              </Button>
            </div>

            {!!headers?.totalCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === maxPage ? '#BFC5C0' : '#404542'} />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__result-container`}>
            <div className={`${mainCssClass}__result-formalized`}>
              <div className={`${mainCssClass}__result-formalized-text`}>
                Общее количество оформленных
              </div>
              <div className={`${mainCssClass}__result-formalized-number`}>
                1433
              </div>
            </div>
            <div className={`${mainCssClass}__formalized-dark`}>
              <div className={`${mainCssClass}__formalized-dark-text`}>
                Оформлено Дарк стор
              </div>
              <div className={`${mainCssClass}__formalized-dark-number`}>
                972
              </div>
            </div>
            <div className={`${mainCssClass}__formalized-white`}>
              <div className={`${mainCssClass}__formalized-white-text`}>
                Оформлено Вайт стор
              </div>
              <div className={`${mainCssClass}__formalized-white-number`}>
                461
              </div>
            </div>
          </div>
          <div className={`${mainCssClass}__price-container`}>
            <div className={`${mainCssClass}__price-formalized`}>
              <div className={`${mainCssClass}__price-formalized-text`}>
                Стоимость 1 оформленного кандидата
              </div>
              <div className={`${mainCssClass}__price-formalized-number`}>
                30 000 ₽
              </div>
            </div>
            <div className={`${mainCssClass}__price-dark`}>
              <div className={`${mainCssClass}__price-dark-text`}>
                Стоимость Дарк стор
              </div>
              <div className={`${mainCssClass}__price-dark-number`}>
                28 000 ₽
              </div>
            </div>
            <div className={`${mainCssClass}__price-white`}>
              <div className={`${mainCssClass}__price-white-text`}>
                Стоимость Вайт стор
              </div>
              <div className={`${mainCssClass}__formalized-white-number`}>
                32 000 ₽
              </div>
            </div>
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <CostOfHiringTable className={`${mainCssClass}__table`} data={data} />
            </NewScrollContainer>
          </div>
          <NewEntryModal
            open={modalNewEntryOpen}
            className={`${mainCssClass}__modals`}
            onCancel={handleNewEntryModalCancel}
          />
          <HidePostModal
            open={modalHidePostOpen}
            className={`${mainCssClass}__modals`}
            onCancel={handleHidePostModalCancel}
          />
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(CostOfHiring))
