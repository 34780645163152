/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, useParams } from 'react-router'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Progress } from 'antd'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { getNotifications, selectDefDataNotify } from '../../../features/notificaitionCenter'
import MyAccount from '../../Systems/components/MyAccount'
import SystemsNav from '../../Systems/components/SystemsNav'
import {
  selectBaseUrl,
  selectIsFirstLogin,
  selectLoading,
  selectLoadingProgress,
  selectModalAutoCallOpen,
  selectModalAutoCallOptions,
  selectNavigateToUrl,
  selectOpenRefreshModalByTime,
  selectOpenRefreshModalByVersion,
  selectPhoneModal,
  selectQueueModal,
  selectRbacModel,
  selectSubUrl,
  selectUserInfo,
  selectVisibleTable
} from '../selectors'
import Candidate from '../../candidateV2/components/Candidate'
import InnerMenu from './InnerMenu'
import ErrorPage from '../../../features/errorPage/ErrorPage'
import { navigateTo, setIsFirstLogin } from '../../../common/actions/navigate'
import { TBaseUrl } from '../types'
import {
  getInternalAutoCall,
  getStatusesMobiles,
  getUserActivity,
  getUserInfo,
  getVersionFront,
  setBaseUrl,
  setCallMessage,
  setLeadMessage,
  setMessageWhatsApp,
  setSubUrl,
  showOpenModalRefresh,
  showOpenModalRefreshByTime
} from '../actions'
import CandidateList from '../../ManagamentCandidates/components/CandidateList'
import FormAuth from '../../auth/components/FormAuth'
import ManagementRequest from '../../ManagementRequests/components/ManagementRequests'
import ManagementVacancies from '../../ManagementVacancies/components/ManagementVacancies'
import ChangePassword from '../../Systems/components/ChangePassword'
import RequestPage from '../../Request/components/RequestPage'
import ManagementInterviews from '../../ManagementInterviews/components/ManagementInterviews'
import Interview from '../../InterviewCard/components/Interview'
import { EntityContextProvider } from '../../../common/context/EntityContextProvider'
import Funnel from '../../Funnel/components/Funnel'
import { selectFilters } from '../../../features/filters/selectors'
import Filters, { formValuesArray } from '../../../features/filters/components/Filters'
import CalendarView from '../../Calendar/components/CalendarView'
import SettingsCalendar from '../../../features/SettingsCalendar/components/SettingsCalendar'
import { selectOpenSettingsCalendar } from '../../../features/SettingsCalendar/selectors'
import Questionnaire from '../../Systems/components/Questionnaire'
import Triggers from '../../Systems/components/Triggers'
import Dictionary from '../../Systems/components/Dictionary'
import Templates from '../../Systems/components/Templates'
import StageSetup from '../../Systems/components/StageSetup'
import RoleListConstructor from '../../Systems/components/RoleListConstructor'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import UserListConstructor from '../../Systems/components/UserListConstructor'
import StoreList from '../../Systems/components/StoreList'
import { openReportFilters } from '../../../features/reportFilter/actions'
import TasksPage from '../../Tasks/components/TasksPage'
import { selectTasksFilter } from '../../Tasks'
import { selectFiltersType, selectReportFilter } from '../../../features/reportFilter'
import ReportFilter from '../../../features/reportFilter/components/ReportFilter'
import TasksFilter from '../../Tasks/components/TasksFilter'
import Analytics from '../../analytics/components/Analytics'
import WebRTCPhoneComponent from '../../../app/JsSipPhone'
import InformationModal from '../../candidate/components/InformationModal'
import { selectCandidate, selectInformationModal } from '../../candidate/selectors'
import WhatsappTemplates from '../../Systems/components/WhatsappTemplates'
import CallBackSetting from '../../Systems/components/CallBackSetting'

import { selectInterviews, selectWorkloadFilter, selectWorkloadPage } from '../../ManagementInterviews/selectors'
import WorkloadFilters from '../../ManagementInterviews/components/WorkloadFilters'
import Asterisk from '../../Asterisk'
import { selectInterview } from '../../InterviewCard/selectors'
import { selectSelectedVacancyEdit, selectVacancies } from '../../ManagementVacancies/selectors'
import { showNotification } from '../../../common/components/notification/utils'
import { selectCandidates } from '../../ManagamentCandidates/selectors'
import { selectRequest } from '../../Request/selectors'
import { selectRequests } from '../../ManagementRequests/selectors'
import RefreshFrontModal from '../../../features/refreshModals/components/RefreshFrontModal'
import RefreshModal from '../../../features/refreshModals/components/RefreshModal'
import ReminderList from '../../Systems/components/ReminderList'
import ControlPanelPhone from './ControlPanelPhone'
import PhoneModal from './PhoneModal'
import EmployeesCenter from '../../../features/employeesModal/components/employeesModal'
import { selectOpenEmployeesCenter } from '../../../features/employeesModal/selectors'
import Tokens from '../../Systems/components/Tokens'
import alarmSound from '../viber.mp3'
import Leads from '../../Leads/components/Leads'
import LeadsFilter from '../../Leads/components/LeadsFilter'
import { selectOpenLeadsFilter } from '../../Leads'
import Internship from '../../internship/components/Internship'
import InternshipView from '../../internship/components/InternshipView'
import { selectInternshipFilter, selectOpenInternshipModal } from '../../internship/selectors'
import InternshipModal from '../../internship/components/InternshipModal'
import ModalAutoCall from './ModalAutoCall'
import MyWhatsappTemplates from '../../Systems/components/MyWhatsappTemplates'
import { setUpdateTimerToLogout } from '../../../app/utils'
import PaperPlane from '../../../common/svg/PaperPlane'
import FluidityList from '../../Fluidity/components/FluidityList'
import CostOfHiring from '../../CostOfHiring/components/CostOfHiring'
import Phot from '../../Phot/components/Phot'
import Parsing from '../../parsing/components/Parsing'
import AutoCalls from '../../autocalls/components/AutoCalls'
import InternshipFilters from '../../internship/components/InternshipFilters'
import DepartmentWorkPlan from '../../DepartmentWorkPlan/components/DepartmentWorkPlan'
import QueueModal from './QueueModal'
import QuestionMark from '../../../common/svg/Question_mark'
import { getDialogs } from '../../../features/chatPanel'
import CandidatesListChat from '../../../features/chatPanel/components/CandidateListChat'
import { jsonParseDownloadAccess } from '../../Systems/utils'
import ProductivityPage from '../../Productivity/components/ProductivityPage'
import { allTargetURL } from '../utils'
import {
  selectActiveTab,
  selectProductivityRecruiterFilter,
  selectProductivityResearcherFilter
} from '../../Productivity'
import ProductivityRecruiterFilter from '../../Productivity/components/ProductivityRecruiterFilter'
import ProductivityResearcherFilter from '../../Productivity/components/ProductivityResearcherFilter'
import './InnerRouter.scss'
import { setFiltersLabels } from '../../../features/filters/actions'
import { setMessageForChat } from '../../../features/chat/actions'
import { selectChatDrawer } from '../../candidateV2/selectors'
import Garland from '../../../common/svg/Garland'
import UnionNewYear from '../../../common/svg/UnionNewYear'
import EllipseNewYaer from '../../../common/svg/EllipseNewYaer'

export type TUrlParams = {
  pageId?:
    | 'vacancies'
    | 'candidates'
    | 'interviews'
    | 'analytics'
    | 'candidate'
    | 'interview'
    | 'vacancy'
    | 'systems'
    | 'tasks'
    | 'asterisk'
    | 'internships'
    | 'parsing'
    | 'autocalls'
    | 'costOfHiring'
    | 'phot'
    | 'departmentWorkPlan'
    | 'fluidity'
  entityId?: string
  subPageId?: string
}

const mainCssClass = 'inner-router'

const InnerRouter: FC = () => {
  const userInfo = useSelector(selectUserInfo)
  const userId = localStorage.getItem('user_id')
  const [percent, setPercent] = useState(0)
  const [updateSocket, setUpdateSocket] = useState<number>(0)
  const [reconnectTimeout, setReconnectTimeout] = useState(null)
  const [notify, setNotify] = useState(0)
  const dispatch = useDispatch()
  const { pageId, subPageId, entityId } = useParams<TUrlParams>()
  const navigate = useNavigate()
  const navigateToUrl: string | null = useSelector(selectNavigateToUrl)
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const subUrl: string | null = useSelector(selectSubUrl)
  const visibleTable: boolean = useSelector(selectVisibleTable)
  const rbacModel = useSelector(selectRbacModel)
  const targetUrl: TBaseUrl = baseUrl || pageId || 'candidates'
  const targetSubUrl: string | null = subUrl || subPageId || null
  const filters = useSelector(selectFilters)
  const tasksFilter = useSelector(selectTasksFilter)
  const openSettings = useSelector(selectOpenSettingsCalendar)
  const token = localStorage.getItem('token')
  const reportFilter = useSelector(selectReportFilter)
  const filterType = useSelector(selectFiltersType)
  const informationModal = useSelector(selectInformationModal)
  const loading = useSelector(selectLoading)
  const workloadPage = useSelector(selectWorkloadPage)
  const workloadFilter = useSelector(selectWorkloadFilter)
  const candidate = useSelector(selectCandidate)
  const candidates = useSelector(selectCandidates)
  const interview = useSelector(selectInterview)
  const interviews = useSelector(selectInterviews)
  const request = useSelector(selectRequest)
  const requests = useSelector(selectRequests)
  const vacancy = useSelector(selectSelectedVacancyEdit)
  const vacancies = useSelector(selectVacancies)
  const phoneModal = useSelector(selectPhoneModal)
  const queueModal = useSelector(selectQueueModal)
  const openRefreshModalByTime = useSelector(selectOpenRefreshModalByTime)
  const openRefreshModalByVersion = useSelector(selectOpenRefreshModalByVersion)
  const openModalAutoCall = useSelector(selectModalAutoCallOpen)
  const openEmployeesCenter = useSelector(selectOpenEmployeesCenter)
  const leadsFilter = useSelector(selectOpenLeadsFilter)
  const isFirstLogin = useSelector(selectIsFirstLogin)
  const defDataNotify = useSelector(selectDefDataNotify)
  const internshipFilter = useSelector(selectInternshipFilter)
  const loadingProgress = useSelector(selectLoadingProgress)
  const productivityRecruiterFilter = useSelector(selectProductivityRecruiterFilter)
  const productivityResearcherFilter = useSelector(selectProductivityResearcherFilter)
  const chatDrawer = useSelector(selectChatDrawer)
  const activeTab = useSelector(selectActiveTab)
  const alarmAudio = new Audio(alarmSound)

  const [isHovered, setIsHovered] = useState(false)
  const activeInterview = useMemo(() => {
    if (userInfo && 'has_active_interview' in userInfo && 'id' in userInfo.has_active_interview) {
      return userInfo.has_active_interview.id
    }
    return null
  }, [userInfo])

  const handleClickQuestion = () => {
    window.open('https://t.me/HrmSupportBot', '_blank')
  }

  useEffect(() => {
    if (userId) {
      const timerId = setTimeout(() => {
        dispatch(getNotifications(defDataNotify))
      }, 1500)
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [userId, defDataNotify, notify])

  useEffect(() => {
    if (userId && notify && !userInfo?.is_sound_notify) {
      alarmAudio?.play()
    }
  }, [notify])

  const isNotCorrectTargetUrl = useMemo(() => {
    if (targetUrl) {
      if (allTargetURL.includes(targetUrl)) {
        return false
      }
      return true
    }
  }, [targetUrl, entityId])

  useEffect(() => {
    if (subPageId) {
      dispatch(setSubUrl(subPageId))
    } else {
      dispatch(setSubUrl(null))
    }
  }, [subPageId])

  useEffect(() => {
    if (navigateToUrl) {
      navigate(navigateToUrl)
      dispatch(setBaseUrl(null))
      dispatch(navigateTo(null))
    }
  }, [navigateToUrl])

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo(true))
      dispatch(getStatusesMobiles())
      dispatch(getVersionFront())
    }
  }, [token])

  useEffect(() => {
    if (localStorage.getItem('redirectUrl') && localStorage.getItem('token') && userInfo) {
      const redirectUrl = localStorage.getItem('redirectUrl')
      if (redirectUrl) {
        setTimeout(() => {
          window.location.href = redirectUrl
        }, 200)
      }
      localStorage.removeItem('redirectUrl')
    }
  }, [userInfo, loading, localStorage.getItem('redirectUrl')])

  useEffect(() => {
    if (
      activeInterview &&
      targetUrl !== 'candidate' &&
      targetUrl !== 'vacancies' &&
      targetUrl !== 'application' &&
      targetUrl !== 'applications' &&
      targetUrl !== 'vacancy' &&
      !localStorage.getItem('redirectUrl')
    ) {
      dispatch(navigateTo(`/interview/${activeInterview}`))
    }
  }, [activeInterview, targetUrl])

  useEffect(() => {
    if (userInfo?.department === 175 && (targetUrl === 'interviews' || targetUrl === 'interview') && !activeInterview) {
      dispatch(navigateTo('/candidates'))
    }
    // if (!pageId) {
    //   dispatch(navigateTo('candidates'))
    // }
  }, [targetUrl, userInfo, activeInterview])

  useEffect(() => {
    const interval = setInterval(() => {
      if (targetUrl) {
        dispatch(showOpenModalRefreshByTime(true))
      }
    }, 7200000)
    return () => clearInterval(interval)
  }, [dispatch, targetUrl, candidate, candidates, interview, interviews, request, requests, vacancy, vacancies])

  useEffect(() => {
    const interval = setInterval(() => dispatch(getVersionFront()), 300000)

    return () => clearInterval(interval)
  }, [dispatch])
  useEffect(() => {
    if (rbacModel && rbacModel.main_page && isFirstLogin) {
      dispatch(setIsFirstLogin(false))
      localStorage.setItem('initial_url', rbacModel.main_page)
      window.location.href = rbacModel.main_page
    }
  }, [isFirstLogin, rbacModel])

  useEffect(() => {
    setUpdateTimerToLogout()
    dispatch(getDialogs({ page: 1 }))
  }, [])

  useEffect(() => {
    let timer1: unknown
    let timer2: unknown
    let timer3: unknown
    let timer4: unknown

    if (loadingProgress) {
      timer1 = setTimeout(() => {
        setPercent(29)
      }, 200) // через 0.2 секунды показываем 29%

      timer2 = setTimeout(() => {
        setPercent(64)
      }, 400) // еще через 0.2 секунды показываем 64%
    } else {
      timer3 = setTimeout(() => {
        setPercent(100)
      }, 600) // через 0.2 секунды после отключения показываем 100%

      timer4 = setTimeout(() => {
        setPercent(0) // Через 0.2 секунды после достижения 100%, скрываем прогресс-бар
      }, 800)
    }

    // Очищаем таймеры при демонтировании компонента или изменении loadingProgress
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
      clearTimeout(timer4)
    }
  }, [loadingProgress])

  useEffect(() => {
    if (token) {
      const socket = new WebSocket(`${process.env.DOMAIN_WSS}/wss/chatonline?token=${token}`)

      // const reconnect = () => {
      //   clearTimeout(reconnectTimeout)
      //   setReconnectTimeout(setTimeout(() => {
      //     setUpdateSocket((prev) => prev + 1)
      //   }, 60000))
      // }

      socket.onopen = () => {
        console.log('Соединение установлено')
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout)
        }
      }

      socket.onmessage = (event) => {
        try {
          if (event.data) {
            const data = JSON.parse(event.data)
            if (data.type === 'message') {
              const message = data.data
              dispatch(setMessageForChat(message))
              dispatch(setMessageWhatsApp(message))
            }
            if (data.type === 'notify') {
              setNotify((prev) => prev + 1)
            }
            if (data.type === 'call_queue') {
              const message = data.data
              dispatch(setCallMessage(message))
            }
            if (data.type === 'lead_queue') {
              const message = data.data
              dispatch(setLeadMessage(message))
            }
            if (data.type === 'hrmJobButton') {
              dispatch(getUserActivity())
            }
          }
        } catch (error) {
          console.error('Ошибка при парсинге JSON:', error)
        }
      }

      socket.onclose = () => {
        console.log('Соединение закрыто')
      }

      socket.onerror = () => {
        console.log('Ошибка соединения')
      }

      return () => {
        socket.close()
      }
    }
  }, [updateSocket, token])

  return (
    <NewScrollContainer autoHeight autoHeightMax="100%" className="mainScroll">
      {percent > 0 && (
        <div className={`${mainCssClass}__progress`}>
          <Progress type="line" strokeColor="#2DBE64" percent={percent} showInfo={false} size="small" />
        </div>
      )}
      {!localStorage.getItem('token') && <FormAuth />}
      {isNotCorrectTargetUrl &&
        localStorage.getItem('token') &&
        targetUrl !== 'role-error' &&
        targetUrl !== 'init-error' && <ErrorPage errorCode={404} />}
      {targetUrl === 'role-error' && localStorage.getItem('token') && <ErrorPage errorCode="role-error" />}
      {targetUrl === 'init-error' && localStorage.getItem('token') && <ErrorPage errorCode="init-error" />}
      {targetUrl === 'is-candidate-group-error' && localStorage.getItem('token') && (
        <ErrorPage errorCode="is-candidate" />
      )}
      {!rbacModel && localStorage.getItem('token') && <Preloader />}
      {targetUrl === 'asterisk' && (
        <div className={`${mainCssClass}__asterisk`}>
          {' '}
          <Asterisk />{' '}
        </div>
      )}
      {openEmployeesCenter && <EmployeesCenter open={openEmployeesCenter} />}
      {phoneModal && <PhoneModal open={phoneModal} />}
      {queueModal && <QueueModal open={queueModal} />}

      {!isNotCorrectTargetUrl &&
        localStorage.getItem('token') &&
        rbacModel &&
        !localStorage.getItem('redirectUrl') &&
        targetUrl !== 'asterisk' && (
          <div className={`${mainCssClass}__candidate-with-navbar`}>
            <InnerMenu />
            {userInfo && !!userInfo?.sipSettings && !!userInfo?.sipSettings?.websocket_proxy_url && (
              <div className={`${mainCssClass}__webrtc-phone`}>
                <WebRTCPhoneComponent />
              </div>
            )}

            {targetUrl !== 'analytics' && targetUrl !== 'event-funnel' && targetUrl !== 'systems' && (
              <div className={`${mainCssClass}__block-main`}>
                {/* Удалить поле НГ className={`${mainCssClass}__block-main_newYear`} */}
                <div className={`${mainCssClass}__block-main_newYear`}>
                  <div className={`${mainCssClass}__block-main_newYear_first`}>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                  </div>
                </div>
                {rbacModel?.panels?.is_mobile_panel_visible === 1 && (
                  <div className={`${mainCssClass}__block-panel`}>
                    <ControlPanelPhone targetUrl={targetUrl} />
                  </div>
                )}

                <div className={`${mainCssClass}__candidates-table`}>
                  <EntityContextProvider
                    email={userInfo?.email}
                    phone={userInfo?.phone}
                    surname={userInfo?.surname}
                    name={userInfo?.name}
                    patronymic={userInfo?.patronymic}
                    avatar={userInfo?.avatar}
                    role={userInfo?.role}
                    rbacControl={rbacModel}
                    has_active_interview={userInfo?.has_active_interview}
                    isCanAppointRecruiter={userInfo?.isCanAppointRecruiter}
                    isColdSearch={userInfo?.isColdSearch}
                    department={userInfo?.department}
                    is_mass_stage={!!userInfo?.is_mass_stage}
                    download_access={jsonParseDownloadAccess(userInfo?.download_access)}
                  >
                    {targetUrl === 'candidate' && <Candidate />}
                    {targetUrl === 'candidates' && <CandidateList />}
                    {targetUrl === 'applications' && rbacModel?.request.page_access === 1 && <ManagementRequest />}
                    {targetUrl === 'application' && rbacModel?.request.page_access === 1 && <RequestPage />}
                    {targetUrl === 'vacancies' && rbacModel?.vacancy.page_access === 1 && <ManagementVacancies />}
                    {targetUrl === 'tasks' && <TasksPage />}
                    {targetUrl === 'fluidity' && <FluidityList />}
                    {targetUrl === 'costOfHiring' && <CostOfHiring />}
                    {targetUrl === 'phot' && <Phot />}
                    {targetUrl === 'departmentWorkPlan' && <DepartmentWorkPlan />}
                    {targetUrl === 'interview' && userInfo?.department !== 175 && <Interview />}
                    {targetUrl === 'internships' && userInfo?.is_can_internship && <InternshipView />}
                    {targetUrl === 'interviews' &&
                    userInfo?.department !== 175 &&
                    rbacModel?.interview.page_access === 1 ? (
                      (userInfo?.role?.role_id === 'recruiter' ||
                        userInfo?.isColdSearch ||
                        userInfo?.is_can_conduct_interview) &&
                      !visibleTable &&
                      rbacModel.interview.calendar_access === 1 ? (
                        <CalendarView />
                      ) : (
                        <ManagementInterviews />
                      )
                    ) : null}
                    {targetUrl === 'leads' && rbacModel?.leads.page_access === 1 && <Leads />}
                    {targetUrl === 'parsing' && <Parsing />}
                    {targetUrl === 'productivity' && <ProductivityPage />}
                    {targetUrl === 'autocalls' && rbacModel?.call.page_access && <AutoCalls />}
                  </EntityContextProvider>
                </div>
              </div>
            )}

            {targetUrl === 'analytics' && (rbacModel?.analytics.page_access === 1 || userInfo?.can_make_report) && (
              <div className={`${mainCssClass}__block-main`}>
                {/* Удалить поле НГ className={`${mainCssClass}__block-main_newYear`} */}
                <div className={`${mainCssClass}__block-main_newYear`}>
                  <div className={`${mainCssClass}__block-main_newYear_first`}>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                  </div>
                </div>
                <EntityContextProvider
                  email={userInfo?.email}
                  phone={userInfo?.phone}
                  surname={userInfo?.surname}
                  name={userInfo?.name}
                  patronymic={userInfo?.patronymic}
                  avatar={userInfo?.avatar}
                  role={userInfo?.role}
                  rbacControl={rbacModel}
                  has_active_interview={userInfo?.has_active_interview}
                  isCanAppointRecruiter={userInfo?.isCanAppointRecruiter}
                  isColdSearch={userInfo?.isColdSearch}
                  department={userInfo?.department}
                  is_mass_stage={!!userInfo?.is_mass_stage}
                  download_access={jsonParseDownloadAccess(userInfo?.download_access)}
                >
                  {rbacModel?.panels?.is_mobile_panel_visible === 1 && (
                    <div className={`${mainCssClass}__block-panel`}>
                      <ControlPanelPhone targetUrl={targetUrl} />
                    </div>
                  )}
                  <div
                    className={
                      reportFilter
                        ? `${mainCssClass}__candidates-table-analyticsWithFilters`
                        : `${mainCssClass}__candidates-table-analytics`
                    }
                  >
                    <Analytics />
                  </div>
                </EntityContextProvider>
              </div>
            )}

            {targetUrl === 'event-funnel' && rbacModel?.candidate.edit_view_access === 1 && (
              <div className={`${mainCssClass}__block-main-funnel`}>
                {/* Удалить поле НГ className={`${mainCssClass}__block-main-funnel_newYear``} */}
                <div className={`${mainCssClass}__block-main-funnel_newYear`}>
                  <div className={`${mainCssClass}__block-main-funnel_newYear_first`}>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                    <p>
                      <Garland />
                    </p>
                  </div>
                </div>
                {rbacModel?.panels?.is_mobile_panel_visible === 1 && (
                  <div className={`${mainCssClass}__block-panel`}>
                    <ControlPanelPhone targetUrl={targetUrl} />
                  </div>
                )}
                <div
                  className={
                    filters
                      ? `${mainCssClass}__candidates-table-funnelWithFilters`
                      : `${mainCssClass}__candidates-table-funnel`
                  }
                >
                  <EntityContextProvider
                    email={userInfo?.email}
                    phone={userInfo?.phone}
                    surname={userInfo?.surname}
                    name={userInfo?.name}
                    patronymic={userInfo?.patronymic}
                    avatar={userInfo?.avatar}
                    role={userInfo?.role}
                    has_active_interview={userInfo?.has_active_interview}
                    rbacControl={rbacModel}
                    department={userInfo?.department}
                    is_mass_stage={!!userInfo?.is_mass_stage}
                    download_access={jsonParseDownloadAccess(userInfo?.download_access)}
                  >
                    <Funnel className={`${mainCssClass}__funnel`} />
                  </EntityContextProvider>
                </div>
              </div>
            )}
            {targetUrl === 'systems' && (
              <div className={`${mainCssClass}__system-page`}>
                <div className={`${mainCssClass}__system-page_wrapper`}>
                  <EntityContextProvider
                    email={userInfo?.email}
                    phone={userInfo?.phone}
                    surname={userInfo?.surname}
                    name={userInfo?.name}
                    patronymic={userInfo?.patronymic}
                    avatar={userInfo?.avatar}
                    role={userInfo?.role}
                    rbacControl={rbacModel}
                    has_active_interview={userInfo?.has_active_interview}
                    department={userInfo?.department}
                  >
                    <SystemsNav />
                    {targetSubUrl === 'userListConstructor' && rbacModel?.users.page_access === 1 && (
                      <UserListConstructor />
                    )}
                    {targetSubUrl === 'questionnaire' && rbacModel?.questionnare.create_access === 1 && (
                      <Questionnaire />
                    )}
                    {targetSubUrl === 'roleListConstructor' && rbacModel?.role.create_access === 1 && (
                      <RoleListConstructor />
                    )}
                    {targetSubUrl === 'stores' && <StoreList />}
                    {targetSubUrl === 'my' && rbacModel?.profile.create_access === 1 && <MyAccount />}
                    {targetSubUrl === 'password' && rbacModel?.profile.create_access === 1 && <ChangePassword />}
                    {targetSubUrl === 'triggers' && rbacModel?.template.create_access === 1 && <Triggers />}
                    {targetSubUrl === 'templates' && rbacModel?.template.create_access === 1 && <Templates />}
                    {targetSubUrl === 'tokens' && rbacModel?.profile.create_access === 1 && <Tokens />}
                    {targetSubUrl === 'dictionary' && rbacModel?.dictionary.create_access === 1 && <Dictionary />}
                    {targetSubUrl === 'stageSetup' && rbacModel?.default_stage.create_access === 1 && <StageSetup />}
                    {targetSubUrl === 'whatsappTemplates' && rbacModel?.profile.create_access === 1 && (
                      <WhatsappTemplates />
                    )}
                    {targetSubUrl === 'myWhatsappTemplates' && rbacModel?.profile.create_access === 1 && (
                      <MyWhatsappTemplates />
                    )}
                    {targetSubUrl === 'callbackSetting' && rbacModel?.profile.create_access === 1 && (
                      <CallBackSetting />
                    )}
                    {targetSubUrl === 'reminders' && rbacModel?.profile.create_access === 1 && <ReminderList />}
                  </EntityContextProvider>
                </div>
              </div>
            )}

            {targetUrl === 'candidates' && filters && <Filters candidate />}
            {targetUrl === 'applications' && rbacModel?.request.page_access === 1 && filters && <Filters request />}
            {targetUrl === 'vacancies' && rbacModel?.vacancy.page_access === 1 && filters && <Filters vacancy />}
            {targetUrl === 'tasks' && tasksFilter && <TasksFilter />}
            {targetUrl === 'leads' && leadsFilter && rbacModel?.leads.page_access === 1 && <LeadsFilter />}
            {targetUrl === 'productivity' && productivityRecruiterFilter && activeTab === 'recruiter' && (
              <ProductivityRecruiterFilter />
            )}
            {targetUrl === 'productivity' && productivityResearcherFilter && activeTab === 'researcher' && (
              <ProductivityResearcherFilter />
            )}
            {targetUrl === 'internships' && userInfo?.is_can_internship && internshipFilter && <InternshipFilters />}
            {targetUrl === 'event-funnel' && rbacModel?.candidate.edit_view_access === 1 && filters && (
              <Filters vacancyPage />
            )}
            {targetUrl === 'analytics' && reportFilter && <ReportFilter type={filterType} />}

            {targetUrl === 'interviews' &&
              openSettings &&
              (userInfo?.role?.role_id === 'recruiter' ||
                userInfo?.isColdSearch ||
                userInfo?.is_can_conduct_interview) &&
              !visibleTable && (
                <EntityContextProvider
                  email={userInfo?.email}
                  phone={userInfo?.phone}
                  surname={userInfo?.surname}
                  name={userInfo?.name}
                  patronymic={userInfo?.patronymic}
                  avatar={userInfo?.avatar}
                  role={userInfo?.role}
                  rbacControl={rbacModel}
                  calendar_settings={userInfo?.calendar_settings}
                  department={userInfo?.department}
                >
                  <SettingsCalendar />
                </EntityContextProvider>
              )}
            {targetUrl === 'interviews' && filters && visibleTable && !workloadPage && <Filters interview />}
            {targetUrl === 'interviews' && visibleTable && workloadPage && workloadFilter && <WorkloadFilters />}
            {informationModal && <InformationModal open={informationModal} />}
            <RefreshFrontModal open={openRefreshModalByTime} />
            <RefreshModal open={openRefreshModalByVersion} />
            {openModalAutoCall && <ModalAutoCall open={openModalAutoCall} />}
            {targetUrl !== 'candidate' && (
              <>
                <div
                  className={`${mainCssClass}__first-line`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{ width: isHovered ? '180px' : '96px' }}
                >
                  <div className={`${mainCssClass}__question-mark`}>
                    <QuestionMark />
                  </div>
                  {isHovered && (
                    <span className={`${mainCssClass}__question-text`} onClick={handleClickQuestion}>
                      Задать вопрос
                    </span>
                  )}
                  <div className={`${mainCssClass}__first-line_union`}>
                    <UnionNewYear color="#FFD608" />
                  </div>
                  <div className={`${mainCssClass}__first-line_ellipse`}>
                    <EllipseNewYaer color="#e5e7eb" />
                  </div>
                </div>
              </>
            )}
            <CandidatesListChat />
          </div>
        )}
    </NewScrollContainer>
  )
}

export default InnerRouter
