import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import MenuDrop from '../../../common/svg/MenuDrop'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { setHandleModalHistoryOpen, setHandleModalQuestionnaireHistoryOpen } from '../actions'
import {selectModalHistoryQuestionnaireInterviewOpen} from "../selectors";

type TProps ={
  id: string | number | null
  snapshot:string
}
const ButtonsTable: FC<TProps> = ({ id, snapshot }) => {
  const dispatch = useDispatch()


  const handleOpenInfo = useCallback(() => {
    dispatch(setHandleModalHistoryOpen(true))
  }, [])

  const handleOpenQuestionnaire = useCallback(() => {
    dispatch(setHandleModalQuestionnaireHistoryOpen(snapshot))
  }, [snapshot])

  const items: MenuProps['items'] = [
    {
      key: 'info',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenInfo}>
          Подробная информация
        </Button>
      )
    },
    {
      key: 'questionnaire',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenQuestionnaire}>
          Анкета
        </Button>
      )
    }
  ]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button
        type="ghost"
        icon={<MenuDrop />}
        size="large"
      />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
