import React, {
  memo,
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography, Tabs
} from 'antd'
import { useForm } from 'react-hook-form'
import EditPencil1 from '../../../common/svg/EditPencil1'
import Redo from '../../../common/svg/Redo'
import Archive from '../../../common/svg/Archive'
import { getCssClassName } from '../../../common/utils/generateClassName'

import {
  archiveStatusRole,
  createOrEditRole,
  getRoleConstructor,
  getRoleList,
  reset,
  updateStatusRole
} from '../actions'
import {
  selectCreateOrEditRoleFormShow, selectDefDataRole, selectRoleHeaders, selectRoleListActive, selectRoleListArchive
} from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import { TRoleRbac } from '../../../common/api/systems'
import RoleForm from './RoleForm'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TabPane = Tabs.TabPane
import './RoleListConstructor.scss'

const mainCssClass = getCssClassName('role-list-constructor')

export type TFormValues = {
  perPagesCount: number
  filter:string
}

function RoleListConstructor() {
  const dispatch = useDispatch()
  const roleFormShow = useSelector(selectCreateOrEditRoleFormShow)
  const roleListActive = useSelector(selectRoleListActive)
  const roleListArchive = useSelector(selectRoleListArchive)
  const headers = useSelector(selectRoleHeaders)
  const defDataRole = useSelector(selectDefDataRole)
  const [page, setPage] = useState<number>(defDataRole?.page || 1)

  const { rbacControl } = useEntityContext()

  const {
    control, watch, getValues, setValue
  } = useForm<TFormValues>({
    defaultValues: {
      perPagesCount: defDataRole?.perPage
    }
  })
  const [roleStatus, setRoleStatus] = useState<string>('active')
  const activeRoleListOnTab = useMemo(() => {
    if (roleStatus === 'active') {
      return roleListActive
    }
    return roleListArchive
  }, [roleListArchive, roleListActive, roleStatus])

  const handleArchive = (id:string) => {
    dispatch(archiveStatusRole({ id, payloadData: { is_archive: 1, role_id: id } }))
  }

  const handleReturnArchive = (id:string) => {
    dispatch(updateStatusRole({ id, payloadData: { is_archive: 0, role_id: id } }))
  }

  const handleEditQuestionnaire = (item:TRoleRbac) => {
    dispatch(getRoleConstructor(item.role_id))
    dispatch(createOrEditRole(true))
  }

  const handleChangeStatusRole = useCallback((e) => {
    setRoleStatus(e)
  }, [roleListActive, roleListArchive])

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  // const handleNextPage = useCallback(() => {
  //   if (page !== headers?.total) {
  //     setPage((prev) => prev + 1)
  //   }
  // }, [page, headers])

  // const handlePreviousPage = useCallback(() => {
  //   if (page !== 1) {
  //     setPage((prev) => prev - 1)
  //   }
  // }, [page])

  // const handlePerPage = (selectedParametr:number) => {
  //   setValue('perPagesCount', selectedParametr)
  //   setPage(1)
  // }

  useEffect(() => {
    dispatch(getRoleList())
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>

      { !roleFormShow && (
        <div className={`${mainCssClass}_create`}>
          <Typography.Title level={3}>
            Роли
          </Typography.Title>
          {/* <InputFormControl name="roleName" control={control} placeholder="Начните вводить название..." /> */}
          <div className={`${mainCssClass}__btn-header-container`}>
            <Tabs onChange={handleChangeStatusRole} defaultActiveKey={roleStatus}>
              <TabPane
                key="active"
                tab={(
                  <div>
                    Активные&nbsp;
                    {roleListActive?.length || 0}
                  </div>
                )}
              />
              <TabPane
                key="archive"
                tab={(
                  <div>
                    Архив&nbsp;
                    {roleListArchive?.length || 0}
                  </div>
                )}
              />
            </Tabs>
            {rbacControl?.role.create_access === 1 && (
              <ControlButton typeButton="secondary" size="large" icon="left" className="button" onClick={() => dispatch(createOrEditRole(true))}>
                <AddPlusSvg />
                Создать роль
              </ControlButton>
            )}
          </div>
          {/*
          <div className={`${mainCssClass}_search-container`}>
            <InputFormControlGlobalSearch name="filter" control={control} placeholder="Начните вводить название" className={`${mainCssClass}_global-search`} />

            <SelectFormControl
              className={`${mainCssClass}__pagination-count`}
              name="perPagesCount"
              control={control}
              options={optionsPagination}
              onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
            />

            <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
              <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
              <Typography.Text className={`${mainCssClass}__pagination-font`}>
                {stringCountsPagination}
              </Typography.Text>
              <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === headers?.total ? '#BFC5C0' : '#404542'} />
            </div>
          </div> */}

          <div className={`${mainCssClass}_create_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_create_role-list`}>
                {
                  activeRoleListOnTab?.map((item) => (
                    <div
                      key={item.role_id}
                      className={`${mainCssClass}_create_role-list_item`}
                    >
                      <div className={`${mainCssClass}_create_role-list_item_text`}>
                        <Typography.Text className={`${mainCssClass}_create_role-list_item_text_title`}>
                          {item.is_archive !== 1 ? `${item.role_name}` : item.role_name }
                        </Typography.Text>
                      </div>
                      <div className={`${mainCssClass}_create_role-list_item_action`}>
                        {item.is_archive === 0
                          ? (
                            <>
                              {rbacControl?.role.edit_view_access === 1 && (
                                <ControlButton typeButton="secondary" className="button" onClick={() => handleEditQuestionnaire(item)}>
                                  <EditPencil1 />
                                </ControlButton>
                              )}
                              {rbacControl?.role.archive_access === 1 && (
                                <ControlButton typeButton="secondary" className="button" onClick={() => handleArchive(item.role_id)}>
                                  <Archive />
                                </ControlButton>
                              )}
                            </>
                          ) : (
                            <>
                              {rbacControl?.role.edit_view_access === 1 && (
                                <ControlButton typeButton="secondary" className="button" onClick={() => handleReturnArchive(item.role_id)}>
                                  <Redo />
                                </ControlButton>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </NewScrollContainer>
          </div>
        </div>
      )}

      {roleFormShow && rbacControl?.role.edit_view_access === 1 && (
        <RoleForm />
      )}
    </div>
  )
}

export default memo(withErrorBoundary(RoleListConstructor))
