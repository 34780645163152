import { put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios/index'
import { fetchPatchCandidate, fetchPatchUpdateStatusCandidate } from '../../common/api/Candidate'
import { TCandidate, TDATA, TStageRequest } from '../../compositions/candidate/types'
import {
  archiveCandidate,
  assignVacancy,
  TArchiveCandidateRequest,
  TRequestAssignVacancy,
  TUpdateCandidateStatusRequest,
  updateStatusCandidate
} from './actions'
import {
  getCandidates,
  getDictionaries,
  setOpenModalArchive,
  setOpenModalChangeStatus
} from '../../compositions/ManagamentCandidates/actions'
import { getRequests } from '../../compositions/ManagementRequests/actions'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { selectDefDataCandidate } from '../../compositions/ManagamentCandidates/selectors'
import { setLoadingModal, setLoadingProgress } from '../../compositions/InnerRouter/actions'
import { openArchiveModal, openStatusModal, openVacancyModal, setCandidate } from '../../compositions/candidateV2/actions'

const moduleName = 'Страница кандидата'
function* updateCandidateStatusSaga({ payload }: PayloadAction<TArchiveCandidateRequest>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TCandidate = yield fetchPatchUpdateStatusCandidate(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить статус кандидата',
          error: response as AxiosError
        })
      )
    } else {
      if (payload.isCandidateTable) {
        const data = yield select(selectDefDataCandidate)
        if (data) {
          yield put(getCandidates({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
      }
      yield put(setOpenModalChangeStatus(false))
      yield put(openStatusModal(false))
      yield put(setCandidate(response.data.DATA))
      showSuccessNotification({
        text: 'Статус кандидата обновлён'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* archiveCandidateStatusSaga({ payload }: PayloadAction<TArchiveCandidateRequest>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TCandidate = yield fetchPatchCandidate(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось архивировать кандидата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setOpenModalArchive(false))

      if (payload.isCandidateTable) {
        const data = yield select(selectDefDataCandidate)
        if (data) {
          yield put(getCandidates({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
      }
      yield put(setCandidate(response.data.DATA))
      yield put(setOpenModalArchive(false))
      yield put(openArchiveModal(false))
      showSuccessNotification({
        text: 'Кандидат архивирован'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* assignVacancySaga({ payload }: PayloadAction<TRequestAssignVacancy>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: TCandidate = yield fetchPatchCandidate(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось назначить вакансию',
          error: response as AxiosError
        })
      )
    } else {
      yield put(openVacancyModal(false))

      yield put(setCandidate(response.data.DATA))

      showSuccessNotification({
        text: 'Вакансия назначена'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

export function* modalsCandidatesSaga() {
  yield takeLatest(updateStatusCandidate, updateCandidateStatusSaga)
  yield takeLatest(archiveCandidate, archiveCandidateStatusSaga)
  yield takeLatest(assignVacancy, assignVacancySaga)
}
