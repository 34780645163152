import React, {
  memo,
  useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import {
  Dropdown, type MenuProps
} from 'antd'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import ControlButton from '../../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import MoreHorisontal from '../../../../common/svg/MoreHorisontal'
import ArrowLeftRight from '../../../../common/svg/ArrowLeftRight'
import EditPencil1 from '../../../../common/svg/EditPencil1'
import './HeaderButtonsPanel.scss'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import {
  appointAll, appointRecruiter, appointReseacher,
  openArchiveModal,
  openCommentModal,
  openEditCandidateDrawer,
  openStageStatusModal,
  openStatusModal,
  openUpdateModal,
  setNewInterview,
  setStageModal, setUpdateCandidateMode, updateStage
} from '../../actions'
import {
  selectCandidate
} from '../../selectors'
import { openTaskCreateModal } from '../../../Tasks'
import { setHandleModalEditOpen } from '../../../InterviewCard/actions'
import ChatCircleCheck from '../../../../common/svg/ChatCircleCheck'
import CloseSM from '../../../../common/svg/CloseSM'
import CheckBig from '../../../../common/svg/CheckBig'
import ModalRemoveFromArchive from '../ModalRemoveFromArchive'

const MainCSSClass = getCssClassName('headerButtonsPanel')

function HeaderButtonsPanel({ isMobile } : {isMobile: boolean}) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const userId = localStorage.getItem('user_id')
  const [removeArchive, setRemoveArchive] = useState(false)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]) // Массив для хранения рефов элементов меню
  const [visibleItems, setVisibleItems] = useState<any[]>([])
  const [hiddenItems, setHiddenItems] = useState<any[]>([])
  const [checkHiddenItemsOnZero, setCheckHiddenItemsOnZero] = useState<boolean>(false)

  const {
    rbacControl, role, isCanAppointRecruiter, isColdSearch, department, has_active_interview
  } = useEntityContext()

  const activeInterview = useMemo(() => {
    if (has_active_interview && 'id' in has_active_interview) {
      return has_active_interview
    }
    return null
  }, [has_active_interview])

  const items: MenuProps['items'] = useMemo(
    () => [

      {
        label: 'Комментарий',
        key: 'comment'
      },
      ...((rbacControl?.candidate?.edit_view_access === 1
        && ((department !== 175 && (role?.role_id === 'researcher' || isColdSearch))
        || (department === 175 && role?.role_id === 'recruiter' && !(candidate?.responsible)) || role?.role_id === 'superadmin'
        )) && role?.role_id !== 'courier_curator' ? [
          {
            label: 'Взять в работу',
            key: 'addWork'
          }
        ] : []),
      ...(rbacControl?.candidate?.edit_view_access === 1 ? [
        ...(!activeInterview && role?.role_id !== 'courier_curator' ? [{
          label: 'Перевести на этап',
          key: 'stage'
        }] : []),
        ...(role?.role_id !== 'courier_curator' ? [{
          label: 'Редактировать',
          key: 'edit'
        }] : []),
        ...(rbacControl?.candidate?.archive_access === 1 && candidate?.status !== 51 ? [
          {
            label: 'Отклонить',
            key: 'reject'
          }
        ] : []),
        ...(rbacControl?.candidate?.archive_access === 1 && candidate?.status === 51 ? [
          {
            label: 'Вернуть из отклоненных',
            key: 'remove'
          }
        ] : []),
        ...(role?.role_id === 'courier_curator' ? [{
          label: 'Принять',
          key: 'courier'
        }] : []),
        ...(!activeInterview && candidate?.vacancy_id
        && candidate?.request_id
        && candidate?.researcher && role?.role_id !== 'courier_curator'
          ? ((candidate?.id_interview && !candidate?.result_interview && !activeInterview))
            ? [{
              label: ''
                + 'Редактировать интервью',
              key: 'editInterview'
            }]
            : [{
              label: ''
                + 'Назначить интервью',
              key: 'AddInterview'
            }] : []),
        ...(role?.role_id !== 'courier_curator' ? [{
          label: 'Создать задачу',
          key: 'task'
        }] : []),
        ...(!activeInterview && role?.role_id !== 'courier_curator' ? [{
          label: 'Изменить статус на этапе',
          key: 'stageState'
        }] : [])
      ] : [])],
    [candidate, activeInterview, isCanAppointRecruiter]
  )

  const handleAppoint = () => {
    if (userId && candidate) {
      if (department !== 175) {
        if (isColdSearch) {
          dispatch(appointAll({ id: candidate?.id, researcher: userId, responsible: userId }))
        } else {
          dispatch(appointReseacher({ id: candidate?.id, researcher: userId }))
        }
      }
      if (department === 175) {
        dispatch(appointRecruiter({ id: candidate?.id, responsible: userId, stage_id: 680 }))
      }
    }
  }
  const handleTransferStage = () => {
    dispatch(updateStage({ stage: 10, candidate_id: candidate?.id }))
  }

  const handleOpenRemove = useCallback(() => {
    setRemoveArchive(true)
  }, [])

  const handleOkRemove = useCallback(() => {
    setRemoveArchive(false)
  }, [])

  const handleCancelRemove = useCallback(() => {
    setRemoveArchive(false)
  }, [])

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
    case 'comment':
      dispatch(openCommentModal(true))
      break
    case 'stage':
      dispatch(setStageModal(true))
      break
    case 'task':
      dispatch(openTaskCreateModal(true))
      break
    case 'editInterview':
      dispatch(setHandleModalEditOpen(true))
      break
    case 'AddInterview':
      dispatch(setNewInterview(true))
      break
    case 'stageState':
      dispatch(openStageStatusModal(true))
      break
    case 'edit':
      dispatch(setUpdateCandidateMode(true))
      dispatch(openEditCandidateDrawer(true))
      break
    case 'addWork':
      handleAppoint()
      break
    case 'reject':
      dispatch(openArchiveModal(true))
      break
    case 'remove':
      handleOpenRemove()
      break
    case 'courier':
      handleTransferStage()
      break
    default:
      break
    }
  }

  const menuProps = {
    items: hiddenItems,
    onClick: handleMenuClick
  }

  const menuPropsMobile = {
    items,
    onClick: handleMenuClick
  }
  const processedItems = useMemo(() => {
    // Фильтруем элементы и помещаем 'reject' в конец
    const filteredItems = visibleItems?.filter((item) => item.key !== 'reject') || []
    const rejectItem = visibleItems?.find((item) => item.key === 'reject')

    // Возвращаем новый массив с 'reject' в конце
    return rejectItem ? [...filteredItems, rejectItem] : filteredItems
  }, [visibleItems])

  const adjustMenuItems = useCallback((changeChekHiddenItemsOnZero?: Function) => {
    if (changeChekHiddenItemsOnZero) {
      changeChekHiddenItemsOnZero()
    }
    requestAnimationFrame(() => {
      const containerWidth = containerRef.current?.offsetWidth - 200 || 0
      let totalWidth = 0
      const visible: any[] = []
      const hidden: any[] = []

      items.forEach((item, index) => {
        totalWidth += itemRefs.current[index]?.offsetWidth || 0 // Получаем ширину элемента через реф
        if (totalWidth <= containerWidth) {
          visible.push(item)
        } else {
          hidden.push(item)
        }
      })

      setVisibleItems(visible)
      setHiddenItems(hidden)
    })
  }, [items])

  // Устанавливаем рефы и запускаем измерение после полной отрисовки
  useEffect(() => {
    if (!isMobile) {
      itemRefs.current = items.map((_, i) => itemRefs.current[i] || null)
    }
  }, [items, adjustMenuItems, isMobile])

  // Подписка на изменения размеров контейнера
  useEffect(() => {
    if (!isMobile) {
      const resizeObserver = new ResizeObserver(debounce(() => {
        adjustMenuItems()
      }, 200))

      if (containerRef?.current) {
        resizeObserver.observe(containerRef.current)
      }

      // adjustMenuItems() // Выполняем измерение сразу после рендера

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [items, isMobile])

  useEffect(() => {
    if (!isMobile) {
      if (hiddenItems.length === 0 && !checkHiddenItemsOnZero) {
        const changeChekHiddenItemsOnZero = () => {
          setCheckHiddenItemsOnZero(true)
        }
        adjustMenuItems(changeChekHiddenItemsOnZero)
      }
      if (hiddenItems.length > 0) {
        setCheckHiddenItemsOnZero(false)
      }
    }
  }, [hiddenItems, checkHiddenItemsOnZero, isMobile])

  return (
    <div className={MainCSSClass} ref={containerRef}>

      {!isMobile

      && (
        <>

          {hiddenItems.length > 0 && (
            <Dropdown menu={menuProps}>
              {rbacControl?.candidate.edit_view_access && (
                <ControlButton typeButton="secondary" className={`button ${MainCSSClass}__panel-buttons_font`}>
                  <MoreHorisontal className={`${MainCSSClass}__panel-buttons_icon`} />
                  Еще
                </ControlButton>
              )}
            </Dropdown>
          )}

          {processedItems
            .map((item, index) => (
              <div
                key={index}
                ref={(el) => { itemRefs.current[index] = el }}
                className={cn(
                  `${MainCSSClass}_button`,
                  {
                    [`${MainCSSClass}_button-reject`]: item.key === 'reject'
                  },
                  {
                    [`${MainCSSClass}_button-primary`]: item.key === 'courier'
                  }
                )}
                onClick={() => handleMenuClick({ key: item.key })}
              >
                {item.key === 'comment' && <ChatCircleCheck color="#5A615C" />}
                {item.key === 'reject' && <CloseSM color="#DF1E12" />}
                {item.key === 'addWork' && <CheckBig color="#5A615C" />}
                {item.key === 'stage' && <ArrowLeftRight color="#5A615C" />}
                {item.key === 'edit' && <EditPencil1 color="#5A615C" />}
                {item.label}
              </div>
            ))}

        </>
      )}

      {isMobile
            && (
              <Dropdown menu={menuPropsMobile}>
                {rbacControl?.candidate.edit_view_access && (
                  <ControlButton typeButton="secondary" className={`button ${MainCSSClass}__panel-buttons_font`}>
                    <MoreHorisontal className={`${MainCSSClass}__panel-buttons_icon`} />
                    Действия
                  </ControlButton>
                )}
              </Dropdown>
            )}

      {removeArchive && (
        <ModalRemoveFromArchive
          open={removeArchive}
          onOk={handleOkRemove}
          onCancel={handleCancelRemove}
          candidateId={candidate?.id}
        />
      )}
    </div>
  )
}

export default memo(HeaderButtonsPanel)
