import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ map }: TRootState) => map

export const selectRequests = createSelector(selectState, ({ requests }) => requests.filter((request) => request?.store && request?.store?.lattitude && request?.store?.longtitude))

export const selectFilteredRequests = createSelector(selectState, ({ filteredRequests }) => filteredRequests)
export const selectFilteredPreviousRequests = createSelector(selectState, ({ filteredRequestsPrevious }) => filteredRequestsPrevious)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectFilter = createSelector(selectState, ({ filter }) => filter)
export const selectPreviousFilter = createSelector(selectState, ({ previousFilter }) => previousFilter)

export const selectZoomCoordinates = createSelector(selectState, ({ zoomCoordinates }) => zoomCoordinates)
export const selectOpenDetailsModal = createSelector(selectState, ({ openDetailsModal }) => openDetailsModal)
export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})
export const selectSelectedRequest = createSelector(selectState, ({ selectedRequest }) => selectedRequest)
export const selectFilterOptions = createSelector(selectState, ({ filterOptions }) => filterOptions)
export const selectSlicedRequests = createSelector(selectState, ({ slicedRequests }) => slicedRequests)
export const selectMapBounds = createSelector(selectState, ({ mapBounds }) => mapBounds)
export const selectSlicedRequestsTotalCounts = createSelector(selectState, ({ totalCountsOfSliced }) => totalCountsOfSliced)
export const selectPage = createSelector(selectState, ({ page }) => page)

export const selectStoreStatus = createSelector(selectState, ({ storeStatus }) => storeStatus)


export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

// eslint-disable-next-line max-len
export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})
