/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios'
import $api, { API_URL } from './http'
import { TRequestUserWorkPlan, TUserWorkPlanItem } from '../../compositions/DepartmentWorkPlan'

export interface TUserWorkPlanResponse {
    ERR: string
    DATA: TUserWorkPlanItem[]
    Headers: {
      'x-pagination-total-count': number[],
      'x-pagination-page-count': number[],
      'x-pagination-current-page': number[],
    }
  }

export async function fetchGetUserWorkPlan(payload: TRequestUserWorkPlan): Promise<AxiosResponse<TUserWorkPlanResponse>> {
  const response = await $api.get<TUserWorkPlanResponse>(`/api/v1/user-work-plans?page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filters ? payload.filters : ''}`)
  return response
}
