import { createReducer } from '@reduxjs/toolkit'
import {
  openDuplicateModal,
  openRequestDrawer,
  reset,
  setDictionaries,
  setEditRequestMode,
  setIsRequestTable,
  setResponsibleList
} from './actions'
import { TDictionaries, TOption } from '../../common/types/dictionaries'

type TInterviewModalsState = {
    editRequestMode: boolean
    duplicateModal: boolean
    requestDrawer: boolean
    requestTable: boolean
    dictionaries: TDictionaries | null
  responsibleList: TOption[]

};

const initialState: TInterviewModalsState = {
  editRequestMode: false,
  duplicateModal: false,
  requestDrawer: false,
  requestTable: false,
  dictionaries: null,
  responsibleList: []
}

const requestModalsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEditRequestMode, (state, { payload }) => {
      state.editRequestMode = payload
    })
    .addCase(openDuplicateModal, (state, { payload }) => {
      state.duplicateModal = payload
    })
    .addCase(openRequestDrawer, (state, { payload }) => {
      state.requestDrawer = payload
    })
    .addCase(setIsRequestTable, (state, { payload }) => {
      state.requestDrawer = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setResponsibleList, (state, { payload }) => {
      state.responsibleList = payload
    })
})

export default requestModalsReducer
