import React, { memo } from 'react'
import { Button, ButtonProps } from 'antd'
import cn from 'classnames'
import './Button.scss'
import withErrorBoundary from '../../hoc/withErrorBoundary/withErrorBoundary'

type TControlButtonProps =
  ButtonProps & {
  size?: 'small' | 'large'
  typeButton?: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'ghost' | 'mobil'
  icon?: 'right' | 'none' | 'left' | 'iconOnly' | React.ReactNode
  state?: 'default' | 'hover' | 'pressed' | 'disabled'
}

function ControlButton({
  className,
  size = 'large',
  icon = 'none',
  state = 'default',
  ...rest
}: TControlButtonProps): JSX.Element {
  const mainCssClass = size === 'small' ? 'small-button' : 'large-button'

  const buttonClasses = cn(
    className,
    mainCssClass,
    `${rest.typeButton}`,
    `icon-${icon}`,
    `state-${state}`,
    { 'disabled': !!rest?.disabled }
  )

  return <Button className={buttonClasses} {...rest} />
}

export default memo(withErrorBoundary<TControlButtonProps>(ControlButton))
