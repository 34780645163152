import {
  Tag,
  Button, Modal, ModalProps, Typography
} from 'antd'
import React, {
  useMemo
} from 'react'

import cn from 'classnames'

import { getInterviewInfo, getInterviewInfoHistory } from '../../../compositions/InterviewCard/utils'
import { TInterview, TInterviewHistory } from '../../../common/api/interviews'
import './HistoryInterviewModals.scss'
import { useSelector } from 'react-redux'
import { selectDictionaryOptionsByName } from '../../../compositions/InterviewCard/selectors'
import { DetailsList } from '../../../common/components/DetailsList'

const { Title } = Typography
const mainCssClass = 'history-interview-modals'

type TProps = {
    tags: {label: string, value: string}[]
    interview: TInterviewHistory | null
} & ModalProps
function HistoryQuestionnaireInterviewModals({
  open, className, onCancel, interview, tags
}: TProps) {

  const detailsListInterview = useMemo(() => getInterviewInfoHistory(interview), [interview])
  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={handleModalClose}
      closable
      title="Анкета"
      className={cn(className, mainCssClass)}
      width="400px"
      wrapClassName="wrapModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >

      <div className={`${mainCssClass}_content-modal`}>
        <div>
          <div className={`${mainCssClass}_header`}>
            <Title level={4}>Вакансии:</Title>
          </div>
          { tags ? (
            <Tag key={tags?.value} className="customLabel" color="blue">
              {tags?.label}
            </Tag>
          ) : <span> Нет прикрепленных вакансий </span>}
        </div>
        <DetailsList
          size="md"
          items={[{
            items: detailsListInterview
          }]}
        />
      </div>
    </Modal>
  )
}

export default HistoryQuestionnaireInterviewModals
