import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import './ModalCloseSure.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { openCheckDocModal, updateCandidate } from '../actions'
import { selectCandidate } from '../selectors'

const mainCssClass = 'modalCloseSure'

function ModalCheckDoc({
  open, className
}: ModalProps) {
  const modalStyle = {
    zIndex: 19999
  }
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)

  const onCancel = () => {
    dispatch(openCheckDocModal(false))
  }

  const onOk = () => {
    if (candidate) {
      dispatch(updateCandidate({ id: candidate.id, is_validate_document: 1 }))
    }
    dispatch(openCheckDocModal(false))
  }
  return (
    <CommonModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title="Документы проверены"
      className={cn(className, mainCssClass)}
      width="400px"
      centered
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      style={modalStyle}
    >
      <p>Вы подтверждаете, что документы кандидата проверены?</p>
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(ModalCheckDoc))
