import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import './SimpleModalCandidate.scss'
import { useDispatch, useSelector } from 'react-redux'
import $api from '../../../common/api/http'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import { createLinkCandidate, setOpenModalMap } from '../actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate, selectOpenModalMap } from '../selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import ModalMap from '../../../features/map/components/ModalMap'
import { MapSvg } from '../../../common/svg/SvgFromIconWrapper/MapSvg'

const mainCssClass = 'simpleModalCandidate'

export type TProps = {
  candidateId?: number | null | string
  onOk: () => void
} & ModalProps

type TFormValues = {
  request_id_list: number
}

function ModalAssiagnApplication({
  onOk, open, className, onCancel, candidateId
}: TProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const selectOpenMap = useSelector(selectOpenModalMap)

  const fetchRequestList = useCallback(async (name: string) => {
    // eslint-disable-next-line max-len
    const data = await $api.get(`/api/v1/requests?page=1&filter[name][like]=${name}&filter[status][in][]=92`)
    return data.data.DATA.request_list.map((obj: { id: number; name: string }) => ({ label: `${obj.name} (вакансия: ${obj.vacancy_label})`, value: obj.id }))
  }, [candidate])

  const defaultValues = useMemo(
    () => (candidate?.request_id
      ? { request_id_list: { label: candidate.labels.request, value: candidate.request_id } }
      : {}),
    [candidate]
  )

  const {
    control, handleSubmit, reset, watch
  } = useForm<TFormValues>({ defaultValues })

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }

  const handleOpenMap = useCallback(() => {
    dispatch(setOpenModalMap(true))
  }, [])

  const onSubmit = useCallback(() => {
    if (candidateId) {
      const request = watch('request_id_list')
      dispatch(
        createLinkCandidate({
          request_id_list: [request],
          candidate_id: candidateId
        })
      )
      onOk()
      reset()
    }
  }, [candidateId])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleModalClose}
      title={candidate?.reject_id ? 'Переназначить на заявку' : 'Назначить на заявку'}
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <DebounceSelectFormControl
        name="request_id_list"
        control={control}
        placeholder="Выберите заявку"
        fetchOptions={fetchRequestList}
        label="Заявка"
        required
        rules={{ required: true }}
        notFoundContent="Начните вводить название заявки"
      />
      <Button className={`${mainCssClass}__map-btn`} icon={<MapSvg />} type="ghost" onClick={handleOpenMap}>Выбрать на карте </Button>
      <ModalMap open={selectOpenMap} isEdit candidate={candidate} />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalAssiagnApplication))
