import React, { memo, useCallback } from 'react'
import { ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import $api from '../../../common/api/http'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import './SimpleModalCandidate.scss'
import { appointReseacher } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'

const mainCssClass = 'simpleModalCandidate'

type TFormValues = {
  reseacher: number
  isResearcher: string | null
}

export type TProps = {
  researcher?: number | null
  candidate_id: number

} & ModalProps

function ModalAppointResearcher({
  open, className, onCancel, researcher, candidate_id
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, handleSubmit, getValues, watch
  } = useForm<TFormValues>()
  const values = [{ label: 'Назначить себе', value: true }]
  const isResearcher = watch('isResearcher')

  const fetchRecruiterList = useCallback(async (name: string) => {
    if (!name || !name.trim()) {
      const data = await $api.get('/api/v1/user/user-list?role_id=researcher')
      return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
        label: `${obj.name} ${obj.surname}`,
        value: obj.id
      }))
    }

    const data = await $api.get(`/api/v1/user/user-list?role_id=researcher&search=${name}`)

    return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
      label: `${obj.name} ${obj.surname}`,
      value: obj.id
    }))
  }, [])

  const onSubmit = () => {
    if (candidate_id) {
      if (isResearcher?.length) {
        const reseacher = localStorage.getItem('user_id')
        dispatch(appointReseacher({ id: candidate_id, researcher: Number(reseacher) }))
      } else {
        dispatch(appointReseacher({ id: candidate_id, ...getValues() }))
      }
    }
  }
  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      centered
      title={researcher ? 'Переназначить ресечера' : 'Назначить ресечера'}
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <CheckBoxFormControl
        options={values}
        control={control}
        name="isResearcher"
      />
      <DebounceSelectFormControl
        name="researcher"
        control={control}
        placeholder="Выберите ресечера"
        fetchOptions={fetchRecruiterList}
        notFoundContent="Начните вводить имя"
        label="Ресечер"
        disabled={!!isResearcher?.length}
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalAppointResearcher))
