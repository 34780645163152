import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, {
  memo, useEffect, useRef, useState
} from 'react'

interface OverflowTooltipProps {
  label: string;
  placement?: TooltipPlacement
  noOfLines?: number;
  className?: string;
}

function OverflowTooltip({ label, noOfLines = 1, className ,placement}: OverflowTooltipProps) {
  const textElementRef = useRef<HTMLDivElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)

  /**
   * Check whether the element is overflow or not
   */
  const compareSize = () => {
    const element = textElementRef.current

    const compare = element
      ? element.offsetWidth < element.scrollWidth
        || element.offsetHeight < element.scrollHeight
      : false

    setIsOverflown(compare)
  }

  useEffect(() => {
    compareSize()
  }, [])

  return (
    isOverflown
      ? (
        <Tooltip title={label} placement={placement || 'bottomRight'}>
          <p
            className={className}
            ref={textElementRef}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {label}
          </p>
        </Tooltip>
      )

      : (
        <p
          className={className}
          ref={textElementRef}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {label}
        </p>
      )
  )
}

export default OverflowTooltip
