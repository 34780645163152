import dayjs from 'dayjs'

export function getDayOfWeek(date: string) {
  const dayNumber = dayjs(date, 'DD.MM.YYYY').day()
  switch (dayNumber) {
  case 6: { return 'Сб' }
  case 0: { return 'Вс' }
  case 1: { return 'Пн' }
  case 2: { return 'Вт' }
  case 3: { return 'Ср' }
  case 4: { return 'Чт' }
  case 5: { return 'Пт' }
  }
}
