import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Typography } from 'antd'
import { Bar } from 'react-chartjs-2'
import { useForm } from 'react-hook-form'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { reset } from '../../../Systems'
import { selectFilterString } from '../../../../features/reportFilter'
import { selectLoading, selectProductivity, selectScenario } from '../../selectors'
import { getProductivity, setScenario } from '../../actions'
import { Heading } from '../../../../common/components/Text'
import ProductivityTable from './ProductivityTable'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { methodOptions } from '../../utils'
import { setVisibleTable } from '../../../InnerRouter/actions'
import TabPane = Tabs.TabPane
import SelectFormControl from '../../../../common/components/formcontrol/SelectFormControl'
import { Preloader } from '../../../../common/components/Preloader/Preloader'
import './Productivity.scss'

const mainCssClass = getCssClassName('productivity')

type TFormValues = {
    method:string
}

function Productivity() {
  const dispatch = useDispatch()
  const productivity = useSelector(selectProductivity)
  const filter = useSelector(selectFilterString)
  const scenario = useSelector(selectScenario)
  const filtersString = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)

  // const {
  //   control, watch, getValues, setValue
  // } = useForm<TFormValues>({ defaultValues: { method: 'summary' } })

  const handleTabClick = (key:string) => {
    dispatch(setScenario(key))
  }

  useEffect(() => {
    if (filtersString) {
      dispatch(getProductivity({ filter, scenario, method: 'summary' }))
    }
  }, [filter, scenario])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__title-block`}>
        <Heading.H3>
          Продуктивность
        </Heading.H3>
      </div>
      {loading && <Preloader size="md" />}

      {filtersString
      && !loading && (
        <>
          <div className={`${mainCssClass}__up-block`}>
            <div className={`${mainCssClass}__tabs`}>
              <Tabs onChange={(key) => handleTabClick(key)} defaultActiveKey={scenario}>
                <TabPane key="by_user" tab="По пользователям" />
                <TabPane key="by_vacancy" tab="По вакансиям" />
                <TabPane key="by_request" tab="По заявкам" />
              </Tabs>
            </div>
            {/* <div className={`${mainCssClass}__select-block`}>
              <SelectFormControl name="method" control={control} options={methodOptions} />
            </div> */}
          </div>

          <div>
            <ProductivityTable productivity={productivity} />
          </div>
        </>
      )}
      {!filtersString && !loading
      && (
        <div className={`${mainCssClass}_filter`}>
          <span>
            Отчет появится после настройки фильтров.
            <br />
            Настройте фильтры и нажмите кнопку (Применить)
          </span>
        </div>
      )}

    </div>

  )
}

export default memo(withErrorBoundary(Productivity))
