import { createAction } from '@reduxjs/toolkit'
import { TEditTable, TRequestUserWorkPlan, TUserWorkPlanHeaders, TUserWorkPlanItem } from '.'

export const getUserWorkPlan = createAction<TRequestUserWorkPlan>('userWorkPlan/getUserWorkPlan')
export const setUserWorkPlan = createAction<TUserWorkPlanItem[]>('userWorkPlan/setUserWorkPlan')
export const setLoading = createAction<boolean>('userWorkPlan/setLoading')
export const setHeaders = createAction<TUserWorkPlanHeaders>('userWorkPlan/setHeaders')
export const reset = createAction('userWorkPlan/setReset')

export const setEditInfoTable = createAction<TEditTable | null>('userWorkPlan/setEditInfoTable')

export const setActualPage = createAction<number>('userWorkPlan/actualPage')
export const setActualPerPage = createAction<number>('userWorkPlan/actualPerPage')
export const setActualFilters = createAction<string>('userWorkPlan/actualPerPage')

export const openMassSettingsModal = createAction<boolean>('userWorkPlan/openMassSettingsModal')
export const openPeriodModal = createAction<boolean>('userWorkPlan/openPeriodModal')
export const editPeriodModal = createAction<boolean>('userWorkPlan/editPeriodModal')
export const setSelectedPeriod = createAction<any>('userWorkPlan/setSelectedPeriod')

