import { useDispatch, useSelector } from 'react-redux'
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { Button, Tooltip, Typography } from 'antd'
import Logo from '../../../images/logo.svg'
import EditPencil1 from '../../../common/svg/EditPencil1'

import { getCandidateInfo } from '../utils'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  setHandleModalEditOpen,
  setHandleModalCancelOpen,
  setSelectedInterviewId,
  setHandleModalHistoryOpen,
  updateInterview,
  setHandleModalEndOpen
} from '../actions'

import { getUserInfo, setPreviousPage } from '../../InnerRouter/actions'
import {
  selectCandidate, selectInterview, selectLabelById, selectTypeById
} from '../selectors'
import { AcceptSolid } from '../../../common/svg/SvgFromIconWrapper/AcceptSolid'
import CardUser from '../../../common/svg/CardUser'
import { navigateTo } from '../../../common/actions/navigate'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import UserAvatar from '../../../common/svg/UserAvatar'
import ControlButton from '../../../common/components/Buttons/Button'
import { openActiveInterviewModal } from '../../../features/interviewModals/actions'
import { selectUserInfo } from '../../InnerRouter/selectors'
import { DetailsList } from '../../../common/components/DetailsList'
import { Heading } from '../../../common/components/Text'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './HeaderInterview.scss'

const mainCssClass = getCssClassName('header-interview')

const HeaderInterview: FC = () => {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const interview = useSelector(selectInterview)
  const { role } = useEntityContext()
  const detailsListCandidate = useMemo(() => getCandidateInfo(candidate), [candidate])
  const { rbacControl } = useEntityContext()
  const stageType = useSelector(selectTypeById(candidate?.stage_id, 'stageStatus'))

  const userInfo = useSelector(selectUserInfo)

  const activeInterview = useMemo(() => {
    if (userInfo && 'has_active_interview' in userInfo && 'id' in userInfo.has_active_interview) {
      return userInfo.has_active_interview.id
    }
    return null
  }, [userInfo])

  const handleModalEditOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalEditOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])

  const handleModalCancelOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalCancelOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])

  const handleModalRejectOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalEndOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])
  const handleModalStartInterviewOpen = useCallback(() => {
    if (interview) {
      dispatch(
        updateInterview({
          ...interview,
          status: 255
        })
      )
    }
  }, [interview])

  const handleAcceptInterviewInterviewOpen = useCallback(() => {
    if (interview) {
      dispatch(
        updateInterview({
          ...interview,
          confirm: 'confirmed'
        })
      )
    }
  }, [interview])
  const handleNavigate = useCallback(() => {
    if (activeInterview) { dispatch(openActiveInterviewModal(true)) }
    if (candidate?.id && !activeInterview) {
      dispatch(navigateTo(`/candidate/${candidate.id}`))
      dispatch(setPreviousPage({ navigate: `/interview/${interview?.id}`, caption: 'Карточка интервью' }))
    }
  }, [candidate, userInfo, activeInterview])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_header`}>
        <div className={`${mainCssClass}_avatar`}>
          <UserAvatar />
        </div>
        <div className={`${mainCssClass}_list`}>
          <DetailsList
            size="xs"
            items={[{
              items: detailsListCandidate
            }]}
          />

        </div>
      </div>
      <div className={`${mainCssClass}_actions`}>
        {interview?.status === 94 && role?.role_id === 'recruiter' && rbacControl?.interview.create_access === 1 && (
          <>
            <ControlButton
              className="button"
              onClick={handleModalStartInterviewOpen}
              typeButton="primary"
              icon="none"
            >
              {' '}
              <AcceptSolid />
              Начать
            </ControlButton>
          </>
        )}
        {role?.role_id === 'recruiter' && (interview?.status === 255 || interview?.status === 95) && rbacControl?.interview.create_access === 1 && (
          <ControlButton
            typeButton="primary"
            className="button"
            onClick={handleModalRejectOpen}
            icon="none"
          >
            <AcceptSolid />
            {interview?.status === 95 ? 'Редактировать результат' : 'Завершить'}
          </ControlButton>
        )}

        {interview?.status !== 95 && interview?.status !== 93 && rbacControl?.interview.create_access === 1 && (
          <ControlButton
            typeButton="secondary"
            className="button"
            size="large"
            icon="left"
            onClick={handleModalEditOpen}
          >
            <EditPencil1 size="s_svg" />
            Редактировать
          </ControlButton>
        )}
        {interview?.status !== 93 && interview?.status !== 95 && interview?.status !== 255 && rbacControl?.interview.create_access === 1 && (
          <ControlButton typeButton="warning" size="large" icon="none" className="button" onClick={handleModalCancelOpen}>
            Отменить
            {' '}
          </ControlButton>
        )}
        {interview?.confirm === 'unconfirmed' && interview?.status !== 255 && interview?.status !== 93 && rbacControl?.interview.create_access === 1 && (
          <ControlButton typeButton="primary" size="large" icon="none" state="default" className="button" onClick={handleAcceptInterviewInterviewOpen}>
            Подтвердить
            {' '}
          </ControlButton>
        )}
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(HeaderInterview))

