import { createReducer } from '@reduxjs/toolkit'
import {
  openInternshipDateModal,
  openInternshipFilter,
  reset, setDefDataInternship,
  setDictionaries,
  setInternship, setInternshipFilter, setInternshipModal,
  setLoading,
  setPage,
  setSearchInternship,
  setSelectedInternship,
  TInternship
} from './actions'

import { TDictionaries } from '../../common/types/dictionaries'
import InternshipModal from './components/InternshipModal'

export type TInternshipInitialState = {
  loading: boolean
  dictionaries: TDictionaries | null
  page: number
  internship: TInternship[] | null
  getInternshipModal:boolean
  selectedInternship:any
  internshipFilter: boolean
  filter: string
  defDataInternship: {
    page:number,
    perPage: number,
    filters: string,
    search: string
  } | null
  searchInternship: string
  internshipDateModal: boolean
}
const initialState: TInternshipInitialState = {
  loading: false,
  dictionaries: null,
  page: 0,
  internship: null,
  getInternshipModal: false,
  selectedInternship: null,
  internshipFilter: false,
  filter: '',
  defDataInternship: null,
  searchInternship: '',
  internshipDateModal: false
}

const internshipReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setPage, (state, { payload }) => {
      state.page = payload
    })
    .addCase(setInternship, (state, { payload }) => {
      state.internship = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setInternshipModal, (state, { payload }) => {
      state.getInternshipModal = payload
    })
    .addCase(setSelectedInternship, (state, { payload }) => {
      state.selectedInternship = payload
    })
    .addCase(setDefDataInternship, (state, { payload }) => {
      state.defDataInternship = payload
    })
    .addCase(openInternshipFilter, (state, { payload }) => {
      state.internshipFilter = payload
    })
    .addCase(setInternshipFilter, (state, { payload }) => {
      state.filter = payload
    })
    .addCase(setSearchInternship, (state, { payload }) => {
      state.searchInternship = payload
    })
    .addCase(openInternshipDateModal, (state, { payload }) => {
      state.internshipDateModal = payload
    })
})

export default internshipReducer
