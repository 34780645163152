import { createAction } from '@reduxjs/toolkit'
import { TBaseUrl, TUserActivityStatus } from './types'
import { TDATA } from '../candidate/types'
import { TOption } from '../../common/types/dictionaries'

export const setBaseUrl = createAction<TBaseUrl | null>('innerRouter/setBaseUrl')
export const setSubUrl = createAction<string | null>('innerRouter/setSubUrl')

export const getUserInfo = createAction<boolean>('innerRouter/getUserInfo')

export const setUserInfo = createAction<any>('innerRouter/setUserInfo')
export const setRbacInfo = createAction<any>('innerRouter/setRbacInfo')

export const setLoading = createAction<boolean>('innerRouter/setLoading')

export type TPreviousPage = {
  caption: string
  navigate: string
}

export const setPreviousPage = createAction<TPreviousPage>('innerRouter/setPreviosPage')

export const setLoadingProgress = createAction<boolean>('innerRouter/setLoadingProgress')

export const setVisibleTable = createAction<boolean>('innerRouter/setVisibleTable')

export const OpenInnerMenuMobil = createAction<boolean>('innerRouter/OpenInnerMenuMobil')

export const OpenSettingsCalendarMobil = createAction<boolean>('innerRouter/OpenSettingsCalendarMobil')
export const setDestinationNumber = createAction<number | string | null>('innerRouter/setDestinationNumber')
export const getTransferNumbers = createAction('innerRouter/getTransferNumbers')
export const setTransferNumbers = createAction<any>('innerRouter/setTransferNumbers')
export const setHangUpCall = createAction<boolean>('innerRouter/setHangUpCall')
export const setResumeCall = createAction<boolean>('innerRouter/setResumeCall')
export const setPauseCall = createAction<boolean>('innerRouter/setPauseCall')
export const setTransferCall = createAction<number | string | null>('innerRouter/setTransferCall')
export const setCandidateSelected = createAction<TDATA>('innerRouter/setCandidateSelected')
export const setConectedWss = createAction<boolean>('innerRouter/setConectedWss')
export const setMessageWhatsApp = createAction<any>('innerRouter/setMessageWhatsApp')

export const setLoadingModal = createAction<boolean>('innerRouter/setLoadingModal')
export const getStatusesMobiles = createAction('innerRouter/getStatusesMobiles')
export const setStatusesMobiles = createAction<TOption[]>('innerRouter/setStatusesMobiles')
export type TUpdateStatusesMobiles = {
  user_id: number
  status_id: number
}
export const updateStatusesMobiles = createAction<TUpdateStatusesMobiles>('innerRouter/updateStatusesMobiles')
export const getCurrentStatus = createAction('innerRouter/getCurrentStatus')
export const getCurrentRtt = createAction('innerRouter/getCurrentRtt')
export const setCurrentRtt = createAction<any>('innerRouter/setCurrentRtt')
export const setCurrentStatus = createAction<TOption | null>('innerRouter/setCurrentStatus')

export const showOpenModalRefreshByTime = createAction<boolean>(
  'innerRouter/showOpenModalRefreshByTime'
)
export const showOpenModalFrontRefreshByVersion = createAction<boolean>(
  'innerRouter/showOpenModalFrontRefreshByVersion'
)
export const getVersionFront = createAction('innerRouter/getVersionFront')
export const setVersionFront = createAction<string>('innerRouter/setVersionFront')
export const setFullVersion = createAction<boolean>('innerRouter/setFullVersion')

export const openPhoneModal = createAction<boolean>('innerRouter/openPhoneModal')
export const openQueueModal = createAction<boolean>('innerRouter/openQueueModal')

export const getInternalInformationByUserId = createAction('innerRouter/getInternalInformationByUserId')
export type TInternalInformationData ={
  total: {
    key: string,
    label: string,
    count: number
  },
  take: {
    key: string,
    label: string,
    count: number
  },
  miss: {
    key: string,
    label: string,
    count: number
  },
  done: {
    key: string,
    label: string,
    count: number
  }
}
export const setInternalInformationByUserId = createAction<TInternalInformationData>('innerRouter/setInternalInformationByUserId')
export const getInternalAutoCall = createAction('innerRouter/getInternalAutoCall')
export const setLoadingAutoCall = createAction<boolean>('innerRouter/setLoadingAutoCall')
export const setStatusPhone = createAction<boolean>('innerRouter/setStatusPhone')
export const setModalAutoCallOpen = createAction<boolean>('innerRouter/setModalAutoCallOpen')
export const setModalAutoCallId = createAction<number | null>('innerRouter/setModalAutoCallId')
export const setOptionsAutoCall = createAction<TOption[]>('innerRouter/setOptionsAutoCall')
export const getStartAutoCall = createAction<number>('innerRouter/getStartAutoCall')
export const getStopAutoCall = createAction<number>('innerRouter/getStopAutoCall')

export type TUserActivity = {
user_id: 1,
'status': 1,
'guid': 'xxxxx',
'command': 'start'
}

export const getUserActivity = createAction('innerRouter/getUserActivity')
export const setUserActivity = createAction<any>('innerRouter/setUserActivity')
export const changeUserActivity = createAction<TUserActivityStatus>('innerRouter/changeUserActivity')

export const setCallMessage = createAction<any>('innerRouter/setCallMessage')
export const setLeadMessage = createAction<any>('innerRouter/setLeadMessage')

export const setEditKeyDetailList = createAction<string>('innerRouter/setEditKeyDetailList')

export const setIconNumber = createAction<number>('innerRouter/setIconNumber')
