import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import excel from '../../common/svg/Excel'

const selectState = ({ calendar }: TRootState) => calendar

export const selectCalendars = createSelector(selectState, ({ calendar }) => calendar)

export const selectTimeSlots = createSelector(selectState, ({ timeSlots }) => timeSlots)

export const selectOpenModalInterview = createSelector(selectState, ({ openModalMoreInfo }) => openModalMoreInfo)

export const selectInterview = createSelector(selectState, ({ interview }) => interview)
export const selectSelectedInterviewId = createSelector(selectState, ({ selectedInterviewId }) => selectedInterviewId)
export const selectLoading = createSelector(selectState, ({ loading }) => loading)
export const selectPeriod = createSelector(selectState, ({ period }) => period)
export const selectPage = createSelector(selectState, ({ page }) => page)

