/* eslint-disable max-len */
import React, { memo, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectActiveCallback } from '../selectors'
import { createCallback, openCallbackSettingForm, setActiveCallback, updateCallback } from '../actions'
import CloseMD from '../../../common/svg/CloseMD'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import { formdOptions, getCallbackOption, methodOptions } from '../utils'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import './CallBackSettingForm.scss'

const mainCssClass = getCssClassName('callback-setting-form')
export type TCallback = {
  id: number
  event_id: number
  lid_utm_id: number
  method: 'get' | 'post'
  url: string
  params: string
  is_archive: boolean
  created_at: string
  event_label: string
  lid_utm_label: string
  form: boolean
  is_candidate_url: any
}

type TFormValues = {
  name: string
  event_id: string
  url: string
  method: 'get' | 'post'
  lid_utm_id: number
  params: { url_param: string; custom_param: string; candidate_field: number | null }[]
  form: boolean
  is_candidate_url: any
} & any

function CallBackSettingForm() {
  const dispatch = useDispatch()
  const [eventOptions, setEventOptions] = useState<any[]>([])
  const [lidOptions, setLidOptions] = useState<any[]>([])
  const [fieldOptions, setFieldOptions] = useState<any[]>([])
  const archiveOptions = [{ label: 'URL из настроек кандидата', value: '1' }]

  const activeCallback = useSelector(selectActiveCallback)

  const defaultValues = useMemo(
    () =>
      activeCallback
        ? {
            event_id: activeCallback.event_id,
            url: activeCallback.url,
            method: activeCallback.method,
            is_send_message: activeCallback.is_send_message,
            is_send_duplicate: activeCallback.is_send_duplicate,
            form: activeCallback.form ? 1 : 2,
            is_candidate_url: activeCallback?.is_candidate_url ? ['1'] : [],
            lid_utm_id: activeCallback.lid_utm_id,
            params: Array.isArray(activeCallback.params)
              ? activeCallback.params.map((el) => ({
                  url_param: el.url_param,
                  custom_param: el.custom_param,
                  candidate_field: el.candidate_field
                }))
              : JSON.parse(activeCallback.params).map((el) => ({
                  url_param: el.url_param,
                  custom_param: el.custom_param,
                  candidate_field: el.candidate_field
                }))
          }
        : {},
    [activeCallback]
  )

  const { control, handleSubmit, reset, getValues, watch, resetField, setValue } = useForm<TFormValues>({
    defaultValues
  })

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'params'
  })

  if (fields?.length === 0) {
    prepend({
      url_param: '',
      custom_param: '',
      candidate_field: null
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleCancel = () => {
    dispatch(setActiveCallback(null))
    dispatch(openCallbackSettingForm(false))
  }

  const onSubmit = () => {
    const value = getValues()

    if (!activeCallback) {
      dispatch(
        createCallback({
          ...value,
          is_candidate_url: !!value?.is_candidate_url?.length,
          params: JSON.stringify(value.params),
          form: value.form === 1
        })
      )
    } else {
      dispatch(
        updateCallback({
          ...value,
          id: activeCallback.id,
          is_candidate_url: !!value?.is_candidate_url?.length,
          params: JSON.stringify(value.params),
          form: value.form === 1
        })
      )
    }
  }

  useLayoutEffect(() => {
    getCallbackOption()
      .then((responseData) => {
        setEventOptions(responseData.event_list)
        setLidOptions(responseData.lid_list)
        setFieldOptions(responseData.candidate_label_list)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>{activeCallback ? 'Редактировать callback' : 'Новый callback'}</Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <SelectFormControl
              name="event_id"
              control={control}
              label="Событие"
              required
              rules={{ required: true }}
              options={eventOptions}
            />
            <SearchSelectFormControlSource
              name="lid_utm_id"
              control={control}
              label="Источник"
              required
              rules={{ required: true }}
              options={lidOptions}
              showSearch
              optionFilterProp="children"
            />

            <CheckBoxFormControl options={archiveOptions} control={control} name="is_candidate_url" />

            <RadioGroupFormControl
              name="method"
              control={control}
              options={methodOptions}
              label="Callback Метод"
              rules={{ required: true }}
            />

            <RadioGroupFormControl
              name="form"
              control={control}
              options={formdOptions}
              label="Тип передачи данных"
              rules={{ required: true }}
            />
            <div className={`${mainCssClass}_list_switch-row`}>
              <div className={`${mainCssClass}_list_switch-row_item`}>
                <SwitchFormControl control={control} name="is_send_message" />
                <p>Отправлять текстовые сообщения</p>
              </div>
              <div className={`${mainCssClass}_list_switch-row_item`}>
                <SwitchFormControl control={control} name="is_send_duplicate" />
                <p>Отправлять сообщения, если кандидат дубль</p>
              </div>
            </div>

            <InputFormControl name="url" control={control} label="URL" required rules={{ required: true }} />

            {fields?.map((item, index) =>
              index === 0 ? (
                <div className={`${mainCssClass}_list_item`} key={index}>
                  <InputFormControl name={`params[${index}].custom_param`} control={control} label="Параметр" />
                  <InputFormControl name={`params[${index}].url_param`} control={control} label="Значение из меток" />
                  <SelectFormControl
                    name={`params[${index}].candidate_field`}
                    control={control}
                    label="Поле из кандидата"
                    options={fieldOptions}
                  />
                </div>
              ) : (
                <div className={`${mainCssClass}_list_item`} key={index}>
                  <InputFormControl name={`params[${index}].custom_param`} control={control} label="Параметр" />
                  <InputFormControl name={`params[${index}].url_param`} control={control} label="Значение из меток" />
                  <SelectFormControl
                    name={`params[${index}].candidate_field`}
                    control={control}
                    label="Поле из кандидата"
                    options={fieldOptions}
                  />
                  <ControlButton onClick={() => handleRemove(index)} className="button" typeButton="warning">
                    <CloseMD />
                  </ControlButton>
                </div>
              )
            )}

            <div>
              <ControlButton
                onClick={() => append({ url_param: '', custom_param: '', candidate_field: null })}
                typeButton="secondary"
                className="button"
              >
                <AddPlusSvg />
                Добавить строчку
              </ControlButton>
            </div>
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}
export default memo(withErrorBoundary(CallBackSettingForm))
