import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, Typography, Radio
} from 'antd'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import Excel from '../../../common/svg/Excel'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import RequestsTable from './RequestsTable'
import {
  getRequests,
  reset,
  setOpenModalArchiveRequest,
  setOpenModalEditRequest,
  setOpenModalSure,
  setSelectedRequest,
  setSelectedRequestId,
  getDictionaries,
  archiveOpen,
  exportCSV
} from '../actions'
import {
  selectActualRequestCount,
  selectArchiveRequestCount,
  selectConsiderationRequestCount,
  selectDictionaryOptionsByName,
  selectModalArchiveOpen,
  selectModalEditOpen,
  selectOpenSureModal,
  selectRequests,
  selectResultRequestCount,
  selectSelectedRequest,
  selectSelectedRequestId,
  selectLoading
} from '../selectors'
import { openFilters, setFilters } from '../../../features/filters/actions'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import { removeFilterFragments } from '../utils'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { optionsPagination } from '../../../common/components/options'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ModalEditRequest from '../../../features/requestModals/components/ModalEditRequest'
import ModalArchiveRequest from '../../../features/requestModals/components/ModalArchiveRequest'
import ModalCloseSure from '../../candidate/components/ModalCloseSure'
import ControlButton from '../../../common/components/Buttons/Button'
import { selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import InputFormControlGlobalSearch from '../../../common/components/formcontrol/InputFormControlGlobalSearch'
import Map from '../../../common/svg/Map'
import './ManagementRequestsMobil.scss'
import ModalMap from '../../../features/map/components/ModalMap'
import { setOpenModalMap } from '../../candidate/actions'
import { selectOpenModalMap } from '../../candidate/selectors'
import { setDefStringInterviews } from '../../ManagementInterviews/actions'
import { setDefStringFunnel } from '../../Funnel/actions'
import MapRequest from '../../../common/svg/MapRequest'
import RequestsTableMobil from './RequestsTableMobil'

type TFormValues = {
    perPagesCount: number
    globalSearch:string
}

const mainCssClass = getCssClassName('management-requests-mobil')
const ManagementRequest: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const [searchValue, setSearchValue] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [requestStatus, setRequestStatus] = useState<string>('&filter[status][in][]=92')
  const requests = useSelector(selectRequests)
  const resultCount = useSelector(selectResultRequestCount)
  const considerationCount = useSelector(selectConsiderationRequestCount)
  const archiveCount = useSelector(selectArchiveRequestCount)
  const actualCount = useSelector(selectActualRequestCount)
  const editModal = useSelector(selectModalEditOpen)
  const archiveModal = useSelector(selectModalArchiveOpen)
  const requestId = useSelector(selectSelectedRequestId)
  const request = useSelector(selectSelectedRequest)
  const sureModal = useSelector(selectOpenSureModal)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const reasonOptions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const sheduleOptions = useSelector(selectDictionaryOptionsByName('schedule'))
  const defFilters = useSelector(selectDefFilters)
  const selectOpenMap = useSelector(selectOpenModalMap)
  const { control, watch, getValues } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10,
      globalSearch:''
    }
  })

  const perPagesCount = watch('perPagesCount')
  const maxPage = useMemo(() => {
    if (resultCount) {
      const counted = resultCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
    return 0
  }, [resultCount, perPagesCount])
  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])
  const stringCountsPagination = useMemo(() => {
    if (resultCount) {
      return `${perPagesCount * page - perPagesCount + 1} - ${
        resultCount > perPagesCount ? perPagesCount * page : perPagesCount * page + resultCount - perPagesCount
      } из ${resultCount}`
    }
    return ''
  }, [resultCount, perPagesCount, page])

  const handleCancelEdit = useCallback(() => dispatch(setOpenModalSure(true)), [])

  const handleSureClose = useCallback(() => dispatch(setOpenModalSure(false)), [])
  const handleSureOk = useCallback(() => {
    dispatch(setOpenModalEditRequest(false))
    dispatch(setOpenModalSure(false))
    dispatch(setSelectedRequest(null))
    dispatch(setSelectedRequestId(null))
  }, [])

  const handleCancelArchive = useCallback(() => {
    dispatch(setOpenModalArchiveRequest(false))
    dispatch(setSelectedRequestId(null))
  }, [])

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handleChangeRequestStatus = (e) => {
    setRequestStatus(e.target.value)
    dispatch(setFilters(`${removeFilterFragments(filtersString)}${e.target.value}`))
    if (e.target.value === '&filter[status][in][]=650') {
      dispatch(archiveOpen(true))
    } else {
      dispatch(archiveOpen(false))
    }
  }

  const globalSearch = watch('globalSearch')

  const handleExportCSV = () => {
    dispatch(exportCSV({
      has_head: true,
      filter: watch('globalSearch') ? `${filtersString || ''}&search=${watch('globalSearch')}` : (filtersString || '')
    }))
  }
  const handleModalMapOpen = () => {
    dispatch(setOpenModalMap(true))
  }

  useEffect(() => {
    setPage(1)
  }, [perPagesCount])


  useEffect(() => {
    dispatch(getDictionaries(['rejectionReason', 'schedule']))
    if ((pageId === defFilters?.type) && defFilters?.defFilterString) { dispatch(setFilters(defFilters?.defFilterString)) }
    dispatch(setDefStringInterviews(''))
    dispatch(setDefStringFunnel(''))
  }, [])

  const memoRequestStatus = useMemo(() => (filtersString !== requestStatus ? requestStatus : ''), [requestStatus, filtersString])
  const memoGlobalSearch = useMemo(() => watch('globalSearch'), [watch('globalSearch')])

  useEffect(() => {
    if (filtersString) {
      dispatch(getRequests({
        page,
        perPage: perPagesCount,
        filters: memoGlobalSearch
          ? `${filtersString}&search=${memoGlobalSearch}${memoRequestStatus}` : `${filtersString}${memoRequestStatus}`
      }))
    } else if (!filtersString && memoGlobalSearch) {
      dispatch(getRequests({ page, perPage: perPagesCount, filters: `&search=${memoGlobalSearch}${memoRequestStatus}` }))
    } else {
      dispatch(getRequests({ page, perPage: perPagesCount, filters: memoRequestStatus }))
    }
  }, [page, perPagesCount, filtersString, memoGlobalSearch, memoRequestStatus])

  useEffect(
    () => () => {
      dispatch(setFilters(''))
      dispatch(reset())
    },
    []
  )

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>Заявки</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        <div className={`${mainCssClass}__count`}>
          <Typography.Title level={5} className={`${mainCssClass}__title`}>
            &nbsp;
            {resultCount}
          </Typography.Title>
        </div>

      </div>

      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <InputFormControlGlobalSearch name="globalSearch" control={control} placeholder="Название заявки" />
          </div>
          <div className={`${mainCssClass}__container-info`}>
            {!!resultCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  control={control}
                />
                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === maxPage ? '#BFC5C0' : '#404542'} />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <ScrollContainer className={`${mainCssClass}__scroll-container`}>
              <RequestsTableMobil requests={requests || []} className={`${mainCssClass}__table`} />
            </ScrollContainer>
          </div>
          {request && (
            <>
              <ModalEditRequest
                editMode
                requestId={requestId}
                open={editModal}
                onCancel={handleCancelEdit}
                isRequestTable
                sheduleOptions={sheduleOptions}
              />
              <ModalCloseSure open={sureModal} onCancel={handleSureClose} onOk={handleSureOk} />
            </>
          )}
          {archiveModal && (
            <ModalArchiveRequest
              isRequestTable
              options={reasonOptions}
              open={archiveModal}
              onCancel={handleCancelArchive}
              requestId={requestId}
            />
          )}
        </>
      )}
      <ModalMap isEdit={false} open={selectOpenMap} />
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ManagementRequest))
