import { put, call, takeLatest, spawn } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { getDictionaries, setDictionaries } from './actions'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'компонент фильтр отчёта')
}

function* reportFilterSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
}

export default reportFilterSaga
