import {
  put, call, takeLatest, delay
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import {
  getAuth, getLogout, setLoading, setToken
} from './actions'
import { TAuth, TLoginCredentials } from './types'
import { fetchGetLogout, fetchPostAuth } from '../../common/api/Auth'
import { navigateTo, setIsFirstLogin } from '../../common/actions/navigate'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'

type AuthResponse = AxiosResponse<TAuth>

const moduleName = 'Страница авторизации'
function* loginSaga({ payload }: PayloadAction<TLoginCredentials>) {
  try {
    yield put(setLoading(true))
    const response: AuthResponse = yield fetchPostAuth(payload)

    if (response?.data?.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      localStorage.setItem('token', response.data.DATA.token)
      localStorage.setItem('token_refresh', response.data.DATA.token_refresh)
      localStorage.setItem('user_id', JSON.stringify(response.data.DATA.user_id))
      yield put(setToken(response.data.DATA.user_id))
      if (localStorage.getItem('redirectUrl')) {
        yield put(navigateTo('/candidates'))
      } else {
        if (localStorage.getItem('initial_url')) {
          window.location.href = `/${localStorage.getItem('initial_url')}`
        } else {
          window.location.href = '/candidates'
        }
      }
    }
  } catch (error) {
    console.error('Catch block error:', error) // Добавьте этот лог
    showCriticalNotification(
      getErrorNotification({
        moduleName,
        text: 'Не удалось авторизоваться',
        error: error.response
      })
    )
  } finally {
    yield put(setIsFirstLogin(true))
    yield put(setLoading(false))
  }
}

function* logoutSaga() {
  try {
    const response: AuthResponse = yield call(fetchGetLogout)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось выйти из учётной записи ',
          error: response as AxiosError
        })
      )
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('token_refresh')
      localStorage.removeItem('user_id')
      yield put(navigateTo('/'))
      showSuccessNotification({
        text: 'Выход из учётной записи выполнен'
      })
    }
  } catch (error) {
    throw Error(error)
  }
}

function* authSaga() {
  yield takeLatest(getAuth, loginSaga)
  yield takeLatest(getLogout, logoutSaga)
}

export default authSaga
