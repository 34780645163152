/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import {
  Checkbox, Tag, Tooltip, Typography
} from 'antd'

import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import ChatCircleDots from '../../../common/svg/ChatCircleDots'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TDATA } from '../../candidate/types'
import CardUser from '../../../common/svg/CardUser'
import { navigateTo } from '../../../common/actions/navigate'
import { TClassName } from '../../../common/types/props'
import { setPreviousPage } from '../../InnerRouter/actions'
import { Heading } from '../../../common/components/Text'
import OverflowTooltip from '../../../common/components/Ellipsis/components/Ellipsis'
import './CandidateCard.scss'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { useEntityContext } from '../../../common/context/EntityContextProvider'

type TProps = {
  candidate?: TDATA
  handleOnChange: (e: CheckboxChangeEvent)=> void
  containerId?: number
  checkedDisabled: number | null
  checkedValues: number[]
} & TClassName
const mainCssClass = 'candidate-card'
const CandidateCard: FC<TProps> = ({
  candidate, handleOnChange, className, containerId, checkedDisabled, checkedValues
}) => {
  const isChecked = useMemo(() => !!checkedValues?.find((item) => item === candidate?.id), [checkedValues, candidate])
  const dispatch = useDispatch()
  const {
    rbacControl, role, isCanAppointRecruiter, isColdSearch, department, has_active_interview
  } = useEntityContext()
  const checkBoxLabel = `${candidate?.last_name || ''} ${candidate?.name || ''} ${candidate?.second_name || ' '}`
  const handleNavigate = useCallback((e) => {
    if (e.target.dataset?.qa !== 'check-box-value') {
      if (candidate?.id) {
        dispatch(setPreviousPage({
          navigate: '/event-funnel',
          caption: 'Воронка'
        }))
        if (e.ctrlKey || e.metaKey || e.which === 2) {
          // Если Ctrl была нажата, открываем ссылку в новой вкладке
          window.open(`/candidate/${candidate.id}`, '_blank')
        } else {
          dispatch(navigateTo(`/candidate/${candidate.id}`))
        }
      }
    } else {

    }
  }, [candidate])

  return (
    <div className={cn(className, mainCssClass, `${mainCssClass}__status-${candidate?.status}`)}>
      <div className={`${mainCssClass}__container-check`} onClick={handleNavigate}>
        <Checkbox
          name={`${candidate?.id}`}
          data-containerId={containerId}
          data-qa="check-box-value"
          value={candidate?.id}
          checked={checkedDisabled === containerId ? isChecked : false}
          onChange={handleOnChange}
          disabled={typeof checkedDisabled === 'number' ? checkedDisabled !== containerId : false}
          className={cn(`${mainCssClass}__checkbox`, `${mainCssClass}__status-color-${candidate?.status}`)}
        >

          <OverflowTooltip label={checkBoxLabel} className={`${mainCssClass}__ellipsis`} />
        </Checkbox>
        <NavLink className={`${mainCssClass}__link`} onClick={handleNavigate} to={`/candidate/${candidate?.id}`}>

          <div className={`${mainCssClass}__icon`}>
            <CardUser />
          </div>
        </NavLink>

      </div>
      <NavLink className={`${mainCssClass}__link`} onClick={handleNavigate} to={`/candidate/${candidate?.id}`}>

        <Typography.Paragraph className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.address ? candidate?.address : 'Адрес не заполнен'}
        </Typography.Paragraph>
        <Typography.Paragraph className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.phone ? candidate?.phone : 'Телефон не заполнен'}
        </Typography.Paragraph>
        <Typography.Paragraph className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.city ? candidate?.city : 'Город не заполнен'}
        </Typography.Paragraph>
        <Typography.Paragraph className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.date_lid_respond ? `Дата отклика: ${dayjs(candidate?.date_lid_respond).format(DATE_DOTS_FORMAT)}` : 'Дата отклика не заполнена'}
        </Typography.Paragraph>
        {department === 176 && (
            <>
              <Typography.Paragraph
                className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
              {candidate?.date_internship ? `Дата стажировки: ${dayjs(candidate?.date_internship).format(DATE_DOTS_FORMAT)}` : 'Дата стажировки не заполнена'}
              </Typography.Paragraph>
                <Typography.Paragraph
                  className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
                {candidate?.time_internship ? `Время стажировки: ${candidate?.time_internship}` : 'Время стажировки не заполнена'}
              </Typography.Paragraph>
            </>
        )}

        {!!candidate?.wa_enqueued_count
      && (
        <div className={`${mainCssClass}_wa`}>
          <ChatCircleDots color="#FFF" />
          <Typography.Paragraph className={`${mainCssClass}_wa_new`}>
            Новые сообщения
          </Typography.Paragraph>
        </div>
      )}
      </NavLink>

    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(CandidateCard))
