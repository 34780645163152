/* eslint-disable react/function-component-definition */
import React, {
  FC, memo
} from 'react'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Flag from '../../../../common/svg/Flag'

type TProps = {
  backgroundColor: string
  color: string
}

const RequestPriority: FC<TProps> = ({ backgroundColor, color }) => <Flag fill={color} fillBg={backgroundColor} />
export default memo<TProps>(withErrorBoundary<TProps>(RequestPriority))
