import {
  ModalProps, Typography
} from 'antd'
import React from 'react'
import './ModalResultInternship.scss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

import { selectCandidate } from '../selectors'
import { openResultInternshipModal } from '../actions'

const mainCssClass = 'result-internship-modals'
function ModalResultInternship({
  open, className, onCancel
}: ModalProps) {
  const candidate = useSelector(selectCandidate)
  const dispatch = useDispatch()
  const handleOnCancel = () => {
    dispatch(openResultInternshipModal(false))
  }
  const getCameToInternshipText = (value: number): string => {
    switch (value) {
    case 1:
      return 'Да'
    case 2:
      return 'Нет'
    case 0:
      return 'Нет информации'
    default:
      return ''
    }
  }

  return (
    <CommonModal
      open={open}
      onCancel={handleOnCancel}
      centered
      title="Результат стажировки"
      className={cn(className)}
      width="400px"
      wrapClassName="wrapModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={mainCssClass}>
        <div className={`${mainCssClass}_header`}>
          <div className={`${mainCssClass}_up-text`}>
            Сотрудник пришел на стажировку:
          </div>
          {' '}
          <div className={`${mainCssClass}_down-text`}>
            {getCameToInternshipText(candidate?.internship?.is_came || 0)}
          </div>

        </div>
        <div className={`${mainCssClass}_header`}>
          <div className={`${mainCssClass}_up-text`}>
            Наставник:
          </div>
          {' '}
          <div className={`${mainCssClass}_down-text`}>
            {candidate?.internship?.mentor || 'Не указан'}
          </div>
        </div>
        <div className={`${mainCssClass}_header`}>
          <div className={`${mainCssClass}_up-text`}>
            Оформление:
          </div>
          {' '}
          <div className={`${mainCssClass}_down-text`}>
            {getCameToInternshipText(candidate?.internship?.registration || 0)}
          </div>

        </div>
        <div className={`${mainCssClass}_header`}>
          <div className={`${mainCssClass}_up-text`}>
            Комментарий:
          </div>
          {' '}
          <div className={`${mainCssClass}_down-text`}>
            {candidate?.internship?.comment || 'Нет комментария'}
          </div>

        </div>
      </div>
    </CommonModal>
  )
}
export default ModalResultInternship
