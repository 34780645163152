import React, { useEffect, useRef } from 'react'
import {
  DatePicker, DatePickerProps, Typography, Input, InputProps
} from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import dayjs from 'dayjs'
import InputMask from 'react-input-mask'
import 'dayjs/locale/ru'

import cn from 'classnames'
import './DatePickerFormControl.scss'
import { DATE_DOTS_FORMAT } from '../../utils/dateFormats'
import Required from '../../svg/Required'

type TDatePickerFormControlProps = Omit<
  UseControllerProps &
    DatePickerProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      isDisableDate?: boolean
      isDisablePastDays?: boolean
      required?: boolean
      mask?: boolean
      tasks?: boolean
    },
  'isDisabled'
>

interface IDatePickerFormControlProps extends TDatePickerFormControlProps {
  rules?: {
    required?: boolean
    validate?: (value: string) => boolean;
  }
}
const { Title } = Typography

const mainCssClass = 'datePickerFormControl'

function DatePickerFormControl({
  name,
  control,
  label,
  rules,
  className,
  size = 'large',
  isDisableDate,
  isDisablePastDays,
  required,
  tasks,
  onChange,
  defaultValue,
  ...rest
}: IDatePickerFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  const inputRef = React.useRef(null)

  const handleDatePickerChange = (date: dayjs.Dayjs | null, dateString?: string) => {
    if (onChange) { onChange(date, '') }
    const formattedDate = date ? date.format('YYYY-MM-DD') : null
    field.onChange(formattedDate)
  }

  const handleClickDatePicker = (e) =>{

  }

  const disabledDate = (current) => {
    const today = dayjs().startOf('day')
    const tomorrow = today.add(1, 'day')
    // const dayAfterTomorrow = today.add(2, 'day')

    if (tasks || isDisablePastDays) {
      return (
        current.isBefore(today)
      )
    }
    return (
      current.isBefore(today) // Даты в прошлом
      || current.isAfter(tomorrow, 'day') // Даты послезавтра и далее
      // || (current.isAfter(tomorrow, 'day') && current.isBefore(dayAfterTomorrow))
    // Даты между завтра и послезавтра
    )
  }

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])

  const renderCustomInput = (inputProps: any) => (
    <InputMask
      {...inputProps}
      mask="99.99.9999"
      placeholder="Выберите дату"
      onChange={(e) => {
        const { value } = e.target
        if (value === '__.__.____') {
          field.onChange(null)
          return
        }

        const parts = value.split('.')
        if (parts.length === 3 && /^[0-9]{4}$/.test(parts[2])) { // Добавляем проверку на полное значение года
          const date = dayjs(`${parts[2]}-${parts[1]}-${parts[0]}`)
          if (date.isValid()) {
            handleDatePickerChange(date)
          } else {
            handleDatePickerChange(null)
          }
        }
        // Вызов оригинального обработчика onChange от inputProps, если он есть
        if (inputProps.onChange) {
          inputProps.onChange(e)
        }
      }}
      onBlur={field.onBlur}
      ref={inputRef}
      onClick={handleClickDatePicker}
    >
      {(inputProps: InputProps) => (
        <Input
          className={fieldState.error ? 'error' : ''}
          {...inputProps}
        />
      )}
    </InputMask>
  )

  return (

    <div>
      {label && (
        <Title className="datePickerFormControl_label" level={4}>
          {label}
          {required && <Required className="required" />}
        </Title>
      )}
      <DatePicker
        onChange={handleDatePickerChange}
        suffixIcon={null}
        placeholder="12.10.1995"
        format={DATE_DOTS_FORMAT}
        disabledDate={isDisableDate ? disabledDate : undefined}
        defaultPickerValue={dayjs(new Date())}
        className={cn(
          className,
          mainCssClass,
          { datePickerLarge: size === 'large' },
          { datePickerMiddle: size === 'middle' },
          { datePickerSmall: size === 'small' },
          { error: fieldState.error }
        )}
        value={field.value ? dayjs(field.value) : null}
        size={size}
        inputRender={renderCustomInput}
        {...rest}
        dateRender={(current) => {
          const isSelected = field.value && current.isSame(dayjs(field.value), 'day')
          const isToday = current.isSame(dayjs(), 'day') && !field.value

          return (
            <div className={cn({ 'today': isToday, 'selected': isSelected })}>
              {current.date()}
            </div>
          )
        }}
      />
    </div>

  )
}

export default DatePickerFormControl
