/* eslint-disable react/function-component-definition */
import React, { FC, memo, useMemo } from 'react'
import { Tag, Typography } from 'antd'
import { useSelector } from 'react-redux'
import {
  selectCandidate, selectDictionaryOptionsByName,
  selectInterview, selectVacanciesTags
} from '../selectors'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { getInterviewInfo, prepareResultInterview } from '../utils'
import './Information.scss'
import { DetailsList } from '../../../common/components/DetailsList'

const { Title } = Typography
const mainCssClass = 'info-interview'
const Information: FC = () => {
  const interview = useSelector(selectInterview)
  const candidate = useSelector(selectCandidate)
  const tags = useSelector(selectVacanciesTags)
  const interviewNote = useSelector(selectDictionaryOptionsByName('interviewNote'))
  const interviewResult = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const interviewStatus = useSelector(selectDictionaryOptionsByName('statusResult'))
  const candidateCharacteristic = useSelector(selectDictionaryOptionsByName('candidateCharacteristic'))
  const detailsListInterview = useMemo(
    () => getInterviewInfo(interview, interviewStatus, interviewResult, interviewNote, typeof interview.characteristic === 'object' ? interview?.characteristic.label : prepareResultInterview(candidateCharacteristic, interview.characteristic)),
    [interview, interviewStatus, interviewResult, interviewNote]
  )
  return (
    <div className={mainCssClass}>
      <div>
        <div className={`${mainCssClass}_header`}>
          <Title className={`${mainCssClass}_header_title`} level={4}>Вакансии:</Title>
        </div>
        { tags?.length
          ? (
            <Tag key={tags[0].value} className="customLabel" color="blue">
              {tags[0].label}
            </Tag>
          )
          : <span> Нет прикрепленных вакансий </span>}
      </div>
      <DetailsList
        size="xs"
        items={[{
          items: detailsListInterview
        }]}
      />
    </div>
  )
}

export default memo(withErrorBoundary(Information))
