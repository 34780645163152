import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'empty-asterisk'

function EmptyHistory({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="56" height="56" rx="24" fill="#E8ECE9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5 23C18.5 22.4477 18.9477 22 19.5 22H30.5C31.0523 22 31.5 22.4477 31.5 23C31.5 23.5523 31.0523 24 30.5 24H19.5C18.9477 24 18.5 23.5523 18.5 23ZM18.5 28C18.5 27.4477 18.9477 27 19.5 27H30.5C31.0523 27 31.5 27.4477 31.5 28C31.5 28.5523 31.0523 29 30.5 29H19.5C18.9477 29 18.5 28.5523 18.5 28ZM30.5 32C30.5 31.4477 30.9477 31 31.5 31L37.5 31C38.0523 31 38.5 31.4477 38.5 32C38.5 32.5523 38.0523 33 37.5 33L31.5 33C30.9477 33 30.5 32.5523 30.5 32ZM18.5 33C18.5 32.4477 18.9477 32 19.5 32H26.5C27.0523 32 27.5 32.4477 27.5 33C27.5 33.5523 27.0523 34 26.5 34H19.5C18.9477 34 18.5 33.5523 18.5 33Z" fill="#404542" />
    </svg>
  )
}
export default EmptyHistory
