import { createReducer } from '@reduxjs/toolkit'
import {
  setLoading, setDictionaries, reset, setInterviews, setDefStringInterviews, setDefDataInterview, setHeaders, resetAllInterview, setBlockedFiltersResponsibleOrRecruiter, setWorkload, setWorkloadPage, openWorkloadFilter, setWorkloadFilters, setDefWorkloadFilters
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'
import { TInterviewsResponseData } from '../../common/api/interviews'

type TManagementRequestsState = {
  interviews: null | TInterviewsResponseData
  dictionaries: TDictionaries | null
  loading: boolean
  archiveOpen: boolean
  defString: string
  defDataInterview: {
    page:number,
    perPage: number,
    filters: string,
    search: string
    self: string
  } | null
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
  blockedFiltersResponsibleOrRecruiter: boolean
  workload: any
  workloadFilter: boolean
  workloadPage: boolean
  workloadFilterString: string
  defWorkloadFilter: any
}

const initialState: TManagementRequestsState = {
  interviews: null,
  dictionaries: null,
  loading: false,
  archiveOpen: false,
  defString: '',
  defDataInterview: null,
  headers: null,
  blockedFiltersResponsibleOrRecruiter: true,
  workload: null,
  workloadFilter: false,
  workloadPage: false,
  workloadFilterString: '',
  defWorkloadFilter: {}
}

const managementInterviewsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setInterviews, (state, { payload }) => {
      state.interviews = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setDefStringInterviews, (state, { payload }) => {
      state.defString = payload
    })
    .addCase(reset, (state) => ({
      ...initialState,
      defDataInterview: state.defDataInterview,
      dictionaries: state.dictionaries
    }))
    .addCase(setDefDataInterview, (state, { payload }) => {
      state.defDataInterview = payload
    })
    .addCase(resetAllInterview, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setBlockedFiltersResponsibleOrRecruiter, (state, { payload }) => {
      state.blockedFiltersResponsibleOrRecruiter = payload
    })
    .addCase(setWorkload, (state, { payload }) => {
      state.workload = payload
    })
    .addCase(setWorkloadPage, (state, { payload }) => {
      state.workloadPage = payload
    })
    .addCase(openWorkloadFilter, (state, { payload }) => {
      state.workloadFilter = payload
    })
    .addCase(setWorkloadFilters, (state, { payload }) => {
      state.workloadFilterString = payload
    })
    .addCase(setDefWorkloadFilters, (state, { payload }) => {
      state.defWorkloadFilter = payload
    })
})

export default managementInterviewsReducer
