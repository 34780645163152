/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Typography } from 'antd'

import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import RejectEvent from '../../../common/svg/RejectEvent'
import EventsListIconIn from '../../../common/svg/eventsListIconIn'
import EventsListIconOUT from '../../../common/svg/eventsListIconOUT'

import './HistoryTypeNameTable.scss'

  type TProps = {
      action: string
  }
const mainCssClass = 'types-name-table'

const HistoryTypeNameTable: FC<TProps> = ({ action }) => {
  if (action === '1') {
    return (
      <div className={mainCssClass}>
        <EventsListIconIn />
        <Typography.Text>
          Исходящий звонок
        </Typography.Text>

      </div>
    )
  }

  if (action === '2') {
    return (
      <div className={mainCssClass}>
        <EventsListIconOUT />
        <Typography.Text>
          Входящий звонок
        </Typography.Text>

      </div>
    )
  }

  if (action === '3') {
    return (
      <div className={mainCssClass}>
        <RejectEvent />
        <Typography.Text>
          Пропущенный звонок
        </Typography.Text>

      </div>
    )
  }

  return <Typography.Text> Действие не определено</Typography.Text>
}
export default memo<TProps>(withErrorBoundary<TProps>(HistoryTypeNameTable))
