import { createAction } from '@reduxjs/toolkit'
import { TRequest } from '../../common/api/request'
import { TDictionaries, TDictionariesNames, TOption } from '../../common/types/dictionaries'

export const getRequests = createAction<string>('map/getRequests')
export const setRequests = createAction<TRequest[]>('map/setRequests')
export const setFilter = createAction<string>('map/setFilter')
export const setPreviousFilter = createAction<string>('map/setPreviousFilter')
export const setLoading = createAction<boolean>('map/setLoading')
export const reset = createAction('map/reset')
export const setFilteredRequests = createAction<TRequest[]>('map/setFilteredRequests')
export const setFilteredPreviousRequests = createAction<TRequest[]>('map/setFilteredPreviousRequests')

export const setZoomCoordinates = createAction<number[]>('map/setZoomCoordinates')
export const setSelectedRequest = createAction<TRequest | null>('map/setSelectedRequest')
export const setTotalCountsOfSliced = createAction<number>('map/setTotalCountsOfSliced')
export const setOpenDetailsModal = createAction<boolean>('map/setOpenDetailsModal')
export const getDictionaries = createAction<TDictionariesNames>('map/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('map/dictionaries/setDictionaries')
export const setFiltersOptions = createAction<TFiltersOptions | null>('map/setFiltersOptions')
export const setPreviousFilteredOptions = createAction<TFiltersOptions | null>('map/setPreviousFilteredOptions')


export type TFiltersOptions = {
  customers: TOption[]
  responsible: TOption[]
  vacancies: TOption[]
  stores: TOption[]
}

export type TFilters = {
  filters: string
  isEdit: boolean
  bounds?: any
  page?: number
  store: boolean
}
export const getFilteredRequests = createAction<TFilters>('map/getFilteredRequests')

export const getSlicedRequests = createAction<TFilters>('map/getSlicedRequests')
export const setSlicedRequests = createAction<TRequest[] | null>('map/setSlicedRequests')
export const setMapBounds = createAction<any>('map/setMapBounds')

export const setPage = createAction<number>('map/setPage')
export const setStoreStatus = createAction<string>('map/setStoreStatus')
