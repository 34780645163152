/* eslint-disable no-multi-str */
/* eslint-disable no-useless-escape */
import React, {
  memo,
  useEffect, useMemo
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import { selectActiveWhatsappTemplate } from '../selectors'
import {
  createWhatsappTemplate,
  openWhatsappTemplatesForm, setActiveWhatsappTemplate, updateWhatsappTemplate
} from '..'

import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './WhatsTemplatesForm.scss'

const mainCssClass = getCssClassName('templates-form')

  type TFormValues = {
    name: string
    content: string
    user_id: any
  }

function WhatsTemplatesForm() {
  const dispatch = useDispatch()
  const userId = localStorage.getItem('user_id')

  const activeTemplate = useSelector(selectActiveWhatsappTemplate)

  const defaultValues = useMemo(() => (
    activeTemplate ? {
      name: activeTemplate.name,
      content: activeTemplate.content,
      user_id: Number(activeTemplate.user_id) === Number(userId)
    } : {}
  ), [activeTemplate])

  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>({ defaultValues })

  const content = watch('content')
  const name = watch('name')
  const user_id = watch('user_id')

  const handleCancel = () => {
    dispatch(openWhatsappTemplatesForm(false))
    dispatch(setActiveWhatsappTemplate(null))
  }

  const onSubmit = () => {
    if (!activeTemplate) {
      dispatch(createWhatsappTemplate({ content, name, user_id: user_id ? userId : null }))
    } else if (activeTemplate) {
      dispatch(updateWhatsappTemplate({
        content, name, user_id: user_id ? userId : null, id: activeTemplate.id
      }))
    }
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (

    <div className={mainCssClass}>
      <Typography.Title level={3}>
        {activeTemplate ? 'Редактировать шаблон' : 'Новый шаблон cообщений'}
      </Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}_container_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl
              name="name"
              control={control}
              label="Название сообщения"
              required
              rules={{ required: true }}
            />
            <TextAreaFormControl
              name="content"
              rules={{ required: true }}
              control={control}
              label="Текст сообщения"
              required
              autoSize={{ minRows: 4, maxRows: 8 }}
            />

            <div className={`${mainCssClass}_content-short`}>
              <SwitchFormControl control={control} name="user_id" />
              <p>Мое сообщение</p>
            </div>
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(WhatsTemplatesForm))
