import { createReducer } from '@reduxjs/toolkit'
import {
  setCandidates,
  setLoading,
  reset,
  setHeaders, openChatMobil, setSelectedStatus, setShowMassStage,
  setProductivityRecruiter,
  setProductivityResearcher,
  setActiveTab,
  openProductivityRecruiterFilter,
  openProductivityResearcherFilter,
  setRecruiterFilterString,
  setResearcherFilterString,
  setDictionaries,
  setSearchText
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'


type TManagementCandidateState = {
  loading: boolean
  productivityRecruiter: any[]
  productivityResearcher: any[]
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
  activeTab: 'recruiter' | 'researcher'
  recruiterFilter: boolean
  researcherFilter: boolean
  dictionaries: TDictionaries | null
  recruiterFilterString: string
  researcherFilterString: string
  searchText: string
}

const initialState: TManagementCandidateState = {
  loading: false,
  headers: null,
  productivityRecruiter: [],
  productivityResearcher: [],
  activeTab: 'recruiter',
  recruiterFilter: false,
  researcherFilter: false,
  dictionaries: null,
  recruiterFilterString: '',
  researcherFilterString: '',
  searchText: ''
}

const productivityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setProductivityRecruiter, (state, { payload }) => {
      state.productivityRecruiter = payload
    })
    .addCase(setProductivityResearcher, (state, { payload }) => {
      state.productivityResearcher = payload
    })
    .addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload
    })
    .addCase(openProductivityRecruiterFilter, (state, { payload }) => {
      state.recruiterFilter = payload
    })
    .addCase(openProductivityResearcherFilter, (state, { payload }) => {
      state.researcherFilter = payload
    })
    .addCase(setRecruiterFilterString, (state, { payload }) => {
      state.recruiterFilterString = payload
    })
    .addCase(setResearcherFilterString, (state, { payload }) => {
      state.researcherFilterString = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setSearchText, (state, { payload }) => {
      state.searchText = payload
    })
})

export default productivityReducer
