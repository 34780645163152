import React, {
  memo,
  useEffect, useMemo, useRef, useState
} from 'react'
import JsSIP from 'jssip'
import 'webrtc-adapter'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  selectCandidateSelected, selectCurrentUserStatusMobile,
  selectDestinationNumber,
  selectNeedHangUpCall,
  selectNeedPauseCall,
  selectNeedResumeCall, selectNeedTransferCall, selectStatusPhone, selectUserInfo
} from '../compositions/InnerRouter/selectors'
import {
  setCandidateSelected, setConectedWss,
  setDestinationNumber, setHangUpCall, setPauseCall, setResumeCall, setTransferCall
} from '../compositions/InnerRouter/actions'
import './JsSipPhone.scss'
import { SwapOperator } from '../common/svg/SvgFromIconWrapper/SwapOperator'
import { ClosePhone } from '../common/svg/SvgFromIconWrapper/ClosePhone'
import { AcceptPhone } from '../common/svg/SvgFromIconWrapper/AcceptPhone'
import { PausePhone } from '../common/svg/SvgFromIconWrapper/PausePhone'
import { PlayPhone } from '../common/svg/SvgFromIconWrapper/PlayPhone'
import AvatarNavbar from '../common/svg/AvatarNavbar'
import { getCandidates } from '../compositions/ManagamentCandidates/actions'
import { selectCandidates } from '../compositions/ManagamentCandidates/selectors'
import { API_URL } from '../common/api/http'
import SelectFormControl from '../common/components/formcontrol/SelectFormControl'
import withErrorBoundary from '../common/hoc/withErrorBoundary/withErrorBoundary'

const mainCssClass = 'webrtc-sip-phone'
type TFormValues = {
  transferNumber: string
}
function WebRTCPhoneComponent() {
  const socket = useRef(null)
  const dispatch = useDispatch()
  const userAgent = useRef(null)
  const inComingAudio = useRef(new Audio())
  const outComingAudio = useRef(new Audio())
  const remoteAudio = useRef(new Audio())
  const [incoming, setIncoming] = useState<boolean>(false)
  const [sessionActive, setSession] = useState(null)
  let timeoutId
  const [activePause, setActivePause] = useState<boolean>(true)
  const [activePlay, setActivePlay] = useState<boolean>(false)
  const [activeCall, setActiveCall] = useState<boolean>(false)
  const [dndStatus, setDndStatus] = useState<boolean>(true)
  const [transferCallOpen, setTransferCallOpen] = useState<boolean>(false)
  const [needRestartTimeout, setNeedRestartTimeout] = useState<boolean>(false)
  const destinationNumber = useSelector(selectDestinationNumber)
  const currentStatus = useSelector(selectCurrentUserStatusMobile)
  const needHangUpCall = useSelector(selectNeedHangUpCall)
  const needResumeCall = useSelector(selectNeedResumeCall)
  const needStopCall = useSelector(selectNeedPauseCall)
  const transferDestinationNumber = useSelector(selectNeedTransferCall)
  const candidate = useSelector(selectCandidateSelected)
  const userInfo = useSelector(selectUserInfo)
  const [incomingPhone, setInComingPhone] = useState(null)
  const candidates = useSelector(selectCandidates)
  const [currentTime, setCurrentTime] = useState('')
  const intervalRef = useRef()
  const statusPhone = useSelector(selectStatusPhone)

  const { control, watch } = useForm<TFormValues>({
    defaultValues: {
      transferNumber: ''
    }
  })

  const name = useMemo(() => {
    if (incomingPhone && candidates && candidates?.[0]?.last_name && candidates?.[0]?.name) {
      const newStr = `${candidates?.[0]?.last_name || ''} ${candidates?.[0]?.name || ''} ${candidates?.[0]?.second_name || ''}`
      newStr.slice(42)
      return newStr
    }
    if (candidate) {
      const newStr = `${candidate?.last_name || ''} ${candidate?.name || ''} ${candidate?.second_name || ''}`
      newStr.slice(42)
      return newStr
    }

    return 'Нет информации'
  }, [candidate, incomingPhone, candidates])

  const phone = useMemo(() => {
    if (incomingPhone) {
      return incomingPhone
    }
    if (candidate) {
      return candidate?.phone
    }

    return 'Нет информации'
  }, [candidate, incomingPhone])
  // Воспроизведение входящего звонка
  function playIncomingCall() {
    fetch(`${API_URL}/asterisk/ringtones/inside.mp3`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob) // Create a blob URL
        if (url) {
          inComingAudio.current.src = url // Use src instead of srcObjec
          inComingAudio.current.loop = true// t
          return inComingAudio?.current?.play()
        }
      })
  }

  // Остановка входящего звонка
  function stopIncomingCall() {
    inComingAudio.current.pause()
    inComingAudio.current.currentTime = 0 // Сброс времени на начало
  }

  const setDoNotDisturb = async (dndCode: string) => {
    if (!userAgent.current) {
      console.error('UserAgent не инициализирован')
      return
    }

    try {
      // Номер для включения/выключения DND

      // Параметры вызова, например, без передачи медиа
      const options = {
        mediaConstraints: { audio: true, video: false }
      }

      // Инициация звонка на номер *78
      const session = userAgent.current?.call?.(`sip:${dndCode}@${userInfo.sipSettings.realm}`, options)

      // Завершаем вызов сразу после установки DND
      session.on('accepted', () => {
        setDndStatus((prevState) => !prevState) // Инвертируем статус
        session.terminate()
      })

      session.on('failed', (error) => {
        console.error('Ошибка при переключении DND:', error)
      })
    } catch (error) {
      console.error('Ошибка при отправке INVITE для DND:', error)
    }
  }

  // Воспроизведение исходящего звонка
  function playOutgoingCall() {
    fetch(`${API_URL}/asterisk/ringtones/outside.mp3`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob) // Create a blob URL
        if (url) {
          outComingAudio.current.src = url // Use src instead of srcObject
          outComingAudio.current.loop = true
          return outComingAudio?.current?.play().catch((e) => console.log(e, 'Ошибка дозвона!'))
        }
      })
  }
  // Остановка исходящего звонка
  function stopOutgoingCall() {
    outComingAudio.current.pause()
    outComingAudio.current.currentTime = 0 // Сброс времени на начало
  }

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const makeCall = async (destinationNumber: string | number) => {
    if (userAgent.current && destinationNumber) {
      const localStream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const sessionConfig = {
        audio: true,
        video: false,
        mediaConstraints: {
          audio: true,
          video: false
        }
      }

      const session = userAgent.current?.call?.(
        `sip:${destinationNumber}@${userInfo.sipSettings.realm}`,
        sessionConfig
      )

      if (session) {
        session.on('accepted', () => {
          stopOutgoingCall()
          setSession(session)
          const remoteStream = session?.connection?.getRemoteStreams()[0]
          console.log(remoteStream,'remote!')
          remoteAudio.current.srcObject = remoteStream
          setActiveCall(true)
          const receivers = session.connection.getReceivers()
          console.log(receivers,'receivers!')
          if (receivers.length > 0) {
            const remoteStream = new MediaStream(receivers.map((receiver) => receiver.track))
            remoteAudio.current.srcObject = remoteStream
          }
          remoteAudio.current.play().catch((error) => console.error('Ошибка воспроизведения:', error))
        })
        session?.on('failed', (e) => {
          console.log(e, 'e!')
          hangupCall()
        })
      } else {
        hangupCall()
      }
    } else {
      dispatch(setDestinationNumber(null))
      dispatch(setCandidateSelected(null))
    }
  }

  const pauseCall = () => {
    if (userAgent.current && userAgent?.current?._sessions) {
      const session = Object.values(userAgent?.current?._sessions)?.[0]
      session?.hold?.()
      setActivePause(false)
      setActivePlay(true)
    }
  }

  const resumeCall = () => {
    if (userAgent.current && userAgent?.current?._sessions) {
      const session = Object.values(userAgent?.current?._sessions)?.[0]
      Object.values(userAgent?.current?._sessions)?.[0]?.unhold?.()
      setActivePause(true)
      setActivePlay(false)
    }
  }

  const transferCall = (newDestinationNumber) => {
    if (userAgent.current && userAgent.current?._sessions) {
      const session = Object.values(userAgent?.current?._sessions)?.[0]
      const transferOptions = {
        events_listener: {
          succeeded: (e) => {
          },
          failed: (e) => {
            console.error('Transfer failed', e)
          }
        }
      }
      session.transfer(`sip:${newDestinationNumber}@${userInfo.sipSettings.realm}`, transferOptions)
    }
  }
  const hangupCall = () => {
    console.log(sessionActive, 'sessionActive!')
    if (sessionActive) {
      if (sessionActive?.connection && typeof sessionActive?.connection?.hangup === 'function') {
        sessionActive?.connection?.hangup?.()
      } else {
        console.warn('sessionActive.connection.hangup is not a function')
      }

      if (typeof sessionActive?.terminate === 'function') {
        sessionActive?.terminate?.()
      } else {
        console.warn('sessionActive.terminate is not a function')
      }
      setInComingPhone(null)
      setIncoming(false)
      dispatch(setDestinationNumber(null))
      setSession(null)
      setNeedRestartTimeout(true)
    } else if (userAgent?.current && Object.values(userAgent?.current?._sessions)) {
      setIncoming(false)
      setSession(null)
      setIncoming(false)
      setInComingPhone(null)
      setNeedRestartTimeout(true)
      dispatch(setDestinationNumber(null))
    } else {
      setInComingPhone(null)
      setIncoming(false)
      dispatch(setDestinationNumber(null))
      setSession(null)
      setNeedRestartTimeout(true)
    }
  }

  useEffect(
    () => {
      if (userInfo?.sipSettings && process.env.WEBSOCKET_ASTERISK_PROXY_URL && !statusPhone) {
        socket.current = new JsSIP.WebSocketInterface(process.env.WEBSOCKET_ASTERISK_PROXY_URL)
        const configuration = {
          mediaConstraints: { audio: true, video: false },
          sockets: [socket.current],
          uri: userInfo.sipSettings.impu,
          password: userInfo.sipSettings.password,
          pcConfig: {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
          }
        }
        userAgent.current = new JsSIP.UA(configuration)

        userAgent.current.on('connecting', () => {
        })

        userAgent.current.on('registered', () => {
        })

        userAgent.current.on('unregistered', () => {
        })

        userAgent.current.on('registrationFailed', (data: any) => {
        })
        userAgent.current.on('bye', (data: any) => {
        })
        userAgent.current.on('terminate', (data: any) => {
          setIncoming(false)
          setSession(null)
          setIncoming(false)
          setInComingPhone(null)
          setNeedRestartTimeout(true)
          dispatch(setDestinationNumber(null))
        })
        userAgent.current.on('ended', (e: any) => {
        })
        userAgent.current.on('newRTCSession', (e: any) => {

          const { session } = e
          let dtmfSender
          session.on('peerconnection', (event) => {
            const pc = event.peerconnection

            pc.addEventListener('track', (e) => {
              if (remoteAudio.current && e.streams[0]) {
                remoteAudio.current.srcObject = e.streams[0]
                remoteAudio.current.play().catch((error) => console.error('Ошибка воспроизведения:', error))
              }
            })
          })
          session.on('bye', (e: any) => {
            setInComingPhone(null)
            setSession(null)
            setIncoming(false)
          })
          session.on('ended', (e: any) => {
            setInComingPhone(null)
            setSession(null)
            setIncoming(false)
            dispatch(setDestinationNumber(null))
            dispatch(setCandidateSelected(null))
            setActiveCall(false)
          })
          session.on('failed', (e: any) => {
            setInComingPhone(null)
            setSession(null)
            setIncoming(false)
            dispatch(setDestinationNumber(null))
            dispatch(setCandidateSelected(null))
            setActiveCall(false)
          })
          session.on('terminated', (e: any) => {
            setInComingPhone(null)
            setSession(null)
            setIncoming(false)
            dispatch(setDestinationNumber(null))
            dispatch(setCandidateSelected(null))
            setActiveCall(false)
          })
          session.on('addstream', (e) => {
          })
          console.log(session,'session!!!!')
          if (session.direction === 'outgoing') {
            clearInterval(intervalRef?.current)
            startCall()
            playOutgoingCall()
          }

          if (session.direction === 'incoming') {
            clearInterval(intervalRef?.current)
            startCall()
            setInComingPhone(session.remote_identity.uri.user)
            dispatch(getCandidates({ filters: `&search=${session.remote_identity.uri.user}` }))
            playIncomingCall()
            setIncoming(true)
            setSession(session)
          }
        })
        userAgent?.current?.start()
      }
      return () => {
        if (userAgent.current) {
          userAgent?.current?.stop()
        }
      }
    },
    [userInfo, remoteAudio, statusPhone]
  )

  useEffect(() => {
    if (needRestartTimeout) {
      userAgent?.current?.stop()
      setNeedRestartTimeout(false)
    }
    if (userAgent?.current) {
      userAgent.current.on('connected', () => {
        dispatch(setConectedWss(true))
        if (!activeCall && !incoming) {
          timeoutId = setTimeout(() => {
            if (!activeCall && !incoming) {
              userAgent.current?.stop()
            }
          }, 90000)
        }
      })

      userAgent.current.on('disconnected', () => {
        dispatch(setConectedWss(false))
        userAgent.current.start()
      })
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [incoming, activeCall, userInfo, destinationNumber, needRestartTimeout])

  useEffect(() => {
    if ((activeCall || incoming || destinationNumber) && timeoutId) {
      clearTimeout(timeoutId)
    }
  }, [activeCall, incoming, destinationNumber, timeoutId])

  useEffect(() => {
    if (destinationNumber) {
      makeCall(destinationNumber)
    }
  }, [destinationNumber])

  useEffect(() => {
    if (transferDestinationNumber) {
      transferCall(transferDestinationNumber)
      dispatch(setTransferCall(null))
    }
  }, [transferDestinationNumber])

  useEffect(() => {
    if (needResumeCall) {
      resumeCall()
      dispatch(setResumeCall(false))
    }
  }, [needResumeCall])

  useEffect(() => {
    if (needStopCall) {
      pauseCall()
      dispatch(setPauseCall(false))
    }
  }, [needStopCall])

  const handleAccept = () => {
    if (sessionActive) {
      sessionActive?.answer({
        mediaConstraints: {
          audio: true,
          video: false,
          audio_remote: remoteAudio
        }
      })
      sessionActive?.on('accepted', () => {
        stopIncomingCall()
        stopOutgoingCall()
        const remoteStream = sessionActive.connection.getRemoteStreams()[0]
        remoteAudio.current.srcObject = remoteStream
        setActiveCall(true)
        const receivers = sessionActive.connection.getReceivers()
        if (receivers.length > 0) {
          const remoteStream = new MediaStream(receivers.map((receiver) => receiver.track))
          remoteAudio.current.srcObject = remoteStream
        }
        remoteAudio.current.play()
          .catch((error) => console.error('Ошибка воспроизведения:', error))
      })
      setActiveCall(true)
    }
    setIncoming(false)
  }
  useEffect(() => {
    if (activeCall) {
      clearInterval(intervalRef?.current)
      startCall()
    }
    return () => {
      // Очистка таймера при размонтировании компонента
      clearInterval(intervalRef.current)
    }
  }, [activeCall])

  useEffect(() => {
    if (needHangUpCall) {
      hangupCall()
      dispatch(setHangUpCall(false))
      dispatch(setDestinationNumber(null))
      localStorage.removeItem('session')
    }
  }, [needHangUpCall])

  // useEffect(() => {
  //   dispatch(getTransferNumbers())
  // }, [])
  // временно скрыто

  useEffect(() => {
    console.log(currentStatus)
    if (currentStatus?.id === 1) {
      userAgent.current.stop()
    }
    if ((currentStatus?.id === 3 || currentStatus?.id === 5)) {
      userAgent.current.stop()
    }
    if ((currentStatus?.id === 2 || currentStatus?.id === 4)) {
      userAgent?.current?.start?.()
    }
  }, [currentStatus])

  const startCall = () => {
    const start = new Date()
    intervalRef.current = setInterval(() => {
      const now = new Date()
      const difference = new Date(now - start)

      const hours = difference.getUTCHours()
      const minutes = difference.getUTCMinutes()
      const seconds = difference.getUTCSeconds()

      setCurrentTime(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`)
    }, 1000) // Обновлять каждую секунду
  }
  const handleOpenTransferCall = () => {
    setTransferCallOpen(true)
  }
  console.log(incoming, activeCall, '!!!!')
  const pad = (num) => (num < 10 ? `0${num}` : num)

  if (!incoming && !destinationNumber && !incomingPhone) {
    return null
  }

  return (
    <Draggable>
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__container`}>
          <div>
            {candidate?.photo ? <img src={candidate?.photo} alt="стрелка" className={`${mainCssClass}__avatar`} /> : <AvatarNavbar />}
          </div>
          <div className={`${mainCssClass}__text-container`}>
            <p className={`${mainCssClass}__call-type`}>
              {incomingPhone ? 'Входящий вызов' : 'Исходящий вызов'}
            </p>
            {incomingPhone && (
              <NavLink to={`/candidate/${candidates?.[0]?.id}`}>
                <p className={`${mainCssClass}__who-call`}>
                  {name}
                </p>
              </NavLink>
            )}
            {!incomingPhone && (
              <p className={`${mainCssClass}__who-call`}>
                {name}
              </p>
            )}
            <p className={`${mainCssClass}__who-call`}>
              {phone}
            </p>
          </div>
          <div className={`${mainCssClass}__button-group`}>
            {!incoming && activeCall && (
              <Button type="ghost" onClick={handleOpenTransferCall}>
                <SwapOperator />
              </Button>
            )}
            {(incoming || destinationNumber || incomingPhone) && (
              <Button type="ghost" onClick={hangupCall}>
                <ClosePhone />
              </Button>
            )}
            {incoming && (
              <Button type="ghost" onClick={handleAccept}>
                <AcceptPhone />
              </Button>
            )}
            {!incoming && activeCall && (
              <>
                {!activePlay && activeCall && (
                  <Button type="ghost" onClick={pauseCall}>
                    <PausePhone />
                  </Button>
                )}
                {!activePause && activeCall && (<Button type="ghost" onClick={resumeCall}><PlayPhone /></Button>)}
              </>
            )}
            {activeCall && (
              <div className={`${mainCssClass}__timer`}>
                <p>
                  Время звонка:
                  {currentTime}
                </p>
              </div>
            )}
            {!activeCall && (
              <div className={`${mainCssClass}__timer`}>
                <p>
                  Время дозвона:
                  {currentTime}
                </p>
              </div>
            )}
          </div>
        </div>
        {incoming && !activeCall && (<audio ref={inComingAudio} type="audio/mpeg" />)}
        {!activeCall && (<audio ref={outComingAudio} type="audio/mpeg" />)}
        <audio
          ref={remoteAudio}
          controls
          // @ts-ignore
          type="audio/mpeg"
          className={`${mainCssClass}__audio`}
        />
        {transferCallOpen && (
          <div>
            <SelectFormControl className={`${mainCssClass}__select`} name="transferNumber" control={control} options={[]} />
          </div>
        )}
      </div>

    </Draggable>
  )
}
export default memo(withErrorBoundary(WebRTCPhoneComponent))
