import React, {
  memo,
  useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button, Radio, Tooltip } from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { isEqual } from 'lodash'
import {
  selectDefString, selectLoading, selectLoadingCandidates, selectShowButton
} from '../selectors'
import {
  exportCSV,
  getCandidatesData,
  getCounters,
  getDictionaries, reset,
  resetCandidates,
  setBlockedFiltersResponsibleOrRecruiter,
  setDefStringFunnel,
  setSelfFunnel,
  setShowModal
} from '../actions'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import { TOption } from '../../../common/types/dictionaries'
import FunnelDrag from './FunnelDrop'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { openFilters, setCountFilters, setFilters } from '../../../features/filters/actions'
import { TClassName } from '../../../common/types/props'
import { selectCountFilters, selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import { resetAllInterview } from '../../ManagementInterviews/actions'
import { resetAllRequest } from '../../ManagementRequests/actions'
import { resetAllCandidate } from '../../ManagamentCandidates/actions'
import { resetAllVacancy } from '../../ManagementVacancies/actions'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ControlButton from '../../../common/components/Buttons/Button'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Search from '../../../common/svg/Search'
import Excel from '../../../common/svg/Excel'
import './Funnel.scss'

type TFormValues = {
  search: string
  color: TOption
  name: string
}

const mainCssClass = 'funnel'
function Funnel({ className }:TClassName) {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const nameString = useSelector(selectDefString)
  const filtersCount = useSelector(selectCountFilters)
  const { rbacControl, download_access } = useEntityContext()
  const [requestStatus, setRequestStatus] = useState<string>('true')
  const defFilters = useSelector(selectDefFilters)

  const loading = useSelector(selectLoading)
  const loadingCandidates = useSelector(selectLoadingCandidates)
  const { control, watch } = useForm<TFormValues>({
    defaultValues: {
      name: nameString
    }
  })
  const [page, setPage] = useState<number>(1)
  const filtersString = useSelector(selectFilterString)

  const name = watch('name')

  const showButton = useSelector(selectShowButton)

  const handldeOpenFilter = () => {
    dispatch(openFilters(true))
  }
  const handleShowModal = () => {
    dispatch(setShowModal(true))
  }
  const handleChangeStatus = (e) => {
    setRequestStatus(e.target.value)
    dispatch(setSelfFunnel(e.target.value))
  }

  const handleClickSearch = () => {
    dispatch(setDefStringFunnel(name))
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleClickSearch()
    }
  }

  const handleExportCSV = () => {
    dispatch(exportCSV({
      filters: name
        ? `${filtersString}&search=${nameString}` : filtersString,
      page,
      perPage: 10,
      download: true,
      self: requestStatus
    }))
  }

  useEffect(() => {
    dispatch(getDictionaries(['stageStatus']))
    dispatch(setSelfFunnel('true'))
    dispatch(getCounters())
    if ((pageId === defFilters?.type) && defFilters?.defFilterString) { dispatch(setFilters(defFilters?.defFilterString)) }
    dispatch(resetAllInterview())
    dispatch(resetAllRequest())
    dispatch(resetAllCandidate())
    dispatch(resetAllVacancy())
  }, [])

  useEffect(() => () => {
    dispatch(reset())
    dispatch(setFilters(''))
  }, [])

  useEffect(() => {
    if (requestStatus === 'true') {
      dispatch(setBlockedFiltersResponsibleOrRecruiter(true))
    } else {
      dispatch(setBlockedFiltersResponsibleOrRecruiter(false))
    }
  }, [requestStatus])

  useEffect(() => {
    dispatch(resetCandidates())
    if (filtersString) {
      dispatch(getCandidatesData({
        page,
        perPage: 10,
        filters: name
          ? `${filtersString}&search=${nameString}` : filtersString,
        self: requestStatus
      }))
    } else if (!filtersString && name) {
      dispatch(getCandidatesData({
        page, perPage: 10, filters: `&search=${nameString}`, self: requestStatus
      }))
    } else {
      dispatch(getCandidatesData({
        page, perPage: 10, filters: '', self: requestStatus
      }))
    }
  }, [page, filtersString, nameString, requestStatus])

  useEffect(() => {
    if (localStorage.getItem(`def${pageId}`)) {
      try {
        const parsedFilters = JSON.parse(localStorage.getItem(`def${pageId}`))
        if (!isEqual(parsedFilters, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFilters).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item !== false))
                .length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing filters:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])
  return (
    <div className={cn(mainCssClass, className)}>
      {(loading || loadingCandidates) && (
        <div className={`${mainCssClass}__preloader`}>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      {!loading && !loadingCandidates && (
        <div className={`${mainCssClass}__wrapper`}>

          <div className={`${mainCssClass}__control-panel`}>
            <div className={`${mainCssClass}__control-panel_search-container`}>
              <InputSearchFormControl
                className={`${mainCssClass}__control-panel_search-container_search`}
                name="name"
                control={control}
                placeholder="ФИО кандидата"
                onKeyDown={handleKeyDown}
              />
              <ControlButton typeButton="primary" className={`${mainCssClass}__control-panel_search-container_btn button`} icon="left" onClick={handleClickSearch}>
                <Search />
                Поиск
              </ControlButton>
              {' '}
              {download_access?.candidates && (
                <>
                  { !(filtersString || name || requestStatus === 'true')
                    ? (
                      <Tooltip title="Выберите фильтр или введите в поиск" placement="rightBottom">
                        <Button
                          type="ghost"
                          icon={<Excel />}
                          className={cn(`${mainCssClass}__excel`, {
                            [`${mainCssClass}__excel-disabled`]: !(filtersString || name || requestStatus === 'true')
                          })}
                          disabled
                        >
                          {' '}
                          Выгрузить в Excel
                          {' '}
                        </Button>
                      </Tooltip>
                    )
                    : (
                      <Button type="ghost" icon={<Excel />} className={cn(`${mainCssClass}__excel`)} onClick={handleExportCSV}>
                        {' '}
                        Выгрузить в Excel
                        {' '}
                      </Button>
                    )}
                </>
              )}
            </div>
            {' '}
            {showButton && rbacControl?.candidate?.edit_view_access === 1 && (
              <Button id="ModalTransferStage" onClick={handleShowModal} className={`${mainCssClass}__stage`}>
                Перевести на этап
              </Button>
            )}
            <Radio.Group value={requestStatus} onChange={handleChangeStatus} className={`${mainCssClass}__tabs`}>
              <Radio.Button value="true">
                Мои&nbsp;
              </Radio.Button>
              <Radio.Button value="false">
                Все&nbsp;
              </Radio.Button>
            </Radio.Group>
            {/* <SelectFormControl */}
            {/*  name="color" */}
            {/*  options={colors} */}
            {/*  control={control} */}
            {/*  defaultValue={colors[0].value} */}
            {/*  className={`${mainCssClass}_selectColor`} */}
            {/* /> */}
            <ControlButton
              icon="left"
              size="large"
              typeButton="secondary"
              className="button"
              onClick={handldeOpenFilter}
            >
              <FiltersIcon />
              Фильтры
              {filtersCount >= 1 ? (
                <span className={`${mainCssClass}_count-in-brackets`}>
                  (
                  {filtersCount}
                  )
                </span>
              ) : ''}
            </ControlButton>

          </div>
          <FunnelDrag className={`${mainCssClass}__drag-menu`} requestStatus={requestStatus} self={requestStatus} />
        </div>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(Funnel))
