import { PayloadAction, current } from '@reduxjs/toolkit'
import {put, race, select, take, takeLatest} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { setLoadingProgress } from '../InnerRouter/actions'
import { getUserWorkPlan, setHeaders, setLoading, setUserWorkPlan, TRequestUserWorkPlan } from '.'
import { fetchGetUserWorkPlan, TUserWorkPlanResponse } from '../../common/api/userWorkPlan'
import {fetchPatchInterview} from "../../common/api/interviews";
import {updateInterview} from "../Calendar/actions";


const moduleName = 'План работы'

export default function* getUserWokPlanSaga({ payload }: PayloadAction<TRequestUserWorkPlan>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response:  AxiosResponse<TUserWorkPlanResponse>;
    ({response} = yield race({
      response: fetchGetUserWorkPlan(payload),
      cancel: take(getUserWorkPlan.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить кандидатов',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setUserWorkPlan(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

export function* userWorkPlanSaga() {
  yield takeLatest(getUserWorkPlan, getUserWokPlanSaga)
}
