import React from 'react'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'christmasTree'

function ChristmasTree({ className, size = '1', color }: TSvgProps) {
  return (

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="christmas-tree-christmas-svgrepo-com 1">
        <g id="Group">
          <path
            id="Vector"
            d="M29.5593 28.7458C26.5763 26.8132 24.6779 24.9491 24.6779 24.9491C26.1017 25.0847 26.8496 23.322 26.8496 23.322C25.4595 22.983 23.0552 20.6101 23.0552 20.6101C24.4447 20.5759 26.3137 19.5254 26.3137 19.5254C24.8222 19.2884 20.8981 15.1864 20.8981 15.1864C22.1184 15.3561 23.0844 14.644 23.0844 14.644C21.8641 14.407 19.8644 11.9321 19.8644 11.9321C21.3895 11.9999 22.6441 11.3897 22.6441 11.3897C19.9978 9.75775 17.8208 5.59994 16.8369 3.47C16.3227 3.96369 16 4.65412 16 5.42375C16 4.65412 15.6773 3.96369 15.1631 3.47012C14.1792 5.6 12.0022 9.75787 9.35594 11.3899C9.35594 11.3899 10.6099 12.0001 12.1356 11.9322C12.1356 11.9322 10.1354 14.4071 8.915 14.6441C8.915 14.6441 9.8815 15.3562 11.1019 15.1865C11.1019 15.1865 7.17781 19.2884 5.68631 19.5255C5.68631 19.5255 7.55531 20.5761 8.94487 20.6103C8.94487 20.6103 6.54056 22.9831 5.15044 23.3221C5.15044 23.3221 5.89838 25.0848 7.32213 24.9492C7.32213 24.9492 5.42381 26.8134 2.44075 28.7459C2.44075 28.7459 9.18625 28.9492 12.2034 27.1187C12.2034 27.1187 9.32181 29.7286 8.40681 29.8306C8.40681 29.8306 12.5424 29.7964 14.1018 28.2035C14.1018 28.2035 14.9153 29.1185 16.0001 29.2882C17.0848 29.1185 17.8984 28.2035 17.8984 28.2035C19.4577 29.7964 23.5933 29.8306 23.5933 29.8306C22.6783 29.7287 19.7967 27.1187 19.7967 27.1187C22.8138 28.9491 29.5593 28.7458 29.5593 28.7458Z"
            fill="#559E62"
          />
          <g id="Group_2">
            <path
              id="Vector_2"
              d="M17.188 15.7288H16.439C16.1966 15.7288 16.0003 15.5325 16.0003 15.2895V14.541C16.0003 14.298 16.1966 14.1017 16.439 14.1017H17.188C17.4304 14.1017 17.6273 14.298 17.6273 14.541V15.2895C17.6273 15.5325 17.4304 15.7288 17.188 15.7288Z"
              fill="#EEC319"
            />
            <path
              id="Vector_3"
              d="M15.5609 10.8474H14.8119C14.5695 10.8474 14.3731 10.6511 14.3731 10.4081V9.65963C14.3731 9.41663 14.5695 9.22031 14.8119 9.22031H15.5609C15.8033 9.22031 16.0002 9.41663 16.0002 9.65963V10.4081C16.0002 10.6511 15.8033 10.8474 15.5609 10.8474Z"
              fill="#EEC319"
            />
            <path
              id="Vector_4"
              d="M13.3914 18.4407H12.6424C12.3999 18.4407 12.2036 18.2444 12.2036 18.0014V17.2529C12.2036 17.0099 12.3999 16.8136 12.6424 16.8136H13.3914C13.6338 16.8136 13.8307 17.0099 13.8307 17.2529V18.0014C13.8308 18.2443 13.6339 18.4407 13.3914 18.4407Z"
              fill="#EEC319"
            />
            <path
              id="Vector_5"
              d="M20.4423 19.5254H19.6933C19.4508 19.5254 19.2545 19.3291 19.2545 19.0861V18.3376C19.2545 18.0946 19.4508 17.8983 19.6933 17.8983H20.4423C20.6847 17.8983 20.8816 18.0946 20.8816 18.3376V19.0861C20.8816 19.3291 20.6847 19.5254 20.4423 19.5254Z"
              fill="#EEC319"
            />
            <path
              id="Vector_6"
              d="M20.4423 25.4915H19.6933C19.4508 25.4915 19.2545 25.2952 19.2545 25.0522V24.3037C19.2545 24.0607 19.4508 23.8644 19.6933 23.8644H20.4423C20.6847 23.8644 20.8816 24.0607 20.8816 24.3037V25.0522C20.8816 25.2952 20.6847 25.4915 20.4423 25.4915Z"
              fill="#EEC319"
            />
            <path
              id="Vector_7"
              d="M16.1033 22.2373H15.3543C15.1118 22.2373 14.9155 22.041 14.9155 21.798V21.0495C14.9155 20.8065 15.1118 20.6102 15.3543 20.6102H16.1033C16.3457 20.6102 16.5426 20.8065 16.5426 21.0495V21.798C16.5426 22.0409 16.3457 22.2373 16.1033 22.2373Z"
              fill="#EEC319"
            />
            <path
              id="Vector_8"
              d="M11.7643 24.9491H11.0153C10.7729 24.9491 10.5766 24.7528 10.5766 24.5098V23.7613C10.5766 23.5183 10.7729 23.322 11.0153 23.322H11.7643C12.0068 23.322 12.2036 23.5183 12.2036 23.7613V24.5098C12.2036 24.7528 12.0068 24.9491 11.7643 24.9491Z"
              fill="#EEC319"
            />
          </g>
          <path
            id="Vector_9"
            d="M16 29.2881C15.5894 29.2241 15.2184 29.0533 14.9153 28.8656V31.4576H17.0848V28.8656C16.7816 29.0533 16.4106 29.2241 16 29.2881Z"
            fill="#994530"
          />
          <path
            id="Vector_10"
            d="M13.2881 2.71187C14.7873 2.71187 16 1.49913 16 0C16 1.49913 17.2128 2.71187 18.7119 2.71187C17.2128 2.71187 16 3.92462 16 5.42375C16 3.92462 14.7873 2.71187 13.2881 2.71187Z"
            fill="#EEC319"
          />
          <path
            id="Vector_11"
            d="M18.7119 32H13.2881C12.9888 32 12.7458 31.7576 12.7458 31.4576C12.7458 31.1577 12.9888 30.9153 13.2881 30.9153H18.7119C19.0113 30.9153 19.2543 31.1577 19.2543 31.4576C19.2543 31.7576 19.0113 32 18.7119 32Z"
            fill="#7A3726"
          />
        </g>
      </g>
    </svg>

  )
}

export default ChristmasTree
