
export function createYearsArray() {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let year = 1960; year <= currentYear; year++) {
    years.push({ label: String(year), value: year })
  }

  return years
}
