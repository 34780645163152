import { createAction } from '@reduxjs/toolkit'

export const setOpenEmployeesCenter = createAction<boolean>('employeesCenter/setOpenEmployeesCenter')

export const getEmployeesCenter = createAction('employeesCenter/openEmployeesCenter')

export const setEmployeesTableData = createAction<any[]>('employeesCenter/setEmployeesTableData')

export const setEmployeesStatusesBars = createAction<any[]>('employeesCenter/setEmployeesStatusesBars')

export const setEmployeesStatusesDiagram = createAction<any[]>('employeesCenter/setEmployeesStatusesDiagram')
export const setLoadingEmployeesCenter = createAction<boolean>('employeesCenter/setLoadingEmployeesCenter')
