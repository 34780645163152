import React, {
  memo, useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import dayjs from 'dayjs'
import { log } from '@rspack/core/compiled/neo-async'
import FoodPattern from '../../../common/svg/FoodPattern'
import OneMessage from './OneMessage'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import {
  selectHeadersForChat, selectMessageForChat, selectMessages, selectPageMessages
} from '../selectors'
import {
  getMessages,
  getReadMessages, setMessageForChat, setMessages, setPageMessages
} from '../actions'
import { selectCandidate } from '../../../compositions/candidate/selectors'
import { selectSystemColor } from '../../../compositions/InnerRouter/selectors'
import { formatTimestamp } from '../utils'
import './Messages.scss'

const MainCSSClass = 'messages'

function Messages() {
  const dispatch = useDispatch()
  const scrollbarsRef = useRef(null)
  const page = useSelector(selectPageMessages)
  const messageArr = useSelector(selectMessages)
  const candidate = useSelector(selectCandidate)
  const systemColor = useSelector(selectSystemColor)
  const newMessage = useSelector(selectMessageForChat)
  const headers = useSelector(selectHeadersForChat)

  const handleScroll = () => {
    if (scrollbarsRef?.current && headers && page && page < headers.total) {
      const scrollContainer = scrollbarsRef.current
      if (page && scrollContainer?.viewScrollTop === 0) {
        dispatch(setPageMessages(page + 1))
      }
    }
  }

  const svgClasses = useMemo(
    () => cn(`${MainCSSClass}_svg`, {
      [`${MainCSSClass}_svg-blue`]: systemColor === 'blue',
      [`${MainCSSClass}_svg-green`]: systemColor === 'green',
      [`${MainCSSClass}_svg-tomato`]: systemColor === 'tomato',
      [`${MainCSSClass}_svg-beet`]: systemColor === 'beet',
      [`${MainCSSClass}_svg-carrot`]: systemColor === 'carrot',
      [`${MainCSSClass}_svg-bread`]: systemColor === 'bread',
      [`${MainCSSClass}_svg-plum`]: systemColor === 'plum'
    }),
    [MainCSSClass, systemColor]
  )
  useEffect(() => {
    if (messageArr && messageArr.length && newMessage && newMessage?.candidate_id === candidate?.id) {
      const oldMessage = messageArr.find((item) => item.direction.toLowerCase() === 'in') || {}
      const findedMessage = messageArr?.find((item) => item.requestId === newMessage?.requestId)
      if (!findedMessage) {
        const formattedMessage = {
          ...oldMessage,
          requestId: newMessage?.requestId,
          id: newMessage?.requestId,
          messageContent: {
            type: 'TEXT',
            attachment: null,
            location: null,
            referral: null,
            caption: null,
            text: newMessage.message,
            payload: null,
            story: null,
            items: null,
            contact: null,
            product: null,
            catalog: null,
            order: null
          },
          direction: 'IN',
          status: 'UNREAD',
          created_at: dayjs(newMessage.date)
            .unix()
        }
        dispatch(setMessages([formattedMessage, ...messageArr]))
        dispatch(setMessageForChat(null))
      }
    } else if (newMessage?.candidate_id === candidate?.id) {
      dispatch(setMessageForChat(null))
      dispatch(getMessages({ phone: candidate.phone[0], page: 1 }))
    }
  }, [newMessage, candidate])

  const messagesLoaded = !!messageArr?.length

  useEffect(() => {
    if (scrollbarsRef?.current && messagesLoaded) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToBottom()
    }
  }, [messagesLoaded])

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handleMessages = () => {
      if (messageArr && messageArr.length) {
        const readMessages = messageArr
          .filter((el) => el.status !== 'READ' && el.direction.toLowerCase() === 'in' && el.requestId)
          ?.slice(-1)

        if (readMessages && readMessages.length) {
          dispatch(getReadMessages(readMessages))
        }
      }
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // Устанавливаем новый таймер
    timeoutRef.current = setTimeout(handleMessages, 2000)
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [messageArr, newMessage, dispatch])

  const sortedMessages = useMemo(() => (messageArr ? [...messageArr].reverse() : []), [messageArr])
  return (
    <div className={`${MainCSSClass}`}>
      <FoodPattern className={svgClasses} size="1" />
      <div className={`${MainCSSClass}_chat`}>
        <NewScrollContainer scrollbarsRef={scrollbarsRef} onScroll={handleScroll}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              width: '96%'
            }}
          >
            {sortedMessages && sortedMessages.length > 0 && sortedMessages
              ?.map((el) => ({
                status: el?.direction,
                name: el?.userInfo?.userName,
                date: formatTimestamp(el.created_at || 0),
                text: el.messageContent?.text,
                timestamp: el.created_at,
                statusRead: el.status
              }))
              .map((el, index) => (
                <OneMessage props={el} key={el?.requestId ? el?.requestId : `${el.timestamp}/${index}`} />
              ))}
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(Messages))
