import ru from 'react-phone-number-input/locale/ru'
import React from 'react'
import { useController } from 'react-hook-form'
import cn from 'classnames'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css' // импорт стилей из библиотеки
import Required from '../../../common/svg/Required'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { CountrySelectWithIcon } from './CustomSelect'
import './PhoneInputFormControl.scss'

const mainCssClass = getCssClassName('phone-input')

function PhoneInputFormControl({
  label, required, defaultCountry, className, error, tooltip, ...rest
}:any) {

  const currentDomain = window.location.origin
  const tooltipTitle = `${currentDomain}/candidate/${tooltip}`

  return (
    <div className={cn(
      className,
      mainCssClass,
      { error }
    )}
    >
      {label && (
        <p className="inputLabel">
          {label}
          {required && <Required className="required" />}
        </p>
      )}
      <PhoneInputWithCountry
        international
        labels={ru}
        countryCallingCodeEditable={false}
        countrySelectComponent={CountrySelectWithIcon}
        defaultCountry={defaultCountry || 'RU'}
        {...rest}
      />
      {tooltip && (
        <p className="errorText">
          Кандидат с такими данными уже существует.
          {' '}
          <a href={`${tooltipTitle}`} target="_blank" rel="noreferrer">Нажмите чтобы посмотреть</a>
        </p>
      )}
    </div>
  )
}

export default PhoneInputFormControl
