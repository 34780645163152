import React, { memo } from 'react'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import './ModalCloseSure.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'modalCloseSure'

function ModalCloseSure({
  onOk, open, className, onCancel
}: ModalProps) {
  const modalStyle = {
    zIndex: 19999
  }
  return (
    <CommonModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title="Закрыть форму?"
      className={cn(className, mainCssClass)}
      width="400px"
      centered
      typeButtonAccept="warning"
      okText="Закрыть"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      style={modalStyle}
    >
      <p>Внесенные изменения будут потеряны</p>
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(ModalCloseSure))
