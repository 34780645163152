import { createReducer } from '@reduxjs/toolkit'
import {
  openСalendarSettings,
  reset, setActiveDateChange, setBlockedSlots,
  setDictionaries,
  setEmptySlotByUser,
  setEmptySlotTimeStop,
  setHolidays,
  setReserveSlotsUser
} from './actions'
import { TDictionaries, TOption } from '../../common/types/dictionaries'
import {TBlockedSlots} from "../../common/api/calendar";

type TCalendarSettingsState = {
  openSettings: boolean
  dictionaries: TDictionaries | null
  recruiter: TOption[] | null
  researcher: TOption[] | null
  emptySlots: string[]
  emptySlotsTimeStop: string[]
  holidays: any
  reserveSlots: any
  blockedSlots: TBlockedSlots[] | null
  activeDateChange: string
}

const initialState: TCalendarSettingsState = {
  openSettings: false,
  dictionaries: null,
  recruiter: null,
  researcher: null,
  emptySlots: [],
  emptySlotsTimeStop: [],
  holidays: null,
  reserveSlots: null,
  blockedSlots: null,
  activeDateChange: ''
}

const calendarSettingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openСalendarSettings, (state, { payload }) => {
      state.openSettings = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setEmptySlotByUser, (state, { payload }) => {
      state.emptySlots = payload
    })
    .addCase(setEmptySlotTimeStop, (state, { payload }) => {
      state.emptySlotsTimeStop = payload
    })
    .addCase(setReserveSlotsUser, (state, { payload }) => {
      state.reserveSlots = payload
    })
    .addCase(setHolidays, (state, { payload }) => {
      state.holidays = payload
    })
      .addCase(setBlockedSlots, (state, { payload }) => {
        state.blockedSlots = payload
      })
    .addCase(setActiveDateChange, (state, { payload }) => {
      state.activeDateChange = payload
    })
})

export default calendarSettingsReducer
