import { put, takeLatest, spawn, select, race, take } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse, AxiosError } from 'axios'
import * as tty from 'node:tty'
import { TRequestPatchUser, fetchPatchUpdateUser } from '../../common/api/user'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  TAddStageRequest,
  TRequestCreateDictionaryOptions,
  TRequestCreateQuestionnaire,
  TRequestPAssword,
  TRequestUpdateDictionaryOptions,
  TRequestUpdateTrigger,
  TUpdateStageRequest,
  changePassword,
  createDictionaryOptions,
  createQuestionnaire,
  createStage,
  createTemplate,
  createTrigger,
  deleteTemplate,
  deleteTrigger,
  getActiveDictionary,
  getDictionaries,
  getQuestionnaire,
  getStages,
  getTemplates,
  getTriggers,
  getVertices,
  openDictionaryForm,
  openNewTrigger,
  openQuestionnaireForm,
  openTemplatesForm,
  setActiveDictionary,
  setActiveQuestionnaire,
  setDictionaries,
  setLoading,
  setQuestionnaire,
  setStages,
  setTemplates,
  setTriggers,
  setVertices,
  sortStage,
  updateDictionaryOptions,
  updateQuestionnaire,
  updateStage,
  updateStatusQuestionnaire,
  updateTemplate,
  updateTrigger,
  updateUser,
  setRoleList,
  getRoleList,
  updateStatusRole,
  archiveStatusRole,
  createOrEditRole,
  createRole,
  updateRole,
  getRoleConstructor,
  setRoleConstructor,
  setStageState,
  getStageState,
  TRequestPatchStageState,
  updateStageState,
  TRequestCreateStageState,
  createStageState,
  setUsers,
  getUsers,
  createUser,
  updateUserModel,
  setSelectedUser,
  createOrEditUser,
  getDictionariesDropdown,
  setDictionariesDropdown,
  setStores,
  getStores,
  createStore,
  updateStore,
  setSelectedStore,
  setCreateOrEditStore,
  TRequestUpdateVertices,
  updateVertices,
  setHeadersStore,
  setHeadersUser,
  TRequestWhatsappTemplates,
  setWhatsappTemplates,
  setHeadersWhatsappTemplates,
  getWhatsappTemplates,
  TRequestCreateWhatsappTemplate,
  openWhatsappTemplatesForm,
  createWhatsappTemplate,
  TRequestUpdateWhatsappTemplate,
  updateWhatsappTemplate,
  setActiveWhatsappTemplate,
  deleteWhatsappTemplate,
  updateStatusDictionary,
  TUpdateUserModel,
  TRequestCallbacks,
  setCallbacks,
  setHeadersCallback,
  getCallbacks,
  TRequestCreateCallback,
  createCallback,
  openCallbackSettingForm,
  TRequestUpdateCallback,
  updateCallback,
  getSynchronization,
  getReminders,
  setReminders,
  setHeadersReminders,
  openReminderForm,
  createReminder,
  TRequestUpdateReminder,
  updateReminder,
  TRequestTokens,
  setTokens,
  setHeadersTokens,
  TRequestCreateToken,
  openTokenForm,
  getTokens,
  TRequestUpdateToken,
  createToken,
  updateToken,
  getSelectedUser,
  createPinWhatsappTemplate,
  TRequestPinWhatsappTemplate,
  deletePinWhatsappTemplate,
  TFieldConfigRequest,
  getFieldConfig,
  setFieldConfig,
  TFieldConfig,
  updateFieldConfig,
  createFieldConfig,
  setConstructorModal,
  incrementFieldConfig,
  decrementFieldConfig
} from './actions'
import {
  TRequestCreateTemplate,
  TRequestCreateTrigger,
  TRequestSortStage,
  TRequestStatusQuestionnaire,
  TRequestUpdateTemplate,
  TResponseTrigger,
  TResponseTriggers,
  TStagesResponse,
  TVerticesResponse,
  fetchDeleteTemplate,
  fetchDeleteTrigger,
  fetchGetCreateLinkTrigger,
  fetchGetDictionaries,
  fetchGetDictionary,
  fetchGetQuestionnaire,
  fetchGetStages,
  fetchGetTemplates,
  fetchGetTriggers,
  fetchGetVertices,
  fetchPatchDictionaryOptions,
  fetchPatchStatusQuestionnaire,
  fetchPatchUpdateStage,
  fetchPatchUpdateTemplate,
  fetchPatchUpdateTrigger,
  fetchPostCreateQuestionnaire,
  fetchPostCreateStage,
  fetchPostCreateTemplate,
  fetchPostCreateTrigger,
  fetchPostDictionaryOptions,
  fetchPostSortStage,
  fetchPostUpdateQuestionnaire,
  fetchPostValidatePassword,
  fetchGetRoleList,
  TRbacRoleListResponse,
  TRequestStatusArchive,
  fetchPatchUpdateRoleArchive,
  fetchDeleteRoleArchive,
  fetchPatchUpdateRole,
  fetchCreateRole,
  fetchGetRole,
  fetchGetStageState,
  fetchPatchUpdateStageState,
  fetchPostCreateStageState,
  fetchGetUsersList,
  fetchDeleteUser,
  TUser,
  fetchPostUser,
  fetchPatchUser,
  fetchGetStores,
  fetchPostStore,
  TStore,
  fetchPatchStore,
  TStoreResponse,
  fetchPatchUpdateVertices,
  fetchGetWhatsappTemplates,
  fetchPostCreateWhatsappTemplate,
  fetchPatchUpdateWhatsappTemplate,
  fetchDeleteWhatsappTemplate,
  TResponseCallbacks,
  fetchGetReminders,
  TRequestReminders,
  fetchPostCreateRemind,
  fetchPatchUpdateReminder,
  TRequestCreateReminder,
  TRequestStatusDictionary,
  fetchPatchStatusDictionary,
  fetchGetCallbacks,
  fetchPostCreateCallbacks,
  fetchPatchUpdateCallback,
  fetchGetUserSynchronization,
  fetchGetTokens,
  fetchPostCreateTokens,
  fetchPatchUpdateToken,
  TStagesLimitationResponse,
  fetchGetStageLimitation,
  fetchGetSelectUser,
  fetchPostPinWhatsappTemplate,
  fetchDeletePinWhatsappTemplate,
  fetchGetFieldsConfig,
  fetchPatchUpdateFieldsConfig,
  fetchPostCreateFieldsConfig,
  fetchPostPriorityIncrementFieldsConfig,
  fetchPostPriorityDecrementFieldsConfig
} from '../../common/api/systems'
import { getUserInfo, setLoadingProgress } from '../InnerRouter/actions'
import {
  TCreateFieldConfig,
  TCreateFieldConfigWithFilter,
  TRoleSetting,
  TUpdateFieldConfig,
  TUpdatePriorityFieldConfigWithFilter
} from './types'
import {
  selectDefDataCallback,
  selectDefDataReminders,
  selectDefDataStore,
  selectDefDataTokens,
  selectDefDataUser,
  selectDefDataWatsapp,
  selectFieldConfig,
  selectStagesState
} from './selectors'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { selectUserInfo } from '../InnerRouter/selectors'
import { TPaginationRequest } from '../../common/types/pagination'
import { TResponse } from '../../common/types/response'
import { getFilterString } from '../DepartmentWorkPlan/utils'

const moduleName = 'Страница настроек'

type TriggersResponse = AxiosResponse<TResponseTriggers>
type VerticesResponse = AxiosResponse<TVerticesResponse>
type StagesResponse = AxiosResponse<TStagesResponse>
type TAxiosFieldConfigResponse = AxiosResponse<TResponse<TFieldConfig[]>>
type TAxiosUpdateFieldConfigResponse = AxiosResponse<TResponse<TFieldConfig>>

function* getDictionariesDropdownSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionariesDropdown, 'страница настроек')
}

function* getQuestionnaireSaga() {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetQuestionnaire(),
      cancel: take(getQuestionnaire.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить анкеты',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setQuestionnaire(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateStatusQuestionnaireSaga({ payload }: PayloadAction<TRequestStatusQuestionnaire>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchStatusQuestionnaire(payload),
      cancel: take(updateStatusQuestionnaire.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статус анкеты',
          error: response
        })
      )
    } else {
      yield spawn(getQuestionnaireSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createQuestionnaireSaga({ payload }: PayloadAction<TRequestCreateQuestionnaire>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostCreateQuestionnaire(payload),
      cancel: take(createQuestionnaire.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать анкету',
          error: response
        })
      )
    } else {
      yield put(openQuestionnaireForm(false))
      yield spawn(getQuestionnaireSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateQuestionnaireSaga({ payload }: PayloadAction<TRequestCreateQuestionnaire>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostUpdateQuestionnaire(payload),
      cancel: take(updateQuestionnaire.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать анкету',
          error: response
        })
      )
    } else {
      yield put(openQuestionnaireForm(false))
      yield put(setActiveQuestionnaire(null))
      yield spawn(getQuestionnaireSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* changePasswordSaga({ payload }: PayloadAction<TRequestPAssword>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPostValidatePassword(payload), cancel: take(changePassword.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить пароль',
          error: response
        })
      )
    } else if (response.data.DATA.result === 'error') {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Неверные данные',
          error: 'Неверные данные'
        })
      )
    } else {
      yield fetchPatchUpdateUser({ password: payload.new_password })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getTriggersSaga() {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TriggersResponse
    ;({ response } = yield race({
      response: fetchGetTriggers(),
      cancel: take(getTriggers.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить триггеры',
          error: response
        })
      )
    } else {
      yield put(setTriggers(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createTriggerSaga({ payload }: PayloadAction<TRequestCreateTrigger>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TriggersResponse
    ;({ response } = yield race({
      response: fetchPostCreateTrigger(payload),
      cancel: take(createTriggerSaga.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать триггер',
          error: response
        })
      )
    } else {
      if (payload.template_id) {
        yield fetchGetCreateLinkTrigger({
          trigger_action: response.data.DATA.id,
          trigger_template: payload.template_id
        })
      }
      yield put(openNewTrigger(false))
      yield spawn(getTriggersSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateTriggerSaga({ payload }: PayloadAction<TRequestUpdateTrigger>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateTrigger(payload),
      cancel: take(updateTrigger.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить триггер',
          error: response
        })
      )
    } else {
      yield put(openNewTrigger(false))
      yield spawn(getTriggersSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* deleteTriggerSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchDeleteTrigger(payload),
      cancel: take(deleteTrigger.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить триггер',
          error: response
        })
      )
    } else {
      yield spawn(getTriggersSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getTemplatesSaga() {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetTemplates(),
      cancel: take(getTemplates.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить шаблоны',
          error: response
        })
      )
    } else {
      yield put(setTemplates(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateUserSaga({ payload }: PayloadAction<TRequestPatchUser>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateUser(payload),
      cancel: take(updateUser.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить данные пользователя',
          error: response
        })
      )
    } else {
      yield put(getUserInfo(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createTemplateSaga({ payload }: PayloadAction<TRequestCreateTemplate>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostCreateTemplate(payload),
      cancel: take(createTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать шаблон',
          error: response
        })
      )
    } else {
      yield put(openTemplatesForm(false))
      yield spawn(getTemplatesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateTemplateSaga({ payload }: PayloadAction<TRequestUpdateTemplate>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateTemplate(payload),
      cancel: take(updateTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить шаблон',
          error: response as AxiosError
        })
      )
    } else {
      yield put(openTemplatesForm(false))
      yield spawn(getTemplatesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* deleteTemplateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchDeleteTemplate(payload), cancel: take(deleteTemplate.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить шаблон',
          error: response as AxiosError
        })
      )
    } else {
      if (response.data.DATA) {
        showCriticalNotification(
          getErrorNotification({
            moduleName,
            text: `${response.data.DATA.message}. Установите другой шаблон для указанных триггеров.`
          })
        )
      } else {
      }
      yield spawn(getTemplatesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getDictionariesSaga() {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    const { response } = yield race({ response: fetchGetDictionaries(), cancel: take(getDictionaries.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить справочники',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setDictionaries(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getDictionarySaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetDictionary(payload),
      cancel: take(getActiveDictionary.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить справочники',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setActiveDictionary(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}
function* updateStatusDictionarySaga({ payload }: PayloadAction<TRequestStatusDictionary>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchStatusDictionary(payload),
      cancel: take(updateStatusDictionary.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статус справочника',
          error: response as AxiosError
        })
      )
    } else {
      yield spawn(getDictionariesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createDictionaryOptionsSaga({ payload }: PayloadAction<TRequestCreateDictionaryOptions>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostDictionaryOptions(payload),
      cancel: take(createDictionaryOptions.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать запись',
          error: response as AxiosError
        })
      )
    } else {
      yield spawn(getDictionarySaga, getActiveDictionary(response.data.DATA.dictionary_id))
      // yield spawn(getDictionariesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateDictionaryOptionsSaga({ payload }: PayloadAction<TRequestUpdateDictionaryOptions>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchDictionaryOptions(payload),
      cancel: take(updateDictionaryOptions.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить запись',
          error: response as AxiosError
        })
      )
    } else {
      yield fetchPatchDictionaryOptions(payload)
      yield put(openDictionaryForm(false))
      // yield spawn(getDictionariesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getVerticesSaga() {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: VerticesResponse
    ;({ response } = yield race({
      response: fetchGetVertices(),
      cancel: take(getVertices.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить отделы',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setVertices(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateVerticesSaga({ payload }: PayloadAction<TRequestUpdateVertices>) {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPatchUpdateVertices(payload), cancel: take(updateVertices.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить отдел',
          error: response as AxiosError
        })
      )
    } else {
      yield fetchPatchUpdateVertices(payload)
      yield spawn(getVerticesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getStagesSaga() {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: StagesResponse
    ;({ response } = yield race({
      response: fetchGetStages(),
      cancel: take(getStages.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить этапы',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setStages(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* addStageSaga({ payload }: PayloadAction<TAddStageRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPostCreateStage(payload), cancel: take(createStage.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать этап',
          error: response
        })
      )
    } else {
      yield spawn(getStagesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateStageSaga({ payload }: PayloadAction<TUpdateStageRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPatchUpdateStage(payload), cancel: take(updateStage.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить этап',
          error: response
        })
      )
    } else {
      yield spawn(getStagesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* sortStageSaga({ payload }: PayloadAction<TRequestSortStage>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: StagesResponse
    ;({ response } = yield race({
      response: fetchPostSortStage(payload),
      cancel: take(sortStage.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отсортировать этапы',
          error: response
        })
      )
    } else {
      yield spawn(getStagesSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getRoleListSaga() {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TRbacRoleListResponse
    ;({ response } = yield race({
      response: fetchGetRoleList(),
      cancel: take(getRoleList.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список ролей',
          error: response
        })
      )
    } else {
      yield put(setRoleList(response.data.DATA))
      // yield put(setHeadersUser({current: res.Headers['x-pagination-current-page'][0], total: res.Headers['x-pagination-page-count'][0]}))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getUsersListSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: AxiosResponse
    ;({ response } = yield race({
      response: fetchGetUsersList(payload),
      cancel: take(getUsers.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список пользователей',
          error: response
        })
      )
    } else {
      yield put(
        setHeadersUser({
          current: response.data.Headers['x-pagination-current-page'][0],
          total: response.data.Headers['x-pagination-page-count'][0]
        })
      )
      yield put(setUsers(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getSelectedUserSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse
    ;({ response } = yield race({
      response: fetchGetSelectUser(payload),
      cancel: take(getSelectedUser.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить данные пользователя',
          error: response
        })
      )
    } else {
      yield put(setSelectedUser(response.data.DATA[0]))
      yield put(createOrEditUser(true))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateUserModelSaga({ payload }: PayloadAction<TUpdateUserModel>) {
  try {
    if (!payload.isCodeChalenge) {
      yield put(setLoading(true))
      yield put(setLoadingProgress(true))
    }
    const { response } = yield race({ response: fetchPatchUser(payload.user), cancel: take(updateUserModel.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список юзеров',
          error: response as AxiosError
        })
      )
    } else if (!payload.isCodeChalenge) {
      yield put(setSelectedUser(null))

      yield put(createOrEditUser(false))
      const data = yield select(selectDefDataUser)
      if (data) {
        yield put(getUsers(data))
      }
      const user = yield select(selectUserInfo)

      if (user.mail_settings.user_id === payload.id) {
        yield put(getUserInfo(false))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
  }
}
function* createUserSaga({ payload }: PayloadAction<TUser>) {
  try {
    yield put(setLoading(true))
    const { response } = yield race({ response: fetchPostUser({ User: payload }), cancel: take(createUser.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список юзеров',
          error: response as AxiosError
        })
      )
    } else {
      // yield fetchPostUser({ User: payload })
      const data = yield select(selectDefDataUser)
      if (data) {
        yield put(getUsers(data))
      }
      yield put(createOrEditUser(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* deleteUserSaga({ payload }: PayloadAction<any>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchDeleteUser(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить юзера',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getUsers())
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* updateRoleStatusSaga({ payload }: PayloadAction<TRequestStatusArchive>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPatchUpdateRoleArchive(payload),
      cancel: take(updateStatusRole.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить роль',
          error: response as AxiosError
        })
      )
    } else {
      yield spawn(getRoleListSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* archiveRoleStatusSaga({ payload }: PayloadAction<TRequestStatusArchive>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchDeleteRoleArchive(payload), cancel: take(archiveStatusRole.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить роль',
          error: response as AxiosError
        })
      )
    } else {
      yield fetchDeleteRoleArchive(payload)
      yield spawn(getRoleListSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* createRoleSaga({ payload }: PayloadAction<TRoleSetting>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchCreateRole(payload), cancel: take(createRole.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить роль',
          error: response as AxiosError
        })
      )
    } else {
      yield fetchCreateRole(payload)
      yield put(createOrEditRole(false))
      yield spawn(getRoleListSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* updateRoleSaga({ payload }: PayloadAction<TRoleSetting>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchPatchUpdateRole(payload), cancel: take(updateRole.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить роль',
          error: response as AxiosError
        })
      )
    } else {
      yield put(createOrEditRole(false))
      yield put(setRoleConstructor(null))
      yield spawn(getRoleListSaga)
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getRoleConstructorSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchGetRole(payload), cancel: take(getRoleConstructor.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить роль',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRoleConstructor(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getStageStateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({ response: fetchGetStageState(payload), cancel: take(getStageState.type) })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить статусы на этапе',
          error: response as AxiosError
        })
      )
    } else {
      const responses = yield select(selectStagesState)
      yield put(setStageState([...responses, { id: payload, stages: response.data.DATA }]))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getStoreListSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TStoreResponse
    ;({ response } = yield race({
      response: fetchGetStores(payload),
      cancel: take(getStores.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось объекты',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setStores(response.data.DATA))
      yield put(
        setHeadersStore({
          current: response.data.Headers['x-pagination-current-page'][0],
          total: response.data.Headers['x-pagination-page-count'][0]
        })
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* createStoreSaga({ payload }: PayloadAction<TStore>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostStore(payload),
      cancel: take(createStore.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось сохранить объект',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCreateOrEditStore(false))
      // const page: number = yield select(selectPageStore)

      const data = yield select(selectDefDataStore)
      if (data) {
        yield put(getStores(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateStoreSaga({ payload }: PayloadAction<TStore>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchStore(payload),
      cancel: take(updateStore.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить объект',
          error: response as AxiosError
        })
      )
    } else {
      const data = yield select(selectDefDataStore)
      if (data) {
        yield put(getStores(data))
      }
      yield put(setSelectedStore(null))
      yield put(setCreateOrEditStore(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateStageStateSaga({ payload }: PayloadAction<TRequestPatchStageState>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPatchUpdateStageState(payload),
      cancel: take(updateStageState.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статусы на этапе',
          error: response as AxiosError
        })
      )
    } else {
      const newStages = yield fetchGetStageState(response.data.DATA.stage_id)
      const res = yield select(selectStagesState)

      yield put(
        setStageState(
          res.map((item) => {
            if (item.id === response.data.DATA.stage_id) {
              return { ...item, stages: newStages.data.DATA }
            }
            return item
          })
        )
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createStageStateSaga({ payload }: PayloadAction<TRequestCreateStageState>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: fetchPostCreateStageState(payload),
      cancel: take(createStageState.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать статус на этапе',
          error: response as AxiosError
        })
      )
    } else {
      const newStages = yield fetchGetStageState(response.data.DATA.stage_id)
      const res = yield select(selectStagesState)

      yield put(
        setStageState(
          res.map((item) => {
            if (item.id === response.data.DATA.stage_id) {
              return { ...item, stages: newStages.data.DATA }
            }
            return item
          })
        )
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getWhatsappTemplatesSaga({ payload }: PayloadAction<TRequestWhatsappTemplates>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TStoreResponse
    ;({ response } = yield race({
      response: fetchGetWhatsappTemplates(payload),
      cancel: take(getWhatsappTemplates.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить шаблоны сообщений получены',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setWhatsappTemplates(response.data.DATA))
      yield put(
        setHeadersWhatsappTemplates({
          current: response.data.Headers['x-pagination-current-page'][0],
          total: response.data.Headers['x-pagination-page-count'][0]
        })
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createWhatsappTemplateSaga({ payload }: PayloadAction<TRequestCreateWhatsappTemplate>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TStoreResponse
    ;({ response } = yield race({
      response: fetchPostCreateWhatsappTemplate(payload),
      cancel: take(createWhatsappTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать шаблоны сообщений',
          error: response as AxiosError
        })
      )
    } else {
      yield put(openWhatsappTemplatesForm(false))
      const data = yield select(selectDefDataWatsapp)
      if (data) {
        yield put(getWhatsappTemplates(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateWhatsappTemplateSaga({ payload }: PayloadAction<TRequestUpdateWhatsappTemplate>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TStoreResponse
    ;({ response } = yield race({
      response: fetchPatchUpdateWhatsappTemplate(payload),
      cancel: take(updateWhatsappTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить шаблон сообщения',
          error: response
        })
      )
    } else {
      yield put(openWhatsappTemplatesForm(false))
      yield put(setActiveWhatsappTemplate(null))
      const data = yield select(selectDefDataWatsapp)
      if (data) {
        yield put(getWhatsappTemplates(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* deleteWhatsappTemplateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TStoreResponse>
    ;({ response } = yield race({
      response: fetchDeleteWhatsappTemplate(payload),
      cancel: take(deleteWhatsappTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить шаблон сообщения',
          error: response
        })
      )
    } else {
      const data = yield select(selectDefDataWatsapp)
      if (data) {
        yield put(getWhatsappTemplates(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* createPinWhatsappTemplateSaga({ payload }: PayloadAction<TRequestPinWhatsappTemplate>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TStoreResponse>
    ;({ response } = yield race({
      response: fetchPostPinWhatsappTemplate(payload),
      cancel: take(createPinWhatsappTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось добавить в избранное шаблон сообщений',
          error: response
        })
      )
    } else {
      const data = yield select(selectDefDataWatsapp)
      if (data) {
        yield put(getWhatsappTemplates(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* deletePinWhatsappTemplateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TStoreResponse>
    ;({ response } = yield race({
      response: fetchDeletePinWhatsappTemplate(payload),
      cancel: take(deletePinWhatsappTemplate.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить из избранных',
          error: response
        })
      )
    } else {
      const data = yield select(selectDefDataWatsapp)
      if (data) {
        yield put(getWhatsappTemplates(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getCallbacksSaga({ payload }: PayloadAction<TRequestCallbacks>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse
    ;({ response } = yield race({
      response: fetchGetCallbacks(payload),
      cancel: take(getCallbacks.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список callback',
          error: response
        })
      )
    } else {
      yield put(setCallbacks(response.data.DATA.callback_list))
      yield put(setHeadersCallback({ current: response.data.DATA.page, total: response.data.DATA.page_total }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* createCallbackSaga({ payload }: PayloadAction<TRequestCreateCallback>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse
    ;({ response } = yield race({
      response: fetchPostCreateCallbacks(payload),
      cancel: take(createCallback.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: `Не удалось создать callback. ${response.data.DATA[0]?.message || ''}`,
          error: response
        })
      )
    } else {
      yield put(openCallbackSettingForm(false))
      const data = yield select(selectDefDataCallback)
      if (data) {
        yield put(getCallbacks(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateCallbackSaga({ payload }: PayloadAction<TRequestUpdateCallback>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateCallback(payload),
      cancel: take(updateCallback.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить callback',
          error: response
        })
      )
    } else {
      yield put(openCallbackSettingForm(false))
      const data = yield select(selectDefDataCallback)
      if (data) {
        yield put(getCallbacks(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getSynchronizationSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetUserSynchronization(payload),
      cancel: take(getSynchronization.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось синхронизиировать',
          error: response
        })
      )
    } else {
      const data = yield select(selectDefDataUser)
      if (data) {
        yield put(getUsers(data))
      }
      yield put(setSelectedUser(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}
function* getTokensSaga({ payload }: PayloadAction<TRequestTokens>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetTokens(payload),
      cancel: take(getTokens.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список токенов',
          error: response
        })
      )
    } else {
      yield put(setTokens(response.data.DATA))
      yield put(
        setHeadersTokens({
          current: response.data.Headers['x-pagination-current-page'][0],
          total: response.data.Headers['x-pagination-page-count'][0]
        })
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createTokenSaga({ payload }: PayloadAction<TRequestCreateToken>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostCreateTokens(payload),
      cancel: take(createToken.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: `Не удалось создать токен. ${response.data.DATA[0]?.message || ''}`,
          error: response
        })
      )
    } else {
      yield put(openTokenForm(false))
      const data = yield select(selectDefDataTokens)
      if (data) {
        yield put(getTokens(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateTokenSaga({ payload }: PayloadAction<TRequestUpdateToken>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateToken(payload),
      cancel: take(updateToken.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить токен',
          error: response
        })
      )
    } else {
      yield put(openTokenForm(false))
      const data = yield select(selectDefDataTokens)
      if (data) {
        yield put(getTokens(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getRemindersSaga({ payload }: PayloadAction<TRequestReminders>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchGetReminders(payload),
      cancel: take(getReminders.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить список напоминаний',
          error: response
        })
      )
    } else {
      yield put(setReminders(response.data.DATA))
      yield put(
        setHeadersReminders({
          current: response.data.Headers['x-pagination-current-page'][0],
          total: response.data.Headers['x-pagination-page-count'][0]
        })
      )
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* createReminderSaga({ payload }: PayloadAction<TRequestCreateReminder>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPostCreateRemind(payload),
      cancel: take(createReminder.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать напоминание.',
          error: response
        })
      )
    } else {
      yield put(openReminderForm(false))
      const data = yield select(selectDefDataReminders)
      if (data) {
        yield put(getReminders(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* updateReminderSaga({ payload }: PayloadAction<TRequestUpdateReminder>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any
    ;({ response } = yield race({
      response: fetchPatchUpdateReminder(payload),
      cancel: take(updateReminder.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить напоминание',
          error: response
        })
      )
    } else {
      yield put(openReminderForm(false))
      const data = yield select(selectDefDataReminders)
      if (data) {
        yield put(getReminders(data))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getFieldConfigSaga({ payload }: PayloadAction<TFieldConfigRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const { response }: { response: TAxiosFieldConfigResponse } = yield race({
      response: fetchGetFieldsConfig(payload),
      cancel: take(getFieldConfig.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить конструктор',
          error: response
        })
      )
    } else {
      yield put(setFieldConfig(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateFieldConfigSaga({ payload }: PayloadAction<TUpdateFieldConfig>) {
  try {
    const fieldConfig: TFieldConfig[] = yield select(selectFieldConfig)
    yield put(setLoadingProgress(true))
    const { response }: { response: TAxiosUpdateFieldConfigResponse } = yield race({
      response: fetchPatchUpdateFieldsConfig(payload),
      cancel: take(updateFieldConfig.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить конструктор',
          error: response
        })
      )
    } else {
      const updatedFieldConfig = fieldConfig.map((config) =>
        config.id === response.data.DATA.id ? response.data.DATA : config
      )
      yield put(setFieldConfig(updatedFieldConfig))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* createFieldConfigSaga({ payload }: PayloadAction<TCreateFieldConfigWithFilter>) {
  try {
    yield put(setLoadingProgress(true))
    const { response }: { response: TAxiosUpdateFieldConfigResponse } = yield race({
      response: fetchPostCreateFieldsConfig(payload.data),
      cancel: take(createFieldConfig.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить конструктор',
          error: response
        })
      )
    } else {
      yield put(setConstructorModal(false))
      yield spawn(getFieldConfigSaga, getFieldConfig({ filter: payload.filter }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* incrementFieldConfigSaga({ payload }: PayloadAction<TUpdatePriorityFieldConfigWithFilter>) {
  try {
    yield put(setLoadingProgress(true))
    const { response }: { response: TAxiosUpdateFieldConfigResponse } = yield race({
      response: fetchPostPriorityIncrementFieldsConfig(payload.data),
      cancel: take(incrementFieldConfig.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить приоритет',
          error: response
        })
      )
    } else {
      yield spawn(getFieldConfigSaga, getFieldConfig({ filter: payload.filter }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* decrementFieldConfigSaga({ payload }: PayloadAction<TUpdatePriorityFieldConfigWithFilter>) {
  try {
    yield put(setLoadingProgress(true))
    const { response }: { response: TAxiosUpdateFieldConfigResponse } = yield race({
      response: fetchPostPriorityDecrementFieldsConfig(payload.data),
      cancel: take(decrementFieldConfig.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить приоритет',
          error: response
        })
      )
    } else {
      yield spawn(getFieldConfigSaga, getFieldConfig({ filter: payload.filter }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* systemsSaga() {
  yield takeLatest(getDictionariesDropdown, getDictionariesDropdownSaga)
  yield takeLatest(getQuestionnaire, getQuestionnaireSaga)
  yield takeLatest(getRoleList, getRoleListSaga)
  yield takeLatest(updateStatusQuestionnaire, updateStatusQuestionnaireSaga)
  yield takeLatest(updateStatusDictionary, updateStatusDictionarySaga)
  yield takeLatest(createQuestionnaire, createQuestionnaireSaga)
  yield takeLatest(updateQuestionnaire, updateQuestionnaireSaga)
  yield takeLatest(changePassword, changePasswordSaga)
  yield takeLatest(getTriggers, getTriggersSaga)
  yield takeLatest(createTrigger, createTriggerSaga)
  yield takeLatest(deleteTrigger, deleteTriggerSaga)
  yield takeLatest(updateTrigger, updateTriggerSaga)
  yield takeLatest(getTemplates, getTemplatesSaga)
  yield takeLatest(updateUser, updateUserSaga)
  yield takeLatest(createTemplate, createTemplateSaga)
  yield takeLatest(updateTemplate, updateTemplateSaga)
  yield takeLatest(deleteTemplate, deleteTemplateSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getActiveDictionary, getDictionarySaga)
  yield takeLatest(createDictionaryOptions, createDictionaryOptionsSaga)
  yield takeLatest(updateDictionaryOptions, updateDictionaryOptionsSaga)
  yield takeLatest(getVertices, getVerticesSaga)
  yield takeLatest(getStages, getStagesSaga)
  yield takeLatest(createStage, addStageSaga)
  yield takeLatest(updateStage, updateStageSaga)
  yield takeLatest(sortStage, sortStageSaga)
  yield takeLatest(updateStatusRole, updateRoleStatusSaga)
  yield takeLatest(archiveStatusRole, archiveRoleStatusSaga)
  yield takeLatest(createRole, createRoleSaga)
  yield takeLatest(updateRole, updateRoleSaga)
  yield takeLatest(getRoleConstructor, getRoleConstructorSaga)
  yield takeLatest(getStageState, getStageStateSaga)
  yield takeLatest(updateStageState, updateStageStateSaga)
  yield takeLatest(createStageState, createStageStateSaga)
  yield takeLatest(createUser, createUserSaga)
  yield takeLatest(getUsers, getUsersListSaga)
  yield takeLatest(getSelectedUser, getSelectedUserSaga)
  yield takeLatest(updateUserModel, updateUserModelSaga)
  yield takeLatest(getStores, getStoreListSaga)
  yield takeLatest(createStore, createStoreSaga)
  yield takeLatest(updateStore, updateStoreSaga)
  yield takeLatest(updateVertices, updateVerticesSaga)
  yield takeLatest(getWhatsappTemplates, getWhatsappTemplatesSaga)
  yield takeLatest(createWhatsappTemplate, createWhatsappTemplateSaga)
  yield takeLatest(updateWhatsappTemplate, updateWhatsappTemplateSaga)
  yield takeLatest(deleteWhatsappTemplate, deleteWhatsappTemplateSaga)
  yield takeLatest(createPinWhatsappTemplate, createPinWhatsappTemplateSaga)
  yield takeLatest(deletePinWhatsappTemplate, deletePinWhatsappTemplateSaga)
  yield takeLatest(getCallbacks, getCallbacksSaga)
  yield takeLatest(createCallback, createCallbackSaga)
  yield takeLatest(updateCallback, updateCallbackSaga)
  yield takeLatest(getSynchronization, getSynchronizationSaga)
  yield takeLatest(getReminders, getRemindersSaga)
  yield takeLatest(createReminder, createReminderSaga)
  yield takeLatest(updateReminder, updateReminderSaga)
  yield takeLatest(getTokens, getTokensSaga)
  yield takeLatest(createToken, createTokenSaga)
  yield takeLatest(updateToken, updateTokenSaga)
  yield takeLatest(getFieldConfig, getFieldConfigSaga)
  yield takeLatest(updateFieldConfig, updateFieldConfigSaga)
  yield takeLatest(createFieldConfig, createFieldConfigSaga)
  yield takeLatest(incrementFieldConfig, incrementFieldConfigSaga)
  yield takeLatest(decrementFieldConfig, decrementFieldConfigSaga)
}

export default systemsSaga
