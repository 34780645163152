export function formatTimestamp(timestamp: number) {
  let date = new Date()
  if (timestamp) {
    date = new Date(timestamp * 1000)
  }
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number)

  const day = addLeadingZero(date.getDate())
  const month = addLeadingZero(date.getMonth() + 1)
  const year = date.getFullYear()
  const hours = addLeadingZero(date.getHours())
  const minutes = addLeadingZero(date.getMinutes())

  const formattedDateTime = `${hours}:${minutes} ${day}.${month}.${year}`

  return formattedDateTime
}

export function convertHttpToLink(inputString: string) {
  // Регулярное выражение для поиска ссылок, начинающихся с "http"
  // const httpRegex = /http\S*/g

  // Заменяем найденные совпадения на HTML-ссылки
  let replacedString = inputString
  if (inputString.includes('Новая задача')) {
    const match = inputString.match(/\((\d+)\)/)
    if (match)
    // Формируем ссылку из подстроки "Новая задача" и добавляем в replacedString
    {
      (replacedString = inputString.replace(inputString, `<a href="/tasks?${match[1]}" target="_blank">${inputString}</a>`)
      )
    }
  }

  return replacedString
}

export function getDate(arr) {
  if (arr && arr.length === 2) {
    const strArrStr = arr.map((el) => el.split('-').reverse().join('.')).join('-')
    return `&between[created_at]=${strArrStr}`
  } return ''
}
