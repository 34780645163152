import React from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { ModalProps } from 'antd'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { openMassSettingsModal } from '../actions'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'

const mainCssClass = getCssClassName('mass-settings')

export type TFormValues = {
    work_day_per_month: string
}

function MassSettings({
  open, className
}: ModalProps) {
  const dispatch = useDispatch()
  const { control, watch, setValue, handleSubmit } = useForm<TFormValues>()

  const onCancel = () => {
    dispatch(openMassSettingsModal(false))
  }
  const onSubmit = () => {
    dispatch(openMassSettingsModal(false))
  }
  return (
    <CommonModal
      open={open}
      onCancel={onCancel}
      centered
      onOk={handleSubmit(onSubmit)}
      title="Массовая настройка"
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <InputFormControl control={control} name="" label="Рабочих д./месяц" />
        <InputFormControl control={control} name="" label="План" />
      </div>
    </CommonModal>
  )
}

export default MassSettings
