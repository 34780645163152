import { PayloadAction } from '@reduxjs/toolkit'
import {
  put, race, takeLatest, take
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  exportCSV,
  getDictionaries, getVacancies, setDictionaries, setHeaders, setLoading, setVacancies
} from './actions'
import { fetchGetVacancies, fetchPostCreateVacancyCSV, TResponseVacancy } from '../../common/api/vacancy'
import { TPaginationRequest } from '../../common/types/pagination'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { TRequestRequestsCSV } from '../ManagementRequests/actions'
import { setLoadingProgress } from '../InnerRouter/actions'

const moduleName = 'Вакансии таблица'
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'вакансии таблица')
}
function* getVacanciesSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: TResponseVacancy;
    ({ response } = yield race({
      response: fetchGetVacancies(payload),
      cancel: take(getVacancies.type)
    }))
    yield put(setVacancies(response.DATA))
    yield put(setHeaders({
      current: response.Headers['x-pagination-current-page'][0],
      total: response.Headers['x-pagination-page-count'][0],
      totalCount: response.Headers['x-pagination-total-count'][0]
    }))
  } catch (error) {
    showCriticalNotification(
      getErrorNotification({
        moduleName,
        text: 'Не удалось загрузить вакансии',
        error: error as AxiosError
      })
    )
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TRequestRequestsCSV>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: any;
    ({ response } = yield race({
      response: fetchPostCreateVacancyCSV(payload),
      cancel: take(exportCSV.type)
    }))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить файл',
          error: response as AxiosError
        })
      )
    } else {
      // const blob = new Blob([response.data], { type: 'text/csv' })
      // const url = window.URL.createObjectURL(blob)
      // const link = document.createElement('a')
      // link.href = url
      // link.download = 'filename'
      // link.click()
      // window.URL.revokeObjectURL(url)

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

export function* managementVacanciesSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getVacancies, getVacanciesSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
}
