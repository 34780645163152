import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import { Tabs, Typography } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { selectLoadingModal } from '../../../compositions/InnerRouter/selectors'
import {
  selectCandidate,
  selectHistoryAsterisk, selectHistoryVariable
} from '../../../compositions/candidate/selectors'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getCssClassName } from '../../../common/utils/generateClassName'
import AsteriskTable from './AsteriskTable'
import { getHistoryAsterisk } from '../actions'
import EmptyList from '../../../features/emptyList/components/EmptyList'
import './HistoryAsterisk.scss'

const data = [
  {
    incoming: '17:23:24 31.01.2021',
    recordUrl: 'http://localhost:8080/candidate/4292982',
    caller: {
      name: 'хей хей хей'
    },
    type: '1'
  }
]

  type TFormValues = {
      actions: string
      perPagesCount: number
    }

export type TFormFilter = {
      user_id: number[]
      date: any
    }

const MainCSSClass = getCssClassName('asterisk-history')

function HistoryAsterisk() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const historyData = useSelector(selectHistoryAsterisk)
  const variable = useSelector(selectHistoryVariable)
  const loading = useSelector(selectLoadingModal)

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      // Ваш код обработки изменения размеров
    }, 300)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (candidate) {
      dispatch(getHistoryAsterisk(candidate.id))
    }
  }, [candidate, variable])

  return (
    <div className={MainCSSClass}>

      {loading && (
        <div>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      <div className={`${MainCSSClass}_scroll-container`}>
        <NewScrollContainer className={`${MainCSSClass}_scroll-container_scroll`}>
          {!loading
          && !!historyData?.length
          && (
            <AsteriskTable
              historyData={historyData}
              className={`${MainCSSClass}_table`}
            />
          )}
          {!loading
          && !historyData?.length
           && <EmptyList type="asterisk" />}

        </NewScrollContainer>
      </div>
    </div>
  )
}

export default HistoryAsterisk
