/* eslint-disable max-len */
import React, {
  memo, useCallback, useEffect, useMemo,
  useRef
} from 'react'
import { Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { getQuestionnaireForServer, getQuestionnaireFromServer } from '../../../candidate/utils'
import EditPencil1 from '../../../../common/svg/EditPencil1'
import SelectFormControl from '../../../../common/components/formcontrol/SelectFormControl'
import {
  selectCandidate, selectCandidateQuestionnaire, selectIsEditQuestionnaire, selectQuestionnaire,
} from '../../selectors'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  createQuestionnaire, getQuestionnaire, setIsEditModeQuestionnaire, updateCandidate
} from '../../actions'
import { TAnswer } from '../../../candidate/actions'
import { selectUserInfo } from '../../../InnerRouter/selectors'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import NewScrollContainer from '../../../../common/components/NewScrollContainer/ScrollContainer'
import InputFormControlQuestionnaire from '../../../../common/components/formcontrol/InputFormControlQuestionnaire'
import { Preloader } from '../../../../common/components/Preloader/Preloader'
import ControlButton from '../../../../common/components/Buttons/Button'

const MainCSSClass = 'questionnaireCandidate'

function QuestionnaireCandidate() {
  const dispatch = useDispatch()
  const {
    rbacControl, role, isCanAppointRecruiter, isColdSearch
  } = useEntityContext()

  const questionnaire = useSelector(selectQuestionnaire)
  const candidate = useSelector(selectCandidate)
  const selectUser = useSelector(selectUserInfo)
  const candidateQuestionnaire = useSelector(selectCandidateQuestionnaire)
  const defaultValues = useMemo(() => getQuestionnaireFromServer(
    candidateQuestionnaire,
    selectUser?.role?.role_id,
    candidate?.questionnare_id
  ), [candidateQuestionnaire, candidate?.questionnare_id])
  const isEditMode = useSelector(selectIsEditQuestionnaire)
  const {
    control, watch, getValues, setValue, reset, handleSubmit
  } = useForm<TFormValues>({ defaultValues })
    type TFormValues = {
      questionnaire: number
    } & any
    const questionnaireId = watch('questionnaire')
    const selectQuestionnaireFromCandidate = useMemo(() => {
      if (candidateQuestionnaire && questionnaire) {
        return questionnaire?.find((item) => item.id === candidate?.questionnare_id)
      } return null
    }, [candidate, questionnaire, candidateQuestionnaire])

    const inputRefs = useRef<Array<HTMLInputElement | null>>([])

    // Функция для добавления рефов к инпутам
    const addInputRef = (ref: HTMLInputElement | null, index: number) => {
      inputRefs.current[index] = ref
    }

    // Обработчик для перехода по полям при нажатии Enter
    const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        const nextIndex = index + 1
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex]?.focus()
        }
      }
    }
    const selectQuestionnaires = useMemo(() => {
      if (questionnaireId && questionnaire) {
        reset()
        setValue('questionnaire', questionnaireId)
        return questionnaire?.find((item) => item.id === questionnaireId)
      } return null
    }, [questionnaireId, questionnaire])

    const filteredQuestions = useMemo(() => selectQuestionnaires?.question_list?.filter((item) => (item.is_show === 1 && item.is_recruiter === 0) || (item.is_show === 1 && item.is_recruiter === 1 && selectUser?.role?.role_id === 'recruiter')).sort((a, b) => a.id - b.id), [selectUser, selectQuestionnaires, questionnaireId])
    const handleCreateQuestionnaire = useCallback(() => {
      if (candidate) {
        const values = getValues()
        const answers: TAnswer[] = getQuestionnaireForServer(values)
        dispatch(createQuestionnaire({ candidate_id: candidate.id, answers }))
      }
    }, [dispatch, getValues, candidate, candidateQuestionnaire])

    const sortedQuestions = useMemo(() => {
      if (candidateQuestionnaire?.answers?.length) {
        const sorted = [...candidateQuestionnaire.answers]
        sorted.sort((a, b) => Number(a.question_id) - Number(b.question_id))

        return sorted
      }
      return []
    }, [candidate, candidateQuestionnaire, questionnaireId])

    const options = useMemo(() => {
      if (questionnaire && questionnaire.length > 0) {
        return questionnaire.map((el) => ({ label: el.name, value: el.id }))
      }
      return []
    }, [questionnaire])
    const handleEditMode = () => {
      dispatch(setIsEditModeQuestionnaire(true))
    }

    const handleSelect = (selectOption: number) => {
      dispatch(updateCandidate({ id: candidate.id, questionnare_id: selectOption }))
      reset({ questionnaire: selectOption })
    }

    useEffect(() => {
      dispatch(getQuestionnaire())
    }, [])

    useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])

    return (
      <div className={`${MainCSSClass}`}>
        <NewScrollContainer className={`${MainCSSClass}_scroll`}>
          <div className={`${MainCSSClass}_header`}>

            {!questionnaire
        && <Preloader size="md" />}

            {questionnaire
          && (
            <Typography.Title level={4}>
              Анкета
              { rbacControl?.candidate?.edit_view_access === 1 && (
                <SelectFormControl
                  className={`${MainCSSClass}_header_select`}
                  name="questionnaire"
                  onSelect={handleSelect}
                  control={control}
                  options={options}
                />
              )}

            </Typography.Title>
          )}
            { candidate?.questionnare_id && !isEditMode ? (
              <>
                <div className={`${MainCSSClass}__heading-qq`}>
                  <Typography.Title level={4}>
                    Анкета:&nbsp;
                    {selectQuestionnaireFromCandidate?.name}
                  </Typography.Title>
                  { rbacControl?.candidate?.edit_view_access === 1 && (<EditPencil1 onClick={handleEditMode} />)}
                </div>
                {sortedQuestions?.map((item, index) => (
                  <InputFormControlQuestionnaire
                    label={item.question}
                    name={`${item.question_id}`}
                    control={control}
                    disabled
                    ref={(ref) => addInputRef(ref, index)}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                  />
                ))}
              </>
            )
              : filteredQuestions && (
                <>
                  <Typography.Title level={4}>
                    Анкета:&nbsp;
                    {selectQuestionnaires?.name}
                  </Typography.Title>
                  {filteredQuestions?.map((item, index) => (
                    <InputFormControlQuestionnaire
                      label={item.question}
                      name={`${item.id}`}
                      control={control}
                      ref={(ref) => addInputRef(ref, index)}
                      onKeyDown={(event) => handleKeyDown(event, index)}
                    />

                  ))}
                  <ControlButton onClick={handleSubmit(handleCreateQuestionnaire)} className={cn('button', `${MainCSSClass}_header_button`)} typeButton="primary">
                    Сохранить
                  </ControlButton>
                </>
              )}
            {candidate?.questionnare_id && !isEditMode && !sortedQuestions?.length
              && (
                <div>
                  Анкета кандидата пустая. Чтобы начать заполнять анкету, нажмите на редактировать (карандаш)
                </div>
              )}
          </div>
        </NewScrollContainer>

      </div>

    )
}

export default memo(withErrorBoundary(QuestionnaireCandidate))
