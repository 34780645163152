import React, { FC, memo, useMemo } from 'react'
import { Typography } from 'antd'
import './CandidateOldHistoryTableStatus.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TProps = {
    action: JSX.Element
    after: string,
    before: string
}

const mainCssClass = 'history-candidate-name-table'

const CandidateOldHistoryTableStatus: FC<TProps> = ({
  action, after, before
}) => (
  <div className={mainCssClass}>
    <div className={`${mainCssClass}__title`}>
      {/* <Typography.Title level={4} className={`${mainCssClass}__title-text`}> */}
      {/*  <Typography.Text className={`${mainCssClass}__coust`}> */}
      {/*    {date} */}
      {/*    {' '} */}
      {/*  </Typography.Text> */}
      {/*  {' '} */}
      {/*  {user} */}
      {/* </Typography.Title> */}
      <Typography.Text className={`${mainCssClass}__coust`}>
        {action}
      </Typography.Text>
      <Typography.Text className={`${mainCssClass}__coust`}>
        <div dangerouslySetInnerHTML={{ __html: after }} />
      </Typography.Text>
      <Typography.Text className={`${mainCssClass}__coust`}>
        <div dangerouslySetInnerHTML={{ __html: before }} />
      </Typography.Text>
    </div>
  </div>
)
export default memo<TProps>(withErrorBoundary<TProps>(CandidateOldHistoryTableStatus))
