import {
  put, select, spawn, takeLatest, delay,
  race,
  take
} from 'redux-saga/effects'
import { AxiosResponse, AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'

import { selectEditKeyDetailList, selectUserInfo } from '../../compositions/InnerRouter/selectors'
import {
  TPatchRequest,
  TPostRequest,
  TUpdateRequestStatus,
  archiveStatusRequest,
  createRequest,
  updateRequest,
  updateStatusRequest,
  TArchiveRequest, updateArchiveRequest, getResponsibleList, setResponsibleList,
  openDuplicateModal,
  getDuplicateRequest,
  setIsRequestTable,
  setEditRequestMode,
  openRequestDrawer,
  setDictionaries,
  getDictionaries
} from './actions'
import { setOpenModalArchive } from '../../compositions/ManagamentCandidates/actions'
import { TResponseOneRequest } from '../../compositions/Request/types'
import {
  fetchGetArchiveRequestUpdateStatus, fetchGetRequest, fetchGetResponsible,
  fetchPatchRequestArchive,
  fetchPatchRequestUpdateStatus,
  fetchPatchUpdateRequest,
  fetchPostCreateLinksSchedule,
  fetchPostCreateRequest, fetchPostCurators, fetchPostResponsible
} from '../../common/api/request'
import {
  getRequest,
  openArchiveModal,
  openCreateRequestModal,
  openEditRequestModal,
  openRejectModal,
  openStatusModal,
  setRequest
} from '../../compositions/Request/actions'
import {
  getRequests,
  setOpenModalArchiveRequest,
  setOpenModalEditRequest
} from '../../compositions/ManagementRequests/actions'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { selectDefDataRequest } from '../../compositions/ManagementRequests/selectors'
import { navigateTo } from '../../common/actions/navigate'
import { setEditKeyDetailList, setLoadingModal, setLoadingProgress } from '../../compositions/InnerRouter/actions'
import { selectOpenDuplicateModal } from './selectors'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'

type RequestResponse = AxiosResponse<TResponseOneRequest>

const moduleName = 'Модалки заявок'


function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'заявки модалки')
}

function* updateRequestStatusSaga({ payload }: PayloadAction<TUpdateRequestStatus>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    const response: RequestResponse = yield fetchPatchRequestUpdateStatus(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить статус заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequest(response.data.DATA))
      if (payload.isRequestTable) {
        const data = yield select(selectDefDataRequest)
        if (data) {
          yield put(getRequests({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
      }
      yield put(openStatusModal(false))
      showSuccessNotification({
        text: 'Статус заявки обновлён'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}
function* updateArchiveRequestStatusSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    const response: RequestResponse = yield fetchGetArchiveRequestUpdateStatus(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить статус заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getRequest(payload))
      showSuccessNotification({
        text: 'Статус заявки обновлён'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* archiveRequestStatusSaga({ payload }: PayloadAction<TArchiveRequest>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: RequestResponse = yield fetchPatchRequestArchive(payload.data)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось архивировать статус заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setOpenModalArchive(false))
      if (payload.isRequestTable) {
        const data = yield select(selectDefDataRequest)
        if (data) {
          yield put(getRequests({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
        yield put(setOpenModalArchiveRequest(false))
      }
      yield put(setRequest(response.data.DATA))
      yield put(openArchiveModal(false))
      yield put(openRejectModal(false))
      showSuccessNotification({
        text: 'Статус заявки архивирован'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* createRequestSaga({ payload }: PayloadAction<TPostRequest>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const response: RequestResponse = yield fetchPostCreateRequest(payload.data)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать заявку',
          error: response as AxiosError
        })
      )
    } else {
      if (response.data.DATA.id && payload.data.responsible_id_list) {
        yield fetchPostResponsible({ request_id: response.data.DATA.id, responsible_id_list: payload.data.responsible_id_list })
      }
      yield put(openCreateRequestModal(false))
      const { id } = response.data.DATA
      if (id && payload.data.curator?.length > 0) {
        const payloadCurators = {
          curator_id_list: payload.data.curator,
          request_id: id
        }
        yield fetchPostCurators(payloadCurators)
      }
      yield put(openRequestDrawer(false))
      yield delay(1000)
      yield put(navigateTo(`/application/${id}`))
      showSuccessNotification({
        text: 'Заявка создана'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* updateRequestSaga({ payload: { data, isRequestTable, isCurator } }: PayloadAction<TPatchRequest>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    const editKeyDetailList = select(selectEditKeyDetailList)
    const duplicateModal: boolean = yield select(selectOpenDuplicateModal)
    if (data.id && data.responsible_id_list) {
      yield fetchPostResponsible({ request_id: data.id, responsible_id_list: data.responsible_id_list })
    }
    const response: RequestResponse = yield fetchPatchUpdateRequest(data)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить заявку',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setRequest(response.data.DATA))

      if (data.id && isCurator) {
        const payloadCurators = {
          curator_id_list: data.curator || [],
          request_id: data.id
        }
        yield fetchPostCurators(payloadCurators)
      }
      yield put(openEditRequestModal(false))
      if (isRequestTable) {
        const data = yield select(selectDefDataRequest)
        if (data) {
          yield put(getRequests({ page: data.page, perPage: data.perPage, filters: data.filters }))
        }
        yield put(setIsRequestTable(false))
      }
      if (duplicateModal) {
        yield put(openDuplicateModal(false))
      }
      yield put(setEditRequestMode(false))
      yield put(openRequestDrawer(false))
      if (editKeyDetailList) {
        yield put(setEditKeyDetailList(''))
      }
      showSuccessNotification({
        text: 'Заявка обновлена'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* getDuplicateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    const userInfo = yield select(selectUserInfo)

    const { response } = yield race({
      response: fetchGetRequest(payload),
      cancel: take(getRequest.type)
    })

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось дублировать заявку',
          error: response as AxiosError
        })
      )
    } else {
      yield put(createRequest({ data: { ...response.data.DATA, status: 92 }, isCurator: userInfo?.department === 175 }))
      yield put(openDuplicateModal(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}

function* getResponsibleListSaga() {
  try {
    yield put(setLoadingProgress(true))
    const response: RequestResponse = yield fetchGetResponsible()
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить статус заявки',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setResponsibleList(response.data.DATA.map((obj: { id: number, responsible_label:string }) => ({
        label: obj.responsible_label,
        value: obj.id
      }))))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

export function* modalsRequestSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(updateStatusRequest, updateRequestStatusSaga)
  yield takeLatest(updateArchiveRequest, updateArchiveRequestStatusSaga)
  yield takeLatest(archiveStatusRequest, archiveRequestStatusSaga)
  yield takeLatest(createRequest, createRequestSaga)
  yield takeLatest(updateRequest, updateRequestSaga)
  yield takeLatest(getResponsibleList, getResponsibleListSaga)
  yield takeLatest(getDuplicateRequest, getDuplicateSaga)
}
