import React, { useEffect, useState } from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps, useForm } from 'react-hook-form'
import cn from 'classnames'
import './SelectFormControl.scss'
import Required from '../../svg/Required'
import { TOption } from '../../types/dictionaries'

type TControlSelectProps = Omit<
  UseControllerProps &
    SelectProps & { className?: string; size?: 'small' | 'middle' | 'large'; label?: string; required?: boolean },
  'isDisabled'
>

interface SelectFormControlProps extends TControlSelectProps {
  rules?: {
    required: boolean
  }
}

const { Title } = Typography

const { Option } = Select

function SelectFormControlMult({
  name,
  disabled = false,
  control,
  rules,
  label,
  className,
  size,
  required,
  options,
  placeholder,
  defaultValue,
  onBlur,
  onChange,
  ...rest
}: SelectFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })
  const [searchValue, setSearchValue] = useState('')

  const handleSelectChange = (value: any, option: TOption | TOption[]) => {
    field.onChange(value)
    if (onChange) {
      onChange(value, option)
    }
  }
  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])

  const sanitizeInput = (input: string) => {
    if (input === undefined || input === null) {
      return ''
    }
    return input.replace(/[^a-zA-Z0-9а-яА-ЯёЁ ]/g, '')
  }

  const filterOption = (input: string, option: any) => {
    const sanitizedInput = sanitizeInput(input)
    const sanitizedLabel = sanitizeInput(option.children)
    return sanitizedLabel.toLowerCase().includes(sanitizedInput.toLowerCase())
  }

  const handleSearch = (value: string) => {
    const sanitizedValue = sanitizeInput(value)
    setSearchValue(sanitizedValue)
  }

  return (
    <div
      className={cn(
        className,
        'customSelect',
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' }
      )}
    >
      {label && (
        <div>
          <Title className="customSelect_label" level={4}>
            {label}
            {required && <Required className="required" />}
          </Title>
        </div>
      )}
      <Select
        mode="multiple"
        placeholder={placeholder || 'выберите из списка'}
        onChange={handleSelectChange}
        value={field.value}
        className={fieldState.error ? 'error' : ''}
        size={size}
        onBlur={onBlur || field.onBlur}
        disabled={disabled}
        filterOption={filterOption}
        onSearch={handleSearch}
        searchValue={searchValue}
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectFormControlMult
