import React from 'react'
import Icon from '../svgIcons/ArrowLeft.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function ArrowLeft(props: IconProps) {
  return (
    <IconWrapper {...props} name="ArrowLeft">
      <Icon />
    </IconWrapper>
  )
}
