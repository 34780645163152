import classNames from 'classnames'
import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { ScrollContainerProps } from './types'
import { ReactCustomScrollbar } from './ReactCustomScrollBar'

import './styles.scss'

const mainCssClass = 'newScrollContainer'

export default function NewScrollContainer({
  'data-testid': testId = 'scroll',
  children,
  maxHeight = '100%',
  minHeight = '100%',
  scrollbarsRef,
  className,
  ...rest
}: ScrollContainerProps) {
  // const calculatedAutoHeightMax = useMemo(() => {
  //   if (typeof autoHeightMax === 'string' && autoHeightMax.endsWith('%')) {
  //     const percentageValue = parseInt(autoHeightMax, 10)
  //     if (!Number.isNaN(percentageValue)) {
  //       return `${(document.body.clientHeight * percentageValue) / 100}px`
  //     }
  //   }
  //   return autoHeightMax
  // }, [autoHeightMax])

  return (
    <ReactCustomScrollbar
      data-testid={testId}
      ref={scrollbarsRef}
      renderView={(props) => (
        <div
          {...props}
          className={classNames(`${mainCssClass}_render`, className)}
          style={{
            ...props.style, 
          }}
        />
      )}
      renderTrackHorizontal={(props) => <div {...props} className={`${mainCssClass}_trackX`} />}
      renderTrackVertical={(props) => <div {...props} className={`${mainCssClass}_trackY`} />}
      renderThumbHorizontal={(props) => <div {...props} className={`${mainCssClass}_thumbX`} />}
      renderThumbVertical={(props) => <div {...props} className={`${mainCssClass}_thumbY`} />}
      style={{
        maxHeight,
        minHeight,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%'
      }}
      tagName="div"
      {...rest}
      className={className}
    >
      {children}
    </ReactCustomScrollbar>
  )
}
