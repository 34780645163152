import { createAction } from '@reduxjs/toolkit'
import { TRequestMessages, TRequestReadMessage, TRequestSendMessage, TResponseMessages } from '../../common/api/chat'

export const getMessages = createAction<TRequestMessages>('chat/getMessages')

export const setMessages = createAction<TResponseMessages[]>('chat/setMessages')
export const setPageMessages = createAction<number>('chat/setPageMessages')

export const resetChat = createAction('chat/rest')
export const setLoading = createAction<boolean>('chat/setLoading')

export const sendMessage = createAction<TRequestSendMessage>('chat/sendMessage')


export type TRequestMailMessages = {
    page:number
    perPage:number
    identifier:string | number
}

export const getMailMessages = createAction<TRequestMailMessages>('chat/getMailMessages')

export const setMailMessages = createAction<TResponseMessages[]>('chat/setMailMessages')

export type TRequestSendMailMessage = {
    email: string,
    message: string
    candidate_id: string | number
}

export const sendMailMessage = createAction<TRequestSendMailMessage>('chat/sendMailMessage')

export const setActualEmailMessages = createAction<number>('chat/setActualEmailMessages')

export const resetMailMessages = createAction('chat/resetMailMessages')

export const setTemplateContent = createAction<string>('chat/setTemplateContent')

export const getReadMessages = createAction<TRequestReadMessage[]>('chat/getReadMessages')
export const setMessageForChat = createAction<any>('chat/setMessageForChat')
export type TChatPanelHeaders = {
    current:number,
    total:number,
    totalCount: number
}

export const setHeaders = createAction<TChatPanelHeaders>('chat/setHeaders')
