import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'

const selectState = ({ phot }: TRootState) => phot

export const selectIsOpenHidePost = createSelector(
  selectState,
  ({ openModalHidePost }) => openModalHidePost
)

export const selectIsOpenNewEntry = createSelector(
  selectState,
  ({ openModalNewEntry }) => openModalNewEntry
)
