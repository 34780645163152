import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { openActiveInterviewModal } from '../actions'
import './HasActiveInterview.scss'

const mainCssClass = 'hasActiveModal'

function HasActiveModals({
 open, className
}: ModalProps) {
  const dispatch = useDispatch()

  const handleOk = () => {
    dispatch(openActiveInterviewModal(false))
  }

  const handleCancel = () => {
    dispatch(openActiveInterviewModal(false))
  }
  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title="Активное интервью"
      className={cn(className, mainCssClass)}
      width="400px"
      centered
      okText="Хорошо"
      wrapClassName="wrapModal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p>Прежде чем покинуть страницу, завершите текущее интервью.</p>
    </Modal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(HasActiveModals))
