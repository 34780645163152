import $api from '../../common/api/http'

export const LoadInitial = async () => {
  const { data } = await $api.get('/api/v1/vacancies?sort=-is_favorite&page=1&per-page=100&fields=id,label,description&filter[status][in][]=137&filter[status][in][]=138')

  return data.DATA.vacancy_list.map((obj: { id: number; label: string; description: string }) => ({
    label: `${obj.label}`,
    value: obj.id,
    description: obj.description ? obj.description : 'Нет описания'
  }))
}
