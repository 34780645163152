import React, {
  memo, useEffect, useMemo
} from 'react'
import {
  ModalProps, Typography
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import EmptyTasks from '../../../common/svg/EmptyTasks'
import { navigateTo } from '../../../common/actions/navigate'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  openTasksModal
} from '../actions'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { selectCandidate } from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { tasksOptions } from '../utils'

import ControlButton from '../../../common/components/Buttons/Button'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import EditPencil1 from '../../../common/svg/EditPencil1'
import './TasksModal.scss'
import {
  openTaskCreateModal, selectTasks, setSelectTask, TTask, updateTask
} from '../../Tasks'
import { selectUserInfo } from '../../InnerRouter/selectors'

const mainCssClass = getCssClassName('tasks-modal')

  type TFormValues = {
    type: string
  }

function TasksModal({
  open, className
}: ModalProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const userInfo = useSelector(selectUserInfo)
  const tasks = useSelector(selectTasks)
  const { rbacControl } = useEntityContext()

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues: { type: 'today' } })

  const handleClickCancel = () => {
    dispatch(openTasksModal(false))
  }

  const handleClickDone = (id:number) => {
    dispatch(updateTask({ task_id: id, status: 1 }))
  }

  const handleClickArchive = (id:number) => {
    dispatch(updateTask({ task_id: id, is_archive: true }))
  }

  const type = watch('type')

  const actualTasks = useMemo(() => {
    if (tasks) {
      if (type !== 'all') {
        return tasks.filter((el) => el.type === type)
      } return [{
        list: tasks.reduce((acc, el) => {
          if (el.list) {
            acc.push(...el.list)
          }
          return acc
        }, [])
      }]
    }
    return [{ list: [] }]
  }, [type, tasks])

  const handleEditTask = (elem:TTask) => {
    dispatch(setSelectTask(elem))
    dispatch(openTaskCreateModal(true))
  }

  useEffect(() => () => {
    reset()
  }, [])

  return (
    <CommonModal
      open={open}
      onCancel={handleClickCancel}
      title="Мои задачи"
      className={cn(className, mainCssClass)}
      width="335px"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      footer={null}
    >
      <div className={`${mainCssClass}_content`}>
        <Typography.Title level={4}>
          Кандидат:
          {' '}
          {candidate?.last_name
            ? `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`
            : `${candidate?.name} ${candidate?.second_name || ''}`}
        </Typography.Title>
        <SelectFormControl name="type" control={control} options={tasksOptions} />
        <div className={`${mainCssClass}_content_container`}>
          <NewScrollContainer className={`${mainCssClass}_content_container_scroll`}>
            <div className={`${mainCssClass}_content_list`}>

              {

                !actualTasks[0]?.list.length && (
                  <div className={`${mainCssClass}_content_list_empty`}>
                    <EmptyTasks />
                    <Typography.Title level={5}>Задач нет</Typography.Title>
                    <p>В данном разделе у вас нет задач по данному кандидату</p>
                  </div>
                )
              }

              {!!actualTasks
                && actualTasks[0]?.list?.map((elem) => (
                  <div
                    className={cn(
                      actualTasks[0].type === 'overdue' || (new Date(elem.deadline_at) < new Date()) ? `${mainCssClass}_content_list_card-overdue` : `${mainCssClass}_content_list_card`,
                      {
                        [`${mainCssClass}_content_list_card-done`]: elem.status === 1
                      }
                    )}
                    key={elem.task_id}
                  >
                    <div className={`${mainCssClass}_content_list_card_main`}>

                      {elem.is_archive && (
                        <Typography.Text className="task-card_name">
                          Архивировано
                        </Typography.Text>
                      )}
                      {!!elem.status
                          && (
                            <Typography.Text className="task-card_name">
                              Выполнена
                            </Typography.Text>
                          )}
                      <div className={`${mainCssClass}_content_list_card_main_header`}>
                        <Typography.Text className="task-card_name">
                          {elem.name}
                        </Typography.Text>
                        {!elem.is_archive && rbacControl?.task.edit_view_access === 1 && (userInfo?.id === elem.author_id || userInfo?.role?.role_id === 'superadmin')
                              && (
                                <EditPencil1 onClick={() => handleEditTask(elem)} />
                              )}

                      </div>
                      <Typography.Text className="task-card_description">
                        {elem?.description?.length > 100 ? `${elem.description.slice(0, 100)}...` : elem.description}
                      </Typography.Text>
                      <Typography.Text className="task-card_deadline_at">
                        Выполнить до
                        {' '}
                        {elem.deadline_at.slice(0, -3)}
                      </Typography.Text>
                      <Typography.Text className="task-card_author">
                        Автор:
                        {' '}
                        {elem.author_label}
                      </Typography.Text>

                      {elem.target_label
                      && (
                        <Typography.Text className="task-card_author">
                          Цель задачи:
                          {' '}
                          {elem.target_label}
                        </Typography.Text>
                      )}

                      {elem.candidate_label
                      && (
                        <Typography.Text className="task-card_author">
                          Кандидат:
                          {' '}
                          {elem.candidate_label}
                          {' '}
                          {elem.candidate_phone || ''}
                        </Typography.Text>
                      ) }

                    </div>

                    <div className="action">
                      {!elem.status && !elem.is_archive && (userInfo?.id === elem.user_id || userInfo?.role?.role_id === 'superadmin') && (
                        <ControlButton className="button" typeButton="secondary" onClick={() => handleClickDone(elem.task_id)}>
                          Выполнена
                        </ControlButton>
                      )}
                      {!elem.is_archive && rbacControl?.task.archive_access === 1 && (userInfo?.id === elem.author_id || userInfo?.role?.role_id === 'superadmin') && (
                        <ControlButton typeButton="tertiary" className="button" onClick={() => handleClickArchive(elem.task_id)}>
                          Архивировать
                        </ControlButton>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </NewScrollContainer>
        </div>
        {

          !!actualTasks[0]?.list.length && (
            <ControlButton typeButton="secondary" className="button" onClick={() => dispatch(navigateTo('/tasks'))}>
              Перейти в мои задачи
            </ControlButton>
          )
        }

      </div>

    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(TasksModal))
