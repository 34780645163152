import dayjs from 'dayjs'
import { TOption } from '../../common/types/dictionaries'

export function getColorOnRequest(date:any, priority: number, priorityOptions: TOption[]) {
  let arrayPriorityStr = priorityOptions?.find((item) => item.value === priority)?.params
  arrayPriorityStr = arrayPriorityStr?.replace(/\\/g, '')
  arrayPriorityStr = arrayPriorityStr
    ?.replace(/^"|"$|\\+/g, '') // Убираем внешние кавычки и экранирование
    .replace(/^\[|]$/g, '{') // Заменяем квадратные скобки на фигурные
    .replace(/""/g, '"') // Исправляем лишние двойные кавычки
    .replace(/color":/g, 'color":') // Убираем лишние кавычки у ключей
    .replace(/backgroundColor":/g, 'backgroundColor":') // Убираем лишние кавычки у ключей
    .replace(/(\{\s*\})|\{\s*\}$/, '') // Удаляем пустые фигурные скобки и лишние фигурные скобки в конце
    .replace(/}$/, '}') // Удаляем лишние фигурные скобки в конце, если они есть
    .replace(/([^}])$/, '$1}') // Закрываем фигурную скобку, если она отсутствует

  // Убираем лишние пробелы в начале и в конце
  arrayPriorityStr = arrayPriorityStr?.trim()

  // Удаляем лишнюю фигурную скобку в конце
  arrayPriorityStr = arrayPriorityStr?.replace(/\{\}\s*$/, '')

  // Проверяем и добавляем закрывающую фигурную скобку, если ее нет
  if (!arrayPriorityStr?.endsWith('}')) {
    arrayPriorityStr += '}'
  }

  // Удаляем кавычки, если они окружают строку
  if (arrayPriorityStr?.startsWith('"') && arrayPriorityStr?.endsWith('"')) {
    arrayPriorityStr = arrayPriorityStr.slice(1, -1)
  }

  // Попробуем распарсить строку в объект
  let arrayPriority = null
  try {
    arrayPriority = JSON?.parse(arrayPriorityStr)
  } catch (error) {
    console.error('Ошибка при парсинге JSON:', error)
  }

  // Если arrayPriority корректно спарсился, извлекаем значения
  const color = arrayPriority ? arrayPriority.color || '#404542' : '#404542'
  const updatedAt = dayjs(date)
  const now = dayjs()
  const differenceInDays = now.diff(updatedAt, 'day')
  if (differenceInDays > 30) {
    return 'gray'
  }
  return color
}

export function newFilterString(inputString) {
  if (inputString) {
    return inputString.replace(/&union\[without_store\]=(true|false)&?/, '')
  } return inputString
}
