/* eslint-disable indent */
/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import {
 selectChatPanelHeaders, selectDialogs, selectLoading, selectCandidate
} from '../selectors'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { TClassName } from '../../../common/types/props'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ChatCard from './ChatCard'
import ArrowLeftMD from '../../../common/svg/ArrowLeftMD'
import ArrowRightMD from '../../../common/svg/ArrowRightMD'
import ArrowCaretDownMD from '../../../common/svg/ArrowCaretDownMD'
import {
 getDialogs, getCandidate, setCandidate, setDialogs
} from '../actions'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import './CandidateListChat.scss'
import { selectMessageWhatsApp } from '../../../compositions/InnerRouter/selectors'
import { setMessageWhatsApp } from '../../../compositions/InnerRouter/actions'

const mainCssClass = getCssClassName('candidate-list-chat')
// type ChatData = {
//     fullName: string;
//     avatar: string;
//     messages: string[];
// }

const CandidatesListChat: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const [isFullView, setIsFullView] = useState<boolean>(false)
  const dialogs = useSelector(selectDialogs)
  const scrollbarsRef = useRef(null)
  const loading = useSelector(selectLoading)
  const headers = useSelector(selectChatPanelHeaders)
  const message = useSelector(selectMessageWhatsApp)
  const candidate = useSelector(selectCandidate)
  const [updateDialogs, setUpdateDialogs] = useState<any[]>(dialogs)
  const isHaveNextPage = useMemo(() => headers && headers.current && headers?.current < headers?.total, [headers])


  const handleClick = () => {
    setIsFullView((prev) => !prev)
  }

  const handleClickPaginate = () => {
    if (isHaveNextPage) {
      dispatch(getDialogs({ page: headers.current + 1 }))
    }
  }

  useEffect(() => {
    if (updateDialogs && message) {
      const isHasNewDialogs = updateDialogs.find((item) => item.candidate_id === message.candidate_id)
      if (isHasNewDialogs) {
        const newDialogs = updateDialogs.map((item) => (item.candidate_id === message.candidate_id ? { ...item, unread_count: message.unread_count, messagePreview: message.message } : item))
        setUpdateDialogs(newDialogs)
        dispatch(setMessageWhatsApp(null))
      } else if (!candidate) {
        dispatch(getCandidate(message.candidate_id))
      } else if (candidate && !isHasNewDialogs) {
        const newDialog = {
          candidate_id: message.candidate_id,
          unread_count: message.unread_count,
          messagePreview: message.message,
          fio: candidate?.last_name
            ? `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`
            : `${candidate?.name} ${candidate?.second_name || ''}`,
          avatar: candidate.photo
        }
        setUpdateDialogs((prev) => {
          const isDialogExists = prev.some((dialog) => dialog.candidate_id === newDialog.candidate_id)

          if (isDialogExists) {
            return prev.map((dialog) => (dialog.candidate_id === newDialog.candidate_id ? { ...dialog, messagePreview: message.message, unread_count: message.unread_count } : dialog))
          }
            return [...prev, newDialog]
        })
        dispatch(setMessageWhatsApp(null))
        dispatch(setCandidate(null))
        dispatch(setDialogs([]))
      }
    }
  }, [message, candidate])

  useEffect(() => {
    if (dialogs?.length && headers?.current === 1 && !message) {
      setUpdateDialogs(dialogs)
      dispatch(setDialogs([]))
    } else if (dialogs?.length && headers?.current > 1) {
      setUpdateDialogs([...dialogs, ...updateDialogs])
      dispatch(setDialogs([]))
    }
  }, [dialogs, updateDialogs, headers, message])

  return (
    <div
      className={cn(`${mainCssClass}__scroll-div`, {
        [`${mainCssClass}__scroll-div--expanded`]: isFullView,
        [`${mainCssClass}__scroll-div--collapsed`]: !isFullView
      })}
    >
      {isFullView ? <ArrowLeftMD onClick={handleClick} /> : <ArrowRightMD onClick={handleClick} />}
      <div className={cn(`${mainCssClass}__chat-list`)}>
        {isFullView ? (
          <NewScrollContainer
            className={`${mainCssClass}__scroll-container`}
          // onScroll={handleScroll}
            scrollbarsRef={scrollbarsRef}
          >
            {loading && <Preloader size="sm" />}
            {!loading
            && (
            <div className={cn(`${mainCssClass}__chat-list_content`)}>
              {updateDialogs.map((item, index) => (
                <ChatCard key={index} item={item} isFullView={isFullView} />
            ))}
              {!updateDialogs.length && (
              <div>
                Диалогов нет
              </div>
              )}
              {isHaveNextPage && (
              <div
                onClick={handleClickPaginate}
                className={`${mainCssClass}__download`}
              >
                Загрузить еще
              </div>
              )}
              {!isFullView && isHaveNextPage && <ArrowCaretDownMD />}

            </div>
              )}
          </NewScrollContainer>
        ) : (
          <div className={cn(`${mainCssClass}__chat-list_content-expanded`)}>
            {updateDialogs?.map((item, index) => (
              <ChatCard item={item} isFullView={isFullView} key={index} />
            ))}
            {!isFullView && isHaveNextPage && <ArrowCaretDownMD />}
          </div>
        )}
      </div>
    </div>
  )
}

export default CandidatesListChat
