import axios, { AxiosResponse } from 'axios'
import { method } from 'lodash'
import $api, { API_URL } from './http'
import { TRequestMailMessages, TRequestSendMailMessage } from '../../features/chat/actions'

export type TRequestMessages = {
  page?: number
  phone?: string
}

export type TResponseMessages = {
  requestId: string
  id: number
  userInfo: {
    userName: string
    avatarUrl: string
  }
  messageContent: {
    text: string
  }
  direction: string
  created_at: number
  identifier: string
  status: string
}

export type TRequestSendMessage = {
  phone: number
  message: string
}

export async function fetchGetMessages(payload: TRequestMessages): Promise<AxiosResponse<TResponseMessages[]>> {
  const response = await $api.get(
    `/api/v1/whatsapp/message?phone=${payload.phone}&per-page=20&page=${payload?.page || 1}`
  )
  return response
}

export async function fetchPostSendMessage(payload: TRequestSendMessage): Promise<AxiosResponse<TResponseMessages>> {
  const response = await $api.post('/api/v1/whatsapp/send', payload)
  return response
}


export async function fetchGetMailMessages(payload: TRequestMailMessages): Promise<AxiosResponse<TResponseMessages[]>> {
  const response = await $api.get(
    `/api/v1/mail/get-list?identifier=${payload.identifier}&page=${payload?.page}&per_page=${payload.perPage || 20}`
  )
  return response
}


export async function fetchPostSendMailMessage(payload: TRequestSendMailMessage): Promise<AxiosResponse<TResponseMessages>> {
  const response = await $api.post('/api/v1/mail/send', payload)
  return response
}


export type TRequestReadMessage = {
    requestId: string,
    status: string
}

export async function fetchReadMessage(payload: TRequestReadMessage): Promise<AxiosResponse<any>> {
  const response = await $api.post('/integration/v1/whatsapp/status', payload)
  return response
}

