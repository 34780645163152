import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Typography } from 'antd'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  selectCallbackForm,
  selectCallbacks,
  selectDefDataCallback,
  selectHeadersCallback,
  selectLoading
} from '../selectors'
import {
  getCallbacks,
  openCallbackSettingForm,
  reset,
  setActiveCallback,
  setDefDataCallback,
  updateCallback
} from '../actions'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import ControlButton from '../../../common/components/Buttons/Button'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import Archive from '../../../common/svg/Archive'
import Redo from '../../../common/svg/Redo'
import CallBackSettingForm from './CallBackSettingForm'
import './CallBackSetting.scss'

const mainCssClass = getCssClassName('callback-setting')

export type TFormValues = {
  perPagesCount: number
}

function CallBackSetting() {
  const dispatch = useDispatch()
  const callbackForm = useSelector(selectCallbackForm)
  const templates = useSelector(selectCallbacks)
  const loading = useSelector(selectLoading)
  const defData = useSelector(selectDefDataCallback)

  const headers = useSelector(selectHeadersCallback)
  const [page, setPage] = useState<number>(1)

  const { rbacControl } = useEntityContext()

  const { control, handleSubmit, getValues, watch, setValue } = useForm<TFormValues>({
    defaultValues: { perPagesCount: defData?.perPage || 10 }
  })

  const perPage = watch('perPagesCount')

  const handleEditTemplates = (item: any) => {
    dispatch(setActiveCallback(item))
    dispatch(openCallbackSettingForm(true))
  }

  const handleArchiveCallback = (id: number) => {
    dispatch(updateCallback({ id, is_archive: true }))
  }

  const handleReturnArchive = (id: number) => {
    dispatch(updateCallback({ id, is_archive: false }))
  }

  const handleNextPage = useCallback(() => {
    if (page < headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(setDefDataCallback({ page, perPage }))
  }, [perPage, page])

  useEffect(() => {
    if (defData) {
      dispatch(getCallbacks({ page, perPage }))
    }
  }, [defData])

  return (
    <div className={mainCssClass}>
      {!callbackForm && (
        <div className={`${mainCssClass}_create`}>
          <div className={`${mainCssClass}_create_title`}>
            <Typography.Title level={3}>Callback</Typography.Title>

            {!loading && (
              <div className={`${mainCssClass}__pagination-container`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  control={control}
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM
                    className="arrowCursor"
                    size="1"
                    onClick={handlePreviousPage}
                    fill={page === 1 ? '#BFC5C0' : '#404542'}
                  />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination}
                  </Typography.Text>
                  <ArrowLeftSM
                    className="arrowCursor"
                    size="1"
                    onClick={handleNextPage}
                    fill={headers && page >= headers?.total ? '#BFC5C0' : '#404542'}
                  />
                </div>
              </div>
            )}
          </div>

          {loading && <Preloader size="md" />}
          {!loading && (
            <div className={`${mainCssClass}_create_action`}>
              <ControlButton
                typeButton="secondary"
                size="large"
                icon="left"
                className="button"
                onClick={() => dispatch(openCallbackSettingForm(true))}
              >
                <AddPlusSvg />
                Создать callback
              </ControlButton>
            </div>
          )}

          {!loading && (
            <div className={`${mainCssClass}_create_scrollContainer`}>
              <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
                <div className={`${mainCssClass}_create_templates-list`}>
                  {templates?.map((item) => (
                    <div
                      className={cn(`${mainCssClass}_create_templates-list_item`, {
                        [`${mainCssClass}_create_templates-list_item_archive`]: item.is_archive
                      })}
                      key={item.id}
                    >
                      <div className={`${mainCssClass}_create_templates-list_item_main`}>
                        <div className={`${mainCssClass}_create_templates-list_item_text`}>
                          <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                            Событие: {item.event_label}
                          </Typography.Text>
                          <Typography.Text className={`${mainCssClass}_create_templates-list_item_text_title`}>
                            {item.lid_utm_label || 'Не назначен'}
                          </Typography.Text>
                        </div>
                        <div className={`${mainCssClass}_create_templates-list_item_action`}>
                          {!item.is_archive ? (
                            <>
                              <ControlButton
                                typeButton="secondary"
                                className="button"
                                onClick={() => handleEditTemplates(item)}
                              >
                                <EditPencil1 />
                              </ControlButton>
                              <ControlButton
                                typeButton="secondary"
                                className="button"
                                onClick={() => handleArchiveCallback(item.id)}
                              >
                                <Archive />
                              </ControlButton>
                            </>
                          ) : (
                            <ControlButton
                              typeButton="secondary"
                              className="button"
                              onClick={() => handleReturnArchive(item.id)}
                            >
                              <Redo />
                            </ControlButton>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </NewScrollContainer>
            </div>
          )}
        </div>
      )}

      {callbackForm && <CallBackSettingForm />}
    </div>
  )
}
export default memo(withErrorBoundary(CallBackSetting))
