import axios, { AxiosResponse } from 'axios'
import { TAuth } from '../../compositions/auth/types'
import $api, { API_URL } from './http'

axios.defaults.withCredentials = true
export async function fetchPostAuth(payload: { email: string; password: string }): Promise<AxiosResponse<TAuth>> {
  const response = await $api.post<TAuth>('/api/v1/auth/login', {
    ...payload
  })

  return response
}

export async function fetchGetLogout(): Promise<AxiosResponse<any>> {
  const response = await $api.get('/api/v1/auth/logout')
  return response
}
