/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'
import { setIsLoadingRespondHandler, setStageStatus } from './actions'

const selectState = ({ candidate }: TRootState) => candidate

export const selectCandidate = createSelector(selectState, ({ candidate }) => candidate)
export const selectStatus = createSelector(selectState, ({ candidate }) => candidate?.status)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)
export const selectIsOpenArchive = createSelector(selectState, ({ archiveModal }) => archiveModal)

export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})

export const selectTypeById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.type : null
})

export const selectOpenCreateModal = createSelector(selectState, ({ createModal }) => createModal)

export const selectOpenUpdateModal = createSelector(selectState, ({ updateModal }) => updateModal)

export const selectOpenSureModal = createSelector(selectState, ({ sureModal }) => sureModal)

export const selectOpenStatusModal = createSelector(selectState, ({ statusModal }) => statusModal)

export const selectEmptySlots = createSelector(
  selectState,
  ({ emptySlots }) => emptySlots?.map((item: string) => ({ value: item, label: item })) || []
)

export const selectOpenVacancyModal = createSelector(selectState, ({ vacancyModal }) => vacancyModal)

export const selectNewInterview = createSelector(selectState, ({ newInterview }) => newInterview)

export const selectQuestionnaire = createSelector(selectState, ({ questionnaire }) => questionnaire)
export const selectIsEditQuestionnaire = createSelector(selectState, ({ isEditQuestionnaire }) => isEditQuestionnaire)

export const selectOpenRecriuterModal = createSelector(selectState, ({ recruiterModal }) => recruiterModal)
export const selectOpenReseacherModal = createSelector(selectState, ({ reseacherModal }) => reseacherModal)
export const selectStageStatusModal = createSelector(selectState, ({ stageStatusModal }) => stageStatusModal)

export const selectCandidateComments = createSelector(selectState, ({ comments }) => comments)

export const selectCommentsPage = createSelector(selectState, ({ commentsPage }) => commentsPage)
export const selectOpenModalMap = createSelector(selectState, ({ openModalMap }) => openModalMap)

export const selectHistoryCandidateData = createSelector(selectState, ({ historyAction }) => historyAction)

export const selectOldHistoryCandidateData = createSelector(selectState, ({ oldHistoryAction }) => oldHistoryAction)
export const selectOpenHistoryCandidateData = createSelector(selectState, ({ historyModal }) => historyModal)
export const selectTotalCommentPage = createSelector(selectState, ({ totalCommentPage }) => totalCommentPage)
export const selectCandidateStageState = createSelector(selectState, ({ stageStatus }) => stageStatus?.map((item) => ({ label: item.name, value: item.id })) || [])

export const selectChatMobil = createSelector(selectState, ({ openChat }) => openChat)

export const selectChatVisible = createSelector(selectState, ({ chatVisible }) => chatVisible)
export const selectHistoryVisible = createSelector(selectState, ({ historyVisible }) => historyVisible)
export const selectRejectModal = createSelector(selectState, ({ rejectModal }) => rejectModal)
export const selectAssignModal = createSelector(selectState, ({ assignModal }) => assignModal)
export const selectStageModal = createSelector(selectState, ({ stageModal }) => stageModal)

export const selectPhoneError = createSelector(selectState, ({ phoneError }) => phoneError)

export const selectInnError = createSelector(selectState, ({ innError }) => innError)
export const selectEmailError = createSelector(selectState, ({ emailError }) => emailError)

export const selectInformationModal = createSelector(selectState, ({ informationModal }) => informationModal)
export const selectLoadingSlots = createSelector(selectState, ({ loadingSlots }) => loadingSlots)

export const selectSelectedCommet = createSelector(selectState, ({ selectedComment }) => selectedComment)
export const selectCommentFile = createSelector(selectState, ({ commentFile }) => commentFile)
export const selectDeleteCommentModal = createSelector(selectState, ({ deleteCommentModal }) => deleteCommentModal)
export const selectCommentModal = createSelector(selectState, ({ commentModal }) => commentModal)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectDuplicateModal = createSelector(selectState, ({ duplicateModal }) => duplicateModal)
export const selectDuplicateList = createSelector(selectState, ({ duplicateList }) => duplicateList)

export const selectResponds = createSelector(selectState, ({ responds }) => responds)
export const selectIsLoadingRespondHandler = createSelector(selectState, ({ isLoadingRespondHandler }) => isLoadingRespondHandler)

export const selectMergeDuplicateModal = createSelector(selectState, ({ mergeDuplicateModal }) => mergeDuplicateModal)
export const selectMergeDuplicateList = createSelector(selectState, ({ mergeDuplicateList }) => mergeDuplicateList)

export const selectHistoryVariable = createSelector(selectState, ({ historyVariable }) => historyVariable)

export const selectHistoryAsterisk = createSelector(selectState, ({ asteriskHistory }) => asteriskHistory)
export const selectTasksModal = createSelector(selectState, ({ tasksModal }) => tasksModal)

export const selectOpenLimitationStageCandidate = createSelector(selectState, ({ openLimitationStageCandidateModal }) => openLimitationStageCandidateModal)

export const selectMessageLimitationCandidate = createSelector(selectState, ({ messageLimitationCandidate }) => messageLimitationCandidate)

export const selectCandidateCoordinates = createSelector(selectState, ({ candidateCoordinates }) => candidateCoordinates)

export const selectListingStages = createSelector(selectState, ({ stagesListing }) => stagesListing)

export const selectResultInternshipModal = createSelector(selectState, ({ resultInternshipModal }) => resultInternshipModal)

export const selectDocumentList = createSelector(selectState, ({ document_list }) => document_list)

export const selectCandidateQuestionnaire = createSelector(selectState, ({ candidateQuestionnaire }) => candidateQuestionnaire)

export const selectCheckDocModal = createSelector(selectState, ({ checkDocModal }) => checkDocModal)

export const selectCandidateHistoryV2 = createSelector(selectState, ({ candidateHistoryV2 }) => candidateHistoryV2)

export const selectChatDrawer = createSelector(selectState, ({ chatDrawer }) => chatDrawer)

export const selectAsteriskDrawer = createSelector(selectState, ({ asteriskDrawer }) => asteriskDrawer)

export const selectCommunicationDrawer = createSelector(selectState, ({ communicationDrawer }) => communicationDrawer)

export const selectEditCandidateDrawer = createSelector(selectState, ({ editCandidateDrawer }) => editCandidateDrawer)

export const selectUpdateCandidateMode = createSelector(selectState, ({ updateCandidateMode }) => updateCandidateMode)