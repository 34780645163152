import React from 'react'
import Icon from '../svgIcons/Map.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function MapSvg(props: IconProps) {
  return (
    <IconWrapper {...props} name="Map">
      <Icon />
    </IconWrapper>
  )
}
