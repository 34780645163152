import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { TOption } from '../../../common/types/dictionaries'
import './ModalArchiveRequest.scss'
import { archiveStatusRequest } from '../actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectDictionaryOptionsByName } from '../../../compositions/candidate/selectors'
import { setOpenModalArchiveRequest } from '../../../compositions/ManagementRequests/actions'
import { getDictionaries } from '../../../compositions/Request/actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'archive-request'

type TFormValues = {
  reason: string
  comment: string
}

export type TProps = {
  options: TOption[]
  requestId?: number | null | string
  isRequestTable: boolean
} & ModalProps
function ArchiveCandidateModal({
  open, className, onCancel, options, requestId, isRequestTable
}: TProps) {
  const dispatch = useDispatch()

  const {
    control, watch, getValues, handleSubmit
  } = useForm<TFormValues>()

  const onSubmit = useCallback(() => {
    if (requestId) {
      if (isRequestTable) {
        dispatch(setOpenModalArchiveRequest(false))
      }
      dispatch(
        archiveStatusRequest({
          data: {
            status: 650,
            id: requestId,
            ...getValues(),
            isRequestTable
          },
          isRequestTable
        })
      )
    }
  }, [requestId, isRequestTable])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Архивировать заявку?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="warning"
      okText="Архивировать"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <SelectFormControl
          name="reason"
          control={control}
          placeholder="Выберите причину"
          options={options}
          required
          label="Причина"
          rules={{ required: true }}
        />
        <TextAreaFormControl name="comment" control={control} label="Комментарий" required rules={{ required: true }} />
      </div>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ArchiveCandidateModal))
