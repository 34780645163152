import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useFieldArray, useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CloseMD from '../../../common/svg/CloseMD'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName } from '../selectors'
import { createLanguageResume, createRecomendationResume, getDictionaries } from '../actions'
import './ResumeModal.scss'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  language_list: { language: number | string; level: number | string }[]
}

function ModalLanguage({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const languageOptions = useSelector(selectDictionaryOptionsByName('language')) || []
  const languageLevelOptions = useSelector(selectDictionaryOptionsByName('languageLevel')) || []

  const defaultValues = useMemo(
    () => (editMode && resume?.language && resume?.language.length > 0
      ? {
        language_list: resume?.language.map((e) => ({
          language: e.language,
          level: e.level
        }))
      }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray<TFormValues, 'language_list'>({
    control,
    name: 'language_list'
  })

  const onSubmit = useCallback(() => {
    dispatch(
      createLanguageResume({
        resume_id: resumeId,
        language_list: getValues().language_list
      })
    )
  }, [])

  if (fields?.length === 0) {
    prepend({
      language: '',
      level: ''
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Языки"
      className={cn(className, mainCssClass)}
      typeButtonAccept="primary"
      okText="Сохранить"
      centered
      width="500px"
      bodyStyle={{ padding: '0', margin: 0 }}
      cancelText="Отменить"
      maskClosable={false}
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_languageContainer`}>
        <NewScrollContainer className={`${mainCssClass}_scroll`} style={{ width: '470px' }}>
          <div className={`${mainCssClass}_list`} style={{ width: '450px' }}>
            {fields?.map((rec, index) => (index === 0 ? (
              <div className={`${mainCssClass}_items`}>
                <SelectFormControl
                  label="Язык"
                  name={`language_list[${index}].language`}
                  control={control}
                  rules={{ required: true }}
                  options={languageOptions}
                />
                <SelectFormControl
                  label="Уровень"
                  name={`language_list[${index}].level`}
                  control={control}
                  rules={{ required: true }}
                  options={languageLevelOptions}
                />
              </div>
            ) : (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_dop`}>
                  <SelectFormControl
                    label="Язык"
                    name={`language_list[${index}].language`}
                    control={control}
                    rules={{ required: true }}
                    options={languageOptions}
                  />

                  <Button className={`${mainCssClass}_items_dop_close`} onClick={() => handleRemove(index)}>
                    <CloseMD />
                  </Button>
                </div>

                <SelectFormControl
                  label="Уровень"
                  name={`language_list[${index}].level`}
                  control={control}
                  rules={{ required: true }}
                  options={languageLevelOptions}
                />
              </div>
            )))}
            <Button onClick={() => append({ language: '', level: '' })} className={`${mainCssClass}_items_add`}>
              <AddPlusSvg />
              Добавить язык
            </Button>
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalLanguage))
