import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ request }: TRootState) => request

export const selectRequest = createSelector(selectState, ({ request }) => request)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectOpenArchiveModal = createSelector(selectState, ({ requestArchiveModal }) => requestArchiveModal)
export const selectOpenUpdateModal = createSelector(
  selectState,
  ({ requestUpdateStatusModal }) => requestUpdateStatusModal
)

export const selectOpenRejectModal = createSelector(selectState, ({ requestRejectModal }) => requestRejectModal)

export const selectOpenCreateRequestModal = createSelector(selectState, ({ createRequestModal }) => createRequestModal)

export const selectOpenEditModal = createSelector(selectState, ({ editRequestModal }) => editRequestModal)

export const selectOpenEditCustomerModal = createSelector(selectState, ({ editRequestCustomerModal }) => editRequestCustomerModal)

export const selectCustomerValue = createSelector(selectState, ({ customerValue }) => customerValue)
export const selectSaveCustomerModal = createSelector(selectState, ({ saveCustomerRequest }) => saveCustomerRequest)
export const selectSureRequestModal = createSelector(selectState, ({ sureRequestModal }) => sureRequestModal)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectHistoryRequestData = createSelector(selectState, ({ historyAction }) => historyAction)

export const selectOpenHistoryRequestData = createSelector(selectState, ({ historyModal }) => historyModal)

export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})
export const selectCandidateOfRequest = createSelector(selectState, ({ requestRemoveData }) => requestRemoveData)
export const selectOpenRemoveCandidateofRequest = createSelector(selectState, ({ openRequestRemove }) => openRequestRemove)

export const selectRequestHistoryHeaders = createSelector(selectState, ({ headers }) => headers)
export const selectRequestHistoryLoading = createSelector(selectState, ({ historyLoading }) => historyLoading)
export const selectTabRequest = createSelector(selectState, ({ tabRequest }) => tabRequest)
export const selectRequestHistoryV2 = createSelector(selectState, ({ historyRequestV2 }) => historyRequestV2)
