import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  Button, Typography, Input, Radio, Tooltip, DatePicker
} from 'antd'
import type { RadioChangeEvent } from 'antd'
import CloseMD from '../../../common/svg/CloseMD'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  selectDictionaryOptionsByName,
  selectEmptySlots,
  selectFilterString,
  selectHolidays,
  selectReserveSlots
} from '../selectors'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import './SettingsCalendarMobil.scss'
import Redo from '../../../common/svg/Redo'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import {
  createReserveSlot, deleteReserveSlot,
  getDictionaries,
  getEmptySlotByUser,
  getHolidays,
  getReserveSlotsUser,
  openСalendarSettings,
  updateCalendarSettings,
  updateUserCalendarSettings
} from '../actions'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import {
  DATE_DOTS_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_SECONDS_FORMAT,
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
  SERVER_DATE_TIME_SECOND_FORMAT,
  SERVER_TIME_FORMAT
} from '../../../common/utils/dateFormats'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ControlButton from '../../../common/components/Buttons/Button'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { OpenSettingsCalendarMobil } from '../../../compositions/InnerRouter/actions'
import { selectOpenSettingsCalendar } from '../../../compositions/InnerRouter/selectors'

type TFormValues = {
    status: number
    date_status: string
    slot: string
    schedule_id_list?: number
    time_day: string[]
    date_vacation: string
    date_sick: string
    schedule_date: string
}

const mainCssClass = getCssClassName('settings-calendar-mobil')

function SettingsCalendarMobil() {
  const dispatch = useDispatch()
  const sсheduleOptions = useSelector(selectDictionaryOptionsByName('scheduleRecruiter'))
  const dayStatus = [{ value: 1, label: 'Нерабочий' }, { value: 0, label: 'Рабочий' }]
  const holidays = useSelector(selectHolidays)
  const emptySlots = useSelector(selectEmptySlots)
  const reserveSlots = useSelector(selectReserveSlots)
  const openSettingsModal = useSelector(selectOpenSettingsCalendar)
  const { calendar_settings, role } = useEntityContext()
  const defaultValues: TFormValues = {
    schedule_id_list: calendar_settings?.graphic_option_id,
    time_day: [dayjs(new Date(calendar_settings?.begin_time_at)), dayjs(new Date(calendar_settings?.end_time_at))]
  }
  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TFormValues>({ defaultValues })

  const status = watch('status')
  const dateStatus = watch('date_status')
  const slot = watch('slot')
  const scheduleId = watch('schedule_id_list')
  const timeDay = watch('time_day')
  const dateVacation = watch('date_vacation')
  const dateSick = watch('date_sick')
  const scheduleDate = watch('schedule_date')

  const disabledSubmitStatus = useMemo(() => !((dateStatus && status !== undefined)), [status, dateStatus])
  const disabledSubmitSlot = useMemo(() => !slot, [slot])
  const disabledSubmitScheduleList = useMemo(() => !scheduleId, [scheduleId])
  const disabledSubmitTimeDay = useMemo(() => !timeDay, [timeDay])
  const disabledSubmitDateVacation = useMemo(() => !dateVacation, [dateVacation])

  const handleApply = useCallback(() => {

  }, [])

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  const handleCloseFilters = useCallback(() => {
    dispatch(openСalendarSettings(false))
  }, [])
  const handleUpdateCalendarGraphic = useCallback(() => {
    if (calendar_settings && scheduleId && scheduleDate) {
      dispatch(updateUserCalendarSettings(
        {
          user_id: calendar_settings.user_id,
          graphic_option_id: scheduleId,
          recalculate_at: dayjs(scheduleDate).format(SERVER_DATE_TIME_SECOND_FORMAT)
        }
      ))
    }
  }, [calendar_settings, scheduleId, scheduleDate])

  const handleUpdateCalendarTime = useCallback(() => {
    if (calendar_settings && timeDay) {
      const newDate = dayjs(new Date()).format(SERVER_DATE_FORMAT)
      dispatch(updateUserCalendarSettings({
        user_id: calendar_settings.user_id,
        begin_time_at: `${newDate} ${dayjs(timeDay[0]).format(SERVER_TIME_FORMAT)}`,
        end_time_at: `${newDate} ${dayjs(timeDay[1]).format(SERVER_TIME_FORMAT)}`
      }))
    }
  }, [calendar_settings, timeDay])

  const handleUpdateVacation = useCallback(() => {
    if (calendar_settings && dateVacation) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(dateVacation[0]).format(SERVER_DATE_FORMAT),
        end_at: dayjs(dateVacation[1]).format(SERVER_DATE_FORMAT),
        user_id: calendar_settings.user_id,
        is_blocked: 1
      }))
    }
  }, [calendar_settings, dateVacation])

  const handleAddStatusDay = useCallback(() => {
    if (calendar_settings && dayStatus && dateStatus) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(dateStatus).format(SERVER_DATE_FORMAT),
        end_at: dayjs(dateStatus).format(SERVER_DATE_FORMAT),
        user_id: calendar_settings.user_id,
        is_blocked: status
      }))
    }
  }, [calendar_settings, status, dateStatus])

  const handleAddReserveSlot = useCallback(() => {
    if (calendar_settings && slot) {
      dispatch(createReserveSlot({
        time_interval: slot,
        user_id: calendar_settings.user_id,
        date: dayjs(new Date())
          .format(SERVER_DATE_FORMAT)
      }))
    }
  }, [calendar_settings, slot])

  const handleDeleteDays = useCallback((item) => {
    if (calendar_settings) {
      dispatch(updateCalendarSettings({
        begin_at: dayjs(item.begin_at)
          .format(SERVER_DATE_FORMAT),
        end_at: dayjs(item.end_at)
          .format(SERVER_DATE_FORMAT),
        user_id: calendar_settings.user_id,
        is_blocked: 0,
        calendar_id: item.calendar_id
      }))
    }
  }, [calendar_settings])

  const handleDeleteReserve = useCallback((item) => {
    dispatch(deleteReserveSlot({
      user_id: item.user_id,
      reserve_slot_id: item.id
    }))
  }, [])

  useEffect(() => {
    dispatch(getDictionaries(['scheduleRecruiter', 'daystatus']))
  }, [])

  useEffect(() => {
    if (calendar_settings?.user_id) {
      dispatch(getEmptySlotByUser({
        userId: calendar_settings?.user_id,
        date: dayjs(new Date())
          .format(SERVER_DATE_FORMAT)
      }))
      dispatch(getReserveSlotsUser({
        userId: calendar_settings?.user_id,
        date: dayjs(new Date())
          .format(SERVER_DATE_FORMAT)
      }))
      dispatch(getHolidays())
    }
  }, [calendar_settings])
  const handleModalClose = () => {
    dispatch(OpenSettingsCalendarMobil(false))
  }
  return (
    <CommonModal
      open={openSettingsModal}
      onCancel={handleModalClose}
      closable
      width="100%"
    >

      <div className={mainCssClass}>
        <ScrollContainer className={`${mainCssClass}__scroll-container`}>
          <div className={`${mainCssClass}__content`}>
            <div className={`${mainCssClass}_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={3}>Настройки</Typography.Title>
              </div>
              <Button onClick={handleModalClose}>
                <CloseMD />
              </Button>
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Установить статус на день
              </Typography.Title>
              <DatePickerFormControl control={control} name="date_status" />
              <SelectFormControl
                control={control}
                name="status"
                label="Статус"
                placeholder="Выберите статус"
                options={dayStatus}
              />
              <ControlButton className="button" size="large" typeButton="primary" state="disabled" disabled={disabledSubmitStatus} onClick={handleAddStatusDay}>Применить</ControlButton>
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Добавить резерв на сегодня
              </Typography.Title>
              <SelectFormControlMult
                control={control}
                name="slot"
                label="Слот"
                placeholder="Выберите слот времени"
                options={emptySlots}
              />
              <ControlButton className="button" size="large" typeButton="primary" state="disabled" disabled={disabledSubmitStatus} disabled={disabledSubmitSlot} onClick={handleAddReserveSlot}>Применить</ControlButton>
              {reserveSlots && !!reserveSlots?.length && reserveSlots.map((item) => (
                <div className={`${mainCssClass}__content-delete`}>
                  <Typography.Paragraph className={`${mainCssClass}__labels-delete`}>
                    {item.time_interval}
                  </Typography.Paragraph>
                  <Button icon={<CloseMD />} onClick={() => handleDeleteReserve(item)} className={`${mainCssClass}__delete-button`} />
                </div>
              ))}
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Мой график работы
              </Typography.Title>
              <SelectFormControl
                control={control}
                name="schedule_id_list"
                label="График"
                options={sсheduleOptions}
              />
              <DatePickerFormControl control={control} name="schedule_date" label="Выберите дату" />
              <ControlButton
                className="button"
                size="large"
                typeButton="secondary"
                state="disabled"
                disabled={disabledSubmitScheduleList}
                onClick={handleUpdateCalendarGraphic}
              >
                Сохранить
              </ControlButton>
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Рабочее время
              </Typography.Title>
              <RangePickerFormControl
                control={control}
                name="time_day"
                label="Выберите время"
                // @ts-ignore
                placeholder={['Начало дня', 'Конец дня']}
                picker="time"
                isNeedRulesTime
                className={`${mainCssClass}_items_content_range`}
                format="HH:mm"
                isTime
              />

              <ControlButton
                className="button"
                size="large"
                typeButton="secondary"
                state="disabled"
                disabled={disabledSubmitTimeDay}
                onClick={handleUpdateCalendarTime}
              >
                Добавить
              </ControlButton>
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Нерабочие дни
              </Typography.Title>
              <RangePickerFormControl
                control={control}
                format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                name="date_vacation"
                // @ts-ignore
                placeholder={['Начало', 'Конец']}
                label="Выберите даты"
                className={`${mainCssClass}_items_content_range`}
              />
              <ControlButton
                className="button"
                size="large"
                typeButton="primary"
                state="disabled"
                disabled={disabledSubmitDateVacation}
                onClick={handleUpdateVacation}
              >
                Добавить
              </ControlButton>
              {holidays && !!holidays?.length && holidays.map((item) => (
                <div className={`${mainCssClass}__content-delete`}>
                  <Typography.Paragraph className={`${mainCssClass}__labels-delete`}>
                    {`${dayjs(item.begin_at).format(DATE_DOTS_FORMAT)}-${dayjs(item.end_at).format(DATE_DOTS_FORMAT)}`}
                  </Typography.Paragraph>
                  <Button icon={<CloseMD />} onClick={() => handleDeleteDays(item)} className={`${mainCssClass}__delete-button`} />
                </div>
              ))}
            </div>
            <div className={`${mainCssClass}__block-items`}>
              <Typography.Title level={5}>
                Перерывы
              </Typography.Title>
              <DatePickerFormControl control={control} name="time_stop_date" label="Выберите дату" />

              <ControlButton
                className="button"
                size="large"
                typeButton="secondary"
                state="disabled"
                disabled={disabledSubmitTimeDay}
                onClick={handleUpdateCalendarTime}
              >
                Добавить
              </ControlButton>
            </div>
          </div>
        </ScrollContainer>
      </div>
    </CommonModal>
  )
}

export default SettingsCalendarMobil
