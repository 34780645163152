import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ managementCandidate }: TRootState) => managementCandidate

export const selectCandidates = createSelector(selectState, ({ candidates }) => (candidates ? candidates?.candidate_list : []))

export const selectResultCandidateCount = createSelector(selectState, ({ candidates }) => {
  const { count } = candidates?.counters?.find((item) => item.id === 'result') || {}
  return count || 0
})

export const selectIsOpenChangeStatus = createSelector(
  selectState,
  ({ openModalChangeStatus }) => openModalChangeStatus
)

export const selectIsOpenArchive = createSelector(selectState, ({ openModalArchive }) => openModalArchive)

export const selectIsOpenAssignVacancy = createSelector(
  selectState,
  ({ openModalAssignVacancy }) => openModalAssignVacancy
)
export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectSelectedId = createSelector(selectState, ({ selectedCandidateId }) => selectedCandidateId)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectDefDataCandidate = createSelector(selectState, ({ defDataCandidate }) => defDataCandidate)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectChatMobil = createSelector(selectState, ({ openChat }) => openChat)

export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})

export const selectSelectedStatus = createSelector(selectState, ({ selectedStatus }) => selectedStatus)

export const selectOpenModalMassStage = createSelector(selectState, ({ openModalMassStage }) => openModalMassStage)

export const selectDashboard = createSelector(selectState, ({ dashboard }) => dashboard)
export const selectDashboardFilter = createSelector(selectState, ({ dashboardFilter }) => dashboardFilter)

export const selectLoadingDashboard = createSelector(selectState, ({ loadingDashboard }) => loadingDashboard)
export const selectDisabledDashboard = createSelector(selectState, ({ disabledDashboard }) => disabledDashboard)
