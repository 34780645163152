import React, {
  memo, useCallback, useEffect, useMemo
} from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useFieldArray, useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CloseMD from '../../../common/svg/CloseMD'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectActiveResume, selectDictionaryOptionsByName } from '../selectors'
import {
  createLanguageResume, createRecomendationResume, createWishesResume, getDictionaries
} from '../actions'
import './ResumeModal.scss'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'

const mainCssClass = 'resume-modal'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void
  editMode?: boolean
  resumeId: string | number
}

type TFormValues = {
  wages:  string
  position: string
  schedule_list: string | number[]
  employment_list: string | number[]
  currency: number
  specialization_list: { spec: string }[]
}

function ModalWishes({
  onOk, open, className, onCancel, editMode, resumeId
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const resume = useSelector(selectActiveResume)
  const currencyOptions = useSelector(selectDictionaryOptionsByName('currency')) || []
  const scheduleOptions = useSelector(selectDictionaryOptionsByName('schedule')) || []
  const employmentOptions = useSelector(selectDictionaryOptionsByName('employment')) || []

  const defaultValues = useMemo(
    () => (editMode ? {
      wages: String(resume?.wages) || '' ,
      position: resume?.position,
      employment_list: resume.employment_list.map((el) => el.dictionary_option_id),
      schedule_list: resume.schedule_list.map((el) => el.dictionary_option_id),
      currency: resume.currency,
      specialization_list: resume.specialization.split(',').map((el) => ({ spec: el }))
    }
      : {}),
    [resumeId]
  )

  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>({ defaultValues })

  const {
    fields, append, prepend, remove
  } = useFieldArray({
    control,
    name: 'specialization_list'
  })

  const onSubmit = () => {
    const values = getValues()
    const updatedValues = {
      ...values,
      specialization_list: values.specialization_list.map((e) => e.spec),
      wages: Number(values.wages.replace(/\s/g, ""))
    }

    dispatch(
      createWishesResume({
        resume_id: resumeId,
        ...updatedValues
      })
    )
  }

  if (fields?.length === 0) {
    prepend({
      spec: ''
    })
  }

  const handleRemove = (index: number) => {
    remove(index)
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Пожелания кандидата"
      className={cn(className, mainCssClass)}
      typeButtonAccept="primary"
      okText="Сохранить"
      centered
      width="500px"
      maskClosable={false}
      bodyStyle={{ padding: '0', margin: 0 }}
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_coursesContainer`}>
        <NewScrollContainer className={`${mainCssClass}_scroll`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl name="position" control={control} label="Должность" rules={{ required: true }} />
            <div className={`${mainCssClass}_items_courses`}>
              <InputFormControl
                name="wages"
                control={control}
                label="Ожидаемая ЗП"
                rules={{
                  required: true,
                  validate: {
                    isValid: (value) => /^(?!\s*$)[0-9\s]+$/.test(value),
                  },
                }}
              />
              <SelectFormControl
                options={currencyOptions}
                name="currency"
                control={control}
                rules={{ required: true }}
              />
            </div>

            {fields?.map((rec, index) => (index === 0 ? (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_courses`}>
                  <InputFormControl
                    label="Cпециализация"
                    name={`specialization_list[${index}].spec`}
                    control={control}
                    rules={{ required: true }}
                  />
                  <Button onClick={() => append({ spec: '' })} className={`${mainCssClass}_items_addSpec`}>
                    <AddPlusSvg />
                  </Button>
                </div>
              </div>
            ) : (
              <div className={`${mainCssClass}_items`}>
                <div className={`${mainCssClass}_items_dop`}>
                  <InputFormControl
                    label="Специализация"
                    name={`specialization_list[${index}].spec`}
                    control={control}
                    rules={{ required: true }}
                  />

                  <Button className={`${mainCssClass}_items_dop_close`} onClick={() => handleRemove(index)}>
                    <CloseMD />
                  </Button>
                </div>
              </div>
            )))}
            <SelectFormControlMult
              name="employment_list"
              options={employmentOptions}
              control={control}
              label="Занятость"
              rules={{ required: true }}
            />
            <SelectFormControlMult
              name="schedule_list"
              options={scheduleOptions}
              control={control}
              label="График работы"
              rules={{ required: true }}
            />
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalWishes))
