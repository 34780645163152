/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Tabs, Typography } from 'antd'
import {
  getDictionaries,
  getInterview,
  reset,
  setHandleModalEditOpen,
  setHandleModalCancelOpen,
  setHandleModalEndOpen,
  setHandleModalHistoryOpen, getQuestionnaire, setHandleModalQuestionnaireHistoryOpen,
  getCandidateQuestionnaire
} from '../actions'
import {
  selectCandidate,
  selectInterview,
  selectLoading,
  selectHistoryData,
  selectModalCancelOpen,
  selectModalEditOpen,
  selectInterviewId,
  selectModalEndInterviewOpen,
  selectModalHistoryInterviewOpen,
  selectDictionaryOptionsByName, selectModalHistoryQuestionnaireInterviewOpen
} from '../selectors'
import ReturnButton from '../../InnerRouter/components/ReturnButton'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TUrlParams } from '../../InnerRouter/components/InnerRouter'
import HeaderInterview from './HeaderInterview'
import Information from './Information'
import SummaryCandidate from './SummaryCandidate'
import { getCssClassName } from '../../../common/utils/generateClassName'
import HistoryInterviewsTable from './HistoryInterviewTable'
import CancelInterviewModals from '../../../features/interviewModals/components/CancelInterviewModals'
import EditInterviewModals from '../../../features/interviewModals/components/EditInterviewModals'
import EndInterviewModals from '../../../features/interviewModals/components/EndInterviewModals'
import HistoryInterviewModals from '../../../features/interviewModals/components/HistoryInterviewModals'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import QuestionnaireCandidate from './questionnaireCandidate/QuestionnaireCandidate'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import './Interview.scss'
import ChatMobil from '../../../features/chat/components/ChatMobil'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import UserAvatarMobile from '../../../common/svg/UserAvatarMobile'
import MoreGrid from '../../../common/svg/MoreGrid'
import HistoryInterviewsTableMobil from './HistoryInterviewTableMobil'
import Clock from '../../../common/svg/SvgFromIconWrapper/Clock'
import { Heading } from '../../../common/components/Text'
import CardUser from '../../../common/svg/CardUser'
import HeaderInterviewMobil from './HeaderInterviewMobil'
import HistoryQuestionnaireInterviewModals
from '../../../features/interviewModals/components/HistoryQuestionnaireInterviewModals'
import { useEntityContext } from '../../../common/context/EntityContextProvider'

const mainCssClass = getCssClassName('interview')
const { TabPane } = Tabs
const Interview: FC = () => {
  const dispatch = useDispatch()
  const historyData = useSelector(selectHistoryData)
  const { entityId } = useParams<TUrlParams>()
  const loading = useSelector(selectLoading)
  const editInterview = useSelector(selectModalEditOpen)
  const cancelInterview = useSelector(selectModalCancelOpen)
  const selectedInterviewId = useSelector(selectInterviewId)
  const candidate = useSelector(selectCandidate)
  const endInterview = useSelector(selectModalEndInterviewOpen)
  const historyInterviewModalOpen = useSelector(selectModalHistoryInterviewOpen)
  const historyInterviewQuestionnaireModalOpen = useSelector(selectModalHistoryQuestionnaireInterviewOpen)
  const interview = useSelector(selectInterview)
  const optionsReason = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const optionsResult = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const optionsCharacteristic = useSelector(selectDictionaryOptionsByName('candidateCharacteristic'))
  const optionsNote = useSelector(selectDictionaryOptionsByName('interviewNote'))
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const [isMoreInfo, setIsMoreInfo] = useState<boolean>(false)
  const {
    rbacControl, department
  } = useEntityContext()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const
    isMobile = useMemo(() => windowSize.width < 500 && windowSize.height < 900, [windowSize])

  const handleModalEditOpen = useCallback(() => {
    dispatch(setHandleModalEditOpen(false))
  }, [])

  const handleModalCancelOpen = useCallback(() => {
    dispatch(setHandleModalCancelOpen(false))
  }, [])

  const handleModalEndOpen = useCallback(() => {
    dispatch(setHandleModalEndOpen(false))
  }, [])

  const handleModalHistoryOpen = useCallback(() => {
    dispatch(setHandleModalHistoryOpen(false))
  }, [])
  const handleModalHistoryQuestionnaireOpen = useCallback(() => {
    dispatch(setHandleModalQuestionnaireHistoryOpen(false))
  }, [])
  const handleChangeMoreInfo = () => {
    setIsMoreInfo(true)
  }
  const handleOpenHistory = () => {
    setIsMoreInfo(false)
  }

  const handleClickTab = (key) => {
    if (candidate?.id && Number(key) === 3) {dispatch(getCandidateQuestionnaire(candidate?.id))}
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (entityId) {
      dispatch(getInterview(entityId))
    }
  }, [entityId])

  useEffect(() => {
    dispatch(getDictionaries(['candidateStatus', 'interviewRejectionReason', 'interviewNote',
      'interviewResult', 'statusResult', 'candidateCharacteristic', 'stageStatus', 'rejectionReason']))
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>
      {loading && (
        <div className={`${mainCssClass}__preloader`}>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      {!loading && interview && candidate && (
        <>
          <div className={`${mainCssClass}__wrapper`}>
            {!isMobile && (
              <div className={`${mainCssClass}__mobileButton`}>
                <ReturnButton />
              </div>
            )}
            {!isMobile && (
              <div className={`${mainCssClass}__content`}>
                <div className={`${mainCssClass}__information-container`}>
                  <HeaderInterview />
                  <Tabs className={`${mainCssClass}__information-block`} onTabClick={handleClickTab}>
                    <TabPane tab="Интервью" key="1">
                      <Information />
                    </TabPane>
                    {department !== 175 && department !== 176 && department !== 177 && (
                      <TabPane tab="Резюме" key="2">
                        <SummaryCandidate />
                      </TabPane>
                    )}
                    <TabPane tab="Анкета" key="3">
                      <QuestionnaireCandidate />
                    </TabPane>
                  </Tabs>
                </div>
                <div className={`${mainCssClass}__history-table`}>
                  <Typography.Title level={4}>История интервью кандидата</Typography.Title>
                  <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
                    <HistoryInterviewsTable historyData={historyData} />
                  </NewScrollContainer>
                </div>
              </div>
            )}
            {isMobile && (
              <div className={`${mainCssClass}_interview-header-mobil`}>
                <div className={`${mainCssClass}_interview-header-logo`}>
                  <Typography.Text>
                    <div className={`${mainCssClass}_arrow-right`}>
                      <ArrowRightSM onClick={handleOpenHistory} />
                    </div>
                    <div className={`${mainCssClass}_title-interview`}>
                      {isMoreInfo ? 'История интервью кандидата' : `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`}
                    </div>
                  </Typography.Text>

                </div>
                <div className={`${mainCssClass}_interview-header-actions`}>
                  <Clock onClick={handleChangeMoreInfo} />
                </div>
              </div>
            )}
            {!!isMobile && !isMoreInfo && (
              <>
                <HeaderInterviewMobil />
                <Tabs className={`${mainCssClass}__information-block-mobile`}>
                  {department !== 175 && department !== 176 && department !== 177 && (
                    <TabPane tab="Интервью" key="1">
                      <Information />
                    </TabPane>
                  )}
                  <TabPane tab="Резюме" key="2">
                    <SummaryCandidate />
                  </TabPane>
                </Tabs>
              </>
            )}
            {isMobile && isMoreInfo && (
              <HistoryInterviewsTableMobil historyData={historyData} />)}
          </div>
          {editInterview
            && (
              <EditInterviewModals
                open={editInterview}
                candidate={candidate}
                className={`${mainCssClass}__modals`}
                onCancel={handleModalEditOpen}
                isCandidatePage={false}
              />
            )}
          <CancelInterviewModals
            open={cancelInterview}
            interview={interview}
            className={`${mainCssClass}__modals`}
            onCancel={handleModalCancelOpen}
            options={optionsReason}
          />
          <EndInterviewModals
            optionsCharacteristic={optionsCharacteristic}
            open={endInterview}
            interview={interview}
            className={`${mainCssClass}__modals`}
            onCancel={handleModalEndOpen}
            optionsResult={optionsResult}
            optionsNote={optionsNote}
            optionsReason={optionsReason}
            stageOptions={stageOptions}
            stage={candidate?.stage_id}
          />
          <HistoryInterviewModals
            open={historyInterviewModalOpen}
            interview={historyData[0]}
            tags={historyData?.vacancy_list}
            className={`${mainCssClass}__modals`}
            onCancel={handleModalHistoryOpen}
          />
          <HistoryQuestionnaireInterviewModals
            open={historyInterviewQuestionnaireModalOpen}
            interview={historyData[0]}
            tags={historyData?.snapshot}
            className={`${mainCssClass}__modals`}
            onCancel={handleModalHistoryQuestionnaireOpen}
          />
        </>
      )}
    </div>
  )
}

export default memo(withErrorBoundary(Interview))
