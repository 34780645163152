import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography, Progress, Button, Tooltip
} from 'antd'
import CloseSM from '../../../../common/svg/CloseSM'
import EditPencil1 from '../../../../common/svg/EditPencil1'
import Chat from '../../../../common/svg/Chat'
import CloseMD from '../../../../common/svg/CloseMD'
import Plus from '../../../../common/svg/Plus'
import {
  selectCustomerValue,
  selectDictionaryOptionsByName,
  selectOpenArchiveModal, selectOpenEditCustomerModal,
  selectOpenEditModal,
  selectOpenRejectModal,
  selectOpenUpdateModal,
  selectRequest,
  selectSureRequestModal,
  selectLabelById
} from '../../selectors'
import ModalUdpateStatusRequest from '../../../../features/requestModals/components/ModalUdpateStatusRequest'
import ModalCommentRequest from '../ModalCommentRequest'
import {
  openArchiveModal, openEditRequestCustomerModal,
  openEditRequestModal, openHistoryRequestData,
  openRejectModal,
  openStatusModal,
  openSureRequestModal
} from '../../actions'
import ModalArchiveRequest from '../../../../features/requestModals/components/ModalArchiveRequest'
import ModalRejectRequest from '../../../../features/requestModals/components/ModalRejectRequest'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ModalEditRequest from '../../../../features/requestModals/components/ModalEditRequest'
import ModalCloseSure from '../../../candidate/components/ModalCloseSure'
import RequestPriority from './RequestPriority'
import ControlButton from '../../../../common/components/Buttons/Button'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import {
  openRequestDrawer, setEditRequestMode, updateArchiveRequest, updateStatusRequest
} from '../../../../features/requestModals/actions'
import { selectLoadingModal } from '../../../InnerRouter/selectors'
import './HeaderRequestPage.scss'
import ModalAppointCustomer from '../ModalAppointCustomer'
import EditRequestDrawer from '../../../../features/requestModals/components/EditRequestDrawer'
import { selectOpenRequestDrawer } from '../../../../features/requestModals/selectors'

const { Title } = Typography

const mainCssClass = 'headerRequestPage'

type Props = {
  isMobile: boolean
}

function HeaderRequestPage({ isMobile }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [comment, setComment] = useState(false)
  const archiveModal = useSelector(selectOpenArchiveModal)
  const rejectModal = useSelector(selectOpenRejectModal)
  const statusModal = useSelector(selectOpenUpdateModal)
  const sureModal = useSelector(selectSureRequestModal)
  const editCustomerModal = useSelector(selectOpenEditCustomerModal)
  const request = useSelector(selectRequest)
  const [color, setColor] = useState<string>('#404542')
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFF')
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))

  const modalLoading = useSelector(selectLoadingModal)
  const {
    rbacControl, role
  } = useEntityContext()
  const reasonOptions = useSelector(selectDictionaryOptionsByName('archiveReason'))
  const rejectOptions = useSelector(selectDictionaryOptionsByName('requestRejectionReason'))
  const request_priority = useSelector(selectLabelById(request?.priority_id, 'priority'))
  const customerValue = useSelector(selectCustomerValue)
  const editDrawer = useSelector(selectOpenRequestDrawer)

  const handleOpenComment = useCallback(() => setComment(true), [])
  const handleOpenArchive = useCallback(() => dispatch(openArchiveModal(true)), [])
  const handleCancelArchive = useCallback(() => dispatch(openArchiveModal(false)), [])

  const handleOpenReject = useCallback(() => dispatch(openRejectModal(true)), [])
  const handleCancelReject = useCallback(() => dispatch(openRejectModal(false)), [])

  const handleOpenModalStatus = useCallback(() => dispatch(openStatusModal(true)), [])
  const handleCancelModalStatus = useCallback(() => dispatch(openStatusModal(false)), [])

  const handleOkComment = useCallback(() => setComment(false), [])
  const handleCancelComment = useCallback(() => setComment(false), [])

  const handleOpenEdit = useCallback(() => {
    dispatch(setEditRequestMode(true))
    dispatch(openRequestDrawer(true))
  }, [])
  const handleCancelEdit = useCallback(() => dispatch(openSureRequestModal(true)), [])
  const handleOpenEditCustomer = useCallback(() => dispatch(openEditRequestCustomerModal(false)), [])
  const handleSureClose = useCallback(
    () => dispatch(openSureRequestModal(false)),
    []
  )
  const handleSureOk = useCallback(() => {
    dispatch(openSureRequestModal(false))
    dispatch(openRequestDrawer(false))
    dispatch(setEditRequestMode(false))
  }, [])

  const handleOpenHistoryRequest = useCallback(() => {
    dispatch(openHistoryRequestData(true))
  }, [])

  useEffect(() => {
    let arrayPriorityStr = priorityOptions?.find((item) => item.value === request?.priority_id)?.params
    if (request?.priority_id) {
      arrayPriorityStr = arrayPriorityStr?.replace(/\\/g, '')
      arrayPriorityStr = arrayPriorityStr
        ?.replace(/^"|"$|\\+/g, '') // Убираем внешние кавычки и экранирование
        .replace(/^\[|]$/g, '{') // Заменяем квадратные скобки на фигурные
        .replace(/""/g, '"') // Исправляем лишние двойные кавычки
        .replace(/color":/g, 'color":') // Убираем лишние кавычки у ключей
        .replace(/backgroundColor":/g, 'backgroundColor":') // Убираем лишние кавычки у ключей
        .replace(/(\{\s*\})|\{\s*\}$/, '') // Удаляем пустые фигурные скобки и лишние фигурные скобки в конце
        .replace(/}$/, '}') // Удаляем лишние фигурные скобки в конце, если они есть
        .replace(/([^}])$/, '$1}') // Закрываем фигурную скобку, если она отсутствует

      // Убираем лишние пробелы в начале и в конце
      arrayPriorityStr = arrayPriorityStr?.trim()

      // Удаляем лишнюю фигурную скобку в конце
      arrayPriorityStr = arrayPriorityStr?.replace(/\{\}\s*$/, '')

      // Проверяем и добавляем закрывающую фигурную скобку, если ее нет
      if (!arrayPriorityStr?.endsWith('}')) {
        arrayPriorityStr += '}'
      }

      // Удаляем кавычки, если они окружают строку
      if (arrayPriorityStr?.startsWith('"') && arrayPriorityStr?.endsWith('"')) {
        arrayPriorityStr = arrayPriorityStr.slice(1, -1)
      }

      // Попробуем распарсить строку в объект
      let arrayPriority = null
      try {
        arrayPriority = JSON.parse(arrayPriorityStr)
      } catch (error) {
        console.error('Ошибка при парсинге JSON:', error)
      }

      const backgroundColor = arrayPriority ? arrayPriority.backgroundColor || '#FFF' : '#FFF'
      const color = arrayPriority ? arrayPriority.color || '#404542' : '#404542'
      setBackgroundColor(backgroundColor)
      setColor(color)
    }
  }, [request, priorityOptions])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_header`}>
        {request_priority && (
          <div className={`${mainCssClass}_header_priority`} style={{ backgroundColor }}>
            <RequestPriority backgroundColor={backgroundColor} color={color} />
            {`${request_priority || ''} приоритет`}
          </div>
        )}
        <div className={`${mainCssClass}_header_title`}>
          <Title level={3}>
            {request?.name ? request.name : request?.store.address}
          </Title>
        </div>
      </div>

      {request?.status !== 126 && (
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_content_info`}>
            <Title level={4}>Закрытие заявки</Title>
            <Typography.Text>
              {
                request?.closed && request?.cost
                  ? `${request?.closed}/${request?.cost}`
                  : `0/ ${request?.cost}`
              }
            </Typography.Text>
          </div>
          <Progress
            type="line"
            percent={
              // eslint-disable-next-line no-unsafe-optional-chaining
              request?.closed && request?.cost
                ? Number(request?.closed) === 0
                  ? 0
                  : (Number(request?.closed) / Number(request?.cost)) * 100
                : 0
            }
            showInfo={false}
            // format={() => (request?.closed && request?.cost
            //   ? `Позиции ${request?.closed}/${request?.cost}`
            //   : `Позиции 0/ ${request?.cost}`)}
          />
        </div>
      )}
      {(request?.status === 92 || request?.status === 0) && rbacControl?.request.edit_view_access === 1 && (
        <div className={`${mainCssClass}_actions`}>
          <ControlButton typeButton="secondary" className="button" onClick={handleOpenEdit}>
            <EditPencil1 />
            Редактировать
          </ControlButton>

          <ControlButton typeButton="secondary" className="button" onClick={handleOpenComment}>
            <Chat />
            Комментарий
          </ControlButton>
          <Tooltip title="Архивировать заявку" placement="rightTop">
            <ControlButton typeButton="warning" className="button" onClick={handleOpenArchive}>
              <CloseSM />
            </ControlButton>
          </Tooltip>
        </div>
      )}

      {/* {request?.status === 650 && rbacControl?.request.archive_access === 1 && ( */}
      {/*  <div className={`${mainCssClass}_actions`}> */}
      {/*    /!* <Button onClick={handleOpenEdit}> */}
      {/*      <EditPencil1 /> */}
      {/*      Редактировать */}
      {/*    </Button> */}

      {/*    <Button onClick={handleOpenComment}> */}
      {/*      <Chat /> */}
      {/*      Комментарий */}
      {/*    </Button> *!/ */}
      {/*    <ControlButton typeButton="secondary" className="button" onClick={handleReturnFromArchive} disabled={modalLoading}> */}
      {/*      Вернуть из архивированных */}
      {/*    </ControlButton> */}
      {/*  </div> */}
      {/* )} */}

      {request?.status === 91 && rbacControl?.request.edit_view_access === 1
      && role?.role_id !== 'customer_in_bulk' && role?.role_id !== 'zakazchik_kur_erov' && (
        <div className={`${mainCssClass}_actionsB`}>

          {(role?.role_id === 'superadmin' || role?.role_id === 'admin') && (
            <>
              <Button className={`${mainCssClass}_actionsB_plus`} onClick={handleOpenModalStatus}>
                <Plus />
                Взять в работу
              </Button>
              {statusModal && (
                <ModalUdpateStatusRequest
                  open={statusModal}
                  isRequestTable={false}
                  onCancel={handleCancelModalStatus}
                  requestId={request.id}
                />
              )}
            </>
          )}

          <Button className={`${mainCssClass}_actionsB_edit`} onClick={handleOpenEdit}>
            <EditPencil1 />
            Редактировать
          </Button>

          <Button className={`${mainCssClass}_actionsB_close`} onClick={handleOpenReject}>
            <CloseMD />
          </Button>
        </div>
      )}

      {request?.status === 112 && rbacControl?.request.edit_view_access === 1 && (
        <div className={`${mainCssClass}_actions`}>
          <Button onClick={handleOpenEdit}>
            <EditPencil1 />
            Редактировать
          </Button>
        </div>
      )}
      <ModalAppointCustomer
        requestId={request ? request.id : ''}
        created_at={request ? request.created_at : ''}
        customer={customerValue ? [customerValue] : []}
        customer_list={request?.customer_list || []}
        open={editCustomerModal}
        onCancel={handleOpenEditCustomer}
      />
      {archiveModal && (
        <ModalArchiveRequest
          isRequestTable={false}
          options={reasonOptions}
          open={archiveModal}
          onCancel={handleCancelArchive}
          requestId={request?.id}
        />
      )}

      {rejectModal && (
        <ModalRejectRequest
          open={rejectModal}
          options={rejectOptions}
          requestId={request?.id}
          onCancel={handleCancelReject}
          isRequestTable={false}
        />
      )}
      <ModalCommentRequest
        open={comment}
        onOk={handleOkComment}
        onCancel={handleCancelComment}
        requestId={request?.id}
      />

      {
        isMobile
        && (
          <EditRequestDrawer
            open={editDrawer}
            onCancel={handleCancelEdit}
          />
        )
      }
      {
        isMobile && (editDrawer
        && <ModalCloseSure open={sureModal} onOk={handleSureOk} onCancel={handleSureClose} />)
      }

    </div>
  )
}

export default memo(withErrorBoundary(HeaderRequestPage))
