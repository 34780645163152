import React, { FC, useMemo } from 'react'
import Ellipse from '../../../common/svg/Ellipse'
import './ParsingStatus.scss'

const mainCssClass = 'parsing-status'

// Define the props type
type ParsingStatusProps = {
  status: string
  text: string
}

const ParsingStatus: FC<ParsingStatusProps> = ({ status, text }) => {
  const fill = useMemo(() => '#2DBE64', [])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__icon-container`}>
        <Ellipse fill={fill} />
      </div>
      {text}
    </div>
  )
}

export default ParsingStatus
