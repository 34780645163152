import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Table from '../../../common/components/table/Table'
import { THistoryCandidateData } from '../../../common/api/Candidate'
import HistoryTypeNameTable from './HistoryTypeNameTable'
import Download from '../../../common/svg/Download'
import PhoneRecordLink from './PhoneRecordLink'
import PlayRecordAudioAsterisk from './PlayRecordAudioAsterisk'

const mainCssClass = getCssClassName('client-contracts-table')

  type TProps = {
      historyData: THistoryCandidateData[]
  } & TClassName
  type TRowData = {
      action: JSX.Element
      user: string
      before: JSX.Element | string
      after: JSX.Element | string
      date: string
  }

const useColumns = (): Column<any>[] => useMemo(
  () => [
    { accessor: 'date', Header: 'Дата' },
    {
      accessor: 'type',
      Header: 'Действие'
    },
    {
      accessor: 'user',
      Header: 'Сотрудник'
    },
    {
      accessor: 'recordUrl',
      Header: 'Запись звонка'
    },
    {
      accessor: 'playAudio',
      Header: 'Воспроизвести'
    }
  ],
  []
)

const useRowsData = (historyData: THistoryCandidateData[]) => {
  const userId = localStorage.getItem('user_id')

  const dispatch = useDispatch()
  const rows: TRowData[] = useMemo(
    () => (historyData?.length
      ? historyData.map<TRowData>(
        ({
          caller,
          type,
          incoming,
          recordUrl
        }) => ({
          date: incoming
            ? dayjs(incoming, 'HH:mm:ss DD.MM.YYYY').format('DD.MM.YY HH:mm') : '',
          user: caller ? caller?.name : '-',
          type: <HistoryTypeNameTable action={type} />,
          recordUrl: <PhoneRecordLink link={recordUrl || ''} />,
          playAudio: <PlayRecordAudioAsterisk link={recordUrl} />
          // Объединяем before и after в одну строку
        })
      )
      : []),
    [historyData]
  )
  const handleRowClick = () => {

  }
  return { rows, handleRowClick }
}
const AsteriskTable: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(historyData)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
      handleRowClick={handleRowClick}
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(AsteriskTable))
