import React from 'react'
import { Drawer, DrawerProps } from 'antd'
import cn from 'classnames'
import './CommonDrawer.scss'
import { getCssClassName } from '../../../common/utils/generateClassName'

const mainCssClass = getCssClassName('common-drawer')

function CommonDrawer({ className, ...rest }: DrawerProps) {
  return (
    <Drawer
      className={cn(mainCssClass, className)}
      {...rest}
    />

  )
}

export default CommonDrawer
