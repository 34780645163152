import React from 'react'
import Icon from '../svgIcons/WhatsApp.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function WhatsApp(props: IconProps) {
  return (
    <IconWrapper {...props} name="WhatsApp" size="xs">
      <Icon />
    </IconWrapper>
  )
}
