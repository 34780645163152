/* eslint-disable react/function-component-definition */
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Button, Tabs, Typography, Radio } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { isEqual, isInteger } from 'lodash'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import Excel from '../../../common/svg/Excel'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'

import InterviewsTable from './InterviewsTable'
import {
  exportCSV,
  getDictionaries,
  getInterviews,
  getWorkload,
  openWorkloadFilter,
  reset,
  setBlockedFiltersResponsibleOrRecruiter,
  setDefDataInterview,
  setDefStringInterviews,
  setWorkloadPage
} from '../actions'
import {
  selectInterviews,
  selectResultInterviewsCount,
  selectLoading,
  selectDefDataInterview,
  selectHeaders,
  selectWorkload,
  selectWorkloadPage,
  selectWorkloadFilterString
} from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import {
  getFilters,
  openFilters,
  setCountFilters,
  setDefFilters,
  setDeleteFilterByKey,
  setFilters,
  setFiltersLabels
} from '../../../features/filters/actions'
import ControlButton from '../../../common/components/Buttons/Button'
import {
  selectCountFilters,
  selectDefFilters,
  selectDeleteFilterKey,
  selectFilterString,
  selectLabelsFilter
} from '../../../features/filters/selectors'
import { selectIconNumber, selectUserInfo } from '../../InnerRouter/selectors'
import { setIconNumber, setVisibleTable } from '../../InnerRouter/actions'
import { resetAllCandidate } from '../../ManagamentCandidates/actions'
import { resetAllRequest } from '../../ManagementRequests/actions'
import { resetAllVacancy } from '../../ManagementVacancies/actions'
import { setDefStringFunnel } from '../../Funnel/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import InputSearchFormControl from '../../../common/components/formcontrol/InputSearchFormControl'
import Search from '../../../common/svg/Search'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import TabPane = Tabs.TabPane
import { DATE_DOTS_FORMAT, SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import Workload from './Workload'
import './ManagementInterviews.scss'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import ResponsiveMenuFilters from '../../../common/components/ResponsiveMenu/ResponsiveMenu'
import { formValuesArray } from '../../../features/filters/components/Filters'
import { objectToQueryString } from '../../../features/filters/utils'
import ChristmasIcon from '../../../features/ChristmasIcon/ChristmasIcon'

type TFormValues = {
  perPagesCount: number
  globalSearch: string
  date?: string
}

const mainCssClass = getCssClassName('management-interviews')
const ManagementInterviews: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const defDataInterview = useSelector(selectDefDataInterview)
  const workload = useSelector(selectWorkload)
  const workloadPage = useSelector(selectWorkloadPage)
  const { rbacControl, download_access } = useEntityContext()
  const headers = useSelector(selectHeaders)
  const [page, setPage] = useState<number>(defDataInterview?.page || 1)
  const [requestStatus, setRequestStatus] = useState<string>('true')
  const interviews = useSelector(selectInterviews)
  const userInfo = useSelector(selectUserInfo)
  const filterKey = useSelector(selectDeleteFilterKey)
  const resultCount = useSelector(selectResultInterviewsCount)
  const filtersCount = useSelector(selectCountFilters)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const defFilters = useSelector(selectDefFilters)
  const workloadFilter = useSelector(selectWorkloadFilterString)
  const filterLabels = useSelector(selectLabelsFilter)
  const filteredFilterLabel = useMemo(() => filterLabels.filter((item) => item.value.length > 0), [filterLabels])
  const { pageId } = useParams()
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const { page: pageInterview, filters: filtersInterview, perPage: perPageInterview } = defDataInterview ?? {}

  const { control, watch, getValues, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataInterview?.perPage || 10,
      globalSearch: defDataInterview?.search || '',
      date: dayjs(new Date()).format(SERVER_DATE_FORMAT)
    }
  })

  const perPagesCount = watch('perPagesCount')
  const maxPage = useMemo(() => {
    if (headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(() => `${headers?.current} из ${headers?.total}`, [headers])

  const handleOpenFilters = () => {
    if (workloadPage) {
      dispatch(openWorkloadFilter(true))
    } else {
      dispatch(openFilters(true))
    }
  }

  const handleChangeRequestStatus = (e) => {
    setPage(1)

    setRequestStatus(e)
    if (e === 'true') {
      dispatch(setBlockedFiltersResponsibleOrRecruiter(true))
    } else {
      dispatch(setBlockedFiltersResponsibleOrRecruiter(false))
    }
    if (e !== 'workload' && workloadPage) {
      dispatch(setWorkloadPage(false))
    }
    if (e === 'workload') {
      dispatch(setWorkloadPage(true))
    }
  }

  const handleExportCSV = () => {
    dispatch(
      exportCSV({
        has_head: true,
        filter: watch('globalSearch') ? `${filtersString || ''}&search=${globalSearch}` : `${filtersString || ''}`,
        self: requestStatus
      })
    )
  }

  const handleTabClick = useCallback((e) => {
    if (e === '1') {
      dispatch(setVisibleTable(false))
    } else {
      dispatch(setVisibleTable(true))
    }
  }, [])
  const handlePerPage = (selectedParametr: number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const globalSearch = watch('globalSearch')
  const date = watch('date')

  const handleClickSearch = () => {
    if (defDataInterview) {
      dispatch(
        setDefDataInterview({
          ...defDataInterview,
          search: globalSearch,
          filters: `${filtersString || ''}${globalSearch ? `&search=${globalSearch}` : ''}`
        })
      )
    }
  }

  const handleKeyDown = (event: any) => {
    if (defDataInterview) {
      if (event.key === 'Enter') {
        handleClickSearch()
      }
    }
  }

  const handleBlurSearch = () => {
    if (!globalSearch && defDataInterview) {
      dispatch(
        setDefDataInterview({
          ...defDataInterview,
          search: globalSearch,
          filters: `${filtersString || ''}${globalSearch ? `&search=${globalSearch}` : ''}`
        })
      )
    }
  }

  const handleDeleteSearch = () => {
    if (defDataInterview) {
      dispatch(
        setDefDataInterview({
          ...defDataInterview,
          search: '',
          filters: `${filtersString || ''}`
        })
      )
    }
  }

  const resetValueByKey = (key: string) => {
    const values = JSON.parse(localStorage.getItem(`def${pageId || 'candidates'}`))
    const valuesWithoutDate = { ...values, [key]: Array.isArray(values[key]) ? [] : null }
    const currentFormValuesUpdate = filterLabels.map((item) => (item.key === key ? { ...item, value: [] } : item))
    dispatch(setFiltersLabels(currentFormValuesUpdate))
    localStorage.setItem(`${pageId || 'candidates'}-labels`, JSON.stringify(currentFormValuesUpdate))
    localStorage.setItem(`def${pageId || 'candidates'}`, JSON.stringify(valuesWithoutDate))
    localStorage.setItem(pageId || 'candidates', objectToQueryString(valuesWithoutDate))

    dispatch(getFilters({ filter: valuesWithoutDate, isEventFunnel: pageId === 'event-funnel' }))
    dispatch(
      setDefFilters({
        type: pageId || 'candidates',
        filter: valuesWithoutDate,
        defFilterString: objectToQueryString(valuesWithoutDate, pageId === 'event-funnel')
      })
    )
  }

  useEffect(() => {
    if (pageId === defFilters?.type && defFilters?.defFilterString) {
      localStorage.setItem(defFilters.type, defFilters.defFilterString)
      localStorage.setItem(`def${defFilters.type}`, JSON.stringify(defFilters.filter))
      dispatch(setFilters(defFilters?.defFilterString))
    } else if (
      pageId &&
      (localStorage?.getItem(pageId) || localStorage?.getItem(pageId) === '') &&
      (!defFilters?.type || defFilters?.type !== pageId)
    ) {
      dispatch(setFilters(localStorage.getItem(pageId) || ''))

      let parsedFilter
      try {
        parsedFilter = localStorage.getItem(`def${pageId}`) ? JSON.parse(localStorage.getItem(`def${pageId}`)) : ''
      } catch (parseError) {
        console.error('Error parsing defFilter:', parseError)
        parsedFilter = ''
      }

      dispatch(
        setDefFilters({ type: pageId, defFilterString: localStorage.getItem(pageId) || '', filter: parsedFilter })
      )
    } else {
      dispatch(setFilters(''))
      dispatch(setDefFilters(null))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    if (pageId && localStorage?.getItem(pageId) && pageId === 'interviews') {
      dispatch(
        setDefDataInterview({
          page,
          perPage: perPagesCount,
          filters: defDataInterview?.search
            ? `${localStorage?.getItem(pageId)}&search=${defDataInterview?.search}`
            : `${localStorage?.getItem(pageId)}`,
          search: defDataInterview?.search || ''
        })
      )
    } else if (!localStorage?.getItem(pageId) && defDataInterview?.search) {
      dispatch(
        setDefDataInterview({
          page,
          perPage: perPagesCount,
          filters: `&search=${defDataInterview?.search}`,
          search: defDataInterview?.search || ''
        })
      )
    } else {
      dispatch(
        setDefDataInterview({
          page,
          perPage: perPagesCount,
          filters: '',
          search: defDataInterview?.search || ''
        })
      )
    }
  }, [page, perPagesCount, filtersString, pageId, globalSearch])

  useEffect(() => {
    dispatch(getDictionaries(['interviewResult', 'statusResult', 'group']))
  }, [])

  useEffect(() => {
    if (localStorage.getItem(`def${pageId}`)) {
      try {
        const parsedFilters = JSON.parse(localStorage.getItem(`def${pageId}`))
        if (!isEqual(parsedFilters, {})) {
          dispatch(
            setCountFilters(
              Object.values(parsedFilters).filter((item) => (Array.isArray(item) ? item.length > 0 : !!item !== false))
                .length
            )
          )
        } else {
          dispatch(setCountFilters(0))
        }
      } catch (parseError) {
        console.error('Error parsing filters:', parseError)
        dispatch(setCountFilters(0))
      }
    } else {
      dispatch(setCountFilters(0))
    }
  }, [pageId, filtersString])

  useEffect(() => {
    if (requestStatus === 'workload' && date) {
      dispatch(
        getWorkload({
          date: dayjs(date).format(SERVER_DATE_FORMAT),
          filter: workloadFilter || ''
        })
      )
    }
  }, [date, requestStatus, workloadFilter])

  useEffect(() => {
    if (pageInterview && perPageInterview && pageId && requestStatus !== 'workload') {
      dispatch(
        getInterviews({
          page: pageInterview,
          perPage: perPageInterview,
          filters: filtersInterview || '',
          self: requestStatus
        })
      )
    }
  }, [pageInterview, perPageInterview, filtersInterview, requestStatus, pageId])
  //_________________________Удалить после НГ
  useEffect(() => {
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
  }, [])
  //___________________________
  useEffect(
    () => () => {
      dispatch(reset())
      dispatch(setFilters(''))
    },
    []
  )
  useEffect(() => {
    if (localStorage.getItem(`${pageId}-labels`) && pageId === 'interviews') {
      try {
        const parsedLabels = JSON.parse(localStorage.getItem(`${pageId}-labels`))
        dispatch(setFiltersLabels(parsedLabels))
      } catch (parseError) {
        console.error('Error parsing filters labels:', parseError)
        dispatch(setFiltersLabels(formValuesArray))
      }
    } else {
      dispatch(setFiltersLabels(formValuesArray))
    }
  }, [pageId])

  useEffect(() => {
    if (filterKey) {
      resetValueByKey(filterKey)
      dispatch(setDeleteFilterByKey(''))
    }
  }, [filterKey])

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          {/*//_________________________Удалить после НГ*/}
          <ChristmasIcon />
          {/*//___*/}
          <Typography.Title level={2}>Интервью</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <ControlButton icon="none" size="large" typeButton="secondary" className="button" onClick={handleOpenFilters}>
            <FiltersIcon />
            Фильтры
            {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
          </ControlButton>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__search-container`}>
            <Tabs
              onChange={(e) => handleChangeRequestStatus(e)}
              activeKey={requestStatus}
              className={`${mainCssClass}__tabs`}
            >
              <TabPane key="all" tab="Все" />
              <TabPane key="true" tab="Мои" />
              {(userInfo?.role?.role_id === 'superadmin' || userInfo?.role?.role_id === 'admin') && (
                <TabPane key="workload" tab="Загруженность" />
              )}
            </Tabs>

            {requestStatus === 'workload' &&
              (userInfo?.role?.role_id === 'recruiter' ||
                userInfo?.isColdSearch === true ||
                userInfo?.role?.role_id === 'superadmin' ||
                userInfo?.role?.role_id === 'admin' ||
                userInfo?.is_can_conduct_interview) && (
                <>
                  <div>
                    <DatePickerFormControl control={control} name="date" isDisableDate tasks />
                  </div>
                  <div className={`${mainCssClass}__tabs`}>
                    <Tabs onChange={(e) => handleTabClick(e)} defaultActiveKey="2">
                      <TabPane key="1" tab="Календарь" />
                      <TabPane key="2" tab="Таблица" />
                    </Tabs>
                  </div>
                </>
              )}

            {requestStatus !== 'workload' && (
              <div className={`${mainCssClass}__search-container_search`}>
                <InputSearchFormControl
                  className={`${mainCssClass}__global-search`}
                  name="globalSearch"
                  control={control}
                  placeholder="ФИО кандидата"
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlurSearch}
                  handleDelete={handleDeleteSearch}
                />
                <ControlButton
                  typeButton="primary"
                  className={`${mainCssClass}__search-container_btn button`}
                  icon="left"
                  onClick={handleClickSearch}
                >
                  <Search />
                  Поиск
                </ControlButton>
              </div>
            )}
          </div>
          {requestStatus !== 'workload' && (
            <div className={`${mainCssClass}__container-info`}>
              <div className={`${mainCssClass}__container-title`}>
                <Typography.Title level={5} className={`${mainCssClass}__title-result`}>
                  Результат:&nbsp;
                  {headers?.totalCount || ''}
                </Typography.Title>
                {!!resultCount && !!download_access?.interviews && (
                  <Button type="ghost" icon={<Excel />} className={`${mainCssClass}__excel`} onClick={handleExportCSV}>
                    {' '}
                    Выгрузить в Excel{' '}
                  </Button>
                )}
              </div>
              <div className={`${mainCssClass}__container-pagination`}>
                {!!headers && (
                  <SelectFormControl
                    className={`${mainCssClass}__pagination-count`}
                    name="perPagesCount"
                    options={optionsPagination}
                    control={control}
                    onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  />
                )}
                {!!headers && (
                  <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                    <ArrowRightSM
                      className="arrowCursor"
                      size="1"
                      onClick={handlePreviousPage}
                      fill={page === 1 ? '#BFC5C0' : '#404542'}
                    />
                    <Typography.Text className={`${mainCssClass}__pagination-font`}>
                      {stringCountsPagination || 'неизвестно'}
                    </Typography.Text>
                    <ArrowLeftSM
                      className="arrowCursor"
                      size="1"
                      onClick={handleNextPage}
                      fill={page === maxPage ? '#BFC5C0' : '#404542'}
                    />
                  </div>
                )}
                {(userInfo?.role?.role_id === 'recruiter' ||
                  userInfo?.role?.role_id === 'superadmin' ||
                  userInfo?.role?.role_id === 'admin' ||
                  userInfo?.isColdSearch === true ||
                  userInfo?.is_can_conduct_interview) && (
                  <div className={`${mainCssClass}__option_tabs`}>
                    <Tabs onChange={(e) => handleTabClick(e)} defaultActiveKey="2">
                      <TabPane key="1" tab="Календарь" />
                      <TabPane key="2" tab="Таблица" />
                    </Tabs>
                  </div>
                )}
              </div>
            </div>
          )}
          <div>
            <ResponsiveMenuFilters items={filteredFilterLabel} className={`${mainCssClass}__menu-labels`} />
          </div>

          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              {requestStatus !== 'workload' && <InterviewsTable interviews={interviews || []} />}
              {requestStatus === 'workload' && workload && <Workload />}
            </NewScrollContainer>
          </div>
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ManagementInterviews))
