import React from 'react'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'giftChristmas'

function GiftChristmas({ className, size = '1', color }: TSvgProps) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="gift-svgrepo-com 1" clipPath="url(#clip0_2_6753)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M24.7906 13.2413C24.8286 13.7351 24.8364 14.2152 24.8165 14.4612C24.7669 15.0792 24.8353 17.1553 24.4739 18.7586H32V13.2413H24.7906Z"
              fill="#EEC319"
            />
            <path
              id="Vector_2"
              d="M7.18349 14.4615C7.16362 14.2148 7.17137 13.7354 7.20943 13.2416H0V18.7589H7.52612C7.16531 17.155 7.23368 15.0788 7.18349 14.4615Z"
              fill="#EEC319"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_3"
              d="M24.4739 18.7585C24.3183 19.4493 24.0838 20.054 23.7241 20.4137C22.812 21.3263 21.1707 21.0924 19.9464 20.198L19.947 20.1986L21.2055 22.8971L19.232 22.5815L18.7586 23.3716V30.8966H29.7716C30.3929 30.8966 30.8965 30.3928 30.8965 29.7716V18.7585H24.4739Z"
              fill="#24AE5F"
            />
            <path
              id="Vector_4"
              d="M12.4562 22.5817L10.4827 22.8968L11.6066 20.4868L11.6071 20.4862C10.454 21.1312 9.08075 21.2184 8.27581 20.414C7.91606 20.0537 7.68218 19.4495 7.52655 18.7588H1.10332V29.7713C1.10332 30.3926 1.60707 30.8968 2.22883 30.8968H13.2413V23.8926L12.4562 22.5817Z"
              fill="#24AE5F"
            />
            <path
              id="Vector_5"
              d="M7.48781 11.7838C7.81331 11.1857 8.46438 10.5071 9.74826 10.4828C11.089 10.4575 12.3861 11.9537 13.2412 13.0622V1.10339H2.2287C1.60745 1.10339 1.1032 1.60715 1.1032 2.22834V13.2414H7.20974C7.25493 12.6555 7.34324 12.0497 7.48781 11.7838Z"
              fill="#24AE5F"
            />
            <path
              id="Vector_6"
              d="M29.7717 1.10327H18.7586V13.062C19.6138 11.9535 20.9114 10.4573 22.2521 10.4827C23.536 10.5069 24.187 11.1855 24.5126 11.7836C24.6571 12.0495 24.7454 12.6554 24.7906 13.2412H30.8966V2.22822C30.8966 1.60696 30.3929 1.10327 29.7717 1.10327Z"
              fill="#24AE5F"
            />
          </g>
          <path
            id="Vector_7"
            d="M7.48781 11.7837C7.81331 11.1856 8.46438 10.507 9.74827 10.4827C11.6197 10.4469 13.4112 13.3804 14.0252 14.0783C13.5419 14.5755 13.2412 15.2518 13.2412 16C13.2412 16.5015 13.3852 16.9649 13.6192 17.3705C13.6192 20.1551 9.85364 21.9912 8.27569 20.4138C7.08118 19.2188 7.255 15.3445 7.18325 14.4612C7.14018 13.9222 7.22131 12.2736 7.48781 11.7837Z"
            fill="#BE3A2B"
          />
          <g id="Group_4">
            <path
              id="Vector_8"
              d="M13.2409 16.5517C13.1262 16.5517 13.0097 16.5158 12.9104 16.4413L10.7035 14.7861C10.4596 14.6034 10.4106 14.2575 10.5932 14.0136C10.7763 13.7698 11.1217 13.7207 11.3656 13.9033L13.5725 15.5585C13.8164 15.7411 13.8654 16.087 13.6829 16.3309C13.5742 16.4755 13.4087 16.5517 13.2409 16.5517Z"
              fill="#721C19"
            />
            <path
              id="Vector_9"
              d="M11.0351 18.2068C10.8674 18.2068 10.7019 18.1307 10.5932 17.9862C10.4106 17.7423 10.4597 17.3963 10.7036 17.2137L12.9105 15.5585C13.1554 15.3759 13.5009 15.4256 13.6829 15.6689C13.8655 15.9128 13.8164 16.2587 13.5725 16.4413L11.3657 18.0965C11.2663 18.171 11.1499 18.2068 11.0351 18.2068Z"
              fill="#721C19"
            />
          </g>
          <path
            id="Vector_10"
            d="M24.5124 11.7837C24.1869 11.1856 23.5359 10.507 22.252 10.4827C20.3805 10.4469 18.5891 13.3804 17.975 14.0783C18.4583 14.5755 18.759 15.2518 18.759 16C18.759 16.5015 18.615 16.9649 18.3811 17.3705C18.3811 20.1551 22.1466 21.9912 23.7246 20.4138C24.9191 19.2188 24.7452 15.3445 24.817 14.4612C24.8601 13.9222 24.7789 12.2736 24.5124 11.7837Z"
            fill="#BE3A2B"
          />
          <g id="Group_5">
            <path
              id="Vector_11"
              d="M18.7593 16.5516C18.5916 16.5516 18.4261 16.4755 18.3174 16.3309C18.1348 16.087 18.1839 15.7411 18.4278 15.5585L20.6347 13.9033C20.8796 13.7207 21.225 13.7698 21.4071 14.0137C21.5897 14.2575 21.5406 14.6035 21.2967 14.7861L19.0898 16.4413C18.9905 16.5157 18.8741 16.5516 18.7593 16.5516Z"
              fill="#721C19"
            />
            <path
              id="Vector_12"
              d="M20.9651 18.2068C20.8504 18.2068 20.7339 18.1709 20.6346 18.0964L18.4277 16.4412C18.1838 16.2586 18.1348 15.9126 18.3173 15.6687C18.5005 15.4254 18.8459 15.3758 19.0898 15.5584L21.2967 17.2136C21.5405 17.3962 21.5896 17.7421 21.407 17.986C21.2984 18.1306 21.1329 18.2068 20.9651 18.2068Z"
              fill="#721C19"
            />
            <path
              id="Vector_13"
              d="M18.381 17.3704C17.9043 18.1963 17.0221 18.7585 16.0002 18.7585C14.9779 18.7585 14.0962 18.1963 13.6195 17.3704C13.6195 18.7342 12.7142 19.8675 11.6068 20.4865L10.483 22.897L12.4559 22.5815L13.4832 24.2962L16.0002 18.7586L18.2049 24.2962L19.2322 22.5815L21.2052 22.897L19.9472 20.1985C19.054 19.5464 18.381 18.545 18.381 17.3704Z"
              fill="#721C19"
            />
          </g>
          <g id="Group_6">
            <path
              id="Vector_14"
              d="M14.0254 14.0785C14.5263 13.5637 15.2248 13.2415 16 13.2415C16.7758 13.2415 17.4736 13.5637 17.9746 14.0785C18.1489 13.8804 18.4205 13.5009 18.7586 13.0622V1.10352H13.2413V13.0622C13.5801 13.5009 13.851 13.8804 14.0254 14.0785Z"
              fill="#EEC319"
            />
            <path
              id="Vector_15"
              d="M18.2051 24.296L15.9999 18.7583L13.4829 24.296L13.2413 23.8927V30.8963H18.7585V23.3718L18.2051 24.296Z"
              fill="#EEC319"
            />
          </g>
          <path
            id="Vector_16"
            d="M18.7588 15.9999C18.7588 17.5232 17.5235 18.7585 16.0001 18.7585C14.4768 18.7585 13.2415 17.5232 13.2415 15.9999C13.2415 14.4765 14.4768 13.2412 16.0001 13.2412C17.5235 13.2412 18.7588 14.4766 18.7588 15.9999Z"
            fill="#F97B76"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_6753">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GiftChristmas
