/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  getRequest,
  openArchiveModal,
  openCreateRequestModal,
  openEditRequestModal,
  openRejectModal,
  openStatusModal,
  openSureRequestModal,
  reset,
  setDictionaries,
  setLoading,
  setRequest,
  setHistoryRequestData,
  openHistoryRequestData,
  setOpenModalRemove,
  removeRequestCandidateData,
  setHeaders,
  setRequestHistoryLoading,
  setTabRequest,
  openEditRequestCustomerModal,
  saveActiveRequestCustomer,
  setEditCustomerValue,
  setRequestHistoryV2
} from './actions'
import { TRequestState } from './types'

const initialState: TRequestState = {
  request: null,
  loading: false,
  dictionaries: null,
  requestArchiveModal: false,
  requestRejectModal: false,
  requestUpdateStatusModal: false,
  createRequestModal: false,
  editRequestModal: false,
  editRequestCustomerModal: false,
  sureRequestModal: false,
  historyAction: [],
  historyModal: false,
  openRequestRemove: false,
  requestRemoveData: null,
  headers: null,
  historyLoading: false,
  tabRequest: 'candidate',
  saveCustomerRequest: null,
  customerValue: '',
  historyRequestV2: []
}

const requestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRequest, (state, { payload }) => {
      state.request = payload
    })
    .addCase(setHistoryRequestData, (state, { payload }) => {
      state.historyAction = payload
    })
    .addCase(openHistoryRequestData, (state, { payload }) => {
      state.historyModal = payload
    })
    .addCase(setOpenModalRemove, (state, { payload }) => {
      state.openRequestRemove = payload
    })
    .addCase(removeRequestCandidateData, (state, { payload }) => {
      state.requestRemoveData = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(reset, () => initialState)
    .addCase(openArchiveModal, (state, { payload }) => {
      state.requestArchiveModal = payload
    })
    .addCase(openStatusModal, (state, { payload }) => {
      state.requestUpdateStatusModal = payload
    })
    .addCase(openRejectModal, (state, { payload }) => {
      state.requestRejectModal = payload
    })
    .addCase(openEditRequestModal, (state, { payload }) => {
      state.editRequestModal = payload
    })
    .addCase(openEditRequestCustomerModal, (state, { payload }) => {
      state.editRequestCustomerModal = payload
    })
    .addCase(setEditCustomerValue, (state, { payload }) => {
      state.customerValue = payload
    })
    .addCase(saveActiveRequestCustomer, (state, { payload }) => {
      state.saveCustomerRequest = payload
    })
    .addCase(openCreateRequestModal, (state, { payload }) => {
      state.createRequestModal = payload
    })
    .addCase(openSureRequestModal, (state, { payload }) => {
      state.sureRequestModal = payload
    })
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setRequestHistoryLoading, (state, { payload }) => {
      state.historyLoading = payload
    })
    .addCase(setTabRequest, (state, { payload }) => {
      state.tabRequest = payload
    })
    .addCase(setRequestHistoryV2, (state, { payload }) => {
      state.historyRequestV2 = payload
    })
})

export default requestReducer
