export function removeFilterFragments(inputString: string) {
  const regex = /&filter\[status\]\[in\]\[\]\=(92|91|650)/g
  return inputString.replace(regex, '')
}

export function generateRequestFilters(words: string) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const email_like = `[email][like]=${words[i]}`
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(email_like, name_like, surname_like)
  }

  const query = `${filters.join('&orFilter')}`
  return query
}
