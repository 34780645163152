/* eslint-disable react/function-component-definition */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { Checkbox, Tag, Typography } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TDATA } from '../../candidate/types'
import CardUser from '../../../common/svg/CardUser'
import { TClassName } from '../../../common/types/props'
import { Heading } from '../../../common/components/Text'
import './InternshipCandidateCard.scss'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import EditPencil1 from '../../../common/svg/EditPencil1'

type TProps = {
    candidate_id?: TDATA
    onClick: () => void
    type: 'day' | 'week'
} & TClassName
const mainCssClass = 'internship-candidate-card'
const InternshipCandidateCard: FC<TProps> = ({
  candidate, className, onClick, type
}) => {
  const checkBoxLabel = `${candidate?.last_name || ''} ${candidate?.name || ''} ${candidate?.second_name || ' '}`

  const handleNavLinkClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
  }, [])

  const getCameToInternshipText = (value: number): string => {
    switch (value) {
    case 1:
      return 'Да'
    case 2:
      return 'Нет'
    case 0:
      return 'Нет информации'
    default:
      return ''
    }
  }

  return (
    <div
      className={cn(
        className,
        mainCssClass,
        `${mainCssClass}__status-${candidate?.status}`,
        { [`${mainCssClass}__status-result`]: candidate?.mentor }
      )}
      onClick={onClick}
    >
      {!!candidate.mentor && <div className={`${mainCssClass}__result-text`}>Стажировка завершена</div>}
      <div className={`${mainCssClass}__container-check`}>
        {checkBoxLabel}
        <div className={`${mainCssClass}__icon`}>
          <NavLink to={`/candidate/${candidate?.candidate_id}`} className={`${mainCssClass}__link`} onClick={handleNavLinkClick}>
            <CardUser />
          </NavLink>
        </div>

      </div>
      <div className={cn(
        `${mainCssClass}__container-info`,
        {
          [`${mainCssClass}__container-info-week`]: type === 'week'
        },
        {
          [`${mainCssClass}__container-info-result`]: !!candidate.mentor
        }
      )}
      >
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.address ? candidate?.address : 'Адрес не заполнен'}
        </Typography.Text>
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          {candidate?.phone ? candidate?.phone : 'Телефон не заполнен'}
        </Typography.Text>
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          <Typography.Text className={`${mainCssClass}__labels`}>Дата стажировки:</Typography.Text>
          <Typography.Text>
            {candidate?.date ? dayjs(candidate?.date).format(DATE_DOTS_FORMAT) : ''}
            {' '}
            {candidate.time ? candidate.time.slice(0, 5) : ''}
          </Typography.Text>
        </Typography.Text>
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          <Typography.Text className="label-text">График:</Typography.Text>
          {' '}
          <Typography.Text>
            {candidate?.schedule?.label ? candidate?.schedule.label : 'График не заполнен'}
          </Typography.Text>
        </Typography.Text>
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          <Typography.Text className="label-text">Вакансия:</Typography.Text>
          {' '}
          <Typography.Text className={`${mainCssClass}__red-text`}>
            {candidate?.vacancy_label ? candidate?.vacancy_label : 'не заполнена'}
          </Typography.Text>
        </Typography.Text>
        <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
          <Typography.Text className="label-text">Заявка:</Typography.Text>
          {' '}
          <Typography.Text className={`${mainCssClass}__red-text`}>
            {candidate?.request_name ? candidate?.request_name : ' не заполнена'}
          </Typography.Text>
        </Typography.Text>

        {!!candidate.mentor

        && (
          <>
            <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
              <Typography.Text className="label-text">Сотрудник пришел на стажировку:</Typography.Text>
              {' '}
              <Typography.Text>
                {getCameToInternshipText(candidate?.is_came || 0)}
              </Typography.Text>
            </Typography.Text>
            <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
              <Typography.Text className="label-text">Наставник:</Typography.Text>
              {' '}
              <Typography.Text>
                {candidate?.mentor || 'Не указан'}
              </Typography.Text>
            </Typography.Text>
            <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
              <Typography.Text className="label-text">Оформление:</Typography.Text>
              {' '}
              <Typography.Text>
                {getCameToInternshipText(candidate?.registration || 0)}
              </Typography.Text>
            </Typography.Text>
            <Typography.Text className={cn(`${mainCssClass}__labels`, `${mainCssClass}__status-color-${candidate?.status}`)}>
              <Typography.Text className="label-text">Комментарий:</Typography.Text>
              {' '}
              <Typography.Text>
                {candidate?.comment || 'Нет комментария'}
              </Typography.Text>
            </Typography.Text>
          </>
        )}
        {
          !candidate.mentor
              && (
                <div className={`${mainCssClass}__edit`}>
                  <EditPencil1 color="#2DBE64" />
                  Редактировать
                </div>
              )
        }
      </div>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(InternshipCandidateCard))
