import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { isDefined } from '../../../../utils/utilsContext'
import { DetailsItemProps } from '../../types'
import { Skeleton } from '../../../Skeleton'
import { Text } from '../../../Text'
import { setEditKeyDetailList } from '../../../../../compositions/InnerRouter/actions'
import { selectEditKeyDetailList } from '../../../../../compositions/InnerRouter/selectors'
import './styles.scss'

export type TDetailsListProps = DetailsItemProps & {
  edit?: boolean
  editComponent?: JSX.Element
  editKey?: string
}

const mainCssClass = 'ListItem'

export function DetailsListItem({
  'data-testid': testId = 'detailsListItem',
  size = 'md',
  skeletonOptions = {},
  className,
  isLoading,
  item,
  editKey,
  editComponent,
  edit
}: TDetailsListProps) {
  const dispatch = useDispatch()
  const editKeyDetailList = useSelector(selectEditKeyDetailList)
  const TextComponent = size === 'xs' ? Text.SmallSm : Text.SmallMd


  const handleEditOpen = (edit, editKey) => {
    if (edit && editKey ) {
      dispatch(setEditKeyDetailList(editKey))
    }
  }


  return (
    <div className={cn(mainCssClass, className)} data-testid={testId}>
      <div
        className={cn(`${mainCssClass}_label`, [`${mainCssClass}_label__${size}`])}
        data-testid={`${testId}-label`}
      >
        {isLoading ? (
          <Skeleton
            className={`${mainCssClass}_skeleton`}
            randomWidthFrom={30}
            {...skeletonOptions}
          />
        ) : (
          <TextComponent data-testid={`${testId}-label-text`}>{item.label}</TextComponent>
        )}
      </div>

      <div className={`${mainCssClass}_value`} data-testid={`${testId}-value`}>
        {isLoading ? (
          <Skeleton
            className={`${mainCssClass}_skeleton`}
            randomWidthFrom={30}
            {...skeletonOptions}
          />
        ) : (
          <>
            {edit && editKeyDetailList && editKey === editKeyDetailList ? (
              editComponent
              && React.cloneElement(editComponent)
            ) : (
              <div onClick={() => handleEditOpen(edit, editKey)}>
                <TextComponent data-testid={`${testId}-value-text`}>{item.value}</TextComponent>
              </div>
            )}

            {isDefined(item.error) && (
              <div>
                <Text
                  className={`${mainCssClass}_value__error`}
                  data-testid={`${testId}-error`}
                >
                  {item.error}
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
