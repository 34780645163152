import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ reportFilter }: TRootState) => reportFilter

export const selectReportFilter = createSelector(selectState, ({ openFilter }) => openFilter)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) =>
  createSelector(
    selectDictionariesMap,
    // @ts-ignore
    (dictionaries) => dictionaries[name] || []
  )

export const selectFiltersType = createSelector(selectState, ({ filterType }) => filterType)

export const selectResetFilter = createSelector(selectState, ({ resetFilter }) => resetFilter)

export const selectFilterString = createSelector(selectState, ({ filterString }) => filterString)