import React from 'react'
import Icon from '../svgIcons/OkSolid.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function OkSolid(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid">
      <Icon />
    </IconWrapper>
  )
}
