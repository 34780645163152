import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'request-reject'

function RequestReject({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.00003" y="1" width="38" height="38" rx="19" fill="#FFCAC7" />
      <rect x="1.00003" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L20 18.5858L25.293 13.2929C25.6835 12.9024 26.3166 12.9024 26.7072 13.2929C27.0977 13.6834 27.0977 14.3166 26.7072 14.7071L21.4143 20L26.7071 25.2929C27.0976 25.6834 27.0976 26.3165 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L20 21.4142L14.7071 26.7071C14.3166 27.0977 13.6834 27.0977 13.2929 26.7071C12.9024 26.3166 12.9024 25.6835 13.2929 25.2929L18.5858 20L13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929Z" fill="#DF1E12" />
    </svg>

  )
}
export default RequestReject
