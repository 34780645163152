import { AxiosResponse } from 'axios'
import $api from './http'
import { TAutoCall } from '../../compositions/autocalls/actions'

export async function fetchPostAutoCalls(payload: TAutoCall, filterLeads: string): Promise<AxiosResponse<TAutoCall>> {
  let newFilterLeads: string = filterLeads
  if (newFilterLeads.startsWith('&')) {
    newFilterLeads = `?${newFilterLeads.slice(1)}`
  }
  const response = await $api.post(`/fraxvill/internal/auto-calls${newFilterLeads}`, payload)
  return response
}

export async function fetchGetAutoCalls(): Promise<AxiosResponse<TAutoCall[]>> {
  const response = await $api.get(
    `${process.env.DOMAIN_ASTERISK}/internal/auto-call`
  )
  return response
}
export async function fetchGetStartAutoCall(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`${process.env.DOMAIN_ASTERISK}/internal/auto-call/enable?autocall_id=${payload}`)
  return response
}
export async function fetchGetStopAutoCall(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`${process.env.DOMAIN_ASTERISK}/internal/auto-call/disable?autocall_id=${payload}`)
  return response
}

export async function fetchGetUsersWhoCalls(payload: string): Promise<AxiosResponse<any[]>> {

  const response = await $api.get(
    `${process.env.DOMAIN_ASTERISK}/internal/auto-call-user/users?${payload}`
  )
  return response
}
export async function fetchGetGroupUsersWhoCalls(): Promise<AxiosResponse<any[]>> {
  const response = await $api.get(
    `${process.env.DOMAIN_ASTERISK}/internal/queue/get-drop-down`
  )
  return response
}
