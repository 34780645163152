import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  fill?: string
}

const mainCssClass = 'circle'

function Circle({ className, size = '1', fill }: TSvgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" className={cn(className, mainCssClass)}>
      <circle cx="3" cy="3" r="3" fill={!fill ? '#BFC5C0' : fill}/>
    </svg>
  )
}
export default Circle
