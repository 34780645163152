import React from 'react'
import Icon from '../svgIcons/PausePhone.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function PausePhone(props: IconProps) {
  return (
    <IconWrapper {...props} name="OkSolid" size={props?.size || 'lg'}>
      <Icon />
    </IconWrapper>
  )
}
