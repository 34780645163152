/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  TTriggers,
  openDictionaryForm,
  openNewTrigger,
  openQuestionnaireForm,
  openTemplatesForm,
  reset,
  setActiveDictionary,
  setActiveQuestionnaire,
  setActiveTemplate,
  setActiveTrigger,
  setDictionaries,
  setDictionaryName,
  setLoading,
  setQuestionnaire,
  setStages,
  setTemplates,
  setTriggers,
  setVertices,
  createOrEditRole,
  setRoleList,
  setRoleConstructor,
  setStageState,
  setUsers,
  setSelectedUser,
  createOrEditUser,
  setDictionariesDropdown,
  setStores,
  setCreateOrEditStore,
  setSelectedStore,
  setHeadersStore,
  setDefDataStore,
  setDefDataUser,
  setHeadersUser,
  setDefDataRole,
  setHeadersRole,
  setYandexObjects,
  setWhatsappTemplates,
  setHeadersWhatsappTemplates,
  setDefDataWhatsapp,
  openWhatsappTemplatesForm,
  setActiveWhatsappTemplate,
  setActiveCallback,
  setCallbacks,
  setDefDataCallback,
  setHeadersCallback,
  openCallbackSettingForm,
  openReminderForm,
  setHeadersReminders,
  setDefDataReminders,
  setReminders,
  setActiveReminder, openTokenForm, setHeadersTokens, setDefDataTokens, setTokens, setActiveToken,
  setDropdownStageSetupKey,
  TDropdownStageSetupKey,
  setConstructorModal,
  TFieldConfig,
  setFieldConfig,
  setFieldConfigFilter
} from './actions'
import { TQuestionnare, TRoleSetting } from './types'
import {
  TCallback,
  TRbacRoleList, TStage, TStore, TUser, TVertices
} from '../../common/api/systems'
import { TDictionaries } from '../../common/types/dictionaries'

export type TSystemsState = {
  loading: boolean
  questionnare: TQuestionnare[] | null
  activeQuestionnare:TQuestionnare | null,
  questionnaireForm:boolean,
  newTrigger: boolean,
  activeTrigger:TTriggers | null,
  dictionaryForm: boolean,
  activeDictionary: any
  yandexObject: any
  triggers:any
  templatesForm: boolean
  templates: any[] | null
  activeTemplate: any
  dictionaries: any
  dictionariesName: {id:number, name:string} | null
  vertices: TVertices[]
  stages: TStage[]
  users: TUser[]
  selectedUser: TUser | null
  roleList: TRbacRoleList | null
  createOrEditRoleShow: boolean
  createOrEditUserShow: boolean
  roleConstructor: TRoleSetting | null
  stageState:any[]
  dictionariesDropdown: TDictionaries | null
  stores: TStore[]
  createOrEditStore: boolean
  selectedStore: TStore | null
  defDataStore: {
    page:number,
    perPage: number,
    filters: string
  } | null
  headersStore: {
    current: number
    total :number
  } | null
  defDataUser: {
    page:number,
    perPage: number,
    filters: string
  } | null
  headersUser: {
    current: number
    total :number
  } | null
  defDataRole: {
    page:number,
    perPage: number,
    filters: string
  } | null
  headersRole: {
    current: number
    total :number
  } | null
  whatsappTemplates: any[]
  whatsappTemplatesForm: boolean
  activeWhatsTemplate: any
  headersWhatsapp: {
    current: number
    total :number
  } | null
  defDataWhatsapp: {
    page:number,
    perPage: number,
  } | null
  whatsappForm: boolean
  defDataCallback: {
    page:number,
    perPage: number,
  } | null
  headersCallback: {
    current: number
    total :number
  } | null
  callbackSettingForm: boolean,
  activeCallback: TCallback | null,
  callbacks: any[]
  reminders: any[]
  activeReminder: any | null
  headersReminders: {
    current: number
    total :number
  } | null
  defDataReminders: {
    page:number,
    perPage: number,
  } | null
  reminderForm: boolean
  tokens: any[]
  activeToken: any | null
  headersTokens: {
    current: number
    total :number
  } | null
  defDataTokens: {
    page:number,
    perPage: number,
  } | null
  tokenForm: boolean,
  dropdownStageSetupKey: TDropdownStageSetupKey
  modalConstructor: boolean
  fieldConfig: TFieldConfig[]
  fieldConfigFilter: string
}

const initialState: TSystemsState = {
  loading: false,
  questionnare: null,
  activeQuestionnare: null,
  questionnaireForm: false,
  newTrigger: false,
  activeTrigger: null,
  dictionaryForm: false,
  activeDictionary: [],
  triggers: null,
  templatesForm: false,
  templates: null,
  activeTemplate: null,
  dictionaries: null,
  dictionariesName: null,
  vertices: [],
  stages: [],
  createOrEditRoleShow: false,
  roleList: null,
  roleConstructor: null,
  stageState: [],
  createOrEditUserShow: false,
  users: [],
  selectedUser: null,
  dictionariesDropdown: null,
  stores: [],
  createOrEditStore: false,
  selectedStore: null,
  yandexObject: null,
  defDataStore: {
    page: 1,
    perPage: 10,
    filters: ''
  },
  headersStore: null,
  defDataUser: null,
  headersUser: null,
  defDataRole: {
    page: 1,
    perPage: 10,
    filters: ''
  },
  headersRole: null,
  whatsappTemplates: [],
  whatsappTemplatesForm: false,
  activeWhatsTemplate: null,
  headersWhatsapp: null,
  defDataWhatsapp: null,
  whatsappForm: false,
  defDataCallback: null,
  headersCallback: null,
  callbackSettingForm: false,
  activeCallback: null,
  callbacks: [],
  activeReminder: null,
  defDataReminders: null,
  reminderForm: false,
  reminders: [],
  headersTokens: null,
  activeToken: null,
  defDataTokens: null,
  tokenForm: false,
  tokens: [],
  headersReminders: null,
  dropdownStageSetupKey: '',
  modalConstructor: false,
  fieldConfig: [],
  fieldConfigFilter: ''
}

const systemsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setQuestionnaire, (state, { payload }) => {
      state.questionnare = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setActiveQuestionnaire, (state, { payload }) => {
      state.activeQuestionnare = payload
    })
    .addCase(openQuestionnaireForm, (state, { payload }) => {
      state.questionnaireForm = payload
    })
    .addCase(openNewTrigger, (state, { payload }) => {
      state.newTrigger = payload
    })
    .addCase(setActiveTrigger, (state, { payload }) => {
      state.activeTrigger = payload
    })
    .addCase(openDictionaryForm, (state, { payload }) => {
      state.dictionaryForm = payload
    })
    .addCase(setRoleConstructor, (state, { payload }) => {
      state.roleConstructor = payload
    })
    .addCase(setActiveDictionary, (state, { payload }) => {
      state.activeDictionary = payload
    })
    .addCase(setTriggers, (state, { payload }) => {
      state.triggers = payload
    })
    .addCase(createOrEditRole, (state, { payload }) => {
      state.createOrEditRoleShow = payload
    })
    .addCase(openTemplatesForm, (state, { payload }) => {
      state.templatesForm = payload
    })
    .addCase(setTemplates, (state, { payload }) => {
      state.templates = payload
    })
    .addCase(setActiveTemplate, (state, { payload }) => {
      state.activeTemplate = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setDictionaryName, (state, { payload }) => {
      state.dictionariesName = payload
    })
    .addCase(setVertices, (state, { payload }) => {
      state.vertices = payload
    })
    .addCase(setStages, (state, { payload }) => {
      state.stages = payload
    })
    .addCase(setRoleList, (state, { payload }) => {
      state.roleList = payload
    })
    .addCase(setUsers, (state, { payload }) => {
      state.users = payload
    })
    .addCase(setSelectedUser, (state, { payload }) => {
      state.selectedUser = payload
    })
    .addCase(setStageState, (state, { payload }) => {
      state.stageState = payload
    })
    .addCase(createOrEditUser, (state, { payload }) => {
      state.createOrEditUserShow = payload
    })
    .addCase(setDictionariesDropdown, (state, { payload }) => {
      state.dictionariesDropdown = payload
    })
    .addCase(setStores, (state, { payload }) => {
      state.stores = payload
    })
    .addCase(setDefDataStore, (state, { payload }) => {
      state.defDataStore = payload
    })
    .addCase(setHeadersStore, (state, { payload }) => {
      state.headersStore = payload
    })
    .addCase(setCreateOrEditStore, (state, { payload }) => {
      state.createOrEditStore = payload
    })
    .addCase(setYandexObjects, (state, { payload }) => {
      state.yandexObject = payload
    })
    .addCase(setSelectedStore, (state, { payload }) => {
      state.selectedStore = payload
    })
    .addCase(setDefDataUser, (state, { payload }) => {
      state.defDataUser = payload
    })
    .addCase(setHeadersUser, (state, { payload }) => {
      state.headersUser = payload
    })
    .addCase(setDefDataRole, (state, { payload }) => {
      state.defDataRole = payload
    })
    .addCase(setHeadersRole, (state, { payload }) => {
      state.headersRole = payload
    })
    .addCase(setWhatsappTemplates, (state, { payload }) => {
      state.whatsappTemplates = payload
    })
    .addCase(setHeadersWhatsappTemplates, (state, { payload }) => {
      state.headersWhatsapp = payload
    })
    .addCase(setDefDataWhatsapp, (state, { payload }) => {
      state.defDataWhatsapp = payload
    })
    .addCase(openWhatsappTemplatesForm, (state, { payload }) => {
      state.whatsappForm = payload
    })
    .addCase(setActiveWhatsappTemplate, (state, { payload }) => {
      state.activeWhatsTemplate = payload
    })
    .addCase(setActiveCallback, (state, { payload }) => {
      state.activeCallback = payload
    })
    .addCase(setCallbacks, (state, { payload }) => {
      state.callbacks = payload
    })
    .addCase(setDefDataCallback, (state, { payload }) => {
      state.defDataCallback = payload
    })
    .addCase(setHeadersCallback, (state, { payload }) => {
      state.headersCallback = payload
    })
    .addCase(openCallbackSettingForm, (state, { payload }) => {
      state.callbackSettingForm = payload
    })
    .addCase(openReminderForm, (state, { payload }) => {
      state.reminderForm = payload
    })
    .addCase(setHeadersReminders, (state, { payload }) => {
      state.headersReminders = payload
    })
    .addCase(setDefDataReminders, (state, { payload }) => {
      state.defDataReminders = payload
    })
    .addCase(setReminders, (state, { payload }) => {
      state.reminders = payload
    })
    .addCase(setActiveReminder, (state, { payload }) => {
      state.activeReminder = payload
    })
    .addCase(openTokenForm, (state, { payload }) => {
      state.tokenForm = payload
    })
    .addCase(setHeadersTokens, (state, { payload }) => {
      state.headersTokens = payload
    })
    .addCase(setDefDataTokens, (state, { payload }) => {
      state.defDataTokens = payload
    })
    .addCase(setTokens, (state, { payload }) => {
      state.tokens = payload
    })
    .addCase(setActiveToken, (state, { payload }) => {
      state.activeToken = payload
    })
    .addCase(setDropdownStageSetupKey, (state, { payload }) => {
      state.dropdownStageSetupKey = payload
    })
    .addCase(setConstructorModal, (state, { payload }) => {
      state.modalConstructor = payload
    })
    .addCase(setFieldConfig, (state, { payload }) => {
      state.fieldConfig = payload
    })
    .addCase(setFieldConfigFilter, (state, { payload }) => {
      state.fieldConfigFilter = payload
    })
})

export default systemsReducer
