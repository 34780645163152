import $api from '../../common/api/http'
import { TTaskFilter } from './components/LeadsFilter'

export const defObject: any = {
  metro: false,
  cities: false,
  vacancyContent: false,
  workSites: false,
  birthday: false,
  statusCandidate: false,
  gender: false,
  isWork: false,
  groupModal: false,
  type: false,
  aggregator: false,
  processing_user_ids: false,
  date_processing_at: false
}

export const fetchMetroList = async (name: string) => {
  if (name) {
    const data = await $api.get(`/api/v1/dadata/suggest-metro?search=${name}`)

    return data.data.DATA.map((obj) => ({
      label: `${obj.unrestricted_value}`,
      value: `${obj.data.name}`
    }))
  }
  return []
}

export function objectToQueryString(obj: TTaskFilter): string {
  let str = ''
  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object' && key !== 'created_at') {
        if (key === 'processing_user_ids') {
          const strArrStr = obj[key].map((el) => el.value).join(',')
          const newStrArr = strArrStr.replace(/undefined/g, '')
          str += `&processing_user_ids=${newStrArr}`
        } else {
          const strArrStr = obj[key].reduce<string>((acc, el) => {
            acc += `&filter[${key}][in][]=${el.value}`
            return acc
          }, '')
          str += strArrStr
        }
      }
      if (typeof obj[key][0] === 'number') {
        if (key === 'group') {
          const strArrStr = obj[key].map((el) => el).join(',')
          const newStrArr = strArrStr.replace(/undefined/g, '')
          str += `&groups=${newStrArr}`
        } else {
          const strArrStr = obj[key].reduce<string>((acc, el) => {
            acc += `&filter[${key}][in][]=${el}`
            return acc
          }, '')
          str += strArrStr
        }
      }
      if (key === 'created_at' || key === 'date_processing_at') {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (typeof obj[key][0] === 'string' && key !== 'created_at' && key !== 'date_processing_at') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
    } else if (typeof obj[key] === 'string' && !!obj[key].length) {
      if (key === 'is_at_work') {
        str += `&union[${key}]=${obj[key]}`
      } else str += `&filter[${key}]=${obj[key]}`
    }
  }
  return str
}

export const getSourceOptions = async () => {
  const { data } = await $api.get('/api/v1/eventlead/get-drop-down')
  return data
}

export const isWorkOptions = [
  { label: 'Отдан в работу', value: '1' },
  { label: 'Не отдан', value: '0' }
]
