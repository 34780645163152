import { PayloadAction } from '@reduxjs/toolkit'
import {
  all, call, put, select, takeLatest
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios/index'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  fetchGetRequests, fetchGetRequestsCard, fetchGetRequestsPagination, TRequest, TResponseRequest
} from '../../common/api/request'
import {
  getRequests,
  setFilteredRequests,
  setLoading,
  setRequests,
  setDictionaries,
  getDictionaries,
  getFilteredRequests,
  TFilters,
  setSlicedRequests, setTotalCountsOfSliced, getSlicedRequests
} from './actions'
import { selectRequests } from './selectors'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { selectOpenModalMap } from '../../compositions/candidate/selectors'
import { setLoadingProgress } from '../../compositions/InnerRouter/actions'

const moduleName = 'карта'
export function* getRequestsSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    const page = 1
    const perPage = 150
    const requestsPromises = []

    const initialResponse: any = yield fetchGetRequestsPagination({ perPage, filters: payload })

    const pageCount = initialResponse || 0

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      requestsPromises.push(call(fetchAndHandleRequest, currentPage, perPage, payload))
    }

    const responses = yield all(requestsPromises)
    const newRequests = responses.reduce((acc, response) => {
      if (response.data.ERR) {
        showCriticalNotification(
          getErrorNotification({
            moduleName,
            text: 'Не удалось загрузить',
            error: response as AxiosError,
          })
        );
      } else {
        acc.push(...response.data.DATA.request_list);
      }
      return acc;
    }, []);
    yield put(setRequests([...newRequests]));

    // Continue with the rest of your logic...
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* fetchAndHandleRequest(page, perPage, payload) {
  try {
    const response: AxiosResponse<TResponseRequest> = yield fetchGetRequestsCard({ page, perPage, filters: payload.filters })
    return response
  } catch (error) {
    return { data: { ERR: true } } // Simulate an error response
  }
}
function* getDictionariesSaga(payload : PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, moduleName)
}

export function* getFilteredRequestsSaga({ payload }: PayloadAction<TFilters>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    const page = 1
    const perPage = 150
    const requestsPromises = []

    const initialResponse: any = yield fetchGetRequestsPagination({ perPage, filters: payload.filters })

    const pageCount = initialResponse || 0

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      requestsPromises.push(call(fetchAndHandleRequest, currentPage, perPage, payload))
    }

    const responses = yield all(requestsPromises)
    const newRequests = responses.reduce((acc, response) => {
      if (response.data.ERR) {
        showCriticalNotification(
          getErrorNotification({
            moduleName,
            text: 'Не удалось загрузить',
            error: response as AxiosError,
          })
        );
      } else {
        acc.push(...response.data.DATA.request_list);
      }
      return acc;
    }, []);
    yield put(setRequests(newRequests));
    yield put(setFilteredRequests(newRequests))

    // Continue with the rest of your logic...
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}
function* getSlicedRequestsSaga({ payload } :PayloadAction<TFilters>) {
  try {
    yield put(setLoadingProgress(true))
    const response:any = yield fetchGetRequestsCard(payload, true)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить карточки кандидатов',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setSlicedRequests(response.data.DATA.request_list))
      yield put(setTotalCountsOfSliced(response.data.Headers?.['x-pagination-total-count'][0]))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

export function* mapSaga() {
  yield takeLatest(getRequests, getRequestsSaga)
  yield takeLatest(getFilteredRequests, getFilteredRequestsSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getSlicedRequests, getSlicedRequestsSaga)
}
