import React, {
  memo, useCallback, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography, Input } from 'antd'
import 'dayjs/locale/ru'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import CloseMD from '../../../common/svg/CloseMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import Search from '../../../common/svg/Search'
import { selectDefWorkloadFilterString, selectDictionaryOptionsByName } from '../selectors'
import { fetchRecruiterList, filteredOptions } from '../../../features/filters/utils'
import { defObject, objectToQueryString } from '../utils'
import { openWorkloadFilter, setDefWorkloadFilters, setWorkloadFilters } from '../actions'
import './WorkloadFilters.scss'

const mainCssClass = getCssClassName('workload-filter')

export type TWorkloadFilter = {
  recruiter: number[]
  group: number[]
}

function WorkloadFilters() {
  const dispatch = useDispatch()

  const [allModal, setAllModal] = useState<any>(defObject)
  const [groupInput, setGroupInput] = useState('')

  const groupOtions = useSelector(selectDictionaryOptionsByName('group'))
  const defFilters = useSelector(selectDefWorkloadFilterString)

  const defaultValues = useMemo(() => defFilters || {}, [defFilters])

  const handleAllReset = useCallback(() => {
    const mapValues = getValues()
    if (defaultValues) {
      // eslint-disable-next-line prefer-const
      for (let key in mapValues) {
        setValue(key, [])
      }
    } else {
      reset({})
    }
  }, [])

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TWorkloadFilter>({ defaultValues })

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleApply = () => {
    dispatch(setWorkloadFilters(objectToQueryString(getValues())))
    dispatch(setDefWorkloadFilters(getValues()))
  }

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>

              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={() => dispatch(openWorkloadFilter(false))}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Группы</Typography.Title>
                {watch('group') && !!watch('group') && <Redo onClick={() => setValue('group', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('groupModal')}>
                {allModal.groupModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.groupModal && (
              <div className={`${mainCssClass}_items_content`}>
                <Input
                  prefix={<Search />}
                  className={`${mainCssClass}_items_content_search`}
                  value={groupInput}
                  onChange={handleGroupInput}
                />
                <CheckBoxFormControl
                  options={filteredOptions(groupOtions, groupInput)}
                  control={control}
                  name="group"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>Рекрутер</Typography.Title>
                {watch('recruiter') && watch('recruiter').length > 0 && <Redo onClick={() => setValue('recruiter', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('recruiterModal')}>
                {allModal.recruiterModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.recruiterModal && (
              <div className={`${mainCssClass}_items_content`}>
                <DebounceSelectFormControlMult
                  name="recruiter"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по имени..."
                  label="Рекрутер"
                  fetchOptions={fetchRecruiterList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>

        </div>
      </NewScrollContainer>
    </div>
  )
}

export default memo(withErrorBoundary(WorkloadFilters))
