import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography, Modal, ModalProps
} from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectCandidate, selectCandidateHistoryV2, selectOpenHistoryCandidateData
} from '../selectors'
import { getHistoryCandidateV2, openHistoryCandidateData } from '../actions'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { CandidateHistoryV2 } from './candidateHistory/CandidateHistoryV2'
import '../ModalHistoryActions.scss'
import { selectLoadingModal } from '../../InnerRouter/selectors'

const MainCSSClass = 'actionsCandidate'
type TFormValues = {
  actions: string
} & ModalProps
function HistoryActionsCandidate({ className }: TFormValues) {
  const openHistoryAction = useSelector(selectOpenHistoryCandidateData)
  const { control, watch } = useForm<TFormValues>()
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const historyData = useSelector(selectCandidateHistoryV2)
  const loading = useSelector(selectLoadingModal)
  useEffect(() => {
    if (candidate) {
      dispatch(getHistoryCandidateV2({ candidate_id: candidate.id }))
    }
  }, [candidate])
  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(openHistoryCandidateData(false))
  }
  return (
    <Modal
      open={openHistoryAction}
      onCancel={handleModalClose}
      closable
      className={cn(className, MainCSSClass)}
      wrapClassName="wrapModal"
      width="900px"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={`${MainCSSClass}`}>
        <div className={`${MainCSSClass}_header`}>
          <Typography.Title level={3}>История кандидата</Typography.Title>
        </div>
        {loading && (
          <div className={`${MainCSSClass}__preloader`}>
            <Preloader size="md" />
            {' '}
          </div>
        )}
        {!loading && !!historyData?.length && (
          <CandidateHistoryV2 />
        )}
        {!loading && !historyData?.length && (
          <div>
            <Typography.Paragraph>
              Пока здесь пусто :(
            </Typography.Paragraph>
          </div>

        )}
      </div>
    </Modal>
  )
}

export default memo(withErrorBoundary(HistoryActionsCandidate))
