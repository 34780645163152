import React, {
  memo, useCallback, useEffect, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography, Input } from 'antd'
import 'dayjs/locale/ru'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import CloseMD from '../../../common/svg/CloseMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import {
  defObject,
  fetchMetroList,
  getSourceOptions,
  isWorkOptions,
  objectToQueryString
} from '../utils'

import {
  fetchCitiesList, fetchResponsibleList,
  fetchVacancyList,
  filteredOptions,
  filteredOptionsSource
} from '../../../features/filters/utils'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import { openLeadsFilter, setFilter } from '../actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'

import Search from '../../../common/svg/Search'
import { selectDictionaryOptionsByName } from '../selectors'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import './LeadsFilter.scss'

const mainCssClass = getCssClassName('leads-filter')

export type TTaskFilter = {
  status: number[]
  vacancy_id: number[]
  utm_source: number[]
  metro: any[]
  city: any[]
  group: any[]
  is_at_work: boolean[]
  aggregator: number[]
  type: number[]
  date_respond: number[]
  date_processing_at: string[] | null
  processing_user_ids: number[]
}

function TasksFilter() {
  const dispatch = useDispatch()
  const [allModal, setAllModal] = useState<any>(defObject)
  const [workSitesInput, setWorkSitesInput] = useState('')
  const [aggregatorInput, setAggregatorInput] = useState('')
  const [typeInput, seTypeInput] = useState('')

  const [statusCandidateInput, setStatusCandidateInput] = useState('')
  const [genderInput, setGenderInput] = useState('')
  const [groupInput, setGroupInput] = useState('')
  const [sourceOptions, setSourceOptions] = useState([])
  const [aggregatorsOptions, setAggregatorsOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])

  // const [aggregatorsOptions, setAggregatorsOptions] = useState([])

  const genderOptions = useSelector(selectDictionaryOptionsByName('gender'))
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))

  const handleAllReset = useCallback(() => {
    localStorage.removeItem('leads')
    localStorage.removeItem('defleads')
    dispatch(setFilter(''))
    reset({})
    setValue('created_at', null)
    setValue('date_processing_at', null)
  }, [])

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TTaskFilter>({
    defaultValues: (() => {
      try {
        const storedValues = localStorage.getItem('defleads');
        return storedValues ? JSON.parse(storedValues) : undefined;
      } catch (parseError) {
        console.error('Error parsing default values:', parseError);
        return undefined;
      }
    })()
  });

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleApply = () => {
    localStorage.setItem('leads', objectToQueryString(getValues()))
    localStorage.setItem('defleads', JSON.stringify(getValues()))
    dispatch(setFilter(objectToQueryString(getValues())))
  }

  const handleWorkSitesInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setWorkSitesInput(e.target.value)
  }

  const handleAggregatorInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAggregatorInput(e.target.value)
  }

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  const handleTypeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    seTypeInput(e.target.value)
  }

  const handleStatusCandidateInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusCandidateInput(e.target.value)
  }

  const handleGenderInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGenderInput(e.target.value)
  }

  const resetValueByKey = (key: string, value: unknown) => {
    const values = getValues()
    const valuesWithoutDate = { ...values, [key]: value }
    localStorage.setItem('defleads', JSON.stringify(valuesWithoutDate))
    localStorage.setItem('leads', objectToQueryString(valuesWithoutDate))
    dispatch(setFilter(objectToQueryString(valuesWithoutDate)))
    setValue(key, value)
  }

  useEffect(() => {
    getSourceOptions().then((data) => {
      const uniqueOptions = filteredOptionsSource(data.DATA.lid_utm) // Вызовите filteredOptions с массивом data.DATA
      setSourceOptions(uniqueOptions)
      setAggregatorsOptions(data.DATA.event_aggregators)
      setTypeOptions(data.DATA.event_types)
    })
  }, [])

  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры</Typography.Title>
              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={() => dispatch(openLeadsFilter(false))}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Аггрегатор
                  {watch('aggregator') && watch('aggregator').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('aggregator').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('aggregator') && watch('aggregator').length > 0 && (
                  <Redo onClick={() => resetValueByKey('aggregator', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('aggregator')}>
                {allModal.aggregator ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.aggregator && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={aggregatorInput}
                    onChange={handleAggregatorInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(aggregatorsOptions, aggregatorInput)}
                    control={control}
                    name="aggregator"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Вакансия
                  {watch('vacancy_id') && watch('vacancy_id').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('vacancy_id').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('vacancy_id') && watch('vacancy_id')?.length > 0
                    && <Redo onClick={() => resetValueByKey('vacancy_id', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('vacancyContent')}>
                {allModal.vacancyContent ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.vacancyContent && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="vacancy_id"
                  control={control}
                  notFoundContent="Начните вводить название вакансии"
                  mode="multiple"
                  placeholder="Поиск по наименованию..."
                  label="Вакансия"
                  fetchOptions={fetchVacancyList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Город
                  {watch('city') && watch('city').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('city').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>

                {watch('city') && watch('city').length > 0
                    && <Redo onClick={() => resetValueByKey('city', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('cities')}>
                {allModal.cities ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.cities && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="city"
                  control={control}
                  mode="multiple"
                  label="Город"
                  placeholder="Поиск по наименованию..."
                  fetchOptions={fetchCitiesList}
                  notFoundContent="Начните вводить город"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Группы
                  {watch('group') && watch('group').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('group').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('group') && watch('group').length > 0
                    && <Redo onClick={() => resetValueByKey('group', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('groupModal')}>
                {allModal.groupModal ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>

            </div>
            {allModal.groupModal && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={groupInput}
                    onChange={handleGroupInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(groupOptions, groupInput)}
                    control={control}
                    name="group"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата обработки</Typography.Title>
                {watch('created_at') && <Redo onClick={() => resetValueByKey('created_at', null)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('dateModal')}>
                {allModal.dateModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.dateModal && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="created_at"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>

          {/* <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата рождения</Typography.Title>
                {watch('birthday') && <Redo onClick={() => resetField('birthday', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('birthday')}>
                {allModal.dateModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.birthday && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="birthday"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Выберите даты"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div> */}

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Источник
                  {watch('utm_source') && watch('utm_source').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('utm_source').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('utm_source') && watch('utm_source').length > 0 && (
                  <Redo onClick={() => resetValueByKey('utm_source', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('workSites')}>
                {allModal.workSites ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.workSites && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={workSitesInput}
                    onChange={handleWorkSitesInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(sourceOptions, workSitesInput)}
                    control={control}
                    name="utm_source"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Метро
                  {watch('metro') && watch('metro').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('metro').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>

                {watch('metro') && watch('metro').length > 0
                    && <Redo onClick={() => resetValueByKey('metro', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('metro')}>
                {allModal.metro ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.metro && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="metro"
                  control={control}
                  mode="multiple"
                  label="Метро"
                  placeholder="Поиск по наименованию..."
                  fetchOptions={fetchMetroList}
                  notFoundContent="Начните вводить станцию метро"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Отдан в работу</Typography.Title>
                {watch('is_at_work') && watch('is_at_work').length > 0
                    && <Redo onClick={() => resetValueByKey('is_at_work', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('isWork')}>
                {allModal.isWork ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.isWork && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RadioGroupFormControl options={isWorkOptions} control={control} name="is_at_work" />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Пол</Typography.Title>
                {watch('gender') && watch('gender').length > 0
                    && <Redo onClick={() => resetValueByKey('gender', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('gender')}>
                {allModal.gender ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.gender && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={genderInput}
                    onChange={handleGenderInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(genderOptions, genderInput)}
                    control={control}
                    name="gender"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Статус кандидата
                  {watch('status') && watch('status').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('status').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('status') && watch('status').length > 0
                    && <Redo onClick={() => resetValueByKey('status', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('statusCandidate')}>
                {allModal.statusCandidate ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.statusCandidate && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={statusCandidateInput}
                    onChange={handleStatusCandidateInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(candidateStatusOptions, statusCandidateInput)}
                    control={control}
                    name="status"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Тип
                  {watch('type') && watch('type').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('type').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('type') && watch('type').length > 0 && (
                  <Redo onClick={() => resetValueByKey('type', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('type')}>
                {allModal.type ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.type && (
              <div className={`${mainCssClass}_items_content`}>
                <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                  <Input
                    prefix={<Search />}
                    className={`${mainCssClass}_items_content_search`}
                    value={typeInput}
                    onChange={handleTypeInput}
                  />
                  <CheckBoxFormControl
                    options={filteredOptions(typeOptions, typeInput)}
                    control={control}
                    name="type"
                  />
                </NewScrollContainer>
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Дата обработки сотрудником</Typography.Title>
                {watch('date_processing_at') && <Redo onClick={() => resetValueByKey('date_processing_at', null)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('date_processing_at')}>
                {allModal.date_processing_at ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.date_processing_at && (
              <div className={`${mainCssClass}_items_content-short`}>
                <RangePickerFormControl
                  control={control}
                  name="date_processing_at"
                  format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                  label="Дата обработки сотрудником"
                  className={`${mainCssClass}_items_content_range`}
                />
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Кто обработал
                  {watch('processing_user_ids') && watch('processing_user_ids').length > 1 ? (
                    <span className={`${mainCssClass}_count-in-brackets`}>
                      (
                      {watch('processing_user_ids').length}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </Typography.Title>
                {watch('processing_user_ids') && watch('processing_user_ids')?.length > 0 && (
                  <Redo onClick={() => resetValueByKey('processing_user_ids', [])} tooltip />
                )}
              </div>
              <ControlButton onClick={() => handldeOpenModal('processing_user_ids')}>
                {allModal.processing_user_ids ? <CaretUpMD />
                  : <CaretDownMD />}
              </ControlButton>
            </div>

            {allModal.processing_user_ids && (
              <div className={`${mainCssClass}_items_content-short`}>
                <DebounceSelectFormControlMult
                  name="processing_user_ids"
                  control={control}
                  mode="multiple"
                  notFoundContent="Начните вводить имя"
                  label="Кто обработал"
                  fetchOptions={fetchResponsibleList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>

        </div>
      </NewScrollContainer>
    </div>
  )
}

export default memo(withErrorBoundary(TasksFilter))
