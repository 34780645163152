import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import { Simulate } from 'react-dom/test-utils'
import { TDictionaries, TDictionariesNames } from '../types/dictionaries'
import { fetchGetV1DictionariesList } from '../api/dictionaries'
import { getErrorNotification } from '../components/ErrorComponentSaga'
import { showCriticalNotification, showDangerNotification } from '../components/notification/utils'
import error = Simulate.error
import { AxiosError } from 'axios'

const moduleName = 'Получение справочников'
export function* loadDictionariesList(
  { payload }: PayloadAction<TDictionariesNames>,
  dictionariesListSetData: ActionCreatorWithPayload<TDictionaries>,
  name: string
) {
  try {
    const dictionariesList: TDictionaries = yield call(fetchGetV1DictionariesList, payload)
    payload.forEach((dictionaryName) => {
      if (dictionariesList[dictionaryName]) {
        if (!dictionariesList[dictionaryName].length) {
          showDangerNotification(getErrorNotification({
            moduleName: `${name}/${moduleName}`,
            text: `Cправочник "${dictionaryName}" не содержит записей`
          }))
        }
      } else {
        showCriticalNotification(getErrorNotification({
          moduleName,
          text: `Не удалось загрузить справочник "${dictionaryName}"`
        }))
      }
    })
    yield put(dictionariesListSetData(dictionariesList))
  } catch (error) {
    showCriticalNotification(getErrorNotification({
      moduleName,
      text: 'Не удалось загрузить справочники',
      error: error as AxiosError
    }))
  }
}
