import { createSelector } from '@reduxjs/toolkit'
import { TDictionariesName } from 'src/common/types/dictionaries'
import { TRootState } from '../../common/store/rootReducer'
import { setStagesLimitation } from './actions'

const selectState = ({ systems }: TRootState) => systems

export const selectQuestionnare = createSelector(selectState, ({ questionnare }) => questionnare)

export const selectActiveQuestionnare = createSelector(selectState, ({ activeQuestionnare }) => activeQuestionnare)

export const selectQuestionnaireForm = createSelector(selectState, ({ questionnaireForm }) => questionnaireForm)

export const selectCreateOrEditRoleFormShow = createSelector(
  selectState,
  ({ createOrEditRoleShow }) => createOrEditRoleShow
)

export const selectCreateOrEditUserFormShow = createSelector(
  selectState,
  ({ createOrEditUserShow }) => createOrEditUserShow
)

// eslint-disable-next-line max-len
export const selectRoleOptions = createSelector(selectState, ({ roleList }) =>
  roleList?.role_list?.map((item) => ({ value: item.role_id, label: item.role_name }))
)

export const selectActualRoleOptions = createSelector(selectState, ({ roleList }) =>
  roleList?.role_list
    ?.filter((item) => !item.is_archive)
    .map((item) => ({ value: item.role_id, label: item.role_name }))
)

export const selectNewTrigger = createSelector(selectState, ({ newTrigger }) => newTrigger)

export const selectActiveTrigger = createSelector(selectState, ({ activeTrigger }) => activeTrigger)

export const selectDictionaryForm = createSelector(selectState, ({ dictionaryForm }) => dictionaryForm)

export const selectActiveDictionary = createSelector(selectState, ({ activeDictionary }) => activeDictionary)

export const selectTriggers = createSelector(selectState, ({ triggers }) => triggers)

export const selectTemplatesForm = createSelector(selectState, ({ templatesForm }) => templatesForm)

export const selectTemplates = createSelector(selectState, ({ templates }) => templates)

export const selectActiveTemplate = createSelector(selectState, ({ activeTemplate }) => activeTemplate)

export const selectDictionaries = createSelector(selectState, ({ dictionaries }) => dictionaries)

export const selectDictionaryName = createSelector(selectState, ({ dictionariesName }) => dictionariesName)

export const selectVertices = createSelector(selectState, ({ vertices }) => vertices)

export const selectStages = createSelector(selectState, ({ stages }) => stages)

export const selectActiveStages = createSelector(selectState, ({ stages }) => stages.filter((el) => el.status))

export const selectRoleListActive = createSelector(
  selectState,
  ({ roleList }) => roleList?.role_list?.filter((item) => item.is_archive === 0) || []
)
export const selectRoleListArchive = createSelector(
  selectState,
  ({ roleList }) => roleList?.role_list?.filter((item) => item.is_archive === 1) || []
)
export const selectRoleConstructor = createSelector(selectState, ({ roleConstructor }) => roleConstructor)

export const selectStagesState = createSelector(selectState, ({ stageState }) => stageState)

export const selectUserListActive = createSelector(
  selectState,
  ({ users }) => users?.filter((item) => item.status === 10) || []
)
export const selectUserListArchive = createSelector(
  selectState,
  ({ users }) => users?.filter((item) => item.status === 0) || []
)

export const selectSelectedUser = createSelector(selectState, ({ selectedUser }) => selectedUser)

export const selectDictionariesMap = createSelector(
  selectState,
  ({ dictionariesDropdown }) => dictionariesDropdown || {}
)

export const selectDictionaryOptionsByName = (name: TDictionariesName) =>
  createSelector(
    selectDictionariesMap,
    // @ts-ignore
    (dictionariesDropdown) => dictionariesDropdown[name] || []
  )

export const selectStores = createSelector(selectState, ({ stores }) => stores || [])

export const selectSelectedStore = createSelector(selectState, ({ selectedStore }) => selectedStore)

export const selectCreateOrEditStore = createSelector(selectState, ({ createOrEditStore }) => createOrEditStore)

export const selectDefDataStore = createSelector(selectState, ({ defDataStore }) => defDataStore)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectStoreHeaders = createSelector(selectState, ({ headersStore }) => headersStore)

export const selectDefDataUser = createSelector(selectState, ({ defDataUser }) => defDataUser)

export const selectUsersHeaders = createSelector(selectState, ({ headersUser }) => headersUser)

export const selectDefDataRole = createSelector(selectState, ({ defDataRole }) => defDataRole)

export const selectRoleHeaders = createSelector(selectState, ({ headersRole }) => headersRole)

export const selectYandexObjects = createSelector(selectState, ({ yandexObject }) => yandexObject)

export const selectWhatsappTemplates = createSelector(selectState, ({ whatsappTemplates }) => whatsappTemplates)
export const selectWhatsappHeaders = createSelector(selectState, ({ headersWhatsapp }) => headersWhatsapp)
export const selectDefDataWatsapp = createSelector(selectState, ({ defDataWhatsapp }) => defDataWhatsapp)
export const selectWhatsappForm = createSelector(selectState, ({ whatsappForm }) => whatsappForm)
export const selectActiveWhatsappTemplate = createSelector(
  selectState,
  ({ activeWhatsTemplate }) => activeWhatsTemplate
)

export const selectCallbacks = createSelector(selectState, ({ callbacks }) => callbacks)
export const selectCallbackForm = createSelector(selectState, ({ callbackSettingForm }) => callbackSettingForm)

export const selectDefDataCallback = createSelector(selectState, ({ defDataCallback }) => defDataCallback)
export const selectHeadersCallback = createSelector(selectState, ({ headersCallback }) => headersCallback)
export const selectActiveCallback = createSelector(selectState, ({ activeCallback }) => activeCallback)

export const selectReminders = createSelector(selectState, ({ reminders }) => reminders)
export const selectReminderForm = createSelector(selectState, ({ reminderForm }) => reminderForm)

export const selectDefDataReminders = createSelector(selectState, ({ defDataReminders }) => defDataReminders)
export const selectHeadersReminders = createSelector(selectState, ({ headersReminders }) => headersReminders)
export const selectActiveReminder = createSelector(selectState, ({ activeReminder }) => activeReminder)

export const selectTokens = createSelector(selectState, ({ tokens }) => tokens)
export const selectTokenForm = createSelector(selectState, ({ tokenForm }) => tokenForm)

export const selectDefDataTokens = createSelector(selectState, ({ defDataTokens }) => defDataTokens)
export const selectHeadersTokens = createSelector(selectState, ({ headersTokens }) => headersTokens)
export const selectActiveToken = createSelector(selectState, ({ activeToken }) => activeToken)

export const selectDropdownStageSetupKey = createSelector(
  selectState,
  ({ dropdownStageSetupKey }) => dropdownStageSetupKey
)
export const selectConstructorModal = createSelector(selectState, ({ modalConstructor }) => modalConstructor)

export const selectFieldConfig = createSelector(selectState, ({fieldConfig}) => fieldConfig)
