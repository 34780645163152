import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  selectDefDataTokens, selectHeadersTokens, selectLoading,
  selectTokenForm,
  selectTokens
} from '../selectors'
import {
  reset,
  setActiveToken,
  openTokenForm,
  updateToken,
  setDefDataTokens, getTokens
} from '..'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'

import EditPencil1 from '../../../common/svg/EditPencil1'
import ControlButton from '../../../common/components/Buttons/Button'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './Tokens.scss'
import NewTokens from './NewTokens'
import Archive from '../../../common/svg/Archive'
import Redo from '../../../common/svg/Redo'
import { TFormValues } from './CallBackSetting'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { optionsPagination } from '../../../common/components/options'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { Preloader } from '../../../common/components/Preloader/Preloader'

const mainCssClass = getCssClassName('tokens')

function Triggers() {
  const dispatch = useDispatch()
  const tokenForm = useSelector(selectTokenForm)
  const templates = useSelector(selectTokens)
  const loading = useSelector(selectLoading)
  const defData = useSelector(selectDefDataTokens)

  const headers = useSelector(selectHeadersTokens)
  const [page, setPage] = useState<number>(1)

  const { rbacControl } = useEntityContext()

  const {
    control, handleSubmit, getValues, watch, setValue
  } = useForm<TFormValues>({
    defaultValues: { perPagesCount: defData?.perPage || 10 }
  })

  const perPage = watch('perPagesCount')

  const handleEditTemplates = (item:any) => {
    dispatch(setActiveToken(item))
    dispatch(openTokenForm(true))
  }

  const handleArchiveTokens = (id:number) => {
    dispatch(updateToken({ id, is_active: false }))
  }

  const handleReturnArchive = (id:number) => {
    dispatch(updateToken({ id, is_active: true }))
  }

  const handleNextPage = useCallback(() => {
    if (page < headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(setDefDataTokens({ page, perPage }))
  }, [perPage, page])

  useEffect(() => {
    if (defData) {
      dispatch(getTokens({ page, perPage }))
    }
  }, [defData])

  return (
    <div className={mainCssClass}>

      { !tokenForm && (
        <div className={`${mainCssClass}_create`}>
          <div className={`${mainCssClass}_create_title`}>
            <Typography.Title level={3}>
              Токены
            </Typography.Title>

            <div className={`${mainCssClass}__pagination-container`}>
              <SelectFormControl
                className={`${mainCssClass}__pagination-count`}
                name="perPagesCount"
                control={control}
                options={optionsPagination}
                onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
              />

              <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                <Typography.Text className={`${mainCssClass}__pagination-font`}>
                  {stringCountsPagination}
                </Typography.Text>
                <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={headers && page >= headers?.total ? '#BFC5C0' : '#404542'} />
              </div>
            </div>

          </div>

          {loading && <Preloader size="md" />}
          {!loading && (
            <div className={`${mainCssClass}_create_action`}>

              <ControlButton typeButton="secondary" size="large" icon="left" className="button" onClick={() => dispatch(openTokenForm(true))}>
                <AddPlusSvg />
                Создать токен
              </ControlButton>

            </div>
          )}

          <div className={`${mainCssClass}_create_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_create_tokens-list`}>

                {
                  templates?.map((item) => (
                    <div
                      className={`${mainCssClass}_create_tokens-list_item`}
                    >
                      <div className={`${mainCssClass}_create_tokens-list_item_text`}>
                        <Typography.Text className={`${mainCssClass}_create_tokens-list_item_text_title`}>
                          {item.lid_name}
                        </Typography.Text>
                        <Typography.Text className={`${mainCssClass}_create_tokens-list_item_text_quantity`}>
                          {item.token}
                        </Typography.Text>
                      </div>
                      <div className={`${mainCssClass}_create_tokens-list_item_action`}>
                        {item.is_active
                          ? (
                            <>
                              <ControlButton typeButton="secondary" className="button" onClick={() => handleEditTemplates(item)}>
                                <EditPencil1 />
                              </ControlButton>
                              <ControlButton typeButton="secondary" className="button" onClick={() => handleArchiveTokens(item.id)}>
                                <Archive />
                              </ControlButton>
                            </>
                          ) : (
                            <ControlButton typeButton="secondary" className="button" onClick={() => handleReturnArchive(item.id)}>
                              <Redo />
                            </ControlButton>
                          )}
                      </div>
                    </div>
                  ))
                }
              </div>
            </NewScrollContainer>
          </div>
        </div>
      )}

      {tokenForm && rbacControl?.profile.create_access === 1 && (
        <NewTokens />
      )}
    </div>
  )
}
export default memo(withErrorBoundary(Triggers))
