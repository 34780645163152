/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import $api, { API_URL } from './http'
import { TRequestLeadsCSV } from '../../compositions/Leads'

export interface TLeadsResponse {
    ERR: string
    DATA: any
    Headers: {
      'x-pagination-total-count': number[],
      'x-pagination-page-count': number[],
      'x-pagination-current-page': number[],
    }
  }

export type TRequestLeads = {
    page: number
    perPage: number
    filters: string
    search: string
}

export async function fetchGetLeads(payload: TRequestLeads): Promise<AxiosResponse<TLeadsResponse>> {
  console.log(payload,'!')
  const response = await $api.get<TLeadsResponse>(`/api/v1/eventleads?page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filters ? payload.filters : ''}${payload.search ? `&search=${payload.search}` : ''}`)
  return response
}

export async function fetchPostCreateCSV(payload: TRequestLeadsCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/eventlead/export-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}`)
  return response
}

export async function fetchPostCreateMovemetCSV(payload: TRequestLeadsCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/eventlead/export-report-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}`)
  return response
}
