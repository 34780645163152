import React, {
  memo
} from 'react'
import {
  Typography
} from 'antd'
import './RefreshFrontModal.scss'
import { useDispatch } from 'react-redux'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { showOpenModalRefreshByTime } from '../../../compositions/InnerRouter/actions'
import CommonModalRefresh from '../../../common/components/CommonModalRefresh/CommonModalRefresh'

const mainCssClass = 'refresh-front-modal'
type TProps ={
  open: boolean
}
function RefreshFrontModal({ open }: TProps) {
  const dispatch = useDispatch()
  const handleModalClose = () => {
    dispatch(showOpenModalRefreshByTime(false))
  }
  const handleOk = () => {
    window.location.href = `${window.location.href}?nocache=${new Date().getTime()}`
  }

  return (
    <CommonModalRefresh
      centered
      open={open}
      onOk={handleOk}
      onCancel={handleModalClose}
      title="Неактуальные данные"
      className={mainCssClass}
      width="400px"
      typeButtonAccept="primary"
      okText="Перезагрузить"
      cancelText="Перезагрузить позже"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <Typography.Text>
          Отображаемые данные устарели, необходимо перезагрузить страницу, чтобы обновить их.

          Действие необязательное, но мы рекомендуем.
        </Typography.Text>
      </div>
    </CommonModalRefresh>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(RefreshFrontModal))
