import React from 'react'
import { TClassName } from '../types/props'

type TProps = {
  fill?: string
} & TClassName
const Ellipse: React.FC<TProps> = ({ className, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" className={className}>
    <circle cx="5" cy="5" r="5" fill={fill || '#DF1E12'} />
  </svg>
)
export default Ellipse
