import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'


export const openTaskCreateModal = createAction<boolean>('tasks/openTaskCreateModal')

export const openMoreInfoModal = createAction<boolean>('tasks/openMoreInfoModal')

export const reset = createAction('tasks/reset')

export type TRequestCreateTask = {
    user_id: number,
    name: string,
    description: string,
    status: 0 | 1,
    is_archive: boolean,
    deadline_at: string,
    candidate_id: number | string
}

export type TTasks = {
    type: string,
    label: string,
    total:number,
    list: TTask[]
}

export type TTask = {
    author_id: number,
    status: 0 | 1,
    name: string,
    description: string,
    created_at: string,
    deadline_at: string,
    author_label: string,
    task_id:number,
    is_archive:boolean
    candidate_id: number
    user_id: number
    user_label: string
    candidate_label: string
    candidate_phone: string
    target_id: null | number
    target_label: string
    is_main: number
}

export type TRequestTasks = {
    filter:string
    is_archive: boolean
}

export const createTask = createAction<TRequestCreateTask>('tasks/createTask')
export const getTasks = createAction<TRequestTasks>('tasks/getTasks')
export const setTasks = createAction<TTasks[]>('tasks/setTasks')

export const openTasksFilter = createAction<boolean>('tasks/openTasksFilter')

export type TRequestUpdateTask = {
    task_id: number
    is_archive?: boolean
    status?: 0|1
    name?: string,
    description?: string,
    deadline_at?: string,
    user_id?: number,
}
export const updateTask = createAction<TRequestUpdateTask>('tasks/updateTask')

export const setSelectTask = createAction<TTask | null>('tasks/setSelectTask')
export const setFilter = createAction<string>('tasks/setFilter')

export const setIsArchiveTask = createAction<boolean>('tasks/setIsArchiveTask')

export const getDictionaries = createAction<TDictionariesNames>('tasks/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('tasks/dictionaries/setDictionaries')

export const getNotifyTaskId = createAction<string>('tasks/getNotifyTaskId')
export const setNotifyTask = createAction<TTask | null>('tasks/setNotifyTask')