import React, { useEffect } from 'react'

function YandexMetrika() {
  const yandexMetrika = process.env.metrik_enabled
  useEffect(() => {
    if (yandexMetrika) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://mc.yandex.ru/metrika/tag.js'

      script.onload = () => {
        window.ym = window.ym || function () {
          // eslint-disable-next-line prefer-rest-params
          (window.ym.a = window.ym.a || []).push(arguments)
        }
        window.ym.l = 1 * new Date()
        window.ym(95526256, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          defer: true
        })
      }

      document.head.appendChild(script)

      // Создание noscript элемента
      const noscript = document.createElement('noscript')
      const img = document.createElement('img')
      img.src = 'https://mc.yandex.ru/watch/95526256'
      img.style = 'position:absolute; left:-9999px;'
      img.alt = ''
      noscript.appendChild(img)

      document.body.appendChild(noscript)

      // Очистка после размонтирования
      return () => {
        document.head.removeChild(script)
        document.body.removeChild(noscript)
      }
    }
  }, [yandexMetrika])

  return null
}

export default YandexMetrika
