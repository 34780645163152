/* eslint-disable react/jsx-one-expression-per-line */
import dayjs from 'dayjs'
import React, { memo, useCallback, useMemo } from 'react'
import { Button, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import SelectFormControl from '../../../../../common/components/formcontrol/SelectFormControl'
import withErrorBoundary from '../../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import filterNullAndEmptyKeys, {
  getRelocateDetailList,
  getTransferDetailList,
  getWhishesDetailList
} from '../../../utils'
import {
  openCertificateModal,
  openCoursesModal,
  openEditCertificateModal,
  openEditCoursesModal,
  openEditEducationModal,
  openEditExperienceModal,
  openEditInfoModal,
  openEditLanguageModal,
  openEditRecomendationModal,
  openEditRelocateModal,
  openEditSkillsModal,
  openEditTransferModal,
  openEditWishesModal,
  openEducationModal,
  openExperienceModal,
  openInfoModal,
  openLanguageModal,
  openRecomendationModal,
  openRelocateModal,
  openSkillsModal,
  openTransferModal,
  openWishesModal,
  selectActiveResume,
  selectCertificateModal,
  selectCoursesModal,
  selectEditCertificateModal,
  selectEditCoursesModal,
  selectEditEducationModal,
  selectEditExperienceModal,
  selectEditInfoModal,
  selectEditLanguageModal,
  selectEditRecomendationModal,
  selectEditRelocateModal,
  selectEditSkillsModal,
  selectEditTransferModal,
  selectEditWishesModal,
  selectEducationModal,
  selectExperienceModal,
  selectInfoModal,
  selectLanguageModal,
  selectRecomendationModal,
  selectRelocateModal,
  selectSkillsModal,
  selectTransferModal,
  selectWishesModal
} from '../../../../Resume'
import ModalSkillsCandidate from '../../../../Resume/components/ModalSkillsCandidate'
import EditPencil1 from '../../../../../common/svg/EditPencil2'
import { TClassName } from '../../../../../common/types/props'
import ModalCertificates from '../../../../Resume/components/ModalCertificates'
import ModalRecomendation from '../../../../Resume/components/ModalRecomendation'
import ModalInfoResume from '../../../../Resume/components/ModalInfoResume'
import ModalLanguage from '../../../../Resume/components/ModalLanguage'
import ModalCourses from '../../../../Resume/components/ModalCourses'
import ModalRelocate from '../../../../Resume/components/ModalRelocate'
import ModalTransferTime from '../../../../Resume/components/ModalTransferTime'
import ModalEducation from '../../../../Resume/components/ModalEducation'
import ModalExperienceResume from '../../../../Resume/components/ModalExperienceResume'
import ModalWishes from '../../../../Resume/components/ModalWishes'
import { DetailsList } from '../../../../../common/components/DetailsList'
import { useEntityContext } from '../../../../../common/context/EntityContextProvider'
import { DATE_DOTS_FORMAT } from '../../../../../common/utils/dateFormats'

type TFormValues = {
  chapter: string
}

type TProps = {
  readOnly: boolean
} & TClassName
function SummaryInfo({ className, readOnly }: TProps) {
  const dispatch = useDispatch()

  const modalSkills = useSelector(selectSkillsModal)
  const modalEditSkills = useSelector(selectEditSkillsModal)
  const cetrificate = useSelector(selectCertificateModal)
  const editCetrificate = useSelector(selectEditCertificateModal)
  const recomendationModal = useSelector(selectRecomendationModal)
  const editRecomendationModal = useSelector(selectEditRecomendationModal)
  const infoModal = useSelector(selectInfoModal)
  const editInfoModal = useSelector(selectEditInfoModal)
  const languageModal = useSelector(selectLanguageModal)
  const editLanguageModal = useSelector(selectEditLanguageModal)
  const coursesModal = useSelector(selectCoursesModal)
  const editCoursesModal = useSelector(selectEditCoursesModal)
  const relocateModal = useSelector(selectRelocateModal)
  const editRelocateModal = useSelector(selectEditRelocateModal)
  const transferModal = useSelector(selectTransferModal)
  const editTransferModal = useSelector(selectEditTransferModal)
  const educationModal = useSelector(selectEducationModal)
  const editEducationModal = useSelector(selectEditEducationModal)
  const experienceModal = useSelector(selectExperienceModal)
  const editExperienceModal = useSelector(selectEditExperienceModal)
  const wishesModal = useSelector(selectWishesModal)
  const editWishesModal = useSelector(selectEditWishesModal)
  const { rbacControl } = useEntityContext()

  const { control, watch } = useForm<TFormValues>()
  const activeResume = useSelector(selectActiveResume)

  const options = useMemo(() => filterNullAndEmptyKeys(activeResume), [activeResume])

  const handleChooseChapter = useCallback(() => {
    const chapter = watch('chapter')
    switch (chapter) {
    case 'Ключевые навыки':
      dispatch(openSkillsModal(true))
      break
    case 'Сертификаты':
      dispatch(openCertificateModal(true))
      break
    case 'Рекомендации':
      dispatch(openRecomendationModal(true))
      break
    case 'Дополнительная информация':
      dispatch(openInfoModal(true))
      break
    case 'Языки':
      dispatch(openLanguageModal(true))
      break
    case 'Курсы повышения квалификации':
      dispatch(openCoursesModal(true))
      break
    case 'Готовность к переезду/командировкам':
      dispatch(openRelocateModal(true))
      break

    case 'Гражданство, время пути до работы':
      dispatch(openTransferModal(true))
      break
    case 'Образование':
      dispatch(openEducationModal(true))
      break
    case 'Опыт работы':
      dispatch(openExperienceModal(true))
      break
    case 'Пожелания кандидата':
      dispatch(openWishesModal(true))
      break
    default:
      break
    }
  }, [])

  const handleSkillsCancel = useCallback(() => {
    dispatch(openSkillsModal(false))
    dispatch(openEditSkillsModal(false))
  }, [])

  const handleEditSkills = useCallback(() => {
    dispatch(openEditSkillsModal(true))
  }, [])

  const handleCertificateCancel = useCallback(() => {
    dispatch(openCertificateModal(false))
    dispatch(openEditCertificateModal(false))
  }, [])

  const handleEditCertificate = useCallback(() => {
    dispatch(openEditCertificateModal(true))
  }, [])

  const handleRecomendationCancel = useCallback(() => {
    dispatch(openRecomendationModal(false))
    dispatch(openEditRecomendationModal(false))
  }, [])

  const handleEditRecomendation = useCallback(() => {
    dispatch(openEditRecomendationModal(true))
  }, [])

  const handleInfoCancel = useCallback(() => {
    dispatch(openInfoModal(false))
    dispatch(openEditInfoModal(false))
  }, [])

  const handleEditInfo = useCallback(() => {
    dispatch(openEditInfoModal(true))
  }, [])

  const handleLanguageCancel = useCallback(() => {
    dispatch(openLanguageModal(false))
    dispatch(openEditLanguageModal(false))
  }, [])

  const handleEditLanguage = useCallback(() => {
    dispatch(openEditLanguageModal(true))
  }, [])

  const handleCoursesCancel = useCallback(() => {
    dispatch(openCoursesModal(false))
    dispatch(openEditCoursesModal(false))
  }, [])

  const handleEditCourses = useCallback(() => {
    dispatch(openEditCoursesModal(true))
  }, [])

  const handleRelocateCancel = useCallback(() => {
    dispatch(openRelocateModal(false))
    dispatch(openEditRelocateModal(false))
  }, [])

  const handleEditRelocate = useCallback(() => {
    dispatch(openEditRelocateModal(true))
  }, [])

  const handleTransferCancel = useCallback(() => {
    dispatch(openTransferModal(false))
    dispatch(openEditTransferModal(false))
  }, [])

  const handleEditTransfer = useCallback(() => {
    dispatch(openEditTransferModal(true))
  }, [])

  const handleEducationCancel = useCallback(() => {
    dispatch(openEducationModal(false))
    dispatch(openEditEducationModal(false))
  }, [])

  const handleEditEducation = useCallback(() => {
    dispatch(openEditEducationModal(true))
  }, [])

  const handleExperienceCancel = useCallback(() => {
    dispatch(openExperienceModal(false))
    dispatch(openEditExperienceModal(false))
  }, [])

  const handleEditExperience = useCallback(() => {
    dispatch(openEditExperienceModal(true))
  }, [])

  const handleWishesCancel = useCallback(() => {
    dispatch(openWishesModal(false))
    dispatch(openEditWishesModal(false))
  }, [])

  const handleEditWishes = useCallback(() => {
    dispatch(openEditWishesModal(true))
  }, [])

  const mainCSSClass = className

  return (
    <div style={{ height: '100%' }}>
      <div className={`${mainCSSClass}_list`}>
        {activeResume?.specialization && activeResume?.position && activeResume?.wages && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <DetailsList
                size="md"
                items={[{
                  label: 'Пожелания кандидата',
                  items: getWhishesDetailList(
                      activeResume.position as string,
                      activeResume.specialization,
                      activeResume.wages,
                      activeResume.labels.currency,
                      activeResume.labels.employment_list.map((el) => el.value).join(', '),
                      activeResume.labels.schedule_list.map((el) => el.value).join(', ')
                  )
                }]}
              />
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditWishes}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.can_relocate && activeResume?.can_business_trip && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <DetailsList
                size="md"
                items={[{
                  label: 'Готовность к переездам/командировкам',
                  items: getRelocateDetailList(
                    activeResume.labels.can_relocate.label,
                    activeResume.labels.can_business_trip.label
                  )
                }]}
              />
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditRelocate}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.skill_list && activeResume?.skill_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Ключевые навыки </Typography.Title>
              <Typography.Text className={`${mainCSSClass}_list_skills`}>
                {activeResume.skill_list.map((e) => e.label).join(' ')}
              </Typography.Text>
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditSkills}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.certificate_list && activeResume?.certificate_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Cертификаты </Typography.Title>

              {activeResume.certificate_list.map((e) => (
                <div>
                  <div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                      {e.number}
                    </Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>({e.name}) </Typography.Text>
                  </div>
                  <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.link}</Typography.Text>
                </div>
              ))}
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditCertificate}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.recommendation_list && activeResume?.recommendation_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Рекомендации </Typography.Title>

              {activeResume.recommendation_list.map((e) => (
                <div>
                  <div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>{e.fio}</Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      {e.position}{' '}
                    </Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      ({e.company})
                    </Typography.Text>
                  </div>
                  <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.contact}</Typography.Text>
                </div>
              ))}
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditRecomendation}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.info && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Дополнительная информация</Typography.Title>
              <Typography.Paragraph className={`${mainCSSClass}_list_skills`}>
                {activeResume.info}
              </Typography.Paragraph>
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditInfo}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.language && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Языки</Typography.Title>

              <Typography.Paragraph className={`${mainCSSClass}_list_skills`}>
                {activeResume.labels?.language.map((item) => `${item.label} - ${item.level}`).join(', ')}
              </Typography.Paragraph>
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditLanguage}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.course_list && activeResume?.course_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Курсы повышения квалификации </Typography.Title>

              {activeResume.course_list.map((e) => (
                <div>
                  <div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>{e.name}</Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      {e.completed_at}{' '}
                    </Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      ({e.labels.document})
                    </Typography.Text>
                  </div>
                  <Typography.Text className={`${mainCSSClass}_list_items_sertifLink`}>{e.side}</Typography.Text>
                </div>
              ))}
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditCourses}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.work_permit && activeResume?.transfer_time && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <DetailsList
                size="md"
                items={[{
                  label: 'Гражданство, время пути до работы',
                  items: getTransferDetailList(
                    activeResume.labels.citizenship,
                    activeResume.labels.work_permit,
                    activeResume.labels.transfer_time
                  )
                }]}
              />
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditTransfer}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.education_list && activeResume?.education_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Образование </Typography.Title>

              {activeResume.education_list.map((e) => (
                <div>
                  <div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>{e.name}</Typography.Text>
                    {e.period_start && e.period_end && (
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        ({e.period_start.slice(0, 4)}-{e.period_end.slice(0, 4)})
                      </Typography.Text>
                    )}
                    {e.period_start && !e.period_end && (
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        ({e.period_start.slice(0, 4)})
                      </Typography.Text>
                    )}
                  </div>

                  <div>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>Высшее</Typography.Text>
                    {e.faculty && (
                      <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                        {e.faculty}
                      </Typography.Text>
                    )}
                  </div>

                  {e.specialization && (
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      {e.specialization}
                    </Typography.Text>
                  )}
                </div>
              ))}
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditEducation}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}

        {activeResume?.experience_list && activeResume?.experience_list.length > 0 && (
          <div className={`${mainCSSClass}_list_items`}>
            <div>
              <Typography.Title level={5}>Опыт работы </Typography.Title>

              {activeResume.experience_list.map((e) => (
                <div className={`${mainCSSClass}_list_items_experience`}>
                  <div className={`${mainCSSClass}_list_items_experience_years`}>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                      {dayjs(e.period_start).format(DATE_DOTS_FORMAT)} - {e.period_end ? dayjs(e.period_end).format(DATE_DOTS_FORMAT) : 'По настоящее время'}
                    </Typography.Text>
                  </div>
                  <div className={`${mainCSSClass}_list_items_experience_info`}>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifNumber`}>
                      {e.organization}
                    </Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>Москва</Typography.Text>
                    <Typography.Text className={`${mainCSSClass}_list_items_sertifName`}>
                      {e.description}
                    </Typography.Text>
                  </div>
                </div>
              ))}
            </div>
            {!readOnly && (
              <Button className={`${mainCSSClass}_list_items_edit`} onClick={handleEditExperience}>
                <EditPencil1 />
              </Button>
            )}
          </div>
        )}
      </div>
      {!readOnly && (
        <div className={`${mainCSSClass}_footer`}>
          <SelectFormControl
            name="chapter"
            className={`${mainCSSClass}_footer_chapterSelect`}
            control={control}
            options={options}
          />
          <Button
            icon={<PlusOutlined />}
            className={`${mainCSSClass}_footer_chapterButton`}
            onClick={handleChooseChapter}
          >
            Добавить раздел
          </Button>

          {modalSkills && (
            <ModalSkillsCandidate
              resumeId={activeResume ? activeResume.id : ''}
              open={modalSkills}
              onCancel={handleSkillsCancel}
            />
          )}
          {cetrificate && (
            <ModalCertificates
              resumeId={activeResume ? activeResume.id : ''}
              open={cetrificate}
              onCancel={handleCertificateCancel}
            />
          )}
          {modalEditSkills && (
            <ModalSkillsCandidate
              resumeId={activeResume ? activeResume.id : ''}
              open={modalEditSkills}
              onCancel={handleSkillsCancel}
              editMode
            />
          )}
          {editCetrificate && (
            <ModalCertificates
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editCetrificate}
              onCancel={handleCertificateCancel}
            />
          )}
          {recomendationModal && (
            <ModalRecomendation
              resumeId={activeResume ? activeResume.id : ''}
              open={recomendationModal}
              onCancel={handleRecomendationCancel}
            />
          )}
          {editRecomendationModal && (
            <ModalRecomendation
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editRecomendationModal}
              onCancel={handleRecomendationCancel}
            />
          )}

          {infoModal && (
            <ModalInfoResume
              resumeId={activeResume ? activeResume.id : ''}
              open={infoModal}
              onCancel={handleInfoCancel}
            />
          )}

          {editInfoModal && (
            <ModalInfoResume
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editInfoModal}
              onCancel={handleInfoCancel}
            />
          )}

          {languageModal && (
            <ModalLanguage
              resumeId={activeResume ? activeResume.id : ''}
              open={languageModal}
              onCancel={handleLanguageCancel}
            />
          )}

          {editLanguageModal && (
            <ModalLanguage
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editLanguageModal}
              onCancel={handleLanguageCancel}
            />
          )}

          {coursesModal && (
            <ModalCourses
              resumeId={activeResume ? activeResume.id : ''}
              open={coursesModal}
              onCancel={handleCoursesCancel}
            />
          )}

          {editCoursesModal && (
            <ModalCourses
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editCoursesModal}
              onCancel={handleCoursesCancel}
            />
          )}

          {relocateModal && (
            <ModalRelocate
              resumeId={activeResume ? activeResume.id : ''}
              open={relocateModal}
              onCancel={handleRelocateCancel}
            />
          )}

          {editRelocateModal && (
            <ModalRelocate
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editRelocateModal}
              onCancel={handleRelocateCancel}
            />
          )}

          {transferModal && (
            <ModalTransferTime
              resumeId={activeResume ? activeResume.id : ''}
              open={transferModal}
              onCancel={handleTransferCancel}
            />
          )}

          {editTransferModal && (
            <ModalTransferTime
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editTransferModal}
              onCancel={handleTransferCancel}
            />
          )}

          {educationModal && (
            <ModalEducation
              resumeId={activeResume ? activeResume.id : ''}
              open={educationModal}
              onCancel={handleEducationCancel}
            />
          )}

          {editEducationModal && (
            <ModalEducation
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editEducationModal}
              onCancel={handleEducationCancel}
            />
          )}

          {experienceModal && (
            <ModalExperienceResume
              resumeId={activeResume ? activeResume.id : ''}
              open={experienceModal}
              onCancel={handleExperienceCancel}
            />
          )}

          {editExperienceModal && (
            <ModalExperienceResume
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editExperienceModal}
              onCancel={handleExperienceCancel}
            />
          )}

          {wishesModal && (
            <ModalWishes
              resumeId={activeResume ? activeResume.id : ''}
              open={wishesModal}
              onCancel={handleWishesCancel}
            />
          )}

          {editWishesModal && (
            <ModalWishes
              resumeId={activeResume ? activeResume.id : ''}
              editMode
              open={editWishesModal}
              onCancel={handleWishesCancel}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(SummaryInfo))
