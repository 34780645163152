import React, { memo, useEffect, useMemo } from 'react'
import { Typography } from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectConstructorModal, selectDropdownStageSetupKey, selectFieldConfig, selectVertices } from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'

import ArrowDownSM from '../../../common/svg/ArrowDownSM'
import ArrowUpSM from '../../../common/svg/ArrowUpSM'
import TrashFull from '../../../common/svg/TrashFull'
import Redo from '../../../common/svg/Redo'
import './CandidateConstructor.scss'
import {
  decrementFieldConfig,
  getFieldConfig,
  incrementFieldConfig,
  setConstructorModal,
  updateFieldConfig
} from '../actions'
import ConstructorModal from './ConstructorModal'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'

const mainCssClass = getCssClassName('candidate-constructor')

export type TCandidateConstructorProps = {
  department_id?: number
}

type TDescription = {
  type: number
  dictionary_name?: string
}

function CandidateConstructor({ department_id }: TCandidateConstructorProps) {
  const dispatch = useDispatch()
  const { control, getValues } = useForm()
  const vertices = useSelector(selectVertices)

  const constructorKey = useSelector(selectDropdownStageSetupKey)
  const constructorModal = useSelector(selectConstructorModal)
  const fieldConfig = useSelector(selectFieldConfig)

  const [verticesLabel, vertices_id] = useMemo(() => {
    if (department_id && vertices) {
      const department = vertices.find((el) => el.id === department_id)
      return [department?.name, department?.department]
    }
    return ['', '']
  }, [department_id, vertices])

  const dropdownKeyTitle = useMemo(() => {
    switch (constructorKey) {
      case 'candidate':
        return 'Карточка кандидата'
      case 'request':
        return 'Карточка заявки'
      case 'funnel':
        return 'Карточка воронки'
      default:
        return ''
    }
  }, [constructorKey])

  const handleClickOpenModal = () => {
    dispatch(setConstructorModal(true))
  }

  const handleChangeSwitch = (id: number, e: boolean) => {
    dispatch(updateFieldConfig({ id, is_required: e }))
  }

  const handleArchive = (id: number) => {
    dispatch(updateFieldConfig({ id, status: 0 }))
  }

  const handleRemoveArchive = (id: number) => {
    dispatch(updateFieldConfig({ id, status: 1 }))
  }

  const handleClickIncrement = (id: number) => {
    dispatch(
      incrementFieldConfig({
        data: { id },
        filter: `&filter[department_id]=${vertices_id}&filter[model]=${constructorKey}`
      })
    )
  }

  const handleClickDecremet = (id: number) => {
    dispatch(
      decrementFieldConfig({
        data: { id },
        filter: `&filter[department_id]=${vertices_id}&filter[model]=${constructorKey}`
      })
    )
  }

  const typeDescription = ({ type, dictionary_name }: TDescription) => {
    switch (type) {
      case 1:
        return 'Свободный ввод значения'
      case 2:
        return `Значение из справочника: ${dictionary_name}`
      case 3:
        return 'Дата'
      case 4:
        return 'Ответ да/нет'
      case 5:
        return 'Дата период'
      default:
        return 'Ребята, надо проставить типы, справочники и тд + кэш не сбрасывается, чекай свитчер :)'
    }
  }

  useEffect(() => {
    if (vertices_id && constructorKey) {
      dispatch(getFieldConfig({ filter: `&filter[department_id]=${vertices_id}&filter[model]=${constructorKey}` }))
    }
  }, [vertices_id, constructorKey])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_header`}>
        <div className={`${mainCssClass}_header_title`}>
          <Typography.Title level={3}>{dropdownKeyTitle}</Typography.Title>
          <Typography.Text className={`${mainCssClass}_vertices`}>{verticesLabel}</Typography.Text>
        </div>

        <ControlButton className="button" typeButton="secondary" onClick={handleClickOpenModal}>
          <AddPlusSvg />
          Добавить поле
        </ControlButton>
      </div>

      <div className={`${mainCssClass}_list`}>
        <NewScrollContainer>
          {fieldConfig?.map((el, i) => (
            <div className={cn(`${mainCssClass}_list_item`, { [`${mainCssClass}_list_item-archive`]: !el.status })}>
              <div className={`${mainCssClass}_list_item_container`}>
                <div className={`${mainCssClass}_list_item_container_actions`}>
                  <ArrowUpSM
                    isDisabled={i === 0}
                    className={cn({
                      [`${mainCssClass}_arrow_pointer`]: i !== 0,
                      [`${mainCssClass}_arrow_notAllowed`]: i === 0
                    })}
                    onClick={() => handleClickIncrement(el.id)}
                  />
                  <ArrowDownSM
                    isDisabled={i === fieldConfig.length - 1}
                    className={cn({
                      [`${mainCssClass}_arrow_pointer`]: i !== fieldConfig.length - 1,
                      [`${mainCssClass}_arrow_notAllowed`]: i === fieldConfig.length - 1
                    })}
                    onClick={() => handleClickDecremet(el.id)}
                  />
                </div>
                <div className={`${mainCssClass}_list_item_container_text-container`}>
                  <Typography.Text className={`${mainCssClass}_list_item_container_text-container_label`}>
                    {el.label}
                  </Typography.Text>
                  <Typography.Text className={`${mainCssClass}_list_item_container_text-container_type`}>
                    {typeDescription({ type: el.type, dictionary_name: el.dictionary_name })}
                  </Typography.Text>
                </div>
              </div>
              <div className={`${mainCssClass}_list_item_action`}>
                <div className={`${mainCssClass}_list_item_action_switch`}>
                  <SwitchFormControl
                    control={control}
                    name={`is_required${el.id}`}
                    defaultValue={el.is_required}
                    onChange={(e) => handleChangeSwitch(el.id, e)}
                    disabled={el.type === 6}
                  />
                  <p>Обязательное поле</p>
                </div>
                {!!el.status && (
                  <TrashFull
                    color="#EF544B"
                    isDisabled={el.type === 6}
                    onClick={() => handleArchive(el.id)}
                    className={cn({
                      [`${mainCssClass}_arrow_pointer`]: el.type !== 6,
                      [`${mainCssClass}_arrow_notAllowed`]: el.type === 6
                    })}
                  />
                )}
                {!el.status && (
                  <Redo
                    onClick={() => handleRemoveArchive(el.id)}
                    className={cn({
                      [`${mainCssClass}_arrow_pointer`]: el.type !== 6,
                      [`${mainCssClass}_arrow_notAllowed`]: el.type === 6
                    })}
                    isDisabled={el.type === 6}
                  />
                )}
              </div>
            </div>
          ))}
        </NewScrollContainer>
      </div>
      {constructorModal && (
        <ConstructorModal
          open={constructorModal}
          department_id={Number(vertices_id)}
          model={constructorKey}
          filter={`&filter[department_id]=${vertices_id}&filter[model]=${constructorKey}`}
        />
      )}
    </div>
  )
}

export default memo<TCandidateConstructorProps>(withErrorBoundary<TCandidateConstructorProps>(CandidateConstructor))
