import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography, Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import UserCardID from '../../../common/svg/UserCardID'
import RequestPageSvg from '../../../common/svg/RequestPageSvg'
import { setPreviousPage } from '../../InnerRouter/actions'
import { selectDictionaryOptionsByName, selectRequest } from '../selectors'
import { navigateTo } from '../../../common/actions/navigate'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import AvatarSizeSmall from '../../../common/svg/AvatarSizeSmall'
import CloseSM from '../../../common/svg/CloseSM'
import { removeRequestCandidateData, setOpenModalRemove } from '../actions'
import ControlButton from '../../../common/components/Buttons/Button'
import './CandidateTabRequest.scss'
import { selectUserInfo } from '../../InnerRouter/selectors'

const { Title } = Typography
const mainCSSClass = 'candidateTabRequest'

type TFormValues = {
  stage: number | null
  status: any[]
}

const statusOptions = [{ label: 'Показывать отклоненных', value: '1' }]

function CandidateTabRequest() {
  const dispatch = useDispatch()
  const request = useSelector(selectRequest)
  const userInfo = useSelector(selectUserInfo)
  const stageOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const finalStageOptions = useMemo(() => (stageOptions ? [{ label: 'Все', value: null }, ...stageOptions] : null), [stageOptions])

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>(
    {
      defaultValues: {
        stage: null,
        status: []
      }
    }
  )

  const handleNavigate = useCallback((id: number) => {
    dispatch(navigateTo(`/candidate/${id}`))
    dispatch(setPreviousPage({ navigate: `/application/${request?.id}`, caption: 'Карточка заявки' }))
  }, [])

  const handleRemove = (candidateId: number) => {
    if (request?.id) {
      dispatch(setOpenModalRemove(true))
      dispatch(removeRequestCandidateData({ candidateId, requestId: request.id }))
    }
  }

  const handleNavigateToCandidates = () => {
    dispatch(navigateTo('/candidates'))
  }

  const stageId = watch('stage')
  const status = watch('status')

  const candidateList = useMemo(
    () => {
      if (stageId && status && status.length > 0) {
        return request?.candidate_list?.filter((el) => el.stage_id === stageId)
      }
      if (stageId && status && status.length === 0) {
        return request?.candidate_list?.filter((el) => el.stage_id === stageId && el.status !== 51)
      }
      if (!stageId && status && status.length > 0) {
        return request?.candidate_list
      }
      return request?.candidate_list?.filter((el) => el.status !== 51)
    },

    [request, stageId, status]
  )

  const isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={mainCSSClass}>

      {
        isMobile ? (
          <>
            {request?.candidate_list?.length === 0 && (
              <div className={`${mainCSSClass}_empty`}>
                <RequestPageSvg />
                <Title level={5}>Кандидатов нет</Title>
                <p>Добавьте кандидатов в данную заявку и они появятся в этом списке</p>
                <Button onClick={handleNavigateToCandidates}>Перейти к кандидатам</Button>
              </div>
            )}

            {request?.candidate_list?.length !== 0 && request?.candidate_list

        && (
          <div className={`${mainCSSClass}_filter`}>
            <div className={`${mainCSSClass}_filter_select`}>
              <SelectFormControl control={control} name="stage" options={finalStageOptions || []} placeholder="Выбрать этап воронки" />
              <Typography.Text>
                {`Всего: ${candidateList?.length} кандидатов`}
              </Typography.Text>
            </div>
            <div>
              <CheckBoxFormControl control={control} name="status" options={statusOptions} />

            </div>
          </div>
        )}

            {candidateList?.length !== 0
        && candidateList?.map((el) => (
          <div key={el?.id} className={`${mainCSSClass}_items`}>
            {el.photo ? <img src={el.photo} alt="avatar" className={`${mainCSSClass}_items_photo`} /> : <AvatarSizeSmall /> }
            <div className={`${mainCSSClass}_items_info`}>
              <div className={`${mainCSSClass}_items_info_name`}>{`${el?.last_name} ${el?.name} ${el?.second_name || ''}`}</div>
              <div className={`${mainCSSClass}_items_info_age`}>{el.status === 51 ? <span style={{ color: 'red' }}>Отклоненный</span> : `${el?.stage_label}`}</div>
            </div>
            <div className={`${mainCSSClass}_items_actions`}>
              <Tooltip placement="top" title="Перейти к кандидату">

                <ControlButton typeButton="secondary" className="button" onClick={() => handleNavigate(el.id)} size="small">
                  <NavLink to={`/candidate/${el.id}`}>
                    <UserCardID />
                  </NavLink>
                </ControlButton>

              </Tooltip>
              {userInfo?.role?.role_id !== 'courier_curator' && (
                <Tooltip placement="top" title="Открепить кандидата">
                  <ControlButton typeButton="warning" className="button" onClick={() => handleRemove(el.id)} size="small">
                    <CloseSM />
                  </ControlButton>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
          </>
        ) : (
          <NewScrollContainer className={`${mainCSSClass}_scroll`}>
            {request?.candidate_list?.length === 0 && (
              <div className={`${mainCSSClass}_empty`}>
                <RequestPageSvg />
                <Title level={5}>Кандидатов нет</Title>
                <p>Добавьте кандидатов в данную заявку и они появятся в этом списке</p>
                <Button onClick={handleNavigateToCandidates}>Перейти к кандидатам</Button>
              </div>
            )}

            {request?.candidate_list?.length !== 0 && request?.candidate_list

        && (
          <div className={`${mainCSSClass}_filter`}>
            <div className={`${mainCSSClass}_filter_select`}>
              <SelectFormControl control={control} name="stage" options={finalStageOptions || []} placeholder="Выбрать этап воронки" />
              <Typography.Text>
                {`Всего: ${candidateList?.length} кандидатов`}
              </Typography.Text>
            </div>
            <div>
              <CheckBoxFormControl control={control} name="status" options={statusOptions} />

            </div>
          </div>
        )}

            {candidateList?.length !== 0
        && candidateList?.map((el) => (
          <div key={el?.id} className={`${mainCSSClass}_items`}>
            {el.photo ? <img src={el.photo} alt="avatar" className={`${mainCSSClass}_items_photo`} /> : <AvatarSizeSmall /> }
            <div className={`${mainCSSClass}_items_info`}>
              <div className={`${mainCSSClass}_items_info_name`}>{`${el?.last_name} ${el?.name} ${el?.second_name || ''}`}</div>
              <div className={`${mainCSSClass}_items_info_age`}>{el.status === 51 ? <span style={{ color: 'red' }}>Отклоненный</span> : `${el?.stage_label}`}</div>
            </div>
            <div className={`${mainCSSClass}_items_actions`}>
              <Tooltip placement="top" title="Перейти к кандидату">

                <ControlButton typeButton="secondary" className="button" onClick={() => handleNavigate(el.id)}>
                  <NavLink to={`/candidate/${el.id}`}>
                    <UserCardID />
                  </NavLink>
                </ControlButton>

              </Tooltip>
              {userInfo?.role?.role_id !== 'courier_curator' && (
                <Tooltip placement="top" title="Открепить кандидата">
                  <ControlButton typeButton="warning" className="button" onClick={() => handleRemove(el.id)}>
                    <CloseSM />
                  </ControlButton>
                </Tooltip>
              )}

            </div>
          </div>
        ))}
          </NewScrollContainer>
        )
      }

    </div>
  )
}

export default CandidateTabRequest
