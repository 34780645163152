import React, {
  memo, useEffect, useMemo, useRef, useState
} from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import OneMessage from './OneMessage'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import './Messages.scss'
import { selectActualEmailMessages, selectMailMessages, selectPageMessages } from '../selectors'
import {
  getMailMessages, resetMailMessages, setActualEmailMessages, setMailMessages, setPageMessages
} from '../actions'
import { selectCandidate } from '../../../compositions/candidate/selectors'
import { formatTimestamp } from '../utils'
import FoodPattern from '../../../common/svg/FoodPattern'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { selectSystemColor } from '../../../compositions/InnerRouter/selectors'

const MainCSSClass = 'messages'

function Messages() {
  const dispatch = useDispatch()
  const scrollbarsRef = useRef(null)
  const page = useSelector(selectPageMessages)
  const messageArr = useSelector(selectMailMessages)
  const candidate = useSelector(selectCandidate)
  const actualPage = useSelector(selectActualEmailMessages)
  const systemColor = useSelector(selectSystemColor)

  const parse = useMemo(() => null, [])

  const handleScroll = () => {
    if (scrollbarsRef.current) {
      const scrollContainer = scrollbarsRef.current
      if (page && scrollContainer?.scrollTop === 0) {
        if (actualPage && actualPage - 1 > 0 && candidate?.email) {
          dispatch(getMailMessages({ page: actualPage - 1, perPage: 20, identifier: candidate.id }))
          dispatch(setActualEmailMessages(actualPage - 1))
        }
      }
    }
  }

  const svgClasses = useMemo(
    () => cn(
      `${MainCSSClass}_svg`,
      `${MainCSSClass}_svg-mail`,
      {
        [`${MainCSSClass}_svg-blue`]: systemColor === 'blue',
        [`${MainCSSClass}_svg-green`]: systemColor === 'green',
        [`${MainCSSClass}_svg-tomato`]: systemColor === 'tomato',
        [`${MainCSSClass}_svg-beet`]: systemColor === 'beet',
        [`${MainCSSClass}_svg-carrot`]: systemColor === 'carrot',
        [`${MainCSSClass}_svg-bread`]: systemColor === 'bread',
        [`${MainCSSClass}_svg-plum`]: systemColor === 'plum'
      }
    ),
    [MainCSSClass, systemColor]
  )

  // useEffect(() => {
  //   if (messageArr && parse) {
  //     if ('message_status_id' in parse) {
  //       dispatch(setMailMessages(
  //         messageArr.map((obj) => ({
  //           ...obj,
  //           message_status_id: obj.message_status_id === parse.message_status_id ? null : obj.message_status_id
  //         }))
  //       ))
  //     } else { dispatch(setMailMessages([parse, ...messageArr])) }
  //   }
  // }, [parse])

  const messagesLoaded = messageArr.length > 0

  useEffect(() => {
    if (scrollbarsRef.current && messagesLoaded) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToBottom()
    }
  }, [messagesLoaded])

  const candidateEmailMemo = useMemo(() => (candidate?.email ? candidate.email[0] : null), [candidate?.email])

  useEffect(() => {
    if (candidate && candidate.email && candidate.email.length > 0) {
      dispatch(getMailMessages({ page: 1, perPage: 20, identifier: candidate.id }))
    }
  }, [candidateEmailMemo])

  useEffect(
    () => () => {
      dispatch(resetMailMessages())
    },
    []
  )

  return (
    <div className={`${MainCSSClass}`}>
      <FoodPattern className={svgClasses} size="2" />
      <div className={`${MainCSSClass}_chat`}>
        <NewScrollContainer
          className={`${MainCSSClass}_scroll-container_scroll`}
          scrollbarsRef={scrollbarsRef}
          onScroll={handleScroll}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              gap: '1rem',
              width: '96%'
            }}
          >
            {messageArr
              ?.map((el) => ({
                status: el.direction,
                name: el.userInfo?.userName,
                date: formatTimestamp(el.created_at),
                text: el.messageContent?.text,
                timestamp: el.created_at,
                messageId: 'message_status_id' in el ? el.message_status_id : null
              }))
              .map((el) => (
                <OneMessage props={el} key={el.timestamp} />
              ))}
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(Messages))
