import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import MenuDrop from '../../../common/svg/MenuDrop'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectAutoCalls } from '../selectors'
import { setSelectedAutoCall, startAutoCall, stopAutoCall } from '../actions'

type TProps = {
  id: any
  handleOpenModal: Function
  status: any
}
const ButtonsTable: FC<TProps> = ({ id, handleOpenModal, status }) => {
  const dispatch = useDispatch()
  const autoCalls = useSelector(selectAutoCalls)
  const findedAutoCall = useMemo(() => autoCalls.find((item) => item.id === id), [autoCalls])

  const handleSelectAutoCall = useCallback(() => {
    if (findedAutoCall) {
      dispatch(setSelectedAutoCall(findedAutoCall))
      handleOpenModal()
    }
  }, [findedAutoCall])

  const handleStartAutoCall = () => {
    dispatch(startAutoCall(id))
  }
  const handleStopAutoCall = () => {
    dispatch(stopAutoCall(id))
  }

  const items: MenuProps['items'] = [
    {
      key: 'stop',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={() => handleSelectAutoCall}
        >
          Редактировать
        </Button>
      )
    },
    ...(status !== 5 ? [{
      key: 'reboot',
      label: (
        <Button type="ghost" size="large" onClick={handleStopAutoCall}>
          Остановить
        </Button>
      )
    }] : []),
    ...(status === 5 ? [{
      key: 'start',
      label: (
        <Button type="ghost" size="large" onClick={handleStartAutoCall}>
          Запустить
        </Button>
      )
    }] : [])
  ]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
