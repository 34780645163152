import {
  put, call, takeLatest, spawn, select, race, take
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse, AxiosError } from 'axios'
import {
  createCandidate,
  createLinkCandidate,
  createNewComment,
  createTagCandidate,
  delTagCandidate,
  deleteCandidate,
  getCandidate,
  getDictionaries,
  removeFromRequest,
  setCandidate,
  setDictionaries,
  setLoading,
  updateCandidate,
  updateStage,
  setQuestionnaire,
  getQuestionnaire,
  createQuestionnaire,
  TCandidateQuestionnaire,
  setIsEditModeQuestionnaire,
  getCandidateUpdate,
  setEmptySlots,
  getEmptySlots,
  addInterview,
  TAddInterview,
  setNewInterview,
  createNote,
  TRequestAppoint,
  appointRecruiter,
  appointReseacher,
  openRecruiterModal,
  openReseacherModal,
  TRequestStageStatusCandidate,
  openStageStatusModal,
  stageStatusCandidate,
  removeDocuments,
  TDocument,
  TGetAllEmptySlots,
  setComments,
  getComments,
  TRequestPatchCandidateComments,
  updateComments,
  setCommentsPage,
  getHistoryCandidateData,
  setHistoryCandidateData,
  setTotalCommentsPage,
  getStageStatusByStage,
  setStageStatus,
  appointAll,
  TRequestPhoneFind,
  setPhoneError,
  getPhoneFind,
  getEmailFind,
  setEmailError,
  setOpenModalMap,
  setAssignModal,
  setLoadingSlots,
  deleteComment,
  openDeleteCommentModal,
  openCommentModal,
  setSelectedComment,
  removeRequestLink,
  TRequestRemoveRequestLink,
  mergeDuplicates,
  setHeaders,
  TRequestCandidateHistory,
  setCandidateDuplicate,
  getCandidateDuplicate,
  openDuplicateModal,
  setRespondsCandidate,
  getRespondsCandidate,
  respondHandler,
  setOldHistoryCandidateData,
  getOldHistoryCandidateData,
  setIsLoadingRespondHandler,
  setCandidateMergeDuplicate,
  openMergeDuplicateModal,
  getCandidateMergeDuplicate,
  setHistoryAsterisk,
  getHistoryAsterisk,
  showLimitationStageCandidateModal, setMessageLimitationCandidate, getStagesListing, setStageListing,
  TRequestInnFind, setInnError, getInnFind,
  TRequestRespondHandler,
  setCandidateDocument,
  getCandidateDocument,
  setCandidateQuestionnaire,
  getCandidateQuestionnaire, getHistoryCandidateV2, setHistoryCandidateV2,
  openEditCandidateDrawer,
  setUpdateCandidateMode
} from './actions'
import {
  TAddTag,
  TCandidate,
  TCandidateComments,
  TCandidateResponseUpdate,
  TComment,
  TCreateLink,
  TDATA,
  TDelTag,
  TRemoveFromRequest,
  TRequestNote,
  TStageRequest
} from './types'
import {
  TRequestCandidateComments,
  fetchDeleteCandidate,
  fetchDeleteRequestCandidate,
  fetchDeleteTag,
  fetchGetCandidate,
  fetchGetCandidateComments,
  fetchGetQuestionnaire,
  fetchPatchCandidate,
  fetchPatchCandidateComments,
  fetchPatchUpdateStage,
  fetchPostAddComment,
  fetchPostAddTag,
  fetchPostCandidate,
  fetchPostQuestionnaire,
  fetchPostRemoveDocument,
  fetchGetHistoryCandidateData,
  fetchGetStageState,
  fetchPostFindPhone,
  fetchDeleteComment,
  fetchPostDuplicatesCandidates,
  fetchGetCandidateDuplicateList,
  fetchGetRespondsCandidate,
  fetchPostRespondHandler,
  TResponseOldHistoryCandidateData, fetchGetOldHistoryCandidateData, fetchGetHistoryAsterisk,
  fetchGetCandidateDocument,
  fetchGetCandidateQuestionnaire, fetchGetCandidateHistoryV2
} from '../../common/api/Candidate'
import { TDictionariesNames, TOption } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { fetchDeleteRequestLink, fetchPostCreateLinkRequest } from '../../common/api/request'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  selectCandidate,
  selectCandidateComments,
  selectCommentsPage,
  selectDocumentList,
  selectEditCandidateDrawer,
  selectEmailError,
  selectPhoneError,
  selectUpdateCandidateMode
} from './selectors'
import { fetchGetEmptySlots, fetchPostCalendarAddInterview } from '../../common/api/calendar'
import { navigateTo } from '../../common/actions/navigate'
import { setEditKeyDetailList, setLoadingModal, setLoadingProgress } from '../InnerRouter/actions'
import { setCandidateResume } from '../Resume'
import { getRequest } from '../Request/actions'
import { fetchGetV1DictionariesList } from '../../common/api/dictionaries'
import { TResponse } from '../../common/types/response'
import {fetchGetRejectionInfo} from "../../common/api/analytics";
import {getRejectionInfo} from "../analytics/actions";
import { selectEditKeyDetailList } from '../InnerRouter/selectors'

type CandidateResponse = AxiosResponse<TCandidate>
type TAxiosResponseOldHistoryCandidateData = AxiosResponse<TResponseOldHistoryCandidateData>

// type TResponseVacancy = AxiosResponse<TResponse<TVacancy>>

const moduleName = 'Страница кандидата'
function* getCandidateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    const { response } = yield race({
      response: call(fetchGetCandidate, payload),
      cancel: take(getCandidate.type)});

    if (response.data.status === 403) {
      yield put(navigateTo('is-candidate-group-error'))
    }
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setLoading(true))
      yield put(setCandidate(response.data.DATA))
      yield put(setCandidateResume(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getHistoryCandidateDataSaga({ payload }: PayloadAction<TRequestCandidateHistory>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: TAxiosResponseOldHistoryCandidateData;
    ({response} = yield race({
      response: fetchGetHistoryCandidateData(payload),
      cancel: take(getHistoryCandidateData.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setHistoryCandidateData(response.data.DATA))
      yield put(setHeaders({ current: response.data.Headers['x-pagination-current-page'][0], total: response.data.Headers['x-pagination-page-count'][0] }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      // Если error - это экземпляр класса Error, можно безопасно получить его сообщение
      throw new Error(error.message)
    } else {
      // Если error не является экземпляром Error, вы можете обработать его по-другому
      throw new Error(String(error)) // Преобразуем в строку
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}
function* getOldHistoryCandidateDataSaga({ payload }: PayloadAction<TRequestCandidateHistory>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: TResponseOldHistoryCandidateData;
    ({response} = yield race({
      response: fetchGetOldHistoryCandidateData(payload),
      cancel: take(getOldHistoryCandidateData.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (response.data.DATA.history) {
      yield put(setOldHistoryCandidateData(response.data.DATA.history))
      yield put(setHeaders({ current: response.data.Headers['x-pagination-current-page'][0], total: response.data.Headers['x-pagination-page-count'][0] }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}
function* getCandidateUpdateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchGetCandidate(payload),
      cancel: take(getCandidateUpdate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* delCandidateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchGetCandidate(payload),
      cancel: take(deleteCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить кандидата',
          error: response
        })
      )
    } else {
      yield call(fetchDeleteCandidate, payload)

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateCandidateSaga({ payload }: PayloadAction<TCandidateResponseUpdate | TRequestNote>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(updateCandidate.type)
    }));
    const editMode: boolean = yield select(selectUpdateCandidateMode)
    const candidateDrawer: boolean = yield select(selectEditCandidateDrawer)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить кандидата',
          error: response
        })
      )
    } else {
      if (candidateDrawer) {
        yield put(openEditCandidateDrawer(false))
      }
      yield put(setCandidate(response.data.DATA))
      if (payload.questionnare_id && payload.id) {
        yield put(getCandidateQuestionnaire(payload.id))
      }
      if (editMode) {
        yield put(setUpdateCandidateMode(false))
      }
      const editKeyDetailList: string = yield select(selectEditKeyDetailList)
      if (editKeyDetailList) {
        yield put(setEditKeyDetailList(''))
      }
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}
function* createCandidateSaga({ payload }: PayloadAction<TDATA>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const candidateDrawer: boolean = yield select(selectEditCandidateDrawer)

    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPostCandidate(payload),
      cancel: take(createCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать кандидата',
          error: response
        })
      )
    } else {
      yield put(navigateTo(`/candidate/${response.data.DATA.id}`))
      if (candidateDrawer) {
        yield put(openEditCandidateDrawer(false))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))
  }
}
function* createCommentSaga({ payload }: PayloadAction<TComment>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostAddComment(payload),
      cancel: take(createNewComment.type)
    }));
    const comments = yield select(selectCandidateComments)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать комментарий',
          error: response
        })
      )
    } else {
      yield spawn(getCandidateCommentsSaga, getComments({ id: response.data.DATA.candidate_id, page: 1 }))
      yield put(setCommentsPage(1))
      yield put(openCommentModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* createTagSaga({ payload }: PayloadAction<TAddTag>) {
  try {
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPostAddTag(payload),
      cancel: take(createTagCandidate.type)
    }));
    yield put(setLoadingProgress(true))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать тэг',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* delTagSaga({ payload }: PayloadAction<TDelTag>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchDeleteTag(payload),
      cancel: take(delTagCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить тэг',
          error: response
        })
      )
    } else {
      yield fetchDeleteTag(payload)

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateStageSaga({ payload }: PayloadAction<TStageRequest>) {
  try {
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchUpdateStage(payload),
      cancel: take(updateStage.type)
    }));
    yield put(setLoadingProgress(true))
    if (response.data.DATA.code === 403) {
      yield put(showLimitationStageCandidateModal(true))
      yield put(setMessageLimitationCandidate(response.data.DATA.message))
    } else if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить этап',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA?.candidate_list))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* createLinkCandidateSaga({ payload }: PayloadAction<TCreateLink>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPostCreateLinkRequest(payload),
      cancel: take(createLinkCandidate.type)
    }));
    yield spawn(getCandidateSaga, getCandidate(String(payload.candidate_id)))
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось добавить ссылку кандидата',
          error: response
        })
      )
    } else {
      yield put(setOpenModalMap(false))
      yield put(setAssignModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'кандидат страница')
}

function* removeFromRequestSaga({ payload }: PayloadAction<TRemoveFromRequest>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchDeleteRequestCandidate(payload),
      cancel: take(removeFromRequest.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отвязать заявку',
          error: response
        })
      )
    } else {
      if (payload.isRequest) {
        yield put(getRequest(payload.request_id.toString()))
      } else {
        yield spawn(getCandidateSaga, getCandidate(String(payload.candidate_id)))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* getQuestionnaireSaga() {
  try {
    yield put(setLoadingProgress(true))
    const {response} = yield race({response: fetchGetQuestionnaire(), cancel: take(getQuestionnaire.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить анкету кандедата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setQuestionnaire(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}
function* createQuestionnaireSaga({ payload }: PayloadAction<TCandidateQuestionnaire>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostQuestionnaire(payload),
      cancel: take(createQuestionnaire.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать анкету кандидата',
          error: response
        })
      )
    } else {
      yield put(setIsEditModeQuestionnaire(false))
      const candidate: TDATA | null = yield select(selectCandidate)
      if (candidate && response) {
        yield put(getCandidateUpdate(candidate?.id?.toString()))
        yield put(getCandidateQuestionnaire(candidate.id))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* emptySlotSaga({ payload }: PayloadAction<TGetAllEmptySlots>) {
  try {
    yield put(setLoadingSlots(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetEmptySlots(payload),
      cancel: take(getEmptySlots.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setEmptySlots(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingSlots(false))
    yield put(setLoadingProgress(false))
  }
}

function* addInterviewSaga({ payload }: PayloadAction<TAddInterview>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: call(fetchPostCalendarAddInterview, payload),
      cancel: take(addInterview.type)
    }));

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else if (response.data.DATA?.interview?.errors) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Назначение интервью на данную вакансию запрещено',
          error: response
        })
      )
    } else {
      yield put(getCandidateUpdate(payload?.candidate_id?.toString()))
      yield put(setNewInterview(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* createNoteSaga({ payload }: PayloadAction<TRequestNote>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(createNote.type)
    }));

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить кандидата',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* appointRecruiterSaga({ payload }: PayloadAction<TRequestAppoint>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(appointRecruiter.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось назначить рекрутера',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
      yield put(openRecruiterModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* appointAllSaga({ payload }: PayloadAction<TRequestAppoint>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(appointAll.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось назначить рекрутера и ресечера',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* appointReseacherSaga({ payload }: PayloadAction<TRequestAppoint>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(appointReseacher.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось назначить ресечера',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
      yield put(openReseacherModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* stageStatusCandidateSaga({ payload }: PayloadAction<TRequestStageStatusCandidate>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(stageStatusCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось изменить статус на этапе',
          error: response
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
      yield put(openStageStatusModal(false))


    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}
function* removeDocumentsSaga({ payload }: PayloadAction<TDocument>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPostRemoveDocument(payload.fileId),
      cancel: take(removeDocuments.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить документ кандидата',
          error: response
        })
      )
    } else {
      const prevDocuments = yield select(selectDocumentList)
      yield put(setCandidateDocument(prevDocuments.filter((el) => el.id !== Number(payload.fileId))))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateCommentsSaga({ payload }: PayloadAction<TRequestCandidateComments>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetCandidateComments(payload),
      cancel: take(getComments.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить комментарии кандидата',
          error: response
        })
      )
    } else {
      const comments = yield select(selectCandidateComments)
      if (payload.page === 1) {
        yield put(setComments(response.data.DATA))
        yield put(setTotalCommentsPage(response.data.Headers['x-pagination-page-count'][0]))
      } else {
        yield put(setComments([...comments, ...response.data.DATA]))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* getStageStatusSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetStageState(payload),
      cancel: take(getStageStatusByStage.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить статусы',
          error: response
        })
      )
    } else {
      yield put(setStageStatus(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateCommentsSaga({ payload }: PayloadAction<TRequestPatchCandidateComments>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPatchCandidateComments(payload),
      cancel: take(updateComments.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось сменить статус комментария',
          error: response
        })
      )
    } else {
      yield spawn(getCandidateCommentsSaga, getComments({ id: response.data.DATA.candidate_id, page: 1 }))
      yield put(setCommentsPage(1))
      yield put(openCommentModal(false))
      yield put(setSelectedComment(null))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* getPhoneFindSaga({ payload }: PayloadAction<TRequestPhoneFind>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))

    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostFindPhone(payload),
      cancel: take(getPhoneFind.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не получилось',
          error: response
        })
      )
    } else {
      const currentPhoneError: any[] = yield select(selectPhoneError)
      if (response.data.DATA.length) {
        const foundObject = currentPhoneError.find((obj) => obj.input_id === payload.input_id)
        if (Number(response.data.DATA[0]) !== Number(payload.candidate_id)) {
          if (foundObject) {
            yield put(setPhoneError(currentPhoneError
              .map((el) => (el.input_id === payload.input_id ? { input_id: el.input_id, candidate: response.data.DATA[0] } : el))))
          } else { yield put(setPhoneError([...currentPhoneError, { input_id: payload.input_id, candidate: response.data.DATA[0] }])) }
        }
      } else {
        const foundObject = currentPhoneError.find((obj) => obj.input_id === payload.input_id)
        if (foundObject) {
          yield put(setPhoneError(currentPhoneError.filter((el) => el.input_id !== payload.input_id)))
        }
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoadingModal(false))

  }
}
function* getInnFindSaga({ payload }: PayloadAction<TRequestInnFind>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostFindPhone(payload),
      cancel: take(getInnFind.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не получилось',
          error: response
        })
      )
    } else if (response.data.DATA.length) {
      if (payload.candidate_id !== response.data.DATA[0]) {
        yield put(setInnError([response.data.DATA[0]]))
      }
    } else {
      yield put(setInnError([]))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getEmailFindSaga({ payload }: PayloadAction<TRequestPhoneFind>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostFindPhone(payload),
      cancel: take(getEmailFind.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить заявки',
          error: response
        })
      )
    } else {
      const currentEmailError: any[] = yield select(selectEmailError)
      if (response.data.DATA.length) {
        const foundObject = currentEmailError.find((obj) => obj.input_id === payload.input_id)
        if (Number(response.data.DATA[0]) !== Number(payload.candidate_id)) {
          if (foundObject) {
            yield put(setEmailError(currentEmailError
              .map((el) => (el.input_id === payload.input_id ? { input_id: el.input_id, candidate: response.data.DATA[0] } : el))))
          } else { yield put(setEmailError([...currentEmailError, { input_id: payload.input_id, candidate: response.data.DATA[0] }])) }
        }
      } else {
        const foundObject = currentEmailError.find((obj) => obj.input_id === payload.input_id)
        if (foundObject) {
          yield put(setEmailError(currentEmailError.filter((el) => el.input_id !== payload.input_id)))
        }
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* deleteCommentSaga({ payload }: PayloadAction<TCandidateComments>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    yield fetchDeleteComment(payload.id)
    const page = yield select(selectCommentsPage)
    const comments = yield select(selectCandidateComments)

    if (page === 1) {
      yield spawn(getCandidateCommentsSaga, getComments({ id: payload.candidate_id, page: 1 }))
    } else {
      const res = yield fetchGetCandidateComments({ id: payload.candidate_id, page })

      if (page <= res.data.Headers['x-pagination-page-count'][0]) {
        yield put(setComments([...comments.slice(0, page * 10 - 1), ...res.data.DATA]))
      } else {
        yield put(setComments(comments.filter((el) => el.id !== payload.id)))
      }
    }
    yield put(openDeleteCommentModal(false))
    yield put(setSelectedComment(null))
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* removeRequestLinkSaga({ payload }: PayloadAction<TRequestRemoveRequestLink>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    const res: AxiosResponse = yield fetchDeleteRequestLink(payload)
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось открепить заявку',
          error: res
        })
      )
    } else {
      yield put(setCandidate(res.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* mergeDuplicatesSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    const res: AxiosResponse = yield fetchPostDuplicatesCandidates(payload)
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось объединить дубли',
          error: res
        })
      )
    } else {
      if (Number(payload) === Number(res.data.DATA.id)) {
        yield put(setCandidate(res.data.DATA))
      } else {
        yield put(navigateTo(`/candidate/${res.data.DATA.id}`))
      }
      yield put(openDuplicateModal(false))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateDuplicateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    const res = yield fetchGetCandidateDuplicateList({ id: payload })
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить дубли',
          error: res as AxiosError
        })
      )
    } else {
      yield put(setCandidateDuplicate(res.data.DATA))
      yield put(openDuplicateModal(true))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getRespondsCandidateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setIsLoadingRespondHandler(true))
    yield put(setLoadingProgress(true))
    const res = yield fetchGetRespondsCandidate(payload)
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить отклики',
          error: res as AxiosError
        })
      )
    } else {
      yield put(setRespondsCandidate(res.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setIsLoadingRespondHandler(false))
  }
}

function* respondHandlerSaga({ payload }: PayloadAction<TRequestRespondHandler>) {
  try {
    yield put(setIsLoadingRespondHandler(true))
    yield put(setLoadingProgress(true))
    const res = yield fetchPostRespondHandler(payload)
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обработать отклики',
          error: res as AxiosError
        })
      )
    } else {
      yield put(setRespondsCandidate(res.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setIsLoadingRespondHandler(false))
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateMergeDuplicateSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    const res: AxiosResponse = yield fetchGetCandidateDuplicateList({ id: payload, isMerged: true })
    if (res.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить слитые дубли',
          error: res
        })
      )
    } else {
      yield put(setCandidateMergeDuplicate(res.data.DATA))
      yield put(openMergeDuplicateModal(true))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getHistoryAsteriskSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetHistoryAsterisk(payload),
      cancel: take(getHistoryAsterisk.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить историю телефонии',
          error: response
        })
      )
    } else {
      yield put(setHistoryAsterisk(response.data.DATA.models))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* getStagesListingSaga() {
  try {
    yield put(setLoadingProgress(true))
    let response: TOption[];
    ({response} = yield race({
      response: fetchGetV1DictionariesList(['stageStatus'], 'isListing=true'),
      cancel: take(getStagesListing.type)
    }));
    if (!response) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить этапы для прогрессбара',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setStageListing(response?.stageStatus || []))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateDocumentSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetCandidateDocument(payload),
      cancel: take(getCandidateDocument.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить документы кандидата',
          error: response
        })
      )
    } else {
      yield put(setCandidateDocument(response?.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateQuestionnaireSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetCandidateQuestionnaire(payload),
      cancel: take(getCandidateQuestionnaire.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить анкету кандидата',
          error: response
        })
      )
    } else {
      const filteredAnswers = response?.data?.DATA?.answers?.filter((item, index, self) => index === self.findIndex((t) => t.question_id === item.question_id))
      const filteredData = { ...response.data.DATA, answers: filteredAnswers }
      yield put(setCandidateQuestionnaire(filteredData))
      yield put(setIsEditModeQuestionnaire(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* getHistoryCandidateSaga({ payload }: PayloadAction<TRequestCandidateHistory>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchGetCandidateHistoryV2(payload),
      cancel: take(getHistoryCandidateV2.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить историю кандидата',
          error: response
        })
      )
    } else {
      yield put(setHistoryCandidateV2(response.data.DATA))
      yield put(setHeaders({ current: response.data.Headers['x-pagination-current-page'][0], total: response.data.Headers['x-pagination-page-count'][0] }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* candidateSagaV2() {
  yield takeLatest(getCandidate, getCandidateSaga)
  yield takeLatest(getHistoryCandidateData, getHistoryCandidateDataSaga)
  yield takeLatest(getOldHistoryCandidateData, getOldHistoryCandidateDataSaga)
  yield takeLatest(getCandidateUpdate, getCandidateUpdateSaga)
  yield takeLatest(deleteCandidate, delCandidateSaga)
  yield takeLatest(updateCandidate, updateCandidateSaga)
  yield takeLatest(createCandidate, createCandidateSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(createNewComment, createCommentSaga)
  yield takeLatest(createTagCandidate, createTagSaga)
  yield takeLatest(delTagCandidate, delTagSaga)
  yield takeLatest(updateStage, updateStageSaga)
  yield takeLatest(createLinkCandidate, createLinkCandidateSaga)
  yield takeLatest(removeFromRequest, removeFromRequestSaga)
  yield takeLatest(getEmptySlots, emptySlotSaga)
  yield takeLatest(getQuestionnaire, getQuestionnaireSaga)
  yield takeLatest(createQuestionnaire, createQuestionnaireSaga)
  yield takeLatest(addInterview, addInterviewSaga)
  yield takeLatest(createNote, createNoteSaga)
  yield takeLatest(appointRecruiter, appointRecruiterSaga)
  yield takeLatest(appointReseacher, appointReseacherSaga)
  yield takeLatest(stageStatusCandidate, stageStatusCandidateSaga)
  yield takeLatest(removeDocuments, removeDocumentsSaga)
  yield takeLatest(getComments, getCandidateCommentsSaga)
  yield takeLatest(updateComments, updateCommentsSaga)
  yield takeLatest(getStageStatusByStage, getStageStatusSaga)
  yield takeLatest(appointAll, appointAllSaga)
  yield takeLatest(getPhoneFind, getPhoneFindSaga)
  yield takeLatest(getInnFind, getInnFindSaga)
  yield takeLatest(getEmailFind, getEmailFindSaga)
  yield takeLatest(deleteComment, deleteCommentSaga)
  yield takeLatest(removeRequestLink, removeRequestLinkSaga)
  yield takeLatest(mergeDuplicates, mergeDuplicatesSaga)
  yield takeLatest(getCandidateDuplicate, getCandidateDuplicateSaga)
  yield takeLatest(getRespondsCandidate, getRespondsCandidateSaga)
  yield takeLatest(respondHandler, respondHandlerSaga)
  yield takeLatest(getCandidateMergeDuplicate, getCandidateMergeDuplicateSaga)
  yield takeLatest(getHistoryAsterisk, getHistoryAsteriskSaga)
  yield takeLatest(getStagesListing, getStagesListingSaga)
  yield takeLatest(getCandidateDocument, getCandidateDocumentSaga)
  yield takeLatest(getCandidateQuestionnaire, getCandidateQuestionnaireSaga)
  yield takeLatest(getHistoryCandidateV2, getHistoryCandidateSaga)
}

export default candidateSagaV2
