import React, { memo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Typography, Input } from 'antd'
import 'dayjs/locale/ru'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import CloseMD from '../../../common/svg/CloseMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import {
  defObject, filteredOptions, objectToQueryString, statusOptions, typeOptions
} from '../utils'

import {
  fetchResearcherList
} from '../../../features/filters/utils'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import { openTasksFilter, setFilter } from '../actions'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'

import Search from '../../../common/svg/Search'
import './TasksFilter.scss'
import { selectDictionaryOptionsByName } from '../selectors'

const mainCssClass = getCssClassName('tasks-filter')

export type TTaskFilter = {
  author_id: number[]
  type: number[]
  status: number[]
  deadline_at:string
  target_id: number[]
  type_registration: number[]
}

function TasksFilter() {
  const dispatch = useDispatch()

  const [allModal, setAllModal] = useState<any>(defObject)
  const [targetInput, setTargetInput] = useState('')
  const [appendInput, setAppendInput] = useState('')

  const targetOPtions = useSelector(selectDictionaryOptionsByName('targetTask'))
  const appendOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))

  const handleAllReset = useCallback(() => {
    reset({})
  }, [])

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TTaskFilter>()

  const handldeOpenModal = (key:string) => {
    setAllModal((prevAllModal: any) => ({
      ...prevAllModal,
      [key]: !prevAllModal[key]
    }))
  }

  const handleApply = () => {
    dispatch(setFilter(objectToQueryString(getValues())))
  }

  const handleTargetInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTargetInput(e.target.value)
  }

  const handleAppendInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAppendInput(e.target.value)
  }


  return (
    <div className={mainCssClass}>
      <NewScrollContainer className={`${mainCssClass}_scroll`}>
        <div className={`${mainCssClass}_content`}>
          <div className={`${mainCssClass}_title`}>
            <div className={`${mainCssClass}_title_redo`}>
              <Typography.Title level={3}>Фильтры
              </Typography.Title>

              <Tooltip placement="bottomRight" title="Очистить всё">
                <ControlButton>
                  <Redo onClick={handleAllReset} />
                </ControlButton>
              </Tooltip>
            </div>
            <ControlButton onClick={() => dispatch(openTasksFilter(false))}>
              <CloseMD />
            </ControlButton>
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Тип задачи</Typography.Title>
                {watch('type') && !!watch('type') && <Redo onClick={() => resetField('type', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('addTypeModal')}>
                {allModal.addTypeModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.addTypeModal && (
              <div className={`${mainCssClass}_items_content`}>
                <RadioGroupFormControl options={typeOptions} control={control} name="type" />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Цель задачи</Typography.Title>
                {watch('target_id') && !!watch('target_id') && <Redo onClick={() => resetField('target_id', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('targetModal')}>
                {allModal.targetModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.targetModal && (
              <div className={`${mainCssClass}_items_content`}>
                <Input
                  prefix={<Search />}
                  className={`${mainCssClass}_items_content_search`}
                  value={targetInput}
                  onChange={handleTargetInput}
                />
                <CheckBoxFormControl
                  options={filteredOptions(targetOPtions, targetInput)}
                  control={control}
                  name="target_id"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>
                  Вид оформления
                  {watch('type_registration') && watch('type_registration').length > 1 ? (
                      <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                        {watch('type_registration').length}
                        )
                          </span>
                  ) : (
                      ''
                  )}
                </Typography.Title>
                {watch('type_registration') && !!watch('type_registration') && <Redo onClick={() => resetField('type_registration', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('typeRegistrationModal')}>
                {allModal.typeRegistrationModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.typeRegistrationModal && (
              <div className={`${mainCssClass}_items_content`}>
                <Input
                  prefix={<Search />}
                  className={`${mainCssClass}_items_content_search`}
                  value={appendInput}
                  onChange={handleAppendInput}
                />
                <CheckBoxFormControl
                  options={filteredOptions(appendOptions, appendInput)}
                  control={control}
                  name="type_registration"
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_items_title_redo`}>
                <Typography.Title level={4}>
                  Автор задачи
                  {watch('author_id') && watch('author_id').length > 1 ? (
                      <span className={`${mainCssClass}_count-in-brackets`}>
                            (
                        {watch('author_id').length}
                        )
                          </span>
                  ) : (
                      ''
                  )}
                </Typography.Title>
                {watch('author_id') && watch('author_id').length > 0 && <Redo onClick={() => resetField('author_id', [])} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('authorModal')}>
                {allModal.authorModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.authorModal && (
              <div className={`${mainCssClass}_items_content`}>
                <DebounceSelectFormControlMult
                  name="author_id"
                  control={control}
                  mode="multiple"
                  placeholder="Поиск по имени..."
                  label="Автор"
                  fetchOptions={fetchResearcherList}
                />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Статус задачи</Typography.Title>
                {watch('status') && watch('status').length > 0 && <Redo onClick={() => resetField('status', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('statusModal')}>
                {allModal.statusModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.statusModal && (
              <div className={`${mainCssClass}_items_content`}>
                <RadioGroupFormControl options={statusOptions} control={control} name="status" />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_items`}>
            <div className={`${mainCssClass}_items_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={4}>Выполнить до</Typography.Title>
                {watch('deadline_at') && <Redo onClick={() => resetField('deadline_at', undefined)} tooltip />}
              </div>
              <ControlButton onClick={() => handldeOpenModal('dateModal')}>
                {allModal.dateModal ? <CaretUpMD /> : <CaretDownMD />}
              </ControlButton>
            </div>
            {allModal.dateModal && (
              <div className={`${mainCssClass}_items_content`}>
                <DatePickerFormControl control={control} name="deadline_at" />
              </div>
            )}
          </div>

          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
              Применить
            </ControlButton>
            {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
          </div>

        </div>
      </NewScrollContainer>
    </div>
  )
}

export default memo(withErrorBoundary(TasksFilter))
