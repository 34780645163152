import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../common/utils/dateFormats'
import $api from '../../common/api/http'
import { TResume } from '../Resume'

function filterNullAndEmptyKeys(obj: TResume | null) {
  if (!obj) return []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const resumeOptions = [
        { label: 'Пожелания кандидата', value: obj.position },
        { label: 'Языки', value: obj.language },
        { label: 'Ключевые навыки', value: obj.skill_list },
        { label: 'Готовность к переезду/командировкам', value: obj.can_relocate },
        { label: 'Курсы повышения квалификации', value: obj.course_list },
        { label: 'Сертификаты', value: obj.certificate_list },
        { label: 'Дополнительная информация', value: obj.info },
        { label: 'Рекомендации', value: obj.recommendation_list },
        { label: 'Опыт работы', value: obj.experience_list },
        { label: 'Гражданство, время пути до работы', value: obj.citizenship },
        { label: 'Образование', value: obj.education_list }
      ]

      const resumeOptionsFiltred = resumeOptions.filter(
        (el) => !el.value || ((Array.isArray(el.value) && el.value.length === 0))
      )
      const resumeOptionsMap = resumeOptionsFiltred.map((el) => ({ label: el.label, value: el.label }))

      return resumeOptionsMap
    }
  }
}

export default filterNullAndEmptyKeys

export function getTransferDetailList(citizenship: string, work_permit: string, transfer_time: string) {
  const detailListProps = [
    { label: 'Гражданство', value: citizenship },
    { label: 'Разрешение на работу', value: work_permit },
    { label: 'Время в пути на работу', value: transfer_time }
  ]

  return detailListProps
}

export function getWhishesDetailList(
  position: string,
  specialization?: string,
  wages?: number,
  currency?:string,
  employment_list?:string,
  schedule_list?:string
) {
  const detailListProps = [
    { label: 'Должность', value: position },
    { label: 'Специализация', value: specialization },
    { label: 'Зарплата', value: `${wages} ${currency}` },
    { label: 'Занятость', value: employment_list },
    { label: 'График работы', value: schedule_list }
  ]

  return detailListProps
}

export function getRelocateDetailList(relocate: string, trip: string) {
  const detailListProps = [
    { label: 'Переезд', value: relocate },
    { label: 'Командировки', value: trip }
  ]

  return detailListProps
}
export function getQuestionnaireForServer(obj: Object) {
  const arr = []
  for (const [key, value = ''] of Object.entries(obj)) {
    if (key === 'questionnaire') {
      continue
    }

    const question_id = parseInt(key)
    const answer = value || ''

    arr.push({ question_id, answer })
  }
  return arr
}
export function getQuestionnaireFromServer(questionnaire: Array, role, id) {

  if (!questionnaire?.answers?.length) {
    if (id) {
      return { questionnaire: id }
    }
    return {}
  }

  const obj = { questionnaire: questionnaire?.questionnare_data.id }

  questionnaire?.answers?.forEach(({
    question_id, answer
  }) => {
    obj[question_id] = answer
  })
  return obj
}

export async function fetchCitiesList(name: string) {
  const data = await $api.get(`/api/v1/dadata/suggest-city?search=${name}`)
  return data.data.DATA.map((obj: { data: { city: string; city_kladr_id: number | string } }) => ({
    label: `${obj.data.city}`,
    value: obj.data.city,
    key: obj.data.city_kladr_id
  }))
}

export function extractValue(field: any): string {
  return typeof field === 'object' ? field.value : field
}

export function generateFilters(words: string[]) {
  const filters = []

  for (let i = 0; i < words.length; i++) {
    const name_like = `[name][like]=${words[i]}`
    const surname_like = `[surname][like]=${words[i]}`

    filters.push(name_like, surname_like)
  }

  const query = `${filters.join('&filter')}&unshift=OR`
  return query
}

export function formatDateTime(dateTimeString:string | undefined) {
  if (!dateTimeString) {
    return 'Не назначено'
  }

  const dateTime = dayjs(dateTimeString, ['DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'])
  if (!dateTime.isValid()) {
    return 'Неверный формат'
  }

  return dateTime.format(DATE_TIME_FORMAT)
}

export function createArrayOfTimeObjects() {
  const timeArray = []

  // Добавляем начальный объект
  timeArray.push({ label: 'Не выбрано', value: null })

  // Создаем объекты с интервалом в 30 минут до 21:00
  const currentTime = new Date()
  currentTime.setHours(9, 0, 0, 0) // Начнем с 09:00

  const endTime = new Date()
  endTime.setHours(21, 0, 0, 0) // Закончим в 21:00

  while (currentTime <= endTime) {
    const label = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    const value = label
    timeArray.push({ label, value })

    currentTime.setMinutes(currentTime.getMinutes() + 30)
  }

  return timeArray
}

export function objectToQueryString(obj: any): string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object' && key !== 'tag') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }
      if ((typeof obj[key][0] === 'number' || typeof obj[key][0] === 'boolean') && key !== 'schedule_id' && key !== 'characteristic' && key !== 'group') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
      if ((key === 'date_internship' || key === 'created_at' || key === 'date_registration' || key === 'date')) {
        // str += `&beetween[${key}]=${obj[key][0]}-${obj[key][1]}`
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (key === 'tag') {
        const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        str += `&union[tag]=${strArrStr}`
      }
      if (key === 'schedule_id') {
        const strArrStr = obj[key].join(',')
        str += `&union[schedule]=${strArrStr}`
      }
      if (key === 'customer') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[customer]=${strArrStr}`
      }
      if (key === 'request_id') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[request]=${strArrStr}`
      }
      if (key === 'characteristic') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&characteristic=${strArrStr}`
      }
      if (key === 'group') {
        // const strArrStr = obj[key].map((el) => el.value.replace('#', '')).join(',')
        const strArrStr = obj[key].map((el) => el).join(',')
        const newStrArr = strArrStr.replace(/undefined/g, '')
        str += `&groups=${newStrArr}`
      }
    } else if (typeof obj[key] === 'string' && key !== 'schedule_id') {
      str += `&filter[${key}]=${obj[key]}`
    }
  }

  return str
}

export function removePrefixCity(value : any) {
  return value && value[0] === 'г' ? value.replace(/^[^,]*,\s*/, '') : value
}

export const tasksOptions = [{ label: 'Просроченные', value: 'overdue' }, { label: 'На сегодня', value: 'today' }, { label: 'На завтра', value: 'tomorrow' }, { label: 'Послезавтра и далее', value: 'after_tomorrow' }, { label: 'За всё время', value: 'all' }]

export function updateCandidatePhone<T>(array: T[], index: number): T[] {
  const newArray = [...array]; // Создаем копию исходного массива
  const element = newArray.splice(index, 1)[0];
  newArray.unshift(element);

  return newArray;
}
