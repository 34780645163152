import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { createRequest, getDuplicateRequest, openDuplicateModal } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { selectSelectedRequestId } from '../../../compositions/ManagementRequests/selectors'


const mainCssClass = 'duplicate-vacancy-modal'

// @ts-ignore
function ModalDuplicateRequest({
  open, className
}: ModalProps) {
  const dispatch = useDispatch()
  const requestId = useSelector(selectSelectedRequestId)

  const onCancel = () => {
    dispatch(openDuplicateModal(false))
  }

  const onSubmit = () => {
    if (requestId) {
      dispatch(getDuplicateRequest(String(requestId)))
    }
  }

  return (
    <CommonModal
      centered
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      title="Дублировать вакансию"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Применить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        Вы уверены, что хотите дублировать заявку?
      </div>

    </CommonModal>
  )
}

export default ModalDuplicateRequest
