import dayjs from 'dayjs'
import { TTaskFilter } from './components/InternshipFilters'
import $api from '../../common/api/http'

export function getDayOfWeek(date: string) {
  const dayNumber = dayjs(date, 'DD.MM.YYYY').day()
  switch (dayNumber) {
  case 6: { return 'Сб' }
  case 0: { return 'Вс' }
  case 1: { return 'Пн' }
  case 2: { return 'Вт' }
  case 3: { return 'Ср' }
  case 4: { return 'Чт' }
  case 5: { return 'Пт' }
  }
}

export const defObject: any = {
  is_came: false,
  registration: false,
  vacancy_id: false,
  registrType: false,
  citizenship: false,
  group: false,
  requestContent: false,
  workSites: false,
  recruiter: false,
  date_created_at: false,
  interviewOffline: false,
  officeContent: false,
  date_interview: false,
  date_registration: false,
  date_internship: false,
  stage: false,
  tag: false,
  statusCandidate: false,
  stageState: false,
  cities: false,
  store: false,
  time: false
}

export const fetchMetroList = async (name: string) => {
  if (name) {
    const data = await $api.get(`/api/v1/dadata/suggest-metro?search=${name}`)

    return data.data.DATA.map((obj) => ({
      label: `${obj.unrestricted_value}`,
      value: `${obj.data.name}`
    }))
  }
  return []
}

export function objectToQueryString(obj: TTaskFilter): string {
  let str = ''
  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&filter[${key}]=${strArrStr}`
      }
      if (typeof obj[key][0] === 'number') {
        if (key === 'list_group') {
          const strArrStr = obj[key].map((el) => el).join(',')
          const newStrArr = strArrStr.replace(/undefined/g, '')
          str += `&filter[list_group]=${newStrArr}`
        } else {
          const strArrStr = obj[key].map((el) => el).join(',')
          str += `&filter[${key}]=${strArrStr}`
        }
      }
      if (key === 'date_created_at' || key === 'date_interview_offline' || key === 'date_lid_respond' || key === 'date_registration' || key === 'date_internship' || key === 'date_interview') {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (key === 'time') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el}`
          return acc
        }, '')
        str += strArrStr
      }
    } else if ((key === 'is_came' || key === 'registration') && obj[key]) {
      if (Number(obj[key]) === 4) {
        str += '&filter[not_result]=1'
      } else {
        str += `&filter[${key}]=${obj[key]}`
      }
    }
  }
  return str
}

export const getSourceOptions = async () => {
  const { data } = await $api.get('/api/v1/eventlead/get-drop-down')
  return data
}

export const isWorkOptions = [
  { label: 'Отдан в работу', value: '1' },
  { label: 'Не отдан', value: '0' }
]

export const registrationOptions = [{ label: 'Да', value: '1' }, { label: 'Нет', value: '2' }, { label: 'Нет информации', value: '0' }, { label: 'Не заполнено', value: '4' }]

// export function objectToQueryString(obj: TTaskFilter): string {
//   let str = ''
//   for (const key in obj) {
//     if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
//       if (typeof obj[key][0] === 'object') {
//         const strArrStr = obj[key].reduce<string>((acc, el) => {
//           acc += `&filter[${key}][in][]=${el.value}`
//           return acc
//         }, '')
//         str += strArrStr
//       }
//       if (typeof obj[key][0] === 'number') {
//         if (key === 'list_group') {
//           const strArrStr = obj[key].map((el) => el).join(',')
//           const newStrArr = strArrStr.replace(/undefined/g, '')
//           str += `&filter[list_group]=${newStrArr}`
//         } else {
//           const strArrStr = obj[key].reduce<string>((acc, el) => {
//             acc += `&filter[${key}][in][]=${el}`
//             return acc
//           }, '')
//           str += strArrStr
//         }
//       }
//       if (key === 'date_created_at' || key === 'date_interview_offline' || key === 'date_lid_respond' || key === 'date_registration' || key === 'date_internship' || key === 'date_interview') {
//         const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
//         str += `&between[${key}]=${strArrStr}`
//       }
//     } else if ((key === 'is_came' || key === 'registration')) {
//       if (obj[key]) {
//         str += `&filter[${key}]=${obj[key]}`
//       } else { console.log('') }
//     }
//   }
//   return str
// }

export function createObjectsArray() {
  const objectsArray = []
  for (let hour = 9; hour < 21; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const label = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}-${(hour + Math.floor((minutes + 30) / 60)).toString().padStart(2, '0')}:${((minutes + 30) % 60).toString().padStart(2, '0')}`
      objectsArray.push({ label, value: label })
    }
  }

  return objectsArray
}
