import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import MenuDrop from '../../../common/svg/MenuDrop'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { setOpenModalHidePost, setOpenModalNewEntry } from '../actions'

type TProps = {
  id: string | number | null
  // status: string | number | null
  // is_duplicates: boolean
}
const ButtonsTable: FC<TProps> = ({ id, status, is_duplicates }) => {
  const dispatch = useDispatch()
  const handleOpenNewEntry = useCallback(() => {
    dispatch(setOpenModalNewEntry(true))
  }, [])
  const handleOpenHidePost = useCallback(() => {
    dispatch(setOpenModalHidePost(true))
  }, [])
  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={handleOpenNewEntry}
        >
          Редактировать
        </Button>
      )
    },
    {
      key: 'hide-post',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={handleOpenHidePost}
        >
          Скрыть запись
        </Button>
      )
    }
  ]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
