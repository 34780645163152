import { PayloadAction } from '@reduxjs/toolkit'
import {
  call, put, select, takeLatest, race, take
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios/index'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  getCandidatesData,
  getDictionaries,
  setCandidatesData,
  setLoading,
  setLoadingCandidates,
  setDictionaries,
  updateStage,
  uploadNewCandidates,
  setCounters,
  getCounters,
  resetCandidates,
  setMaxPage,
  changeOrder,
  updateMassStage,
  showLimitationStageFunnelModal, setMessageLimitationFunnel,
  exportCSV
} from './actions'
import {
  fetchGetCandidateHistoryV2,
  fetchGetCandidates,
  fetchGetCandidatesFunnel,
  fetchGetCounters,
  fetchGetFunnel,
  fetchPatchUpdateMassStage,
  fetchPatchUpdateStage,
  TCounters
} from '../../common/api/Candidate'
import { TCandidate, TCandidates } from '../candidate/types'
import { TStageMassRequest, TStageRequest } from './types'
import { fetchGetVacancy } from '../../common/api/vacancy'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { TPaginationRequest } from '../../common/types/pagination'
import {
  selectCandidatesData, selectDictionaryOptionsByName, selectMaxPage, selectSelfFunnel
} from './selectors'
import { fetchGetV1DictionariesList } from '../../common/api/dictionaries'
import { selectStageFunnelFilter } from '../../features/filters/selectors'
import { setLoadingProgress } from '../InnerRouter/actions'


type CandidateResponse = AxiosResponse<TCandidate>
const moduleName = 'Страница вакансии'
function* getDictionariesSaga(payload : PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'вакансии таблица')
}
function* getCandidatesDataSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingCandidates(true))
    const filterStages: number[] = yield select(selectStageFunnelFilter)
    const dictionariesList: TDictionaries = yield call(fetchGetV1DictionariesList, ['stageStatus'])
    const allStages = dictionariesList.stageStatus
    let stages = allStages
    if (filterStages && filterStages.length > 0) {
      stages = stages.filter((item) => filterStages.includes(Number(item.value)))
    }
    const stageValues = stages.map((item) => `filter[stage_id][in][]=${item.value}`).join('&')
    const filters = payload.filters ? `${payload.filters}&${stageValues}` : `&${stageValues}`
    const resp = yield fetchGetFunnel(filters, payload?.self)
    const candidatesMap = new Map<string, any[]>()
    const lengthMap = new Map<string, number>()

    resp.data.DATA.candidate_list.forEach((item: any) => {
      const stageId = item.stage_id
      if (!candidatesMap.has(stageId)) {
        candidatesMap.set(stageId, [])
        lengthMap.set(stageId, item.stage_total_count)
      }
      candidatesMap.get(stageId).push(item)
    })
    const arrayContainers = stages.map((item) => ({
      id: item.value,
      name: item.label,
      items: candidatesMap.get(item.value) || [],
      length: lengthMap.get(item.value) || 0,
      sortOrder: 'asc'
    }))

    // Добавляем элементы из allStages, которые не вошли в stages
    allStages.forEach((item) => {
      if (!stages.some((stage) => stage.value === item.value)) {
        const emptyObject = {
          id: item.value,
          name: item.label,
          items: [],
          length: 0,
          sortOrder: 'asc'
        }
        arrayContainers.push(emptyObject)
      }
    })

    const sortedArr = arrayContainers.sort((a, b) => {
      const indexA = stages.findIndex((item) => item.value === a.id)
      const indexB = stages.findIndex((item) => item.value === b.id)

      if (indexA < indexB) {
        return -1
      }
      if (indexA > indexB) {
        return 1
      }
      return 0
    })

    yield put(setMaxPage(lengthMap))
    yield put(setCandidatesData(sortedArr))
  } catch (error) {
    console.error(error)
  } finally {
    yield put(setLoadingCandidates(false))
    yield put(setLoadingProgress(false))
  }
}

function* uploadCandidatesDataSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const dictionariesList: TDictionaries = yield call(fetchGetV1DictionariesList, ['stageStatus'])
    const allStages = dictionariesList.stageStatus
    const filterStages: number[] = yield select(selectStageFunnelFilter)
    let stages = allStages
    if (filterStages && filterStages.length > 0) {
      stages = stages.filter((item) => filterStages.includes(Number(item.value)))
    }
    const stageValues = stages.map((item) => `filter[stage_id][in][]=${item.value}`).join('&')
    const filters = payload.filters ? `${payload.filters}&${stageValues}` : `&${stageValues}`

    const resp = yield fetchGetFunnel(filters, payload?.self, payload.page)

    const candidatesMap = new Map<string, any[]>()
    const lengthMap = new Map<string, number>()

    resp.data.DATA.candidate_list.forEach((item: any) => {
      const stageId = item.stage_id
      if (!candidatesMap.has(stageId)) {
        candidatesMap.set(stageId, [])
      }
      candidatesMap.get(stageId).push(item)
    })

    const counters = yield fetchGetCounters()
    counters.data.DATA.forEach((item: any) => {
      lengthMap.set(item.stage_id, item.count)
    })

    const containers = yield select(selectCandidatesData)

    if (stages) {
      const arrayContainers = []

      stages.forEach((item) => {
        const newObject = {
          id: item.value,
          name: item.label,
          items: candidatesMap.get(item.value) || [],
          length: lengthMap.get(item.value) || 0,
          sortOrder: 'asc'
        }

        arrayContainers.push(newObject)
      })

      if (containers) {
        const newContainers = containers.map((item) => {
          const newItem = arrayContainers.find((newItem) => newItem.id === item.id)
          return newItem ? { ...item, items: [...item.items, ...newItem.items] } : item
        })

        const sortedArr = newContainers.sort((a, b) => {
          const indexA = stages.findIndex((item) => item.value === a.id)
          const indexB = stages.findIndex((item) => item.value === b.id)

          if (indexA < indexB) {
            return -1
          }
          if (indexA > indexB) {
            return 1
          }
          return 0
        })

        yield put(setCandidatesData(sortedArr))
      }

      yield put(setMaxPage(lengthMap))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* changeOrderSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    const containers = yield select(selectCandidatesData)
    yield put(setLoadingProgress(false))
    const count = payload.page
    const newPayload = {
      ...payload,
      filters: payload.filters
        ? `${payload.filters}&filter[stage_id][in][]=${payload.value}`
        : `&filter[stage_id][in][]=${payload.value}`,
      order: payload.order === 'desc' ? 'asc' : 'desc',
      page: count
    }

    const newArray = []

    const { response } = yield race({
      response: call(fetchGetCandidatesFunnel, newPayload),
      cancel: take(changeOrder.type)
    });

    const newContainers = containers.map((item) => (item.id === payload.value
      ? { ...item, items: response.data.DATA.candidate_list, sortOrder: payload.order === 'desc' ? 'asc' : 'desc' }
      : item))
    yield put(setCandidatesData(newContainers))
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateStageSaga({ payload }: PayloadAction<TStageRequest>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchUpdateStage(payload),
      cancel: take(updateStage.type)
    }));
    if (response.data.DATA.code === 403) {
      yield put(showLimitationStageFunnelModal(true))
      yield put(setMessageLimitationFunnel(response.data.DATA.message))
    } else if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить этап',
          error: response as AxiosError
        })
      )
    } else {
      if (payload.isMass) {
        const data = yield select(selectSelfFunnel)
        yield put(resetCandidates())
        yield put(getCandidatesData({ perPage: 10, page: 1, self: data }))
        yield put(getCounters())
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateMassStageSaga({ payload }: PayloadAction<TStageMassRequest>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchUpdateMassStage(payload),
      cancel: take(updateMassStage.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить этап',
          error: response as AxiosError
        })
      )
    } else {
      const data = yield select(selectSelfFunnel)
      yield put(resetCandidates())
      yield put(getCandidatesData({ perPage: 10, page: 1, self: data }))
      yield put(getCounters())
    }

  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCountersSaga() {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: fetchGetCounters(),
      cancel: take(getCounters.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Каунтеры не получены',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCounters(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* exportCSVSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const filterStages: number[] = yield select(selectStageFunnelFilter)
    const dictionariesList: TDictionaries = yield call(fetchGetV1DictionariesList, ['stageStatus'])
    const allStages = dictionariesList.stageStatus
    let stages = allStages
    if (filterStages && filterStages.length > 0) {
      stages = stages.filter((item) => filterStages.includes(Number(item.value)))
    }
    const stageValues = stages.map((item) => `filter[stage_id][in][]=${item.value}`).join('&')
    const filters = payload.filters ? `${payload.filters}&${stageValues}` : `&${stageValues}`
    // const response = yield fetchGetFunnel(filters, payload?.self, payload.page, payload.download)
    const {response} = yield race({response: fetchGetFunnel(filters, payload?.self, payload.page, payload.download), cancel: take(exportCSV.type)})

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить файл',
          error: response as AxiosError
        })
      )
    } else if (!response.data.ERR) {

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

export function* funnelSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getCandidatesData, getCandidatesDataSaga)
  yield takeLatest(updateStage, updateStageSaga)
  yield takeLatest(updateMassStage, updateMassStageSaga)
  yield takeLatest(uploadNewCandidates, uploadCandidatesDataSaga)
  yield takeLatest(getCounters, getCountersSaga)
  yield takeLatest(changeOrder, changeOrderSaga)
  yield takeLatest(exportCSV, exportCSVSaga)
}
