import { Tooltip } from 'antd'
import React, { memo } from 'react'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

export type TProps = {
  text: string
}

function CandidateCommentTableRow({ text }: TProps) {
  return (

    text.length > 20
      ? (
        <Tooltip title={text}>
          <div>
            {`${text.slice(0, 20)}...`}
          </div>
        </Tooltip>
      )

      : (
        <div>
          {text}
        </div>
      )
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidateCommentTableRow))
