import axios, { AxiosResponse } from 'axios'
import { method } from 'lodash'
import dayjs from 'dayjs'
import $api, { API_URL } from './http'
import { getLocalStorageBooleanValue } from '../utils/localStorageUtils'
import { TCalendarSettings } from '../../features/SettingsCalendar/actions'
import { TUpdateStatusesMobiles } from '../../compositions/InnerRouter/actions'
import { DATE_DOTS_FORMAT, SERVER_DATE_FORMAT } from '../utils/dateFormats'

export async function fetchGetUserInfo(): Promise<AxiosResponse<any>> {
  const response = await $api.get('/api/v1/user/view', { withCredentials: true })
  return response
}

export async function fetchPatchUser(payload: TCalendarSettings): Promise<AxiosResponse<any>> {
  const response = await $api.patch('/api/v1/user/update', payload)
  return response
}
export async function fetchPostUpdateCalendarUser(payload: TCalendarSettings): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/user/recruiter-update-calendar-settings', payload)
  return response
}

export type TRequestPatchUser = {
  password?: string
  phone?: string
  surname?: string
  name?: string
  patronymic?: string
  seat?: string
  role?: string
  color_system?:string
}

export async function fetchPatchUpdateUser(payload:TRequestPatchUser) {
  const response = await $api.patch('/api/v1/user/update', payload)
  return response
}
export async function fetchGetStatusesMobile() {
  const response = await $api.get('api/v1/fraxvill/get-statuses')
  return response
}
export async function fetchPostStatusMobile(payload: TUpdateStatusesMobiles) {
  const response = await $api.post(`api/v1/fraxvill/setup-status?user_id=${payload.user_id}&status_id=${payload.status_id}`)
  return response
}

export async function fetchGetCurrentStatusUserMobile() {
  const response = await $api.get('api/v1/fraxvill/get-user-statuses')
  return response
}
export async function fetchGetAutoCall() {
  const response = await $api.get(`${process.env.DOMAIN_ASTERISK}/internal/auto-call`)
  return response
}
export async function fetchGetInternalInformationByUserId() {
  const response = await $api.get('/api/v1/fraxvill/stats-calls-self')
  return response
}
export async function fetchGetAutoCallStart(id: number) {
  const response = await $api.get(`/api/v1/fraxvill/auto-call-enable?autocall_id=${id}`)
  return response
}
export async function fetchGetAutoCallStop(id: number) {
  const response = await $api.get(`/api/v1/fraxvill/auto-call-disable?autocall_id=${id}`)
  return response
}
export async function fetchGetPhoneBookList() {
  const response = await $api.get('/api/v1/fraxvill/phonebook-list')
  return response
}

export async function fetchGetUserActivity() {
  const response = await $api.get('/api/v1/user-activity/get-time')
  return response
}

export async function fetchPostChangeUserActivity(payload: string) {
  const response = await $api.post('/api/v1/user-activity/execute', { command: payload })
  return response
}
export async function fetchGetRtt() {
  const response = await $api.get(`${process.env.DOMAIN_ASTERISK}/internal/status/get-rtt`)
  return response
}
