import { createReducer } from '@reduxjs/toolkit'
import {
  resetData,
  setCandidatesData,
  setLoading,
  setDictionaries,
  setLoadingCandidates,
  openEditVacancyModal,
  openCreateVacancyModal,
  setShowButton,
  setShowModal,
  resetCandidates,
  setCounters,
  setDefStringFunnel,
  setMaxPage,
  setBlockedFiltersResponsibleOrRecruiter,
  reset,
  setSelfFunnel,
  showLimitationStageFunnelModal,
  setMessageLimitationFunnel
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'
import { TVacancy } from '../../common/api/vacancy'
import { TCandidates, TCandidatesData, TDATA } from '../candidate/types'
import { TCounters } from '../../common/api/Candidate'

type TVacancyState = {
  vacancy: null | TVacancy
  dictionaries: TDictionaries | null
  loading: boolean
  candidatesData: any[] | []
  loadingCandidates: boolean
  editVacancyModal: boolean
  createVacancyModal: boolean
  showButton: boolean
  showModal: boolean
  counters: null | TCounters[]
  defString: string
  maxPage: Map<any, any>
  blockedFiltersResponsibleOrRecruiter: boolean
  self: string
openLimitationStageFunnelModal:boolean
  messageLimitationFunnel:string
}

const initialState: TVacancyState = {
  vacancy: null,
  dictionaries: null,
  loading: false,
  candidatesData: [],
  loadingCandidates: false,
  editVacancyModal: false,
  createVacancyModal: false,
  showButton: false,
  showModal: false,
  counters: null,
  defString: '',
  maxPage: new Map(),
  blockedFiltersResponsibleOrRecruiter: false,
  self: '',
  openLimitationStageFunnelModal: false,
  messageLimitationFunnel: ''
}

const funnelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setLoadingCandidates, (state, { payload }) => {
      state.loadingCandidates = payload
    })
    .addCase(setCandidatesData, (state, { payload }) => {
      state.candidatesData = payload
    })
    .addCase(setCounters, (state, { payload }) => {
      state.counters = payload
    })
    .addCase(resetCandidates, (state) => {
      state.candidatesData = []
    })
    .addCase(openEditVacancyModal, (state, { payload }) => {
      state.editVacancyModal = payload
    })
    .addCase(openCreateVacancyModal, (state, { payload }) => {
      state.createVacancyModal = payload
    })
    .addCase(setShowButton, (state, { payload }) => {
      state.showButton = payload
    })
    .addCase(setShowModal, (state, { payload }) => {
      state.showModal = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })

    .addCase(resetData, (state) => ({
      ...initialState,
      defString: state.defString
    }))
    .addCase(setDefStringFunnel, (state, { payload }) => {
      state.defString = payload
    })
    .addCase(setMaxPage, (state, { payload }) => {
      state.maxPage = payload
    })
    .addCase(setBlockedFiltersResponsibleOrRecruiter, (state, { payload }) => {
      state.blockedFiltersResponsibleOrRecruiter = payload
    })
    .addCase(setSelfFunnel, (state, { payload }) => {
      state.self = payload
    })
    .addCase(showLimitationStageFunnelModal, (state, { payload }) => {
      state.openLimitationStageFunnelModal = payload
    })
    .addCase(setMessageLimitationFunnel, (state, { payload }) => {
      state.messageLimitationFunnel = payload
    })
    .addCase(reset, () => initialState)
})

export default funnelReducer
