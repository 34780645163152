import React, { memo, useState } from 'react'

import type { MenuProps } from 'antd'
import { Button, Menu } from 'antd'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { useParams } from 'react-router'
import { ProfileIcon, SettingTriggers, SettingsIcon } from './NavIcons'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { navigateTo } from '../../../common/actions/navigate'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { openDictionaryForm, setDropdownStageSetupKey } from '..'

import './SystemsNav.scss'

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[],
  type?: 'group',
  disabled?: 'disabled',
  subPageId?: string,
  className?: string

): MenuItem {
  const isActive = key === subPageId
  return {
    key,
    children,
    label,
    type,
    disabled,
    className: cn(className, isActive ? 'active' : '')
  } as MenuItem
}

const mainCssClass = getCssClassName('systems-nav')

function SystemsNav() {
  const dispatch = useDispatch()
  const { subPageId } = useParams()
  const { rbacControl } = useEntityContext()

  const handleMenuClick = (e) => {
    if (e.key === 'my' || e.key === 'password' || e.key === 'myWhatsappTemplates') {
      dispatch(navigateTo(`/systems/profile/${e.key}`))
    }
    if (e.key === 'questionnaire' || e.key === 'triggers' || e.key === 'dictionary'
      || e.key === 'templates' || e.key === 'tokens' || e.key === 'stageSetup' || e.key === 'userListConstructor' || e.key === 'roleListConstructor' || e.key === 'stores' || e.key === 'whatsappTemplates'
      || e.key === 'callbackSetting' || e.key === 'reminders'
    ) {
      dispatch(navigateTo(`/systems/settings/${e.key}`))
    }

    if (e.key === 'dictionary') {
      dispatch(openDictionaryForm(false))
    }

    if (e.key === 'stageSetup') {
      dispatch(setDropdownStageSetupKey(''))
    }
  }

  const items: MenuItem[] = [

    getItem(<ProfileIcon />, 'profile', [
      ...(rbacControl?.profile.page_access === 1 ? [getItem('Мой аккаунт', 'my', undefined, undefined, undefined, subPageId)] : []),
      getItem('Смена пароля', 'password', undefined, undefined, undefined, subPageId),
      getItem('Мои Whatsapp шаблоны', 'myWhatsappTemplates', undefined, undefined, undefined, subPageId)
    ], 'group', 'disabled'),

    ...((rbacControl?.users.create_access || rbacControl?.role.create_access || rbacControl?.questionnare.create_access
    || rbacControl?.dictionary.create_access || rbacControl?.default_stage.create_access || rbacControl?.template.create_access) ? [getItem(<SettingsIcon />, 'settings', [
        ...(rbacControl?.users.create_access === 1 ? [getItem('Пользователи', 'userListConstructor', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.role.create_access === 1 ? [getItem('Роли', 'roleListConstructor', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.role.create_access === 1 ? [getItem('Объекты', 'stores', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.questionnare.create_access === 1 ? [getItem('Конструктор анкет', 'questionnaire', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.questionnare.create_access === 1 ? [getItem('Whatsapp шаблоны', 'whatsappTemplates', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.dictionary.create_access === 1 ? [getItem('Настройка справочников', 'dictionary', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.default_stage.create_access === 1 ? [getItem('Настройка этапов по умолчанию', 'stageSetup', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.callback.create_access === 1 ? [getItem('Настройки callback', 'callbackSetting', undefined, undefined, undefined, subPageId)] : []),
        ...(rbacControl?.profile.create_access === 1 ? [getItem('Токены', 'tokens', undefined, undefined, undefined, subPageId)] : []),
        ...[getItem('Напоминания', 'reminders', undefined, undefined, undefined, subPageId)],
        ...(rbacControl?.template.create_access === 1 ? [getItem(
          <SettingTriggers />,
          'settings-triggers',
          [getItem('Триггеры', 'triggers', undefined, undefined, undefined, subPageId),
            getItem('Шаблоны', 'templates', undefined, undefined, undefined, subPageId)
          ],
          'group',
          undefined,
          undefined,
          'settings-triggers'
        )] : [])

      ], 'group', 'disabled')] : [])
  ]

  return (
    <div className={mainCssClass}>
      <Menu
        onClick={handleMenuClick}
        mode="inline"
        theme="light"
        items={items}
      />
    </div>
  )
}

export default memo(withErrorBoundary(SystemsNav))
