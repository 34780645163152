import React, {
  useState, useEffect, memo, useMemo
} from 'react'
import './Internship.scss'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { Button, Typography } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { TCalendar, TSlot } from '../../../common/api/calendar'
import { getDayOfWeek } from '../utils'
import { selectOpenModalInterview, selectSelectedInternship, selectSelectedInterviewId } from '../selectors'
import { DATE_DOTS_FORMAT, SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import {
  getInternship,
  getInterview, openInternshipDateModal, setInternshipModal, setOpenMoreInfoModal, setSelectedInternship, setSelectedInterviewId, TInternship
} from '../actions'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import InternshipCandidateCard from './InternshipCandidateCard'
import { setCountFilters } from '../../../features/filters/actions'

dayjs.locale('ru')
dayjs.extend(calendar)
type TProps = {
  internships: TInternship[] | null;
  internshipValue: string;
  currentDate: string;
  handleIncrementPage: () => void;
} & TClassName;
const mainCssClass = 'internship'
function Internship({
  internships, className, internshipValue, currentDate: selectedDate, handleIncrementPage
}: TProps) {
  const dispatch = useDispatch()
  const selectedInternship = useSelector(selectSelectedInternship)
  const selectedDay = dayjs(selectedDate, 'YYYY-MM-DD').startOf('day')
  const filteredInternships = useMemo(() => {
    if (!internships || !internships.calendar_list) {
      return []
    }

    const filteredItems = internships.calendar_list.filter((item) => item.date !== null)

    return filteredItems
  }, [internshipValue, internships, selectedDay, selectedDate])

  const handleOpenInternshipModal = (e, item) => {
    if (e.target.classList.contains('internship-candidate-card__edit')) {
      dispatch(setSelectedInternship(item))
      dispatch(openInternshipDateModal(true))
      return
    }
    dispatch(setSelectedInternship(item))
    dispatch(setInternshipModal(true))
  }

  return (
    <div className={cn(mainCssClass, className)}>
      <table className={`${mainCssClass}__table`}>
        <thead>
          <tr>
            {filteredInternships?.map((tableHeader) => (
              <th key={tableHeader.date}>
                <div>
                  <Typography.Paragraph className={cn(
                    `${mainCssClass}__head-columns`,
                    { [`${mainCssClass}__colorized-font`]: tableHeader.date === dayjs(new Date()).format(DATE_DOTS_FORMAT) }
                  )}
                  >
                    {dayjs(tableHeader.date, 'YYYY-MM-DD').format('DD MMMM')}
                  </Typography.Paragraph>
                  <Typography.Paragraph className={cn(
                    `${mainCssClass}__head-columns`,
                    { [`${mainCssClass}__colorized-font`]: tableHeader.date === dayjs(new Date()).format(DATE_DOTS_FORMAT) }
                  )}
                  >
                    Кандидаты:
                    {' '}
                    {tableHeader.count}
                  </Typography.Paragraph>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          <tr>
            {filteredInternships?.map((internshipItem) => (
              internshipItem ? (
                <td key={dayjs(internshipItem.date, 'DD.MM.YYYY')}>
                  {internshipItem.items.map((item, itemIndex) => (
                    <div key={itemIndex} className={`${mainCssClass}__drag-container`}>
                      <InternshipCandidateCard candidate={item} phone={item.phone} address={item.address} onClick={(e) => handleOpenInternshipModal(e, item)} type={filteredInternships.length > 1 ? 'week' : 'day'} />
                    </div>
                  ))}
                </td>
              ) : null
            ))}
          </tr>
          <div className={`${mainCssClass}__show-div`}>
            <Button type="ghost" onClick={handleIncrementPage} className={`${mainCssClass}__show`}>Показать еще</Button>
          </div>
        </tbody>
      </table>
    </div>
  )
}

export default memo(withErrorBoundary(Internship))
