/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios'
import { TRequestPlanFact } from 'src/compositions/analytics/actions'
import $api from './http'

export type TRequestAnalyticsEffectivity = {
  method: string
  filter?:string
  scenario: 'by_user' | 'by_request' | 'by_vacancy'
  download?: boolean
}

export type TRequestAnalyticsWithMethods = {
  filter?:string
  method1: string
  method2: string
  download?: boolean
}
export type TAnalyticsRejection = {
  filter?:string
  download?: boolean
}

export type TRequestAnalytics = {
  filter?:string
  method1?: string
  method2?: string
  download?: boolean
}

export async function fetchGetSourseOfCandidates(payload:TRequestAnalytics): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/analytic/leadgen${payload.filter ? `${payload.filter}${payload.download ? '&download=true' : ''}` : ''}`)
  return response
}
export async function fetchGetProductivity(payload:TRequestAnalyticsEffectivity): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/analytic/effectivity${payload.filter ? `${payload.filter}&method[]=${payload.method}&scenario=${payload.scenario}${payload.download ? '&download=true' : ''}`
    : `?method[]=${payload.method}&scenario=${payload.scenario}`}`)
  return response
}
export async function fetchGetVertexInfo(payload:TRequestAnalyticsWithMethods): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/analytic/vertex${payload.filter ? `${payload.filter}&method[]=${payload.method1}&method[]=${payload.method2}${payload.download ? '&download=true' : ''}`
    : `?method[]=${payload.method1}&method[]=${payload.method2}`}`)
  return response
}

export async function fetchGetPlanFactInfo(payload:TRequestPlanFact): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/analytic/plan-fact?by_vacancy=${payload.by_vacancy === 'by_vacancy' ? 1 : 0}${payload.filter ? `&${payload.filter.slice(1)}` : ''}${payload.download ? '&download=true' : ''}`)
  return response
}

export async function fetchGetRejectionInfo(payload:TAnalyticsRejection): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/analytic/reject${payload.filter ? `${payload.filter}${payload.download ? '&download=true' : ''}` : ''}`)
  return response
}