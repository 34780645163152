/* eslint-disable react/function-component-definition */
/* eslint-disable no-multiple-empty-lines */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDuplicateStatusOpen,
  setOpenModalChangeStatus,
  setOpenModalEditVacancy,
  setSelectedVacancy,
  setSelectedVacancyId
} from '../actions'
import MenuDrop from '../../../common/svg/MenuDrop'
import { TMainProps } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { updateVacancy } from '../../../features/vacancyModals/actions'
import { selectVacancies, selectVacancyArchiveOpen } from '../selectors'
import { useEntityContext } from '../../../common/context/EntityContextProvider'

type TProps = {
  id?: string | number | null
}
const ButtonsTable: FC<TProps> = ({ id }) => {
  const dispatch = useDispatch()
  const vacancies = useSelector(selectVacancies)
  const { rbacControl } = useEntityContext()

  const vacancy = useMemo(() => vacancies.find((item) => item.id === id), [id])


  const handleOpenChangeStatus = useCallback(() => {
    dispatch(setOpenModalChangeStatus(true))
    if (id) {
      dispatch(setSelectedVacancyId(id))
    }
  }, [id])

  const handleDuplicateStatusOpen = useCallback(() => {
    dispatch(setDuplicateStatusOpen(true))
    if (id) {
      dispatch(setSelectedVacancyId(id))
    }
  }, [id])

  const handleToFixVacancy = () => {
    dispatch(updateVacancy({
      data: {
        id,
        is_favorite: !vacancy?.is_favorite
      },
      isVacancyTable: true
    }))
  }

  const handleEditVacancy = useCallback(() => {
    if (id && vacancies) {
      const vacancy = vacancies.find((item) => item.id === id)
      if (vacancy) {
        dispatch(setSelectedVacancy(vacancy))
      }
      dispatch(setSelectedVacancyId(id))
      dispatch(setOpenModalEditVacancy(true))
    }
  }, [id, vacancies])

  const items: MenuProps['items'] = [
    {
      key: 'approve',
      label: (
        <Button type="ghost" size="large" onClick={handleEditVacancy}>
          Редактировать
        </Button>
      )
    },
    {
      key: 'change-status',
      label: (
        <Button type="ghost" size="large" onClick={handleOpenChangeStatus}>
          Изменить статус
        </Button>
      )
    },
    {
      key: 'archive',
      label: (
        <Button type="ghost" size="large" onClick={handleDuplicateStatusOpen}>
          Дублировать
        </Button>
      )
    },
    {
      key: 'to-fix',
      label: (
        <Button type="ghost" size="large" onClick={handleToFixVacancy}>
          {vacancy?.is_favorite ? 'Открепить' : 'Закрепить сверху'}
        </Button>
      )
    }
  ]
  if (rbacControl?.vacancy.edit_view_access === 1) {
    return (
      <Dropdown
        menu={{
          items,
          selectable: true
        }}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
      >
        <Button type="ghost" icon={<MenuDrop />} size="large" />
      </Dropdown>
    )
  }
  return null
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
