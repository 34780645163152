import React, { useEffect } from 'react'
import { Select, SelectProps, Typography } from 'antd'
import { useController, UseControllerProps, useForm } from 'react-hook-form'
import cn from 'classnames'
import './SelectFormControl.scss'
import Required from '../../svg/Required'
import { TOption } from '../../types/dictionaries'

type TControlSelectProps = Omit<
  UseControllerProps &
    SelectProps & { className?: string; size?: 'small' | 'middle' | 'large'; label?: string; required?: boolean },
  'isDisabled'
>

interface SelectFormControlProps extends TControlSelectProps {
  rules?: {
    required: boolean
  }
}

const { Title } = Typography

const { Option } = Select

function SelectFormControl({
  name,
  control,
  rules,
  label,
  className,
  size,
  required,
  options,
  placeholder,
  defaultValue,
  onChange,
  ...rest
}: SelectFormControlProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue)
    }
  }, [])

  const handleSelectChange = (value: any, option: TOption | TOption[]) => {
    field.onChange(value)
    if (onChange) {
      onChange(value, option)
    }
  }

  return (
    <div
      className={cn(
        className,
        'customSelect',
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' }
      )}
    >
      {label && (
        <div>
          <Title className="customSelect_label" level={4}>
            {label}
            {required && <Required className="required" />}
          </Title>
        </div>
      )}
      <Select
        placeholder={placeholder || 'Выберите из списка'}
        onChange={handleSelectChange}
        value={field.value}
        className={fieldState.error ? 'error' : ''}
        size={size}
        onBlur={field.onBlur}
        {...rest}
      >
        {options?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectFormControl
