import React, { FC, memo } from 'react'
import { Tag } from 'antd'

export type TProps= {
label: string
}
const Vacation: FC<TProps> = ({ label }) => (
  <div>

    <Tag key={label} className="customLabel" color="blue">
      {label}
    </Tag>
  </div>
)

export default memo(Vacation)
