import React from 'react'
import User01 from '../../../common/svg/User01'
import Settings from '../../../common/svg/Settings'
import SquareWarning from '../../../common/svg/SquareWarning'

export function ProfileIcon() {
  return (
    <>
      <User01 />
      <span>Профиль</span>
    </>
  )
}

export function SettingsIcon() {
  return (
    <>
      <Settings />
      <span>Настройки</span>
    </>
  )
}

export function SettingTriggers() {
  return (
    <>
      <SquareWarning />
      <span>Настройка триггеров</span>
    </>
  )
}
