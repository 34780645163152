import React from 'react'
import Icon from '../svgIcons/SmallExit.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function SmallExit({ onClick, className }: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" onClick={onClick} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5.00001 3.5858L8.29292 0.292893C8.68344 -0.0976311 9.31661 -0.0976311 9.70713 0.292893C10.0977 0.683417 10.0977 1.31658 9.70713 1.70711L6.41422 5.00001L9.70708 8.29287C10.0976 8.6834 10.0976 9.31656 9.70708 9.70708C9.31656 10.0976 8.6834 10.0976 8.29287 9.70708L5.00001 6.41422L1.70711 9.70713C1.31658 10.0977 0.683417 10.0977 0.292893 9.70713C-0.0976311 9.31661 -0.0976311 8.68344 0.292893 8.29292L3.5858 5.00001L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        fill="#404542"
      />
    </svg>
  )
}
