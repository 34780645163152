import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
}

const mainCssClass = 'watchDevice'

function UserAvatar({ className, size = '0.5' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_153)">
        <rect width="42" height="42" rx="21" fill="#E8ECE9" />
        <path
          d="M17.5075 76.0866C19.4233 69.0512 25.8122 64.1695 33.1038 64.1695H34.4417C37.2729 64.1695 40.0185 65.14 42.2208 66.9191V66.9191C46.759 70.5852 53.241 70.5852 57.7792 66.9191V66.9191C59.9815 65.14 62.7271 64.1695 65.5583 64.1695H66.8962C74.1878 64.1695 80.5767 69.0512 82.4925 76.0867L92 111H8L17.5075 76.0866Z"
          fill="#D5DBD6"
        />
        <path
          d="M32.9452 37.1982C32.3053 27.3443 40.1253 19 50 19V19C59.8747 19 67.6947 27.3443 67.0548 37.1982L66.4872 45.9385C65.9229 54.6294 58.7092 61.3898 50 61.3898V61.3898C41.2908 61.3898 34.0771 54.6294 33.5127 45.9385L32.9452 37.1982Z"
          fill="#D5DBD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_153">
          <rect width="42" height="42" rx="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default UserAvatar
