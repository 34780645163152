import { ReactNode } from 'react'
import { notify } from './Notification/notify'
import { NotificationPlacement, NotificationStatus } from './Notification/types'

const { toast } = notify()

export type TNotification = {
  status: NotificationStatus
  title?: string
  text?: ReactNode
  duration?: number
  placement?: NotificationPlacement
  href?: any
  linkText?:any
  withAlarm?: boolean
}
export type TUnstatusedNotification = Omit<TNotification, 'status'>

export function showNotification(notification: TNotification) {
  toast(
    {
      className: 'loan-notifications',
      status: notification.status,
      duration: notification.duration ?? undefined,
      title: notification.title,
      text: notification.text,
      href: notification.href,
      linkText: notification.linkText,
      withAlarm: notification?.withAlarm
    },
    notification.placement || 'bottomRight'
  )
}

export function showCriticalNotification(notification: TUnstatusedNotification) {
  showNotification({
    ...notification,
    status: 'danger',
    duration: process.env.NOTIFICATION_DURATION ? Number(process.env.NOTIFICATION_DURATION) : 5
  })
}

export function showSuccessNotification(notification: TUnstatusedNotification) {
  showNotification({
    ...notification,
    status: 'success',
    duration: process.env.NOTIFICATION_DURATION ? Number(process.env.NOTIFICATION_DURATION) : 5
  })
}

export function showDangerNotification(notification: TUnstatusedNotification) {
  showNotification({
    ...notification,
    status: 'warning',
    duration: process.env.NOTIFICATION_DURATION ? Number(process.env.NOTIFICATION_DURATION) : 5
  })
}

export function showInfoNotification(notification: TUnstatusedNotification) {
  showNotification({
    status: 'info',
    duration: process.env.NOTIFICATION_DURATION ? Number(process.env.NOTIFICATION_DURATION) : 5,
    ...notification
  })
}
