/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import { TRequestRequestsCSV } from '../../compositions/ManagementRequests/actions'
import $api from './http'
import { TResponseVacancy } from './vacancy'
import { TPaginationRequest } from '../types/pagination'
import {
  TCommentRequest, TPostRequest, TRequestReq, TResponseOneRequest
} from '../../compositions/Request/types'
import {
  TArchiveRequestStatus, TCreateLinkShedule,
  TPatchRequest,
  TRequestPatchData,
  TUpdateRequestStatus
} from '../../features/requestModals/actions'
import { TCreateLink } from '../../compositions/candidate/types'
import { TRequestHistoryRequestData, TRequestRemoveRequestLink, TResposible } from '../../compositions/Request/actions'
import { TOption } from '../types/dictionaries'

export type TRequest = {
  id: string
  status: number
  address: string
  description: string
  responsible: number
  customer: number
  period_start?: string
  period_finish?: string
  priority_id: number
  vacancy_id: number
  store_id:number
  cost: number
  reward: number
  is_current_request: boolean
  created_at: string
  updated_at: string
  vacancy_label: string
  candidate_list?: { id: number; name: string; second_name: string; last_name: string }[]
  candidate_reserve_list?: { id: number; name: string; second_name: string; last_name: string }[]
  user: {
    responsible: { id: number; label: string }
    customer: { id: number; label: string, phone: string } []
  }
  name: string
  cost_finalists: number
  schedule_list: string[]
  cost_process: number
  customer_list: { id: number; email: string; name: string; surname: string; patronymic: string, phone:string, created_at: string }[]
  responsible_list: { email: string; name: string; surname: string; patronymic: string }
  responsible_id_list: TResposible[]
  reward_average_day: number,
  reward_average_month: number,
  candidate_age: number,
  is_student: boolean,
  education_id: boolean,
  is_patent: boolean,
  is_internship: boolean,
  address_internship: string,
  responsible_internship: string,
  candidate_characteristic_id: number,
  characteristic_list?: string[],
  store: {
    id: number | string
    name: string
    address: string
    city: string
    metro: string
    lattitude: string | number
    longtitude: string | number
  }
  income_average_day: string | null,
  mileage_average_day:string | null,
  reward_average_order: string | null
}

export type TResponseRequest = {
  err: string
  DATA: TResponseRequestData
  Headers: {
    'x-pagination-total-count': number[],
    'x-pagination-page-count': number[],
    'x-pagination-current-page': number[],
  }
}
export type TResponseRequestData = {
  request_list: TRequest[]

  counters: {
    count: number
    label: string
    id: string | number
  }[]
}
export type THistoryRequestData = {
  action: string,
  candidate: {
    candidate_id: number,
    candidate_name: string
  },
  user: {
    user_id: number,
    user_name: string
  },
  before: [
    {
      attribuyte_name: string
    }
  ],
  after: [
    {
      attribuyte_name: string
    }
  ],
  date: string
} []
export async function fetchGetRequests(queryParams: TPaginationRequest): Promise<TResponseRequest> {
  const response = await $api.get<TResponseRequest>(
    `/api/v1/requests?sort=-updated_at&fields=name,vacancy_label,cost_process,responsible_list,cost_finalists,cost,schedule_list,customer_list,user,id,priority_id,vacancy_id,status&per-page=${queryParams?.perPage || 10}&page=${queryParams?.page || 1}${
      queryParams?.filters ? queryParams.filters.includes('without_store') ? queryParams.filters : `${queryParams.filters}&union[without_store]=false` : `${queryParams.filters}&union[without_store]=false`
    }`
  )
  return response
}

export async function fetchGetRequestsCard(queryParams: TPaginationRequest, isSliced): Promise<TResponseRequest> {
  let boundsStr = ''
  if (isSliced) {
    const { bounds } = queryParams
    if (queryParams.filters.indexOf('union[without_store]=true') !== -1) {
      boundsStr = ''
    } else if (bounds) {
      const [topLeft, bottomRight] = bounds
      const [topLat, leftLon] = topLeft
      const [bottomLat, rightLon] = bottomRight
      boundsStr = `&bounds=${topLat},${leftLon},${bottomLat},${rightLon}`
    }
  }
  const response = await $api.get<TResponseRequest>(
    `/api/v1/requests?sort=-updated_at&fields=id,updated_at,count_opened_position,store,schedule_list,cost_finalists,cost_process,vacancy_label,closed,cost,priority_id,name,user,description,vacancy_id,reward_average_day,reward_average_month,is_student,is_patent,address_internship,is_internship,characteristic_list&per-page=
${queryParams?.perPage || 10}&page=${queryParams?.page || 1}${
  queryParams?.filters || ''
}&filter[status][in][]=92${boundsStr}`
  )
  return response
}
export async function fetchGetRequestsPagination(queryParams: TPaginationRequest): Promise<any> {
  const response = await $api.get<any>(
    `/api/v1/request/get-pagination?per-page=${queryParams.perPage}${queryParams?.filters || ''}&filter[status][in][]=92`
  )
  return response.data.DATA?.['page-count']
}

export async function fetchGetRequest(id: string): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.get<TResponseOneRequest>(`/api/v1/requests/${id}`)
  return response
}
export type TResponseHistoryRequestData = {
  DATA: THistoryRequestData[]
  ERR: string
}
export async function fetchGetCurators(): Promise<AxiosResponse<any>> {
  const response = await $api.get<TResponseOneRequest>('/api/v1/request/get-curator-list')
  return response
}
export async function fetchGetResponsible(): Promise<TOption[]> {
  const response = await $api.get<any>('/api/v1/request/get-responsible-list')
  return response
}
export async function fetchPostCurators(payload: any): Promise<AxiosResponse<any>> {
  const response = await $api.post<TResponseOneRequest>('/api/v1/request/update-curator', payload)
  return response
}
export async function fetchPostResponsible(payload: any): Promise<AxiosResponse<any>> {
  const response = await $api.post<TResponseOneRequest>('/api/v1/request/update-responsible', payload)
  return response
}
export async function fetchGetHistoryRequestData(payload: TRequestHistoryRequestData): Promise<AxiosResponse<TResponseHistoryRequestData>> {
  const response = await $api.get(`/api/v1/request/get-data-log?request_id=${payload.id}&per-page=${payload?.perPage || 10}&page=${payload?.page || 1}${
    payload?.filter || ''
  }`)
  return response
}
export async function fetchPatchRequestArchive(
  payload: TArchiveRequestStatus
): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.patch<TResponseOneRequest>(`/api/v1/requests/${payload.id}`, payload)
  return response
}

export async function fetchPatchRequestUpdateStatus(
  payload: TUpdateRequestStatus
): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.patch<TResponseOneRequest>(`/api/v1/requests/${payload.id}`, payload)
  return response
}
export async function fetchGetArchiveRequestUpdateStatus(
  payload: string
): Promise<AxiosResponse<any>> {
  const response = await $api.get<any>(`/api/v1/request/resurrection?request_id=${payload}`)
  return response
}

export async function fetchPostCommentRequest(payload: TCommentRequest): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.post<TResponseOneRequest>('/api/v1/request/add-comment', payload)
  return response
}

export async function fetchPostCreateLinkRequest(payload: TCreateLink): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.post('/api/v1/request/create-link', payload)
  return response
}

export async function fetchPostCreateRequest(payload: TPostRequest): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.post<TResponseOneRequest>('/api/v1/requests', payload)
  return response
}

export async function fetchPatchUpdateRequest(payload: TRequestPatchData): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.patch<TResponseOneRequest>(`/api/v1/requests/${payload.id}`, payload)
  return response
}

export async function fetchPostCreateLinksSchedule(
  payload: TCreateLinkShedule
): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.post('/api/v1/request/add-links-to-schedule', payload)

  return response
}

export async function fetchDeleteLinksSchedule(
  payload: TCreateLinkShedule
): Promise<AxiosResponse<TResponseOneRequest>> {
  const response = await $api.delete('/api/v1/request/remove-links-to-schedule', payload)
  return response
}

export async function fetchPostCreateRequestsCSV(payload: TRequestRequestsCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/request/export-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}`)
  return response
}

export async function fetchDeleteRequestLink(payload: TRequestRemoveRequestLink): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/request/remove-link?candidate_id=${payload.candidate_id}&request_id=${payload.request_id}`)
  return response
}

export async function fetchGetRequestHistoryV2(payload: TRequestHistoryRequestData): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/request/get-history-json?request_id=${payload.id}&page=${payload.page || 1}&per-page=${payload.perPage || 100}${payload.filter ? payload.filter : ''}`)
  return response
}
