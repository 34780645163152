import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { Dropdown, Tooltip, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import type { MenuProps } from 'antd'
import { isArray } from 'lodash'
import PhoneSvg from '../../../../common/svg/PhoneSvg'
import { selectCandidate } from '../../selectors'
import { useEntityContext } from '../../../../common/context/EntityContextProvider'
import Star from '../../../../common/svg/Star'
import { openUpdateModal, updateCandidate } from '../../actions'
import { updateCandidatePhone } from '../../utils'
import './CandidateCardRefreshButtons.scss'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { WhatsApp } from '../../../../common/svg/SvgFromIconWrapper/WhatsApp'

import CopySvg from '../../../../common/svg/CopySvg'
import { selectConnectedAsterisk, selectStatusPhone } from '../../../InnerRouter/selectors'
import { setCandidateSelected, setDestinationNumber } from '../../../InnerRouter/actions'

const MainCSSClass = getCssClassName('candidateCardRefresh')

function CandidateCardRefreshButton() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const statusPhone = useSelector(selectStatusPhone)
  const connectedWss = useSelector(selectConnectedAsterisk)
  const [isCopy, setIsCopy] = useState<boolean>(false)

  const {
    rbacControl, role, isCanAppointRecruiter, isColdSearch, department, has_active_interview
  } = useEntityContext()

  const items: MenuProps['items'] = useMemo(
    () => candidate?.phone.map((el, i) => ({
      key: i,
      label: (
        <div className={`${MainCSSClass}_dropdown`}>
          <Star color="#D5DBD6" />
          <span>{el}</span>
        </div>)
    })),
    [candidate?.phone]
  )
  const handleClickDropdown: MenuProps['onClick'] = ({ key }) => {
    if (Number(key) !== 0 && candidate) {
      dispatch(updateCandidate({ id: candidate?.id, phone: updateCandidatePhone(candidate.phone, Number(key)) }))
    }
  }
  const handleOpenModalEdit = () => {
    dispatch(openUpdateModal(true))
  }

  const handlePhone = useCallback(() => {
    if (!(statusPhone || !connectedWss) && candidate) {
      let newNumber = candidate?.phone?.[0]?.toString()
      if (newNumber.startsWith('7')) {
        newNumber = `8${newNumber.slice(1)}`
      } else {
        newNumber = candidate?.phone
      }
      dispatch(setDestinationNumber(newNumber))
      dispatch(setCandidateSelected(candidate))
    }
  }, [statusPhone, connectedWss, candidate])

  const copyToClipboard = (text) => {
    setIsCopy(true)
    navigator.clipboard.writeText(text).then(() => {
      console.log('Phone number copied to clipboard')
    }).catch((err) => {
      console.error('Failed to copy phone number: ', err)
    })
  }

  return (
    <div className={MainCSSClass}>
      <div className={`${MainCSSClass}__buttons-card`}>
        <div className={`${MainCSSClass}__buttons-card_phone`}>
          {candidate?.phone.length && candidate?.phone.length > 1 ? (
            <>
              <div className={`${MainCSSClass}__buttons-card_phone_container`}>
                <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                  <div className={`${MainCSSClass}__phone-button`}>
                    {candidate?.phone[0]}
                  </div>
                </div>
                {(!statusPhone || connectedWss) && (
                  <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                    <div
                      onClick={handlePhone}
                      className={`${MainCSSClass}__phone-button`}
                    >
                      <PhoneSvg fill="#262A28" />
                    </div>
                  </div>
                )}
                <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                  <Tooltip title="Номер скопирован" trigger="click">
                    <div
                      className={`${MainCSSClass}__phone-button`}
                      onClick={() => copyToClipboard(candidate?.phone[0])}
                    >
                      <CopySvg onClick={() => copyToClipboard(candidate?.phone[0])} />
                    </div>
                  </Tooltip>
                </div>
                <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                  <Typography.Paragraph className={`${MainCSSClass}__phone-button`}>
                    <Dropdown
                      menu={{
                        items,
                        defaultSelectedKeys: ['0'],
                        className: MainCSSClass,
                        onClick: handleClickDropdown
                      }}
                      placement="bottomRight"
                      arrow
                    >
                      <span className={`${MainCSSClass}__buttons-card_phone_dropdown_number_add`}>
                        еще
                        {' '}
                        {candidate?.phone.length ? candidate?.phone.length - 1 : '0'}
                        ...
                      </span>
                    </Dropdown>
                  </Typography.Paragraph>
                </div>
              </div>
              <div className={`${MainCSSClass}__buttons-card_phone_dropdown`}>
                <Typography.Paragraph className={`${MainCSSClass}__buttons-card_phone_dropdown_wa`}>
                  Написать:
                  {' '}
                  {candidate?.phone && department !== 175 && (
                    <WhatsApp
                      className={`${MainCSSClass}__icon`}
                      onClick={() => window.open(`https://wa.me/${isArray(candidate?.phone) ? candidate?.phone?.[0] : candidate?.phone}`)}
                    />
                  )}
                </Typography.Paragraph>
              </div>
            </>
          ) : (
            <>
              <div className={`${MainCSSClass}__buttons-card_phone_container`}>
                <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                  <div className={`${MainCSSClass}__phone-button`}>
                    {candidate?.phone[0]}
                  </div>
                </div>
                {(!statusPhone || connectedWss) && (
                  <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                    <div
                      onClick={handlePhone}
                      className={`${MainCSSClass}__phone-button`}
                    >
                      <PhoneSvg fill="#262A28" />
                    </div>
                  </div>
                )}
                <div className={`${MainCSSClass}__buttons-card_phone_edit`}>
                  <Tooltip title="Номер скопирован" trigger="click">
                    <div
                      className={`${MainCSSClass}__phone-button`}
                      onClick={() => copyToClipboard(candidate?.phone[0])}
                    >
                      <CopySvg onClick={() => copyToClipboard(candidate?.phone[0])} />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className={`${MainCSSClass}__buttons-card_phone_dropdown`}>
                <Typography.Paragraph className={`${MainCSSClass}__buttons-card_phone_dropdown_wa`}>
                  Написать:
                  {' '}
                  {candidate?.phone && department !== 175 && (
                    <WhatsApp
                      className={`${MainCSSClass}__icon`}
                      onClick={() => window.open(`https://wa.me/${isArray(candidate?.phone) ? candidate?.phone?.[0] : candidate?.phone}`)}
                    />
                  )}
                </Typography.Paragraph>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CandidateCardRefreshButton
