import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'listChecklist'

function ListChecklist({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6402 4.2318C9.06448 4.58537 9.1218 5.21593 8.76824 5.64021L6.26824 8.64021C5.94107 9.03281 5.37053 9.11555 4.94531 8.83208L3.44531 7.83208C2.98579 7.52572 2.86161 6.90485 3.16796 6.44532C3.47432 5.9858 4.09519 5.86162 4.55471 6.16797L5.30705 6.66953L7.23179 4.35984C7.58536 3.93556 8.21592 3.87824 8.6402 4.2318ZM10 7.00003C10 6.44774 10.4477 6.00003 11 6.00003H20C20.5523 6.00003 21 6.44774 21 7.00003C21 7.55231 20.5523 8.00003 20 8.00003H11C10.4477 8.00003 10 7.55231 10 7.00003ZM8.6402 9.23168C9.06448 9.58525 9.1218 10.2158 8.76824 10.6401L6.26824 13.6401C5.94108 14.0327 5.37055 14.1154 4.94534 13.832L3.44534 12.832C2.9858 12.5257 2.86161 11.9048 3.16795 11.4453C3.47429 10.9858 4.09515 10.8616 4.55469 11.1679L5.30703 11.6694L7.23179 9.35972C7.58536 8.93544 8.21592 8.87812 8.6402 9.23168ZM10 12C10 11.4477 10.4477 11 11 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H11C10.4477 13 10 12.5523 10 12ZM8.6402 14.2318C9.06448 14.5854 9.1218 15.2159 8.76824 15.6402L6.26824 18.6402C5.94107 19.0328 5.37053 19.1156 4.94531 18.8321L3.44531 17.8321C2.98579 17.5257 2.86161 16.9049 3.16796 16.4453C3.47432 15.9858 4.09519 15.8616 4.55471 16.168L5.30705 16.6695L7.23179 14.3598C7.58536 13.9356 8.21592 13.8782 8.6402 14.2318ZM10 17C10 16.4477 10.4477 16 11 16H20C20.5523 16 21 16.4477 21 17C21 17.5523 20.5523 18 20 18H11C10.4477 18 10 17.5523 10 17Z"
        fill="#404542"
      />
    </svg>
  )
}
export default ListChecklist
