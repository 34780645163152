import React, {
  FC, memo, useMemo
} from 'react'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import { Typography } from 'antd'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { TInterview, TInterviewHistory } from '../../../common/api/interviews'
import StatusInterviewTableRow from './StatusInterviewTableRow'
import ResultInterviewTableRow from './ResultInterviewTableRow'
import ButtonsTable from './ButttonsTable'
import './HistoryInterviewTableMobil.scss'
import TableMobil from '../../../common/components/table/TableMobil'
import TableInterviewMobil from '../../../common/components/table/TableInterviewMobil.'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

const mainCssClass = getCssClassName('interview-history-table')

type TProps= {
  historyData: TInterviewHistory[]
} & TClassName
type TRowData = {
  date: string
  recruiter_name: string
  buttons: JSX.Element
}

const useColumns = (): Column<any>[] => (
  useMemo(
    () => ([
      {
        accessor: 'date', Header: 'Дата'
      },
      { accessor: 'recruiter_name', Header: 'Рекрутер' },
      { accessor: 'buttons', Header: '' }

    ]),
    []
  )
)

const useRowsData = (interviews: TInterviewHistory[]) => {
  const rows: TRowData[] = useMemo(
    () => (
      interviews?.length ? interviews.map<TRowData>(({
        date, recruiter_name, research_name, status, result, labels, id
      }) => ({
        date,
        recruiter_name: labels?.recruiter || '-',
        buttons: <ButtonsTable id={id} />
      })) : []
    ),
    [interviews]
  )
  return { rows }
}
const HistoryInterviewsTableMobil: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows } = useRowsData(historyData)

  return (
    <TableInterviewMobil
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
    />
  )
}

export default memo(withErrorBoundary(HistoryInterviewsTableMobil))
