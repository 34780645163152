import React, { memo, useCallback } from 'react'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import './SimpleModalCandidate.scss'
import { useDispatch } from 'react-redux'
import { updateStatusCandidate } from '../../../features/candidateModals/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

type TProps = {
  candidateId?: number | null | string
  onOk: () => void
} & ModalProps

function ModalRemoveFromArchive({
  onOk, open, className, onCancel, candidateId
}: TProps) {
  const dispatch = useDispatch()

  const handleSubmit = useCallback(() => {
    if (candidateId) {
      dispatch(updateStatusCandidate({ id: candidateId, status: 49, isCandidateTable: false }))
      onOk()
    }
  }, [candidateId])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      title="Вернуть из отклоненных?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      centered
    >
      <p> Вы собираетесь вернуть кандидата из отклоненных. Подтвердить действие?</p>
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalRemoveFromArchive))
