import * as React from 'react'
import { useCallback, useEffect } from 'react'

import cn from 'classnames'

import './styles.scss'
import { Tooltip } from 'antd'
import { InformerProps, InformerSize } from './types'
import { CloseableOverlay } from './CloseableOverlay'
import { KeyCode } from '../../utils/utilities'
import { useControlledState } from '../../utils/useControlledState'
import { IconSize } from '../../svg/IconWrapper/IconBaseProps'
import { QuestionSolid } from '../../svg/SvgFromIconWrapper/QuestionSolid'

const DefaultIconsSizeMap: Record<InformerSize, IconSize> = {
  xs: 'xs',
  sm: 'xs',
  md: 'sm',
  lg: 'sm'
}

export function Informer({
  size = 'md',
  iconSize,
  defaultVisible = false,
  iconClassName,
  onChange,
  externalIsOpen,
  info,
  overlayRef,
  overlayClassName,
  wrapperClassName,
  ...props
}: InformerProps) {
  const [isOpened, toggle] = useControlledState(defaultVisible, externalIsOpen)

  function close(e: React.MouseEvent) {
    e.stopPropagation()
    toggle(false)
    onChange && onChange(false)
  }

  function handleVisibleChanged(visible: boolean) {
    toggle(visible)
    onChange && onChange(visible)
  }

  const closeInformer = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === KeyCode.ESC) {
        toggle(false)
      }
    },
    [toggle]
  )

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation()
  }

  useEffect(() => {
    document.addEventListener('keydown', closeInformer)
    return () => document.removeEventListener('keydown', closeInformer)
  }, [closeInformer])
  return (
    <span onClick={handleClick} className={cn('InformerWrapper', wrapperClassName)}>
      <Tooltip
        overlayClassName={cn('Overlay', [`Overlay__${size}`], overlayClassName)}
        overlay={(
          <CloseableOverlay overlayRef={overlayRef} onClick={close}>
            {info}
          </CloseableOverlay>
        )}
        visible={isOpened}
        trigger="click"
        onVisibleChange={handleVisibleChanged}
        {...props}
      >
        <QuestionSolid
          className={cn('informerIcon', iconClassName, {
            'InformerIcon__active': isOpened
          })}
          tabIndex={0}
          size={iconSize || DefaultIconsSizeMap[size]}
        />
      </Tooltip>
    </span>
  )
}
