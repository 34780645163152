import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Tabs, Typography } from 'antd'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import HeaderCandidate from './headerCandidate/HeaderCandidate'
import {
  selectCandidate, selectChatMobil, selectCheckDocModal, selectLoading, selectOpenHistoryCandidateData
} from '../selectors'
import ReturnButton from '../../InnerRouter/components/ReturnButton'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  getCandidate, getCandidateDocument, getCandidateQuestionnaire,
  getDictionaries, reset, setCandidateCoordinates
} from '../actions'
import {
  resetChat, setPageMessages
} from '../../../features/chat/actions'
import { getCandidateResume, reset as resetResume } from '../../Resume/actions'
import { reset as resetTasks, setFilter } from '../../Tasks'

import Information from './infoCandidate/InfoCandidate/Information'
import SummaryCandidate from './infoCandidate/SummaryCandidate/SummaryCandidate'
import DocumentCandidate from './infoCandidate/DocumentCandidate/DocumentCandidate'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { selectPageMessages } from '../../../features/chat/selectors'
import QuestionnaireCandidate from './infoCandidate/questionnaireCandidate/QuestionnaireCandidate'
import ModalHistoryActions from './ModalHistoryActions'
import UserAvatarMobile from '../../../common/svg/UserAvatarMobile'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import MoreGrid from '../../../common/svg/MoreGrid'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import RespondsCandidate from './infoCandidate/RespondsCandidate/RespondsCandidate'
import { fetchGetFieldCoordinatesYandex } from '../../../common/api/yandex'
import { selectUserInfo } from '../../InnerRouter/selectors'
import ModalCheckDoc from './ModalCheckDoc'
import CandidateHistory from '../../../features/chat/components/CandidateHistory'
import CandidateComment from './CandidateComment/CandidateComment'
import './InfoCandidate.scss'
import './Candidate.scss'
import CandidateHistoryDelete from '../../../features/chat/components/CandidateHistoryDelete'

const { TabPane } = Tabs

function Candidate() {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const page = useSelector(selectPageMessages)
  const loading = useSelector(selectLoading)
  const openChat = useSelector(selectChatMobil)
  const checkDocModal = useSelector(selectCheckDocModal)
  const userInfo = useSelector(selectUserInfo)
  const historyModal = useSelector(selectOpenHistoryCandidateData)
  const [historyKey, setHistoryKey] = useState(0)
  const [commentKey, setCommentKey] = useState(0)
  const [oldHistoryKey, setOldHistoryKey] = useState(0)

  const [isMoreInfo, setIsMoreInfo] = useState<boolean>(false)
  const { has_active_interview } = useEntityContext()
  const {
    rbacControl, department
  } = useEntityContext()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const activeInterview = useMemo(() => {
    if (has_active_interview && 'id' in has_active_interview) {
      return has_active_interview
    }
    return null
  }, [has_active_interview])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const
    isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])
  const MainCSSClass = 'candidateContainer'
  const { entityId } = useParams()

  const handleChangeMoreInfo = () => {
    setIsMoreInfo(true)
  }
  const handleOpenChat = () => {
    setIsMoreInfo(false)
  }

  useEffect(() => {
    if (entityId) {
      dispatch(getCandidate(entityId))
    }
  }, [entityId])

  useEffect(() => {
    dispatch(setPageMessages(1))
  }, [])

  useEffect(() => {
    dispatch(
      getDictionaries([
        'stageStatus',
        'gender',
        'appendType',
        'currency',
        'schedule',
        'WorkSites',
        'internshipDuration',
        'schedule',
        'rejectionReason',
        'candidateStatus',
        'keySkills',
        'language',
        'languageLevel',
        'documentType',
        'yes_no',
        'citizenship',
        'transferTime',
        'educationLevel',
        'region',
        'employment',
        'archiveReason',
        'stageState',
        'typeRegistrationWork',
        'interviewResult',
        'office',
        'targetTask'
      ])
    )
  }, [])

  const handleClickTab = (key: string) => {
    if (candidate?.id && Number(key) === 2) { dispatch(getCandidateResume(candidate?.id)) }
    if (candidate?.id && Number(key) === 3) { dispatch(getCandidateDocument(candidate?.id)) }
    if (candidate?.id && Number(key) === 5) { dispatch(getCandidateQuestionnaire(candidate?.id)) }
    if (Number(key) === 6) {
      setHistoryKey((prevKey) => prevKey + 1)
    }
    if (Number(key) === 7) {
      setCommentKey((prevKey) => prevKey + 1)
    }
    if (Number(key) === 8) {
      setOldHistoryKey((prevKey) => prevKey + 1)
    }
  }

  useEffect(
    () => () => {
      dispatch(reset())
      dispatch(resetChat())
      dispatch(resetResume())
      dispatch(resetTasks())
    },
    []
  )
  useEffect(() => {
    if (entityId) {
      dispatch(setFilter(`&candidate_id=${entityId}`))
    }
  }, [])

  useEffect(() => {
    if (candidate?.address) {
      fetchGetFieldCoordinatesYandex(candidate.address).then((data) => {
        if (data && data?.length) {
          dispatch(setCandidateCoordinates(data[0]?.value.split(',').map(parseFloat)))
        }
      })
    }
  }, [candidate?.address])


  return (
    <div className={`${MainCSSClass}`}>
      {loading && (
        <div className={`${MainCSSClass}__preloader`}>
          <Preloader size="md" />
          {' '}
        </div>
      )}
      <NewScrollContainer>
        {!loading && candidate && (
          <>
            {!isMobile && (
              <div className={`${MainCSSClass}_candidateReturnButton`}>
                <ReturnButton />
              </div>
            )}
            <div className={`${MainCSSClass}_candidateContent`}>
              <div className={`${MainCSSClass}_candidateInfo`}>
                {isMobile && (
                  <div className={`${MainCSSClass}_candidate-header-mobil`}>
                    <div className={`${MainCSSClass}_candidate-header-logo`}>
                      <Typography.Text>
                        <div className={`${MainCSSClass}_arrow-right`}>
                          <ArrowRightSM onClick={handleOpenChat} />
                        </div>
                        <div className={`${MainCSSClass}_user-avatar`}>
                          <UserAvatarMobile className={`${MainCSSClass}_avatar-mobil`} />
                        </div>

                        {
                          candidate?.second_name
                            ? `${candidate?.last_name} ${candidate?.name} ${candidate?.second_name}`
                            : `${candidate?.last_name} ${candidate?.name}`
                        }
                      </Typography.Text>

                    </div>
                    <div className={`${MainCSSClass}_candidate-header-actions`}>
                      <MoreGrid onCLick={handleChangeMoreInfo} />
                    </div>
                  </div>
                )}

                <HeaderCandidate isMobile={isMobile} />
                <Tabs className="infoCandidate" onTabClick={handleClickTab} defaultActiveKey="1">
                  <TabPane tab="Информация" key="1">
                    <Information />
                  </TabPane>
                  <TabPane tab="История" key="6">
                    <CandidateHistory key={historyKey} />
                  </TabPane>
                  <TabPane tab="Cтарая история" key="8">
                    <CandidateHistoryDelete key={oldHistoryKey} />
                  </TabPane>
                  <TabPane tab="Комментарии" key="7">
                    <CandidateComment key={commentKey} />
                  </TabPane>
                  {userInfo?.role?.role_id !== 'courier_curator' && rbacControl?.leads.page_access && (
                    <TabPane tab="Отклики" key="4">
                      <RespondsCandidate />
                    </TabPane>
                  )}
                  {department === 174 && userInfo?.role?.role_id !== 'courier_curator' && (
                    <TabPane tab="Резюме" key="2">
                      <SummaryCandidate />
                    </TabPane>
                  )}
                  <TabPane tab="Документы" key="3">
                    <DocumentCandidate />
                  </TabPane>
                  {/* <TabPane tab="Действия" key="4">
                  <ActionsCandidate />
                </TabPane> */}
                  {!activeInterview && userInfo?.role?.role_id !== 'courier_curator' && (
                    <TabPane tab="Анкета" key="5">
                      <QuestionnaireCandidate />
                    </TabPane>
                  )}
                </Tabs>

              </div>
              {/* <div className={`${MainCSSClass}_candidateChat`}> */}
              {/*   {!isMobile && (<Chat />)} */}
              {/* </div> */}
            </div>
          </>
        )}
        { historyModal && <ModalHistoryActions />}
        {checkDocModal && <ModalCheckDoc open={checkDocModal} />}
      </NewScrollContainer>
    </div>
  )
}

export default memo(withErrorBoundary(Candidate))
