import React, {
  FC, memo, ReactNode, useCallback, useMemo, useState
} from 'react'
import { Checkbox, Tag, Typography } from 'antd'

import { useDispatch } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TDATA } from '../../candidate/types'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import CardUser from '../../../common/svg/CardUser'
import { navigateTo } from '../../../common/actions/navigate'
import './Container.scss'
import { TContainer } from '../types'
import { TClassName } from '../../../common/types/props'
import ArrowUpSM from '../../../common/svg/ArrowUpSM'
import ArrowDownSM from '../../../common/svg/ArrowDownSM'

type TProps = {
  container: TContainer
  setValue: (values:number[], containerId: string) => void
  checkedDisabled: number | null
  setCheckedValue:(value:number | null)=> void
  handleChangeSort: (containerId: number, sortOrder: string) => void
} & TClassName
const mainCssClass = 'container'
const Contrainer: FC<TProps> = ({
  container, setValue, className, checkedDisabled, setCheckedValue, handleChangeSort
}) => {
  const dispatch = useDispatch()

  const handleOnChange = (e) => {
    if (e.target.checked) {
      const items = container.items.map((item) => item.id)
      setValue(items, e.target.name)
      setCheckedValue(container.id)
    } else {
      setCheckedValue(null)
      setValue([], e.target.name)
    }
  }

  return (
    <div className={cn(className, mainCssClass)}>
      <Checkbox
        name={`${container?.id}`}
        value={container?.isChecked}
        onChange={handleOnChange}
        className={`${mainCssClass}__checkbox`}
        disabled={typeof checkedDisabled === 'number' ? checkedDisabled !== container.id : false}
      >
        <div className={`${mainCssClass}__stage-name`}>
          {container.name}
        </div>

      </Checkbox>
      <Typography.Paragraph className={`${mainCssClass}__labels`}>
        <div className={`${mainCssClass}__text`}>
          Кандидаты:&nbsp;
          {container?.length ? container?.length : 'Не подсчитано'}
        </div>

        <div className={`${mainCssClass}__time`}>
          2д 4ч
        </div>
      </Typography.Paragraph>
      {container.sortOrder === 'desc'
        ? <ArrowUpSM onClick={() => handleChangeSort(container.id, container.sortOrder)} className={`${mainCssClass}__cursor`} />
        : <ArrowDownSM onClick={() => handleChangeSort(container.id, container.sortOrder)} className={`${mainCssClass}__cursor`} />}
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(Contrainer))
