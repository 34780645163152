import React from 'react'
import { Input, InputProps } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import cn from 'classnames'
import './InputPasswordFormControl.scss'
import Required from '../../svg/Required'

type TControlInputProps = Omit<
  UseControllerProps &
    InputProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      required?: boolean
    },
  'isDisabled'
>

function InputPasswordFormControl({
  name,
  control,
  rules,
  className,
  size = 'large',
  label,
  required,
  ...rest
}: TControlInputProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  return (
    <>
      {!label && (
        <div
          className={cn(
            className,
            'inputPasswordFormControl',
            // { inputLarge: size === 'large' },
            // { inputMiddle: size === 'middle' },
            // { inputSmall: size === 'small' }
          )}
        >
          <Input.Password size={size} value={field.value} onChange={field.onChange} onBlur={field.onBlur} {...rest} />
        </div>
      )}
      {label && (
        <div
          className={cn(
            className,
            'inputPasswordFormControl',
            { inputLarge: size === 'large' },
            { inputMiddle: size === 'middle' },
            { inputSmall: size === 'small' },
            { error: fieldState.error }
          )}
        >
          <p className="inputLabel">
            {label}
            {required && <Required className="required" />}
          </p>
          <Input.Password
            size={size}
            value={field.value}
            className={fieldState.error ? 'error' : ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...rest}
          />
        </div>
      )}
    </>
  )
}

export default InputPasswordFormControl
