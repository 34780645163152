import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'empty-candidate-comment'

function EmptyCandidateComment({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="24" fill="#E8ECE9" />
      <path d="M26.7071 25.2929C26.3166 24.9024 25.6834 24.9024 25.2929 25.2929C24.9024 25.6834 24.9024 26.3166 25.2929 26.7071L26.5858 28L25.2929 29.2929C24.9024 29.6834 24.9024 30.3165 25.2929 30.7071C25.6834 31.0976 26.3166 31.0976 26.7071 30.7071L28 29.4142L29.2929 30.7071C29.6834 31.0976 30.3165 31.0976 30.7071 30.7071C31.0976 30.3166 31.0976 29.6834 30.7071 29.2929L29.4142 28L30.7071 26.7071C31.0976 26.3166 31.0976 25.6834 30.7071 25.2929C30.3165 24.9024 29.6834 24.9024 29.2929 25.2929L28 26.5858L26.7071 25.2929Z" fill="#404542" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28 18C22.4772 18 18 22.4772 18 28C18 29.7928 18.4726 31.4781 19.3008 32.9351L18.5458 35.2C18.4742 35.4147 18.4005 35.6356 18.3564 35.823C18.3135 36.0056 18.247 36.3473 18.3775 36.7132C18.5289 37.1377 18.863 37.4712 19.2867 37.6223C19.6523 37.7527 19.994 37.6864 20.1766 37.6435C20.3648 37.5993 20.5866 37.5253 20.802 37.4535L23.065 36.6991C24.522 37.5273 26.2073 38 28.0001 38C33.523 38 38 33.5228 38 28C38 22.4772 33.5228 18 28 18ZM28 20C23.5817 20 20 23.5817 20 28C20 29.4551 20.3876 30.8166 21.0644 31.9903L21.0796 32.0161C21.132 32.1055 21.2383 32.2865 21.292 32.4807C21.3377 32.6459 21.3546 32.81 21.3428 32.9827C21.3291 33.183 21.2635 33.3746 21.2322 33.466L21.2234 33.4919L20.581 35.419L22.5083 34.7765L22.5342 34.7677C22.6266 34.7362 22.8169 34.6711 23.0172 34.6574C23.188 34.6457 23.3524 34.6619 23.5193 34.708C23.7135 34.7617 23.8941 34.8676 23.984 34.9204L24.0099 34.9355C25.1835 35.6124 26.545 36 28.0001 36C32.4184 36 36 32.4183 36 28C36 23.5817 32.4183 20 28 20Z" fill="#404542" />
    </svg>

  )
}
export default EmptyCandidateComment
