import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { useForm } from 'react-hook-form'
import ControlButton from '../../../common/components/Buttons/Button'
import { selectUserInfo } from '../../InnerRouter/selectors'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputPasswordFormControl from '../../../common/components/formcontrol/InputPasswordFormControl'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { changePassword } from '..'

import './ChangePassword.scss'

type TFormValues = {
  new_password: string
  new_password_confirm: string
  old_password: string
}

const mainCssClass = getCssClassName('change-password')

function ChangePassword() {
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()

  const { control, handleSubmit, reset, getValues, watch } = useForm<TFormValues>()

  const handleClick = () => {
    dispatch(changePassword({ ...getValues() }))
    reset()
  }

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>Смена пароля</Typography.Title>

      <div className={`${mainCssClass}_form`}>
        <InputPasswordFormControl
          name="old_password"
          control={control}
          label="Текущий пароль"
          required
          rules={{ required: true }}
          type="password"
          placeholder="Введите текущий пароль"
        />
        <InputPasswordFormControl
          name="new_password"
          control={control}
          label="Новый пароль"
          autoComplete="new-password"
          required
          rules={{ required: true }}
          placeholder="Введите новый пароль"
        />
        <InputPasswordFormControl
          name="new_password_confirm"
          control={control}
          label="Подтверждение пароля"
          required
          rules={{ required: true }}
          placeholder="Введите новый пароль повторно"
        />

        <div>
          <ControlButton
            typeButton="primary"
            size="large"
            state="default"
            className="button"
            onClick={handleSubmit(handleClick)}
          >
            Сохранить
          </ControlButton>
        </div>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(ChangePassword))
