/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, {
  FC, memo, useState
} from 'react'
import { Typography } from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'

import './ParsingList.scss'
import { TClassName } from '../../../common/types/props'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { getCssClassName } from '../../../common/utils/generateClassName'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import ControlButton from '../../../common/components/Buttons/Button'
import { optionsPagination } from '../../../common/components/options'
import { PausePhoneSmall } from '../../../common/svg/SvgFromIconWrapper/PausePhoneSmall'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ParsingTable from './ParsingTable'
import ParsingModal from './ParsingModal'

const mainCssClass = getCssClassName('parsing__list')

type TFormValues = {
  perPagesCount: number
}

const Parsing: FC<TClassName> = ({ className }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: 10
    }
  })
  const loading = false
  const handleCancelModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const parsingData = [{
    name: 'ИМЯ111111', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '321'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1234'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '12345'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '11123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123333'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1232222'
  },{
    name: 'ИМЯ111111', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '321'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1234'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '12345'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '11123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123333'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1232222'
  },{
    name: 'ИМЯ111111', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '321'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1234'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '12345'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '11123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123333'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1232222'
  },{
    name: 'ИМЯ111111', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '321'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1234'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '12345'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '11123'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '123333'
  }, {
    name: 'ИМЯ1', text: 'Какой-то текст', status: 'активный', activeNumber: '0', processedNumber: '0', successedNumber: '0', failedNumber: '0', returnedNumber: '0', id: '1232222'
  }]

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>Парсинг</Typography.Title>
          {!!loading && <Preloader size="md" />}
        </div>
        <div className={`${mainCssClass}__container-buttons-phone`}>
          <div className={`${mainCssClass}__pause-button`}>
            <ControlButton icon="left" size="large" typeButton="secondary" className="button">
              <PausePhoneSmall className={`${mainCssClass}__pause-phone`} />
              Остановить все
            </ControlButton>
          </div>
        </div>
      </div>
      <div className={`${mainCssClass}__container-info`}>
        <div className={`${mainCssClass}__container-title`}>
          <Typography.Title level={5} className={`${mainCssClass}__title`}>
            Результат:&nbsp;
            {0 || 'неизестно'}
          </Typography.Title>
        </div>
        <div>
          <div className={`${mainCssClass}__container-pagination`}>
            <SelectFormControl
              className={`${mainCssClass}__pagination-count`}
              name="perPagesCount"
              options={optionsPagination}
              control={control}
            />

            <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
              <ArrowRightSM
                className="arrowCursor"
                size="1"
              />
              <Typography.Text className={`${mainCssClass}__pagination-font`}>
                {0 || 'неизвестно'}
              </Typography.Text>
              <ArrowLeftSM
                className="arrowCursor"
                size="1"
              />

            </div>
          </div>
        </div>
      </div>
      <div className={`${mainCssClass}__scroll-div`}>
        <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
          <ParsingTable parsingData={parsingData} className={mainCssClass} handleOpenModal={handleOpenModal} />
        </NewScrollContainer>
      </div>
      <ParsingModal open={openModal} onCancel={handleCancelModal} onOk={handleCancelModal} parsingData={parsingData} />
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(Parsing))
