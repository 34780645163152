import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import { isNumber, orderBy, sortBy } from 'lodash'
import { NavLink } from 'react-router-dom'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { TRequest } from '../../../common/api/request'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import RequestNameTable from './RequestNameTable'
import { navigateTo } from '../../../common/actions/navigate'
import ButtonsTable from './ButttonsTable'
import logo from '../../../common/svg/Logo'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import Vacation from './VacancyTableRow'
import './RequestTable.scss'
import { openEditRequestCustomerModal, saveActiveRequestCustomer } from '../../Request/actions'

const mainCssClass = getCssClassName('request-table')

type TProps = {
  requests: TRequest[]
} & TClassName
type TRowData = {
  name: JSX.Element
  vacancy: JSX.Element | string
  cost_process: JSX.Element | string
  cost_finalists: JSX.Element | string
  cost: JSX.Element | string
  schedule_list: JSX.Element | string
  responsible: JSX.Element | string
  customer: JSX.Element | string
  buttons: JSX.Element | string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'name',
      Header: 'Заявка',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.name && valueB?.props?.name) {
          return valueA.props.name.localeCompare(valueB.props.name, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'vacancy',
      Header: 'Вакансия',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId].props.children
        const valueB = rowB.original[columnId].props.children
        return Number(valueA) > Number(valueB) ? 1 : -1
      }
    },
    {
      accessor: 'cost_process',
      Header: 'В работе',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId].props.children
        const valueB = rowB.original[columnId].props.children
        return Number(valueA) > Number(valueB) ? 1 : -1
      }
    },
    {
      accessor: 'cost_finalists',
      Header: 'Финалисты',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId].props.children
        const valueB = rowB.original[columnId].props.children
        return Number(valueA) > Number(valueB) ? 1 : -1
      }
    },
    {
      accessor: 'cost',
      Header: 'Позиции',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId].props.children
        const valueB = rowB.original[columnId].props.children
        return Number(valueA) > Number(valueB) ? 1 : -1
      }
    },
    {
      accessor: 'schedule_list',
      Header: 'График',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'responsible',
      Header: 'Ответственный',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string' && valueB !== '-' && valueA !== '-') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'customer',
      Header: 'Заказчик',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'buttons', Header: '' }
  ],
  []
)

const useRowsData = (requests: TRequest[]) => {
  const dispatch = useDispatch()
  const handleEditCustomer = (id:string | number, created_at: string, customer:number[], customer_list: any[]) => {
    if ([customer]) {
      dispatch(saveActiveRequestCustomer({
        id, created_at, customer, customer_list
      }))
      dispatch(openEditRequestCustomerModal(true))
    }
  }
  const rows: TRowData[] = useMemo(
    () => (requests?.length
      ? requests.map<TRowData>(
        ({
          name,
          cost_process,
          cost_finalists,
          cost,
          schedule_list,
          user,
          id,
          priority_id,
          vacancy_label,
          created_at,
          customer_list,
          responsible_list
        }) => ({
          name: (
            <NavLink to={`/application/${id}`} className={`${mainCssClass}__link`}>
              <div className={`${mainCssClass}__link_name`}>
                <RequestNameTable className={`${mainCssClass}__link_name_table`} name={name || '-'} priority={priority_id} id={id} />
              </div>
            </NavLink>),
          vacancy: (<div className={`${mainCssClass}__link_name`}>
            { vacancy_label ? (<Vacation label={vacancy_label} />) : ''}
          </div>),
          cost_process: (<div className={`${mainCssClass}__link_name`}>
            <div className={`${mainCssClass}__link_name_width`}>
              {isNumber(cost_process) ? String(cost_process) : '-'}
            </div>
          </div>),
          cost_finalists: (<div className={`${mainCssClass}__link_name`}>
            {isNumber(cost_finalists) ? String(cost_finalists) : '-'}
          </div>),
          cost: (<div className={`${mainCssClass}__link_name`}>
            {isNumber(cost) ? String(cost) : '-'}
          </div>),
          schedule_list: (<div className={`${mainCssClass}__link_name`}>
            {schedule_list ? schedule_list?.map((e) => e.value).join(', ') : '-'}
          </div>),
          responsible: (<div className={`${mainCssClass}__link_name`}>
            {responsible_list ? `${responsible_list.surname}  ${responsible_list.name} ${responsible_list.patronymic}` : '-'}
          </div>),
          customer: (<div className={`${mainCssClass}__link_customer`}>
            {customer_list.length ? customer_list.map((item) => (
              <div className={`${mainCssClass}__link_customer`} onClick={() => handleEditCustomer(item.id, item.created_at, [item.id], customer_list)}>
                {item.surname}
                {' '}
                {item.name}
                {' '}
                {item.patronymic}
                {' '}
              </div>
            )) : '-'}
          </div>),
          buttons: (<div className={`${mainCssClass}__link_name`}>
            <ButtonsTable id={id} />
          </div>),
          id
        })
      )
      : []),
    [requests]
  )
  const handleRowClick = () => {

  }
  return { rows, handleRowClick }
}
const RequestsTable: FC<TProps> = ({ className, requests }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(requests)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      sortabled
      withoutLastColumnElement
      withoutHeaderDividers
      handleRowClick={handleRowClick}
    />
  )
}

export default memo(RequestsTable)
