import React from 'react'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'unionNewYear'

function UnionNewYear({ className, size = '1', color }: TSvgProps) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96791 0.460916C7.32072 -0.153639 6.2714 -0.153639 5.6242 0.460916L0.243023 5.57069C-0.0353428 5.83501 -0.0797699 6.2487 0.136677 6.56094C3.05372 10.769 6.79376 14.4066 11.1541 17.2767L12.0119 17.8414C12.3844 18.0866 12.8884 18.0436 13.2089 17.7392L18.5146 12.7012C19.1618 12.0866 19.1618 11.0902 18.5146 10.4757L15.0607 7.19599C16.7121 7.01678 17.9948 5.68521 17.9948 4.06916C17.9948 2.33094 16.5108 0.921832 14.6802 0.921832C12.9784 0.921832 11.5761 2.13981 11.3873 3.70788L7.96791 0.460916ZM16.3375 4.06916C16.3375 4.93828 15.5955 5.64283 14.6802 5.64283C13.765 5.64283 13.023 4.93828 13.023 4.06916C13.023 3.20005 13.765 2.4955 14.6802 2.4955C15.5955 2.4955 16.3375 3.20005 16.3375 4.06916Z"
        fill="#FFD608"
      />
    </svg>
  )
}

export default UnionNewYear
