/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux'
import {
  Tag,
  Button, Modal, ModalProps, Typography
} from 'antd'
import React, {
  memo,
  useMemo
} from 'react'

import cn from 'classnames'

import { NavLink } from 'react-router-dom'
import ControlButton from '../../../common/components/Buttons/Button'
import { TInterview, TInterviewHistory } from '../../../common/api/interviews'
import './ModaViewMoreInfo.scss'
import { getInterviewInfo, getInterviewInfoHistory, getMoreInterviewInfo } from '../../InterviewCard/utils'
import HistoryInterviewModals from '../../../features/interviewModals/components/HistoryInterviewModals'
import { navigateTo } from '../../../common/actions/navigate'
import { setOpenMoreInfoModal, updateInterview } from '../actions'
import { selectDictionaryOptionsByName } from '../../InterviewCard/selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { DetailsList } from '../../../common/components/DetailsList'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import candidate from '../../candidate/components/Candidate'

const { Title } = Typography
const mainCssClass = 'more-info-interview-modals'

type TProps = {
  tags: {label: string, value: string}[]
  interview: TInterview | null
} & ModalProps
function MoreInfoInterview({
  open, className, onCancel, interview, tags
}: TProps) {
  const dispatch = useDispatch()
  const interviewNote = useSelector(selectDictionaryOptionsByName('interviewNote'))
  const interviewResult = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const interviewStatus = useSelector(selectDictionaryOptionsByName('statusResult'))

  const detailsListInterview = useMemo(() => getMoreInterviewInfo(interview, interviewStatus, interviewResult, interviewNote, interview?.characteristic.label), [interview, interviewStatus, interviewResult, interviewNote])

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }
  const handleSubmit = (e) => {
    if (interview?.id) {
      if (e.ctrlKey || e.metaKey || e.which === 2) {
        // Если Ctrl была нажата, открываем ссылку в новой вкладке
        window.open(`/interview/${interview?.id}`, '_blank')
      } else {
        dispatch(navigateTo(`/interview/${interview?.id}`))
        dispatch(setOpenMoreInfoModal(false))
      }
    }
  }
  return (
    <CommonModal
      centered
      open={open}
      onCancel={handleModalClose}
      onOk={handleSubmit}
      closable
      title="История интервью"
      className={cn(className, mainCssClass)}
      width="400px"
      wrapClassName="wrapModal"
      typeButtonReject="secondary"
      typeButtonAccept="primary"
      okText="Перейти к интервью"
    >

      <div className={`${mainCssClass}_content-modal`}>
        <div>
          <div className={`${mainCssClass}_header`}>
            <Title level={4}>Вакансия:</Title>
          </div>
          { tags ? (
            <Tag key={tags?.value} className="customLabel" color="blue">
              {tags?.label}
            </Tag>
          ) : <span> Нет прикрепленных вакансий </span>}
        </div>
        <NavLink to={`/interview/${interview?.id}`} className={`${mainCssClass}__link`}>
          <DetailsList
            size="md"
            items={[{
              items: detailsListInterview
            }]}
          />
        </NavLink>
        {interview?.confirm === 'unconfirmed' && (
          <ControlButton typeButton="primary" className={cn('button', `${mainCssClass}_button`)} onClick={() => dispatch(updateInterview({ confirm: 'confirmed', id: interview.id, period: interview.period }))}>
            Подтвердить интервью
          </ControlButton>
        )}

      </div>
    </CommonModal>
  )
}

export default memo(withErrorBoundary(MoreInfoInterview))
