import React from 'react'
import ChatCircle from '../../../common/svg/ChatCircle'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './ColorPicker.scss'



const mainCssClass = getCssClassName('color-picker')

function ColorPickerOptionGreen() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#0F6E55" />
      <ChatCircle fill="#369D82" />
      <ChatCircle fill="#8AD8C3" />
      <ChatCircle fill="#D0FAEF" />
    </div>
  )
}

function ColorPickerOptionBlue() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#2864BE" />
      <ChatCircle fill="#4C86DD" />
      <ChatCircle fill="#4C86DD" />
      <ChatCircle fill="#CDE1FF" />
    </div>
  )
}

function ColorPickerOptionPlum() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#5A2882" />
      <ChatCircle fill="#8F55BD" />
      <ChatCircle fill="#C192E8" />
      <ChatCircle fill="#EBD4FE" />
    </div>
  )
}

function ColorPickerOptionBeet() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#731446" />
      <ChatCircle fill="#B13978" />
      <ChatCircle fill="#DC75AB" />
      <ChatCircle fill="#FFC6E4" />
    </div>
  )
}

function ColorPickerOptionBread() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#73230F" />
      <ChatCircle fill="#AB533D" />
      <ChatCircle fill="#D89888" />
      <ChatCircle fill="#F9E1DB" />
    </div>
  )
}


function ColorPickerOptionCarrot() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#FA5F0A" />
      <ChatCircle fill="#FA7E3A" />
      <ChatCircle fill="#FF9E69" />
      <ChatCircle fill="#FFDDCB" />
    </div>
  )
}

function ColorPickerOptionTomato() {
  return (
    <div className={mainCssClass}>
      <ChatCircle fill="#DF1E12" />
      <ChatCircle fill="#EF544B" />
      <ChatCircle fill="#F98983" />
      <ChatCircle fill="#FFCAC7" />
    </div>
  )
}


export const ColorPickerOptions = [{ label: <ColorPickerOptionGreen />, value: 'green' },
  { label: <ColorPickerOptionBlue />, value: 'blue' }, { label: <ColorPickerOptionPlum />, value: 'plum' },
  { label: <ColorPickerOptionBeet />, value: 'beet' }, { label: <ColorPickerOptionBread />, value: 'bread' },
  { label: <ColorPickerOptionCarrot />, value: 'carrot' }, { label: <ColorPickerOptionTomato />, value: 'tomato' }]
