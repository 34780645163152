import React, { memo, useCallback } from 'react'
import { Modal, ModalProps } from 'antd'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { createCommentRequest } from '../actions'
import '../../candidate/components/SimpleModalCandidate.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

type TProps = {
  requestId?: number | null | string
  onOk: () => void
} & ModalProps

function ModalCommentRequest({
  onOk, open, className, onCancel, requestId
}: TProps) {
  const {
    control, getValues, reset, handleSubmit
  } = useForm<TFormValues>()
  const dispatch = useDispatch()

  type TFormValues = {
    comment: string
  }

  const onSubmit = useCallback(() => {
    if (requestId) {
      dispatch(createCommentRequest({ request_id: requestId, ...getValues() }))
      onOk()
      reset()
    }
  }, [requestId])

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
    reset()
  }

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleModalClose}
      title="Комментарий"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"

    >
      <TextAreaFormControl
        name="comment"
        autoSize={{ minRows: 4, maxRows: 5 }}
        control={control}
        label="Текст комментария"
        required
        rules={{ required: true }}
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalCommentRequest))
