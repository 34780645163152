import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { TDATA } from '../../candidate/types'

import './PhotTable.scss'
import Duplicate from './Duplicate'
import TableFluidity from '../../../common/components/table/TableFluidity'
import { TClassName } from '../../../common/types/props'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TableCostOfHiring from '../../../common/components/table/TableCostOfHiring'
import TableAnalytic from "../../../common/components/table/TableAnalytic";
import TablePhot from "../../../common/components/table/TablePhot";

const mainCssClass = getCssClassName('phot-table')

type TProps = {
  data: TRowData[]
} & TClassName

type TRowData = {
  full_name: string
  plan: number
  implementation_plan: string
  formalized: number
  hours_worked: number
  salary: string
  payment_hour: string
  basic_salary: string
  plan_premium: string
  additional_bonus: string
  additional_bonus_information: string
  total: string
  total_ndfl: string
  up_to_finish_plan: string
  above_plan: string
  fomicheva: string
  x2: string
  interview_positive_character: string
  reutskaya: string
  research_work: string
  buttons: JSX.Element
  id:number
}
const data: TRowData[] = [
  {
    full_name: 'Иван Иванов',
    plan: 205,
    implementation_plan: '111,22%',
    formalized: 228,
    hours_worked: 159,
    salary: '70 000,00 ₽',
    payment_hour: '440,25 ₽',
    basic_salary: '70 000,00 ₽',
    plan_premium: '30 000,00 ₽',
    additional_bonus: '20 000,00 ₽',
    additional_bonus_information: 'премия от Туник',
    total: '120 000,00 ₽',
    total_ndfl: '137 931,00 ₽',
    up_to_finish_plan: '137 931,00 ₽',
    above_plan: '137 931,00 ₽',
    fomicheva: '137 931,00 ₽',
    x2: '137 931,00 ₽',
    interview_positive_character: '137 931,00 ₽',
    reutskaya: '137 931,00 ₽',
    research_work: '137 931,00 ₽',
    buttons: <ButtonsTable id={1} />,
    id: 1
  },
  {
    full_name: 'Петр Петров',
    plan: 210,
    implementation_plan: '105,67%',
    formalized: 222,
    hours_worked: 160,
    salary: '75 000,00 ₽',
    payment_hour: '468,75 ₽',
    basic_salary: '75 000,00 ₽',
    plan_premium: '35 000,00 ₽',
    additional_bonus: '25 000,00 ₽',
    additional_bonus_information: 'премия от Иванова',
    total: '135 000,00 ₽',
    total_ndfl: '154 695,00 ₽',
    up_to_finish_plan: '154 695,00 ₽',
    above_plan: '154 695,00 ₽',
    fomicheva: '154 695,00 ₽',
    x2: '154 695,00 ₽',
    interview_positive_character: '154 695,00 ₽',
    reutskaya: '154 695,00 ₽',
    research_work: '154 695,00 ₽',
    buttons: <ButtonsTable id={2} />,
    id: 2
  },
  {
    full_name: 'Александр Александров',
    plan: 215,
    implementation_plan: '115,35%',
    formalized: 248,
    hours_worked: 170,
    salary: '80 000,00 ₽',
    payment_hour: '470,59 ₽',
    basic_salary: '80 000,00 ₽',
    plan_premium: '40 000,00 ₽',
    additional_bonus: '30 000,00 ₽',
    additional_bonus_information: 'премия от Петрова',
    total: '150 000,00 ₽',
    total_ndfl: '171 464,00 ₽',
    up_to_finish_plan: '171 464,00 ₽',
    above_plan: '171 464,00 ₽',
    fomicheva: '171 464,00 ₽',
    x2: '171 464,00 ₽',
    interview_positive_character: '171 464,00 ₽',
    reutskaya: '171 464,00 ₽',
    research_work: '171 464,00 ₽',
    buttons: <ButtonsTable id={3} />,
    id: 3
  },
  {
    full_name: 'Сергей Сергеев',
    plan: 220,
    implementation_plan: '108,18%',
    formalized: 238,
    hours_worked: 180,
    salary: '85 000,00 ₽',
    payment_hour: '472,22 ₽',
    basic_salary: '85 000,00 ₽',
    plan_premium: '45 000,00 ₽',
    additional_bonus: '35 000,00 ₽',
    additional_bonus_information: 'премия от Александрова',
    total: '165 000,00 ₽',
    total_ndfl: '188 990,00 ₽',
    up_to_finish_plan: '188 990,00 ₽',
    above_plan: '188 990,00 ₽',
    fomicheva: '188 990,00 ₽',
    x2: '188 990,00 ₽',
    interview_positive_character: '188 990,00 ₽',
    reutskaya: '188 990,00 ₽',
    research_work: '188 990,00 ₽',
    buttons: <ButtonsTable id={4} />,
    id: 4
  },
  {
    full_name: 'Николай Николаев',
    plan: 225,
    implementation_plan: '112,44%',
    formalized: 253,
    hours_worked: 190,
    salary: '90 000,00 ₽',
    payment_hour: '473,68 ₽',
    basic_salary: '90 000,00 ₽',
    plan_premium: '50 000,00 ₽',
    additional_bonus: '40 000,00 ₽',
    additional_bonus_information: 'премия от Сергеева',
    total: '180 000,00 ₽',
    total_ndfl: '206 518,00 ₽',
    up_to_finish_plan: '206 518,00 ₽',
    above_plan: '206 518,00 ₽',
    fomicheva: '206 518,00 ₽',
    x2: '206 518,00 ₽',
    interview_positive_character: '206 518,00 ₽',
    reutskaya: '206 518,00 ₽',
    research_work: '206 518,00 ₽',
    buttons: <ButtonsTable id={5} />,
    id: 5
  },
  {
    full_name: 'Михаил Михайлов',
    plan: 230,
    implementation_plan: '106,09%',
    formalized: 244,
    hours_worked: 200,
    salary: '95 000,00 ₽',
    payment_hour: '475,00 ₽',
    basic_salary: '95 000,00 ₽',
    plan_premium: '55 000,00 ₽',
    additional_bonus: '45 000,00 ₽',
    additional_bonus_information: 'премия от Николаева',
    total: '195 000,00 ₽',
    total_ndfl: '223 046,00 ₽',
    up_to_finish_plan: '223 046,00 ₽',
    above_plan: '223 046,00 ₽',
    fomicheva: '223 046,00 ₽',
    x2: '223 046,00 ₽',
    interview_positive_character: '223 046,00 ₽',
    reutskaya: '223 046,00 ₽',
    research_work: '223 046,00 ₽',
    buttons: <ButtonsTable id={6} />,
    id: 6
  },
  {
    full_name: 'Владимир Владимиров',
    plan: 235,
    implementation_plan: '110,21%',
    formalized: 259,
    hours_worked: 210,
    salary: '100 000,00 ₽',
    payment_hour: '476,19 ₽',
    basic_salary: '100 000,00 ₽',
    plan_premium: '60 000,00 ₽',
    additional_bonus: '50 000,00 ₽',
    additional_bonus_information: 'премия от Михайлова',
    total: '210 000,00 ₽',
    total_ndfl: '240 584,00 ₽',
    up_to_finish_plan: '240 584,00 ₽',
    above_plan: '240 584,00 ₽',
    fomicheva: '240 584,00 ₽',
    x2: '240 584,00 ₽',
    interview_positive_character: '240 584,00 ₽',
    reutskaya: '240 584,00 ₽',
    research_work: '240 584,00 ₽',
    buttons: <ButtonsTable id={7} />,
    id: 7
  },
  {
    full_name: 'Алексей Алексеев',
    plan: 240,
    implementation_plan: '115,42%',
    formalized: 277,
    hours_worked: 220,
    salary: '105 000,00 ₽',
    payment_hour: '478,64 ₽',
    basic_salary: '105 000,00 ₽',
    plan_premium: '65 000,00 ₽',
    additional_bonus: '55 000,00 ₽',
    additional_bonus_information: 'премия от Владимирова',
    total: '225 000,00 ₽',
    total_ndfl: '259 131,00 ₽',
    up_to_finish_plan: '259 131,00 ₽',
    above_plan: '259 131,00 ₽',
    fomicheva: '259 131,00 ₽',
    x2: '259 131,00 ₽',
    interview_positive_character: '259 131,00 ₽',
    reutskaya: '259 131,00 ₽',
    research_work: '259 131,00 ₽',
    buttons: <ButtonsTable id={8} />,
    id: 8
  },
  {
    full_name: 'Дмитрий Дмитриев',
    plan: 245,
    implementation_plan: '108,57%',
    formalized: 266,
    hours_worked: 230,
    salary: '110 000,00 ₽',
    payment_hour: '479,13 ₽',
    basic_salary: '110 000,00 ₽',
    plan_premium: '70 000,00 ₽',
    additional_bonus: '60 000,00 ₽',
    additional_bonus_information: 'премия от Алексеева',
    total: '240 000,00 ₽',
    total_ndfl: '278 689,00 ₽',
    up_to_finish_plan: '278 689,00 ₽',
    above_plan: '278 689,00 ₽',
    fomicheva: '278 689,00 ₽',
    x2: '278 689,00 ₽',
    interview_positive_character: '278 689,00 ₽',
    reutskaya: '278 689,00 ₽',
    research_work: '278 689,00 ₽',
    buttons: <ButtonsTable id={9} />,
    id: 9
  },
  {
    full_name: 'Евгений Евгеньевич',
    plan: 250,
    implementation_plan: '111,60%',
    formalized: 279,
    hours_worked: 240,
    salary: '115 000,00 ₽',
    payment_hour: '480,42 ₽',
    basic_salary: '115 000,00 ₽',
    plan_premium: '75 000,00 ₽',
    additional_bonus: '65 000,00 ₽',
    additional_bonus_information: 'премия от Дмитриева',
    total: '255 000,00 ₽',
    total_ndfl: '299 258,00 ₽',
    up_to_finish_plan: '299 258,00 ₽',
    above_plan: '299 258,00 ₽',
    fomicheva: '299 258,00 ₽',
    x2: '299 258,00 ₽',
    interview_positive_character: '299 258,00 ₽',
    reutskaya: '299 258,00 ₽',
    research_work: '299 258,00 ₽',
    buttons: <ButtonsTable id={10} />,
    id: 10
  }
]

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'full_name',
      Header: 'ФИО',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'plan',
      Header: 'План',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'implementation_plan',
      Header: 'Выполнение плана %',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'formalized',
      Header: 'Оформленных',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'hours_worked',
      Header: 'Отработанные часы',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'salary',
      Header: 'ОКЛАД',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'payment_hour',
      Header: 'Оплата за 1 час',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'basic_salary',
      Header: 'Основная зп',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'plan_premium',
      Header: 'Премия за план',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'additional_bonus',
      Header: 'Доп премия',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'additional_bonus_information',
      Header: 'Инфо по доп премии',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'total',
      Header: 'Итого',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'total_ndfl',
      Header: 'Итого с НДФЛ',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'up_to_finish_plan',
      Header: 'До 100% плана',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'above_plan',
      Header: 'Сверх плана',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'fomicheva',
      Header: 'Фомичева',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'x2',
      Header: 'Х2',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'interview_positive_character',
      Header: 'Интервью (положит. харак-ка)',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'reutskaya',
      Header: 'Реуцкая',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'research_work',
      Header: 'ХП+Ресечерская работа',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'buttons', Header: '' }

  ],
  []
)

const useRowsData = (data: TRowData[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const { original: { id } } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (data?.length
      ? data.map<TRowData>(({
        full_name,
        plan,
        implementation_plan,
        formalized,
        hours_worked,
        salary,
        payment_hour,
        basic_salary,
        plan_premium,
        additional_bonus,
        additional_bonus_information,
        total,
        total_ndfl,
        up_to_finish_plan,
        above_plan,
        fomicheva,
        x2,
        interview_positive_character,
        reutskaya,
        research_work,
        buttons,
        id
      }) => ({
        full_name: full_name || '-',
        plan: plan || 0,
        implementation_plan: implementation_plan || '-',
        formalized: formalized || 0,
        hours_worked: hours_worked || 0,
        salary: salary || '-',
        payment_hour: payment_hour || '-',
        basic_salary: basic_salary || '-',
        plan_premium: plan_premium || '-',
        additional_bonus: additional_bonus || '-',
        additional_bonus_information: additional_bonus_information || '-',
        total: total || '-',
        total_ndfl: total_ndfl || '-',
        up_to_finish_plan: up_to_finish_plan || '-',
        above_plan: above_plan || '-',
        fomicheva: fomicheva || '-',
        x2: x2 || '-',
        interview_positive_character: interview_positive_character || '-',
        reutskaya: reutskaya || '-',
        research_work: research_work || '-',
        buttons: buttons || <ButtonsTable id={id} />,
        id
      }))
      : []),
    [data]
  )

  return { rows, handleRowClick }
}
const PhotTable: FC<TProps> = ({ className, data }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(data)


  return (
    <TablePhot
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(PhotTable))
