import React from 'react'
import './Svg.scss'

        type TSvgProps = {
        className?: string
        size?: string
        fill?: string
        fillBg?: string
        }

const mainCssClass = 'logo-NewYear'

function LogoNewYear({
  className, size = '1', fill, fillBg
}: TSvgProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logoVV">
        <g clipPath="url(#clip0_2_2237)">
          <path id="Vector" d="M43.3573 0H4.64272C2.07861 0 0 2.07861 0 4.64272V43.3573C0 45.9214 2.07861 48 4.64272 48H43.3573C45.9214 48 48 45.9214 48 43.3573V4.64272C48 2.07861 45.9214 0 43.3573 0Z" fill="#2DBE64" />
          <path id="Rectangle 10" d="M0 43L5.52556 40.2372C9.56527 38.2174 14.3513 38.3716 18.2526 40.6473C21.8728 42.7591 26.2733 43.0528 30.142 41.4408L31.1612 41.0162C34.2813 39.7161 37.7664 39.5888 40.973 40.6577L48 43V48H0V43Z" fill="white" fillOpacity="0.8" />
          <path id="Rectangle 9" d="M0 40L6.61538 42.7564C10.0615 44.1923 13.9385 44.1923 17.3846 42.7564L18.6154 42.2436C22.0615 40.8077 25.9385 40.8077 29.3846 42.2436L30.6154 42.7564C34.0615 44.1923 37.9385 44.1923 41.3846 42.7564L48 40V48H0V40Z" fill="white" fillOpacity="0.6" />
          <g id="Group">
            <path id="Vector_2" d="M35.6718 32.2352H24.0066V15.764H34.6296C38.0585 15.764 39.9199 17.9009 39.9199 20.4292C39.9199 21.5641 39.3482 22.7239 38.4709 23.6012C39.7636 24.2835 40.8723 25.8224 40.8723 27.4261C40.8723 30.2484 38.6697 32.2344 35.6718 32.2344V32.2352ZM28.0775 28.9216H35.1171C36.1035 28.9216 36.7526 28.1921 36.7526 27.2954V27.2691C36.7526 26.3724 36.1035 25.6429 35.1171 25.6429H28.0775V28.9216ZM28.0775 22.3293H34.0997C35.1658 22.3293 35.8002 21.5997 35.8002 20.7031C35.8002 19.8064 35.2052 19.0768 34.0997 19.0768H28.0775V22.3293Z" fill="white" />
            <path id="Vector_3" d="M16.8479 32.2352H8.22861V15.764H15.8066C19.2354 15.764 21.0968 17.9009 21.0968 20.4292C21.0968 21.5641 20.5251 22.7239 19.6478 23.6012C20.9406 24.2835 22.0492 25.8224 22.0492 27.4261C22.0492 30.2484 19.8466 32.2344 16.8487 32.2344L16.8479 32.2352ZM12.2996 28.9216H16.294C17.2804 28.9216 17.9295 28.1921 17.9295 27.2954V27.2691C17.9295 26.3724 17.2804 25.6429 16.294 25.6429H12.2996V28.9216ZM12.2996 22.3293H15.2766C16.3427 22.3293 16.9771 21.5997 16.9771 20.7031C16.9771 19.8064 16.3822 19.0768 15.2766 19.0768H12.2996V22.3293Z" fill="white" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_2237">
          <rect width="48" height="48" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default LogoNewYear
