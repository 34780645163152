import React, { memo, useCallback } from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import $api from '../../../common/api/http'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import './SimpleModalCandidate.scss'
import { appointRecruiter } from '../actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { selectUserInfo } from '../../InnerRouter/selectors'

const mainCssClass = 'simpleModalCandidate'

type TFormValues = {
  responsible: number
  isRecruiter: any
}

export type TProps = {
  responsible?: number | null
  candidate_id: number
} & ModalProps

function ModalAppointRecruiter({
 open, className, onCancel, responsible, candidate_id
}: TProps) {
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const values = [{ label: 'Назначить себе', value: true }]
  const {
    control, handleSubmit, reset, getValues, watch
  } = useForm<TFormValues>()

  const fetchRecruiterList = useCallback(async (name: string) => {
    if (name) {
      const data = await $api.get(`/api/v1/user/user-list?role_id=recruiter&search=${name}`)
      return data.data.DATA.map((obj: { id: number; name: string; surname: string }) => ({
        label: `${obj.name} ${obj.surname}`,
        value: obj.id
      }))
    }
    return []
  }, [])

  const isRecruiter = watch('isRecruiter')
  const responsibleFromForm = watch('responsible')
  const onSubmit = () => {
    if (candidate_id) {
      if (isRecruiter?.length) {
        const responsible = localStorage.getItem('user_id')
        dispatch(appointRecruiter({ id: candidate_id, responsible }))
      } else {
        dispatch(appointRecruiter({ id: candidate_id, responsible: responsibleFromForm }))
      }
    }
  }
  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      centered
      onCancel={onCancel}
      title={responsible ? 'Переназначить рекрутера' : 'Назначить рекрутера'}
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      {userInfo?.role.role_id === 'recruiter' && (
        <CheckBoxFormControl
          options={values}
          control={control}
          name="isRecruiter"
        />
      )}
      <DebounceSelectFormControl
        name="responsible"
        control={control}
        placeholder="Выберите рекрутера"
        fetchOptions={fetchRecruiterList}
        notFoundContent="Начните вводить имя"
        label="Рекрутер"
        rules={{ required: !isRecruiter?.length }}
        disabled={!!isRecruiter?.length}
        required
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalAppointRecruiter))
