import { InformerOverlayProps } from '../types'
import './styles.scss'
import CloseSM from '../../../svg/CloseSM'

export function CloseableOverlay({ children, onClick, overlayRef }: InformerOverlayProps) {
  return (
    <div className="CloseableOverlay" ref={overlayRef}>
      {children}
      <div className="CloseableOverlay_btn" onClick={onClick} role="button">
        <CloseSM className="closeableOverlay__btnIcon" />
      </div>
    </div>
  )
}
