import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import cn from 'classnames'
import { Column } from 'react-table'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Table from '../../../common/components/table/Table'
import HistoryActionNameTable from './HistoryActionNameTable'
import { THistoryCandidateData, TOldHistoryCandidateData } from '../../../common/api/Candidate'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import CandidateOldHistoryTableStatus from './CandidateOldHistoryTableStatus'
import TableHistory from '../../../common/components/table/TableHistory'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
    historyData: TOldHistoryCandidateData[];
    className?: string;
};

type TRowData = {
    history: JSX.Element;
};

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'history',
      Header: ''
    }
  ],
  []
)

const useRowsData = (historyData: TOldHistoryCandidateData[]) => {
  const dispatch = useDispatch()
  const rows: TRowData[] = useMemo(
    () => (historyData?.length
      ? historyData.map<TRowData>(({
        id, after, before, action
      }) => ({
        history: (
          <CandidateOldHistoryTableStatus
            key={id}
            after={after}
            before={before}
            action={<HistoryActionNameTable action={action || '-'} after={after || ''}/>}
          />
        )
      }))
      : []),
    [historyData]
  )

  return { rows }
}

const CandidateOldHistoryTable: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows } = useRowsData(historyData)

  return (
    <TableHistory
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidateOldHistoryTable))
