import React, { useEffect, useRef } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useController, UseControllerProps } from 'react-hook-form'
import { DebounceInput } from 'react-debounce-input'
import cn from 'classnames'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Search from '../../../common/svg/Search'
import './InputFormControlGlobalSearch.scss'
import CloseSM from '../../../common/svg/CloseSM'

type TControlInputProps = Omit<
  UseControllerProps & {
    className?: string
    size?: 'small' | 'middle' | 'large'
    label?: string
    index?: number
    required?: boolean
    placeholder?:string,
    inputRef?:any
  },
  'isDisabled'
>

const mainCssClass = getCssClassName('global-search')

function InputFormControlGlobalSearch({
  name,
  control,
  rules,
  className,
  size = 'large',
  placeholder,
  ...rest
}: TControlInputProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  const inputElementRef = useRef(null)

  useEffect(() => {
    // Перемещаем курсор в конец input после установки фокуса
    if (inputElementRef.current && field.value) {
      inputElementRef.current.focus()
      inputElementRef.current.selectionStart = inputElementRef.current.selectionEnd = inputElementRef.current.value.length
    }
  }, [field.value])

  const handleCLick = () => {
    field.onChange('')
  }
  return (
    <div
      className={cn(
        className,
        mainCssClass,
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' },
        { error: fieldState.error }
      )}
    >
      <Search />
      <DebounceInput
        size={size}
        debounceTimeout={1000}
        value={field.value}
        placeholder={placeholder}
        onChange={(event) => field.onChange(event.target.value)}
        inputRef={inputElementRef}
        {...rest}
      />
      {field.value && <CloseSM onClick={handleCLick} />}
    </div>
  )
}

export default InputFormControlGlobalSearch
