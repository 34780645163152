import { PayloadAction } from '@reduxjs/toolkit'
import {
  put, race, select, spawn, take, takeLatest
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  getCalendar, getTimeSlots, setCalendar, setLoading, setTimeSlots, TCalendarRequest, setInterview, getInterview, TRequestUpdateConfirmInterview, updateInterview, setDictionaries, getDictionaries
} from './actions'
import { fetchGetCalendar, fetchGetSlots, TCalendarResponse } from '../../common/api/calendar'
import { fetchGetInterview, fetchPatchInterview, TInterviewResponse } from '../../common/api/interviews'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { selectCalendars, selectSlots } from './selectors'
import { setLoadingProgress } from '../InnerRouter/actions'
import {fetchGetCandidateHistoryV2} from "../../common/api/Candidate";
import {getHistoryCandidateV2} from "../candidateV2/actions";

const moduleName = 'Календарь'

type TAxiosInterviewResponse = AxiosResponse<TInterviewResponse>

function* getCalendarSaga({ payload }: PayloadAction<TCalendarRequest>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TCalendarResponse>;
    ({response} = yield race({
      response: fetchGetCalendar(payload.user_id, payload.period, payload?.page),
      cancel: take(getCalendar.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить календарь',
          error: response
        })
      )
    } else {
      yield put(setCalendar(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}
function* getTimeSlotsSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response:  AxiosResponse<string[]>;
    ({response} = yield race({
      response: fetchGetSlots(payload),
      cancel: take(getTimeSlots.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setTimeSlots(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getInterviewSaga({ payload }: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    let response:  TAxiosInterviewResponse;
    ({response} = yield race({
      response: fetchGetInterview(payload.toString()),
      cancel: take(getInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response
        })
      )
    } else {
      yield put(setInterview(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateInterviewSaga({ payload }: PayloadAction<TRequestUpdateConfirmInterview>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response:  TAxiosInterviewResponse;
    ({response} = yield race({
      response: fetchPatchInterview(payload),
      cancel: take(updateInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось подтвердить интервью',
          error: response
        })
      )
    } else {
      yield put(setInterview(response.data.DATA))
      if (payload.period) {
        const trimmedPeriod = payload.period.replace(/ /g, '') // Удаление всех пробелов из payload.period
        const calendar = yield select(selectCalendars) // Замените YourCalendarType на ваш тип данных

        const updatedCalendar = calendar.map((cal) => {
          if (cal.slots && cal.slots[trimmedPeriod]) {
            return {
              ...cal,
              slots: {
                ...cal.slots,
                [trimmedPeriod]: {
                  ...cal.slots[trimmedPeriod],
                  confirm: 'confirmed'
                }
              }
            }
          }
          return cal
        })

        // Отправляем обновленный calendar в редюсер
        yield put(setCalendar(updatedCalendar))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'календарь страница')
}

function* calendarSaga() {
  yield takeLatest(getCalendar, getCalendarSaga)
  yield takeLatest(getTimeSlots, getTimeSlotsSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getInterview, getInterviewSaga)
  yield takeLatest(updateInterview, updateInterviewSaga)
}

export default calendarSaga
