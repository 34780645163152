import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import type { UploadProps } from 'antd'
import {
  Button, message, Upload, Typography
} from 'antd'
import './DocumentCandidate.scss'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFile } from 'antd/es/upload/interface'
import UploadDocumentSvg from '../../../../../common/svg/UploadDocumentSvg'
import withErrorBoundary from '../../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectCandidate, selectDocumentList } from '../../../selectors'
import {
  getCandidateDocument, removeDocuments
} from '../../../actions'
import Paperclip_Attechment_Tilt from '../../../../../common/svg/Paperclip_Attechment_Tilt'
import { useEntityContext } from '../../../../../common/context/EntityContextProvider'

const MainCSSClass = 'documentCandidate'

function DocumentCandidate() {
  const candidate = useSelector(selectCandidate)
  const document_list = useSelector(selectDocumentList)
  const dispatch = useDispatch()
  const { rbacControl } = useEntityContext()
  const fileListDefault: UploadFile[] = useMemo(() => {
    if (candidate) {
      return document_list.map((item) => ({
        name: item.name, status: 'done', url: item.url, uid: item.id.toString()
      }))
    }
    return []
  }, [document_list])
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const props: UploadProps = useMemo(() => ({
    name: 'file',
    action: `${process.env.DOMAIN}/api/v1/candidate/upload-document?candidate_id=${candidate.id}&document_id=89`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file успешно загружен`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDownload(file) {
    },
    onRemove(file) {
      if (candidate && candidate.id) {
        dispatch(removeDocuments({
          fileId: file.uid,
          candidateId: candidate.id
        }))
      }
      const newFileList = fileList.filter((item) => item.uid !== file.uid) || []
      setFileList(newFileList)
    },
    onSuccess() {
      dispatch(getCandidateDocument(candidate?.id))
    }
  }), [candidate])

  useEffect(() => {
    setFileList(fileListDefault)
  }, [fileListDefault, candidate])

  return (
    <div className={`${MainCSSClass}`}>
      <div className={`${MainCSSClass}_header`}>
        <Typography.Title level={4}>Документы кандидата:</Typography.Title>
        { rbacControl?.candidate?.edit_view_access === 1 && (
          <Upload {...props} fileList={fileList} className="customUpload">
            <Button>
              <Paperclip_Attechment_Tilt />
              Загрузить документ
            </Button>
          </Upload>
        )}
      </div>
      {!fileList?.length && (
        <div className={`${MainCSSClass}_content`}>
          <UploadDocumentSvg className={`${MainCSSClass}_content_svg`} size="1" />
          <h5>Ни одного документа не загружно</h5>
          <span>Загрузите первый документ, и он появится в этом разделе</span>
        </div>
      )}
    </div>
  )
}

export default memo(withErrorBoundary(DocumentCandidate))
