import React, { useEffect, useMemo, useState } from 'react'
import { Tabs } from 'antd'
import './InfoRequest.scss'
import InfoTabRequest from './InfoTabRequest'
import CandidateTabRequest from '../CandidateTabRequest'
import ReserveTabRequest from './ReserveTabRequest'

const { TabPane } = Tabs

const mainCssClass = 'infoRequest'

function InfoRequest() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const isMobile = useMemo(() => windowSize.width < 450 && windowSize.height < 900, [windowSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Tabs className={mainCssClass}>
      <TabPane tab="Информация" key="1">
        <InfoTabRequest />
      </TabPane>
      {isMobile
       && (
         <TabPane tab="Кандидаты" key="2">
           <CandidateTabRequest />
         </TabPane>
       )}
      <TabPane tab="Резервы" key="3">
        <ReserveTabRequest />
      </TabPane>
    </Tabs>
  )
}

export default InfoRequest
