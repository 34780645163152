/* eslint-disable no-console */
import React, { memo } from 'react'
import { useForm } from 'react-hook-form'
import { Typography } from 'antd'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import Input from '../../../common/components/formcontrol/InputFormControl'
import Logo from '../../../images/logo.svg'
import './formAuth.scss'
import Stroke from '../../../images/stroke.svg'
import { getAuth } from '../actions'
import RejectSvg from '../../../common/svg/RejectSvg'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import FoodPatern from '../../../common/svg/FoodPatern'

const { Title } = Typography

function FormAuth(): JSX.Element {
  const dispatch = useDispatch()

  interface TFormValues {
    email: string
    password: string
  }

  const { control, handleSubmit, getValues } = useForm<TFormValues>()

  const onSubmit = () => {
    const redirectUri = localStorage.getItem('redirectUrl')
    dispatch(getAuth({ ...getValues(), redirectUrl: redirectUri || '' }))
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  const mainCssClass = 'auth'
  return (
    <div className="auth-page" onKeyDown={handleKeyDown}>
      <div id="svg-container">
        <FoodPatern />
      </div>
      <div className={mainCssClass}>

        <div className={`${mainCssClass}_header`}>
          <Logo />
          <Title>Войти в систему</Title>
        </div>
        <div className={`${mainCssClass}_credentials`}>
          <Input
            name="email"
            type="email"
            control={control}
            label="Логин"
            rules={{ required: true }}

          />
          <Input
            name="password"
            type="password"
            control={control}
            label="Пароль"
            rules={{ required: true }}

          />
        </div>
        <button className={`${mainCssClass}_login-btn`} type="button" onClick={handleSubmit(onSubmit)}>
          Войти
          <Stroke />
        </button>
        <span>Если у вас нет или вы забыли логин и пароль, обратитесь к администратору</span>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(FormAuth))
