import {
  put, select, spawn, takeLatest
} from 'redux-saga/effects'
import { AxiosResponse, AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  getDialogs, setDialogs, setHeaders, setLoading, getCandidate, setCandidate
} from './actions'
import { fetchGetDialogs, TRequestDialogs } from '../../common/api/chatPanel'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { selectDialogs } from './selectors'
import { fetchGetCandidate } from '../../common/api/Candidate'
import { TCandidate, TDATA } from '../../compositions/candidate/types'

const moduleName = 'Чат панель'

function* getDialogsSaga({ payload }: PayloadAction<TRequestDialogs>) {
  try {
    yield put(setLoading(true))
    const response: any = yield fetchGetDialogs(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить диалоги',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setDialogs(response.data.DATA))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))
      showSuccessNotification({
        text: 'Диалоги получены'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
  }
}
function* getCandidateSaga({ payload }: PayloadAction<string>) {
  try {
    const response: AxiosResponse<TCandidate> = yield fetchGetCandidate(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          // @ts-ignore
          error: response as unknown as AxiosError
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
    }
  } catch (error) {
    // @ts-ignore
    throw Error(error)
  }
}

export function* chatPanelSaga() {
  yield takeLatest(getDialogs, getDialogsSaga)
  yield takeLatest(getCandidate, getCandidateSaga)
}
