import React, {
  forwardRef, Fragment, useImperativeHandle, useLayoutEffect
} from 'react'
import cn from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { Button, Checkbox, Pagination } from 'antd'
import {
  Row, useExpanded, useFilters, usePagination, useSortBy, useTable
} from 'react-table'
import { TTableProps } from './types'
import './TableHistory.scss'

const mainCssClass = 'table-history'

export type TableRefType = {
    toggleAllRowsExpanded: (value: boolean) => void
}

const TableHistory = forwardRef<TableRefType, TTableProps<any>>(({
  className,
  columns,
  data,
  renderRowSubComponent,
  sortabled,
  withMarker = false,
  withFilters,
  withPagination,
  withoutDividers,
  withoutHeaderDividers,
  withExpandCellHeader,
  hideExpandingRowCloseButton,
  handleRowClick,
  onTableLayoutUpdate,
  renderCustomRowWrapper,
  unionWithExpanded = false,
  withControlIconShow = false,
  initialExpanded = {},
  initialSortBy = [],
  autoResetExpanded = true,
  initialPageIndex = 0,
  autoCloseExpanded = false,
  autoResetPage = true
}, ref) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    visibleColumns,
    prepareRow,
    page,
    toggleRowExpanded,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
        {
          columns,
          data,
          defaultColumn: { Filter: () => null },
          initialState: {
            pageIndex: initialPageIndex,
            expanded: initialExpanded,
            sortBy: initialSortBy
          },
          autoResetPage,
          autoResetExpanded
        } as any,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
  )

  const rowsToDisplay = withPagination ? (page as Row<any>[]) : rows

  useImperativeHandle(ref, () => ({
    toggleAllRowsExpanded: (value: boolean) => page.forEach(({ id }) => {
      toggleRowExpanded([id], value)
    })
  }))

  useLayoutEffect(() => {
    onTableLayoutUpdate?.({ rows: rowsToDisplay })
  }, [rowsToDisplay])

  const RowWrapper = renderCustomRowWrapper || Fragment

  const classNames = cn(className, mainCssClass, {
    [`${mainCssClass}_with-marker`]: withMarker,
    [`${mainCssClass}_without-dividers`]: withoutDividers,
    [`${mainCssClass}_without-header-dividers`]: withoutDividers || withoutHeaderDividers
  })
  const rowClasses = cn({ [`${mainCssClass}_active-row`]: !!handleRowClick })

  return (
    <>
      <table className={classNames} {...getTableProps()}>
        <thead>
          {headerGroups.map(({ headers }) => (
            <Fragment key={uniqueId()}>
              <tr>
                {headers.map((column) => (
                  <th {...column.getHeaderProps([
                    { className: cn(column.className) },
                    sortabled ? (column as any).getSortByToggleProps() : {}
                  ])}
                  >
                    <div className={`${mainCssClass}__header-row`}>
                      <span>{column.render('Header')}</span>
                      {/* Здесь иконка сортировки */}
                    </div>
                  </th>
                ))}
                {/* eslint-disable-next-line */}
                            {withExpandCellHeader && <th className={`${mainCssClass}__expanding-header-cell`} />}
              </tr>
              {withFilters && (
                <tr>
                  {headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Filter')}
                    </th>
                  ))}
                </tr>
              )}
            </Fragment>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rowsToDisplay.map((row) => {
            prepareRow(row)
                    type TRowData = object & {
                        isLoading: boolean
                        markered: boolean
                        className: string
                    }
                    const {
                      isLoading,
                      markered: isMarkered,
                      className: rowClassName
                    } = (row.original as TRowData)

                    const { collapseIcon } = row.original as object & {
                        collapseIcon?: { show: boolean; isDisabled: boolean }
                    }
                    // const CollapseIcon = row.isExpanded ? иконка открыта : иконка закрыта
                    const collapseIconShow = collapseIcon?.show

                    const rowClass = `${mainCssClass}__row`
                    const rowCssClasses = cn(rowClassName, rowClasses, rowClass, {
                      [`${rowClass}_is-expanded`]: !unionWithExpanded && row.isExpanded,
                      [`${rowClass}_markered`]: withMarker && isMarkered
                    })
                    const subRowCssClasses = cn(
                      rowClassName,
                      `${mainCssClass}__expanded-section`,
                      rowClass,
                      {
                        [`${mainCssClass}__expanded-section_closable`]: !hideExpandingRowCloseButton,
                        [`${rowClass}_markered`]: withMarker && isMarkered
                      }
                    )

                    return (

                      <RowWrapper key={row.id} {...(renderCustomRowWrapper ? (autoCloseExpanded ? { row, rows } : { row }) : {})}>
                        <tr
                          {...row.getRowProps()}
                          className={rowCssClasses}
                          onClick={() => handleRowClick?.(row)}
                        >

                          {row.cells.map((cell, index) => (
                            <td
                              key={index}
                              className={cn(
                                {
                                  ...cell.getCellProps([
                                    {
                                      className: cell.column.className
                                    }
                                  ])
                                },
                                unionWithExpanded && row.isExpanded ? `${mainCssClass}__cell-without-box-shadow` : ''
                              )}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}

                          {/* {collapseIconShow ? ( */}
                          {/*   <td> */}
                          {/*     <CollapseIcon */}
                          {/*       className={cn( */}
                          {/*         `${mainCssClass}__collapse-icon${collapseIcon?.isDisabled ? '_is-disabled' : ''}`, */}
                          {/*         unionWithExpanded && row.isExpanded ? `${mainCssClass}__cell-without-box-shadow` : '' */}
                          {/*       )} */}
                          {/*       style={{ ...(unionWithExpanded && row.isExpanded ? { boxShadow: 'none' } : {}) }} */}
                          {/*       onClick={(event) => { */}
                          {/*         event.stopPropagation() */}
                          {/*         if (!collapseIcon?.isDisabled) { */}
                          {/*           (row.getToggleRowExpandedProps() as any)?.onClick?.() */}
                          {/*         } */}
                          {/*       }} */}
                          {/*     /> */}
                          {/*   </td> */}
                          {/* ) : withoutLastColumnElement ? null : ( */}
                          {/*   <td */}
                          {/*     className={unionWithExpanded && row.isExpanded ? `${mainCssClass}__cell-without-box-shadow` : ''} */}
                          {/*   /> */}
                          {/* )} */}
                          {/* {isLoading && <div className={`${mainCssClass}__loader-wrapper`}><Preloader /></div>} loader custom */}
                        </tr>
                        { (withControlIconShow ? (collapseIconShow && row.isExpanded) : row.isExpanded)
                          ? (
                            <tr className={subRowCssClasses}>
                              <td colSpan={collapseIconShow ? visibleColumns.length + 1 : visibleColumns.length}>
                                {renderRowSubComponent?.({ row })}

                                {!collapseIconShow && !hideExpandingRowCloseButton && (
                                  <Button
                                    type="link"
                                    onClick={() => (row.getToggleRowExpandedProps() as any)?.onClick?.()}
                                  >
                                    Свернуть
                                  </Button>
                                )}
                              </td>
                            </tr>
                          )
                          : null}
                      </RowWrapper>
                    )
          })}
        </tbody>
      </table>
      {/* {withPagination && pageCount > 1 && ( */}
      {/*   // <div className={`${mainCssClass}__pagination`}> */}
      {/*   //   <Pagination */}
      {/*   //     totalBoundaryShowSizeChanger={pageIndex + 1} */}
      {/*   //     showGoTo */}
      {/*   //     totalPages={pageCount} */}
      {/*   //     onChange={(value) => { */}
      {/*   //       gotoPage(value - 1) */}
      {/*   //     }} */}
      {/*   //     hasNextPage={canNextPage} */}
      {/*   //   /> */}
      {/*   // </div> */}
      {/* )} */}
    </>
  )
})

TableHistory.displayName = 'Table'

export default TableHistory
