import React, {
  memo, useCallback, useEffect, useLayoutEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Select, Switch, Typography, Upload, Tooltip
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { TOption } from '../../../common/types/dictionaries'
import $api from '../../../common/api/http'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import './ModalEditRequest.scss'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import {
  createRequest, getResponsibleList, updateRequest
} from '../actions'
import { selectRequest } from '../../../compositions/Request/selectors'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import {
  extractValue, fetchRequestList, fetchStoreList, fetchVacancyList
} from '../utils'
import { getCurators, getDictionaries } from '../../../compositions/ManagementRequests/actions'
import { selectCurators, selectSelectedRequest } from '../../../compositions/ManagementRequests/selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import DebounceSelectFormControlCity from '../../../common/components/formcontrol/DebounceSelectFormControlCity'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { fetchGetResponsible, TRequest } from '../../../common/api/request'
import { selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import { selectResponsibleList } from '../selectors'

type TFormValues = {
  cost: number
  description: string
  schedule_id_list: string | number[]
  vacancy_id: number
  customer: TOption[]
  store_id: {
    value: number
    label: string,
    title: string
  }
  responsible: number | null
  is_student:boolean
  is_patent:boolean
  is_internship: boolean
  address_internship: string
  responsible_internship: string
  responsible_number_internship: string
  curator: string[]
  reward_average_day: number
  reward_average_month: number
  candidate_age: number
  characteristic_list:string[]
  education_id: number
  priority_id: number
  mileage_average_day: string
  reward_average_order: string
  income_average_day: string
  flag: boolean
  nominate_me:boolean
}

export type TProps = {
  requestId?: number | null | string
  request?: TRequest
  editMode?: boolean
  isRequestTable?: boolean
  sheduleOptions: TOption[]
  educationOptions: TOption[]
  characteristicOptions: TOption[]
  priorityOptions: TOption[]
} & ModalProps

const mainCssClass = 'edit-request'

function ModalEditRequest({
  open, className, onCancel, requestId, isRequestTable, editMode, sheduleOptions, educationOptions, characteristicOptions, priorityOptions
}: TProps) {
  const dispatch = useDispatch()
  const requestFromRequest = useSelector(selectRequest)
  const userInfo = useSelector(selectUserInfo)
  const requestFromTable = useSelector(selectSelectedRequest)
  const curators = useSelector(selectCurators)
  const responsibleOptions = useSelector(selectResponsibleList)

  const request = useMemo(() => {
    if (requestFromTable) {
      return requestFromTable
    }
    return requestFromRequest
  }, [requestFromRequest, requestFromTable])

  const defaultValues = useMemo(
    () => (editMode && request
      ? {
        curator: request?.curator_list ? request?.curator_list?.map((el) => el.id) : null,
        description: request.description,
        cost: request.cost,
        schedule_id_list: request.schedule_list?.map((el) => el.id),
        customer: request.customer_list?.map((item) => ({ value: item.id, label: `${item?.name || ''} ${item?.surname || ''} ${item?.email}` })) || [],
        priority_id: request.priority_id,
        store_id: request?.store_id ? {
          value: request?.store_id,
          label: request?.store?.name || 'Фраксис VV',
          title: request?.store.city
        } : null,
        vacancy_id: {
          value: request.vacancy_id,
          label: request.vacancy_label
        },
        responsible: {
          value: request.responsible,
          label: `${request?.responsible_list?.name} ${request.responsible_list.surname} ${request.responsible_list.email}`
        },
        address_internship: request.address_internship,
        is_internship: !!request.is_internship,
        is_student: !!request.is_student,
        is_patent: !!request.is_patent,
        responsible_internship: request.responsible_internship || '',
        responsible_number_internship: request.responsible_number_internship || '',
        reward_average_day: request.reward_average_day || '',
        reward_average_month: request.reward_average_month || '',
        candidate_age: request.candidate_age || '',
        characteristic_list: request.characteristic_list || [],
        education_id: request.education_id,
        mileage_average_day: request.mileage_average_day || '',
        reward_average_order: request.reward_average_order || '',
        income_average_day: request.income_average_day || '',
        flag: !request?.store_id,
        nominate_me: !!request.customer_list.filter((el) => Number(el.id) === Number(userInfo?.id)).length,
        responsible_id_list: request?.responsible_id_list ? request.responsible_id_list.map((el) => el.id) : []
      }
      : {}),
    [request, editMode]
  )

  const {
    control, handleSubmit, reset, watch, getValues, setValue, formState: { errors }
  } = useForm<TFormValues>({ defaultValues })

  const is_student = watch('is_student')
  const is_internship = watch('is_internship')

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(e)
    }
  }

  const handleChangeStore = (e) => {
    setValue('address_internship', null)
  }

  const onSubmit = useCallback(() => {
    const values = getValues()
    const updatedValues = {
      ...values,
      vacancy_id: extractValue(values.vacancy_id),
      customer: values?.customer?.map((item) => item.value) || [],
      store_id: extractValue(values.store_id),
      responsible: extractValue(values.responsible),
      cost: Number(values.cost)
    }
    if (requestId) {
      dispatch(
        updateRequest({
          data: {
            id: requestId,
            ...updatedValues,
            created_at: request.created_at
          },
          isRequestTable,
          isCurator: userInfo?.department === 175
        })
      )
    } else {
      dispatch(
        createRequest({
          data: {
            status: 91,
            ...updatedValues
          },
          isCurator: userInfo?.department === 175
        })
      )
    }
  }, [requestId, isRequestTable])

  const store = watch('store_id')
  const flag = watch('flag')
  const customer = watch('customer')
  const nominate_me = watch('nominate_me')
  const reward = watch('reward_average_day')

  const fetchAdressList = useCallback(async (name: string) => {
    const data = await $api.get(`/api/v1/dadata/suggest-all?search=${name}&city=${watch('store_id')?.title || userInfo?.city?.name || 'Москва'}`)

    return data.data.DATA.map((obj:any) => ({
      label: `${obj.value}`,
      value: obj.value
    }))
  }, [store])

  const handleChangeFlag = (e) => {
    if (e) { setValue('store_id', null) }
  }
  const handleChangeNominateMe = (e) => {
    const customer = watch('customer')
    if (e) {
      if (userInfo && userInfo?.role?.role_id === 'client' && userInfo?.hasOwnProperty('customer_responsible') && userInfo.customer_responsible.length) {
        setValue('responsible', { label: userInfo?.customer_responsible[0].label, value: userInfo?.customer_responsible[0].id })
      }
      if (customer && customer.length < 3) {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }, ...customer])
      } else if (customer && customer.length === 3) {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }, ...customer.slice(0, 2)])
      } else {
        setValue('customer', [{ label: `${userInfo?.name || ''} ${userInfo?.surname || ''}`, value: `${userInfo?.id}` }])
      }
    } if (!e) {
      setValue('customer', customer.filter((el) => Number(el.value) !== Number(userInfo.id)))
      if (userInfo && userInfo?.role?.role_id === 'client' && userInfo?.hasOwnProperty('customer_responsible') && userInfo.customer_responsible.length) {
        setValue('responsible', null)
      }
    }
  }

  useEffect(() => {
    if (!editMode) {
      dispatch(
        getDictionaries([
          'schedule', 'requestCandidateEducation', 'requestCandidateCharacteristic', 'priority'
        ])
      )
    }
  }, [editMode])

  useEffect(() => {
    dispatch(getResponsibleList())
    dispatch(getCurators())
  }, [])

  useEffect(() => {
    if (nominate_me && customer) {
      const checkCustomer = customer.filter((el) => Number(el.value) === (userInfo?.id))
      if (!checkCustomer.length) {
        setValue('nominate_me', false)
      }
    }
  }, [customer])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleModalClose}
      title={editMode ? 'Редактировать заявку' : 'Новая заявка'}
      className={cn(className, mainCssClass)}
      width="960px"
      typeButtonAccept="primary"
      okText="Применить"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <DebounceSelectFormControl
          rules={{ required: true }}
          name="vacancy_id"
          control={control}
          fetchOptions={fetchVacancyList}
          label="Вакансия"
          required
          placeholder="Поиск по наименованию..."
        />

        <div className={`${mainCssClass}_items-store`}>

          <DebounceSelectFormControlCity
            name="store_id"
            control={control}
            fetchOptions={fetchStoreList}
            rules={{ required: !flag }}
            label="Объект"
            placeholder="Поиск по наименованию..."
            required
            onChange={(e) => handleChangeStore(e)}
            labelInValue
            disabled={flag}
            key={`${flag}1`}
          />
          <div className={`${mainCssClass}_switch`}>
            <SwitchFormControl control={control} name="flag" onChange={handleChangeFlag} />
            <Typography.Text>Потребность под кандидата</Typography.Text>
          </div>
        </div>

        {editMode && (
          <SelectFormControl
            name="priority_id"
            control={control}
            placeholder="Приоритет"
            options={priorityOptions}
            required
            label="Приоритет"
            rules={{ required: true }}
          />
        ) }
        <div className={`${mainCssClass}_switch`}>
          <SwitchFormControl control={control} onChange={handleChangeNominateMe} name="nominate_me" />
          <Typography.Text>Назначить меня заказчиком</Typography.Text>
        </div>
        <div className={`${mainCssClass}_items`}>
          <DebounceSelectFormControlMult
            control={control}
            name="customer"
            placeholder="Поиск по имени..."
            fetchOptions={fetchRequestList}
            label={(userInfo?.department === 175 || userInfo?.department === 177) ? 'Помощник розницы' : 'Заказчик'}
            maxSelections={3}
            required
            rules={{ required: true }}
          />

          <InputFormControl
            name="cost"
            control={control}
            label="Количество позиций"
            required
            rules={{
              required: true,
              pattern: /^[0-9]*$/
            }}

          />

          <DebounceSelectFormControl
            placeholder="Поиск по имени..."
            fetchOptions={fetchRequestList}
            style={{ width: '100%' }}
            label={(userInfo?.department === 175 || userInfo?.department === 177) ? 'Заказчик - Ответственный' : 'Ответственный'}
            rules={{ required: true }}
            required
            name="responsible"
            control={control}
          />
        </div>

        <SelectFormControlMult
          mode="multiple"
          control={control}
          name="schedule_id_list"
          rules={{ required: true }}
          label="Графики"
          required
          options={sheduleOptions}
        />
        <SelectFormControlMult mode="multiple" control={control} name="characteristic_list" options={characteristicOptions} label="Особенности кандидата" />
        <div className={`${mainCssClass}_items`}>
          {errors.reward_average_day ? (
            <Tooltip placement="bottom" title="Введите не менее 4 цифр для заполнения">
              <InputFormControl
                name="reward_average_day"
                control={control}
                label="Средняя зп в день на точке"
                required
                rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
              />
            </Tooltip>
          ) : (
            <InputFormControl
              name="reward_average_day"
              control={control}
              label="Средняя зп в день на точке"
              required
              rules={{ required: true, pattern: /^[^0].{3,}$/, minLength: 4 }}
            />
          )}

          <InputFormControl
            name="reward_average_month"
            control={control}
            label="Средняя зп в месяц на точке"
            rules={{
              pattern: /^[0-9\s,-]*$/
            }}
          />
          <InputFormControl
            name="candidate_age"
            control={control}
            label="Желаемый возраст кандидата"
            rules={{ validate: (value) => (value ? value.length < 20 : true) }}
          />
        </div>
        {userInfo?.department === 175
            && (
              <div className={`${mainCssClass}_items`}>
                <InputFormControl
                  name="mileage_average_day"
                  control={control}
                  label="Средний пробег в день"
                  rules={{
                    pattern: /^[0-9]*$/
                  }}
                />
                <InputFormControl
                  name="reward_average_order"
                  control={control}
                  label="Средняя оплата за заказ "
                  rules={{
                    pattern: /^[0-9]*$/
                  }}
                />
                {userInfo?.department !== 175 && userInfo?.department !== 177
                  && (
                    <InputFormControl
                      name="income_average_day"
                      control={control}
                      label="Средний доход в день"
                      rules={{
                        pattern: /^[0-9]*$/
                      }}
                    />
                  )}
              </div>
            )}

        {userInfo?.department !== 175
             && (
               <>
                 <div className={`${mainCssClass}_switch`}>
                   <SwitchFormControl control={control} name="is_student" />
                   <Typography.Text>Подходят ли студенты</Typography.Text>
                 </div>
                 <SelectFormControl control={control} name="education_id" label="Форма обучения" options={educationOptions} disabled={!is_student} />
                 <div className={`${mainCssClass}_switch`}>
                   <SwitchFormControl control={control} name="is_patent" />
                   <Typography.Text>Можно с патентом</Typography.Text>
                 </div>
                 <div className={`${mainCssClass}_switch`}>
                   <SwitchFormControl control={control} name="is_internship" />
                   <Typography.Text>Нужна ознакомительная стажировка</Typography.Text>
                 </div>
                 <div className={`${mainCssClass}_items`}>
                   <DebounceSelectFormControl
                     name="address_internship"
                     control={control}
                     fetchOptions={fetchAdressList}
                     label="Адрес"
                     notFoundContent="Начните вводить адрес"
                     disabled={!is_internship}
                     key={`${watch('store_id')?.title}0`}

                   />
                   <InputFormControl
                     name="responsible_internship"
                     control={control}
                     label="Ответственный"
                     disabled={!is_internship}
                   />
                   <SearchSelectFormControlSource
                     name="responsible_id_list"
                     control={control}
                     options={responsibleOptions}
                     mode="multiple"
                     label="Вайтсторы и Дарксторы"
                     showSearch
                   />

                 </div>

               </>
             )}

        {userInfo?.department === 175 && (
          <SearchSelectFormControlSource
            mode="multiple"
            control={control}
            showSearch
            name="curator"
            label="Куратор"
            options={curators}
          />
        )}

        <TextAreaFormControl
          name="description"
          control={control}
          label="Описание"
          required
          rules={{ required: true }}
        />

      </div>
    </CommonModal>
  )
}

export default ModalEditRequest
