import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ analytics }: TRootState) => analytics
export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectSourseOfCandidates = createSelector(selectState, ({ sourseOfCandidates }) => sourseOfCandidates)
export const selectVertex = createSelector(selectState, ({ vertexInfo }) => vertexInfo)

export const selectProductivity = createSelector(selectState, ({ productivityInfo }) => productivityInfo)

export const selectScenario = createSelector(selectState, ({ scenario }) => scenario)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectPlanFactVacancy = createSelector(selectState, ({ planFactVacancy }) => planFactVacancy)

export const selectPlanFact = createSelector(selectState, ({ planFactInfo }) => planFactInfo)

export const selectRejectionInfo = createSelector(selectState, ({ rejectInfo }) => rejectInfo)

export const selectVertexMethod = createSelector(selectState, ({ vertexMethod }) => vertexMethod)