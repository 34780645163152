import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import { isNumber } from 'lodash'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { TRequest } from '../../../common/api/request'
import { navigateTo } from '../../../common/actions/navigate'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import TableMobil from '../../../common/components/table/TableMobil'
import RequestNameTableMobil from './RequestNameTableMobil'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
    requests: TRequest[] | []
} & TClassName
type TRowData = {
    name: JSX.Element
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'name',
      Header: 'Заявка'
    }
  ],
  []
)

const useRowsData = (requests: TRequest[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    dispatch(navigateTo(`/application/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (requests?.length
      ? requests.map<TRowData>(
        ({
          name,
          id,
          cost,
          priority_id,
          cost_process
        }) => ({
          name: (<RequestNameTableMobil
            name={name || '-'}
            priority={priority_id}
            id={id}
            cost={isNumber(cost) ? String(cost) : '-'}
            cost_process={isNumber(cost_process) ? String(cost_process) : '-'}
          />),

          id
        })
      )
      : []),
    [requests]
  )
  return { rows, handleRowClick }
}
const RequestsTable: FC<TProps> = ({ className, requests }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(requests)

  return (
    <TableMobil
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
      handleRowClick={handleRowClick}
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(RequestsTable))
