/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import React, {
  FC, useEffect, useMemo, useState, memo, useCallback, useRef, useLayoutEffect
} from 'react'
import { useParams } from 'react-router'
import {
  Button, Tooltip, Typography,
  message, Upload
} from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { isInteger } from 'lodash'
import { resetAllRequest } from '../../ManagementRequests/actions'
import { resetAllVacancy } from '../../ManagementVacancies/actions'
import {
  getCandidates, getDictionaries, reset, setDefDataCandidate, setOpenModalChangeStatus
} from '../../ManagamentCandidates/actions'
import { resetAllInterview } from '../../ManagementInterviews/actions'
import CandidatesTable from './CandidatesTable'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'

import {
  setDefStringFunnel
} from '../../Funnel/actions'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import { optionsPagination } from '../../../common/components/options'
import { openFilters, setDefFilters, setFilters } from '../../../features/filters/actions'
import { selectCountFilters, selectDefFilters, selectFilterString } from '../../../features/filters/selectors'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import ControlButton from '../../../common/components/Buttons/Button'
import FiltersIcon from '../../../common/svg/FiltersIcon'

import './FluidityList.scss'
import {
  selectCandidates,
  selectDefDataCandidate,
  selectHeaders, selectIsOpenChangeStatus,
  selectLoading

} from '../../ManagamentCandidates/selectors'
import ChangeStatusModal from '../../../features/candidateModals/components/ChangeStatusModal'
import AutodialModal from '../../../features/FluidityModals/components/AutodialModal'
import { selectIsOpenAutoDial } from '../selectors'
import { setOpenModalAutoDial } from '../actions'
import Excel from '../../../common/svg/Excel'
import PhoneSvg from '../../../common/svg/PhoneSvg'
import NewAutodialModal from '../../../features/FluidityModals/components/NewAutodialModal'

const mainCssClass = getCssClassName('candidate-list')

const FluidityList: FC<TClassName> = ({ className }) => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const defDataCandidate = useSelector(selectDefDataCandidate)
  const filtersCount = useSelector(selectCountFilters)
  const [page, setPage] = useState<number>(defDataCandidate?.page || 1)
  const candidatesList = useSelector(selectCandidates)
  const loading = useSelector(selectLoading)
  const filtersString = useSelector(selectFilterString)
  const defFilters = useSelector(selectDefFilters)
  const headers = useSelector(selectHeaders)
  const prevDefDataCandidateRef = useRef(defDataCandidate)
  const modalAutoDialOpen = useSelector(selectIsOpenAutoDial)
  const { control, watch, setValue } = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues: {
      perPagesCount: defDataCandidate?.perPage || 10,
      name: defDataCandidate?.search || ''
    }
  })
  const smoke = ''
  type TFormValues = {
    perPagesCount: number
    name: string
  }
  const perPagesCount = watch('perPagesCount')

  const maxPage = useMemo(() => {
    if (headers?.total && headers) {
      const counted = headers.totalCount / perPagesCount
      if (isInteger(counted)) {
        return counted
      }
      return Math.floor(counted) + 1
    }
  }, [headers, perPagesCount])

  const handleAutoDialModalCancel = () => {
    dispatch(setOpenModalAutoDial(false))
  }
  const handleOpenAutoDial = useCallback(() => {
    dispatch(setOpenModalAutoDial(true))
  }, [])
  const handleNextPage = useCallback(() => {
    if (page !== maxPage) {
      setPage((prev) => prev + 1)
    }
  }, [page, maxPage])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleFiltersOpen = useCallback(() => {
    dispatch(openFilters(true))
  }, [])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  useEffect(() => {
    dispatch(getDictionaries(['archiveReason', 'candidateStatus', 'rejectionReason', 'stageStatus']))
    if ((pageId === defFilters?.type) && defFilters?.defFilterString) { dispatch(setFilters(defFilters?.defFilterString)) } else {
      dispatch(setDefFilters(null))
      dispatch(setFilters(''))
    }
    dispatch(resetAllInterview())
    dispatch(resetAllRequest())
    dispatch(resetAllVacancy())
    dispatch(setDefStringFunnel(''))
  }, [])

  useEffect(() => {
    if (filtersString && ((!pageId && defFilters?.type === 'candidates') || (pageId === defFilters?.type))) {
      dispatch(setDefDataCandidate({
        page,
        perPage: perPagesCount,
        filters: defDataCandidate?.search
          ? `${filtersString}&search=${defDataCandidate?.search}` : filtersString,
        search: defDataCandidate?.search || ''
      }))
    } else if (!filtersString && defDataCandidate?.search) {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: `&search=${defDataCandidate?.search}`, search: defDataCandidate?.search || ''
      }))
    } else {
      dispatch(setDefDataCandidate({
        page, perPage: perPagesCount, filters: '', search: defDataCandidate?.search || ''
      }))
    }
  }, [page, perPagesCount, filtersString])

  useEffect(() => {
    if (defDataCandidate !== prevDefDataCandidateRef.current && defDataCandidate) {
      prevDefDataCandidateRef.current = defDataCandidate // Обновите предыдущее значение
      dispatch(getCandidates({
        page: defDataCandidate?.page,
        perPage: defDataCandidate?.perPage,
        filters: defDataCandidate?.filters
      }))
    }
  }, [defDataCandidate])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={cn(className, mainCssClass)}>
      <div className={`${mainCssClass}__heading`}>
        <div className={`${mainCssClass}__title`}>
          <Typography.Title level={2}>Текучесть</Typography.Title>
          {loading && <Preloader size="md" />}
        </div>
        {!loading && (
          <div className={`${mainCssClass}__container-buttons-phone`}>
            <div className={`${mainCssClass}__mobileButton`}>
              <ControlButton icon="left" size="large" typeButton="secondary" className="button" onClick={handleFiltersOpen}>
                <FiltersIcon />
                Фильтры
                {filtersCount >= 1 ? (
                  <span className={`${mainCssClass}_count-in-brackets`}>
                    (
                    {filtersCount}
                    )
                  </span>
                ) : (
                  ''
                )}
              </ControlButton>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <>
          <div className={`${mainCssClass}__container-info`}>
            <div className={`${mainCssClass}__container-title`}>
              <Typography.Title level={5} className={`${mainCssClass}__title`}>
                Результат:&nbsp;
                {headers?.totalCount || 'неизестно'}
              </Typography.Title>
              <Button type="ghost" icon={<PhoneSvg />} className={cn(`${mainCssClass}__excel`)} onClick={handleOpenAutoDial}>
                {' '}
                Автообзвон
                {' '}
              </Button>
            </div>
            {!!headers?.totalCount && (
              <div className={`${mainCssClass}__container-pagination`}>
                <SelectFormControl
                  className={`${mainCssClass}__pagination-count`}
                  name="perPagesCount"
                  options={optionsPagination}
                  onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
                  control={control}
                />

                <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                  <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                  <Typography.Text className={`${mainCssClass}__pagination-font`}>
                    {stringCountsPagination || 'неизвестно'}
                  </Typography.Text>
                  <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === maxPage ? '#BFC5C0' : '#404542'} />
                </div>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}__scroll-div`}>
            <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
              <CandidatesTable candidates={candidatesList} className={`${mainCssClass}__table`} />
            </NewScrollContainer>
          </div>
          <AutodialModal
            open={modalAutoDialOpen}
            isCandidateTable
            className={`${mainCssClass}__modals`}
            onCancel={handleAutoDialModalCancel}
          />
        </>
      )}
    </div>
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(FluidityList))
