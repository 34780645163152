import { put, call, takeLatest, spawn } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { getDictionaries, getFilter, getFilters, getRequest, setDictionaries, setFilters } from './actions'
import { TFilter } from './types'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { objectToQueryString } from './utils'

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'компонент фильтра')
}

function* getFiltersSaga({ payload }: PayloadAction<TFilter>) {
  const res: string = yield objectToQueryString(payload.filter)
  yield put(setFilters(res))
}

function* filtersSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getFilters, getFiltersSaga)
}

export default filtersSaga
