import React from 'react'
import { Bar } from 'react-chartjs-2'

export type TProps = {
    dataValues: any []
    className: string
    labels: any[]
}

function SourceBar({
  labels, dataValues, className
} : TProps) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'none' as const
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        type: 'category',
        offset: true,
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 30,
          autoSkip: true,
          maxTicksLimit: 200// Устанавливай значение, которое считаешь оптимальным
        }
      },
      y: {
        beginAtZero: true
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  return (
    <div className={className}>
      <Bar options={options} data={data} height={327} />
    </div>
  )
}

export default SourceBar
