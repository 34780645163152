import React, { FC } from 'react'

import { TEntity } from '../types/entity'
import { createGenericContext } from '../utils/utilsContext'

export type TEntityContext = TEntity

const [useContext, ContextProvider] = createGenericContext<TEntityContext>()

export const EntityContextProvider: FC<TEntityContext> = ({ children, ...properties }) => (
  <ContextProvider value={{
    ...properties
  }}
  >
    {children}
  </ContextProvider>
)

export const useEntityContext = useContext
