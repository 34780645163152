import { PayloadAction } from '@reduxjs/toolkit'
import {put, race, take, takeLatest} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  getDictionaries,
  getProductivityRecruiter,
  getProductivityResearcher,
  setDictionaries,
  setHeaders,
  setLoading,
  setProductivityRecruiter,
  setProductivityResearcher
} from './actions'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { setLoadingProgress } from '../InnerRouter/actions'
import { TPaginationRequest } from '../../common/types/pagination'
import { fetchGetProductivityRecruiter, fetchGetProductivityResearcher } from '../../common/api/productivity'


const moduleName = 'Продуктивность'

function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'продуктивность')
}

export default function* getProductivityRecruiterSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: fetchGetProductivityRecruiter(payload),
      cancel: take(getProductivityRecruiter.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить продуктивность',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setProductivityRecruiter(response.data.DATA.productivity_list))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

export function* getProductivityResearcherSaga({ payload }: PayloadAction<TPaginationRequest>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: fetchGetProductivityResearcher(payload),
      cancel: take(getProductivityResearcher.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить продуктивность',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setProductivityResearcher(response.data.DATA.productivity_list))
      yield put(setHeaders({
        current: response.data.Headers['x-pagination-current-page'][0],
        total: response.data.Headers['x-pagination-page-count'][0],
        totalCount: response.data.Headers['x-pagination-total-count'][0]
      }))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

export function* productivitySaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getProductivityRecruiter, getProductivityRecruiterSaga)
  yield takeLatest(getProductivityResearcher, getProductivityResearcherSaga)

}
