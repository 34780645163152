import { createObjectsArray } from '../../features/filters/utils'
import { TProductivityFilterRecruiter } from './components/ProductivityRecruiterFilter'
import { TProductivityFilter } from './components/ProductivityResearcherFilter'

export const filterObject: any = {
  statusCandidate: false,
  priority: false,
  requestContent: false,
  vacancyCandidate: false,
  interviewResult: false,
  characteristic: false,
  time: false,
  recruiter: false,
  researcher: false,
  client: false,
  dateInterview: false,
  statusVacancy: false,
  group: false,
  dateRegistration: false
}

export const timeOptions = createObjectsArray()

export function objectToQueryString(obj: TProductivityFilter | TProductivityFilterRecruiter): string {
  let str = ''
  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        const newStrArr = strArrStr.replace(/undefined/g, '')
        str += `&filter[${key}]=${newStrArr}`
      }
      if (typeof obj[key][0] === 'number') {
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&filter[${key}]=${strArrStr}`
      }
      if (key === 'date_interview') {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&filter[${key}]=${strArrStr}`
      }
      if (key === 'date_registration') {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&filter[${key}]=${strArrStr}`
      }
      if (key === 'time_interview') {
        const strArrStr = obj[key].map((el) => el).join(',')
        str += `&filter[${key}]=${strArrStr}`
      }
    }
  }
  return str
}
