import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import './SimpleModalCandidate.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TOption } from '../../../common/types/dictionaries'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { TDATA } from '../types'
import { updateStage } from '../actions'

type ModalPropsWithOk = ModalProps & {
    stageId: number
    stageLabel: string
    candidate: TDATA | null
}

const mainCssClass = 'simpleModalCandidate'

function ModalStageOnProgress({
  open, stageLabel, stageId, className, onCancel, candidate
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const onSubmit = (e : React.MouseEvent<HTMLButtonElement>) => {
    dispatch(updateStage({ candidate_id: candidate?.id, stage: stageId }))
    if (onCancel) {
      onCancel(e)
    }
  }

  return (
    <CommonModal
      centered
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      title="Сменить этап?"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div>
        Вы собираетесь сменить этап кандидата
        {' '}
        {`${candidate?.name || ''} ${candidate?.last_name || ''}`}
        {' '}
        на
        {' '}
        {`"${stageLabel}"`}
      </div>
    </CommonModal>
  )
}

export default ModalStageOnProgress
