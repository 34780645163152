import { TReoirtFilter } from './components/ReportFilter'

export const defObject: any = {
  vacancyModal: false,
  leadgen_source_id: false,
  researcherModal: false,
  recruiterModal: false,
  addTypeModal: false,
  dateBeginModal: false,
  requestModal: false,
  departmentModal: false,
  createdModal: false,
  groupModal: false,
  cityVacancyModal: false,
  cityСandidateModal: false,
  citizenshipModal: false,
  responsibleModal: false,
  customerModal: false,
  scheduleModal: false,
  idModal: false,
  storeModal: false,
  stageModal: false,
  updatedModal: false,
  rejReas: false
}

export const defInputObject: any = {
  sourceInput: '',
  groupInput: '',
  citizenshipInput: '',
  scheduleInput: '',
  stageInput: '',
  regInput: ''
}

export const departmentOptions = [{ label: 'Без отдела', value: 299 }, { label: 'Офисный подбор', value: 174 }, { label: 'Курьеры', value: 175 }, { label: 'Розничный подбор', value: 176 }, { label: 'Курьеры (Розница)', value: 177 }]

export function objectToQueryString(obj: TReoirtFilter): string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object' && key !=='city_vacancy' && key !== 'city_candidate' && key !== 'customer' && key !=='id') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][in][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }
      if (typeof obj[key][0] === 'number' || typeof obj[key][0] === 'boolean') {
        if (key === 'add_type' || key === 'group' ||  key === 'citizenship') {
          const strArrStr = obj[key].join(',')
          str += `&union[${key}]=${strArrStr}`
        }
        else if (key === 'schedule') {
          const strArrStr = obj[key].join(',')
          str += `&graphic=${strArrStr}`
        }
        else {
          const strArrStr = obj[key].reduce<string>((acc, el) => {
            acc += `&filter[${key}][in][]=${el}`
            return acc
          }, '')
          str += strArrStr
        }
      }
      if ((key === 'date_internship' || key === 'added_at' || key === 'created_at' || key === 'updated_at')) {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[${key}]=${strArrStr}`
      }
      if (key === 'city_vacancy') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[city_vacancy]=${strArrStr}`
      }
      if (key === 'city_candidate') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[city_candidate]=${strArrStr}`
      }
      if (key === 'id') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&union[request_id]=${strArrStr}`
      }
      if (key === 'customer') {
        const strArrStr = obj[key].map((el) => el.value).join(',')
        str += `&customer=${strArrStr}`
      }
    } else if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
      str += `&filter[${key}][in][]=${obj[key]}`
    }
  }

  if (str !== '') {
    str = `?${str.slice(1)}` // Заменяем первый символ на "?"
  }

  return str
}
