/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/alt-text */
import React, {
  FC, memo, useCallback, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { TClassName } from '../../../common/types/props'
import UserAvatar from '../../../common/svg/UserAvatar'
import { setPreviousPage } from '../../../compositions/InnerRouter/actions'
import { navigateTo } from '../../../common/actions/navigate'
import Button from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './ChatCard.scss'
import AvatarSizeSmall from '../../../common/svg/AvatarSizeSmall'
import Ellipse from '../../../common/svg/Ellipse'
import { selectDialogs } from '../selectors'
import { setDialogs } from '../actions'

const mainCssClass = getCssClassName('chat-card')

const ChatCard: FC<TClassName> = ({ className, item, isFullView }) => {
  const dispatch = useDispatch()
  const dialogs = useSelector(selectDialogs)

  const handleNavigate = useCallback((e) => {
    if (item?.candidate_id) {
      if (e.ctrlKey || e.metaKey || e.which === 2) {
        // Если Ctrl была нажата, открываем ссылку в новой вкладке
        window.open(`/candidate/${item.candidate_id}`, '_blank')
      } else {
        const newDialogs = dialogs.map((dialog) => (item.candidate_id === dialog.candidate_id ? { ...dialog, unread_count: 0 } : dialog))
        dispatch(setDialogs(newDialogs))
        dispatch(navigateTo(`/candidate/${item.candidate_id}`))
      }
    }
  }, [item, dialogs])

  return (
    <div className={cn(className, mainCssClass)} onClick={handleNavigate}>
      <div className={`${mainCssClass}__card`}>
        <div className={`${mainCssClass}__avatar`}>

          {item?.avatar ? (
            <div className={`${mainCssClass}__avatar`}>
              <img className={`${mainCssClass}__avatar`} src={item.avatar} />
            </div>
          ) : (
            <div className={`${mainCssClass}_header_avatar`}>
              <AvatarSizeSmall />
            </div>
          )}
          {!isFullView && Number(item.unread_count) > 0 && (
            <div className={`${mainCssClass}__mini`}>
              <Ellipse />
            </div>
          )}
        </div>
        {isFullView && (
          <>
            <div className={`${mainCssClass}__text`}>
              <div className={`${mainCssClass}__name`}>
                {item.fio}
              </div>
              <div className={`${mainCssClass}__message`}>
                {item.messagePreview}
              </div>
            </div>
            {isFullView && Number(item.unread_count) > 0 && (
              <div className={`${mainCssClass}__circle`}>
                <div className={`${mainCssClass}__number`}>
                  {item.unread_count}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ChatCard
