import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'required'

function Required({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '4' }
      )}
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.988 4.792L3.08 3.294L4.186 4.792L4.942 4.246L3.836 2.748L5.614 2.16L5.32 1.278L3.542 1.852V0.0599995H2.604V1.852L0.812 1.264L0.546 2.16L2.31 2.748L1.232 4.246L1.988 4.792Z"
        fill="#D7144B"
      />
    </svg>
  )
}
export default Required
