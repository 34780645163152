import React from 'react'
import Icon from '../svgIcons/CloseOutline.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export function CloseOutline(props: IconProps) {
  return (
    <IconWrapper {...props} name="CloseOutline">
      <Icon />
    </IconWrapper>
  )
}
