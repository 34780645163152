import { createAction } from '@reduxjs/toolkit'
import { TOption } from '../../common/types/dictionaries'

export const saveAutoCalls = createAction<TAutoCall>('autocalls/saveAutoCalls')
export type TAutoCall = {
  name: string,
  busy_recall: number,
  no_answer_recall: number,
  not_available_recall: number,
  busy_timeout: number,
  no_answer_timeout: number,
  not_available_timeout: number,
  waiting_answer_time: number
  id: number
}

export const getAutoCalls = createAction('autocalls/getAutoCalls')
export const setAutoCalls = createAction<TAutoCall[]>('autocalls/setAutoCalls')
export const setLoading = createAction<boolean>('autocalls/setLoading')
export const setSelectedAutoCall = createAction<TAutoCall>('autocalls/setSelectedAutoCall')
export const startAutoCall = createAction<number>('autocalls/startAutoCall')
export const stopAutoCall = createAction<number>('autocalls/stopAutoCall')
export const getUsersWhoCalls = createAction<string>('autocalls/getUsersWhoCalls')
export const getGroupUserWhoCalls = createAction('autocalls/getGroupUsersCalls')
export const setUsersWhoCalls = createAction<TOption[]>('autocalls/setUsersWhoCalls')
export const setGroupUsersWhoCalls = createAction<TOption[]>('autocalls/setGroupUsersWhoCalls')
