import React from 'react'
import { Bar } from 'react-chartjs-2'

export type TProps = {
    dataValues: any []
    className: string
    labels: any[]
    height: number
}

function RejectionBar({labels, dataValues, className, height} : TProps) {
  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'none' as const
      },
      title: {
        display: false
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  return (
    <div className={className}>
      <Bar options={options} data={data} height={height * 25} />
    </div>
  )
}

export default RejectionBar
