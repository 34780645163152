import React from 'react'
import Icon from '../svgIcons/Clock.svg'
import { IconProps, IconWrapper } from '../IconWrapper/IconWrapper'

export default function Clock(props: IconProps) {
  return (
    <IconWrapper {...props} name="Clock">
      <Icon />
    </IconWrapper>
  )
}
