import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    onClick?:() => void
}

const mainCssClass = 'arrowDownMD'

function ArrowCaretDownMD({ className, size = '1', onClick }: TSvgProps) {
  return (
    <svg
      onClick={onClick}
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 9.29289C7.68342 8.90237 8.31658 8.90237 8.70711 9.29289L12 12.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L7.29289 10.7071C6.90237 10.3166 6.90237 9.68342 7.29289 9.29289Z"
        fill="#2DBE64"
      />
    </svg>
  )
}
export default ArrowCaretDownMD
