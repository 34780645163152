import { createAction } from '@reduxjs/toolkit'
import { TDATA } from '../candidate/types'
import { TCalendar } from '../../common/api/calendar'
import { TInterview } from '../../common/api/interviews'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'

export type TCalendarRequest = {
  user_id: number
  period: string
  page?: number
}
export const getCalendar = createAction<TCalendarRequest>('calendar/getCalendar')
export const setCalendar = createAction<TCalendar[]>('calendar/setCalendar')
export const setPeriod = createAction<string>('calendar/setPeriod')
export const getTimeSlots = createAction<string | undefined>('calendar/getTimeSlots')
export const setTimeSlots = createAction<string[]>('calendar/setTimeSlots')
export const reset = createAction('calendar/reset')
export const setLoading = createAction<boolean>('calendar/setLoading')
export const setPage = createAction<number>('calendar/setPage')

export const setSelectedInterviewId = createAction<number | null>('calendar/setSelectedInterviewId')
export const getInterview = createAction<number>('calendar/getInterview')

export type TRequestUpdateConfirmInterview = {
  id:number
  confirm:string
  period?: string
}

export const updateInterview = createAction<TRequestUpdateConfirmInterview>('calendar/updateInterview')
export const setInterview = createAction<TInterview | null>('interview/setInterview')
export const getDictionaries = createAction<TDictionariesNames>('calendar/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('calendar/dictionaries/setDictionaries')
export const setOpenMoreInfoModal = createAction<boolean>('calendar/setOpenMoreInfoModal')
