import { useEffect } from 'react'

function dataSourceEventOnKeyUp(e: KeyboardEvent) {
  const html = document.querySelector('html')
  // если нажали на tab, то мы включаем режим клавиатуры
  if (e.keyCode === 9) {
    if (html?.getAttribute('data-source-events') !== 'other') {
      html?.setAttribute('data-source-events', 'other')
    }
  }
}

function dataSourceEventMouseDown() {
  const html = document.querySelector('html')
  // при нажатии мышкой мы включаем режим мышки
  if (html?.getAttribute('data-source-events') === 'other') {
    html?.setAttribute('data-source-events', 'pointer')
  }
}
export function useDataSourceEvents() {
  useEffect(() => {
    document.addEventListener('keyup', dataSourceEventOnKeyUp)
    document.addEventListener('mousedown', dataSourceEventMouseDown)
    return () => {
      document.removeEventListener('keyup', dataSourceEventOnKeyUp)
      document.removeEventListener('mousedown', dataSourceEventMouseDown)
    }
  }, [])
}
