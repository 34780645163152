import React, { memo, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button } from 'antd'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { selectRoleConstructor } from '../selectors'
import { createOrEditRole, createRole, setRoleConstructor, updateRole } from '../actions'

import { prepareForDefaultValues, prepareForSubmit } from '../utils'
import ControlButton from '../../../common/components/Buttons/Button'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { TRoleFormFormValues } from '../types'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import './RoleForm.scss'

const mainCssClass = getCssClassName('role-form')
function RoleForm() {
  const dispatch = useDispatch()
  const editViewOptions = [
    {
      label: 'Редактирование',
      value: 'edit'
    },
    { label: 'Просмотр', value: 'view' }
  ]
  const archiveOptions = [{ label: 'Разрешить архивирование', value: '1' }]
  const roleConstructor = useSelector(selectRoleConstructor)

  const defaultValues = useMemo(() => prepareForDefaultValues(roleConstructor), [roleConstructor])

  const { control, handleSubmit, reset, getValues, watch, resetField, setValue } = useForm<TRoleFormFormValues>({
    defaultValues
  })

  const handleCancel = () => {
    dispatch(createOrEditRole(false))
    dispatch(setRoleConstructor(null))
  }

  const candidate_visible = watch('candidate_block')
  const request_visible = watch('request_block')
  const task_visible = watch('task_block')
  const interview_block = watch('interview_block')
  const vacancy_block = watch('vacancy_block')
  const analytics_block = watch('analytics_block')
  const profile_visible = watch('profile_visible')
  const profile_company_visible = watch('profile_company_visible')
  const users_visible = watch('users_visible')
  const role_visible = watch('role_visible')
  const questionnaire_visible = watch('questionnaire_visible')
  const stages_visible = watch('stages_visible')
  const callback_visible = watch('callback_visible')
  const call = watch('call')
  const dictionaries_visible = watch('dictionaries_visible')
  const integrate_career_websites_visible = watch('integrate_career_websites_visible')
  const templates_visible = watch('templates_visible')
  const card_rating_visible = watch('card_rating_visible')
  const settings_candidates_visible = watch('settings_candidates_visible')
  const generate_tokens_visible = watch('generate_tokens_visible')
  const main_page_block = watch('main_page_block')
  const onSubmit = () => {
    if (roleConstructor?.role_id) {
      dispatch(updateRole(prepareForSubmit(getValues(), roleConstructor.role_id)))
    } else {
      dispatch(createRole(prepareForSubmit(getValues())))
    }
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  return (
    <div className={mainCssClass}>
      <Typography.Title level={3}>{roleConstructor ? 'Редактировать роль' : 'Новая роль'}</Typography.Title>
      <div className={`${mainCssClass}_container`}>
        <NewScrollContainer className={`${mainCssClass}__scroll-container`}>
          <div className={`${mainCssClass}_list`}>
            <InputFormControl
              name="name"
              control={control}
              label="Название Роли"
              isRequired
              required
              rules={{ required: true }}
            />
            <div className={`${mainCssClass}_list_item`}>
              <Typography.Text>Общие разделы</Typography.Text>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Кандидаты</Typography.Title>
                <SwitchFormControl control={control} name="candidate_block" defaultChecked disabled />
              </div>
              <div className={`${mainCssClass}_list_item_controls-block`}>
                <RadioGroupFormControl
                  name="candidate_block_is_edit"
                  control={control}
                  options={editViewOptions}
                  className={`${mainCssClass}__radio-group`}
                  defaultValue="view"
                />
                <CheckBoxFormControl options={archiveOptions} control={control} name="candidate_block_archive" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Заявки</Typography.Title>
                <SwitchFormControl control={control} name="request_block" />
              </div>
              {request_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="request_block_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="request_block_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Интервью</Typography.Title>
                <SwitchFormControl control={control} name="interview_block" />
              </div>
              {interview_block && (
                <>
                  <SelectFormControl
                    control={control}
                    name="interview_is_recruiter"
                    options={[
                      {
                        label: 'Рекрутер',
                        value: 'is_recruiter'
                      },
                      {
                        label: 'Ресечер',
                        value: 'is_researcher'
                      }
                    ]}
                  />
                  <div className={`${mainCssClass}_list_item_controls-block`}>
                    <RadioGroupFormControl
                      name="interview_block_is_edit"
                      control={control}
                      options={editViewOptions}
                      className={`${mainCssClass}__radio-group`}
                    />
                    <CheckBoxFormControl options={archiveOptions} control={control} name="interview_block_archive" />
                  </div>
                </>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Главная страница по умолчанию</Typography.Title>
                <SwitchFormControl control={control} name="main_page_block" />
              </div>
              {main_page_block && (
                <SelectFormControl
                  control={control}
                  name="main_page"
                  options={[
                    {
                      label: 'Кандидаты',
                      value: 'candidates'
                    },
                    {
                      label: 'Стажировка',
                      value: 'internships'
                    }
                  ]}
                />
              )}

              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Вакансии</Typography.Title>
                <SwitchFormControl control={control} name="vacancy_block" />
              </div>
              {vacancy_block && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="vacancy_block_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="vacancy_block_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Аналитика</Typography.Title>
                <SwitchFormControl control={control} name="analytics_block" />
              </div>
              {analytics_block && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="analytics_block_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Лиды</Typography.Title>
                <SwitchFormControl control={control} name="leads" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>План факт работы</Typography.Title>
                <SwitchFormControl control={control} name="user_work_plan" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Продуктивность</Typography.Title>
                <SwitchFormControl control={control} name="productivity" />
              </div>

              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Обзвоны</Typography.Title>
                <SwitchFormControl control={control} name="call" />
              </div>
              {call && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="call_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="call_is_archive" />
                </div>
              )}
            </div>
            {/* <div className={`${mainCssClass}_list_item`}>
              <Typography.Text>
                Создание сущностей
              </Typography.Text>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>
                  Создание кандидатов
                </Typography.Title>
                <SwitchFormControl control={control} name="candidate_create" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>
                  Создание заявок
                </Typography.Title>
                <SwitchFormControl control={control} name="request_create" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>
                  Создание вакансий
                </Typography.Title>
                <SwitchFormControl control={control} name="vacancy_create" />
              </div>
            </div> */}
            <div className={`${mainCssClass}_list_item`}>
              <Typography.Text>Создание сущностей</Typography.Text>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Создание кандидатов</Typography.Title>
                <SwitchFormControl control={control} name="candidate_create" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Создание заявок</Typography.Title>
                <SwitchFormControl control={control} name="request_create" />
              </div>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Создание вакансий</Typography.Title>
                <SwitchFormControl control={control} name="vacancy_create" />
              </div>
            </div>
            <div className={`${mainCssClass}_list_item`}>
              <Typography.Text>Профиль</Typography.Text>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Профиль</Typography.Title>
                <SwitchFormControl control={control} name="profile_visible" />
              </div>
              {profile_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="profile_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
            </div>
            <div className={`${mainCssClass}_list_item`}>
              <Typography.Text>Настройки</Typography.Text>
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Профиль компании</Typography.Title>
                <SwitchFormControl control={control} name="profile_company_visible" />
              </div>
              {profile_company_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="profile_company_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Пользователи</Typography.Title>
                <SwitchFormControl control={control} name="users_visible" />
              </div>
              {users_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="users_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="users_is_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Роли</Typography.Title>
                <SwitchFormControl control={control} name="role_visible" />
              </div>
              {role_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="role_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Анкеты</Typography.Title>
                <SwitchFormControl control={control} name="questionnaire_visible" />
              </div>
              {questionnaire_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="questionnaire_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="questionnaire_block_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Callback</Typography.Title>
                <SwitchFormControl control={control} name="callback_visible" />
              </div>
              {callback_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="callback_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="callback_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Настройка этапов по умолчанию</Typography.Title>
                <SwitchFormControl control={control} name="stages_visible" />
              </div>
              {stages_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="stages_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Настройка справочников</Typography.Title>
                <SwitchFormControl control={control} name="dictionaries_visible" />
              </div>

              {dictionaries_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="dictionaries_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Интеграция с карьерными сайтами</Typography.Title>
                <SwitchFormControl control={control} name="integrate_career_websites_visible" />
              </div>
              {integrate_career_websites_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="integrate_career_websites_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Шаблоны</Typography.Title>
                <SwitchFormControl control={control} name="templates_visible" />
              </div>
              {templates_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="templates_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="template_is_archive" />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Карточка оценки</Typography.Title>
                <SwitchFormControl control={control} name="card_rating_visible" />
              </div>
              {card_rating_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="card_rating_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Настройка списка кандидатов</Typography.Title>
                <SwitchFormControl control={control} name="settings_candidates_visible" />
              </div>
              {settings_candidates_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="settings_candidates_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Генерация токенов</Typography.Title>
                <SwitchFormControl control={control} name="generate_tokens_visible" />
              </div>
              {generate_tokens_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="generate_tokens_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                </div>
              )}
              <div className={`${mainCssClass}_list_item_header-block`}>
                <Typography.Title level={5}>Задачи</Typography.Title>
                <SwitchFormControl control={control} name="task_block" />
              </div>
              {task_visible && (
                <div className={`${mainCssClass}_list_item_controls-block`}>
                  <RadioGroupFormControl
                    name="task_block_is_edit"
                    control={control}
                    options={editViewOptions}
                    className={`${mainCssClass}__radio-group`}
                  />
                  <CheckBoxFormControl options={archiveOptions} control={control} name="task_block_archive" />
                </div>
              )}
            </div>
            <div className={`${mainCssClass}_list_item_header-block`}>
              <Typography.Title level={5}>Панель телефонии</Typography.Title>
              <SwitchFormControl control={control} name="is_mobile_panel_visible" />
            </div>
            <div className={`${mainCssClass}_list_item_header-block`}>
              <Typography.Title level={5}>Показывать только оформленных кандидатов</Typography.Title>
              <SwitchFormControl control={control} name="candidate_only_accepted" />
            </div>
          </div>
          <div className={`${mainCssClass}_actions`}>
            <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
              Отменить
            </ControlButton>
            <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
              Сохранить
            </ControlButton>
          </div>
        </NewScrollContainer>
      </div>
    </div>
  )
}
export default memo(withErrorBoundary(RoleForm))
