import React, {
  FC, memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import './RequestNameTable.scss'
import { useDispatch, useSelector } from 'react-redux'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { navigateTo } from '../../../common/actions/navigate'
import Flag from '../../../common/svg/Flag'
import { selectDictionaryOptionsByName } from '../selectors'

type TProps = {
  name: string
  priority: number
  id: number
}
const mainCssClass = 'request-name-table'
const RequestNameTable: FC<TProps> = ({ name, priority, id }) => {
  const dispatch = useDispatch()
  const [color, setColor] = useState<string>('#404542')
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFF')
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))

  useEffect(() => {
    let arrayPriorityStr = priorityOptions?.find((item) => item.value === priority)?.params

    if (priority) {
      arrayPriorityStr = arrayPriorityStr?.replace(/\\/g, '')
      arrayPriorityStr = arrayPriorityStr
        ?.replace(/^"|"$|\\+/g, '') // Убираем внешние кавычки и экранирование
        .replace(/^\[|]$/g, '{') // Заменяем квадратные скобки на фигурные
        .replace(/""/g, '"') // Исправляем лишние двойные кавычки
        .replace(/color":/g, 'color":') // Убираем лишние кавычки у ключей
        .replace(/backgroundColor":/g, 'backgroundColor":') // Убираем лишние кавычки у ключей
        .replace(/(\{\s*\})|\{\s*\}$/, '') // Удаляем пустые фигурные скобки и лишние фигурные скобки в конце
        .replace(/}$/, '}') // Удаляем лишние фигурные скобки в конце, если они есть
        .replace(/([^}])$/, '$1}') // Закрываем фигурную скобку, если она отсутствует

      // Убираем лишние пробелы в начале и в конце
      arrayPriorityStr = arrayPriorityStr?.trim()

      // Удаляем лишнюю фигурную скобку в конце
      arrayPriorityStr = arrayPriorityStr?.replace(/\{\}\s*$/, '')

      // Проверяем и добавляем закрывающую фигурную скобку, если ее нет
      if (!arrayPriorityStr?.endsWith('}')) {
        arrayPriorityStr += '}'
      }

      // Удаляем кавычки, если они окружают строку
      if (arrayPriorityStr?.startsWith('"') && arrayPriorityStr?.endsWith('"')) {
        arrayPriorityStr = arrayPriorityStr.slice(1, -1)
      }

      // Попробуем распарсить строку в объект
      let arrayPriority = null
      try {
        arrayPriority = JSON.parse(arrayPriorityStr)
      } catch (error) {
        console.error('Ошибка при парсинге JSON:', error)
      }

      const backgroundColor = arrayPriority ? arrayPriority.backgroundColor || '#FFF' : '#FFF'
      const color = arrayPriority ? arrayPriority.color || '#404542' : '#404542'
      setBackgroundColor(backgroundColor)
      setColor(color)
    }
  }, [priority, priorityOptions])

  return (
    <div className={mainCssClass}>
      <Flag fill={color} fillBg={backgroundColor} />
      <Typography.Text>{name}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(RequestNameTable))
