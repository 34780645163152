import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import './InternshipModal.scss'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { getEmployeesCenter, setOpenEmployeesCenter } from '../../../features/employeesModal/actions'
import { getInternshipModal, setInternshipModal, updateInternships } from '../actions'
import { selectSelectedInternship } from '../selectors'
import { formatDateTime } from '../../Tasks/utils'

const mainCSSclass = 'internship-modal'
type TFormValues = {
    candidate_id: number,
    is_came: number,
    mentor: string,
    registration: number,
    comment: string
}
function InternshipModal({
  open, className, date, handleReset, search
}) {
  const selectedInternship = useSelector(selectSelectedInternship)

  const defaultValues = useMemo(
    () => (selectedInternship
      ? {
        is_came: selectedInternship.is_came,
        mentor: selectedInternship.mentor,
        registration: selectedInternship.registration,
        comment: selectedInternship.comment
      }
      : {}),
    [selectedInternship]
  )
  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValues>({ defaultValues })
  const dispatch = useDispatch()

  const onSubmit = () => {
    handleReset()
    dispatch(updateInternships({
      data: { id: selectedInternship.candidate_internship_id, ...getValues() },
      date,
      search
    }))
  }

  const handleOnCancel = () => {
    dispatch(setInternshipModal(false))
  }

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleOnCancel}
      centered
      title="Результат стажировки"
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCSSclass}>
        <SelectFormControl
          name="is_came"
          control={control}
          label="Сотрудник пришел на стажировку"
          required
          rules={{ required: true }}
          placeholder="Выберите вариант"
          options={[{ label: 'Да', value: 1 }, { label: 'Нет', value: 2 }, { label: 'Нет информации', value: 0 }]}
        />
        <InputFormControl control={control} name="mentor" label="Наставник" required rules={{ required: true }} placeholder="Введите ФИО" />

        <SelectFormControl
          name="registration"
          control={control}
          label="Оформление"
          required
          rules={{ required: true }}
          placeholder="Выберите вариант"
          options={[{ label: 'Да', value: 1 }, { label: 'Нет', value: 2 }, { label: 'Нет информации', value: 0 }]}
        />
        <TextAreaFormControl control={control} name="comment" label="Комментарий" placeholder="Напишите комментарий" />
      </div>
    </CommonModal>
  )
}

export default InternshipModal
