import cn from 'classnames'
import React from 'react'
import type { NotificationInstance, NotificationProps } from 'rc-notification/lib/Notification'
import './components/styles.scss'
import { NotificationBody } from './components/NotificationBody'
import {
  NoticeTheme,
  NotificationParams,
  NotificationPlacement,
  NotificationStatus
} from './types'
import { PartialRecord } from '../../../utils/utilities'
import { isDefined } from '../../../utils/utilsContext'
import { CloseOutline } from '../../../svg/SvgFromIconWrapper/CloseOutline'
import alarmSound from './alarm.mp3'

const RCNotificationInstance = () => import('rc-notification')

const DEFAULT_STATUS: NotificationStatus = 'info'
const TOAST_PLACEMENT: NotificationPlacement = 'bottomRight'
const NOTICE_PLACEMENT: NotificationPlacement = 'top'
const DEFAULT_NOTICE_THEME: NoticeTheme = 'light'
const DEFAULT_CLOSABLE = true

const NOTICE_IS_CLOSABLE = false
const TOAST_IS_CLOSABLE = true

type NotificationInstancesMap = PartialRecord<NotificationPlacement, Promise<NotificationInstance>>;
type InstanceParams = NotificationProps & {
  getContainer?: () => HTMLElement;
};

export type NoticeProps = NotificationParams;

export type ToastProps = Omit<NotificationParams, 'theme'>;

const instancesMap: NotificationInstancesMap = {}

const mainCssClass = 'Notification'

export function notify() {
  function toast(
    { closable = TOAST_IS_CLOSABLE, ...props }: ToastProps,
    placement?: NotificationPlacement
  ) {
    callNotification({
      ...props, closable, theme: 'dark'
    }, placement || TOAST_PLACEMENT)
  }

  function notice(
    {
      className,
      theme = DEFAULT_NOTICE_THEME,
      closable = NOTICE_IS_CLOSABLE,
      ...props
    }: NoticeProps,
    placement?: NotificationPlacement
  ) {
    callNotification(
      {
        ...props,
        closable,
        theme,
        className: cn(className, `${mainCssClass}__notice`, `${mainCssClass}__${theme}`),
        type: 'notice'
      },
      placement || NOTICE_PLACEMENT
    )
  }

  async function callNotification(
    {
      href,
      linkText,
      status = DEFAULT_STATUS,
      text,
      title,
      theme,
      closable = DEFAULT_CLOSABLE,
      style = {}, // overriding inner Notice style with 'right:50%'
      onLinkClick,
      type = 'toast',
      'data-testid': testId,
      withAlarm = false,
      ...params
    }: NotificationParams,
    placement: NotificationPlacement
  ) {
    const instance = await getInstance({ ...params, placement })
    const alarmAudio = new Audio(alarmSound)
    if (withAlarm) {
      alarmAudio?.play()
    }
    instance.notice({
      ...params,
      style,
      closable,
      content: (
        <NotificationBody
          title={title}
          href={href}
          linkText={linkText}
          status={status}
          theme={theme}
          onLinkClick={onLinkClick}
          type={type}
          data-testid={testId}
        >
          {text}
        </NotificationBody>
      )
    })
  }

  const mainCssClassCloseIcon = 'CloseIcon'
  function getInstance({
    placement,
    style,
    className,
    ...params
  }: InstanceParams & { placement: NotificationPlacement }): Promise<NotificationInstance> {
    const instanceParams: InstanceParams = {
      ...params,
      style: {}, // overriding inner Notice style with 'right:50%'
      className: cn(className, `${mainCssClass}__${placement}`),
      prefixCls: mainCssClass,
      closeIcon: <CloseOutline className={mainCssClassCloseIcon} />
    }

    const cachedInstance = instancesMap[placement]

    if (isDefined(cachedInstance)) {
      return cachedInstance
    }

    instancesMap[placement] = new Promise<NotificationInstance>((resolve) => {
      RCNotificationInstance().then((results) => {
        results.default.newInstance(instanceParams, (instance) => {
          resolve(instance)
        })
      })
      // RCNotification.newInstance(instanceParams, (instance) => {
      //   resolve(instance);
      // });
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return instancesMap[placement]!
  }

  return { notice, toast }
}
