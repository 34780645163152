import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Tooltip, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { editInterviewModals, getEmptySlots } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './EditInterviewModals.scss'
import { TInterview } from '../../../common/api/interviews'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import { FULL_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../common/utils/dateFormats'
import { selectEmptySlots } from '../selectors'
import { editInterview, getInterview } from '../../../compositions/InterviewCard/actions'
import { TDATA } from '../../../compositions/candidate/types'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import { selectUserInfo } from '../../../compositions/InnerRouter/selectors'
import { selectInterview } from '../../../compositions/InterviewCard/selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import Redo from '../../../common/svg/Redo'
import { setEmptySlots } from '../../../compositions/candidate/actions'
import { selectLoadingSlots } from '../../../compositions/candidate/selectors'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'

const mainCssClass = 'edit-interview-modals'

type TProps = {
  candidate: TDATA
  isCandidatePage: boolean
} & ModalProps
function EditInterviewModals({
  open, className, onCancel, candidate, isCandidatePage
}: TProps) {
  const dispatch = useDispatch()
  const emptySlots = useSelector(selectEmptySlots)
  const interview = useSelector(selectInterview)
  const loadingSlots = useSelector(selectLoadingSlots)
  const [disableByInterval, setDisableByInterval] = useState<boolean>(false)
  const user = useSelector(selectUserInfo)
  const withoutVideoEnabled = useMemo(() => !!emptySlots.find((item) => item.value === 'without_video'), [emptySlots])
  const withoutVideoSlots = useMemo(() => emptySlots.filter((item) => item.value !== 'without_video'), [emptySlots])

  const {
    control, watch, getValues, reset, resetField
  } = useForm<TFormValues>()
  const values = [{ label: 'Назначить интервью на текущего рекрутера', value: 'isRecruiter' }]
  const date = watch('date')
  const slot = watch('slot')
  const isRecruiter = watch('isRecruiter')
  const without_video = watch('without_video')
  const comment = watch('comment')
    type TFormValues = {
      date: string
      slot: string
      isRecruiter: string
      without_video: boolean
      comment: string
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }

    const handleSubmit = useCallback(() => {
      if (
        candidate?.id && candidate?.vacancy_id
      && candidate?.request_id && date && (slot || without_video) && candidate?.researcher) {
        dispatch(editInterview({
          data:
            {
              user_id: 0,
              candidate_id: candidate.id,
              vacancy_id: candidate.vacancy_id,
              questionnaire_id: candidate?.questionnare_id ? candidate.questionnare_id : null,
              researcher: candidate?.researcher,
              interview_id: interview?.id,
              recruiter: isRecruiter ? candidate?.responsible : null,
              date,
              slot: without_video ? 'without_video' : slot,
              comment,
              isResercherBeRecruter: isRecruiter ? interview?.recruiter : null
            },
          isCandidatePage
        }))
      }
    }, [candidate, date, slot, interview, isRecruiter, user, comment, without_video])

    useEffect(() => {
      if (isCandidatePage && candidate && candidate.id_interview) {
        dispatch(getInterview(String(candidate.id_interview)))
      }
    }, [])

    useEffect(() => {
      if (date && interview) {
        dispatch(getEmptySlots({
          date: dayjs(date).format(SERVER_DATE_FORMAT),
          user_id: isRecruiter ? interview?.recruiter : interview?.responsible,
          isRecruiter: !!isRecruiter,
          candidate_id: interview.candidate_id
        }))
      }
      if (isRecruiter) {
        resetField('slot')
      }
    }, [date, isRecruiter, interview])

    const handleRefreshSlots = () => {
      if (date && candidate) {
        dispatch(getEmptySlots({
          date: dayjs(date).format(FULL_DATE_FORMAT),
          user_id: user?.isColdSearch ? candidate?.researcher : candidate.responsible,
          isRecruiter: !!user?.isColdSearch || !!user?.isCanAppointRecruiter,
          candidate_id: candidate.id
        }))
        setDisableByInterval(false)
      }
    }
    useEffect(() => {
      const intervalId = setInterval(() => {
        setDisableByInterval(true)
        dispatch(setEmptySlots([]))
      }, 90000)

      return () => clearInterval(intervalId)
    }, [dispatch])

    return (
      <CommonModal
        open={open}
        onOk={handleSubmit}
        onCancel={handleModalClose}
        title="Редактировать интервью"
        className={cn(className, mainCssClass)}
        width="400px"
        typeButtonAccept="primary"
        okText="Сохранить"
        cancelText="Отменить"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >

        <DatePickerFormControl
          name="date"
          control={control}
          label="Выберите дату"
          required
          isDisableDate
          rules={{ required: true }}
          isDisablePastDays={!!user?.can_assign_calendar_future_days}
        />
        <Tooltip title="Необходимо обновить слоты по кнопке" visible={disableByInterval} placement="bottomRight">
          <SelectFormControl
            label="Временной слот"
            required
            name="slot"
            control={control}
            defaultValue="Выберите слот"
            options={withoutVideoSlots}
            disabled={!date || loadingSlots || disableByInterval || without_video}
            {...(disableByInterval ? { overlay: { title: 'Необходимо обновить слоты по кнопке', placement: 'top' } } : {})}
          />
        </Tooltip>
        {disableByInterval && (
          <div className={`${mainCssClass}__button-refresh`}>
            <ControlButton typeButton="secondary" className="button" onClick={handleRefreshSlots}>
              <Redo />
            </ControlButton>
          </div>
        )}
        {withoutVideoEnabled && (
          <div className={`${mainCssClass}__switch-container`}>
            <SwitchFormControl name="without_video" control={control} />
            <span>Без видеосвязи</span>
          </div>
        )}
        <TextAreaFormControl
          name="comment"
          autoSize={{ minRows: 4, maxRows: 5 }}
          control={control}
          label="Комментарий"
        />
        <CheckBoxFormControl
          options={values}
          control={control}
          name="isRecruiter"
        />
      </CommonModal>
    )
}

export default EditInterviewModals
