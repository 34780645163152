import React, {
  FC, memo
} from 'react'
import { Typography } from 'antd'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Archive from '../../../../common/svg/Archive'
import EditSvg from '../../../../common/svg/EditSvg'
import RequestAddEvent from '../../../../common/svg/RequestAddEvent'
import СommentAddEvent from '../../../../common/svg/СommentAddEvent'
import AvatarSizeSmall from '../../../../common/svg/AvatarSizeSmall'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import RequestDone from '../../../../common/svg/RequestDone'
import RequestProgress from '../../../../common/svg/RequestProgress'
import RequestAdd from '../../../../common/svg/RequestAdd'
import RequestReject from '../../../../common/svg/RequestReject'
import './IconActionHistory.scss'

type TProps = {
  action: string
  avatar: string
}
const mainCssClass = getCssClassName('history-icon-action')

// eslint-disable-next-line react/function-component-definition
const IconActionHistory: FC<TProps> = ({ action, avatar }) => {
  if (action === 'requestArchive') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <Archive />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'candidateAddToRequest') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall />}
          <div className={`${mainCssClass}__action`}>
            <RequestAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestEdit' || action === 'candidateRemoveFromRequest'
  ) {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <EditSvg />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'commentAdd') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <СommentAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'add') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RequestAddEvent />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestAdd') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RequestAdd />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestReject') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RequestReject />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestInProgress') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RequestProgress />
          </div>
        </div>
      </div>
    )
  }
  if (action === 'requestDone') {
    return (
      <div className={mainCssClass}>
        <div className={`${mainCssClass}__avatar`}>
          {avatar ? <img src={avatar} alt="avatar" className={`${mainCssClass}_avatar`} /> : <AvatarSizeSmall /> }
          <div className={`${mainCssClass}__action`}>
            <RequestDone />
          </div>
        </div>
      </div>
    )
  }
  return <Typography.Text> Действие не определено</Typography.Text>
}
export default memo<TProps>(withErrorBoundary<TProps>(IconActionHistory))
