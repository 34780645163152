import { createReducer } from '@reduxjs/toolkit'
import {
  setLoading,
  setDictionaries,
  reset,
  setHeaders,
  setLeads,
  setDefDataLeads,
  setSearchLeads,
  openLeadsFilter,
  setFilter, setOpenModalAutoCall
} from './actions'
import { TDictionaries } from '../../common/types/dictionaries'

type TLeadsState = {
  leads: null | any[]
  dictionaries: TDictionaries | null
  loading: boolean
  defDataLeads: {
    page:number,
    perPage: number,
    filters: string,
    search: string
  } | null
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
  search: string
  openFilter: boolean,
  filter: string
  openModalAutoCall: boolean
}

const initialState: TLeadsState = {
  leads: null,
  dictionaries: null,
  loading: false,
  defDataLeads: null,
  headers: null,
  search: '',
  openFilter: false,
  filter: '',
  openModalAutoCall: false
}

const leadsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLeads, (state, { payload }) => {
      state.leads = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setOpenModalAutoCall, (state, { payload }) => {
      state.openModalAutoCall = payload
    })
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setDefDataLeads, (state, { payload }) => {
      state.defDataLeads = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setSearchLeads, (state, { payload }) => {
      state.search = payload
    })
    .addCase(openLeadsFilter, (state, { payload }) => {
      state.openFilter = payload
    })
    .addCase(setFilter, (state, { payload }) => {
      state.filter = payload
    })
})

export default leadsReducer
