import { createReducer } from '@reduxjs/toolkit'
import { TEditTable, TUserWorkPlanItem } from './types'
import { editPeriodModal, openMassSettingsModal, openPeriodModal, reset, setActualPage, setActualPerPage, setEditInfoTable, setHeaders, setLoading, setSelectedPeriod, setUserWorkPlan } from '.'

type TLeadsState = {
  userWorkPlan: TUserWorkPlanItem[]
  loading: boolean
  headers: {
    current: number
    total :number
    totalCount: number
  } | null
  filter: string
  editInfoTable: TEditTable | null
  actualPage: number
  actualPerPage: number
  massSettings: boolean
  periodModal: boolean
  editPeriod: any
  selectedPeriod: any
}

const initialState: TLeadsState = {
  userWorkPlan: [],
  loading: false,
  headers: null,
  filter: '',
  editInfoTable: null,
  actualPage: 1,
  actualPerPage: 10,
  massSettings: false,
  periodModal: false,
  editPeriod: null,
  selectedPeriod: null
}

const userWorkPlanReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserWorkPlan, (state, { payload }) => {
      state.userWorkPlan = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setActualPage, (state, { payload }) => {
      state.actualPage = payload
    })
    .addCase(setActualPerPage, (state, { payload }) => {
      state.actualPerPage = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
    .addCase(setEditInfoTable, (state, { payload }) => {
      state.editInfoTable = payload
    })
    .addCase(openMassSettingsModal, (state, { payload }) => {
      state.massSettings = payload
    })
    .addCase(openPeriodModal, (state, { payload }) => {
      state.periodModal = payload
    })
    .addCase(editPeriodModal, (state, { payload }) => {
      state.editPeriod = payload
    })
    .addCase(setSelectedPeriod, (state, { payload }) => {
      state.selectedPeriod = payload
    })
})

export default userWorkPlanReducer
