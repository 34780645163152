import cn from 'classnames'
import React from 'react'
import './styles.scss'
import { PreloaderProps } from './types'
import { SpinnerSolid } from '../../svg/SvgFromIconWrapper/SpinnerSolid'

/**
 * Компонент прелоадера.
 */
const mainCssClass = 'Preloader'
export function Preloader({
  'data-testid': testId = 'preloader',
  size = 'sm',
  className,
  innerClassName
}: PreloaderProps) {
  return (
    <SpinnerSolid
      className={cn(mainCssClass, className)}
      innerClassName={cn(`${mainCssClass}_icon`, innerClassName)}
      data-testid={testId}
      size={size}
    />
  )
}
