import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
    className?: string
    size?: string
    color?: string
}

const mainCssClass = 'chat-circle-check'

function ChatCircleCheck({ className, size = '1', color }: TSvgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.523 22 12.0001 22C10.2073 22 8.522 21.5273 7.06503 20.6991L4.80204 21.4535C4.58658 21.5253 4.36482 21.5993 4.17665 21.6435C3.99403 21.6864 3.65233 21.7527 3.28668 21.6223C2.86298 21.4712 2.52886 21.1377 2.37749 20.7132C2.24703 20.3473 2.31347 20.0056 2.3564 19.823C2.40048 19.6356 2.47419 19.4147 2.5458 19.2L3.30079 16.9351C2.47265 15.4781 2 13.7928 2 12ZM4 12C4 13.4551 4.38757 14.8166 5.06444 15.9903L5.07956 16.0161C5.13202 16.1055 5.23835 16.2865 5.29202 16.4807C5.33768 16.6459 5.3546 16.81 5.3428 16.9827C5.3291 17.183 5.26348 17.3746 5.23217 17.466L5.22337 17.4919L4.581 19.419L6.50831 18.7765L6.53421 18.7677C6.62655 18.7362 6.81691 18.6711 7.01719 18.6574C7.18804 18.6457 7.35242 18.6619 7.51934 18.708C7.7135 18.7617 7.89407 18.8676 7.98403 18.9204L8.00985 18.9355C9.18349 19.6124 10.545 20 12.0001 20C16.4184 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
        fill={color || '#2DBE64'}
      />
      <path
        d="M15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L11 12.5858L9.70711 11.2929C9.31658 10.9024 8.68342 10.9024 8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071L15.7071 10.7071Z"
        fill={color || '#2DBE64'}
      />
    </svg>
  )
}

export default ChatCircleCheck
