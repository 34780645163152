import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'empty-tasks'

function EmptyTasks({ className, size = '1' }: TSvgProps) {
  return (

  
<svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" width="56" height="56" rx="24" fill="#E8ECE9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.5 20C24.0817 20 20.5 23.5817 20.5 28C20.5 32.4183 24.0817 36 28.5 36C32.9183 36 36.5 32.4183 36.5 28C36.5 23.5817 32.9183 20 28.5 20ZM18.5 28C18.5 22.4772 22.9772 18 28.5 18C34.0228 18 38.5 22.4772 38.5 28C38.5 33.5228 34.0228 38 28.5 38C22.9772 38 18.5 33.5228 18.5 28ZM24.7929 24.2929C25.1834 23.9024 25.8166 23.9024 26.2071 24.2929L28.5 26.5858L30.7928 24.293C31.1833 23.9025 31.8165 23.9025 32.207 24.293C32.5975 24.6835 32.5975 25.3167 32.207 25.7072L29.9142 28L32.207 30.2928C32.5975 30.6833 32.5975 31.3165 32.207 31.707C31.8165 32.0975 31.1833 32.0975 30.7928 31.707L28.5 29.4142L26.2071 31.7071C25.8166 32.0976 25.1834 32.0976 24.7929 31.7071C24.4024 31.3166 24.4024 30.6834 24.7929 30.2929L27.0858 28L24.7929 25.7071C24.4024 25.3166 24.4024 24.6834 24.7929 24.2929Z" fill="#404542"/>
</svg>
  )
}
export default EmptyTasks







