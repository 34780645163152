import React, {
  FC, memo, useMemo
} from 'react'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { TInterview, TInterviewHistory } from '../../../common/api/interviews'
import StatusInterviewTableRow from './StatusInterviewTableRow'
import ResultInterviewTableRow from './ResultInterviewTableRow'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ButtonsTable from './ButttonsTable'

const mainCssClass = getCssClassName('history-interview-table')

type TProps= {
  historyData: TInterviewHistory[]
} & TClassName
type TRowData = {
  date: string
  recruiter_name: string
  research_name: string
  status: JSX.Element | string
  result: JSX.Element | string
  buttons: JSX.Element
}

const useColumns = (): Column<any>[] => (
  useMemo(
    () => ([
      {
        accessor: 'date', Header: 'Дата'
      },
      { accessor: 'recruiter_name', Header: 'Рекрутер' },
      { accessor: 'research_name', Header: 'Ресечер' },
      { accessor: 'status', Header: 'Статус интервью' },
      { accessor: 'result', Header: 'Результат интервью' },
      { accessor: 'buttons', Header: '' }
    ]),
    []
  )
)

const useRowsData = (interviews: TInterviewHistory[]) => {
  const rows: TRowData[] = useMemo(
    () => (
      interviews?.length ? interviews.map<TRowData>(({
        date, recruiter_name, research_name, status, result, labels, id, snapshot
      }) => ({
        date,
        recruiter_name: labels?.recruiter || '-',
        research_name: labels?.research || '-',
        status: labels?.status || '-',
        result: labels?.result || '-',
        buttons: <ButtonsTable id={id} snapshot={snapshot} />
      })) : []
    ),
    [interviews]
  )
  return { rows }
}
const HistoryInterviewsTable: FC<TProps> = ({ className, historyData }) => {
  const columns = useColumns()
  const { rows } = useRowsData(historyData)

  return (
    <Table
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      withoutHeaderDividers
    />
  )
}

export default memo(withErrorBoundary(HistoryInterviewsTable))
