import React, {
  memo, useEffect, useMemo
} from 'react'
import { ModalProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import './SimpleModalCandidate.scss'
import { getStageStatusByStage, stageStatusCandidate } from '../actions'
import { selectCandidate, selectCandidateStageState } from '../selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

type TFormValues = {
  stage_state_id: number
}

export type TProps = {
  candidate_id: number
} & ModalProps

function ModalStageStatusModal({
  onOk, open, className, onCancel, candidate_id
}: TProps) {
  const dispatch = useDispatch()
  const stageOptions = useSelector(selectCandidateStageState)
  const candidate = useSelector(selectCandidate)

  const defaultValues = useMemo(
    () => (candidate?.stage_state && candidate
      ? {
        stage_state_id: candidate.stage_state_id
      }
      : {}),
    [candidate]
  )

  const {
    control, handleSubmit, reset, getValues
  } = useForm<TFormValues>({ defaultValues })

  const onSubmit = () => {
    if (candidate) {
      dispatch(stageStatusCandidate({ id: candidate_id, ...getValues() }))
    }
  }

  useEffect(() => {
    if (candidate?.stage_id) {
      dispatch(getStageStatusByStage(candidate.stage_id))
    }
  }, [candidate])

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Изменить статус на этапе"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <SelectFormControl
        label="Cтатус на этапе"
        required
        name="stage_state_id"
        control={control}
        defaultValue="Выберите статус"
        options={stageOptions}
      />
    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ModalStageStatusModal))
