import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import { TDictionariesName } from '../../common/types/dictionaries'

const selectState = ({ leads }: TRootState) => leads

export const selectLeads = createSelector(selectState, ({ leads }) => leads)

export const selectDictionariesMap = createSelector(selectState, ({ dictionaries }) => dictionaries || {})

export const selectDictionaryOptionsByName = (name: TDictionariesName) => createSelector(
  selectDictionariesMap,
  // @ts-ignore
  (dictionaries) => dictionaries[name] || []
)

export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectDefDataLeads = createSelector(selectState, ({ defDataLeads }) => defDataLeads)

export const selectLeadsFilter = createSelector(selectState, ({ filter }) => filter)

export const selectLeadsSearch = createSelector(selectState, ({ search }) => search)

export const selectOpenLeadsFilter = createSelector(selectState, ({ openFilter }) => openFilter)

export const selectOpenModalAutoCall = createSelector(selectState, ({ openModalAutoCall }) => openModalAutoCall)

export const selectHeaders = createSelector(selectState, ({ headers }) => headers)

export const selectLabelById = (stageId: number | undefined, dictionaryName: TDictionariesName) => createSelector(selectDictionaryOptionsByName(dictionaryName), (options) => {
  const foundOption = options.find((option: any) => option.value === stageId)
  return foundOption ? foundOption.label : null
})
