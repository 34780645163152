import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'

import { Checkbox } from 'antd/lib'
import { NavLink } from 'react-router-dom'
import { Typography } from 'antd'
import Vacation from './Vacation'
import { TClassName } from '../../../common/types/props'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { TDATA } from '../../candidate/types'
import { navigateTo } from '../../../common/actions/navigate'
import CandidateStatusTableRow from './CandidateStatusTableRow'
import CandidateStageTableRow from './CandidateStageTableRow'
import CandidateCommentTableRow from './CandidateCommentTableRow'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Duplicate from './Duplicate'
import AvatarCandidateEdit from '../../../common/svg/AvatarCandidateEdit'

import './CandidateTable.scss'
import OverflowTooltip from '../../../common/components/Ellipsis/components/Ellipsis'

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
  candidates: TDATA[] | []
} & TClassName
type TRowData = {
  checkbox: JSX.Element
  name: JSX.Element
  request: JSX.Element | string
  stage: JSX.Element
  status: JSX.Element
  vacations: JSX.Element
  last_comment: JSX.Element | string
  city: string
  buttons: JSX.Element
  is_duplicates: JSX.Element
  customer_list:string
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    // {
    //   accessor: 'checkbox', Header: (<Checkbox />)
    // },
    {
      accessor: 'name',
      Header: 'Кандидат',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'customer_list',
      Header: 'Заказчик',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'request',
      Header: 'Заявка',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA.props.children === 'string' && typeof valueB.props.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'stage',
      Header: 'Этап',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'is_duplicates',
      Header: 'Дубли',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'status',
      Header: 'Статус',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'city',
      Header: 'Город',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'last_comment',
      Header: 'Последний комментарий',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
        // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.vacancies_list?.[0] && valueB?.props?.vacancies_list?.[0]) {
          return valueA.props.vacancies_list[0]?.label.localeCompare(valueB.props.vacancies_list[0]?.label, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'vacations',
      Header: 'Вакансии',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
        // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.vacancies_list?.[0] && valueB?.props?.vacancies_list?.[0]) {
          return valueA.props.vacancies_list[0]?.label.localeCompare(valueB.props.vacancies_list[0]?.label, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'buttons', Header: '' }

  ],
  []
)

const useRowsData = (candidates: TDATA[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (candidates?.length
      ? candidates.map<TRowData>(({
        name, photo, second_name, last_name, customer_list, experience, is_duplicates, id, vacancy_list, status, stage_id, address, request_id, request_list, comment_list, city,
        merge_status
      }) => ({
        checkbox: <Checkbox />,
        name: (
          <NavLink to={`/candidate/${id}`} className={`${mainCssClass}__link`}>
            <div className={`${mainCssClass}__name-${status}`}>
              <div className={`${mainCssClass}__name_status-columns`}>
                <div className={`${mainCssClass}__name_status-columns_page`}>
                  {photo ? (
                    <img
                      src={photo}
                      alt={`${name} ${last_name}`}
                      className={`${mainCssClass}__name_status-columns_photo`}
                    />
                  ) : (
                    <AvatarCandidateEdit />
                  )}
                </div>
                {' '}
                {`${last_name} ${name} ${second_name}`?.length > 60
                  ? `${`${last_name || ''} ${name} ${second_name || ''}`?.slice(45)}...` : `${last_name || ''} ${name} ${second_name || ''}` }
              </div>
            </div>
          </NavLink>
        ),
        customer_list: customer_list?.length > 0 ? (
          <div className={`${mainCssClass}__name_status-columns`}>
            {customer_list.map((customer, index) => (
              <div key={index}>{customer}</div>
            ))}
          </div>
        ) : '-',
        request: <div className={`${mainCssClass}__name_status-columns`}>
          {request_list.label || 'Не назначена'}
        </div>,
        status: <div className={`${mainCssClass}__name_status-columns`}>
          <div className={`${mainCssClass}__name_status-columns_stage`}>
            <CandidateStatusTableRow status={status} />
          </div>
        </div>,
        stage: <div className={`${mainCssClass}__name_status-columns`}>
          <div className={`${mainCssClass}__name_status-columns_stage`}>
            <CandidateStageTableRow stage={stage_id} />
          </div>
        </div>,
        city: <div className={`${mainCssClass}__name_status-columns`}>
          {city || 'Не выбран'}
        </div>,
        last_comment: <div className={`${mainCssClass}__name_status-columns`}>
          {comment_list && !!comment_list?.length
            ? <CandidateCommentTableRow text={comment_list[0].comment || ''} /> : ''}
        </div>,
        vacations: <div className={`${mainCssClass}__name_status-columns`}>
          <Vacation vacancies_list={vacancy_list} />
        </div>,
        buttons: <div className={`${mainCssClass}__name_status-columns`}>
          <ButtonsTable id={id} status={status} is_duplicates={is_duplicates} />
        </div>,
        id,
        is_duplicates: <div className={`${mainCssClass}__name_status-columns`}>
          <Duplicate is_duplicates={is_duplicates} key={id} merge_status={merge_status} />
        </div>
      }))
      : []),
    [candidates]
  )
  return { rows, handleRowClick }
}
const CandidatesTable: FC<TProps> = ({ className, candidates }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(candidates)

  return (
    <Table
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidatesTable))
