import React, { useEffect } from 'react'
import { Input, InputProps, Typography } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import { TextAreaProps } from 'antd/lib/input'
import cn from 'classnames'
import Required from '../../../common/svg/Required'
import './TextAreaFormControl.scss'

type TControlInputProps = Omit<
  UseControllerProps &
    TextAreaProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      required?: boolean
    },
  'isDisabled'
>
const { Title } = Typography

const { TextArea } = Input

function TextAreaFormControl({
  name,
  control,
  rules,
  className,
  size = 'large',
  label,
  required,
  defaultValue,
  ...rest
}: TControlInputProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    defaultValue ? field.onChange(defaultValue) : undefined
  }, [])

  return (
    <>
      {!label && (
        <TextArea
          className={cn(
            className,
            'textArea',
            { inputLarge: size === 'large' },
            { inputMiddle: size === 'middle' },
            { inputSmall: size === 'small' },
            { error: fieldState.error }
          )}
          size={size}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...rest}
        />
      )}
      {label && (
        <div>
          <Title className="textAreaLabel" level={4}>
            {label}
            {required && <Required className="required" />}
          </Title>
          <TextArea
            className={cn(
              className,
              'textArea',
              { inputLarge: size === 'large' },
              { inputMiddle: size === 'middle' },
              { inputSmall: size === 'small' },
              { error: fieldState.error }
            )}
            size={size}
            value={field.value}
            defaultValue={defaultValue}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...rest}
          />
        </div>
      )}
    </>
  )
}

export default TextAreaFormControl
