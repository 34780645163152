import React, { ReactNode } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { TUnstatusedNotification } from './notification/utils'

import { TServerErrorResponse } from '../types/response'

export function getErrorMessage(error: AxiosError<TServerErrorResponse>): string | null {
  return error?.response?.data?.errors?.[0]?.title || error?.message || null
}

type TArgs = {
  error?: AxiosError<TServerErrorResponse | string> | AxiosResponse
  moduleName?: string
  title?: string
  text?: string
  content?: ReactNode
}

const mainCssStyle = 'loan-error-notifications'

export function getErrorNotification({
  moduleName,
  error,
  title,
  text
}: TArgs): TUnstatusedNotification {
  let targetTitle: string | undefined = title
  const targetText: string | undefined = text
  let targetMessage: string | undefined
  let targetStatus: string | undefined
  let targetUrl: string | undefined
  let targetMethod: string | undefined
  let targetTimeout: number | undefined
  let targetDate: string | undefined
  let targetClarifications: string[] | undefined
  if (error) {
    if (error.isAxiosError) {
      if (!targetTitle && moduleName) {
        if (error.isAxiosError) {
          targetTitle = `Ошибка API в модуле "${moduleName}"`
        } else {
          targetTitle = `Ошибка интерфейса в модуле "${moduleName}"`
        }
      }
    }
    if (error.config) {
      const { url, method, timeout } = error.config || {}
      targetUrl = url
      targetMethod = method
      targetTimeout = timeout
      if (url && targetMethod) {
        targetDate = Date()
      }
    }
    if (error.data.DATA) {
      targetTitle = error.data.DATA.name
      targetMessage = error.data.DATA.message
    }
    if (error.code === 'ECONNABORTED') {
      targetStatus = 'TIMEOUT'
      targetMessage = `Превышено максимальное время ожидания отклика${targetTimeout ? ` - ${targetTimeout / 1000} сек.` : ''}`
    } else if (error.response) {
      const { status, data } = error.response
      if (typeof data === 'string' && !data.includes('html')) {
        targetMessage = data
      } else if (typeof data === 'object') {
        const { DATA } = data
        targetMessage = DATA.message || undefined
        if (errors?.length) {
          errors.forEach(({ code, title }) => {
            if (code || title) {
              if (!targetClarifications) {
                targetClarifications = []
              }
              targetClarifications.push(`${code ? `${code}: ` : ''}${title || '-'}`)
            }
          })
        }
      }
      targetStatus = status.toString()
    } else if (error.name || error.message) {
      if (error.name) {
        targetMessage = error.name
      }
      if (error.name && error.message) {
        targetMessage += ': '
      }
      if (error.message) {
        targetMessage += error.message
      }
    }
    if (targetMessage) {
      if (!targetClarifications) {
        targetClarifications = []
      }
      targetClarifications.unshift(targetMessage)
    }
  }

  return {
    title: targetTitle || 'Ошибка',
    text: error ? (
      <div className={`${mainCssStyle}__content ${mainCssStyle}__error-content`}>
        {targetText && <div className={`${mainCssStyle}__error-text`}>{targetText}</div>}
        <div className={`${mainCssStyle}__error-details`}>
          {targetStatus && <div className={`${mainCssStyle}__error-status`}>{`Статус-код: ${targetStatus}`}</div>}
          {targetUrl && targetMethod && (
            <div className={`${mainCssStyle}__error-url`}>
              {`Запрос: ${targetMethod.toUpperCase()} ...${targetUrl}`}
            </div>
          )}
          {targetDate && <div className={`${mainCssStyle}__error-time`}>{`Время завершения: ${targetDate}`}</div>}
          {targetClarifications?.length && (
            <div className={`${mainCssStyle}__error-clarifications`}>
              <div>Детали:</div>
              {targetClarifications.map((detail) => <div key={detail} className={`${mainCssStyle}__error-clarification`}>{detail}</div>)}
            </div>
          )}
        </div>
      </div>
    ) : targetText
  }
}
