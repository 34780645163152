import React, {
  memo,
  useEffect,
  useMemo
} from 'react'
import {
  ModalProps, Drawer, DrawerProps
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
// import './NotifyDrawer.scss'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import './ChatDrawer.scss'
import Chat from '../../../../features/chat/components/Chat'
import { openChatDrawer } from '../../actions'
import { getMessages, setMessages, setPageMessages } from '../../../../features/chat/actions'
import { selectCandidate } from '../../selectors'
import { selectPageMessages } from '../../../../features/chat/selectors'
import { getDialogs } from '../../../../features/chatPanel'
import CommonDrawer from '../../../../common/components/CommonDrawer/CommonDrawer'

const mainCssClass = getCssClassName('chat-drawer')

type TCustomDrawerProps = DrawerProps & {isMobile?: boolean}

function ChatDrawer({
  open, className, isMobile
}:TCustomDrawerProps) {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const page = useSelector(selectPageMessages)
  const candidatePhoneMemo = useMemo(() => (candidate?.phone ? candidate.phone[0] : null), [candidate?.phone])

  const handleOnClose = () => {
    dispatch(openChatDrawer(false))
    dispatch(setMessages([]))
    dispatch(getDialogs({ page: 1 }))
    dispatch(setPageMessages(1))
  }

  useEffect(() => {
    if (candidate && candidate.phone && candidate.phone.length > 0 && open) {
      dispatch(getMessages({ phone: candidate.phone[0], page }))
    }
  }, [candidatePhoneMemo, page, open])

  return (
    <CommonDrawer
      title="Чат с кандидатом"
      onClose={handleOnClose}
      open={open}
      className={cn(className, mainCssClass)}
      contentWrapperStyle={isMobile ? { width: '700px' } : { width: '100%' }}
    >
      <div className={`${mainCssClass}_candidateChat`}>
        <Chat />
      </div>
    </CommonDrawer>
  )
}

export default memo<TCustomDrawerProps>(withErrorBoundary<TCustomDrawerProps>(ChatDrawer))
