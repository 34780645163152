/* eslint-disable react/jsx-indent */
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'

import { NavLink } from 'react-router-dom'

import { Tooltip } from 'antd'
import { setPreviousPage } from '../../../compositions/InnerRouter/actions'
import Info from '../../../common/svg/Info'
import Vacation from './Vacation'
import { TClassName } from '../../../common/types/props'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import AvatarCandidateEdit from '../../../common/svg/AvatarCandidateEdit'

import './ProductivityTable.scss'
import { selectProductivityRecruiter } from '../selectors'

const mainCssClass = getCssClassName('productivity-table')

  type TRowData = {
    name: JSX.Element
    request: JSX.Element
    stage: JSX.Element
    status: JSX.Element
    vacations: JSX.Element
    request_priority: JSX.Element
    customer_list:string
  }

const useColumns = (): Column<any>[] => useMemo(
  () => [
    // {
    //   accessor: 'checkbox', Header: (<Checkbox />)
    // },
    {
      accessor: 'name',
      Header: 'Кандидат',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'customer_list',
      Header: 'Заказчик',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'request',
      Header: 'Заявка',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA.props.children === 'string' && typeof valueB.props.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'priority',
      Header: <div className={`${mainCssClass}__priority`}>
        Приоритет заявки
        <Tooltip
          title="На момент принятия
                      данного кандидата"
          placement="top"
        >
          <div>
            <Info />
          </div>
        </Tooltip>
              </div>,
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'stage',
      Header: 'Этап',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'status',
      Header: 'Статус',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'vacations',
      Header: 'Вакансии',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    }
  ],
  []
)

const useRowsData = (candidates: any[]) => {
  const dispatch = useDispatch()

  const handleClickPreviousPage = () => {
    dispatch(setPreviousPage({ navigate: '/productivity', caption: 'Продуктивность' }))
  }
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (candidates?.length
      ? candidates.map<any>(({
        candidate_id, candidate_avatar, candidate_fio, customer_list, id, vacancy, status, request, stage, priority
      }) => ({
        name: (
          <NavLink to={`/candidate/${candidate_id}`} className={`${mainCssClass}__link`} onClick={handleClickPreviousPage}>
            <div className={`${mainCssClass}__name-${status}`}>
              <div className={`${mainCssClass}__name_status-columns`}>
                <div className={`${mainCssClass}__name_status-columns_page`}>
                  {candidate_avatar ? (
                    <img
                      src={candidate_avatar}
                      alt={candidate_avatar}
                      className={`${mainCssClass}__name_status-columns_photo`}
                    />
                  ) : (
                    <AvatarCandidateEdit />
                  )}
                </div>
                {' '}
                {candidate_fio?.length > 60
                  ? candidate_fio.slice(45) : candidate_fio }
              </div>
            </div>
          </NavLink>
        ),
        status: <div className={`${mainCssClass}__name_status-columns`}>
          {status || 'Не назначен' }
        </div>,
        customer_list: customer_list?.length > 0 ? (
          <div className={`${mainCssClass}__name_status-columns`}>
            {customer_list.join(', ')}
          </div>
        )
          : '-',
        request: <div className={`${mainCssClass}__name_status-columns`}>
          {request || 'Не назначена' }
        </div>,
        priority: <div className={`${mainCssClass}__name_status-columns`}>
          {priority || 'Не назначена' }
        </div>,
        stage: <div className={`${mainCssClass}__name_status-columns`}>
          {stage || 'Не назначена' }
        </div>,
        vacations: <div className={`${mainCssClass}__name_status-columns`}>
          <Vacation vacancy={vacancy} />
        </div>,
        id
      }))
      : []),
    [candidates]
  )
  return { rows, handleRowClick }
}
function ProductivityRecruiterTable({ className } : TClassName) {
  const productivityRecruiter = useSelector(selectProductivityRecruiter)
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(productivityRecruiter)

  return (
    <Table
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TClassName>(withErrorBoundary<TClassName>(ProductivityRecruiterTable))
