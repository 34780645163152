import $api from '../../common/api/http'

export async function fetchCitiesList(name: string) {
  const data = await $api.get(`/api/v1/dadata/suggest-city?search=${name}`)

  return data.data.DATA.map((obj: { data: { city: string; city_kladr_id: number | string } }) => ({
    label: `${obj.data.city}`,
    value: obj.data.city,
    key: obj.data.city_kladr_id
  }))
}

export function extractValue(field: any): string {
  return typeof field === 'object' ? field.value : field
}
export async function getGroup() {
  const { data } = await $api.get('/api/v1/dictionary/get-drop-down-group')
  return data.DATA.map((item) => ({
    value: item.id,
    label: item.value
  }))
}

export function transformArray(arr : any) {
  // Применяем метод map для извлечения свойства 'url' из объектов и фильтруем пустые значения
  const transformedArray = arr.map((el) => el.url).filter((el) => el !== '')

  // Проверяем, является ли получившийся массив пустым
  if (transformedArray.length === 0) {
    return null
  }
  return transformedArray
}
