import dayjs from 'dayjs'

export const getCurrentHoursAndMinutes = () => {
  const now = new Date()
  const hours = now.getHours().toString().padStart(2, '0') // Добавляем ведущий нуль, если нужно
  const minutes = now.getMinutes().toString().padStart(2, '0') // Добавляем ведущий нуль, если нужно
  return `${hours}:${minutes}`
}

export const formatTimeMinutesSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}

export const phoneButton = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

export const allTargetURL = ['vacancies', 'candidates', 'interviews', 'analytics', 'candidate', 'applications', 'application', 'interview', 'vacancy', 'systems', 'event-funnel', 'tasks', 'fluidity', 'costOfHiring', 'phot', 'departmentWorkPlan', 'asterisk', 'internships', 'leads', 'parsing', 'autocalls', 'productivity']

export const formatTime = (time: number) => {
  const hours = String(Math.floor(time / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0')
  const seconds = String(time % 60).padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

// Функция для конвертации строки времени в секунды
export const timeStringToSeconds = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number)
  return hours * 3600 + minutes * 60 + seconds
}

export const getCurrentColor = (currentRtt: number) => {
  if (currentRtt < 2000) {
    return '#BFC5C0'
  }
  if (currentRtt > 2000 && currentRtt < 4000) {
    return '#DF1E12'
  }
  if (currentRtt > 4000 && currentRtt < 6000) {
    return '#FA5F0A'
  }
  if (currentRtt > 6000 && currentRtt < 8000) {
    return '#FFD608'
  }
  if (currentRtt > 8000) {
    return '#2DBE64'
  }
}
export const getCurrentTextRtt = (currentRtt: number) => {
  if (currentRtt < 2000) {
    return 'Нет сети'
  }
  if (currentRtt > 2000 && currentRtt < 4000) {
    return 'Плохое'
  }
  if (currentRtt > 4000 && currentRtt < 6000) {
    return 'Удов.'
  }
  if (currentRtt > 6000 && currentRtt < 8000) {
    return 'Хорошее'
  }
  if (currentRtt > 8000) {
    return 'Отличное'
  }
}
export function convertSecondsToTime(seconds: string) {

  // Преобразуем строку в число
  const secondsNumber = parseInt(seconds, 10);

  // Проверяем, является ли число корректным
  if (isNaN(secondsNumber)) {
    throw new Error('Invalid number of seconds');
  }

  // Создаем объект dayjs с началом эпохи Unix (1 января 1970)
  const startOfEpoch = dayjs('1970-01-01T00:00:00Z');

  // Добавляем количество секунд к началу эпохи
  const time = startOfEpoch.add(secondsNumber, 'second');

  // Форматируем время в нужный формат (HH:mm:ss)
  return time.format('HH:mm:ss');
}

