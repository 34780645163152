import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'
import { TOption } from '../../../common/types/dictionaries'
import './ModalUpdateStatusRequest.scss'
import { archiveStatusRequest, updateStatusRequest } from '../actions'
import Plus from '../../../common/svg/Plus'
import { selectDictionaryOptionsByName } from '../../../compositions/Request/selectors'
import { getDictionaries } from '../../../compositions/Request/actions'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'update-request'

type TFormValues = {
  priority_id: number
  comment: string
}

export type TProps = {
  requestId?: number | null | string
  isRequestTable: boolean
} & ModalProps
function ModalUdpateStatusRequest({
  open, className, onCancel, requestId, isRequestTable
}: TProps) {
  const dispatch = useDispatch()
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))

  const {
    control, watch, getValues, handleSubmit, reset
  } = useForm<TFormValues>()

  const onSubmit = useCallback(() => {
    if (requestId) {
      dispatch(
        updateStatusRequest({
          status: 92,
          id: requestId,
          ...getValues(),
          isRequestTable
        })
      )
    }
  }, [requestId])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title="Взять в работу"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Применить"
      cancelText="Отменить"
      centered
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={mainCssClass}>
        <div className={`${mainCssClass}_priority`}>
          <SelectFormControl
            name="priority_id"
            control={control}
            placeholder="Приоритет"
            options={priorityOptions}
            required
            label="Приоритет"
            rules={{ required: true }}
          />
        </div>

        {/* <TextAreaFormControl name="comment" control={control} label="Комментарий" required rules={{ required: true }} /> */}
      </div>
    </CommonModal>
  )
}

export default ModalUdpateStatusRequest
