/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Dropdown, MenuProps, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Redo from '../../../common/svg/Redo'
import { getCssClassName } from '../../../common/utils/generateClassName'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import {
  createStage,
  createStageState,
  getStageState,
  getStages,
  getVertices,
  selectActiveStages,
  selectDropdownStageSetupKey,
  selectStages,
  selectStagesState,
  selectVertices,
  setDropdownStageSetupKey,
  setStageState,
  sortStage,
  updateStage,
  updateStageState,
  updateVertices
} from '..'
import ControlButton from '../../../common/components/Buttons/Button'
import LockInterface from '../../../common/svg/Lock'
import EditPencil1 from '../../../common/svg/EditPencil1'
import DragVertical from '../../../common/svg/DragVertical'
import Archive from '../../../common/svg/Archive'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import Check from '../../../common/svg/Check'
import CloseMD from '../../../common/svg/CloseMD'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { getLimitationOption, getStageOptions, getTypes, sortedStage, sortedStageOptions } from '../utils'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import CheckBig from '../../../common/svg/CheckBig'
import './StageSetup.scss'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import PickerFormControl from '../../../common/components/formcontrol/PickerFormControl'
import MoreVertical from '../../../common/svg/MoreVertical'
import CandidateConstructor from './CandidateConstructor'

const mainCssClass = getCssClassName('stage-setup')

type TFormValuesName = {
  name: string
}

type TFormVertices = {
  name: string
}

type TFormValuesStages = {
  stages: {
    name: string
    limit_on_day: string
    is_finalist: boolean
    is_process: boolean
    is_accepted: boolean
    restriction: number[]
    stages: boolean
    state: { name: string; state: number | null; stage: number | null }[]
    newState: string
  }[]
} & any

type TFormValuesNewStages = {
  stages: {
    newState: string
  }[]
}

function Example() {
  const dispatch = useDispatch()
  const vertices = useSelector(selectVertices)
  const stages = useSelector(selectStages)
  const activeStages = useSelector(selectActiveStages)
  const stageState = useSelector(selectStagesState)
  const dropdownStageSetupKey = useSelector(selectDropdownStageSetupKey)

  const [officeContent, setOfficeContent] = useState(false)
  const [couriersContent, setCouriersContent] = useState(false)
  const [retailContent, setRetailContent] = useState(false)
  const [fourthContent, setFourthContent] = useState(false)
  const [addStage, setAddStage] = useState<number[]>([])
  const [editStage, setEditStage] = useState<number[]>([])
  const [editVertices, setEditVertices] = useState<number | null>(null)
  const [activeStage, setActiveStage] = useState<any>(undefined)
  const [containers, setContainers] = useState<any[]>([])
  const [types, setTypes] = useState<any>([])
  const [limitationOption, setLimitationOption] = useState([])
  const [departmentId, setDepartmentId] = useState()

  const newArray = useMemo(() => sortedStage(stages), [stages])

  const stagesOptions = useMemo(() => sortedStageOptions(stages), [stages])

  const {
    control: nameControl,
    handleSubmit: handleSubmitName,
    resetField: resetNameField,
    watch: watchName
  } = useForm<TFormValuesName>()

  const {
    control: verticesControl,
    handleSubmit: handleSubmitVertices,
    resetField: resetVerticesField,
    watch: watchVertices
  } = useForm<TFormVertices>()

  const {
    control: stageNameControl,
    handleSubmit: handleSubmitStageName,
    watch: watchStage,
    getValues,
    trigger,
    setValue
  } = useForm<TFormValuesStages>()

  const {
    control: newStageControl,
    handleSubmit: handleSubmitNewStage,
    watch: watchNewStage
  } = useForm<TFormValuesNewStages>()

  const items: MenuProps['items'] = [
    {
      key: 'addStage',
      label: <span>Добавить этап</span>
    }
    // {
    //   key: 'candidate',
    //   label: <span>Карточка кандидата</span>
    // }
    // {
    //   key: 'request',
    //   label: <span>Карточки заявки</span>
    // },
    // {
    //   key: 'funnel',
    //   label: <span>Карточки воронки</span>
    // }
  ]

  const chooseIcons = (id: number) => {
    switch (id) {
      case 1:
        return officeContent
      case 2:
        return couriersContent
      case 3:
        return retailContent
      case 4:
        return fourthContent
      default:
        return null
    }
  }

  const handleClick = (id: number) => {
    switch (id) {
      case 1:
        return setOfficeContent((prev) => !prev)
      case 2:
        return setCouriersContent((prev) => !prev)
      case 3:
        return setRetailContent((prev) => !prev)
      case 4:
        return setFourthContent((prev) => !prev)
      default:
        return null
    }
  }

  const handleClickEditVertices = (id: number) => {
    setEditVertices(id)
  }

  const handleClickUpdateVertices = (id: number) => {
    const name = watchVertices('name')
    dispatch(updateVertices({ id, name }))
    setEditVertices(null)
  }

  const handleAddStage = (id: number) => {
    if (addStage.includes(id)) {
      setAddStage([])
    } else setAddStage([id])
  }

  const handleCreateStage = (id: number) => {
    const { priority } = containers[id - 1].end[0]
    dispatch(
      createStage({
        name: watchName('name'),
        vertex_id: id,
        priority,
        status: 1
      })
    )
    setAddStage([])
    resetNameField('name')

    const new_stage_list = containers[id - 1].end.map((el) => ({ stage_id: el.id, priority: el.priority + 1 }))

    setTimeout(() => {
      dispatch(sortStage({ stage_list: new_stage_list }))
    }, 1000)
  }

  const handleArchive = (id: number) => {
    dispatch(updateStage({ id, status: 0 }))
  }
  const handleChangeColor = (inx, id) => {
    dispatch(
      updateStage({
        id,
        color: watchStage(`stages[${inx}].color`)
      })
    )
  }

  const handleReturnArchive = (id: number) => {
    dispatch(updateStage({ id, status: 1 }))
  }

  const handleClickEdit = (id: number) => {
    if (editStage.includes(id)) {
      setEditStage(editStage.filter((el) => el !== id))
      dispatch(setStageState(stageState.filter((el) => el.id !== id)))
    } else {
      setEditStage([...editStage, id])
      dispatch(getStageState(id))
    }
  }

  const handleClickBlockEdit = (id: number) => {
    if (editStage.includes(id)) {
      setEditStage(editStage.filter((el) => el !== id))
    } else {
      setEditStage([...editStage, id])
    }
  }

  const handleDragStart = (event, containerId, elemId, priority) => {
    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        containerId,
        elemId,
        priority
      })
    )
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event, targetContainerElemId, targetContainerId) => {
    event.preventDefault()
    const data = JSON.parse(event.dataTransfer.getData('text/plain'))
    const { containerId, elemId, priority } = data

    if (priority === targetContainerElemId || containerId !== targetContainerId) {
      return
    }
    if (priority !== targetContainerElemId && containerId === targetContainerId) {
      if (priority > targetContainerElemId) {
        // const sorted_array = containers[containerId].noBlock.sort((a, b) => a.vertex_id - b.vertex_id)
        const oldIndex = containers[containerId].noBlock.findIndex((el) => el.priority === priority)
        const newIndex = containers[containerId].noBlock.findIndex((el) => el.priority === targetContainerElemId)

        const newArray = containers[containerId].noBlock.map((el, i) => {
          if (i < newIndex) {
            return { stage_id: el.id, priority: el.priority }
          }
          if (i >= newIndex && i < oldIndex) {
            return { stage_id: el.id, priority: el.priority + 1 }
          }
          if (i === oldIndex) {
            return { stage_id: el.id, priority: containers[containerId].noBlock[newIndex].priority }
          }
          if (i > oldIndex) {
            return { stage_id: el.id, priority: el.priority }
          }
        })
        dispatch(sortStage({ stage_list: newArray }))
      }
      if (priority < targetContainerElemId) {
        const oldIndex = containers[containerId].noBlock.findIndex((el) => el.priority === priority)
        const newIndex = containers[containerId].noBlock.findIndex((el) => el.priority === targetContainerElemId)
        const newArray = containers[containerId].noBlock.map((el, i) => {
          if (i < oldIndex) {
            return { stage_id: el.id, priority: el.priority }
          }
          if (i <= newIndex && i > oldIndex) {
            return { stage_id: el.id, priority: el.priority - 1 }
          }
          if (i > newIndex) {
            return { stage_id: el.id, priority: el.priority }
          }
          if (i === oldIndex) {
            return { stage_id: el.id, priority: containers[containerId].noBlock[newIndex].priority }
          }
        })

        dispatch(sortStage({ stage_list: newArray }))
      }
    }
  }

  const handleAddStageState = (i, stage) => {
    const newState = watchNewStage(`stages[${i}].newState`) || ''

    dispatch(
      createStageState({
        name: newState,
        stage_id: stage
      })
    )
    setValue(`stages[${i}].stages`, false)
  }

  const handleArchiveStageState = (i, status) => {
    dispatch(
      updateStageState({
        id: i,
        status: !status
      })
    )
  }

  const handleChangeProcess = (id, process) => {
    dispatch(
      updateStage({
        id,
        is_process: process ? null : 1
      })
    )
  }

  const handleChangeFinal = (id, final) => {
    dispatch(
      updateStage({
        id,
        is_finalist: final ? null : 1
      })
    )
  }

  const handleChangeAccept = (id, accept) => {
    dispatch(
      updateStage({
        id,
        is_accepted: accept ? null : 1
      })
    )
  }
  const handleChangeShowCheckDoc = (id, check) => {
    dispatch(
      updateStage({
        id,
        is_candidate_show_check_doc: check ? null : 1
      })
    )
  }

  const handleChangeType = (id, value) => {
    dispatch(
      updateStage({
        id,
        type: value
      })
    )
  }
  const handleChangeLimitTransfer = async (id, value, index) => {
    const isValid = await trigger(`stages.${index}.limit_on_day`)

    if (isValid) {
      dispatch(
        updateStage({
          id,
          limit_transfer: value
        })
      )
    }
  }

  const handleChangeNextStage = (id, status) => {
    dispatch(
      updateStageState({
        id,
        move_to_stage: status
      })
    )
  }

  const handleChangeNextStageState = (id, status) => {
    dispatch(
      updateStageState({
        id,
        move_to_stage_state: status
      })
    )
  }

  const handleUpdateStageState = (inx, i, element) => {
    dispatch(
      updateStageState({
        id: element,
        name: watchStage(`stages[${inx}].state[${i}].name`)
      })
    )
  }

  const handleUpdateStage = (index, id) => {
    dispatch(
      updateStage({
        id,
        name: watchStage(`stages[${index}].name`)
      })
    )
  }

  const handleUpdateStageLimit = async (index, id) => {
    const isValid = await trigger(`stages.${index}.limit_on_day`)
    if (isValid) {
      dispatch(
        updateStage({
          id,
          limit_on_day: watchStage(`stages[${index}].limit_on_day`)
            ? Number(watchStage(`stages[${index}].limit_on_day`))
            : null
        })
      )
    }
  }
  const handleUpdateStageLimitation = (index, id) => {
    dispatch(
      updateStage({
        id,
        restriction: watchStage(`stages[${index}].restriction`)
      })
    )
  }

  const handleClickDropdown = (e, id) => {
    setDepartmentId(id)
    if (e.key !== 'addStage') {
      dispatch(setDropdownStageSetupKey(e.key))
    } else if (addStage.includes(id)) {
      setAddStage([])
    } else setAddStage([id])
  }

  useEffect(() => {
    dispatch(getVertices())
    dispatch(getStages())
    getTypes()
      .then((responseData) => {
        setTypes([{ value: null, label: 'Не выбрано' }, ...responseData])
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
    getLimitationOption()
      .then((responseData) => {
        setLimitationOption(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  useEffect(() => {
    setContainers(newArray)
  }, [newArray])

  return (
    <div className={mainCssClass}>
      {!dropdownStageSetupKey && (
        <>
          <Typography.Title level={3}>Настройка этапов по умолчанию</Typography.Title>
          <div className={`${mainCssClass}_list`}>
            <NewScrollContainer className={`${mainCssClass}_list_scroll`}>
              {vertices?.map((el, index) => (
                <div key={el.id} className={`${mainCssClass}_list_item`}>
                  <div className={`${mainCssClass}_list_item_main`}>
                    <div className={`${mainCssClass}_list_item_main_title-container`}>
                      {editVertices === el.id ? (
                        <>
                          <InputFormControl
                            control={verticesControl}
                            name="name"
                            defaultValue={el.name}
                            rules={{ required: true }}
                          />
                          <ControlButton className="edit-button">
                            <CheckBig onClick={() => handleClickUpdateVertices(el.id)} />
                          </ControlButton>
                          <ControlButton className="edit-button">
                            <CloseMD onClick={() => setEditVertices(null)} />
                          </ControlButton>
                        </>
                      ) : (
                        <>
                          {chooseIcons(el.id) ? (
                            <CaretUpMD onClick={() => handleClick(el.id)} />
                          ) : (
                            <CaretDownMD onClick={() => handleClick(el.id)} />
                          )}
                          <Typography.Text
                            className={`${mainCssClass}_list_item_main_title`}
                            onClick={() => handleClick(el.id)}
                          >
                            {el.name}
                          </Typography.Text>
                          <ControlButton className="edit-button" onClick={() => handleClickEditVertices(el.id)}>
                            <EditPencil1 />
                          </ControlButton>
                        </>
                      )}
                    </div>

                    <Dropdown
                      menu={{ items, selectable: true, onClick: (e) => handleClickDropdown(e, el.id) }}
                      placement="left"
                      arrow={{ pointAtCenter: true }}
                      type="ghost"
                      overlayClassName={`${mainCssClass}__dropdown-open`}
                    >
                      <div className={`${mainCssClass}__dropdown-container`}>
                        <MoreVertical />
                      </div>
                    </Dropdown>
                  </div>
                  {addStage.includes(el.id) && (
                    <div className={`${mainCssClass}_list_item_add`}>
                      <InputFormControl
                        name="name"
                        control={nameControl}
                        required
                        label="Новая запись"
                        rules={{ required: true }}
                      />
                      <ControlButton
                        className="button"
                        typeButton="secondary"
                        onClick={handleSubmitName(() => handleCreateStage(el.id))}
                      >
                        <Check />
                      </ControlButton>
                      <ControlButton className="button" onClick={() => handleAddStage(el.id)} typeButton="warning">
                        <CloseMD />
                      </ControlButton>
                    </div>
                  )}

                  {chooseIcons(el.id) && (
                    <div className={`${mainCssClass}_list_item_stage-list`}>
                      {containers[index]?.begin?.map((elem, inx) =>
                        editStage.includes(elem.id) ? (
                          <div
                            key={elem.id}
                            className={`${mainCssClass}_list_item_stage-list_block`}
                            onMouseEnter={() => setActiveStage(elem.id)}
                          >
                            <div className={`${mainCssClass}_list_item_stage-list_block_title`}>
                              <div>
                                <LockInterface />
                                {elem.name}
                              </div>
                              <div>
                                <ControlButton
                                  typeButton="secondary"
                                  className="button"
                                  onClick={() => handleClickBlockEdit(elem.id)}
                                >
                                  <EditPencil1 />
                                </ControlButton>
                              </div>
                            </div>
                            <InputFormControl
                              name={`stages[${inx}].name`}
                              control={stageNameControl}
                              defaultValue={elem.name}
                              required
                              label="Название"
                              rules={{ required: true }}
                              onBlur={handleSubmitStageName(() => handleUpdateStage(inx, elem.id))}
                            />
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch-picker`}>
                              <PickerFormControl
                                onChange={() => handleChangeColor(inx, elem.id)}
                                defaultValue={elem.color || '#2DBE64'}
                                name={`stages[${inx}].color`}
                                control={stageNameControl}
                              />
                            </div>
                          </div>
                        ) : (
                          <div key={elem.id} className={`${mainCssClass}_list_item_stage-list_block`}>
                            <div className={`${mainCssClass}_list_item_stage-list_block_title`}>
                              <div>
                                <LockInterface />
                                {elem.name}
                              </div>
                              <div>
                                <ControlButton
                                  typeButton="secondary"
                                  className="button"
                                  onClick={() => handleClickBlockEdit(elem.id)}
                                >
                                  <EditPencil1 />
                                </ControlButton>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      {containers[index]?.noBlock?.map((elem, inx) =>
                        editStage.includes(elem.id) ? (
                          <div
                            key={elem.id}
                            data-container-id={index}
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, elem.priority, index)}
                            onDragStart={(event) => handleDragStart(event, index, elem.id, elem.priority)}
                            className={`${mainCssClass}_list_item_stage-list_edit-unblocked`}
                            onMouseEnter={() => setActiveStage(elem.id)}
                          >
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_title`}>
                              <div>
                                <div style={{ width: '24px' }}>
                                  <DragVertical />
                                </div>
                                {elem.name}
                              </div>
                              <ControlButton
                                typeButton="secondary"
                                className="button"
                                onClick={() => handleClickEdit(elem.id)}
                              >
                                <EditPencil1 />
                              </ControlButton>
                            </div>
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_first`}>
                              <InputFormControl
                                name={`stages[${inx}].name`}
                                control={stageNameControl}
                                defaultValue={elem.name}
                                required
                                label="Название"
                                rules={{ required: activeStage === elem.id }}
                                onBlur={() => handleUpdateStage(inx, elem.id)}
                              />
                              <SelectFormControl
                                name={`stages[${inx}].type`}
                                control={newStageControl}
                                label="Тип этапа"
                                options={types}
                                onChange={(selectedValue) => handleChangeType(elem.id, selectedValue)}
                                defaultValue={elem.type}
                              />
                            </div>
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_first-limit`}>
                              <InputFormControl
                                name={`stages[${inx}].limit_on_day`}
                                control={stageNameControl}
                                defaultValue={elem.limit_on_day}
                                label="Лимит нахождения на этапе (дней)"
                                onBlur={() => handleUpdateStageLimit(inx, elem.id)}
                                rules={{
                                  pattern: /^[0-9]*$/
                                }}
                              />
                              {!!watchStage(`stages[${inx}].limit_on_day`) && (
                                <div
                                  className={`${mainCssClass}_list_item_stage-list_edit-unblocked_first-limit_transfer`}
                                >
                                  <Typography.Text className={`${mainCssClass}_warning`}>
                                    Внимание! При заполнении поля "Лимит нахождения на этапе" нужно выбрать этап для
                                    перевода.
                                  </Typography.Text>
                                  <SelectFormControl
                                    name={`stages[${inx}].limit_transfer`}
                                    control={stageNameControl}
                                    label="Этап для перевода"
                                    options={getStageOptions(activeStages, el.id)}
                                    onChange={(selectedValue) => handleChangeLimitTransfer(elem.id, selectedValue, inx)}
                                    defaultValue={elem.limit_transfer}
                                    rules={{
                                      required: !!watchStage(`stages[${inx}].limit_on_day`)
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <SelectFormControlMult
                              name={`stages[${inx}].restriction`}
                              control={stageNameControl}
                              label="Ограничение на этапе"
                              options={limitationOption}
                              onBlur={() => handleUpdateStageLimitation(inx, elem.id)}
                              defaultValue={elem.restriction}
                            />
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_first`}>
                              <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch`}>
                                <SwitchFormControl
                                  control={stageNameControl}
                                  name={`stages[${inx}].is_process`}
                                  defaultChecked={Boolean(elem.is_process)}
                                  onChange={() => handleChangeProcess(elem.id, elem.is_process)}
                                />
                                <Typography.Text>Этап в процессе</Typography.Text>
                              </div>
                              <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch`}>
                                <SwitchFormControl
                                  control={stageNameControl}
                                  name={`stages[${inx}].is_finalist`}
                                  defaultChecked={Boolean(elem.is_finalist)}
                                  onChange={() => handleChangeFinal(elem.id, elem.is_finalist)}
                                />
                                <Typography.Text>Финальный этап</Typography.Text>
                              </div>
                              <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch`}>
                                <SwitchFormControl
                                  control={stageNameControl}
                                  name={`stages[${inx}].is_accepted`}
                                  defaultChecked={Boolean(elem.is_accepted)}
                                  onChange={() => handleChangeAccept(elem.id, elem.is_accepted)}
                                />
                                <Typography.Text>Завершающий этап</Typography.Text>
                              </div>
                            </div>
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch`}>
                              <SwitchFormControl
                                control={stageNameControl}
                                name={`stages[${inx}].is_candidate_show_check_doc`}
                                defaultChecked={Boolean(elem.is_candidate_show_check_doc)}
                                onChange={() => handleChangeShowCheckDoc(elem.id, elem.is_candidate_show_check_doc)}
                              />
                              <Typography.Text>Может проверять документы</Typography.Text>
                            </div>
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch`}>
                              <SwitchFormControl control={stageNameControl} name={`stages[${inx}].stages`} />
                              <Typography.Text>Добавить статус на этапе</Typography.Text>
                            </div>
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch-picker`}>
                              <PickerFormControl
                                onChange={() => handleChangeColor(inx, elem.id)}
                                defaultValue={elem.color || '#2DBE64'}
                                name={`stages[${inx}].color`}
                                control={stageNameControl}
                              />
                            </div>
                            {stageState.map((el) => {
                              if (el.id === elem.id) {
                                return el.stages.map((element, i) => (
                                  <div
                                    key={element.id}
                                    className={`${mainCssClass}_list_item_stage-list_edit-unblocked_first`}
                                  >
                                    <InputFormControl
                                      label="Статус"
                                      required
                                      name={`stages[${inx}].state[${i}].name`}
                                      control={stageNameControl}
                                      defaultValue={element.name}
                                      onBlur={handleSubmitStageName(() => handleUpdateStageState(inx, i, element.id))}
                                      rules={{ required: true }}
                                    />
                                    <SelectFormControl
                                      name={`stages[${inx}].state[${i}].stage`}
                                      control={stageNameControl}
                                      label="Перевести на этап"
                                      options={[{ label: 'Не выбрано', value: null }, ...stagesOptions[index]]}
                                      onChange={(selectedValue) => handleChangeNextStage(element.id, selectedValue)}
                                      defaultValue={element.move_to_stage}
                                    />
                                    <SelectFormControl
                                      name={`stages[${inx}].state[${i}].state`}
                                      control={stageNameControl}
                                      label="Перевести на статус на этапе"
                                      options={[
                                        { label: 'Не выбрано', value: null },
                                        ...el.stages
                                          .filter((stageState) => stageState.id !== element.id)
                                          .map((el) => ({ label: el.name, value: el.id }))
                                      ]}
                                      onChange={(selectedValue) =>
                                        handleChangeNextStageState(element.id, selectedValue)
                                      }
                                      defaultValue={element.move_to_stage_state}
                                    />

                                    {element.status ? (
                                      <ControlButton
                                        typeButton="secondary"
                                        className="button"
                                        onClick={() => handleArchiveStageState(element.id, element.status)}
                                      >
                                        <Archive />
                                      </ControlButton>
                                    ) : (
                                      <ControlButton
                                        typeButton="secondary"
                                        className="button"
                                        onClick={() => handleArchiveStageState(element.id, element.status)}
                                      >
                                        <Redo />
                                      </ControlButton>
                                    )}
                                  </div>
                                ))
                              }
                            })}
                            {watchStage(`stages[${inx}].stages`) && (
                              <InputFormControl
                                label="Статус"
                                required
                                name={`stages[${inx}].newState`}
                                control={newStageControl}
                                onBlur={handleSubmitNewStage(() => handleAddStageState(inx, elem.id))}
                                rules={{ required: true }}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key={elem.id}
                            data-container-id={index}
                            className={`${mainCssClass}_list_item_stage-list_edit`}
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, elem.priority, index)}
                            draggable
                            onDragStart={(event) => handleDragStart(event, index, elem.id, elem.priority)}
                          >
                            <div>
                              <div style={{ width: '24px' }}>
                                <DragVertical />
                              </div>{' '}
                              {elem.name}
                            </div>
                            <div>
                              {Boolean(elem.status) && (
                                <>
                                  <ControlButton
                                    typeButton="secondary"
                                    className="button"
                                    onClick={() => handleClickEdit(elem.id)}
                                  >
                                    <EditPencil1 />
                                  </ControlButton>
                                  <ControlButton
                                    typeButton="secondary"
                                    className="button"
                                    onClick={() => handleArchive(elem.id)}
                                  >
                                    <Archive />
                                  </ControlButton>
                                </>
                              )}
                              {Boolean(elem.status) === false && (
                                <ControlButton
                                  typeButton="secondary"
                                  className="button"
                                  onClick={() => handleReturnArchive(elem.id)}
                                >
                                  <Redo />
                                </ControlButton>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      {containers[index]?.end?.map((elem, inx) =>
                        editStage.includes(elem.id) ? (
                          <div
                            key={elem.id}
                            className={`${mainCssClass}_list_item_stage-list_block`}
                            onMouseEnter={() => setActiveStage(elem.id)}
                          >
                            <div className={`${mainCssClass}_list_item_stage-list_block_title`}>
                              <div>
                                <LockInterface />
                                {elem.name}
                              </div>
                              <div>
                                <ControlButton
                                  typeButton="secondary"
                                  className="button"
                                  onClick={() => handleClickBlockEdit(elem.id)}
                                >
                                  <EditPencil1 />
                                </ControlButton>
                              </div>
                            </div>
                            <InputFormControl
                              name={`stages[${inx}].name`}
                              control={stageNameControl}
                              defaultValue={elem.name}
                              required
                              label="Название"
                              rules={{ required: true }}
                              onBlur={handleSubmitStageName(() => handleUpdateStage(inx, elem.id))}
                            />
                            <div className={`${mainCssClass}_list_item_stage-list_edit-unblocked_switch-picker`}>
                              <PickerFormControl
                                onChange={() => handleChangeColor(inx, elem.id)}
                                defaultValue={elem.color || '#2DBE64'}
                                name={`stages[${inx}].color`}
                                control={stageNameControl}
                              />
                            </div>
                          </div>
                        ) : (
                          <div key={elem.id} className={`${mainCssClass}_list_item_stage-list_block`}>
                            <div className={`${mainCssClass}_list_item_stage-list_block_title`}>
                              <div>
                                <LockInterface />
                                {elem.name}
                              </div>
                              <div>
                                <ControlButton
                                  typeButton="secondary"
                                  className="button"
                                  onClick={() => handleClickBlockEdit(elem.id)}
                                >
                                  <EditPencil1 />
                                </ControlButton>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </NewScrollContainer>
          </div>
        </>
      )}

      {dropdownStageSetupKey && <CandidateConstructor department_id={departmentId} />}
    </div>
  )
}

export default memo(withErrorBoundary(Example))
