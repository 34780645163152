import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

function InterviewEvent({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#D0FAEF" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2929 10.2929C26.6834 9.90237 27.3166 9.90237 27.7071 10.2929C28.4566 11.0424 29.0511 11.9321 29.4567 12.9113C29.8623 13.8906 30.071 14.9401 30.071 15.9999C30.071 17.0598 29.8622 18.1094 29.4566 19.0887C29.051 20.0678 28.4566 20.9576 27.7071 21.7072C27.3166 22.0977 26.6834 22.0977 26.2929 21.7072C25.9024 21.3167 25.9024 20.6835 26.2929 20.293C26.8566 19.7293 27.3037 19.06 27.6088 18.3233C27.9139 17.5867 28.071 16.7972 28.071 15.9999C28.071 15.2026 27.914 14.4132 27.6089 13.6767C27.3038 12.9401 26.8566 12.2709 26.2929 11.7071C25.9024 11.3166 25.9024 10.6834 26.2929 10.2929ZM17 13C15.3431 13 14 14.3431 14 16C14 17.6569 15.3431 19 17 19C18.6569 19 20 17.6569 20 16C20 14.3431 18.6569 13 17 13ZM12 16C12 13.2386 14.2386 11 17 11C19.7614 11 22 13.2386 22 16C22 18.7614 19.7614 21 17 21C14.2386 21 12 18.7614 12 16ZM24.121 12.4645C24.5115 12.074 25.1447 12.074 25.5352 12.4645C25.9995 12.9288 26.3678 13.48 26.6191 14.0866C26.8703 14.6931 26.9998 15.3432 26.9998 16C26.9998 16.6566 26.8705 17.3068 26.6192 17.9134C26.3679 18.5201 25.9995 19.0713 25.5352 19.5356C25.1447 19.9261 24.5115 19.9261 24.121 19.5356C23.7305 19.145 23.7305 18.5119 24.121 18.1213C24.3997 17.8427 24.6207 17.5119 24.7714 17.1481C24.9222 16.7841 24.9998 16.3939 24.9998 16C24.9998 15.6062 24.9222 15.2161 24.7713 14.852C24.6206 14.488 24.3996 14.1573 24.121 13.8787C23.7305 13.4882 23.7305 12.855 24.121 12.4645ZM12.2027 23.3395C13.4742 22.4918 15.1733 22 17 22C18.8267 22 20.5258 22.4918 21.7973 23.3395C23.0619 24.1826 24 25.457 24 27C24 27.5523 23.5523 28 23 28C22.4477 28 22 27.5523 22 27C22 26.3338 21.5949 25.6083 20.6879 25.0036C19.7879 24.4036 18.487 24 17 24C15.513 24 14.2121 24.4036 13.3121 25.0036C12.4051 25.6083 12 26.3338 12 27C12 27.5523 11.5523 28 11 28C10.4477 28 10 27.5523 10 27C10 25.457 10.9381 24.1826 12.2027 23.3395Z" fill="#0F6E55" />
    </svg>

  )
}
export default InterviewEvent
