import axios, { AxiosResponse } from 'axios'
import $api from './http'
import {
  TRequestCreateTask, TRequestTasks, TRequestUpdateTask, TTask, TTasks
} from '../../compositions/Tasks'

export type TTasksResponse =
{
  ERR: boolean,
  DATA: TTasks[]
}

export async function fetchGetTasks(payload:TRequestTasks): Promise<AxiosResponse<TTasksResponse>> {
  const response = await $api.get(`/api/v1/task/task-list?page=1&per_page=1000${payload.filter || ''}${payload.is_archive ? '&is_archive=1' : ''}`)
  return response
}

export async function fetchPostCreateTasks(payload:TRequestCreateTask): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/tasks', payload)
  return response
}

export async function fetchPatchUpdateTask(payload:TRequestUpdateTask): Promise<AxiosResponse<any>> {
  const response = await $api.patch(`/api/v1/tasks/${payload.task_id}`, payload)
  return response
}

export async function fetchGetTask(payload:string): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/tasks/${payload}`)
  return response
}