import React, { useEffect, useRef } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useController, UseControllerProps } from 'react-hook-form'
import { DebounceInput } from 'react-debounce-input'
import cn from 'classnames'
import { Input, InputProps, Tooltip } from 'antd'
import { getCssClassName } from '../../utils/generateClassName'
import Search from '../../svg/Search'
import './InputSearchFormControl.scss'
import CloseSM from '../../svg/CloseSM'

type TControlInputProps = Omit<
  UseControllerProps &
    InputProps & {
      className?: string
      size?: 'small' | 'middle' | 'large'
      label?: string
      index?: number
      required?: boolean
  isRequired?: boolean
  handleDelete?: () => void
    },
  'isDisabled'
>

const mainCssClass = getCssClassName('global-search-without-debounce')

function InputSearchFormControl({
  name,
  control,
  rules,
  className,
  size = 'large',
  placeholder,
  handleDelete,
  ...rest
}: TControlInputProps): JSX.Element {
  const { field, fieldState } = useController({ name, control, rules })

  const inputElementRef = useRef(null)

  useEffect(() => {
    // Перемещаем курсор в конец input после установки фокуса
    if (inputElementRef.current && field.value) {
      inputElementRef.current.focus()
      if (inputElementRef.current.value) {
        inputElementRef.current.selectionStart = inputElementRef.current.selectionEnd = inputElementRef.current.value.length
      }
    }
  }, [field.value])
  const handleCLick = () => {
    if (handleDelete) {
      handleDelete()
    }
    field.onChange('')
  }
  return (
    <div
      className={cn(
        className,
        mainCssClass,
        { inputLarge: size === 'large' },
        { inputMiddle: size === 'middle' },
        { inputSmall: size === 'small' },
        { error: fieldState.error }
      )}
    >
      <Search />
      <Input
        ref={inputElementRef}
        size={size}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        placeholder={placeholder}
        {...rest}
      />
      {field.value && <CloseSM onClick={handleCLick} />}
    </div>
  )
}

export default InputSearchFormControl
