import React, { memo, useEffect } from 'react'
import { ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import {
  openMergeDuplicateModal
} from '../actions'
import { selectMergeDuplicateList } from '../selectors'
import User02 from '../../../common/svg/User02'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './MergeDuplicateModal.scss'
import { selectLoadingModal } from '../../InnerRouter/selectors'

const mainCssClass = getCssClassName('merge-duplicate-modal')

export type TProps = {
  candidateId?: number
} & ModalProps

type TFormValues = {
  comment: string
}

function MergeDuplicateModal({
  open, className
}: TProps) {
  const dispatch = useDispatch()
  const mergeDuplicateList = useSelector(selectMergeDuplicateList)
  const loadingModal = useSelector(selectLoadingModal)

  const handleClickCancel = () => {
    dispatch(openMergeDuplicateModal(false))
  }

  return (
    <CommonModal
      open={open}
      //   onOk={handleSubmit(onSubmit)}
      onCancel={handleClickCancel}
      title="Слитые дубли кандидата"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      //   okText= {userInfo?.role?.role_id ==='recruitment-agency' ? null :"Объединить"}
      centered
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      okButtonProps={{ style: { display: 'none' } }}
    >

      <div className={`${mainCssClass}_list`}>

        {loadingModal && <Preloader size="md" /> }
        {!!mergeDuplicateList.length && !loadingModal
        && mergeDuplicateList.map((el, i) => (
          <div key={i} className={`${mainCssClass}_list_item`}>

            <User02 />
            <a href={`/candidate/${el.candidate_id}`} target="_blank" rel="noreferrer">
              {el.candidate_label}
            </a>
            <div className={`${mainCssClass}_list_item_main`}>
              <span className={`${mainCssClass}_list_item_main_id`}>
                {el.is_main === 1 ? '(основной)' : '(побочный)'}
              </span>
            </div>
          </div>
        ))}
      </div>

    </CommonModal>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(MergeDuplicateModal))
