import { createSelector } from '@reduxjs/toolkit'
import { TRootState } from '../../common/store/rootReducer'
import excel from '../../common/svg/Excel'
import { log } from '@rspack/core/compiled/neo-async'

const selectState = ({ autoCall }: TRootState) => autoCall

export const selectAutoCalls = createSelector(selectState, ({ autoCalls }) => autoCalls)
export const selectLoading = createSelector(selectState, ({ loading }) => loading)

export const selectSelectedAutoCall = createSelector(selectState, ({ selectedAutoCall }) => selectedAutoCall)

export const selectWhoCallsOptions = createSelector(selectState, ({ usersWhoCalls }) => {
  const uniqueUsers: any[] = []
  const seenIds = new Set()

  usersWhoCalls.forEach((user) => {
    if (!seenIds.has(user.value)) {
      seenIds.add(user.value)
      uniqueUsers.push(user)
    }
  })
  return uniqueUsers
})
export const selectWhoCallsGroupOptions = createSelector(selectState, ({ usersWhoCallsGroupOptions }) => usersWhoCallsGroupOptions)
