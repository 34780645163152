import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  Button, Typography, Input, Radio, Tooltip
} from 'antd'
import type { RadioChangeEvent } from 'antd'
import { useParams } from 'react-router-dom'
import CloseMD from '../../../common/svg/CloseMD'
import {
  fetchCitiesList,
  fetchClientList,
  fetchRecruiterList,
  fetchRequestList,
  fetchResearcherList,
  fetchResponsibleList,
  fetchStoreList,
  fetchTagsList,
  fetchVacancyList,
  filteredOptions,
  notFoundContentClient,
  objectToQueryString
} from '../utils'
import { getCssClassName } from '../../../common/utils/generateClassName'
import CaretDownMD from '../../../common/svg/CaretDownMD'
import CaretUpMD from '../../../common/svg/CaretUpMD'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import Search from '../../../common/svg/Search'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import {
  getDictionaries, getFilter, getFilters, OpenFilterMobil, openFilters, reset, setDefFilters, setFilters
} from '../actions'
import { selectDefFilters, selectDictionaryOptionsByName, selectFilterMobil } from '../selectors'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import Redo from '../../../common/svg/Redo'
import DebounceSelectFormControlMult from '../../../common/components/formcontrol/DebounceSelectFormControlMult'
import ScrollContainer from '../../../common/components/ScrollContainer/ScrollContainer'
import RadioGroupFormControl from '../../../common/components/formcontrol/RadioFormControl'
import ControlButton from '../../../common/components/Buttons/Button'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import './FiltersMobil.scss'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { TBaseUrl } from '../../../compositions/InnerRouter/types'
import { selectBaseUrl } from '../../../compositions/InnerRouter/selectors'

type TFormValues = {
    citizenship: number[]
    date_begin_at: string[] | null
    stage_id: number[]
    status: number[]
    date_registration: string[] | null
    date_internship: string[] | null
    leadgen_source_id: number[]
    priority_id: number[]
    reject_id: number[]
    researcher: number[]
    responsible: number[]
    customer: number[]
    vacancy_id: number[]
    city: string[]
    store_id: (number | string)[]
    type_registration: number[]
    recruiter: number[]
    result: number[]
    confirm: boolean[]
    date: string | null
    request_id: number[]
    group: number[]
    stage_state: number[]
    tag: any[]
}

type TFiltersProps = {
    candidate?: boolean
    request?: boolean
    vacancy?: boolean
    vacancyPage?: boolean
    interview?: boolean
}

const mainCssClass = getCssClassName('filters-mobil')

function FiltersMobil({
  candidate, request, vacancy, vacancyPage, interview
}: TFiltersProps) {
  const dispatch = useDispatch()

  const { pageId } = useParams()
  const defFilters = useSelector(selectDefFilters)
  const baseUrl: TBaseUrl | null = useSelector(selectBaseUrl)
  const targetUrl: TBaseUrl = baseUrl || pageId || 'candidates'

  const defaultValues = useMemo(() => (pageId === defFilters?.type ? defFilters?.filter : {}), [defFilters, pageId])

  const {
    control, watch, getValues, handleSubmit, setValue, reset, resetField
  } = useForm<TFormValues>({ defaultValues })

  const [stage, setStage] = useState(false)
  const [citizenship, setCitizenship] = useState(false)
  const [statusCandidate, setStatusCandidate] = useState(false)
  const [workSites, setWorkSites] = useState(false)
  const [rejReas, setRejReas] = useState(false)
  const [cities, setSities] = useState(false)
  const [sub, setSub] = useState(false)
  const [intership, setIntership] = useState(false)
  const [begin, setBegin] = useState(false)
  const [priority, setPriority] = useState(false)
  const [registrType, setRegistrType] = useState(false)
  const [recruiter, setRecruiter] = useState(false)
  const [researcher, setResearcher] = useState(false)
  const [client, setClient] = useState(false)
  const [vacancyContent, setVacancyContent] = useState(false)
  const [store, setStore] = useState(false)
  const [responsible, setResponsible] = useState(false)
  const [statusVacancy, setStatusVacancy] = useState(false)
  const [recriuterContent, setRecriuterContent] = useState(false)
  const [interviewStatus, setInterviewStatus] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [interviewResult, setInterviewResult] = useState(false)
  const [date, setDate] = useState(false)
  const [requestContent, setRequestContent] = useState(false)
  const [vacancyCandidate, setVacancyCandidate] = useState(false)
  const [group, setGroup] = useState(false)
  const [statusStage, setStatusStage] = useState(false)
  const [tag, setTag] = useState(false)

  const [citizenshipInput, setCitizenshipInput] = useState('')
  const [stageInput, setStageInput] = useState('')
  const [statusCandidateInput, setStatusCandidateInput] = useState('')
  const [workSitesInput, setWorkSitesInput] = useState('')
  const [rejReasInput, setRejReasInput] = useState('')
  const [statusStageInput, setStatusStageInput] = useState('')
  const [priorityInput, setPriorityInput] = useState('')
  const [registrTypeInput, setRegistrTypeInput] = useState('')
  const [statusVacancyInput, setStatusVacancyInput] = useState('')
  const [interviewStatusInput, setInterviewStatusInput] = useState('')
  const [interviewResultInput, setInterviewResultInput] = useState('')
  const [groupInput, setGroupInput] = useState('')

  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const candidateStatusOptions = useSelector(selectDictionaryOptionsByName('candidateStatus'))
  const stageStatusOptions = useSelector(selectDictionaryOptionsByName('stageStatus'))
  const workSitesStatusOptions = useSelector(selectDictionaryOptionsByName('WorkSites'))
  const reasonOptions = useSelector(selectDictionaryOptionsByName('rejectionReason'))
  const registrOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))
  const statusStageOptions = useSelector(selectDictionaryOptionsByName('stageState'))
  const priorityOptions = useSelector(selectDictionaryOptionsByName('priority'))
  const vacancyStatusOptions = useSelector(selectDictionaryOptionsByName('vacancyStatus'))
  const interviewStatusOptions = useSelector(selectDictionaryOptionsByName('statusResult'))
  const interviewResultOptions = useSelector(selectDictionaryOptionsByName('interviewResult'))
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const filterMobil = useSelector(selectFilterMobil)
  const confirmOptions = [
    { label: 'Подтверждено', value: 'confirmed' },
    { label: 'Не подтверждено', value: 'unconfirmed' }
  ]

  const [subValue, setSubValue] = useState<number | undefined>(undefined)
  const [intershipValue, setIntershipValue] = useState<number | undefined>(undefined)
  const [beginValue, setBeginValue] = useState<number | undefined>(undefined)
  const [dateValue, setDateValue] = useState<number | undefined>(undefined)

  const handleApply = useCallback(() => {
    dispatch(getFilters({ filter: getValues(), isEventFunnel: targetUrl === 'event-funnel' }))
    dispatch(setDefFilters({ type: pageId, filter: getValues(), defFilterString: objectToQueryString(getValues(), targetUrl === 'event-funnel') }))
  }, [])

  const handleAllReset = useCallback(() => {
    const mapValues = getValues()

    if (defaultValues) {
      // eslint-disable-next-line prefer-const
      for (let key in mapValues) {
        if (mapValues[key] === 'date_registration' || mapValues[key] === 'date_internship') {
          reset({ date_registration: null })
          setSubValue(undefined)
          reset({ date_internship: null })
          setIntershipValue(undefined)
          reset({ date_begin_at: null })
          setBeginValue(undefined)
        } else {
          reset({ key: [] })
        }
      }
    }
    reset({})
  }, [defaultValues])

  const onChangeReg = (e: RadioChangeEvent) => {
    setSubValue(e.target.value)

    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      setValue('date_registration', [weekAgo, currentDate])
    } else if (e.target.value === 2) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      setValue('date_registration', [weekAgo, currentDate])
    } else if (e.target.value === 3) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'y').format('YYYY-MM-DD')
      setValue('date_registration', [weekAgo, currentDate])
    } else {
      setValue('date_registration', null)
    }
  }

  const onChangeIntership = (e: RadioChangeEvent) => {
    setIntershipValue(e.target.value)

    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      setValue('date_internship', [weekAgo, currentDate])
    } else if (e.target.value === 2) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      setValue('date_internship', [weekAgo, currentDate])
    } else if (e.target.value === 3) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'y').format('YYYY-MM-DD')
      setValue('date_internship', [weekAgo, currentDate])
    } else {
      setValue('date_internship', null)
    }
  }

  const onChangeBegin = (e: RadioChangeEvent) => {
    setBeginValue(e.target.value)

    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      setValue('date_begin_at', [weekAgo, currentDate])
    } else if (e.target.value === 2) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      setValue('date_begin_at', [weekAgo, currentDate])
    } else if (e.target.value === 3) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      const weekAgo = dayjs().subtract(1, 'y').format('YYYY-MM-DD')
      setValue('date_begin_at', [weekAgo, currentDate])
    } else {
      setValue('date_begin_at', null)
    }
  }

  const onChangeDate = (e: RadioChangeEvent) => {
    setDateValue(e.target.value)

    if (e.target.value === 1) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      setValue('date', currentDate)
    } else if (e.target.value === 2) {
      const dayAgo = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      setValue('date', dayAgo)
    } else {
      setValue('date', null)
    }
  }

  const handleCloseFilters = useCallback(() => {
    dispatch(openFilters(false))
    dispatch(setFilters(''))
    dispatch(setDefFilters(null))
  }, [])

  const handleCitizenshipContent = useCallback(() => {
    setCitizenship((prev) => !prev)
  }, [])

  const handleDate = useCallback(() => {
    setDate((prev) => !prev)
  }, [])

  const handleRequest = useCallback(() => {
    setRequestContent((prev) => !prev)
  }, [])

  const handleStageContent = useCallback(() => {
    setStage((prev) => !prev)
  }, [])

  const handleVacancyCandidate = useCallback(() => {
    setVacancyCandidate((prev) => !prev)
  }, [])

  const handleInterviewResult = useCallback(() => {
    setInterviewResult((prev) => !prev)
  }, [])

  const handleInterviewStatus = useCallback(() => {
    setInterviewStatus((prev) => !prev)
  }, [])
  const handleStatusVacancy = useCallback(() => {
    setStatusVacancy((prev) => !prev)
  }, [])

  const handleResponsible = useCallback(() => {
    setResponsible((prev) => !prev)
  }, [])

  const handleTags = useCallback(() => {
    setTag((prev) => !prev)
  }, [])

  const handleStore = useCallback(() => {
    setStore((prev) => !prev)
  }, [])

  const handleRecruiterContent = useCallback(() => {
    setRecriuterContent((prev) => !prev)
  }, [])

  const handleConfirm = useCallback(() => {
    setConfirm((prev) => !prev)
  }, [])

  const handleStatusCandidate = useCallback(() => {
    setStatusCandidate((prev) => !prev)
  }, [])

  const handleWorkSites = useCallback(() => {
    setWorkSites((prev) => !prev)
  }, [])

  const handleRejReas = useCallback(() => {
    setRejReas((prev) => !prev)
  }, [])

  const handleStatusStage = useCallback(() => {
    setStatusStage((prev) => !prev)
  }, [])

  const handleClient = useCallback(() => {
    setClient((prev) => !prev)
  }, [])

  const handleVacancyContent = useCallback(() => {
    setVacancyContent((prev) => !prev)
  }, [])

  const handleCities = useCallback(() => {
    setSities((prev) => !prev)
  }, [])

  const handleSub = useCallback(() => {
    setSub((prev) => !prev)
  }, [])

  const handleIntership = useCallback(() => {
    setIntership((prev) => !prev)
  }, [])

  const handleGroup = useCallback(() => {
    setGroup((prev) => !prev)
  }, [])

  const handleBegin = useCallback(() => {
    setBegin((prev) => !prev)
  }, [])

  const handleRegistrType = useCallback(() => {
    setRegistrType((prev) => !prev)
  }, [])

  const handlePriority = useCallback(() => {
    setPriority((prev) => !prev)
  }, [])

  const handleResearcher = useCallback(() => {
    setResearcher((prev) => !prev)
  }, [])

  const handleRecruiter = useCallback(() => {
    setRecruiter((prev) => !prev)
  }, [])

  const handleCitizenshipInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCitizenshipInput(e.target.value)
  }

  const handleStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStageInput(e.target.value)
  }

  const handleGroupInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setGroupInput(e.target.value)
  }

  const handleInterviewResultInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInterviewResultInput(e.target.value)
  }
  const handleStatusCandidateInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusCandidateInput(e.target.value)
  }
  const handleWorkSitesInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setWorkSitesInput(e.target.value)
  }

  const handleRejReasInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRejReasInput(e.target.value)
  }

  const handleStatusStageInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusStageInput(e.target.value)
  }
  const handleInterviewStatusInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInterviewStatusInput(e.target.value)
  }

  const handleStatusVacancyInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatusVacancyInput(e.target.value)
  }

  const handleRegistrTypeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setRegistrTypeInput(e.target.value)
  }

  const handlePriorityInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPriorityInput(e.target.value)
  }

  const resetCity = () => {
    reset({ city: [] })
  }

  const resetСitizenship = () => {
    reset({ citizenship: [] })
  }

  const resetResult = () => {
    reset({ result: [] })
  }

  const resetRecruiter = () => {
    reset({ recruiter: [] })
  }

  const resetCustomer = () => {
    reset({ customer: [] })
  }

  const resetRequest = () => {
    reset({ request_id: [] })
  }
  const resetDateBegin = () => {
    reset({ date_begin_at: null })
    setBeginValue(undefined)
  }
  const resetDateInternship = () => {
    reset({ date_internship: null })
    setIntershipValue(undefined)
  }
  const resetDateRegistration = () => {
    reset({ date_registration: null })
    setSubValue(undefined)
  }
  const resetLeadgen = () => {
    reset({ leadgen_source_id: [] })
  }
  const resetPriority = () => {
    reset({ priority_id: [] })
  }

  const resetReject = () => {
    reset({ reject_id: [] })
  }
  const resetResearcher = () => {
    reset({ researcher: [] })
  }

  const resetResponsible = () => {
    reset({ responsible: [] })
  }

  const resetStage = () => {
    reset({ stage_id: [] })
  }

  const resetStatus = () => {
    reset({ status: [] })
  }

  const resetConfirm = () => {
    resetField('confirm')
  }
  const resetStore = () => {
    reset({ store_id: [] })
  }
  const resetGroup = () => {
    reset({ group: [] })
  }
  const resetRegistration = () => {
    reset({ type_registration: [] })
  }

  const resetVacancy = () => {
    reset({ vacancy_id: [] })
  }

  const resetDate = () => {
    resetField('date')
    setDateValue(undefined)
  }

  const resetStageState = () => {
    reset({ stage_state: [] })
  }

  const resetTags = () => {
    reset({ tag: [] })
  }

  const handleModalClose = () => {
    dispatch(OpenFilterMobil(false))
  }

  useEffect(() => {
    dispatch(
      getDictionaries([
        'stageStatus',
        'gender',
        'appendType',
        'currency',
        'schedule',
        'WorkSites',
        'rejectionReason',
        'candidateStatus',
        'yes_no',
        'citizenship',
        'priority',
        'transferTime',
        'educationLevel',
        'region',
        'employment',
        'typeRegistrationWork',
        'vacancyStatus',
        'statusResult',
        'interviewResult',
        'group',
        'stageState'
      ])
    )
  }, [])

  useEffect(
    () => () => {
      dispatch(setFilters(''))
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={filterMobil}
      onCancel={handleModalClose}
      closable
      className={mainCssClass}
      width="100%"
    >

      <div className={mainCssClass}>
        <NewScrollContainer className={`${mainCssClass}_scroll`}>
          <div className={`${mainCssClass}_content`}>
            <div className={`${mainCssClass}_title`}>
              <div className={`${mainCssClass}_title_redo`}>
                <Typography.Title level={3}>Фильтры</Typography.Title>
                <Tooltip placement="bottomRight" title="Очистит все поля">
                  <ControlButton>
                    <Redo onClick={handleAllReset} />
                  </ControlButton>
                </Tooltip>
              </div>
              <ControlButton onClick={handleModalClose}>
                <CloseMD />
              </ControlButton>
            </div>
            {(candidate || vacancyPage) && (
              <>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Ответственный рекрутер</Typography.Title>
                      {watch('responsible') && watch('responsible').length > 0 && <Redo onClick={resetResponsible} tooltip />}
                    </div>
                    {!recruiter && (
                      <ControlButton onClick={handleRecruiter}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {recruiter && (
                      <ControlButton onClick={handleRecruiter}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {recruiter && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="responsible"
                          control={control}
                          mode="multiple"
                          label="Рекрутер"
                          placeholder="Поиск по имени..."
                          notFoundContent="Начните вводить имя"
                          fetchOptions={fetchRecruiterList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Город</Typography.Title>

                      {watch('city') && watch('city').length > 0 && <Redo onClick={resetCity} tooltip />}
                    </div>
                    {!cities && (
                      <ControlButton onClick={handleCities}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {cities && (
                      <ControlButton onClick={handleCities}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {cities && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="city"
                          control={control}
                          mode="multiple"
                          label="Город"
                          placeholder="Поиск по наименованию..."
                          fetchOptions={fetchCitiesList}
                          notFoundContent="Начните вводить город"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Тэги</Typography.Title>

                      {watch('tag') && watch('tag').length > 0 && <Redo onClick={resetTags} tooltip />}
                    </div>
                    {!tag && (
                      <ControlButton onClick={handleTags}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {tag && (
                      <ControlButton onClick={handleTags}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {tag && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="tag"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по наименованию..."
                          label="Тэг"
                          fetchOptions={fetchTagsList}
                          notFoundContent="Начните вводить тэг"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Заявка</Typography.Title>
                      {watch('request_id') && watch('request_id').length > 0 && <Redo onClick={resetRequest} tooltip />}
                    </div>
                    {!requestContent && (
                      <ControlButton onClick={handleRequest}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {requestContent && (
                      <ControlButton onClick={handleRequest}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {requestContent && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="request_id"
                          control={control}
                          placeholder="Поиск по наименованию..."
                          mode="multiple"
                          label="Заявка"
                          fetchOptions={fetchRequestList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Вакансия</Typography.Title>
                      {watch('vacancy_id') && watch('vacancy_id').length > 0 && <Redo onClick={resetVacancy} tooltip />}
                    </div>
                    {!vacancyCandidate && (
                      <ControlButton onClick={handleVacancyCandidate}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {vacancyCandidate && (
                      <ControlButton onClick={handleVacancyCandidate}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {vacancyCandidate && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="vacancy_id"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по наименованию..."
                          label="Вакансия"
                          fetchOptions={fetchVacancyList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Ответственный ресечер</Typography.Title>
                      {watch('researcher') && watch('researcher').length > 0 && <Redo onClick={resetResearcher} tooltip />}
                    </div>
                    {!researcher && (
                      <ControlButton onClick={handleResearcher}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {researcher && (
                      <ControlButton onClick={handleResearcher}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {researcher && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="researcher"
                          notFoundContent="Начните вводить имя"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по имени..."
                          label="Ресечер"
                          fetchOptions={fetchResearcherList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Гражданство</Typography.Title>
                      {watch('citizenship') && watch('citizenship').length > 0 && <Redo onClick={resetСitizenship} tooltip />}
                    </div>
                    {!citizenship && (
                      <ControlButton onClick={handleCitizenshipContent}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {citizenship && (
                      <ControlButton onClick={handleCitizenshipContent}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {citizenship && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={citizenshipInput}
                          onChange={handleCitizenshipInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(citizenshipOptions, citizenshipInput)}
                          control={control}
                          name="citizenship"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Этап</Typography.Title>
                      {watch('stage_id') && watch('stage_id').length > 0 && <Redo onClick={resetStage} tooltip />}
                    </div>
                    {!stage && (
                      <ControlButton onClick={handleStageContent}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {stage && (
                      <ControlButton onClick={handleStageContent}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {stage && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={stageInput}
                          onChange={handleStageInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(stageStatusOptions, stageInput)}
                          control={control}
                          name="stage_id"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Статус кандидата</Typography.Title>
                      {watch('status') && watch('status').length > 0 && <Redo onClick={resetStatus} tooltip />}
                    </div>
                    {!statusCandidate && (
                      <ControlButton onClick={handleStatusCandidate}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {statusCandidate && (
                      <ControlButton onClick={handleStatusCandidate}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {statusCandidate && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={statusCandidateInput}
                          onChange={handleStatusCandidateInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(candidateStatusOptions, statusCandidateInput)}
                          control={control}
                          name="status"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Cтатус на этапе</Typography.Title>
                      {watch('stage_state') && watch('stage_state').length > 0 && <Redo onClick={resetStageState} tooltip />}
                    </div>
                    {!statusStage && (
                      <ControlButton onClick={handleStatusStage}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {statusStage && (
                      <ControlButton onClick={handleStatusStage}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {statusStage && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={statusStageInput}
                          onChange={handleStatusStageInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(statusStageOptions, statusStageInput)}
                          control={control}
                          name="stage_state"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Источник</Typography.Title>
                      {watch('leadgen_source_id') && watch('leadgen_source_id').length > 0 && (
                        <Redo onClick={resetLeadgen} tooltip />
                      )}
                    </div>
                    {!workSites && (
                      <ControlButton onClick={handleWorkSites}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {workSites && (
                      <ControlButton onClick={handleWorkSites}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {workSites && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={workSitesInput}
                          onChange={handleWorkSitesInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(workSitesStatusOptions, workSitesInput)}
                          control={control}
                          name="leadgen_source_id"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Причины отказа</Typography.Title>
                      {watch('reject_id') && watch('reject_id').length > 0 && <Redo onClick={resetReject} tooltip />}
                    </div>
                    {!rejReas && (
                      <ControlButton onClick={handleRejReas}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {rejReas && (
                      <ControlButton onClick={handleRejReas}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {rejReas && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={rejReasInput}
                          onChange={handleRejReasInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(reasonOptions, rejReasInput)}
                          control={control}
                          name="reject_id"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Вид оформления</Typography.Title>
                      {watch('type_registration') && watch('type_registration').length > 0 && (
                        <Redo onClick={resetRegistration} tooltip />
                      )}
                    </div>
                    {!registrType && (
                      <ControlButton onClick={handleRegistrType}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {registrType && (
                      <ControlButton onClick={handleRegistrType}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {registrType && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={registrTypeInput}
                          onChange={handleRegistrTypeInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(registrOptions, registrTypeInput)}
                          control={control}
                          name="type_registration"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>По дате оформления</Typography.Title>
                      {watch('date_registration') && watch('date_registration')?.length > 0 && (
                        <Redo onClick={resetDateRegistration} tooltip />
                      )}
                    </div>
                    {!sub && (
                      <ControlButton onClick={handleSub}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {sub && (
                      <ControlButton onClick={handleSub}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {sub && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <RangePickerFormControl
                          control={control}
                          name="date_registration"
                          format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                          label="Выберите даты"
                          className={`${mainCssClass}_items_content_range`}
                        />
                        <Radio.Group onChange={onChangeReg} value={subValue}>
                          <Radio value={1}>За последнюю неделю</Radio>
                          <Radio value={2}>За последний месяц</Radio>
                          <Radio value={3}>За последний год</Radio>
                        </Radio.Group>
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>По дате стажировки</Typography.Title>
                      {watch('date_internship') && watch('date_internship')?.length > 0 && (
                        <Redo onClick={resetDateInternship} tooltip />
                      )}
                    </div>
                    {!intership && (
                      <ControlButton onClick={handleIntership}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {intership && (
                      <ControlButton onClick={handleIntership}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {intership && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <RangePickerFormControl
                          control={control}
                          name="date_internship"
                          label="Выберите даты"
                          format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                          className={`${mainCssClass}_items_content_range`}
                        />
                        <Radio.Group onChange={onChangeIntership} value={intershipValue}>
                          <Radio value={1}>За последнюю неделю</Radio>
                          <Radio value={2}>За последний месяц</Radio>
                          <Radio value={3}>За последний год</Radio>
                        </Radio.Group>
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>По дате добавления</Typography.Title>
                      {watch('date_begin_at') && watch('date_begin_at')?.length > 0 && (
                        <Redo onClick={resetDateBegin} tooltip />
                      )}
                    </div>
                    {!begin && (
                      <ControlButton onClick={handleBegin}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {begin && (
                      <ControlButton onClick={handleBegin}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {begin && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <RangePickerFormControl
                          control={control}
                          name="date_begin_at"
                          label="Выберите даты"
                          format={[DATE_DOTS_FORMAT, DATE_DOTS_FORMAT]}
                          className={`${mainCssClass}_items_content_range`}
                        />
                        <Radio.Group onChange={onChangeBegin} value={beginValue}>
                          <Radio value={1}>За последнюю неделю</Radio>
                          <Radio value={2}>За последний месяц</Radio>
                          <Radio value={3}>За последний год</Radio>
                        </Radio.Group>
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
              </>
            )}

            {request && (
              <>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Приоритет</Typography.Title>
                      {watch('priority_id') && watch('priority_id')?.length > 0 && <Redo onClick={resetPriority} tooltip />}
                    </div>
                    {!priority && (
                      <ControlButton onClick={handlePriority}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {priority && (
                      <ControlButton onClick={handlePriority}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {priority && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={priorityInput}
                          onChange={handlePriorityInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(priorityOptions, priorityInput)}
                          control={control}
                          name="priority_id"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Ответственный</Typography.Title>
                      {watch('responsible') && watch('responsible')?.length > 0 && (
                        <Redo onClick={resetResponsible} tooltip />
                      )}
                    </div>
                    {!responsible && (
                      <ControlButton onClick={handleResponsible}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {responsible && (
                      <ControlButton onClick={handleResponsible}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {responsible && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="responsible"
                          control={control}
                          mode="multiple"
                          notFoundContent="Начните вводить имя"
                          label="Ответственный"
                          fetchOptions={fetchResponsibleList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Заказчик</Typography.Title>
                      {watch('customer') && watch('customer')?.length > 0 && <Redo onClick={resetCustomer} tooltip />}
                    </div>
                    {!client && (
                      <ControlButton onClick={handleClient}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {client && (
                      <ControlButton onClick={handleClient}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {client && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="customer"
                          control={control}
                          mode="multiple"
                          label="Заказчик"
                          placeholder="Поиск по имени..."
                          fetchOptions={fetchClientList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Вакансия</Typography.Title>
                      {watch('vacancy_id') && watch('vacancy_id')?.length > 0 && <Redo onClick={resetVacancy} tooltip />}
                    </div>
                    {!vacancyContent && (
                      <ControlButton onClick={handleVacancyContent}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {vacancyContent && (
                      <ControlButton onClick={handleVacancyContent}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {vacancyContent && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="vacancy_id"
                          control={control}
                          notFoundContent="Начните вводить название вакансии"
                          mode="multiple"
                          placeholder="Поиск по наименованию..."
                          label="Вакансия"
                          fetchOptions={fetchVacancyList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Объект</Typography.Title>
                      {watch('store_id') && watch('store_id')?.length > 0 && <Redo onClick={resetStore} tooltip />}
                    </div>
                    {!store && (
                      <ControlButton onClick={handleStore}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {store && (
                      <ControlButton onClick={handleStore}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {store && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="store_id"
                          placeholder="Поиск по наименованию..."
                          control={control}
                          mode="multiple"
                          notFoundContent="Начните вводить название объекта"
                          label="Объект"
                          fetchOptions={fetchStoreList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
              </>
            )}
            {vacancy && (
              <>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Статус</Typography.Title>
                      {watch('status') && watch('status').length > 0 && <Redo onClick={resetStatus} tooltip />}
                    </div>
                    {!statusVacancy && (
                      <ControlButton onClick={handleStatusVacancy}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {statusVacancy && (
                      <ControlButton onClick={handleStatusVacancy}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {statusVacancy && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={statusVacancyInput}
                          onChange={handleStatusVacancyInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(vacancyStatusOptions, statusVacancyInput)}
                          control={control}
                          name="status"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Группы</Typography.Title>
                      {watch('group') && watch('group').length > 0 && <Redo onClick={resetGroup} tooltip />}
                    </div>
                    {!group && (
                      <ControlButton onClick={handleGroup}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {group && (
                      <ControlButton onClick={handleGroup}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {group && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={groupInput}
                          onChange={handleGroupInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(groupOptions, groupInput)}
                          control={control}
                          name="group"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
              </>
            )}

            {interview && (
              <>
                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Статус</Typography.Title>
                      {watch('status') && watch('status').length > 0 && <Redo onClick={resetStatus} tooltip />}
                    </div>
                    {!interviewStatus && (
                      <ControlButton onClick={handleInterviewStatus}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {interviewStatus && (
                      <ControlButton onClick={handleInterviewStatus}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {interviewStatus && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={interviewStatusInput}
                          onChange={handleInterviewStatusInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(interviewStatusOptions, interviewStatusInput)}
                          control={control}
                          name="status"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Результат</Typography.Title>
                      {watch('result') && watch('result').length > 0 && <Redo onClick={resetResult} tooltip />}
                    </div>
                    {!interviewResult && (
                      <ControlButton onClick={handleInterviewResult}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {interviewResult && (
                      <ControlButton onClick={handleInterviewResult}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {interviewResult && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <Input
                          prefix={<Search />}
                          className={`${mainCssClass}_items_content_search`}
                          value={interviewResultInput}
                          onChange={handleInterviewResultInput}
                        />
                        <CheckBoxFormControl
                          options={filteredOptions(interviewResultOptions, interviewResultInput)}
                          control={control}
                          name="result"
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_items_title_redo`}>
                      <Typography.Title level={4}>Рекрутер</Typography.Title>
                      {watch('recruiter') && watch('recruiter').length > 0 && <Redo onClick={resetRecruiter} tooltip />}
                    </div>
                    {!recriuterContent && (
                      <ControlButton onClick={handleRecruiterContent}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {recriuterContent && (
                      <ControlButton onClick={handleRecruiterContent}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>

                  {recriuterContent && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DebounceSelectFormControlMult
                          name="recruiter"
                          control={control}
                          mode="multiple"
                          placeholder="Поиск по имени..."
                          notFoundContent="Начните вводить имя"
                          label="Рекрутер"
                          fetchOptions={fetchRecruiterList}
                        />
                      </NewScrollContainer>
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Подтверждение</Typography.Title>
                      {watch('confirm') && watch('confirm').length > 0 && <Redo onClick={resetConfirm} tooltip />}
                    </div>
                    {!confirm && (
                      <ControlButton onClick={handleConfirm}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {confirm && (
                      <ControlButton onClick={handleConfirm}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {confirm && (
                    <div className={`${mainCssClass}_items_content`}>
                      <RadioGroupFormControl options={confirmOptions} control={control} name="confirm" />
                    </div>
                  )}
                </div>

                <div className={`${mainCssClass}_items`}>
                  <div className={`${mainCssClass}_items_title`}>
                    <div className={`${mainCssClass}_title_redo`}>
                      <Typography.Title level={4}>Дата проведения</Typography.Title>
                      {watch('date') && watch('date')?.length > 0 && <Redo onClick={resetDate} tooltip />}
                    </div>
                    {!date && (
                      <ControlButton onClick={handleDate}>
                        <CaretDownMD />
                      </ControlButton>
                    )}
                    {date && (
                      <ControlButton onClick={handleDate}>
                        <CaretUpMD />
                      </ControlButton>
                    )}
                  </div>
                  {date && (
                    <div className={`${mainCssClass}_items_content`}>
                      <NewScrollContainer className={`${mainCssClass}_items_content_scroll`}>
                        <DatePickerFormControl control={control} name="date" />
                        <Radio.Group onChange={onChangeDate} value={dateValue}>
                          <Radio value={1}>Сегодня</Radio>
                          <Radio value={2}>Вчера</Radio>
                        </Radio.Group>
                      </NewScrollContainer>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className={`${mainCssClass}_actions`}>
              <ControlButton typeButton="secondary" size="small" className="button" onClick={handleApply}>
                Применить
              </ControlButton>
              {/* <ControlButton typeButton="primary" size="small" className="button">Сохранить</ControlButton> */}
            </div>
          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default FiltersMobil
