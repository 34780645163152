import React, {
  FC, memo, useMemo, useState
} from 'react'
import { Tag, Typography } from 'antd'
import './VacancyStatusTableRow.scss'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TProps = {
status: number
}
const mainCssClass = 'vacancy-status-table-row'
const VacancyStatusTableRow: FC<TProps> = ({ status }) => {
  const text = useMemo(() => {
    if (status === 137) {
      return 'Открыта'
    }
    if (status === 136) {
      return 'Закрыта'
    }
    if (status === 640) {
      return 'Архив'
    }
    if (status === 138) {
      return 'Приостановлена'
    }

    return 'Отклонена'
  }, [status])
  return (
    <div className={mainCssClass}>
      <Typography.Text className={cn(`${mainCssClass}__status`, `${mainCssClass}__status-${status}`)}>{text}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(VacancyStatusTableRow))
