import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { Heading } from '../../../common/components/Text'
import Excel from '../../../common/svg/Excel'
import { VertexInfo } from './vertex/VertexInfo'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import SourceOfCandidates from './sourceOfCandidates/SourceOfCandidates'
import FiltersIcon from '../../../common/svg/FiltersIcon'
import {
  openReportFilters,
  selectFilterString,
  selectFiltersType,
  selectResetFilter,
  setFilter,
  setResetFilter,
  setTypeReportFilter
} from '../../../features/reportFilter'
import Productivity from './productivity/Productivity'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  getDictionaries,
  getPlanFact,
  getProductivity,
  getRejectionInfo,
  getSourseOfCandidates,
  getVertexInfo
} from '../actions'
import { RejectionInfo } from './rejection/RejectionInfo'
import { ClosingOfApplications } from './closingOfApplications/ClosingOfApplications'
import './Analytics.scss'
import { selectPlanFactVacancy, selectScenario, selectVertexMethod } from '../selectors'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import { selectCountFilters } from '../../../features/filters/selectors'
import { selectIconNumber } from '../../../compositions/InnerRouter/selectors'
import { setIconNumber } from '../../../compositions/InnerRouter/actions'
import { reset } from '../../Leads'

const mainCssClass = getCssClassName('analytics')
export type TFormValues = {
  analytics: string
}
type TFormValue = {
  method1: string
  method2: string
}
function Analytics() {
  const dispatch = useDispatch()
  const filterType = useSelector(selectFiltersType)
  const resetFilter = useSelector(selectResetFilter)
  const filtersCount = useSelector(selectCountFilters)
  const filter = useSelector(selectFilterString)
  const vertexMethod = useSelector(selectVertexMethod)
  const by_vacancy = useSelector(selectPlanFactVacancy)
  const scenario = useSelector(selectScenario)
  //_________________________Удалить после НГ(  const iconNumber = useSelector(selectIconNumber))
  const iconNumber = useSelector(selectIconNumber)
  const { rbacControl, download_access } = useEntityContext()
  const options = [
    { label: 'Источники кандидатов', value: 'sourceOfCandidates' },
    { label: 'Воронка подбора', value: 'vertex' },
    { label: 'Продуктивность', value: 'productivity' },
    { label: 'Отказы', value: 'rejection' },
    { label: 'План/факт закрытия заявки', value: 'closingOfApplications' }
  ]
  const {
    control: analyticsControl,
    watch: analyticsWatch,
    getValues: analyticsGetValues,
    setValue: analyticsSetValue
  } = useForm<TFormValues>({
    defaultValues: {
      analytics: filterType
    }
  })
  const {
    control: methodControl,
    watch: methodWatch,
    getValues: methodGetValues,
    setValue: methodSetValue
  } = useForm<TFormValues>({ defaultValues: { method1: 'summary', method2: 'summary' } })
  const analytics = analyticsWatch('analytics')
  const activeAnalytics = useMemo(() => analytics, [analytics])

  const openFilters = () => {
    dispatch(openReportFilters(true))
  }

  const handleClickExportCSV = () => {
    if (activeAnalytics === 'vertex') {
      dispatch(
        getVertexInfo({
          filter,
          method1: vertexMethod,
          method2: vertexMethod,
          download: true
        })
      )
    }
    if (activeAnalytics === 'closingOfApplications') {
      dispatch(getPlanFact({ by_vacancy, filter, download: true }))
    }
    if (activeAnalytics === 'rejection') {
      dispatch(getRejectionInfo({ filter, download: true }))
    }
    if (activeAnalytics === 'sourceOfCandidates') {
      dispatch(getSourseOfCandidates({ filter, download: true }))
    }
    if (activeAnalytics === 'productivity') {
      dispatch(
        getProductivity({
          filter,
          scenario,
          method: 'summary',
          download: true
        })
      )
    }
  }

  const handleChangeTypeFilter = (value: string) => {
    dispatch(setFilter(''))
    dispatch(setResetFilter(resetFilter + 1))
    dispatch(setTypeReportFilter(value))
  }

  // useEffect(() => {
  //   dispatch(getVertexInfo({ filter, method1, method2 }))
  // }, [filter, method1, method2])

  useEffect(() => {
    dispatch(getDictionaries(['stageStatus']))
    //_________________________Удалить после НГ
    if (iconNumber === 3) {
      dispatch(setIconNumber(1))
    } else {
      dispatch(setIconNumber(iconNumber + 1))
    }
    //__________________________
  }, [])

  useEffect(
    () => () => {
      dispatch(setResetFilter(1))
      dispatch(setFilter(''))
    },
    []
  )

  return (
    <div className={cn(mainCssClass)}>
      <div className={`${mainCssClass}__title-block`}>
        <div>
          {/*//_________________________Удалить после НГ*/}
          <div className={`${mainCssClass}__title-block_icon`}>
            <Heading.H3>Аналитика</Heading.H3>
          </div>
          {/*//_________________________Удалить после НГ*/}
          {/*//_____________________________Венрнуть после НГ*/}
          {/*<Heading.H3>*/}
          {/*  Аналитика*/}
          {/*</Heading.H3>*/}
          <SelectFormControl
            name="analytics"
            options={options}
            control={analyticsControl}
            className={`${mainCssClass}__select`}
            onChange={(value) => handleChangeTypeFilter(value)}
          />
          {!!download_access?.analytics && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {filter ? (
                <Button
                  type="ghost"
                  icon={<Excel />}
                  className={`${mainCssClass}__excel`}
                  onClick={handleClickExportCSV}
                >
                  Скачать отчет
                </Button>
              ) : (
                <Tooltip placement="bottomRight" title="Выберите фильтры">
                  <Button
                    type="ghost"
                    icon={<Excel />}
                    className={cn(`${mainCssClass}__excel`, {
                      [`${mainCssClass}__excel-disabled`]: !filter
                    })}
                    disabled={!filter}
                  >
                    Скачать отчет
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </div>
        <ControlButton icon="left" size="large" typeButton="secondary" className="button" onClick={openFilters}>
          <FiltersIcon />
          Фильтры
          {filtersCount >= 1 ? <span className={`${mainCssClass}_count-in-brackets`}>({filtersCount})</span> : ''}
        </ControlButton>
      </div>
      <div className={`${mainCssClass}__scroll-container`}>
        <NewScrollContainer className={`${mainCssClass}__scroll-container_scroll`}>
          {activeAnalytics === 'sourceOfCandidates' && <SourceOfCandidates />}
          {activeAnalytics === 'vertex' && <VertexInfo />}
          {activeAnalytics === 'productivity' && <Productivity />}
          {activeAnalytics === 'rejection' && <RejectionInfo />}
          {activeAnalytics === 'closingOfApplications' && <ClosingOfApplications />}
        </NewScrollContainer>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(Analytics))
