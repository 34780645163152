import {
  put, takeLatest, spawn, select, take, race
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import {
  getCandidate,
  getDictionaries,
  setCandidate,
  setDictionaries,
  setLoading,
  setInterview,
  getInterview,
  setHistoryData,
  getHistoryData,
  updateInterview,
  setHandleModalCancelOpen,
  setHandleModalEndOpen,
  setIsEditModeQuestionnaire,
  setQuestionnaire,
  getQuestionnaire,
  createQuestionnaire,
  updateCandidate,
  setHandleModalEditOpen,
  editInterview,
  setCandidateQuestionnaire,
  getCandidateQuestionnaire
} from './actions'
import {
  getCandidate as getCandidatePage,
  getCandidateUpdate, setNewInterview, TAddInterview, TCandidateQuestionnaire
} from '../candidate/actions'
import { getCandidateSaga as getCandidatePageSaga } from '../candidate/saga'

import {

  fetchGetCandidate,
  fetchGetCandidateQuestionnaire,
  fetchGetHistoryCandidateData,
  fetchGetQuestionnaire,
  fetchPatchCandidate,
  fetchPostQuestionnaire
} from '../../common/api/Candidate'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import {
  fetchGetInterview,
  fetchGetInterviewHistory,
  fetchPatchInterview,
  TInterview,
  TInterviewHistoryResponse,
  TInterviewResponse,
  TInterviewsResponse
} from '../../common/api/interviews'
import { TCandidate, TDATA, TRequestNote } from '../candidate/types'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import { selectCandidate } from './selectors'
import { fetchPostCalendarAddInterview } from '../../common/api/calendar'
import { navigateTo } from '../../common/actions/navigate'
import { getUserInfo, setLoadingModal, setLoadingProgress } from '../InnerRouter/actions'


type CandidateResponse = AxiosResponse<TCandidate>

const moduleName = 'Карточка интервью'

function* getCandidateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchGetCandidate(payload),
      cancel: take(getCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* getInterviewSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TInterviewResponse>;
    ({response} = yield race({
      response: fetchGetInterview(payload),
      cancel: take(getInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setInterview(response.data.DATA))
      if (response.data.DATA.candidate_id) {
        yield spawn(getCandidateSaga, getCandidate(String(response.data.DATA.candidate_id)))
        yield spawn(getInterviewHistorySaga, getHistoryData(String(response.data.DATA.candidate_id)))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* getInterviewHistorySaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    let response: AxiosResponse<TInterviewHistoryResponse>;
    ({response} = yield race({
      response: fetchGetInterviewHistory(payload),
      cancel: take(getHistoryData.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setHistoryData(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoading(false))
    yield put(setLoadingProgress(false))
  }
}

function* updateInterviewSaga({ payload }: PayloadAction<TInterview>) {
  try {
    yield put(setLoadingModal(true))
    yield put(setLoadingProgress(true))
    let newPayload = payload
    if (typeof payload.characteristic === 'object') {
      newPayload = { ...payload, characteristic: payload.characteristic.id }
    }
    let response: AxiosResponse<TInterviewHistoryResponse>;
    ({response} = yield race({
      response: fetchPatchInterview(newPayload),
      cancel: take(updateInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить интервью',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setInterview(response.data.DATA))
      yield put(setHandleModalCancelOpen(false))
      yield put(setHandleModalEndOpen(false))
      yield put(setHandleModalEditOpen(false))

      if (payload.status === 255 || payload.status === 95) {
        yield put(getUserInfo(false))
      }
      if (payload.status === 255) {
        yield spawn(getCandidateSaga, getCandidate(String(response.data.DATA.candidate_id)))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}
function* getQuestionnaireSaga() {
  try {
    yield put(setLoadingProgress(true))
    const {response} = yield race({response: fetchGetQuestionnaire(), cancel: take(getQuestionnaire.type)})
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить анкету кандедата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setQuestionnaire(response.data.DATA))

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* createQuestionnaireSaga({ payload }: PayloadAction<TCandidateQuestionnaire>) {
  try {
    yield put(setLoadingProgress(true))
    let response: AxiosResponse;
    ({response} = yield race({
      response: fetchPostQuestionnaire(payload),
      cancel: take(createQuestionnaire.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось создать анкету кандидата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setIsEditModeQuestionnaire(false))
      const candidate: TDATA | null = yield select(selectCandidate)
      if (candidate && response) {
        yield put(getCandidateUpdate(candidate?.id?.toString()))
        yield put(getCandidateQuestionnaire(candidate.id))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateCandidateSaga({ payload }: PayloadAction<TDATA | TRequestNote>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchPatchCandidate(payload),
      cancel: take(updateCandidate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось обновить кандидата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
      if (payload.questionnare_id && payload.id) {
        yield put(getCandidateQuestionnaire(payload.id))
      }

    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'интервью карточка')
}

function* editInterviewSaga({ payload }: PayloadAction<TAddInterview>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoadingModal(true))
    let response: AxiosResponse<any>;
    ({response} = yield race({
      response: fetchPostCalendarAddInterview(payload.data),
      cancel: take(editInterview.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else if (!response.data.ERR) {
      if (!payload.isCandidatePage) {
        yield put(getCandidateUpdate(payload?.data.candidate_id?.toString()))
        yield put(navigateTo(`/interview/${response?.data?.DATA?.interview.id}`))
        yield put(setHandleModalEditOpen(false))
      } else {
        yield put(getCandidatePage(String(payload.data.candidate_id)))
        yield put(setHandleModalEditOpen(false))
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingModal(false))
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateQuestionnaireSaga({payload}: PayloadAction<number>) {
  try {
    yield put(setLoadingProgress(true))
    const {response} = yield race({response: fetchGetCandidateQuestionnaire(payload), cancel: take(getCandidateQuestionnaire.type)})
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить анкету кандидата',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCandidateQuestionnaire(response?.data.DATA))
      yield put(setIsEditModeQuestionnaire(false))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* getCandidateUpdateSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    let response: CandidateResponse;
    ({response} = yield race({
      response: fetchGetCandidate(payload),
      cancel: take(getCandidateUpdate.type)
    }));
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setCandidate(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* interviewCardSaga() {
  yield takeLatest(editInterview, editInterviewSaga)
  yield takeLatest(getCandidate, getCandidateSaga)
  yield takeLatest(getInterview, getInterviewSaga)
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getQuestionnaire, getQuestionnaireSaga)
  yield takeLatest(createQuestionnaire, createQuestionnaireSaga)
  yield takeLatest(getHistoryData, getInterviewHistorySaga)
  yield takeLatest(updateInterview, updateInterviewSaga)
  yield takeLatest(updateCandidate, updateCandidateSaga)
  yield takeLatest(getCandidateQuestionnaire, getCandidateQuestionnaireSaga)
  yield takeLatest(getCandidateUpdate, getCandidateUpdateSaga)
}

export default interviewCardSaga
