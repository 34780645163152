import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ControlButton from '../../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import CloseMD from '../../../../common/svg/CloseMD'
import CheckBig from '../../../../common/svg/CheckBig'
import './CandidateDetailListEdit.scss'
import { TDATA } from '../../types'
import SelectFormControl from '../../../../common/components/formcontrol/SelectFormControl'
import { selectDictionaryOptionsByName, selectInnError } from '../../selectors'
import {
  getInnFind, setInnError, updateCandidate
} from '../../actions'
import InputFormControl from '../../../../common/components/formcontrol/InputFormControl'
import DatePickerFormControl from '../../../../common/components/formcontrol/DatePickerFormControl'
import { setEditKeyDetailList } from '../../../../compositions/InnerRouter/actions'

const mainCssClass = getCssClassName('candidate-detailList-edit')

function CandidateDetailListEdit({ candidate, editKey } : { candidate: TDATA | null, editKey: string }) {
  const dispatch = useDispatch()
  const registrationOptions = useSelector(selectDictionaryOptionsByName('typeRegistrationWork'))
  const citizenshipOptions = useSelector(selectDictionaryOptionsByName('citizenship'))
  const innError = useSelector(selectInnError)
  const [isDisabled, setDisabled] = useState(false)
  const refPrevInn = useRef(candidate?.inn)

  const { control, watch, handleSubmit } = useForm()

  const handleCloseEditMode = () => {
    dispatch(setEditKeyDetailList(''))
  }

  const onSubmit = () => {
    if (candidate) {
      dispatch(updateCandidate({ id: candidate?.id, [editKey]: watch(editKey) }))
    }
  }

  const onChangeInn = (e) => {
    const innNumber = watch('inn')

    if (innNumber && /^\d{12}$/.test(e.target.value) && e.target.value !== refPrevInn.current) {
      setDisabled(true)
      refPrevInn.current = e.target.value
      dispatch(getInnFind({ inn: e.target.value, candidate_id: candidate?.id || 0 }))
      setTimeout(() => {
        setDisabled(false)
      }, 1500)
    } else if (!innNumber || !/\d/.test(e.target.value)) {
      dispatch(setInnError([]))
    }
  }

  const renderTypeRegistration = () => (
    <div className={mainCssClass}>
      <SelectFormControl control={control} name="type_registration" defaultValue={candidate?.type_registration} options={registrationOptions} />
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderCitizenship = () => (
    <div className={mainCssClass}>
      <SelectFormControl control={control} name="citizenship" defaultValue={candidate?.citizenship} options={citizenshipOptions} />
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderInn = () => (
    <div className={mainCssClass}>
      <InputFormControl
        name="inn"
        control={control}
        mask="999999999999"
        defaultValue={candidate?.inn || ''}
        onChange={onChangeInn}
        rules={{
          validate: (value) => (value ? /^\d{12}$/.test(value) && !innError.length : true)
        }}
        className={innError.length ? 'error' : ''}
        tooltip={innError.length
          ? innError[0] : null}
      />
      <ControlButton className={`${mainCssClass}_edit-button`} disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderDateInterviewOffline = () => (
    <div className={mainCssClass}>
      <DatePickerFormControl
        name="date_interview_offline"
        control={control}
        defaultValue={candidate?.date_interview_offline}
      />
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderDateRegistration = () => (
    <div className={mainCssClass}>
      <DatePickerFormControl
        name="date_registration"
        control={control}
        defaultValue={candidate?.date_registration}
      />
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderDateBeginAt = () => (
    <div className={mainCssClass}>
      <DatePickerFormControl
        name="date_begin_at"
        control={control}
        defaultValue={candidate?.date_begin_at}
      />
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleSubmit(onSubmit)}>
        <CheckBig />
      </ControlButton>
      <ControlButton className={`${mainCssClass}_edit-button`} onClick={handleCloseEditMode}>
        <CloseMD />
      </ControlButton>
    </div>
  )

  const renderContent = () => {
    switch (editKey) {
    case 'type_registration':
      return renderTypeRegistration()
    case 'citizenship':
      return renderCitizenship()
    case 'inn':
      return renderInn()
    case 'date_interview_offline':
      return renderDateInterviewOffline()
    case 'date_registration':
      return renderDateRegistration()
    case 'date_begin_at':
      return renderDateBeginAt()

    default:
      return null
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

export default CandidateDetailListEdit
