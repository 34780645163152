import React, { useCallback, useEffect, useState } from 'react'
import {
  Button, Modal, ModalProps, Typography
} from 'antd'
import cn from 'classnames'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getDictionaries } from '../../../compositions/ManagementVacancies/actions'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { changeStatusVacancyModal } from '../actions'
import { TOption } from '../../../common/types/dictionaries'
import './ChangeStatusVacancyModal.scss'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'change-status-vacancy-modal'

type TProps = {
    options: TOption[]
    vacancyId?: number | string | null
} & ModalProps
function ChangeStatusVacancyModal({
  open, className, onCancel, options, vacancyId
}: TProps) {
  const dispatch = useDispatch()
  const {
    control, watch, getValues, reset
  } = useForm<TFormValues>()

    type TFormValues = {
        status: number
    }

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e)
        reset()
      }
    }

    const handleSubmit = useCallback(() => {
      if (vacancyId) {
        const data = watch('status')
        if (data) {
          dispatch(changeStatusVacancyModal({ id: vacancyId, ...getValues() }))
        }
      }
    }, [vacancyId])

    useEffect(() => {
      dispatch(getDictionaries(['vacancyStatus']))
    }, [])

    return (
      <CommonModal
        centered
        open={open}
        onOk={handleSubmit}
        onCancel={handleModalClose}
        title="Смена статуса вакансии"
        className={cn(className, mainCssClass)}
        width="400px"
        typeButtonAccept="primary"
        okText="Применить"
        cancelText="Отменить"
        typeButtonReject="secondary"
        wrapClassName="wrapModal"
      >
        <div>
          <SelectFormControl
            name="status"
            control={control}
            label="Выберите "
            options={options}
            required
            rules={{ required: true }}
          />
        </div>
      </CommonModal>
    )
}

export default ChangeStatusVacancyModal
