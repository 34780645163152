import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'request-add'

function RequestAdd({ className, size = '1' }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.00003" y="1" width="38" height="38" rx="19" fill="#E1FAE6" />
      <rect x="1.00003" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 12C16 10.8954 16.8955 10 18 10H22C23.1046 10 24 10.8954 24 12V16H28C29.1046 16 30 16.8954 30 18V22C30 23.1046 29.1046 24 28 24H24V28C24 29.1046 23.1046 30 22 30H18C16.8955 30 16 29.1046 16 28V24H12C10.8955 24 10 23.1046 10 22V18C10 16.8954 10.8955 16 12 16H16V12ZM22 12H18V17C18 17.5523 17.5523 18 17 18H12V22H17C17.5523 22 18 22.4477 18 23V28H22V23C22 22.4477 22.4477 22 23 22L28 22V18H23C22.4477 18 22 17.5523 22 17L22 12Z" fill="#2DBE64" />
    </svg>

  )
}
export default RequestAdd
