/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import $api from './http'
import { TPaginationRequest } from '../types/pagination'
import { TRequestInterviewsCSV, TRequestUpdateConfirmInterview } from '../../compositions/ManagementInterviews/actions'

export type TInterview = {
  id: number
  status: number
  research: number
  recruiter: number
  vacancy_id: number
  comment: string
  note: number
  confirm: string
  vacancy_list: { label: string; value: string }[]
  candidate_id: number
  slot: string
  characteristic: any
  request:{label: string}
  researcher_name: {
    name: string
    id: number
  }
  recruiter_name: {
    name: string
    id: number
  }
  candidate_name: {
    label: string
    id: number
    phone:string
  }
  date: string
  start_at: Date
  finish_at: Date
  period: string
  result: number
  questionnaire_id: number
  created_at: Date
  updated_at: Date
  reject_id: number
  characteristic: any
  rejection_reason:number
  canceling_reason: string
}

export type TInterviewHistory = {
  id: number
  status: number
  research: number
  recruiter: number
  vacancy_id: number
  vacancy_list: { label: string; value: string }[]
  candidate_id: number
  research_name: {
    name: string
    id: number
  }
  recruiter_name: {
    name: string
    id: number
  }
  candidate_name: {
    name: string
    id: number
  }
  date: string
  start_at: Date
  finish_at: Date
  period: string
  confirm: true
  result: number
  questionnaire_id: number
  created_at: Date
  updated_at: Date
  labels: {
    research: string
    recruiter: string
    status: string
    result: string
  }
}
export type TInterviewsResponse = {
  err: string
  DATA: TInterviewsResponseData
  Headers: {
    'x-pagination-total-count': number[],
    'x-pagination-page-count': number[],
    'x-pagination-current-page': number[],
  }
}
export type TInterviewsResponseData = {
  interview_list: TInterview[]
  counters: {
    count: number
    label: string
    id: string | number
  }[]
}

export type TInterviewResponse = {
  ERR: string
  DATA: TInterview
}
export type TInterviewHistoryResponse = {
  err: string
  DATA: TInterviewHistory[]
}

export async function fetchGetInterviews(queryParams: TPaginationRequest): Promise<AxiosResponse<TInterviewsResponse>> {
  const response = await $api.get<TInterviewsResponse>(
    `/api/v1/interviews?fields=cache,candidate_name,date,period,confirm,recruiter_name,researcher_name,id,vacancy_list,city,status,result,characteristic,start_date&per-page=${queryParams.perPage}&page=${queryParams.page}${queryParams?.filters || ''}${queryParams?.self === 'true' ? '&self=1' : ''}`
  )
  return response
}

export async function fetchGetInterview(id: string): Promise<AxiosResponse<TInterviewsResponse>> {
  const response = await $api.get<TInterviewResponse>(`/api/v1/interviews/${id}`)
  return response
}
export async function fetchPatchInterview(payload: TInterview | TRequestUpdateConfirmInterview): Promise<AxiosResponse<TInterviewsResponse>> {
  const response = await $api.patch<TInterviewResponse>(`/api/v1/interviews/${payload.id}`, payload)
  return response
}

export async function fetchGetInterviewHistory(id: string): Promise<AxiosResponse<TInterviewHistoryResponse>> {
  const response = await $api.get<TInterviewResponse>(`/api/v1/candidate/get-interview-story?candidate_id=${id}`)
  return response
}

export async function fetchPostCreateInterviewCSV(payload: TRequestInterviewsCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/interview/export-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}${payload?.self === 'true' ? payload.filter ? '&self=1' : '?self=1' : ''}`)
  return response
}

export type TRequestWorkload = {
  filter?: string
  date: string
}

export async function fetchGetWorkload(payload: TRequestWorkload): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/interview/get-workload?date=${payload.date}${payload.filter ? payload.filter : ''}`)
  return response
}
