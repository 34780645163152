/* eslint-disable react/function-component-definition */
import { useDispatch, useSelector } from 'react-redux'
import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { Button, Tooltip, Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import { isArray } from 'lodash'
import Logo from '../../../images/logo.svg'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  setHandleModalEditOpen,
  setHandleModalCancelOpen,
  setSelectedInterviewId,
  setHandleModalHistoryOpen,
  updateInterview,
  setHandleModalEndOpen
} from '../actions'

import {
  getUserInfo, setCandidateSelected, setDestinationNumber, setPreviousPage
} from '../../InnerRouter/actions'
import {
  selectCandidate, selectInterview, selectLabelById, selectTypeById
} from '../selectors'
import { AcceptSolid } from '../../../common/svg/SvgFromIconWrapper/AcceptSolid'
import CardUser from '../../../common/svg/CardUser'
import { navigateTo } from '../../../common/actions/navigate'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import UserAvatar from '../../../common/svg/UserAvatar'
import ControlButton from '../../../common/components/Buttons/Button'
import { openActiveInterviewModal } from '../../../features/interviewModals/actions'
import { selectStatusPhone, selectUserInfo } from '../../InnerRouter/selectors'
import { DetailsList } from '../../../common/components/DetailsList'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Heading } from '../../../common/components/Text'
import './HeaderInterview.scss'
import { TDATA } from '../../candidate/types'
import PhoneSvg from '../../../common/svg/PhoneSvg'

const mainCssClass = getCssClassName('header-interview')

function getCandidateInfo(candidate: TDATA | null, handlePhone: Function, statusPhone: boolean) {
  return [
    { label: 'Возраст', value: candidate?.age || '-' },
    {
      label: 'Телефон',
      value: (<div className={`${mainCssClass}__container-phone`}>
        <div>{isArray(candidate?.phone) ? candidate?.phone?.join(', ') : candidate?.phone}</div>
        <Button type="link" ghost icon={<PhoneSvg />} disabled={statusPhone} onClick={handlePhone} className={`${mainCssClass}__button-phone`} />
      </div>)
    },
    { label: 'Email', value: (isArray(candidate?.email) ? candidate?.email?.join(', ') : candidate?.email) || '-' },
    { label: 'Адрес', value: candidate?.address || '-' },
    { label: 'Город', value: candidate?.city || '-' },
    { label: 'Вакансия', value: candidate?.vacancy_list?.[0]?.label }
  ]
}
const HeaderInterview: FC = () => {
  const dispatch = useDispatch()
  const candidate = useSelector(selectCandidate)
  const interview = useSelector(selectInterview)
  const statusPhone = useSelector(selectStatusPhone)
  const { role } = useEntityContext()
  const handlePhone = () => {
    let newNumber = candidate?.phone?.[0]?.toString()
    if (newNumber.startsWith('7')) {
      newNumber = `8${newNumber.slice(1)}`
    } else {
      newNumber = candidate?.phone
    }
    dispatch(setDestinationNumber(newNumber))
    dispatch(setCandidateSelected(candidate))
  }
  const detailsListCandidate = useMemo(() => getCandidateInfo(candidate, handlePhone, statusPhone), [candidate, statusPhone])
  const { rbacControl } = useEntityContext()
  const stageType = useSelector(selectTypeById(candidate?.stage_id, 'stageStatus'))

  const userInfo = useSelector(selectUserInfo)

  const activeInterview = useMemo(() => {
    if (userInfo && 'has_active_interview' in userInfo && 'id' in userInfo.has_active_interview) {
      return userInfo.has_active_interview.id
    }
    return null
  }, [userInfo])

  const handleModalEditOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalEditOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])

  const handleModalCancelOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalCancelOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])

  const handleModalRejectOpen = useCallback(() => {
    if (interview) {
      dispatch(setHandleModalEndOpen(true))
      dispatch(setSelectedInterviewId(interview.id))
    }
  }, [interview])
  const handleModalStartInterviewOpen = useCallback(() => {
    if (interview) {
      dispatch(
        updateInterview({
          ...interview,
          status: 255
        })
      )
    }
  }, [interview])

  const handleAcceptInterviewInterviewOpen = useCallback(() => {
    if (interview) {
      dispatch(
        updateInterview({
          ...interview,
          confirm: 'confirmed'
        })
      )
    }
  }, [interview])
  const handleNavigate = useCallback(() => {
    if (candidate?.id) {
      dispatch(navigateTo(`/candidate/${candidate.id}`))
      dispatch(setPreviousPage({ navigate: `/interview/${interview?.id}`, caption: 'Карточка интервью' }))
    }
  }, [candidate, userInfo, activeInterview])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_header`}>
        <div className={`${mainCssClass}_header_avatar`}>
          {candidate?.photo ? <img src={candidate.photo} alt="аватар" /> : <UserAvatar />}
        </div>
        <div className={`${mainCssClass}_list`}>
          <div className={`${mainCssClass}_list_title`}>
            <Heading.H3 className={`${mainCssClass}_list_user`}>
              <div className={`${mainCssClass}_list_user_name`}>
                {`${candidate?.last_name} ${candidate?.name} ${candidate?.second_name || ''}`}
              </div>
              {' '}
              <NavLink to={`/candidate/${candidate?.id}`} className={`${mainCssClass}__link`}>
                {' '}
                <CardUser handleClick={handleNavigate} className={`${mainCssClass}__logo`} fill="#2dbe64" />
                {' '}
              </NavLink>
            </Heading.H3>
          </div>
          <DetailsList
            size="xxs"
            items={[{
              items: detailsListCandidate
            }]}
          />

        </div>
      </div>
      <div className={`${mainCssClass}_actions`}>
        {interview?.status === 94 && (userInfo?.role?.role_id === 'recruiter' || userInfo?.isColdSearch || userInfo?.is_can_conduct_interview) && rbacControl?.interview.create_access === 1 && (
          <ControlButton
            className="button"
            onClick={handleModalStartInterviewOpen}
            typeButton="primary"
            icon="none"
          >
            {' '}
            <AcceptSolid />
            Начать
          </ControlButton>
        )}
        {(userInfo?.role?.role_id === 'recruiter' || userInfo?.isColdSearch || userInfo?.is_can_conduct_interview) && (interview?.status === 255 || interview?.status === 95) && rbacControl?.interview.create_access === 1 && (
          <ControlButton
            typeButton="primary"
            className="button"
            onClick={handleModalRejectOpen}
            icon="none"
          >
            <AcceptSolid />
            {interview?.status === 95 ? 'Редактировать результат' : 'Завершить'}
          </ControlButton>
        )}

        {interview?.status !== 95 && interview?.status !== 93
          && interview?.status !== 255 && rbacControl?.interview.create_access === 1 && (
            <ControlButton
              typeButton="secondary"
              className="button"
              size="large"
              icon="left"
              onClick={handleModalEditOpen}
            >
              <EditPencil1 size="s_svg" />
              Редактировать
            </ControlButton>
          )}
        {interview?.status !== 93 && interview?.status !== 95 && interview?.status !== 255 && rbacControl?.interview.create_access === 1 && (
          <ControlButton typeButton="warning" size="large" icon="none" className="button" onClick={handleModalCancelOpen}>
            Отменить
            {' '}
          </ControlButton>
        )}
        {interview?.confirm === 'unconfirmed' && interview?.status !== 255 && interview?.status !== 93 && rbacControl?.interview.create_access === 1 && (
          <ControlButton typeButton="primary" size="large" icon="none" state="default" className="button" onClick={handleAcceptInterviewInterviewOpen}>
            Подтвердить
            {' '}
          </ControlButton>
        )}
      </div>
    </div>
  )
}
export default memo(withErrorBoundary(HeaderInterview))
