import { combineReducers } from 'redux'
import authReducer from '../../compositions/auth/reducer'
import candidateReducer from '../../compositions/candidate/reducer'
import { innerRouterReducer } from '../../compositions/InnerRouter/reducer'
import managementCandidateReducer from '../../compositions/ManagamentCandidates/reducer'
import managementRequestsReducer from '../../compositions/ManagementRequests/reducer'
import managementVacanciesReducer from '../../compositions/ManagementVacancies/reducer'
import requestReducer from '../../compositions/Request/reducer'
import managementInterviewsReducer from '../../compositions/ManagementInterviews/reducer'
import interviewReducer from '../../compositions/InterviewCard/reducer'
import resumeReducer from '../../compositions/Resume/reducer'
import filtersReducer from '../../features/filters/reducer'
import calendarReducer from '../../compositions/Calendar/reducer'
import calendarSettingsReducer from '../../features/SettingsCalendar/reducer'
import chatReducer from '../../features/chat/reducer'
import interviewModalsReducer from '../../features/interviewModals/reducer'
import systemsReducer from '../../compositions/Systems/reducer'
import mapReducer from '../../features/map/reducer'
import funnelReducer from '../../compositions/Funnel/reducer'
import notificationReducer from '../../features/notificaitionCenter/reducer'
import reportFilterReducer from '../../features/reportFilter/reducer'
import tasksReducer from '../../compositions/Tasks/reducer'
import analyticsReducer from '../../compositions/analytics/reducer'
import employeesCenterReducer from '../../features/employeesModal/reducer'
import internshipReducer from '../../compositions/internship/reducer'
import leadsReducer from '../../compositions/Leads/reducer'
import fluidityReducer from '../../compositions/Fluidity/reducer'
import costOfHiringReducer from '../../compositions/CostOfHiring/reducer'
import photReducer from '../../compositions/Phot/reducer'
import autoCallReducer from '../../compositions/autocalls/reducer'
import chatPanelReducer from '../../features/chatPanel/reducer'
import userWorkPlanReducer from '../../compositions/DepartmentWorkPlan/reducer'
import productivityReducer from '../../compositions/Productivity/reducer'
import candidateReducerV2 from '../../compositions/candidateV2/reducer'
import requestModalsReducer from '../../features/requestModals/reducer'

function RootReducer() {
  return combineReducers({
    // reducers from features or compositions
    auth: authReducer,
    candidate: candidateReducerV2,
    innerRouter: innerRouterReducer,
    managementCandidate: managementCandidateReducer,
    map: mapReducer,
    managementRequests: managementRequestsReducer,
    managementVacancies: managementVacanciesReducer,
    request: requestReducer,
    managementInterviews: managementInterviewsReducer,
    interviewCard: interviewReducer,
    resume: resumeReducer,
    funnel: funnelReducer,
    filters: filtersReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    internship: internshipReducer,
    calendarSettings: calendarSettingsReducer,
    modalsInterview: interviewModalsReducer,
    systems: systemsReducer,
    notification: notificationReducer,
    reportFilter: reportFilterReducer,
    analytics: analyticsReducer,
    tasks: tasksReducer,
    employeesCenter: employeesCenterReducer,
    leads: leadsReducer,
    fluidity: fluidityReducer,
    costOfHiring: costOfHiringReducer,
    phot: photReducer,
    autoCall: autoCallReducer,
    chatPanel: chatPanelReducer,
    userWorkPlan: userWorkPlanReducer,
    productivity: productivityReducer,
    requestModals: requestModalsReducer
  })
}

export type TRootState = ReturnType<ReturnType<typeof RootReducer>>
export default RootReducer
