import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import { TClassName } from '../../../common/types/props'
import { getCssClassName } from '../../../common/utils/generateClassName'
import { DATE_DOTS_FORMAT } from '../../../common/utils/dateFormats'
import { navigateTo } from '../../../common/actions/navigate'
import { TInterview } from '../../../common/api/interviews'
import TableMobil from '../../../common/components/table/TableMobil'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import StatusInterviewTableRowMobil from './StatusInterviewTableRowMobil'

const mainCssClass = getCssClassName('interviews-table-mobil')

type TProps= {
    interviews: TInterview[] | []
} & TClassName
type TRowData = {
    candidate_name: JSX.Element
}

const useColumns = (): Column<any>[] => (
  useMemo(
    () => [
      {
        accessor: 'candidate_name', Header: 'Кандидат'
      }

    ],
    []
  )
)

const useRowsData = (interviews: TInterview[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback(
    (row) => {
      const { original: { id } } = row
      dispatch(navigateTo(`/interview/${id}`))
    },
    []
  )
  const rows: TRowData[] = useMemo(
    () => (
      interviews?.length ? interviews.map<TRowData>(({
        candidate_name, date, period, id, status, confirm
      }) => ({
        candidate_name: (<StatusInterviewTableRowMobil
          status={status}
          candidate_name={candidate_name?.label || '-'}
          period={period || '-'}

          date={dayjs(date).format(DATE_DOTS_FORMAT)}
        />),
        id
      })) : []
    ),
    [interviews]
  )
  return { rows, handleRowClick }
}
const InterviewsTableMobil: FC<TProps> = ({ className, interviews }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(interviews)

  return (
    <TableMobil
      className={cn(mainCssClass, className)}
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(InterviewsTableMobil))
