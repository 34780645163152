import React, { FC, memo, useState } from 'react'
import './HeadHunterUrl.scss'
import HeadHunter from '../../../common/svg/HeadHunter'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import ControlButton from '../../../common/components/Buttons/Button'

type TProps = {
    url_hh: string[]
  vacancy_count: number
}
const mainCssClass = 'headhunter-vacancy-url'
const HeadHunterUrlRow: FC<TProps> = ({ url_hh, vacancy_count }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleView = () => {
    setVisible(!visible)
  }
  return (

    <div className={mainCssClass}>
      {!url_hh?.length && (
        <span>
          Нет активных ссылок
        </span>
      )}

      {url_hh?.length && (
        <>
          <span>
            Количество ссылок:&nbsp;
            {url_hh?.length}
          </span>
          <ControlButton onClick={handleView} type="ghost">
            {visible ? 'Скрыть'
              : 'Раскрыть'}
          </ControlButton>
          {visible && url_hh?.map((el) => (
            <div className={`${mainCssClass}__link`}>
              <HeadHunter />
              <a href={el?.href || el} alt={el?.href || el} target="_blank" rel="noopener noreferrer">
                {el?.label || 'Нет информации'}
              </a>
            </div>
          ))}
        </>
      )}
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(HeadHunterUrlRow))
