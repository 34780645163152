import React, {
  memo, useCallback, useMemo, useState
} from 'react'
import { ModalProps, Typography } from 'antd'

import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

import { TTask, createTask } from '../actions'
import { selectNotifyTask, selectSelectTask } from '../selectors'
import { getCssClassName } from '../../../common/utils/generateClassName'
import './MoreInfoModal.scss'
import { navigateTo } from '../../../common/actions/navigate'
import { setPreviousPage } from '../../InnerRouter/actions'

const mainCssClass = getCssClassName('more-info-modal')

function MoreInfoModal({
  open, className, onCancel
}: ModalProps) {
  const dispatch = useDispatch()
  const selectTask = useSelector(selectSelectTask)
  const notifyTask = useSelector(selectNotifyTask)

  const task = useMemo(() => notifyTask || selectTask, [selectTask, notifyTask])

  const handleClickNavigate = (e) => {
    if (e.ctrlKey || e.metaKey || e.which === 2) {
      window.open(`/candidate/${task?.candidate_id}`, '_blank')
    } else {
      dispatch(navigateTo(`/candidate/${task?.candidate_id}`))
      dispatch(setPreviousPage({
        navigate: '/tasks',
        caption: 'Задачи'
      }))
    }
  }

  return (
    <CommonModal
      open={open}
      onCancel={onCancel}
      centered
      title={task?.name}
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      footer={null}
    >
      <div className={mainCssClass}>
        <Typography.Text className="task-card_description">
          {task?.description}
        </Typography.Text>
        <Typography.Text className="task-card_author">
          Автор:
          {' '}
          {task?.author_label}
        </Typography.Text>
        <Typography.Text className="task-card_deadline_at">
          Выполнить до
          {' '}
          {task?.deadline_at}
        </Typography.Text>
        {!!task?.candidate_id && (

          <Typography.Paragraph className="task-card_deadline_at">
            Кандидат:
            {' '}
            <Typography.Text className="task-card_candidate" onClick={handleClickNavigate}>
              {task.candidate_label}
            </Typography.Text>
          </Typography.Paragraph>
        )}
        <Typography.Text className="task-card_deadline_at">
          Телефон:
          {' '}
          {task?.candidate_phone || ''}
        </Typography.Text>

      </div>
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(MoreInfoModal))
