/* eslint-disable react/function-component-definition */
/* eslint-disable indent */
import React, {
    FC, memo, useCallback, useMemo
  } from 'react'
  import { useDispatch, useSelector } from 'react-redux'
  import cn from 'classnames'
  import { Column } from 'react-table'
  import dayjs from 'dayjs'
  import IntlCollator from 'intl'
  import customParseFormat from 'dayjs/plugin/customParseFormat'
  import { TClassName } from '../../../../common/types/props'
  import { getCssClassName } from '../../../../common/utils/generateClassName'
  import Table from '../../../../common/components/table/Table'
  import { TInterview } from '../../../../common/api/interviews'
  import { Heading } from '../../../../common/components/Text'
  import { TSourseOfCandidates } from '../../types'
  import TableAnalytic from '../../../../common/components/table/TableAnalytic'
  import { selectPlanFact } from '../../selectors'
  import './ByVacancyTable.scss'

  dayjs.extend(customParseFormat)

  const mainCssClass = getCssClassName('by-vacancy-table')
  type TProps= {
      sourse: TSourseOfCandidates[]
  } & TClassName
  type TRowData = {
      priority: string
      business_unit: string
      client: string
      vacancy: string
      chart:number
      quantity_item:number
      quantity_item_close:number
      shortage:number
      responsible_recruter:string
      numbers_works:number
      numbers_perdays_candidate:number
      original_plan: number
      change_plan:number
      comment:string
      creation_date:number
      date_acceptance_application:number
      people_fired:number

  }

  const useColumns = (): Column<any>[] => (
    useMemo(
      () => ([
        {
          accessor: 'vacancy_title',
          Header: 'Вакансия',
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        },
        {
          accessor: 'request_plan_title',
          Header: 'План заявок',
          sortType: (rowA, rowB, columnId, desc) => {
            const valueA = rowA.original[columnId]
            const valueB = rowB.original[columnId]

            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
            }

            return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
          }
        },
        {
            accessor: 'request_fact_title',
            Header: 'Факт заявок',
            sortType: (rowA, rowB, columnId, desc) => {
              const valueA = rowA.original[columnId]
              const valueB = rowB.original[columnId]

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
              }

              return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
            }
          },
          {
            accessor: 'shortage_title',
            Header: 'Недобор',
            sortType: (rowA, rowB, columnId, desc) => {
              const valueA = rowA.original[columnId]
              const valueB = rowB.original[columnId]

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
              }

              return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
            }
          }

      ]),
      []
    )
  )

  const useRowsData = (sourse: TProps[]) => {
    const dispatch = useDispatch()
    const handleRowClick = useCallback(
      (row) => {
        const { original: { id } } = row
      },
      []
    )
    const rows: TRowData[] = useMemo(
      () => (
        sourse?.length ? sourse.map<TRowData>((el) => ({
          vacancy_title: el.vacancy_value,
          request_plan_title: el.request_plan_value || '0',
          request_fact_title: el.request_fact_value || '0',
          shortage_title: el.shortage_value || '0'

        })) : []
      ),
      [sourse]
    )
    return { rows, handleRowClick }
  }
  const ByVacancyTable: FC = ({ className }) => {
    const dataPlan = useSelector(selectPlanFact)
    const columns = useColumns()
    const { rows, handleRowClick } = useRowsData(dataPlan ? dataPlan.analyticRow : [])

    return (
      <div>
        <TableAnalytic
          className={cn(mainCssClass, className)}
          columns={columns}
          data={rows}
          sortabled
          withoutLastColumnElement
          handleRowClick={handleRowClick}
          withoutHeaderDividers
        />
      </div>

    )
  }

  export default memo(ByVacancyTable)
