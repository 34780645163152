import React, { memo, useState } from 'react'
import { Modal, ModalProps } from 'antd'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import { selectDictionaryOptionsByName } from '../selectors'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import './SimpleModalCandidate.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { TOption } from '../../../common/types/dictionaries'
import CommonModal from '../../../common/components/CommonModal/CommonModal'

const mainCssClass = 'simpleModalCandidate'

interface ModalPropsWithOk extends Omit<ModalProps, 'onOk'> {
  onOk: (data: any) => void,
  stageOptions: TOption[],
  isFunnel: boolean
  self?: boolean
}

function ModalTransferStage({
  onOk, open, className, onCancel, stageOptions, isFunnel
}: ModalPropsWithOk) {
  const dispatch = useDispatch()
  const { control, handleSubmit, reset } = useForm<TFormValues>()

  type TFormValues = {
    stage: string
  }

  const onSubmit = (data: any) => {
    onOk?.(data)
  }

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      reset()
      onCancel(e)
    }
  }

  return (
    <CommonModal
      centered
      open={open}
      onOk={handleSubmit(onSubmit)}
      onCancel={handleModalClose}
      title="Перевести на этап"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <SelectFormControl
        label="Этап"
        required
        name="stage"
        control={control}
        placeholder="Выберите этап"
        options={stageOptions}
      />
    </CommonModal>
  )
}

export default memo<ModalPropsWithOk>(withErrorBoundary<ModalPropsWithOk>(ModalTransferStage))
