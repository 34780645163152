/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Button, Typography } from 'antd'

import { useForm } from 'react-hook-form'
import SelectFormControl from '../../../../common/components/formcontrol/SelectFormControl'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { Heading } from '../../../../common/components/Text'
import RejectedCandidatesTable from './RejectedCandidatesTable'
import AddedCandidatesTable from './AddedCandidatesTable'
import { reset } from '../../../Systems'
import {
  selectLoading, selectMethod1, selectMethod2, selectSourseOfCandidates
} from '../../selectors'
import { selectFilterString } from '../../../../features/reportFilter'
import { getSourseOfCandidates } from '../../actions'
import './SourseOfCandidates.scss'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { Preloader } from '../../../../common/components/Preloader/Preloader'
import SourceBar from './SourceBar'

const mainCssClass = getCssClassName('sourse-candidates')

function SourceOfCandidates() {
  const dispatch = useDispatch()
  const sourseOfCandidates = useSelector(selectSourseOfCandidates)
  const filter = useSelector(selectFilterString)
  const filtersString = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)

  const analyticRow1 = sourseOfCandidates?.[0]?.analyticRow || []

  const analyticRow2 = sourseOfCandidates?.[1]?.analyticRow || []

  useEffect(() => {
    if (filtersString) {
      dispatch(getSourseOfCandidates({ filter }))
    }
  }, [filter])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )
  useEffect(() => {
  }, [loading])
  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__title-block`}>
        <Heading.H3>
          Источники кандидатов
        </Heading.H3>
      </div>
      {loading && <Preloader size="md" />}
      {filtersString
          && !loading && (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className={`${mainCssClass}__title-block`}>
            <Heading.H4>
                Откликнувшиеся
            </Heading.H4>
            <Typography.Paragraph>
                {sourseOfCandidates?.[0].total}
              </Typography.Paragraph>
          </div>

            {!!analyticRow1.length
            && analyticRow1.map((el) => (
              <div>
                {el.label}
                <SourceBar
                  className={`${mainCssClass}__bar`}
                  labels={el?.sub_categories?.map((elem) => elem.label)}
                  dataValues={el?.sub_categories?.map((elem) => elem.count)}
                />
                <AddedCandidatesTable sourse={el?.sub_categories} />
              </div>
            ))}

            {/* <div className={`${mainCssClass}__table`}>
              <Bar options={options} className={`${mainCssClass}__bar`} data={data} />
            </div>
          <div>
              <RejectedCandidatesTable sourse={sourseOfCandidates} />
            </div> */}
            <div className={`${mainCssClass}__title-block`}>
            <Heading.H4>
                Добавленные
            </Heading.H4>
            <Typography.Paragraph>
                {sourseOfCandidates?.[1].total}
              </Typography.Paragraph>
          </div>

          {!!analyticRow2.length
            && analyticRow2.map((el) => (
              <div>
                {el.label}
                <SourceBar
                  className={`${mainCssClass}__bar`}
                  labels={el?.sub_categories?.map((elem) => elem.label)}
                  dataValues={el?.sub_categories?.map((elem) => elem.count)}
                />
                <RejectedCandidatesTable sourse={el?.sub_categories} />
              </div>
            ))}
          </div>
      )}
      {!filtersString && !loading
          && (
            <div className={`${mainCssClass}_filter`}>
              <span>
                Отчет появится после настройки фильтров.
                <br />
                Настройте фильтры и нажмите кнопку (Применить)
              </span>
            </div>
          )}

    </div>

  )
}

export default memo(withErrorBoundary(SourceOfCandidates))
