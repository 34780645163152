import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'userAdd'

function UserAdd({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5C7.34315 5 6 6.34315 6 8C6 9.65685 7.34315 11 9 11C10.6569 11 12 9.65685 12 8C12 6.34315 10.6569 5 9 5ZM4 8C4 5.23858 6.23858 3 9 3C11.7614 3 14 5.23858 14 8C14 10.7614 11.7614 13 9 13C6.23858 13 4 10.7614 4 8ZM19 9C19.5523 9 20 9.44772 20 10V12H22C22.5523 12 23 12.4477 23 13C23 13.5523 22.5523 14 22 14H20V16C20 16.5523 19.5523 17 19 17C18.4477 17 18 16.5523 18 16V14H16C15.4477 14 15 13.5523 15 13C15 12.4477 15.4477 12 16 12H18V10C18 9.44772 18.4477 9 19 9ZM4.20266 15.3395C5.47422 14.4918 7.17326 14 9 14C10.8267 14 12.5258 14.4918 13.7973 15.3395C15.0619 16.1826 16 17.457 16 19C16 19.5523 15.5523 20 15 20C14.4477 20 14 19.5523 14 19C14 18.3338 13.5949 17.6083 12.6879 17.0036C11.7879 16.4036 10.487 16 9 16C7.51303 16 6.21207 16.4036 5.31206 17.0036C4.40506 17.6083 4 18.3338 4 19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19C2 17.457 2.93809 16.1826 4.20266 15.3395Z"
        fill="#404542"
      />
    </svg>
  )
}
export default UserAdd
