import React, { CSSProperties } from 'react'

import cn from 'classnames'

import '../Typography.scss'

import { InternalText, InternalTextProps, InternalTextType } from './InternalText'

export type TextProps = Omit<InternalTextProps, 'tag'> & { style?: CSSProperties };

interface Text extends InternalTextType {
  LongMd: typeof LongMd;
  LongSm: typeof LongSm;
  SmallMd: typeof SmallMd;
  SmallSm: typeof SmallSm;
  Cap: typeof Cap;
  ButtonMd: typeof ButtonMd;
  ButtonSm: typeof ButtonSm;
}

export const Text: Text = InternalText as Text

Text.LongMd = LongMd
Text.LongSm = LongSm
Text.SmallMd = SmallMd
Text.SmallSm = SmallSm
Text.Cap = Cap
Text.ButtonMd = ButtonMd
Text.ButtonSm = ButtonSm

/**
 *
 * Базовый размер шрифта Body / 15 Long
 */
function LongMd({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('long16', className)} {...rest} />
}

function LongSm({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('long14', className)} {...rest} />
}

function SmallMd({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('small16', className)} {...rest} />
}

function SmallSm({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('small14', className)} {...rest} />
}

function Cap({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('cap12', className)} {...rest} />
}

function ButtonMd({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('button16', className)} {...rest} />
}

function ButtonSm({ className, ...rest }: TextProps) {
  return <InternalText tag="span" className={cn('button14', className)} {...rest} />
}
