/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import $api, { API_URL } from './http'
import {
  TAddTag,
  TCandidate,
  TCandidateUpdate,
  TComment,
  TDATA,
  TDelTag,
  TStageRequest,
  TCandidates,
  TRemoveFromRequest,
  TRequestNote,
  TCandidateResponseUpdate
} from '../../compositions/candidate/types'
import {
  TArchiveCandidateRequest,
  TRequestAssignVacancy,
  TUpdateCandidateStatusRequest
} from '../../features/candidateModals/actions'
import { TGetCandidateRequest, TRequestCandidateCSV } from '../../compositions/ManagamentCandidates/actions'
import {
  TCandidateQuestionnaire,
  TRequestAppoint,
  TRequestCandidateHistory, TRequestInnFind,
  TRequestPatchCandidateComments,
  TRequestPhoneFind,
  TRequestRespondHandler,
  TRequestStageStatusCandidate,
  TSlots
} from '../../compositions/candidate/actions'
import candidate from '../../compositions/candidate/components/Candidate'
import { SERVER_DATE_TIME_FORMAT } from '../utils/dateFormats'
import { TStageMassRequest } from '../../compositions/Funnel/types'
import { TDashboardResponse } from '../../compositions/ManagamentCandidates/types'

axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`

export type THistoryCandidateData =
    {
      action: string,
      candidate: {
        candidate_id: number,
        candidate_name: string
      },
      user: {
        user_id: number,
        user_name: string
      },
      before: [
        {
          attribuyte_name: string
        }
      ],
      after: [
        {
          attribuyte_name: string
        }
      ],
      date: string
    } []
export type TOldHistoryCandidateData = {
  history: [
    {
      after: string,
      action: string,
      before: string
    }
  ]
} []

export async function fetchGetCandidate(id: string): Promise<AxiosResponse<TCandidate>> {
  const response = await $api.get<TCandidate>(`/api/v1/candidates/${id}`)
  return response
}
export type TResponseHistoryCandidateData = {
  DATA: THistoryCandidateData[]
  ERR: string
}
export async function fetchGetHistoryCandidateData(payload: TRequestCandidateHistory): Promise<AxiosResponse<TResponseHistoryCandidateData>> {
  const response = await $api.get(`/api/v1/candidate/get-data-log?candidate_id=${payload.candidate_id}&required=1&page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filter ? payload.filter : ''}`)
  return response
}
export type TResponseOldHistoryCandidateData = {
  DATA: TOldHistoryCandidateData[]
  ERR: string
}

export async function fetchDeleteCandidate(id: string): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/candidates/${id}`)
  return response
}
export async function fetchGetOldHistoryCandidateData(payload: TRequestCandidateHistory): Promise<AxiosResponse<TResponseOldHistoryCandidateData>> {
  const response = await $api.get(`/api/v1/candidate/get-old-history?candidate_id=${payload.candidate_id}&required=1&page=${payload.page || 1}&per-page=${payload.perPage || 10}${payload.filter ? payload.filter : ''}`)
  return response
}
export async function fetchPatchCandidate(
  payload: TCandidateResponseUpdate | TRequestAssignVacancy | TRequestNote | TRequestAppoint | TRequestStageStatusCandidate |TArchiveCandidateRequest
): Promise<AxiosResponse<TCandidate>> {
  const { date_interview, photo } = payload
  const newDate = dayjs(date_interview, 'DD.MM.YYYY HH:mm:ss').format(SERVER_DATE_TIME_FORMAT)
  if (newDate && payload?.date_interview) {
    payload.date_interview = newDate
  }
  delete payload.photo
  const response = await $api.patch<TCandidate>(`/api/v1/candidates/${payload.id}`, payload)
  return response
}

export async function fetchPostCandidate(payload: TDATA): Promise<AxiosResponse<TCandidate>> {
  const response = await $api.post<TCandidate>('/api/v1/candidates', payload)
  return response
}

export async function fetchPostAddTag(payload: TAddTag): Promise<AxiosResponse<TCandidate>> {
  const response = await $api.post<TCandidate>('/api/v1/candidate/add-tag', payload)
  return response
}

export async function fetchDeleteTag(payload: TDelTag): Promise<AxiosResponse<any>> {
  const encodedLabel = payload.label !== undefined ? encodeURIComponent(payload.label) : ''

  const response = await $api.delete(
    `/api/v1/candidate/delete-tag?candidate_id=${payload.candidate_id}&label=${encodedLabel}`
  )
  return response
}

export async function fetchPostAddComment(payload: TComment): Promise<AxiosResponse<TCandidate>> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data' // Установка Content-Type на multipart/form-data
    }
  }
  const response = await $api.post('/api/v1/candidate-comments', payload, config)
  return response
}

export async function fetchPatchUpdateStage(payload: TStageRequest): Promise<AxiosResponse<TCandidate>> {
  const candidate_list = payload?.candidateArray ? payload.candidateArray : [payload.candidate_id]

  const response = await $api.patch<TCandidate>(`/api/v1/candidate/set-stage?stage=${payload.stage}`, { candidate_list })
  return response
}

export async function fetchPatchUpdateMassStage(payload: TStageMassRequest): Promise<AxiosResponse<TCandidate>> {
  const response = await $api.post<TCandidate>(`/api/v1/candidate/set-mass-stage?${payload.filtersString}${payload?.self === 'true' ? '&self=1' : ''}`, { stage_id: payload.stage_id })
  return response
}
export async function fetchGetCandidates(queryParams: TGetCandidateRequest): Promise<AxiosResponse<TCandidates>> {
  const sort = queryParams.order === 'desc' ? 'updated_at' : '-updated_at'
  const response = await $api.get<TCandidates>(
    `/api/v1/candidates?sort=${sort}&fields=id,name,photo,last_name,
second_name,status,request_list,vacancy_list,merge_status,stage_id,address,phone,city,comment_list,is_duplicates,cache,customer_list,updated_at&${((queryParams.filters && queryParams.filters.includes('search')) || queryParams.order) ? '' : 'sort=-updated_at&'}per-page=${queryParams?.perPage || 10}&page=${queryParams?.page || 1}${
  queryParams?.filters || ''
}${queryParams?.self === 'true' ? '&self=1' : ''}`
  )
  return response
}
export async function fetchGetCandidatesFunnel(queryParams: TGetCandidateRequest): Promise<AxiosResponse<TCandidates>> {
  const sort = queryParams.order === 'desc' ? 'updated_at' : '-updated_at'
  const response = await $api.get<TCandidates>(
    `/api/v1/candidates?sort=${sort}&fields=id,name,last_name,
second_name,status,stage_id,address,phone,city,cache,updated_at&${((queryParams.filters && queryParams.filters.includes('search')) || queryParams.order) ? '' : 'sort=-updated_at&'}per-page=${queryParams?.perPage || 10}&page=${queryParams?.page || 1}${
  queryParams?.filters || ''
}${queryParams?.self === 'true' ? '&self=1' : ''}&vertex=true`
  )
  return response
}

export async function fetchPatchUpdateStatusCandidate(payload: TArchiveCandidateRequest): Promise<AxiosResponse<TCandidates>> {
  const response = await $api.patch<TCandidate>(
    `/api/v1/candidate/set-status?candidate_id=${payload.id}&status=${payload.status}`,
    payload
  )
  return response
}
export async function fetchGetCandidatesByVacancy(id: number): Promise<AxiosResponse<TCandidates>> {
  const response = await $api.get<AxiosResponse<TCandidates>>(`/api/v1/candidate/get-for-vacancy?vacancy_id=${id}`)
  return response
}

export async function fetchDeleteRequestCandidate(payload: TRemoveFromRequest): Promise<AxiosResponse<TCandidate>> {
  const response = await $api.delete(
    `/api/v1/request/remove-link?candidate_id=${payload.candidate_id}&request_id=${payload.request_id}`
  )
  return response
}

export async function fetchGetQuestionnaire(): Promise<AxiosResponse<any>> {
  const response = await $api.get('/api/v1/questionnares')
  return response
}
export async function fetchPostQuestionnaire(payload: TCandidateQuestionnaire): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/questionnare-answer/update-candidate-answers', payload)
  return response
}
export async function fetchPostRemoveDocument(id: string): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/candidate/remove-document?document_id=${id}`)
  return response
}

export async function fetchPostCreateCSV(payload: TRequestCandidateCSV): Promise<AxiosResponse<any>> {
  const response = await $api.post(`/api/v1/candidate/export-excel${payload.filter ? `?${payload.filter.slice(1)}` : ''}`)
  return response
}

export async function fetchGetCounters(): Promise<AxiosResponse<TCounters[]>> {
  const response = await $api.get('/api/v1/candidate/get-stage-counters')
  return response
}

export async function fetchGetFunnel(filtersString: string, self: string, page?:string, download?: boolean): Promise<AxiosResponse<TCounters[]>> {
  const response = await $api.get(`/api/v1/candidate/get-candidate-vertex?sort=-updated_at&fields=id,name,last_name,second_name,status,stage_id,address,phone,date_lid_respond,date_internship,time_internship,city,cache,updated_at&per-page=10&page=${page || 1}${self === 'true' ? '&self=1' : ''}${filtersString}${download ? '&download=true' : ''}`)
  return response
}

export type TRequestCandidateComments = {
  id: number |string
  page:number
}

export async function fetchGetCandidateComments(payload:TRequestCandidateComments): Promise<AxiosResponse<any>> {
  // eslint-disable-next-line max-len
  const response = await $api.get(`/api/v1/candidate-comments?candidate_id=${payload.id}&page=${payload.page}&per-page=10`)
  return response
}

export type TCounters = {
  stage_id: number,
  label: string,
  count: number
}

export async function fetchPatchCandidateComments(payload:TRequestPatchCandidateComments): Promise<AxiosResponse<any>> {
  if (payload instanceof FormData) {
    const id = payload.get('id')
    const response = await $api.patch(`/api/v1/candidate-comments/${id}`, payload)
    return response
  }
  const response = await $api.patch(`/api/v1/candidate-comments/${payload.id}`, payload)
  return response
}
export async function fetchGetStageState(payload: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`/api/v1/stage-states?filter[stage_id]=${payload}`)
  return response
}

export async function fetchPostFindPhone(payload: TRequestPhoneFind | TRequestInnFind): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/candidate/find-duplicate', payload)
  return response
}

export async function fetchPostDuplicatesCandidates(id: string): Promise<AxiosResponse<any>> {
  const response = await $api.post('/api/v1/candidate/merge-duplicates', { candidate_id: id })
  return response
}

export async function fetchDeleteComment(id: number): Promise<AxiosResponse<any>> {
  const response = await $api.delete(`/api/v1/candidate-comments/${id}`)
  return response
}

export type TRequestCandidateDuplicate = {
  id: number
  isMerged?: boolean
}

export async function fetchGetCandidateDuplicateList(payload: TRequestCandidateDuplicate): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/candidate/get-duplicate-list?candidate_id=${payload.id}${payload.isMerged ? `&isMerged=${payload.isMerged}` : ''}`)
  return response
}

export async function fetchGetRespondsCandidate(id: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/eventlead/get-by-type?candidate_id=${id}`)
  return response
}

export async function fetchPostRespondHandler(payload: TRequestRespondHandler): Promise<AxiosResponse<any>> {
  const response = await $api.post('api/v1/eventlead/handler', payload)
  return response
}

export async function fetchGetHistoryAsterisk(id: number): Promise<AxiosResponse<any>> {
  const response = await $api.post(`api/v1/fraxvill/get-phone-records?candidate_id=${id}`)
  return response
}

export async function fetchGetCandidateDocument(id: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/candidate/document-list?candidate_id=${id}`)
  return response
}

export async function fetchGetCandidateQuestionnaire(id: number): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/candidate/questionnare?candidate_id=${id}`)
  return response
}

export async function fetchGetCandidateHistoryV2(payload: TRequestCandidateHistory): Promise<AxiosResponse<any>> {
  const response = await $api.get(`api/v1/candidate/get-history-json?candidate_id=${payload.candidate_id}&page=${payload.page || 1}&per-page=${payload.perPage || 100}${payload.filter ? payload.filter : ''}`)
  return response
}

export async function fetchGetCandidateDashboard(payload: string): Promise<AxiosResponse<TDashboardResponse>> {
  const response = await $api.get(`api/v1/candidate/dashboard${payload ? `?${payload}` : ''}`)
  return response
}
