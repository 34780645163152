import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TRequestLeads } from '../../common/api/leads'


export type TCandidateHeaders = {
    current:number,
    total:number,
    totalCount: number
  }

export const getLeads = createAction<TRequestLeads>('leads/getCandidates')
export const setLeads = createAction<any | null>('leads/setCandidates')
export const setLoading = createAction<boolean>('leads/setLoading')
export const setHeaders = createAction<TCandidateHeaders>('leads/setHeaders')
export const setOpenModalAutoCall = createAction<boolean>('leads/setOpenModalAutoCall')

export const getDictionaries = createAction<TDictionariesNames>('leads/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('leads/dictionaries/setDictionaries')


export type TRequestLeadsCSV = {
    filter: string
  }

export const exportCSV = createAction<TRequestLeadsCSV>('leads/exportCSV')

export const exportMovementCSV = createAction<TRequestLeadsCSV>('leads/exportMovementCSV')

export const reset = createAction('leads/reset')

export type TDefDataLeads = {
    page: number,
    perPage: number,
    filters: string,
    search:string
}

export const setDefDataLeads = createAction<TDefDataLeads>('leads/setDefDataLeads')

export const setSearchLeads = createAction<string>('leads/setSearchLeads')
export const openLeadsFilter = createAction<boolean>('leads/openLeadsFilter')

export const setFilter = createAction<string>('leads/setFilter')



