import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import SantaClausChristmas from '../../common/svg/SantaClausChristmas'
import GiftChristmas from '../../common/svg/GiftChristmas'
import ChristmasTree from '../../common/svg/ChristmasTree'
import { selectIconNumber } from '../../compositions/InnerRouter/selectors'

function ChristmasIcon() {
  const iconNumber = useSelector(selectIconNumber)

  const renderContent = useCallback(() => {
    switch (iconNumber) {
      case 1:
        return <ChristmasTree />
      case 2:
        return <SantaClausChristmas />
      case 3:
        return <GiftChristmas />
      default:
        return null
    }
  }, [iconNumber])

  return <div>{renderContent()}</div>
}

export default ChristmasIcon
