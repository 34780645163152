import React, {
  memo, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Button, Tabs, Typography } from 'antd'
import { getCssClassName } from '../../../../common/utils/generateClassName'
import { Heading } from '../../../../common/components/Text'
import { reset } from '../../../Systems'
import RejectedCandidatesTable from '../vertex/RejectedCandidatesTable'
import AddedCandidatesTable from '../vertex/AddedCandidatesTable'
import RejectionLeaderTable from './RejectionLeaderTable'
import { getPlanFact, setPlanFactVacancy, setScenario } from '../../actions'
import withErrorBoundary from '../../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectLoading, selectPlanFact, selectPlanFactVacancy, selectVertex
} from '../../selectors'
import { selectFilterString } from '../../../../features/reportFilter'
import TabPane = Tabs.TabPane
import ApplicationPriorityTable from './ApplicationPriorityTable'
import MonitorPlay from '../../../../common/svg/MonitorPlay'
import { Preloader } from '../../../../common/components/Preloader/Preloader'
import './ClosingOfApplications.scss'
import ByVacancyTable from './ByVacancyTable'

const mainCssClass = getCssClassName('closing-applications')

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'none' as const
    },
    title: {
      display: false
    }
  }
}

export function ClosingOfApplications() {
  const dispatch = useDispatch()
  const filter = useSelector(selectFilterString)
  const loading = useSelector(selectLoading)
  const by_vacancy = useSelector(selectPlanFactVacancy)
  const dataPlan = useSelector(selectPlanFact)

  const handleTabClick = (key:string) => {
    dispatch(setPlanFactVacancy(key))
  }

  // const {
  //   control, watch, getValues, setValue
  // } = useForm<TFormValues>({ defaultValues: { method1: 'summary', method2: 'summary' } })

  const labels = useMemo(() => (dataPlan && by_vacancy === 'by_request' && dataPlan.graphics ? dataPlan.graphics.map((el) => el.label) : []), [dataPlan, by_vacancy])
  const dataValues = useMemo(() => (dataPlan && by_vacancy === 'by_request' && dataPlan.graphics ? dataPlan?.graphics.map((item) => item.count) : []), [dataPlan, by_vacancy])

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataValues,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  useEffect(() => {
    if (filter) {
      dispatch(getPlanFact({ by_vacancy, filter }))
    }
  }, [filter, by_vacancy])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )
  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}__title-block`}>
        <Heading.H3>
          План/факт закрытия заявки
        </Heading.H3>
      </div>

      {loading && <Preloader size="md" />}
      {!loading && filter && (
        <>
          <div className={`${mainCssClass}__up-block`}>
            <div className={`${mainCssClass}__tabs`}>
              <Tabs onChange={(key) => handleTabClick(key)} defaultActiveKey={by_vacancy}>
                <TabPane key="by_request" tab="По заявкам" />
                <TabPane key="by_vacancy" tab="По вакансиям" />
              </Tabs>
            </div>
          </div>
          {
            by_vacancy === 'by_request' && (
              <div className={`${mainCssClass}__table`}>
                <Bar options={options} data={data} height={labels.length * 20} />
              </div>
            )
          }
          <div>
            {by_vacancy === 'by_request' ? <ApplicationPriorityTable /> : <ByVacancyTable />}
          </div>
        </>
      )}

      {!filter && !loading
      && (
        <div className={`${mainCssClass}_filter`}>
          <span>
            Отчет появится после настройки фильтров.
            <br />
            Настройте фильтры и нажмите кнопку (Применить)
          </span>
        </div>
      )}
    </div>

  )
}
export default memo(withErrorBoundary(ClosingOfApplications))
