import React, { FC, memo, useCallback } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { useDispatch } from 'react-redux'
import MenuDrop from '../../../common/svg/MenuDrop'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'

type TProps = {
  id: any
  handleOpenModal: Function
}
const ButtonsTable: FC<TProps> = ({ id, handleOpenModal }) => {
  const dispatch = useDispatch()

  const items: MenuProps['items'] = [
    {
      key: 'stop',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={() => handleOpenModal()}
        >
          Остановить
        </Button>
      )
    },
    {
      key: 'reboot',
      label: (
        <Button type="ghost" size="large" onClick={() => handleOpenModal()}>
          Перезапустить пул
        </Button>
      )
    }, {
      key: 'update',
      label: (
        <Button
          type="ghost"
          size="large"
          onClick={() => handleOpenModal()}
        >
          Обновить
        </Button>
      )
    }]

  return (
    <Dropdown
      menu={{
        items,
        selectable: true
      }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Button type="ghost" icon={<MenuDrop />} size="large" />
    </Dropdown>
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(ButtonsTable))
