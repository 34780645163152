import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { useSelector } from 'react-redux'
import { getCssClassName } from '../../../common/utils/generateClassName'

import { TOption } from '../../../common/types/dictionaries'
import ModalStageOnProgress from './ModalStageOnProgress'
import { selectCandidate } from '../selectors'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import './ProgressBar.scss'
import { Typography } from 'antd'

const mainCssClass = getCssClassName('progress-bar-candidate')

type TProgressBarProps = {
    stages: TOption[],
    currentStage: number,
    isMobile: boolean
}

function ProgressBar({ stages, currentStage, isMobile } : TProgressBarProps) {
  const {
    rbacControl, role, has_active_interview
  } = useEntityContext()
  const scrollContainerRef = useRef(null)
  const currentStageRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [nextStage, setNextStage] = useState<[number, string]>([0, ''])
  const candidate = useSelector(selectCandidate)

  const activeInterview = useMemo(() => {
    if (has_active_interview && 'id' in has_active_interview) {
      return has_active_interview
    }
    return null
  }, [has_active_interview])

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartX(e.pageX - scrollContainerRef?.current?.offsetLeft)
    setScrollLeft(scrollContainerRef?.current?.scrollLeft)
  }

  const handleMouseLeave = () => {
    if (isDragging) setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - scrollContainerRef.current.offsetLeft
    const walk = (x - startX) * 1.5 // Настройте скорость прокрутки
    scrollContainerRef.current.scrollLeft = scrollLeft - walk
  }

  const candidateStageColor = useMemo(() => {
    if (stages && currentStage && stages.length) {
      const actualStage = stages.find((el) => el.value === currentStage)
      return actualStage?.color
    }
    return 'E8ECE9'
  }, [stages, currentStage])

  const candidateStageIndex = useMemo(() => {
    if (stages && currentStage) {
      const actualStage = stages.findIndex((el) => el.value === currentStage)
      return actualStage === -1 ? undefined : actualStage
    }
    return undefined
  }, [stages, currentStage])

  const handleOpenModal = (stage: any) => {
    if (currentStage !== stage.value
        && rbacControl?.candidate?.edit_view_access === 1
        && role?.role_id !== 'courier_curator'
    ) {
      setNextStage([stage.value, stage.label])
      setOpenModal(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (currentStageRef.current) {
      if (isMobile) {
        scrollContainerRef.current.scrollLeft = currentStageRef.current.offsetLeft
      } else {
        scrollContainerRef.current.scrollLeft = currentStageRef.current.offsetLeft / 2
      }
    }
  }, [currentStageRef.current, scrollContainerRef, currentStage, isMobile])

  return (
    <div
      key={currentStage}
      className={mainCssClass}
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{
        display: 'flex',
        overflowX: 'auto',
        cursor: isDragging ? 'grabbing' : 'grab', // Иконка при перетаскивании
        userSelect: 'none' // Отключает выделение текста при перетаскивании
      }}
    >
      {stages && stages?.map((el, i) => (
        <div
          className={`${mainCssClass}__item`}
          style={{ position: 'relative' }}
          key={el.value}
          ref={Number(el.value) === Number(currentStage) ? currentStageRef : null}
        >
          <div className={`${mainCssClass}__item_container`}>
            <div
              className={`${mainCssClass}__item_container_rhombus`}
              style={{
                backgroundColor:
            (candidateStageIndex && candidateStageColor && (i <= candidateStageIndex)) ? candidateStageColor : 'E8ECE9'
              }}
              onClick={() => handleOpenModal(el)}
            >
              <span
                className={`${mainCssClass}__item_container_rhombus_content`}
              >
                {i + 1}
              </span>
            </div>
            {i !== stages.length - 1
          && (
            <div
              className={`${mainCssClass}__item_container_line`}
              style={{
                backgroundColor:
                 (candidateStageIndex && candidateStageColor && (i < candidateStageIndex)) ? candidateStageColor : 'E8ECE9'
              }}
            />
          )}

          </div>
          <div
            className={`${mainCssClass}__item_label`}
            style={{
              transform: i === 0 ? 'none' : i === stages.length - 1 ? 'translateX(calc(-100% + 24px))' : 'translateX(calc(-50% + 12px))',
              textAlign: i === 0 ? 'left' : i === stages.length - 1 ? 'right' : 'center'
            }}
          >
            <Typography.Text
              onClick={() => handleOpenModal(el)}
              className={`${mainCssClass}__item_label_ellipsis`}
              ellipsis={{ tooltip: el.label }}
            >
              {el.label}
            </Typography.Text>
          </div>
        </div>
      ))}
      {openModal && (
        <ModalStageOnProgress
          open={openModal}
          onCancel={closeModal}
          stageId={nextStage[0]}
          stageLabel={nextStage[1]}
          candidate={candidate}
        />
      )}
    </div>
  )
}

export default ProgressBar
