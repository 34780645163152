import React, {
  FC, memo, useCallback, useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Column } from 'react-table'

import { Checkbox } from 'antd/lib'
import { NavLink } from 'react-router-dom'
import Vacation from './Vacation'
import { TClassName } from '../../../common/types/props'
import ButtonsTable from './ButtonsTable'
import { getCssClassName } from '../../../common/utils/generateClassName'
import Table from '../../../common/components/table/Table'
import { TDATA } from '../../candidate/types'
import { navigateTo } from '../../../common/actions/navigate'
import CandidateStatusTableRow from './CandidateStatusTableRow'
import CandidateStageTableRow from './CandidateStageTableRow'
import CandidateCommentTableRow from './CandidateCommentTableRow'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './CandidateTable.scss'
import Duplicate from './Duplicate'
import TableFluidity from "../../../common/components/table/TableFluidity";

const mainCssClass = getCssClassName('client-contracts-table')

type TProps = {
  candidates: TDATA[] | []
} & TClassName
type TRowData = {
  checkbox: JSX.Element
  name: JSX.Element
  stage: JSX.Element
  city: string
  buttons: JSX.Element
}

const useColumns = (): Column<any>[] => useMemo(
  () => [
    {
      accessor: 'checkbox', Header: (<Checkbox />)
    },
    {
      accessor: 'name',
      Header: 'Кандидат',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'when_accepted',
      Header: 'Когда принят',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'when_fired',
      Header: 'Когда уволен',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'city',
      Header: 'Город',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Use String.localeCompare() for Russian alphabet sorting
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.children === 'string' && typeof valueB?.props?.children === 'string') {
          return valueA.props.children.localeCompare(valueB.props.children, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    {
      accessor: 'stage',
      Header: 'Этап',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId]
        const valueB = rowB.original[columnId]
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB, 'ru', { sensitivity: 'base' }) * (desc ? -1 : 1)
        }
        if (typeof valueA?.props?.stage === 'number' && typeof valueB?.props?.stage === 'number') {
          return valueA?.props?.stage > valueB?.props?.stage ? 1 : -1
        }

        // Default sorting behavior for non-string values
        return rowA.values[columnId] > rowB.values[columnId] ? 1 : -1
      }
    },
    { accessor: 'buttons', Header: '' }

  ],
  []
)

const useRowsData = (candidates: TDATA[]) => {
  const dispatch = useDispatch()
  const handleRowClick = useCallback((row) => {
    const {
      original: { id }
    } = row
    // dispatch(navigateTo(`/candidate/${id}`))
  }, [])
  const rows: TRowData[] = useMemo(
    () => (candidates?.length
      ? candidates.map<TRowData>(({
        name, second_name, last_name, is_duplicates, id, stage_id, city
      }) => ({
        checkbox: <Checkbox />,
        name: (
          <div className={`${mainCssClass}__name`}>
            {' '}
            {`${last_name} ${name} ${second_name}`?.length > 60
              ? `${`${last_name || ''} ${name} ${second_name || ''}`?.slice(45)}...` : `${last_name || ''} ${name} ${second_name || ''}` }
          </div>
        ),
        stage: <CandidateStageTableRow stage={stage_id} />,
        city: city || 'Не выбран',
        buttons: <ButtonsTable id={id} status={status} is_duplicates={is_duplicates} />,
        id

      }))
      : []),
    [candidates]
  )
  return { rows, handleRowClick }
}
const CandidatesTable: FC<TProps> = ({ className, candidates }) => {
  const columns = useColumns()
  const { rows, handleRowClick } = useRowsData(candidates)

  return (
    <TableFluidity
      className={cn(mainCssClass, className)}
      sortabled
      columns={columns}
      data={rows}
      withoutLastColumnElement
      handleRowClick={handleRowClick}
      withoutHeaderDividers
    />
  )
}

export default memo<TProps>(withErrorBoundary<TProps>(CandidatesTable))
