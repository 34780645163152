import React, { useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { ModalProps } from 'antd'
import RangePickerFormControl from '../../../common/components/formcontrol/RangePickerFormControl'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import AddPlusSvg from '../../../common/svg/AddPlusSvg'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  editPeriodModal, openMassSettingsModal, openPeriodModal, setSelectedPeriod
} from '../actions'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import ControlButton from '../../../common/components/Buttons/Button'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import { periodArr } from '../utils'
import CheckBoxFormControl from '../../../common/components/formcontrol/CheckBoxFormControl'
import CheckBoxOneOptionsFormControl from '../../../common/components/formcontrol/CheckBoxOneOptionsFormControl'
import EditPencil1 from '../../../common/svg/EditPencil1'
import './PeriodModal.scss'
import { selectEditPeriod, selectPeriodModal, selectSelectedPeriod } from '../selectors'

const mainCssClass = getCssClassName('period-modal')

export type TFormValuesCheckbox = {
  [key: string]: boolean;
};

export type TFormValuesYear = {
  year: number | string;
};

function PeriodModal({
  open, className
}: ModalProps) {
  const dispatch = useDispatch()
  const editPeriod = useSelector(selectEditPeriod)
  const selectedPeriod = useSelector(selectSelectedPeriod)
  const {
    control, watch, setValue, handleSubmit, getValues
  } = useForm<TFormValuesCheckbox>()
  const {
    control: controlYear, watch: watchYear, setValue: setValueYear, handleSubmit: handleSubmitYear, getValues: getValuesYear
  } = useForm<TFormValuesYear>()
  const {
    control: controlPeriod, watch: watchPeriod, setValue: setValuePeriod, handleSubmit: handleSubmitPeriod, getValues: getValuesPeriod
  } = useForm<TFormValuesYear>()
  const onCancel = () => {
    if (editPeriod) {
      dispatch(editPeriodModal(false))
      dispatch(setSelectedPeriod(null))
    } else dispatch(openPeriodModal(false))
  }
  const onSubmit = () => {
    dispatch(openPeriodModal(false))
  }

  const handleClickEditPeriod = (el) => {
    dispatch(editPeriodModal(true))
    dispatch(setSelectedPeriod(el))
  }
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // If the changed field is set to `true`, reset all other fields to `false`
      if (value[name] === true) {
        Object.keys(value).forEach((key) => {
          if (key !== name && value[key] === true) {
            setValue(key, false)
          }
        })
      }
    })

    return () => subscription.unsubscribe() // Отписываемся при размонтировании
  }, [watch, setValue])

  return (
    <CommonModal
      open={open}
      onCancel={onCancel}
      centered
      onOk={handleSubmit(onSubmit)}
      title={editPeriod ? 'Отчетный период' : 'Отчетные периоды'}
      className={cn(className)}
      width="400px"
      typeButtonAccept="primary"
      okText="Применить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={cn(mainCssClass, {
        [`${mainCssClass}_editPeriod`]: editPeriod
      })}
      >
        <NewScrollContainer>
          <div className={cn(`${mainCssClass}_list`)}>
            {editPeriod
              ? (
                <>
                  <InputFormControl name="name_period" label="Название периода" control={controlPeriod} />
                  <RangePickerFormControl name="date" control={controlPeriod} label="Даты периода" />
                  <p>
                    Все показатели плана будут перенесены из предыдущего периода, эти значения можно будет отредактировать в таблице.
                    План по рабочим дням будет назначен согласно количеству рабочих дней из выбранного периода.
                  </p>
                </>
              )
              : (
                <>
                  <SelectFormControl control={controlYear} name="year" label="Год" />
                  <ControlButton className="button" typeButton="secondary">
                    <AddPlusSvg />
                    Добавить период
                  </ControlButton>
                  {periodArr.map((el) => (
                    <div
                      key={el.id}
                      className={cn(
                        `${mainCssClass}_item`,
                        {
                          [`${mainCssClass}_item-active`]: !!watch(`name${el.id}`)
                        }
                      )}
                    >
                      <span
                        className={`${mainCssClass}_status`}
                      >
                        {el.status}
                      </span>
                      <div className={`${mainCssClass}_edit`}>
                        <CheckBoxOneOptionsFormControl name={`name${el.id}`} control={control}>
                          <span className={`${mainCssClass}_name`}>{el.name}</span>
                        </CheckBoxOneOptionsFormControl>
                        {el.status !== 'last' && <EditPencil1 onClick={() => handleClickEditPeriod(el)} />}
                      </div>
                      <div className={`${mainCssClass}_date`}>
                        <span>{el.date}</span>
                        <span>
                          Рабочих дней
                          {' '}
                          {el.work_days}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}

          </div>
        </NewScrollContainer>
      </div>
    </CommonModal>
  )
}

export default PeriodModal
