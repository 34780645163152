import { PayloadAction } from '@reduxjs/toolkit'
import {
  put, race, select, take, takeLatest
} from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  getAutoCalls,
  getGroupUserWhoCalls,
  getUsersWhoCalls,
  saveAutoCalls,
  setAutoCalls, setGroupUsersWhoCalls,
  setLoading,
  setUsersWhoCalls,
  startAutoCall,
  stopAutoCall,
  TAutoCall
} from './actions'
import { fetchGetCalendar, TCalendarResponse } from '../../common/api/calendar'
import {
  fetchGetAutoCalls, fetchGetGroupUsersWhoCalls, fetchGetStartAutoCall, fetchGetStopAutoCall, fetchGetUsersWhoCalls, fetchPostAutoCalls
} from '../../common/api/autocall'
import { useSelector } from 'react-redux'
import { selectLeadsSearch } from '../Leads'

const moduleName = 'Календарь'
function* getAutoCallsSaga() {
  try {
    yield put(setLoading(true))
    let response: AxiosResponse<TCalendarResponse>;
    ({response} = yield race({
      response: fetchGetAutoCalls(),
      cancel: take(getAutoCalls.type)
    }));
    yield put(setAutoCalls(response.data))

  } catch (error) {
    // @ts-ignore
    throw Error(error)
  } finally {
    yield put(setLoading(false))
  }
}

function* updateAutoCallSaga({ payload }: PayloadAction<TAutoCall>) {
  try {
    yield put(setLoading(true))
    const filterLeads = localStorage.getItem('leads')
    const search = yield select(selectLeadsSearch)
    const filterString = search ? `${filterLeads || ''}&search=${search}` : filterLeads || ''
    const {response} = yield race({
      response: fetchPostAutoCalls(payload, filterString),
      cancel: take(saveAutoCalls.type)
    })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось сохранить автообзвон',
          error: response as unknown as AxiosError
        })
      )
    } else {
      yield put(getAutoCalls())

    }
  } catch (error) {
    // @ts-ignore
    throw Error(error)
  } finally {
    yield put(setLoading(false))
  }
}
function* startAutoCallSaga({ payload }: PayloadAction<number>) {
  try {
    let response: AxiosResponse<TCalendarResponse>;
    ({response} = yield race({
      response: fetchGetStartAutoCall(payload),
      cancel: take(startAutoCall.type)
    }));

  } catch (error) {
    // @ts-ignore
    throw Error(error)
  }
}

function* stopAutoCallSaga({ payload }: PayloadAction<number>) {
  try {
    let response: AxiosResponse<TCalendarResponse>;
    ({response} = yield race({
      response: fetchGetStopAutoCall(payload),
      cancel: take(stopAutoCall.type)
    }));

  } catch (error) {
    // @ts-ignore
    throw Error(error)
  }
}
function* getUsersWhoCallsSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoading(true))
    const response: AxiosResponse<any[]> = yield fetchGetUsersWhoCalls(payload)
    if (response.data) {
      const optionsWhoCalls = response.data.map((item) => ({ value: item.id, label: item.fio }))
      yield put(setUsersWhoCalls(optionsWhoCalls))
    }
  } catch (error) {
    // @ts-ignore
    throw Error(error)
  } finally {
    yield put(setLoading(false))
  }
}
function* getGroupUsersWhoCallsSaga() {
  try {
    yield put(setLoading(true))
    const response: AxiosResponse<any[]> = yield fetchGetGroupUsersWhoCalls()
    if (response.data) {
      const result = Object.entries(response.data).map(([value, label]) => ({
        label,
        value
      }))
      yield put(setGroupUsersWhoCalls(result))
    }
  } catch (error) {
    // @ts-ignore
    throw Error(error)
  } finally {
    yield put(setLoading(false))
  }
}

function* autocallSaga() {
  yield takeLatest(getAutoCalls, getAutoCallsSaga)
  yield takeLatest(saveAutoCalls, updateAutoCallSaga)
  yield takeLatest(startAutoCall, startAutoCallSaga)
  yield takeLatest(stopAutoCall, stopAutoCallSaga)
  yield takeLatest(getUsersWhoCalls, getUsersWhoCallsSaga)
  yield takeLatest(getGroupUserWhoCalls, getGroupUsersWhoCallsSaga)
}

export default autocallSaga
