import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'interviewSvg'

function EditSvg({ className, size = '1' }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xl_svg: size === '8' }
      )}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#FFC6E4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.382 11.5121C23.7837 11.3816 24.2164 11.3816 24.6181 11.5121C24.9079 11.6062 25.1298 11.7658 25.3046 11.9143C25.4636 12.0493 25.6372 12.223 25.8155 12.4012L27.5983 14.184C27.7767 14.3625 27.9506 14.5363 28.0858 14.6955C28.2342 14.8703 28.3938 15.0922 28.4879 15.382C28.6184 15.7837 28.6184 16.2163 28.4879 16.618C28.3938 16.9077 28.2342 17.1296 28.0858 17.3044C27.9506 17.4637 27.7767 17.6375 27.5982 17.816L24.7112 20.703C24.7098 20.7044 24.7085 20.7057 24.7071 20.7071C24.7058 20.7085 24.7044 20.7098 24.703 20.7112L16.7071 28.7071C16.5196 28.8946 16.2652 29 16 29L12 29C11.7348 29 11.4804 28.8946 11.2929 28.7071C11.1054 28.5196 11 28.2652 11 28V24C11 23.7348 11.1054 23.4804 11.2929 23.2929L22.1615 12.4242L22.184 12.4018C22.3625 12.2233 22.5363 12.0494 22.6955 11.9142C22.8704 11.7658 23.0923 11.6062 23.382 11.5121ZM20 17.4142L13 24.4142V27L15.5858 27L22.5858 20L20 17.4142ZM24 18.5858L21.4142 16L23.5757 13.8384C23.7854 13.6288 23.9007 13.5146 23.9899 13.4389C23.9934 13.4359 23.9968 13.433 24 13.4304C24.0032 13.433 24.0066 13.4358 24.0101 13.4388C24.0993 13.5146 24.2145 13.6287 24.4242 13.8384L26.1615 15.5757C26.3712 15.7854 26.4854 15.9007 26.5611 15.9899C26.5641 15.9934 26.5669 15.9968 26.5696 16C26.5669 16.0032 26.5641 16.0065 26.5611 16.0101C26.4854 16.0993 26.3712 16.2146 26.1615 16.4242L24 18.5858Z"
        fill="#731446"
      />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" strokeWidth="2" />
    </svg>
  )
}
export default EditSvg
