import React from 'react'

export function isDefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined
}
export function devWarning(isValidCondition: boolean, message: string): void {
  if (process.env.NODE_ENV !== 'production' && !isValidCondition && console !== undefined) {
    console.error(`Warning: ${message}`)
  }
}
export function isTruthy<T>(value: T): value is Exclude<T, undefined | null | 0 | false | ''> {
  return !!value
}

export function isNotNil<T>(value: T): value is Exclude<T, undefined | null> {
  return value !== undefined && value !== null
}
export function devDeprecated<P>(deprecatedPropObj: Record<string, P>, propName: string): void {
  const propKey = Object.keys(deprecatedPropObj)[0]

  devWarning(
    !isDefined(deprecatedPropObj[propKey]),
    `${propKey} is deprecated! Use "${propName}" instead.`
  )
}

export const createGenericContext = <T extends unknown>(defaultValue?: T) => {
  const genericContext = React.createContext<T | undefined>(defaultValue)
  const useGenericContext = () => {
    const context = React.useContext(genericContext)
    if (!isDefined(context)) {
      throw new Error('useGenericContext должен быть использован внутри Provider')
    }
    return context
  }
  return [useGenericContext, genericContext.Provider] as const
}
