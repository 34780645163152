import React, {
  FC, memo, useMemo, useState
} from 'react'
import { useSelector } from 'react-redux'
import { Tag, Typography } from 'antd'
import './CandidateStatusTableRow.scss'
import cn from 'classnames'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { selectLabelById } from '../selectors'

type TProps = {
  stage: number
}
const mainCssClass = 'vacancy-status-table-row'
const CandidateStatusTableRow: FC<TProps> = ({ stage }) => {
  const text = useMemo(() => {
    if (stage === 63) {
      return 'Принят'
    }
    if (stage === 65) {
      return 'Добавлен'
    }
    if (stage === 54) {
      return 'Откликнулся'
    }
    if (stage === 55) {
      return 'Диалог'
    }
    if (stage === 56) {
      return 'Телефонное интервью'
    }
    if (stage === 57) {
      return 'Приглашен'
    }
    if (stage === 59) {
      return 'Положительный'
    }
    if (stage === 58) {
      return 'Интервью с менеджером'
    }
    if (stage === 660) {
      return 'Архив'
    }
    if (stage === 61) {
      return 'Стажировка'
    }
    if (stage === 52) {
      return 'Оформление'
    }
    if (stage === 64) {
      return 'Уволен'
    }

    return '-'
  }, [stage])

  const stageLabel = useSelector(selectLabelById(stage, 'stageStatus'))

  return (
    <div className={mainCssClass}>
      <Typography.Text className={cn(`${mainCssClass}__status`, `${mainCssClass}__status-${stage}`)}>{stageLabel}</Typography.Text>
    </div>
  )
}
export default memo<TProps>(withErrorBoundary<TProps>(CandidateStatusTableRow))
