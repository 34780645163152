import axios, { AxiosResponse } from 'axios'
import { method } from 'lodash'
import $api, { API_URL } from './http'
import {
  TCertificateRequest,
  TCoursesRequest,
  TDeleteResumeRequest,
  TEducationRequest,
  TExperienceRequest,
  TLanguageRequest,
  TRecomendationRequest,
  TRelocateRequest,
  TRequestInfoResume,
  TResume,
  TResumeRequest,
  TSkillsRequest,
  TTransferRequest,
  TWishesRequest
} from '../../compositions/Resume'


export async function fetchGetCandidateResume(payload: number): Promise<AxiosResponse<TResume[]>> {
  const response= await $api.post(`/api/v1/resume/get-list?candidate_id=${payload}`, payload)
  return response
}
export async function fetchPostCreateSkillsResume(payload: TSkillsRequest): Promise<AxiosResponse<TResume>> {
  const response= await $api.post('/api/v1/resume/skills', payload)
  return response
}

export async function fetchPostCreateCertificateResume(payload: TCertificateRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resume/certificate', payload)
  return response
}

export async function fetchPostCreateRecomendationResume(payload: TRecomendationRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resume/recommendations', payload)
  return response
}

export async function fetchPatchCreateInfoResume(payload: TRequestInfoResume): Promise<AxiosResponse<TResume>> {
  const response = await $api.patch('/api/v1/resume/info', payload)
  return response
}

export async function fetchPatchCreateLanguageResume(payload: TLanguageRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.patch('/api/v1/resume/language', payload)
  return response
}

export async function fetchPostCreateCoursesResume(payload: TCoursesRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resume/courses', payload)
  return response
}

export async function fetchPatchCreateRelocateResume(payload: TRelocateRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.patch('/api/v1/resume/relocate-business-trips', payload)
  return response
}

export async function fetchPatchCreateTransferResume(payload: TTransferRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.patch('/api/v1/resume/citizenship-transfer-time', payload)
  return response
}

export async function fetchPostCreateEducationResume(payload: TEducationRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resume/education', payload)
  return response
}

export async function fetchPostCreateExperienceResume(payload: TExperienceRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resume/experience', payload)
  return response
}

export async function fetchPatchCreateWishesResume(payload: TWishesRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.patch('/api/v1/resume/wishes', payload)
  return response
}

export async function fetchPostCreateResume(payload: TResumeRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.post('/api/v1/resumes', payload)
  return response
}

export async function fetchDeleteCResume(payload: TDeleteResumeRequest): Promise<AxiosResponse<TResume>> {
  const response = await $api.delete(`/api/v1/resumes/${payload.resume_id}`)
  return response
}
