import {
  put, call, takeLatest, spawn, select
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import {
  fetchDeleteNotifications, fetchDeleteNotifiy, fetchGetNotifications, fetchReadNotifications, fetchReadNotifiy
} from '../../common/api/notify'
import { showCriticalNotification, showSuccessNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  TRequestNotify,
  deleteNotifications, deleteNotify, getNotifications, readNotifications, readNotify, setDefDataNotify, setLoading, setNotifications,
  setNotifyHeaders
} from './actions'
import { selectDefDataNotify, selectNotifications } from './selectors'
import { setLoadingProgress } from '../../compositions/InnerRouter/actions'

const moduleName = 'Центр уведомлений'

function* getNotificationsSaga({ payload }: PayloadAction<TRequestNotify>) {
  try {
    yield put(setLoading(true))
    yield put(setLoadingProgress(true))
    const response = yield fetchGetNotifications(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось получить уведомления',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setNotifications(response.data.DATA.notify_list))
      showSuccessNotification({
        text: 'Уведомления получены'
      })
      yield put(setNotifyHeaders({
        current: response.data.Headers['x-pagination-current-page'][0] + 1,
        total: response.data.Headers['x-pagination-page-count'][0] + 1,
        totalCount: response.data.Headers['x-pagination-total-count'][0],
        unread: response.data.Headers.unread[0]
      }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* deleteNotificationsSaga() {
  try {
    yield put(setLoadingProgress(true))
    const response = yield fetchDeleteNotifications()
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось очистить уведомления',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setNotifications([]))
      showSuccessNotification({
        text: 'Уведомления очищены'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* deleteNotifysSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    const response = yield fetchDeleteNotifiy(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось удалить уведомление',
          error: response as AxiosError
        })
      )
    } else {
      const notifyArr = yield select(selectNotifications)
      const newNotifyArr = notifyArr.filter((el) => el.guid !== payload)
      yield put(setNotifications(newNotifyArr))
      showSuccessNotification({
        text: 'Уведомление удалено'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
  }
}

function* readNotifysSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    const response = yield fetchReadNotifiy(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отметить прочитанным уведомление',
          error: response as AxiosError
        })
      )
    } else {
      const defData = yield select(selectDefDataNotify)
      if (defData.page === 0) {
        yield spawn(getNotificationsSaga, getNotifications(defData))
      } else {
        yield put(setDefDataNotify({ ...defData, page: 0 }))
      }
      showSuccessNotification({
        text: 'Уведомление отмечено прочитанным'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* readNotificationsSaga() {
  try {
    yield put(setLoadingProgress(true))
    yield put(setLoading(true))
    const response = yield fetchReadNotifications()
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отметить прочитанными уведомления',
          error: response as AxiosError
        })
      )
    } else {
      const defData = yield select(selectDefDataNotify)
      yield put(setNotifications([]))
      showSuccessNotification({
        text: 'Уведомления прочитаны'
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
  } finally {
    yield put(setLoadingProgress(false))
    yield put(setLoading(false))
  }
}

function* notificationsSaga() {
  yield takeLatest(getNotifications, getNotificationsSaga)
  yield takeLatest(deleteNotifications, deleteNotificationsSaga)
  yield takeLatest(deleteNotify, deleteNotifysSaga)
  yield takeLatest(readNotify, readNotifysSaga)
  yield takeLatest(readNotifications, readNotificationsSaga)
}

export default notificationsSaga
