import { createAction } from '@reduxjs/toolkit'
import { TDictionaries, TDictionariesNames } from '../../common/types/dictionaries'
import { TFilter } from './types'

export const openFilters = createAction<boolean>('filters/openFilters')
export const reset = createAction('filters/reset')
export const getDictionaries = createAction<TDictionariesNames>('filters/dictionaries/getDictionaries')
export const setDictionaries = createAction<TDictionaries>('filters/dictionaries/setDictionaries')
export const setFilters = createAction<string>('filters/setFilters')

export type TFilterQuery = {
    filter: TFilter
    isEventFunnel?: boolean
}
export const getFilters = createAction<TFilterQuery>('filters/getFilters')

export type TDefFilters = {
    type:string
    filter:any
    defFilterString: string
}

export type TFilterLabel ={
    key: string
    value: any[]
    label: string
}

export const setDefFilters = createAction<TDefFilters | null>('filters/setDefFilters')
export const setFiltersLabels = createAction<TFilterLabel[]>('filters/setFiltersLabels')

export const setStagesFunnelFilter = createAction<number[] | null>('filters/setStagesFunnelFilter')
export const OpenFilterMobil = createAction<boolean>('filters/OpenFilterMobil')
export const setCountFilters = createAction<number>('filters/setCountFilters')
export const setDeleteFilterByKey = createAction<string>('filters/setDeleteFilter')
