import { createReducer } from '@reduxjs/toolkit'
import {
  setAutoCalls, setGroupUsersWhoCalls, setLoading, setSelectedAutoCall, setUsersWhoCalls, TAutoCall
} from './actions'
import calendarReducer from '../Calendar/reducer'
import { TOption } from '../../common/types/dictionaries'
import { selectWhoCallsGroupOptions } from './selectors'


export type TAutoCallInitialState = {
  autoCalls: TAutoCall[]
  loading: boolean
  selectedAutoCall: TAutoCall | null
  usersWhoCalls: TOption[]
  usersWhoCallsGroupOptions: TOption[]
}
const initialState: TAutoCallInitialState = {
  autoCalls: [],
  loading: false,
  selectedAutoCall: null,
  usersWhoCalls: [],
  usersWhoCallsGroupOptions: []
}

const autoCallReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAutoCalls, (state, { payload }) => {
      state.autoCalls = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setSelectedAutoCall, (state, { payload }) => {
      state.selectedAutoCall = payload
    })
    .addCase(setUsersWhoCalls, (state, { payload }) => {
      state.usersWhoCalls = payload
    })
    .addCase(setGroupUsersWhoCalls, (state, { payload }) => {
      state.usersWhoCallsGroupOptions = payload
    })
})

export default autoCallReducer
