import { createReducer } from '@reduxjs/toolkit'
import { reset, setCandidate, setDialogs, setHeaders, setLoading } from './actions'
import { TCandidate, TDATA } from '../../compositions/candidateV2/types'

type TChatPanelState = {
    loading?: boolean
    dialogs: any[]
    headers: {
      current: number
      total :number
      totalCount: number
    } | null
  candidate: TDATA | null
}

const initialState: TChatPanelState = {
  dialogs: [],
  loading: false,
  headers: null,
  candidate: null
}

const chatPanelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reset, () => initialState)
    .addCase(setDialogs, (state, { payload }) => {
      state.dialogs = payload
    })
    .addCase(setCandidate, (state, { payload }) => {
      state.candidate = payload
    })
    .addCase(setLoading, (state, { payload }) => {
      state.loading = payload
    })
    .addCase(setHeaders, (state, { payload }) => {
      state.headers = payload
    })
})

export default chatPanelReducer
