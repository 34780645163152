import React, { forwardRef } from 'react'
import type { ScrollbarProps } from 'react-scrollbars-custom'

const ReactCustomScrollbarComponent = React.lazy(() => import('react-scrollbars-custom')) as any

function ReactCustomScrollbarForward(props: ScrollbarProps, ref?: any) {
  return (
    <React.Suspense fallback={null}>
      <ReactCustomScrollbarComponent {...props} ref={ref} />
    </React.Suspense>
  )
}

export const ReactCustomScrollbar = forwardRef(ReactCustomScrollbarForward)
