import {
  put, call, takeLatest, spawn, select, delay
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import {
  createReserveSlot, createTimeSlotStop, deleteBlockedSlot, deleteReserveSlot, getBlockedSlots,
  getDictionaries,
  getEmptySlotByUser, getEmptySlotsTimeStop, getHolidays, getReserveSlotsUser, setBlockedSlots,
  setDictionaries,
  setEmptySlotByUser, setEmptySlotTimeStop, setHolidays, setReserveSlotsUser,
  TCalendarSettings, TDeleteReserveSlot,
  TGetEmptyRequest,
  updateCalendarSettings,
  updateUserCalendarSettings
} from './actions'
import { loadDictionariesList } from '../../common/saga/dictionariesSaga'
import { TDictionariesNames } from '../../common/types/dictionaries'
import { showCriticalNotification } from '../../common/components/notification/utils'
import { getErrorNotification } from '../../common/components/ErrorComponentSaga'
import {
  fetchDeleteCalendarBlockedSlot,
  fetchGetBlockedSlotsByUser,
  fetchGetCalendarHolidaysUser,
  fetchGetEmptySlotsByUser,
  fetchGetReserveSlotsByUser,
  fetchGetSlots, fetchGetSlotsTimeStop,
  fetchPostCalendar,
  fetchPostCalendarBlockedSlots,
  fetchPostCreateReserveSlot,
  fetchPostDeleteReserveSlot,
  TBlockedSlotDelete,
  TBlockedSlotsResponse,
  TRequestBodyBlockedSlots,
  TRequestBodyUpdateSettings
} from '../../common/api/calendar'
import { fetchPatchUser, fetchPostUpdateCalendarUser } from '../../common/api/user'
import { selectUserInfo } from '../../compositions/InnerRouter/selectors'
import { getUserInfo, setLoadingProgress, setUserInfo } from '../../compositions/InnerRouter/actions'
import { getCalendar } from '../../compositions/Calendar/actions'
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from '../../common/utils/dateFormats'
import { selectPage, selectPeriod } from '../../compositions/Calendar/selectors'

const moduleName = 'Настройки календаря'
function* getDictionariesSaga(payload: PayloadAction<TDictionariesNames>) {
  yield loadDictionariesList(payload, setDictionaries, 'настройки календаря')
}

function* getEmptySlotByUserSaga({ payload }: PayloadAction<TGetEmptyRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetEmptySlotsByUser(payload.userId, payload.date)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setEmptySlotByUser(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* getEmptySlotsTimeStopSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetSlotsTimeStop(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setEmptySlotTimeStop(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateUserCalendarSettingsSaga({ payload }: PayloadAction<TCalendarSettings>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchPostUpdateCalendarUser(payload)
    if (response.data.DATA.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось отменить интервью',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getUserInfo(false))
      yield delay(1500)
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield put(getCalendar({ user_id: 0, period, page }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateCalendarCreateReserveSaga({ payload }: PayloadAction<TCalendarSettings>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchPostCreateReserveSlot(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield delay(1500)
      yield put(getCalendar({ user_id: 0, period, page }))
      yield put(getReserveSlotsUser({ userId: 0, date: dayjs(new Date()).format(SERVER_DATE_FORMAT) }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* deleteReserveSlotSaga({ payload }: PayloadAction<TDeleteReserveSlot>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchPostDeleteReserveSlot(payload)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield put(getCalendar({ user_id: 0, period, page }))
      yield put(getReserveSlotsUser({ userId: payload.user_id, date: dayjs(new Date()).format(SERVER_DATE_FORMAT) }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* updateCalendarSettingsSaga({ payload }: PayloadAction<TRequestBodyUpdateSettings>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchPostCalendar(payload)

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getHolidays())
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield delay(1500)
      yield put(getEmptySlotByUser({ userId: payload.user_id, date: dayjs(new Date()).format(SERVER_DATE_FORMAT) }))
      yield put(getCalendar({ user_id: 0, period, page }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* updateCalendarTimeStopSaga({ payload }: PayloadAction<TRequestBodyBlockedSlots>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchPostCalendarBlockedSlots({ begin_at: payload.begin_at, end_at: payload.end_at })
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getBlockedSlots({ date: dayjs(payload.date).format(SERVER_DATE_FORMAT) }))
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield delay(1500)
      yield put(getCalendar({ user_id: 0, period, page }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* getReserveSlotByUserSaga({ payload }: PayloadAction<TGetEmptyRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetReserveSlotsByUser(payload.date)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setReserveSlotsUser(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}
function* getBlockedSlotsSaga({ payload }: PayloadAction<TGetEmptyRequest>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetBlockedSlotsByUser(payload.date)
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setBlockedSlots(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* deleteBlockedSlotSaga({ payload }: PayloadAction<TBlockedSlotDelete>) {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchDeleteCalendarBlockedSlot(payload)

    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(getBlockedSlots({ date: dayjs(payload.date).format(SERVER_DATE_FORMAT) }))
      yield delay(1500)
      const period: string = yield select(selectPeriod)
      const page: number = yield select(selectPage)
      yield put(getCalendar({ user_id: 0, period, page }))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* getHolidaysSaga() {
  try {
    yield put(setLoadingProgress(true))
    const response: AxiosResponse = yield fetchGetCalendarHolidaysUser()
    if (response.data.ERR) {
      showCriticalNotification(
        getErrorNotification({
          moduleName,
          text: 'Не удалось загрузить',
          error: response as AxiosError
        })
      )
    } else {
      yield put(setHolidays(response.data.DATA))
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error(String(error))
    }
} finally {
    yield put(setLoadingProgress(false))
  }
}

function* settingsCalendarSaga() {
  yield takeLatest(getDictionaries, getDictionariesSaga)
  yield takeLatest(getEmptySlotByUser, getEmptySlotByUserSaga)
  yield takeLatest(updateUserCalendarSettings, updateUserCalendarSettingsSaga)
  yield takeLatest(updateCalendarSettings, updateCalendarSettingsSaga)
  yield takeLatest(createReserveSlot, updateCalendarCreateReserveSaga)
  yield takeLatest(getReserveSlotsUser, getReserveSlotByUserSaga)
  yield takeLatest(getHolidays, getHolidaysSaga)
  yield takeLatest(deleteReserveSlot, deleteReserveSlotSaga)
  yield takeLatest(getEmptySlotsTimeStop, getEmptySlotsTimeStopSaga)
  yield takeLatest(createTimeSlotStop, updateCalendarTimeStopSaga)
  yield takeLatest(deleteBlockedSlot, deleteBlockedSlotSaga)
  yield takeLatest(getBlockedSlots, getBlockedSlotsSaga)
}

export default settingsCalendarSaga
