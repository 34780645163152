import React, {
  memo, useCallback,
  useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'
import {
  createStore,
  setCreateOrEditStore,
  setSelectedStore,
  updateStore
} from '../actions'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import {
  selectSelectedStore, selectYandexObjects
} from '../selectors'
import { prepareForSubmitStore, storeType } from '../utils'
import DebounceSelectFormControlCity from '../../../common/components/formcontrol/DebounceSelectFormControlCity'
import { fetchCitiesList } from '../../candidate/utils'
import DebounceSelectFormControl from '../../../common/components/formcontrol/DebounceSelectFormControl'
import $api from '../../../common/api/http'
import { TStore } from '../../../common/api/systems'

import './StoreForm.scss'
import { fetchGetFieldCoordinatesYandex } from '../../../common/api/yandex'

export type TFormValuesStore = TStore

const mainCssClass = getCssClassName('store-form')

function StoreForm() {
  const store = useSelector(selectSelectedStore)
  const dispatch = useDispatch()
  const [storeCoordinates, setStoreCoordinates] = useState<any>(null)
  const { rbacControl } = useEntityContext()
  const yandexObjects = useSelector(selectYandexObjects)

  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValuesStore>()

  const onSubmit = () => {
    if (address) {
      const finded = yandexObjects?.find((item: any) => item.value === address)
      if (store) {
        dispatch(updateStore(prepareForSubmitStore(getValues(), finded)))
      } else {
        dispatch(createStore(prepareForSubmitStore(getValues(), finded)))
      }
    }
  }

  const handleCancel = () => {
    dispatch(setCreateOrEditStore(false))
    dispatch(setSelectedStore(null))
  }

  useEffect(() => {
    if (store) {
      const defaultValues = {
        name: store.name,
        id: store.id,
        address: store?.address || '',
        city: {
          value: store?.city,
          key: store?.dadata_city_key
        },
        metro: store?.metro || '',
        // eslint-disable-next-line no-restricted-globals
        longtitude: !isNaN(+store.longtitude) ? store.longtitude : null,
        // eslint-disable-next-line no-restricted-globals
        lattitude: !isNaN(+store.lattitude) ? store.lattitude : null,
        type: store.type
      }
      reset(defaultValues)
    } else {
      const defaultValues = {
        name: '',
        address: '',
        city: '',
        metro: '',
        longtitude: '',
        lattitude: '',
        type: null
      }
      reset(defaultValues)
    }
  }, [store])

  const city = watch('city')
  const address = watch('address')

  const fetchMetroList = useCallback(async (name: string) => {
    if (city?.key) {
      const data = await $api.get(`/api/v1/dadata/suggest-metro?search=${name}&city_kladr_id=${city.key}
    `)
      return data.data.DATA.map((obj: { data: { name: string } }) => ({
        label: `${obj.data.name}`,
        value: obj.data.name
      }))
    }
  }, [city])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_form`}>
        <div className={`${mainCssClass}_form_main`}>
          <div className={`${mainCssClass}_form_main_item`}>
            <InputFormControl
              name="name"
              control={control}
              label="Название объекта"
              required
              rules={{ required: true }}
            />
            <SelectFormControl name="type" control={control} label="Тип объекта" options={storeType} />
          </div>
          <div className={`${mainCssClass}_form_main_item`}>
            <DebounceSelectFormControlCity
              name="city"
              control={control}
              fetchOptions={fetchCitiesList}
              label="Город"
              required
              notFoundContent="Начните вводить название города"
              rules={{ required: true }}
              labelInValue
            />

            <DebounceSelectFormControl
              name="address"
              control={control}
              fetchOptions={fetchGetFieldCoordinatesYandex}
              label="Адрес объекта"
              isYandex
              disabled={!city?.value || !city}
              notFoundContent="Начните вводить адрес"
              required
              rules={{ required: true }}

            />
            <DebounceSelectFormControl
              name="metro"
              control={control}
              fetchOptions={fetchMetroList}
              label="Метро(если есть)"
              disabled={!city || !city?.value}
              notFoundContent="Начните вводить название метро"
              key={city?.key}
            />
          </div>
          <div>
            <div className={`${mainCssClass}_actions`}>
              <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
                Сохранить
              </ControlButton>
              <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
                Отменить
              </ControlButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(StoreForm))
