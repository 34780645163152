import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, Input } from 'antd'
import { getCssClassName } from '../../../common/utils/generateClassName'
import {
  getActiveDictionary,
  getDictionaries,
  openDictionaryForm,
  reset,
  selectDictionaries,
  selectDictionaryForm,
  setDictionaryName,
  updateStatusDictionary
} from '..'
import DictionaryForm from './DictionaryForm'
import ControlButton from '../../../common/components/Buttons/Button'
import EditPencil1 from '../../../common/svg/EditPencil1'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import Archive from '../../../common/svg/Archive'
import Redo from '../../../common/svg/Redo'
import CheckBig from '../../../common/svg/CheckBig'
import CloseMD from '../../../common/svg/CloseMD'
import './Dictionary.scss'

const mainCssClass = getCssClassName('dictionary')

function Dictionary() {
  const dispatch = useDispatch()
  const dictionaryForm = useSelector(selectDictionaryForm)
  const dictionaries = useSelector(selectDictionaries)
  const { rbacControl } = useEntityContext()
  const [changeName, setChangeName] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [editedName, setEditedName] = useState('')
  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  useEffect(() => {
    dispatch(getDictionaries())
  }, [])

  const handleArchive = (key: string) => {
    dispatch(updateStatusDictionary({ key, is_archive: true }))
  }

  const handleReturnArchive = (key: string) => {
    dispatch(updateStatusDictionary({ key, is_archive: false }))
  }

  const handleOpenEditQuestionnaire = (id) => {
    setSelectedId(id)
    setChangeName(true)
  }

  const handleEditQuestionnaire = (item: any, name: string) => {
    dispatch(getActiveDictionary(item))
    dispatch(setDictionaryName({ id: item, name }))
    dispatch(openDictionaryForm(true))
  }
  const handleEditQuestionnaireName = (key) => {
    dispatch(updateStatusDictionary({ key, name: editedName }))
    setSelectedId(null)
    setChangeName(false)
  }
  const handleInputChange = (e) => {
    setEditedName(e.target.value)
  }
  const handleSaveChanges = (itemId) => {
    handleEditQuestionnaire(itemId, editedName)
  }

  const handleCancelEdit = () => {
    setChangeName(false)
    setSelectedId(null)
    setEditedName('')
  }

  return (
    <div className={mainCssClass}>
      {!dictionaryForm && (
        <>
          <div className={`${mainCssClass}_create`}>
            <Typography.Title level={3}>Настройка справочников</Typography.Title>
          </div>

          <div className={`${mainCssClass}_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_dictionary-list`}>
                {dictionaries?.map((item) => (
                  <div className={`${mainCssClass}_dictionary-list_item`}>
                    {changeName && selectedId === item.id ? (
                      <div className={`${mainCssClass}_dictionary-list_item_input`}>
                        <Input
                          value={editedName}
                          defaultValue={item.name}
                          onChange={(e) => handleInputChange(e)}
                          // onBlur={() => handleEditQuestionnaire(item.id, item.name)}
                          // onPressEnter={() => handleEditQuestionnaire(item.id, item.name)}
                        />
                        <ControlButton className="edit-button" onClick={() => handleEditQuestionnaireName(item.key)}>
                          <CheckBig />
                        </ControlButton>
                        <ControlButton className="edit-button" onClick={() => handleCancelEdit()}>
                          <CloseMD />
                        </ControlButton>
                      </div>
                    ) : (
                      <div className={`${mainCssClass}_dictionary-list_item_text`}>
                        <Typography.Text className={`${mainCssClass}_dictionary-list_item_text_title`}>
                          {item.name} <EditPencil1 onClick={() => handleOpenEditQuestionnaire(item.id)} />
                        </Typography.Text>
                      </div>
                    )}

                    {rbacControl?.dictionary.edit_view_access === 1 && (
                      <div className={`${mainCssClass}_dictionary-list_item_action`}>
                        {item.is_archive === false ? (
                          <>
                            <ControlButton
                              onClick={() => handleEditQuestionnaire(item.id, item.name)}
                              className="button"
                              typeButton="secondary"
                            >
                              <EditPencil1 />
                            </ControlButton>
                            <ControlButton
                              typeButton="secondary"
                              className="button"
                              onClick={() => handleArchive(item.key)}
                            >
                              <Archive />
                            </ControlButton>
                          </>
                        ) : (
                          <ControlButton
                            typeButton="secondary"
                            className="button"
                            onClick={() => handleReturnArchive(item.key)}
                          >
                            <Redo />
                          </ControlButton>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </NewScrollContainer>
          </div>
        </>
      )}

      {dictionaryForm &&
        (rbacControl?.dictionary.create_access === 1 || rbacControl?.dictionary.edit_view_access === 1) && (
          <DictionaryForm />
        )}
    </div>
  )
}

export default memo(withErrorBoundary(Dictionary))
