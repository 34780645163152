import { TOption } from '../../common/types/dictionaries'
import $api from '../../common/api/http'
import { generateFilters } from '../filters/utils'
import { TFormFilter } from './components/CandidateHistory'

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000)

  const addLeadingZero = (number : number) => (number < 10 ? `0${number}` : number)

  const day = addLeadingZero(date.getDate())
  const month = addLeadingZero(date.getMonth() + 1)
  const year = date.getFullYear()
  const hours = addLeadingZero(date.getHours())
  const minutes = addLeadingZero(date.getMinutes())

  const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`

  return formattedDateTime
}

export const actionOptions = [{ label: 'Изменение кандидата', value: 'edit' }, { label: 'Добавление кандидата', value: 'candidateAdd' },
  { label: 'Архивациия кандидата', value: 'candidateArchive' }, { label: 'Отклонение кандидата', value: 'candidateReject' },
  { label: 'Добавления комментария', value: 'commentAdd' }, { label: 'Назначение на заявку', value: 'requestAdd' },
  { label: 'Назначения на интервью', value: 'interviewAdd' }, { label: 'Создание задачи', value: 'taskAdd' }]

export async function fetchResponsibleList(name: string): Promise<TOption[]> {
  if (!name || !name.trim()) {
    const { data } = await $api.get('/api/v1/users?page=1&per-page=100&fields=id,name,surname,email')

    return data.DATA.map((obj: { id: number; name: string; surname: string;}) => ({
      label: `${obj.name} ${obj.surname}`,
      value: obj.id
    }))
  }
  const filtredName = generateFilters(name.split(' '))

  const { data } = await $api.get(`/api/v1/users?page=1&per-page=10&fields=id,name,surname,email&filter${filtredName}`)

  return data.DATA.map((obj: { id: number; name: string; surname: string;}) => ({
    label: `${obj.name} ${obj.surname}`,
    value: obj.id
  }))
}

export async function getWhatsappTemplates() {
  const { data } = await $api.get('/api/v1/whatsapp-templates?page=1&per-page=1000&fields=id,name,content,is_pinned')
  return data.DATA.map((el) => ({
    label: el.name,
    value: el.content,
    is_pinned: el.is_pinned
  }))
}

export function objectToQueryString(obj:TFormFilter):string {
  let str = ''

  for (const key in obj) {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length) {
      if (typeof obj[key][0] === 'object') {
        const strArrStr = obj[key].reduce<string>((acc, el) => {
          acc += `&filter[${key}][]=${el.value}`
          return acc
        }, '')
        str += strArrStr
      }

      if (key === 'date') {
        const strArrStr = obj[key].map((el) => el.split('-').reverse().join('.')).join('-')
        str += `&between[created_at]=${strArrStr}`
      }
    }
  }

  return str
}

export function removeATag(inputString, candidate_id, user_id) {
  if (Number(candidate_id) !== Number(user_id)) {
    return inputString.replace(/<a\b[^>]*>(.*?)<\/a>/g, '$1')
  }
  return inputString
}
