import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  Button, Modal, ModalProps, Tooltip
} from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import dayjs from 'dayjs'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import DatePickerFormControl from '../../../common/components/formcontrol/DatePickerFormControl'
import './MiddleModalCandidate.scss'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import { addInterview, getEmptySlots, setEmptySlots } from '../actions'
import { FULL_DATE_FORMAT } from '../../../common/utils/dateFormats'
import { selectCandidate, selectEmptySlots, selectLoadingSlots } from '../selectors'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { selectUserInfo } from '../../InnerRouter/selectors'
import Redo from '../../../common/svg/Redo'
import ControlButton from '../../../common/components/Buttons/Button'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import TextAreaFormControl from '../../../common/components/formcontrol/TextAreaFormControl'

const mainCssClass = 'middleModalCandidate'

function ModalNewInterview({
  onOk, open, className, onCancel
}: ModalProps) {
  const {
    control, watch, resetField, reset
  } = useForm<TFormValues>()
  const emptySlots = useSelector(selectEmptySlots)
  const [disableByInterval, setDisableByInterval] = useState<boolean>(false)
  const candidate = useSelector(selectCandidate)
  const loadingSlots = useSelector(selectLoadingSlots)
  const user = useSelector(selectUserInfo)
  const values = [{ label: 'Назначить интервью себе', value: 'isRecruiter' }]
  const selectUser = useSelector(selectUserInfo)
  type TFormValues = {
    date: string
    slot: string
    isRecruiter: string
    without_video: boolean
    comment: string
  }

  const dispatch = useDispatch()
  const date = watch('date')
  const slot = watch('slot')
  const isRecruiter = watch('isRecruiter')
  const without_video = watch('without_video')
  const comment = watch('comment')
  const withoutVideoEnabled = useMemo(() => !!emptySlots.find((item) => item.value === 'without_video'), [emptySlots])
  const withoutVideoSlots = useMemo(() => emptySlots.filter((item) => item.value !== 'without_video'), [emptySlots])

  useEffect(() => {
    if (date && candidate) {
      dispatch(getEmptySlots({
        date: dayjs(date).format(FULL_DATE_FORMAT),
        user_id: selectUser?.isColdSearch ? candidate?.researcher : candidate.responsible,
        isRecruiter: !!selectUser?.isColdSearch || !!selectUser?.isCanAppointRecruiter,
        candidate_id: candidate.id
      }))
    }
  }, [date, candidate, isRecruiter, selectUser])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisableByInterval(true)
      dispatch(setEmptySlots([]))
    }, 90000)

    return () => clearInterval(intervalId)
  }, [dispatch])

  useEffect(() => {
    const updateState = () => {
      resetField('slot')
      dispatch(setEmptySlots([]))
    }

    updateState()
  }, [isRecruiter, date])

  const handleSubmit = useCallback(() => {
    if (
      candidate?.id && candidate?.vacancy_id
      && candidate?.request_id && date && (slot || without_video) && candidate?.researcher) {
      dispatch(addInterview({
        user_id: 0,
        candidate_id: candidate.id,
        vacancy_id: candidate.vacancy_id,
        questionnaire_id: candidate.questionnare_id ? candidate.questionnare_id : null,
        researcher: candidate?.researcher,
        recruiter: user?.isColdSearch ? candidate?.researcher : user?.isCanAppointRecruiter ? candidate.responsible : null,
        comment,
        date,
        slot: without_video ? 'without_video' : slot,
        isResercherBeRecruter: user?.isColdSearch ? candidate?.researcher : user?.isCanAppointRecruiter ? candidate.responsible : null
      }))
    }
  }, [candidate, date, slot, user, without_video, comment])
  const handleRefreshSlots = () => {
    if (date && candidate) {
      dispatch(getEmptySlots({
        date: dayjs(date).format(FULL_DATE_FORMAT),
        user_id: selectUser?.isColdSearch ? candidate?.researcher : candidate.responsible,
        isRecruiter: !!selectUser?.isColdSearch || !!selectUser?.isCanAppointRecruiter,
        candidate_id: candidate.id
      }))
      setDisableByInterval(false)
    }
  }

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return (
    <CommonModal
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      title="Назначить на интервью"
      className={cn(className, mainCssClass)}
      width="400px"
      typeButtonAccept="primary"
      okText="Подтвердить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
      centered
    >
      <>
        <div>
          <DatePickerFormControl
            label="Выберите дату"
            required
            name="date"
            control={control}
            isDisableDate
            placeholder="Выберите дату"
            isDisablePastDays={!!user?.can_assign_calendar_future_days}
          />
        </div>
        <div>
          <Tooltip title="Необходимо обновить слоты по кнопке" visible={disableByInterval}>
            <SelectFormControl
              label="Временной слот"
              required
              name="slot"
              control={control}
              placeholder="Выберите слот"
              options={withoutVideoSlots}
              disabled={!date || loadingSlots || disableByInterval || without_video}
              {...(disableByInterval ? { overlay: { title: 'Необходимо обновить слоты по кнопке', placement: 'top' } } : {})}
            />
          </Tooltip>
          {disableByInterval && (
            <div className={`${mainCssClass}__button-refresh`}>
              <ControlButton typeButton="secondary" className="button" onClick={handleRefreshSlots}>
                <Redo />
              </ControlButton>
            </div>
          )}
          {withoutVideoEnabled && (
            <div className={`${mainCssClass}__switch-container`}>
              <SwitchFormControl name="without_video" control={control} />
              <span>Без видеосвязи</span>
            </div>
          )}
          <TextAreaFormControl
            name="comment"
            autoSize={{ minRows: 4, maxRows: 5 }}
            control={control}
            label="Комментарий"
          />
        </div>
        <div />
      </>
    </CommonModal>
  )
}

export default memo<ModalProps>(withErrorBoundary<ModalProps>(ModalNewInterview))
