import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { ModalProps, Typography, Radio } from 'antd'
import cn from 'classnames'
import { DebounceInput } from 'react-debounce-input'
import { useDispatch } from 'react-redux'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { assignVacancy } from '../actions'
import $api from '../../../common/api/http'
import SearchGlass from '../../../common/svg/Search_Magnifying_Glass'
import CommonModal from '../../../common/components/CommonModal/CommonModal'
import { LoadInitial } from '../utils'

import './AssignVacancyModal.scss'

const mainCssClass = 'assign-vacancy'

export type TProps = {
  vacancyId?: number
  vacancyLabel?: string
  candidate_id: number
} & ModalProps

function AssignVacancyModal({
  open,
  className,
  onCancel,
  vacancyId,
  candidate_id,
  vacancyLabel
}: TProps) {
  const dispatch = useDispatch()
  const [input, setInput] = useState('')
  const [options, setOptions] = useState<any>([])
  const [radioValue, setRadioValue] = useState<any>(undefined)
  const [initialOptions, setInitialOptions] = useState<any>([])

  const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = useCallback(async (e) => {
    setInput(e.target.value)
    setRadioValue(undefined)
    if (e.target.value) {
      const newOptions = await handleSearchOptions(e.target.value)
      setOptions(newOptions)
    }
    if (!e.target.value) {
      setOptions([])
    }
  }, [])

  const handleSearchOptions = useCallback(async (name: string) => {
    // eslint-disable-next-line max-len
    const { data } = await $api.get(`/api/v1/vacancies?sort=-is_favorite,updated_at&page=1&per-page=10&fields=id,label,description&search=${name}&filter[status][in][]=137&filter[status][in][]=138`)

    return data.DATA.vacancy_list.map((obj: { id: number; label: string; description: string }) => ({
      label: `${obj.label}`,
      value: obj.id,
      description: obj.description ? obj.description : 'Нет описания'
    }))
  }, [])

  const selectedVacancy = useMemo(
    () => (options.length > 0
      ? options.find((option) => option.value === radioValue)
      : initialOptions.find((option) => option.value === radioValue)),
    [radioValue, input]
  )

  useEffect(() => {
    const fetchData = async () => {
      const newOptions = await LoadInitial()

      if (vacancyId) {
        const fitredOptions = newOptions.filter((option) => option.value !== vacancyId)
        setInitialOptions([{ label: vacancyLabel, value: vacancyId }, ...fitredOptions])
      } else setInitialOptions(newOptions)
    }

    if (open) {
      fetchData()
    }
  }, [open, vacancyId])

  const handleSumbit = () => {
    if (radioValue) {
      dispatch(assignVacancy({ id: candidate_id, vacancy_id: radioValue }))
    }
  }
  return (
    <CommonModal
      open={open}
      onOk={handleSumbit}
      onCancel={onCancel}
      title={vacancyId ? 'Переназначить на вакансию' : 'Отобрать на вакансию'}
      className={cn(className, mainCssClass)}
      width="960px"
      typeButtonAccept="primary"
      okText="Сохранить"
      cancelText="Отменить"
      typeButtonReject="secondary"
      wrapClassName="wrapModal"
    >
      <div className={`${mainCssClass}_content`}>
        <div className={`${mainCssClass}_content_search`}>
          <div className={`${mainCssClass}_content_search_input`}>
            <SearchGlass />
            <DebounceInput minLength={1} debounceTimeout={1000} onChange={handleChangeInput} value={input} />
          </div>
          <div className={`${mainCssClass}_content_search_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_content_search_scrollContainer_scroll`}>
              {!input && (
                <Radio.Group
                  buttonStyle="solid"
                  onChange={(e) => setRadioValue(e.target.value)}
                  value={radioValue}
                  defaultValue={vacancyId || null}
                  className={`${mainCssClass}__radio`}
                >
                  {initialOptions.map((option) => (
                    <Radio.Button value={option.value}>
                      {option.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
              {options && (
                <Radio.Group buttonStyle="solid" onChange={(e) => setRadioValue(e.target.value)} value={radioValue} className={`${mainCssClass}__radio`}>
                  {options.map((option) => (
                    <div className={`${mainCssClass}__div-elem`}>
                      <Radio.Button style={{ whiteSpace: 'normal' }} key={option.value} value={option.value}>
                        {option.label}
                      </Radio.Button>
                    </div>
                  ))}
                </Radio.Group>
              )}
            </NewScrollContainer>
          </div>
        </div>
        <div className={`${mainCssClass}_content_description`}>
          <NewScrollContainer className={`${mainCssClass}_content_description_scroll`}>
            <Typography.Title level={4}>Описание</Typography.Title>
            <Typography.Text>
              {selectedVacancy && (<div dangerouslySetInnerHTML={{ __html: selectedVacancy.description }} />)}
            </Typography.Text>
          </NewScrollContainer>

        </div>
      </div>
    </CommonModal>
  )
}

export default AssignVacancyModal
