import { isArray } from 'lodash'
import dayjs from 'dayjs'
import { TDATA } from '../candidate/types'
import { TInterview, TInterviewHistory } from '../../common/api/interviews'
import { DATE_DOTS_FORMAT } from '../../common/utils/dateFormats'
import { TOption } from '../../common/types/dictionaries'

export function getCandidateInfo(candidate: TDATA | null) {
  return [
    { label: 'Возраст', value: candidate?.age || '-' },
    { label: 'Телефон', value: (isArray(candidate?.phone) ? candidate?.phone?.join(', ') : candidate?.phone) || '-' },
    { label: 'Email', value: (isArray(candidate?.email) ? candidate?.email?.join(', ') : candidate?.email) || '-' },
    { label: 'Адрес', value: candidate?.address || '-' },
    { label: 'Город', value: candidate?.city || '-' },
    { label: 'Вакансия', value: candidate?.vacancy_list?.[0]?.label }
  ]
}

export function getInterviewInfo(interview: TInterview | null, statusInterview: TOption[], interviewResult: TOption[], interviewNote: TOption[], characteristic:string) {
  const interviewInfo = [
    { label: 'Рекрутер', value: `${interview?.recruiter_name?.name} ${interview?.recruiter_name?.surname}` || '-' },
    { label: interview?.confirm === 'done' ? 'Проведено' : 'Подтверждено', value: (interview?.confirm !== 'done' ? (interview?.confirm === 'confirmed' ? 'Да' : 'Нет') : 'Да') },
    { label: 'Заявка', value: interview?.request?.label || 'Не назначена' },
    { label: 'Дата интервью', value: interview?.date ? dayjs(interview?.date).format(DATE_DOTS_FORMAT) : '-' },
    { label: 'Время интервью', value: interview?.period || '-' },
    { label: 'Статус', value: prepareStatusInterview(interview?.status, statusInterview) },
    { label: 'Результат', value: prepareResultInterview(interview?.result, interviewResult) },
    { label: 'Примечание', value: interview?.note },
    { label: 'Характеристика', value: characteristic },
    {label: 'Комментарий ресечера', value: interview?.comment}
  ]

  if (interview?.status === 93) {
    interviewInfo.splice(6, 0, { label: 'Причина отмены', value: interview.canceling_reason || '-' })
  }

  return interviewInfo
}

export function getMoreInterviewInfo(interview: TInterview | null, statusInterview: TOption[], interviewResult: TOption[], interviewNote: TOption[], characteristic:string) {
  return [
    { label: 'Кандидат', value: interview?.candidate_name.label || '' },
    { label: 'Телефон', value: interview?.candidate_name.phone || '' },
    { label: 'Рекрутер', value: `${interview?.recruiter_name?.name} ${interview?.recruiter_name?.surname}` || '-' },
    { label: interview?.confirm === 'done' ? 'Проведено' : 'Подтверждено', value: (interview?.confirm !== 'done' ? (interview?.confirm === 'confirmed' ? 'Да' : 'Нет') : 'Да') },
    { label: 'Заявка', value: interview?.request?.label || 'Не назначена' },
    { label: 'Дата интервью', value: interview?.date ? dayjs(interview?.date).format(DATE_DOTS_FORMAT) : '-' },
    { label: 'Время интервью', value: interview?.period || '-' },
    { label: 'Статус', value: prepareStatusInterview(interview?.status, statusInterview) },
    { label: 'Результат', value: prepareResultInterview(interview?.result, interviewResult) },
    { label: 'Примечание', value: interview?.note },
    { label: 'Характеристика', value: characteristic }

  ]
}

export function getInterviewInfoHistory(interview: TInterviewHistory | null, interviewNote: TOption[], interviewResult: TOption[], statusInterview: TOption[]) {
  return [
    { label: 'Рекрутер', value: interview?.labels?.recruiter || '-' },
    { label: 'Подтверждено', value: interview ? (interview.confirm ? 'Да' : 'Нет') : '-' },
    { label: 'Заявка', value: interview?.request?.label || 'Не назначена' },
    { label: 'Дата интервью', value: interview?.date || '-' },
    { label: 'Время интервью', value: interview?.period || '-' },
    { label: 'Статус', value: prepareStatusInterview(interview?.status, interviewResult) },
    { label: 'Результат', value: prepareResultInterview(interview?.result, interviewResult) },
    { label: 'Примечание', value: interview?.note }
  ]
}

export function prepareStatusInterview(status?: number, options?: TOption[]) {
  return options?.find((item) => Number(item.value) === status)?.label || '-'
}

export function prepareNoteInterview(status?: number, options?: TOption[]) {
  return options?.find((item) => Number(item.value) === status)?.label || '-'
}
export function prepareResultInterview(status?: number, options?: TOption[]) {
  return options?.find((item) => Number(item.value) === status)?.label || '-'
}
export function prepareResultInterviewObject(status?: number, options?: TOption[]) {
  return options?.find((item) => Number(item.value) === status) || {}
}
