import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
}

const mainCssClass = 'avatar-mob-version'

function AvatarMobVersion({ className, size = '1' }: TSvgProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8_154)">
        <rect width="48" height="48" rx="24" fill="#E8ECE9" />
        <path d="M7.70939 36.8841C8.69481 33.3536 11.9114 30.9119 15.5767 30.9119H16.2336C17.6425 30.9119 19.0103 31.3862 20.1168 32.2582C22.3944 34.0533 25.6056 34.0533 27.8832 32.2582C28.9897 31.3862 30.3575 30.9119 31.7664 30.9119H32.4233C36.0886 30.9119 39.3052 33.3536 40.2906 36.8841L45 53.7561H3L7.70939 36.8841Z" fill="#D5DBD6" />
        <path d="M15.4873 17.9762C15.1596 13.0523 19.0652 8.87805 24 8.87805C28.9348 8.87805 32.8404 13.0523 32.5127 17.9762L32.2561 21.8312C31.9668 26.178 28.3565 29.556 24 29.556C19.6435 29.556 16.0332 26.1781 15.7439 21.8312L15.4873 17.9762Z" fill="#D5DBD6" />
      </g>
      <defs>
        <clipPath id="clip0_8_154">
          <rect width="48" height="48" rx="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default AvatarMobVersion
