import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  onClick: () => void
}

const mainCssClass = 'information'

function Information({ className, size = '1', onClick }: TSvgProps) {
  return (

    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' }
      )}
      width="76"
      height="40"
      viewBox="0 0 76 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="76" height="40" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39 12C34.5817 12 31 15.5817 31 20C31 24.4183 34.5817 28 39 28C43.4183 28 47 24.4183 47 20C47 15.5817 43.4183 12 39 12ZM29 20C29 14.4772 33.4772 10 39 10C44.5228 10 49 14.4772 49 20C49 25.5228 44.5228 30 39 30C33.4772 30 29 25.5228 29 20ZM37.9502 16C37.9502 15.4477 38.3979 15 38.9502 15H39.0498C39.6021 15 40.0498 15.4477 40.0498 16V16.1C40.0498 16.3653 39.9444 16.6196 39.7568 16.8072C39.5692 16.9947 39.3148 17.1001 39.0496 17.1L38.95 17.1C38.3978 17.0998 37.9502 16.6522 37.9502 16.1V16ZM39 18C39.5523 18 40 18.4477 40 19V24C40 24.5523 39.5523 25 39 25C38.4477 25 38 24.5523 38 24V19C38 18.4477 38.4477 18 39 18Z" fill="#404542" />
    </svg>
  )
}
export default Information
