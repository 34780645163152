import { useForm } from 'react-hook-form'
import React, {
  memo,
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography
} from 'antd'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import ArrowRightSM from '../../../common/svg/ArrowRightSM'
import EditPencil1 from '../../../common/svg/EditPencil1'
import Redo from '../../../common/svg/Redo'
import Archive from '../../../common/svg/Archive'
import { getCssClassName } from '../../../common/utils/generateClassName'

import {
  getStores,
  reset, setCreateOrEditStore, setDefDataStore, setSelectedStore, updateStore
} from '../actions'
import {
  selectCreateOrEditStore, selectDefDataStore, selectLoading, selectStoreHeaders, selectStores
} from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import { TStore } from '../../../common/api/systems'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { SmallMap } from '../../../common/svg/SvgFromIconWrapper/SmallMap'
import StoreForm from './StoreForm'
import ArrowLeftSM from '../../../common/svg/ArrowLeftSM'
import { optionsPagination } from '../../../common/components/options'
import InputFormControlGlobalSearch from '../../../common/components/formcontrol/InputFormControlGlobalSearch'
import { generateStoreListFilters } from '../utils'
import { Preloader } from '../../../common/components/Preloader/Preloader'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './StoreList.scss'

const mainCssClass = getCssClassName('store-list')

export type TFormValues = {
  perPagesCount: number
  filter:string
}
function StoreList() {
  const dispatch = useDispatch()
  const formShow = useSelector(selectCreateOrEditStore)
  const stores = useSelector(selectStores)
  const scrollbarsRef = useRef(null)
  const headers = useSelector(selectStoreHeaders)
  const defDataStore = useSelector(selectDefDataStore)
  const loading = useSelector(selectLoading)
  const [page, setPage] = useState<number>(defDataStore?.page || 1)

  const { rbacControl } = useEntityContext()

  const {
    control, handleSubmit, getValues, watch, setValue
  } = useForm<TFormValues>()

  const perPage = watch('perPagesCount')
  const filter = watch('filter')

  const handleArchive = (item) => {
    dispatch(updateStore({ ...item, is_archive: true }))
  }

  const handleReturnArchive = (item) => {
    dispatch(updateStore({ ...item, is_archive: false }))
  }
  const handleEdit = (item:TStore) => {
    dispatch(setSelectedStore(item))
    dispatch(setCreateOrEditStore(true))
    if (scrollbarsRef.current) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToTop()
    }
  }

  const handleCreateStore = () => {
    dispatch(setCreateOrEditStore(true))
    dispatch(setSelectedStore(null))

    if (scrollbarsRef.current) {
      const scrollContainer = scrollbarsRef.current
      scrollContainer.scrollToTop()
    }
  }

  const stringCountsPagination = useMemo(
    () => `${headers?.current} из ${headers?.total}`,
    [headers]
  )

  const handleNextPage = useCallback(() => {
    if (page !== headers?.total) {
      setPage((prev) => prev + 1)
    }
  }, [page, headers])

  const handlePreviousPage = useCallback(() => {
    if (page !== 1) {
      setPage((prev) => prev - 1)
    }
  }, [page])

  const handlePerPage = (selectedParametr:number) => {
    setValue('perPagesCount', selectedParametr)
    setPage(1)
  }

  useEffect(() => {
    dispatch(setDefDataStore({ filters: generateStoreListFilters(filter), page, perPage }))
  }, [perPage, page, filter])

  useEffect(() => {
    if (headers && page > headers?.total) { setPage(1) }
  }, [headers])

  useEffect(() => {
    if (defDataStore) {
      dispatch(getStores(defDataStore))
    }
  }, [defDataStore])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>

      <div className={`${mainCssClass}_create`}>
        <div>
          <Typography.Title level={3}>
            Объекты
          </Typography.Title>
          {loading && <Preloader size="md" />}

          {!loading && rbacControl?.users.create_access === 1 && (
            <ControlButton
              typeButton="secondary"
              size="large"
              icon="left"
              className="button"
              onClick={handleCreateStore}
            >
              <SmallMap />
              Создать объект
            </ControlButton>
          ) }
        </div>

        {!loading
         && (
           <>
             <div className={`${mainCssClass}_search-container`}>
               <InputFormControlGlobalSearch name="filter" control={control} placeholder="Начните вводить название" className={`${mainCssClass}_global-search`} />

               <SelectFormControl
                 className={`${mainCssClass}__pagination-count`}
                 name="perPagesCount"
                 control={control}
                 options={optionsPagination}
                 onChange={(selectedParametr: number) => handlePerPage(selectedParametr)}
               />

               <div className={`${mainCssClass}__wrapper-arrow-pagination`}>
                 <ArrowRightSM className="arrowCursor" size="1" onClick={handlePreviousPage} fill={page === 1 ? '#BFC5C0' : '#404542'} />
                 <Typography.Text className={`${mainCssClass}__pagination-font`}>
                   {stringCountsPagination}
                 </Typography.Text>
                 <ArrowLeftSM className="arrowCursor" size="1" onClick={handleNextPage} fill={page === headers?.total ? '#BFC5C0' : '#404542'} />
               </div>
             </div>
             <div className={`${mainCssClass}_create_scrollContainer`}>
               <NewScrollContainer
                 className={`${mainCssClass}_create_scrollContainer_scroll`}
                 scrollbarsRef={scrollbarsRef}
               >

                 {formShow && (
                   <StoreForm />
                 )}
                 <div className={`${mainCssClass}_create_role-list`}>
                   {stores?.map((item) => (
                     <div
                       key={item.id}
                       className={`${mainCssClass}_create_role-list_item`}
                     >
                       <div className={`${mainCssClass}_create_role-list_item_all_text`}>
                         <div className={`${mainCssClass}_create_role-list_item_text`}>
                           <Typography.Text className={`${mainCssClass}_create_role-list_item_text_title`}>
                             {`${item.name}${item?.metro ? `(м. ${item?.metro})` : ''}`}
                           </Typography.Text>
                           <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_quantity`}>
                             {`${item?.city || ''}, ${item?.address || ''}, Ш - ${item.lattitude} Д - ${item.longtitude}`}
                           </Typography.Text>
                         </div>
                       </div>
                       <div className={`${mainCssClass}_create_role-list_item_action`}>
                         {!item?.is_archive
                           ? (
                             <>
                               <ControlButton typeButton="secondary" className="button" onClick={() => handleEdit(item)}>
                                 <EditPencil1 />
                               </ControlButton>
                               <ControlButton typeButton="secondary" className="button" onClick={() => handleArchive(item)}>
                                 <Archive />
                               </ControlButton>
                             </>
                           ) : (
                         // eslint-disable-next-line react/jsx-no-useless-fragment
                             <>
                               <ControlButton typeButton="secondary" className="button" onClick={() => handleReturnArchive(item)}>
                                 <Redo />
                               </ControlButton>
                             </>
                           )}
                       </div>
                     </div>
                   ))}
                 </div>
               </NewScrollContainer>
             </div>

           </>
         )}

      </div>

    </div>
  )
}

export default memo(withErrorBoundary(StoreList))
