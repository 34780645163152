import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Typography } from 'antd'
import EditPencil1 from '../../../common/svg/EditPencil1'
import Redo from '../../../common/svg/Redo'
import Archive from '../../../common/svg/Archive'
import { getCssClassName } from '../../../common/utils/generateClassName'
import ListAdd from '../../../common/svg/ListAdd'

import {
  getQuestionnaire,
  openQuestionnaireForm,
  reset,
  setActiveQuestionnaire,
  updateStatusQuestionnaire
} from '../actions'
import { selectQuestionnaireForm, selectQuestionnare } from '../selectors'
import ControlButton from '../../../common/components/Buttons/Button'
import { TQuestionnare } from '../types'
import QuestionnaireForm from './QuestionnaireForm'
import NewScrollContainer from '../../../common/components/NewScrollContainer/ScrollContainer'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import './Questionnaire.scss'

const mainCssClass = getCssClassName('systems-questionnaire')

function Questionnaire() {
  const dispatch = useDispatch()
  const questionnare = useSelector(selectQuestionnare)
  const questionnaireForm = useSelector(selectQuestionnaireForm)
  const { rbacControl } = useEntityContext()

  const handleArchive = (id: number) => {
    dispatch(updateStatusQuestionnaire({ id, status: 2 }))
  }

  const handleReturnArchive = (id: number) => {
    dispatch(updateStatusQuestionnaire({ id, status: 1 }))
  }

  const handleEditQuestionnaire = (item: TQuestionnare) => {
    dispatch(setActiveQuestionnaire(item))
    dispatch(openQuestionnaireForm(true))
  }

  useEffect(() => {
    dispatch(getQuestionnaire())
  }, [])

  useEffect(
    () => () => {
      dispatch(reset())
    },
    []
  )

  return (
    <div className={mainCssClass}>
      {!questionnaireForm && (
        <div className={`${mainCssClass}_create`}>
          <Typography.Title level={3}>Конструктор анкет</Typography.Title>
          {!!rbacControl?.questionnare.create_access && (
            <ControlButton
              typeButton="secondary"
              className="button"
              onClick={() => dispatch(openQuestionnaireForm(true))}
            >
              <ListAdd />
              Создать анкету
            </ControlButton>
          )}

          <div className={`${mainCssClass}_create_scrollContainer`}>
            <NewScrollContainer className={`${mainCssClass}_create_scrollContainer_scroll`}>
              <div className={`${mainCssClass}_create_questionnaire-list`}>
                {questionnare?.map((item) => (
                  <div
                    key={item.id}
                    className={cn(`${mainCssClass}_create_questionnaire-list_item`, {
                      [`${mainCssClass}_create_questionnaire-list_archive`]: item.status !== 1
                    })}
                  >
                    <div className={`${mainCssClass}_create_questionnaire-list_item_text`}>
                      <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_title`}>
                        {item.status !== 1 ? `${item.name} (архив)` : item.name}
                      </Typography.Text>
                      <Typography.Text className={`${mainCssClass}_create_questionnaire-list_item_text_quantity`}>
                        Вопросов:
                        {item?.question_list?.length}
                      </Typography.Text>
                    </div>
                    {rbacControl?.questionnare.edit_view_access === 1 && (
                      <div className={`${mainCssClass}_create_questionnaire-list_item_action`}>
                        {item.status === 1 ? (
                          <>
                            <ControlButton
                              typeButton="secondary"
                              className="button"
                              onClick={() => handleEditQuestionnaire(item)}
                            >
                              <EditPencil1 />
                            </ControlButton>
                            <ControlButton
                              typeButton="secondary"
                              className="button"
                              onClick={() => handleArchive(item.id)}
                            >
                              <Archive />
                            </ControlButton>
                          </>
                        ) : (
                          <ControlButton
                            typeButton="secondary"
                            className="button"
                            onClick={() => handleReturnArchive(item.id)}
                          >
                            <Redo />
                          </ControlButton>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </NewScrollContainer>
          </div>
        </div>
      )}

      {questionnaireForm && rbacControl?.questionnare.create_access === 1 && <QuestionnaireForm />}
    </div>
  )
}

export default memo(withErrorBoundary(Questionnaire))
