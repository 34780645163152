import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  color?: string
  onClick?: () => void
}

const mainCssClass = 'requestSvg'

function Star({
  className, size = '1', color, onClick
}: TSvgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" onClick={onClick} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3631 2.5654C12.8269 1.4029 11.1748 1.40334 10.6388 2.56545L8.22379 7.8012L2.49782 8.48009C1.22673 8.6308 0.716256 10.2019 1.656 11.0709L5.88903 14.9855L4.76526 20.6411C4.5156 21.8976 5.85352 22.8669 6.9695 22.2423L12.001 19.426L17.0322 22.2422C18.1493 22.8675 19.4854 21.8963 19.236 20.641L18.1125 14.9856L22.3452 11.071C23.2849 10.2019 22.7746 8.6308 21.5035 8.48009L18.6961 8.14726L15.7782 7.80133L14.6425 5.33905L13.3631 2.5654ZM9.9227 8.89301C9.70393 9.3673 9.25428 9.69303 8.73688 9.75438L3.80976 10.3386L7.45262 13.7074C7.83629 14.0622 8.00668 14.5902 7.90519 15.1009L6.93813 19.9679L11.2685 17.544C11.7241 17.289 12.2789 17.2896 12.7337 17.5441L17.0631 19.9675L16.0963 15.101C15.9948 14.5901 16.1654 14.0621 16.5489 13.7074L19.9894 10.5255L20.1915 10.3386L18.123 10.0933L15.2641 9.75439C14.8668 9.70728 14.5117 9.50476 14.2692 9.20101C14.1945 9.10744 14.1305 9.00427 14.0792 8.89308L12.001 4.3873L10.2138 8.26198L9.9227 8.89301Z" fill={color || '#404542'} />
    </svg>
  )
}
export default Star
