import React from 'react'
import { TClassName } from '../types/props'

type TProps = {
    onCLick?:()=>void
} & TClassName
const QuestionMark: React.FC<TProps> = ({ className, onCLick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className} onClick={onCLick}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.70799 11.256V10.8C3.70799 9.35999 4.42799 8.47199 5.74799 7.55999C6.80399 6.86399 7.85999 6.47999 7.85999 5.32799C7.85999 4.34399 7.06799 3.81599 6.10799 3.81599C5.12399 3.81599 4.13999 4.43999 4.13999 5.68799H0.515991C0.515991 2.68799 2.98799 0.23999 6.10799 0.23999C9.27599 0.23999 11.484 2.56799 11.484 5.11199C11.484 6.76799 11.1 8.01599 9.41999 9.26399C8.14799 10.224 7.33199 10.656 7.33199 11.232V11.256H3.70799ZM5.50799 17.76C4.09199 17.76 2.93999 16.608 2.93999 15.192C2.93999 13.776 4.09199 12.624 5.50799 12.624C6.92399 12.624 8.07599 13.776 8.07599 15.192C8.07599 16.608 6.92399 17.76 5.50799 17.76Z" fill="white" />

  </svg>
)
export default QuestionMark
