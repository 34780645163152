import { createReducer } from '@reduxjs/toolkit'
import { TDictionaries } from '../../common/types/dictionaries'
import { openReportFilters, reset, resetFilter, setDictionaries, setFilter, setResetFilter, setTypeReportFilter } from './actions'


type TFiltersState = {
  filterString: string
  openFilter: boolean
  dictionaries: TDictionaries | null
  defFilters: {
    type: string;
    filter: any
    defFilterString: string
  } | null
  filterType: string
  resetFilter: number
}

const initialState: TFiltersState = {
  filterString: '',
  openFilter: false,
  dictionaries: null,
  defFilters: null,
  filterType: 'sourceOfCandidates',
  resetFilter: 0
}

const reportFilterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openReportFilters, (state, { payload }) => {
      state.openFilter = payload
    })
    .addCase(reset, () => initialState)
    .addCase(setDictionaries, (state, { payload }) => {
      state.dictionaries = payload
    })
    .addCase(setTypeReportFilter, (state, { payload }) => {
      state.filterType = payload
    })
    .addCase(setFilter, (state, { payload }) => {
      state.filterString = payload
    })
    .addCase(setResetFilter, (state, { payload }) => {
      state.resetFilter = payload
    })
})

export default reportFilterReducer
