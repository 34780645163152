import React, {
  memo,
  useCallback,
  useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import SearchSelectFormControlSource from '../../../common/components/formcontrol/SearchSelectFormControlSource'
import ControlButton from '../../../common/components/Buttons/Button'
import { getCssClassName } from '../../../common/utils/generateClassName'
import InputFormControl from '../../../common/components/formcontrol/InputFormControl'

import './UserForm.scss'
import {
  createOrEditUser,
  createUser, getSynchronization, setSelectedUser, updateUserModel
} from '../actions'
import { useEntityContext } from '../../../common/context/EntityContextProvider'
import withErrorBoundary from '../../../common/hoc/withErrorBoundary/withErrorBoundary'
import InputPasswordFormControl from '../../../common/components/formcontrol/InputPasswordFormControl'
import SelectFormControl from '../../../common/components/formcontrol/SelectFormControl'
import {
  selectActualRoleOptions, selectDictionaryOptionsByName, selectRoleOptions, selectSelectedUser
} from '../selectors'
import {
  getCity, jsonDownloadAccess, jsonParseDownloadAccess, prepareForSubmitUser
} from '../utils'
import SelectFormControlMult from '../../../common/components/formcontrol/SelectFormControlMult'
import SwitchFormControl from '../../../common/components/formcontrol/SwitchFormControl'
import { Text } from '../../../common/components/Text'
import CopySvg from '../../../common/svg/CopySvg'

export type TFormValuesUser = {
  name: string
  surname:string
  patronymic:string
  role:string
  phone:string
  seat:string
  email:string
  password1:string
  password_confirm: string
  department?: number
  isColdSearch:boolean
  isCanAppointRecruiter:boolean
  group_list?: number[]
  can_assign_calendar_future_days: boolean
  is_can_conduct_interview: boolean
  can_make_report: boolean
  telegram_channel_chat_id?: string
  telegram_tag?: string
  telegram_chat_id?: string
  is_tester: boolean
  is_mass_stage: boolean
  sip_login: string
  sip_password: string
  city_id: number | null
  is_can_internship: boolean
  candidates: boolean
  requests: boolean
  vacancies: boolean
  analytics: boolean
  leads: boolean
  interviews: boolean,
  is_delete_any_comment: boolean
}

const mainCssClass = getCssClassName('user-form')

function UserForm() {
  const values = [{ label: 'Холодный поиск', value: true }]
  const [badPassword, setBadPassword] = useState<boolean>(false)
  const [codeChalengeToken, setCodeChalengeToken] = useState<string>('')
  const userInfo = useSelector(selectSelectedUser)
  const roleOptions = useSelector(selectActualRoleOptions)
  const groupOptions = useSelector(selectDictionaryOptionsByName('group'))
  const [cityOptions, setCityOptions] = useState([])

  const dispatch = useDispatch()
  const { rbacControl, role } = useEntityContext()
  const departmentOptions = [{ label: 'Без отдела', value: 299 }, { label: 'Офисный подбор', value: 174 }, { label: 'Курьеры', value: 175 }, { label: 'Розничный подбор', value: 176 }, { label: 'Курьеры (Розница)', value: 177 }]

  const {
    control, handleSubmit, reset, getValues, watch, setValue
  } = useForm<TFormValuesUser>()

  const download_access = useMemo(
    () => {
      if (userInfo) {
        return jsonParseDownloadAccess(userInfo?.download_access)
      }
      return {}
    },

    [userInfo]
  )

  const onSubmit = () => {
    const {
      password1, password_confirm, candidates, analytics, vacancies, requests, leads, interviews
    } = getValues()

    if (userInfo) {
      if (password1 === password_confirm || (!password1 && !password_confirm)) {
        dispatch(updateUserModel({ user: prepareForSubmitUser(getValues(), userInfo.id, codeChalengeToken) }))
      } else {
        setBadPassword(true)
      }
    } else if (password1 === password_confirm || (!password1 && !password_confirm)) {
      dispatch(createUser(prepareForSubmitUser(getValues(), null, codeChalengeToken)))
    } else {
      setBadPassword(true)
    }
  }
  const handleCancel = () => {
    dispatch(createOrEditUser(false))
    dispatch(setSelectedUser(null))
  }

  const isColdSearch = watch('isColdSearch')
  const isCanAppointRecruiter = watch('isCanAppointRecruiter')

  const handleChangeAppoinmemt = () => {
    if (!isCanAppointRecruiter) { setValue('isColdSearch', false) }
  }

  const handleChangeColdSearch = () => {
    if (!isColdSearch) { setValue('isCanAppointRecruiter', false) }
  }
  async function generateRandomBytes(length: any) {
    const randomValues = new Uint8Array(length)
    window.crypto.getRandomValues(randomValues)
    return randomValues
  }
  const base64Url = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  async function generateCodeVerifier(length: any) {
    const randomBytes = await generateRandomBytes(length)

    let result = ''
    randomBytes.forEach((value) => {
      const randomIndex = value % base64Url.length
      result += base64Url.charAt(randomIndex)
    })

    return result
  }
  const handleGenerateToken = async () => {
    const codeVerifier = await generateRandomBytes(32)
    const codeChaleng = await generateCodeVerifier(codeVerifier)
    setCodeChalengeToken(codeChaleng)
    const { password1, password_confirm } = getValues()
    if (userInfo) {
      if (password1 === password_confirm || (!password1 && !password_confirm)) {
        dispatch(updateUserModel({ user: prepareForSubmitUser(getValues(), userInfo.id, codeChaleng), isCodeChalenge: true }))
      } else {
        setBadPassword(true)
      }
    }
  }

  const handleClickAllGroups = () => {
    setValue('group_list', groupOptions.map((el) => el.value))
  }

  const handleSynchronization = useCallback(() => {
    if (userInfo) {
      dispatch(getSynchronization(String(userInfo?.id)))
    }
  }, [
    userInfo
  ])

  const copyPassword = () => {
    const password = watch('password1')
    navigator.clipboard.writeText(password)
      .then(() => { })
      .catch((err) => {
        console.error('Не удалось скопировать пароль: ', err)
      })
  }

  useEffect(() => {
    if (userInfo) {
      const defaultValues = {
        name: userInfo?.name,
        surname: userInfo?.surname,
        seat: userInfo?.seat || '',
        patronymic: userInfo?.patronymic,
        role: userInfo?.role?.role_id,
        phone: userInfo?.phone,
        email: userInfo?.email,
        password1: userInfo?.password,
        password_confirm: userInfo?.password,
        department: userInfo?.department_id,
        isColdSearch: userInfo?.isColdSearch,
        telegram_channel_chat_id: userInfo.telegram_channel_chat_id,
        telegram_chat_id: userInfo?.telegram_chat_id,
        telegram_tag: userInfo.telegram_tag,
        group_list: userInfo?.group_list,
        isCanAppointRecruiter: userInfo.isCanAppointRecruiter,
        can_assign_calendar_future_days: userInfo.can_assign_calendar_future_days,
        is_can_conduct_interview: !!userInfo.is_can_conduct_interview,
        can_make_report: !!userInfo.can_make_report,
        is_tester: !!userInfo.is_tester,
        is_mass_stage: !!userInfo.is_mass_stage,
        sip_login: userInfo.sip_login || '',
        sip_password: userInfo.sip_password || '',
        city_id: userInfo.city_id,
        is_can_internship: !!userInfo.is_can_internship,
        candidates: download_access.candidates,
        requests: download_access.requests,
        vacancies: download_access.vacancies,
        analytics: download_access.analytics,
        leads: download_access.leads,
        interviews: download_access.interviews,
        is_delete_any_comment: !!userInfo.is_delete_any_comment
      }
      reset(defaultValues)
    } else {
      const defaultValues = {
        name: '',
        surname: '',
        seat: '',
        patronymic: '',
        role: '',
        phone: '',
        email: '',
        password1: '',
        password_confirm: '',
        telegram_chat_id: '',
        department: null,
        isColdSearch: false,
        group_list: undefined,
        isCanAppointRecruiter: false,
        can_assign_calendar_future_days: false,
        is_can_conduct_interview: false,
        can_make_report: false,
        is_tester: false,
        is_mass_stage: false,
        sip_login: '',
        sip_password: '',
        city_id: null,
        is_can_internship: false,
        candidate: false,
        requests: false,
        vacancies: false,
        analytics: false,
        leads: false,
        interviews: false,
        is_delete_any_comment: false
      }
      reset(defaultValues)
    }
  }, [userInfo])

  useEffect(() => {
    getCity()
      .then((responseData) => {
        setCityOptions(responseData)
      })
      .catch((error) => {
        console.error('Error fetching model data:', error)
      })
  }, [])

  return (
    <div className={mainCssClass}>
      <div className={`${mainCssClass}_form`}>
        <div className={`${mainCssClass}_form_main`}>
          <div className={`${mainCssClass}_form_main_item`}>
            <InputFormControl
              name="name"
              control={control}
              label="Имя"
              required
              disabled={rbacControl?.users.edit_view_access !== 1}
              rules={{ required: true }}
            />
            <InputFormControl
              name="surname"
              control={control}
              label="Фамилия"
              required
              disabled={rbacControl?.users.edit_view_access !== 1}
              rules={{ required: true }}

            />
            <InputFormControl
              name="patronymic"
              control={control}
              label="Отчество"
              disabled={rbacControl?.users.edit_view_access !== 1}
              required
            />
          </div>
          <div className={`${mainCssClass}_form_main_item`}>
            {userInfo && (
              <SelectFormControl
                name="department"
                control={control}
                label="Департамент"
                required
                options={departmentOptions}
                rules={{ required: true }}
              />
            )}
            <SelectFormControl
              name="role"
              control={control}
              label="Роль"
              options={roleOptions}
              required
              rules={{ required: true }}
            />
          </div>
          <div className={`${mainCssClass}_form_main_item`}>
            <InputFormControl
              name="phone"
              control={control}
              label="Телефон"
              mask="+7 (999) 999-99-99"
              required
              rules={{ required: true }}
            />
            <InputFormControl
              name="email"
              control={control}
              label="Почта"
              type="email"
              disabled={rbacControl?.users.edit_view_access !== 1}
              required
            />
            {userInfo && (
              <div className={`${mainCssClass}_form_main_item_group`}>
                <SelectFormControlMult
                  name="group_list"
                  control={control}
                  label="Группы"
                  options={groupOptions}
                />
                <button type="button" className={`${mainCssClass}_form_main_item_button`} onClick={handleClickAllGroups}>Все группы</button>
              </div>
            )}
          </div>
          <div className={`${mainCssClass}_form_main_item-sip`}>
            <InputPasswordFormControl
              name="password1"
              control={control}
              label="Новый пароль"
              autoComplete="new-password"
              placeholder="Введите новый пароль"
            />
            <ControlButton className="button" typeButton="tertiary" onClick={copyPassword}>
              <CopySvg />
            </ControlButton>
            <InputPasswordFormControl
              name="password_confirm"
              control={control}
              autoComplete="new-password"
              label="Подтверждение пароля"
              placeholder="Введите новый пароль повторно"
            />
            {badPassword && (<div> Пароли не совпадают</div>)}
          </div>
          <SearchSelectFormControlSource
            name="city_id"
            label="Город"
            control={control}
            options={cityOptions}
            showSearch
            optionFilterProp="children"
          />

          <div className={`${mainCssClass}_form_main_item-sip`}>
            <InputFormControl
              name="sip_login"
              control={control}
              label="SIP логин"
            />
            <InputFormControl
              name="sip_password"
              control={control}
              label="SIP пароль"
            />
            {role?.role_id === 'superadmin'
              && (
                <ControlButton typeButton="secondary" className="button" onClick={handleSynchronization}>
                  Синхронизировать
                </ControlButton>
              )}
          </div>
          {(userInfo?.role?.role_id === 'zakazchik_kur_erov' || userInfo?.role?.role_id === 'courier_curator')
            && (
              <InputFormControl
                name="telegram_channel_chat_id"
                control={control}
                label="Телеграмм канал"
              />
            )}
          <InputFormControl
            name="telegram_chat_id"
            control={control}
            label="Телеграмм id"
          />
          {
            (userInfo?.role?.role_id === 'zakazchik_kur_erov' || userInfo?.role?.role_id === 'courier_curator')
            && (
              <InputFormControl
                name="telegram_tag"
                control={control}
                label={userInfo?.role?.role_id === 'zakazchik_kur_erov' ? 'Телеграмм тэг' : 'Телеграмм тэг'}
              />
            )
          }
          {userInfo?.token_plugin && (
            <Text tag="h3">
              {userInfo.token_plugin}
            </Text>
          )}
          {!userInfo?.token_plugin && (
            <>
              {!codeChalengeToken ? (
                <div>

                  <ControlButton
                    typeButton="primary"
                    size="large"
                    className="button"
                    disabled={!!codeChalengeToken}
                    onClick={handleGenerateToken}
                  >
                    Сгенерировать токен
                    {' '}
                  </ControlButton>
                </div>
              ) : (
                <Text tag="h4">
                  {`Токен: ${codeChalengeToken}`}

                </Text>
              )}
            </>
          )}
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="isColdSearch"
              onChange={handleChangeColdSearch}
            />
            <p>Холодный поиск</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="isCanAppointRecruiter"
              onChange={handleChangeAppoinmemt}
            />
            <p>Возможность назначать рекрутеров</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="is_delete_any_comment"
            />
            <p>Может удалять чужой комментарий</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="can_assign_calendar_future_days"
            />
            <p>Возможность назначать интервью на несколько дней вперед</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="is_can_conduct_interview"
            />
            <p>Возможность проводить интервью</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="can_make_report"
            />
            <p>Доступ к аналитике</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="is_tester"
            />
            <p>Разрешить рассылку тестовых уведомлений</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="is_can_internship"
            />
            <p>Доступ к стажировкам</p>
          </div>
          {userInfo?.role?.role_id === 'admin' || userInfo?.role?.role_id === 'superadmin' && (
            <div className={`${mainCssClass}_form_main_switch`}>
              <SwitchFormControl
                control={control}
                name="is_mass_stage"
              />
              <p>Возможность массового перевода с этапа на этап</p>
            </div>
          )}
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="candidates"
            />
            <p>Разрешить выгрузку - Кандидаты</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="requests"
            />
            <p>Разрешить выгрузку - Заявки</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="vacancies"
            />
            <p>Разрешить выгрузку - Вакансии</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="analytics"
            />
            <p>Разрешить выгрузку - Аналитика</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="leads"
            />
            <p>Разрешить выгрузку - Лиды</p>
          </div>
          <div className={`${mainCssClass}_form_main_switch`}>
            <SwitchFormControl
              control={control}
              name="interviews"
            />
            <p>Разрешить выгрузку - Интервью</p>
          </div>
          <div>
            {rbacControl?.users.edit_view_access === 1 && (
              <div className={`${mainCssClass}_actions`}>
                <ControlButton typeButton="primary" size="large" className="button" onClick={handleSubmit(onSubmit)}>
                  Сохранить
                </ControlButton>
                <ControlButton typeButton="secondary" size="large" className="button" onClick={handleCancel}>
                  Отменить
                </ControlButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(withErrorBoundary(UserForm))
