import React from 'react'
import cn from 'classnames'
import './Svg.scss'

type TSvgProps = {
  className?: string
  size?: string
  fill?: string
}

const mainCssClass = 'phoneSvg'

function PhoneSvg({ className, size = '1', fill }: TSvgProps) {
  return (
    <svg
      className={cn(
        className,
        mainCssClass,
        { s_svg: size === '0.5' },
        { m_svg: size === '1' },
        { l_svg: size === '2' },
        { xl_svg: size === '4' },
        { xxl_svg: size === '8' }
      )}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.89474 0C1.29599 0 0 1.29608 0 2.89478C0 12.3418 7.65852 20 17.1055 20C18.7043 20 20 18.7039 20 17.1053L19.9999 14.354C19.9999 13.1272 19.2528 12.0242 18.114 11.5687L15.4785 10.5144C14.4555 10.1052 13.2906 10.2896 12.444 10.9951L11.7629 11.5625C11.3655 11.8936 10.7813 11.8671 10.4156 11.5014L8.49844 9.58437C8.13295 9.21885 8.1064 8.63457 8.43768 8.23704L9.00504 7.55616C9.71053 6.70954 9.89486 5.54444 9.48567 4.52148L8.43143 1.88583C7.97584 0.746853 6.87271 0 5.646 0H2.89474ZM2.89474 2C2.40061 2 2 2.4006 2 2.89478C2 11.2372 8.76304 18 17.1055 18C17.5995 18 18 17.5996 18 17.1054L17.9999 14.3541C17.9999 13.9453 17.751 13.5775 17.3712 13.4256L14.7357 12.3714C14.3947 12.2349 14.0065 12.2964 13.7244 12.5315L13.0433 13.0989C11.8513 14.0922 10.0986 14.0128 9.00146 12.9157L7.08428 10.9986C5.98694 9.9013 5.90808 8.14849 6.90119 6.95673L7.46857 6.27582C7.70363 5.99375 7.76518 5.60541 7.62872 5.26425L6.57447 2.62861C6.42261 2.24895 6.0549 2 5.646 2H2.89474Z"
        fill={fill || '#2DBE64'}
      />
    </svg>
  )
}
export default PhoneSvg
